<template>
  <div class="mb-3">
    <TextInput
      v-model="valueModel"
      :label="label"
      :placeholder="placeholder"
      :icon="icon ? `social/${icon}` : null"
      :fixed-placeholder="fixedPlaceholder"
      :input-left-padding="inputLeftPadding"
    />
  </div>
</template>

<script>
import TextInput from '@/components/atoms/form/HeraFormTextInput';

export default {
  name: 'InputSocial',
  components: {
    TextInput,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    fixedPlaceholder: {
      type: String,
      default: null,
    },
    inputLeftPadding: {
      type: String,
      default: null,
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
