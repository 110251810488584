import store from '@/store';
import { STATIC_PAGES } from './static-pages.js';
//import { PROFILE_SETUP } from '@/constants/router';

export const fromStatic = (to, from, next) => {
  if (STATIC_PAGES.includes(from.name)) {
    next(from);
  } else {
    next();
  }
};
export const ifNotAuthorized = (to, from, next) => {
  !store.getters.isAuthorized ? next() : next({ path: '/' });
};
export const ifAuthenticated = (to, from, next) => {
  store.getters.isAuthenticated ? next() : next({ name: 'signin' });
};
export const ifPartiallyAuthorized = (to, from, next) => {
  if (
    store.getters.isPartiallyAuthorized &&
    from.name === 'verifyCode' &&
    from.params.from === 'reset'
  ) {
    return next();
  }

  next({ name: 'signin' });
};
const saveActivationCodeToSessionStorage = activationCode => {
  sessionStorage.setItem('activation_code', activationCode);
  store.dispatch('ph/setPhTempActivationCode', activationCode);
};

export const hasActivationCode = (to, from, next) => {
  if (to.query.activation_code) {
    saveActivationCodeToSessionStorage(to.query.activation_code);

    if (!store.getters.isAuthorized) {
      next({ name: 'signin' });
    } else {
      next({ name: 'billing' });
    }
  } else {
    next();
  }
};

export const hasActivationCodeInSession = (to, from, next) => {
  const activationCode = sessionStorage.getItem('activation_code');
  if (activationCode && !store.getters.isAuthorized) {
    store.dispatch('ph/setPhTempActivationCode', activationCode);

    if (!store.getters.isAuthorized) {
      next({ name: 'signin' });
    } else {
      next({ name: 'billing' });
    }
  } else {
    next();
  }
};

export const hasLanguageinQuery = (to, from, next) => {
  if (to.query.lang) {
    sessionStorage.setItem('lang', to.query.lang);
  }
  next();
};

export const ifAuthorized = (to, from, next) => {
  if (store.getters.regionLoading) {
    return;
  }

  if (store.getters.isAuthorized) {
    return store
      .dispatch('my/fetchMyAccount')
      .then(() => {
        /*console.log(JSON.stringify(data));
        if (
          Array.isArray(data) &&
          data.length === 0 &&
          this.account &&
          this.account.incomplete &&
          to.name !== PROFILE_SETUP
        ) {
          window.location.href = `${window.location.origin}/profile-setup/1`;
        }*/

        // please not remove this comment. Saved for QA
        // store.commit('clearToken', 'tempToken'); // used for verification phone =/
        return next();
      })
      .catch(() => {
        // try fetchMyAccount in MainLayout (because interceptor use $store)
        // todo replace it after TH-8347
        next();
      });
  }
  next();
  // next({ name: 'signin', params: { redirectTo: to.fullPath } });
};

export const beforeEnterCheckAuth = (to, from, next) => {
  store.getters.isAuthorized ? next() : next('/signin');
};

//ovdje je bio PleaseSignIn modal

export const beforeEnterCheckManagementAuth = (to, from, next) => {
  store.getters.isAuthorized
    ? store.getters.hasAccessByPermission('editTournament')
      ? next()
      : store.dispatch('modalNotify', {
          message: 'Access denied',
          messageKey: '_web_auth_error',
        })
    : next('/signin');
  // ovdje je bio PleaseSignIn modal
};

export const regionRedirect = async to => {
  const isAuth = store.getters.isAuthorized;
  if (isAuth) {
    const region = store.getters.currentRegion;
    if (!region) return null;
    if (!to.matched[0].path.includes(region.id)) {
      return {
        path: 'wrongregion',
      };
    } else if (
      to.matched[0].path.includes(region.id) &&
      to.query.registration_token
    ) {
      return { path: to.params.pathMatch, query: to.query };
    } else {
      return { path: to.params.pathMatch };
    }
  } else {
    try {
      // Dispatch the action to fetch regions
      await store.dispatch('fetchRegions');

      // Now that regions are fetched, access the getters
      const region = store.getters.currentRegion;
      const possibleRegions = store.getters.regions;

      if (!region) {
        console.log('no current');
        return null; // No redirection needed if there's no current region
      }

      // Check if the current path includes the region ID
      if (!to.matched[0].path.includes(region.id)) {
        console.log('Path does not include region ID:', region.id);
        const regionToSet = possibleRegions.find(reg => {
          return to.matched[0].path.split('/')[1] === reg.id;
        });

        if (regionToSet.id) {
          // store.commit('setRegion', regionToSet);
          // Redirect to the new path with the updated region
          return {
            path: 'wrongregion',
          };
        } else {
          // Handle case where region is not found in possible regions
          return { path: `${to.params.pathMatch}` };
        }
      } else if (
        to.matched[0].path.includes(region.id) &&
        to.query.registration_token
      ) {
        return { path: to.params.pathMatch, query: to.query };
      }

      // If the path already includes the correct region ID
      return { path: `${to.params.pathMatch}` };
    } catch (error) {
      console.error('Error fetching regions:', error);
      return null;
    }
  }
};
export default {
  ifNotAuthorized,
  ifAuthenticated,
  ifPartiallyAuthorized,
  ifAuthorized,
  beforeEnterCheckAuth,
  beforeEnterCheckManagementAuth,
  regionRedirect,
};
