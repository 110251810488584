<template>
  <component :is="tag">
    <template v-for="(arr, index) in contentArr">
      <bdi
        v-if="arr[0]"
        :key="`text_span_${index}`"
        class="bdi-wraper-text"
        v-text="arr[0]"
      ></bdi>
      <template v-if="arr[1]">
        <bdi :key="`bdi_wrapper_${index}`">
          <router-link
            v-if="arr[1].to"
            :key="`text_a_${index}`"
            :to="
              arr[1].to.name === 'post'
                ? { path: `singlePosts/${arr[1].to.params.id}` }
                : arr[1].to
            "
            @click.native="event => event.stopImmediatePropagation()"
            v-text="arr[1].text || arr[1].to.name"
          />
          <a
            v-else-if="arr[1].fn"
            :key="`text_a_1`"
            :href="arr[1].href || '#'"
            class="resend-link"
            @click.prevent="arr[1].fn"
            v-text="arr[1].text || arr[1].href"
          />
          <a
            v-else-if="arr[1].href"
            :key="`text_a_2`"
            :href="arr[1].href"
            target="_blank"
            v-text="arr[1].text || arr[1].href"
          />
        </bdi>
      </template>
    </template>
  </component>
</template>

<script>
import { zip } from '@/utils/lodashUtils';

export default {
  name: 'TextWithLinks',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    content: {
      type: String,
      default: '',
    },
    contentProps: {
      type: Object,
      default: () => ({}),
    },
    translateKey: {
      type: String,
      default: '',
    },
    truncate: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    contentArr() {
      const propsLinks = this.getPropsLinks();
      const propsString = this.getPropsStrings();

      let strTranslate = this.translateKey
        ? this.$t(this.translateKey, this.content, propsString)
        : this.content;

      strTranslate = strTranslate.replace(/{:(.+?)}/g, `<$1>`);

      if (this.truncate) {
        strTranslate = strTranslate
          .slice(0, this.truncate)
          .replace(/<[^>]*$/, '')
          .replace(/\s+[^\s]{1,24}$/, ' ');
        strTranslate += '...';
      }
      // PH-811
      // new line lookbehind regex removed, since it does not work in Safari and its meaning is unclear.
      // it was introduced as experimental functionality for bi directional posts with simple text
      // const arrTexts = strTranslate.split(/<.+?>|(?<=\n)/gm); // '<a>foo<b>bar <c> baz' => ['', 'foo', 'bar ', ' baz'];

      const arrTexts = strTranslate.split(/<.+?>/gm); // '<a>foo<b>bar <c> baz' => ['', 'foo', 'bar ', ' baz'];
      const arrLinks = strTranslate.match(/<.+?>/g) || []; // '<a>foo<b>bar <c> baz' => ['a', 'b', 'c'];

      const arrLinksObject = arrLinks.map(
        propName => propsLinks[propName.slice(1, -1)]
      );

      return zip(arrTexts, arrLinksObject);
    },
  },
  methods: {
    getPropsLinks() {
      const props = this.contentProps;

      return Object.keys(props).reduce((res, key) => {
        const item = props[key];
        if (typeof item === 'object') {
          res[key] = item || {};
        }
        return res;
      }, {});
    },
    getPropsStrings() {
      const props = this.contentProps;

      return Object.keys(props).reduce((res, key) => {
        if (typeof props[key] !== 'object') {
          res[key] = `${props[key]}`;
        }
        return res;
      }, {});
    },
  },
};
</script>

<style lang="scss">
.resend-link {
  font-size: 16px;
  color: white;
  text-decoration: underline;
}

.bdi-wraper-text {
  line-height: 23px;
  text-align: center;
}
</style>
