import api from '@/api';

//fileDirectPreupload,
//fileDirectUpload,
//fileCommitUpload,

const actions = {
  preUploadFile: ({ getters: { accessToken } }, payload) =>
    api.files
      .filePreupload(accessToken, payload)
      .then(response => response.data),

  preUploadFileForAdmin: ({ getters: { accessToken } }, payload) =>
    api.files
      .filePreuploadForAdmin(accessToken, payload)
      .then(response => response.data),

  uploadFile: ({ getters: { accessToken } }, payload) =>
    api.files
      .fileUpload(payload.uploadURI, accessToken, payload.blob)
      .then(response => response.data),
  async sendFile(
    {
      getters: { accessToken },
    },
    fileData
  ) {
    const preuploadInfo = {
      file_name: fileData.name,
      file_size: fileData.size,
    };
    const { data } = await api.files.filePreupload(accessToken, preuploadInfo);
    await api.files.fileUpload(data.upload_uri, accessToken, fileData);
    return data;
  },
  async sendFileDirect(
    {
      getters: { accessToken },
    },
    { fileData = {}, config = {} }
  ) {
    const { data: preuploadData } = await api.files.fileDirectPreupload(
      accessToken,
      {
        file_name: fileData.name,
        file_size: fileData.file_size || fileData.rawSize || fileData.size,
      }
    );
    await api.files.fileDirectUpload(
      preuploadData.upload_uri,
      fileData.blob || fileData,
      config
    );
    const commitData = await api.files.fileCommitUpload(accessToken, {
      file_id: preuploadData.file_id,
    });
    return {
      ...preuploadData,
      ...(commitData ? commitData.data || null : null),
    };
  },
  // fileDirectPreupload: ({ getters: { accessToken } }, payload) =>
  //   api.files
  //     .fileDirectPreupload(accessToken, payload)
  //     .then(response => response.data),
  // fileDirectUpload: ({ getters: { accessToken } }, payload) =>
  //     api.files
  //       .fileDirectUpload(payload.uploadURI, accessToken, payload.blob)
  //       .then(response => response.data),
};

export default {
  actions,
};
