<template>
  <div class="post-poll my-5">
    <p class="hera-muted mb-5 d-none">
      {{ $t('_web_post_anonymous-poll', 'Anonymous Poll') }}
    </p>

    <ul
      class="list-unstyled"
      :class="{ 'post-poll_options--not-voted': !isVoted }"
    >
      <li
        v-for="option in options"
        :key="option.id"
        class="my-4 post-poll_option hover-possible"
        :class="{ 'cursor-pointer': !voteImpossible }"
        @click="vote(option)"
      >
        <div class="post-poll_bar-container">
          <div
            class="d-flex w-100 align-items-center justify-content-between custom-positions"
          >
            <PrimaryText
              class="text-bar"
              :class="voteImpossible ? '' : 'h-ml-2'"
              :text="option.text"
            />
            <PrimaryText
              class="precentage-bar"
              :text="`${getOptionPercents(option)}%`"
            />
          </div>
          <div
            class="post-poll_bar"
            :class="{
              'post-poll_bar--is-voted': option.voted,
              'post-poll_bar--is-visible': true,
            }"
            :style="{ width: `${getOptionPercents(option)}%` }"
          >
            <div
              class="d-flex w-100 align-items-center justify-content-between mb-4"
            >
              <transition name="fade"> </transition>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div style="margin-top: 12px;">
      <span class="hera-muted">
        {{ $t('_web_post_poll-votes', '{votes} votes', { votes }) }}
      </span>
      <span :class="{ voted: isVoted }">
        {{
          isVoted ? $t('_web_post_poll-thank', 'Thank you for your vote!') : ''
        }}
      </span>
      <span v-if="!isCompleted" class="hera-muted">
        {{
          $t('_web_post_poll-time-left', '{timeLeftLabel} left', {
            timeLeftLabel,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { secToDuration } from '@/filters/dateTime';
//import Radio from '@/components/atoms/form/HeraFormRadio';
import PrimaryText from '@/components/atoms/PrimaryText';

export default {
  components: {
    //Radio,
    PrimaryText,
  },

  props: {
    poll: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      options: [],
    };
  },

  computed: {
    ...mapState('my', ['account', 'accountId']),
    ...mapGetters(['isAuthorized']),
    votes() {
      return this.options.reduce((acc, { votes }) => acc + votes, 0);
    },

    isVoted() {
      return this.options.some(({ voted }) => voted);
    },

    timeLeft() {
      return this.poll.endTime - Date.now() / 1000;
    },

    timeLeftLabel() {
      return secToDuration(this.timeLeft);
    },

    isCompleted() {
      return this.timeLeft < 1;
    },

    voteImpossible() {
      return this.isVoted || this.isCompleted;
    },
  },

  watch: {
    poll() {
      this.cloneOptions();
    },
  },

  created() {
    this.cloneOptions();
  },

  methods: {
    ...mapActions(['votePollPost', 'errorNotify']),

    cloneOptions() {
      this.options = [...this.poll.options];
    },

    vote(option) {
      if (this.isAuthorized) {
        if (this.voteImpossible) {
          return;
        }
        option.voted = true;
        option.votes++;
        this.votePollPost({
          pollId: this.poll.id,
          optionId: option.id,
        })
          .then(
            window.dataLayer.push({
              event: 'post voting',
              from: this.$route.path,
              hasAttachments: true,
              postType: 'poll',
              postID: this.poll.id,
              userID: this.accountId,
            })
          )
          .catch(this.errorNotify);
      } else {
        this.$store.dispatch('openModal', {
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
          events: {
            cancel: this.$store.dispatch('closeModal'),
          },
        });
      }
    },

    getOptionPercents(option) {
      const value = Math.round((option.votes / this.votes) * 100);
      return isNaN(value) ? 0 : value;
    },
  },
};
</script>

<style lang="scss">
.post-poll {
  position: relative;
  &_option {
    word-break: break-word;
    transition: margin 0.3s;
  }

  &_bar {
    height: 0;
    background-color: #141338;
    min-width: 0.25rem;
    border-radius: $ph-small-radius;
    transition: width 1s;

    &--is-voted {
      background-color: #5a58f2;
    }

    &--is-visible {
      height: 3rem;
    }
  }
}
.voted {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5a58f2;
  padding-left: 3px;
  padding-right: 3px;
}
.post-poll_bar-container {
  margin-bottom: 12px;
}
.custom-positions {
  position: absolute;
  transform: translateY(14px);
}
.text-bar {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-left: 28px;
  padding-right: 28px;
}
.precentage-bar {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.post-poll_options--not-voted .post-poll_option.hover-possible:hover {
  background-color: #12161f;
}
.post-poll_options--not-voted .post-poll_option.hover-possible {
  background-color: #181c25;
}
</style>
