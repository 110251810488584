<template>
  <div>
    <HeraFormInput
      v-model="title"
      v-validate="'required|max:100'"
      required
      :view-mode="disabled"
      :disabled="isStageFieldDisabled('stageName') || disabled"
      :label="$t('_web_tournament_stage_label', 'Stage name:')"
      :placeholder="$t('_web_tournament_stage_placeholder', 'Stage name')"
      :error="errors.first('stage-title')"
      :data-vv-as="$t('_web_tournament_stage_error-label', 'Stage name')"
      data-vv-name="stage-title"
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormInput from '@/components/atoms/form/HeraFormTextInput';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageTitleSetting',
  components: {
    HeraFormInput,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    title: {
      get() {
        return this.$lodash.get(
          this.currentTournamentStage,
          'title',
          this.$t(
            '_web_tournament_stage_editor_default_title',
            'Stage {index}',
            { index: this.currentTournamentStage.index || 1 }
          )
        );
      },
      set(value) {
        this.updateCurrentTournamentStage({
          title: value,
        });
      },
    },
  },
};
</script>

<style></style>
