import api from '@/api';

const INITIAL_STATE = () => {
  return {
    selectedCategory: null,
    categories: [],
    shopSearch: '',
  };
};

const getters = {
  selectedCategory: state => state.selectedCategory,
  categories: state => state.categories,
  shopSearch: state => state.shopSearch,
};

const mutations = {
  setSelectedCategory(state, category) {
    state.selectedCategory = category;
  },
  setCategoryArray(state, categories) {
    state.categories = categories.items;
  },
  setShopSearch(state, shopSearch) {
    state.shopSearch = shopSearch;
  },
};

const actions = {
  setCategories({ commit }, categories) {
    commit('setCategoryArray', categories);
  },

  selectCategory({ commit }, category) {
    commit('setSelectedCategory', category);
    window.dataLayer.push({
      event: 'shop cat view',
      categoryID: category.id,
      categoryName: category.name,
    });
  },

  setSearchShop({ commit }, shopSearch) {
    commit('setShopSearch', shopSearch);
  },
  // products and categories **************************************************************
  fetchProducts(
    {
      getters: { accessToken },
    },
    {
      pageSize,
      page,
      page_number = 1,
      category,
      search,
      cost_from,
      cost_to,
      lang,
    } = {}
  ) {
    return api.shop
      .getProducts(
        accessToken,
        pageSize,
        page,
        page_number,
        category,
        search,
        cost_from,
        cost_to,
        lang
      )
      .then(res => {
        if (search && search.trim() !== '') {
          window.dataLayer.push({
            event: 'shop search',
            searchTermText: search,
          });
        }
        return res.data;
      });
  },

  fetchIndividualCard(
    {
      getters: { accessToken },
    },
    { id, lang } = {}
  ) {
    return api.shop.getIndividualCard(accessToken, id, lang).then(res => {
      return res.data;
    });
  },

  fetchCategories(
    {
      commit,
      getters: { accessToken },
    },
    { lang } = {}
  ) {
    return api.shop.getCategories(accessToken, lang).then(res => {
      commit('setCategoryArray', res.data);
    });
  },
  fetchCategoriesNew(
    {
      getters: { accessToken },
    },
    { lang } = {}
  ) {
    return api.shop.getCategories(accessToken, lang);
  },
  fetchSingleCard(
    {
      getters: { accessToken },
    },
    { id, lang } = {}
  ) {
    return api.shop.getSingleCard(accessToken, id, lang).then(res => {
      return res.data;
    });
  },

  // orders *******************************************************************************

  fetchOrders(
    {
      getters: { accessToken },
    },
    { pageSize = 20, page, page_number, status } = {}
  ) {
    return api.shop
      .getOrders(accessToken, pageSize, page, page_number, status)
      .then(res => {
        return res.data;
      });
  },
  fetchOrderStatus(
    {
      getters: { accessToken },
    },
    { provider, id }
  ) {
    return api.shop.checkOrderStatus(accessToken, provider, id).then(res => {
      return res.data;
    });
  },

  fetchOrderById(
    {
      getters: { accessToken },
    },
    { id }
  ) {
    return api.shop.getOrderById(accessToken, id).then(res => {
      return res.data;
    });
  },

  fulfillOrder(
    {
      getters: { accessToken },
    },
    { id, token, save_card }
  ) {
    return api.shop
      .orderFulfill(accessToken, id, token, save_card)
      .then(res => res.data);
  },
  couponApply(
    {
      getters: { accessToken },
    },
    { orderId, coupon }
  ) {
    return api.shop
      .applyCoupon(accessToken, orderId, coupon)
      .then(res => res.data);
  },
  cancelOrder(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api.shop.deleteOrder(accessToken, id);
  },
  revokeForPaid(
    {
      getters: { accessToken },
    },
    { clan, tournament }
  ) {
    return api.shop
      .tournamentRevokePaid(accessToken, clan, tournament)
      .then(res => res.data);
  },

  postOrder(
    {
      getters: { accessToken },
    },
    data
  ) {
    return api.shop.placeOrder(accessToken, data).then(res => res.data);
  },
  postTournamentOrder(
    {
      getters: { accessToken },
    },
    data
  ) {
    return api.shop
      .placeTournamentOrder(accessToken, data)
      .then(res => res.data);
  },
  fetchCards({ getters: { accessToken } }) {
    return api.shop.getCards(accessToken).then(res => {
      return res.data;
    });
  },
};

export default {
  state: INITIAL_STATE(),
  getters,
  mutations,
  actions,
  // namespaced: true,
};
