<template>
  <Panel
    class="tournament-info_format"
    :variant="['transparent', 'rounded-pre-medium']"
  >
    <Subheader
      class="mb-4 text-uppercase font-weight-normal"
      :text="$t('_web_tournament-info_game', 'Game')"
      :text-block-props="{ size: 0.875, lineHeight: 1.5 }"
      variant="hr-secondary"
    />
    <div class="d-flex game-div">
      <div class="main-img-container">
        <img :src="currentTournament.logo" class="main-img" />
      </div>
      <span v-if="gameName" class="game-title">{{ gameName }} </span>
      <img
        :src="starSrc"
        :class="starClass"
        alt="Star icon"
        @click="filled = !filled"
      />
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import Subheader from '@/components/atoms/Subheader';
import Panel from '@/components/atoms/Panel.vue';
import StarFill from '@/assets/images/auth/starFill.svg';
import Star from '@/assets/images/auth/star.svg';
export default {
  name: 'Game',
  components: { Subheader, Panel },
  data() {
    return {
      filled: false,
    };
  },

  computed: {
    starClass() {
      return this.filled ? 'star-icon-fill' : 'star-icon';
    },
    starSrc() {
      return this.filled ? StarFill : Star;
    },
    ...mapGetters([
      'currentTournament',
      'games',
      'accountEditData',
      'gameByID',
    ]),
    game() {
      return this.gameByID(this.currentTournament.game);
    },
    gameName() {
      return this.$lodash.get(this, 'game.name') || '';
    },
  },
};
</script>
<style lang="scss">
.game-div {
  align-items: center;
  @media (max-width: 1300px) {
    .game-title {
      padding: 0px;
    }
    .main-img-container {
      display: none;
    }
    // flex-direction: column;
    // gap: 5px;
    // align-items: flex-start;
  }
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    .game-title {
      padding: 5px;
    }
    .main-img-container {
      display: flex;
    }
  }
}
.main-img-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
}
.main-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  min-width: 40px;
  border-radius: $hr-radius-01;
}

.game-title {
  flex-grow: 1;
  display: flex;
  word-break: break-word;
  white-space: normal;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
}

.star-icon {
  width: 32px !important;
  height: 32px !important;
  border: 1px solid $hr-text-secondary;
  border-radius: $hr-radius-01;
  padding: 3px;
}

.star-icon-fill {
  /* bottom: 15px; */
  width: 32px !important;
  height: 32px !important;
  border: 1px solid $hr-text-secondary;
  border-radius: $hr-radius-01;
  padding: 5px;
}
</style>
