<template>
  <div class="h-progress-wrapper d-flex flex-column">
    <TextBlock
      v-if="showValue"
      tag="div"
      class="h-progress-value mb-3 w-100 text-center"
      :size="0.625"
      :line-height="1"
      >{{ percents }}%</TextBlock
    >
    <div class="h-progress-bar d-flex align-items-center justify-content-start">
      <div
        class="h-progress-bar-line d-flex flex-column justify-content-center"
        :style="{ width: `${percents}%` }"
      ></div>
    </div>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
export default {
  name: 'ProgressBar',
  components: {
    TextBlock,
  },
  props: {
    current: prop(Number, 0),
    max: prop(Number, 0),
    showValue: prop(Boolean, false),
  },
  computed: {
    percents() {
      return ((this.current * 100) / this.max) ^ 0 || 0;
    },
  },
};
</script>

<style lang="scss">
$progressHeight: 0.5rem;
.h-progress-wrapper {
  .h-progress-bar {
    overflow: hidden;
    line-height: 0;
    height: $progressHeight;
    background-color: var(--ph-input-bg);
    border-radius: math.div($progressHeight, 2);
    .h-progress-bar-line {
      background-color: var(--ph-green);
      height: $progressHeight;
      border-radius: math.div($progressHeight, 2);
      overflow: hidden;
      white-space: nowrap;
      transition: width 0.3s ease;
      will-change: width;
    }
  }
}
</style>
