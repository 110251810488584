import isLoading from './isLoading';
import { uniqBy } from '@/utils/lodashUtils';

export const getPageData = {
  mixins: [isLoading],
  data() {
    return {
      pageIndex: 0,
      pageSize: 20,
      totalItems: 0,
      properties: {},
    };
  },
  computed: {
    listIsDisabled() {
      return this.isLoading;
    },
  },
  created() {
    if (this.$route.params.page) {
      this.pageIndex = this.$route.params.page;
    }
  },
  methods: {
    paramsWithPageNumber(params = {}) {
      if (!this.pageIndex) {
        this.setDefaultPageNumber();
      }
      return {
        ...params,
        page_number: this.pageIndex,
        page_size: params.page_size ? params.page_size : this.pageSize,
      };
    },
    setDefaultPageNumber() {
      this.pageIndex = 0;
    },
    getPageData(listName, dataFetcher = Promise.resolve(), options = {}) {
      this.setLoading();
      this.toggleCrashed(false);

      return dataFetcher
        .then(response => {
          this.isLoading = false;

          if (!response) {
            return;
          }

          let items = (response && response.items) || [];
          Object.entries(options).forEach(([method, fn]) => {
            if (method in Array.prototype && typeof fn === 'function') {
              const replacedList = items[method](fn);
              if (Array.isArray(replacedList)) {
                items = replacedList;
              }
            }
          });

          this.properties = response.properties || null;

          this[listName] = items;

          // replace and unique items:
          if (options.uniqBy) {
            this[listName] = uniqBy(this[listName], options.uniqBy);
          }

          this.totalItems = response.total || items.length;
          return this[listName];
        })
        .catch(error => {
          this.toggleCrashed(true);
          return this.$store.dispatch('errorNotify', error);
        });
    },
  },
};

export default [getPageData];
