<template>
  <div class="text-content-wrapper">
    <TextBlock unset-styles tag="div">
      <TextWithLinks
        tag="div"
        :dir="contentDirAttr"
        :class="[contentAlignment]"
        :truncate="currentLength"
        :content="contentWithLinks.content ? contentWithLinks.content : ''"
        :content-props="contentWithLinks.props"
        class="d-inline"
      />
      <a
        v-if="textIsOverflow"
        href="#"
        class="toggle mx-2 toggled-text"
        :class="{
          'toggled-text': this.$router.history.current.path.includes('feed'),
        }"
        @click.prevent="toggleExpand"
      >
        {{ toggleText }}
      </a>
      <!-- <template v-else>{{ content | truncate(currentLength) }}</template> -->
    </TextBlock>
  </div>
</template>

<script>
import TextWithLinks from '@/components/text/TextWithLinks';
import TextBlock from '@/components/atoms/TextBlock';
import direction from 'direction';
import { REGEX_URL, REGEX_MENTION_TAG } from '@/constants/regexps';
import { mapGetters } from 'vuex';

const EXPAND_TEXT_LENGTH = 250;
const EXPANDED_TEXT_IF_MORE = 500;

export default {
  name: 'TextContent',
  components: {
    TextBlock,
    TextWithLinks,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    hasExpander: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const textIsOverflow =
      this.hasExpander && this.content.length > EXPANDED_TEXT_IF_MORE;
    return {
      isExpanded: textIsOverflow,
      textIsOverflow,
    };
  },
  computed: {
    ...mapGetters(['localeDirection']),
    contentDirection() {
      return direction(this.content);
    },
    contentDirAttr() {
      return this.contentDirection === 'neutral'
        ? 'auto'
        : this.contentDirection;
    },
    contentAlignment() {
      return this.contentDirection === 'neutral' ||
        this.contentDirection.toLowerCase() ===
          this.localeDirection.toLowerCase()
        ? ''
        : this.contentDirection === 'rtl'
        ? 'text-right'
        : 'text-left';
    },
    currentLength() {
      return this.isExpanded ? EXPAND_TEXT_LENGTH : undefined;
    },
    contentWithLinks() {
      if (!this.content) {
        return '';
      }

      // let hasLinks = false;
      const props = {};
      const content =
        this.content === null
          ? ''
          : this.content
              // _blank links
              .replace(REGEX_URL, (match, input, protocol, path, offset) => {
                // hasLinks = true;
                const key = `link_${offset}`;

                props[key] = {
                  href: protocol ? match : '//' + match,
                  text: match,
                };
                return `{:${key}}`;
              })
              // domain links
              .replace(REGEX_MENTION_TAG, (match, type, id, text, offset) => {
                // hasLinks = true;
                const key = `${type}_${id}_${offset}`;

                if (type === 'hashtag') {
                  props[key] = {
                    text,
                    to: {
                      name: this.$routerNames.MAIN_NEWSFEED,
                      params: { tag: id },
                    },
                  };
                  return `{:${key}}`;
                }

                props[key] = {
                  text,
                  to: {
                    name: this.$routerNames.ACCOUNT,
                    params: {
                      accountType: type,
                      accountId: id,
                    },
                  },
                };
                return `{:${key}}`;
              });

      // if (!hasLinks) {
      //   return null;
      // }

      return {
        content,
        props,
      };
    },
    toggleText() {
      return this.isExpanded
        ? this.$t('_web_text-content_more', 'More')
        : this.$t('_web_text-content_less', 'Less');
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss">
.text-content-wrapper {
  a {
    color: white;
    font-weight: 700;
    @include inject-pseudo {
      color: white;
    }

    .toggle {
      font-size: $ph-basic-font-size;
    }
  }
  white-space: pre-wrap; /* Preserve line breaks and paragraphs */
  word-wrap: break-word; /* Break long words if necessary */
  overflow: hidden;
}
.toggled-text {
  white-space: initial;
}
</style>
