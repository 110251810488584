<template>
  <div>
    <div v-if="!reposted && author" class="avatar-preview">
      <LinkAccount
        :id="author && author._id"
        :type="author && author._type"
        :vanity-id="author && author.vanityId"
      >
        <Avatar
          class="avatar-icon"
          :img-url="author && author._avatar"
          :size="48"
          :type="author && author._type"
          :is-deleted="author && author.deleted"
        />
      </LinkAccount>
      <div v-if="isAuthorVisible && author" class="row no-gutters mb-2">
        <div class="col-auto d-flex align-items-center author-spacing">
          <LinkAccount
            :id="author && author._id"
            :type="author._type"
            :vanity-id="author.vanityId"
            ><div class="author-name spacing-name-author">
              <span class="author-name-padding">{{ author._name }}</span>
              <span class="author-tag"
                >@{{
                  author._type === 'organization' || 'clan'
                    ? author.name
                    : author.login
                }}</span
              >
            </div>
            <span class="author-time">
              {{ post.created_on | timeAgo($t) }}
            </span>
          </LinkAccount>
        </div>
      </div>
    </div>
    <div class="post-body" :class="{ 'reposted h-pl-5 my-4': reposted }">
      <div v-if="reposted">
        <LinkAccount
          :id="author._id"
          :type="author._type"
          :vanity-id="author.vanityId"
        >
          <div class="avatar-preview">
            <Avatar
              class="avatar-icon"
              :img-url="author._avatar"
              :size="48"
              :type="author._type"
              :is-deleted="author.deleted"
            />
            <div class="avatar-text">
              <div class="author-name">
                {{ author._name }}
                <span class="author-tag"
                  >@{{
                    author._type === 'organization' || 'clan'
                      ? author.name
                      : author._id
                  }}</span
                >
              </div>
              <span class="author-time">
                {{ post.created_on | timeAgo($t) }}
              </span>
            </div>
          </div>
        </LinkAccount>
      </div>
      <template v-if="type === GENERIC || type === POLL">
        <TextContent
          v-if="postText"
          has-expander
          :content="postText"
          class="my-3 font-size-sm"
        />
        <template v-if="post.attachments && post.attachments.length">
          <MediaGrid :media="post.attachments" :thumbnail="post.thumbnail" />
        </template>
        <LinkPreview v-else-if="post.preview" v-bind="{ ...post.preview }" />
      </template>
      <Poll v-if="type === POLL" :poll="post.poll" />
      <router-link
        v-else-if="
          type === TOURNAMENT_FINISHED || type === TOURNAMENT_ANNOUNCED
        "
        :to="{
          path: `/tournaments/${post.tournament.id}`,
          params: { tournamentId: post.tournament.id },
        }"
        class="d-block text-decoration-none"
      >
        <Tournament
          :tournament="post.tournament"
          :owner="post.author"
          :is-announced="type === TOURNAMENT_ANNOUNCED"
        />
      </router-link>
    </div>
  </div>
</template>
<script>
import { get } from '@/utils/lodashUtils';
import Avatar from '@/components/images/Avatar';
import MediaGrid from '@/components/common/MediaGrid.vue';
import TextContent from '@/components/text/TextContent';
import Poll from './components/Poll';
import Tournament from './components/Tournament';
import LinkAccount from '@/components/links/Account';
import AccountModel from '@/models/Account';
import LinkPreview from '@/components/molecules/OpenGraphCard';
import {
  GENERIC,
  POLL,
  TOURNAMENT_FINISHED,
  TOURNAMENT_ANNOUNCED,
  INJECTION_POSTS,
} from '@/constants/postTypes';
export default {
  name: 'Post',
  components: {
    LinkAccount,
    TextContent,
    Avatar,
    MediaGrid,
    Poll,
    Tournament,
    LinkPreview,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    reposted: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: GENERIC,
    },
  },
  data() {
    const { author } = this.post;
    const data = {
      GENERIC,
      POLL,
      TOURNAMENT_FINISHED,
      TOURNAMENT_ANNOUNCED,
    };
    if (author) {
      data.author = new AccountModel(
        author.type || 'profile',
        author.id || author.login
      ).update(author);
    }
    return data;
  },
  computed: {
    postText() {
      return get(this, 'post.text') || get(this, 'post.poll.text') || '';
    },
    isAuthorVisible() {
      return !INJECTION_POSTS.includes(this.type);
    },
  },
  watch: {
    post() {
      if (this.post.author)
        this.author = new AccountModel(
          this.post.author.type || 'profile',
          this.post.author.id || this.post.author.login
        ).update(this.post.author);
    },
  },
};
</script>
<style lang="scss">
.post-body {
  border-radius: 4px;
  padding-left: 64px;
  @include auto-rtl(padding-left, 64px);
  padding-right: 110px;
  @include auto-rtl(padding-right, 110px);
  &.reposted {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      @include auto-rtl(left);
      width: 2px;
      height: 100%;
      border-radius: $ph-round-radius;
      background-color: #9d9cf7;
    }
  }
  .post-image {
    object-fit: contain;
  }
  .posted a {
    color: $ph-secondary-text;
  }
}
.avatar-icon {
  @include auto-rtl(margin-right, 16px);
}
.avatar-preview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.reposted-author {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .reposted-author-name {
    margin-left: 8px;
    font-size: 14px;
    color: $ph-primary-text;
  }
}
.avatar-text {
  margin-top: 8px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}
.author-name {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #eff0f1;
  display: flex;
  @include auto-rtl(flex-direction, row);
  @media (max-width: 560px) {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.author-tag {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #c1c7d7;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    max-width: 75px;
  }
}
.author-name-padding {
  @include auto-rtl(margin-right, 8px);
}
.author-time {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #404a63;
  @include auto-rtl(margin-right, 8px);
  margin-top: 2px;
}
.author-spacing {
  margin-top: 2px;
  overflow: hidden;
  width: 177px;
  text-overflow: ellipsis;
}
.spacing-name-author {
  max-width: 300px;
  flex-wrap: wrap;
}

@media (max-width: 1100px) {
  .post-body {
    @include auto-rtl(padding-right, 0px);
  }
}

@media (max-width: 767px) {
  .post-body {
    overflow: hidden;
    @include auto-rtl(padding-right, 0px);
    @include auto-rtl(padding-left, 50px);
  }
  .author-spacing {
    margin-top: 0px;
  }
}
</style>
