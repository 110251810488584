<template>
  <div
    class="position-relative dropdown"
    :class="[{ disabled: disabled }, dropdownVariant]"
  >
    <WrapperFormControl
      v-bind="{
        label,
        error,
        tooltip,
        hideLabel,
        required: required && !viewMode,
      }"
    >
      <button
        slot="control"
        ref="dropdownActivator"
        type="button"
        :disabled="disabled"
        :class="{ 'input-form': variant === 'input-like', 'is-invalid': error }"
        class="btn btn-sm d-flex flex-row justify-content-between align-items-center dropdown_activator"
        @click="togglePopover"
      >
        <TextBlock class="text-truncate">
          <span v-if="isEmpty" class="placeholder-text">
            {{ emptyText }}
          </span>
          <slot v-else :chosenItem="dropdownValue" name="chosen">
            <span class="chosen-item">{{ dropdownValue }}</span>
          </slot>
        </TextBlock>
        <icon name="arrow-s" class="dropdown-icon h-mr-2 h-ml-3" width="16" />
      </button>
    </WrapperFormControl>
    <PopoverMenu
      :position="dropdownPosition"
      :closest="$el"
      :items="filteredList"
      :float="['center']"
      regard="top"
      :width="popoverWidth"
      class="dropdown-popover w-auto"
    >
      <input
        v-if="showFilter"
        slot="header"
        v-model.trim="dropdownFilter"
        type="text"
        class="form-control shadow-none mb-2 border-top-0 border-left-0 border-right-0 rounded-0"
        :class="searchIcon"
        :placeholder="filterPlaceholder"
        name="dropdownFilter"
        autocomplete="off"
      />
      <template slot-scope="{ item }">
        <span @click="changeItem(item)">
          <slot name="item" v-bind="{ item }">
            {{ item.text }}
          </slot>
        </span>
      </template>
    </PopoverMenu>
  </div>
</template>
<script>
import icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import { prop, propRequired } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
import PopoverMenu from '@/components/popover/Menu';

export default {
  name: 'HeraFormDropdown',
  components: {
    icon,
    TextBlock,
    WrapperFormControl,
    PopoverMenu,
  },

  props: {
    viewMode: prop(Boolean),
    variant: {
      type: String,
      default: 'default',
      validator: val => {
        return [
          'default',
          'input-like',
          'button',
          'transparent',
          'view',
        ].includes(val);
      },
    },
    disabled: prop(Boolean),
    required: prop(Boolean),
    emptyOption: prop(Boolean),
    emptyText: prop(String, 'Open to select...'),
    list: propRequired(Array),
    error: prop(String),
    value: {
      type: null,
      default: '',
    },
    label: prop(String),
    showFilter: prop(Boolean),
    couldBeEmpty: prop(Boolean),
    searchIcon: prop(String),
    filterPlaceholder: prop(String, 'Type to filter list...'),
    tooltip: prop(String),
    hideLabel: prop(Boolean),
    popoverWidth: prop(String, 'auto'),
  },

  data() {
    return {
      dropdownPosition: null,
      dropdownFilter: '',
    };
  },

  computed: {
    dropdownValue: {
      get() {
        return this.$lodash.get(this, 'value.text', this.value);
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isEmpty() {
      return !this.value;
    },
    filteredList() {
      const filterLowerCase = this.dropdownFilter.toLowerCase();
      return this.list.filter(item => {
        return this.showFilter && this.dropdownFilter
          ? (item.nameLower || item.name.toLowerCase()).includes(
              filterLowerCase
            )
          : true;
      });
    },
    dropdownVariant() {
      return this.viewMode ? 'dropdown--view' : `dropdown--${this.variant}`;
    },
  },
  methods: {
    changeItem(item) {
      this.dropdownValue = item || this.emptyText;
      this.dropdownPosition = null;
    },
    togglePopover() {
      this.dropdownPosition = this.dropdownPosition
        ? null
        : this.$refs.dropdownActivator;
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  transition: all 0.3s ease-in-out;
  .dropdown_activator {
    outline: none !important;
    box-shadow: none !important;
    font-size: 0.75rem;
  }
  &--input-like {
    .dropdown_activator {
      height: $input-height;
      width: auto;
    }
  }
  &--transparent {
    .dropdown_activator {
      background-color: transparent;
    }
  }
  &--button {
    .dropdown_activator {
      border-radius: 2rem;
      color: $ph-primary-text;
      background-color: $ph-card;
    }
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Molecules/Form"
  name="HeraFormDropdown"
  knobs="{
    viewMode: {
      default: boolean('viewMode', false)
    },
    variant: {
      default: select('variant', ['default', 'input-like', 'button', 'transparent', 'view'], 'input-like')
    },
    disabled: {
      default: boolean('disabled', false)
    },
    required: {
      default: boolean('required', false)
    },
    emptyOption: {
      default: boolean('emptyOption', false)
    },
    emptyText: {
      default: text('emptyText', 'emptyText')
    },
    value: {
      default: text('value', 'value')
    },
    error: {
      default: text('error', '')
    },
    couldBeEmpty: {
      default: boolean('couldBeEmpty', false)
    },
    showFilter: {
      default: boolean('showFilter', false)
    },
    label: {
      default: text('label', 'label')
    },
    tooltip: {
      default: text('tooltip', '')
    },
    searchIcon: {
      default: text('searchIcon', '')
    },
    filterPlaceholder: {
      default: text('filterPlaceholder', '')
    },
    hideLabel: {
      default: boolean('showLabel', false)
    },
    popoverWidth: {
      default: text('popoverWidth', '100%')
    }
  }"
>
  <HeraFormDropdown v-bind="{
    viewMode,
    variant,
    disabled,
    required,
    emptyOption,
    emptyText,
    value,
    error,
    couldBeEmpty,
    showFilter,
    label,
    tooltip,
    searchIcon,
    filterPlaceholder,
    hideLabel,
    popoverWidth}"
    :list="[{text: 'aaa'}, {text: 'bbb'}]"
    @input="e=>action('input', e)"
    @change="e=>action('change', e)"
    @itemChanged="e=>action('itemChanged', e)"
  />
</story>
