var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SegmentControl',_vm._g(_vm._b({},'SegmentControl',{
    id: _vm.inputID,
    options: [
      {
        id: 'yes',
        label: _vm.trueLabel || _vm.$$t('Yes'),
        value: _vm.trueValue,
      },
      {
        id: 'no',
        label: _vm.falseLabel || _vm.$$t('No'),
        value: _vm.falseValue,
      } ],
    required: _vm.required,
    errorInTooltip: _vm.errorInTooltip,
    error: _vm.error,
    label: _vm.label,
    tooltip: _vm.tooltip,
    readOnly: _vm.readOnly,
    value: _vm.value,
    disabled: _vm.disabled,
  },false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }