<template>
  <div>
    <MainLayout>
      <div class="privacy-policy-mena">
        <div class="refundpolicy-container">
          <div class="priv"><h1>Refund policy</h1></div>
          <div class="terms">
            <div class="title">
              <h2>General Terms and Conditions:</h2>
              <ul>
                <li>
                  <p>
                    By clicking on "I accept the terms and conditions", the
                    customer confirms that he has read the terms, fully
                    understood them, and agreed to them.
                  </p>
                </li>
                <li>
                  <p>
                    Delivery charges will be added to the order’s final price,
                    based on the customer's request whether the shipping is
                    inside or outside Saudi Arabia.
                  </p>
                </li>
                <li>
                  <p>
                    We are not bound by any expired price offer (advertisement),
                    in case of stock runs out or the date of the offer has
                    expired
                  </p>
                </li>
                <li>
                  <p>
                    Orders will be canceled and the full amount will be returned
                    to the customer In case of system failure or the required
                    product is out of stock.
                  </p>
                </li>
                <p>
                  Digital products such as top-ups or digital games are
                  none-refundable.
                </p>
                <li>
                  <p>
                    These terms and conditions are subject to the laws and
                    decisions that regulate business in the Kingdom of Saudi
                    Arabia and are subject to the authority of the Saudi courts
                    in the event of any legal dispute.
                  </p>
                </li>
                <li>
                  <p>
                    Playhera has the right to refuse any request for return,
                    refund or exchange according to the case evaluation
                  </p>
                </li>
              </ul>
              <h2>Refund policy</h2>
              <p>
                We have a 7-days return policy, which means you have 7 days
                after receiving your item to request a return.
              </p>
              <ul>
                <li>
                  <p>
                    To be eligible for a return, your item must be in the same
                    condition that you received it, unworn or unused, with tags,
                    and in its original packaging. You’ll also need the receipt
                    or proof of purchase
                  </p>
                </li>
                <li>
                  <p>
                    To start a return, you can contact us at
                    support@playhera.com
                  </p>
                </li>
                <li>
                  <p>
                    If your return is accepted, we’ll send you a return shipping
                    label, as well as instructions on how and where to send your
                    package. Items sent back to us without first requesting a
                    return will not be accepted.
                  </p>
                </li>
                <li>
                  <p>
                    We will notify you once we’ve received and inspected your
                    return, and let you know if the refund was approved or not.
                    If approved, you’ll be automatically refunded on your
                    original payment method within 14 - 21 business days. Please
                    remember it can take some time for your bank or credit card
                    company to process and post the refund too.
                  </p>
                </li>
                <li>
                  <p>
                    If more than 21 business days have passed since we’ve
                    approved your return, please contact us at
                    support@playhera.com.
                  </p>
                </li>
                <li>
                  <p>
                    Cancellations or refunds before receiving the order may not
                    be accepted until receiving the order
                  </p>
                </li>
                <li>
                  <p>
                    The return and refund process can be requested in the event
                    that the requested product is not available or it does not
                    match the specifications announced on the site
                  </p>
                </li>
                <li>
                  <p>
                    Also, the return and refund process can be requested within
                    7 days if the product is in its original condition and
                    original packaging, in order to receive the full amount.
                    Note that this does not include the shipping price.
                  </p>
                </li>
              </ul>
              <p>
                You can always contact us for any return question at
                support@playhera.com.
              </p>
              <h2>Damages and issues</h2>
              <p>
                Please inspect your order upon reception and contact us
                immediately if the item is <br />
                defective, damaged or if you receive the wrong item, so that we
                can evaluatebr the issue and make it right.
              </p>
              <h2>Exceptions / non-returnable items</h2>
              <p>
                Certain types of items cannot be returned, like custom products
                (such as special orders or personalized items) and Digital
                products. <br />
                Unfortunately, we cannot accept returns on sale items or gift
                cards
              </p>
              <h2>Exchanges</h2>
              <p>
                The fastest way to ensure you get what you want is by contacting
                us on support@playhera.com. or calling our CC number.
              </p>
              <ul>
                <li>
                  <p>
                    The customer can exchange the product within 7 days of
                    receipt, provided that it has not been used, tampered with,
                    removed the sticker or any pieces in the product envelope,
                    and that the product is in its original condition and
                    original packaging (except for digital cards).
                  </p>
                </li>
                <li>
                  <p>
                    The exchange process requires additional shipping fees if
                    the product is replaced and there is no manufacturing defect
                    or product error
                  </p>
                </li>
                <li>
                  <p>
                    Products are not allowed to be exchanged after 7 days of
                    receiving them
                  </p>
                </li>
              </ul>
              <h2>Warranty Policy:</h2>
              <ul>
                <li>
                  <p>
                    Warranty is for manufacture defects only and does not
                    include other malfunctions such as fractures or damage
                    resulting from misuse
                  </p>
                </li>
                <li>
                  <p>
                    The warranty will not apply to any damages caused by (using
                    accessories that are &lt; not compatible with the item - not
                    following the manufacturer's instructions)
                  </p>
                </li>
                <li>
                  <p>
                    The warranty is considered void if the product is used
                    incorrectly, such as overclocking the processor or graphics
                    card, or raising the temperature or downloading dangerous
                    programs.
                  </p>
                </li>
                <li>
                  <p>
                    Attempting to maintain the product at any maintenance center
                    not approved by the agent will void the warranty on the
                    product
                  </p>
                </li>
                <li>
                  <p>
                    If a problem appears in the device upon receipt or within 48
                    hours of receiving it, Playhera bears the shipping costs to
                    and from the customer and the replacement of the device in
                    the event of a defect or manufacturing defect being proven.
                  </p>
                </li>
                <li><p>After that, the guarantor is the agent by brand</p></li>
                <li>
                  <p>
                    Most of the items are subject to the warranty of the
                    manufacturer or the agent, and in this case, the customer
                    must consult the manufacturer himself or his agent in Saudi
                    Arabia directly.
                  </p>
                </li>
                <li>
                  <p>
                    You can send the product to the authorized maintenance
                    center of the manufacturer, or you can contact us to help
                    you.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="refundpolicy-container">
            <div class="priv"><h1>Shipping Policy</h1></div>
            <div class="data">
              <h2><u>Delivery terms and conditions:</u></h2>
              <ul>
                <li>
                  <p>
                    Shipment processing dates depend on your order, city, and
                    your payment type. Please note that Pre Orders might not
                    match the standard shipping time shown on the product page
                    and might be changed.
                  </p>
                </li>
                <li>
                  <p>
                    If delivery times for pre-orders change, Playhera will
                    contact you through any means available at the time.
                  </p>
                </li>
                <li>
                  <p>
                    Playhera is not responsible for the data registered by the
                    customers on the order, and this data can be modified only
                    before the shipment starts. Once the shipment starts, the
                    registered information cannot be modified and the delivery
                    will be processed to the registered information of the
                    order, otherwise, you can cancel the order and make another
                    order with different information.
                  </p>
                </li>
                <li>
                  <p>
                    The product page is updated regularly based on the latest
                    information. Delivery is also dependent on the customer
                    accepting and arranging delivery with the courier.
                  </p>
                </li>
                <li>
                  <p>
                    The responsibility to verify that the products are received
                    without any defects or breakage is the responsibility of the
                    customer upon receiving the order from the representative of
                    the shipping company
                  </p>
                </li>
                <li>
                  <p>
                    Delivery of returned products due to a manufacturing defect
                    or doesn’t match the ordered product is free of charge.
                  </p>
                </li>
                <h3><u>Delivery Charges:</u></h3>
                <li>
                  <p>
                    All Items may be subject to delivery fees unless expressed
                    on the website.
                  </p>
                </li>
                <li>
                  <p>
                    In case of Cash on Delivery Charges: 15 SR cash on delivery
                    charge.
                  </p>
                </li>
                <li>
                  <p>
                    Delivery Charges: will be calculated and charge on the
                    invoice before payment.
                  </p>
                </li>
                <li>
                  <p>
                    In case you make an online order that includes large and
                    small items together, delivery might take place in different
                    shipments at different times.
                  </p>
                </li>
                <li>
                  <p>
                    We deliver the large products maximum to the 3rd floor of
                    the building.
                  </p>
                </li>
                <li>
                  <p>
                    Playhera team does not install items for the customer unless
                    explicitly mentioned.
                  </p>
                </li>
                <li>
                  <p>
                    In case you need to return a product for a personal reason
                    that is not related to manufacturing defects, you can do the
                    return within 7 days if the purchase was from the website,
                    noting that up to 100SAR may be deducted as delivery fees in
                    case if the home delivery service has been completed.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Refundpolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.priv {
  font-size: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refundpolicy-container {
  max-width: 1511px;
  width: 80vw !important;
}
.privacy-policy-mena {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .refundpolicy-container {
    width: 90vw !important;
  }
}
</style>
