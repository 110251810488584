export default {
  unread_count: 0,
  next_page_token: null,
  prevPageToken: null,
  items: [
    {
      id: '2a8ad64e-a4fa-4cf1-bbdc-dfedf3bbaff3',
      title: 'new test words',
      subtitle: 'APEX LEGENDS tournament teams',
      filteredMessages: [
        {
          id: 'edec8c05-2a4f-4c2d-984a-12601e5b6f76',
          sentDate: '2023-03-16T13:39:14.002Z',
          text: 'bronze',
        },
        {
          id: 'e2301c7f-f396-4948-8e1f-caf2b1753165',
          sentDate: '2023-03-16T13:39:35.295Z',
          text: 'bronze',
        },
        {
          id: 'fa831458-c528-412c-8198-41b3c06a8bc3',
          sentDate: '2023-03-16T13:39:56.673Z',
          text: 'bronze',
        },
      ],
      last_activity: 1678973999,
      last_message: 'camel',
      last_message_sender_id: 'efd6a7ee-6ada-4deb-bb48-69d273281f7d',
      last_message_sender_name: 'ZoranOrg',
      unread_count: 0,
      conversation_type: 'group_chat',
      active: true,
      embedded: false,
      avatar: null,
      is_deleted: false,
      visible: true,
      pendingUpdate: null,
      participants: null,
      participants_count: 1,
      author: null,
      correlationId: null,
      correlation_type: 'none',
    },
  ],
};
