<template>
  <div v-show="!buttonIsHidden">
    <div
      ref="attachmentsPreview"
      class="attachments-preview mt-4"
      :class="{
        'is-hidden': !attachments.length,
        'custom-scrollbar scrollable-attach': attachments.length > 1,
        'inserted-after': insertAfter,
      }"
    >
      <Attach
        v-for="item in attachments"
        :key="item.id || item[item.type]"
        :class="[
          item.isApplication
            ? 'attachments-item-application'
            : 'attachments-item-media',
        ]"
        :data="item"
        :hide-preview="item.isApplication"
        @deleteAttach="deleteAttach(item)"
        @attachLoaded="attachLoaded(item, $event)"
        @previewLoaded="previewLoaded(item, $event)"
        @attachError="attachError(item)"
      />
    </div>
    <button
      v-if="buttonIsRendered"
      class="d-flex align-items-center align-self-center h-mr-3 h-ml-2 btn btn-outline-secondary btn-upload"
      type="button"
      :disabled="isMaximumFilesCount"
    >
      <AppIcon :name="uploadBtnIcon" :width="iconSize" />
      <label>
        <input
          ref="input"
          type="file"
          :accept="$options.accepts"
          name="attachments[]"
          multiple
          @change="onChange"
        />
      </label>
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { uniqueId } from '@/utils/lodashUtils';
import AppIcon from '@/components/atoms/Icon';
import Attach from './Attach.vue';
import { prop } from '@/utils/factories';

const ALL_ACCEPTS = ['image/*', 'video/*', 'application/pdf'];
const MAX_ATTACHMENTS_COUNT = 4;

export default {
  name: 'EditableAttachments',
  components: {
    AppIcon,
    Attach,
  },
  model: {
    prop: 'files',
    event: 'input',
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    accepts: {
      type: Array,
      default: null,
    },
    parentEl: {
      type: Node,
      default: null,
    },
    uploadBtnIcon: {
      type: String,
      default: 'editable/upload',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    insertAfter: prop(Boolean),
    insertBefore: prop(Boolean),
    buttonIsHidden: prop(Boolean),
  },
  data() {
    return {
      acceptsRegExp: ALL_ACCEPTS.map(type =>
        RegExp(type.replace(/\*/g, '.*'), 'i')
      ),
      attachments: this.files || [],
      buttonIsRendered: false,
    };
  },
  computed: {
    isMaximumFilesCount() {
      return this.attachments.length >= MAX_ATTACHMENTS_COUNT;
    },
    isAllAttachLoaded() {
      return !this.attachments.find(attach => !attach.loaded);
    },
  },
  watch: {
    files(files) {
      // todo - TH-7097 return files if they are saved (errorSend[feature])
      this.attachments = files;
    },
    isAllAttachLoaded(isLoaded) {
      this.$emit('busy', !isLoaded);

      if (isLoaded) {
        this.$emit('input', this.attachments);
      }
    },
    attachments(attachments) {
      this.$emit('isFull', attachments.length === MAX_ATTACHMENTS_COUNT);
    },
  },
  created() {
    this.$options.accepts = this.accepts || ALL_ACCEPTS;
  },
  mounted() {
    this.$nextTick(() => {
      this.buttonIsRendered = true;
    });
    const $closestElement = this.parentEl || this.$el.parentElement;

    if (this.buttonIsHidden) {
      $closestElement.parentElement.insertBefore(
        this.$el,
        this.insertAfter ? $closestElement.nextSibling : $closestElement
      );
    }

    $closestElement.parentElement.insertBefore(
      this.$refs.attachmentsPreview,
      this.insertAfter
        ? $closestElement.nextSibling
        : this.insertBefore
        ? this.parentEl
        : $closestElement
    );
    this.$emit('busy', false);
  },
  beforeDestroy() {
    this.$refs.attachmentsPreview.remove(); // remove from parentElement
  },
  methods: {
    ...mapActions(['errorNotify']),

    onSelectFiles() {
      this.$refs.input.click();
    },
    onChange(event) {
      const files = event.currentTarget.files;
      if (files && !files.length) {
        return;
      }

      this.addFiles([...files]);
      event.currentTarget.value = '';
    },
    onDrop(dataTransferFiles) {
      const files = [...dataTransferFiles].filter(file =>
        this.checkFileType(file.type)
      );

      if (files && files.length) {
        this.addFiles(files);
      }
    },
    addFiles(files) {
      const attachments = files.map(file => {
        const type = file.type.split('/')[0];
        return {
          file,
          type,
          id: uniqueId('attachment_'),
          isApplication: type === 'application',
        };
      });

      const allAttachments = [...this.attachments, ...attachments];

      if (allAttachments.length > MAX_ATTACHMENTS_COUNT) {
        this.notify(
          this.$t(
            '_web_upload-files_max-count-error',
            'You cannot add more than {count} files',
            { count: MAX_ATTACHMENTS_COUNT }
          )
        );

        allAttachments.splice(MAX_ATTACHMENTS_COUNT);
      }

      allAttachments.sort(attach => {
        return attach.isApplication ? 1 : -1;
      });

      this.attachments = allAttachments;
    },
    checkFileType(fileType) {
      return this.acceptsRegExp.some(reg => reg.test(fileType));
    },
    deleteAttach(item) {
      const index = this.attachments.indexOf(item);
      this.attachments.splice(index, 1);
      this.$emit('input', this.attachments);
    },
    attachLoaded(item, data) {
      this.$set(item, 'loaded', true);
      item.fileId = data.file_id || data.token;
      item.displayUri = data.display_uri || data[data.type];
      item.fileOriginalName = data.name;
    },
    previewLoaded(item, data) {
      item.preview = data;
    },
    attachError(item) {
      item.error = true;
    },
    notify(msg) {
      // todo - TH-7098 for feature
      this.errorNotify(msg);
    },
    isEmpty() {
      return !(this.attachments && this.attachments.length);
    },
  },
};
</script>

<style lang="scss">
.scrollable-attach {
  margin: 0 !important;
  margin-bottom: -15px !important;
}

.scrollable-attach .attachments-preview {
  max-height: 70px;
}

.scrollable-attach + .chat-dialog-form {
  padding-bottom: 5px !important;
}

.attachments-preview {
  z-index: 20 !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: visible;
  padding-bottom: $ph-tiny-space;
  margin: 0 0 0.25rem 0;
  padding: 10px 42px;
  &.inserted-after {
    margin: 0.25rem 0 0;
  }

  &.is-hidden {
    display: none;
  }

  .attachments-item-media {
    margin: $ph-tiny-space;
    &:last-child {
      @include auto-rtl(margin-right, 0);
    }
    &:first-child {
      @include auto-rtl(margin-left, 0);
    }
  }
  .attachments-item-application {
    width: 100%;
  }
}

.btn-upload {
  position: relative;
  > label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    input {
      transform: translate(-100%, -100%);
    }
  }
  &:disabled {
    pointer-events: none;
  }
}
</style>
