import { render, staticRenderFns } from "./Title.vue?vue&type=template&id=02288d50&"
import script from "./Title.vue?vue&type=script&lang=js&"
export * from "./Title.vue?vue&type=script&lang=js&"
import style0 from "./Title.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Title.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2FText&name=Title&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Text'%2C%20'Another%20one%20boring%20title')%0A%20%20%20%20%7D%2C%0A%20%20%20%20variant%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('variant'%2C%20%5B'strong'%2C%20'em'%2C%20'normal'%5D%2C%20'normal')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports