<template>
  <div ref="chatWrapper" class="position-relative">
    <router-link
      tag="li"
      :to="{ name: 'conversations', params: { id: data.id } }"
      class="card chat-list-item"
      :class="{
        active: isActive,
        'chat-inactive': !data.active || isUserBlocked,
      }"
      @click.native.capture="clickHandler($event)"
    >
      <a class="card-body media m-0 text-light" :title="data.title">
        <div class="align-self-center h-mr-3">
          <Avatar :size="48" :img-url="data.avatar" :type="avatarType" />
        </div>
        <HeraBadge
          v-if="+data.unread_count"
          class="font-size-xs chats-list-badge"
        >
          {{ data.unread_count }}
        </HeraBadge>
        <div class="d-flex flex-column chats-list-body">
          <div class="d-flex justify-content-between chat-list-msg-header">
            <div
              class="w-100 text-light font-size-base text-truncate chats-list-title"
            >
              <AppIcon
                v-if="conversationIcon"
                :name="conversationIcon"
                width="12"
              />
              {{
                `${data.title} ${
                  !data.active || isUserBlocked
                    ? $t('_web_conversation_blocked', 'Blocked')
                    : ''
                }`
              }}
            </div>
            <div
              class="flex-shrink-0 text-muted "
              :class="
                data.active &&
                data.correlation_type === 'profile' &&
                !isUserBlocked
                  ? 'text-ajdustment-conv'
                  : ''
              "
            >
              {{ data.last_activity | localDate($times.DATE_SUBMED) }}
            </div>
          </div>
          <div
            class="d-flex justify-content-left text-muted font-size-sm chats-list-msg-preview"
          >
            <span v-if="data.last_message">
              {{ data.last_message | removeTagsMention }}
            </span>
          </div>
        </div>
      </a>
    </router-link>

    <div
      v-if="
        data.active && data.correlation_type === 'profile' && !isUserBlocked
      "
      :class="miniMode ? 'icon-wrapper' : 'icon-wrapper-reg'"
    >
      <Icon
        role="button"
        :fill="$scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        name="dots"
        class="cursor-pointer"
        @click.stop="openPopoverMenu($event)"
      />
    </div>

    <PopoverMenu
      :closest="$refs.chatWrapper"
      :position="optionsPosition"
      :items="popoverItems"
      :float="['top', 'right']"
      :auto-close="true"
      @toggle="onMenuToggle"
    >
      <template v-slot="{ item }">
        <TextBlock
          :variant="item.disabled ? 'muted' : 'primary'"
          :text="item.label"
          class="py-2"
          @click.native="menuHandler(item)"
        />
      </template>
    </PopoverMenu>
  </div>
</template>

<script>
import {
  getAvatarTypeByConversation,
  getIconByConversation,
} from '@/utils/getProps';
import { mapMutations, mapActions } from 'vuex';
import Avatar from '@/components/images/Avatar';
import AppIcon from '@/components/atoms/Icon';
import HeraBadge from '@/components/atoms/HeraBadge';
import Icon from '@/components/atoms/Icon';
import PopoverMenu from '@/components/popover/Menu';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'ConversationListItem',
  components: {
    HeraBadge,
    AppIcon,
    Avatar,
    Icon,
    PopoverMenu,
    TextBlock,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    miniMode: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoverItems: null,
      optionsPosition: null,
      blockUserLoading: false,
      isUserBlocked: false,
    };
  },
  computed: {
    avatarType() {
      return getAvatarTypeByConversation(this.data);
    },
    conversationIcon() {
      return getIconByConversation(this.data);
    },
  },
  methods: {
    ...mapMutations(['setActivePopoverChat']),
    ...mapActions(['blockConversation', 'closeModal', 'openModal']),
    openBlockConfirm() {
      return this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_profile_block_conversation',
            'Are you sure you want to block the conversation with {name}? You will not be able to send or receive messages anymore.',
            {
              name: this.data.correlationId,
            }
          ),
          cancelButtonText: this.$t('_web_post_delete-cancel', 'Cancel'),
          confirmButtonText: this.$t('_web_post_block-confirm', 'Yes, block'),
        },
        events: {
          cancel: this.closeModal,
          confirm: () =>
            this.blockConversation({
              conversationId: this.data.id,
            }).then(() => {
              this.closeModal();
              this.$emit('reload');
              this.isUserBlocked = true;
            }),
        },
        options: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },

    openPopoverMenu(ev) {
      this.optionsPosition = ev.target;
      this.popoverItems = [
        {
          label: this.$t('_web_chat-block', 'Block'),
          handler: () => this.openBlockConfirm(),
        },
      ];
    },
    onMenuToggle(visible) {
      if (!visible) {
        this.popoverItems = null;
        this.optionsPosition = null;
      }
    },
    menuHandler(item) {
      item.handler();
    },

    clickHandler(e) {
      if (this.miniMode) {
        e.preventDefault();
        this.setActivePopoverChat(this.data.id);
      }
    },
  },
};
</script>

<style lang="scss">
$card-padding: 0.75rem;
$card-padding-y-mini: 0.5rem;

.icon-wrapper {
  position: absolute;
  @include auto-rtl(right, 10px);
  bottom: 35px;
}
.text-ajdustment-conv {
  @include auto-rtl(padding-right, 35px);
}

.icon-wrapper-reg {
  position: absolute;
  @include auto-rtl(right, 10px);
  bottom: 36px;
}

.card.chat-list-item {
  border: none;
  background: transparent;
  transition: background-color 0.1s;

  .screen-mode & {
    margin-bottom: $card-padding;
  }

  .mini-mode & {
    border: none;
    border-radius: 0;
  }

  &.active {
    background-color: #1c212b;
  }

  &:hover,
  &:focus {
    background-color: $new-bg-page-faded;
  }

  // &.chat-inactive .chats-list-title {
  //   text-decoration: line-through;
  //   color: $ph-secondary-text !important;
  // }

  &:last-of-type {
    margin-bottom: 0;
  }

  .card-body {
    .mini-mode & {
      padding: $card-padding;
    }

    .screen-mode & {
      padding: $card-padding;
    }

    text-decoration: none;
  }

  .chats-list-body {
    width: calc(100% - 56px);
  }

  .chat-list-msg-header {
    line-height: 20px;

    .chats-list-title {
      max-width: 220px; // hack for webkit text-overflow
    }
  }

  .chats-list-msg-preview {
    @include auto-rtl(margin-right, $card-padding);
    overflow-wrap: break-word;
    width: calc(100% - #{$card-padding});
    margin-top: 0.2rem;
    position: relative;
    overflow: hidden;

    > span {
      max-width: 220px; // hack for webkit text-overflow

      .screen-mode & {
        @include text-multiline-ellipses(2, 18px);
      }

      .mini-mode & {
        @include text-multiline-ellipses(1, 18px);
      }
    }
  }

  .chats-list-badge {
    position: absolute;
    bottom: 16px;
    .mini-mode & {
      margin-top: 2px;
      @include auto-rtl(right, $card-padding);
    }

    .screen-mode & {
      bottom: $card-padding;
      @include auto-rtl(right, $card-padding);
    }
  }
}
</style>
