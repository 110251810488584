var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 poll-editor"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"poll-wrapper"},[_c('ul',{staticClass:"m-0 p-0 flex-grow-1 list-unstyled"},[_vm._l((_vm.options),function(item,index){return _c('li',{key:item.key,staticClass:" position-relative poll-wrapp"},[_c('div',{staticClass:"break-line-poll"}),_c('div',{staticClass:"input-icon-row"},[_c('TextInputMultiline',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"input-poll flex-grow-1",attrs:{"placeholder":_vm.getOptionLabel(index),"maxlength":_vm.MAX_POLL_OPTION_CHARS,"error":_vm.errors.first(("poll-option-" + (item.key))),"data-vv-name":("poll-option-" + (item.key)),"data-vv-as":_vm.getOptionLabel(index),"variant":"input-like"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"focus":function($event){_vm.isInputFocused = true},"blur":function($event){_vm.isInputFocused = false}},model:{value:(_vm.options[index].value),callback:function ($$v) {_vm.$set(_vm.options[index], "value", $$v)},expression:"options[index].value"}}),(_vm.options.length > 2)?_c('Icon',{staticClass:"cursor-pointer mb-3 mx-4",class:{
                disabled: _vm.isOptionsMinCount,
                'icon-onfocus': _vm.isInputFocused,
              },attrs:{"name":"close","width":"20"},on:{"click":function($event){return _vm.deleteOption(index)}}}):_vm._e()],1)])}),_c('div',{staticClass:"break-line-poll"})],2)])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"option-spacing"},[_c('p',{staticClass:"more-options",on:{"click":_vm.addOption}},[_vm._v("\n        "+_vm._s(_vm.$t('_web_add-more-option', 'Add more options +'))+"\n      ")]),_c('p',{staticClass:"max-option"},[_vm._v(_vm._s(_vm.$t('_web_poll_max-option', 'Maximum 5')))])]),_c('div',{staticClass:"poll-wrapper"},[_c('div',{staticClass:"poll-editor_length-selector"},[_c('FormDropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"dropdown-poll",class:{ 'is-invalid': _vm.errors.first('poll-length') },attrs:{"list":_vm.LENGTH_VARIANTS,"variant":"input-like","label":_vm.$t('_web_create_post_poll-duration', 'Poll duration'),"name":"poll-length","border-bot":"","theme-dark":"","error":_vm.errors.first('poll-length')},scopedSlots:_vm._u([{key:"chosen",fn:function(ref){
              var chosenItem = ref.chosenItem;
return [_c('span',[_vm._v(_vm._s(_vm.getPollLengthLabel(chosenItem.label)))])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.getPollLengthLabel(item.label)))]}}]),model:{value:(_vm.length),callback:function ($$v) {_vm.length=$$v},expression:"length"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }