<template>
  <div class="featured-tournament-card" :style="{ backgroundImage: cover }">
    <div class="featured-tournament-overlay">
      <div
        class="featured-tournament-content-wrapper"
        :style="{ '--scrollWidth': scrollWidth }"
      >
        <div class="hero-tournament-content-inner">
          <component
            :is="linkComponent"
            v-bind="linkComponentBind"
            class="hero-tournament-card-left"
          >
            <TournamentStatusIcon
              :tournament-status="tournament.status"
              class="tour-status-mobile"
            />
            <StartsOn
              :time-only="true"
              :tournament-start-time="tournament.tournament_start"
            />
            <h2 class="featured-tournament-title">{{ tournament.title }}</h2>
            <div class="hero-tournament-row">
              <TournamentStatusIcon
                :tournament-status="tournament.status"
                class="tour-status-desktop"
              />
              <TournamentPlatformAndSquad :tournament="tournament" />
            </div>
            <div class="hero-tournament-organiser-sponsor">
              <div class="hero-tournament-organiser-sponsor-wrapper">
                <span class="muted">{{
                  $t('_web_organiser', 'ORGANISER')
                }}</span>
                <TournamentCardOrganizer
                  class="hero-tournament-organiser"
                  :tournament="tournament"
                  :size="25"
                  :put-class="false"
                />
              </div>
              <div
                v-if="hasSponsors"
                class="hero-tournament-organiser-sponsor-wrapper"
              >
                <span class="muted">{{
                  $t('_web_powered_by', 'POWERED BY')
                }}</span>
                <TournamentSponsors
                  v-if="hasSponsors"
                  :sponsors="sponsors"
                  height="25px"
                  align="center"
                  class="hero-tournament-sponsors"
                />
              </div>
            </div>
            <hr class="featured-tournament-hr" />
            <component
              :is="linkComponent"
              v-bind="linkComponentBind"
              class="icon-groups-wrapper"
            >
              <div class="icon-group">
                <img src="@/assets/images/icons/trophy.svg" alt="trophy" />
                <span>${{ tournament.prize.prize_pool }}</span>
              </div>
              <div class="icon-group">
                <img
                  src="@/assets/images/icons/user-added.svg"
                  alt="user-added"
                />
                <span>{{ tournament.squads_joined }}</span>
              </div>
              <div class="icon-group">
                <img src="@/assets/images/icons/messages.svg" alt="messages" />
                <span>{{ total }} {{ $t('_web_comments', 'Comments') }}</span>
              </div>
            </component>
            <div class="status-info-wrap-mobile">
              <StatusInfoHome
                v-if="tournament.id && isAuthorized"
                :key="'mobile-status'"
                class="status-info-mobile"
                :color="'transparent'"
                :tournamentprop="tournament.id"
              />
            </div>
          </component>

          <component
            :is="linkComponent"
            v-bind="linkComponentBind"
            class="featured-tournament-card-right-cont"
          >
            <div class="featured-tournament-card-right-cont">
              <div
                class="featured-tournament-card-right"
                :style="{ backgroundImage: cover }"
              >
                <StatusInfoHome
                  v-if="tournament.id && isAuthorized"
                  :key="'desktop-status'"
                  class="status-info-desktop"
                  :color="'heroPageSingleInfo'"
                  :tournamentprop="tournament.id"
                />
                <!-- <StatusInfoHome /> -->
              </div>
            </div>
          </component>
        </div>
        <!--        <TournamentCardPerIndicator :tournament="tournament" :is-organizer="forOrganizer" />-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { prop, propRequired } from '@/utils/factories';
import { ORGANIZATION } from '@/constants/accounts';
import { DEFAULT } from '@/constants/common';
import { translate } from '@/mixins/translate';
import viewsTrackingMixin from '@/mixins/metrics';
import TournamentSponsors from '@/views/tournaments/management/singleTournament/components/SponsorsListPreview';
import StatusInfoHome from '@/views/tournaments/list/Pinned/components/StatusInfoHome';
import { API } from '@/api/config';
import StartsOn from '../common/StartsOn';
import TournamentStatusIcon from '../common/TournamentStatusIcon';
import TournamentPlatformAndSquad from '../common/TournamentPlatformAndSquad';
import TournamentCardOrganizer from '../common/TournamentCard/components/Organizer';

export default {
  components: {
    StartsOn,
    TournamentStatusIcon,
    TournamentPlatformAndSquad,
    TournamentCardOrganizer,
    TournamentSponsors,
    StatusInfoHome,
  },
  mixins: [translate('_web_tournament'), viewsTrackingMixin],
  props: {
    tournament: propRequired(Object),
    forOrganizer: prop(Boolean),
  },
  data() {
    return {
      scrollWidth: null,
      total: 0,
    };
  },

  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['isTournamentPastStatus', 'currentCountry']),
    sponsors({ tournament }) {
      let sponsors = tournament.sponsor_logo || tournament.sponsorLogo || [];

      return sponsors[this.currentCountry] || sponsors[DEFAULT];
    },
    hasSponsors({ sponsors }) {
      return !!(sponsors && sponsors.length);
    },
    linkComponent() {
      return 'router-link';
    },
    linkComponentBind({ forOrganizer, tournament = {} }) {
      const { id, vanity_id, vanityId = vanity_id } = tournament;

      return {
        id,
        vanityId,
        ...(forOrganizer
          ? {
              to: {
                name: 'TournamentSetup',
                params: { tournamentID: id },
              },
            }
          : {
              to: {
                name: 'singleCompetition',
                params: { tournamentID: id },
              },
            }),
      };
    },
    itemAccountBind({ tournament = {} }) {
      const {
        event_organizer_logo,
        event_organizer_name,
        event_organizer_vanity_id,
        owner,
      } = tournament;
      return {
        type: ORGANIZATION,
        avatar: event_organizer_logo,
        name: event_organizer_name,
        vanityId: event_organizer_vanity_id,
        accountId: owner,
        size: 36,
      };
    },
    observeVisibility({
      forOrganizer,
      handleScrollIntoViewForViewsTracking,
      tournament: { id } = {},
    }) {
      return {
        callback: isVisible =>
          !forOrganizer
            ? handleScrollIntoViewForViewsTracking(isVisible, id, 'tournament')
            : this.$lodash.noop(),
        intersection: {
          threshold: 0.1,
        },
      };
    },
    cover({ tournament: { cover } }) {
      return `url(${encodeURI((cover || '').replace('.original.', `.500.`))})`;
    },
  },
  mounted() {
    if (this.isAuthorized) {
      this.getDatacomments();
    }
    this.getScrollBarWidth();
  },
  methods: {
    async getDatacomments() {
      try {
        const response = await API(
          `/comments/tournament/${this.tournament.id}`
        );
        // console.log(response);
        return (this.total = response.data.total);
      } catch (error) {
        console.log(error);
      }
    },
    getScrollBarWidth() {
      let el = document.createElement('div');
      el.style.cssText =
        'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      el.remove();
      return (this.scrollWidth = width.toString() + 'px');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './variables';

.featured-tournament-overlay {
  background-color: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  display: flex;
  justify-content: center;
  // -webkit-box-shadow: inset 0 0 45px 40px $new-bg-page;
  // box-shadow: inset 0 0 45px 40px $new-bg-page;
  box-shadow: inset 0px 35px 30px -10px $new-bg-page,
    inset 0px -35px 30px -10px $new-bg-page;
}

.featured-tournament-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $hr-wrapper-width; // 80vw
  max-width: $hr-wrapper-max-width; //1511 after paddings
  gap: $hr-spacing-07;
}

@media screen and (max-width: 1483px) {
  .featured-tournament-content-wrapper {
    width: 92vw;
  }
}

.hero-tournament-card-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 550px;
  background-color: rgba(17, 23, 31, 0.75);
  border-radius: 5px;
  padding: 4rem 3rem 4rem 3rem;
  flex: 1;
}

.hero-tournament-content-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.featured-tournament-card-right-cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 550px;
  max-width: 850px;
}

.featured-tournament-card-right {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 15px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 16/9;
  padding: 0px;
  border-radius: 5px;
}

.featured-tournament-card:before {
  content: '';
  position: absolute;
  background: inherit;
  z-index: -1;

  filter: blur(40px);
  width: 140%;
  height: 140%;
  left: -20%;
  top: -20%;
}

.featured-tournament-card {
  &,
  &:hover,
  &:active,
  a,
  a:hover,
  a:active {
    text-decoration: none;
    color: $ph-primary-text;
  }

  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
  height: 700px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-tournament-organizer {
  display: block;
  padding: 0px;
}

.featured-tournament-title {
  font-family: $hr-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 110%;
  padding: $hr-spacing-04 0px;
}

.hero-tournament-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.hero-tournament-organiser-sponsor {
  display: flex;
  align-items: stretch;
  gap: 40px;
}

.hero-tournament-organiser-sponsor-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.muted {
  color: $hr-text-muted;
  font-family: $hr-font-family;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 140%;
}

.hero-tournament-organiser {
  a {
    font-weight: 700;
    border-radius: 5px;
  }
}

.hero-tournament-sponsors {
  img {
    border-radius: 5px;
  }
}

.featured-tournament-image {
  height: 612px;
  overflow: hidden;
}

.featured-tournament-hr {
  border: 0.5px solid rgba(185, 183, 183, 0.5);
  margin: $hr-spacing-06 0px;
}

.icon-groups-wrapper {
  display: flex;
  gap: 20px;
}

.icon-group {
  height: 24px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;

  .img {
    width: 24px;
    height: 24px;
  }

  span {
    white-space: nowrap;
  }
}

.status-info-mobile {
  display: none;
}

.tour-status-mobile {
  display: none;
}

@media screen and (max-width: 1251px) {
  .hero-tournament-card-left {
    padding: 3rem 2rem 3rem 2rem;
  }
}

@media screen and (max-width: 1095px) {
  .hero-tournament-content-inner {
    flex-direction: column-reverse;
  }

  .featured-tournament-card {
    height: auto;
  }

  .featured-tournament-card-right {
    max-width: none;
  }

  .featured-tournament-content-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .status-info-desktop {
    display: none;
  }

  .status-info-mobile {
    display: block;
  }

  .featured-tournament-title {
    font-size: $hr-heading-5;
  }

  .status-info-wrap-mobile {
    width: 100%;
  }

  .hero-tournament-card-left {
    padding: 1rem;
    background-color: rgba(45, 67, 94, 0.15);
  }

  .tour-status-mobile {
    display: block;
    @include auto-rtl(margin-right, auto);
    margin-bottom: 1rem;
  }

  .tour-status-desktop {
    display: none;
  }

  .hero-tournament-organiser-sponsor {
    padding-top: 1rem;
  }

  .featured-tournament-card-right-cont {
    max-width: none;
  }
}

@media screen and (max-width: 991px) {
  .featured-tournament-content-wrapper {
    max-width: calc(100vw - var(--scrollWidth));
    padding: 4rem 0 4rem 0;
  }
}
</style>
