import {
  isString,
  lowerCase,
  snakeCase,
  kebabCase,
  upperFirst,
} from '@/utils/lodashUtils';
import { localDate, secToDuration } from '@/filters/dateTime';

// TODO: think about such implementation of detecting timestamp fields
// for @olsy and @olbe

const DATE_TIME_TYPES = ['DateEnd', 'DateStart'];
const DURATION_FIELDS = ['MatchDuration'];

export const errorMessagePropsFactory = ({ error, context }, type) => {
  context = context || [];
  const hasMessage = isString(context);
  error = error || '';
  const translateKey = `_web_server_errors-${type}-${kebabCase(error)}`,
    translateDefault = hasMessage
      ? context
      : Object.entries(context)
          .reduce(
            (acc, [key, value]) => {
              if (key !== 'message' || value) {
                acc.push(`${key}: {${key}}`);
              }
              return acc;
            },
            [`{field}: ${lowerCase(error)}`]
          )
          .join(', '),
    props = hasMessage
      ? {}
      : Object.entries(context).reduce((acc, [key, value]) => {
          const contextValue = parseInt(`${value}`);
          // think about such implementation of detecting timestamp fields
          // for @olsy and @olbe
          const isDateField = DATE_TIME_TYPES.includes(type) && value;

          // temporary method to detect timestamp in value from server
          // timestamp = integer with exactly 10 digits
          const isTimestamp =
            contextValue && contextValue.toString().length === 10;
          acc[key] = DURATION_FIELDS.includes(type)
            ? secToDuration(+value)
            : isTimestamp || isDateField
            ? localDate(+value)
            : `${value}`;
          return acc;
        }, {});

  return {
    translateKey,
    translateDefault,
    props,
  };
};

export const getErrorFromFields = ({ message, fields = message.fields }) => {
  return Object.entries(fields).reduce((acc, [key, [value]]) => {
    const { context, error } = value;
    const { debugMessage, message } = context || {}; // if null?
    const errorSnakeCase = snakeCase(error); // error key must always be in snake_case
    const translateKey = `${kebabCase(key)}_${errorSnakeCase}`;

    acc.push({
      title: {
        translateKey: `${translateKey}_title`,
        translateDefault: upperFirst(message || lowerCase(key)),
        props: context,
      },
      text: {
        translateKey: `${translateKey}_text`,
        translateDefault: upperFirst(
          debugMessage || (isString(context) ? context : lowerCase(error))
        ),
        props: context,
      },
      error: errorSnakeCase,
    });

    return acc;
  }, []);
};
