import { generateMutations } from '@/utils/store';
import { ALERT_TYPES } from '@/constants/modal';

const { CONFIRM } = ALERT_TYPES;

const INITIAL_ALERTS_STATE = () => ({
  visibleAlert: null,
});

const mutations = {
  ...generateMutations('visibleAlert'),
};

const actions = {
  confirm(
    { commit },
    { text, textKey, confirmButtonText, cancelButtonText, autoClose = true }
  ) {
    return new Promise((resolve, reject) => {
      commit('setVisibleAlert', {
        type: CONFIRM,
        props: {
          text,
          textKey,
          confirmButtonText,
          cancelButtonText,
        },
        events: {
          cancel() {
            commit('setVisibleAlert', null);
            reject({ silent: true });
          },
          confirm() {
            if (autoClose) {
              commit('setVisibleAlert', null);
            }
            resolve();
          },
        },
      });
    });
  },
};

export default {
  namespaced: true,
  state: INITIAL_ALERTS_STATE(),
  mutations,
  actions,
};
