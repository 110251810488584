<template>
  <div :class="{ 'mini-mode': miniMode, 'screen-mode': !miniMode }">
    <ListLoader
      class="align-items-stretch chats-list-wrap"
      :is-loading="isLoading"
      :is-crashed="isCrashed"
      :is-empty-list="!items.length"
      :dummy-props="{
        iconName: 'dummy/chat',
        size: miniMode ? 160 : 200,
        [miniMode ? 'subheaderText' : 'headerText']: $t(
          '_web_chat-list_is-empty',
          'You have no chats at the moment'
        ),
        text: $t(
          '_web_chat-list_is-empty_subheader',
          'Start a new chat and browse profiles to find someone to talk with'
        ),
      }"
      @reload="reload"
    >
      <template slot="list">
        <div class="d-flex align-items-stretch flex-column h-100 flex-grow-1">
          <div
            ref="chatList"
            class="custom-scrollbar chats-list h-100"
            tabindex="-1"
            @wheel.passive.capture="focusIn"
          >
            <InfiniteScroll
              tag="ul"
              class="list-unstyled mb-0 h-auto"
              :list="items"
              list-name="conversations"
              :is-disabled="scrollIsDisabled || !nextPageToken"
              :in-container="true"
              @ScrollToBottom="fetchChats"
            >
              <template slot-scope="{}">
                <div v-if="!miniMode">
                  <ChatsListItem
                    v-for="item in items"
                    :key="item.id + Math.random() * 9"
                    :data="item"
                    :mini-mode="miniMode"
                    :is-active="activeConversationId === item.id"
                  />
                </div>
                <div v-else>
                  <ChatsListItem
                    v-for="item in items"
                    :key="item.id"
                    :data="item"
                    :mini-mode="miniMode"
                    :is-active="activeConversationId === item.id"
                  />
                </div>
              </template>
            </InfiniteScroll>
          </div>
        </div>
      </template>
    </ListLoader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ChatsListItem from './Item';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import ListLoader from '@/components/common/HeraListPreloader';
import isLoading from '@/mixins/isLoading';

export default {
  name: 'ConversationList',
  components: {
    InfiniteScroll,
    ChatsListItem,
    ListLoader,
  },
  mixins: [isLoading],
  props: {
    miniMode: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chatsType: 'all',
      items: [],
      itemsFiltered: [],
      activeConversation: null,
      nextPageToken: '',
      scrollIsDisabled: false,
      hasSearchScoped: this.hasSearch,
      isUserBlocked: [],
    };
  },
  computed: {
    ...mapGetters([
      'lastMsgInConversation',
      'activeConversationUnreadCount',
      'activePopoverChat',
      'popoverChatIsOpened',
    ]),
    ...mapState('current', ['account', 'accountId']),
    ...mapState('my', { myAccount: 'account' }),
    activeConversationId() {
      return this.miniMode ? this.activePopoverChat : this.$route.params.id;
    },
  },
  watch: {
    searchValue() {
      this.nextPageToken = '';
      this.fetchFilterChat();
    },
    items() {
      // if (
      //   this.items.filter(item => {
      //     return item.last_message !== null;
      //   }).length < 10 &&
      //   this.nextPageToken !== ('' || null)
      // ) {
      //   this.fetchChats();
      // }
    },
    hasSearch() {
      this.hasSearchScoped = !this.hasSearchScoped;
    },
    activeConversationUnreadCount(val) {
      if (!val) {
        (
          this.items.find(msg => msg.id === this.activeConversationId) || {}
        ).unread_count = 0;
      }
    },
    lastMsgInConversation(val) {
      if (val && this.items.length) {
        let conversation = this.items.find(msg => msg.id === val.id);

        if (conversation) {
          Object.assign(conversation, val.data);
        }
      }
    },
  },
  mounted() {
    this.reload();
    this.addMessageSubscriber(this.messagesSubscriber);
  },
  beforeDestroy() {
    this.removeMessageSubscriber(this.messagesSubscriber);
    this.$root.$off('moveActiveConversationToUp');
  },
  methods: {
    ...mapActions([
      'addMessageSubscriber',
      'removeMessageSubscriber',
      'fetchConversations',
      'getConversationInfo',
      'errorNotify',
    ]),
    messagesSubscriber(res) {
      if (res.type === 'chat_message_sent') {
        this.updateListPreview(res.extended);
      }
    },
    updateListPreview(data) {
      if (!data) {
        return;
      }

      let conversation = this.items.find(
        item => item.id === data.conversationId
      );

      if (conversation) {
        Object.assign(conversation, {
          last_message: data.text,
          sent_date: data.sentDate,
          last_active: data.sentDate,
        });

        if (conversation.id !== this.activeConversationId) {
          conversation.unread_count = +conversation.unread_count + 1; // not use unread_count++
        }

        this.moveConversationToUp(conversation);
      } else {
        this.getConversationInfo({ conversationId: data.conversationId })
          .then(conversationInfo => {
            conversation = { ...conversationInfo };
            this.moveConversationToUp(conversation);
          })
          .catch(this.errorNotify);
      }
    },
    reload() {
      if (this.miniMode && !this.popoverChatIsOpened) {
        return;
      }

      this.toggleLoading(true);
      this.toggleCrashed(false);

      this.fetchChats()
        .then(() => {
          this.$root.$on('moveActiveConversationToUp', id => {
            const conversation = this.items.find(item => {
              return item.id === id;
            });

            if (conversation) {
              this.moveConversationToUp(conversation);
            }
          });

          this.$nextTick(() => {
            const $chatsList = this.$refs.chatList;

            if ($chatsList) {
              const $li = $chatsList.querySelector('li.active');
              if ($li) {
                $chatsList.scrollTop = $li.offsetTop - 24 || 0;
              }
            }

            this.toggleLoading(false);
          });
        })
        .catch(() => {
          this.toggleCrashed(true);
        });
    },
    fetchChats() {
      this.scrollIsDisabled = true;

      return new Promise((resolve, reject) => {
        if (this.nextPageToken === null) return reject();

        this.searchValue !== ''
          ? this.fetchConversations({
              pageToken: this.nextPageToken,
              Filter: this.searchValue,
              hasMessages: true,
            })
          : this.fetchConversations({
              pageToken: this.nextPageToken,
              hasMessages: true,
            })
              .then(res => {
                this.nextPageToken =
                  this.nextPageToken === res.next_page_token
                    ? null
                    : res.next_page_token;

                this.items = [...this.items, ...res.items];
                this.scrollIsDisabled = false;
                resolve(res);
              })
              .catch(err => {
                this.errorNotify(err);
                reject(err);
              });
      });
    },
    fetchFilterChat() {
      this.scrollIsDisabled = true;
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        if (this.nextPageToken === null) return reject();

        this.fetchConversations({
          pageToken: this.nextPageToken,
          Filter: this.searchValue,
          hasMessages: true,
        })
          .then(res => {
            this.nextPageToken =
              this.nextPageToken === res.next_page_token
                ? null
                : res.next_page_token;

            this.items = [...res.items];
            this.scrollIsDisabled = false;
            this.isLoading = false;
            resolve(res);
          })
          .catch(err => {
            this.errorNotify(err);
            reject(err);
          });
      });
    },
    filterChat() {
      this.itemsFiltered = this.items.filter(item =>
        item.title.toLowerCase().includes(this.searchValue)
      );
    },
    moveConversationToUp(conversation) {
      const index = this.items.indexOf(conversation);

      if (index === -1) {
        this.items = [conversation, ...this.items];
      }

      if (index > 0) {
        this.items.splice(index, 1);
        this.items = [conversation, ...this.items];
      }
    },
    focusIn() {
      if (
        this.$refs.chatList &&
        this.$refs.chatList !== document.activeElement
      ) {
        this.$refs.chatList.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.chats-list-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  .screen-mode & {
    @include auto-rtl(margin-right, -12px);
    min-height: auto;
  }
  .mini-mode & {
    @include auto-rtl(margin-right, 0);
  }
}

.chats-list {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  .screen-mode & {
    @include auto-rtl(padding-right, 6px);
  }
}
</style>
