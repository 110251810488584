import api from '@/api';
import { generateMutations, generateGetters } from '@/utils/store';
import { arrayOfEmptyObject } from '@/utils/factories';

export const INITIAL_CLANS_STATE = () => ({
  popularClans: arrayOfEmptyObject()(5),
  popularClansIsLoading: false,
});

const getters = {
  ...generateGetters('popularClans', 'popularClansIsLoading'),
};

const mutations = {
  ...generateMutations('popularClans', 'popularClansIsLoading'),
};

const actions = {
  fetchClanDetails(
    {
      getters: { accessToken },
    },
    clanID
  ) {
    if (!clanID) {
      return;
    }
    return new Promise((resolve, reject) => {
      api.clans
        .getClan(clanID)(accessToken)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },

  followClan(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.clans.followClan(accessToken, payload.clanID, {
      login: payload.login,
      follow: payload.follow,
    });
  },

  updateClan(
    {
      getters: { accessToken },
      dispatch,
    },
    clan
  ) {
    let { id } = clan;

    delete clan.logoUri;
    delete clan.coverUri;

    return new Promise((resolve, reject) => {
      api.clans
        .updateClan(id)(accessToken, clan)
        .then(() =>
          dispatch('fetchClanDetails', id).then(
            res => resolve({ data: res }),
            err => reject(err)
          )
        )
        .catch(err => reject(err));
    });
  },
  deleteClan(
    {
      getters: { accessToken },
      commit,
    },
    clanID
  ) {
    return api.clans.deleteClan(accessToken, clanID).then(() => {
      commit('my/clans/deleteClan', clanID);
      commit('current/clans/deleteClan', clanID);
    });
  },
  deleteTeamInClan(
    {
      getters: { accessToken },
    },
    { clanID, teamID }
  ) {
    return api.clans.deleteTeamInClan(accessToken, { clanID, teamID });
  },
  fetchPopularClans({ getters: { accessToken }, commit }) {
    commit('setPopularClansIsLoading', true);
    return api.clans.getPopularClans(accessToken).then(({ data }) => {
      commit('setPopularClans', data);
      commit('setPopularClansIsLoading', false);
      return data;
    });
  },
  fetchClanTeam(
    {
      getters: { accessToken },
    },
    { clanID, teamID }
  ) {
    return api.clans
      .getClanTeam({ clanID, teamID })(accessToken)
      .then(res => res.data);
  },
  fetchClanTeams(
    {
      getters: { accessToken },
    },
    clanID
  ) {
    return api.clans
      .getClanTeams(clanID)(accessToken)
      .then(res => res.data);
  },
  fetchClanCaptainsTeam(
    {
      getters: { accessToken },
    },
    clanID
  ) {
    return api.clans
      .getClanTeams(clanID)(accessToken, {
        params: {
          member: 'my',
          member_role: 'captain',
        },
      })
      .then(res => res.data);
  },
  /* not used @olesk
  fetchClanMembersTeams(
    {
      getters: { accessToken },
    },
    { clanID }
  ) {
    return api.clans
      .getClanTeams(clanID)(accessToken, {})
      .then(res => res.data);
  },
  */
  fetchClanTeamMembers(
    {
      getters: { accessToken },
    },
    { clanID, teamID }
  ) {
    return api.clans
      .getClanTeamMembers({ clanID, teamID })(accessToken)
      .then(res => res.data);
  },
  createTeam(
    {
      getters: { accessToken },
    },
    { clanID, team }
  ) {
    return api.clans
      .createTeamInClan({ clanID })(accessToken, team)
      .then(response => response.data);
  },
  updateTeam(
    {
      getters: { accessToken },
    },
    { clanID, teamID, team }
  ) {
    return api.clans
      .updateTeamInClan({ clanID, teamID })(accessToken, team)
      .then(response => response.data);
  },
  updateCaptainInTeam(
    {
      getters: { accessToken },
    },
    { clanID, teamID, captain }
  ) {
    return api.clans
      .updateCaptainInTeam({ clanID, teamID })(accessToken, captain)
      .then(response => response.data);
  },
  updateRoleInClan(
    {
      getters: { accessToken },
    },
    { clanID, login, role }
  ) {
    return api.clans
      .updateRoleInClan({ clanID, login })(accessToken, role)
      .then(response => response.message);
  },
  inviteToClan(
    {
      getters: { accessToken },
    },
    { clan, candidates }
  ) {
    return api.clans.inviteClanMember(accessToken, clan, candidates);
  },
  cancelInviteToClan(
    {
      getters: { accessToken },
    },
    { clan, candidates }
  ) {
    return api.clans.cancelInviteClanMember(accessToken, clan, candidates);
  },
};

export default {
  state: INITIAL_CLANS_STATE(),
  getters,
  mutations,
  actions,
};
