<template>
  <div
    class="form-control custom-scrollbar form-control-editable"
    :class="({ empty: isEmpty }, background)"
    :style="heightStyles"
  >
    <div
      ref="editable"
      class="text-light editable"
      contenteditable
      spellcheck
      dir="auto"
      v-on="$eventHandler"
      @keypress.enter="onEnter"
      @keydown.alt.enter="onEnterAlt"
      @keydown.ctrl.enter="onEnterAlt"
      v-text="resetText"
    />
    <div
      v-if="!isFocused && isEmpty"
      class="d-flex align-items-center text-truncate placeholder"
    >
      {{ placeholder }}
    </div>
    <button v-if="showButton" class="button-send">
      <img src="@/assets/images/icons/send-arrow.svg" alt="send" />
    </button>
  </div>
</template>

<script>
import { textCollapsed } from '@/utils';
import { prop } from '@/utils/factories';
import direction from 'direction';
import { mapGetters } from 'vuex';
import { removeTagsMention } from '@/filters/string';

export default {
  name: 'EditableInput',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    showButton: prop(Boolean),
    value: prop(String),
    placeholder: prop(String),
    maxLength: prop(Number),
    rows: {
      type: [Number, String, Array],
      default: 3,
    },
    metaSend: prop(Boolean),
    disableAutofocus: prop(Boolean),
    mentionsActivators: {
      type: Array,
      default: null,
    },
    background: prop(String),
  },
  data() {
    return {
      text: '',
      resetText: '',
      isFocused: false,
      currentRange: null,
    };
  },
  computed: {
    ...mapGetters(['isRTL', 'popoverChatIsOpened']),
    isCurrentLineRTL() {
      const currentLine = this.text.length && this.text.match(/.+$/gim).pop();
      return !this.text.length ? this.isRTL : direction(currentLine) === 'rtl';
    },
    $eventHandler() {
      return {
        ...this.$listeners,
        input: this.onInput,
        paste: this.onPaste,
        focus: this.onFocus,
        blur: this.onBlur,
        drop: this.onDrop,
        selectionchange: this.onSelectionchange,
        keydown: this.onDelete,
        ...(this.maxLength
          ? {
              keydown: this.checkMaxLength,
            }
          : null),
      };
    },
    isEmpty() {
      return !this.text;
    },
    heightStyles() {
      const rowsIsArray = this.rows instanceof Array;
      const min = (rowsIsArray ? this.rows[0] : this.rows) || 1;
      const max = (rowsIsArray ? this.rows[1] : this.rows) || min;
      const isCollapsed = min === max && min === 1;
      const delta = isCollapsed ? 1.125 : this.$scss.editableLineHeight;
      return {
        minHeight: `${min * delta}rem`,
        maxHeight: `${max * delta}rem`,
      };
    },
    $editable() {
      return this.$refs.editable;
    },
  },
  watch: {
    value(parentValue) {
      if (parentValue !== this.text) {
        this.update(parentValue);
      }
    },
    maxLength() {
      this.focus(true);
      this.onInput();
    },
  },
  mounted() {
    document.execCommand('defaultParagraphSeparator', false, 'br');
    if (this.value) {
      this.resetText = this.value;
      this.text = this.value;
    }
    if (!this.disableAutofocus) {
      this.$nextTick(() => {
        this.$editable.focus();
      });
    }
    this.$emit('busy', !this.value);
  },
  updated() {
    if (this.value && !this.text) {
      this.resetText = this.value;
      this.text = this.value;
    }
  },
  methods: {
    checkMention(activator) {
      return (
        this.mentionsActivators && this.mentionsActivators.includes(activator)
      );
    },
    checkMaxLength(event) {
      const { focusOffset = 0, anchorOffset = 0 } = document.getSelection();
      /*
       1. if maxLength is != 0
       2. if event.key has text - event.key.length === 1.
          Other keys has more (Backspace, Delete, Home, End, Clear, CapsLock, Control, etc)
       3. unblock copy/cut/paste
       4. if has selected text (should be rewritten)
       */
      if (
        this.maxLength &&
        this.text.length >= this.maxLength && // 1
        (event.key.length === 1 || event.key === 'Enter') && // 2
        !event.ctrlKey && // 3
        focusOffset === anchorOffset // 4
      ) {
        event.preventDefault();
      }
    },
    getCaretPosition() {
      const sel = document.getSelection();
      sel.modify('extend', 'backward', 'paragraphboundary');
      var pos = sel.toString().length;
      if (sel.anchorNode != undefined) sel.collapseToEnd();

      return pos;
    },
    onInput() {
      const value = this.$editable.innerHTML;

      this.text = value;
      this.$emit('input', value);
      this.$emit('busy', !textCollapsed(value));

      if (this.maxLength) {
        this.$emit('isFull', value.length === this.maxLength);
        this.$emit('isOverlay', value.length > this.maxLength);
      }
    },
    // here was the logic to remove the whole mention by backspace
    onDelete(e) {
      if (
        (e.key === 'Backspace' && !this.popoverChatIsOpened) ||
        (e.key === 'Delete' && !this.popoverChatIsOpened)
      ) {
        //check if it’s new mentions wrapped in link and unwrap
        const text = removeTagsMention(this.text);
        const caretPos = this.getCaretPosition();
        const lastSpaceIndex = text.slice(0, caretPos).lastIndexOf(' ');
        // get word from last space to current position of the caret + remove zero-width space characters (ltr-rtl marks)
        const wordToRemove = text
          .slice(lastSpaceIndex + 1, caretPos)
          .replace(/[\u200B-\u200D\u200F\u200E\uFEFF]/g, '');
        // check if it is mention
        if (this.checkMention(wordToRemove.charAt(0))) {
          // remove the whole mention from string
          const modifiedString =
            text.slice(0, lastSpaceIndex + 1) +
            text.slice(caretPos, text.length);
          this.$emit('input', modifiedString);
        }
      }
    },
    onEnter(event) {
      if (this.metaSend) {
        const { endContainer, endOffset } = this.getRange();
        const isRangeEnd =
          endContainer.length === endOffset &&
          !endContainer.wholeText.match(/\n$/);

        document.execCommand('insertHTML', false, isRangeEnd ? '\n\n' : '\n'); // fix default browser paste (`<br/><div></div>`) [warn]
        this.onInput();

        event.preventDefault();
      } else if (!event.shiftKey) {
        this.submit();
        event.preventDefault();
      }
    },
    onEnterAlt(event) {
      if (this.metaSend) {
        event.preventDefault();
        this.submit();
      }
    },
    onPaste(event) {
      event.preventDefault();
      this.execPaste(event.clipboardData);
    },
    onDrop(event) {
      this.execPaste(event.dataTransfer);
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('isFocused');
      const { endContainer } = this.getRange();
      this.setPos(endContainer.length, endContainer);
    },
    onBlur() {
      this.isFocused = false;
      this.$emit('lostFocus');
    },
    onSelectionchange() {
      const currentSelection = document.getSelection();

      if (currentSelection && currentSelection.rangeCount > 0) {
        this.currentRange = currentSelection.getRangeAt(0);
      }
    },
    reset() {
      this.$editable.innerHTML = this.resetText;
      if (this.isFocused) {
        this.setPos(0);
      }
      this.onInput();
    },
    update(text = '') {
      if (text) {
        this.$editable.innerHTML = text;
        this.setPos(-1);
        return;
      }

      return this.reset();
    },
    putNode(node, spacesCount = 0) {
      const selection = document.getSelection();

      if (selection && selection.rangeCount === 0 && this.currentRange) {
        selection.addRange(this.currentRange);
      }

      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        // add here logic to wrap tag/mention node with RLM LRM marks
        if (this.isCurrentLineRTL) {
          this.pasteInRange(
            range,
            document.createTextNode(String.fromCharCode(0x200f) + ' '),
            node,
            document.createTextNode(
              ' '.repeat(spacesCount) + String.fromCharCode(0x200f)
            )
          );
        } else {
          this.pasteInRange(
            range,
            document.createTextNode(' '),
            node,
            document.createTextNode(' '.repeat(spacesCount))
          );
        }
        this.onInput();
      }
    },
    pasteInRange(range, ...node) {
      range.deleteContents();

      node.forEach(n => {
        range.insertNode(n);
        range.setStart(range.endContainer, range.endOffset);
      });
    },
    submit() {
      this.$emit('submit');
    },
    focus(setCaretToEnd) {
      this.$editable.focus();
      if (setCaretToEnd) {
        const { endContainer } = this.getRange();
        this.setPos(endContainer.length, endContainer);
      }
    },
    /**
     * get caret position
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range}
     * @returns {Object}
     */
    getRange() {
      const _range = document.getSelection().getRangeAt(0);
      const range = _range.cloneRange();
      range.selectNodeContents(this.$editable);
      range.setEnd(_range.endContainer, _range.endOffset);
      return range;
      // return range.toString().length;
    },

    /**
     * set caret position
     * @param {number} pos - caret position
     * @param {Container} child
     */
    setPos(pos = 0, child = null) {
      if (!this.isFocused) {
        this.focus();
      }

      const selection = document.getSelection();

      if (selection) {
        switch (pos) {
          case -1:
            selection.collapseToEnd();
            break;
          case 0:
            selection.collapseToStart();
            break;
          default:
            if (Number.isSafeInteger(pos)) {
              selection.collapse(child || this.$editable, pos);
            }
        }
      }
    },

    execPaste(clipboardData) {
      if (!this.isFocused) {
        this.focus();
      }
      let text = clipboardData
        .getData('text/plain')
        .replace(/<(?:br|BR)\/?>/gim, '\n');
      if (this.maxLength) {
        text = text.substring(0, this.maxLength - this.text.length);
      }
      document.execCommand('insertHTML', false, text);
      this.onInput();
    },
  },
};
</script>

<style lang="scss">
.form-control.form-control-editable {
  padding: $ph-small-space;
  height: auto;
  line-height: $ph-h2-line-height;
  box-shadow: none;
  background: $new-bg-elevated;
  cursor: text;
  border: none;
  border-radius: $hr-radius-01 !important;
  &:focus-within {
    border-color: $ph-accent;
  }
  .editable,
  .placeholder {
    border-radius: $hr-radius-01;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: inherit;
    font-weight: 400;
  }

  .editable {
    position: relative;
    bottom: 0;
    width: 100%;
    min-height: 1.25rem;
    box-sizing: content-box;
    z-index: 1;
    white-space: pre-wrap;
    caret-color: currentColor;
    &:focus {
      outline: 0;
      min-height: 1.5rem;
    }
    // helper for break-space
    &::after {
      content: '';
      position: relative;
      height: 1px;
      width: 100%;
      background-color: transparent;
      display: block;
    }
  }
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: $ph-small-space;
    // visibility: hidden;
    z-index: 0;
    min-height: 1.25rem;
    line-height: 1.25rem;
    color: $ph-secondary-text;
  }

  // &.empty,
  // &:focus-within {
  //   & .placeholder {
  //     visibility: visible;
  //   }
  // }
}

.new-background {
  background: $hr-bg-search !important;
}
.button-send {
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 100%;
  border: 1px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
html[dir='rtl'] {
  .button-send {
    right: unset !important;
    left: 5px !important;
    transform: rotate(270deg);
  }
}
</style>
