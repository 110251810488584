var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"h-100",class:{
    '': _vm.canEdit,
  }},[_c('div',{staticClass:"w-100"},[(_vm.getStageLoader)?[_c('div',{staticClass:"row"},_vm._l((4),function(index){return _c('div',{key:index,staticClass:"col-3 border-right border-muted",staticStyle:{"border-right-style":"dashed!important"}},[_c('ContentLoader',{attrs:{"variant":"StageLoader"}})],1)}),0)]:[_c('transition-group',{staticClass:"row flex-nowrap custom-scrollbar no-gutters stages-wrap",attrs:{"tag":"div","name":"stage"}},[_vm._l((_vm.stages),function(stage,index){return [_c('div',{key:index,staticClass:" d-flex flex-column justify-content-between"},[_c('TournamentStage',{key:stage.id,staticClass:"h-mr-4 h-100",class:{
                'ph-panel--with-loader':
                  _vm.isLoading || (_vm.stageLoading === stage.id && !_vm.groupLoading),
              },attrs:{"stage-info":stage,"can-edit":_vm.canEdit,"publish-mode":_vm.canPublish},on:{"publishStage":function($event){return _vm.showModalPublish(stage.id)},"unPublishStage":function($event){return _vm.showModalUnPublish(stage.id)},"startStage":function($event){return _vm.showModalStart(stage.id)},"deleteStage":function($event){return _vm.deleteStage(stage.id)},"startCheckIn":function($event){return _vm.confirmStartCheckIn(stage)}}},[(!_vm.isLoading)?_vm._l((_vm.getBlocksByStageID(stage.id)),function(block){return _c('TournamentBlockEditable',{key:block.id,staticClass:"mb-4",attrs:{"block-info":block,"block-type":_vm.getBlockType(stage),"is-edit-mode":_vm.canEdit,"is-loading":_vm.groupLoading === block.id},on:{"change":_vm.onBlockUpdate,"deleteBlock":_vm.deleteBlock},nativeOn:{"click":function($event){return _vm.onClickBlockHandler(stage, block)}}})}):_vm._e(),(
                  _vm.canEdit &&
                    _vm.$route.name !== _vm.$options.TOURN_ROUTE_NAMES.EDIT_STAGE
                )?_c('AddTournamentBlockCover',{key:"add-block",class:{
                  disabled: _vm.isStageFieldDisabled('addGroup', stage),
                  'ph-panel--with-loader loader-only':
                    _vm.groupLoading && _vm.stageLoading === stage.id,
                },nativeOn:{"click":function($event){return _vm.addBlock(stage.id)}}}):_vm._e()],2)],1)]}),(_vm.canEdit)?_c('div',{key:"addStage",staticClass:"col-4 d-flex",attrs:{"data-delay":"false"}},[_c('AddTournamentStageCover',{class:{ disabled: _vm.isTournamentFieldDisabled('addStage') }})],1):_vm._e()],2)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }