var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group m-0"},[_c('label',{staticClass:"h-text-left pull-left pb-2 m-0",attrs:{"for":_vm.id}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_c('div',{class:{ 'is-invalid': _vm.error }},[_c('input',_vm._b({staticClass:"new-email-input",class:{ 'red-outline': _vm.error },attrs:{"autocomplete":_vm.autocompleteOff ? 'new-password' : 'on'},on:{"input":_vm.updateValue,"blur":_vm.onBlur,"focus":_vm.onFocus}},'input',{
        id: _vm.id,
        name: _vm.name,
        type: _vm.type,
        value: _vm.value,
        placeholder: _vm.placeholder,
        tabindex: _vm.tabindex,
        autofocus: _vm.autofocus,
      },false))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:((_vm.error, _vm.showError)),expression:"(error, showError)"}],staticClass:"form-text text-danger error-help",attrs:{"id":"emailHelp"},domProps:{"textContent":_vm._s(_vm.error)}})])}
var staticRenderFns = []

export { render, staticRenderFns }