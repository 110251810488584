<template>
  <Icon
    class="d-block"
    :name="`social/${name}`"
    :size="size"
    :fill="$scss.colors.primaryTextBg"
  />
</template>

<script>
import Icon from '@/components/atoms/Icon';

export default {
  name: 'SocialIcon',
  components: { Icon },
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
