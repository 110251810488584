<template>
  <div class="navbar-sidechat">
    <div
      class="position-relative cursor-hand px-2"
      :class="[unreadConversations ? 'has-unread' : 'text-muted']"
      @click="toggle"
    >
      <Icon name="chat" :width="18" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Icon from '@/components/atoms/Icon';
import routeHelpers from '@/mixins/routeHelpers';

export default {
  name: 'SideChat',
  components: { Icon },
  mixins: [routeHelpers],
  computed: {
    ...mapGetters([
      'activePopoverChat',
      'sliderChatIsShow',
      'unreadConversations',
    ]),
  },
  methods: {
    ...mapMutations([
      'removeActivePopoverChat',
      'showPopoverChat',
      'toggleSliderChat',
    ]),
    toggle() {
      // if (
      //   this.$route.params.tournamentID &&
      //   this.getMetaFromParents('showAdminFunctions')
      // ) {
      //   return this.toggleSliderChat();
      // }
      if (window.innerWidth <= 768) {
        this.$router.push('/conversations');
      } else {
        if (this.sliderChatIsShow) {
          this.toggleSliderChat();
        }
        this.$nextTick(() => {
          this.showPopoverChat();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-sidechat {
  .has-unread {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      @include auto-rtl(right, 0);
      bottom: 100%;
      background: $ph-error;
      border-radius: 50%;
      transform: translateY(100%);
    }
  }
}
</style>
