<template>
  <div class="border border-hover p-5 ph-panel--rounded-small">
    <HorizontalTabs
      v-bind="{ tabs }"
      use-custom
      :active-tab="activeTab"
      class="mb-5"
      @change="onTabChanged"
    />
    <template v-if="isStageSummaryView">
      <div
        v-for="(stage, index) in currentTournamentStages"
        :key="stage.id"
        :class="{
          'mt-4': index > 1,
        }"
      >
        <StageSettingsSummary v-bind="{ stage }" :class="{ 'mt-4': index }" />
      </div>
    </template>
    <template v-else>
      <div
        v-for="(stage, index) in currentTournamentStages"
        :key="stage.id"
        :class="{
          'mt-4': index > 1,
        }"
      >
        <StageMatchesSummary
          v-if="stagesSummary[stage.id]"
          v-bind="{ summary: stagesSummary[stage.id] }"
          :class="{ 'mt-4': index }"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { translate } from '@/mixins/translate';
import { TOURNAMENT_STATUSES } from '@/constants/tournaments';
import HorizontalTabs from '@/components/common/HorizontalTabs';
import StageSettingsSummary from '@/views/tournaments/management/dashboard/components/StageSummaryCard';
import StageMatchesSummary from '@/views/tournaments/management/dashboard/components/MatchSummaryCard';

const defaultTabs = [
  {
    key: '_web_tournament_dashboard_stage-summary',
    label: 'Stage summary',
    disabled: false,
  },
  {
    key: '_web_tournament_dashboard_matches-summary',
    label: 'Matches summary',
    disabled: false,
  },
];

export default {
  name: 'TournamentStructureWidget',
  components: {
    HorizontalTabs,
    StageSettingsSummary,
    StageMatchesSummary,
  },
  mixins: [translate('_web_tournament_dashboard')],
  data() {
    return {
      activeTab: defaultTabs[0].key,
      stagesSummary: {},
    };
  },
  computed: {
    ...mapGetters([
      'currentTournament',
      'isTournamentPastStatus',
      'currentTournamentStages',
      'stagesList',
    ]),
    tabs() {
      return [...defaultTabs];
    },
    isStageSummaryView() {
      return this.activeTab === defaultTabs[0].key;
    },
  },
  watch: {
    stagesList(newVal, oldVal) {
      if (!this.$lodash.isEqual(newVal, oldVal)) {
        this.getAllSummaryData();
      }
    },
    'currentTournament.status'(newValue) {
      if (
        newValue &&
        this.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED)
      ) {
        this.onTabChanged(defaultTabs[1].key);
      }
    },
  },
  created() {
    if (!this.$lodash.isEmpty(this.currentTournamentStages)) {
      this.getAllSummaryData();
    }
    if (
      !this.$lodash.isEmpty(this.currentTournament) &&
      this.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED)
    ) {
      this.onTabChanged(defaultTabs[1].key);
    }
  },
  methods: {
    ...mapActions(['fetchStageSummaryData', 'errorNotify']),
    onTabChanged(tab) {
      this.activeTab = tab;
    },
    getAllSummaryData() {
      if (this.$lodash.isArray(this.currentTournamentStages)) {
        Promise.all(
          this.currentTournamentStages.map(stage =>
            this.getSummaryDataByStage(stage.id)
          )
        )
          .then(responses => {
            this.stagesSummary = responses.reduce(
              (acc, response) => ({
                ...acc,
                [response.id]: response,
              }),
              {}
            );
          })
          .catch(this.errorNotify);
      }
    },
    getSummaryDataByStage(stageID) {
      return this.fetchStageSummaryData({
        tournamentID: this.$route.params.tournamentID,
        stageID,
      }).then(response => ({ ...response, id: stageID }));
    },
  },
};
</script>
