<template>
  <div class="widget">
    <div class="d-flex justify-content-between align-items-start">
      <div class="d-flex flex-nowrap align-items-start">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <Subheader
              :text="title"
              class="d-flex align-items-center mb-0 widget__title"
            />
            <div class="h-ml-4">
              <slot name="additionalInfo">
                <InfoTooltip
                  v-if="helpText"
                  :text="helpText"
                  :icon-props="{
                    width: 18,
                  }"
                />
              </slot>
            </div>
          </div>

          <slot name="subtitle">
            <TextBlock v-if="subTitle" variant="muted" :text="subTitle" />
          </slot>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <HeraSearchInput
          v-if="!hideSearch"
          v-model="value"
          :placeholder="
            searchPlaceholder || $t('_web_tournaments_search', 'Search')
          "
          :debounce="700"
          :read-only="searchDisabled"
          class="widget__searchfield"
          @input="val => $emit('input', val)"
        />
        <slot></slot>
        <div
          v-if="statusBtnMode && statusBtnTop"
          class="status-btn-wrapper h-ml-auto"
        >
          <StatusBtn :mode="statusBtnMode" :disabled="statusBtnDisabled" />
        </div>
        <template v-if="actionsMenu">
          <InfoTooltip
            v-for="menuItem in actionsMenu"
            :key="`widget-menu-item-${menuItem.key}`"
            class="h-ml-3"
            :show-icon="false"
            :text="menuItem.subtitle"
          >
            <Panel
              class="cursor-pointer p-3"
              :variant="[
                'transparent',
                'stroked',
                'stroked-secondary',
                'rounded',
                'rounded-medium',
              ]"
              :class="{
                disabled: menuItem.disabled,
              }"
              @click.stop="menuItem.handler"
            >
              <Icon :name="menuItem.icon" width="16" />
            </Panel>
          </InfoTooltip>
        </template>
        <!-- <ActionsMenu
          v-if="actionsMenu"
          class="h-ml-5 d-flex align-content-center"
          :actions-btn-items="actionsMenu"
        /> -->
      </div>
    </div>
    <div
      class="d-flex justify-content-center"
      :class="{
        'has-status-btn':
          ((statusBtnMode && statusBtnBottom) || $slots.actionBtn) &&
          indicatorsCentered,
        'mt-5':
          (indicators && indicators.length) ||
          (statusBtnMode && statusBtnBottom) ||
          $slots.actionBtn,
      }"
    >
      <slot name="beforeIndicators"></slot>
      <PerformanceIndicators
        v-bind="{
          indicators,
        }"
        :class="{
          'h-mr-auto':
            indicatorsCentered && (statusBtnMode || $slots.actionBtn),
        }"
      />
      <slot name="afterIndicators"></slot>
      <div
        v-if="$slots.actionBtn"
        class="h-ml-auto"
        :class="{ 'status-btn-wrapper': !(statusBtnMode && statusBtnBottom) }"
      >
        <slot name="actionBtn"></slot>
      </div>
      <div
        v-if="statusBtnMode && statusBtnBottom"
        class="status-btn-wrapper h-ml-auto"
      >
        <StatusBtn :mode="statusBtnMode" :disabled="statusBtnDisabled" />
      </div>
    </div>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import Subheader from '@/components/atoms/Subheader';
const BTN_TOP = 'top';
const BTN_BOTTOM = 'bottom';
const POSSIBLE_POSITIONS = [BTN_TOP, BTN_BOTTOM];
// import ActionsMenu from '@/components/common/ActionsMenu';
import PerformanceIndicators from '@/views/tournaments/management/summaryWidgets/PerformanceIndicatorsSet';
import HeraSearchInput from '@/components/molecules/form/HeraSearchInput';
import TextBlock from '@/components/atoms/TextBlock';
import StatusBtn from '../singleTournament/components/StatusBtn';
import InfoTooltip from '@/components/molecules/InfoTooltip';
import Panel from '@/components/atoms/Panel';
import Icon from '@/components/atoms/Icon';
export default {
  name: 'Widget',
  components: {
    Subheader,
    Panel,
    Icon,
    // ActionsMenu,
    HeraSearchInput,
    PerformanceIndicators,
    TextBlock,
    StatusBtn,
    InfoTooltip,
  },
  props: {
    value: prop(String),
    title: prop(String),
    subTitle: prop(String),
    helpText: prop(String),
    indicators: prop(Array, null),
    indicatorsCentered: prop(Boolean),
    actionsMenu: prop(Array, null),
    searchPlaceholder: prop(String),
    searchDisabled: prop(Boolean),
    statusBtnDisabled: prop(Boolean),
    hideSearch: prop(Boolean),
    statusBtnMode: prop(String),
    statusBtnPosition: prop(String, BTN_BOTTOM, val =>
      POSSIBLE_POSITIONS.includes(val)
    ),
  },
  computed: {
    statusBtnTop() {
      return this.statusBtnPosition === BTN_TOP;
    },
    statusBtnBottom() {
      return this.statusBtnPosition === BTN_BOTTOM;
    },
  },
};
</script>

<style lang="scss">
.widget {
  .status-btn-wrapper {
    .status-btn {
      min-width: 220px;
      @include auto-rtl(margin-left, $ph-huge-space);
    }
  }
  .has-status-btn {
    @include auto-rtl(padding-left, 220px);
  }
  .widget__searchfield {
    max-width: 175px;
    margin-bottom: 0px !important;
  }
  .widget__title {
    height: 34px;
  }
}
</style>
