<template>
  <div class="row my-3 pb-3">
    <div
      v-for="(item, key) in timeSteps"
      :key="key + currentTournamentStage[key]"
      class="col-auto"
    >
      <DateTimePicker
        :id="key"
        v-validate="
          isStageFieldDisabled(`stage_${key}`) || disabled
            ? {}
            : key === 'date_start' && currentTournamentStage.date_end
            ? {
                after: calcMinDate(key),
                before: calcMaxDate(key),
              }
            : {
                after: calcMinDate(key),
              }
        "
        :value="currentTournamentStage[key]"
        class="date-time-input"
        :required="item.required"
        :data-vv-name="$lodash.lowerCase(key)"
        :data-vv-as="$t(`_web_tournament_date-${key}`, item.label)"
        :disabled="isStageFieldDisabled(`stage_${key}`) || disabled"
        :date-label="$t(`_web_tournament_date-${key}`, item.label)"
        :time-label="$t(`_web_tournament_date-${key}-time`, 'Time')"
        @input="$event => updateCurrentTournamentStage({ [key]: $event })"
      />
    </div>
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import dateMethods from '@/mixins/dateMethods';
import DateTimePicker from '@/components/form/DateTimePicker';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
const timeSteps = () => ({
  date_start: {
    value: null,
    label: 'Stage begin:',
    required: false,
  },
  date_end: {
    value: null,
    label: 'Stage finish:',
    required: false,
  },
});
export default {
  name: 'StageScheduleSettings',
  components: {
    DateTimePicker,
  },
  inject: ['$validator'],
  mixins: [
    ...dateMethods,
    translate('_web_tournament_stage_form'),
    stageSetupHelpers,
  ],
  props: {
    disabled: prop(Boolean),
  },
  data() {
    return {
      timeSteps: timeSteps(),
    };
  },
  methods: {
    calcMaxDate(key) {
      const {
        date_end,
        block_configuration = {},
      } = this.currentTournamentStage;
      const limit =
        key === 'check_in_start_date'
          ? date_end || null
          : key !== 'date_start'
          ? null
          : !date_end
          ? null
          : date_end - block_configuration.match_duration;
      return limit;
    },
    calcMinDate(key) {
      const now = this.getNowSeconds();
      const {
        date_start = now,
        block_configuration = {},
        check_in_start_date = now,
      } = this.currentTournamentStage;
      const limit =
        key === 'date_end'
          ? date_start + block_configuration.match_duration || now
          : key === 'check_in_end_date'
          ? check_in_start_date
          : now;
      return now > limit ? now : limit;
    },
  },
};
</script>

<style></style>
