var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SegmentControl',_vm._b({on:{"input":_vm.availabilityTypeChanged},model:{value:(_vm.availabilityType),callback:function ($$v) {_vm.availabilityType=$$v},expression:"availabilityType"}},'SegmentControl',{
      id: 'stagePlaceType',
      viewMode: _vm.disabled,
      options: _vm.stageAvailabilityTypes,
      required: !_vm.disabled,
      error: _vm.errors.first('stagePlaceType'),
      label: _vm.$t('_web_tournament_stage_type-of-stage-label', 'Type'),
      tooltip: _vm.$t(
        '_web_tournament_stage_type-tooltip',
        'Switcher defines if a current stage is conducted online or offline. For offline location must be provided'
      ),
      readOnly: _vm.disabled,
      disabled: _vm.isStageFieldDisabled('stagePlaceType') || _vm.disabled,
      'data-vv-name': 'stagePlaceType',
      'data-vv-as': _vm.$t('_web_tournament_stage_type-of-stage-label', 'Type'),
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }