var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-communications"},[_c('ContentLayout',[_c('div',{staticClass:"col-12 d-none d-md-flex justify-content-between my-4"},[_c('Subheader',{staticClass:"col-md-4 pt-2 mb-0 header-chat-title"},[_vm._v(_vm._s(_vm.$t('_android_chats', 'Chats')))]),_c('ChatCreate',{staticClass:"align-self-center"})],1),_c('div',{staticClass:"pb-0 flex-grow-0 flex-wrap chat-body"},[_c('div',{staticClass:"col-md-4 h-100 d-md-flex flex-column",class:{
          'd-none': _vm.conversationId,
          'd-flex': !_vm.conversationId,
        }},[_c('Panel',{staticClass:"flex-grow-1 overflow-hidden p-4 position-initial-panel"},[_c('div',{staticClass:"d-md-none justify-content-between mb-4",class:{
              'd-none': _vm.conversationId,
              'd-flex': !_vm.conversationId,
            }},[_c('h4',{staticClass:"all-comms-text header-chat-title"},[_vm._v("\n              "+_vm._s(_vm.$t('_android_chats', 'Chats'))+"\n            ")]),_c('ChatCreate',{staticClass:"align-self-center"})],1),_c('div',{staticClass:"search-input"},[_c('ConversationSearch',{attrs:{"is-visible":""},on:{"change":_vm.getSearchValue}})],1),_c('div',{staticClass:"chats-container"},[_c('ChatsList',{key:_vm.chatListKey,staticClass:"chat-list\n             oveflow-hidden",attrs:{"has-search":"","search-value":_vm.searchValue}})],1)])],1),_c('div',{staticClass:"col-md-8 flex-grow-1 h-100",class:{
          'd-none d-md-block': !_vm.conversationId,
          'd-block': _vm.conversationId,
        }},[_c('Panel',{staticClass:"h-100 p-4 position-relative overflow-hidden",attrs:{"variant":[]}},[_c('ChatDialog',{staticClass:"h-100 overflow-hidden"})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }