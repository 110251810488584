import store from '@/store';

export default {
  created() {
    console.log('sssssssssssssssssss', store.getters.isAuthorized);
    if (this.$flagsmith && store.getters.isAuthorized) {
      this.$flagsmith.identify(store.getters.myProfile.login);
    }
  },
};
