import isLoading from './isLoading';
import { uniqBy } from '@/utils/lodashUtils';

export const getNextPageData = {
  mixins: [isLoading],
  data() {
    return {
      nextPageToken: undefined,
      properties: {},
    };
  },
  computed: {
    listIsDisabled() {
      return this.isLoading || this.nextPageToken === null;
    },
  },
  methods: {
    getNextPageData(listName, dataFetcher = Promise.resolve(), options = {}) {
      if (this.nextPageToken !== null) {
        this.setLoading();
        this.toggleCrashed(false);

        return dataFetcher
          .then(response => {
            this.isLoading = false;

            if (!response) {
              this.nextPageToken = null;
              return;
            }

            let items = (response && response.items) || [];
            Object.entries(options).forEach(([method, fn]) => {
              if (method in Array.prototype && typeof fn === 'function') {
                const replacedList = items[method](fn);
                if (Array.isArray(replacedList)) {
                  items = replacedList;
                }
              }
            });

            this.nextPageToken =
              (items.length && response.next_page_token) || null;
            this.properties = response.properties || null;

            this[listName] = (this[listName] || []).concat(items);

            // replace and unique items:
            if (options.uniqBy) {
              this[listName] = uniqBy(this[listName], options.uniqBy);
            }

            this.total = response.total;
            return this[listName];
          })
          .catch(error => {
            this.toggleCrashed(true);
            return this.$store.dispatch('errorNotify', error);
          });
      }

      return Promise.reject();
    },
  },
};

export default [getNextPageData];
