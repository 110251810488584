<template>
  <div class="d-flex flex-column">
    <h5 class="font-size-sm">
      {{ $t('_web_communications_create_dialog_participants', 'Participants') }}
    </h5>
    <Search v-model="searchValue" class="mb-5" />
    <UsersList
      v-model="participants"
      v-bind="{ searchValue }"
      class="flex-grow-1 flex-shrink-1"
    />
  </div>
</template>

<script>
import Search from '@/components/molecules/form/HeraSearchInput';
import UsersList from './UsersList';

export default {
  name: 'ConversationCreateDialog',
  components: {
    UsersList,
    Search,
  },
  model: {
    prop: 'conversationData',
    event: 'update',
  },
  props: {
    conversationData: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchValue: '',
      participants: [],
    };
  },
  watch: {
    participants: {
      handler(participants) {
        this.$emit('update', { ...this.conversationData, participants });
      },
      deep: true,
    },
  },
};
</script>
