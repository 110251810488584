import { DateTime } from 'luxon';
import { isFinite, isString } from '@/utils/lodashUtils';

export const ISOSecondsConvertion = {
  methods: {
    startOfDayObj(seconds) {
      return seconds
        ? DateTime.fromSeconds(seconds)
            .toLocal()
            .startOf('day')
        : DateTime.local().startOf('day');
    },
    endOfDayObj() {
      return DateTime.local().endOf('day');
    },
    secondsToMillis(seconds) {
      return seconds && isFinite(seconds)
        ? Math.floor(DateTime.fromSeconds(seconds).toMillis())
        : null;
    },
    millisToSeconds(milliseconds) {
      return milliseconds && isFinite(milliseconds)
        ? Math.floor(DateTime.fromMillis(milliseconds).toSeconds())
        : null;
    },
    secondsToISO(seconds) {
      return isFinite(seconds)
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' }).toISO()
        : '';
    },
    secondsToDatetime(seconds) {
      return isFinite(seconds)
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' })
        : null;
    },
    ISOdateToSeconds(ISOdate) {
      return isString(ISOdate)
        ? DateTime.fromISO(ISOdate, { zone: 'UTC' }).toSeconds()
        : null;
    },
    getNow(zone = 'UTC') {
      return DateTime.fromJSDate(new Date(), { zone });
    },
    getNowSeconds() {
      return this.dateToSeconds(new Date());
    },
    dateToSeconds(jsDate, zone = 'UTC') {
      return Math.floor(DateTime.fromJSDate(jsDate, { zone }).toSeconds());
    },
    datetimeFromJsDate(jsDate) {
      return DateTime.fromJSDate(jsDate);
    },
    toLocal(datetime) {
      return datetime.toLocal();
    },
    toUTC(datetime) {
      return datetime.toUTC();
    },
    fromFormat(format) {
      return DateTime.fromFormat(format);
    },
    toSeconds(datetime) {
      return Math.floor(datetime.toSeconds());
    },
    secondsToFormat(seconds, format) {
      return isFinite(seconds)
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' })
            .toLocal()
            .toFormat(format)
        : null;
    },
  },
};
const pickerHandlers = {
  methods: {
    handleDateChange(seconds, prop, handlerFunc) {
      if (seconds) {
        const pickedDate = DateTime.fromSeconds(seconds);
        const limit = DateTime.fromMillis(this.calcMinDate(prop));
        const isSameDate =
          pickedDate.day === limit.day &&
          pickedDate.month === limit.month &&
          pickedDate.year === limit.year &&
          pickedDate.hour === limit.hour &&
          pickedDate.minute === limit.minute;
        const result =
          isSameDate && !this.timeSteps[prop].value
            ? limit.toSeconds()
            : seconds;
        this.timeSteps[prop].value = result;

        if (result) {
          handlerFunc({
            [prop]: Math.floor(result),
          });
          this.errors.clear();
        }
      }
    },
  },
};
export default [ISOSecondsConvertion, pickerHandlers];
