//https://twitter.com/intent/tweet?url=https%3A//youtu.be/BNdJaetPrlg&text=%D0%A8%D0%B2%D0%B5%D1%86%D0%B8%D1%8F.%20%D0%9A%D0%B0%D0%BA%20%D0%B6%D0%B8%D1%82%D1%8C%20%D0%B2%20%D0%BA%D0%B0%D0%B9%D1%84%20%D0%B2%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B5%20%D1%81%20%D0%BF%D0%BB%D0%BE%D1%85%D0%B8%D0%BC%20%D0%BA%D0%BB%D0%B8%D0%BC%D0%B0%D1%82%D0%BE%D0%BC.%20%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%BE%D0%B9%20%D0%92%D1%8B%D0%BF%D1%83%D1%81%D0%BA.&via=YouTube&related=YouTube,YouTubeTrends,YTCreators
const RELATED = 'PlayHera';
const HASHTAGS = 'playhera';
export const shareTwitterLink = {
  methods: {
    shareToTwitter({ text = '', url } = {}) {
      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURI(
          url
        )}&text=${encodeURI(text)}&related=${encodeURI(
          RELATED
        )}&hastags=${encodeURI(HASHTAGS)}`,
        '_blank'
      );
    },
  },
};

export default [shareTwitterLink];
