<template>
  <div class="position-relative hera-table-wrap">
    <div
      class="d-flex justify-content-end align-items-center hera-table-control"
    >
      <slot name="control" />
      <Icon
        v-if="$scopedSlots.control && hasMenu"
        :name="menuIcon"
        size="18"
        class="cursor-pointer align-self-start h-mr-5"
        :class="{ disabled: menuDisabled }"
        :fill="$scss.secondaryTextBg"
        @click="!menuDisabled && $emit('show-menu', $event)"
      />
    </div>
    <table class="table table-hover hera-table">
      <thead>
        <tr class="hera-table-head">
          <slot v-if="selectable" name="head-check-all">
            <th class="hear-table-checkbox-wrapper">
              <Checkbox
                v-if="columns.length"
                :checked="selectAll"
                :indeterminate="indeterminate"
                :disabled="loading || !items.length"
                @change="onSelectAll"
              />
            </th>
          </slot>
          <slot name="head-items">
            <th
              v-for="(column, index) in columns"
              :key="index"
              :class="column._thClass"
            >
              <div class="d-flex align-items-start justify-content-between">
                <slot name="head-item" v-bind="{ column, index }" />
                <Icon
                  v-if="
                    !$scopedSlots.control &&
                      index === columns.length - 1 &&
                      hasMenu
                  "
                  :name="menuIcon"
                  size="18"
                  class="cursor-pointer hera-table-menu"
                  :class="{ disabled: menuDisabled }"
                  :fill="$scss.secondaryTextBg"
                  @click="!menuDisabled && $emit('show-menu', $event)"
                />
              </div>
            </th>
          </slot>
        </tr>
        <tr v-if="showSubheader" class="hera-table-subhead">
          <th v-if="selectable"></th>
          <th
            v-for="(column, index) in columns"
            :key="index"
            :class="column._thClass"
          >
            <slot name="subhead-item" v-bind="{ column, index }"></slot>
          </th>
        </tr>
      </thead>
      <template v-if="!loading && items.length">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`row_${item.id || index}`"
            :class="{ active: item._selected, 'has-addon': item.isAddon }"
            @click="$emit('clickOnRow', index)"
          >
            <slot v-if="selectable" body="body-checkbox">
              <td class="hear-table-checkbox-wrapper">
                <Checkbox
                  v-if="!item.hideCheckbox"
                  v-model="item._selected"
                  @change="val => itemSelected(val, item)"
                />
              </td>
            </slot>
            <slot name="body-items">
              <template v-if="!item.isAddon">
                <td
                  v-for="(column, colIndex) in columns"
                  :key="`col_${colIndex}_${index}`"
                  :class="column._tdClass"
                >
                  <slot
                    name="body-item"
                    v-bind="{ item, column, index }"
                  ></slot>
                </td>
              </template>
              <slot v-else name="body-item-addon" v-bind="{ item, index }">
              </slot>
            </slot>
          </tr>
        </tbody>
        <tfoot v-if="$scopedSlots.footer">
          <tr>
            <td colspan="100%" class="hera-table-footer">
              <slot name="footer"></slot>
            </td>
          </tr>
        </tfoot>
      </template>
      <caption v-else>
        <HeraDummyList v-if="loading" class="pre-loader" />
        <HeraDummyList
          v-else-if="!items.length"
          v-bind="{
            iconName: dummyIcon,
            text: dummyText,
            ...dummyProps,
          }"
        >
          <template #ctaButton>
            <slot name="noUnitsCTA"></slot>
          </template>
        </HeraDummyList>
      </caption>
    </table>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import Checkbox from '@/components/atoms/form/Checkbox';
import HeraDummyList from '@/components/common/HeraDummyList';

export default {
  name: 'Table',
  components: {
    Icon,
    Checkbox,
    HeraDummyList,
  },
  props: {
    columns: prop(Array, () => []),
    items: prop(Array, () => []),
    selectable: prop(Boolean),
    maxItemsLength: prop(Number),
    showSubheader: prop(Boolean),
    hasMenu: prop(Boolean),
    menuIcon: prop(String, 'settings-outline'),
    menuDisabled: prop(Boolean),
    loading: prop(Boolean),
    dummyIcon: prop(String), // todo remove it (use dummyProps)
    dummyText: prop(String), // todo remove it (use dummyProps)
    dummyProps: prop(null),
  },
  data() {
    return {
      // selectAll: false,
    };
  },

  computed: {
    selectAll: {
      get() {
        return (
          (!this.maxItemsLength || this.maxItemsLength === this.items.length) &&
          this.items.every(({ _selected }) => _selected)
        );
      },
      set(val) {
        this.toggleAll(val);
      },
    },
    selectedLength() {
      return this.items.filter(({ _selected }) => _selected).length;
    },
    indeterminate() {
      return this.selectedLength > 0 && !this.selectAll;
    },

    /*  atLeastOneSelected() {
      return this.selectable && this.items.some(i => i._selected);
    },
  */
  },

  methods: {
    toggleAll(val) {
      // this.items = this.items.map(item => ({ ...item, _selected: val }));
      this.$emit('select-all', val);
    },
    itemSelected(val, item) {
      this.$emit('select', val, item);
      this.$nextTick(() => {
        if (this.selecAll) {
          this.$emit('select-all', true);
        }
      });
      // this.selectAll =
      //   (!this.maxItemsLength || this.maxItemsLength === this.items.length) &&
      //   !this.items.some(({ _selected }) => !_selected);
    },
    onSelectAll(val) {
      this.selectAll = val;
    },
  },
};
</script>

<style lang="scss">
.hera-table-wrap {
  background-color: $ph-card;
  border-radius: $ph-medium-radius;
  padding: $ph-large-space 0 $ph-huge-space 0;
  &,
  & .table {
    border-radius: $ph-large-radius;
  }
  & .table {
    margin-bottom: 0;
  }
  .hera-table-menu {
    bottom: -2px;
    @include auto-rtl(right, 0);
    z-index: 3;
  }
  .hera-table-subhead th {
    vertical-align: middle;
    padding-top: 0;
  }
  .hear-table-checkbox-wrapper {
    width: 2rem;
    @include auto-rtl(padding-right, $ph-medium-space);
  }
  .hera-table-footer {
    padding: 0 !important;
  }

  &.transparent {
    background-color: transparent;

    .hera-table {
      background-color: transparent;
    }
  }
}
</style>
