import defaultUser from '@/assets/images/defaults/user.svg';
import defaultClan from '@/assets/images/defaults/clan.svg';
import defaultOrganization from '@/assets/images/defaults/organization.png';
import defaultESPL from '@/assets/images/defaults/eSPL.png';
import defaultChannels from '@/assets/images/defaults/channel.svg';
import defaultGroup from '@/assets/images/defaults/group.svg';
import defaultGame from '@/assets/images/defaults/game.svg';
import defaultPlace from '@/assets/images/defaults/place.svg';
import defaultAdmin from '@/assets/images/defaults/admin.svg';
import defaultPHmax from '@/assets/images/ph-logo-new.svg';

import deletedClan from '@/assets/images/deleted/clan.svg';

export default {
  defaultUser,
  defaultClan,
  defaultOrganization,
  defaultESPL,
  defaultChannels,
  defaultGroup,
  defaultGame,
  defaultPlace,
  defaultAdmin,
  defaultPHmax,
  deletedClan,
};
