<template>
  <div class="bannerContainer">
    <div class="banner">
      <span class="bannerText">{{
        $t('_web_create_team-text', 'Don&#96;t have a team?')
      }}</span>

      <h1>
        {{
          $t(
            '_web_create_team-headline',
            'Create a team to play team tournaments'
          )
        }}
      </h1>
      <router-link to="/clan-create">
        <button class="bannerBtn">
          <img
            src="@/assets/images/icons/createTeamnew.svg"
            alt="create-team"
          />
          {{ $t('_web_create_team_page', 'CREATE A TEAM') }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import './variables';
.bannerContainer {
  width: 100%;
  height: $banner_height + $hr-spacing-13;
  background-color: $new-bg-page;
  display: flex;
  align-items: center;
  justify-content: center;
  //cursor: pointer;
}

.bannerBtn {
  background-color: #5a58f2;
  color: $hr-bg-modal-btn;
  border: 1px solid #30374a;
  border-radius: 5px;
  width: 176px;
  height: 44px;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bannerBtn:hover {
  background-color: #30374a;
}

.banner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 300px;
  // max-width: 360px;
}

h1 {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 110%;
  color: $hr-bg-modal-btn;
  height: 49px;
}

.bannerText {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  color: $hr-bg-modal-btn;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 24px;
  }
}
</style>
