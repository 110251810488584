import { render, staticRenderFns } from "./HeraLogo.vue?vue&type=template&id=7a759852&scoped=true&"
import script from "./HeraLogo.vue?vue&type=script&lang=js&"
export * from "./HeraLogo.vue?vue&type=script&lang=js&"
import style0 from "./HeraLogo.vue?vue&type=style&index=0&id=7a759852&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a759852",
  null
  
)

/* custom blocks */
import block0 from "./HeraLogo.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms&name=HeraLogo&knobs=%7B%0A%20%20%20%20type%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('type'%2C%20'full')%0A%20%20%20%20%7D%2C%0A%20%20%20%20animated%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('animated'%2C%20false)%0A%20%20%20%20%7D%2C%0A%20%20%20%20variant%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('variant'%2C%20%5B'strong'%2C%20'em'%2C%20'normal'%2C%20'secondary'%5D%2C%20'normal')%0A%20%20%20%20%7D%2C%0A%20%20%20%20fullWidth%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('fullWidth'%2C%20'156')%0A%20%20%20%20%7D%2C%0A%20%20%20%20shortWidth%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('shortWidth'%2C%20'17')%0A%20%20%20%20%7D%2C%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports