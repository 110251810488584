<template>
  <div ref="tableWrapper" class="mb-4">
    <HeraDummyList
      v-if="fetchedWithoutFilters && !tournaments.length"
      class="my-6 py-6 tournament-list-empty"
      icon-name="empty-competitions"
      size="200"
      :header-text="
        $t(
          '_web_competitions_tournaments-list-empty-state',
          'There are no tournaments at the moment'
        )
      "
      :text="dummyText"
    >
      <div
        v-if="canCreateTournament"
        slot="ctaButton"
        class="d-flex justify-content-center pt-6 mt-4"
      >
        <router-link :to="{ name: 'TournamentSetup' }">
          <HeraFormBtn
            class="btn btn-primary create-tournament-button"
            variant="full-width"
            :label="createButtonLabel"
          />
        </router-link>
      </div>
    </HeraDummyList>
    <template v-else>
      <div
        v-if="
          canCreateTournament &&
            (isActiveTab ? !(!isLoading && !tournaments.length) : true)
        "
        class="d-flex justify-content-end"
      >
        <router-link :to="{ name: 'TournamentSetup' }">
          <HeraFormBtn
            class="btn btn-primary create-tournament-button"
            :label="createButtonLabel"
          />
        </router-link>
      </div>
      <div class="row no-gutters justify-content-center mt-5 mb-6">
        <div class="col-6">
          <HorizontalTabs
            :tabs="tabs"
            :active-tab="activeTab"
            class="w-100 tournaments-tabs position-relative"
            use-custom
            @change="setActiveTab"
          />
        </div>
      </div>
      <div class="row d-flex justify-content-between align-items-end my-2 py-1">
        <CustomFilter
          v-for="(filter, key) in multiselectTournamentFilters"
          :key="key"
          v-model="filters[key]"
          :options="filter.options"
          :disabled="!filter.options"
          :input-name="key"
          :label="filter.label"
          :all-placeholder="filter.allPlaceholder"
          clear-if-selected-all
          class="col-2 mb-3"
          @close="onFilterChange"
        />
        <HeraFormDropdown
          v-model="sortBy"
          :list="sortByOptions"
          :label="
            $t('_web_competitions_tournaments-list-sort-label', 'Sort by')
          "
          variant="input-like"
          theme-dark
          class="col-2 mb-3"
          @input="onFilterChange"
        >
          <template slot="chosen" slot-scope="chosen">
            {{ $t(chosen.chosenItem.key, chosen.chosenItem.label) }}
          </template>

          <template slot="item" slot-scope="{ item }">
            {{ $t(item.key, item.label) }}
          </template>
        </HeraFormDropdown>
        <SearchInput
          v-model="filters.name"
          :debounce="700"
          :placeholder="
            $t(
              '_web_competitions_tournaments-list-search-placeholder',
              'Search'
            )
          "
          class="col-3 h-ml-auto"
          @input="onFilterChange"
        />
      </div>
      <Paginated
        v-bind="{
          totalItems,
          hidePagination: !tournaments.length,
          pageSize,
          inContainer: true,
        }"
        class="w-auto mt-4 pt-3"
        @change="fetchTournaments"
      >
        <template v-if="tournaments.length">
          <template v-for="item in tournaments">
            <div :key="item.id" class="col-12 col-lg-4 mb-5">
              <TournamentCard
                v-bind="{
                  tournament: item,
                  forOrganizer: true,
                }"
                class="h-100"
              />
            </div>
          </template>
        </template>
        <HeraDummyList
          v-else
          v-bind="{
            size: 200,
            iconName: 'empty-competitions',
            headerText: $t(
              '_web_competitions_tournaments-list-empty-state',
              'There are no tournaments at the moment'
            ),
            text: dummyText,
            ...(isLoading
              ? {
                  headerText: $t('_web_list-loading', 'Loading'),
                  subheaderText: '',
                  text: '',
                }
              : null),
          }"
        >
          <template #ctaButton>
            <div
              v-if="isActiveTab && !isLoading"
              class="d-flex justify-content-center pt-6 mt-4"
            >
              <router-link
                v-if="canCreateTournament"
                :to="{ name: 'TournamentSetup' }"
              >
                <HeraFormBtn
                  class="btn btn-primary create-new-tournament-button"
                  variant="full-width"
                  :label="createButtonLabel"
                />
              </router-link>
            </div>
          </template>
        </HeraDummyList>
      </Paginated>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TournamentCard from '@/components/common/MyTournamentCard';
// import InfiniteScroll from '@/components/common/InfiniteScroll';
import SearchInput from '@/components/molecules/form/HeraSearchInput';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import HorizontalTabs from '@/components/common/HorizontalTabs';
import listMixin from '@/mixins/paginatedLists';
import paramsMixin from '@/mixins/queryParams';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
import { tournamentStatuses } from '@/mixins/tournaments/tournamentStatuses';
import {
  TOURNAMENT_STATUSES,
  DEFAULT_STATUSES_ACTIVE,
} from '@/constants/tournaments';
import CustomFilter from '@/components/form/MultiSelectBox';
import HeraDummyList from '@/components/common/HeraDummyList';
import HeraFormDropdown from '@/components/molecules/form/HeraFormDropdown';
import Paginated from '@/components/common/Paginated';
const tournamentColumns = [
  { id: 'name', label: 'Tournament name' },
  { id: 'status', label: 'Status' },
  { id: 'game', label: 'Game' },
  { id: 'platforms', label: 'Platforms' },
  // { id: 'participants', label: 'Participants' },
  // { id: 'registration_start', label: 'Registration' },
  { id: 'tournament_start', label: 'Start' },
  { id: 'tournament_actions', label: '' },
];
const TABS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  COMPLETED: 'completed',
};
export default {
  name: 'TournamentsList',
  components: {
    TournamentCard,
    HeraDummyList,
    // InfiniteScroll,
    SearchInput,
    HeraFormBtn,
    CustomFilter,
    HorizontalTabs,
    HeraFormDropdown,
    Paginated,
  },
  mixins: [...listMixin, tournamentHelpers, paramsMixin, tournamentStatuses()],
  data: () => ({
    columns: tournamentColumns,
    filters: {},
    sorting: {
      columnId: '',
      desc: false,
    },
    sortBy: null,
    sortByOptions: [
      {
        key: '_web_sort-by_name_a-z',
        label: 'Name (A-Z)',
        value: {
          prop: 'name',
          order: 'asc',
        },
      },
      {
        key: '_web_sort-by_name_z-a',
        label: 'Name (Z-A)',
        value: {
          prop: 'name',
          order: 'desc',
        },
      },
      {
        key: '_web_sort-tournament_start_a-z',
        label: 'Newest first',
        value: {
          prop: 'tournament_start',
          order: 'desc',
        },
      },
      {
        key: '_web_sort-tournament_start_z-a',
        label: 'Oldest first',
        value: {
          prop: 'tournament_start',
          order: 'asc',
        },
      },
    ],
    tournamentId: null,
    tournaments: [],
    fetchedWithoutFilters: false,
    fetchedGamesAndPlatforms: false,
    activeTab: '',
    popoverItems: null,
    optionsPosition: null,
    dummyText: '',
  }),
  computed: {
    ...mapState({
      tournamentsListStoredFilters: state =>
        state.tournamentsAdmin.tournamentsListStoredFilters,
    }),
    ...mapState('my', ['account']),
    ...mapGetters([
      'platforms',
      'games',
      'getGamePropById',
      'getPlatformPropById',
      'hasAccessByPermission',
      'permissions',
    ]),
    multiselectTournamentFilters() {
      if (!this.fetchedGamesAndPlatforms) {
        return {};
      }
      const filters = {
        game: {
          label: this.$t(
            '_web_competitions_tournaments-list-game-filter-label',
            'Game'
          ),
          allPlaceholder: this.$t(
            '_web_competitions_tournaments-list-all-games',
            'All games'
          ),
          options: this.games,
        },
        platform: {
          label: this.$t(
            '_web_competitions_tournaments-list-platform-filter-label',
            'Platform'
          ),
          allPlaceholder: this.$t(
            '_web_competitions_tournaments-list-all-platforms',
            'All platforms'
          ),
          options: this.platforms,
        },
      };
      if (this.activeTab === TABS.ACTIVE) {
        filters.status = {
          label: this.$t(
            '_web_competitions_tournaments-list-status-filter-label',
            'Status'
          ),
          allPlaceholder: this.$t(
            '_web_competitions_tournaments-list-all-statuses',
            'All statuses'
          ),
          options: this.filtersStatuses,
        };
      }
      return filters;
    },
    filtersStatuses() {
      let statuses = this.$lodash.cloneDeep(this.localizedTournamentsStatuses);
      delete statuses.closed;
      delete statuses.archived;
      return statuses;
    },
    hasFilters() {
      return !this.$lodash.isEmpty(this.filters);
    },
    filtersIsDefault() {
      return !!Object.values(this.filters).some(
        filterValue => filterValue && filterValue.length
      );
    },
    canCreateTournament() {
      // TODO TH-8800
      // Hiding a button for a Regional Operator.
      // That has "administrative: all" permission but should not create tournaments (backend problem).
      return this.account.isOrganization
        ? !!this.permissions.createTournament
        : this.hasAccessByPermission('createTournament');
    },
    tabs() {
      return [
        {
          key: TABS.ACTIVE,
          labelStated: this.$t('_web_tournaments_menu-active', 'Active'),
          class: 'flex-grow-1 justify-content-center',
        },
        {
          key: TABS.COMPLETED,
          labelStated: this.$t('_web_tournaments_menu-completed', 'Completed'),
          class: 'flex-grow-1 justify-content-center',
        },
        {
          key: TABS.ARCHIVED,
          labelStated: this.$t('_web_tournaments_menu-archived', 'Archived'),
          class: 'flex-grow-1 justify-content-center',
        },
      ];
    },
    createButtonLabel() {
      return this.fetchedWithoutFilters && !this.tournaments.length
        ? this.$t(
            '_web_competitions_tournaments-list-create-first-btn',
            'Create first tournament'
          )
        : this.$t('_web_competitions_tournaments-list-create-btn', 'Create');
    },
    isActiveTab() {
      return this.activeTab === TABS.ACTIVE;
    },
  },
  created() {
    this.sortBy = this.sortByOptions[2];
    this.pageSize = 18;
    Promise.all([
      //for filters render
      this.$store.dispatch('fetchGames'),
      this.$store.dispatch('fetchPlatforms'),
    ])
      .then(this.initializeFilterValues)
      .then(() => {
        this.fetchedGamesAndPlatforms = true;
        this.setActiveTab();
      })
      .then(this.fetchTournaments)
      .then(() => {
        this.fetchedWithoutFilters = !this.hasFilters;
      });
  },
  methods: {
    ...mapActions([
      'getAdminTournaments',
      'fetchGames',
      'fetchPlatforms',
      'openModal',
      'closeModal',
    ]),
    ...mapMutations(['setTournamentsListFilter']),
    onFilterChange() {
      this.$nextTick(() => {
        this.tournaments = [];
        // this.nextPageToken = undefined;
        this.pageIndex = 0;
        this.setQueryParams({
          ...this.$lodash.cloneDeep(this.filters),
          page: this.pageIndex,
        });
        this.fetchTournaments();
      });
      this.saveFiltersDataToStorage();
    },
    isShowFilter(key) {
      return key === 'status' ? this.activeTab === TABS.ACTIVE : true;
    },
    mapFilters() {
      // let order_by = this.sorting.columnId || null;
      // order_by = order_by && this.sorting.desc ? order_by + ':desc' : order_by;
      let order_by = this.sortBy
        ? `${this.sortBy.value.prop}:${this.sortBy.value.order}`
        : null;
      return {
        order_by,
        ...this.filters,
        status: this.$lodash.isEmpty(this.filters.status)
          ? DEFAULT_STATUSES_ACTIVE
          : this.filters.status,
      };
    },
    mapPlatforms({ platforms }) {
      if (!platforms) {
        return [];
      }
      return platforms.map(id => {
        return this.getPlatformPropById(id, 'logo').uri || null;
      });
    },
    initializeFilterValues() {
      //if filters from url empty
      if (!this.hasFilters) {
        //set stored filters or default filters preset
        this.filters = this.$lodash.cloneDeep(
          this.tournamentsListStoredFilters
        );
      }
    },
    fetchTournaments(newPage) {
      this.pageIndex = newPage;
      return this.getPageData(
        'tournaments',
        this.getAdminTournaments(this.paramsWithPageNumber(this.mapFilters()))
      ).then(() => {
        if (!this.tournaments.length) {
          this.updateDummyText();
        }
      });
    },
    sort(columnId) {
      if (this.sorting.columnId === columnId) {
        this.sorting.desc = !this.sorting.desc;
      } else {
        this.sorting.columnId = columnId;
        this.sorting.desc = false;
      }
      this.onFilterChange();
    },

    columnIconName(columnId) {
      return this.sorting.columnId !== columnId
        ? 'sort'
        : this.sorting.desc
        ? 'sort-dsc'
        : 'sort-asc';
    },

    saveFiltersDataToStorage() {
      this.setTournamentsListFilter(this.filters);
    },
    setActiveTab(tab) {
      if (!tab) {
        this.filters.status &&
        this.filters.status.includes(TOURNAMENT_STATUSES.ARCHIVED)
          ? (this.activeTab = TABS.ARCHIVED)
          : this.filters.status &&
            this.filters.status.includes(TOURNAMENT_STATUSES.CLOSED)
          ? (this.activeTab = TABS.COMPLETED)
          : (this.activeTab = TABS.ACTIVE);
      } else {
        this.activeTab = tab;
        this.filters.status =
          tab === TABS.ARCHIVED
            ? [TOURNAMENT_STATUSES.ARCHIVED]
            : tab === TABS.COMPLETED
            ? [TOURNAMENT_STATUSES.CLOSED]
            : [];
        this.onFilterChange();
      }
    },
    updateDummyText() {
      const emptyStateText = this.$t(
          '_web_competitions_tournaments-all-lists-empty_subheader',
          'Create a tournament. Your players are waiting for you'
        ),
        emptyStateSearchText = this.$t(
          '_web_competitions_tournaments-list-empty_subheader',
          'Check the filters or come back later. Hope you find what you are looking for'
        );
      this.dummyText =
        this.fetchedWithoutFilters && !this.tournaments.length
          ? emptyStateText
          : !this.isActiveTab
          ? ''
          : this.filtersIsDefault
          ? emptyStateSearchText
          : emptyStateText;
    },
  },
};
</script>

<style lang="scss">
.tournament-list-empty {
  height: 50vh;
}
.create-new-tournament-button {
  height: 2rem !important;
}
.tournament-list {
  .filter-table {
    width: 130px;
  }
  table {
    table-layout: fixed;
    width: 100%;
    tr {
      cursor: pointer;
    }
    td,
    th {
      padding: $ph-mini-space $ph-medium-space;
      &:first-child {
        width: 28%;
        > div {
          word-wrap: break-word;
        }
      }
      &:last-child {
        width: 6rem;
      }
    }
    th {
      padding-bottom: $ph-medium-space;
    }
  }
  .create-tournament-button {
    height: 2rem !important;
  }
}
</style>
