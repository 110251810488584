var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative dropdown",class:[{ disabled: _vm.disabled }, _vm.dropdownVariant]},[_c('WrapperFormControl',_vm._b({},'WrapperFormControl',{
      label: _vm.label,
      error: _vm.error,
      tooltip: _vm.tooltip,
      hideLabel: _vm.hideLabel,
      required: _vm.required && !_vm.viewMode,
    },false),[_c('button',{ref:"dropdownActivator",staticClass:"btn btn-sm d-flex flex-row justify-content-between align-items-center dropdown_activator",class:{ 'input-form': _vm.variant === 'input-like', 'is-invalid': _vm.error },attrs:{"slot":"control","type":"button","disabled":_vm.disabled},on:{"click":_vm.togglePopover},slot:"control"},[_c('TextBlock',{staticClass:"text-truncate"},[(_vm.isEmpty)?_c('span',{staticClass:"placeholder-text"},[_vm._v("\n          "+_vm._s(_vm.emptyText)+"\n        ")]):_vm._t("chosen",[_c('span',{staticClass:"chosen-item"},[_vm._v(_vm._s(_vm.dropdownValue))])],{"chosenItem":_vm.dropdownValue})],2),_c('icon',{staticClass:"dropdown-icon h-mr-2 h-ml-3",attrs:{"name":"arrow-s","width":"16"}})],1)]),_c('PopoverMenu',{staticClass:"dropdown-popover w-auto",attrs:{"position":_vm.dropdownPosition,"closest":_vm.$el,"items":_vm.filteredList,"float":['center'],"regard":"top","width":_vm.popoverWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.changeItem(item)}}},[_vm._t("item",[_vm._v("\n          "+_vm._s(item.text)+"\n        ")],null,{ item: item })],2)]}}],null,true)},[(_vm.showFilter)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dropdownFilter),expression:"dropdownFilter",modifiers:{"trim":true}}],staticClass:"form-control shadow-none mb-2 border-top-0 border-left-0 border-right-0 rounded-0",class:_vm.searchIcon,attrs:{"slot":"header","type":"text","placeholder":_vm.filterPlaceholder,"name":"dropdownFilter","autocomplete":"off"},domProps:{"value":(_vm.dropdownFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dropdownFilter=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}},slot:"header"}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }