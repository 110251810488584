<template>
  <ModalBackdrop
    v-model="modalModel"
    v-bind="{ clickOnBackdrop, escKey, variant, closeIcon, containerClass }"
  >
    <component
      :is="activeModal.component"
      v-if="activeModal"
      v-bind="activeModal.props"
      v-on="activeModal.events"
    />
  </ModalBackdrop>
</template>
<script>
/**
 * This component will render a picked modal component via vuex module
 * to use it just call openModal action from vuex with appropriate parameters
 * anywhere in the app
 * or
 * you could call modal adding query parameters to current route
 */
import { mapGetters, mapActions } from 'vuex';
import ModalBackdrop from '@/components/common/ModalBackdrop';

export default {
  name: 'Modal',
  components: {
    ModalBackdrop,
  },
  computed: {
    ...mapGetters([
      'activeModal',
      'modalIsShow',
      'clickOnBackdrop',
      'escKey',
      'variant',
      'closeIcon',
      'containerClass',
    ]),
    modalModel: {
      get() {
        return this.modalIsShow;
      },
      set(isShow) {
        if (isShow) {
          return;
        }

        const { query } = this.$route;
        if (query.modal) {
          this.$router.replace({
            query: {
              ...this.$lodash.pickBy(query, (value, key) => key !== 'modal'),
            },
          });
        } else {
          this.closeModal();
        }
      },
    },
  },
  watch: {
    /**
     * Watcher for routing query changes
     * if there is a modal parameter in query modal will be opened
     * otherwise it will be closed
     * @param {string} modal - modal name (could be undefined)
     * @param {string} closedModal - old modal name (could be undefined)
     */
    '$route.query.modal'(modal, closedModal) {
      if (modal) {
        this.openModal({
          name: modal,
        });
      } else if (closedModal) {
        this.closeModal();
      }
    },
  },
  created() {
    if (this.$route.query.modal) {
      this.openModal({
        name: this.$route.query.modal,
      });
    }
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),
  },
};
</script>
