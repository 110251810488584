<script>
export default {
  name: 'InfiniteLoadBothSides',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    inContainer: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    listName: {
      required: true,
      type: String,
      default: 'items',
    },
    isDisabled: {
      required: true,
      type: Boolean,
      default: true, // not trigger callback by default
    },
    isDisabledBottom: {
      required: false,
      type: Boolean,
      default: true, // not trigger callback by default
    },
    activationGap: {
      type: Number,
      default: 0,
    },
    containerElement: {
      type: Node,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      prevParentHeight: 0,
      listIsChanged: false,
      debounceTestPage: () => {},
      debounceTime: 50, // ms
      relatedElement: null,
    };
  },
  watch: {
    list() {
      this.isLoading = false;
      // this.listIsChanged = !!(this.reverse && this.relatedElement);
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.setDebounceTestPage();
      if (this.inContainer) {
        this.relatedElement = this.containerElement || this.$el.parentElement;
      }
      // todo - TH-7089 use rxjs
      (this.relatedElement || window).addEventListener(
        'scroll',
        this.debounceTestPage
      );
    });
  },
  beforeUpdate() {
    if (this.listIsChanged) {
      this.prevParentHeight =
        this.relatedElement.scrollHeight - this.relatedElement.scrollTop;
    }
  },
  updated() {
    if (this.listIsChanged) {
      this.relatedElement.scrollTop =
        this.relatedElement.scrollHeight - this.prevParentHeight;
      this.listIsChanged = false;
    }
  },
  beforeDestroy() {
    (this.relatedElement || window).removeEventListener(
      'scroll',
      this.debounceTestPage
    );
  },
  methods: {
    setDebounceTestPage() {
      this.debounceTestPage = this.$lodash.debounce(
        this.testPageBottom,
        this.debounceTime
      );
    },
    testPageBottom(e) {
      const $wrapper = this.inContainer && this.relatedElement;
      if (this.reverse) {
        const scrollY = $wrapper ? $wrapper.scrollTop : window.pageYOffset;

        if (+scrollY <= this.activationGap && !this.isDisabled) {
          this.isLoading = true;
          this.$emit('ScrollToTop', e);
        }

        return;
      }

      let viewHeight = $wrapper
        ? $wrapper.clientHeight
        : document.documentElement.clientHeight; //viewport height

      let pageHeight = $wrapper
        ? Math.max(
            $wrapper.scrollHeight,
            $wrapper.offsetHeight,
            $wrapper.clientHeight
          )
        : Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
          );

      const scrollY = $wrapper ? $wrapper.scrollTop : window.pageYOffset;

      if (
        pageHeight - scrollY - viewHeight <= this.activationGap &&
        !this.isDisabled
      ) {
        this.isLoading = true;
        this.$emit('ScrollToBottom', e);

        // this.callback()
      } else if (scrollY === 0 && !this.isDisabledBottom) {
        // check if scrolled to top
        this.isLoading = true;
        this.$emit('ScrollToTop', e);
      }
    },
  },

  render(createElement) {
    return createElement(
      this.tag,
      {
        class: {
          'w-100': true,
          'infinite-wrapper': true,
          'loader-after': true,
          'loader-before': true,
          fetching: this.isLoading,
        },
      },
      [
        this.$scopedSlots.default({
          [this.listName]: this.list || [],
        }),
      ]
    );
  },
};
</script>

<style lang="scss">
$spin-wrap-height: 5rem;
$spin-size: 2rem;
$spin-offset-y: 1.5rem; //calc(math.div($spin-wrap-height, 2) - math.div($spin-size, 2));

%spin-style {
  content: '';
  display: block;
  height: $spin-size;
  width: $spin-size;
  position: absolute;
  left: 50%;
  border: 2px solid transparent;
  border-left-color: $ph-accent;
  border-bottom-color: $ph-accent;
  border-radius: 50%;
  transform: translateX(-50%);
  @include rotate-element;
}

.infinite-wrapper {
  position: relative;

  &.fetching {
    &.loader-after {
      padding-bottom: $spin-wrap-height;
      &:after {
        @extend %spin-style !optional;
        bottom: $spin-offset-y;
      }
    }

    &.loader-before {
      padding-top: $spin-wrap-height;
      &:before {
        @extend %spin-style !optional;
        top: $spin-offset-y;
      }
    }
  }
}
</style>
