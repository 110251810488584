<template>
  <span v-if="tournament.squad_size">
    <span class="mx-1">•</span>
    {{ tournament.squad_size }}vs{{ tournament.squad_size }}
  </span>
</template>

<script>
import { propRequired } from '@/utils/factories';

export default {
  name: 'TournamentCardSquadSize',
  props: {
    tournament: propRequired(Object),
  },
};
</script>
