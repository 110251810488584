<template>
  <div
    class="position-relative dropdown"
    :class="[{ disabled: disabled }, dropdownVariant]"
  >
    <WrapperFormControl
      v-bind="{
        label,
        error,
        tooltip,
        hideLabel,
        required: required && !viewMode,
      }"
    >
      <button
        slot="control"
        ref="dropdownActivator"
        type="button"
        :disabled="disabled"
        :class="{ 'input-form': variant === 'input-like', 'is-invalid': error }"
        class="btn shop-drp-btn btn-sm d-flex flex-row justify-content-between align-items-center dropdown_activator"
        @click="togglePopover"
      >
        <TextBlock class="text-truncate">
          <span v-if="isEmpty || isListEmpty" class="placeholder-text">
            {{ emptyText }}
          </span>
          <slot v-else :chosenItem="dropdownValue" name="chosen">
            <span class="chosen-item">{{ dropdownValue }}</span>
          </slot>
        </TextBlock>
        <icon name="chevron-down-small" width="16" class="ml-2 mr-2" />
      </button>
    </WrapperFormControl>
    <PopoverMenu
      :position="dropdownPosition"
      :closest="$el"
      :items="filteredList"
      :float="['center']"
      regard="bottom"
      :width="popoverWidth"
      class="dropdown-popover w-auto"
    >
      <input
        v-if="showFilter"
        slot="header"
        v-model.trim="dropdownFilter"
        type="text"
        class="form-control shadow-none mb-2 border-top-0 border-left-0 border-right-0 rounded-0"
        :class="searchIcon"
        :placeholder="filterPlaceholder"
        name="dropdownFilter"
        autocomplete="off"
      />
      <template slot-scope="{ item }">
        <span @click="changeItem(item)">
          <slot name="item" v-bind="{ item }">
            {{ item.text }}
          </slot>
        </span>
      </template>
    </PopoverMenu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import { prop } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
import PopoverMenu from '@/components/popover/Menu';

export default {
  name: 'ShopDropdown',
  components: {
    icon,
    TextBlock,
    WrapperFormControl,
    PopoverMenu,
  },

  props: {
    viewMode: prop(Boolean),
    variant: {
      type: String,
      default: 'default',
      validator: val => {
        return [
          'default',
          'input-like',
          'button',
          'transparent',
          'view',
        ].includes(val);
      },
    },
    disabled: prop(Boolean),
    required: prop(Boolean),
    emptyOption: prop(Boolean),
    emptyText: prop(String, 'Open to select...'),
    list: {
      type: [Array, Object],
      required: true,
    },
    error: prop(String),
    value: {
      type: null,
      default: '',
    },
    label: prop(String),
    showFilter: prop(Boolean),
    couldBeEmpty: prop(Boolean),
    searchIcon: prop(String),
    filterPlaceholder: prop(String, 'Type to filter list...'),
    tooltip: prop(String),
    hideLabel: prop(Boolean),
    popoverWidth: prop(String, 'auto'),
  },

  data() {
    return {
      dropdownPosition: null,
      dropdownFilter: '',
    };
  },

  computed: {
    ...mapGetters(['isRTL']),
    dropdownValue: {
      get() {
        if (
          typeof this.value === 'object' &&
          this.value.final_price &&
          this.value.currency &&
          this.value.value
        ) {
          if (this.isRTL) {
            return `${this.value.value} ${this.value.final_price} - ${
              this.value.currency
            } `;
          } else {
            return ` ${this.value.value} - ${this.value.currency} ${
              this.value.final_price
            }`;
          }
        } else if (
          typeof this.value === 'object' &&
          this.value.final_price &&
          this.value.currency
        ) {
          return `${this.value.currency} ${this.value.final_price}`;
        } else {
          return this.$lodash.get(this, 'value.text', this.value);
        }
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isListEmpty() {
      return !this.list || (Array.isArray(this.list) && this.list.length === 0);
    },
    isEmpty() {
      return !this.value;
    },
    filteredList() {
      const filterLowerCase = this.dropdownFilter.toLowerCase();
      return this.list.filter(item => {
        return this.showFilter && this.dropdownFilter
          ? (item.nameLower || item.name.toLowerCase()).includes(
              filterLowerCase
            )
          : true;
      });
    },
    dropdownVariant() {
      return this.viewMode ? 'dropdown--view' : `dropdown--${this.variant}`;
    },
  },
  methods: {
    changeItem(item) {
      this.dropdownValue = item || this.emptyText;
      this.dropdownPosition = null;
    },
    togglePopover() {
      this.dropdownPosition = this.dropdownPosition
        ? null
        : this.$refs.dropdownActivator;
    },
  },
};
</script>

<style lang="scss">
.chosen-item {
}
.dropdown {
  transition: all 0.3s ease-in-out;
  .wrapper-control {
    .wrapper-control-label {
      label {
        color: #a9a9bc !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.18px;
      }
    }
  }
  .dropdown_activator {
    outline: none !important;
    box-shadow: none !important;
    background-color: #181c25;
    border-radius: 8px;
    width: 168px;
    height: 64px;
    padding: 12px;
  }
  &--input-like {
    .dropdown_activator {
      height: $input-height;
      width: auto;
    }
  }
  &--transparent {
    .dropdown_activator {
      background-color: transparent;
    }
  }
  &--button {
    .dropdown_activator {
      background-color: #181c25;
      border-radius: 8px;
      width: 168px;
      height: 64px;
      padding: 12px;
    }
  }
  .chevron-divider {
    span {
      display: inline-flex;
      gap: 3px;
    }
  }
  .float-dropbox {
    min-width: 160px;
    background-color: rgba(44, 51, 68, 1) !important;
  }
  .dropbox-menu li > :not(hr) {
    height: 45px;
    padding: 12px;
    line-height: 1.5;
  }
  .dropbox-menu li > :not(hr):hover {
    background-color: rgba(64, 74, 99, 1) !important;
  }
}
</style>
<style lang="scss">
.custom-scrollbar {
  & {
    scrollbar-width: thin;
    scrollbar-color: $ph-secondary-text transparent;
  }
}
</style>
<story
  options="{
      panelPosition: 'right'
    }"
  group="Common|Molecules/Form"
  name="HeraFormDropdown"
  knobs="{
      viewMode: {
        default: boolean('viewMode', false)
      },
      variant: {
        default: select('variant', ['default', 'input-like', 'button', 'transparent', 'view'], 'input-like')
      },
      disabled: {
        default: boolean('disabled', false)
      },
      required: {
        default: boolean('required', false)
      },
      emptyOption: {
        default: boolean('emptyOption', false)
      },
      emptyText: {
        default: text('emptyText', 'emptyText')
      },
      value: {
        default: text('value', 'value')
      },
      error: {
        default: text('error', '')
      },
      couldBeEmpty: {
        default: boolean('couldBeEmpty', false)
      },
      showFilter: {
        default: boolean('showFilter', false)
      },
      label: {
        default: text('label', 'label')
      },
      tooltip: {
        default: text('tooltip', '')
      },
      searchIcon: {
        default: text('searchIcon', '')
      },
      filterPlaceholder: {
        default: text('filterPlaceholder', '')
      },
      hideLabel: {
        default: boolean('showLabel', false)
      },
      popoverWidth: {
        default: text('popoverWidth', '100%')
      }
    }"
>
    <HeraFormDropdown v-bind="{
      viewMode,
      variant,
      disabled,
      required,
      emptyOption,
      emptyText,
      value,
      error,
      couldBeEmpty,
      showFilter,
      label,
      tooltip,
      searchIcon,
      filterPlaceholder,
      hideLabel,
      popoverWidth}"
      :list="[{text: 'aaa'}, {text: 'bbb'}]"
      @input="e=>action('input', e)"
      @change="e=>action('change', e)"
      @itemChanged="e=>action('itemChanged', e)"
    />
  </story>
