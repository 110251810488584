<template>
  <span class="badge-hera d-inline-flex align-items-center" :class="variant">
    <span v-if="text">{{ text }}</span>
    <slot></slot>
  </span>
</template>

<script>
import { prop } from '@/utils/factories';

export default {
  name: 'HeraBadge',
  props: {
    variant: prop(String),
    text: prop([String, Number]),
  },
};
</script>

<style lang="scss">
.badge-hera {
  line-height: 140%;
  text-transform: uppercase;
  // padding: $ph-mini-space $ph-small-space;
  // background-color: $ph-secondary-text;
  font-size: 12px;
  font-weight: bold;

  &.purple {
    color: $hr-bg-accent;
  }
  &.green {
    color: $hr-bg-confirm;
  }
  &.yellow {
    color: yellow;
  }
  &.blue {
    color: #4667dc;
  }

  &.orange {
    color: #fd9f18;
  }
  &.gray {
    color: #717171;
  }
  &.red {
    color: $hr-bg-error;
  }
  &.inverse {
    border: none;
    color: $ph-bg;
  }
  &.outlined {
    background-color: transparent;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="HeraBadge"
  knobs="{
    text: {
      default: text('text', 'bage')
    },
    variant: {
      default: select('variant', ['default', 'orange', 'green', 'blue', 'bg-orange'], 'default')
    }
  }"
>
  <HeraBadge v-bind="{ variant, text }"/>
</story>
