<template>
  <button
    :id="id || $lodash.uniqueId('button_')"
    type="button"
    class="text-truncate btn hera-btn"
    :class="[`hera-${variant}-btn`, { 'is-loading': loading }]"
    :style="contentColor ? { color: contentColor } : null"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot name="prepend"></slot>
    <icon
      v-if="icon && !loading"
      :key="icon"
      class="icon mx-1"
      :name="icon"
      :width="`${iconSize}`"
      fill="white"
      :rotate="iconRotate"
    />
    <slot
      ><span v-if="label" class="mx-1">{{ label }}</span></slot
    >
    <slot name="append"></slot>
  </button>
</template>
<script>
const BUTTON_VARIANTS = [
  'default',
  'filter-clear',
  'full-width',
  'accept-rules',
];
import icon from '@/components/atoms/Icon';
export default {
  components: {
    icon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    contentColor: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: '18',
    },
    iconRotate: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      default: 'default',
      validator(val) {
        return !val || BUTTON_VARIANTS.includes(val);
      },
    },
  },
};
</script>
<style lang="scss">
button.hera-btn {
  position: relative;
  outline: none;
  border: none;
  border-radius: $ph-large-radius;
  background: transparent;
  color: $ph-primary-text;
  border-radius: 5px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-size: $ph-body-font-size; // 0.875rem == 14px
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    color: $ph-primary-text;
  }

  &:focus {
    box-shadow: none;
  }

  &.is-loading {
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1rem;
      width: 1rem;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
      border: 2px solid;
      border-color: transparent transparent $ph-light-gray $ph-light-gray;
      border-radius: 50%;
      @include rotate-element;
    }
  }
  &.btn-primary {
    // @include primary-background-gradient;
    background: var(--ph-main);
    &.is-loading::after {
      border-color: transparent transparent $white $white !important;
    }
  }
  &.btn-primary-new {
    background-color: $new-bg-base;
    border: 1px solid;
    border-color: $new-bg-neutral;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    > span {
      margin: 0 !important;
    }
  }
  &.btn-primary-new:hover {
    color: $new-fg-primary;
  }
  &.btn-secondary-new {
    background-color: transparent;
    border: 1px solid;
    color: #8b8c8f;
    border-color: $new-bg-neutral;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    &.btn-secondary-new:hover {
      color: $new-fg-primary;
    }
  }
  &.btn-primary-simple {
    @include primary-background-gradient;
    &.is-loading::after {
      border-color: transparent transparent $white $white !important;
    }
  }
  &.btn-accent,
  &.btn-accent-simple {
    background-color: $ph-accent;
    &.is-loading::after {
      border-color: transparent transparent $white $white !important;
    }
  }
  &.btn-accent-border {
    background-color: transparent;
    border: 1px solid var(--ph-accent);
  }
  &.btn-error-border {
    background-color: transparent;
    border: 1px solid var(--ph-error);
  }
  &.btn-success,
  &.btn-success-simple {
    background-color: $ph-green;
    &.is-loading::after {
      border-color: transparent transparent $white $white !important;
    }
  }
  &.btn-accent-simple,
  &.btn-primary-simple,
  &.btn-secondary-simple,
  &.btn-success-simple,
  &.hera-secondary-simple-btn {
    border-radius: $ph-medium-radius;
  }
  &.hera-filter-clear-btn {
    border: none;
    border-radius: 0;
    border-bottom-left-radius: $ph-medium-radius;
    border-bottom-right-radius: $ph-medium-radius;
    background-color: $ph-input-bg;
  }
  &.hera-full-width-btn {
    padding: 0 5.625rem;
  }
  &.btn-accept-rules {
    text-transform: uppercase;
    background-color: $hr-bg-modal-btn;
    color: $hr-text-accent;
  }
  &.btn-post-new {
    background-color: #5a58f2;
    border-radius: 8px !important;
    width: 87px !important;
    height: 48px !important;
    padding: 0 !important;
  }
  &.btn-post-new:disabled {
    background-color: #282e3e;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="HeraFormBtn"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    label: {
      default: text('label', 'label for button')
    },
    name: {
      default: text('Name', 'name')
    },
    loading: {
      default: boolean('loading', false)
    },
    contentColor: {
      default: color('contentColor', '#ffffff')
    },
    disabled: {
      default: boolean('disabled', false)
    },
    id: {
      default: text('id', 'id')
    },
    variant: {
      default: text('variant', '')
    },
    cssClass: {
      default: select('class', ['btn-primary', 'btn-secondary', 'btn-primary-simple', 'btn-secondary-simple'], 'btn-primary')
    }
  }"
>
  <div>
  <HeraFormBtn
    v-bind="{class: cssClass, name, loading, contentColor, disabled, id, variant, label}"
    @click="val => action('Click event data', val)"/>
  </div>
</story>
