<template>
  <Panel
    :variant="['rounded', 'transparent', 'stroked', 'stroked-yellow']"
    class="d-flex align-items-center info-panel"
    :class="{ 'has-addons': $slots.addons }"
  >
    <Icon
      v-if="!hideIcon"
      name="info"
      :fill="$scss.colors.yellowBg"
      width="36"
      class="h-mr-4 info-panel__icon align-self-start"
    />
    <div class="h-mr-4 info-panel__text d-flex align-items-center">
      <slot>{{ text }}</slot>
    </div>
    <div v-if="!hideAddons" class="flex-shrink-0 info-panel__addons d-flex">
      <slot name="addons"></slot>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import Icon from '@/components/atoms/Icon';
import { prop } from '@/utils/factories';
export default {
  name: 'InfoPanel',
  components: {
    Panel,
    Icon,
  },
  props: {
    text: prop(String),
    hideIcon: prop(Boolean),
    hideAddons: prop(Boolean),
  },
};
</script>

<style lang="scss">
.info-panel {
  padding: $ph-medium-space;
  &__addons,
  &__text {
    flex-basis: 50%;
  }
  &.has-addons {
    .info-panel__icon,
    .info-panel__text,
    .info-panel__addons {
      min-height: 40px;
    }
  }
}
</style>
