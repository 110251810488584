<template>
  <div>
    <PopoverMenu
      :position="position"
      :float="['right']"
      :items="popoverItems"
      :closest="closest"
      auto-close
      @toggle="$emit('toggle', $event)"
    >
      <span slot-scope="{ item }" @click="item.action">{{ item.label }}</span>
    </PopoverMenu>
  </div>
</template>

<script>
import PopoverMenu from '@/components/popover/Menu';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: { PopoverMenu },

  props: {
    post: {
      type: Object,
      default: null,
    },
    position: {
      type: Node,
      default: null,
    },
    closest: {
      type: Node,
      default: null,
    },
    owner: {
      type: String,
      default: '',
      required: false,
    },
  },

  data() {
    return {
      isDeleting: false,
      isBlocking: false,
    };
  },

  computed: {
    ...mapState('my', ['account', 'accountId']),
    ...mapState('current', {
      currentAccount: 'account',
      currentAccountId: 'accountId',
    }),
    ...mapGetters(['permissions']),
    ...mapGetters('my/clans', ['isMemberOfAClan', 'hasClans', 'getClan']),

    popoverItems() {
      const items = [];
      if (this.canDeletePost) {
        /*        if (this.post && !this.post.poll) {
          items.push({
            label: this.$t('_web_post_edit-comment', 'Edit comment'),
            action: this.openEditPost,
          });
        }*/
        items.push({
          label: this.$t('_web_post_delete-comment', 'Delete comment'),
          action: this.openDeleteConfirm,
        });
      }
      if (!this.isMyComment && this.post) {
        items.push({
          label: this.$t('_web_post_block-user', 'Block {name}', {
            name: this.$filters.displayName(this.post.author),
          }),
          action: this.openBlockConfirm,
        });
      }
      /*
      if (!this.isMyComment) {
        items.push({
          label: this.$t('_web_post_report', 'Report'),
          action: this.report,
        });
      }
      */
      return items;
    },

    isMyComment() {
      const postAuthorId = this.post
        ? this.post.author.id || this.post.author.login
        : null;
      return this.accountId === postAuthorId;
    },

    isMyCommentInClan() {
      const { post } = this;
      if (post) {
        const postClanId = post.author.id || post.author.login;
        const clan = this.getClan(postClanId);
        return (
          this.hasClans && this.isMemberOfAClan(postClanId) && clan.canManage
        );
      }

      return false;
    },

    canDeletePost() {
      return (
        this.permissions.fullPermissions ||
        this.isMyComment ||
        this.isMyCommentInClan ||
        this.owner === this.accountId
      );
    },
  },

  methods: {
    ...mapActions([
      'deleteComment',
      'blockAccount',
      'postComplaint',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    openEditPost() {
      this.$emit('edit', this.post);
    },
    openDeleteConfirm() {
      return this.openModal({
        name: 'HeraConfirm',
        props: {
          title: this.$t(
            '_web_profile_delete_comment_request',
            'Are you sure you want to delete this comment?'
          ),
          cancelButtonText: this.$t('_web_post_delete-cancel', 'Cancel'),
          confirmButtonText: this.$t('_web_post_delete-confirm', 'Yes, delete'),
          isLoading: this.isDeleting,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.confirmRemoveComment,
        },
        options: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    openBlockConfirm() {
      return this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_profile_block_user_request',
            'Are you sure you want to block {name}? All content from this account will be hidden',
            {
              name: this.$filters.displayName(this.post.author),
            }
          ),
          cancelButtonText: this.$t('_web_post_delete-cancel', 'Cancel'),
          confirmButtonText: this.$t(
            '_web_post_block-confirm',
            'Yes, block him'
          ),
          isLoading: this.isBlocking,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.blockAuthor,
        },
        options: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },

    confirmRemoveComment() {
      this.isDeleting = true;
      this.deleteComment(this.post.id)
        .then(() => {
          this.closeModal();
          this.isDeleting = false;
          this.$emit('deleted', this.post);
        })
        .catch(this.errorNotify);
    },
    blockAuthor() {
      this.isBlocking = true;
      this.blockAccount({
        accountType: this.post.author.type,
        accountId: this.post.author.login || this.post.author.id,
      })
        .then(() => {
          this.closeModal();

          this.isBlocking = false;
          this.$emit('authorBlocked');
        })
        .catch(this.errorNotify);
    },

    report() {
      this.postComplaint({ entityId: this.post.id, entityType: 'post' }).catch(
        this.errorNotify
      );
    },
  },
};
</script>
