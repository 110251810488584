<template>
  <Panel
    class="edit-results-modal position-relative d-inline-flex flex-column"
    :variant="['default', 'rounded', 'half-paddings']"
  >
    <template v-if="editMatchModel">
      <Panel
        :key="`${editMatchModel.id}-info`"
        :variant="['transparent']"
        class="p-0 mb-0 d-flex align-items-center justify-content-between position-relative"
      >
        <TextBlock
          v-if="editMatchModel.battle_id"
          :size="0.75"
          :variant="'secondary'"
          :line-height="1"
          class="p-0 mt-0 battle-id-label"
        >
          {{
            $t('_web_tournament_battle_id', '# {match}', {
              match: editMatchModel.index + 1,
            })
          }}
          <span class="mx-3">|</span>{{ roundLabel }}
        </TextBlock>
        <StatusLabel
          :status="editMatchModel.status"
          class="px-4 py-3 position-absolute flex-grow-0 mx-auto"
          :style="{
            left: 0,
            right: 0,
            maxWidth: '80px',
          }"
        />
        <div class="flex-shrink-0">
          <Icon
            name="calendar"
            width="20"
            :fill="$scss.colors.secondaryTextBg"
            class="h-mr-3"
          />
          <TextBlock :size="0.75" :variant="'secondary'" :line-height="1"
            >{{
              editMatchModel.date_start | localDate($times.DATE_SUBMED)
            }},</TextBlock
          >
          <TextBlock
            class="h-ml-3"
            :size="0.75"
            :variant="'secondary'"
            :line-height="1"
            >{{
              editMatchModel.date_start | localDate($times.TIME_SIMPLE)
            }}</TextBlock
          >
          <TextBlock :variant="'secondary'" :size="0.75" :line-height="1"
            >&nbsp;&ndash;&nbsp;</TextBlock
          >
          <TextBlock :variant="'secondary'" :size="0.75" :line-height="1">{{
            editMatchModel.date_end | localDate($times.TIME_SIMPLE)
          }}</TextBlock>
        </div>
      </Panel>
      <div class="d-flex align-items-center justify-content-center mt-5 mb-4">
        <div class="mw-50">
          <table class="table hera-table m-0 px-0">
            <thead>
              <tr>
                <th class="py-0 h-pl-0 h-pr-5">
                  <TextLabel :text="$t('_web_battle_name-th-label', 'Name')" />
                </th>
                <!-- <template v-if="editMatchModel.number_of_rounds > 1"> -->
                <template
                  v-for="(round, index) in editMatchModel.number_of_rounds"
                >
                  <th
                    :key="index + '-header'"
                    class="py-0 results-column"
                    :class="{
                      'px-5': editMatchModel.number_of_rounds < 2,
                      'h-pl-5 h-pr-3':
                        index === 0 && editMatchModel.number_of_rounds > 1,
                      'h-pr-5 h-pl-3':
                        index === editMatchModel.number_of_rounds - 1 &&
                        editMatchModel.number_of_rounds > 1,
                      'px-3':
                        index !== 0 &&
                        index !== editMatchModel.number_of_rounds - 1 &&
                        editMatchModel.number_of_rounds > 1,
                    }"
                  >
                    <div class="text-center">
                      <TextLabel
                        :text="
                          $t(
                            '_web_battle_match-game-th-label',
                            'Game {index}',
                            {
                              index: index + 1,
                            }
                          )
                        "
                      />
                    </div>
                  </th>
                  <th
                    v-if="isRoundConflicted(index)"
                    :key="index + '-header-disputed'"
                    class="px-0"
                  ></th>
                </template>
                <!-- </template> -->
                <th class="text-center px-5 py-0 summary-column">
                  <TextLabel
                    :text="$t('_web_battle_summary-th-label', 'Match result')"
                  />
                </th>
                <th class="text-left h-pl-5 h-pr-0 py-0 set-as-winner-column">
                  <TextLabel
                    :text="
                      $t(
                        '_web-battle-set-as-winner-links-label',
                        'Set as winner'
                      )
                    "
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="index in [1, 2]" :key="`player-${index}`">
                <td class="py-0 h-pl-0 align-top h-pr-5">
                  <div class="d-flex flex-nowrap">
                    <PlayerItem
                      class="p-0 flex-grow-1 flex-shrink-0"
                      :player="editMatchModel[`player_${index}`]"
                      hide-checkin
                      hide-score
                      :avatar-size="48"
                      :player-index="index"
                      :is-profile="isSingle"
                      :chat-with="editMatchModel[`captain_${index}`]"
                      :is-ready-for-battle="
                        !editMatchModel.is_detained ||
                          editMatchModel[`player_${index}_ready`]
                      "
                      :is-empty="editMatchModel[`player_empty_${index}`]"
                    />
                  </div>
                </td>
                <!-- <template v-if="editMatchModel.number_of_rounds > 1"> -->
                <template
                  v-for="(round, roundIndex) in editMatchModel.number_of_rounds"
                >
                  <td
                    :key="roundIndex + '-cell'"
                    class="text-center align-top"
                    :class="{
                      'px-5': editMatchModel.number_of_rounds < 2,
                      'h-pl-5 h-pr-3':
                        roundIndex === 0 && editMatchModel.number_of_rounds > 1,
                      'h-pr-5 h-pl-3':
                        roundIndex === editMatchModel.number_of_rounds - 1 &&
                        editMatchModel.number_of_rounds > 1,
                      'px-3':
                        roundIndex !== 0 &&
                        roundIndex !== editMatchModel.number_of_rounds - 1 &&
                        editMatchModel.number_of_rounds > 1,
                    }"
                  >
                    <div class="d-inline-flex flex-column">
                      <div
                        class="d-flex align-items-center mb-4 justify-content-center"
                      >
                        <SingleScore
                          :class="{
                            disabled: manualMode,
                          }"
                          :value="
                            isRoundConflicted(roundIndex)
                              ? '-'
                              : $lodash.isNumber(
                                  editMatchModel.battleInfo[
                                    `player_1_match_results`
                                  ][roundIndex][`player_${index}_scored`]
                                )
                              ? editMatchModel.battleInfo[
                                  `player_1_match_results`
                                ][roundIndex][`player_${index}_scored`]
                              : editMatchModel.battleInfo[
                                  `player_2_match_results`
                                ][roundIndex][`player_${index}_scored`]
                          "
                          :has-errors="isRoundConflicted(roundIndex)"
                          :edit-mode="!manualMode && canEdit"
                          @update="setScores($event, index, roundIndex)"
                        />
                      </div>
                      <MatchAttachments
                        class="d-flex align-items-center justify-content-center"
                        :value="
                          editMatchModel.battleInfo[
                            `player_${index}_match_results`
                          ][roundIndex]
                        "
                      />
                    </div>
                  </td>
                  <td
                    v-if="isRoundConflicted(roundIndex)"
                    :key="roundIndex + '-cell-disputed'"
                    class="px-0 align-top"
                  >
                    <TextBlock
                      tag="div"
                      variant="secondary"
                      :size="0.75"
                      class="mt-2 pt-2"
                    >
                      {{
                        editMatchModel.battleInfo[
                          `player_${index}_match_results`
                        ][roundIndex][`player_1_scored`]
                      }}<span class="mx-2">:</span
                      >{{
                        editMatchModel.battleInfo[
                          `player_${index}_match_results`
                        ][roundIndex][`player_2_scored`]
                      }}
                    </TextBlock>
                  </td>
                </template>
                <!-- </template> -->
                <td class="px-5 align-top summary-column">
                  <div
                    v-if="!isMatchCancelled"
                    class="d-flex flex-column align-items-center"
                  >
                    <SingleScore
                      :value="getPlayerScore(editMatchModel, index)"
                      class="d-inline-flex mb-4"
                    />
                    <!-- <MatchAttachments
                      v-if="editMatchModel.number_of_rounds < 2"
                      class="d-flex align-items-center justify-content-center"
                      :value="
                        editMatchModel.battleInfo[
                          `player_${index}_match_results`
                        ][0]
                      "
                    /> -->
                  </div>
                </td>
                <td class="h-pl-5 h-pr-0 align-center set-as-winner">
                  <label
                    class="d-flex align-items-center justify-content-center"
                    :class="{
                      disabled:
                        (manualMode && techWinIndex !== index) || !canEdit,
                    }"
                  >
                    <Checkbox
                      :checked="techWinIndex === index"
                      class="h-mr-2"
                      @change="$event => onSetTechWin($event, index)"
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="controls d-flex justify-content-center">
        <HeraFormBtn
          class="btn btn-secondary-simple"
          :label="$t('_web_tournament_discard_modal-cancel-btn', 'Cancel')"
          @click="goToTableView"
        />
        <HeraFormBtn
          :disabled="isSaveDisabled || !canEdit"
          class="btn btn-sm btn-accent-simple h-ml-4"
          :class="{
            disabled: isSaveDisabled || !canEdit,
          }"
          :is-loading="isLoading"
          :label="
            $t('_web_tournaments-tableview_batle-save-btn', 'Save results')
          "
          @click="saveBattleResults"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Panel from '@/components/atoms/Panel';
import { BATTLE_STATUSES } from '@/constants/battles';
import { getPlayerScore } from '@/views/tournaments/utils/players';
import PlayerItem from '@/components/tournaments/playerItem.vue';
import { prop } from '@/utils/factories';
import StatusLabel from '@/components/molecules/StatusLabel';
import TextBlock from '@/components/atoms/TextBlock';
import TextLabel from '@/components/atoms/TextLabel';
import Icon from '@/components/atoms/Icon';
import MatchAttachments from '@/components/tournaments/MatchAttachments.vue';
import matchHelper from '@/views/tournaments/management/brackets/mixins/matchHelper';
import loadingHelper from '@/mixins/isLoading';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import SingleScore from '@/components/tournaments/SingleScore.vue';
import Checkbox from '@/components/atoms/form/Checkbox';
import roundNameHelper from '@/views/tournaments/mixins/regularRoundName';
export default {
  name: 'EditResultsModal',
  components: {
    Panel,
    PlayerItem,
    StatusLabel,
    TextBlock,
    Icon,
    TextLabel,
    HeraFormBtn,
    MatchAttachments,
    SingleScore,
    Checkbox,
  },
  mixins: [matchHelper, loadingHelper, roundNameHelper],
  props: {
    roundName: prop(String),
    // canEdit: prop(Boolean),
    match: {
      type: Object,
      default: () => ({
        date_end: null,
        date_start: null,
        id: '',
        index: 0,
        next_match_id: '',
        next_match_index: 0,
        player_1: {},
        player_1_score: null,
        player_2: {},
        player_2_score: null,
        round: 0,
        isLoading: false,
      }),
    },
  },
  data() {
    return {
      isSaveDisabled: false,
      techWinIndex: null,
      manualMode: false,
      editMatchModel: null,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournamentStage',
      'hasMatchTieResults',
      'hasMatchTieGame',
    ]),
    isMatchCancelled() {
      return this.editMatchModel.status === BATTLE_STATUSES.cancelled;
    },
    isSingle() {
      return (
        this.editMatchModel &&
        this.editMatchModel.player_1 &&
        this.editMatchModel.player_1.type !== 'clan'
      );
    },
    roundLabel() {
      return this.roundName || this.getMatchRoundName(this.editMatchModel);
    },
    canEdit() {
      return this.canEditMatchResult(this.editMatchModel);
    },
  },
  watch: {
    match() {
      this.unsetLoading();
    },
  },
  created() {
    if (this.match.id) {
      this.editMatchModel = this.$lodash.cloneDeep(this.match);
      this.initTempMatchResults({
        battle_id: this.match.battle_id,
        player_1: this.match.battleInfo.player_1_match_results,
        player_2: this.match.battleInfo.player_2_match_results,
      });
    } else {
      const { grid } = this.$route.query;
      let tempMatch;
      this.getCompetitionMatches({
        competitionID: this.competitionID,
        queryParams: {
          round: this.$route.params.roundID,
          from: this.$route.params.matchIndex,
          to: this.$route.params.matchIndex,
          ...(grid ? { grid } : null),
        },
      })
        .then(matches => matches.length && matches[0])
        .then(match => {
          tempMatch = match;
          return this.getBattleResults({
            rounds: match.number_of_rounds,
            battleID: match.battle_id,
          });
        })
        .then(response => ({
          ...tempMatch,
          battleInfo: {
            ...response.match_results,
            battle_id: tempMatch.battle_id,
            number_of_rounds: tempMatch.number_of_rounds,
          },
        }))
        .then(match => {
          this.editMatchModel = match;
          this.initTempMatchResults({
            battle_id: match.battle_id,
            player_1: match.battleInfo.player_1_match_results,
            player_2: match.battleInfo.player_2_match_results,
          });
        })
        .catch(this.errorNotify);
    }
  },
  methods: {
    ...mapMutations(['setTempMatchResults', 'initTempMatchResults']),
    ...mapActions(['getCompetitionMatches', 'getBattleResults', 'errorNotify']),
    getPlayerScore,
    isRoundConflicted(index) {
      return (
        (!this.isMatchFinished(this.match) &&
          (this.editMatchModel.battleInfo.player_1_match_results[index]
            .player_1_scored !== null &&
            this.editMatchModel.battleInfo.player_2_match_results[index]
              .player_1_scored !== null &&
            this.editMatchModel.battleInfo.player_1_match_results[index]
              .player_1_scored !==
              this.editMatchModel.battleInfo.player_2_match_results[index]
                .player_1_scored)) ||
        (this.editMatchModel.battleInfo.player_1_match_results[index]
          .player_2_scored !== null &&
          this.editMatchModel.battleInfo.player_2_match_results[index]
            .player_2_scored !== null &&
          this.editMatchModel.battleInfo.player_1_match_results[index]
            .player_2_scored !==
            this.editMatchModel.battleInfo.player_2_match_results[index]
              .player_2_scored)
      );
    },
    goToTableView() {
      const params = this.$lodash.cloneDeep(this.$route.params);
      delete params.match;
      delete params.matchIndex;
      delete params.roundName;
      this.$router.push({
        name: 'BlockBracketsTable',
        params,
        query: {
          ...this.$route.query,
          scrollTo: this.editMatchModel.id,
        },
      });
    },
    setScores(score, playerNumber, roundIndex) {
      const {
        player_1_scored,
        player_2_scored,
        round,
      } = this.editMatchModel.battleInfo[
        `player_${playerNumber}_match_results`
      ][roundIndex];
      const scores = {
        player_1_scored,
        player_2_scored,
        round,
        [`player_${playerNumber}_scored`]: score,
      };
      const hasTieGame =
        scores.player_1_scored === scores.player_2_scored &&
        scores.player_1_scored !== null &&
        scores.player_2_scored !== null;
      if (
        hasTieGame &&
        !this.currentTournamentStage.block_configuration.tie_game
      ) {
        this.errorNotify({
          message: this.$t(
            '_web_tournaments-tie-game-not-allowed',
            'Tie results are not allowed for this competition'
          ),
          inModal: true,
          yesText: this.$t('_web_form_error_confirm-yes', 'OK'),
        });
        this.isSaveDisabled = true;
        return;
      }
      this.$set(
        this.editMatchModel.battleInfo.player_1_match_results,
        roundIndex,
        {
          ...this.editMatchModel.battleInfo.player_1_match_results[roundIndex],
          ...scores,
        }
      );
      this.$set(
        this.editMatchModel.battleInfo.player_2_match_results,
        roundIndex,
        {
          ...this.editMatchModel.battleInfo.player_2_match_results[roundIndex],
          ...scores,
        }
      );
      const gamesToWin = Math.ceil(this.editMatchModel.number_of_rounds / 2);
      const hasTieMatch = this.editMatchModel.battleInfo.player_1_match_results.reduce(
        (acc, results) => {
          const newP1 =
            acc.p1 + +(results.player_1_scored > results.player_2_scored);
          const newP2 =
            acc.p2 + +(results.player_2_scored > results.player_1_scored);

          return {
            p1: newP1,
            p2: newP2,
            tie:
              newP1 <= gamesToWin &&
              newP2 <= gamesToWin &&
              newP1 === newP2 &&
              results.player_1_scored !== null &&
              results.player_2_scored !== null,
          };
        },
        { p1: 0, p2: 0, tie: false }
      ).tie;
      if (
        hasTieMatch &&
        !this.currentTournamentStage.block_configuration.tie_match
      ) {
        this.errorNotify({
          message: this.$t(
            '_web_tournaments-tie-match-not-allowed',
            'Tie match are not allowed for this competition'
          ),
          inModal: true,
          yesText: this.$t('_web_form_error_confirm-yes', 'OK'),
        });
        this.isSaveDisabled = true;
        return;
      }

      if (
        this.$lodash.isNumber(scores.player_1_scored) &&
        this.$lodash.isNumber(scores.player_2_scored)
      ) {
        //set results temp for match round
        this.setTempMatchResults({
          player_1: scores,
          player_2: scores,
          round: scores.round,
          battle_id: this.editMatchModel.battle_id,
        });
        this.isSaveDisabled = false;
      }
    },
    saveBattleResults() {
      if (this.manualMode) {
        this.setTechWinner();
        return;
      }
      this.setLoading();
      const hasTieGame = this.hasMatchTieGame(this.editMatchModel.battle_id);

      if (
        hasTieGame &&
        !this.currentTournamentStage.block_configuration.tie_game
      ) {
        this.errorNotify({
          message: this.$t(
            '_web_tournaments-tie-game-not-allowed',
            'Tie results are not allowed for this competition'
          ),
          inModal: true,
          yesText: this.$t('_web_form_error_confirm-yes', 'OK'),
        });
        this.unsetLoading();
        return;
      }
      const hasTieMatch = this.hasMatchTieResults(
        this.editMatchModel.battle_id
      );

      if (
        hasTieMatch &&
        !this.currentTournamentStage.block_configuration.tie_match
      ) {
        this.errorNotify({
          message: this.$t(
            '_web_tournaments-tie-match-not-allowed',
            'Tie match are not allowed for this competition'
          ),
          inModal: true,
          yesText: this.$t('_web_form_error_confirm-yes', 'OK'),
        });
        this.unsetLoading();
        return;
      }
      this.saveResults(this.editMatchModel)
        .then(this.goToTableView)
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    onSetTechWin(val, index) {
      this.manualMode = val;
      this.techWinIndex = index;
    },
    setTechWinner() {
      if (this.techWinIndex) {
        this.setLoading();
        this.setTechWin({
          matchID: this.editMatchModel.id,
          index: this.techWinIndex,
        })
          .then(this.goToTableView)
          .catch(this.errorNotify)
          .finally(this.unsetLoading);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-results-modal {
  min-width: 500px;
  table {
    border-spacing: 0px;
    th {
      &.results-column {
        width: 42px;
      }
    }
    .summary-column {
      width: 1%;
    }
  }
  .edit-results-toggle {
    @include auto-rtl(right, 0);
  }
  .players-left-wrapper,
  .players-right-wrapper {
    max-width: calc(50% - 45px);
  }
  .players-separator {
    width: 90px;
  }
}
</style>
