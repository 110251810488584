<template>
  <div v-if="isVisible" class="d-flex align-items-center conversation-search">
    <SearchInput v-model="searchValue" />
    <button
      v-if="miniMode"
      class="btn btn-transparent text-primary h-pr-0"
      @click="$emit('toggle', false)"
    >
      {{ $t('_web_conversation-search_cancel', 'Cancel') }}
    </button>
  </div>
</template>

<script>
import SearchInput from '@/components/form/HeraFormSearch';

export default {
  name: 'ConversationSearch',
  components: { SearchInput },
  model: {
    prop: 'isVisible',
    event: 'toggle',
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    miniMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchValue: '',
    };
  },
  watch: {
    searchValue(value) {
      this.$emit('change', value.toLowerCase());
    },
  },
};
</script>

<style lang="scss">
.conversation-search {
  .mini-mode & {
    padding: 0 0.75rem;
    border-bottom: $card-border-width solid $card-border-color; // as .card for conversations list
    > .input-search {
      margin-bottom: 0.25rem !important;
    }
  }
}
</style>
