<template>
  <div class="row mt-3 mx-0 post-page">
    <div class="col-2 back-section">
      <a href="#" class="btn-back" @click.prevent="goBackToPost">
        <AppIcon name="back" width="16" />
        {{ $t('_web_reply_back', 'Back to post') }}
      </a>
    </div>
    <div class="col-8">
      <Panel variant="secondary-card" class="position-relative post px-5 py-0">
        <Replies
          :input-active="showAddReply"
          :post-id="$route.params.id"
          :comment="comment"
          :quantity="comment.comments_count"
          @toggleAddReply="toggleAddReply"
        />
      </Panel>
    </div>
    <div class="col-2 sidebar"></div>
  </div>
</template>

<script>
import Panel from '@/components/atoms/Panel.vue';
import Replies from '@/components/comments/Replies';
import AppIcon from '@/components/atoms/Icon';

export default {
  name: 'ReplyPage',
  components: {
    Panel,
    Replies,
    AppIcon,
  },
  data() {
    return {
      showAddReply: false,
    };
  },
  computed: {
    comment() {
      return { id: this.$route.params.threadId, comments_count: 1 }; //hardcoded to 1 to emulate comment, do not showing
    },
  },
  methods: {
    toggleAddReply(state) {
      this.showAddReply = state;
    },
    goBackToPost() {
      this.$router.push({
        name: 'post',
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>
