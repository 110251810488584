<template>
  <div class="starts-on">
    <span v-if="!timeOnly" class="muted">{{
      formatedTournamentStartLabel.labelTime
    }}</span>
    <span class="time" :class="`${cardStyle}`">{{
      formatedTournamentStartLabel.formatedTime
    }}</span>
  </div>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';

export default {
  props: {
    tournamentStartTime: propRequired(Number),
    cardStyle: prop(String),
    timeOnly: prop(Boolean, false),
  },
  computed: {
    formatedTournamentStartLabel() {
      let labelTime;
      let formatedTime;

      if (
        typeof this.tournamentStartTime === 'number' &&
        !isNaN(this.tournamentStartTime)
      ) {
        const tournamentStart = new Date(this.tournamentStartTime * 1000);
        let locale = JSON.parse(localStorage.getItem('locale')).key;
        let month = tournamentStart.toLocaleString(locale, { month: 'short' });
        let day = tournamentStart.toLocaleDateString(locale, {
          day: '2-digit',
        });
        let weekday = tournamentStart.toLocaleString(locale, {
          weekday: 'short',
        });
        let hour = tournamentStart.toLocaleString(locale, {
          hour: 'numeric',
          minute: 'numeric',
        });
        formatedTime = `${month} ${day} ·  ${weekday} · ${hour}`.toUpperCase();

        if (tournamentStart > new Date()) {
          labelTime = this.$t('_web_starts_on', 'starts on').toUpperCase();
        } else {
          labelTime = this.$t('_web_started_on', 'started on').toUpperCase();
        }
      } else {
        labelTime = '';
        formatedTime = '';
      }

      return { labelTime, formatedTime };
    },
  },
};
</script>

<style lang="scss">
.starts-on {
  display: flex;
  justify-items: center;
  gap: 10px;
}
.muted {
  color: $hr-text-muted;
  font-family: $hr-font-family;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 140%;
}
.time {
  color: $hr-text-primary;
  font-family: $hr-font-family;
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 140%;
}

.tournament-card-style {
  color: $hr-text-muted;
}
</style>
