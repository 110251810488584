export default function unixToMMMDDDayTime(unixTime) {
  var date = new Date(unixTime * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  var month = months[date.getMonth()];
  var day = days[date.getUTCDay()];
  var hours = date.getHours();
  var minutes = '0' + date.getMinutes();
  var formattedTime;
  if (hours > 12) {
    hours = hours - 12;
    formattedTime = hours + ':' + minutes.substr(-2) + ' PM';
  } else {
    formattedTime = hours + ':' + minutes.substr(-2) + ' AM';
  }
  return month + ' ' + date.getDate() + ' • ' + day + ' • ' + formattedTime;
}
