import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPutRequest,
} from '@/api/requests';

const PROFILE_BASE = 'profiles/';
const MY_PROFILE_BASE = PROFILE_BASE + 'my/';
const MY_DETAILS = PROFILE_BASE + 'my';
const MY_AVATAR = MY_PROFILE_BASE + 'avatar/';
const MY_COVER = MY_PROFILE_BASE + 'cover/';
const MY_PLATFORMS = MY_PROFILE_BASE + 'platforms/';
const MY_GAMES = MY_PROFILE_BASE + 'games/';
const MY_FRIENDS = MY_PROFILE_BASE + 'friends/';
const MY_CLANS = MY_PROFILE_BASE + 'clans/';
const MY_NETWORKS = MY_PROFILE_BASE + 'networks/';

const getProfiles = authorizedGetRequest(PROFILE_BASE);
const getProfile = username => authorizedGetRequest(PROFILE_BASE + username);
const getMyProfileAssociations = authorizedGetRequest(
  PROFILE_BASE + `associations/my`,
  { preventCancelRequest: true }
);
const getMyProfile = authorizedGetRequest(MY_DETAILS, {
  preventCancelRequest: true,
});
const putMyDetails = authorizedPutRequest(MY_PROFILE_BASE);
const putMyAvatar = authorizedPutRequest(MY_AVATAR);
const putMyCover = authorizedPutRequest(MY_COVER);
const putMyPlatforms = authorizedPutRequest(MY_PLATFORMS);
const putMyPlatform = platformId =>
  authorizedPutRequest(MY_PLATFORMS + platformId);
const deleteMyPlatform = platformId =>
  authorizedDeleteRequest(MY_PLATFORMS + platformId);
const putMyGames = authorizedPutRequest(MY_GAMES);
const getMyFriends = authorizedGetRequest(MY_FRIENDS, {
  preventCancelRequest: true,
});
const getMyClans = (pageToken = null) =>
  authorizedGetRequest(MY_CLANS + (pageToken ? `?page=${pageToken}` : ''), {
    preventCancelRequest: true,
  });
const putSocialNetwork = networkID =>
  authorizedPutRequest(`${MY_NETWORKS}/${networkID}`);
const deleteSocialNetwork = networkID =>
  authorizedDeleteRequest(`${MY_NETWORKS}/${networkID}`);
//export const getMyWall = authorizedGetRequest(MY_WALL);

export default {
  getProfiles,
  getProfile,
  getMyProfileAssociations,
  getMyProfile,
  putMyDetails,
  putMyAvatar,
  putMyCover,
  putMyPlatforms,
  putMyPlatform,
  deleteMyPlatform,
  putMyGames,
  getMyFriends,
  getMyClans,
  putSocialNetwork,
  deleteSocialNetwork,
};
