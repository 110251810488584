'use strict';

import { API, REGIONS_API, BASE_REGION_URL } from './config';
import { isEmpty, isObject, uniqueId, isNumber } from '@/utils/lodashUtils';
import {
  isRequestPending,
  optionsWithCancelToken,
  removeTokenFromStore,
} from './requestCancel';

export const alterAuthTokenInHeader = token => {
  API.defaults.headers.common['Authorization'] = encodeURIComponent(token);
};

export const deleteAuthTokenInHeader = () => {
  delete API.defaults.headers.common['Authorization'];
};

/**
 * Create GET request function.
 * @param {String} url
 * @param {Object} settings
 * @param {String} settings.cancelStrategy - Request canceling strategy. Can be `forward` or `back`. Default `back`.
 * @param {Boolean} settings.preventCancelRequest - Prevent this request canceling.
 * @param {Boolean} settings.ignoreParams - Ignore query parameters during request canceling.
 * @returns {Function}
 */
export const simpleGetRequest = (url, settings = {}) => (
  options = null,
  clearAuthTokenInHeader
) => {
  const { ignoreParams } = settings;
  // TODO - TH-7075 remove custom params serializing from requests url!
  let keyUrl = ignoreParams ? url.split('?')[0] : url;
  if (!ignoreParams && isObject(options) && !isEmpty(options.params)) {
    const params = new URLSearchParams();
    Object.keys(options.params).forEach(paramKey => {
      const value = options.params[paramKey];
      const isValueValid = isNumber(value) ? isFinite(value) : !isEmpty(value);
      if (isValueValid) {
        if (Array.isArray(value)) {
          value.forEach(el => params.append(paramKey, el));
        } else {
          params.append(paramKey, value);
        }
      }
    });
    keyUrl = `${keyUrl}?${params.toString()}`;
  }

  if (clearAuthTokenInHeader) {
    alterAuthTokenInHeader(null);
  }

  if (
    settings &&
    settings.cancelStrategy === 'forward' &&
    !settings.preventCancelRequest &&
    isRequestPending(keyUrl)
  ) {
    console.log('Request cancelling: locate duplicated requests for ', url);
    return Promise.reject({
      message: 'Operation canceled due to new request',
      silent: true,
    });
  }

  const requestId = uniqueId('request_');

  const requestOptions =
    settings && settings.preventCancelRequest
      ? options
      : optionsWithCancelToken(options, keyUrl, requestId);

  let currentAPI = !settings.regionsApi ? API : REGIONS_API;

  return currentAPI
    .get(url, requestOptions)
    .finally(() => removeTokenFromStore(requestId));
};

export const simplePostRequest = url => (
  requestPayload = null,
  options = {},
  clearAuthTokenInHeader
) => {
  if (clearAuthTokenInHeader) {
    alterAuthTokenInHeader(null);
  }
  return API.post(url, requestPayload, options);
};

export const simpleHeadRequest = url => (requestPayload = null) =>
  API.head(url, requestPayload);

export const authorizedGetRequest = (url, settings) => (
  token,
  options = {}
) => {
  alterAuthTokenInHeader(token);
  return simpleGetRequest(url, settings)(options);
};

export const authorizedPostRequest = url => (
  token,
  requestPayload = null,
  options = {}
) => {
  alterAuthTokenInHeader(token);
  return simplePostRequest(url)(requestPayload, options);
};

export const authorizedPutRequest = url => (
  token,
  requestPayload = null,
  options = null
) => {
  alterAuthTokenInHeader(token);
  return API.put(url, requestPayload, options);
};

export const authorizedDeleteRequest = url => (
  token,
  requestPayload = null,
  moveDataInBody = false
) => {
  alterAuthTokenInHeader(token);
  const config = moveDataInBody
    ? {
        data: { ...requestPayload },
      }
    : {
        params: { ...requestPayload },
      };
  return API.delete(url, config);
};
export const fileDownload = (accessToken, url, fileName) => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', BASE_REGION_URL + url, true);
    xhr.setRequestHeader('Authorization', accessToken);
    xhr.responseType = 'arraybuffer';

    xhr.onload = function() {
      if (this.status == 200) {
        var blob = new Blob([this.response], {
          type: 'application/octet-stream',
        });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          let blobURL = window.URL.createObjectURL(blob);
          let tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', fileName);

          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
          }

          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
          resolve();
        }
      } else {
        reject(this.message);
      }
    };

    xhr.send();
  });
};

export default {
  simpleGetRequest,
  simplePostRequest,

  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
  fileDownload,
  alterAuthTokenInHeader,
  deleteAuthTokenInHeader,
};
