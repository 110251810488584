var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-between h-pl-0 chat-dialog-wrap",class:{
    'mini-mode': _vm.miniMode,
    'screen-mode': !_vm.miniMode,
    'form-is-visible': _vm.formIsVisible,
  }},[_c('Header',_vm._g(_vm._b({staticClass:"w-100 flex-shrink-0"},'Header',{ miniMode: _vm.miniMode, conversationInfo: _vm.conversationInfo, hasItems: !!_vm.items.length },false),{ translateChat: _vm.translateChat })),(_vm.isTournamentRelated)?_c('CtaAdmin'):_vm._e(),(_vm.openedPost)?_c('Post',{staticClass:"mt-auto mb-0 w-100 overflow-hidden post-wrap",attrs:{"data":_vm.openedPost,"can-edit":false},on:{"closePost":_vm.closePost}}):_c('ListLoader',{staticClass:"d-flex align-items-stretch justify-content-center w-100 h-100 overflow-hidden",class:{
      'align-self-end mt-auto mb-0': _vm.items.length,
      'align-self-center my-auto': !_vm.items.length,
    },attrs:{"is-loading":_vm.isLoading && !_vm.items.length,"is-crashed":_vm.isCrashed,"is-empty-list":!_vm.items.length,"dummy-props":( _obj = {
      iconName: 'dummy/chat',
      size: _vm.miniMode ? 160 : 200
    }, _obj[_vm.miniMode ? 'subheaderText' : 'headerText'] = _vm.conversationInfo
        ? _vm.$t('_web_chat-dialog_is-empty', 'No Messages with {title} ', {
            title: _vm.conversationInfo.title,
          })
        : _vm.$t(
            '_web_chat-dialog_no-selected',
            'Select a chat to start messaging'
          ), _obj.class = 'custom-scrollbar w-100', _obj )},on:{"reload":function($event){return _vm.reloadDialog(_vm.conversationId)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.conversationInfo && _vm.items.length),expression:"conversationInfo && items.length"}],ref:"chatDialog",staticClass:"w-100 h-100 custom-scrollbar chat-dialog",attrs:{"slot":"list","tabindex":"-1"},on:{"&!wheel":function($event){return _vm.focusIn($event)}},slot:"list"},[_c('InfiniteScroll',{staticClass:"d-flex chat-dialog-scroller",attrs:{"reverse":"","list":_vm.items,"list-name":"messages","is-disabled":_vm.scrollIsDisabled || !_vm.nextPageToken,"in-container":true,"activation-gap":200},on:{"ScrollToBottom":_vm.getMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _c('div',{staticClass:"d-flex align-self-end flex-column-reverse w-100"},[_vm._l((_vm.items),function(item){return [(_vm.conversationInfo && _vm.accountId !== item.sender)?_c('div',{key:item.id,staticClass:"msg-item msg-left"},[_c('div',{staticClass:"media justify-content-start msg-wrap"},[(_vm.isGroupChat || !_vm.miniMode)?_c('router-link',{staticClass:"text-center h-mr-3 align-self-end",attrs:{"to":("/profiles/" + (item.sender_name))}},[_c('Avatar',{attrs:{"size":50,"img-url":item.avatar || item.sender_avatar,"type":item.group_chat ? 'clan' : 'user'}})],1):_vm._e(),_c('MsgContent',{staticClass:"media-body media-body-fix text-light ml-3 font-size-sm d-flex align-items-start align-self-end flex-column",attrs:{"item":item,"is-post":_vm.isChannel},on:{"openPost":_vm.openPost}})],1)]):_c('div',{key:item.id,staticClass:"msg-item msg-right"},[_c('div',{staticClass:"media justify-content-end msg-wrap",class:{
                  'msg-muted': item.isShadowMsg,
                  'msg-flash': !!item.flashIt,
                  'msg-flash-out': !!item.fadeOut,
                }},[_c('div',{staticClass:"d-flex align-items-center align-self-stretch mx-2 msg-options",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.setDropdownOptions($event, item)}}},[_c('AppIcon',{attrs:{"name":"dots"}})],1),_c('MsgContent',{staticClass:"media-body media-body-fix text-light font-size-sm d-flex align-items-start flex-column",attrs:{"item":item,"is-post":_vm.isChannel},on:{"openPost":_vm.openPost}}),(!_vm.miniMode)?_c('MyAvatar',{staticClass:"align-self-end h-ml-4 d-none d-sm-inline-block",attrs:{"size":50}}):_vm._e()],1)])]}),_c('PopoverMenu',{attrs:{"position":_vm.optionsPosition,"closest":_vm.$refs.chatDialog,"float":['right'],"regard":"bottom","items":[1],"auto-close":""}},[_c('span',{on:{"click":_vm.deleteMsg}},[_vm._v(_vm._s(_vm.$t('_web_conversation_delete', 'Delete')))])])],2)}}])})],1)]),(_vm.formIsVisible)?_c('div',{staticClass:"align-self-end flex-shrink-0 chat-dialog-form pt-4 p-md-4 h-pl-0"},[_c('FormEditable',{attrs:{"grid":['Attachments', 'Editable'],"placeholder":_vm.$t(
          '_web_conversation_placeholder_enter-message',
          'Enter Your message'
        ),"has-drop":"","show-button":true,"has-tags":"","has-mentions":"","upload-btn-icon":"photo"},on:{"submit":_vm.sendMsg}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }