<template>
  <div>
    <HeraFormInput
      key="points-field-battle-royale"
      v-model="pointsPerKill"
      v-validate="'required|numeric|decimal:0|min_value:0'"
      type="number"
      :min="0"
      step="1"
      size="2"
      class="w-auto points-field-battle-royale"
      :label="$$t('Points per kill:')"
      required
      :class="{ 'is-invalid': errors.first('points') }"
      :view-mode="disabled"
      :disabled="isStageFieldDisabled('pointsPerKill') || disabled"
      name="points"
      :error="errors.first('points')"
      :tooltip="$$t('Points Per Kill', 'points-per-kill_tooltip')"
      :placeholder="$$t('Points', 'points-per-kill_placeholder')"
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormInput from '@/components/atoms/form/HeraFormTextInput';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StagePointsPerKillSetting',
  components: {
    HeraFormInput,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    pointsPerKill: {
      get() {
        return this.getBlockConfigurationByKey('points_per_kill', 1);
      },
      set(newVal) {
        this.setBlockConfigurationByKey('points_per_kill', +newVal);
      },
    },
  },
};
</script>

<style></style>
