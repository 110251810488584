<template>
  <div class="mb-3 position-relative tournament-post">
    <div v-if="!hideCover" class="tournament-img mt-4">
      <img :src="coverURI" alt="cover" />
    </div>

    <!--<div
      :class="{ 'mt-4': hasCountdown }"
      class="w-100 d-flex justify-content-between align-items-center"
    >
      <TimeLabel
        ref="tournamentTimeLabel"
        v-bind="{
          tournament,
        }"
        class="flex-shrink-0"
      />
      <div v-if="hasCountdown" class="h-ml-auto h-100  flex-shrink-0">
        <StatusLabel
          :status="tournament.status"
          :color="statusLabelBg"
          for-tournament
        />
      </div>
    </div>-->
    <div class="d-flex align-items-center " style="margin-top:10px">
      <TournamentLink
        :id="tournament.id"
        :vanity-id="tournament.vanityId || tournament.vanity_id"
        class="flex-grow-1"
        ><TournamentStatusIcon
          v-if="!hasCountdown"
          :tournament-status="tournament.status"
          :color="statusLabelBg"
          for-tournament
        />

        <span class="tournament-title-feed">
          <AppIcon
            v-if="needInvite"
            name="lock-closed"
            :width="20"
            class="title-app-icon"
          />{{ tournament.title }}</span
        >
      </TournamentLink>
    </div>
    <!-- <GameLabel
      v-if="tournament.platforms"
      class="mt-4"
      v-bind="{
        tournament,
        variant: 'large',
        platformLogoSize: 24,
        gameLogoSize: 24,
      }"
    />-->

    <!--
    <div
      v-if="tournament.is_event_organizer_available || hasSponsorLogos"
      class="row mt-3 tournament-post_footer"
    >
       <div v-if="tournament.is_event_organizer_available" class="col">
        <TextLabel>{{ $$t('Organized by') }}</TextLabel>
        <ListItemAccount
          :type="organizationType"
          :avatar="tournament.event_organizer_logo"
          :name="tournament.event_organizer_name"
          :account-id="tournament.owner"
          :vanity-id="owner.vanity_id"
          :size="24"
        />
      </div>
       <div v-if="hasSponsorLogos" class="col">
        <TextLabel>{{ $$t('sponsored by') }}</TextLabel>
        <div v-if="hasSponsorLogos" class="d-flex justify-content-center">
          <img
            v-for="(logo, index) in sponsors"
            :key="index"
            class="tournament-post_sponsor-logo"
            :src="logo"
          />
        </div>
      </div>
    </div>-->
    <div
      v-if="isShowWinners"
      class="d-flex font-weight-bold align-items-center show-winner"
    >
      <div class="winners-placeholder">
        <div>
          {{ $t('_web_finished-tournament-post_winners-cpt', 'WINNERS') }}
        </div>
        <ul class="tournament-post_winners p-0 m-0">
          <li
            v-for="winner in winners"
            :key="winner.login || winner.id"
            class="d-flex align-items-center tournament-post_winner  mt-4"
            :class="`tournament-post_winner--place-${winner.place}`"
          >
            <LinkAccount
              :id="winner.login || winner.id"
              :type="winner.type"
              :vanity-id="winner.vanityId"
              class="d-flex w-100 justify-content-between  position-relative"
              ><div class="prizes-data">
                <div class="tournament-post_place-label h-ml-3 h-mr-4">
                  {{ winner.place
                  }}<span>{{
                    winner.place === 1 ? 'st' : winner.place === 2 ? 'nd' : 'rd'
                  }}</span>
                </div>
                <Avatar
                  :type="winner.type"
                  :img-url="winner.avatar || winner.logo"
                  :size="36"
                  class="avatar-prizes-data"
                />
                <div class="text-truncate winners-nickname">
                  {{ winner | displayName }}
                </div>
              </div>

              <div v-if="hasPrizes" class="tournament-post_prize">
                {{ getPlacePrize(winner.place) }}
              </div>
            </LinkAccount>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TOURN_STATUSES } from '@/constants/accounts';
import { ORGANIZATION } from '@/constants/accounts';
import { DEFAULT } from '@/constants/common';
import { translate } from '@/mixins/translate';
import Avatar from '@/components/images/Avatar';
import TournamentStatusIcon from '@/components/common/TournamentStatusIcon.vue';
//import GameLabel from '@/components/common/MyTournamentCard/GameLabel';
//import StatusLabel from '@/components/molecules/StatusLabel';
//import TimeLabel from '@/components/common/MyTournamentCard/TimeLabel';
//import TextBlock from '@/components/atoms/TextBlock';
import LinkAccount from '@/components/links/Account';
import TournamentLink from './TournamentLink';
import AppIcon from '@/components/atoms/Icon';
//import Panel from '@/components/atoms/Panel.vue';
//import Icon from '@/components/atoms/Icon';
//import ListItemAccount from '@/components/common/ListItemAccount';
//import TextLabel from '@/components/atoms/TextLabel';
import { prop, propRequired } from '@/utils/factories';

const TOURNAMENT_INVITE_ONLY_REGISTRATION = 'closed';

export default {
  components: {
    //TextBlock,
    Avatar,
    TournamentStatusIcon,
    //GameLabel,
    //StatusLabel,
    LinkAccount,
    TournamentLink,
    AppIcon,
    //TimeLabel,
    //Panel,
    //Icon,
    //TextLabel,
    //ListItemAccount,
  },
  mixins: [translate('_web_tournament')],
  props: {
    tournament: propRequired(Object),
    owner: prop(Object, () => ({})),
    isAnnounced: prop(Boolean),
    hideCover: prop(Boolean),
  },
  data() {
    return {
      hasCountdown: false,
      organizationType: ORGANIZATION,
    };
  },
  computed: {
    ...mapGetters(['isTournamentPastStatus', 'currentCountry']),
    prize() {
      return this.$lodash.get(this, 'tournament.prize.prize_pool');
    },
    needInvite() {
      return (
        this.tournament.registrationType ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION ||
        this.tournament.registration_type ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION
      );
    },
    statusLabelBg() {
      return this.$options.isFinished
        ? this.$scss.successColor
        : this.$scss.primaryColor;
    },
    winners() {
      if (this.tournament.winners.length === 4) {
        let winners = this.tournament.winners
          .map((winner, index) => ({
            ...(winner.players ? winner.players[0] : {}),
            place: index + 1,
          }))
          .splice(0, 2)
          .sort((a, b) => {
            return a.place - b.place;
          });
        if (winners.length === 2) {
          const firstPlaceWinner = winners.splice(0, 1)[0];
          winners.splice(1, 0, firstPlaceWinner).sort((a, b) => {
            return a.place - b.place;
          });
        }
        return winners
          .filter(player => player.login || player.id)
          .sort((a, b) => {
            return a.place - b.place;
          });
      } else {
        let winners = this.tournament.winners
          .map((winner, index) => ({
            ...(winner.players ? winner.players[0] : {}),
            place: index + 1,
          }))
          .splice(0, 3)
          .sort((a, b) => {
            return a.place - b.place;
          });
        if (winners.length === 3) {
          const firstPlaceWinner = winners.splice(0, 1)[0];
          winners.splice(1, 0, firstPlaceWinner).sort((a, b) => {
            return a.place - b.place;
          });
        }
        return winners
          .filter(player => player.login || player.id)
          .sort((a, b) => {
            return a.place - b.place;
          });
      }
    },
    hasPrizes() {
      return !!this.$lodash.get(this, `tournament.prize.prize_line`, []).length;
    },

    players() {
      return this.tournament.players_quantity || this.tournament.squads;
    },
    isProRank() {
      return this.tournament.rank_type === 'professional';
    },

    isClanTournament() {
      return this.tournament.player_type === 'clan';
    },
    squadSize() {
      return this.tournament.squad_size;
    },
    coverURI() {
      return `${encodeURI(this.tournament.cover)}`;
    },
    sponsors() {
      return (
        this.tournament.sponsor_logo[this.currentCountry] ||
        this.tournament.sponsor_logo[DEFAULT]
      );
    },
    hasSponsorLogos() {
      return this.sponsors && this.sponsors.length;
    },
    isShowWinners() {
      return this.$options.isFinished && this.winners.length;
    },
  },
  created() {
    this.$options.isFinished =
      !this.isAnnounced &&
      (this.tournament.status === TOURN_STATUSES.CLOSED ||
        this.tournament.status === TOURN_STATUSES.ARCHIVED);
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.tournamentTimeLabel) {
        this.hasCountdown = this.$refs.tournamentTimeLabel.hasValidDate;
      }
    });
  },
  methods: {
    getPlacePrize(place) {
      return this.$lodash.get(
        this,
        `tournament.prize.prize_line[${place - 1}].prize`
      );
    },
  },
};
</script>

<style lang="scss">
.tournament-post {
  .tournament-img {
    img {
      width: 100%;
      margin-bottom: 48px;
    }
    max-width: 100%;
    width: 100%;
    background-position: center center;
    background-size: cover;
    border-radius: $ph-small-radius;
  }
  color: $ph-primary-text;
  a,
  a:hover {
    color: $ph-primary-text;
    text-decoration: none;
  }
  &_winner {
    list-style: none;
    width: 322px;
    @media (max-width: 768px) {
      width: 100%;
    }
    .tournament-post_place-label {
      font-family: $hr-font-family;
      font-weight: 700;
      font-size: $hr-text-small;
    }
    &--place-1 {
      background-color: $hr-bg-tournament-prizes;
      border-radius: 24px;
      .tournament-post_place-label {
        color: $ph-yellow;
      }
      .avatar-prizes-data {
        margin-left: $hr-spacing-02;
      }
    }
  }
  &_prize {
    font-family: $hr-font-family;
    font-weight: 700;
    font-size: $hr-text-small;
    align-self: center;
    padding-right: 25px;
    padding-left: 25px;
    color: $hr-text-primary !important;
  }
  &_sponsor-logo {
    max-height: 1.6rem;
    @include auto-rtl(margin-right, 0.75rem);
  }
}
.tournament-title-feed {
  font-family: $hr-font-family;
  font-weight: 900;
  font-size: $hr-text-regular;
  line-height: 24px;
  color: $hr-text-primary;
  margin-left: 8px;
  margin-right: 8px;
}
.winners-placeholder {
  font-family: $hr-font-family;
  font-weight: 400;
  font-size: $hr-text-small;
  color: $hr-text-secondary;
}
.prizes-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.avatar-prizes-data {
  margin-right: $hr-spacing-05;
  @include auto-rtl(margin-right, $hr-spacing-05);
  border-radius: 100px;
}
.winners-nickname {
  font-family: $hr-font-family;
  font-weight: 700;
  font-size: $hr-text-regular;
  line-height: 16px;
  color: $hr-text-tournament-prizes;
}
.show-winner {
  margin-bottom: 50px;
  margin-top: 43px;
}
</style>
