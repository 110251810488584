<template>
  <div
    class="slider-chat-list-item d-flex align-items-center px-3"
    :class="data.last_message === null ? 'addborder' : ''"
    @click="$emit('click')"
  >
    <div v-if="data.adminMention" class="red-dot"></div>
    <Avatar
      :type="avatarType"
      :img-url="data.avatar"
      class="slider-chat-list-item__avatar"
      :size="50"
    />
    <div
      class="slider-chat-list-item__content d-flex flex-column justify-content-between"
    >
      <div class="d-flex justify-content-between">
        <div class="slider-chat-list-item__name d-flex align-items-center">
          <Icon
            v-if="isIconVisible"
            :name="iconName"
            width="12px"
            class="slider-chat-list-item__icon mx-1"
          />
          <span class="slider-chat-list-item__title text-truncate">{{
            data.title
          }}</span>
        </div>
        <div class="slider-chat-list-item__time d-flex align-items-center">
          <Icon
            v-if="isDoneIconVisible"
            class="mx-1"
            name="done"
            width="14px"
            fill="#8261FC"
          />
          <span v-if="data.last_activity">{{
            data.last_activity | parseDateTime
          }}</span>
        </div>
      </div>
      <div
        v-if="data.last_message"
        class="h-text-left slider-chat-list-item__last-msg"
      >
        {{ data.last_message | removeTagsMention }}
      </div>
      <div
        v-if="data.unread_count > 0"
        class="slider-chat-list-item__msg-count mx-3"
      >
        {{ data.unread_count }}
      </div>
      <div v-if="data.pendingUpdate" class="block-loading"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import flip from '@/directives/flip';
import Icon from '@/components/atoms/Icon';
import Avatar from '@/components/images/Avatar';
import { getAvatarTypeByConversation } from '@/utils/getProps';

export default {
  components: { Icon, Avatar },
  directives: {
    flip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    focus: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('my', ['accountId']),
    isIconVisible() {
      return (
        this.data.conversation_type === 'channel' ||
        this.data.conversation_type === 'group_chat'
      );
    },
    iconName() {
      return this.data.conversation_type === 'channel'
        ? 'channel'
        : 'shield-fill';
    },
    isDoneIconVisible() {
      return (
        this.accountId === this.data.last_message_sender_id &&
        this.data.conversation_type !== 'channel'
      );
    },
    avatarType() {
      return getAvatarTypeByConversation(this.data);
    },
  },
};
</script>

<style lang="scss">
.addborder {
  background-color: #626e3b67;
}
.slider-chat-list-item {
  position: relative;
  height: 4.25rem;
  border-bottom: 1px solid $ph-card;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $ph-input-bg;
  }

  &__content {
    max-width: 80%;
    flex: 1 auto;
    height: 2.8rem;
    margin-left: 0.5rem;
  }

  &__name {
    max-width: 70%;
    font-size: 0.875rem;
    font-weight: 700;
  }

  $icon-width: 0.75rem;
  &__icon {
    width: $icon-width;
  }
  &__title {
    width: calc(100% - #{$icon-width});
  }

  &__time {
    font-size: 0.69rem;
    color: $ph-secondary-text;
  }

  &__last-msg {
    word-wrap: break-word;
    font-size: 0.75rem;
    opacity: 0.6;
    @include text-multiline-ellipses(3, 0.75rem);
  }

  &__msg-count {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    width: 1.5rem;
    height: 1rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    text-align: center;
    background-color: $ph-accent;
  }

  .block-loading {
    @include spinner(2rem);
  }
}
.red-dot {
  width: 6px;
  height: 6px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
  border: 1px solid red;
  background-color: red;
  border-radius: 50%;
}
</style>
