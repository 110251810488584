var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"edit-results-modal position-relative d-inline-flex flex-column",attrs:{"variant":['default', 'rounded', 'half-paddings']}},[(_vm.editMatchModel)?[_c('Panel',{key:((_vm.editMatchModel.id) + "-info"),staticClass:"p-0 mb-0 d-flex align-items-center justify-content-between position-relative",attrs:{"variant":['transparent']}},[(_vm.editMatchModel.battle_id)?_c('TextBlock',{staticClass:"p-0 mt-0 battle-id-label",attrs:{"size":0.75,"variant":'secondary',"line-height":1}},[_vm._v("\n        "+_vm._s(_vm.$t('_web_tournament_battle_id', '# {match}', {
            match: _vm.editMatchModel.index + 1,
          }))+"\n        "),_c('span',{staticClass:"mx-3"},[_vm._v("|")]),_vm._v(_vm._s(_vm.roundLabel)+"\n      ")]):_vm._e(),_c('StatusLabel',{staticClass:"px-4 py-3 position-absolute flex-grow-0 mx-auto",style:({
          left: 0,
          right: 0,
          maxWidth: '80px',
        }),attrs:{"status":_vm.editMatchModel.status}}),_c('div',{staticClass:"flex-shrink-0"},[_c('Icon',{staticClass:"h-mr-3",attrs:{"name":"calendar","width":"20","fill":_vm.$scss.colors.secondaryTextBg}}),_c('TextBlock',{attrs:{"size":0.75,"variant":'secondary',"line-height":1}},[_vm._v(_vm._s(_vm._f("localDate")(_vm.editMatchModel.date_start,_vm.$times.DATE_SUBMED))+",")]),_c('TextBlock',{staticClass:"h-ml-3",attrs:{"size":0.75,"variant":'secondary',"line-height":1}},[_vm._v(_vm._s(_vm._f("localDate")(_vm.editMatchModel.date_start,_vm.$times.TIME_SIMPLE)))]),_c('TextBlock',{attrs:{"variant":'secondary',"size":0.75,"line-height":1}},[_vm._v(" – ")]),_c('TextBlock',{attrs:{"variant":'secondary',"size":0.75,"line-height":1}},[_vm._v(_vm._s(_vm._f("localDate")(_vm.editMatchModel.date_end,_vm.$times.TIME_SIMPLE)))])],1)],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-5 mb-4"},[_c('div',{staticClass:"mw-50"},[_c('table',{staticClass:"table hera-table m-0 px-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"py-0 h-pl-0 h-pr-5"},[_c('TextLabel',{attrs:{"text":_vm.$t('_web_battle_name-th-label', 'Name')}})],1),_vm._l((_vm.editMatchModel.number_of_rounds),function(round,index){return [_c('th',{key:index + '-header',staticClass:"py-0 results-column",class:{
                    'px-5': _vm.editMatchModel.number_of_rounds < 2,
                    'h-pl-5 h-pr-3':
                      index === 0 && _vm.editMatchModel.number_of_rounds > 1,
                    'h-pr-5 h-pl-3':
                      index === _vm.editMatchModel.number_of_rounds - 1 &&
                      _vm.editMatchModel.number_of_rounds > 1,
                    'px-3':
                      index !== 0 &&
                      index !== _vm.editMatchModel.number_of_rounds - 1 &&
                      _vm.editMatchModel.number_of_rounds > 1,
                  }},[_c('div',{staticClass:"text-center"},[_c('TextLabel',{attrs:{"text":_vm.$t(
                          '_web_battle_match-game-th-label',
                          'Game {index}',
                          {
                            index: index + 1,
                          }
                        )}})],1)]),(_vm.isRoundConflicted(index))?_c('th',{key:index + '-header-disputed',staticClass:"px-0"}):_vm._e()]}),_c('th',{staticClass:"text-center px-5 py-0 summary-column"},[_c('TextLabel',{attrs:{"text":_vm.$t('_web_battle_summary-th-label', 'Match result')}})],1),_c('th',{staticClass:"text-left h-pl-5 h-pr-0 py-0 set-as-winner-column"},[_c('TextLabel',{attrs:{"text":_vm.$t(
                      '_web-battle-set-as-winner-links-label',
                      'Set as winner'
                    )}})],1)],2)]),_c('tbody',_vm._l(([1, 2]),function(index){return _c('tr',{key:("player-" + index)},[_c('td',{staticClass:"py-0 h-pl-0 align-top h-pr-5"},[_c('div',{staticClass:"d-flex flex-nowrap"},[_c('PlayerItem',{staticClass:"p-0 flex-grow-1 flex-shrink-0",attrs:{"player":_vm.editMatchModel[("player_" + index)],"hide-checkin":"","hide-score":"","avatar-size":48,"player-index":index,"is-profile":_vm.isSingle,"chat-with":_vm.editMatchModel[("captain_" + index)],"is-ready-for-battle":!_vm.editMatchModel.is_detained ||
                        _vm.editMatchModel[("player_" + index + "_ready")],"is-empty":_vm.editMatchModel[("player_empty_" + index)]}})],1)]),_vm._l((_vm.editMatchModel.number_of_rounds),function(round,roundIndex){return [_c('td',{key:roundIndex + '-cell',staticClass:"text-center align-top",class:{
                    'px-5': _vm.editMatchModel.number_of_rounds < 2,
                    'h-pl-5 h-pr-3':
                      roundIndex === 0 && _vm.editMatchModel.number_of_rounds > 1,
                    'h-pr-5 h-pl-3':
                      roundIndex === _vm.editMatchModel.number_of_rounds - 1 &&
                      _vm.editMatchModel.number_of_rounds > 1,
                    'px-3':
                      roundIndex !== 0 &&
                      roundIndex !== _vm.editMatchModel.number_of_rounds - 1 &&
                      _vm.editMatchModel.number_of_rounds > 1,
                  }},[_c('div',{staticClass:"d-inline-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center mb-4 justify-content-center"},[_c('SingleScore',{class:{
                          disabled: _vm.manualMode,
                        },attrs:{"value":_vm.isRoundConflicted(roundIndex)
                            ? '-'
                            : _vm.$lodash.isNumber(
                                _vm.editMatchModel.battleInfo[
                                  "player_1_match_results"
                                ][roundIndex][("player_" + index + "_scored")]
                              )
                            ? _vm.editMatchModel.battleInfo[
                                "player_1_match_results"
                              ][roundIndex][("player_" + index + "_scored")]
                            : _vm.editMatchModel.battleInfo[
                                "player_2_match_results"
                              ][roundIndex][("player_" + index + "_scored")],"has-errors":_vm.isRoundConflicted(roundIndex),"edit-mode":!_vm.manualMode && _vm.canEdit},on:{"update":function($event){return _vm.setScores($event, index, roundIndex)}}})],1),_c('MatchAttachments',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"value":_vm.editMatchModel.battleInfo[
                          ("player_" + index + "_match_results")
                        ][roundIndex]}})],1)]),(_vm.isRoundConflicted(roundIndex))?_c('td',{key:roundIndex + '-cell-disputed',staticClass:"px-0 align-top"},[_c('TextBlock',{staticClass:"mt-2 pt-2",attrs:{"tag":"div","variant":"secondary","size":0.75}},[_vm._v("\n                    "+_vm._s(_vm.editMatchModel.battleInfo[
                        ("player_" + index + "_match_results")
                      ][roundIndex]["player_1_scored"])),_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(_vm._s(_vm.editMatchModel.battleInfo[
                        ("player_" + index + "_match_results")
                      ][roundIndex]["player_2_scored"])+"\n                  ")])],1):_vm._e()]}),_c('td',{staticClass:"px-5 align-top summary-column"},[(!_vm.isMatchCancelled)?_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('SingleScore',{staticClass:"d-inline-flex mb-4",attrs:{"value":_vm.getPlayerScore(_vm.editMatchModel, index)}})],1):_vm._e()]),_c('td',{staticClass:"h-pl-5 h-pr-0 align-center set-as-winner"},[_c('label',{staticClass:"d-flex align-items-center justify-content-center",class:{
                    disabled:
                      (_vm.manualMode && _vm.techWinIndex !== index) || !_vm.canEdit,
                  }},[_c('Checkbox',{staticClass:"h-mr-2",attrs:{"checked":_vm.techWinIndex === index},on:{"change":function ($event) { return _vm.onSetTechWin($event, index); }}})],1)])],2)}),0)])])]),_c('div',{staticClass:"controls d-flex justify-content-center"},[_c('HeraFormBtn',{staticClass:"btn btn-secondary-simple",attrs:{"label":_vm.$t('_web_tournament_discard_modal-cancel-btn', 'Cancel')},on:{"click":_vm.goToTableView}}),_c('HeraFormBtn',{staticClass:"btn btn-sm btn-accent-simple h-ml-4",class:{
          disabled: _vm.isSaveDisabled || !_vm.canEdit,
        },attrs:{"disabled":_vm.isSaveDisabled || !_vm.canEdit,"is-loading":_vm.isLoading,"label":_vm.$t('_web_tournaments-tableview_batle-save-btn', 'Save results')},on:{"click":_vm.saveBattleResults}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }