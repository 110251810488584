<template>
  <img
    v-if="dontRound"
    :style="cssProps"
    class="noRoundImg"
    :src="avatar"
    alt="avatar"
  />
  <ImageRounder
    v-else
    :size="size"
    :fill-color="fillColor"
    :alt="type || 'avatar'"
    :img-url="avatar"
    @imageLoadError="updateImage"
  />
</template>

<script>
import { prop } from '@/utils/factories';
import ImageRounder from '@/components/atoms/ImageRounder';
import images from '@/constants/images';

export default {
  name: 'Avatar',
  components: {
    ImageRounder,
  },
  props: {
    dontRound: Boolean,
    size: prop(Number, 96),
    imgUrl: {
      type: null,
      default: '',
    },
    fillColor: prop(String),
    type: prop(String),
    autoSizes: prop(Boolean, true),
    isDeleted: prop(Boolean),
  },
  data() {
    const sourceSize = !this.autoSizes
      ? -1
      : this.size <= 100
      ? 100
      : this.size <= 300
      ? 300
      : this.size <= 500
      ? 500
      : -1;

    return {
      sourceSize,
      currentAvatar: '',
    };
  },
  computed: {
    cssProps() {
      console.log(this.size);
      return {
        '--avatar-size': this.size + 'px',
      };
    },
    url() {
      return String(this.imgUrl || '');
    },
    avatar() {
      return (
        (this.isDeleted ? this.getDeletedAvatar() : this.currentAvatar) ||
        (!this.url
          ? this.getDefaultAvatar()
          : this.checkAutoSize()
          ? this.url.replace('.original.', `.${this.sourceSize}.`)
          : this.url)
      );
    },
  },
  watch: {
    imgUrl() {
      this.currentAvatar = ''; // drop avatars if changed;
    },
  },
  methods: {
    checkAutoSize() {
      return (
        this.autoSizes &&
        this.url.includes('.original.') &&
        this.sourceSize !== -1
      );
    },
    updateImage() {
      this.currentAvatar = this.checkAutoSize()
        ? this.getNextSizeAvatar()
        : this.getDefaultAvatar();
    },
    getNextSizeAvatar() {
      this.sourceSize = -1;
      return this.url;
      /* commented @olsy
      if ((this.sourceSize += 200) > 500) {
        this.sourceSize = -1;
        return this.url;
      }

      return this.url.replace('.original.', `.${this.sourceSize}.`);
      */
    },
    getDefaultAvatar() {
      switch (this.type) {
        case 'None':
          return images.defaultPHmax;
        case 'user':
        case 'profile':
          return images.defaultUser;
        case 'clan':
          return images.defaultClan;
        case 'federation':
        case 'organization':
          return images.defaultOrganization;
        case 'platform':
        case 'genre':
        case 'game':
          return images.defaultGame;
        case 'espl':
        case 'league':
          return images.defaultESPL;
        case 'channel':
          return images.defaultChannels;
        case 'group':
          return images.defaultGroup;
        case 'place':
          return images.defaultPlace;
        case 'admin':
          return images.defaultAdmin;
      }
      return '';
    },
    getDeletedAvatar() {
      switch (this.type) {
        case 'clan':
          return images.deletedClan;
      }
      return '';
    },
  },
};
</script>
<style>
.noRoundImg {
  height: var(--avatar-size);
  width: var(--avatar-size);
  border-radius: 5px;
  background-size: cover;
}
</style>
