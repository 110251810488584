<template>
  <transition name="fade">
    <div
      v-if="value"
      class="position-fixed modalbackdrop"
      :class="`modalbackdrop--${variant}`"
      @click.self="clickHandler"
    >
      <Panel
        :class="[
          containerClass,
          'modalbackdrop_container',
          this.$router.history.current.name === 'shop' &&
            'modalbackdrop_shop_class order-status',
        ]"
        :variant="'default'"
      >
        <div
          v-if="closeIcon && clickOnBackdrop"
          class="modal-close position-absolute cursor-hand p-1 d-inline-block"
          @click="closeModal"
        >
          <Icon name="close" width="16" :fill="$scss.primaryTextBg" />
        </div>
        <slot />
      </Panel>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { globalEventListener } from '@/utils/globalEvent';
import { prop } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import Panel from '@/components/atoms/Panel';
const ESC_CODE = 'Escape';

export default {
  components: {
    Icon,
    Panel,
  },

  props: {
    value: prop(Boolean),
    clickOnBackdrop: prop(Boolean),
    escKey: prop(Boolean),
    variant: prop(String, 'default'),
    closeIcon: prop(Boolean),
    containerClass: prop(String),
    transferToBody: prop(Boolean),
    noWrapper: prop(Boolean),
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    wrapperVariants() {
      return this.noWrapper ? ['transparent'] : ['default', 'rounded-new'];
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.show();
        this.$emit('show');
      } else {
        this.hide();
        this.$emit('hide');
      }
    },
  },

  mounted() {
    if (this.transferToBody) {
      document.body.appendChild(this.$el);
    }
  },

  beforeDestroy() {
    this.hide();
  },

  methods: {
    ...mapActions(['toggleBodyScroll', 'closeModal']),
    show() {
      this.toggleBodyScroll(false);
      document.body.appendChild(this.$el);
      globalEventListener('add', 'keydown.modalbackdrop', this.keypressHander);
    },

    hide() {
      this.toggleBodyScroll(true);
      globalEventListener('remove', 'keydown.modalbackdrop');
    },

    clickHandler() {
      if (this.clickOnBackdrop) {
        this.$emit('input', false);
      }
    },

    keypressHander({ key }) {
      if (this.escKey && key === ESC_CODE) {
        this.$emit('input', false);
      }
    },
  },
};
</script>

<style lang="scss">
%default-modal-layout {
  align-items: center;
  justify-content: center;
}

.modalbackdrop {
  @include fill-border();
  display: flex;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.75);

  &_container {
    position: relative;
    max-height: 100vh;
    width: 100%;
    padding: $ph-large-space;
    @media (max-width: 450px) {
      max-height: 100dvh;
    }
    .modal-close {
      top: -2rem;
      @include auto-rtl(right, -2.25rem);
    }
  }

  &--default {
    @extend %default-modal-layout;

    .modalbackdrop_container {
      max-width: 31.25rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  &--loader {
    @extend %default-modal-layout;
    .modalbackdrop_container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: auto;
      width: auto !important;
      padding: 0;
      background: none !important;
      background-color: transparent !important;
    }
  }
  &--transparent {
    @extend %default-modal-layout;

    .modalbackdrop_container {
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: auto;
      width: auto !important;
      padding: 0;
      background: #151520;
      overflow: hidden !important;
      @media (max-width: 915px) {
        max-width: 525px;
        min-width: 525px;
      }
      @media (max-width: 600px) {
        max-width: 455px;
        min-width: 455px;
      }
      @media (max-width: 450px) {
        max-width: 325px;
        min-width: 325px;
      }
    }
  }

  &--fit-content {
    @extend %default-modal-layout;

    .modalbackdrop_container {
      max-width: auto;
      width: auto;
    }
  }

  &--narrow-list {
    @extend %default-modal-layout;

    .modalbackdrop_container {
      max-width: 24rem;
    }
  }

  &--long-list {
    align-items: start;
    justify-content: center;

    .modalbackdrop_container {
      flex-grow: 0;
      flex-shrink: 1;
      max-width: 80%;
      max-height: 80vh;
      margin-top: 10vh;
      display: flex;
      flex-direction: column;
    }
  }

  &--full-width {
    @extend %default-modal-layout;

    .modalbackdrop_container {
      max-width: 90%;
    }
  }

  &.mediaplayer {
    z-index: 999999;
  }
}

.modalbackdrop_shop_class {
  padding: 28px;

  @media (max-height: 748px) {
    padding: 22px;
  }
}
</style>
