var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('Panel',{staticClass:"w-50 mr-5 mb-5",attrs:{"variant":['rounded-small', 'default']}},[_c('div',{staticClass:"stage-summary__title"},[_c('Subheader',{staticClass:"mb-5"},[_vm._v("\n        "+_vm._s(_vm.$$t('Matches: ', 'stage-block-matches'))+"\n      ")]),(!(_vm.isSingleStage && _vm.isSingleBlock))?_c('h3',[_vm._v(_vm._s(_vm.summary.title))]):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('GaugeChart',_vm._b({staticClass:"h-100"},'GaugeChart',{
            data: _vm.stageChartData,
            options: _vm.stageChartOptions,
          },false)),_c('div',{staticClass:"d-flex flex-column justify-content-center"},[(_vm.hasOneGroup)?_vm._l((_vm.blockSummaryData(
                Object.keys(_vm.summary.blocks)[0]
              )),function(setting){return _c('div',{key:setting.key,staticClass:"stage-summary__type d-flex align-items-center px-3 mt-4",class:[("stage-info__" + (setting.key))]},[_c('div',[_vm._v("\n                "+_vm._s(setting.label)+"\n              ")]),(
                  _vm.$lodash.isFinite(setting.value) &&
                    _vm.$lodash.isFinite(setting.limit)
                )?_c('div',{staticClass:"h-ml-auto"},[_vm._v("\n                "+_vm._s(setting.value)+"/"+_vm._s(setting.limit)+"\n              ")]):_vm._e()])}):_vm._e()],2)],1),(_vm.summary.blocks && !_vm.hasOneGroup)?_c('div',{staticClass:"row no-gutters flex-wrap mt-4"},_vm._l((_vm.summary.blocks),function(block,id){return _c('div',{key:id,staticClass:"col-6"},[_c('div',{staticClass:"block-summary__type block-info__title d-flex align-items-center px-3"},[_c('h4',[_vm._v("\n              "+_vm._s(_vm.getBlockTitle(id))+"\n            ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},_vm._l((_vm.blockSummaryData(id)),function(setting,index){return _c('div',{key:setting.key,staticClass:"stage-summary__type d-flex align-items-center px-3",class:[("stage-info__" + (setting.key)), { 'mt-4': index }]},[_c('div',[_vm._v("\n                  "+_vm._s(setting.label)+"\n                ")]),(
                    _vm.$lodash.isFinite(setting.value) &&
                      _vm.$lodash.isFinite(setting.limit)
                  )?_c('div',{staticClass:"h-ml-auto"},[_vm._v("\n                  "+_vm._s(setting.value)+"/"+_vm._s(setting.limit)+"\n                ")]):_vm._e()])}),0),_c('div',{staticClass:"col-5"},[_c('GaugeChart',_vm._b({staticClass:"h-100"},'GaugeChart',{
                  data: _vm.blockChartData(id),
                  options: _vm.blockChartOptions,
                },false))],1)])])}),0):_vm._e()],1)]),_c('Panel',{staticClass:"w-50 mb-5",attrs:{"variant":['rounded-small', 'default']}},[_c('Subheader',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t('_web_cases', 'Cases'))+":")]),_vm._l((_vm.stageSummaryData),function(setting,index){return _c('div',{key:setting.key,staticClass:"stage-summary__type d-flex align-items-center px-3",class:[("stage-info__" + (setting.key)), { 'mt-4': index }]},[_c('div',[_vm._v("\n        "+_vm._s(setting.label)+"\n      ")]),_c('div',{staticClass:"h-ml-auto"},[_vm._v("\n        "+_vm._s(setting.value)+"\n      ")])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }