import {
  BATTLE_STATUSES,
  POSSIBLE_RESULTS,
  WIN,
  LOSE,
} from '@/constants/battles';

export function getPlayerScore(match, playerIndex) {
  let { score, result } = match.players
    ? match.players[playerIndex - 1]
    : {
        score: match[`player_${playerIndex}_score`],
        result: match[`player_${playerIndex}_result`],
      };

  return match.status === BATTLE_STATUSES.cancelled || +score < 0
    ? ''
    : match.status !== BATTLE_STATUSES.completed
    ? '-'
    : result === POSSIBLE_RESULTS.techWin
    ? WIN
    : result === POSSIBLE_RESULTS.techLoss ||
      match[`player_${playerIndex}`] === null
    ? LOSE
    : score;
}
