<template>
  <div :class="color">
    <!--start of view two -->
    <!--start of view two -->
    <div
      v-if="isRegStarted && pendingUsers.length > 2"
      :class="color"
      class="wrapper-universal-home regStarted-status-info"
    >
      <div class="slots-wrapper">
        <img
          src="@/assets/images/Union.svg"
          width="24px"
          height="24px"
          alt="Slots"
        />
        <h2>{{ $t('_web_single_competition_status-info-slots', 'SLOTS') }}</h2>
        <h3 class="d-block">
          {{ currentTournament.squads_pending }}/{{ currentTournament.squads }}
        </h3>
      </div>
      <div class="gray-line-player-card"></div>
      <!--after gray line-->
      <!--after gray line-->
      <div class="imgs-avatar-outer-wrapper">
        <div class="imgs-avatar-wrapper">
          <img
            v-for="(avatar, index) in pendingAvatars"
            :key="index"
            :src="avatar"
            alt="user"
            width="48px"
            height="48px"
            class="small-image-avatar"
          />
        </div>
      </div>
      <div v-if="numberOfPendingUsers > 4" class="players-joined-wrapper">
        {{ lastToJoinPending }}
        <div class="inner-text-player-join">
          {{ $t('_web_single_competition_status-info-and', 'and') }}
          <b
            >{{ numberOfPendingUsers }}
            {{ $t('_web_single_competition_status-info-more', 'more') }}</b
          >
          {{
            $t('_web_single_competition_status-info-joined', 'recently joined.')
          }}
        </div>
      </div>
      <div v-else-if="numberOfPendingUsers > 0" class="players-joined-wrapper">
        {{ lastToJoinPending }}
        <div class="inner-text-player-join">
          {{
            $t('_web_single_competition_status-info-joined', 'recently joined.')
          }}
        </div>
      </div>
    </div>
    <!--end of view two-->
    <!--end of view two-->
    <!--start of view 3 -->
    <!--start of view 3 -->
    <div
      v-if="
        (isRegClosed && users.length > 3) ||
          (users.length > 2 && matches.length < 3)
      "
      :class="color"
      class="wrapper-universal-home regStarted-status-info"
    >
      <div class="slots-wrapper">
        <img
          src="@/assets/images/Union.svg"
          width="24px"
          height="24px"
          alt="Slots"
        />
        <h2>{{ $t('_web_single_competition_status-info-slots', 'SLOTS') }}</h2>
        <h3 class="d-block">
          {{ currentTournament.squads_joined }}/{{ currentTournament.squads }}
        </h3>
      </div>
      <!--after gray line-->
      <!--after gray line-->
      <!--after gray line-->
      <div class="gray-line-player-card"></div>
      <div class="main-avatar-checked-in-wrap">
        <div class="imgs-avatar-outer-wrapper">
          <div class="imgs-avatar-wrapper">
            <img
              v-for="(avatar, index) in avatars"
              :key="index"
              :src="avatar"
              alt="user"
              width="24px"
              height="24px"
              class="small-image-avatar"
            />
          </div>
        </div>
        <div v-if="numberOfUsers > 4" class="players-joined-wrapper">
          {{ lastToJoinUsers }}
          <div class="inner-text-player-join">
            {{ $t('_web_single_competition_status-info-and', 'and') }}
            <b
              >{{ numberOfUsers }}
              {{ $t('_web_single_competition_status-info-more', 'more') }}</b
            >
            {{
              $t(
                '_web_single_competition_status-info-checked-in',
                'recently checked in.'
              )
            }}
          </div>
        </div>
        <div v-else-if="numberOfUsers > 0" class="players-joined-wrapper">
          {{ lastToJoinUsers }}
          <div class="inner-text-player-join">
            {{
              $t(
                '_web_single_competition_status-info-checked-in',
                'recently checked in.'
              )
            }}
          </div>
        </div>
      </div>
      <div v-if="matches.length" class="bracket-button-wrap-home">
        <router-link
          :to="bracketsLink"
          class="brackets-button"
          @click.prevent.stop
        >
          {{ $t('_web_single_competition_status-info-brackets', 'brackets') }}
          <img
            src="@/assets/images/icons/arror-right-new-cro.svg"
            alt="brackets"
            width="14px"
            height="12px"
          />
        </router-link>
      </div>
    </div>
    <!--end of view 3-->
    <!--start of view 4-->
    <transition name="fade" mode="out-in">
      <div
        v-if="matches[2] && dataReady"
        :class="color"
        class="wrapper-universal-home "
      >
        <div class="wrapper-inner">
          <button
            class="button-left"
            :class="{ 'btn-disabled': cardIndex === 0 }"
            @click="moveLeft"
          >
            <img
              src="@/assets/images/homepage/arrowNew.svg"
              class="arrow-left"
              alt="arrow left"
            />
          </button>
          <div class="all-card-wrap">
            <div
              class="card-results-wrapper"
              :class="cardIndex === 0 ? 'match-active' : 'match-hidden'"
            >
              <p class="round-title">{{ roundsToText(matches[2]) }}</p>
              <div class="players-wrap">
                <div class="player-one">
                  <img
                    :src="
                      matches[2].player1avatar === ''
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[2].player1avatar === undefined
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[2].player1avatar
                    "
                    alt="player"
                    width="16px"
                    height="16px"
                    class="player-avatar"
                  />
                  {{ matches[2].player1name }}
                  <p>{{ matches[2].player1score }}</p>
                </div>
                <div class="player-two">
                  <img
                    :src="
                      matches[2].player2avatar === ''
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[2].player2avatar === undefined
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[2].player2avatar
                    "
                    alt="player"
                    width="16px"
                    height="16px"
                    class="player-avatar"
                  />
                  {{ matches[2].player2name }}
                  <p>{{ matches[2].player2score }}</p>
                </div>
              </div>
            </div>
            <div class="gray-line-player-card"></div>
            <div
              class="card-results-wrapper"
              :class="cardIndex === 1 ? 'match-active' : 'match-hidden'"
            >
              <p class="round-title">{{ roundsToText(matches[1]) }}</p>
              <div class="players-wrap">
                <div class="player-one">
                  <img
                    :src="
                      matches[1].player1avatar === ''
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[1].player1avatar === undefined
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[1].player1avatar
                    "
                    alt="player"
                    width="16px"
                    height="16px"
                    class="player-avatar"
                  />
                  {{ matches[1].player1name }}
                  <p>{{ matches[1].player1score }}</p>
                </div>
                <div class="player-two">
                  <img
                    :src="
                      matches[1].player2avatar === ''
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[1].player2avatar === undefined
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[1].player2avatar
                    "
                    alt="player"
                    width="16px"
                    height="16px"
                    class="player-avatar"
                  />
                  {{ matches[1].player2name }}
                  <p>{{ matches[1].player2score }}</p>
                </div>
              </div>
            </div>
            <div class="gray-line-player-card"></div>
            <div
              class="card-results-wrapper"
              :class="cardIndex === 2 ? 'match-active' : 'match-hidden'"
            >
              <p class="round-title">{{ roundsToText(matches[0]) }}</p>
              <div class="players-wrap">
                <div class="player-one">
                  <img
                    :src="
                      matches[0].player1avatar === ''
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[0].player1avatar === undefined
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[0].player1avatar
                    "
                    alt="player"
                    width="16px"
                    height="16px"
                    class="player-avatar"
                  />
                  {{ matches[0].player1name }}
                  <p>{{ matches[0].player1score }}</p>
                </div>
                <div class="player-two">
                  <img
                    :src="
                      matches[0].player2avatar === ''
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[0].player2avatar === undefined
                        ? require('@/assets/images/defaults/user.svg')
                        : matches[0].player2avatar
                    "
                    alt="player"
                    width="16px"
                    height="16px"
                    class="player-avatar"
                  />
                  {{ matches[2].player2name }}
                  <p>{{ matches[2].player2score }}</p>
                </div>
              </div>
            </div>
            <div class="bracket-button-wrap-home bracket-desktop-btn">
              <router-link
                :to="bracketsLink"
                class="messages-button"
                @click.prevent.stop
              >
                <img
                  src="@/assets/images/matchesIcon.png"
                  alt="brackets"
                  width="24px"
                  height="24px"
                  class="matchesImg"
                />
                {{
                  $t('_web_single_competition_status-info-matches', 'Matches')
                }}
              </router-link>
            </div>
          </div>
          <button
            class="button-right"
            :class="{ 'btn-disabled': cardIndex === 2 }"
            @click="moveRight"
          >
            <img
              src="@/assets/images/homepage/arrowNew.svg"
              class="arrow-right"
              alt="arrow right"
            />
          </button>
        </div>
        <div class="bracket-button-wrap-home bracket-mobile-btn">
          <router-link
            :to="bracketsLink"
            class="messages-button"
            @click.prevent.stop
          >
            <img
              src="@/assets/images/matchesIcon.png"
              alt="brackets"
              width="24px"
              height="24px"
              class="matchesImg"
            />
            {{ $t('_web_single_competition_status-info-matches', 'Matches') }}
          </router-link>
        </div>
      </div>
    </transition>
    <!--end of view 4-->
    <!--end of view 4-->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
import matchHelper from '@/views/tournaments/management/brackets/mixins/matchHelper';
import { translate } from '@/mixins/translate';
import { API } from '@/api/config';
import images from '@/constants/images';

import { prop } from '@/utils/factories';
export default {
  name: 'StatusInfoHome',
  components: {},
  mixins: [
    tournamentHelpers,
    matchHelper,
    translate('_web_single_competition_status-info'),
  ],
  props: {
    color: prop(String),
    tournamentprop: prop(String), //prepaired for rendering on homepage component
  },
  data() {
    return {
      hours: '',
      minutes: '',
      seconds: '',
      lastToJoinUsers: '',
      numberOfUsers: 0,
      avatars: [],
      users: [],
      pendingUsers: [],
      pendingAvatars: [],
      lastToJoinPending: '',
      numberOfPendingUsers: 0,
      blocks: [],
      matches: [],
      numberOfRounds: 0,
      dataReady: false,
      cardIndex: 1,
    };
  },

  computed: {
    ...mapState('current', ['account']),
    ...mapState('my', ['account']),
    ...mapGetters([
      'currentTournament',
      'currentTournamentStages',
      'registrationTournament',
      'registrationMembers',
      'getBlocksByStageID',
      'stagesList',
    ]),

    stages({ currentTournament, currentTournamentStages }) {
      return (currentTournament && currentTournamentStages) || null;
    },

    bracketsLink() {
      const stagesList = {
        name: 'playerStagesList',
        params: {
          tournamentID: this.tournamentprop
            ? this.tournamentprop
            : this.tournamentID,
        },
      };
      const firstStage = this.currentTournamentStages[0];
      if (!firstStage) {
        return stagesList;
      }
      return this.hasOneStage && this.hasOneBlock
        ? {
            name: 'playerTournamentBracket',
            params: {
              tournamentID: this.tournamentprop
                ? this.tournamentprop
                : this.tournamentID,
            },
          }
        : stagesList;
    },
  },

  watch: {
    matches() {
      this.dataReady = true;
    },
  },

  async created() {
    this.fetchTournament(this.tournamentprop);
  },

  async mounted() {
    if (this.isAuthorized) {
      const users = await this.getTournamentParticipants({
        id: this.tournamentprop ? this.tournamentprop : this.tournamentID,
      });

      //getting number of qualified

      const allUsers = users.properties;
      const approvedUser = users.items.filter(user => {
        return user.fields_data.approvedTo === 'Qualified';
      });
      //getting all participants data
      //getting approved users
      this.users = approvedUser;
      //getting pending users
      this.pendingUsers = users.items;
      this.pendingAvatars = users.items
        .filter(user => {
          return user.fields_data.avatar === null
            ? user.members === null
              ? (user.fields_data.avatar = images.defaultUser)
              : (user.fields_data.avatar = images.defaultClan)
            : user.fields_data.avatar;
        })
        .map(user => {
          return user.fields_data.avatar === null
            ? '@/assets/images/icons/dummy/members.svg'
            : user.fields_data.avatar;
        })
        .slice(0, 4);

      this.lastToJoinPending = users.items
        .slice(0, 3)
        .map(user => {
          return user.fields_data.username
            ? user.fields_data.username
            : user.id;
        })
        .join(', ');
      this.numberOfPendingUsers =
        allUsers.total_quantity - 3 > 0
          ? allUsers.total_quantity - 3
          : allUsers.total_quantity;
      // console.log(users);
      //checkedIn status
      this.avatars = approvedUser
        .filter(user => {
          return user.fields_data.avatar === null
            ? user.members === null
              ? (user.fields_data.avatar = images.defaultUser)
              : (user.fields_data.avatar = images.defaultClan)
            : user.fields_data.avatar;
        })
        .map(user => {
          return user.fields_data.avatar === null
            ? '@/assets/images/icons/dummy/members.svg'
            : user.fields_data.avatar;
        })
        .slice(0, 4);
      //getting the numbers of rest users in user array for displaying text in DOM
      this.lastToJoinUsers = approvedUser
        .slice(0, 3)
        .map(user => {
          return user.fields_data.username
            ? user.fields_data.username
            : user.id;
        })
        .join(', ');
      this.numberOfUsers =
        allUsers.currently_qualified - 3 > 0
          ? allUsers.currently_qualified - 3
          : allUsers.currently_qualified;

      //fetching all matches
      const finishedMatches = await this.getMatchesData(
        this.tournamentprop ? this.tournamentprop : this.tournamentID
      );

      this.matches =
        finishedMatches &&
        finishedMatches.data.items
          .map(item => {
            if (item) {
              return {
                player1result: item.player_1_result,
                player1score: item.player_1_score,
                player1name:
                  item.player_1 === null || item.player_1 === undefined
                    ? this.$t(
                        '_web_single_competition_status-info-no-player',
                        'no player'
                      )
                    : item.player_1.name,
                player1avatar:
                  item.player_1 === null
                    ? ''
                    : item.player_1 === undefined
                    ? ''
                    : item.player_1.avatar,
                player2result: item.player_2_result,
                player2score: item.player_2_score,
                player2name:
                  item.player_2 === null || item.player_2 === undefined
                    ? this.$t(
                        '_web_single_competition_status-info-no-player',
                        'no player'
                      )
                    : item.player_2.name,
                player2avatar:
                  item.player_2 === null ? '' : item.player_2.avatar,
                round: item.round,
                type: item.type,
                rank: item.match_rank ? item.match_rank : '',
                squads: item.event.squads / 2,
              };
            }
          })
          .reverse();
    }
  },

  methods: {
    ...mapActions([
      'getTournamentParticipants',
      'fetchTournament',
      'fetchTournamentStage',
      'getCompetitionMatches',
      'getLastMatchesAction',
      'isAuthorized',
    ]),

    roundsToText(item) {
      if (item) {
        return item.rank === 'final'
          ? this.$t('_web_single_competition_status-info-final', 'FINAL')
          : item.rank === 'third_place'
          ? this.$t('_web_single_competition_status-info-third', 'THIRD PLACE')
          : item.rank === 'semi_final'
          ? this.$t('_web_single_competition_status-info-semi', 'SEMI FINAL')
          : item.round === 0 && item.rank === 'regular'
          ? `${this.$t(
              '_web_single_competition_status-info-round1',
              'Round 1/'
            )}${item.squads}`
          : `${this.$t(
              '_web_single_competition_status-info-round1',
              'Round 1/'
            )}${Math.pow(2, item.round)}`;
      }
    },

    async getMatchesData(TournamentID) {
      try {
        const response = await API(
          `/competitions/matches/search?event_id=${TournamentID}&battle_status=completed&order_by=dateEnd `
        );
        return await response;
      } catch (err) {
        console.log(err.message);
      }
    },

    moveLeft() {
      if (this.cardIndex === 0) {
        return;
      } else {
        this.cardIndex -= 1;
      }
    },
    moveRight() {
      const max = 2;
      if (this.cardIndex === max) {
        return;
      } else {
        this.cardIndex += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-universal-home {
  margin-top: 20px;
  font-family: $hr-font-family;
  width: 100%;
  min-height: 103px;
  // width: 729px;
  border: none;
  border-radius: 5px;
  background-color: #18181e;
}
.state-wait-to-start {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  h3 {
    margin-bottom: 0px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
  }
}
.time-till-start {
  display: flex;
  height: 48px;
  width: 206px;
  border-radius: 5px;
  background-color: #05285199;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
.user-names-in-tournament {
  display: inline-block;
}
.regStarted-status-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.slots-wrapper {
  position: relative;
  padding-left: 18px;
  height: 103px;
  font-family: $hr-font-family;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  min-width: 75px;
  img {
    margin-bottom: 11px;
  }
  h2 {
    font-weight: 400;

    color: $hr-text-secondary;
    font-size: $hr-text-tiny;
    margin: 0;
    line-height: 15.95px;
    margin-bottom: 2px;
  }
  h3 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    size: $hr-text-tiny;
    line-height: 13.2px;
  }
  .gray-line {
    background-color: $hr-text-secondary;
    width: 1px;
    height: 64px;
    position: absolute;
    right: 22px;
  }
}
.players-joined-wrapper {
  min-width: 450px;
  padding: 20px;
  // flex-grow: 3;
  font-weight: 700;
  display: inline-flex;
  flex-direction: column;
  font-size: $hr-text-small;
  .inner-text-player-join {
    font-weight: 400;
    b {
      font-weight: 700;
    }
  }
}
.small-image-avatar {
  // width: 48px;
  // height: 48px;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid $hr-bgselected;
}
.imgs-avatar-outer-wrapper {
  padding-left: 20px;
  min-width: 150px;
  height: 48px;
  .imgs-avatar-wrapper {
    position: relative;
    img:nth-child(1) {
      position: absolute;
      z-index: 4;
      position: absolute;
    }
    img:nth-child(2) {
      z-index: 3;
      position: absolute;
      transform: translateX(50%);
    }
    img:nth-child(3) {
      z-index: 2;
      position: absolute;
      transform: translateX(100%);
    }
    img:nth-child(4) {
      position: absolute;
      z-index: 1;
      transform: translateX(150%);
    }
  }
}
.brackets-button {
  display: flex;
  justify-content: space-around;
  padding: 0px 20px 0px 20px;
  align-items: center;
  justify-self: end;
  border: none;
  border-radius: 24px;
  width: 126px;
  height: 42px;
  background-color: #181c25;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
}
.bracket-button-wrap-home {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  padding: 30px;
  margin-right: 0;
}
.main-avatar-checked-in-wrap {
  display: flex;
  flex-direction: column;
  .players-joined-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
  .imgs-avatar-outer-wrapper {
    height: 24px;
    padding-left: 20px;
  }
}
.messages-button {
  display: flex;
  justify-content: center;
  padding: 0px 20px 0px 20px;
  align-items: center;
  justify-self: end;
  border: none;
  border-radius: 5px;
  width: 126px;
  height: 42px;
  background-color: #181c25;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
}
.card-results-wrapper {
  justify-content: space-between;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  font-family: $hr-font-family;
  width: 155px;
  height: 100%;
  padding: 23px;
  // padding-left: 23px;
  // padding-right: 23px;
  // padding-bottom: 23px;
  p {
    margin-bottom: 0;
  }
}
.all-card-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-left: 50px;
}
.round-title {
  font-size: $hr-text-tiny;
  color: #b8bcbe;
  padding-bottom: 8px;
}
.player-avatar {
  border-radius: 5px;
}
.player-one {
  p {
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
  }
  display: flex;
  align-items: center;
  font-size: $hr-text-tiny;
  font-weight: 700;
  gap: 7px;
}
.player-two {
  p {
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
  }
  display: flex;
  align-items: center;
  font-size: $hr-text-tiny;
  font-weight: 400;
  gap: 7px;
}
.players-wrap {
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 110%;
}
.gray-line-player-card {
  width: 1px;
  height: 66px;
  background-color: #474a56;
  margin-left: 3px;
  margin-right: 3px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-duration: 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition-duration: 2s;
}
html[dir='rtl'] {
  .all-card-wrap {
    margin-left: 0;
    margin-right: 30px;
  }
  .slots-wrapper {
    margin-right: 18px;
    padding-left: 0;
  }
  .imgs-avatar-outer-wrapper {
    padding-right: 20px;
    padding-left: 0px;
  }
  .imgs-avatar-wrapper {
    position: relative;
    img:nth-child(1) {
      position: absolute;
      z-index: 1;
      position: absolute;
    }
    img:nth-child(2) {
      z-index: 2;
      position: absolute;
      transform: translateX(-50%);
    }
    img:nth-child(3) {
      z-index: 3;
      position: absolute;
      transform: translateX(-100%);
    }
    img:nth-child(4) {
      position: absolute;
      z-index: 4;
      transform: translateX(-150%);
    }
  }
}
.heroPageSingleInfo {
  .bracket-button-wrap-home {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
  .main-avatar-checked-in-wrap {
    margin-bottom: auto;
    margin-top: auto;
  }
  .gray-line-player-card {
    margin-bottom: auto;
    margin-top: auto;
  }
  .all-card-wrap {
    justify-content: space-between;
    flex-grow: 1;
  }
  .bracket-button-wrap-home {
    justify-content: center;
    flex-grow: 0;
  }
  height: fit-content;
  margin: 0;
  width: 100%;
  z-index: 40;
  right: 20px;
  bottom: 20px;
  background: #262633b2;
  display: flex;
  // justify-content: center;
  align-items: flex-end;
}

.matchesImg {
  width: 19.94px;
  height: 13.83px;
  @include auto-rtl(margin-right, 0.5rem);
}

.messages-button {
  &:hover {
    background-color: white;
    color: black;
    .matchesImg {
      filter: invert(1);
    }
  }
}

.button-left {
  display: none;
}

.button-right {
  display: none;
}

.bracket-mobile-btn {
  display: none;
}

.wrapper-inner {
  display: flex;
}

@media screen and (max-width: 1195px) {
  .all-card-wrap {
    margin-left: 0;
  }

  .wrapper-universal-home {
    background: none;
  }

  .messages-button {
    background: #0e1116;
  }
}

@media screen and (max-width: 783px) {
  .bracket-desktop-btn {
    display: none;
  }

  .bracket-mobile-btn {
    display: block;
  }

  .wrapper-universal-home {
    flex-direction: column;
  }

  .messages-button {
    width: 100%;
  }

  .regStarted-status-info {
    flex-direction: row;
  }
}

@media screen and (max-width: 625px) {
  .gray-line-player-card {
    display: none;
  }

  .match-active {
    display: block;
  }

  .match-hidden {
    display: none;
  }

  .all-card-wrap {
    width: 100%;
  }

  .wrapper-universal-home {
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .button-left {
    display: block;
  }

  .button-right {
    display: block;
  }

  .arrow-left {
    width: 100%;
    .app-ltr & {
      rotate: 180deg;
    }

    .app-rtl & {
      rotate: 0deg;
    }
  }

  .arrow-right {
    width: 100%;

    .app-rtl & {
      rotate: 180deg;
    }
  }

  .btn-disabled {
    opacity: 0;
    pointer-events: none;
  }

  .wrapper-inner > button {
    background: none;
    border: none;
  }

  .bracket-button-wrap-home {
    padding: 0;
    margin-bottom: 0.5rem;
  }

  .regStarted-status-info {
    flex-direction: column;
  }

  .players-joined-wrapper {
    align-items: center;
  }
}
</style>
