var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"p-0 create-feed-postEdit",class:{ active: _vm.showActions },attrs:{"variant":!_vm.showActions ? 'transparent' : 'default'}},[_c('div',{staticClass:"create-feed-post_wrapper d-flex"},[_c('Avatar',{attrs:{"img-url":_vm.authorModel._avatar || _vm.authorModel.avatar,"size":48,"type":_vm.authorModel._type}}),_c('div',{staticClass:"h-ml-4 create-feed-postEdit_body-wrapper overflow-hidden"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"create-feed-postEdit_body",class:{
            'create-feed-postEdit-home':
              this.$router.history.current.name === 'account',
          }},[_c('TextInputMultiline',_vm._b({ref:"postInput",staticClass:"mb-0 create-feed-postEdit_input",class:{ 'create-feed-postEdit_input--collapsed': !_vm.showActions },attrs:{"placeholder":_vm.$t('_web_profile-placeholder', 'What’s happening?'),"has-counter":_vm.showActions,"rows":_vm.showActions ? _vm.INPUT_ROWS : 1,"hide-label":"","detect-links":!_vm.linkPreview,"detect-tags":"","detect-mentions":""},on:{"linkDetected":_vm.debouncedPreviewRequest,"isFocused":_vm.expand,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.altKey){ return null; }return _vm.publish($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.resetForm($event)}]},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},'TextInputMultiline',{ maxLength: _vm.maxLength },false)),(_vm.uploadFilesList.length)?_c('div',{staticClass:"img-preview",class:{ 'has-images mt-5': _vm.uploadFilesList.length > 0 }},_vm._l((_vm.uploadFilesList),function(file,index){return _c('div',{key:index,staticClass:"img-wrapper position-relative",class:[
                ("img-wrapper-" + (_vm.uploadFilesList.length)),
                { loading: !file.uploaded } ]},[(_vm.isValidFileFormat(file))?[(file.type.split('/')[0] === 'image')?_c('img',{attrs:{"src":file.obj}}):[_c('video',{on:{"loadedmetadata":function($event){return _vm.setVideoDuration(index, $event)}}},[_c('source',{attrs:{"type":"video/mp4","src":file.obj}})]),_c('span',{staticClass:"video-duration"},[_vm._v(_vm._s(_vm._f("formatTimeFromSeconds")(_vm.getVideoDuration(index))))])]]:_vm._e(),_c('AppIcon',{staticClass:"btn-delete",attrs:{"name":"close","width":"14"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeFile(file)}}}),(!file.uploaded)?_c('ProgressBar',{staticClass:"mt-3",attrs:{"current":file.currentUpload,"max":file.totalUpload,"show-value":""}}):_vm._e()],2)}),0):(_vm.showActions && _vm.linkPreview && _vm.isLinkPreviewValid)?_c('LinkPreview',_vm._b({on:{"remove":_vm.removePreview}},'LinkPreview',Object.assign({}, _vm.linkPreview, {editMode: true}),false)):_vm._e()],1),(_vm.isPollPost)?_c('PollOptionsEditor',{model:{value:(_vm.pollOptions),callback:function ($$v) {_vm.pollOptions=$$v},expression:"pollOptions"}}):_vm._e(),(
            _vm.showActions &&
              _vm.account &&
              _vm.account.isOrganization &&
              _vm.connectedSocials.length
          )?[_c('div',{staticClass:"d-flex align-items-center mt-6"},[_c('TextBlock',{staticClass:"h-mr-4",attrs:{"variant":"secondary","text":_vm.$t('_web_post_share-settings-label', 'Share to:')}}),_vm._l((_vm.connectedSocials),function(network){return _c('div',{key:network,staticClass:"d-flex align-items-center w-auto h-mr-4"},[_c('SocialIcon',{attrs:{"name":("" + (network === 'facebook' ? 'fb' : network)),"size":24}}),_c('Checkbox',{staticClass:"h-ml-3 flex-grow-0",attrs:{"checked":_vm.getSocialValue(network)},on:{"change":function ($event) { return _vm.setSocialValue(network, $event); }}})],1)})],2)]:_vm._e(),(_vm.showActions)?_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-6 post-actions",class:{ disabled: _vm.isActionBarDisabled },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"post-select-files",class:{ disabled: _vm.isAttachBtnDisabled },on:{"click":function($event){$event.stopPropagation();}}},[_c('AppIcon',{class:{ disabled: _vm.isAttachBtnDisabled },attrs:{"name":"editable/upload","width":"18"}}),_c('input',{attrs:{"type":"file","accept":_vm.validFilesFormats,"multiple":""},on:{"change":_vm.prepareImagesForUpload}})],1),(!_vm.postModel)?_c('AppIcon',{staticClass:"h-ml-4 cursor-pointer",class:{ disabled: _vm.isPollBtnDisabled },attrs:{"name":"poll","width":"24"},on:{"click":_vm.togglePollCreation}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-items-center poll-options"},[(_vm.isPollPost)?_c('HeraFormBtn',{staticClass:"btn-secondary-simple remove-btn",attrs:{"label":_vm.$t('_web_create_post_remove-poll', 'Remove Poll')},on:{"click":function($event){$event.stopPropagation();return _vm.removePoll($event)}}}):_vm._e(),_c('HeraFormBtn',{staticClass:"btn-accent-simple",class:{
                'is-loading': _vm.createPostProceed,
              },attrs:{"disabled":_vm.isShareBtnDisabled,"label":!_vm.postModel
                  ? _vm.$t('_web_create_post_create-post', 'Create')
                  : _vm.$t('_web_create_post_save-post', 'Save')},on:{"click":function($event){$event.stopPropagation();return _vm.publish($event)}}}),_c('HeraFormBtn',{staticClass:"btn-cancel",attrs:{"label":_vm.$t('web_create-clan_cancel', 'Cancel')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.resetForm($event)}}})],1)]):_vm._e()],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }