var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-chat-chat d-flex flex-column"},[_c('ChatHeader',{attrs:{"title":_vm.chat.title,"subtitle":(_vm.participantsCountToRender + " " + (_vm.$t(
        '_web_tournament_sidechat_participants',
        'participants'
      ))),"avatar":_vm.avatarType,"avatar-uri":_vm.newAvatar ? _vm.newAvatar : _vm.chat.avatar,"active":"","has-back-icon":"","can-edit":""},on:{"back":function($event){return _vm.$emit('closeChat')},"action":function($event){return _vm.$emit('editChat')}}}),_c('div',{ref:"messages",staticClass:"slider-chat-chat__messages custom-scrollbar d-flex flex-column-reverse align-items-center",on:{"scroll":_vm.scrollHandler}},[_c('ListLoader',{staticClass:"h-100 w-100",attrs:{"is-loading":_vm.isLoading && !_vm.messages.length,"is-empty-list":!_vm.undeliveredMessages.length && !_vm.messages.length,"dummy-props":{
        iconName: 'dummy/chat',
        size: 200,
        subheaderText: _vm.$t(
          '_web_sidechat-dialog_is-empty',
          'You have no messages at the moment'
        ),
        text: _vm.$t(
          '_web_sidechat-dialog_is-empty_subheader',
          'Do not hesitate and send a first one'
        ),
      }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messages && _vm.messages.length),expression:"messages && messages.length"}],ref:"chatDialog",staticClass:"w-100 h-100 custom-scrollbar chat-dialog",attrs:{"slot":"list","tabindex":"-1"},slot:"list"},[_c('InfiniteLoadBothSides',{attrs:{"list":_vm.messages,"list-name":"messages","is-disabled":_vm.isLoading || !_vm.prevPageToken,"is-disabled-bottom":_vm.isLoading || !_vm.nextPageToken,"in-container":true,"activation-gap":1},on:{"ScrollToTop":_vm.fetchMessageList,"ScrollToBottom":_vm.fetchMessageListTop},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _c('div',{staticClass:"d-flex flex-column-reverse"},[_vm._l((_vm.undeliveredMessages),function(item,i){return _c('Message',{key:'item-' + 'index' + '-' + i,attrs:{"data":item,"is-post":_vm.isChannel,"undelivered":""},on:{"delete":function($event){return _vm.removeUndeliveredMessage(item.id)},"resend":function($event){return _vm.resendUndeliveredMessage(item)}}})}),_vm._l((_vm.messages),function(item,i){return _c('Message',{key:'item-' + 'index' + '-' + i,ref:item.id,refInFor:true,class:item.id === _vm.chat.focus && 'colorchange',attrs:{"data":item,"is-post":_vm.isChannel},on:{"delete":function($event){return _vm.removeMessage(item)},"openComments":function($event){return _vm.openComments(item)},"openUserInfo":function($event){return _vm.openUserInfo(item.sender)}}})})],2)}}])})],1)])],1),(!_vm.openedPost && !_vm.userInfo.visible)?_c('SCInput',{on:{"submit":_vm.messageSendHandler}}):_vm._e(),(_vm.openedPost)?_c('Post',{attrs:{"data":_vm.openedPost,"can-edit":""},on:{"closePost":_vm.closeComments}}):_vm._e(),(_vm.userInfo.visible)?_c('UserInfo',{attrs:{"user-id":_vm.userInfo.id},on:{"close":_vm.closeUserInfo}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }