<template>
  <div class="py-4 poll-editor">
    <div class="row no-gutters">
      <div class="poll-wrapper">
        <ul class="m-0 p-0 flex-grow-1 list-unstyled">
          <li
            v-for="(item, index) in options"
            :key="item.key"
            class=" position-relative poll-wrapp"
          >
            <div class="break-line-poll"></div>
            <div class="input-icon-row">
              <TextInputMultiline
                v-model="options[index].value"
                v-validate="'required'"
                class="input-poll flex-grow-1"
                :placeholder="getOptionLabel(index)"
                :maxlength="MAX_POLL_OPTION_CHARS"
                :error="errors.first(`poll-option-${item.key}`)"
                :data-vv-name="`poll-option-${item.key}`"
                :data-vv-as="getOptionLabel(index)"
                variant="input-like"
                @keydown.enter.prevent
                @focus="isInputFocused = true"
                @blur="isInputFocused = false"
              />
              <Icon
                v-if="options.length > 2"
                class="cursor-pointer mb-3 mx-4"
                :class="{
                  disabled: isOptionsMinCount,
                  'icon-onfocus': isInputFocused,
                }"
                name="close"
                width="20"
                @click="deleteOption(index)"
              />
            </div>
          </li>
          <div class="break-line-poll"></div>
        </ul>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="option-spacing">
        <p class="more-options" @click="addOption">
          {{ $t('_web_add-more-option', 'Add more options +') }}
        </p>
        <p class="max-option">{{ $t('_web_poll_max-option', 'Maximum 5') }}</p>
      </div>
      <div class="poll-wrapper">
        <div class="poll-editor_length-selector">
          <FormDropdown
            v-model="length"
            v-validate="'required'"
            :list="LENGTH_VARIANTS"
            variant="input-like"
            class="dropdown-poll"
            :class="{ 'is-invalid': errors.first('poll-length') }"
            :label="$t('_web_create_post_poll-duration', 'Poll duration')"
            name="poll-length"
            border-bot
            theme-dark
            :error="errors.first('poll-length')"
          >
            <template slot="chosen" slot-scope="{ chosenItem }">
              <span>{{ getPollLengthLabel(chosenItem.label) }}</span>
            </template>

            <template slot="item" slot-scope="{ item }">{{
              getPollLengthLabel(item.label)
            }}</template>
          </FormDropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from '@/utils/lodashUtils';
import FormDropdown from '@/components/molecules/form/HeraFormDropdown';
import TextInputMultiline from '@/components/molecules/form/TextInputMultiline';
import Icon from '@/components/atoms/Icon';

const MIN_POLL_OPTIONS = 2;
const MAX_POLL_OPTIONS = 5;
const MAX_POLL_OPTION_CHARS = 100;

const LENGTH_VARIANTS = [
  { label: '1 h', value: 60 * 60 },
  { label: '6 h', value: 60 * 60 * 6 },
  { label: '12 h', value: 60 * 60 * 12 },
  { label: '1 day', value: 60 * 60 * 24 },
  { label: '2 day', value: 60 * 60 * 24 * 2 },
  { label: '3 days', value: 60 * 60 * 24 * 3 },
];

const createEmptyOption = () => ({ value: '', key: uniqueId('poll-option_') });

export default {
  inject: ['$validator'],

  components: {
    TextInputMultiline,
    Icon,
    FormDropdown,
  },

  data() {
    return {
      options: Array.from({ length: MIN_POLL_OPTIONS }).map(createEmptyOption),
      length: null,
      isInputFocused: false, // Add this line

      LENGTH_VARIANTS,
      MAX_POLL_OPTION_CHARS,
    };
  },

  computed: {
    isOptionsMinCount() {
      return this.options.length <= MIN_POLL_OPTIONS;
    },

    isOptionsMaxCount() {
      return this.options.length >= MAX_POLL_OPTIONS;
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.onChange();
      },
    },
    length() {
      this.onChange();
    },
  },

  created() {
    this.length = LENGTH_VARIANTS[0];
  },

  methods: {
    addOption() {
      if (this.isOptionsMaxCount) return;
      this.options.push(createEmptyOption());
    },

    deleteOption(index) {
      if (this.isOptionsMinCount) return;
      this.options.splice(index, 1);
      this.$validator.reset(`poll-option-${index}`);
    },

    getOptionCharsLeft(index) {
      return MAX_POLL_OPTION_CHARS - this.options[index].value.length;
    },
    getPollLengthLabel(label) {
      return this.$t(
        `_web_create_post_poll-length-${label.replace(/\s/g, '-')}`,
        label
      );
    },
    getOptionLabel(index) {
      return this.$t(
        '_web_create_post_add-poll-option_redesigne',
        'Type here option {index}',
        {
          index: index + 1,
        }
      );
    },

    onChange() {
      this.$emit('input', {
        options: this.options.map(({ value: text }) => ({ text })),
        end_time: Math.round(Date.now() / 1000) + this.length.value,
      });
    },
  },
};
</script>

<style lang="scss">
.poll-editor {
  input[type='text'],
  .dropdown-toggle {
    height: 2rem;
  }

  .input-poll {
    width: 100%;
  }

  &_length-selector {
    min-width: 35%;
    @include auto-rtl(border-left, 2px solid $ph-card);
  }

  &_option-controls {
    width: 1.2rem;
  }

  &_option-add {
    @include auto-rtl(right, -0.75rem);
  }

  .icon-add-wrap {
    width: 20px;
  }
}

.poll-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
}

.icon-onfocus {
  display: none;
}

.break-line-poll {
  width: 100%;
  height: 1px;
  background: #30374a;
}

.poll-wrapp {
  display: flex;
  flex-direction: column;
}

.input-icon-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.option-spacing {
  margin-top: 24px;
  margin-bottom: 24px;
}

.input-poll {
  .wrapper-control {
    textarea {
      margin-top: 13px;
      margin-bottom: 13px;
      background: transparent;
      font-family: 'Exo';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */
      color: #ffffff !important;
    }
  }
}

.input-poll {
  .wrapper-control {
    textarea:focus {
      border: 1px solid #4b5672;
      background: #181c25;
    }
  }
}

.max-option {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #474a56;
}

.more-options {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9d9cf7;
  cursor: pointer;
  margin-bottom: 0px;
}
.dropdown-poll {
  .wrapper-control {
    button {
      width: 107px;
    }
  }
}
</style>
