const ROUTER_HISTORY_KEY = 'routerHistory';
const LIMIT = 20; // max routes to store

export const getRouterHistory = () =>
  JSON.parse(localStorage.getItem(ROUTER_HISTORY_KEY));

export const resetRouterHistory = () =>
  localStorage.setItem(ROUTER_HISTORY_KEY, JSON.stringify([]));

export const storeRouteToRouterHistory = pathName => {
  if (!pathName) return;

  let routeHistoryJSON = localStorage.getItem(ROUTER_HISTORY_KEY);
  let routeHistory = JSON.parse(routeHistoryJSON) || [];

  routeHistory.push(pathName);

  if (routeHistory.length > LIMIT) routeHistory.splice(0, 1);

  localStorage.setItem(ROUTER_HISTORY_KEY, JSON.stringify(routeHistory));
};
