var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative flex-grow-1"},[_c('Widget',{staticClass:"mb-6",attrs:{"title":_vm.$t('_web_tournament_manage-stage-title', 'Manage Stage') +
        ' ' +
        _vm.$t('_web_tournament_manage-participants', 'Participants'),"actions-menu":_vm.actionsBtnItems},on:{"input":_vm.onFilterChange},model:{value:(_vm.filters.search_term),callback:function ($$v) {_vm.$set(_vm.filters, "search_term", $$v)},expression:"filters.search_term"}}),_c('InfoPanel',{staticClass:"my-5",scopedSlots:_vm._u([{key:"addons",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('Btn',{staticClass:"btn-accent-simple",attrs:{"label":_vm.$$t('Re-draw Brackets'),"disabled":!_vm.isStageScheduled || _vm.drawLoading},on:{"click":function($event){return _vm.confirmReDraw(_vm.currentStageInfo)}}})],1)]},proxy:true}])},[_vm._v("\n    "+_vm._s(_vm.$$t(
        'Finalize drawing of each group and publish brackets.\nParticipants can be replaced between blocks in Stage Participants list',
        'info-text'
      ))+"\n    ")]),_c('HorizontalTabs',_vm._b({},'HorizontalTabs',{ tabs: _vm.tabs, variant: 'folders', notExactMatch: true },false)),_c('div',{staticClass:"stage-players-list"},[_c('Paginated',_vm._b({on:{"change":_vm.fetchList}},'Paginated',{
        totalItems: _vm.totalItems,
        hidePagination: !_vm.players.length,
        pageSize: _vm.pageSize,
        inContainer: true,
      },false),[_c('Table',{staticClass:"stage-participants-table mb-5 col",attrs:{"columns":_vm.visibleColumns,"items":_vm.players,"selectable":true,"has-menu":true,"show-subheader":true,"max-items-length":_vm.isTotalSelected ? 0 : _vm.totalItems},on:{"select-all":_vm.selectAllCheckboxHandler,"show-menu":_vm.onSettingsModalOpen,"clickOnRow":_vm.setActiveRow,"select":_vm.checkTotalCount},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('PlayersTableControls',{staticClass:"w-100 h-mr-6",attrs:{"selected":_vm.amountFooterCounter,"approved-to-list":_vm.distinctPlayersApprovedTo,"attributes-list":_vm.distinctPlayersAttributes,"show-attributes":"","show-blocks":"","show-approved":"","blocks-list":_vm.blocksList,"stage-info":_vm.currentStageInfo},on:{"approvedToSet":function (approvedTo) { return _vm.setPlayersExtendedData(_vm.selectedItems, { approvedTo: approvedTo }, true); },"attributesSet":function (attributes) { return _vm.setPlayersExtendedData(_vm.selectedItems, { attributes: attributes }); },"blockSet":function (block) { return _vm.setActivePlayerAssignBlock(block); }}})]},proxy:true},{key:"head-item",fn:function(ref){
                var column = ref.column;
return [_c('PlayersTableHeadCell',{attrs:{"column-name":column.name,"sort-icon":_vm.sortFields.field !== _vm.transformField(column.name)
                ? 'sort'
                : _vm.sortFields.order === 'incr'
                ? 'sort-asc'
                : 'sort-dsc'},on:{"sort":_vm.handleSort}})]}},{key:"subhead-item",fn:function(ref){
                var column = ref.column;
return [(_vm.distinctPlayersAttributes && column.name === 'attributes')?_c('CustomFilter',{staticClass:"h-mr-2 custom-filter",attrs:{"clear-if-selected-all":"","options":_vm.distinctPlayersAttributes},on:{"input":_vm.onFilterChange},model:{value:(_vm.filters.attributes_filter),callback:function ($$v) {_vm.$set(_vm.filters, "attributes_filter", $$v)},expression:"filters.attributes_filter"}}):_vm._e(),_c('CustomFilter',{directives:[{name:"show",rawName:"v-show",value:(column.name === 'check-in'),expression:"column.name === 'check-in'"}],staticClass:"custom-filter",attrs:{"clear-if-selected-all":"","options":_vm.checkinOptions,"disabled":_vm.isLoading},on:{"close":_vm.onFilterChange},model:{value:(_vm.filters.checked_in),callback:function ($$v) {_vm.$set(_vm.filters, "checked_in", $$v)},expression:"filters.checked_in"}})]}},{key:"body-item",fn:function(ref){
                var item = ref.item;
                var column = ref.column;
return [(!item.isAddon)?_c('PlayersTableCell',{attrs:{"column-name":column.name,"row":item,"is-clan":_vm.properties.is_clan,"all-attributes":_vm.properties.possible_attributes,"groups":_vm.blocksList},on:{"chat":_vm.startChat,"checkIn":_vm.toggleCheckIn,"attributesSet":function (attributes) { return _vm.setPlayersExtendedData([item], { attributes: attributes }); }}}):_vm._e()]}},{key:"body-item-addon",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"py-0",attrs:{"colspan":_vm.visibleColumns.length}},[_c('Table',{staticClass:"py-0",attrs:{"columns":_vm.memberColumns,"items":item.members},scopedSlots:_vm._u([{key:"head-item",fn:function(ref){
                var column = ref.column;
return [_c('PlayersTableHeadCell',{attrs:{"column-name":column.name}})]}},{key:"body-item",fn:function(ref){
                var player = ref.item;
                var column = ref.column;
return [(column.name === 'Username')?_c('PlayersTableUsernameCell',{attrs:{"account":Object.assign({}, {id: player.id}, player.fields_data)}}):_c('TextBlock',{attrs:{"text":player.fields_data[column.name]}})]}}],null,true)})],1)]}}])})],1)],1),_c('Popover',{staticClass:"table-settings-menu",attrs:{"position":_vm.tableSettingsBtnPosition,"closest":_vm.$el,"float":['right']},on:{"toggle":_vm.onMenuToggle}},[(_vm.optionalColumns.length)?_c('PlayersTableSettingsModal',{attrs:{"clan":_vm.properties.is_clan,"optional-columns":_vm.optionalColumns},on:{"update":_vm.onSettingsModalClose}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }