import auth from './endpoints/auth';
import accounts from './endpoints/accounts';
import settings from './endpoints/settings';
import files from './endpoints/files';
import translations from './endpoints/translations';
import profiles from './endpoints/profiles';
import platforms from './endpoints/platforms';
import players from './endpoints/players';
import clans from './endpoints/clans';
import conversations from './endpoints/conversations';
import posts from './endpoints/posts';
import maps from './endpoints/maps';
import tags from './endpoints/tags';
import requests from './endpoints/requests';
import notifications from './endpoints/notifications';
import organizations from './endpoints/organizations';
import proleagues from './endpoints/proleagues';
import competitions from './endpoints/competitions';
import battles from './endpoints/battles';
import socials from './endpoints/socials';
import statistic from './endpoints/statistic';
import tournaments from './endpoints/tournaments';
import newsfeeds from './endpoints/newsfeeds';
import comments from './endpoints/comments';
import complaints from './endpoints/complaints';
import vanityUrls from './endpoints/vanityUrls';
import metrics from './endpoints/metrics';
import regions from './endpoints/regions';
import payments from './endpoints/payments';
import shop from './endpoints/shop';

export default {
  shop,
  auth,
  accounts,
  settings,
  translations,
  files,
  conversations,
  maps,
  posts,
  clans,
  profiles,
  platforms,
  players,
  tags,
  requests,
  notifications,
  organizations,
  proleagues,
  competitions,
  battles,
  socials,
  statistic,
  tournaments,
  newsfeeds,
  comments,
  complaints,
  vanityUrls,
  metrics,
  regions,
  payments,
};
