<template>
  <div class="clan-item card">
    <div
      class="card-img-top d-flex justify-content-center align-items-center"
      :style="clan.cover ? `background-image: url('${clanCoverURI}');` : null"
    >
      <AppIcon
        v-if="!clan.cover"
        name="full_logo"
        :fill="$scss.secondaryTextBg"
        width="100%"
      />
    </div>
    <div
      class="clan-status-bar d-flex justify-content-between mt-4 align-items-center"
    >
      <div class="d-flex align-items-center w-100 overflow-hidden">
        <LinkClan :id="clan.id" class="d-block position-relative text-light">
          <ClanAvatar
            :size="48"
            class="clan-item-avatar"
            type="clan"
            :img-url="clan.logo"
          />
        </LinkClan>
        <div class="clan-info h-ml-3 w-100 overflow-hidden">
          <LinkClan
            :id="clan.id"
            class="link-clan d-block text-truncate"
            :verified="clan.approved"
          >
            {{ clan.name }}
          </LinkClan>
          <div class="clan-follow-count">
            {{ clan.followers_count | thousandsShort }}
          </div>
        </div>
      </div>
      <FollowBtn class="flex-shrink-0" :model="accountModel" />
    </div>
  </div>
</template>

<script>
import { CLAN } from '@/constants/accounts.js';
import AccountModel from '@/models/Account';
import { join } from '@/mixins/accountActions/clan';
// import AppIcon from '@/components/atoms/Icon';
import ClanAvatar from '@/components/images/Avatar';
import LinkClan from '@/components/links/Clan';
import FollowBtn from '@/components/common/FollowBtn';
import AppIcon from '@/components/atoms/Icon.vue';
export default {
  name: 'ClanItem',
  components: {
    LinkClan,
    // AppIcon,
    ClanAvatar,
    FollowBtn,
    AppIcon,
  },
  mixins: [join],
  props: {
    clan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accountModel: {},
    };
  },
  computed: {
    clanCoverURI() {
      return (this.clan.cover || '').replace('.original.', '.500.');
    },
  },
  created() {
    this.accountModel = new AccountModel(CLAN, this.clan.id).update(this.clan);
  },
};
</script>

<style lang="scss">
.clan-item {
  background: $ph-card !important;
  width: 100%;
  border-radius: $ph-large-radius !important;
  padding: $ph-medium-space;
  .follow-button {
    min-width: unset;
    padding: 5px;
    span {
      font-size: small;
    }
  }
  .card-img-top {
    background-color: $ph-bg;
    height: 144px;
    border-radius: $ph-medium-radius;
  }
  .clan-status-bar {
    .link-clan {
      color: $ph-primary-text;
      font-weight: bold;
    }
    .clan-follow-count {
      color: $ph-secondary-text;
      font-size: $ph-caption-bold-font-size;
      line-height: $ph-caption-bold-line-height;
    }
  }
}
</style>
