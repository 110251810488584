<template>
  <div
    class="embed-responsive embed-responsive-custom"
    :style="{ '--embed': (embed.height || 1) / (embed.width || 1) }"
  >
    <div class="embed-responsive-item">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmbedBlock',
  props: {
    embed: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.embed-responsive-custom {
  --embed: 1;

  &::before {
    padding-top: calc(100% * var(--embed));
  }
}
</style>
