<template>
  <div ref="qrcode" :class="{ 'd-none': hidden }" class="text-center"></div>
</template>

<script>
import * as QRCode from 'easyqrcodejs';
import { debounce } from '@/utils/lodashUtils';
export default {
  name: 'QRCode',
  props: {
    options: {
      type: Object,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    options: {
      handler: function() {
        if (typeof this.$options.debouncedQRUpdate === 'function') {
          this.$options.debouncedQRUpdate();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$options.debouncedQRUpdate = debounce(this.generateQR, 500);
    // this.$options.debouncedQRUpdate();
  },
  mounted() {
    this.generateQR();
  },
  methods: {
    generateQR() {
      this.$refs.qrcode.innerHTML = '';
      new QRCode(this.$refs.qrcode, {
        ...this.options,
        crossOrigin: 'anonymous',
        onRenderingEnd: this.onQrRendered,
      });
    },
    onQrRendered(options, dataUrl) {
      this.$emit('qrReady', dataUrl);
    },
  },
};
</script>
