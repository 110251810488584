import { intersection } from '@/utils/lodashUtils';

// const TOKEN_VALIDITY_THRESOLD = 3570000 // token expires in 30 sec
const TOKEN_VALIDITY_THRESOLD = 30000;

export const isTokenValid = token => {
  let now = Date.now();
  return token.expires - now > TOKEN_VALIDITY_THRESOLD;
};

export const isScrolledToBottom = el => {
  if (!el) return false;
  return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
};

export const scrollToBottom = el => {
  if (!el) {
    return;
  }
  el.scrollTop = el.scrollHeight;
};
export const scrollToTop = el => {
  if (!el) {
    return;
  }
  el.scrollTop = 0;
};

export const copyToClipboard = text => {
  const el = document.createElement('input');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/**
 * replace long-spaces i string. Ex: `     foo          bar       ` -> `foo bar`
 * @param str {string} - input string
 * @returns {string} - result
 */
export const textCollapsed = str => {
  return str
    .trim()
    .split(/[\s]{2,}/)
    .join(' ');
};

/**
 * Check is vue-router routes are equal. Hash ignore.
 * Ex '/foo/' != '/bar/'
 * Ex '/foo/#hash' == '/foo/'
 * @param {Object} from - Vue route
 * @param {Object} to  - Vue route
 * @returns {Boolean} - result
 */
export const areRoutesEqual = (from, to) => {
  return to.fullPath.split('#')[0] === from.fullPath.split('#')[0];
};

export const getFileExt = fileName => {
  return fileName ? fileName.replace(/^.+\.(.*?)$/, '$1') : '';
};

export const getFileNameWithoutExt = fileName => {
  return fileName ? fileName.replace(/\.(.*?)$/, '') : '';
};

export const makeGiphyUrl = id => {
  return `https://media.giphy.com/media/${id}/giphy.gif`;
};

export const intersected = (...arrays) => {
  return !!intersection(...arrays).length;
};

export const addLeadingZero = num => `${+num < 10 ? '0' : ''}${num}`;
