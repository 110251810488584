<template>
  <Panel :variant="['rounded-small', 'default']" class="w-50">
    <div class="stage-info__title pb-5 d-flex align-items-center">
      <Subheader class="mr-5 mb-0">{{ stage.title }}</Subheader>
      <StatusLabel class="mr-4" v-bind="{ status: stage.status }" />
    </div>
    <div
      v-for="setting in stageRelated"
      :key="setting.id"
      class="stage-info__type d-flex align-items-center p-3"
      :class="[`stage-info__${setting.id}`]"
    >
      <Icon
        :name="setting.icon"
        :fill="$scss.lightBordersColor"
        class="h-mr-4"
      />
      <div>
        {{ setting.label }}
      </div>
      <div v-if="setting.value" class="h-ml-auto">
        {{ setting.value }}
      </div>
    </div>
    <div
      v-for="setting in matchRelated"
      :key="setting.id"
      class="stage-info__type d-flex align-items-center p-3"
      :class="[`stage-info__${setting.id}`]"
    >
      <Icon
        :name="setting.icon"
        :fill="$scss.lightBordersColor"
        class="h-mr-4"
      />
      <div>
        {{ setting.label }}
      </div>
      <div v-if="setting.value" class="h-ml-auto">
        {{ setting.value }}
      </div>
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import { STAGE_TYPES } from '@/constants/tournaments';
import { propRequired } from '@/utils/factories';
import Panel from '@/components/atoms/Panel';
import Icon from '@/components/atoms/Icon';
import StatusLabel from '@/components/molecules/StatusLabel';
import Subheader from '@/components/atoms/Subheader';

export default {
  name: 'StageSummaryCard',
  components: {
    Subheader,
    Panel,
    Icon,
    StatusLabel,
  },
  mixins: [translate('_web_tournament_dashboard')],
  props: {
    stage: propRequired(Object),
  },
  computed: {
    ...mapGetters(['currentTournamentStages']),
    settings() {
      return [
        {
          key: 'type',
          icon: this.$lodash.kebabCase(this.getStageKey(this.stage)),
          label: this.getStageName(this.stage),
          isVisible: true,
        },
        {
          key: 'participants',
          icon: 'solo',
          label: this.$t('_web_participants', 'Participants'),
          value: this.stage.participants_required,
          isVisible: true,
        },
        {
          key: 'check-mark',
          icon: 'user-check',
          label: this.$t('_web_check-in', 'Check-in'),
          value: this.getOnOffLabel(this.stage.check_in_enabled),
          isVisible: true,
        },
        {
          key: 'duration',
          icon: 'time',
          label: this.$t('_web_match_duration', 'Match duration'),
          value: this.$filters.secToDuration(
            this.stage.block_configuration.match_duration
          ),
          isVisible: true,
          isMatchRelated: true,
        },
        {
          key: 'checked_in',
          icon: 'check-mark',
          label: this.$t('_web_check-in', 'Checked-in'),
          value: `${this.stage.checked_in || 0}/${
            this.stage.participants_required
          }`,
          isVisible: this.stage.check_in_enabled,
        },
        {
          key: 'best-of',
          icon: 'flag',
          label: this.$t('_web_best-of', 'Best of'),
          value: this.$lodash.get(this.stage, 'block_configuration.best_of', 0),
          isVisible: Boolean(
            this.$lodash.get(this.stage, 'block_configuration.best_of', false)
          ),
          isMatchRelated: true,
        },
        {
          key: 'attachments',
          icon: 'attachment',
          label: this.$t('_web_attachments', 'Attachments'),
          value: this.getOnOffLabel(
            this.$lodash.get(
              this.stage,
              'block_configuration.attachment_required'
            )
          ),
          isVisible: true,
          isMatchRelated: true,
        },
        {
          key: 'autocomplete',
          icon: 'play-circle',
          label: this.$t('_web_matches-autocomplete', 'Autocomplete'),
          value: this.getOnOffLabel(
            this.$lodash.get(
              this.stage,
              'block_configuration.autofinish_battle'
            )
          ),
          isVisible: true,
          isMatchRelated: true,
        },
        // {
        //   key: 'results-provided',
        //   icon: 'picture',
        //   label: this.$$t('Results provided'),
        //   value: `${this.stage.results_provided || 0}/${
        //     this.stage.participants_required
        //   }`,
        //   isVisible: this.$lodash.get(
        //     this.stage,
        //     'block_configuration.attachment_required'
        //   ),
        // },
      ];
    },
    visibleSettings() {
      return this.settings.filter(el => el.isVisible);
    },
    matchRelated() {
      return this.visibleSettings.filter(el => el.isMatchRelated);
    },
    stageRelated() {
      return this.visibleSettings.filter(el => !el.isMatchRelated);
    },
  },
  methods: {
    getOnOffLabel(prop) {
      return prop ? this.$t('_web_on', 'ON') : this.$t('_web_off', 'OFF');
    },
    getStageKey() {
      return this.stage.block_configuration.type;
    },
    getStageName() {
      const stageType = this.getStageKey();
      return this.$t(
        '_web_tournament-management_stage-type-' + stageType,
        STAGE_TYPES.find(item => item.type === stageType).name
      );
    },
  },
};
</script>

<style lang="scss">
.stage-info {
  &__title {
    border-bottom-style: none !important;
  }
}
</style>
