<template>
  <div
    class="main-wrapper-sc"
    :class="{
      anounnced: isScheduled,
      started: isStarted,
      regStarted: isRegStarted,
      regClosed: isRegClosed,
      finished: isClosed,
      'comments-on-single-tour': $route.path.includes('tournament'),
    }"
  >
    <div class="main-inner">
      <main class="content-inner-wrap-single-comp">
        <PinnedTournamentHero
          :tournament="currentTournament"
          details="true"
          :class="`mobile-container`"
        />
        <div class="mobile-hide">
          <StatusInfo v-if="isAuthorized" :key="0" />
        </div>
        <Timeline />
        <TournamentInfoDescription />
        <div class="btn-outer-wrap">
          <div class="btn-options-wrap">
            <div
              v-if="isAuthorized"
              :class="contentOption === `comment` ? `active-btn` : ``"
              class="btn-options"
              @click="() => (contentOption = `comment`)"
            >
              {{ $t('_web_comments', `comments`) }}
            </div>
            <div
              :class="contentOption === `info` ? `active-btn` : ``"
              class="btn-options"
              @click="() => (contentOption = `info`)"
            >
              {{ $t('_web_info', `Info`) }}
            </div>
            <div
              v-if="isAuthorized"
              :class="contentOption === `matches` ? `active-btn` : ``"
              class="btn-options"
              @click="() => (contentOption = `matches`)"
            >
              {{ $t('_web_single_competition_status-info-matches', `Matches`) }}
            </div>
          </div>
        </div>
        <div class="mobile-hide">
          <Comments
            v-if="isAuthorized"
            :post="currentTournament"
            :type="'tournament'"
            :owner="currentTournament.owner"
          />
        </div>
        <div v-if="contentOption === `comment`" class="comment-mobile">
          <Comments
            v-if="isAuthorized"
            :post="currentTournament"
            :type="'tournament'"
            :owner="currentTournament.owner"
          />
        </div>
        <div v-if="contentOption === `info`" class="info-mobile">
          <StatusInfo
            v-if="isAuthorized"
            :key="1"
            :show-matches="true"
            :info="true"
          />
          <TournamentInfo />
          <p v-if="rulesText" class="read-rules" @click="openModalRules">
            {{ $t('_web_read-all-rules', ' Read all rules') }}
          </p>
        </div>
        <div v-if="contentOption === `matches`" class="info-mobile">
          <StatusInfo
            v-if="isAuthorized"
            :key="2"
            :checked="true"
            :show-joined="true"
          />
        </div>
      </main>
      <aside class="side-bar-single-comp">
        <TournamentInfo />
        <p v-if="rulesText" class="read-rules" @click="openModalRules">
          {{ $t('_web_read-all-rules', ' Read all rules') }}
        </p>
      </aside>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
import StatusInfo from '@/views/tournaments/list/Pinned/components/StatusInfo.vue';
import TournamentInfo from '@/views/account/content/info/panels/TournamentInfo';
import Comments from '@/components/comments/Comments';
import Timeline from '@/views/account/Timeline';
import TournamentInfoDescription from '@/views/account/content/info/panels/TournamentInfo/DescriptionNew.vue';
import PinnedTournamentHero from '@/views/tournaments/list/Pinned/item.vue';

export default {
  name: 'SingleCompetition',
  components: {
    StatusInfo,
    TournamentInfo,
    Comments,
    Timeline,
    TournamentInfoDescription,
    PinnedTournamentHero,
  },
  mixins: [tournamentHelpers],
  data() {
    return {
      post: { id: this.$route.params.tournamentID },
      contentOption: `info`,
      rulesText: '',
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapState('my', { myLogin: 'accountId' }),
    ...mapGetters([
      'currentTournament',
      'isTournamentBackuped',
      'currentTournamentStage',
      'currentTournamentStages',
      'isAuthorized',
    ]),

    tournamentID() {
      return this.$route.params.tournamentID;
    },
  },
  watch: {
    $route() {
      document.location.reload();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.tournamentID) {
      this.clearCurrentTournament();
      this.clearAllStages();
      this.clearAllBlocks();
    }
    this.clearCurrentTournamentBackup();
    next();
  },
  created() {
    // console.log(this.$store);
    //
    if (this.tournamentID) {
      this.updateStageLoader(true);
      Promise.all([
        this.$store.dispatch('fetchTournament', this.tournamentID),
        this.$store.dispatch('fetchTournamentStages', this.tournamentID),
      ])
        .then(() => {
          if (
            this.$route.params.stageID &&
            this.currentTournamentStage.id !== this.$route.params.stageID
          ) {
            this.setCurrentTournamentStage(this.$route.params.stageID);
          }
          return this.currentTournamentStages.map(stage => {
            return this.fetchTournamentBlocks({
              stage: stage.index,
            }).then(blocks => {
              this.setTournamentBlocks(blocks);
            });
          });
        })
        .then(blocksPromises =>
          Promise.all(blocksPromises).catch(this.errorNotify)
        )
        .then(() => {
          if (this.isTournamentBackuped) {
            this.backupCurrentTournament();
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.updateStageLoader(false);
          window.dataLayer.push({
            event: 'tournament opening',
            userID: this.account._id,
            from: this.$route.path,
            tournamentId: this.currentTournament.id,
            gameName: this.currentTournament.game,
            squadSize: this.currentTournament.squad_size,
            tournamentName: this.currentTournament.title,
            tournamentFormat: this.currentTournamentStages[0]
              .block_configuration.type,
            tournamentCountry: this.currentTournament.country,
            tournamentPool: this.currentTournament.prize.prize_pool,
            regForm: this.currentTournament.requires_registration_form,
            tournamentPrivate:
              this.currentTournament.registration_type === 'closed'
                ? 'true'
                : 'false',
            tournamentOrganizer: this.currentTournament.event_organizer_name,
          });
        });
      this.fetchGames();
      this.fetchPlatforms();
      this.checkRules();
    }
  },
  methods: {
    ...mapActions([
      'fetchTournamentBlocks',
      'fetchGames',
      'fetchPlatforms',
      'errorNotify',
      'fetchTournament',
      'openModal',
      'fetchTournamentRulesText',
    ]),
    ...mapMutations([
      'setTournamentBlocks',
      'backupCurrentTournament',
      'clearCurrentTournament',
      'clearCurrentTournamentBackup',
      'clearAllStages',
      'clearAllBlocks',
      'updateStageLoader',
      'setCurrentTournamentStage',
    ]),
    checkRules() {
      this.fetchTournamentRulesText(this.tournamentID)
        .then(res => {
          this.rulesText = res.rules;
        })
        .catch(this.errorNotify);
    },
    async openModalRules() {
      try {
        const rulesResult = await this.fetchTournamentRulesText(
          this.currentTournament.id
        );
        this.openModal({
          name: 'RulesConfirmModal',
          props: {
            rules: rulesResult.rules,
            tournament: this.currentTournament,
            teamId: '',
            showCheckbox: false,
          },
        });
      } catch (error) {
        // Handle error
        console.error(error);
      }
    },

    // ...mapMutations('current', ['updateAccountModel']),
  },
};
</script>

<style lang="scss">
.main-wrapper-sc {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
}

.main-inner {
  display: flex;
  justify-content: space-between;
  width: $hr-wrapper-width;
  max-width: $hr-wrapper-max-width;
  @media screen and (max-width: 1300px) {
    width: 98%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    display: block;
  }
}

.content-inner-wrap-single-comp {
  margin-top: 26px;
  flex-basis: 75%;
  align-items: flex-start;
  @media (max-width: 800px) {
    margin-top: 45px;
  }
}
.side-bar-single-comp {
  margin-top: 26px;
  flex-basis: 23%;
  align-items: flex-start;
  height: 100%;
  @media (max-width: 800px) {
    display: none;
  }
}
.anounnced {
  background: linear-gradient(
    180deg,
    rgba(134, 36, 130, 0.09) 0px,
    rgba(134, 36, 130, 0.2) 500px,
    rgba(134, 36, 130, 0) 700px,
    rgba(134, 36, 130, 0) 1200px
  );
  background-size: 300px;
}

.started {
  background: linear-gradient(
    180deg,
    rgba(57, 160, 86, 0.09) 0px,
    rgba(57, 160, 86, 0.2) 500px,
    rgba(57, 160, 86, 0) 700px,
    rgba(57, 160, 86, 0) 1200px
  );
}
.regStarted {
  background: linear-gradient(
    180deg,
    rgba(70, 103, 220, 0.038) 0px,
    rgba(70, 103, 220, 0.19) 500px,
    rgba(70, 103, 220, 0) 700px,
    rgba(70, 103, 220, 0) 1200px
  );
}
.regClosed {
  background: linear-gradient(
    180deg,
    rgba(253, 159, 24, 0.038) 0px,
    rgba(253, 159, 24, 0.19) 500px,
    rgba(253, 159, 24, 0) 700px
  );
}
.finished {
  background: linear-gradient(
    180deg,
    rgba(185, 185, 185, 0.09) 0px,
    rgba(171, 171, 171, 0.166667) 500px,
    rgba(56, 56, 56, 0) 700px,
    rgba(56, 56, 56, 0) 1200px
  );
}
.btn-options {
  cursor: pointer;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 12px 20px 12px 20px;
}

.comments-on-single-tour {
  .text-input-container {
    background-color: #181c25 !important;
    border: 2px solid #4b5672;
    border-radius: 5px;

    @media (max-width: 800px) {
      // background-color: #11171f !important;
      border: none;
    }
  }
  .text-truncate {
    // background-color: #181c25 !important;
  }
  .textarea-container {
    background-color: #181c25 !important;
  }
  .textarea {
    background-color: #181c25 !important;
  }
  .textarea-bottom {
    background-color: #181c25 !important;
  }
}

.btn-options-wrap {
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: fit-content;
  }
  display: none;
  background-color: #11171f;
  padding: 3px;
  border-radius: 5px;
}
.btn-outer-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.active-btn {
  background-color: #242938;
  padding: 12px 20px 12px 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-hide {
  @media (max-width: 800px) {
    display: none;
  }
}
.comment-item {
  word-break: break-all;
}
.comment-mobile {
  display: none;
  @media (max-width: 800px) {
    padding-bottom: 20px;
    display: block;
    background-color: #11171f;
    .post-comments-sum {
      padding: 5%;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    .avatar-mobile-comment {
      margin-left: 5% !important;
      margin-right: 5% !important;
    }
    .gif-icon {
      display: none !important;
    }
    .emoji-icon {
      display: none !important;
    }
    .form {
      margin-top: 15px !important;
    }
    .comment-section {
      padding-left: 5%;
      padding-right: 5%;

      max-width: 95vw !important;
      word-break: break-all !important;

      .bdi-wraper-text {
        display: block;
        text-align: start;
      }
    }
    .header-comment-item {
      display: block !important;
    }
    hr {
      margin: 0;
    }
    .comment-input-footer {
      margin-top: 0px !important;
    }
    .sc-post-attach {
      max-width: 60vw;
    }
    .text-input-container {
      margin-right: 5% !important;
      background-color: #181c25 !important;
    }
    .text-truncate {
      // background-color: #11171f !important;
    }
    .textarea-container {
      background-color: #181c25 !important;
      // max-width: 60vw !important;
    }
    .textarea {
      background-color: #181c25 !important;
    }
    .textarea-bottom {
      background-color: #181c25 !important;
    }
    .comment-input-action-emoji {
      display: none;
    }
    .comment-input-action-gif {
      display: none;
    }
  }
}
.info-mobile {
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
}
.mobile-container {
  @media (max-width: 800px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.read-rules {
  display: flex;
  font-family: Exo;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 24px 0 24px;
  color: rgba(157, 156, 247, 1);
  @media (max-width: 800px) {
    padding: 12px 24px 24px 24px;
    background-color: #11171f;
  }
}
.read-rules:hover {
  cursor: pointer;
  text-decoration: underline rgba(157, 156, 247, 1);
}

html[dir='rtl'] {
  .comment-attachments {
    @media (max-width: 800px) {
      margin-left: 5% !important;
    }
  }
}
</style>
