<template>
  <MainLayout>
    <div class="privacy-policy-indonesia">
      <div class="privacypolicy-container" dir="ltr">
        <ul>
          <li>
            <h1><b>Introduction to Privacy Policy</b></h1>
            <p>
              Welcome to PLAYHERA, a platform based on a website, application,
              service, and product (hereinafter collectively referred to as the
              “Platform”) operated by PT Playhera Loka Indonesia (“PLAYHERA”).
              PLAYHERA is committed to complying with the laws and regulations
              on privacy applicable in Indonesia (“Personal Data Protection Law
              - PDPL”) and is fully committed to respecting the data protection
              and privacy rights of all Users / Verified Users (“Users”)
              registered on our Platform.
            </p>
            <p>
              The following Privacy Policy provides an explanation of how
              PLAYHERA, along with the terms of use of the PLAYHERA Platform as
              set out in the “Terms & Conditions” and other information stated
              on the PLAYHERA website and Platform, establishes the basis for
              collecting, storing, using, processing, controlling, transferring,
              disclosing, and protecting personal data and information of Users
              which PLAYHERA has obtained and collected from Users. This
              includes personal data of Users submitted during registration,
              accessing, and using services on the PLAYHERA Platform.
            </p>
            <p>
              It is important for Users to be aware of and understand this
              Privacy Policy along with other applicable information, which will
              be used as the basis for managing personal data. This Privacy
              Policy (“Privacy Policy”) is designed to help Users understand how
              PLAYHERA collects, uses, discloses, and/or processes the personal
              data Users entrust to PLAYHERA and/or which PLAYHERA has about
              Users, whether at present or in the future, and to help Users make
              informed decisions before submitting personal data to PLAYHERA. By
              continuing to access and use PLAYHERA Services, Users are deemed
              to agree with all Privacy Policies stated on the PLAYHERA
              Platform, including any amendments.
            </p>
            <p>
              IN THE EVENT THAT USERS DO NOT PERMIT THE PROCESSING OF PERSONAL
              DATA AS EXPLAINED IN THIS PRIVACY POLICY, WE ADVISE AGAINST USING
              PLAYHERA SERVICES OR ACCESSING THE PLAYHERA PLATFORM. If PLAYHERA
              makes any amendments to the Privacy Policy, PLAYHERA will post
              such amendments on the PLAYHERA Platform. PLAYHERA reserves the
              right to amend this Privacy Policy at any time without requiring
              approval from Users but will refer to the applicable laws and
              regulations.
            </p>
            <p>This Privacy Policy includes the following matters:</p>
          </li>

          <li>
            <h2>
              <b>Personal Information Obtained and Collected by PLAYHERA</b>
            </h2>
            <ul>
              <li>
                Personal Information is any form of information, whether or not
                recorded in material form, that can directly or indirectly
                identify a person and can be accessed by an authorized party in
                case any issue arises.
              </li>
              <li>
                While Users are using the PLAYHERA Platform and Services,
                PLAYHERA may collect Personal Information, including but not
                limited to:
              </li>
              <ul>
                <li>
                  Identity data, such as name, gender, profile picture, and date
                  of birth;
                </li>
                <li>
                  Contact data, such as delivery address, email address, and
                  phone number;
                </li>
                <li>
                  Account data, such as bank account and payment card details,
                  including the type of payment card or account used, the name
                  of the issuer of that payment card or account, the name of the
                  holder, the identification number, the verification code, and
                  the expiration date of that payment card or account, as
                  applicable, financial history, and credit score;
                </li>
                <li>
                  Transaction data, such as details on payments or transfers
                  made through any electronic money and/or Service provided by
                  us, including information relating to utilization, payment,
                  recipient details, amount of payment paid, bill details,
                  invoice details, and order or purchase of crypto assets or
                  transaction history;
                </li>
                <li>
                  Technical data, such as Internet Protocol (IP) address, login
                  data, type and version of browser, time zone setting, type and
                  version of browser plug-in, operating system, Platform, and
                  device identity while using the PLAYHERA Platform;
                </li>
                <li>
                  Profile data, such as username and password, purchase, order
                  or preference intention, and responses to surveys;
                </li>
                <li>
                  Usage data, such as information on how Users use the PLAYHERA
                  Platform, products, and Services;
                </li>
                <li>
                  Location data, such as the method used by Users while taking
                  and sharing location with PLAYHERA in the form of pictures or
                  videos and uploading them to the PLAYHERA Platform;
                </li>
                <li>
                  Biometric data, such as sound files when Users use the feature
                  of face or other body parts and sound or other features of the
                  device by uploading it to the Platform;
                </li>
                <li>
                  Marketing and correspondence data, such as preferences in
                  receiving marketing materials from PLAYHERA and third parties
                  (including agents, vendors, suppliers, contractors, partners,
                  and any others who provide services to us or you, perform
                  functions on our behalf, or whom we enter into commercial
                  collaboration with) and communication preferences of Users.
                </li>
              </ul>
              <li>
                Users agree not to submit any information that is inaccurate or
                misleading and agree to inform PLAYHERA about any inaccuracies
                or changes. PLAYHERA has the discretion to require further
                documentation to verify information submitted by Users.
              </li>
            </ul>
          </li>

          <li>
            <h2><b>Use of Data by the Platform</b></h2>
            <ul>
              <li>
                PLAYHERA may collect, use, disclose, or process personal data of
                Users for the following purposes:
              </li>
              <ul>
                <li>
                  To identify and register Users and to administer, verify,
                  deactivate, or manage Users' accounts;
                </li>
                <li>
                  To facilitate or enable any verification necessary before
                  providing services or registering Users as verified users,
                  including the Know Your Customer process;
                </li>
                <li>
                  To provide, process, and facilitate orders and payment
                  transactions;
                </li>
                <li>
                  To facilitate customer service in implementing instructions,
                  dealing with or responding to any questions raised by Users;
                </li>
                <li>
                  To communicate with Users and send information about the use
                  of the Platform, including updates and support;
                </li>
                <li>
                  To inform Users about updates to the PLAYHERA Platform or
                  services provided;
                </li>
                <li>
                  To monitor and analyze activity, behavior, and demographic
                  data, including habits and responsiveness to various services
                  available on the Platform;
                </li>
                <li>
                  To send Users direct or targeted marketing communication,
                  advertising, promotion, surveys, and special offers;
                </li>
                <li>
                  To use information for research, analysis, development, and
                  testing of products to improve safety and security of
                  services, as well as for developing new features and products;
                </li>
                <li>
                  To monitor or investigate suspicious transactions or
                  transactions suspected of containing elements of fraud or
                  violations against the Terms and Conditions or applicable
                  laws, and to take necessary actions as follow-up;
                </li>
                <li>
                  In certain situations, to use or disclose User data for law
                  enforcement purposes or to fulfill legal requirements,
                  including in the event of any dispute or legal proceedings
                  between Users and PLAYHERA;
                </li>
                <li>
                  Any other purpose which PLAYHERA will inform Users about when
                  obtaining approval, except for data processing permitted
                  without approval by the Privacy Law.
                </li>
              </ul>
            </ul>
          </li>

          <li>
            <h2><b>Disclosure of Personal Data</b></h2>
            <ul>
              <li>
                PLAYHERA guarantees that there will be no sale, leakage,
                transfer, distribution, or lending of personal data to other
                parties without permission, except in the following cases:
              </li>
              <ul>
                <li>
                  When disclosure is required to assist PLAYHERA in providing
                  services and processing activities on the PLAYHERA Platform,
                  including transaction processes and payment verification;
                </li>
                <li>
                  When relevant data or information is provided to PLAYHERA
                  business partners with Users' approval, including
                  API-integrated services or promotional services;
                </li>
                <li>
                  For banking, payment, and other services with Users, including
                  resolving obstacles;
                </li>
                <li>
                  To vendors, consultants, marketing partners, research firms,
                  or similar service providers;
                </li>
                <li>
                  To PLAYHERA's subsidiaries and affiliates to help in
                  maximizing or providing services and data processing;
                </li>
                <li>
                  To comply with legal obligations or valid requests from law
                  enforcement agencies.
                </li>
              </ul>
            </ul>
          </li>

          <li>
            <h2><b>Storage of Information</b></h2>
            <ul>
              <li>
                Personal Information will only be stored as long as necessary to
                fulfill the purpose of its collection or as required or allowed
                by applicable laws. PLAYHERA will stop storing or delete
                Personal Information when it is no longer necessary for business
                or legal purposes.
              </li>
              <li>
                Personal Information may be stored, transferred, or processed by
                third-party service providers. PLAYHERA will take reasonable
                efforts to ensure all third-party service providers provide
                equivalent protection to this Privacy Policy.
              </li>
              <li>
                Personal Information may also be stored or processed outside the
                Users' country by a third party working for PLAYHERA or by
                PLAYHERA affiliates. PLAYHERA will ensure that such Personal
                Information continues to be subject to protection equivalent to
                the law of the Users' country.
              </li>
              <li>
                Users may withdraw consent to the collection, use, or disclosure
                of Personal Information at any time by giving reasonable notice
                in writing. Withdrawal of consent may result in the termination
                of Users' accounts or contractual relationships with PLAYHERA.
                After receiving a withdrawal request, PLAYHERA will inform Users
                about the likely consequences.
              </li>
            </ul>
          </li>

          <h2>Security Measures</h2>
          <ul>
            <li>
              PLAYHERA is committed to ensuring that Personal Information is
              secure. To prevent unauthorized access, disclosure, or other
              risks, we have implemented appropriate physical, electronic, and
              management procedures to safeguard and secure the information we
              collect.
            </li>
            <li>
              We use encryption, firewalls, and Secure Socket Layer (SSL)
              technology to protect data during transmission and storage.
            </li>
            <li>
              Users are responsible for keeping their account passwords
              confidential and not sharing them with anyone.
            </li>
            <li>
              Despite efforts to protect User data, PLAYHERA cannot guarantee
              complete security and shall not be held liable for unauthorized
              access or use of data by third parties.
            </li>
          </ul>

          <h2>User Rights</h2>
          <ul>
            <li>
              Users have the right to access, correct, update, or request
              deletion of their Personal Information held by PLAYHERA, subject
              to legal and contractual restrictions.
            </li>
            <li>
              Users may also have the right to request the restriction of
              processing or object to the processing of their Personal
              Information under certain circumstances.
            </li>
            <li>
              Users can exercise these rights by contacting PLAYHERA through the
              contact information provided in this Privacy Policy.
            </li>
          </ul>

          <h2>Changes to the Privacy Policy</h2>
          <ul>
            <li>
              PLAYHERA may update this Privacy Policy from time to time. We will
              notify Users of any significant changes by posting a notice on our
              Platform or through other appropriate means.
            </li>
            <li>
              Users are encouraged to periodically review this Privacy Policy to
              stay informed about how we are protecting their data.
            </li>
          </ul>
          <ul>
            <li>
              <h2><b>Cookies</b></h2>
              <ul>
                <li>
                  Cookies are small data storage files in a computer or device
                  when Users browse and/or visit PLAYHERA.
                </li>
                <li>
                  Cookies are identifiers transferred to Users' computers or
                  devices to identify them and inform PLAYHERA about how and
                  when services or the Platform are used or visited.
                </li>
                <li>
                  PLAYHERA may link cookies to personal data and use them for
                  transaction information, delivering specific content, and
                  monitoring the use of PLAYHERA Services.
                </li>
                <li>
                  Users will automatically receive cookies when using or
                  browsing PLAYHERA. Users can choose to modify or refuse the
                  use of cookies through web or browser settings.
                </li>
                <li>
                  PLAYHERA uses Google Analytics, which collects data such as IP
                  addresses and device types for platform development and
                  advertisement purposes. Users can opt out of Google Analytics
                  by downloading the Google Analytics Opt-Out Add-on for their
                  web browsers.
                </li>
                <li>
                  PLAYHERA may share anonymized cookie data with third parties
                  for promotional and advertisement purposes.
                </li>
                <li>
                  Users can control cookies through browser settings, but this
                  may affect the performance of certain functions and pages on
                  the PLAYHERA Platform.
                </li>
              </ul>
            </li>

            <li>
              <h2><b>Personal Data Protection</b></h2>
              <ul>
                <li>
                  PLAYHERA applies various security measures to ensure personal
                  data protection within its system. Personal data is kept
                  behind a secure network accessible only by authorized
                  employees. However, absolute security cannot be guaranteed.
                </li>
                <li>
                  PLAYHERA will keep personal data in accordance with PDPL and
                  applicable laws, destroying or anonymizing data when it is no
                  longer necessary for business or legal purposes. If a User
                  stops using the PLAYHERA Platform or their license to use the
                  Platform is terminated, PLAYHERA may continue to keep, use,
                  and disclose personal data in accordance with this Privacy
                  Policy and legal obligations.
                </li>
                <li>
                  Personal data may be securely disposed of without prior
                  notification to Users.
                </li>
              </ul>
            </li>

            <li>
              <h2><b>Information on Children</b></h2>
              <ul>
                <li>
                  Services are not intended for children under 18 years without
                  parental or guardian approval. PLAYHERA does not intentionally
                  collect or store data from individuals under 18.
                </li>
                <li>
                  Parents or guardians should not permit children to submit
                  personal data without approval and monitoring.
                </li>
                <li>
                  If personal data of children under 18 is disclosed to
                  PLAYHERA, parents or guardians consent to the processing of
                  the data and agree to be bound by this Privacy Policy on
                  behalf of the child. PLAYHERA will close accounts used
                  exclusively by children and delete any personal data believed
                  to be submitted without parental consent.
                </li>
              </ul>
            </li>

            <li>
              <h2><b>Disclaimer of Security and Third-Party Websites</b></h2>
              <ul>
                <li>
                  PLAYHERA DOES NOT GUARANTEE THE SECURITY OF PERSONAL DATA
                  AND/OR OTHER INFORMATION USERS SUBMIT TO THIRD-PARTY WEBSITES.
                </li>
                <li>
                  PLAYHERA implements security measures to maintain the safety
                  of personal data within its control, with limited access for
                  authorized personnel. However, PLAYHERA is not responsible for
                  the content, security settings, and activities of linked
                  third-party websites. Users access these websites at their own
                  risk.
                </li>
                <li>
                  PLAYHERA welcomes feedback on linked websites, including if
                  certain links are not functioning.
                </li>
              </ul>
            </li>

            <li>
              <h2><b>Users' Rights</b></h2>
              <ul>
                <li>
                  Users may have certain rights under applicable law to request
                  access to, correction of, and/or deletion of personal data in
                  PLAYHERA's possession. To exercise these rights, contact
                  PLAYHERA using the details provided below.
                </li>
                <li>
                  PLAYHERA reserves the right to refuse requests if permitted or
                  required by law, including circumstances where personal data
                  contains references to other individuals or where requests are
                  considered trivial, frivolous, or vexatious.
                </li>
                <li>
                  An administrative fee may apply for access and/or correction
                  requests.
                </li>
              </ul>
            </li>

            <li>
              <h2><b>Update and/or Amendment of Privacy Policy</b></h2>
              <ul>
                <li>
                  PLAYHERA may update this Privacy Policy at any time and will
                  notify Users via the Platform or registered email. Users are
                  advised to read and recheck this Privacy Policy regularly for
                  any changes.
                </li>
              </ul>
            </li>

            <li>
              <h2><b>Questions, Issues, or Complaints</b></h2>
              <ul>
                <li>
                  If Users have any questions, issues, or complaints regarding
                  PLAYHERA's Privacy Policy practices, please contact us by
                  email at
                  <a href="mailto:support@id.playhera.com"
                    >support@id.playhera.com</a
                  >.
                </li>
              </ul>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Privacypolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.privacypolicy-container {
  max-width: 1511px;
  width: 80vw !important;
}
.privacy-policy-indonesia {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .privacypolicy-container {
    width: 90vw !important;
  }
}
</style>
