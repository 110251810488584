<template>
  <div
    class="slider-chat-message__wrapper d-flex align-items-center justify-content-center"
    :class="{
      'align-self-start': !isPost,
      'align-self-end': isMy && !isPost,
    }"
  >
    <Avatar
      v-if="!isMy && !isPost"
      class="slider-chat-message__avatar align-self-end"
      type="user"
      :size="28"
      :img-url="data.sender_avatar"
      @click.native="$emit('openUserInfo')"
    />
    <div
      class="d-flex flex-column position-relative"
      :class="{ 'order-1': isMy && !isPost }"
    >
      <div
        class="slider-chat-message"
        :class="{
          'slider-chat-message_post': isPost,
          'slider-chat-message_my': isMy && !isPost,
          'slider-chat-message_undelivered': undelivered,
          'order-1': isMy && !isPost,
          'slider-chat-message_full-width': hasMediaAttach && !isPost,
        }"
      >
        <div v-if="isPost" class="slider-chat-message__header d-flex">
          <span class="slider-chat-message__postedby">{{
            $t('_web_tournament_slidechat_posted-by:', 'Posted by:')
          }}</span>
          {{ data.sender_name || data.sender }}
        </div>
        <TextContent
          class="p-3 slider-chat-message__text"
          :content="data.text"
        />
        <Attachments
          v-if="data.attachments && data.attachments.length"
          :attachments="data.attachments"
        />
        <div
          class="slider-chat-message__footer d-flex justify-content-end"
          :class="{ 'justify-content-between': isPost }"
        >
          <div
            v-if="isPost"
            class="slider-chat-message__comments d-flex"
            @click="openComments"
          >
            <Icon name="comments" width="12px" class="mx-1" />
            <span v-if="!data.comments_count">
              {{ $t('_web_tournament_sidechat_no-comments', 'No comments') }}
            </span>
            <span v-else class="slider-chat-message__comments-count">
              {{ data.comments_count }}
              {{ $t('_web_tournament_sidechat_comments', 'comments') }}
            </span>
          </div>
          <span>{{ data.sent_date | localDate($times.DATETIME_MED) }}</span>
        </div>
      </div>
      <span v-if="!isMy && !isPost" class="slider-chat-message__sender-info">{{
        senderInfo
      }}</span>
      <span
        v-show="undelivered"
        class="slider-chat-message__tryagain order-2"
        :class="{ 'slider-chat-message__tryagain_post': isPost }"
      >
        <Icon name="warning" width="14px" class="mx-1" />
        {{
          $t(
            '_web_tournament_sidechat_no-delivered',
            'The message was no delivered.'
          )
        }}
        <a href="#" @click.prevent="$emit('resend')">{{
          $t('_web_tournament_sidechat_try-again', 'Try again.')
        }}</a>
      </span>
    </div>
    <div
      v-if="isMy"
      :class="[
        'slider-chat-message__dots p-1 mx-1',
        { 'order-0': isMy && !isPost, 'mb-3': !isMy && !isPost },
      ]"
      @click="dotsClickHandler"
    >
      <Icon name="dots" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import flip from '@/directives/flip';
import Icon from '@/components/atoms/Icon';
import Attachments from '@/components/common/MsgAttachments';
import Avatar from '@/components/images/Avatar';
import TextContent from '@/components/text/TextContent';

export default {
  components: { Icon, Attachments, Avatar, TextContent },
  directives: {
    flip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    undelivered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('my', ['accountId', 'account']),
    dropdownItems() {
      return [
        {
          label: this.$t(
            '_web_tournament_sidechat_delete-for-all',
            'Delete for all'
          ),
          action: () => this.$emit('delete'),
        },
      ];
    },
    isMy() {
      return (
        this.accountId === this.data.sender ||
        this.account.name === this.data.sender_name ||
        this.accountId === this.data.sender_name ||
        this.undelivered
      );
    },
    senderInfo() {
      return (
        this.data.sender_name ||
        this.data.sender +
          (this.data.clanName ? ` / ${this.data.clanName}` : '')
      );
    },
    hasMediaAttach() {
      return Array.isArray(this.data.attachments)
        ? this.data.attachments.some(
            attach => attach.type === 'image' || attach.type === 'video'
          )
        : false;
    },
  },
  methods: {
    ...mapActions({
      openPopoverMenu: 'popoverMenu/open',
    }),

    openComments() {
      this.$emit('openComments');
    },

    dotsClickHandler(event) {
      this.openPopoverMenu({
        event,
        settings: {
          stopItemClickBubbling: true,
          isFixed: true,
          autoClose: true,
        },
        items: [
          {
            label: this.$t(
              '_web_tournament_sidechat_delete-for-all',
              'Delete for all'
            ),
            action: () => this.$emit('delete'),
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
.slider-chat-message__wrapper {
  margin-bottom: 0.75rem;
}

.slider-chat-message {
  position: relative;
  background-color: $ph-popover-bg;
  max-width: 15rem;
  border-radius: $ph-medium-radius;
  z-index: 2;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    @include auto-rtl(
      (
        left: 0,
        transform: skewX(-47.5deg),
        border-bottom-left-radius: 6px,
      )
    );
    bottom: 0;
    width: 24px;
    height: 22px;
    background-color: $ph-popover-bg;
  }

  &__avatar {
    cursor: pointer;
    margin: 0 0.75rem;
  }

  &__text {
    word-wrap: break-word;
    white-space: pre-line;
    a {
      color: $ph-primary-text;
      text-decoration: underline;
      &:hover {
        color: $ph-accent;
      }
    }
  }

  &__header {
    line-height: 2.125rem;
    border-bottom: 1px solid $ph-card;
  }

  &__postedby {
    color: $ph-secondary-text;
    font-size: 0.75rem;
    @include auto-rtl(
      (
        margin-right: 0.5rem,
        padding-left: 0.75rem,
      )
    );
  }

  &__footer {
    color: $ph-secondary-text;
    font-size: 0.7rem;
    line-height: 1.8rem;
    margin: -0.5rem 0.75rem 0 0.75rem;
  }

  &__comments {
    cursor: pointer;
  }

  &__comments-count {
    color: $ph-primary-text;
  }

  &__sender-info {
    font-size: 0.6875rem;
    color: $ph-secondary-text;
  }

  &__tryagain {
    position: absolute;
    display: block;
    width: 300px;
    right: 0.2rem;
    bottom: 0;
    margin-bottom: -0.3rem;
    text-align: right;
    font-weight: 700;
    font-size: 0.75rem;
    color: $ph-error;

    a {
      color: $ph-error;
      text-decoration: underline;
    }

    &_post {
      left: 0;
      text-align: left;
    }
  }

  &__dots {
    cursor: pointer;
  }
}

.slider-chat-message_post {
  width: 258px;
  max-width: unset;

  .slider-chat-message__footer {
    margin: 0 0.75rem;
    border-top: 1px solid $ph-card;
  }
}

.slider-chat-message_my {
  background: $ph-card;
  @include auto-rtl(margin, 0 1rem 0 0.625rem);

  &::before {
    background-color: $ph-card;
    @include auto-rtl(
      (
        left: unset,
        right: 0,
        transform: skewX(47.5deg),
        border-bottom-right-radius: 6px,
      )
    );
    bottom: 0;
    width: 24px;
    height: 22px;
  }
}

.slider-chat-message_undelivered {
  background: $ph-secondary-text;
  margin-bottom: 0.7rem;
  &::before {
    background-color: $ph-secondary-text;
  }
}

.slider-chat-message_full-width {
  width: 15rem;
}
</style>
