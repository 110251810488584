var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"player-profile",on:{"mouseenter":function($event){return _vm.getPostAuthorProfile(_vm.data.login)},"mouseleave":function($event){_vm.showFollowButton = false}}},[_c('div',{staticClass:"search-item-list"},[_c('LinkAccount',{staticClass:"avatar-container",attrs:{"id":_vm.data.login,"type":_vm.data.type,"vanity-id":_vm.data.vanity_id}},[_c('Avatar',_vm._b({},'Avatar',{
          size: 48,
          type: _vm.data.type || 'clan',
          imgUrl: _vm.data.avatar ? _vm.data.avatar : _vm.data.logo,
          isDeleted: _vm.data.deleted,
        },false))],1),_c('div',{staticClass:"text-name-container"},[_c('LinkAccount',{staticClass:"d-flex display-name m-0",attrs:{"id":_vm.data.login,"type":_vm.data.type,"vanity-id":_vm.data.vanity_id}},[_c('TextLabel',{staticClass:" text-name m-0"},[_vm._v("\n          "+_vm._s(_vm.data.display_name
              ? _vm.data.display_name
              : _vm.data.name
              ? _vm.data.name
              : _vm.data.login)+"\n        ")])],1),_c('div',{staticClass:"d-flex display-name"},[_c('TextLabel',{staticClass:"text-name text-name-second m-0"},[_vm._v("\n          @\n          "+_vm._s(_vm.data.login
              ? _vm.data.login
              : _vm.data.display_name
              ? _vm.data.display_name
              : _vm.data.name)+"\n        ")])],1)],1),_c('div',{attrs:{"dir":"rtl"}},[_c('FollowBtn',{staticClass:"follow-btn",class:_vm.showFollowButton ? '' : 'follow-hidden',attrs:{"model":_vm.model,"dir":"rtl","follow-class":"btn-primary","hover-class":"btn-secondary","unfollow-class":"btn-secondary"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }