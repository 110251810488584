<template>
  <div class="hero-tournament-card" :style="{ backgroundImage: cover }">
    <div class="hero-tournament-overlay">
      <div
        :is="linkComponent"
        v-bind="linkComponentBind"
        class="hero-tournament-content-wrapper"
        :style="{ '--scrollWidth': scrollWidth }"
      >
        <div class="hero-tournament-content-inner">
          <div class="hero-tournament-card-left">
            <div class="hero-tournament-upper">
              <TournamentStatusIcon
                :tournament-status="tournament.status"
                class="tour-status-mobile"
              />

              <StartsOn :tournament-start-time="tournament.tournament_start" />
              <h2 class="hero-tournament-title">{{ tournament.title }}</h2>
              <div class="hero-tournament-row">
                <TournamentStatusIcon
                  :tournament-status="tournament.status"
                  class="tour-status-desktop"
                />
                <TournamentPlatformAndSquad :tournament="tournament" />
              </div>
            </div>
            <div class="hero-tournament-organiser-sponsor">
              <div class="hero-tournament-organiser-sponsor-wrapper">
                <span class="muted">{{
                  $t('_web_organiser', 'ORGANISER')
                }}</span>
                <TournamentCardOrganizer
                  class="hero-tournament-organiser"
                  :tournament="tournament"
                  :size="25"
                  :put-class="false"
                />
              </div>

              <div
                v-if="hasSponsors"
                class="hero-tournament-organiser-sponsor-wrapper"
              >
                <span class="muted">{{
                  $t('_web_powered_by', 'POWERED BY')
                }}</span>
                <TournamentSponsors
                  v-if="hasSponsors"
                  :sponsors="sponsors"
                  height="25px"
                  align="center"
                  class="hero-tournament-sponsors"
                />
              </div>
            </div>
            <div
              :is="linkComponent"
              class="join-now-btn join-btn-mobile"
              v-bind="linkComponentBind"
            >
              <span>{{
                $t('_web_home_hero_tournament_button', 'JOIN NOW')
              }}</span>
              <img src="@/assets/images/arrow-right-bracket.svg" alt="" />
            </div>
          </div>

          <component
            :is="linkComponent"
            v-bind="linkComponentBind"
            class="hero-tournament-card-right-cont"
          >
            <div class="hero-tournament-card-right-cont">
              <div
                class="hero-tournament-card-right"
                :style="{ backgroundImage: cover }"
              >
                <div class="join-now-btn join-btn-desktop">
                  <span>{{
                    $t('_web_home_hero_tournament_button', 'JOIN NOW')
                  }}</span>
                  <img src="@/assets/images/arrow-right-bracket.svg" alt="" />
                </div>
              </div>
            </div>
          </component>
        </div>
        <!--        <TournamentCardPerIndicator :tournament="tournament" :is-organizer="forOrganizer" />-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { prop, propRequired } from '@/utils/factories';
import { ORGANIZATION } from '@/constants/accounts';
import { DEFAULT } from '@/constants/common';
import { translate } from '@/mixins/translate';
import viewsTrackingMixin from '@/mixins/metrics';
import TournamentSponsors from '@/views/tournaments/management/singleTournament/components/SponsorsListPreview';

import LinkTournament from '@/components/links/Tournament';
import StartsOn from '../common/StartsOn';
import TournamentStatusIcon from '../common/TournamentStatusIcon';
import TournamentPlatformAndSquad from '../common/TournamentPlatformAndSquad';
import TournamentCardOrganizer from '../common/TournamentCard/components/Organizer';

export default {
  components: {
    LinkTournament,
    StartsOn,
    TournamentStatusIcon,
    TournamentPlatformAndSquad,
    TournamentCardOrganizer,
    TournamentSponsors,
  },
  mixins: [translate('_web_tournament'), viewsTrackingMixin],
  props: {
    tournament: propRequired(Object),
    forOrganizer: prop(Boolean),
  },
  data() {
    return {
      scrollWidth: null,
    };
  },
  computed: {
    ...mapGetters(['isTournamentPastStatus', 'currentCountry']),
    sponsors({ tournament }) {
      let sponsors = tournament.sponsor_logo || tournament.sponsorLogo || [];

      return sponsors[this.currentCountry] || sponsors[DEFAULT];
    },
    hasSponsors({ sponsors }) {
      return !!(sponsors && sponsors.length);
    },
    linkComponent() {
      return 'router-link';
    },
    linkComponentBind({ forOrganizer, tournament = {} }) {
      const { id, vanity_id, vanityId = vanity_id } = tournament;

      return {
        id,
        vanityId,
        ...(forOrganizer
          ? {
              to: {
                name: 'TournamentSetup',
                params: { tournamentID: id },
              },
            }
          : {
              to: {
                name: 'singleCompetition',
                params: { tournamentID: id },
              },
            }),
      };
    },
    itemAccountBind({ tournament = {} }) {
      const {
        event_organizer_logo,
        event_organizer_name,
        event_organizer_vanity_id,
        owner,
      } = tournament;
      return {
        type: ORGANIZATION,
        avatar: event_organizer_logo,
        name: event_organizer_name,
        vanityId: event_organizer_vanity_id,
        accountId: owner,
        size: 36,
      };
    },
    observeVisibility({
      forOrganizer,
      handleScrollIntoViewForViewsTracking,
      tournament: { id } = {},
    }) {
      return {
        callback: isVisible =>
          !forOrganizer
            ? handleScrollIntoViewForViewsTracking(isVisible, id, 'tournament')
            : this.$lodash.noop(),
        intersection: {
          threshold: 0.1,
        },
      };
    },
    cover({ tournament: { cover } }) {
      return `url(${encodeURI((cover || '').replace('.original.', `.500.`))})`;
    },
  },
  mounted() {
    this.getScrollBarWidth();
  },
  methods: {
    getScrollBarWidth() {
      let el = document.createElement('div');
      el.style.cssText =
        'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      el.remove();
      return (this.scrollWidth = width.toString() + 'px');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './variables';
.hero-tournament-overlay {
  background: linear-gradient(
    360deg,
    rgba($new-bg-page, 1),
    rgba($new-bg-page, 0.6),
    rgba($new-bg-page, 0.8)
  );
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: inset 0px 35px 30px -10px $new-bg-page,
    inset 0px -35px 30px -10px $new-bg-page;
}

.hero-tournament-content-wrapper {
  display: flex;
  align-items: center;
  width: $hr-wrapper-width; // 80vw
  max-width: $hr-wrapper-max-width; //1511 after paddings
  gap: $hr-spacing-07;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.hero-tournament-content-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.hero-tournament-card-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 550px;
  background-color: rgba(17, 23, 31, 0.75);
  border-radius: 5px;
  padding: 4rem 3rem 4rem 3rem;
  flex: 1;
  // min-width: 450px;
}

.hero-tournament-card-right-cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 550px;
  max-width: 850px;
}
.hero-tournament-card-right {
  position: relative;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  img {
    border-radius: 5px;
    filter: drop-shadow(0px 0px 5px black);
  }
  display: flex;
  justify-content: flex-end;
  width: 100%;
  // height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 16/9;
  // flex-basis: 60%;
  padding: 0px;
}

.hero-tournament-card:before {
  content: '';
  position: absolute;
  background: inherit;
  z-index: -1;

  filter: blur(40px);
  width: 140%;
  height: 140%;
  left: -20%;
  top: -20%;
}

.hero-tournament-card {
  width: 100%;
  &,
  &:hover,
  &:active,
  a,
  a:hover,
  a:active {
    text-decoration: none;
    color: $ph-primary-text;
  }

  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-self: stretch;
}

.hero-tournament-organizer {
  display: block;
  padding: 0px;
}
.hero-tournament-title {
  font-family: $hr-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: $hr-heading-2;
  line-height: 110%;
  padding: $hr-spacing-04 0px;
}
.hero-tournament-row {
  display: flex;
  align-items: center;
  gap: 12px;
}
.hero-tournament-organiser-sponsor {
  // margin-top: 50px;
  display: flex;
  align-items: stretch;
  gap: 40px;
}
.hero-tournament-organiser-sponsor-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.muted {
  color: $hr-text-muted;
  font-family: $hr-font-family;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 140%;
}
.hero-tournament-organiser {
  a {
    font-weight: 700;
    border-radius: 5px;
  }
}
.hero-tournament-sponsors {
  img {
    border-radius: 5px;
  }
}

.heroJoin {
  display: flex;
  position: relative;
  top: 30%;
  right: 7%;

  .app-rtl & {
    right: -7%;
  }
}

.heroButton {
  display: flex;
  align-items: center;
}

.heroArrow {
  position: relative;
  left: -25px;
  transition: transform 0.4s ease;
  .app-rtl & {
    transform: scaleX(-1);
    right: 25px;
  }
}
.heroCircle {
  position: absolute;
}
.hero-tournament-card-right:hover .heroArrow {
  transform: translateX(34px);
}
html[dir='rtl'] {
  .hero-tournament-card-right .heroArrow {
    rotate: 180deg !important;
    transform: translateX(-50px);
  }
  .hero-tournament-card-right:hover .heroArrow {
    rotate: 180deg !important;
    transform: translateX(-13px);
  }
}

.hero-tournament-upper {
  display: flex;
  flex-direction: column;
}

.tour-status-mobile {
  display: none;
}

.join-now-btn {
  font-size: 16px;
  background: #5a58f2;
  margin-top: auto;
  @include auto-rtl(margin-right, 2.5rem);
  margin-bottom: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  span {
    @include auto-rtl(margin-right, 0.5rem);
  }

  &:hover {
    background: #3b39cc;
  }

  img {
    .app-ltr & {
      rotate: 0deg;
    }

    .app-rtl & {
      rotate: 180deg;
    }
  }
}

.join-btn-mobile {
  display: none;
}

@media screen and (max-width: 1483px) {
  .hero-tournament-content-wrapper {
    width: 92vw;
  }

  .hero-tournament-title {
    font-size: $hr-heading-3;
  }

  .hero-tournament-card-left {
    min-width: 400px;
  }
}

@media screen and (max-width: 1251px) {
  .hero-tournament-card-left {
    padding: 3rem 2rem 3rem 2rem;
  }
}

@media screen and (max-width: 1095px) {
  .hero-tournament-content-inner {
    flex-direction: column-reverse;
  }

  .hero-tournament-card {
    height: auto;
    align-self: stretch;
  }

  .hero-tournament-card-right-cont {
    max-width: none;
  }

  .hero-tournament-card-left {
    min-width: auto;
  }

  .hero-tournament-card-left {
    padding: 1rem;
  }

  .hero-tournament-title {
    font-size: $hr-heading-5;
  }

  .hero-tournament-organiser-sponsor {
    padding-top: 1rem;
  }

  .hero-tournament-content-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .tour-status-mobile {
    display: block;
    @include auto-rtl(margin-right, auto);
    margin-bottom: 1rem;
  }

  .tour-status-desktop {
    display: none;
  }

  .join-btn-desktop {
    display: none;
  }

  .join-btn-mobile {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    margin-bottom: 1rem;
    @include auto-rtl(margin-right, 0);
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .hero-tournament-content-wrapper {
    max-width: calc(100vw - var(--scrollWidth));
    padding: 4rem 0 4rem 0;
  }
}
</style>
