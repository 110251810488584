<template>
  <div>
    <MainLayout>
      <div class="privacy-policy-mena">
        <div class="privacypolicy-container">
          <h1 class="text-center"><b>Playhera Privacy Policy</b></h1>
          <h2><b>Summary of how we use your data</b></h2>
          <ul class="disc">
            <li>
              Entertainment Content Company (also referred to as
              <b>"Playhera"</b>, <b>"we"</b> or <b>"us"</b>) uses your personal
              data to operate the 'Playhera' platform (currently available as an
              ioS or Android application or at [www.playhera.com], the
              <b>"Platform"</b>).
            </li>
            <li>
              Where you play on the Platform, we will particularly use your data
              to allow your registration, to operate and moderate the Platform’
              social forums, to administer your involvement in competitions
              organised on the Platform, to promote and retain records of
              competitions managed on the Platform, to notify you of upcoming
              competitions or events you have registered for and to manage any
              disputes between players or other communications or complaints. We
              use SMS verification, through a company called Twilio, as part of
              our registration process.
            </li>
            <li>
              Your data will be shared with third parties when you use the
              Platform. In particular, your information will be shared with
              others you interact with on our social forums, or those you choose
              to play against. Where you join competitions or events, your
              information will be shared with the organisers of those
              competitions or events. This may allow those organisers to contact
              you or send you notifications or updates. Information may also be
              shared with the police or other authorities where there is
              evidence of criminal conduct or where required by civil
              proceedings.
            </li>
            <li>
              Where we rely on your consent, such as for direct marketing or
              sharing your location for our location based services, you can
              withdraw this consent at any time.
            </li>
            <li>
              Playhera is a global platform. Your information may be transferred
              across the world in order to allow you to participate in our
              forums, events and competitions. Playhera takes appropriate steps
              to protect your data.
            </li>
            <li>
              Our privacy policy sets out more details of this processing,
              including details of your data protection rights, including your
              right to object to certain processing.
            </li>
          </ul>
          <h2><b>What does this policy cover?</b></h2>
          <p>
            This policy describes how Playhera will make use of your data when
            you use the Platform and, if you are a Participant, how we will make
            use of your data when you participate in a competition.
          </p>
          <p>
            It also describes your data protection rights, including a right to
            object to some of the processing which Playhera carries out. More
            information about your rights, and how to exercise them, is set out
            in the "What rights do I have?" section.
          </p>
          <h2>What information do we collect?</h2>
          <p>
            We collect and process personal data about you when you download and
            first use our applications, when you register on the Platform, when
            you use our social forums or when you submit information to use
            about your esports activities. This will typically be provided
            directly by you, and may include information you provide on
            registration such as your name, usernames, phone number, email
            address and marketing preferences. The details being provided by you
            will be made clear in forms you complete or will be provided
            directly by you for example in social content or results of esports
            fixtures.
          </p>
          <h2>What information do we receive from third parties?</h2>
          <p>
            Sometimes, we receive information about you from third parties. We
            may, for example, receive information from other players where you
            have competed in a fixture, event or competition. We may also
            receive information about you when others interact or communicate
            with you through our social forums, or make complaints about your
            conduct.
          </p>
          <p>
            We also receive information about you through our use of cookies, as
            described in our “Cookies and similar technologies” sections below.
            We make use of Google’s Analytics cookie in particular.
          </p>
          <p>
            Please note that Playhera does not knowingly collect or process any
            information from any child under the age of 13 without explicit
            permission from their parent or legal guardian.
          </p>
          <h2>
            How do we use this information, and what is the legal basis for this
            use?
          </h2>
          <p>We process this personal data for the following purposes:</p>
          <ul class="disc">
            <li>
              As necessary to administer and perform any contract between us, in
              particular:
            </li>
            <li>
              <ul>
                <li>
                  administering and managing any competitions run by Playhera;
                  and
                </li>
                <li>
                  determining eligibility and winners and delivery of prizes in
                  competitions run by Playhera;
                </li>
              </ul>
            </li>
            <li>
              As required by Playhera to conduct our business and pursue our
              legitimate interests, in particular:
            </li>
            <li>
              <ul>
                <li>
                  we will use your information to provide and administer the
                  Platform, including moderation of our social forums, and
                  respond to any comments or complaints you may send us;
                </li>
                <li>
                  we monitor use of our websites and online services, and use
                  your information to help us monitor, improve and protect our
                  products, content, services and websites, both online and
                  offline;
                </li>
                <li>
                  we use information you provide to personalise our website,
                  products or services for you;
                </li>
                <li>
                  we monitor our player accounts to prevent, investigate and/or
                  report fraud, terrorism, misrepresentation, security incidents
                  or illegal conduct, in accordance with applicable law;
                </li>
                <li>
                  we use information you provide to investigate any complaints
                  received from you or from others, about the Platform or about
                  the conduct of players on the Platform (including you);
                </li>
                <li>
                  we will use data in connection with legal claims, compliance,
                  regulatory and investigative purposes as necessary (including
                  disclosure of such information in connection with legal
                  process or litigation);
                </li>
                <li>
                  we use data of some individuals to invite them to take part in
                  market research; and
                </li>
                <li>
                  using scoreboard, prize and other player information to
                  promote the Platform.
                </li>
              </ul>
            </li>
            <li>Where you give us consent:</li>
            <li>
              <ul>
                <li>
                  we will send you direct marketing or notifications related to
                  the Platform, or will allow competition or event organisers to
                  send you notification or marketing about their events;
                </li>
                <li>
                  we place cookies and use similar technologies, in accordance
                  with our section on Cookies and similar technologies below;
                  and
                </li>
                <li>
                  on other occasions where we ask you for consent, we will use
                  the data for the purpose which we explain at that time.
                </li>
              </ul>
            </li>
            <li>For purposes which are required by law:</li>
            <li>
              <ul>
                <li>
                  where we need parental consent to provide online services to
                  children under 16.
                </li>
                <li>
                  in response to requests by government or law enforcement
                  authorities conducting an investigation; and
                </li>
                <li>maintaining any records as required by law.</li>
              </ul>
            </li>
          </ul>
          <h2>
            Withdrawing consent or otherwise objecting to direct marketing
          </h2>
          <p>
            Wherever we rely on your consent, you will always be able to
            withdraw that consent, although we may have other legal grounds for
            processing your data for other purposes, such as those set out
            above. You have an absolute right to opt-out of direct marketing, or
            profiling we carry out for direct marketing, at any time. You can do
            this by following the instructions in the communication where this
            is an electronic message, or by contacting us using the details set
            out below.
          </p>
          <h2>Cookies and similar technologies</h2>
          <p>
            Cookies are small pieces of information sent by a web server to a
            web browser which allows the server to uniquely identify the browser
            on each page. Other tracking technologies are also used which are
            similar to cookies. This can include pixel tags and tracking URLs.
            All these technologies are together referred to in this Policy as
            <strong>“cookies”</strong>.
          </p>
          <p>
            The types of cookies that we may use on our website, and the
            purposes for which they are used, are set out below:
          </p>
          <ul>
            <li>
              <strong>Strictly necessary cookies</strong>: These cookies are
              essential in order to enable you to move around our website and
              use its features, such as accessing secure areas of our website.
              Without these cookies, any services on our Platform you wish to
              access cannot be provided.
            </li>
            <li>
              <strong>Analytical/performance cookies</strong>: These cookies
              collect information about how you and other visitors use our
              Platform, for instance which pages you go to most often, and if
              you get error messages from web pages. We use data from these
              cookies to help test designs and to ensure a consistent look and
              feel is maintained on your visit to the Platform. All information
              these cookies collect is aggregated. It is only used to improve
              how a Platform works. We use Google Analytics, for example, to
              anonymously track website usage and activity.
            </li>
            <li>
              <strong>Functionality cookies</strong>: These cookies allow our
              website to remember choices you make (such as your user name,
              language or the region you are in) and provide enhanced, more
              personal features. These cookies can also be used to remember
              changes you have made to text size, fonts and other parts of web
              pages that you can customise. The information these cookies
              collect may be anonymised and they cannot track your browsing
              activity on other websites.
            </li>
            <li>
              <strong>Targeting cookies</strong>: These cookies are used to
              deliver adverts more relevant to you and your interests. We, for
              example, make use of the cookie Doubleclick. These cookies are
              also used to limit the number of times you see an advertisement as
              well as help measure the effectiveness of the advertising
              campaign. They are usually placed by advertising networks with the
              website operator’s permission. They remember that you have visited
              a website and this information is shared with other organisations
              such as advertisers. Quite often targeting or advertising cookies
              will be linked to site functionality provided by the other
              organisation.
            </li>
            <li>
              <strong>Social media cookies</strong>: These cookies allow you to
              share what you’ve been doing on our website on social media such
              as Facebook and Twitter. These cookies are not within our control.
              Please refer to the respective privacy policies for how their
              cookies work.
            </li>
            <li>
              <strong>Pixel tags</strong>: Also known as a clear GIF or web
              beacon. These are invisible tags placed on certain pages of our
              website but not on your computer. When you access these pages,
              pixel tags generate a generic notice of that visit. They usually
              work in conjunction with cookies, registering when a particular
              device visits a particular page. They are used to, among other
              things, track the actions of users of our services (including
              email recipients), measure the success of our marketing campaigns
              and compile statistics about usage of our services and response
              rates. If you turn off cookies, the pixel tag will simply detect
              an anonymous website visit.
            </li>
          </ul>
          <p>
            If you do not want to allow cookies at all, or only want to allow
            use of certain cookies, please refer to your browser settings. You
            can also use your browser settings to withdraw your consent to our
            use of cookies at any time and delete cookies that have already been
            set. Please note that by deleting our cookies or disabling future
            cookies you may not be able to access certain areas or features of
            our website.
          </p>
          <p>
            To find out more about cookies please visit:
            <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>
            or see
            <a href="http://www.youronlinechoices.eu"
              >www.youronlinechoices.eu</a
            >
            which contains further information about behavioural advertising and
            online privacy.
          </p>
          <h2>How is data shared, where and when?</h2>
          <p>
            We will share your data with competition and event organisers where
            you choose to participate in their competitions and events. This
            will allow them to manage your participation, to communicate with
            you within the Platform and (where you have consented to
            notifications) to send you notifications and reminders. We also give
            access to your data to E-sports federations and event sponsors where
            they are associated with a competition or event you have entered so
            that they can carry out a market analysis and, where you have given
            your consent, send you associated marketing. Esports federations are
            also given wider access to information about users in their
            territory so that they can better understand their players.
          </p>
          <p>
            Personal data may be shared with government authorities and/or law
            enforcement officials if required for the purposes above, if
            mandated by law or if required for the legal protection of our
            legitimate interests in compliance with applicable laws.
          </p>
          <p>
            Personal data may be shared with market research organisations so
            that they can assist us in better understanding our players and
            their interests.
          </p>
          <p>
            Personal data will also be shared with third party service
            providers, who will process it on behalf of Playhera for the
            purposes identified above. In particular, we use third party
            providers of application development, mail campaign management
            service providers, website hosting, SMS verification services and
            customer support solutions.
          </p>
          <p>
            In the event that the business is sold or integrated with another
            business, your details will be disclosed to our advisers and any
            prospective purchaser’s adviser and will be passed to the new owners
            of the business.
          </p>
          <h2>What rights do I have?</h2>
          <p>
            Depending on your country, you may have a number of different
            rights. In some countries, you have the right to
            <strong>ask us for a copy</strong> of your personal data;
            <strong>to correct, delete</strong> or
            <strong>restrict</strong> (stop any active) processing of your
            personal data; and to
            <strong
              >obtain the personal data you provide to us for a contract or with
              your consent in a structured, machine readable format, and to ask
              us to share (port) this data to another controller.</strong
            >
          </p>
          <p>
            In addition, you may also be able to
            <strong>object to the processing</strong> of your personal data in
            some circumstances (in particular, where we don’t have to process
            the data to meet a contractual or other legal requirement, or where
            we are using the data for direct marketing).
          </p>
          <p>
            These <strong>rights may be limited</strong>, for example if
            fulfilling your request would reveal personal data about another
            person, where they would infringe the rights of a third party
            (including our rights) or if you ask us to delete information which
            we are required by law to keep or have compelling legitimate
            interests in keeping. Relevant exemptions are included in local
            laws. We will inform you of relevant exemptions we rely upon when
            responding to any request you make.
          </p>
          <p>
            To exercise any of these rights, you can get in touch with us using
            the details set out below. If you have unresolved concerns, you have
            the
            <strong>right to complain</strong> to a competent authority. This
            may be a data protection authority where you live, work or where you
            believe a breach may have occurred. We will always inform you where
            information you provide is mandatory. Information that is provided
            in order to complete our registration processes, including SMS
            verification, are mandatory, as are accurate result of fixtures
            organised on the platform. Failure to provide this information will
            mean you are unable to register or that you are in breach of our
            Terms of Use.
          </p>
          <h2>How do I get in touch with you?</h2>
          <p>
            We hope that we can satisfy queries you may have about the way we
            process your data. If you have any concerns about how we process
            your data, or would like to opt out of direct marketing, you can get
            in touch at
            <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a> or by
            writing to address:
          </p>
          <address>
            3671 Thumama rd. Al Rabie District - Unit 8 <br />
            Riyadh 13316 - 8536 <br />
            Saudi Arabia <br />
            Company Name: Entertainment Content Co <br />
          </address>
          <p></p>
          <h2>How long will you retain my data?</h2>
          <p>
            We store information provided by you on the Platform until it is no
            longer necessary to allow you to access our services or until your
            account is deleted – whichever comes first. Where we process
            personal data for marketing purposes or with your consent, we
            process the data until you ask us to stop and for a short period
            after this (to allow us to implement your requests). We also keep a
            record of the fact that you have asked us not to send you direct
            marketing or to process your data indefinitely so that we can
            respect your request in future.
          </p>
          <p>
            Information about Playhera results (such as who has scored points
            and when) and still images of gameplay submitted by user will be
            retained indefinitely to keep a record of the competitions played on
            the Platform and ensure that results are verifiable.
          </p>
          <p class="text-center"><b>APPENDIX 1</b></p>
          <h1 class="text-center">Playhera Shop -Privacy Policy</h1>
          <h2>Terms</h2>
          <ul>
            <li>
              <p>
                The Website Policies and Terms &amp; Conditions may be changed
                or updated occasionally to meet the requirements and standards.
                Therefore the Customers’ are encouraged to frequently visit
                these sections in order to be updated about the changes on the
                website. Modifications will be effective on the day they are
                posted.
              </p>
            </li>
            <li>
              <p>
                Some of the advertisements you see on the Site are selected and
                delivered by third parties, such as ad networks, advertising
                agencies, advertisers, and audience segment providers. These
                third parties may collect information about you and your online
                activities, either on the Site or on other websites, through
                cookies, web beacons, and other technologies in an effort to
                understand your interests and deliver to you advertisements that
                are tailored to your interests. Please remember that we do not
                have access to, or control over, the information these third
                parties may collect. The information practices of these third
                parties are not covered by this privacy policy.
              </p>
            </li>
            <li>
              <p>By using our site, you consent to our privacy policy.</p>
            </li>
          </ul>
          <h2>Handling of customer data at our company</h2>
          <p>
            (“Playhera” or “we”), in operating the Playhera platform (available
            as an iOS/Android application or currently on [www.playhera.com])
            (the “Platform”): We use your personal data. When playing on the
            Platforms, Your Data is primarily used to allow registration,
            operate and moderate social forums, manage competition entries,
            maintain promotions and competition records, notify you about
            competitions and events in which you are registered, Used to manage
            disagreements and other interactions between players or complaints.
            We use SMS authentication with Twilio during the registration
            process. When you use the Platform, your data will be shared with
            third parties. The primary recipients will be those with whom you
            interact on our social forums and selected opponents. If you
            participate in competitions or events, your information will be
            shared with those organizers. This allows match and event organizers
            to send you communications, notifications and updates. We may also
            share your information with the police or other authorities where
            there is evidence of criminal activity or where required in civil
            proceedings. With respect to the sharing of information based on
            your consent, such as location sharing for direct marketing or the
            provision of our location-based services, you may withdraw such
            consent at any time. Playhera is a global platform. Therefore, we
            may transfer your information around the world so that you can
            participate in our forums, events and competitions. In such cases,
            Playhera will take appropriate steps to protect your data. Our
            Privacy Policy provides details on this processing, including
            information about your data protection rights, including your right
            to object to certain processing.
          </p>
          <h2>What does this policy cover?</h2>
          <p>
            This policy explains how Playhera uses your data when you use our
            platform, create an account, buy products, subscribe to a service,
            or participate in a tournament. <br />
            It also describes your data protection rights, including the right
            to object to certain processing carried out by Playhera. More
            information about your rights and how to exercise those rights can
            be found in the "What rights do I have?" section.
          </p>
          <h2>What information does Playhera collect?</h2>
          <p>
            When you download and use our application for the first time, when
            you register on our platform, when you use our social forums, create
            an account, buy products, subscribe to a service, or participate in
            a tournament, and when you submit information about your esports
            activities, We collect and process your personal data. This
            information is typically provided directly by you and includes
            information you provided during registration, such as your name,
            username, phone number, email address, shipping address, online
            payment details, and marketing preferences. We may collect this
            information from you completing forms, or from social content or
            esports tournament results. <br />
            What information does Playhera receive from third parties? We may
            receive information about you from third parties. Such third parties
            may, for example, be your opponents in competitions or events,
            people with whom you have interacted via our social forums, or who
            have lodged complaints about your conduct. It can be a person.
            <br />
            We also collect information about you through the use of cookies, as
            described in the "Cookies and Similar Technologies" section below.
            We mainly use Google Analytics cookies. <br />
            Playhera does not knowingly collect or process information from
            children under the age of 16 without the express permission of a
            parent or legal guardian.
          </p>
          <h2>
            What are we using this information for and on what legal basis?
          </h2>
          <p>We process personal data for the following purposes:</p>
          <ul>
            <li>
              <p>
                Administration and performance of contracts entered into between
                us and you;
              </p>
            </li>
            <li>
              <p>Operation and management of matches played on the Platform;</p>
            </li>
            <li>
              <p>
                Determining eligibility, determining winners and awarding prizes
                in competitions run by Playhera;
              </p>
            </li>
            <li><p>Complete your online purchase</p></li>
            <li><p>Subscribe to a service</p></li>
            <li>
              <p>
                our performance of business and pursuit of our legitimate
                interests;
              </p>
            </li>
            <li>
              <p>
                provide and operate our platform, including moderating social
                forums and responding to comments and complaints you submit to
                us;
              </p>
            </li>
            <li>
              <p>
                monitor your use of our websites and online services and use
                your information to monitor, improve and protect our products,
                content, services and websites, both online and offline;
              </p>
            </li>
            <li>
              <p>
                Personalize our website, products and services based on the
                information you provide;
              </p>
            </li>
            <li>
              <p>
                monitor player accounts to prevent, investigate and report
                fraud, terrorism, misrepresentation, security incidents and
                illegal activity in accordance with applicable law;
              </p>
            </li>
            <li>
              <p>
                investigate complaints about the Platform or the behavior of
                players (including you) on the Platform;
              </p>
            </li>
            <li>
              <p>
                use relevant data for legal claims, compliance, regulatory and
                investigative purposes (including disclosure of information
                required in legal proceedings or litigation);
              </p>
            </li>
            <li>
              <p>
                use data about certain individuals and encourage them to
                participate in market research;
              </p>
            </li>
            <li>
              <p>
                use information about our players (such as scoreboards and
                awards) to promote our platform;
              </p>
            </li>
            <li>
              <p>
                With your consent, provide your information to competition and
                event organizers so that they can send you direct marketing and
                platform-related notices or send you event notices and
                promotions. Place cookies and use similar technologies as
                described in the "Cookies and Similar Technologies" section
                below;
              </p>
            </li>
            <li><p>Instructions to you when seeking other consents;</p></li>
            <li><p>fulfilling purposes required by law;</p></li>
            <li>
              <p>
                Where parental consent is required to provide online services to
                children under the age of 16;
              </p>
            </li>
            <li>
              <p>
                Upon request for data disclosure by an investigating
                government/police authority;
              </p>
            </li>
            <li><p>record retention as required by law;</p></li>
          </ul>
          <p>
            Withdraw your consent or otherwise refuse direct marketing Where we
            have obtained your consent to use your data, withdraw your consent
            at any time, except for the legal grounds described above You can
            For example, opt out of direct marketing or profiling we do to
            conduct direct marketing at any time. It is your absolute right. If
            the direct marketing is by email, you opt-out out by following the
            instructions in the message or by contacting us at our contact
            information provided below.
          </p>
          <br />
          <br />
          <p>
            Cookies and Similar Technologies A cookie: is a small piece of
            information sent by a web server to a web browser that allows the
            server to uniquely identify the browser on each page. We also use
            tracking technologies similar to cookies, such as pixel tags and
            tracking URLs. In this policy, these technologies are collectively
            referred to as "cookies".
          </p>
          <h2>
            Below are the types of cookies we may use on our website and their
            uses.
          </h2>
          <p>
            Strictly Necessary Cookies: Cookies that are essential for accessing
            or navigating secure areas of the website and for using the
            functionality of the website. Without these cookies, you would not
            be able to access any of the services on our platform. <br />
            <br />
            Analytics/Performance Cookies: These cookies collect information
            about how you and other visitors use the Platform, such as which
            pages you visit most often and when you receive error messages from
            web pages. The data obtained from these cookies allows us to test
            the design and provide a consistent look and feel each time you
            visit the platform. The information collected by these cookies is
            aggregated and used only to improve platform functionality. We track
            website usage and activity on an anonymized basis, such as Google
            Analytics. <br />
            <br />
            <br />
            Functionality Cookies: These cookies allow our website to remember
            choices you make (username, language, region, etc.) and offer
            enhanced and more personalized features. It can also remember
            changes you make to customizable parts of web pages, such as text
            size and fonts. The information collected by these cookies can be
            anonymized. Please note that your browsing activity on other
            websites cannot be tracked. <br />
            <br />
            <br />
            Targeting Cookies: These cookies are used to deliver advertisements
            that are more relevant to you and your interests. We use the cookie
            Doubleclick. These cookies are also used to limit the number of
            times you see an advertisement and measure the effectiveness of
            advertising campaigns, and are typically placed by ad networks with
            the website operator's permission. . The websites you visit are
            remembered and that information is shared with other organizations,
            such as advertisers. Targeting/advertising cookies are often linked
            to site functionality provided by other organizations. <br />
            Social Media Cookies: These cookies allow us to share your actions
            on social media sites such as Facebook and Twitter. These cookies
            are not under our control, so please refer to the privacy policy of
            each social media for their function. <br />
            <br />
            <br />
            Pixel Tags: Pixel tags, also known as clear GIFs or web beacons, are
            invisible tags placed on certain pages of our website that do not
            appear on your computer screen. Visiting a page with these tags will
            generate a generic notification for that visit. Pixel tags typically
            work in conjunction with cookies to detect visits to specific pages
            by specific devices. These tags are primarily used to track the
            behavior of users of the Service (including email recipients),
            measure the success of marketing campaigns, and compile statistics
            on Service usage and response rates. If you disable cookies, pixel
            tags will only detect anonymous visits to our website. <br />
            <br />
            <br />
            If you wish to completely refuse the application of cookies or wish
            to use only specific cookies, please refer to your browser settings.
            You can withdraw your consent to our use of cookies at any time
            through your browser settings. You can also delete existing cookies.
            However, if you delete/disable the cookies we have set, you may not
            be able to access certain areas or functions on our website.
          </p>
          <h2>When, where and how is the data shared?</h2>
          <p>
            We share your data with the organizers of the competitions/events
            you choose to participate in. These organizers will then be able to
            manage your participation, interact with you on the Platform, and
            (if you have consented) send you notifications and reminders. In
            addition, if the competition/event to which you have entered is
            involved with an esports organization or event sponsor, we may use
            those organizations for market analysis and related direct marketing
            (if you have consented). <br />
            Also, we provide esports federations with access to a variety of
            information about users within their territories so that they can
            better understand their players. <br />
            We may disclose your personal information and you online payment
            information if necessary to fulfill your online purchase or
            subscriptions on our platform. <br />We may disclose your personal
            information if necessary to fulfill the above purposes or to legally
            protect our legitimate interests pursuant to applicable law, or if
            such disclosure is required by law. Data can be shared with
            government/police officials. <br />We may also share your personal
            data with market research agencies to help us better understand our
            players and their interests. <br />In addition, we may share your
            personal data with third-party service providers who process data on
            behalf of Playhera to fulfill the purposes set out above. We
            primarily use third-party service providers for solutions related to
            application development, email campaign management, website hosting,
            SMS authentication services and customer support. <br />In the event
            of a business sale or merger with another company, we will disclose
            your information not only to our advisers, but also to the advisers
            of any prospective acquirer, who will ultimately pass the business
            on to its new owner.
          </p>
          <h2>What rights do I have?</h2>
          <p>
            Your rights vary by country. Such rights include the right to
            request a copy of your personal data from us, the right to
            rectify/delete your personal data, the right to restrict processing
            (or to stop processing in progress), the right to contractually the
            right to obtain in a structured and machine-readable format your
            personal data submitted to us by or with your consent; You have the
            right to request.<br />You may also object to the processing of your
            personal data in some cases (especially with respect to data that is
            not required to be processed to meet contractual or other legal
            requirements or that we use for direct marketing). can do.
            <br />However, if there is a risk that the personal data of others
            will be exposed or the rights of a third party (including our
            company) may be violated by responding to your request, or if our
            company is obligated by law to retain it. The above rights may be
            limited if you are, or if you ask us to remove information for which
            you may obtain a compelling and legitimate interest in retaining it.
            Relevant exemptions are provided by local law. When responding to
            your request, we will inform you of any relevant exemptions on which
            we rely. <br />If you wish to exercise any of these rights, please
            contact us using the contact details below. If your concern remains
            unresolved, you may lodge a complaint with the competent authority,
            such as the data protection authority of your place of residence,
            place of work or where the violation occurred. We will always let
            you know when it is mandatory for you to provide us with
            information. The registration process, including SMS verification,
            requires the provision of accurate information, as well as the
            results of competitions held on the platform. Failure to do so may
            result in the impossibility of registration and may be considered a
            violation of our Terms of Use. <br />
          </p>
          <h2>How long is your personal data retained?</h2>
          <p>
            We will only retain the information you provide us with when it is
            no longer necessary to enable you to access our services or when
            your account is deleted. It will be saved on the platform until
            whichever comes first. Any processing of personal data that we carry
            out for marketing purposes or with your consent will continue until
            a short period of time after your request to stop it (until we are
            able to comply with your request). will be We will also record and
            retain your request to stop direct marketing or to stop processing
            your personal data so that we can continue to honor your wishes in
            the future. <br />The Company may use Playhera result information
            (such as who earned points and when they were earned) and still
            images of gameplay submitted by users as a record of matches held on
            the platform and for later verification of results. We will keep it
            indefinitely so that we can.
          </p>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Privacypolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.priv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.text-center {
  text-align: center !important;
}
.privacypolicy-container {
  max-width: 1511px;
  width: 80vw !important;
}
.privacy-policy-mena {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .privacypolicy-container {
    width: 90vw !important;
  }
}
</style>
