<template>
  <main class="flex-grow-1 pt-0 mt-0 h-100">
    <div
      class="flex-grow-1 h-100 mx-auto position-relative"
      :class="{
        'content-wrapper':
          this.$router.history.current.name !== 'homepage' &&
          this.$router.history.current.name !== 'singleCompetition' &&
          this.$router.history.current.name !== 'post' &&
          this.$router.history.current.name !== 'SinglePost' &&
          this.$router.history.current.name !== 'subscriptionSetup' &&
          this.$router.history.current.name !== 'subscriptionAnnouncement' &&
          this.$router.history.current.name !== `playerTournamentBracket` &&
          this.$router.history.current.name !== 'playerStagesList' &&
          this.$router.history.current.name !== 'conversations' &&
          this.$router.history.current.name !== 'feed' &&
          this.$router.history.current.name !== 'Shop' &&
          this.$router.history.current.name !== 'ShopLand' &&
          this.$router.history.current.name !== 'singleProduct' &&
          this.$router.history.current.name !== 'ShopSuccess' &&
          this.$router.history.current.name !== 'search',
        'bottom-content-padding':
          this.$router.history.current.name !== 'post' &&
          this.$router.history.current.name !== 'SinglePost' &&
          this.$router.history.current.name !== 'subscriptionAnnouncement' &&
          this.$router.history.current.name !== 'conversations' &&
          this.$router.history.current.name !== 'search',
        'brackets-layout':
          this.$router.history.current.name === `playerTournamentBracket` ||
          this.$router.history.current.name === 'playerStagesList',
        'profile-page-wrapper':
          this.$router.history.current.path.includes('profiles') ||
          this.$router.history.current.path.includes('clans') ||
          this.$router.history.current.path.includes('organizations'),

        'content-outer-wrap':
          this.$router.history.current.name === 'billing' ||
          this.$router.history.current.path.includes('battles'),

        'notifications-class':
          this.$router.history.current.name === 'notifications',
      }"
    >
      <router-view />
    </div>
  </main>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="scss">
main {
  .content-wrapper {
    max-width: $hr-wrapper-max-width; //1511 after paddings
    width: $hr-wrapper-width; // 80vw
    padding-top: $hr-spacing-07;
    & > div {
      padding: 0 20px;
    }
  }

  .bottom-content-padding {
    padding-bottom: $hr-spacing-10;
  }

  .notifications-class {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  main {
    .content-wrapper {
      & > div {
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .profile-page-wrapper {
    width: 82vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .content-outer-wrap {
    width: 90vw !important;
  }
}

.brackets-layout {
  padding-top: $hr-spacing-07;
  width: 100%;
  button > .playhera-icon {
    padding-left: 20px;
  }
  @media (max-width: 768px) {
    .ph-panel {
      border-radius: 0px !important;
    }
    .match-card {
      border-radius: 10px !important  ;
    }
  }
  .stages-wrap {
    padding: 20px;
    display: flex;
    flex-wrap: wrap !important;
    gap: 15px;
    justify-content: flex-start;
    @media (max-width: 400px) {
      justify-content: center;
    }
    width: 100%;
    .ph-panel {
      width: 100%;
    }
  }
  .stage-wrapper {
    width: 100%;
    min-width: 300px;
    border-radius: 10px !important;
    margin: auto !important;
  }
  .stage-header {
    .hera-title {
      span {
        font-size: 20px !important;
      }
    }
  }
}
</style>
