var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WrapperFormControl',_vm._b({staticClass:"w-100"},'WrapperFormControl',{
    id: _vm.inputID,
    viewMode: _vm.disabled,
    disabled: _vm.disabled,
    required: _vm.required,
    errorInTooltip: _vm.errorInTooltip,
    error: _vm.error,
    label: _vm.label,
    tooltip: _vm.tooltip,
    readOnly: _vm.readOnly,
  },false),[_c('div',{staticClass:"segment-control d-table",class:{ disabled: _vm.disabled },attrs:{"slot":"control"},slot:"control"},_vm._l((_vm.options),function(option){return _c('label',{key:option.id || option.value,staticClass:"segment-control-btn d-table-cell align-middle",class:[
        ("is-1-of-" + (_vm.options.length)),
        { active: _vm.$lodash.isEqual(_vm.value, option.value) } ],attrs:{"id":_vm.inputID},on:{"click":function($event){return _vm.switchVal(option.value)}}},[_c('strong',{staticClass:"d-block align-middle text-center text-truncate"},[_vm._v(_vm._s(option.label))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }