<template>
  <div v-if="heraShopLink">
    <button>
      <a :href="heraShopLink + 'consols.html?cat=236'" target="_blank">{{
        $t('_web_shop_vouchers', 'SHOP VOUCHERS')
      }}</a>
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    heraShopLink() {
      const region = JSON.parse(localStorage.getItem('region'));
      const locale = JSON.parse(localStorage.getItem('locale'));
      return region.feature_flags.marketplace_url
        ? `${region.feature_flags.marketplace_url}${locale.key}-sa/`
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 48px;
  width: 172px;
  height: 48px;
  font-family: 'Exo';
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  border-radius: 30px;
  margin-top: 10px;
  border: 0px solid white;
}

button > a {
  color: black;
  text-decoration: none;
}

button:hover {
  background-color: #dfdfdf;
  & a {
    color: #6300fa;
  }
}
</style>
