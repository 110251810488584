<template>
  <WrapperFormControl
    v-bind="{
      hideLabel: !label,
      required: required && !hideAsterisk,
      label,
      tooltip,
      error,
      hideErrorText,
    }"
  >
    <template slot="control">
      <VueDatePicker
        v-model="valueModel"
        class="time-picker"
        type="time"
        value-type="timestamp"
        :minute-step="5"
        :format="duration ? 'HH[h] mm[m]' : 'HH:mm'"
        :clearable="false"
        v-bind="{
          disabled,
          disabledTime,
          placeholder,
          inputAttr: { size: !fitContent ? 20 : duration ? 5 : 4 },
        }"
        :class="{ duration, 'time-picker--fit-content': fitContent }"
        v-on="eventListeners"
      >
        <template slot="icon-calendar">
          <Icon
            v-if="!hideIcon"
            name="arrow-s"
            class="dropdown-icon"
            width="16"
          />
        </template>
      </VueDatePicker>
    </template>
  </WrapperFormControl>
</template>

<script>
import VueDatePicker from 'vue2-datepicker';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
import Icon from '@/components/atoms/Icon.vue';

import { prop } from '@/utils/factories';
import dateMethods from '@/mixins/dateMethods';

export default {
  name: 'TimePicker',
  components: {
    VueDatePicker,
    WrapperFormControl,
    Icon,
  },
  mixins: [...dateMethods],
  props: {
    value: prop([Number, String], null),
    name: prop(String),
    minDate: prop(Number),
    maxDate: prop(Number),
    required: prop(Boolean),
    disabled: prop(Boolean),
    duration: prop(Boolean),
    label: prop(String),
    placeholder: prop(String),
    error: prop(String),
    tooltip: prop(String),
    fitContent: prop(Boolean),
    hideAsterisk: prop(Boolean),
    hideErrorText: prop(Boolean),
    hideIcon: prop(Boolean),
  },
  data() {
    return {
      // placeholder: this.$t(
      //   '_web_tournament_time-steps-time-placeholder',
      //   'Time'
      // ),
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.secondsToMillis(this.value);
      },
      set(newValue) {
        this.$emit('input', this.millisToSeconds(newValue));
      },
    },
    eventListeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      return listeners;
    },
  },
  methods: {
    disabledTime(date) {
      date = this.toSeconds(this.datetimeFromJsDate(date).toUTC());
      let result = false;
      if (this.minDate) {
        result = date < this.minDate;
      }
      if (this.maxDate) {
        result = result || date > this.maxDate;
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
