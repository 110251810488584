<template>
  <div class="my-3 fill-border custom-scrollbar slider-chat-list" @scroll.stop>
    <ListLoader
      v-if="!chats || !chats.length"
      class="h-100"
      :is-loading="isLoading && !listToRender.length"
      :is-empty-list="!listToRender.length"
      :dummy-props="{
        iconName: 'dummy/chat',
        size: 200,
        subheaderText: $t(
          '_web_sidechat_is-empty',
          'You have no chats at the moment'
        ),
        text: $t(
          '_web_sidechat_is-empty_subheader',
          'Press the button to create a new channel or group chat'
        ),
      }"
    />
    <InfiniteScroll
      v-else
      :list="chats"
      list-name="chats"
      :is-disabled="isLoading || !nextPageToken"
      :in-container="true"
      @ScrollToBottom="fetchChats"
    >
      <template slot-scope="{}">
        <!-- TODO: uncomment after implement search in backend (TH-3211) -->
        <!-- <HeraFormTextInput
          v-model="searchQuery"
          class="slider-chat-list__search mx-3"
          :placeholder="$t('_web_tournament_sidechat_search', 'Search')"
          :dir="localeDirection"
        /> -->
        <Item
          v-for="(item, i) in chatsWithFilter.items"
          :key="'item-' + 'index' + '-' + i"
          :data="item"
          :focus="item.id"
          :date="item.date"
          @click="$emit('openChat', item)"
        />
      </template>
    </InfiniteScroll>
  </div>
</template>

<script>
import { cloneDeep, debounce, uniqBy } from '@/utils/lodashUtils';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Item from './ListItem';
import ListLoader from '@/components/common/HeraListPreloader';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import chatsMock from '@/__mock__/chatsMock.js';

export default {
  name: 'SliderChatList',
  components: { Item, ListLoader, InfiniteScroll },
  props: {
    searchMessageFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chatsMock: chatsMock,
      isLoading: false,
      chats: [],
      searchQuery: '',
      debounceSearchConversations: function() {},
      searchedParticipants: [],
      searchNextPageToken: null,
      debouncedLoadParticipantsList: function() {},
      nextPageToken: '',
      scrollIsDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'localeDirection',
      'sliderChatUnreadCount',
      'sliderChatIsShow',
    ]),
    tournamentID() {
      return this.$route.params.tournamentID;
    },
    listToRender() {
      if (this.searchQuery) {
        return this.searchedParticipants;
      } else {
        return this.chats;
      }
    },
    chatsWithFilter() {
      if (this.chats[0].filteredMessages) {
        // add ! in if statement for everything to work and change n next line chatsMock with chats
        const items = this.chats.flatMap(item => {
          return item.filteredMessages.map(msg => {
            return {
              last_activity: item.last_activity,
              last_message: msg.text,
              last_message_sender_id: item.last_message_sender_id,
              last_message_sender_name: item.last_message_sender_name,
              unread_count: item.unread_count,
              conversation_type: item.conversation_type,
              active: item.active,
              embedded: item.embedded,
              avatar: item.avatar,
              is_deleted: item.is_deleted,
              visible: item.visible,
              pendingUpdate: item.pendingUpdate,
              participants: item.participants,
              participants_count: item.participants_count,
              author: item.author,
              correlationId: item.correlationId,
              correlation_type: item.correlation_type,
              title: item.title,
              subtitle: item.subtitle,
              id: item.id,
              text: msg.text,
              focus: msg.id,
              date: msg.sentDate,
            };
          });
        });

        return {
          items: items,
        };
      } else {
        return { items: { ...this.chats } };
      }
    },
  },
  watch: {
    searchQuery() {
      if (!this.searchQuery) return;
      this.searchedParticipants = [];
      this.debouncedLoadParticipantsList();
    },
    searchMessageFilter() {
      if (
        this.searchMessageFilter.length > 2 ||
        this.searchMessageFilter.length === 0
      ) {
        this.isLoading = true;
        this.chats = [];
        // if (!this.searchMessageFilter) return;
        this.debounceSearchConversations();
        this.nextPageToken = '';
      }
    },
    sliderChatIsShow() {
      if (this.sliderChatIsShow !== false) {
        this.chats = [];
        this.nextPageToken = '';
        this.fetchChats();
      }
    },
  },
  chats() {
    if (this.searchMessageFilter === '') {
      this.fetchChats();
    }
  },

  created() {
    this.fetchChats();
    const searchReloadDelay = 250;
    const searchReloadMessageDelay = 500;
    this.debouncedLoadParticipantsList = debounce(
      this.loadParticipantsList,
      searchReloadDelay
    );
    this.debounceSearchConversations = debounce(
      this.loadSearchMessages,
      searchReloadMessageDelay
    );

    this.addMessageSubscriber(this.messagesSubscriber);
  },
  destroyed() {
    this.removeMessageSubscriber(this.messagesSubscriber);
  },
  methods: {
    ...mapMutations(['setSliderChatUnreadCount']),
    ...mapActions([
      'addMessageSubscriber',
      'removeMessageSubscriber',
      'fetchConversations',
      'getConversationInfo',
      'fetchTournamentPlayers',
      'errorNotify',
    ]),
    messagesSubscriber(res) {
      if (res.type === 'chat_message_sent') {
        this.newMessageHandler(res);
      }
    },
    fetchChats() {
      this.isLoading = true;
      this.fetchConversations({
        pageToken: this.nextPageToken,
        correlationId: this.tournamentID,
        correlationType: 'tournament',
        hasMessages: false,
        Filter: this.searchMessageFilter ? this.searchMessageFilter : null,
      })
        .then(res => {
          this.nextPageToken =
            this.nextPageToken === res.next_page_token
              ? null
              : res.next_page_token;
          this.chats = uniqBy([...this.chats, ...res.items], 'id');
          let pending = this.chats.find(c => c.pendingUpdate == true);

          if (pending) {
            setTimeout(this.reloadPendingChat, 2000, pending.id);
          }
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    reloadPendingChat(id) {
      this.getConversationInfo({
        conversationId: id,
        includeParticipants: false,
      })
        .then(res => {
          if (!res.pendingUpdate) {
            let chat = this.chats.find(c => c.id == id);
            chat.pendingUpdate = null;
          } else {
            setTimeout(this.reloadPendingChat, 2000, id);
          }
        })
        .catch(this.errorNotify);
    },
    loadSearchMessages() {
      this.fetchChats();
    },
    loadParticipantsList() {
      this.fetchTournamentPlayers({
        id: this.tournamentID,
        query: {
          page: this.searchNextPageToken,
          search_term: this.searchQuery,
          hasMessages: true,
        },
      })
        .then(res => {
          if (!res) return;
          this.searchNextPageToken = res.next_page_token;
          let items = [];
          if (res.properties.is_clan) {
            res.items.forEach(clan => {
              const members = clan.members.map(member => {
                return {
                  title: member.user_name,
                  last_message: clan.fields_data.user.name,
                };
              });
              items = [...items, ...members];
            });
          } else {
            items = res.items.map(item => {
              return {
                title: item.fields_data.user.name,
                avatar: item.fields_data.user.avatar,
              };
            });
          }
          this.searchedParticipants = [
            ...this.searchedParticipants,
            ...cloneDeep(items),
          ];
        })
        .catch(this.errorNotify);
    },
    newMessageHandler(newMessage) {
      const newMessageChat = this.chats.find(
        chat => chat.id === newMessage.extended.conversationId
      );
      if (newMessageChat) {
        newMessageChat.last_activity = newMessage.sent_date;
        newMessageChat.last_message = newMessage.text;
        newMessageChat.unread_count++;
      }
      this.setSliderChatUnreadCount(this.sliderChatUnreadCount + 1);
    },
  },
};
</script>

<style lang="scss">
.slider-chat-list {
  .list-loader_wrapper {
    height: 100%;
  }
}
</style>

<!-- 






-->
