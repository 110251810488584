import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

// import config from '@/config';

// import { cancelAllRequests } from '@/api/requestCancel';
// import { areRoutesEqual } from '@/utils';

import {
  ifAuthorized,
  regionRedirect,
  hasLanguageinQuery,
  hasActivationCode,
  hasActivationCodeInSession,
} from './guards.js';

import Home from '@/views/main/Home.vue';
// import NewHome from '@/views/main/NewHome.vue';
import HomePage from '@/views/main/HomePage.vue';
// import HomePageId from '@/views/main/IndonesiaLaunch.vue';

import SingleCompetition from '@/views/main/SingleCompetition.vue';
// const HomePageId = () =>
//   import(/* webpackChunkName: "indonesiaLandingPage" */ '@/views/main/IndonesiaLaunch.vue');
import Error404 from '@/components/common/Error404.vue';
import account from './account/';
import vanityUrls from './account/vanityUrls';
import { VANITY_PREFIX } from '@/constants/router';
import profileSetup from './profileSetup.js';
import staticPages from './static-pages.js';
import auth from './auth.js';
import clans from './clans.js';
import conversations from './conversations';
import competitions from './competitions';
import notifications from './notifications';
import shop from './shop';
import subscription from './subscription';
// import tournaments from './tournaments';
// import tournamentsManagement from './tournaments-management';
import feed from './feed.js';
import search from './search.js';
import Support from '@/components/form/Support.vue';
// import { CONFIG, SHARE_URL } from '@/api/config';

// import { storeRouteToRouterHistory } from '@/utils/routerHistory';

const routerCatch = err => {
  // commented @olsy
  if (process.env.NODE_ENV !== 'production' && err) {
    console.warn(err);
  }
};
['replace', 'push'].forEach(method => {
  const originalMethod = Router.prototype[method];
  Router.prototype[method] = function(location, onComplete, onAbort) {
    if (!onComplete && !onAbort && typeof Promise !== 'undefined') {
      return originalMethod.call(this, location).catch(routerCatch);
    }
    return originalMethod.call(
      this,
      location,
      onComplete,
      onAbort || routerCatch
    );
  };
});

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...auth,
    ...staticPages,
    // {
    //   path: '/indonesia',
    //   component: NewHome,
    //   children: [
    //     {
    //       name: 'IndoHomepage',
    //       path: '/',
    //       component: HomePageId,
    //       meta: {
    //         layout: 'empty',
    //       },
    //     },
    //   ],
    // },
    { path: '/support', name: 'Support', component: Support },
    {
      path: '',
      component: Home,
      beforeEnter: (to, from, next) => [
        ifAuthorized(to, from, next),
        hasLanguageinQuery(to, from, next),
        hasActivationCodeInSession(to, from, next),
        hasActivationCode(to, from, next),
      ],
      children: [
        {
          path: '',
          name: 'homepage',
          component: HomePage,
        },

        {
          path: 'tournament/:tournamentID',
          name: 'singleCompetition',
          component: SingleCompetition,
          props: true,
        },

        ...profileSetup,
        ...feed,
        ...search,
        // ...tournamentsManagement,
        ...conversations,
        ...notifications,
        ...clans,
        ...account,
        ...competitions,
        ...subscription,
        ...shop,
        // ...tournaments,
        {
          path: 'arena',
          name: 'arena',
          meta: {
            // inMainNav: 100,
            // inMainNavOrg: 100,
            titleKey: 'web_left-sidebar_nav-arena-link',
            fallbackLabel: 'Arena',
            icon: 'sidebar/clans',
            disabled: true,
          },
        },
        ...vanityUrls,
      ],
    },
    { path: '/:resource/:id', name: 'Error404', component: Error404 },

    {
      path: '/mena/*',
      beforeEnter: async (to, from, next) => {
        const redirect = await regionRedirect(to);
        if (redirect) {
          next(redirect); // Redirect to the new path if needed
        } else {
          next(); // Proceed to the route if no redirection is needed
        }
      },
    },
    {
      path: '/japan/*',

      beforeEnter: async (to, from, next) => {
        const redirect = await regionRedirect(to);
        if (redirect) {
          next(redirect); // Redirect to the new path if needed
        } else {
          next(); // Proceed to the route if no redirection is needed
        }
      },
    },
    {
      path: '/indonesia/*',

      beforeEnter: async (to, from, next) => {
        const redirect = await regionRedirect(to);
        if (redirect) {
          next(redirect); // Redirect to the new path if needed
        } else {
          next(); // Proceed to the route if no redirection is needed
        }
      },
    },

    {
      path: '/*',
      redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    const isScrollHandlingDisabled =
      to.params.isScrollHandlingDisabled || to.meta.isScrollHandlingDisabled;
    if (to.hash) {
      const $nav = document.querySelector('#nav');
      // TODO - TH-7133 use scss variable instead of hardcoded value
      const navHeight = $nav ? $nav.clientHeight : 70;
      return to.hash === '#saved'
        ? { x: 0, y: 0 }
        : !isScrollHandlingDisabled && {
            selector: to.hash,
            offset: { x: 0, y: navHeight },
          };
    } else if (savedPosition) {
      return savedPosition;
    } else if (!isScrollHandlingDisabled) {
      return { x: 0, y: 0 };
    } else {
      return {};
    }
  },
});

// router.beforeEach((to, from, next) => {
//   storeRouteToRouterHistory(from.name);
//   if (
//     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//       navigator.userAgent
//     ) &&
//     !isStaticPath()
//   ) {
//     let lang = '';

//     if (navigator.language.includes('en')) {
//       lang = '#eng';
//     } else if (navigator.language.includes('ja')) {
//       lang = '#jap';
//     } else if (navigator.language.includes('ar')) {
//       lang = '#arab';
//     }
//     window.location.href = SHARE_URL + lang;
//   }

//   if (!areRoutesEqual(to, from)) {
//     cancelAllRequests();
//   }
//   next();

//   function isStaticPath() {
//     return (
//       to.path.startsWith(CONFIG.termsLink) ||
//       to.path.startsWith(CONFIG.privacyLink)
//     );
//   }
// });

router.beforeResolve((to, from, next) => {
  const hasNotAccess = to.matched.some(
    ({ meta }) =>
      !(meta && meta.permission
        ? store.getters.hasAccessByPermission(meta.permission)
        : true)
  );
  if (hasNotAccess) {
    next('/');
  } else {
    const isVanity = to.name && to.name.indexOf(VANITY_PREFIX) > -1;
    if (to.params.vanityId && !isVanity) {
      next({
        ...to,
        name: `${VANITY_PREFIX}${to.name}`,
      });
    } else {
      next();
    }
  }
});

export default router;
