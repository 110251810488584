<template>
  <div class="position-relative chat-dialog-header-wrap">
    <div v-if="conversationInfo" class="chat-dialog-header">
      <AppIcon
        name="arrow-right"
        :width="20"
        :rotate="isRTL ? 0 : -180"
        class="align-self-center cursor-hand  d-md-none p-3 mr-3 back-btn-chat"
        @click="goBack"
      />
      <LinkAccount
        v-if="headerLink.params"
        :id="headerLink.params.accountId"
        :type="headerLink.params.accountType"
      >
        <ListItem
          :avatar-props="{
            size: 48,
            imgUrl: conversationInfo.avatar,
            type: avatarType,
          }"
          :title="conversationInfo.title"
          :class="'header-list-item-chat'"
        >
          <div slot="tail" class="d-flex flex-nowrap">
            <AppIcon
              name="translate"
              :width="20"
              class="cursor-hand"
              @click.prevent.stop="showModalTranslate"
            />
            <AppIcon
              v-if="miniMode"
              name="arrow-left"
              :width="20"
              :rotate="isRTL ? 0 : -180"
              class="align-self-center cursor-hand h-ml-4"
              @click.prevent.stop="removeActivePopoverChat"
            />
            <ThreeDots
              v-else
              class="h-ml-4"
              :items="threeDotsItems"
              hide-if-items-is-empty
            />
          </div>
        </ListItem>
      </LinkAccount>
      <div v-else>
        <ListItem
          :avatar-props="{
            size: 48,
            imgUrl: conversationInfo.avatar,
            type: avatarType,
          }"
          :title="conversationInfo.title"
          :class="'header-list-item-chat'"
        >
          <div slot="tail" class="d-flex flex-nowrap">
            <AppIcon
              name="translate"
              :width="20"
              class="cursor-hand"
              @click.prevent.stop="showModalTranslate"
            />
            <AppIcon
              v-if="miniMode"
              name="arrow-left"
              :width="20"
              :rotate="isRTL ? 0 : -180"
              class="align-self-center cursor-hand h-ml-4"
              @click.prevent.stop="removeActivePopoverChat"
            />
            <ThreeDots
              v-else
              class="h-ml-4"
              :items="threeDotsItems"
              hide-if-items-is-empty
            />
          </div>
        </ListItem>
      </div>
    </div>
    <div
      v-if="conversationInfo"
      class="show-hide-participants"
      :class="miniMode ? 'dialogChat' : ''"
      @click="toggleShow"
    >
      {{
        show
          ? $t('_web_conversation_hide_participants', 'hide participants')
          : $t('_web_conversation_show_participants', 'show participants')
      }}
    </div>
    <div v-if="show" class="participants-list custom-scrollbar">
      <div
        v-for="(participant, index) in conversationInfo.participants"
        :key="index"
      >
        <LinkAccount
          :id="participant.id"
          :type="participant.subjectType"
          class="link-uncolor"
        >
          <Avatar
            :img-url="participant.avatar"
            :size="36"
            :type="participant.subjectType"
          />
          {{ participant.name }}
        </LinkAccount>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/images/Avatar';

import LinkAccount from '@/components/links/Account';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  generateLinkByConversationData,
  getAvatarTypeByConversation,
} from '@/utils/getProps';
import {
  CONVERSATION_TYPE_DIALOG,
  CONVERSATION_TYPE_CLAN,
} from '@/utils/getProps';
import AppIcon from '@/components/atoms/Icon';
import ThreeDots from '@/components/common/ThreeDots';
import ListItem from '@/components/common/ListItem.vue';
import { prop } from '@/utils/factories';

export default {
  name: 'ConversationHeader',
  components: {
    AppIcon,
    ThreeDots,
    ListItem,
    LinkAccount,
    Avatar,
  },
  props: {
    miniMode: prop(Boolean),
    conversationInfo: prop(Object, null),
    hasItems: prop(Boolean),
  },
  data() {
    return {
      chatLang: '',
      conversationOptionsPosition: null,
      show: false,
    };
  },
  computed: {
    ...mapGetters(['isRTL']),
    avatarType() {
      return getAvatarTypeByConversation(this.conversationInfo);
    },
    headerLink() {
      return generateLinkByConversationData(this.conversationInfo);
    },
    isDialog() {
      return (
        this.conversationInfo.conversation_type === CONVERSATION_TYPE_DIALOG
      );
    },
    isClanChat() {
      return this.conversationInfo.conversation_type === CONVERSATION_TYPE_CLAN;
    },
    threeDotsItems() {
      const items = [];

      if (!this.isDialog && !this.isClanChat) {
        items.push({
          label: this.$t(
            '_web_conversation_delete-conversation',
            'Delete Conversation'
          ),
          action: () => this.deleteAllConversation(),
        });
      }

      return items;
    },
  },
  watch: {
    conversationInfo(newInfo, oldInfo) {
      if (!newInfo || !oldInfo || newInfo.id !== oldInfo.id) {
        this.chatLang = '';
      }
    },
  },
  methods: {
    ...mapMutations(['removeActivePopoverChat', 'updateChatListKey']),
    ...mapActions([
      'openModal',
      'deleteConversation',
      'translateMsg',
      'updateConversation',
      'errorNotify',
    ]),
    showModalTranslate() {
      this.openModal({
        name: 'TranslateChatPopup',
        props: {
          currentLang: this.chatLang,
        },
        events: {
          confirm: this.translateChat,
        },
        settings: {
          variant: 'narrow-list',
          closeIcon: true,
        },
      });
    },
    translateChat(lang) {
      this.chatLang = lang;
      this.$emit('translateChat', lang);
    },
    toggleShow() {
      this.show = !this.show;
    },

    deleteAllConversation() {
      this.conversationOptionsPosition = null;

      const { id } = this.conversationInfo;

      (this.isDialog
        ? this.deleteConversation(id)
        : this.updateConversation({ id, data: { visible: false } })
      )
        .then(this.updateChatListKey)
        .catch(this.errorNotify);

      this.$router.push({ name: 'conversations' });
    },
    goBack() {
      this.$router.push({ name: 'conversations' });
      // this.$router.go(-1); // Navigate to previous route
    },
  },
};
</script>

<style lang="scss">
.chat-dialog-header-wrap {
  z-index: 20;
}
.chat-dialog-wrap {
  &.mini-mode {
    .chat-dialog-header {
      border-radius: $ph-large-radius 0 0 0;
      background-color: $new-bg-base;
    }
    .chat-dialog-header-title {
      width: calc(100% - 80px);
      line-height: 1rem;
    }
  }
  &.screen-mode {
    .chat-dialog-header {
      display: flex !important;

      border-bottom: 1px solid $ph-secondary-text;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .chat-dialog-header-title {
      width: calc(100% - 120px);
    }
  }
}
.header-list-item-chat {
  width: 100%;
}
@media (max-width: 500px) {
  .back-btn-chat {
    margin-left: 0 !important;
  }
}
.participants-list {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  position: absolute;
  background-color: #11171f;
  width: 100%;
  overflow: auto;
  max-height: 240px;
}
.show-hide-participants {
  margin-top: -2rem;
  margin-bottom: 10px;
  margin-left: 55px;
  margin-right: 55px;
  cursor: pointer;
  color: gray;

  @media screen and (max-width: 767px) {
    @include auto-rtl(margin-left, 6.2rem);
  }
}
.dialogChat {
  margin-top: -1.1rem !important;
}
</style>
