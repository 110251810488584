<template>
  <div>
    <div class="wrapper-mobile-content">
      <router-link class="back-to-feed-mobile" :to="'/feed'">
        <Icon class="rtl-arrow-left" name="arrow-left" width="30" />
      </router-link>
      <div class="centered-text">Post</div>
    </div>

    <router-link class="back-to-feed" :to="'/feed'">
      <button class="pagi-arrow">
        <Icon class="rtl-arrow-left" name="arrow-left" width="30" />
      </button>
    </router-link>
    <div ref="popoverClosest" class="outer-wrap ">
      <div class="single-post-wrap post">
        <Post
          v-if="post && !postToEdit"
          :key="post.id"
          :post="post"
          :type="post.tournament ? 'tournament_announced_post' : post._type"
          :author="author"
          @dotsClick="event => openPopoverMenu(event, post)"
        />
        <CreatePost
          v-if="!$lodash.isEmpty(post) && postToEdit === post.id"
          :key="post.key"
          :author-model="account"
          :post-model="post"
          class="mb-6"
          @post-created="updatePost"
          @post-cancel="cancelEdit"
        />
        <Popover
          :position="popoverPosition"
          :closest="$refs.popoverClosest"
          :post="popoverPost"
          @toggle="closePopover"
          @edit="editPost"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import viewsTrackingMixin from '@/mixins/metrics';
import Post from '@/components/posts/PostItem.vue';
import CreatePost from '@/components/posts/CreatePost';

import Popover from '@/components/posts/PostPopover';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'SinglePost',
  components: { Post, Popover, Icon, CreatePost },
  mixins: [viewsTrackingMixin],
  data() {
    return {
      post: {},
      author: {},
      isLoading: false,
      popoverPosition: null,
      popoverPost: null,
      currentPosition: 0,
      postToEdit: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
  },
  watch: {
    '$route.params'({ id }) {
      this.getPost(id);
    },
    currentPosition() {
      if (
        this.currentPosition + 1 === this.post.length &&
        this.nextPage === null
      ) {
        this.lastPage = true;
      }
    },
  },
  created() {
    document.addEventListener('keydown', this.goToPreviousRoute);
    this.historyLimit = 2;
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.goToPreviousRoute);
  },
  mounted() {
    this.getPost(this.$route.params.id);
  },

  methods: {
    ...mapActions(['getPostDetails', 'errorNotify']),
    ...mapState('my', ['account']),
    closePopover(isVisible) {
      if (!isVisible && !this.modalIsShow) {
        this.popoverPosition = null;
        /* commented, because modal opened after this function and PostPopover can not delete post (=== null)
          this.popoverPost = null;
          */
      }
    },

    goToPreviousRoute(key) {
      if (key.code !== 'Escape') return;
      this.$router.go(-1);
    },
    getPost(id) {
      this.getPostDetails(id)
        .then(({ post = null }) => {
          this.post = post;
          this.author = post.author;
        })
        .catch(this.errorNotify);
    },
    editPost(post) {
      if (post) {
        this.postToEdit = post.id;
      }
    },
    cancelEdit(post) {
      if (post) this.postToEdit = false;
    },
    updatePost(post) {
      this.post = {
        ...this.post,
        text: post.post.text,
        attachments: post.post.attachments,
      };
    },
    onPostPinned(post) {
      this.post[this.currentPosition].pinned = !post.pinned;
    },
    goBack() {
      if (window.history.length > this.historyLimit) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
    openPopoverMenu(position, post) {
      this.popoverPosition = position;
      this.popoverPost = post;
    },
  },
};
</script>

<style lang="scss">
.main-container {
  display: flex;
  justify-content: center;
  /* min-height: calc(100vh - ($header-height + $footer-height)); */
  padding: 0px !important;
  background: $new-bg-page;
  height: 100%;
  max-height: calc(100vh - 66px - 48px);
  overflow-y: hidden;
  @media (max-width: 1100px) {
    background: black;
    position: relative;
    flex-wrap: wrap;
    overflow: auto;
    max-height: none;
  }
}

.hr-post-media-content {
  position: relative;
  width: 70%;
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.hr-post-side-content {
  flex-basis: 1;
  width: 30%;
  min-width: 454px;
  @media (max-width: 1100px) {
    width: 100%;
    min-width: unset;
    display: none;
  }
}
.close-box {
  border-radius: 90%;
  border: 1px solid black;
  @media (max-width: 1100px) {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #30374a;
    background-color: #181c25;
  }
}

.close-box-wrap {
  overflow-y: hidden;
  max-height: 200px;
  @media (max-width: 1100px) {
    margin: 13px;
    position: absolute;
    left: -30px;
    top: -30px;
    z-index: 10;
  }
}

.hr-post-video {
  height: 60vh !important;
  max-width: 50vw !important;
}

.media-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  @media (max-width: 1100px) {
    align-items: flex-start;
    padding-top: 0px;
    height: 100dvh;
  }
}

.hr-video-element {
  // max-height: 80vh;
  max-width: 50vw;
  flex: 0 0 50%;
  @media (max-width: 1100px) {
    // max-height: unset;
    max-width: unset;
    width: 100%;
    flex: auto;
    height: 100%;
  }
}
</style>
<style lang="scss">
.comment-item {
  word-break: break-all;
  @media (max-width: 1100px) {
    @include auto-rtl(padding-right, 10px);
  }
}
.hr-post-wrapper {
  .mobile-hide {
    display: none;
  }
  @media (max-width: 1100px) {
    .post-comments-sum {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  .comment-item {
    @include auto-rtl(padding-left, 34px);
    @include auto-rtl(padding-right, 10px);
    @media (max-width: 1100px) {
      .post-comments-sum {
        padding: 10px !important;
      }
      .header-comment-item {
        display: none !important;
      }
      padding-left: 10px;
      padding-right: 14px;
    }
  }
  .text-input-container {
    @include auto-rtl(margin-right, 5% !important);
    // background-color: #181c25 !important;;
  }
  .textarea-container {
    background-color: #181c25 !important;
    // max-width: 60vw !important;
  }
  // .textarea {
  //   // background-color: #181c25 !important;
  // }
  // .text-truncate {
  //   // background-color: #181c25 !important;
  // }
  margin-left: 0;
  .post-comments-sum {
    @include auto-rtl(padding-left, 34px);
    @include auto-rtl(padding-right, 10px);
    margin-top: 10px !important;
  }
  @media (max-width: 1100px) {
    padding-bottom: 20px;
    display: block;
    // background-color: #181c25;
    .post-comments-sum {
      padding-bottom: 0px;
      padding-left: 10px !important;
      margin-bottom: 16px;
      margin-top: 0px !important;
    }

    .gif-icon {
      display: none !important;
    }
    .emoji-icon {
      display: none !important;
    }
    .form {
      margin-top: 15px !important;
    }
    .comment-section {
      max-width: 95vw !important;
      word-break: break-all !important;
      .bdi-wraper-text {
        display: block;
        text-align: start;
      }
    }
    .header-comment-item {
      display: block !important;
    }
    hr {
      margin: 0;
    }
    .comment-input-footer {
      margin-top: 0px !important;
    }
    .sc-post-attach {
      max-width: 60vw;
    }
    .text-input-container {
      margin-right: 5% !important;
      // background-color: #11171f !important;
    }
    // .text-truncate {
    //   // background-color: #181c25 !important;
    // }
    .textarea-container {
      background-color: #181c25 !important;
      // max-width: 60vw !important;
    }
    .textarea {
      background-color: #181c25 !important;
    }
    .textarea-bottom {
      background-color: #181c25 !important;
    }
    .comment-input-action-emoji {
      display: none;
    }
    .comment-input-action-gif {
      display: none;
    }
  }
}

html[dir='rtl'] {
  .comment-attachments {
    @media (max-width: 1100px) {
      margin-left: 5% !important;
    }
  }
}
.paggination-actions {
  position: absolute;
  display: flex;
  @include auto-rtl(right, 40px);
  top: 40px;
  z-index: 100;
  gap: 10px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.paggination-arrow {
  border-radius: 50%;
  background: #181c25;
  height: 40px;
  width: 40px;
  font-size: 2rem;
  color: white;
  border: 1px solid #30374a;
  display: flex;
  justify-content: center;
  align-items: center;

  .app-rtl & {
    transform: scale(-1);
  }
}

.highlights-mobile-info-bottom {
  display: none;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (max-width: 1100px) {
    display: block;
    position: fixed;
    bottom: 50px;
  }
}

.highlights-avatar-mobile {
  height: 40px;
  border-radius: 5px;
}

.highlights-mobile-info-cont {
  display: flex;
}

.highlights-bottom-desc {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.highlights-video-component {
  max-height: 100%;
}

@media screen and (max-width: 1100px) {
  .com-sum-mobile {
    @include auto-rtl(margin-left, 24px);
  }
}
.post-body {
  // padding-right: 2% !important;
  @include auto-rtl(padding-right, 2%);
  padding-right: 50px;
  @include auto-rtl(padding-left, 62px);
  // padding-left: 62px;
  @media (max-width: 768px) {
    padding-left: 50px;
  }
}

.single-post-wrap {
  max-width: 60%;
  background: #11171f;
  margin: auto;
  height: 100%;
  flex-grow: 3;
  @media (max-width: 900px) {
    max-width: 100%;
  }
}
.post {
  .braking-lineing {
    @include auto-rtl(margin-left, 64px);
    @media (max-width: 768px) {
      @include auto-rtl(margin-left, 50px);
    }
  }

  .repost-spacing {
    margin-left: 50px;
  }
  .post-dots {
    position: absolute;
    // top: 0;
  }
  .follow-button-newsfeed {
    margin-left: 50px;
    margin-right: 50px;
  }
  .avatar-icon {
    @media (max-width: 768px) {
      width: 32px !important;
      height: 32px !important;
      min-width: unset !important;
    }
  }
  .post-body {
    // padding-right: 2% !important;
    @include auto-rtl(padding-right, 2%);
    @include auto-rtl(padding-left, 62px);
    // padding-left: 62px;
    @media (max-width: 768px) {
      padding-left: 50px !important;
    }
  }
  .img-grid-1 {
    height: 100%;
    img,
    video {
      max-height: 443px;
      object-fit: contain !important;
      width: fit-content;
      max-width: 100%;
      min-width: unset;
    }
    .video-preview {
      width: fit-content;
    }
  }
}
.outer-wrap {
  display: flex;
  width: 100%;
  //   height: 100dvh;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.back-to-feed {
  position: absolute;
  top: 40px;
  @include auto-rtl(left, 90px);
  @media (max-width: 900px) {
    display: none;
  }
}
.back-to-feed-mobile {
  justify-content: start;
  display: none;
  @media (max-width: 900px) {
    display: flex;
  }
}
.pagi-arrow {
  border-radius: 50%;
  background: #30374a;
  height: 40px;
  width: 40px;
  font-size: 2rem;
  color: white;
  border: 1px solid #30374a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-mobile-content {
  display: none;
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    padding-top: 27px;
    .app-ltr & {
      padding-left: 17px;
    }
    .app-rtl & {
      padding-right: 17px;
    }
  }
}

.centered-text {
  display: none;
  @media (max-width: 900px) {
    display: unset;
    flex-grow: 1;
    text-align: center;
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #eff0f1;
  }
}
.rtl-arrow-left {
  .app-rtl & {
    scale: -1;
  }
}
.post-comments-sum {
  @media (max-width: 768px) {
    margin-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.centered-text {
  @include auto-rtl(padding-right, 47px);
}
</style>
