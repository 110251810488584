import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=14c12e3a&"
import script from "./Icon.vue?vue&type=script&lang=js&"
export * from "./Icon.vue?vue&type=script&lang=js&"
import style0 from "./Icon.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Icon.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2F&name=Icon&knobs=%7B%0A%20%20%20%20fill%3A%20%7B%0A%20%20%20%20%20%20default%3A%20color('Fill'%2C%20'%23ffffff')%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20hoverFill%3A%20%7B%0A%20%20%20%20%20%20default%3A%20color('Hover%20Fill'%2C%20'%23ff0000')%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20width%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('Width'%2C%2032%2C%20%7B%0A%20%20%20%20%20%20%20%20%20range%3A%20true%2C%0A%20%20%20%20%20%20%20%20%20min%3A%208%2C%0A%20%20%20%20%20%20%20%20%20max%3A%20128%2C%0A%20%20%20%20%20%20%20%20%20step%3A%204%2C%0A%20%20%20%20%20%20%7D)%0A%20%20%20%20%7D%2C%0A%20%20%20%20rotate%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('Rotate'%2C%200%2C%20%7B%0A%20%20%20%20%20%20%20%20range%3A%20true%2C%0A%20%20%20%20%20%20%20%20min%3A%200%2C%0A%20%20%20%20%20%20%20%20max%3A%20360%2C%0A%20%20%20%20%20%20%20%20step%3A%201%2C%0A%20%20%20%20%20%20%7D)%0A%20%20%20%20%7D%2C%0A%20%20%20%20hoverName%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Hover%20Icon%20Name'%2C%20'')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports