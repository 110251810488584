import { authorizedGetRequest } from '@/api/requests';

const STATISTICS_BASE = 'statistics';

export const statisticDraft = authorizedGetRequest(`${STATISTICS_BASE}/draft`);

export const statisticGames = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/games/${countryId}`);

export const statisticCities = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/cities/${countryId}`);

export const statisticLeagues = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/leagues/${countryId}`);

export const statisticPlayers = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/players/${countryId}`);

export const statisticPlatforms = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/platforms/${countryId}`);

export const statisticTournaments = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/tournaments/${countryId}`);

export const statisticRegistrations = countryId =>
  authorizedGetRequest(`${STATISTICS_BASE}/registrations/${countryId}`);

export const getPostEventReport = eventId =>
  authorizedGetRequest(`${STATISTICS_BASE}/reports/post_event/${eventId}`);

export const statisticTournamentPromotion = tournamentId =>
  authorizedGetRequest(
    `${STATISTICS_BASE}/tournament/${tournamentId}/promotion`
  );

export default {
  statisticDraft,
  statisticGames,
  statisticCities,
  statisticLeagues,
  statisticPlayers,
  statisticPlatforms,
  statisticTournaments,
  statisticRegistrations,
  getPostEventReport,
  statisticTournamentPromotion,
};
