export const thousandsShort = item => {
  let count = item;
  if (count > 999) {
    count = (count / 1000).toFixed(1) + 'K';
  }
  return count;
};
export default {
  thousandsShort,
};
