var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableWrapper",staticClass:"mb-4"},[(_vm.fetchedWithoutFilters && !_vm.tournaments.length)?_c('HeraDummyList',{staticClass:"my-6 py-6 tournament-list-empty",attrs:{"icon-name":"empty-competitions","size":"200","header-text":_vm.$t(
        '_web_competitions_tournaments-list-empty-state',
        'There are no tournaments at the moment'
      ),"text":_vm.dummyText}},[(_vm.canCreateTournament)?_c('div',{staticClass:"d-flex justify-content-center pt-6 mt-4",attrs:{"slot":"ctaButton"},slot:"ctaButton"},[_c('router-link',{attrs:{"to":{ name: 'TournamentSetup' }}},[_c('HeraFormBtn',{staticClass:"btn btn-primary create-tournament-button",attrs:{"variant":"full-width","label":_vm.createButtonLabel}})],1)],1):_vm._e()]):[(
        _vm.canCreateTournament &&
          (_vm.isActiveTab ? !(!_vm.isLoading && !_vm.tournaments.length) : true)
      )?_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{attrs:{"to":{ name: 'TournamentSetup' }}},[_c('HeraFormBtn',{staticClass:"btn btn-primary create-tournament-button",attrs:{"label":_vm.createButtonLabel}})],1)],1):_vm._e(),_c('div',{staticClass:"row no-gutters justify-content-center mt-5 mb-6"},[_c('div',{staticClass:"col-6"},[_c('HorizontalTabs',{staticClass:"w-100 tournaments-tabs position-relative",attrs:{"tabs":_vm.tabs,"active-tab":_vm.activeTab,"use-custom":""},on:{"change":_vm.setActiveTab}})],1)]),_c('div',{staticClass:"row d-flex justify-content-between align-items-end my-2 py-1"},[_vm._l((_vm.multiselectTournamentFilters),function(filter,key){return _c('CustomFilter',{key:key,staticClass:"col-2 mb-3",attrs:{"options":filter.options,"disabled":!filter.options,"input-name":key,"label":filter.label,"all-placeholder":filter.allPlaceholder,"clear-if-selected-all":""},on:{"close":_vm.onFilterChange},model:{value:(_vm.filters[key]),callback:function ($$v) {_vm.$set(_vm.filters, key, $$v)},expression:"filters[key]"}})}),_c('HeraFormDropdown',{staticClass:"col-2 mb-3",attrs:{"list":_vm.sortByOptions,"label":_vm.$t('_web_competitions_tournaments-list-sort-label', 'Sort by'),"variant":"input-like","theme-dark":""},on:{"input":_vm.onFilterChange},scopedSlots:_vm._u([{key:"chosen",fn:function(chosen){return [_vm._v("\n          "+_vm._s(_vm.$t(chosen.chosenItem.key, chosen.chosenItem.label))+"\n        ")]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$t(item.key, item.label))+"\n        ")]}}]),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('SearchInput',{staticClass:"col-3 h-ml-auto",attrs:{"debounce":700,"placeholder":_vm.$t(
            '_web_competitions_tournaments-list-search-placeholder',
            'Search'
          )},on:{"input":_vm.onFilterChange},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],2),_c('Paginated',_vm._b({staticClass:"w-auto mt-4 pt-3",on:{"change":_vm.fetchTournaments}},'Paginated',{
        totalItems: _vm.totalItems,
        hidePagination: !_vm.tournaments.length,
        pageSize: _vm.pageSize,
        inContainer: true,
      },false),[(_vm.tournaments.length)?[_vm._l((_vm.tournaments),function(item){return [_c('div',{key:item.id,staticClass:"col-12 col-lg-4 mb-5"},[_c('TournamentCard',_vm._b({staticClass:"h-100"},'TournamentCard',{
                tournament: item,
                forOrganizer: true,
              },false))],1)]})]:_c('HeraDummyList',_vm._b({scopedSlots:_vm._u([{key:"ctaButton",fn:function(){return [(_vm.isActiveTab && !_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center pt-6 mt-4"},[(_vm.canCreateTournament)?_c('router-link',{attrs:{"to":{ name: 'TournamentSetup' }}},[_c('HeraFormBtn',{staticClass:"btn btn-primary create-new-tournament-button",attrs:{"variant":"full-width","label":_vm.createButtonLabel}})],1):_vm._e()],1):_vm._e()]},proxy:true}])},'HeraDummyList',Object.assign({}, {size: 200,
          iconName: 'empty-competitions',
          headerText: _vm.$t(
            '_web_competitions_tournaments-list-empty-state',
            'There are no tournaments at the moment'
          ),
          text: _vm.dummyText},
          (_vm.isLoading
            ? {
                headerText: _vm.$t('_web_list-loading', 'Loading'),
                subheaderText: '',
                text: '',
              }
            : null)),false))],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }