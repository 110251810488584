<template>
  <div
    class="hr-post-body"
    :class="{ 'reposted h-pl-5 my-4': reposted, 'mt-4': !author }"
  >
    <div v-if="isAuthorVisible && author" class="row no-gutters mb-2">
      <div class="col-auto">
        <LinkAccount
          :id="author._id"
          :type="author._type"
          :vanity-id="author.vanityId"
        >
          <Avatar
            :img-url="author._avatar"
            :size="48"
            :type="author._type"
            :is-deleted="author.deleted"
          />
        </LinkAccount>
      </div>
      <div
        class="col-auto d-flex flex-column ml-3 mr-3 align-items-start justify-content-center"
      >
        <LinkAccount
          :id="author._id"
          class="hr-login"
          :type="author._type"
          :vanity-id="author.vanityId"
        >
          {{ author._name }}
        </LinkAccount>
        <div>
          <router-link :to="{ name: 'post', params: { id: post.id } }">
            <span class="hr-timeago">
              {{ post.created_on | timeAgo($t) }}
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <template v-if="type === GENERIC || type === POLL">
      <TextContent
        v-if="postText"
        has-expander
        :content="postText"
        class="mt-4 font-size-sm hr-post-text-content"
      />
      <LinkPreview v-else-if="post.preview" v-bind="{ ...post.preview }" />
    </template>

    <Poll v-if="type === POLL" :poll="post.poll" />

    <router-link
      v-else-if="type === TOURNAMENT_FINISHED || type === TOURNAMENT_ANNOUNCED"
      :to="{ name: 'post', params: { id: post.id } }"
      class="d-block text-decoration-none"
    >
      <Tournament
        :hide-cover="hideCover"
        :tournament="post.tournament"
        :owner="post.author"
        :is-announced="type === TOURNAMENT_ANNOUNCED"
      />
    </router-link>
  </div>
</template>

<script>
import { get } from '@/utils/lodashUtils';
import Avatar from '@/components/images/Avatar';
import TextContent from '@/components/text/TextContent';
import Poll from './components/Poll';
import Tournament from './components/Tournament';
import LinkAccount from '@/components/links/Account';
import AccountModel from '@/models/Account';

import LinkPreview from '@/components/molecules/OpenGraphCard';
import {
  GENERIC,
  POLL,
  TOURNAMENT_FINISHED,
  TOURNAMENT_ANNOUNCED,
  INJECTION_POSTS,
} from '@/constants/postTypes';

export default {
  name: 'HRPost',

  components: {
    LinkAccount,
    TextContent,
    Avatar,
    Poll,
    Tournament,
    LinkPreview,
  },

  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    reposted: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: GENERIC,
    },
    hideCover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const { author } = this.post;
    const data = {
      GENERIC,
      POLL,
      TOURNAMENT_FINISHED,
      TOURNAMENT_ANNOUNCED,
    };
    if (author) {
      data.author = new AccountModel(
        author.type || 'profile',
        author.id || author.login
      ).update(author);
    }
    return data;
  },

  computed: {
    postText() {
      return get(this, 'post.text') || get(this, 'post.poll.text') || '';
    },

    isAuthorVisible() {
      return !INJECTION_POSTS.includes(this.type);
    },
  },

  watch: {
    post() {
      this.author = new AccountModel(
        this.post.author.type || 'profile',
        this.post.author.id || this.post.author.login
      ).update(this.post.author);
      window.dataLayer.push({
        event: 'highlight video watched',
        postAuthor: this.post.author.name,
        postId: this.post.id,
      });
    },
  },
  created() {
    window.dataLayer.push({
      event: 'highlight video watched',
      postAuthor: this.post.author.name,
      postId: this.post.id,
    });
  },
};
</script>

<style lang="scss" scoped>
.hr-post-body {
  border-radius: 4px;

  &.reposted {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      @include auto-rtl(left);
      width: 5px;
      height: 100%;
      border-radius: $ph-round-radius;
      background-color: $ph-secondary-text;
    }
  }

  .post-image {
    object-fit: contain;
  }

  .time {
    line-height: $ph-small-text-line-height;
    margin: 0 $hr-spacing-04;
  }

  .hr-login {
    color: $ph-primary-text;
    line-height: $ph-small-text-line-height;
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #ffffff;
  }

  .posted a {
    color: $ph-secondary-text;
  }
}

.hr-timeago {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #474a56 !important;
}
.hr-post-text-content {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
</style>
