<template>
  <div class="settings">
    <div v-for="setting in settings" :key="setting.key">
      <div
        class="d-flex align-items-center flex-column text-center settings__item"
      >
        <Icon
          :name="setting.icon"
          :width="24"
          :fill="$scss.lightBordersColor"
        />
        <TextBlock class="my-3 settings__item-title">
          {{ setting.label }}
          <InfoTooltip v-if="setting.helpText" :text="setting.helpText" />
        </TextBlock>
        <router-link
          :to="{
            name: 'TournamentSettings',
            params: $route.params,
            hash: setting.hash,
          }"
          class="d-flex"
        >
          <div>{{ $t('_web_change', 'Change') }}</div>
          <Icon name="arrow-triangle-rounded" :width="8" class="ml-1" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import InfoTooltip from '@/components/molecules/InfoTooltip';

export default {
  name: 'TournamentAutoSettingsWidget',
  components: {
    InfoTooltip,
    TextBlock,
    Icon,
  },
  mixins: [translate('_web_tournament_dashboard')],
  computed: {
    ...mapGetters(['currentTournament']),
    autoprocessTournament() {
      return this.$lodash.get(
        this.currentTournament,
        'schedule.auto_activation_mode',
        null
      );
    },
    isPublicTournament() {
      return (
        this.currentTournament.registration_type === 'open' ||
        this.$lodash.get(this.currentTournament, 'info.registration_type') ===
          'open'
      );
    },
    squadSize() {
      return this.$lodash.get(
        this.currentTournament,
        'players_config.squad_size'
      );
    },
    isSoloTournament() {
      return this.squadSize === 1;
    },
    hasRandomQualification() {
      return (
        this.$lodash.get(
          this.currentTournament,
          'players_config.qualification_type'
        ) === 'random'
      );
    },
    settings() {
      return [
        {
          label: this.isPublicTournament
            ? this.$$t('Public', 'registration-open')
            : this.$$t('Private', 'registration-private'),
          key: 'availability',
          icon: this.isPublicTournament ? 'opened-key' : 'key',
          hash: '#settings-promotion',
        },
        {
          label: this.isSoloTournament
            ? this.$$t('Solo', 'squads-1v1')
            : `${this.squadSize}v${this.squadSize}`,
          key: 'squadSize',
          icon: this.isSoloTournament ? 'solo' : 'controllers',
          hash: '#settings-participants',
        },
        {
          label: this.hasRandomQualification
            ? this.$$t('Random qualification')
            : this.$$t('Qualify first registered'),
          key: 'qualificationType',
          icon: this.hasRandomQualification ? 'user-shuffle' : 'user-check',
          hash: '#settings-schedule',
        },
        // {
        //   label: this.autoprocessTournament
        //     ? this.$$t('Auto registration', 'registration-auto')
        //     : this.$$t('Manual Registration', 'registration-manual'),
        //   key: 'registaration-auto',
        //   icon: 'play-circle',
        //   hash: '#settings-schedule',
        // },
        {
          label: this.autoprocessTournament
            ? this.$$t('Auto qualification', 'qualification-auto')
            : this.$$t('Manual qualification', 'qualification-manual'),
          key: 'qualification-auto',
          icon: 'play-circle',
          hash: '#settings-schedule',
          helpText: this.$$t(
            'Once registration is completed system automatically qualifies participants and builds bracket',
            'auto-process-tip'
          ),
        },
        // {
        //   label: this.autoprocessTournament
        //     ? this.$$t('Auto process brackets', 'brackets-auto')
        //     : this.$$t('Manual brackets', 'brackets-manual'),
        //   key: 'brackets-auto',
        //   icon: 'brackets',
        //   hash: '#settings-schedule',
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  &__item {
    margin-bottom: 50px;
    &-title {
      max-width: 100px;
      font-family: $ph-font-family-secondary;
      display: flex;
      align-items: flex-end;
    }
  }
}
</style>
