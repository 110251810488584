import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      stageID: this.$route.params.stageID,
      blockID: this.$route.params.blockID,
    };
  },
  computed: {
    ...mapGetters['currentTournament'],
    tournamentID() {
      return this.$route.params.tournamentID || this.currentTournament.id;
    },
  },
  methods: {
    ...mapMutations(['updateModalProps']),
    ...mapActions([
      'resetBlockBrackets',
      'resetStageBrackets',
      'successNotify',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    confirmResetBrackets(stage, resetAll) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-block_qualifiers_reset_brackets_modal_title',
            'This action will trigger reset brackets.'
          ),
          isLoading: false,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onResetBrackets.bind(null, stage, resetAll),
        },
      });
    },
    onResetBrackets(stage = {}, resetAll = false) {
      this.updateModalProps({ isLoading: true });
      const options = resetAll
        ? {
            tournamentID: this.tournamentID,
            stageID: this.stageID || stage.id,
          }
        : {
            tournamentID: this.tournamentID,
            stageID: this.stageID || stage.id,
            blockID: this.blockID,
          };

      const method = resetAll
        ? this.resetStageBrackets
        : this.resetBlockBrackets;

      method(options)
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament-block_was-reseted-brackets',
              'Brackets is successfully reseted'
            )
          );
          this.$emit('update');
        })
        .catch(this.errorNotify)
        .finally(() => {
          if (typeof this.updateRouteKey === 'function') {
            this.updateRouteKey();
          }
          if (typeof this.refetchBlocks === 'function' && stage.id) {
            this.refetchBlocks(stage.index);
          }
          this.updateModalProps({ isLoading: false });
          this.closeModal();
        });
    },
  },
};
