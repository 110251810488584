<template>
  <div class="" :class="{ 'loader-mask': isProcessing }">
    <Spinner v-if="isLoading" class="spinner-middle spinner-billing" />
    <div>
      <portal to="header-left-portal">
        <GoBackBtn
          history-mode
          hide-label
          class="h-mr-4 h-pl-0 h-pr-3"
          style="min-width: 16px"
        />
      </portal>
      <Details
        v-bind="{
          handleLoading: setLoading,
        }"
      />
      <History
        :has-external="ActiveExternal"
        :has-canceled="CanceledExternal"
        class="mt-6"
      />
    </div>
  </div>
</template>

<script>
import GoBackBtn from '@/components/common/GoBackBtn.vue';
import Details from './Details.vue';
import History from './History.vue';
import Spinner from '@/views/main/components/Spinner.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Billing',
  components: { GoBackBtn, Details, History, Spinner },
  data() {
    return {
      isLoading: false,
      isProcessing: false,
      extSub: null,
    };
  },
  computed: {
    ActiveExternal() {
      if (this.extSub === null) return false;
      return this.extSub.filter(sub => sub.status === 'active').length > 0;
    },
    CanceledExternal() {
      if (this.extSub === null) return false;
      return this.extSub.filter(sub => sub.status === 'inactive').length > 0;
    },
  },
  created() {
    this.fetchExternalSubscriptions().then(
      res => (this.extSub = res.subscriptions)
    );
  },
  beforeRouteLeave(to, from, next) {
    this.setPhTempActivationCode(null);
    sessionStorage.removeItem('activation_code');
    next();
  },
  methods: {
    ...mapActions('ph', ['setPhTempActivationCode']),
    ...mapActions(['fetchExternalSubscriptions']),
    setLoading() {
      this.isProcessing = !this.isProcessing;
      this.isLoading = !this.isLoading;
    },
  },
};
</script>

<style>
.spinner-billing {
  position: absolute;
  z-index: 999;
  top: 50vh !important;
  left: 40vw !important;
  transform: translate(-50%, -50%) !important;
}
</style>
