import api from '@/api';
import { generateMutations, generateGetters } from '@/utils/store';

const DEFAULT_TOURNAMENT_REG_STATE = () => ({
  registrationData: {},
  registrationTournament: {},
  registrationFields: {},
  registrationTeams: [],
  registrationMembers: [],
});

const getters = {
  ...generateGetters(
    'registrationData',
    'registrationTournament',
    'registrationFields',
    'registrationTeams',
    'registrationMembers'
  ),
};

const mutations = {
  ...generateMutations(
    'registrationData',
    'registrationTournament',
    'registrationFields',
    'registrationTeams',
    'registrationMembers'
  ),
};

const actions = {
  getTournament(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api.tournaments
      .getTournament(id)(accessToken)
      .then(({ data }) => data);
  },
  fetchTournament(
    {
      commit,
      getters: { accessToken },
    },
    id
  ) {
    return api.tournaments
      .getTournament(id)(accessToken)
      .then(response => {
        commit('setCurrentTournament', response.data);
        return id;
      });
  },
  getTournaments(
    {
      getters: { accessToken },
    },
    payload = {}
  ) {
    return api.tournaments
      .getTournaments(accessToken, {
        params: payload,
      })
      .then(({ data }) => data);
  },
  getPinnedTournaments({ getters: { accessToken } }) {
    return api.tournaments
      .getPinnedTournaments(accessToken, {
        params: { pinned: true },
      })
      .then(({ data: { items } }) => items);
  },
  getTournamentParticipants(
    {
      getters: { accessToken },
    },
    { id, query }
  ) {
    return api.tournaments
      .getTournamentPlayers(id)(accessToken, { params: query })
      .then(({ data }) => data);
  },
  setTournamentSquadParticipants(
    {
      getters: {
        accessToken,
        currentTournament: { id },
      },
    },
    players
  ) {
    return api.tournaments
      .putSquadParticipants(id)(accessToken, players)
      .then(({ data }) => data);
  },
  getTournamentSquadParticipants(
    {
      getters: {
        accessToken,
        currentTournament: { id },
      },
    },
    tournamentId = id
  ) {
    return api.tournaments
      .getSquadParticipants(tournamentId)(accessToken)
      .then(({ data }) => data);
  },
  //TODO why profiles use here?
  // because this code created by Spasskyi...
  getProfiles(
    {
      getters: { accessToken },
    },
    logins
  ) {
    return api.profiles
      .getProfiles(accessToken, {
        params: {
          logins,
        },
      })
      .then(({ data }) => data);
  },
  postJoinTournament(
    {
      getters: {
        accessToken,
        registrationData,
        registrationTournament: { id },
      },
    },
    regToken
  ) {
    return api.tournaments
      .joinTournament(id)(accessToken, {
        ...registrationData,
        clan_id: regToken,
      })
      .then(({ data }) => data);
  },
  revokeTournament(
    {
      getters: { accessToken },
    },
    tournamentId
  ) {
    return api.tournaments
      .putRevokeTournament(tournamentId)(accessToken)
      .then(({ data }) => data);
  },
};

export default {
  state: DEFAULT_TOURNAMENT_REG_STATE(),
  getters,
  mutations,
  actions,
};
