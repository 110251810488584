export const GENERIC = 'generic_post';
export const TOURNAMENT_FINISHED = 'tournament_finished_post';
export const TOURNAMENT_ANNOUNCED = 'tournament_announced_post';
export const POLL = 'poll_post';
export const POPULAR_CLANS = 'popular_clans';
export const CLAN_REMINDER = 'create_clan_reminder';
export const BANNER = 'banner';

export const INJECTION_POSTS = [POPULAR_CLANS, CLAN_REMINDER];

export const DEFAULT_POSTS = [GENERIC, TOURNAMENT_FINISHED, POLL];
