<template>
  <div class="form-group m-0">
    <label :for="id" class="h-text-left pull-left pb-2 m-0">
      {{ label }}
    </label>

    <div class="" :class="{ 'is-invalid': error }">
      <input
        class="new-email-input"
        :class="{ 'red-outline': error }"
        :autocomplete="autocompleteOff ? 'new-password' : 'on'"
        v-bind="{
          id,
          name,
          type,
          value,
          placeholder,
          tabindex,
          autofocus,
        }"
        @input="updateValue"
        @blur="onBlur"
        @focus="onFocus"
      />
    </div>
    <span
      v-show="(error, showError)"
      id="emailHelp"
      class="form-text text-danger error-help"
      v-text="error"
    ></span>
  </div>
</template>
<script>
import { prop } from '@/utils/factories';

export default {
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  props: {
    name: prop(String),
    id: prop(String, 'email-input'),
    label: prop(String),
    disabled: prop(Boolean),
    required: prop(Boolean),
    value: prop(String),
    type: prop(String, 'email'),
    error: prop(String),
    tabindex: prop(Number, -1),
    autofocus: prop(Boolean),
    placeholder: prop(String),
    autocompleteOff: prop(Boolean),
  },
  data() {
    return {
      showError: true,
    };
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value);
    },
    onFocus() {
      this.$emit('focus');
      this.showError = false;
    },
    onBlur() {
      this.$emit('blur');
      this.showError = true;
    },
  },
};
</script>

<style lang="scss">
.new-email-input {
  color: $new-fg-neutral;
  border-radius: 0.25rem;
  background-color: $new-bg-base;
  width: 100%;
  height: 2.25rem;
  border: 1px solid;
  text-decoration: underline;
  border-color: $new-border-neutral;
  padding: 0.5rem;
}
input::placeholder {
  color: $new-fg-neutral-faded;
  padding: 0;
}
.red-outline {
  border-color: rgb(223, 72, 95);
}
</style>
