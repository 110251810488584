<template>
  <ListItemAccount
    class="players-table-username"
    :model="$options.accountModel"
    :size="36"
  >
    <HeraIcon
      v-if="hasDropdown"
      slot="action"
      name="dropdown"
      class="players-table-username__dropdown"
      :rotate="isDropdownActive ? 180 : 0"
      @click.stop
    />
  </ListItemAccount>
</template>

<script>
import HeraIcon from '@/components/atoms/Icon';
import { CLAN, PROFILE } from '@/constants/accounts';
import { prop, propRequired } from '@/utils/factories';
import AccountModel from '@/models/Account';
import ListItemAccount from '@/components/common/ListItemAccount';

export default {
  components: { ListItemAccount, HeraIcon },
  props: {
    account: propRequired(Object),
    isClan: prop(Boolean),

    hasDropdown: prop(Boolean),
    isDropdownActive: prop(Boolean),
  },
  created() {
    this.$options.accountModel = new AccountModel(
      this.isClan ? CLAN : PROFILE,
      this.account.id || this.account.login || this.account.name
    ).update(this.account);
  },
};
</script>

<style lang="scss">
.players-table-username {
  a {
    color: $ph-primary-text;
  }

  &__dropdown {
    transition: transform 0.3s;
  }
}
</style>
