var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"horizontal-tabs-wrapper scroll-wrapper"},[(_vm.tabs.length)?_c('HorizontalScroller',{on:{"update":_vm.onScroll}},[_c('ul',{staticClass:"nav horizontal-tabs d-flex justify-content-flex-start p-0 mb-0",class:[("horizontal-tabs--" + _vm.variant), { 'not-exact': _vm.notExactMatch }]},[_vm._l((_vm.tabs),function(tab){return [_c('li',{key:("horizontal-tab-" + (tab.key || tab.id)),staticClass:"horizontal-tabs__item-wrapper",class:tab.class},[_c('InfoTooltip',{staticClass:"h-100",attrs:{"show-icon":false,"text":tab.helpText}},[_c(_vm.useCustom ? 'div' : 'router-link',{tag:"component",staticClass:"d-flex align-items-center cursor-hand justify-content-center horizontal-tabs__item h-100",class:[
                {
                  active:
                    (_vm.useCustom && _vm.activeTab === tab.key) || _vm.isSubActive(tab),
                  'not-allowed disabled': tab.disabled,
                  'flex-column':
                    _vm.$slots.beforeLabel ||
                    _vm.$slots.afterLabel ||
                    _vm.$scopedSlots.beforeLabel ||
                    _vm.$scopedSlots.afterLabel,
                  'flex-row flex-no-wrap': !(
                    _vm.$slots.beforeLabel ||
                    _vm.$slots.afterLabel ||
                    _vm.$scopedSlots.beforeLabel ||
                    _vm.$scopedSlots.afterLabel
                  ),
                } ],attrs:{"to":_vm.useCustom ? '' : tab.route,"tag":"div"},on:_vm._d({},[_vm.eventName,function ($event) { return _vm.onTabClick($event, tab); }])},[_vm._t("beforeLabel",null,null,{ tab: tab }),_c('div',[(tab.labelStated || tab.label)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(tab.labelStated || _vm.$t(tab.key, tab.label, tab.params)))]):_vm._e(),(tab.icon || (tab.disabled && !_vm.hideDisableIcon))?_c('Icon',{class:{ 'h-ml-3': tab.labelStated || tab.label },attrs:{"name":tab.disabled && !_vm.hideDisableIcon ? 'disable' : tab.icon,"width":_vm.variant === 'default' ? 16 : tab.iconWidth || 20,"fill":tab.disabled && !_vm.hideDisableIcon
                      ? _vm.$scss.colors.secondaryTextBg
                      : _vm.$scss.colors.primaryTextBg}}):_vm._e()],1),_vm._t("afterLabel",null,null,{ tab: tab })],2)],1)],1)]})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }