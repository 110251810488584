<template>
  <component :is="component" />
</template>

<script>
import LOADERS from '@/constants/loaders';

export default {
  name: 'ContentLoader',

  props: {
    variant: {
      type: String,
      required: true,
      validator: val => {
        return LOADERS.includes(val);
      },
    },
  },

  data: () => ({
    component: null,
  }),

  computed: {
    loader() {
      return this.variant ? () => import(`./variants/${this.variant}`) : null;
    },
  },

  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch(() => {
        this.component = () => import('./variants/DefaultLoader');
      });
  },
};
</script>
