import {
  ifAuthenticated,
  ifNotAuthorized,
  ifPartiallyAuthorized,
} from './guards.js';

import store from '@/store';

import SignIn from '@/views/auth/Signin.vue';
import SsoSignIn from '@/views/auth/Sso.vue';
import SignUp from '@/views/auth/Signup.vue';
import SnsSignUp from '@/views/auth/SnsSignUp.vue';
import PasswordReset from '@/views/auth/PasswordReset.vue';
import SubmitPassword from '@/views/auth/SubmitPassword.vue';
import VerifyCode from '@/views/auth/VerifyCode.vue';
import ProfileSwitcher from '@/views/auth/ProfileSwitcher';
import NotFound from '@/views/empty/NotFound';

export default [
  {
    path: '/signin/:redirectTo?',
    name: 'signin',
    component: SignIn,
    beforeEnter: ifNotAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/snsSignup',
    name: 'snsSignup',
    component: SnsSignUp,
    beforeEnter: ifNotAuthorized,
    props: true,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/sso',
    name: 'ssoSignIn',
    component: SsoSignIn,
    props: route => ({
      client: route.query.client,
      state: route.query.state,
      // appId: route.query.appId,
      // redirect: route.query.redirect,
    }),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    beforeEnter: ifNotAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/password-reset',
    name: 'passwordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/verify-code/:from',
    name: 'verifyCode',
    component: VerifyCode,
    __beforeEnter: ifAuthenticated, // TH-7176
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/new-password',
    name: 'submitPassword',
    component: SubmitPassword,
    beforeEnter: ifPartiallyAuthorized,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/signout',
    name: 'signout',
    beforeEnter(to, from, next) {
      window.dataLayer.push({ event: 'logout' });
      store
        .dispatch('logout')
        .then(() =>
          !from.path.includes('/indonesia')
            ? next({ name: 'homepage' })
            : window.location.reload()
        );
    },
  },
  {
    path: '/switch/:accountType/:accountId',
    name: 'switch',
    component: ProfileSwitcher,
  },
  {
    path: '/notfound/:id',
    name: 'notfound',
    component: NotFound,
    props: true,
  },
  {
    path: '/WrongRegion/',
    name: 'wrongregion',
    component: NotFound,
    meta: {
      messageKey: '_web_wrong_region',
      messageFallback:
        'Route leads to another region, please change  current region and try again.',
    },
  },
];
