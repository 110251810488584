import { generateGetters, generateMutations } from '@/utils/store';
import { globalEventListener } from '@/utils/globalEvent';
import { uniqueId } from '@/utils/lodashUtils';
import { REGION_STORAGE_KEY } from '@/api/config';

const LOCALE_STORAGE_KEY = 'locale';

const currentLocale = localStorage.getObjectFromItem(LOCALE_STORAGE_KEY) || {};
const currentRegion =
  localStorage.getObjectFromItem(REGION_STORAGE_KEY) || null;

const APP_ID_PREFIX = 'playhera_';

const state = {
  appId: uniqueId(APP_ID_PREFIX),
  isLoading: false,
  countriesFetched: false,
  currentLocale,
  currentRegion,
  isHeaderFixed: false,
  hasCover: false,
  isScreenLocked: false,
  isBodyScrollable: true,
  documentOriginalScrollBehavior: '',
};
const getters = {
  ...generateGetters(
    'appId',
    'isLoading',
    'countriesFetched',
    'currentLocale',
    'currentRegion',
    'isHeaderFixed',
    'isScreenLocked'
  ),
  localeDirection: state => state.currentLocale.direction || 'ltr',
  isRTL: state => state.currentLocale.direction === 'rtl',
  phMaxLink(state) {
    return state.currentRegion && state.currentRegion.feature_flags
      ? state.currentRegion.feature_flags.phmax_url
      : '';
  },
  marketplaceLink(state) {
    return state.currentRegion && state.currentRegion.feature_flags
      ? state.currentRegion.feature_flags.marketplace_url
      : '';
  },
};
const mutations = {
  ...generateMutations('isBodyScrollable', 'documentOriginalScrollBehavior'),
  updateAppId() {
    state.appId = uniqueId(APP_ID_PREFIX);
  },
  countriesFetched(state) {
    state.countriesFetched = true;
  },
  setLocale(state, currentLocale = null) {
    state.currentLocale = currentLocale || {};
    localStorage.setObjectToItem(LOCALE_STORAGE_KEY, currentLocale);
  },
  setRegion(state, region) {
    state.currentRegion = region || {};
    localStorage.setObjectToItem(REGION_STORAGE_KEY, region);
  },
  setLockScreen(state, isLock) {
    state.isScreenLocked = isLock;
  },
};
const actions = {
  toggleLockScreen(
    {
      commit,
      getters: { isScreenLocked },
    },
    isLock
  ) {
    const flag = isLock !== undefined ? isLock : !isScreenLocked;
    return commit('setLockScreen', flag);
  },

  toggleBodyScroll(
    {
      dispatch,
      state: { isBodyScrollable },
    },
    isScrollable
  ) {
    if (isScrollable !== undefined && isScrollable !== isBodyScrollable) {
      isScrollable
        ? dispatch('enableBodyScroll')
        : dispatch('disableBodyScroll');
    }
  },
  disableBodyScroll({ commit }) {
    const originalTop = window.scrollY;
    const originalLeft = window.scrollX;

    commit(
      'setDocumentOriginalScrollBehavior',
      window.getComputedStyle(document.documentElement).scrollBehavior
    );
    document.documentElement.style.scrollBehavior = 'auto';

    globalEventListener('add', 'scroll.scrollDisabler', event => {
      if (event.target === document) {
        document.documentElement.scrollTop = originalTop;
        document.documentElement.scrollLeft = originalLeft;
      }
    });

    commit('setIsBodyScrollable', false);
  },
  enableBodyScroll({ commit, state: { documentOriginalScrollBehavior } }) {
    document.documentElement.style.scrollBehavior = documentOriginalScrollBehavior;
    globalEventListener('remove', 'scroll.scrollDisabler');
    commit('setIsBodyScrollable', true);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
