import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const PRO_LEAGUES = 'proleagues';

export default {
  getList: authorizedGetRequest(`${PRO_LEAGUES}`),
  create: authorizedPostRequest(`${PRO_LEAGUES}`),
  getByID: leagueID => authorizedGetRequest(`${PRO_LEAGUES}/${leagueID}`),
  edit: leagueID => authorizedPutRequest(`${PRO_LEAGUES}/${leagueID}`),
  getParticipants: leagueID =>
    authorizedGetRequest(`${PRO_LEAGUES}/${leagueID}/players`),
  addParticipant: leagueID =>
    authorizedPostRequest(`${PRO_LEAGUES}/${leagueID}/players`),
  removeParticipant: (leagueID, participantID) =>
    authorizedDeleteRequest(
      `${PRO_LEAGUES}/${leagueID}/players/${participantID}`
    ),
  // getSeasons: (leagueID) =>
  //   authorizedGetRequest(`${PRO_LEAGUES}/${leagueID}/seasons`),
  addSeason: leagueID =>
    authorizedPostRequest(`${PRO_LEAGUES}/${leagueID}/seasons`),
  startSeason: leagueID =>
    authorizedPutRequest(`${PRO_LEAGUES}/${leagueID}/seasons/start`),
  stopSeason: leagueID =>
    authorizedPutRequest(`${PRO_LEAGUES}/${leagueID}/seasons/stop`),
};
