<template functional>
  <div
    class="ph-panel"
    :class="[
      ...(typeof props.variant === 'string'
        ? [props.variant]
        : props.variant
      ).map(el => `ph-panel--${el}`),
      data.staticClass,
      data.class,
    ]"
    v-on="{
      ...data.on,
      ...data.nativeOn,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
const PANEL_VARIANTS = [
  'no-padding',
  'half-paddings',
  'transparent',
  'dark-bg',
  'default',
  'input-bg',
  'white',
  'popover-bg',
  'secondary',
  'accent',
  'error',
  'green',
  'stroked',
  'stroked-dark',
  'stroked-input-bg',
  'stroked-popover-bg',
  'stroked-white',
  'stroked-secondary',
  'stroked-accent',
  'stroked-error',
  'stroked-green',
  'stroked-yellow',
  'stroked-dashed',
  'stroked-wide',
  'stroked-gray',
  'stroked-input-borders',
  'rounded',
  'rounded-small',
  'rounded-pre-medium',
  'rounded-medium',
  'rounded-large',
  'rounded-full',
  'shadowed',
  'secondary-card',
  'active-card',
];

export default {
  name: 'Panel',
  props: {
    variant: {
      type: [Array, String],
      default: () => ['default', 'rounded', 'half-paddings', 'no-padding'],
      validator(val) {
        return typeof val === 'string'
          ? PANEL_VARIANTS.includes(val)
          : val.every(el => PANEL_VARIANTS.includes(el));
      },
    },
  },
};
</script>

<style lang="scss">
.ph-panel {
  border-width: 0;
  border-radius: 0;
  padding: $ph-large-space;
  &--half-paddings {
    padding: math.div($ph-large-space, 2);
  }
  &--no-padding {
    padding: 0;
  }
  &--transparent {
    background-color: transparent !important;
  }
  &--dark-bg {
    background-color: $ph-bg !important;
  }
  &--default {
    background-color: #11171f !important;
    border: none;
  }
  &--input-bg {
    background-color: #1c212b !important;
  }
  &--white {
    background-color: $ph-primary-text !important;
  }
  &--secondary {
    background-color: $ph-secondary-text !important;
  }
  &--accent {
    background-color: $ph-accent !important;
  }
  &--error {
    background-color: $ph-error !important;
  }
  &--green {
    background-color: $ph-green !important;
  }
  &--popover-bg {
    background-color: $ph-popover-bg !important;
  }
  &--stroked {
    border-width: 1px !important;
    border-style: solid;
  }
  &--stroked {
    &-dashed {
      border-style: dashed;
    }
    &-wide {
      border-width: 2px !important;
    }
    &-dark {
      border-color: $ph-bg !important;
    }
    &-input-bg {
      border-color: $ph-input-bg !important;
    }
    &-white {
      border-color: $ph-primary-text !important;
    }
    &-secondary {
      border-color: $ph-secondary-text !important;
    }
    &-accent {
      border-color: $ph-accent !important;
    }
    &-error {
      border-color: $ph-error !important;
    }
    &-green {
      border-color: $ph-green !important;
    }
    &-yellow {
      border-color: $ph-yellow !important;
    }
    &-popover-bg {
      border-color: $ph-popover-bg !important;
    }
    &-input-borders,
    &-gray {
      border-color: $ph-input-borders !important;
    }
  }
  &--rounded {
    border-radius: $ph-large-radius !important;
    &-new {
      border-radius: $hr-radius-01;
    }
    &-small {
      border-radius: $ph-small-radius !important;
    }
    &-pre-medium {
      border-radius: $ph-pre-medium-radius !important;
    }
    &-medium {
      border-radius: $ph-medium-radius !important;
    }
    &-large {
      border-radius: $ph-large-radius !important;
    }
    &-full {
      border-radius: $ph-round-radius !important;
    }
    &-top-left-0 {
      border-top-left-radius: 0 !important;
    }
    &-top-right-0 {
      border-top-right-radius: 0 !important;
    }
    &-bottom-left-0 {
      border-bottom-left-radius: 0 !important;
    }
    &-bottom-right-0 {
      border-bottom-right-radius: 0 !important;
    }
  }
  &--shadowed {
    box-shadow: $ph-box-shadow;
  }
  &--with-loader {
    pointer-events: none;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $ph-bg;
      opacity: 0.7;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1rem;
      width: 1rem;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
      border: 2px solid;
      border-color: transparent transparent $ph-light-gray $ph-light-gray;
      border-radius: 50%;
      z-index: 2;
      @include rotate-element;
    }
    &.loader-only {
      * {
        opacity: 0;
      }
      &::before {
        opacity: 0;
      }
    }
  }
}
</style>

<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/"
  name="Panel"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    text: {
      default: text('Inner text', 'I am a text in the panel slot ;)')
    },
    background: {
      default: select('Variant|background-type', ['transparent',
        'dark-bg',
        'default',
        'input-bg',
        'white',
        'secondary',
        'accent',
        'error',
        'green',
        ], 'default')
    },
    strokes: {
      default: select('Variant|strokes-type', ['',
        'stroked',
        'stroked-dark',
        'stroked-input-bg',
        'stroked-white',
        'stroked-secondary',
        'stroked-accent',
        'stroked-error',
        'stroked-green',
        ], '')
    },
    rounded: {
      default: select('Variant|border-radius-type', [
        'rounded',
        'rounded-small',
        'rounded-large',
        'rounded-full'], 'rounded')
    },
    shadowed: {
      default: boolean('Variant|shadows-type', false)
    }
  }"><Panel v-bind="{variant: [background, strokes ? 'stroked' : '', strokes, rounded, shadowed ? 'shadowed' : '' ]}">
  <div>
    {{text}} <br>
    ============================== <br>
    BTW: my single variant prop now is an array of such strings:
    [{{`${background},`}}
    {{strokes ? `stroked, ${strokes}` : ''}}
    {{`${rounded},`}}
    {{shadowed ? 'shadowed' : ''}}]
  </div>
</Panel>
</story>
