<template>
  <div>
    <HeraDummyList
      v-if="
        !posts.length &&
          !teams.length &&
          !tournaments.length &&
          !users.length &&
          !firstLoad
      "
      icon-name="dummy/search-no-matches"
      size="128"
    >
      <Title
        class=""
        :text="$t('_web_players_dummy-list', 'we couldn\'t find anything.')"
      />
    </HeraDummyList>

    <div v-if="tournaments && tournaments.length">
      <div class="search-section-header">
        <Subheader
          :text="$t('web_profile_competitions-tab', 'Competitions')"
          class="m-0"
        />
        <a href="#" class="" @click.prevent="$emit('tab', 'tournaments')">
          <img
            src="@/assets/images/search/arrowRight.svg"
            alt="Right arrow"
            class="arrow-img"
          />
        </a>
      </div>
      <div class="search-result-container-tournaments">
        <TournamentCard
          v-for="tournament in tournaments"
          :key="tournament.id"
          v-bind="{ tournament, forOrganizer: false }"
          class=""
        />
      </div>
    </div>

    <div v-if="users && users.length">
      <div class="search-section-header">
        <Subheader :text="$t('web_player_title', 'Players')" class="m-0" />
        <a href="#" class="" @click.prevent="$emit('tab', 'players')">
          <img
            src="@/assets/images/search/arrowRight.svg"
            alt="Right arrow"
            class="arrow-img"
          />
        </a>
      </div>
      <div class="search-result-container-players">
        <SearchResultItem
          v-for="user in users"
          :key="user.id"
          :data="user"
          class=""
        />
      </div>
    </div>
    <div v-if="teams && teams.length">
      <div class="search-section-header">
        <Subheader :text="$t('_web_clans_сlans', 'Teams')" class="m-0" />
        <a href="#" class="" @click.prevent="$emit('tab', 'teams')">
          <img
            src="@/assets/images/search/arrowRight.svg"
            alt="Right arrow"
            class="arrow-img"
          />
        </a>
      </div>
      <div class="search-result-container-teams">
        <TeamSearch
          v-for="item in teams"
          :key="item.id"
          :data="item"
          class=""
        />
      </div>
    </div>

    <TeamSearch />
  </div>
</template>

<script>
import Subheader from '@/components/atoms/Subheader';
import Title from '@/components/atoms/Title';
import HeraDummyList from '@/components/common/HeraDummyList';
import TournamentCard from '@/components/common/TournamentCard';
import SearchResultItem from '@/views/search/SearchResultItem';
import { emptyArray } from '@/utils/factories';
import TeamSearch from '@/views/search/TeamSearch';

export default {
  name: 'PopularSearch',
  components: {
    TeamSearch,
    Title,
    Subheader,
    HeraDummyList,
    SearchResultItem,
    TournamentCard,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    tournaments: {
      type: Array,
      default: emptyArray,
    },
    teams: {
      type: Array,
      default: emptyArray,
    },
    users: {
      type: Array,
      default: emptyArray,
    },
    posts: {
      type: Array,
      default: emptyArray,
    },
  },
  data() {
    return {
      firstLoad: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.firstLoad = false;
    }, 700);
  },
};
</script>

<style scoped lang="scss">
.search-result-container-tournaments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  @media (max-width: 860px) {
    justify-content: center;
  }
}
.search-result-container-teams {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
  gap: 1rem;
  @media (max-width: 860px) {
    justify-content: center;
  }
}
.search-result-container-players {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(112px, 1fr));
  gap: 1rem;
  @media (max-width: 860px) {
    justify-content: center;
  }
}
.search-section-header {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  gap: 0.5rem;
}
.arrow-img {
  .app-rtl & {
    transform: scaleX(-1);
  }
}
</style>
