import api from '@/api';

const actions = {
  getMatchesByTournament(
    {
      getters: { accessToken },
    },
    { accountId, eventId, page, battleStatuses }
  ) {
    return api.competitions
      .getMatchesByTournament(accessToken, {
        params: {
          player: accountId,
          event_id: eventId,
          page,
          battle_status: battleStatuses,
        },
      })
      .then(response => response.data);
  },
};

export default {
  actions,
};
