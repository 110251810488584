var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3 fill-border custom-scrollbar slider-chat-list",on:{"scroll":function($event){$event.stopPropagation();}}},[(!_vm.chats || !_vm.chats.length)?_c('ListLoader',{staticClass:"h-100",attrs:{"is-loading":_vm.isLoading && !_vm.listToRender.length,"is-empty-list":!_vm.listToRender.length,"dummy-props":{
      iconName: 'dummy/chat',
      size: 200,
      subheaderText: _vm.$t(
        '_web_sidechat_is-empty',
        'You have no chats at the moment'
      ),
      text: _vm.$t(
        '_web_sidechat_is-empty_subheader',
        'Press the button to create a new channel or group chat'
      ),
    }}}):_c('InfiniteScroll',{attrs:{"list":_vm.chats,"list-name":"chats","is-disabled":_vm.isLoading || !_vm.nextPageToken,"in-container":true},on:{"ScrollToBottom":_vm.fetchChats},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _vm._l((_vm.chatsWithFilter.items),function(item,i){return _c('Item',{key:'item-' + 'index' + '-' + i,attrs:{"data":item,"focus":item.id,"date":item.date},on:{"click":function($event){return _vm.$emit('openChat', item)}}})})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }