<template>
  <div class="title-new">
    <AppIcon v-if="needInvite" name="lock-closed" class="h-mr-2 mb-2" />
    <TextBlock tag="h2" class="arenaTitle" :size="1" :text="tournament.title" />
  </div>
</template>

<script>
import { propRequired } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
import AppIcon from '@/components/atoms/Icon';

const TOURNAMENT_INVITE_ONLY_REGISTRATION = 'closed';

export default {
  name: 'TournamentCardTitle',
  components: { TextBlock, AppIcon },
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    text({ tournament: { title } }) {
      return title;
    },
    needInvite() {
      return (
        this.tournament.registrationType ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION ||
        this.tournament.registration_type ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION
      );
    },
  },
};
</script>
<style lang="scss">
.arenaTitle {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 110% !important;
  display: flex;
  align-items: center;
  margin-top: 4px;
  justify-content: flex-start !important;
  @media (max-width: 767px) {
    // justify-content: center !important;
    h2 {
      // text-align: center;
    }
  }
}

.title-new {
  margin-bottom: 24px;
}
</style>
