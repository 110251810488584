<template>
  <component
    :is="tag"
    class="d-flex  not-row-reverse input-verify "
    @focusin="focusHandler"
    @submit.prevent="formSubmit"
  >
    <div v-for="(num, index) in count" :key="`digit_${num}`">
      <input
        v-model.number="digits[index]"
        v-validate="'required|numeric|max:1'"
        class="form-control new-form-control text-center input-number-box"
        :class="{
          'is-invalid': errors.first(`digit-${num}`),
          'border-primary': digits[index],
        }"
        :name="`digit-${num}`"
        type="tel"
        maxlength="1"
        autocomplete="off"
        :tabindex="`${num}`"
        placeholder="-"
        :disabled="disabled"
        @input="event => inputNumber(num, event)"
        @keydown="event => checkInput(num, event)"
        @keydown.left="() => focusToInput(num - 1)"
        @keydown.right="() => focusToInput(num + 1)"
        @keydown.enter="formSubmit"
        @paste="pasteCode"
      />
    </div>
  </component>
</template>

<script>
import Vue from 'vue';
import { prop } from '@/utils/factories';

export default {
  name: 'InputVerify',
  inject: ['$validator'],

  props: {
    value: prop(String),
    tag: prop(String, 'div'),
    count: prop(Number, 4),
    disabled: prop(Boolean),
    autoSend: prop(Boolean),
    autofocus: prop(Boolean, true),
  },
  data() {
    return {
      digits: [],
    };
  },
  computed: {
    code() {
      const code = this.digits.join('');
      return code.length === this.count ? code : null;
    },
    errorText() {
      let err;
      for (let i = 1; i <= this.count; i++) {
        err = this.errors.first(`digit-${i}`);
        if (err) {
          return err;
        }
      }

      return null;
    },
  },
  watch: {
    value(str) {
      if (str !== this.code) {
        this.digits = str && this.$lodash.isString(str) ? [...str] : [];
      }
    },
    errorText(err) {
      this.$emit('error', err);
    },
  },
  mounted() {
    if (this.autofocus) {
      this.focusToInput(1);
    }
  },
  methods: {
    formSubmit() {
      this.$emit('onSubmit', this.code);
    },
    focusToInput(toIndex) {
      const nextFocusElement = this.$el.querySelector(
        `[tabindex="${toIndex}"]`
      );

      if (!nextFocusElement) {
        if (this.autoSend) {
          this.formSubmit();
        }
        return;
      }

      nextFocusElement.focus();
    },
    inputNumber(currentTabIndex, { data }) {
      this.errorRequest = null;
      if (data && !Number.isNaN(+data)) {
        this.focusToInput(currentTabIndex + 1);
      }
      this.triggerInput();
    },
    checkInput(currentTabIndex, event) {
      const { key, ctrlKey, metaKey } = event;
      const isCtrlV = (ctrlKey || metaKey) && key === 'v';
      if (key.length === 1 && Number.isNaN(+key) && !isCtrlV) {
        event.preventDefault();
      } else if (key === 'Backspace') {
        if (!event.target.value) {
          this.focusToInput(currentTabIndex - 1);
        }
      }
    },
    focusHandler(e) {
      e.target.select();
    },
    triggerInput() {
      if (this.code) {
        this.$emit('input', this.code);
      }
    },
    pasteCode(ev) {
      ev.preventDefault();

      let paste = ev.clipboardData.getData('text') || '';
      paste = paste.replace(/\D/g, '');
      if (paste.length !== this.count) {
        return;
      }
      for (let i = 0; i < this.count; i++) {
        Vue.set(this.digits, i, parseInt(paste[i]));
      }
      this.focusToInput(this.digits.length);
      this.$validator.reset();
      this.triggerInput();
      this.$nextTick(() => {
        if (this.autoSend) {
          this.formSubmit();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.input-verify {
  justify-content: space-between;
  margin: auto;
  max-width: 300px;
  min-height: 170px;
  align-items: flex-end;
  gap: 10px;
  overflow: visible;
  ::placeholder {
    font-weight: 100;
  }

  input {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    width: 60px !important;
    min-height: 105px;
    caret-color: transparent;
    &.is-invalid {
      border-color: $ph-error;
      padding-right: 0 !important;
      background-position: center center;
      &::placeholder {
        color: transparent;
      }
    }
  }
  .new-form-control:focus {
    color: #495057 !important;
  }
}
</style>
