var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.hasInfo)?[_c('Widget',{staticClass:"mb-5",attrs:{"title":_vm.title,"actions-menu":_vm.actionsMenu,"hide-search":"","status-btn-mode":_vm.statusBtnMode,"status-btn-position":"bottom"}}),(_vm.goBackVisible || _vm.isPublishRoute)?_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-5"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(_vm.goBackVisible || _vm.isPublishRoute)?_c('HeraFormBtn',{staticClass:"h-ml-3 btn-accent-border",attrs:{"label":_vm.$t('_web_tournament_finish-btn', 'Go back')},on:{"click":_vm.goBack}}):_vm._e(),(_vm.isPublishRoute)?_c('Btn',{staticClass:"btn-accent-simple h-ml-3",attrs:{"disabled":_vm.notQualifiedYet || !_vm.firstDraftStage || !_vm.previousStageFinished,"label":_vm.$t(
              '_web_tournament-brackets-draw-nearest-stage-button',
              'Draw {stageName}',
              {
                stageName: (_vm.firstDraftStage && _vm.firstDraftStage.title) || '',
              }
            )},on:{"click":function($event){return _vm.confirmDraw(_vm.firstDraftStage)}}}):_vm._e()],1)]):_vm._e(),(_vm.isConstructRoute)?_c('Panel',{staticClass:"mb-5"},[_c('Subheader',{staticClass:"tournament-info-wrapper__title mb-5",attrs:{"text":_vm.$$t('Tournament structure')}}),_c('HorizontalTabs',_vm._b({staticClass:"tournament-structure-picker",attrs:{"variant":"panels"},scopedSlots:_vm._u([{key:"beforeLabel",fn:function(ref){
            var tab = ref.tab;
return [(tab.route.name === 'editSingleStage')?_c('Icon',{staticClass:"mb-4",attrs:{"name":"single-elimination","width":"48"}}):_c('div',{staticClass:"row align-items-center no-gutters mb-4 text-center"},[_c('div',{staticClass:"col"},[_c('Icon',{staticClass:"mb-2",attrs:{"name":"single-elimination","width":"20"}}),_c('Icon',{attrs:{"name":"single-elimination","width":"20"}})],1),_c('div',{staticClass:"col-auto mx-1"}),_c('div',{staticClass:"col"},[_c('Icon',{attrs:{"name":"single-elimination","width":"20"}})],1)])]}},{key:"afterLabel",fn:function(ref){
            var tab = ref.tab;
return [(tab.route.name === 'editSingleStage')?_c('TextBlock',{staticClass:"font-weight-normal mt-4 h-pl-4",attrs:{"variant":"secondary","tag":"ul"}},[_c('li',[_vm._v(_vm._s(_vm.$$t('single tournament format')))]),_c('li',[_vm._v(_vm._s(_vm.$$t('single group')))])]):_c('TextBlock',{staticClass:"font-weight-normal mt-4 h-pl-4",attrs:{"variant":"secondary","tag":"ul"}},[_c('li',[_vm._v(_vm._s(_vm.$$t('multiple tournament format')))]),_c('li',[_vm._v(_vm._s(_vm.$$t('multi group')))])])]}}],null,false,1854740373)},'HorizontalTabs',{ tabs: _vm.tabs },false))],1):_vm._e()]:_vm._e(),_c('Panel',{staticClass:"h-100 p-0",attrs:{"variant":['transparent']}},[[_c('router-view',{key:_vm.routeKey,class:{
          '': _vm.isConstructRoute,
        }})]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }