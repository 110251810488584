<template>
  <div class="d-flex flex-column align-items-center my-3 clan-reminder">
    <!-- <TextBlock
      :size="1.125"
      :text="
        $t(
          '_web_newsfeed_team-reminder-you-dont-have-team',
          'You don`t have a team'
        )
      "
      class="font-weight-bold clan-reminder_title"
    /> -->
    <span class="bannerText">{{
      $t('_web_create_team-text', 'Don&#96;t have a team?')
    }}</span>

    <h1>
      {{
        $t(
          '_web_create_team-headline',
          'Create a team to play team tournaments'
        )
      }}
    </h1>
    <div class="row justify-content-center w-100 mb-3">
      <div class="col-md-4 col-lg-3 btns-wrapper">
        <router-link :to="{ name: 'createClanDetails' }">
          <button class="bannerBtn">
            <img src="@/assets/images/auth/createTeam.svg" alt="create-team" />
            {{ $t('_web_create_team_page', 'CREATE A TEAM') }}
          </button>
        </router-link>
      </div>
      <div class="col-md-4 col-lg-3 btns-wrapper">
        <router-link :to="{ name: 'clans' }">
          <button class="bannerBtn">
            {{ $t('_web_join_team_page', 'JOIN TEAM') }}
          </button>
        </router-link>
      </div>
    </div>
    <div class="braking-line-team"></div>
  </div>
</template>

<script>
// import TextBlock from '@/components/atoms/TextBlock';

export default {
  // components: { TextBlock },
};
</script>

<style lang="scss">
.clan-reminder {
  display: flex;
  gap: 20px;
  padding: 1.25rem;
  background-color: transparent;
  border-radius: 0.25rem;

  &_title {
    margin-bottom: 1.25rem;
  }
}
.btns-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  max-width: 220px;
}
.btn-team {
  width: 150px !important;
}
.braking-line-team {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-bottom: 1px solid #30374a;
  height: 1px;
}
.bannerBtn {
  background-color: $new-bg-page;
  color: $hr-bg-modal-btn;
  border: 1px solid #30374a;
  border-radius: 5px;
  width: 176px;
  height: 44px;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bannerBtn:hover {
  background-color: #30374a;
}
.bannerText {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  color: $hr-bg-modal-btn;
}
@media screen and (max-width: 800px) {
  h1 {
    text-align: center;
    font-size: 24px;
  }
}
</style>
