import { authorizedPostRequest, authorizedGetRequest } from '@/api/requests';

const PROFILE_BASE = 'profiles';
const FOLLOW_BASE = PROFILE_BASE + '/my/follow';

export const followUser = authorizedPostRequest(`${FOLLOW_BASE}/`);

// export const friendRequest = login =>
//   authorizedPostRequest(`${PROFILE_BASE}/${login}/friendship_requests`);
//
// export const friendAcceptRequest = login =>
//   authorizedPostRequest(`${PROFILE_BASE}/my/friendship_requests/${login}`);
//
// export const cancelFriendRequest = login =>
//   authorizedDeleteRequest(`${PROFILE_BASE}/my/friendship_requests/${login}`);
//
// export const removeFromFriends = login =>
//   authorizedDeleteRequest(`${PROFILE_BASE}/my/friends/${login}`);

export const fetchFriends = login =>
  authorizedGetRequest(`${PROFILE_BASE}/${login}/friends`);

export const fetchFriendRequests = () =>
  authorizedGetRequest(`${PROFILE_BASE}/my/friendship_requests`);

export const fetchFollowers = login =>
  authorizedGetRequest(`${PROFILE_BASE}/${login}/followed_by`);

export const fetchFollowings = login =>
  authorizedGetRequest(`${PROFILE_BASE}/${login}/follow`);

export default {
  followUser,
  // friendRequest,
  // friendAcceptRequest,
  // cancelFriendRequest,
  // removeFromFriends,
  fetchFriends,
  fetchFriendRequests,
  fetchFollowers,
  fetchFollowings,
};
