<template>
  <div class="flex-grow-1 d-flex flex-column">
    <div
      v-if="showAdminFunctions"
      id="breadcrumbsWrapper"
      ref="breadcrumbsWrapper"
      class="mx-0 breadcrumbs-wrapper w-100 h-pr-0 sticky-top"
    >
      <Widget
        :title="pageTitle"
        :actions-menu="actionMenuItems"
        :help-text="!isPublishRoute ? infoText : ''"
        class="mb-5"
        :status-btn-mode="statusBtnMode"
        :status-btn-disabled="hasDummy"
        status-btn-position="bottom"
        hide-search
      >
        <template v-if="stageType" #subtitle>
          <div class="d-flex align-items-center">
            <Icon
              :name="$lodash.kebabCase(stageType)"
              width="24"
              class="h-mr-3"
              :fill="$scss.colors.secondaryTextBg"
            />
            <TextBlock variant="muted" :text="stageTypeName" />
          </div>
        </template>
      </Widget>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-5">
      <GoBackBtn
        v-if="!showAdminFunctions && currentTournamentStages.length <= 1"
        :direct-path="`/tournament/${currentTournament.id}`"
        class="pl-0 pr-0 mr-5"
        style="min-width: 16px"
      />
      <GoBackBtn
        v-else-if="!showAdminFunctions && currentTournamentStages.length > 1"
        history-mode
        class="pl-0 pr-0 mr-5"
        style="min-width: 16px"
      />
      <HorizontalTabs
        v-if="showAdminFunctions"
        id="brackets-view-toggle"
        class="position-relative h-mr-6"
        v-bind="{
          tabs: routerSubPages,
          useCustomRoute: true,
          hideDisableIcon: true,
          variant: 'buttons',
        }"
      />
      <Btn
        v-if="isManageRoute"
        :disabled="isLoading || !isCompetitionScheduled"
        class="btn-accent-simple h-ml-3"
        :label="hasDummy ? $$t('Build Brackets') : $$t('Re-build Brackets')"
        @click="confirmReDraw(currentTournamentStage)"
      />
      <div
        v-if="showAdminFunctions && infoText && isPublishRoute"
        class="h-ml-auto"
      >
        <Btn
          v-if="goBackVisible"
          :label="$t('_web_tournament_finish-btn', 'Go back')"
          class="h-ml-3"
          @click="goBack"
        />
        <!-- <Btn
          v-if="isPublishRoute"
          :disabled="
            isLoading || !canShuffle || !isCompetitionScheduled || hasDummy
          "
          class="btn-accent-simple h-ml-3"
          :label="$$t('Shuffle participants')"
          @click="confirmShuffleParticipants"
        /> -->
        <Btn
          v-if="isPublishRoute"
          :disabled="isLoading || !isCompetitionScheduled"
          class="btn-accent-simple h-ml-3"
          :label="hasDummy ? $$t('Build Brackets') : $$t('Re-build Brackets')"
          @click="confirmReDraw(currentTournamentStage)"
        />
        <!--        :label="hasDummy ? $$t('Draw Brackets') : $$t('Re-draw Brackets')"-->
      </div>
    </div>
    <!-- <InfoPanel
      v-if=""
      class="mb-5 align-items-center"
    >
      {{ infoText }}
      <template #addons>

      </template>
    </InfoPanel> -->
    <div v-if="!isManageRoute">
      <div
        v-if="
          stageType && currentCompetitionID && isBattleRoyale
            ? isBattleRoyale
            : isSwiss
            ? isSwiss
            : isRoundRobin
            ? isRoundRobin
            : false
        "
        class="flex-grow-1 d-flex flex-column"
      >
        <div class="d-flex justify-content-center mb-3">
          <Btn
            :disabled="showBracketsView"
            class="btn-accent-simple h-ml-3"
            @click="switchToBrackets"
          >
            Brackets
          </Btn>
          <Btn
            :disabled="!showBracketsView"
            class="btn-accent-simple h-ml-3"
            @click="switchToStanding"
          >
            Standing
          </Btn>
        </div>
      </div>
    </div>

    <Panel
      class="flex-grow-1"
      :variant="wrapperVariant"
      :class="[
        isBattleRoyale ? 'pt-4' : 'pt-0',
        { 'p-0': isTableViewRoute, 'text-center': isEditResultsRoute },
      ]"
    >
      <div v-if="showBracketsView">
        <router-view
          v-if="stageType && currentCompetitionID"
          :key="routeKey"
          @noDataToShow="hasDummy = true"
          @notEmpty="hasDummy = false"
        ></router-view>
      </div>
      <div
        v-else-if="!showBracketsView && stageType && currentCompetitionID"
        class="row standings-tables"
      >
        <div v-for="block in blocks" :key="block.id" class="mt-4 col-12">
          <GroupTable
            :id="block.id"
            :title="block.title"
            :type="currentStageType.type"
            :tie-breaker="
              $lodash.get(
                currentStage,
                'block_configuration.tie_breaker_preset'
              )
            "
            class="h-100 flex-grow-1 standings-table"
          />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
// import ActionsMenuGrid from './components/MenuGrid';
// import ActionsMenuTable from './components/MenuTable';
import TextBlock from '@/components/atoms/TextBlock';
import Panel from '@/components/atoms/Panel';
// import InfoPanel from '@/components/molecules/InfoPanel';
import Btn from '@/components/atoms/HeraFormBtn';
import Widget from '@/views/tournaments/management/summaryWidgets/Widget';
import GoBackBtn from '@/components/common/GoBackBtn';
import HorizontalTabs from '@/components/common/HorizontalTabs';
import Icon from '@/components/atoms/Icon';
import GroupTable from '../standings/GroupTable.vue';
// import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';
import {
  BLOCK_TYPES,
  STAGE_TYPES,
  COMPETITION_FINISHED_STATUSES,
  COMPETITION_SCHEDULED_STATUSES,
  TOURNAMENT_STATUSES,
  CONSTRUCTOR_MODES,
} from '@/constants/tournaments';
import routeHelpers from '@/mixins/routeHelpers';
import {
  tournamentHelpers,
  tournamentModals,
} from '@/mixins/tournaments/tournamentHelpers';
import { translate } from '@/mixins/translate';
import drawGrid from '@/views/tournaments/management/brackets/mixins/drawGrid';
import resetGrid from '@/views/tournaments/management/brackets/mixins/resetGrid';
export default {
  name: 'Brackets',
  components: {
    Panel,
    // InfoPanel,
    // ToolIcon,
    Btn,
    Widget,
    HorizontalTabs,
    Icon,
    TextBlock,
    GoBackBtn,
    GroupTable,
  },
  mixins: [
    routeHelpers,
    tournamentHelpers,
    tournamentModals,
    resetGrid,
    drawGrid,
    translate('_web_tournament-brackets'),
  ],
  data() {
    return {
      isLoading: false,
      routeKey: 'grid',
      hasDummy: false,
      shuffleLoading: false,
      showBracketsView: true,
    };
  },
  computed: {
    ...mapState('my', { myAccountID: 'accountId' }),

    ...mapGetters([
      'isScheduled',
      'currentTournament',
      'currentCompetitionID',
      'currentTournamentStage',
      'currentTournamentStages',
      'currentTournamentBlock',
      'getBlocksByStageID',
      'isSingleStage',
      'isSingleBlock',
      'canPause',
      'canReopen',
      'isTournamentPastStatus',
    ]),
    statusBtnMode() {
      return !this.isSingleStage || !this.isSingleBlock
        ? ''
        : this.isPublishRoute
        ? 'publish'
        : this.isManageRoute
        ? 'start'
        : '';
    },
    competitionStatus() {
      return this.$lodash.get(this.currentTournamentBlock, 'status');
    },
    currentStage() {
      return this.currentTournamentStages.find(
        stage => stage.id === this.stageID
      );
    },
    currentStageType() {
      const type = this.$lodash.get(
        this.currentStage,
        'block_configuration.type',
        BLOCK_TYPES.SINGLE
      );
      return STAGE_TYPES.find(s => s.type === type);
    },
    canShuffle() {
      return !this.isBattleRoyale && !this.isRoundRobin;
    },
    isCompetitionFinished() {
      return COMPETITION_FINISHED_STATUSES.includes(this.competitionStatus);
    },
    isCompetitionScheduled() {
      return COMPETITION_SCHEDULED_STATUSES.includes(this.competitionStatus);
    },
    isConstructRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.CONSTRUCT;
    },
    isPublishRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.PUBLISH;
    },
    isManageRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.MANAGE;
    },
    isTableViewRoute() {
      return this.$route.name === 'BlockBracketsTable';
    },
    isEditResultsRoute() {
      return this.$route.name === 'BlockBracketsTableEditResults';
    },
    infoText() {
      return this.isPublishRoute
        ? this.$t(
            '_web_tournaments_publish-brackets-grid-page-info-text',
            `Finalize drawing: shuffle, manualy swap participants or re-draw bracket (add all qualifiers).
            After publish bracket, either will publish it automatically once tournament start time will come.`
          )
        : this.$t(
            '_web_tournaments_manage-brackets-page-info-text',
            'Matches will start according to a schedule or can be started manually'
          );
    },
    pageTitle() {
      return this.isPublishRoute
        ? this.$t(
            '_web_tournament_publish-brackets-title-test',
            'Edit Brackets for {stage}/{block}',
            this.currentTournamentStage && this.currentTournamentBlock
              ? {
                  stage: this.currentTournamentStage.title,
                  block: this.currentTournamentBlock.title,
                }
              : {}
          )
        : this.$t(
            '_web_tournament_manage-brackets-title-test',
            'Manage Matches for {stage}/{block}',
            this.currentTournamentStage && this.currentTournamentBlock
              ? {
                  stage: this.currentTournamentStage.title,
                  block: this.currentTournamentBlock.title,
                }
              : {}
          );
    },
    stageType() {
      return this.currentTournamentStage &&
        this.currentTournamentStage.block_configuration
        ? this.currentTournamentStage.block_configuration.type
        : null;
    },
    stageTypeName() {
      return this.stageType
        ? this.$t(
            '_web_tournament-management_stage-type-' + this.stageType,
            STAGE_TYPES.find(item => item.type === this.stageType).name
          )
        : '';
    },
    showAdminFunctions() {
      return this.getMetaFromParents('showAdminFunctions');
    },
    grid() {
      return this.$router.currentRoute.params.blockType === 'double-elimination'
        ? 'upper'
        : null;
    },
    meta() {
      return this.$route.meta;
    },
    isBattleRoyale() {
      // we can use currentTournamentBlock.type.
      // But it is danger, because before fetch currentTournamentBlock === undefined
      const { blockType = null } = this.$route.params;
      return this.$lodash.snakeCase(blockType) === BLOCK_TYPES.BATTLE_ROYALE;
    },
    isRoundRobin() {
      const { blockType = null } = this.$route.params;
      return this.$lodash.snakeCase(blockType) === BLOCK_TYPES.RR;
    },
    isSwiss() {
      const { blockType = null } = this.$route.params;
      return this.$lodash.snakeCase(blockType) === BLOCK_TYPES.SWISS;
    },
    playersInfo() {
      return this.$lodash.get(this, 'currentTournament.players_info') || {};
    },
    playersConfig() {
      return this.$lodash.get(this, 'currentTournament.players_config') || {};
    },
    actionMenuItems() {
      return [
        ...(!this.isTableViewRoute && this.isPublishRoute
          ? [
              {
                key: this.hasDummy ? 'draw-grid' : 'redraw-grid',
                title: this.hasDummy ? 'Draw Brackets' : 'Re-draw Brackets',
                subtitle: this.hasDummy
                  ? this.$$t('Draw Brackets')
                  : this.$$t('Re-draw Brackets'),
                icon: 'draw',
                disabled:
                  !this.isPublishRoute ||
                  this.isLoading ||
                  !this.isCompetitionScheduled,
                handler: () => this.confirmReDraw(this.currentTournamentStage),
              },
              {
                key: 'shuffle-participants',
                title: 'Shuffle participants',
                subtitle: this.$$t('Shuffle participants'),
                icon: 'shuffle',
                disabled:
                  !this.isPublishRoute ||
                  this.isLoading ||
                  !this.canShuffle ||
                  !this.isCompetitionScheduled ||
                  this.hasDummy,
                handler: this.confirmShuffleParticipants,
              },
            ]
          : []),
        // {
        //   key: 'start-check-in',
        //   title: 'Start Check-in',
        //   subtitle: this.$t(
        //     '_web_tournament-stage-check-in-tooltip',
        //     'Press to manually start check-in in current stage'
        //   ),
        //   handler: this.confirmStartCheckIn,
        //   icon: 'check-mark',
        //   disabled:
        //     this.isLoading ||
        //     this.currentTournament.status !==
        //       TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED ||
        //     (this.currentTournamentStage &&
        //       !this.currentTournamentStage.check_in_enabled) ||
        //     (this.currentTournamentStage &&
        //       (this.currentTournamentStage.check_in_enabled &&
        //         this.currentTournamentStage.check_in_start_date <=
        //           this.getNowSeconds())),
        // },
        {
          key: 'reset-brackets',
          title: 'Reset brackets',
          subtitle: this.$t(
            '_web_tournament-block_reset-brackets-tooltip',
            'Press to clear all brackets in current stage'
          ),
          handler:
            this.currentTournament.status === TOURNAMENT_STATUSES.STARTED
              ? this.confirmTournamentPauseModal
              : this.confirmResetBrackets.bind(
                  null,
                  this.currentTournamentStage,
                  false
                ),
          icon: 'reset',
          disabled:
            this.isLoading ||
            this.hasDummy ||
            (!this.isCompetitionScheduled &&
              this.currentTournament.status !== TOURNAMENT_STATUSES.STARTED),
        },
        ...this.getBracketManageAction,
        // // {
        // //   key: 'stop-tournament',
        // //   title: 'Stop tournament',
        // //   subtitle: this.$t(
        // //     '_web_tournament-stop-tooltip',
        // //     'Reverts tournament status to rebuild a bracket'
        // //   ),
        // //   handler: this.confirmTournamentPauseModal,
        // //   icon: 'stop',
        // //   disabled: this.isLoading || !this.canPause,
        // // },
        // {
        //   key: 'reopen-tournament',
        //   title: 'Reopen tournament',
        //   subtitle: this.$t(
        //     '_web_tournament-reopen-tooltip',
        //     'Return tournament to a STARTED status'
        //   ),
        //   handler: this.confirmTournamentReopenModal,
        //   icon: 'step-back',
        //   disabled: this.isLoading || !this.canReopen,
        // },
        // {
        //   key: 'players-export-csv',
        //   title: 'Export Users to CSV',
        //   subtitle: this.CSVtooltipText,
        //   icon: 'csv',
        //   handler: this.exportToCSV,
        //   disabled: !this.isCompetitionFinished,
        // },
      ];
    },
    currentPageName: {
      get() {
        return this.$route.name;
      },
      set(name) {
        this.$router.push({
          name,
          params: { ...this.$route.params, roundID: 0 },
          ...(this.grid ? { query: { grid: this.grid } } : null),
        });
      },
    },
    wrapperVariant() {
      return this.isTableViewRoute || this.isEditResultsRoute
        ? ['transparent']
        : ['default', 'rounded', 'half-paddings'];
    },
    getBracketManageAction() {
      return [
        {
          key: 'go-to-brackets-edit',
          title: 'Edit brackets',
          subtitle: this.$t(
            '_web_tournament-change-stage-link-tooltip',
            'Edit brackets'
          ),
          icon: 'edit',
          disabled: this.isPublishRoute,
          handler: this.goToBracketsEdit,
        },
        {
          key: 'go-to-stage-edit',
          title: 'Setup stage',
          subtitle: this.$t(
            '_web_tournament-change-stage-link-settings',
            'Bracket setting'
          ),
          icon: 'settings-outline',
          handler: this.goToStageEdit,
        },
      ];
    },
    routerSubPages() {
      return [
        {
          key: 'BlockBracketsTable',
          route: {
            name: 'BlockBracketsTable',
            params: { ...this.$route.params, roundID: 0 },
            ...(this.grid ? { query: { grid: this.grid } } : null),
          },
          icon: 'list',
          iconWidth: 16,
          disabled: !this.isManageRoute || this.isBattleRoyale,
          helpText: this.$t(
            '_web_tournament_management_table_view_link_help',
            'Go to Manage Table'
          ),
        },
        {
          key: 'BlockBracketsGrid',
          route: {
            name: 'BlockBracketsGrid',
            params: this.$route.params,
          },
          icon: 'brackets',
          iconWidth: 16,
          disabled: !this.isManageRoute || this.isBattleRoyale,
          helpText: this.$t(
            '_web_tournament_management_brackets_view_link_help',
            'Go to Brackets view'
          ),
        },
      ];
    },
    goBackVisible() {
      return (
        // !this.isManageRoute ||
        this.isPublishRoute || (!this.notQualifiedYet && this.isConstructRoute)
      );
    },
    notQualifiedYet() {
      return !this.isTournamentPastStatus(
        TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
      );
    },
    notStartedYet() {
      return !this.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED);
    },
    blocks() {
      return this.getBlocksByStageID(this.$route.params.stageID);
    },
  },
  created() {
    this.setCurrentTournamentStage(this.stageID);
    this.setCurrentCompetitionID(this.blockID);
    this.setCurrentTournamentBlock(this.blockID);
    this.switchToBrackets();
  },
  mounted() {
    const $navbar = document.getElementById('nav');
    if ($navbar) {
      if (this.$refs.breadcrumbsWrapper) {
        this.$refs.breadcrumbsWrapper.style.top = `${$navbar.clientHeight}px`;
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentCompetitionID',
      'setCurrentTournamentStage',
      'setCurrentTournamentBlock',
      'updateModalProps',
    ]),
    ...mapActions([
      'successNotify',
      'errorNotify',
      'openModal',
      'closeModal',
      'shuffleBlockParticipants',
      'resetBlockBrackets',
      'startStageCheckIn',
    ]),
    updateRouteKey() {
      this.routeKey = `grid-${Date.now()}`;
    },
    goBack() {
      this.$router.go(-1);
    },
    goToStageEdit() {
      this.$router.push({
        name: 'multiStage',
        params: {
          ...this.$route.params,
          constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
        },
      });
    },
    switchToBrackets() {
      this.showBracketsView = true;
      window.dataLayer.push({
        event: 'tournament bracket',
        userID: this.myAccountID,
        from: this.$route.path,
        tournamentId: this.currentTournament.id,
        gameName: this.currentTournament.game,
        squadSize: this.currentTournament.squad_size,
        tournamentName: this.currentTournament.title,
        tournamentFormat: this.currentTournamentStages[0].block_configuration
          .type,
        tournamentCountry: this.currentTournament.country,
        tournamentPool: this.currentTournament.prize.prize_pool,
        regForm: this.currentTournament.requires_registration_form,
        tournamentPrivate:
          this.currentTournament.registration_type === 'closed'
            ? 'true'
            : 'false',
        tournamentOrganizer: this.currentTournament.event_organizer_name,
      });
    },
    switchToStanding() {
      this.showBracketsView = false;
      window.dataLayer.push({
        event: 'tournament standing',
        userID: this.myAccountID,
        from: this.$route.path,
        tournamentId: this.currentTournament.id,
        gameName: this.currentTournament.game,
        squadSize: this.currentTournament.squad_size,
        tournamentName: this.currentTournament.title,
        tournamentFormat: this.currentTournamentStages[0].block_configuration
          .type,
        tournamentCountry: this.currentTournament.country,
        tournamentPool: this.currentTournament.prize.prize_pool,
        regForm: this.currentTournament.requires_registration_form,
        tournamentPrivate:
          this.currentTournament.registration_type === 'closed'
            ? 'true'
            : 'false',
        tournamentOrganizer: this.currentTournament.event_organizer_name,
      });
    },
    goToBracketsEdit() {
      this.$router.push({
        name: 'BlockBracketsGrid',
        params: {
          ...this.$route.params,
          constructorMode: CONSTRUCTOR_MODES.PUBLISH,
        },
      });
    },
    confirmStartCheckIn() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-stage-check-in_confirm_modal_title',
            'This action will force check-in start. All participants will be notified about that.'
          ),
          isLoading: this.shuffleLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: () =>
            this.startStageCheckIn({
              stageID: this.stageID,
              tournamentID: this.tournamentID,
            })
              .then(() => {
                this.successNotify(
                  this.$t(
                    '_web_tournament-stage-check-in_successful',
                    'Check-In is successfully opened'
                  )
                );
              })
              .catch(this.errorNotify)
              .finally(this.closeModal),
        },
      });
    },
    confirmShuffleParticipants() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-block_qualifiers_shuffle_brackets_modal_title',
            'This action will trigger shuffle participants.'
          ),
          isLoading: this.shuffleLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onShuffleParticipants,
        },
      });
    },
    onShuffleParticipants() {
      this.updateModalProps({ isLoading: true });
      this.shuffleBlockParticipants({
        tournamentID: this.$route.params.tournamentID,
        stageID: this.$route.params.stageID,
        blockID: this.$route.params.blockID,
      })
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament-block_was-participants-shuffled',
              'Shuffle completed'
            )
          );
          this.updateRouteKey();
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.updateModalProps({ isLoading: false });
          this.closeModal();
        });
    },
    // exportToCSV() {
    //   this.setLoading();
    //   this.getTournamentResultsCSV({
    //     stage: this.stageID,
    //     block: this.blockID,
    //   })
    //     .then(res => {
    //       downloadFakeLink(new Blob([res], { type: 'text/csv' }), 'results');
    //     })
    //     .catch(this.errorNotify)
    //     .finally(this.unsetLoading);
    // },
  },
};
</script>

<style lang="scss">
#brackets-view-toggle {
  background-color: $ph-bg;
}

.breadcrumbs-wrapper {
  @include auto-rtl(padding-right, 250px); //fixed view toggler ~ 250px
  z-index: 1024;
  background-color: $ph-bg;
}
</style>
