import { get } from '@/utils/lodashUtils';
import store from '@/store';
import router from '@/router';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';
import { redirectDeletedAccount } from './redirect';
import { subRoutesFactory } from './subRoutes';
// import { VANITY_PREFIX } from '@/constants/router';

const Account = () => import('@/views/account/');

import {
  MAIN_CLANS,
  MAIN_PROFILES,
  MAIN_TOURNAMENTS,
} from '@/constants/router/mains';

export default [
  {
    path: `:vanityId`,
    beforeEnter(to, from, next) {
      if (to.params.vanityId) {
        (to.params.accountId && to.params.accountType
          ? Promise.resolve({
              entityType: to.params.accountType,
              entityId: to.params.accountId,
            })
          : store.dispatch(
              !store.getters.isAuthorized
                ? 'getEntityDetailsByVanityUrlNoAuth'
                : 'getEntityDetailsByVanityUrl',
              to.params.vanityId
            )
        )
          .then(response => {
            if (response.entityType === 'tournament' && response.entityId) {
              // Redirect to /tournament
              next({
                path: `${response.region}/tournament/${response.entityId}${
                  to.query.registration_token
                    ? '?registration_token=' + to.query.registration_token
                    : ''
                } `,
              });
            } else if (response.entityId) {
              return (
                store.dispatch(
                  !store.getters.isAuthorized
                    ? 'fetchAccountNoAuth'
                    : 'fetchAccount',
                  {
                    accountType: response.entityType,
                    accountId: response.entityId,
                  }
                ),
                next({ path: `${response.entityType}s/${response.entityId}` })
              );
            } else {
              throw 'unknown vanity url';
            }
          })
          .then(account => {
            redirectDeletedAccount(account, to, next);
          })
          .catch(error => {
            store.dispatch('errorNotify', error);
            if (error.response) {
              router.push({
                // name: 'notfound',
                path: '/',
                // params: {
                //   id: to.params.vanityId,
                // },
              });
            }
          });
      }
    },
    components: {
      default: Account,
    },
    meta: {
      getCustomTitle: () => {
        const type = store.state.accounts.current.account._type;
        return {
          key: '_web_account-title',
          label: '{accountName} {type}',
          params: {
            type: type === ROUTER_NAMES_ACCOUNT.TOURNAMENT ? '' : type,
            accountName: store.state.accounts.current.account._name,
          },
        };
      },
      getSidebarParent(name) {
        const { account, accountId } = store.state.accounts.current;

        if (account.isTournament) {
          return name === MAIN_TOURNAMENTS;
        }

        if (account.isClan) {
          return name === MAIN_CLANS;
        }

        if (account.isProfile || account.isOrganization) {
          const isMyAccount =
            get(store.state, 'accounts.my.accountId') === accountId;

          return (
            (name === ROUTER_NAMES_ACCOUNT.ACCOUNT && isMyAccount) ||
            (name === MAIN_PROFILES && !isMyAccount)
          );
        }

        return false;
      },
    },
    children: subRoutesFactory('vanity-'),
  },
];
