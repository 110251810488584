import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  simpleGetRequest,
} from '@/api/requests';
import * as ACCOUNT from '@/constants/accounts';
import { replaceLink } from '@/utils/getProps';
import { BATTLES_BASE } from './battles';
const UNIVERSAL = Symbol('universal');

const profileEndpoints = () => {
  const BASE = 'profiles/';
  const MY = `${BASE}my/`;
  const IDENTITY = 'auth/identity/info';
  const FOLLOW = `${MY}follow/`;
  const BLOCK = `${MY}block/`;
  const BLOCKED_BY = `${BASE}$0/blocked_by/`;
  const POSTS = '/posts/$0/wall/';
  const FRIENDS = `${MY}friends/$0/`;
  const FRIEND_REQUESTS = `${BASE}$0/friendship_requests/`;
  const MY_FRIEND_REQUESTS = `${MY}friendship_requests/$0`;

  const DETAILS_PUT = `${BASE}my/$0`;
  const PLATFORMS = `${MY}platforms/$0`;

  return {
    BASE,
    IDENTITY,
    FOLLOW,
    BLOCK,
    BLOCKED_BY,
    POSTS,
    FRIENDS,
    FRIEND_REQUESTS,
    MY_FRIEND_REQUESTS,
    DETAILS_PUT,
    PLATFORMS,
  };
};

const organizationEndpoints = () => {
  const BASE = 'organizations/';
  const FOLLOW = 'profiles/my/follow/'; // O.o
  const BLOCK = `profiles/my/block/`;
  const BLOCKED_BY = `profiles/$0/blocked_by/`;
  const POSTS = '/posts/$0/wall/';
  const DETAILS_PUT = `${BASE}$1/`;

  return {
    BASE,
    FOLLOW,
    BLOCK,
    BLOCKED_BY,
    POSTS,
    DETAILS_PUT,
  };
};

const clanEndpoints = () => {
  const BASE = 'clans/';
  const POSTS = '/posts/clans/$0/wall';
  const USER = `${BASE}$0/user`;
  const FOLLOW = `${BASE}$0/follows`;
  const BLOCK = `profiles/my/block/`;
  const BLOCKED_BY = `profiles/$0/blocked_by/`;
  const JOIN = `${BASE}$0/requests`;
  const ACCEPT = `${BASE}$0/invites`;
  const DETAILS_PUT = `${BASE}$1`;
  const PLATFORMS = ''; // todo

  return {
    BASE,
    USER,
    POSTS,
    FOLLOW,
    BLOCK,
    BLOCKED_BY,
    JOIN,
    ACCEPT,
    DETAILS_PUT,
    PLATFORMS,
  };
};

const tournamentEndpoints = () => {
  const BASE = 'tournaments/';

  return {
    BASE,
  };
};

const battlesEndpoints = () => {
  return {
    BASE: BATTLES_BASE + '/',
  };
};

const universalEndpoints = () => {
  const MY_DETAILS = 'profiles/my/';
  const MY_ORGANIZATIONS = 'profiles/associations/my/';

  return {
    MY_DETAILS,
    MY_ORGANIZATIONS,
  };
};

const POINTS = {
  [ACCOUNT.PROFILE]: profileEndpoints(),
  [ACCOUNT.ORGANIZATION]: organizationEndpoints(),
  [ACCOUNT.CLAN]: clanEndpoints(),
  [ACCOUNT.TOURNAMENT]: tournamentEndpoints(),
  [ACCOUNT.BATTLE]: battlesEndpoints(),
  [UNIVERSAL]: universalEndpoints(),
};

const getAccount = ({ accountType, accountId }) =>
  authorizedGetRequest(POINTS[accountType].BASE + accountId, {
    preventCancelRequest: true,
  });
const getAccountNoAuth = ({ accountType, accountId }) =>
  simpleGetRequest(POINTS[accountType].BASE + accountId, {
    preventCancelRequest: true,
  });

const getMyAccount = authorizedGetRequest(POINTS[UNIVERSAL].MY_DETAILS, {
  preventCancelRequest: true,
});

const fetchMyOrganizations = authorizedGetRequest(
  POINTS[UNIVERSAL].MY_ORGANIZATIONS,
  {
    preventCancelRequest: true,
  }
);

const getAccountBlockedBy = ({ accountType, accountId }) =>
  authorizedGetRequest(replaceLink(POINTS[accountType].BLOCKED_BY, accountId));

const postData = ({ accountType }) =>
  authorizedPostRequest(replaceLink(POINTS[accountType].BASE));

const followAccount = ({ accountType, accountId }) =>
  authorizedPostRequest(
    replaceLink(POINTS[accountType].FOLLOW, accountId),
    accountId
  );

const blockAccount = ({ accountType, accountId }) =>
  authorizedPostRequest(
    replaceLink(POINTS[accountType].BLOCK, accountId),
    accountId
  );

const joinAccountClan = ({ accountId }) =>
  authorizedPostRequest(replaceLink(POINTS[ACCOUNT.CLAN].JOIN, accountId));

const acceptAccountClan = ({ accountId }) =>
  authorizedPutRequest(replaceLink(POINTS[ACCOUNT.CLAN].ACCEPT, accountId));

const friendRequestAccount = ({ accountType, accountId }) =>
  authorizedPostRequest(
    replaceLink(POINTS[accountType].FRIEND_REQUESTS, accountId)
  );

const friendRemoveAccount = ({ accountType, accountId }) =>
  authorizedDeleteRequest(replaceLink(POINTS[accountType].FRIENDS, accountId));

const friendAcceptAccount = ({ accountType, accountId }) =>
  authorizedPostRequest(
    replaceLink(POINTS[accountType].MY_FRIEND_REQUESTS, accountId)
  );

const friendCancelAccount = ({ accountType, accountId }) =>
  authorizedDeleteRequest(
    replaceLink(POINTS[accountType].MY_FRIEND_REQUESTS, accountId)
  );

const fetchAccountWall = ({ accountType, accountId }) =>
  authorizedGetRequest(replaceLink(POINTS[accountType].POSTS, accountId));

const fetchAccountClan = accountId =>
  authorizedGetRequest(`clans/${accountId}/user`, {
    preventCancelRequest: true,
  });

const fetchIdentityInfo = accountId =>
  authorizedGetRequest(
    replaceLink(POINTS[ACCOUNT.PROFILE].IDENTITY, accountId)
  );

const putData = ({ detail = '', accountType, accountId = '' }) =>
  authorizedPutRequest(
    replaceLink(POINTS[accountType].DETAILS_PUT, detail, accountId)
  );

const putPlatform = (platform, accountType) =>
  authorizedPutRequest(replaceLink(POINTS[accountType].PLATFORMS, platform));
const deletePlatform = (platform, accountType) =>
  authorizedDeleteRequest(replaceLink(POINTS[accountType].PLATFORMS, platform));

// rename all actions to {method}{prefix[ex: My, Current, Clan ...etc]}?{action}{...suffixes}?
export default {
  // gets
  getAccount,
  getAccountNoAuth,
  getMyAccount,
  fetchMyOrganizations,
  fetchAccountWall,
  fetchAccountClan,
  fetchIdentityInfo,
  getAccountBlockedBy,

  // posts
  postData,
  followAccount,
  blockAccount,
  joinAccountClan,
  acceptAccountClan,
  friendRequestAccount,
  friendAcceptAccount,

  // deletes
  friendRemoveAccount,
  friendCancelAccount,
  deletePlatform,

  // puts
  putData,
  putPlatform,
};
