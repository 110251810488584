import api from '@/api';
import { get } from '@/utils/lodashUtils';

export const DEFAULT_COMPETITIONS_STATE = () => ({
  currentCompetitionID: null,
});

// const dummy_rounds_list_response = Array.from({length: 6 }, (el, i) => {
//   const start = (Date.now()/1000)^0
//   const end = ((Date.now() + 365 * 31 * 24 * 60 * 60 * 1000)/1000)^0
//   return {
//     "index": i,
//     "matches": 8,
//     "date_start": start,
//     "date_end": end
//   }
// })

// const dummy_matches_list_response = Array.from({length: 8 }, (el, i) => {
//   const competitionID = (Math.random() * 100000)^0
//   const battleID = (Math.random() * 100000)^0
//   const ID = (Math.random() * 100000)^0
//   const start = (Date.now()/1000)^0
//   const end = ((Date.now() + 365 * 31 * 24 * 60 * 60 * 1000)/1000)^0
//   return {
//     "id": ID,
//     "battle_id": battleID,
//     "competition_id": competitionID,
//     "index": i,
//     "round": 0,
//     "date_start": start,
//     "date_end": end,
//     "next_match_id": "",
//     "next_match_index": 0,
//     "status": "unknown",
//     "player_1_score": 0,
//     "player_2_score": 0,
//     "player_1": {
//       name: 'clan-bla-bla-bla',
//       logo: 'https://cdn1.vectorstock.com/i/1000x1000/20/40/cartoon-monster-face-square-avatar-vector-16972040.jpg'
//     },
//     "player_2": {
//       name: 'clan-boo',
//       logo: 'https://thumbs.dreamstime.com/z/cartoon-growling-zombie-face-vector-zombie-monster-square-avatar-cartoon-growling-zombie-face-vector-zombie-monster-square-avatar-101865083.jpg'
//     }
//   }
// })

// const dummy_players_list_response = {
//   "next_page_token": null,
//   "items": Array.from({length: 30 }, (el, i) => {
//     const ID = (Math.random() * 100000)^0
//     const wins = (Math.random() * 100)^0
//     const lost = (Math.random() * 100)^0
//     const draw = (Math.random() * 100)^0
//     const points = (Math.random() * 10000)^0
//     return {
//       "id": ID,
//       "name": "clan-bla-bla-bla",
//       "logo": 'https://cdn1.vectorstock.com/i/1000x1000/20/40/cartoon-monster-face-square-avatar-vector-16972040.jpg',
//       "country_id": "UA",
//       "country": "Ukraine",
//       "type": "clan",
//       wins,
//       lost,
//       draw,
//       points
//     }
//   })
// }

// Array.from({length: 8 }, (el, i) => {
//   const competitionID = (Math.random() * 100000)^0
//   const battleID = (Math.random() * 100000)^0
//   const ID = (Math.random() * 100000)^0
//   const start = (Date.now()/1000)^0
//   const end = ((Date.now() + 365 * 31 * 24 * 60 * 60 * 1000)/1000)^0
//   return {
//     "id": ID,
//     "battle_id": battleID,
//     "competition_id": competitionID,
//     "index": i,
//     "round": 0,
//     "date_start": start,
//     "date_end": end,
//     "next_match_id": "",
//     "next_match_index": 0,
//     "status": "unknown",
//     "player_1_score": 0,
//     "player_2_score": 0,
//     "player_1": {
//       name: 'clan-bla-bla-bla',
//       logo: 'https://cdn1.vectorstock.com/i/1000x1000/20/40/cartoon-monster-face-square-avatar-vector-16972040.jpg'
//     },
//     "player_2": {
//       name: 'clan-boo',
//       logo: 'https://thumbs.dreamstime.com/z/cartoon-growling-zombie-face-vector-zombie-monster-square-avatar-cartoon-growling-zombie-face-vector-zombie-monster-square-avatar-101865083.jpg'
//     }
//   }
// })

const getters = {
  currentCompetitionID: (state, getters) => {
    if (
      typeof state.currentCompetitionID === 'string' &&
      state.currentCompetitionID.includes('-')
    ) {
      return state.currentCompetitionID;
    }
    const blocks = getters.getBlocksByStageID(
      getters.currentTournamentStage.id
    );
    return get(blocks, `[${state.currentCompetitionID}].id`, null);
  },
};

const mutations = {
  setCurrentCompetitionID(state, payload) {
    state.currentCompetitionID = payload;
  },
};

const actions = {
  getCompetitionRounds: (
    { getters: { accessToken, currentCompetitionID } },
    { grid }
  ) => {
    return api.competitions
      .getRounds(currentCompetitionID)(
        accessToken,
        grid ? { params: { grid } } : null
      )
      .then(response => response.data);
  },
  // Promise
  //   .resolve(dummy_rounds_list_response),

  getCompetitionMatches: (
    { getters: { accessToken, currentCompetitionID } },
    payload
  ) =>
    api.competitions
      .getMatches(currentCompetitionID)(accessToken, {
        params: { ...payload.queryParams },
      })
      .then(response => response.data),

  getCompetitionStandings: (
    { getters: { accessToken } },
    { competitionID, queryParams = null }
  ) =>
    api.competitions
      .getStandings(competitionID)(accessToken, {
        params: { ...queryParams },
      })
      .then(({ data }) => data),

  getCompetitionPlayers: (
    { getters: { accessToken, currentCompetitionID } },
    { id, params }
  ) =>
    api.competitions
      .getParticipants(id || currentCompetitionID)(accessToken, { params })
      .then(({ data }) => data),

  getCompetitionPlayersStatus: (
    { getters: { accessToken, currentCompetitionID } },
    { id, params }
  ) =>
    api.competitions
      .getParticipantsStatus(id || currentCompetitionID)(accessToken, {
        params,
      })
      .then(({ data }) => data),

  getCompetitionPlayersAll: (
    { getters: { accessToken, currentCompetitionID } },
    { id, params }
  ) =>
    api.competitions
      .getParticipantsAll(id || currentCompetitionID)(accessToken, { params })
      .then(({ data }) => data),
  updateCompetitionRoundDatetime: ({ getters: { accessToken } }, payload) =>
    api.competitions.updateRound(payload.competitionID, payload.roundIndex)(
      accessToken,
      {
        date_start: payload.dateStart,
        date_end: payload.dateEnd,
      }
    ),
  sendMessagetoRound: ({ getters: { accessToken } }, payload) =>
    api.competitions.sendTournamentRoundMessage(
      payload.tournamentID,
      payload.roundID,
      payload.block
    )(accessToken, {
      message: payload.message,
      CompleteRound: payload.CompleteRound,
    }),
  updateCompetitionMatchDatetime: ({ getters: { accessToken } }, payload) =>
    api.competitions.updateMatchByID(payload.competitionID, payload.matchID)(
      accessToken,
      {
        date_start: payload.dateStart,
        date_end: payload.dateEnd,
      }
    ),
  moveMatchToRound: (
    { getters: { accessToken, currentCompetitionID } },
    payload
  ) =>
    api.competitions.moveMatchToRound(
      currentCompetitionID,
      payload.destinationRoundID
    )(accessToken, {
      match_id: payload.matchId,
    }),

  updateRoundAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { roundID, data } = payload;
    return api.competitions
      .updateRound(tournamentID, stageID, competitionID, roundID)(
        accessToken,
        data
      )
      .then(response => response.data);
  },

  swapPlayers(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { players } = payload;
    return api.competitions
      .swapPlayers(tournamentID, stageID, competitionID)(accessToken, players)
      .then(response => response.data);
  },
  fetchPlayersForAdding(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    matchID
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    return api.competitions
      .getPlayersListForAddingToMatch(
        tournamentID,
        stageID,
        competitionID,
        matchID
      )(accessToken)
      .then(response => response.data);
  },
  addPlayerToMatch(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    { matchID, playerID, playerIndex } = {}
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    return api.competitions
      .addPlayerToMatch(
        tournamentID,
        stageID,
        competitionID,
        matchID,
        playerID,
        playerIndex
      )(accessToken)
      .then(response => response.data);
  },
  deletePlayerFromMatch(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    { matchID, playerID } = {}
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    return api.competitions
      .deletePlayerFromMatch(
        tournamentID,
        stageID,
        competitionID,
        matchID,
        playerID
      )(accessToken)
      .then(response => response.data);
  },
  updateMatchAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { matchID, data } = payload;
    return api.competitions
      .updateMatchByID(tournamentID, stageID, competitionID, matchID)(
        accessToken,
        data
      )
      .then(response => response.data);
  },

  // proceedPlayOffMatch(
  //   {
  //     getters: { accessToken, currentTournament },
  //   },
  //   payload
  // ) {
  //   const tournamentID = currentTournament.id;
  //   const { roundID, matchID } = payload;
  //   return api
  //     .proceedPlayOffMatch(tournamentID, roundID, matchID)(accessToken)
  //     .then(response => response.data);
  // },
  startMatchAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { matchID } = payload;
    return api.competitions
      .forceStartMatch(tournamentID, stageID, competitionID, matchID)(
        accessToken
      )
      .then(response => response.data);
  },
  finishMatchAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { matchID } = payload;
    return api.competitions
      .forceFinishMatch(tournamentID, stageID, competitionID, matchID)(
        accessToken
      )
      .then(response => response.data);
  },
  resetMatchAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { matchID } = payload;
    return api.competitions
      .resetMatch(tournamentID, stageID, competitionID, matchID)(accessToken)
      .then(response => response.data);
  },
  setTechWinAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { matchID, index } = payload;
    return api.competitions
      .setTechWin(tournamentID, stageID, competitionID, matchID)(accessToken, {
        player_win_index: 'player_' + index,
      })
      .then(response => response.data);
  },
  startRoundAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { roundID, grid = null } = payload;
    return api.competitions
      .forceStartRound(tournamentID, stageID, competitionID, roundID)(
        accessToken,
        grid ? { grid } : null
      )
      .then(response => response.data);
  },
  finishRoundAction(
    {
      getters: {
        accessToken,
        currentTournament,
        currentCompetitionID,
        currentStageID,
      },
    },
    payload
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    const { roundID, grid = null } = payload;
    return api.competitions
      .forceFinishRound(tournamentID, stageID, competitionID, roundID)(
        accessToken,
        grid ? { grid } : null
      )
      .then(response => response.data);
  },
  getCompetitionsAction(
    {
      getters: { accessToken },
    },
    payload = {}
  ) {
    return api.competitions
      .getCompetitions(accessToken, { params: payload })
      .then(response => response.data);
  },
  // restartPlayOffRound(
  //   {
  //     getters: { accessToken, currentTournament },
  //   },
  //   payload
  // ) {
  //   const tournamentID = currentTournament.id;
  //   const { roundID } = payload;
  //   return api
  //     .restartPlayOffRound(tournamentID, roundID)(accessToken)
  //     .then(response => response.data);
  // },
};

export default {
  state: DEFAULT_COMPETITIONS_STATE(),
  getters,
  mutations,
  actions,
};
