// import api from '@/api';
import Vue from 'vue';
import { emptyObject } from '@/utils/factories';
import { isEmpty } from '@/utils/lodashUtils';
import { CLAN, CLAN_ROLES } from '@/constants/accounts';
import { displayName } from '@/filters/displayName';

// const CURRENT = 'current';
// const MY = 'my';

export const INITIAL_MY_CLANS_STATE = () => emptyObject();
// ({
//   // [MY]: emptyObject(),
//   // [CURRENT]: emptyObject(),
// });

const clanList = state =>
  Object.keys(state).map(clanId => ({
    ...state[clanId],
    type: CLAN,
    id: clanId,
  }));

const hasClans = state => !isEmpty(state);
const isMemberOfAClan = state => clanId => !isEmpty(state[clanId]);
const getClan = state => clanId => state[clanId];
const hasMultipleClans = state => Object.keys(state).length > 1;

const getters = {
  clanList,
  hasClans,
  isMemberOfAClan,
  getClan,
  hasMultipleClans,
};

const mutations = canEdit => ({
  setClans(state, payload) {
    if (Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i++) {
        const clan = payload[i];
        Vue.set(state, clan.id, {
          logo: encodeURI(clan.logo || ''),
          cover: encodeURI(clan.cover || ''),
          name: displayName(clan),
          role: clan.role,
          country_id: clan.country_id,
          canManage:
            clan.role === CLAN_ROLES.OWNER || this.role === CLAN_ROLES.DEPUTY,
          verified:
            clan.verified || clan.approved || clan.is_ambassador || false,
          vanityId: clan.vanity_id,
        });
      }
    } else if (typeof payload === 'object' && payload !== null) {
      Vue.set(state, payload.id, payload);
    }
  },
  deleteClans(state) {
    for (const clanId in state) {
      if (Object.hasOwnProperty.call(state, clanId)) {
        Vue.delete(state, clanId);
      }
    }
  },
  ...(canEdit
    ? {
        updateClan(state, payload = {}) {
          if (!payload.id) {
            throw 'You should specify clan ID to update it';
          } else if (isEmpty(state[payload.id])) {
            throw `Can't update! Clan with ID ${payload.id} not found`;
          } else {
            Vue.set(state, payload.id, {
              ...state[payload.id],
              ...payload,
            });
          }
        },
        deleteClan(state, clanId) {
          if (!clanId) {
            throw 'You should specify clan ID to delete it';
          } else {
            Vue.delete(state, clanId);
          }
        },
        addClan(state, payload) {
          if (!payload.id) {
            throw 'You should specify clan ID to add it to account';
          } else {
            Vue.set(state, payload.id, payload);
          }
        },
      }
    : {}),
});

const actions = {};

const stateModule = (canEdit = false) => ({
  state: INITIAL_MY_CLANS_STATE(),
  getters,
  mutations: mutations(canEdit),
  actions,
});

export default stateModule;
