/**
 * Set global eventHandles (for window). Use:
 *
 * addListener:
 * addGlobalEventListener('resize', console.log); // 1
 * addGlobalEventListener('resize.myKey', console.log); // 2
 * addGlobalEventListener('resize orientationchange', console.log); // 3
 * addGlobalEventListener('orientationchange.myKey', console.log); // 4
 *
 * removeListener:
 * removeGlobalEventListener('resize.myKey'); // remove listener `resize` with `.myKey` (2)
 * removeGlobalEventListener('.myKey'); // remove all listeners with `.myKey` (2 and 4)
 * removeGlobalEventListener('resize'); // remove all listeners `resize` (1, 2 and 3)
 * removeGlobalEventListener('.'); // remove all global listeners (1, 2, 3 and 4)
 *
 * alt:
 * globalEventListener('add', 'click', console.log) // add listener
 * globalEventListener('replace', 'click', console.log) // remove listener and set again
 * globalEventListener('remove', 'click', console.log) // remove listener
 */

/**
 * Window events
 * @type {Map<string, Object>}
 */
const eventsMap = new Map();

/**
 * addEventListener for Window (one or few) with keys
 * @param binds {string} - events. Ex: `click`, `click.myKey`, `mouseenter mouseleave`, `keydown.key keyup.key` etc
 * @param callback
 */
export const addGlobalEventListener = (binds, callback) => {
  binds.split(' ').forEach(eventName => {
    const [bind, key = Symbol()] = eventName.split('.');

    if (eventsMap.has(bind)) {
      eventsMap.get(bind)[key] = callback;
    } else {
      eventsMap.set(bind, { [key]: callback });
    }

    window.addEventListener(bind, callback);
  });
};

/**
 * removeEventListener for Window and delete key from [eventsMap]
 * @param eventName {string} - eventName (`click`, `mouseenter`, `resize` etc)
 * @param key {string} - key in Object [eventsMap][eventName]
 */
const windowRemoveEventListener = (eventName, key) => {
  const eventsObject = eventsMap.get(eventName);

  if (eventsObject instanceof Object && eventsObject[key]) {
    window.removeEventListener(eventName, eventsObject[key]);
    delete eventsObject[key];
  }
};

/**
 * removeEventListener for Window by eventName and/or key
 * @param binds {string} - Ex: `<eventName>` or `<eventName>.<key>` or `.<key>`
 */
export const removeGlobalEventListener = binds => {
  binds.split(' ').forEach(eventName => {
    const [bind, key] = eventName.split('.');

    // remove by `<eventName>.<key>`
    if (bind && key) {
      windowRemoveEventListener(bind, key);
    }

    // remove all by `<eventName>`
    if (bind && !key) {
      const bindsData = eventsMap.get(bind);

      if (bindsData instanceof Object) {
        Object.keys(bindsData).forEach(item => {
          windowRemoveEventListener(bind, item);
        });
        eventsMap.delete(bind);
      }
    }

    // remove all by `.<key>`
    if (!bind && key) {
      return eventsMap.forEach((obj, eventName) => {
        windowRemoveEventListener(eventName, key);
      });
    }

    // remove all `.`
    if (!bind && !key) {
      eventsMap.forEach((obj, eventName) => {
        Object.keys(obj).forEach(item => {
          windowRemoveEventListener(eventName, item);
        });
      });
      eventsMap.clear();
    }
  });
};

/**
 * Join all functions in const
 * @param type {string} - type. Only `add`, `remove` or `replace`
 * @param binds {string} - Ex: `<eventName>` or `<eventName>.<key>` or `.<key>`
 * @param callback {function} [callback = null] - callback
 */
export const globalEventListener = (type, binds, callback = null) => {
  if (!type || typeof binds !== 'string') {
    return;
  }
  if (type === 'add' && typeof callback === 'function') {
    return addGlobalEventListener(binds, callback);
  }
  if (type === 'remove') {
    return removeGlobalEventListener(binds);
  }
  if (type === 'replace') {
    removeGlobalEventListener(binds);
    addGlobalEventListener(binds, callback);
  }
};

export const getScrollbarWidth = () => {
  const $outer = document.createElement('div');
  $outer.style.visibility = 'hidden';
  $outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild($outer);
  const $inner = document.createElement('div');
  $outer.appendChild($inner);
  const scrollbarWidth = $outer.offsetWidth - $inner.offsetWidth;
  document.body.removeChild($outer);

  return scrollbarWidth;
};
