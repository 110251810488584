<template>
  <FullscreenPopup
    v-show="isVisible && !isHiddenAfterAnimation"
    :title="title || $t('_web_congratulations', 'Congratulations!')"
    v-bind="{ subTitle }"
    animated
    @enterAnimationDone="onEnterAnimationDone"
    @leaveAnimationDone="onLeaveAnimationDone"
    @click.native="isHiddenAfterAnimation = true"
  >
    <slot slot="image">
      <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.4282 18.5789C35.9911 19.1394 35.9911 20.056 35.4282 20.6189L23.626 32.4211C23.3445 32.7025 22.9741 32.8445 22.606 32.8445C22.2379 32.8445 21.8675 32.7025 21.586 32.4211L15.5718 26.4069C15.0089 25.8464 15.0089 24.9299 15.5718 24.3669C16.1348 23.804 17.0465 23.804 17.6118 24.3669L22.606 29.3587L33.3882 18.5789C33.9535 18.016 34.8652 18.016 35.4282 18.5789ZM51 25.5C51 39.5611 39.5611 51 25.5 51C11.4389 51 0 39.5611 0 25.5C0 11.4389 11.4389 0 25.5 0C39.5611 0 51 11.4389 51 25.5ZM48.1132 25.5C48.1132 13.0291 37.9685 2.88679 25.5 2.88679C13.0315 2.88679 2.88679 13.0291 2.88679 25.5C2.88679 37.9709 13.0315 48.1132 25.5 48.1132C37.9685 48.1132 48.1132 37.9709 48.1132 25.5Z"
          fill="white"
        ></path>
      </svg>
    </slot>
  </FullscreenPopup>
</template>

<script>
import FullscreenPopup from './FullscreenPopup';
import { prop } from '@/utils/factories';

export default {
  name: 'CongratulationPopup',
  components: {
    FullscreenPopup,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: 3000, // ms
    },
    redirectTo: {
      type: Object,
      default: null,
    },
    isVisible: prop(Boolean),
  },
  data() {
    return {
      isHiddenAfterAnimation: false,
    };
  },
  methods: {
    onEnterAnimationDone() {
      setTimeout(() => {
        this.isHiddenAfterAnimation = true;
      }, this.delay);
    },
    onLeaveAnimationDone() {
      this.$emit('leave');
      if (this.$lodash.isEmpty(this.redirectTo)) {
        return;
      }
      this.$router.push(this.redirectTo);
    },
  },
};
</script>
