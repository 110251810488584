<template>
  <div
    class="form-group d-flex flex-column hera-form-checkbox"
    :class="{ disabled }"
    @click="$emit('click', $event)"
  >
    <label class="label-checkbox pb-0">
      <input
        type="checkbox"
        :class="{ indeterminate }"
        :name="name"
        :value="val"
        :checked="isChecked"
        :title="label"
        :tabindex="tabindex"
        @change="onChange"
      />
      <span class="hera-checkbox"></span>
      <span class="new-checkbox-text">
        {{ label }}
        <slot />
      </span>
    </label>
    <span
      v-show="error"
      class="form-text text-danger error-helper ml-5 p-0"
      v-text="error"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'HeraCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    val: {
      type: null,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.value.includes(this.val) || this.checked;
    },
  },
  methods: {
    onChange() {
      if (this.isChecked) {
        this.value.splice(this.value.findIndex(el => el === this.val), 1);
      } else {
        this.value.push(this.val);
      }
      this.$emit('change', ...this.value);
    },
  },
};
</script>

<style lang="scss">
.new-checkbox-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: 700;
  span {
    padding: 0 !important;
  }
}

.hera-form-checkbox {
  width: 100%;
  .label-checkbox {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }
  input {
    position: absolute;
    z-index: -2;
    transform: scale(0);
    opacity: 0;

    &:checked {
      ~ .hera-checkbox::before {
        border-color: $new-border-neutral;
        background-color: $new-bg-primary;
        background-image: url('~@/assets/images/icons/checked.svg');
      }
    }
    &.indeterminate {
      ~ .hera-checkbox::before {
        border-color: $new-border-neutral;
        background-color: $hr-bg-accent;
        background-image: url('~@/assets/images/checkbox-indeterminate.svg');
      }
    }
  }
  .hera-checkbox::before {
    content: '';
    display: flex;
    border: 1px solid $new-border-neutral;
    background: center no-repeat;
    background-size: contain;
    cursor: pointer;
    border-radius: 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }
  label {
    margin: 0;
  }
}
</style>
