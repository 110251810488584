<template>
  <Panel
    v-if="
      currentTournament.sponsor_logo && currentTournament.sponsor_logo.DEFAULT
    "
    class="tournament-info_organizer pb-0"
    variant="transparent"
  >
    <Subheader
      class="mb-4 text-uppercase font-weight-normal"
      :text="$t('_web_powered_by', 'POWERED BY')"
      :text-block-props="{ size: 0.875, lineHeight: 1.5 }"
      variant="hr-secondary"
    />
    <div v-if="!currentTournament.sponsor_logo.DEFAULT" class="text-muted">
      {{ $t('_web_tournament-info_no-sponsors', 'No Sponsors') }}
    </div>
    <img
      v-for="(logo, index) in currentTournament.sponsor_logo.DEFAULT"
      :key="`sponsor-${index}`"
      :src="logo"
      class="d-inline-block p-2 sponsors-logo"
    />
  </Panel>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import { mapGetters } from 'vuex';
import Subheader from '@/components/atoms/Subheader';
export default {
  name: 'TournamentInfoSponsors',
  components: {
    Subheader,
    Panel,
  },
  computed: {
    ...mapGetters(['currentTournament']),
  },
};
</script>
<style lang="scss">
.tournament-info_organizer {
  display: block !important;
}
.sponsors-logo {
  max-width: 4.75rem;
  max-height: 2.4rem;
  height: auto;
  border-radius: 5px;
}
</style>
