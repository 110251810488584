export const loader = (...names) => ({
  data() {
    return names.reduce((acc, name) => {
      acc[name] = false;
      return acc;
    }, {});
  },
  methods: {
    setLoading(name) {
      this.toggleLoading(name, true);
    },
    unsetLoading(name) {
      this.toggleLoading(name, false);
    },
    toggleLoading(name, val = !this[name]) {
      if (names.includes(name)) {
        this[name] = !!val;
      }
    },
  },
});
