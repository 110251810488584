<template>
  <div
    v-if="!isEmpty"
    class="align-items-center tournament-actions d-flex flex-grow-1"
    :class="{
      'h-ml-auto': !accountIsOrganization && hasParticipantStatus,
      'mx-auto': (canJoin || checkInRequired) && hideBracketsButton,
    }"
    @click.prevent.stop
  >
    <div
      v-if="!hideJoinActions || showBrackets || showStatus"
      class="position-absolute w-100 d-flex justify-content-center align-items-center cta-wrapper"
    >
      <template v-if="!hideJoinActions">
        <Badge
          v-if="needInvite && !isInvited && !hasParticipantStatus"
          :text="$t('_web_tournaments_invites-only', 'Invites only')"
          class="p-3"
        />
        <Button
          v-else-if="canJoin && !needTeam"
          class="playButton"
          :loading="isLoading"
          :disabled="accountIsOrganization"
          @click.prevent.stop="selectJoinScenario(tournament)"
        >
          {{ $$t('PLAY', '_web_play') }}
        </Button>
        <Button
          v-else-if="canJoin && needTeam && teamJoinViaModal"
          class="playButton"
          :loading="isLoading"
          :disabled="accountIsOrganization"
          @click.prevent.stop="openJoinModal"
        >
          {{ $$t('Join') }}
        </Button>
        <template v-else-if="canJoin && needTeam && $options.multiclanEnabled">
          <Button
            class="joinWithTeam mr-4"
            :loading="isLoading"
            :disabled="accountIsOrganization"
            @click.prevent.stop="selectJoinScenario(tournament)"
          >
            <img
              src="@/assets/images/joinWithTeam.svg"
              alt="Join with a team"
              class="mr-1 ml-3"
            />
            {{ $$t('JOIN WITH A TEAM', '_web_join_with_team') }}
          </Button>

          <Button
            class="createTeam"
            :loading="isLoading"
            :disabled="
              accountIsOrganization || (needInvite && !hasPrivateTokenForJoin)
            "
            @click.prevent.stop="goToClanCreate(tournament)"
            ><img
              src="@/assets/images/createTeam.svg"
              alt="Create a team"
              class="mr-2"
            />
            {{ $$t('CREATE A TEAM', '_web_create_a_team') }}
          </Button>
        </template>
        <!-- <Button
          v-else-if="checkInRequired"
          class="btn-primary"
          :loading="isLoading"
          @click.prevent.stop="onCheckIn"
        >
          {{ $$t('Check-in') }}
        </Button> -->
      </template>
      <StatusLabel
        v-if="!accountIsOrganization && hasParticipantStatus && showStatus"
        :status="status"
        class="p-3"
      />

      <!-- <router-link
        v-if="
          showBrackets && !hideBracketsButton && !checkInRequired && !canJoin
        "
        :to="bracketsLink"
        class="h-ml-3"
        @click.prevent.stop
      >
        <Button class=" text-uppercase">{{ $$t('Brackets') }} /></Button>
      </router-link> -->
    </div>
    <span class="back-btn-hero" @click="$router.go(-1)"
      ><img
        class="back-btn-hero"
        src="@/assets/images/back-btn.svg"
        alt="back button"
    /></span>
    <span
      v-if="!hideSharingButton"
      class="playhera-icon h-ml-auto cursor-pointer position-relative"
      :class="[
        hideAdditionalMenu || !popoverItems.length ? 'h-mr-0' : 'h-mr-4',
      ]"
      @click.prevent="
        $event => {
          return openSharingMenu({
            event: $event,
            type: tournamentType,
            text: `${tournament.title || $filters.displayName(account)}`,
            id: tournament.id || tournament._id,
            vanityId: tournament.vanity_id,
          });
        }
      "
    >
      <AppIcon name="share" class="mobile-btn" />
    </span>
    <Btn
      v-if="hasParticipantStatus && needTeam && registrationOpened && isCap"
      class="postition-relative btn-edit-roster"
      @click="goToRegistration"
      ><img
        alt="manageRoster"
        src="@/assets/images/icons/user-settings.svg"
        class="mx-2"
      /><span>Edit roster</span></Btn
    >
    <ThreeDots
      v-if="!hideAdditionalMenu"
      :items="popoverItems"
      class="position-relative mobile-btn-tree-dots"
      hide-if-items-is-empty
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import Button from '@/components/atoms/HeraFormBtn';
import StatusLabel from '@/components/molecules/StatusLabel.vue';
import ThreeDots from '@/components/common/ThreeDots';
import dateMethods from '@/mixins/dateMethods';
import joinAction from '@/views/tournaments/mixins/joinTournamentAction';
import Badge from '@/components/atoms/HeraBadge';
import Btn from '@/components/atoms/HeraFormBtn';
import { prop, propRequired } from '@/utils/factories';
import { translate } from '@/mixins/translate';
import { CLAN, TOURNAMENT } from '@/constants/accounts';
import {
  PARTICIPANT_STATUSES,
  TOURNAMENT_STATUSES,
  STAGE_STATUSES,
} from '@/constants/tournaments';

import AppIcon from '@/components/atoms/Icon';
import { sharingMenu } from '@/mixins/sharingMenu';
const TOURNAMENT_INVITE_ONLY_REGISTRATION = 'closed';

// const TOURNAMENT_JOIN_ACCESS_NONE = 'none';
const TOURNAMENT_JOIN_ACCESS_INVITED = 'invited';
// const TOURNAMENT_JOIN_ACCESS_JOINED = 'joined';

const STAGE_AVAILABLE_STATUSES = [
  STAGE_STATUSES.PUBLISHED,
  STAGE_STATUSES.STARTED,
  STAGE_STATUSES.FINISHED,
];

export default {
  name: 'StatusBarActionsTournament',
  components: {
    Button,
    ThreeDots,
    StatusLabel,
    AppIcon,
    Badge,
    Btn,
  },
  mixins: [
    ...dateMethods,
    joinAction,
    translate('_web_tournament_statusbar'),
    sharingMenu,
  ],
  props: {
    hideAdditionalMenu: prop(Boolean),
    hideBracketsButton: prop(Boolean),
    hideSharingButton: prop(Boolean),
    hideJoinActions: prop(Boolean),
    teamJoinViaModal: prop(Boolean),
    showStatus: prop(Boolean),
    tournament: propRequired(Object),
  },
  data() {
    return {
      actionPopoverPosition: null,
      localStatus: null,
      showBrackets: false,
      hasOneStage: false,
      hasOneBlock: false,
      checkedIn: false,
      hasTeamInvite: false,
      mySquad: [],
      isCap: false,
    };
  },
  computed: {
    ...mapState('current', ['account', 'accountId']),
    ...mapState('my', { myAccount: 'account' }),
    ...mapGetters([
      'currentTournament',
      'isTournamentPastStatus',
      'currentTournamentStages',
      'getBlocksByStageID',
    ]),
    tournamentType() {
      return TOURNAMENT;
    },
    accountIsOrganization() {
      return this.myAccount ? this.myAccount.isOrganization : false;
    },
    isEmpty() {
      return (
        !this.isLoading &&
        !this.canJoin &&
        !this.checkInRequired &&
        (this.accountIsOrganization || !this.hasParticipantStatus) &&
        (!this.showBrackets || this.hideBracketsButton) &&
        (this.hideAdditionalMenu || !this.popoverItems.length) &&
        this.hideSharingButton &&
        !this.needInvite
      );
    },
    needTeam() {
      return (
        this.tournament.playerType === CLAN ||
        this.tournament.player_type === CLAN
      );
    },
    canJoin() {
      return this.isRegOpen && this.hasAccessToJoin;
    },
    isRegOpen() {
      return this.tournament.status === TOURNAMENT_STATUSES.REG_OPENED;
    },
    hasPrivateTokenForJoin() {
      return Boolean(this.$route.query.registration_token);
    },
    hasAccessToJoin() {
      return (
        !this.hasParticipantStatus &&
        (!this.hasPrivateTokenForJoin && this.needInvite
          ? this.isInvited
          : true)
      );
    },
    registrationOpened() {
      return this.currentTournament.status === 'registration_opened';
    },

    hasParticipantStatus() {
      return this.status !== null && this.status !== 'revoked';
    },
    status() {
      return (
        this.localStatus ||
        (this.tournament.hasOwnProperty('participantStatus')
          ? this.tournament.participantStatus
          : this.tournament.participant_status)
      );
    },
    needInvite() {
      return (
        this.tournament.registrationType ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION ||
        this.tournament.registration_type ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION
      );
    },
    isInvited() {
      return (
        this.joinAccess === TOURNAMENT_JOIN_ACCESS_INVITED || this.hasTeamInvite
      );
    },
    joinAccess() {
      const { joinAccess } = this.tournament || {};
      return (joinAccess || '').toLowerCase(); // first letter - uppercase
    },
    checkInRequired() {
      return (
        this.isTournamentPastStatus(
          TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
          this.tournament
        ) &&
        !this.isTournamentPastStatus(
          TOURNAMENT_STATUSES.CLOSED,
          this.tournament
        ) &&
        this.hasCheckIn &&
        this.$lodash.get(this.checkInModel, 'required', false) &&
        this.$lodash.get(this.checkInModel, 'start') <= this.getNowSeconds() &&
        !this.checkedIn
      );
    },
    hasCheckIn() {
      return this.isParticipated && Boolean(this.checkInModel);
    },
    isRequested() {
      return [
        PARTICIPANT_STATUSES.REQUESTED,
        PARTICIPANT_STATUSES.REGISTERED,
        PARTICIPANT_STATUSES.PAYMENTPENDING,
        PARTICIPANT_STATUSES.QUALIFIED,
      ].includes(this.status);
    },
    isParticipated() {
      return [
        PARTICIPANT_STATUSES.QUALIFIED,
        PARTICIPANT_STATUSES.RESERVED,
      ].includes(this.status);
    },
    isRevoked() {
      return [
        PARTICIPANT_STATUSES.REVOKED,
        PARTICIPANT_STATUSES.NOTQUALIFIED,
      ].includes(this.status);
    },
    popoverItems() {
      const items = [];

      if (this.isRequested && this.isCap) {
        items.push({
          label: this.$$t('Revoke'),
          action: this.revoke,
        });
      }
      return items;
    },
    checkInModel() {
      return this.tournament.hasOwnProperty('checkIn')
        ? this.tournament.checkIn
        : this.tournament.check_in;
    },
    сheckInEnabled() {
      return this.hasCheckIn && this.checkInModel.required;
    },
    isApplicationFormSent() {
      return this.isRequested || this.isParticipated;
    },
    bracketsLink() {
      const stagesList = {
        name: 'playerStagesList',
        params: { tournamentID: this.tournament.id },
      };
      const firstStage = this.currentTournamentStages[0];

      if (!firstStage) {
        return stagesList;
      }
      const firstBlock = this.getBlocksByStageID(firstStage.id)[0];

      return this.hasOneStage && this.hasOneBlock
        ? {
            name: 'playerTournamentBracket',
            params: {
              // ...this.$route.params,
              tournamentID: this.tournament.id,
              stageID: firstStage.id,
              blockID: firstBlock.id,
              blockType: this.$lodash.kebabCase(firstBlock.type),
            },
          }
        : stagesList;
    },
  },
  created() {
    this.$options.multiclanEnabled = true;
    this.afterJoin = this.joinSuccessHandler;
    if (this.needTeam && this.needInvite) {
      this.setLoading();
      this.getCompetitionPlayersStatus({
        id: this.tournament.id,
        params: {
          registration_token: this.$route.query.registration_token,
        },
      })
        .then(data => {
          this.hasTeamInvite = data.some(
            team =>
              (team.join_access || '').toLowerCase() ===
              TOURNAMENT_JOIN_ACCESS_INVITED
          );
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    }
    if (!this.hideBracketsButton) {
      this.fetchTournamentStages(this.tournament.id)
        .then(stages => {
          this.showBrackets = stages.some(stage => {
            const status = stage.status.toLowerCase();
            return STAGE_AVAILABLE_STATUSES.includes(status);
          });
        })
        .then(() => {
          this.hasOneStage = this.currentTournamentStages.length === 1;
          return this.currentTournamentStages.map(stage => {
            return this.fetchTournamentBlocks({
              stage: stage.index,
              tournamentID: stage.tournament_id,
            }).then(blocks => {
              this.setTournamentBlocks(blocks);
              return blocks;
            });
          });
        })
        .then(blocksPromises =>
          Promise.all(blocksPromises).catch(this.errorNotify)
        )
        .then(results => {
          if (this.hasOneStage) {
            this.hasOneBlock = results[0].length === 1;
          }
        })
        .catch(this.errorNotify);
    }
  },
  async mounted() {
    setTimeout(() => {
      this.getTournamentSquadParticipants()
        .then(squad => {
          console.log('squad:', squad);

          this.mySquad = squad || [];
        })
        .then(() => {
          this.isCap = this.mySquad.find(user => {
            return user.login === this.myAccount.login;
          }).captain;
        })
        .catch(this.errorNotify);
    }, 1000);
  },
  methods: {
    ...mapMutations(['setTournamentBlocks']),
    ...mapActions([
      'revokeTournament',
      'errorNotify',
      'openModal',
      'closeModal',
      'fetchTournamentStages',
      'fetchTournamentBlocks',
      'checkInParticipant',
      'getCompetitionPlayersStatus',
      'getTournamentSquadParticipants',
    ]),
    goToRegistration() {
      this.$router.push({
        name: 'tournamentRegistrationCreateRoster',
        params: {
          clanID: this.currentTournament.clan_id,
          action: 'edit',
        },
        query: {
          ...this.$route.query,
        },
      });
    },
    openJoinModal() {
      this.openModal({
        name: 'TeamJoinOptionsModal',
        props: {
          title: this.$$t('Pick an option'),
          pickTeamButtonDisabled: this.accountIsOrganization,
          createTeamButtonDisabled:
            this.accountIsOrganization ||
            (this.needInvite && !this.hasPrivateTokenForJoin),
          isLoading: this.isLoading,
        },
        events: {
          joinWithExistingTeam: () => {
            this.closeModal();
            this.selectJoinScenario(this.tournament);
          },
          joinWithNewTeam: () => {
            this.closeModal();
            this.goToClanCreate(this.tournament);
          },
          // cancel: this.closeModal,
          // confirm: this.onAnnounceTournament.bind(null),
        },
        settings: {
          clickOnBackdrop: true,
          escKey: true,
        },
      });
    },
    updateStatus(participantStatus) {
      this.localStatus = participantStatus;
    },
    onCheckIn() {
      this.setLoading();
      this.checkInParticipant({
        tournamentID: this.tournament.id,
      })
        .then(() => {
          this.checkedIn = true;
          this.updateStatus(PARTICIPANT_STATUSES.CHECKEDIN);
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    joinSuccessHandler() {
      this.updateStatus(PARTICIPANT_STATUSES.REQUESTED);
    },
    revoke() {
      this.setLoading();
      this.revokeTournament(this.tournament.id)
        .then(this.revokeSuccessHandler)
        .then(
          console.log(this.account),
          window.dataLayer.push({
            event: 'tournament revoke',
            userID: this.myAccount._id,
            tournamentId: this.currentTournament.id,
            from: this.$route.path,
            gameId: this.currentTournament.game,
            gameName: this.currentTournament.game,
            tournamentName: this.currentTournament.title,
            squadSize: this.currentTournament.squad_size,
            tournamentFormat: this.currentTournamentStages[0]
              .block_configuration.type,
            tournamentCountry: this.currentTournament.country,
            tournamentPool: this.currentTournament.prize.prize_pool,
            regForm: this.currentTournament.requires_registration_form,
            tournamentPrivate:
              this.currentTournament.registration_type === 'closed'
                ? 'true'
                : 'false',
            tournamentOrganizer: this.currentTournament.event_organizer_name,
          })
        )
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    revokeSuccessHandler() {
      this.updateStatus(PARTICIPANT_STATUSES.REVOKED);
      this.$emit('revoked', PARTICIPANT_STATUSES.REVOKED);
    },
  },
};
</script>

<style lang="scss">
// .brackets {
//   display: flex;
//   width: 126px !important;
//   height: 36px !important;
//   border: 1px solid #ffffff !important;
//   border-radius: 24px !important;
//   align-items: center !important;
//   justify-content: center !important;
//   font-family: 'Exo' !important;
//   font-style: normal !important;
//   font-weight: 800 !important;
//   font-size: 14px !important;
//   line-height: 150% !important;
//   display: flex;
//   align-items: center;
//   color: #ffffff;
// }

.joinWithTeam {
  width: 177px !important;
  height: 36px !important;
  background: #ffffff !important;
  border-radius: 5px !important;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #6300fa !important;
  padding: 0 !important;
}

.playButton {
  width: 105px !important;
  height: 36px !important;
  background: #5a58f2 !important;
  border-radius: 5px !important;
  font-family: 'Exo' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  color: white !important;
}

.createTeam {
  width: 176px;
  height: 36px;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff !important;
  border-radius: 5px !important;
  color: #ffffff;
}
.tournament-actions {
  min-height: 42px;
  > *:not(:last-child) {
    @include auto-rtl(margin-right, 0.75rem);
  }
  .cta-wrapper {
    @include auto-rtl(left, 0);
  }
}
.back-btn-hero {
  .app-rtl & {
    img {
      transform: scale(-1);
    }
  }
  cursor: pointer;
  z-index: 4;
  @media (max-width: 800px) {
    position: absolute;
    top: -22px;
  }
}
.mobile-btn {
  @media (max-width: 800px) {
    position: absolute;
    top: -65px;
    right: 0px;
  }
}
.mobile-btn-tree-dots {
  @media (max-width: 800px) {
    position: absolute;
    top: -53px;
    right: 2px;
  }
}
.btn-edit-roster {
  border: none !important;
  align-content: center;
  @media (max-width: 800px) {
    position: absolute;
    top: -53px;
    right: 2px;
  }
  > img {
    height: 20px;
    @media (max-width: 800px) {
      height: 24px;
    }
  }
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 800px) {
      display: none;
    }
  }
}
html[dir='rtl'] {
  .mobile-btn {
    @media (max-width: 800px) {
      top: -65px;
      right: -15px;
    }
  }
  .mobile-btn-tree-dots {
    // top: -53px;
    right: 2px;
  }
}
</style>
