<template>
  <div>
    <div
      class="d-flex flex-nowrap overflow-auto custom-scrollbar no-gutters pb-2"
    >
      <div
        v-for="item in stageTypes"
        :key="item.key"
        class="d-flex justify-content-center h-mr-5 stage-type-wrapper"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-start text-center stage-simple-form-card w-100 p-3"
          :class="{
            'cursor-pointer': item.isEnabled && !disabled,
            active: blockType === item.type,
            disabled: isStageFieldDisabled('stageType') || disabled,
          }"
          @click="stageTypeClickHandler(item)"
        >
          <Icon
            :name="item.key"
            :fill="
              errors.first('stage-type')
                ? $scss.dangerColor
                : blockType === item.type
                ? $scss.textColor
                : $scss.heraMuted
            "
            width="32"
            class="stage-simple-form-card-icon mb-3"
          />
          <TextBlock
            :variant="
              errors.first('stage-type')
                ? 'error'
                : blockType === item.type
                ? 'primary'
                : 'secondary'
            "
            :size="0.625"
            tag="div"
            class="stage-simple-form-card-text text-uppercase text-center"
          >
            <strong>
              {{ $$t(item.name, item.key) }}
            </strong>
          </TextBlock>
        </div>
        <TextBlock
          variant="secondary"
          :text="item.description"
          :size="0.625"
          tag="div"
          class="text-center"
        />
      </div>
    </div>
    <div v-if="errors.first('stage-type')" class="row mb-1">
      <div class="col">
        <TextBlock :size="0.75" variant="error" class="col-12 d-block mt-1">
          {{ errors.first('stage-type') }}
        </TextBlock>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import { mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageTypeSetting',
  components: {
    Icon,
    TextBlock,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    ...mapGetters(['getBlocksByStageID']),
    blockTypeModel: {
      get() {
        return this.blockType;
      },
      set(newVal) {
        this.setBlockConfigurationByKey('type', newVal);

        const duration = this.isBattleRoyale ? 3600 : 1800;
        this.setBlockConfigurationByKey('match_duration', duration);

        this.checkRounds(newVal);

        this.errors.remove('stage-type');
        this.errors.remove('bestOf');
        this.errors.remove('bestOfLower');
        this.errors.remove('bestOfFinal');
        this.errors.remove('rounds');
      },
    },
  },
  methods: {
    stageTypeClickHandler({ isEnabled, type }) {
      if (isEnabled && !this.disabled) {
        this.setStageType(type);
      }
    },
    setStageType(type) {
      this.blockTypeModel = type;
      this.clearStageOptions();
    },
    clearStageOptions() {
      this.$validator.pause();
      let configuration =
        this.$lodash.cloneDeep(
          this.currentTournamentStage.block_configuration
        ) || {};
      if (this.isRoundRobin || this.isSwiss) {
        delete configuration.best_of_final;
        delete configuration.match_3rd_place;
        delete configuration.best_of_lower;
        this.$validator.detach('bestOfFinal', null);
        this.$validator.detach('third_place_match', null);
        this.$validator.detach('best_of_lower', null);
      }
      if (this.isSingleElimination) {
        delete configuration.rounds;
        delete configuration.tie_match;
        delete configuration.best_of_lower;
        configuration.best_of_final = 1;
        configuration.match_3rd_place = false;
        this.$validator.detach('rounds', null);
        this.$validator.detach('tie_match', null);
        this.$validator.detach('best_of_lower', null);
      }
      if (this.isDoubleElimination) {
        configuration.best_of_lower = configuration.best_of;
        delete configuration.tie_match;
        delete configuration.match_3rd_place;
        this.$validator.detach('tie_match', null);
        this.$validator.detach('third_place_match', null);
      }
      this.updateCurrentTournamentStage({
        block_configuration: configuration,
      });
      this.$nextTick(() => {
        this.$validator.resume();
      });
    },
    checkRounds(blockType) {
      const options = this.getBlocksByStageID(
        this.currentTournamentStage.id
      ).find(({ type }) => type === blockType);
      if (
        options &&
        options.rounds instanceof Array &&
        !options.rounds.includes(this.rounds)
      ) {
        this.setBlockConfigurationByKey(
          'rounds',
          options.rounds[options.rounds.length - 1]
        );
      }
    },
  },
};
</script>

<style></style>
