import {
  authorizedGetRequest,
  authorizedPostRequest,
  simpleHeadRequest,
  simplePostRequest,
} from '@/api/requests';

const AUTH_BASE = 'auth';

const signIn = simplePostRequest(`${AUTH_BASE}/signin`);
const snsSignIn = simplePostRequest(`${AUTH_BASE}/sns/signin`);
const ssoSignIn = authorizedPostRequest(`${AUTH_BASE}/sso/signin`);
const signUp = simplePostRequest(`${AUTH_BASE}/signup`);
const signOut = authorizedPostRequest(`${AUTH_BASE}/signout`);
const sendCode = authorizedPostRequest(`${AUTH_BASE}/sendcode`);
const getIdentity = authorizedGetRequest(`${AUTH_BASE}/identity/info`, {
  preventCancelRequest: true,
});
const sendCodeToDelete = authorizedPostRequest(
  `${AUTH_BASE}/${AUTH_BASE}/sendcode`
);
const verifyCode = authorizedPostRequest(`${AUTH_BASE}/verify`);
const verifyCodeToDelete = authorizedPostRequest(
  `${AUTH_BASE}/${AUTH_BASE}/verify`
);
const resetPass = simplePostRequest(`${AUTH_BASE}/reset_password`);
const submitPass = authorizedPostRequest(`${AUTH_BASE}/submit_password`);
const deleteProfileRequest = authorizedPostRequest(
  `${AUTH_BASE}/delete_profile/request`
);
const deleteProfileSubmit = authorizedPostRequest(
  `${AUTH_BASE}/delete_profile/submit`
);
const refreshToken = authorizedPostRequest(`${AUTH_BASE}/refresh`);
const switchAccount = authorizedPostRequest(`${AUTH_BASE}/switch`);
const setCredentials = (accessToken, { source, password }) =>
  authorizedPostRequest(`${AUTH_BASE}/credentials/`)(accessToken, {
    source,
    password,
  });
const getCredentials = string =>
  simpleHeadRequest(`${AUTH_BASE}/credentials/${string}`);

export default {
  signIn,
  snsSignIn,
  ssoSignIn,
  signUp,
  signOut,
  sendCode,
  verifyCode,
  sendCodeToDelete,
  verifyCodeToDelete,
  deleteProfileRequest,
  deleteProfileSubmit,
  resetPass,
  submitPass,
  refreshToken,
  switchAccount,
  getCredentials,
  setCredentials,
  getIdentity,
};
