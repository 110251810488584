<template>
  <Picker
    :data="emojiIndex"
    set="apple"
    :show-preview="showPreview"
    :color="$scss.primaryColor"
    :picker-styles="styles"
    :i18n="emojiTranslations"
    @select="$emit('select', $event)"
  />
  <!--          :show-preview="true"-->
  <!--          title="Pick your emoji…"-->
</template>

<script>
// Emoji lib \ styles are in assets
import data from 'emoji-mart-vue-fast/data/all.json';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src';
let emojiIndex = new EmojiIndex(data);

export default {
  name: 'EmojiPicker',
  components: { Picker },
  props: {
    styles: {
      type: Object,
      default: () => ({}),
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emojiIndex: emojiIndex,
    };
  },
  computed: {
    emojiTranslations() {
      return {
        search: this.$t('_web_search_input_default_placeholder', 'Search...'),
        notfound: this.$t('_web_emoji_notfound', 'No Emoji Found'),
        categories: {
          search: this.$t('_web_emoji_category-search', 'Search Results'),
          recent: this.$t('_web_emoji_category-recent', 'Frequently Used'),
          smileys: this.$t('_web_emoji_category-smileys', 'Smileys & Emoticon'),
          people: this.$t('_web_emoji_category-people', 'People & Body'),
          nature: this.$t('_web_emoji_category-nature', 'Animals & Nature'),
          foods: this.$t('_web_emoji_category-foods', 'Food & Drink'),
          activity: this.$t('_web_emoji_category-activity', 'Activity'),
          places: this.$t('_web_emoji_category-places', 'Travel & Places'),
          objects: this.$t('_web_emoji_category-objects', 'Objects'),
          symbols: this.$t('_web_emoji_category-symbols', 'Symbols'),
          flags: this.$t('_web_emoji_category-flags', 'Flags'),
          custom: this.$t('_web_emoji_category-custom', 'Custom'),
        },
      };
    },
  },
};
</script>
