<template>
  <div class="w-100">
    <section class="">
      <form
        :id="`signin_form_${idSuff}`"
        class="remove-autofill-styles d-flex flex-column"
        :autocomplete="autocomplete"
        @submit.prevent="formSubmit"
      >
        <div class="signin-container">
          <div class="text-center">
            <h1 class="font-size-32 m-0">
              {{ $t('_web_sign_in_new', 'Log In To PLAYHERA') }}
            </h1>
          </div>
          <section slot="alternative" class="">
            <div class="row">
              <div class="col text-center">
                <h1 style="font-size: 1rem; font-weight: 500" class="m-0">
                  <span>
                    {{ $$t(`Don’t have an account?`, 'no_account') }}
                  </span>
                  <router-link
                    :to="{
                      name: 'signup',
                    }"
                    tabindex="5"
                    style="
                      color: white;
                      font-weight: 400;
                      text-decoration: underline;
                    "
                  >
                    {{ $t('_web_register', 'Register') }}
                  </router-link>
                </h1>
              </div>
            </div>
          </section>
          <HorizontalTabs
            :tabs="$options.signinTabs"
            :active-tab="activeTab"
            use-custom
            @change="setActiveTab"
          />

          <div class="input-container">
            <div class="text-danger">
              {{
                authError &&
                  $t(
                    '_web_server_errors_incorrect_credentials_email',
                    'Incorrect Email or Password.'
                  )
              }}
            </div>

            <InputPhone
              v-if="signinByPhone"
              ref="phoneInput"
              v-model.trim="phone"
              v-validate="'required|numeric'"
              name="phone"
              :label="$$t('Phone Number', 'phone_input_label')"
              :error="errors.first('phone')"
              :saving-key="saveAuth ? 'last_phone' : null"
              :input-props="{
                autofocus: true,
                tabindex: 1,
              }"
              @input="formChanged"
            />

            <InputEmail
              v-else
              v-model.trim="email"
              v-focus
              v-validate="'required|email|max:128'"
              :name="$$t('Email', 'email-label')"
              type="email"
              :label="$$t('Email', 'email-label')"
              :tabindex="1"
              :placeholder="$t('_web_email_placeholder', 'your@email.com')"
              autofocus
              :autocomplete-off="!saveAuth"
              :error="errors.first($$t('Email', 'email-label'))"
              @input="formChanged"
            />
            <InputPassword
              v-model.trim="password"
              v-validate="'required|max:64'"
              :name="$t('_web_password_placeholder', 'Password')"
              :placeholder="$t('_web_password_placeholder', 'Password')"
              :forgot-pass="$$t('Forgot password?', 'forgot_password_link')"
              :label="$$t('Password', 'password_input_label')"
              :autocomplete-off="!saveAuth"
              :error="errors.first($t('_web_password_placeholder', 'Password'))"
              :input-props="{
                tabindex: 2,
                maxLength: 64,
              }"
              @input="formChanged"
            />

            <HeraFormSubmitBtn
              :tabindex="3"
              :label="$$t('Login', 'submit_button')"
              :loading="isLoading"
              class="new-login-btn"
            />
          </div>

          <div class="row justify-content-center mt-5">
            <GoogleSignIn />
            <TwitterSignIn />
            <AppleSignIn />
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import { prop } from '@/utils/factories';
import InputEmail from '@/components/form/InputEmail';
import InputPassword from '@/components/form/InputPassword.vue';
import HeraFormSubmitBtn from '@/components/form/HeraFormSubmitBtn';
import HorizontalTabs from '@/components/common/HorizontalTabsNew';
import InputPhone from '@/components/form/InputPhoneNew';
// import Checkbox from '@/components/atoms/form/Checkbox';
import isLoadingMixin from '@/mixins/isLoading';
import GoogleSignIn from '@/components/auth/GoogleSignIn';
import TwitterSignIn from '@/components/auth/TwitterSignIn';
import AppleSignIn from '@/components/auth/AppleSignIn';

const SAVING_KEY = 'saveAuth';
const TABS = {
  EMAIL: 'tab_email',
  PHONE: 'tab_phone',
};

export default {
  name: 'SignIn',
  components: {
    InputEmail,
    InputPassword,
    HeraFormSubmitBtn,
    GoogleSignIn,
    TwitterSignIn,
    AppleSignIn,
    HorizontalTabs,
    InputPhone,
    // Checkbox,
  },
  mixins: [isLoadingMixin, translate('_web_signin')],
  props: {
    modal: prop(Boolean),
    sso: prop(Boolean),
  },
  inject: ['$validator'],
  data() {
    const activeTab = window.localStorage.getItem(SAVING_KEY);

    return {
      email: null,
      phone: null,
      password: null,

      activeTab: Object.keys(TABS).includes(activeTab) ? activeTab : TABS.EMAIL,
      saveAuth: !!activeTab,
      uniqId: Date.now(),
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapState('current', ['account', 'accountId']),
    ...mapGetters(['currentLocale', 'locales', 'isRTL']),

    autocomplete() {
      return this.saveAuth ? 'on' : 'new-password';
    },
    idSuff() {
      return this.saveAuth ? 'save' : this.uniqId;
    },
    authError() {
      // please not use errors.firstNot('login')
      const errorsCollect = Object.keys(this.errors.collect());
      if (errorsCollect.some(field => field !== 'login')) {
        return '';
      }

      return this.errors.first('login');
    },
    signinByPhone() {
      return this.activeTab === TABS.PHONE;
    },
  },
  created() {
    if (sessionStorage.getItem('lang')) {
      const locale = this.locales.find(
        lang => lang.key === sessionStorage.getItem('lang')
      );
      if (locale) {
        this.changeLocale(locale);
        sessionStorage.removeItem('lang');
      }
    }
    this.$options.signinTabs = [
      {
        key: TABS.EMAIL,
        labelStated: this.$$t('Email', 'tab_email'),
      },
      {
        key: TABS.PHONE,
        labelStated: this.$$t('Phone number', 'tab_phone'),
      },
    ];
  },
  methods: {
    ...mapActions(['authRequest']),

    ...mapMutations(['setLocale']),
    /**
     * open popover-menu for switch
     */
    togglePopover() {
      this.langPopoverPosition = this.popoverIsVisible
        ? null
        : this.$refs.chosenLocale.getBoundingClientRect();
    },
    /**
     * check if popover is visible
     */
    togglePopoverVisible(isVisible) {
      this.popoverIsVisible = isVisible;
    },
    /**
     * change locale
     * @param locale {Object} - new locale
     */
    changeLocale(locale) {
      this.langPopoverPosition = null;

      if (locale) {
        this.setLocale(locale);
        this.$i18n.set(locale.key);
        this.$validator.localize(locale.key);
      }
      this.$emit('localChanged', locale.key);
    },
    formSubmit() {
      if (!this.errors.count()) {
        this.setLoading();
        this.$validator
          .validateAll()
          .then(
            result =>
              result
                ? Promise.resolve({
                    login: this.signinByPhone ? `+${this.phone}` : this.email,
                    password: this.password,
                    scopes: 'application,administrative',
                  })
                : Promise.reject() // eslint-disable-line
          )
          .then(this.authRequest)
          .then(() => {
            if (this.saveAuth && this.signinByPhone) {
              this.$refs.phoneInput.savePhone();
            }
            const path = this.$route.query.redirectTo || '/';
            if (!this.sso) {
              this.$router.push({ path });
            } else {
              this.$emit('authorized');
            }
          })
          .then(
            window.dataLayer.push({
              event: 'login',
              email: this.email,
              phone: this.phone,
              from: this.$route.path,
            })
          )
          .catch(err => {
            this.unsetLoading();
            const msg = err && err.message;

            if (!msg) {
              return;
            }

            this.errors.add({
              field: 'login',
              msg,
            });
          });
      }
    },
    formChanged() {
      this.errors.clear();
      this.$validator.validate(this.signinByPhone ? 'phone' : 'Email');
    },
    toggleSaveAuth(isChecked) {
      if (isChecked) {
        window.localStorage.setItem(SAVING_KEY, this.activeTab);
      } else {
        window.localStorage.removeItem(SAVING_KEY);
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss">
.signin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.input-container {
  width: 25vw;
  min-width: 380px;
  max-width: 25rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
  .float-dropbox.show {
    flex-direction: column;
    margin: 5px !important;
  }
  .float-dropbox.show .input-group .form-control {
    background-color: $new-bg-base !important;
  }
  .input-group-prepend {
    background-color: $new-bg-base;
    z-index: 9999;
    border-radius: 0px;
  }
  .input-search .form-control {
    background-color: $new-bg-base !important;
    z-index: 9999;
    border-radius: 0px;
  }
  .playhera-input .input-group {
    border-radius: 0px;
  }
  .input-search.focused {
    border-bottom: 1px solid $hr-text-secondary;
  }
}
.new-login-btn {
  width: 100%;
  height: 2.5rem;
  background-color: $new-bg-primary !important;
  border-radius: 0.5rem;
  border: none;
}
</style>
