import { render, staticRenderFns } from "./TextLabel.vue?vue&type=template&id=26f39857&"
import script from "./TextLabel.vue?vue&type=script&lang=js&"
export * from "./TextLabel.vue?vue&type=script&lang=js&"
import style0 from "./TextLabel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TextLabel.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2FText&name=TextLabel&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Text'%2C%20'Text%20for%20label')%0A%20%20%20%20%7D%2C%0A%20%20%20%20forId%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('forId'%2C%20'id')%0A%20%20%20%20%7D%2C%0A%20%20%20%20required%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('required'%2C%20false)%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports