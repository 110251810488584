const state = {
  profileOpen: false,
  notificationOpen: false,
  guestOpen: false,
};

const getters = {
  profileOpen: state => state.profileOpen,
  notificationOpen: state => state.notificationOpen,
  guestOpen: state => state.guestOpen,
};

const mutations = {
  setProfileOpen(state, profileOpen) {
    state.profileOpen = !profileOpen;
    state.notificationOpen === true
      ? (state.notificationOpen = false)
      : state.notificationOpen;
  },
  setNotificationOpen(state, notificationOpen) {
    state.notificationOpen = !notificationOpen;
    state.profileOpen === true
      ? (state.profileOpen = false)
      : state.profileOpen;
  },
  setGuestOpen(state, guestOpen) {
    state.guestOpen = !guestOpen;
  },
};

const actions = {
  setProfileOpen({ commit }, profileOpen) {
    commit('setProfileOpen', profileOpen);
  },
  setNotificationOpen({ commit }, notificationOpen) {
    commit('setNotificationOpen', notificationOpen);
  },
  setGuestOpen({ commit }, guestOpen) {
    commit('setGuestOpen', guestOpen);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
