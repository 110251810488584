var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.columns ? 'row' : ''},_vm._l((_vm.items),function(social,index){return _c('InputSocial',{key:("social_" + (social.id) + "_" + index),staticClass:"social-item",class:_vm.columns ? ("col-" + ((12 / _vm.columns) | 0)) : '',attrs:{"label":_vm.$t('_web_account-edit_social-input-label', '{socialName} link', {
        socialName: social.title,
      }),"placeholder":social.fixedPlaceholder
        ? ''
        : _vm.$t(
            '_web_account-edit_social-${social.id}_placeholder',
            "Enter your {socialTitle} ID",
            { socialTitle: social.title }
          ),"icon":social.id,"value":_vm.value[social.id],"input-left-padding":social.inputLeftPadding,"fixed-placeholder":social.fixedPlaceholder},on:{"input":function (event) { return _vm.inputSocial(social.id, event); }}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }