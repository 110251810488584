<template>
  <div class="pt-attrib position-relative">
    <ul class="pt-attrib__list d-flex flex-wrap p-0">
      <li
        v-for="(attribute, index) in realAttributes"
        :key="attribute.id"
        class="pt-attrib__list-item d-flex h-mr-3"
      >
        <AttributeTag
          :name="$$t(attribute.name)"
          :color="$scss.greenBg"
          @delete="delAttribute(index)"
        />
      </li>
      <li
        v-if="availableAttributes.length"
        class="pt-attrib__list-item d-flex align-content-center"
      >
        <Icon
          name="add"
          size="20"
          class="cursor-pointer"
          @click="togglePopover"
        />
      </li>
    </ul>

    <PopoverMenu
      class="pt-attrib__menu"
      :position="popoverPos"
      :float="['right']"
      :items="availableAttributes"
      :closest="$el"
      @toggle="popoverToggled"
    >
      <span slot-scope="{ item }" @click.stop="addAtribute(item)">
        {{ $$t(item.name) }}
      </span>
    </PopoverMenu>
  </div>
</template>

<script>
import { uniqBy, xorBy } from '@/utils/lodashUtils';

import { translate } from '@/mixins/translate';

import Icon from '@/components/atoms/Icon';
import AttributeTag from '@/components/atoms/AttributeTag';
import PopoverMenu from '@/components/popover/Menu';

export default {
  name: 'PlayersTableAttributesCell',

  components: {
    Icon,
    AttributeTag,
    PopoverMenu,
  },

  mixins: [translate('_web_tournament_players-attribute')],

  props: {
    attributes: {
      required: true,
      type: null,
      default: '',
    },
    allAttributes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      popoverPos: null,
      realAttributes: [],
      hasChange: false,
    };
  },

  computed: {
    availableAttributes() {
      if (!this.realAttributes.length) return this.allAttributes;
      if (this.allAttributes.length === this.realAttributes.length) return [];
      return xorBy(this.allAttributes, this.realAttributes, 'id');
    },
  },

  watch: {
    availableAttributes(newAvailableAttributes) {
      if (!newAvailableAttributes.length) {
        this.popoverPos = null;
      }
    },
    attributes() {
      this.fillRealAttributes();
    },
  },

  mounted() {
    this.fillRealAttributes();
  },

  methods: {
    togglePopover(e) {
      this.popoverPos = this.popoverPos ? null : e.target;
    },
    addAtribute(attribute) {
      this.hasChange = true;
      this.realAttributes.push(attribute);
    },
    delAttribute(index) {
      this.realAttributes.splice(index, 1);
      this.emitChanges();
    },
    popoverToggled(val) {
      if (!val && this.hasChange) {
        this.hasChange = false;
        this.emitChanges();
      }
    },
    emitChanges() {
      this.popoverPos = null;
      this.$emit(
        'attributesSet',
        this.realAttributes.map(attribute => attribute.id)
      );
    },
    fillRealAttributes() {
      //Now the server response has string, but this can be fixed in the future
      let attributeIdList = [];
      if (typeof this.attributes === 'string') {
        attributeIdList = this.attributes ? this.attributes.split(',') : [];
      } else if (this.attributes instanceof Array) {
        attributeIdList = this.attributes;
      }
      const attributes = [];
      attributeIdList.forEach(attributeId => {
        const attribute = this.allAttributes.find(
          ({ id }) => id === attributeId
        );
        if (attribute) {
          attributes.push(attribute);
        }
      });
      this.realAttributes = uniqBy(
        [...this.realAttributes, ...attributes],
        'id'
      );
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.pt-attrib {
  max-width: 250px;
  &__list {
    margin-bottom: -$ph-small-space;
  }
  &__list-item {
    padding-bottom: $ph-small-space;
  }
}
</style>
