<template>
  <SegmentControl
    v-bind="{
      id: inputID,
      options: [
        {
          id: 'yes',
          label: falseLabel || $$t('Yes'),
          value: falseValue,
        },
        {
          id: 'no',
          label: trueLabel || $$t('No'),
          value: trueValue,
        },
      ],
      required,
      errorInTooltip,
      error,
      label,
      tooltip,
      readOnly,
      value,
      disabled,
    }"
    v-on="$listeners"
  />
</template>

<script>
import { prop } from '@/utils/factories';
import { translate } from '@/mixins/translate';
import SegmentControl from '@/components/atoms/form/HeraFormSegmentControl';

export default {
  name: 'BSwitch',
  components: {
    SegmentControl,
  },
  mixins: [translate('_web_components_switch')],
  props: {
    value: prop(
      [String, Number, Boolean, Function, Object, Array, Symbol],
      null
    ),
    label: prop(String),
    name: prop(String, ''),
    error: prop(String, ''),
    errorInTooltip: prop(Boolean, false),
    readOnly: prop(Boolean, false),
    disabled: prop(Boolean, false),
    required: prop(Boolean, false),
    tooltip: prop(String, ''),

    trueLabel: prop(String),
    falseLabel: prop(String),

    trueValue: prop(
      [String, Number, Boolean, Function, Object, Array, Symbol],
      true
    ),
    falseValue: prop(
      [String, Number, Boolean, Function, Object, Array, Symbol],
      false
    ),
  },
  computed: {
    inputID() {
      return `InputField_${this.id || this.$lodash.uniqueId('id_')}`;
    },
  },
};
</script>
