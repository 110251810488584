<template>
  <div class="" :class="{ 'loader-mask': isProcessing }">
    <div v-if="isProcessing" class="" />
    <div class="subplan-hero">
      <div class="subplan-hero-outer-wrap">
        <div class="subplan-hero-wrapper">
          <div class="subplan-hero-left">
            <span ref="cloudGame" class="cloud-gaming">{{
              $t('_web_phmax_cloud_gaming', 'CLOUD GAMING')
            }}</span>
            <span class="playhera-max">{{
              $t('_web_phmax_title', 'PLAYHERA MAX')
            }}</span>
            <div class="hero-copy">
              <span>{{
                $t('_web_phmax_run_pc_games', 'Run PC games on any device.')
              }}</span>
              <span>{{
                $t('_web_phmax_no_downloads', 'No downloads! Play in browser!')
              }}</span>
            </div>
            <div class="settings-prices">
              <span>
                <img
                  src="@/assets/images/phmax-assets/green arrows.svg"
                  alt="Settings High"
                  class="settings-high"
                />
                {{ $t('_web_phmax_high', 'Settings High') }}
              </span>
              <span>
                <img
                  src="@/assets/images/phmax-assets/purple arrows.svg"
                  alt="Prices Low"
                  class="prices-low"
                />
                {{ $t('_web_phmax_low', 'Prices Low') }}
              </span>
            </div>
            <Spinner v-if="isLoading" class="spinner-middle" />
            <div class="buttons">
              <button @click="redirectOpener">
                {{ $t('_web_phmax_play_now', 'PLAY NOW') }}
                <img src="@/assets/images/phmax-assets/phmax-play.svg" alt="" />
              </button>
              <button @click="handleRedirectDirectly">
                {{ $t('_web_phmax_learn_more', 'Learn more') }}
              </button>
            </div>
            <!-- <span class="from-price">
              {{ $t('_web_phmax_copy_from', 'From') }}
              <span>{{
                subscriptions &&
                  fromPrice + ' ' + $t('_web_subscription_sar', 'SAR')
              }}</span>
              <br />
              {{
                ('_web_phmax_copy_per_month',
                'per month with annual subscription')
              }}
            </span> -->
          </div>
          <div class="subplan-hero-right">
            <img
              src="@/assets/images/phmax-assets/Graphic-ATF.png"
              class="subplan-img"
            />
          </div>
        </div>
      </div>
    </div>

    <div ref="power-up" class="power-up">
      <div class="subplan-power-wrapper">
        <div class="power-left">
          <span class="power-up-head">{{
            $t('_web_phmax_power_up', 'Power up your device')
          }}</span>
          <img
            src="@/assets/images/phmax-assets/Graphic-Power-Up.png"
            alt="Power up your device"
            class="power-img"
          />
        </div>
        <div class="power-right">
          <div class="your-games">
            <div class="your-games-pic your-games-pic-new">
              <img
                src="@/assets/images/phmax-assets/icon - cloud_sync.svg"
                alt="Sync icon"
              />
            </div>
            <div>
              <div class="your-games-title">
                <span>{{
                  $t('_web_phmax_your_games_our_power', 'Your Games, Our Power')
                }}</span>
              </div>
              <div>
                <span>
                  {{
                    $t(
                      '_web_phmax_your_games_our_power_text',
                      'Games launch on our high-end remote gaming rigs, gameplay is streamed to you. Enjoy hundreds of titles from popular platforms.'
                    )
                  }}
                </span>
              </div>
              <div class="your-games-logos">
                <img
                  src="@/assets/images/phmax-assets/logos.svg"
                  alt="Sync icon"
                />
                <span class="your-games-already">{{
                  $t(
                    '_web_phmax_launch_already_own',
                    '*Launch the games you already own'
                  )
                }}</span>
              </div>
            </div>
          </div>
          <div ref="powerMobile" class="no-downloads no-downloads-new">
            <div class="your-games-pic no-downloads-pic">
              <img
                src="@/assets/images/phmax-assets/icon-file_download_off.svg"
                alt="No Downloads"
              />
            </div>
            <div>
              <div class="your-games-title">
                <span>{{
                  $t(
                    '_web_phmax_no_downloads_updates',
                    'No Downloads, No updates'
                  )
                }}</span>
              </div>
              <div>
                <span>{{
                  $t(
                    '_web_phmax_no_downloads_updates_text',
                    'No downloads, storage and updates of game files. Your job is to game, we take care of the rest.'
                  )
                }}</span>
              </div>
            </div>
          </div>
          <div ref="myElement" class="no-downloads no-session">
            <div class="your-games-pic">
              <img
                src="@/assets/images/phmax-assets/icon - timer_off.svg"
                alt="No Downloads"
              />
            </div>
            <div>
              <div class="your-games-title">
                <span>{{
                  $t('_web_phmax_no_session_time', 'No session Time Limits')
                }}</span>
              </div>
              <div>
                <span>{{
                  $t(
                    '_web_phmax_no_session_time_text',
                    'Instant access to games and unlimited gaming sessions. Play as long as you want!'
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="plenty-main">
      <div class="plenty-wrapper">
        <div>
          <div class="plenty-title">
            <span>{{
              $t('_web_phmax_plenty_cool', 'Plenty of Cool stuff')
            }}</span>
          </div>
        </div>
        <div class="plenty-cards">
          <div class="plenty-cool">
            <img
              src="@/assets/images/phmax-assets/headset_mic.svg"
              alt="No Downloads"
              class="plenty-img"
            />
            <span ref="plentyRef" class="plenty-subheader">{{
              $t('_android_support', 'Support')
            }}</span>
            <span v-if="!isRTL" class="plenty-text">{{
              $t('_web_phmax_online_chat', 'Online chat') +
                ', ' +
                $t('_web_phmax_online_email', 'email support')
            }}</span>
            <span
              v-if="isRTL"
              class="plenty-text"
              :class="{ 'mobile-support': isRTL }"
            >
              {{ $t('_web_phmax_online_chat', 'Online chat') }}
              <br />
              {{ $t('_web_phmax_online_email', 'email support') }}
            </span>
          </div>
          <div class="plenty-cool">
            <img
              src="@/assets/images/phmax-assets/devices.svg"
              alt="No Downloads"
              class="plenty-img"
            />
            <span ref="plentyRefMobile" class="plenty-subheader">{{
              $t('_web_phmax_switching_devices', 'Switching devices')
            }}</span>
            <span class="plenty-text">{{
              $t('_web_phmax_play_any_device', 'Play on any device')
            }}</span>
          </div>
          <div class="plenty-cool">
            <img
              src="@/assets/images/phmax-assets/video_library.svg"
              alt="No Downloads"
              class="plenty-img"
            />
            <span class="plenty-subheader">{{
              $t('_web_phmax_gameplay_streaming', 'Gameplay Streaming')
            }}</span>
            <span class="plenty-text youtube-plenty">{{
              $t('_web_phmax_stream_youtube', 'Stream your gaming to YouTube')
            }}</span>
            <span class="plenty-text">{{
              $t('_web_phmax_upcoming', '*upcoming')
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="platforms-features-container">
      <div class="platforms-features-wrapper">
        <div class="subplan-platforms-container">
          <div class="subplan-platforms">
            <div class="subplan-platforms-wrapper">
              <span class="subplan-platforms-title">{{
                $t('_web_phmax_unlimited_aaa', 'Unlimited AAA Games')
              }}</span>
              <span class="subplan-platforms-subtitle">
                {{
                  $t(
                    '_web_phmax_run_devices',
                    'Run PC games on low-end, outdated or ultra portable devices!'
                  )
                }}
              </span>
              <span class="subplan-platforms-subtitle">{{
                $t(
                  '_web_phmax_run_devices_play',
                  'Play in Browser, Desktop, TV and Mobile'
                )
              }}</span>

              <div class="platforms-table">
                <div class="table-buttons">
                  <button
                    :class="{ 'tab-active': currentTab === 'Browser' }"
                    @click="currentTab = 'Browser'"
                  >
                    {{ $t('_web_phmax_browser', 'Browser') }}
                  </button>
                  <button
                    class="tv-button"
                    :class="{ 'tab-active': currentTab === 'TV' }"
                    @click="currentTab = 'TV'"
                  >
                    <span>{{ $t('_web_phmax_tv', 'TV') }}</span>
                    <span class="coming-soon-tm">{{
                      $t('_web_phmax_coming_soon', 'Coming soon!')
                    }}</span>
                  </button>
                  <button
                    :class="{ 'tab-active': currentTab === 'Mobile' }"
                    @click="currentTab = 'Mobile'"
                  >
                    {{ $t('_web_phmax_mobile_app', 'Mobile App') }}
                  </button>
                </div>
                <div class="tab-container">
                  <div v-if="currentTab === 'Browser'" class="browser-tab">
                    <div class="browser-left">
                      <span>
                        <img
                          src="@/assets/images/phmax-assets/browser-1.svg"
                          alt=""
                        />
                        {{
                          $t(
                            '_web_phmax_browser_log_in',
                            'Log in to your PLAYHERA account'
                          )
                        }}
                      </span>
                      <span>
                        <img
                          src="@/assets/images/phmax-assets/browser-2.svg"
                          alt=""
                        />
                        {{
                          $t(
                            '_web_phmax_browser_subscribe_play',
                            'Subscribe to PLAYHERA Max'
                          )
                        }}
                      </span>
                      <span>
                        <img
                          src="@/assets/images/phmax-assets/browser-3.svg"
                          alt=""
                        />
                        {{
                          $t(
                            '_web_phmax_browser_pick_play',
                            'Pick a game and play!'
                          )
                        }}
                      </span>
                    </div>
                    <div class="browser-right">
                      <span>
                        {{
                          $t(
                            '_web_phmax_browser_supported_all',
                            'Supported on all browsers'
                          )
                        }}
                      </span>
                      <div class="browsers">
                        <img
                          src="@/assets/images/phmax-assets/safari-logo.svg"
                          alt=""
                        />
                        <img
                          src="@/assets/images/phmax-assets/moz-logo.svg"
                          alt=""
                        />
                        <img
                          src="@/assets/images/phmax-assets/chrome-logo.svg"
                          alt=""
                        />
                        <img
                          src="@/assets/images/phmax-assets/opera-logo.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else-if="currentTab === 'TV'" class="tv-tab">
                    <div class="tv-left">
                      <div class="tv-left-inner">
                        <span>{{
                          $t('_web_phmax_tv_mirror', 'Mirror games on your TV')
                        }}</span>
                        <span>{{
                          $t('_web_phmax_coming_soon', 'Coming soon!')
                        }}</span>
                      </div>
                      <span>
                        {{
                          $t(
                            '_web_phmax_tv_without_console',
                            'Enjoy PC games on TV without a console. Grab a gamepad and play on a big screen!'
                          )
                        }}
                      </span>
                    </div>
                    <div class="tv-right">
                      <img
                        src="@/assets/images/phmax-assets/icons8-tv.svg"
                        alt=""
                      />
                      <div class="">
                        <span>{{
                          $t('_web_phmax_tv_mirror_on', 'Mirror screen on')
                        }}</span>
                        <span>{{
                          $t('_web_phmax_tv_player', 'TV player')
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mobile-tab">
                    <div class="mobile-left">
                      <span>{{
                        $t(
                          '_web_phmax_mobile_app_smartphone',
                          'PC games on a smartphone?'
                        )
                      }}</span>
                      <span>
                        {{
                          $t(
                            '_web_phmax_mobile_app_why_not',
                            'Why not! Play wherever you are no matter if you don’t have a computer around.'
                          )
                        }}
                      </span>
                    </div>
                    <div class="mobile-right">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.hera&hl=en&gl=US"
                        class="app-link"
                        target="_blank"
                      >
                        <div class="mobile-platform">
                          <img
                            src="@/assets/images/phmax-assets/Isolation_Mode.svg"
                            alt=""
                          />
                          <div class="">
                            <span>{{
                              $t('_web_phmax_download_for', 'Download for')
                            }}</span>
                            <span>{{
                              $t(
                                '_web_phmax_download_for_android',
                                'Android Mobile'
                              )
                            }}</span>
                          </div>
                        </div>
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/playhera/id1449021935"
                        class="app-link"
                        target="_blank"
                      >
                        <div class="mobile-platform">
                          <img
                            src="@/assets/images/phmax-assets/Layer_1.svg"
                            alt=""
                          />
                          <div class="">
                            <span>{{
                              $t('_web_phmax_download_for', 'Download for')
                            }}</span>
                            <span>{{
                              $t('_web_phmax_download_for_ios', 'iOS Mobile')
                            }}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="core-features">
          <div class="core-layout">
            <div class="core-title">
              <span> {{ $t('_web_phmax_core', 'Core features') }}</span>
            </div>
            <div class="core-wrap">
              <div ref="coreRef" class="core-left">
                <span class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t(
                      '_web_phmax_core_access',
                      'Access to the whole game catalog'
                    )
                  }}</span
                >
                <div>
                  <div class="bottom-line"></div>
                </div>
                <span class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t(
                      '_web_phmax_core_unlimited',
                      'Unlimited gaming session time'
                    )
                  }}</span
                >
                <div>
                  <div class="bottom-line"></div>
                </div>
                <span class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t(
                      '_web_phmax_core_1080',
                      '1080p 60 fps and more coming soon'
                    )
                  }}
                </span>
                <div>
                  <div class="bottom-line"></div>
                </div>
                <span ref="coreRefMobile" class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t(
                      '_web_phmax_core_compatible',
                      'Compatible with many popular gamepads (Xbox Series X|S, Xbox One, Stadia, PS5, PS4, etc.)'
                    )
                  }}</span
                >
              </div>
              <div>
                <div class="bottom-line bottom-line-mobile"></div>
              </div>
              <div class="core-right">
                <span class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t(
                      '_web_phmax_core_access_compatible',
                      'Access to games on all compatible devices (PC, Mac, laptop, tablet, phone or TV)'
                    )
                  }}</span
                >
                <div>
                  <div class="bottom-line"></div>
                </div>
                <span class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t(
                      '_web_phmax_core_secure',
                      'Everything is secure, we dont share your data with anyone'
                    )
                  }}</span
                >
                <div>
                  <div class="bottom-line"></div>
                </div>
                <span class="core-text">
                  <img
                    src="@/assets/images/phmax-assets/checkmark.svg"
                    alt="No Downloads"
                    class="core-img"
                  />
                  {{
                    $t('_web_phmax_core_lockin', 'No lock-in contract, no ads')
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subplans-container">
      <div class="subplans-outer-wrapper">
        <div class="subplans-wrapper">
          <div v-if="!guestSubscriptions.length">
            <div v-if="!filteredSubscriptions.length" ref="plans">
              <div class="subplan-no-wrapper">
                <div class="subplans-top">
                  <span v-if="account">
                    {{ $t('_web_phmax_plan', 'Choose your plan') }}
                  </span>
                </div>
                <div v-if="account" class="subplan-no-country">
                  {{
                    $t(
                      '_web_subscription_no_plans_country',
                      'Unfortunately, the service is not available in {country}',
                      {
                        country: account.country,
                      }
                    )
                  }}
                </div>
                <div v-else class="subplan-no-country">
                  <div>
                    {{
                      $t(
                        '_web_subscription_no_plans_loggedOut',
                        'This service is currently available in Saudi Arabia, Kuwait, Bahrain, Egypt, Jordan, Oman.'
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!isAuthorized && guestSubscriptions.length"
            ref="plans"
            class=""
          >
            <div class="subplans-top">
              <span>
                {{ $t('_web_phmax_plan-explore', 'Explore plans') }}
              </span>
              <span class="subplan-no-country" style="margin-top: 0rem">{{
                $t(
                  '_web_subscription_no_plans_loggedOut',
                  'This service is currently available in Saudi Arabia, Kuwait, Bahrain, Egypt, Jordan, Oman.'
                )
              }}</span>
            </div>
            <div class="dropdown-positioner">
              <ShopDropdown
                v-model="selectedRegion"
                :list="locales"
                :empty-text="$t('_web_shop_selectt', 'Select')"
                :label="$t('_web_shop_label_region', 'Region')"
                style="width: fit-content"
                class="lg-price-picker"
              >
                <template slot="item" slot-scope="options">
                  <div class="chevron-divider">
                    <span>
                      {{ options.item }}
                    </span>
                    <icon
                      name="chevron-down-small"
                      width="16"
                      class="ml-2 mr-2"
                    />
                  </div>
                </template>
              </ShopDropdown>
            </div>

            <div class="subplan-items">
              <div
                v-for="(plan, index) in guestSubscriptions"
                :key="index"
                :class="{ 'active-plan': plan.isActive }"
                class="subplan-item"
              >
                <img
                  v-if="plan && plan.isRecommended"
                  src="@/assets/images/phmax-assets/bookmark.svg"
                  alt="No Downloads"
                  class="bookmark-icon"
                />
                <div class="subplan-header">
                  <span class="subplan-name">
                    {{ $$t(plan.name) }}
                  </span>
                  <!-- <div class=""></div>
              <div v-if="plan.amountMonthly" class=""></div> -->
                  <span v-if="plan.discount > 0" class="subplan-discount">
                    -{{ plan.discount }}%
                  </span>
                </div>

                <div class="subplan-price-wrapper">
                  <div class="subplan-currency-price">
                    <span>
                      {{ $$t(plan.currency) }}
                    </span>
                    <span class="subplan-price-total">
                      {{
                        calcPriceWithDiscount(
                          plan.amountMonthly,
                          plan.discount
                        ).toFixed(2)
                      }}
                    </span>
                  </div>
                  <div class="subplan-price-occur">
                    <span class="">/</span>
                    <span class="">
                      {{ $t('_web_phmax_month_abbrv', 'm') }}
                    </span>
                  </div>
                </div>

                <div class="subplan-strikethrough">
                  <div v-if="plan.discount > 0" class="">
                    <span
                      v-if="plan.discount"
                      style="text-decoration: line-through"
                      class="subplan-discount-strike"
                    >
                      {{ plan.amount + plan.currency }}
                    </span>
                    {{
                      calcPriceWithDiscount(plan.amount, plan.discount).toFixed(
                        2
                      ) +
                        ' ' +
                        plan.currency
                    }}
                  </div>

                  <div v-if="plan.discount === 0" class="">
                    <span
                      v-if="plan.discount"
                      style="text-decoration: line-through"
                      class="subplan-discount-strike"
                    >
                      {{ plan.amount + plan.currency }}
                    </span>
                    {{
                      calcPriceWithDiscount(plan.amount, plan.discount).toFixed(
                        2
                      ) +
                        ' ' +
                        plan.currency
                    }}
                  </div>
                </div>

                <div class="subplan-bottom">
                  <button
                    v-if="!plan.isActive"
                    class="subplan-subscribe-button"
                    @click="pay(plan)"
                  >
                    {{ $t('_web_subscription_subscribe', 'Subscribe') }}
                  </button>
                  <div v-else class="current-plan-text">
                    {{ $t('_web_subscription_current_plan', 'Current plan') }}
                  </div>

                  <div class="subplan-description-wrap">
                    <span class="subplan-description">
                      {{ plan.description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div ref="plan" class="subplans-additional-description-wrap">
              <span class="subplans-additional-description">
                {{
                  $t(
                    '_web_subplans_additional_description',
                    'The service is provided in Saudi Arabia in beta. Prices in SAR equivalent'
                  )
                }}
              </span>
            </div>
          </div>
          <div v-else ref="plans" class="">
            <div class="subplans-top">
              <span>
                {{ $t('_web_phmax_plan', 'Choose your plan') }}
              </span>
              <span>{{
                $t('_web_phmax_plan_sub_play', 'Subscribe and Play')
              }}</span>
              <div class="voucher-reedem">
                <HeraButton
                  v-if="!codeInputActive"
                  class="redeem_btn_sub"
                  :label="$t('_web_redeem_voucher', 'Redeem Voucher')"
                  @click="voucherRedeemActive"
                />
                <div v-else class="voucher-input-wrap">
                  <HeraInput
                    v-model="voucherCode"
                    :label="$t('_web_redeem_voucher', 'Redeem Voucher')"
                    class="voucher-input"
                    :placeholder="
                      $t('_web_voucher-enter-placeholder', 'Enter voucher')
                    "
                  />
                  <HeraButton
                    class="redeem-enter-btn"
                    :label="$t('_web_voucher_enter', 'Enter')"
                    :disabled="!voucherCode"
                    @click="voucherRedeem"
                  />
                </div>
              </div>
            </div>
            <div class="subplan-items">
              <div
                v-for="(plan, index) in filteredSubscriptions"
                :key="index"
                :class="{ 'active-plan': plan.isActive }"
                class="subplan-item"
              >
                <img
                  v-if="plan && plan.isRecommended"
                  src="@/assets/images/phmax-assets/bookmark.svg"
                  alt="No Downloads"
                  class="bookmark-icon"
                />
                <div class="subplan-header">
                  <span class="subplan-name">
                    {{ $$t(plan.name) }}
                  </span>
                  <!-- <div class=""></div>
              <div v-if="plan.amountMonthly" class=""></div> -->
                  <span v-if="plan.discount > 0" class="subplan-discount">
                    -{{ plan.discount }}%
                  </span>
                </div>

                <div class="subplan-price-wrapper">
                  <div class="subplan-currency-price">
                    <span>
                      {{ $$t(plan.currency) }}
                    </span>
                    <span class="subplan-price-total">
                      {{
                        calcPriceWithDiscount(
                          plan.amountMonthly,
                          plan.discount
                        ).toFixed(2)
                      }}
                    </span>
                  </div>
                  <div class="subplan-price-occur">
                    <span class="">/</span>
                    <span class="">
                      {{ $t('_web_phmax_month_abbrv', 'm') }}
                    </span>
                  </div>
                </div>

                <div class="subplan-strikethrough">
                  <div v-if="plan.discount > 0" class="">
                    <span
                      v-if="plan.discount"
                      style="text-decoration: line-through"
                      class="subplan-discount-strike"
                    >
                      {{ plan.amount + plan.currency }}
                    </span>
                    {{
                      calcPriceWithDiscount(plan.amount, plan.discount).toFixed(
                        2
                      ) +
                        ' ' +
                        plan.currency
                    }}
                  </div>

                  <div v-if="plan.discount === 0" class="">
                    <span
                      v-if="plan.discount"
                      style="text-decoration: line-through"
                      class="subplan-discount-strike"
                    >
                      {{ plan.amount + plan.currency }}
                    </span>
                    {{
                      calcPriceWithDiscount(plan.amount, plan.discount).toFixed(
                        2
                      ) +
                        ' ' +
                        plan.currency
                    }}
                  </div>
                </div>

                <div class="subplan-bottom">
                  <button
                    v-if="!plan.isActive"
                    class="subplan-subscribe-button"
                    @click="pay(plan)"
                  >
                    {{ $t('_web_subscription_subscribe', 'Subscribe') }}
                  </button>
                  <div v-else class="current-plan-text">
                    {{ $t('_web_subscription_current_plan', 'Current plan') }}
                  </div>

                  <div class="subplan-description-wrap">
                    <span class="subplan-description">
                      {{ plan.description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div ref="plan" class="subplans-additional-description-wrap">
              <span class="subplans-additional-description">
                {{
                  $t(
                    '_web_subplans_additional_description',
                    'The service is provided in Saudi Arabia in beta. Prices in SAR equivalent'
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="partnerLogo" class="partners-container">
      <div class="partners-wrapper">
        <span>{{ $t('_web_phmax_our_partners', 'OUR PARTNERS') }}</span>
        <img :src="partnerLogo" alt="partner logo" class="partner-logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { translate } from '@/mixins/translate';
import flagsmithMixin from '@/mixins/flagsmithMixin';

import { SSO_CLIENT_ID } from '@/api/config';
import Spinner from '@/views/main/components/Spinner.vue';
import ShopDropdown from '../shop/components/ShopDropdown.vue';
import icon from '@/components/atoms/Icon';

import anime from 'animejs';
import HeraButton from '@/components/atoms/HeraFormBtn';
import HeraInput from '@/components/atoms/form/HeraFormTextInput';
export default {
  name: 'SubscriptionPlans',

  components: { Spinner, ShopDropdown, icon, HeraButton, HeraInput },
  mixins: [translate('_web_subscription'), flagsmithMixin],

  data() {
    return {
      checkout: null,
      currentPlan: null,
      isLoading: false,
      isProcessing: false,
      currentTab: 'Browser',
      currentPlanId: null,
      hasActivePlan: false,
      activePlan: null,
      allPlans: [],
      externalPlan: null,
      codeInputActive: false,
      voucherCode: null,
      guestPlans: [],
      locales: [],
      selectedRegion: '',
      regionNames: [
        'Saudi Arabia',
        'Oman',
        'Kuwait',
        'Bahrain',
        'Egypt',
        'Jordan',
      ],
    };
  },
  computed: {
    ...mapGetters(['phMaxLink', 'subscriptions', 'isRTL', 'isAuthorized']),
    ...mapState('my', ['account']),
    filteredSubscriptions() {
      return this.subscriptions.filter(
        s => s.activeTo || s.nextPaymentOn || s.enabled
      );
    },
    guestSubscriptions() {
      return this.guestPlans.map(item => item);
    },

    ActiveExternalPlan() {
      return this.externalPlan &&
        this.externalPlan.status &&
        this.externalPlan.status !== 'inactive'
        ? true
        : false;
    },

    canSubscribe() {
      return !this.filteredSubscriptions.find(s => s.isActive);
    },
    trialAvailable() {
      //TODO need proper API call to check if this user is used Trial
      //Temporary check
      return this.trialSubscription && this.canSubscribe;
    },
    trialSubscription() {
      return this.filteredSubscriptions.find(s => s.hasTrial) || null;
    },
    trialDays() {
      return this.trialSubscription ? this.trialSubscription.trialDays : 14;
    },
    partnerLogo() {
      const region = JSON.parse(localStorage.getItem('region'));
      return region.logo;
    },
    fromPrice() {
      let cheapestPlan;
      if (this.subscriptions) {
        cheapestPlan = Math.min(
          ...this.subscriptions.map(item =>
            this.calcPriceWithDiscount(item.amountMonthly, item.discount)
          )
        );
      }

      return cheapestPlan.toFixed(2);
    },
  },
  watch: {
    selectedRegion(newRegion, oldRegion) {
      if (newRegion !== oldRegion) {
        const regionCode = this.getRegionCode(newRegion);
        this.fetchSubscriptionsGuest(regionCode)
          .then(res => {
            if (res.length) {
              this.guestPlans = res;
            } else {
              this.fetchSubscriptionsGuest('SA').then(res => {
                this.guestPlans = res;
              });
            }
          })
          .catch(error => {
            console.error('Error fetching guest subscriptions:', error);
          });
      }
    },
  },
  created() {
    if (this.isAuthorized) {
      this.fetchExternalSubscriptions().then(
        res => {
          if (res.subscriptions.length) {
            const activePlan = res.subscriptions.find(
              s => s.status === 'active'
            );
            const inactivePlan = res.subscriptions.find(
              s => s.status === 'inactive'
            );
            this.externalPlan = activePlan || inactivePlan;
          } else {
            this.externalPlan = null;
          }
        }
        // (this.externalPlan = res.subscriptions.length
        //   ? res.subscriptions[res.subscriptions.length - 1]
        //   : null)
      );
      this.fetchUserSubscriptions().then(res => {
        this.currentPlan = res;
        this.hasActivePlan =
          res.subscriptions[0].status === 'active' ? true : false;
        this.currentPlanId = res.subscriptions[0].planId;
        if (
          (this.userHasActiveSubscription || this.ActiveExternalPlan) &&
          this.phMaxLink &&
          !this.$route.query.view
        )
          this.redirectOpener();
        this.fetchSubscriptions().then(res => {
          this.allPlans = res;
        });
      });
    } else {
      const regionData = JSON.parse(localStorage.getItem('region'));
      const regionCode = this.getRegionCode(this.selectedRegion);
      this.fetchSubscriptionsGuestLocale().then(res => {
        console.log(res.map(option => option.country));
        let allowedRegions = [
          'Saudi Arabia',
          'Oman',
          'Kuwait',
          'Bahrain',
          'Egypt',
          'Jordan',
        ];
        let regionSet = new Set(res ? res.map(option => option.country) : []);
        regionSet = new Set(
          [...regionSet].filter(region => allowedRegions.includes(region))
        );
        this.locales = [...regionSet];
      });
      this.fetchSubscriptionsGuest(
        regionData.ip_country === regionCode
          ? regionCode
          : regionData.ip_country
      ).then(res => {
        if (res.length) {
          this.guestPlans = res;
        } else {
          this.fetchSubscriptionsGuest('SA').then(res => {
            this.guestPlans = res;
          });
        }
      });
    }
  },
  mounted() {
    if (this.isAuthorized) {
      window.dataLayer.push({
        event: 'Max page viewed - first time',
        userID: this.account._id,
        from: this.$route.path,
      });
    }

    let cdnId = 'tap-cdn';
    // let bbId = 'bluebird-cdn';
    if (!document.getElementById(cdnId)) {
      let scriptTag = document.createElement('script');
      scriptTag.src = 'https://secure.gosell.io/js/sdk/tap.min.js';
      scriptTag.id = cdnId;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
    if (this.$route.query.confirm_validation && this.$route.query.tap_id) {
      this.isProcessing = true;
      this.processPayment();
    } else if (
      this.$route.query.confirm_validation &&
      this.$route.query.purchase_success === 'True'
    ) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_subscription_payment-proccesing',
            'Your payment is being proccessed. Your PLAYHERA Max account will be ready in a few minutes. In the meantime, you can browse available games'
          ),
          cancelButtonText: this.$t('_web_stay_here', 'Stay here'),
          confirmButtonText: this.$t('_web_browse_games', 'Browse Games'),
        },
        events: {
          cancel: this.closePaymentNotification,
          confirm: this.redirectBrowse,
        },
      });
    } else if (this.$route.query.purchase_success === 'False') {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t('_web_payment_failed', 'Payment failed'),
          confirmButtonText: this.$t('_web_popup_ok', 'ok'),
          cancelButtonText: false,
        },
        events: {
          confirm: this.closePaymentNotification,
        },
      });
    } else if (
      this.$route.query.confirm_validation &&
      this.$route.query.purchase_success === 'False'
    ) {
      this.systemNotify({
        type: 'error',
        text: this.$t('_web_payment_failed', 'Payment failed'),
      });
    }
    anime({
      targets: ['.cloud-gaming', '.playhera-max', '.hero-copy'],
      duration: 1000,
      delay: 300,
      easing: 'easeInOutQuart',
      loop: false,
      opacity: ['0%', '100%'],
    });
    anime({
      targets: '.settings-prices > span:first-child',
      duration: 1000,
      delay: 1200,
      easing: 'easeOutQuart',
      loop: false,
      opacity: ['0%', '100%'],
      translateY: [15, 0],
    });
    anime({
      targets: '.settings-prices > span:last-child',
      duration: 1000,
      delay: 1900,
      easing: 'easeOutQuart',
      loop: false,
      opacity: ['0%', '100%'],
      translateY: [-15, 0],
    });
    window.addEventListener('scroll', this.handlePower);
    this.handlePower();
    window.addEventListener('scroll', this.handlePowerMobile);
    this.handlePowerMobile();
    window.addEventListener('scroll', this.handlePlenty);
    this.handlePlenty();
    window.addEventListener('scroll', this.handlePlentyMobile);
    this.handlePlentyMobile();
    window.addEventListener('scroll', this.handleCore);
    this.handleCore();
    window.addEventListener('scroll', this.handleCoreMobile);
    this.handleCoreMobile();
    window.addEventListener('scroll', this.handleChoosePlan);
    this.handleChoosePlan();
  },
  methods: {
    ...mapGetters(['currentRegion']),
    ...mapActions([
      'fetchSubscriptions',
      'fetchUserSubscriptions',
      'subscribeToPlan',
      'ssoSwitch',
      'verifyCard',
      'openModal',
      'closeModal',
      'closeModal',
      'errorNotify',
      'systemNotify',
      'successNotify',
      'fetchExternalSubscriptions',
      'fetchVoucherSubscriptions',
      'subscribeWithVoucher',
      'fetchSubscriptionsGuest',
      'fetchSubscriptionsGuestLocale',
    ]),
    ...mapMutations(['updateModalProps']),
    calcPriceWithDiscount(price, disc) {
      return price * (1 - disc / 100);
    },

    voucherRedeemActive() {
      this.codeInputActive = true;
    },

    voucherRedeem() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_voucher_confirm',
            'Are you sure you want to redeem this voucher?'
          ),
          confirmButtonText: this.$t('_web_popup_ok', 'ok'),
          cancelButtonText: this.$t('_web_popup_cancel', 'cancel'),
        },
        events: {
          confirm: () => {
            this.voucherSubscribe();
            this.closeModal();
            this.codeInputActive = false;
          },

          cancel: () => {
            (this.codeInputActive = false),
              this.closeModal(),
              (this.voucherCode = null);
          },
        },
      });
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    voucherSubscribe() {
      this.subscribeWithVoucher({ code: this.voucherCode })
        .then(() => {
          this.openModal({
            name: 'HeraConfirm',
            props: {
              text: this.$t(
                '_web_subscription_voucher_redeemed',
                'Your PLAYHERA Max account will be ready in a few minutes. In the meantime, you can browse available games'
              ),
              cancelButtonText: this.$t('_web_stay_here', 'Stay here'),
              confirmButtonText: this.$t('_web_browse_games', 'Browse Games'),
            },
            events: {
              cancel: () => this.closeModal(),
              confirm: this.redirectBrowse,
            },
          });
          this.voucherCode = null;
        })
        .catch(err => {
          this.errorNotify({
            message: this.$t('_web_' + err.message),
            inModal: true,
            yesText: this.$t('_web_popup_ok', 'ok'),
          }).finally(() => {
            this.voucherCode = null;
            this.codeInputActive = false;
          });
        });
    },

    getRegionCode(country) {
      switch (country) {
        case 'Saudi Arabia':
          return 'SA';
        case 'Oman':
          return 'OM';
        case 'Kuwait':
          return 'KW';
        case 'Bahrain':
          return 'BH';
        case 'Egypt':
          return 'EG';
        case 'Jordan':
          return 'JO';
        default:
          return 'SA';
      }
    },
    redirectOpener() {
      if (this.hasActivePlan || this.ActiveExternalPlan) {
        this.isLoading = true;
        const ext = this.$route.query;
        this.ssoSwitch({
          accountId: SSO_CLIENT_ID,
          accountType: 'thirdparty',
          ext,
        })
          .then(resp => {
            window.location.href = resp.redirect;
          })
          .catch(err => {
            this.errorNotify(err);
          });
      } else {
        this.scrollMeTo('plans');
      }
    },
    redirectBrowse() {
      this.closeModal();
      this.isLoading = true;
      const ext = this.$route.query;
      this.ssoSwitch({
        accountId: SSO_CLIENT_ID,
        accountType: 'thirdparty',
        ext,
      })
        .then(resp => {
          window.location.href = resp.redirect;
        })
        .catch(err => {
          this.errorNotify(err);
        });
    },

    handleRedirectDirectly() {
      if (this.phMaxLink) {
        window.location.href = this.phMaxLink;
      }
    },
    handlePower() {
      const element = this.$refs.myElement;
      const elementPosition = element && element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementPosition - windowHeight <= 0 && window.innerWidth > 1000) {
        anime({
          targets: '.your-games-pic-new',
          duration: 1000,
          // delay: 1500,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? 30 : -30, 0],
        });
        anime({
          targets: '.your-games > div:last-child',
          duration: 1000,
          // delay: 1500,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? -30 : 30, 0],
        });
        anime({
          targets: '.no-downloads-pic',
          duration: 1000,
          delay: 700,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? 30 : -30, 0],
        });
        anime({
          targets: '.no-downloads-new > div:last-child',
          duration: 1000,
          delay: 700,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? -30 : 30, 0],
        });
        anime({
          targets: '.no-session',
          duration: 1000,
          delay: 1700,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? 30 : -30, 0],
        });
        anime({
          targets: '.no-session > div:last-child',
          duration: 1000,
          delay: 1700,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? -50 : 50, 0],
        });
        window.removeEventListener('scroll', this.handlePower);
      }
    },
    handlePowerMobile() {
      const element = this.$refs.powerMobile;
      const elementPosition = element && element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementPosition - windowHeight <= 0 && window.innerWidth <= 1000) {
        anime({
          targets: '.your-games',
          duration: 1000,
          // delay: 1500,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateY: [this.isRTL ? 30 : -30, 0],
        });
        anime({
          targets: '.no-downloads-new',
          duration: 1000,
          delay: 700,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateY: [this.isRTL ? 30 : -30, 0],
        });
        anime({
          targets: '.no-session',
          duration: 1000,
          delay: 1700,
          easing: 'easeOutQuart',
          // loop: true,
          opacity: ['0%', '100%'],
          translateY: [this.isRTL ? 30 : -30, 0],
        });
        window.removeEventListener('scroll', this.handlePowerMobile);
      }
    },
    handlePlenty() {
      const plenty = this.$refs.plentyRef;
      const plentyPosition = plenty && plenty.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (plentyPosition - windowHeight <= 0 && window.innerWidth > 1000) {
        anime({
          targets: '.plenty-cards',
          duration: 1000,
          easing: 'easeOutQuart',
          opacity: ['0%', '100%'],
          translateY: [30, 0],
        });
        window.removeEventListener('scroll', this.handlePlenty);
      }
    },
    handlePlentyMobile() {
      const plenty = this.$refs.plentyRefMobile;
      const plentyPosition = plenty && plenty.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (plentyPosition - windowHeight <= 0 && window.innerWidth <= 1000) {
        anime({
          targets: '.plenty-cards',
          duration: 1000,
          easing: 'easeOutQuart',
          opacity: ['0%', '100%'],
          translateY: [30, 0],
        });
        window.removeEventListener('scroll', this.handlePlentyMobile);
      }
    },
    handleCore() {
      const core = this.$refs.coreRef;
      const corePosition = core && core.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (corePosition - windowHeight <= 0 && window.innerWidth > 1000) {
        anime({
          targets: '.core-left',
          duration: 1000,
          easing: 'easeOutQuart',
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? 50 : -50, 0],
        });
        anime({
          targets: '.core-right',
          duration: 1000,
          easing: 'easeOutQuart',
          opacity: ['0%', '100%'],
          translateX: [this.isRTL ? -50 : 50, 0],
        });
        window.removeEventListener('scroll', this.handleCore);
      }
    },
    handleCoreMobile() {
      const core = this.$refs.coreRefMobile;
      const corePosition = core && core.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (corePosition - windowHeight <= 0 && window.innerWidth <= 1000) {
        anime({
          targets: '.core-wrap',
          duration: 1000,
          easing: 'easeOutQuart',
          opacity: ['0%', '100%'],
          translateY: [this.isRTL ? 50 : -50, 0],
        });
        window.removeEventListener('scroll', this.handleCoreMobile);
      }
    },
    handleChoosePlan() {
      const plan = this.$refs.coreRef;
      const planPosition = plan && plan.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (planPosition - windowHeight <= 0) {
        anime({
          targets: '.subplans-outer-wrapper',
          duration: 1500,
          delay: 1500,
          // loop: true,
          easing: 'easeOutQuart',
          backgroundImage: [
            'none',
            `radial-gradient(
              rgba(91, 88, 242, 0.226) 0%,
              rgba(130, 97, 252, 0) 60.69%,
              rgba(13, 17, 23, 0) 90.78%
            )`,
          ],
        });
        window.removeEventListener('scroll', this.handleChoosePlan);
      }
    },
    pay(plan) {
      if (!this.$store.getters.isAuthorized) {
        this.openModal({
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
        });
      } else {
        this.currentPlan = plan;
        this.openModal({
          // here you control witch payment provider is used if tap than name is : CheckoutCard if payfort than name is : CheckoutFort
          name:
            this.$flagsmith.flags.payment_provider.value === 'tap'
              ? 'CheckoutCard'
              : 'CheckoutFort',
          props: { plan },
          settings: {
            variant: 'fit-content',
          },
          events: {
            submit: (token, coupon) => {
              this.processPayment(token, coupon);
            },
          },
        });
      }
      window.dataLayer.push({
        event: 'PH Max subscription attempt',
        userID: this.account._id,
        from: this.$route.path,
        subscriptionId: plan.id,
      });
    },
    closePaymentNotification() {
      this.$router.replace({
        query: { view: 'true' },
      });
      this.closeModal();
    },
    processPayment(token, coupon) {
      this.updateModalProps({ isLoading: true });

      const data = token
        ? {
            subscriptionId: this.currentPlan
              ? this.currentPlan.id
              : this.currentPlanId,
            token: token,
            coupon,
          }
        : {};

      this.verifyCard(data)
        .then(data => {
          // possible statuses -> pending / valid / invalid
          if (data.status === 'valid') {
            this.finishPayment(
              data.cardToken,
              this.$route.query.subscriptionId ||
                this.currentPlan.id ||
                this.currentPlanId,
              this.$route.query.coupon
            );
          } else if (data.status === 'pending')
            window.location.href = data.redirectUrl;
          else
            this.systemNotify({
              type: 'error',
              text: this.$t('_web_subscription_payment-error', 'Payment error'),
            });
        })
        .catch(err => {
          const error = err;
          error.inModal = true;
          if (error.description) error.message = error.description;
          this.errorNotify(error);
        })
        .finally(() => {
          this.isProcessing = false;
          this.updateModalProps({ isLoading: false });
        });
    },
    finishPayment(token, subscriptionId, coupon) {
      let newPlan = this.allPlans.find(plan => plan.id === subscriptionId);
      this.subscribeToPlan({ subscriptionId, token, coupon })
        .then(res => {
          if (res.status === 200)
            this.openModal({
              name: 'HeraConfirm',
              props: {
                text: this.$t(
                  '_web_subscription_payment-proccesing',
                  'Your payment is being proccessed. Your PLAYHERA Max account will be ready in a few minutes. In the meantime, you can browse available games'
                ),
                cancelButtonText: this.$t('_web_stay_here', 'Stay here'),
                confirmButtonText: this.$t('_web_browse_games', 'Browse Games'),
              },
              events: {
                cancel: this.closePaymentNotification,
                confirm: this.redirectBrowse,
              },
            });
          this.$router.replace({ query: null });
        })
        .then(
          setTimeout(() => {
            newPlan &&
              window.dataLayer.push({
                event: 'PH Max user subscribed',
                from: this.$route.path,
                userID: this.account._id,
                planId: subscriptionId,
                planDuration: newPlan.period,
                planPrice: newPlan.amount,
                planCurrency: newPlan.currency,
              });
          }, 3000)
        )
        .catch(err => {
          this.systemNotify({
            type: 'error',
            text: err.message,
          });
        })
        .finally(() => (this.isProcessing = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-positioner {
  display: flex;
  justify-content: flex-end;
}
.subplan-hero {
  display: flex;
  justify-content: center;
  background-color: #0d1117;
  background-image: url('~@/assets/images/phmax-assets/BG-ATF.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (min-width: 1800px) {
    background-size: 70%;
  }

  .subplan-hero-wrapper {
    width: 80vw;
    max-width: 1511px;
    display: flex;

    .subplan-hero-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      font-size: 1rem;
      min-width: 420px;

      .from-price {
        color: #9d9cf7;
        font-weight: bold;
      }

      .cloud-gaming {
        color: #9d9cf7;
        font-weight: bold;
        font-size: 1rem;
      }

      .playhera-max {
        font-size: 3.2rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      .hero-copy {
        font-size: 1rem;
        display: flex;
        flex-direction: column;
      }

      .settings-prices {
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        gap: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .buttons {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;

        button {
          background: none;
          border: none;
          border-radius: 8px;
          font-size: 1rem;
          font-weight: 700;
          padding: 0.7rem 0.9rem;

          &:first-child {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: #5a58f2;
            color: white;

            img {
              .app-rtl & {
                transform: scaleX(-1);
              }
            }
          }

          &:last-child {
            background-color: white;
          }
        }
      }
    }

    .subplan-hero-right {
      flex: 2;
      display: flex;
      justify-content: flex-end;

      img {
        width: 100%;
        max-width: 900px;
      }
    }
  }
}

.subplan-platforms-container {
  background-image: linear-gradient(#0d1017, rgba(#0d1017, 0.1));
}

.subplan-platforms {
  display: flex;
  justify-content: center;
  background-image: url('~@/assets/images/phmax-assets/stars-2.svg');
  background-repeat: no-repeat;
  background-position: left;

  .subplan-platforms-wrapper {
    width: 80vw;
    max-width: 1511px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 8rem;

    .subplan-platforms-title {
      font-size: 40px;
      font-weight: 800;
      margin-bottom: 0.5rem;
    }

    .subplan-platforms-subtitle {
      font-size: 14px;
      color: #c1c7d7;
      margin-bottom: 0.2rem;
    }

    .platforms-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: rgba(#7371f4, 0.1);
      border-radius: 5px;
      overflow: hidden;
      margin-top: 3rem;

      .table-buttons {
        display: flex;
        background-color: #11171f;

        button {
          width: 100%;
          background: none;
          border: none;
          color: #eff0f1;
          padding: 0.5rem;
        }

        .tv-button {
          display: flex;
          justify-content: center;
          align-items: flex-end;

          .coming-soon-tm {
            @include auto-rtl(margin-left, 3px);
            margin-bottom: 1px;
          }
        }

        .tab-active {
          background-color: #282e3e;
          border-radius: 5px;
        }
      }

      .tab-container {
        padding: 2rem 9rem;

        .browser-tab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 200px;

          .browser-left {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-size: 1rem;

            span > img {
              @include auto-rtl(margin-right, 0.5rem);
            }
          }

          .browser-right {
            padding: 1rem 2rem;
            border-radius: 5px;
            background-color: transparent;
            border-top: 2px solid #64007d;
            border-bottom: 2px solid #64007d;
            background-image: linear-gradient(#64007d, #004589, #64007d),
              linear-gradient(#64007d, #004589, #64007d);
            background-size: 2px 100%;
            background-position: 0 0, 100% 0;
            background-repeat: no-repeat;

            span {
              font-size: 14px;
            }

            .browsers {
              margin-top: 0.75rem;
              display: flex;
              gap: 0.5rem;
            }
          }
        }

        .tv-tab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 200px;

          .tv-left {
            max-width: 420px;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            div > span {
              &:first-child {
                font-size: 1.5rem;
                font-weight: bold;
              }

              &:last-child {
                color: #03ab5f;
                font-size: 12px;
                @include auto-rtl(margin-left, 0.2rem);
              }
            }

            span {
              font-size: 1rem;
            }
          }

          .tv-right {
            border-top: 2px solid #820a60;
            border-bottom: 2px solid #820a60;
            background-image: linear-gradient(#820a60, #9aa0aa, #820a60),
              linear-gradient(#820a60, #9aa0aa, #820a60);
            background-size: 2px 100%;
            background-position: 0 0, 100% 0;
            background-repeat: no-repeat;
            border-radius: 5px;
            padding: 1rem 1.5rem;

            &:last-child {
              display: flex;
              gap: 1rem;

              div {
                display: flex;
                flex-direction: column;
                @include auto-rtl(padding-right, 2rem);

                span {
                  &:first-child {
                    font-size: 1rem;
                  }

                  &:last-child {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-top: -0.5rem;
                  }
                }
              }
            }
          }
        }

        .mobile-tab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 200px;

          .mobile-left {
            display: flex;
            flex-direction: column;
            max-width: 420px;
            gap: 1rem;

            span {
              &:first-child {
                font-size: 1.5rem;
                font-weight: bold;
              }

              &:last-child {
                font-size: 1rem;
              }
            }

            span {
              font-size: 1rem;
            }
          }

          .mobile-right {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .mobile-platform {
              display: flex;
              padding: 1rem 1.5rem;
              gap: 1rem;
              border-top: 2px solid #6b0c97;
              border-bottom: 2px solid #6b0c97;
              background-image: linear-gradient(
                  #6b0c97,
                  #1274fe,
                  #6b0c97,
                  #1274fe,
                  #6b0c97
                ),
                linear-gradient(#6b0c97, #1274fe, #6b0c97, #1274fe, #6b0c97);
              background-size: 2px 100%;
              background-position: 0 0, 100% 0;
              background-repeat: no-repeat;
              border-radius: 5px;
              border-radius: 5px;

              img {
                width: 30px;
              }

              a :hover {
                color: white;
              }

              div {
                display: flex;
                flex-direction: column;
                @include auto-rtl(padding-right, 1rem);

                span {
                  font-size: 1rem;

                  &:last-child {
                    font-size: 1.5rem;
                    font-weight: bold;
                    white-space: nowrap;
                    margin-top: -0.3rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.platforms-features-container {
  background-image: url('~@/assets/images/phmax-assets/BG-lower.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.platforms-features-wrapper {
  background-image: linear-gradient(
    90deg,
    #0d1017,
    #0d1017,
    rgba(#0d1017, 0.1)
  );
}

.power-up {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 70px;
  background: linear-gradient(
    89.94deg,
    #0d1117 2.22%,
    rgba(23, 23, 72, 0.455964) 50.61%,
    rgba(90, 88, 242, 0) 91.16%
  );
}

.subplan-power-wrapper {
  width: 80vw;
  max-width: 1511px;
  display: flex;
  gap: 3rem;
}

.power-left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .power-img {
    width: 100%;
    max-width: 650px;
    min-width: 630px;
    @include auto-rtl(margin-left, -6rem);
  }
}

.power-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  @include auto-rtl(padding-right, 4rem);
}

.power-up-head {
  font-size: 40px;
  font-weight: 900;
  padding-bottom: 20px;
}

.your-games {
  display: flex;
  font-size: 16px;
  padding-top: 20px;
}

.no-downloads {
  display: flex;
  font-size: 16px;
  padding-top: 40px;
}

.your-games-title {
  font-weight: 900;
  margin-bottom: 20px;
}

.your-games-pic {
  @include auto-rtl(padding-right, 40px);
}

.your-games-logos {
  padding-top: 25px;
}

.your-games-already {
  font-size: 12px;
  color: #c1c7d7;
  @include auto-rtl(padding-left, 20px);
}

.coming-soon-tm {
  color: #03ab5f;
  font-size: 10px;
}

.plenty-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 12rem;
  background-image: url('~@/assets/images/phmax-assets/Graphic background-Cool-Stuff.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .plenty-wrapper {
    width: 80vw;
    max-width: 1511px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.plenty-title {
  font-size: 40px;
  font-weight: 900;
}

.plenty-cards {
  display: flex;
  flex-direction: row;
  gap: 8rem;
  justify-content: space-between;
  margin-top: 4rem;
  white-space: nowrap;
}

.plenty-cool {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 100px;
}

.plenty-subheader {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 16px;
}

.plenty-text {
  font-size: 18px;
  line-height: 24px;
}

.plenty-img {
  padding-bottom: 16px;
  height: 70px;
}

.core-title {
  font-size: 40px;
  padding-bottom: 60px;
  font-weight: 900;
  display: flex;
  justify-content: center;
}

.core-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(
    89.94deg,
    #0d1117 2.22%,
    #191854 34.74%,
    rgba(90, 88, 242, 0.13) 91.16%
  );
}

.core-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 100px;
}

.bottom-line-mobile {
  display: none;
}

.core-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.core-right {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.core-text {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.bottom-line {
  border-bottom: 1px solid #9d9cf7;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}

.core-layout {
  width: 80vw;
  max-width: 1511px;
}

.core-img {
  @include auto-rtl(padding-right, 20px);
}

.subplans-container {
  display: flex;
  justify-content: center;
}

.subplans-outer-wrapper {
  width: 100%;
  max-width: 2000px;
  background-image: radial-gradient(
    rgba(91, 88, 242, 0.226) 0%,
    rgba(130, 97, 252, 0) 60.69%,
    rgba(13, 17, 23, 0) 90.78%
  );
  background-position: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
}

.subplans-wrapper {
  width: 80vw;
  max-width: 1511px;
  display: flex;
  flex-direction: column;
}

.subplans-top {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 850px) {
    align-items: center;
    text-align: center;
  }

  span:first-child {
    font-size: 40px;
    font-weight: 800;
  }

  span:last-child {
    font-size: 14px;
    color: #c1c7d7;
  }
}

.subplan-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: center;
  gap: 2px;
  text-align: center;
}

.subplan-item {
  width: 300px;
  position: relative;
  display: inline-block;
  flex-direction: column;
  background-color: #eff0f1;
  color: #181c25;
  padding: 2rem;
  outline: solid 2px #5a58f2;

  &:hover {
    background-color: #1c1b55;
    color: #eff0f1;
    transition: all ease-in-out 0.18s;

    .subplan-subscribe-button {
      background-color: white;
      color: #5a58f2;
    }
  }

  .bookmark-icon {
    width: 22px;
    position: absolute;
    @include auto-rtl(right, 2.2rem);
    margin-top: -2.7rem;
  }
}

.subplan-item:first-child {
  @include auto-rtl(border-top-left-radius, 8px);
  @include auto-rtl(border-bottom-left-radius, 8px);
  // outline: solid 2px #5a58f2;
}

.subplan-item:last-child {
  @include auto-rtl(border-top-right-radius, 8px);
  @include auto-rtl(border-bottom-right-radius, 8px);
}

.subplan-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .subplan-discount {
    font-weight: bold;
  }
}

.app-link {
  color: white;
}

.subplan-name {
  font-size: 22px;
  font-weight: 800;
  text-transform: capitalize;
}

.subplan-price-wrapper {
  display: flex;
  align-items: flex-end;
}

.subplan-currency-price {
  display: flex;
  align-items: flex-start;

  span:first-child {
    margin-top: 10px;
    color: #767d92;
  }

  span:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.subplan-price-total {
  font-size: 40px;
  font-weight: 800;
}

.subplan-price-occur {
  margin-bottom: 12.5px;
}

.subplan-strikethrough {
  margin-top: -4px;
}

.subplan-discount-strike {
  @include auto-rtl(margin-right, 10px);
}

.subplan-subscribe-button {
  background-color: #5a58f2;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 0.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.subplan-description-wrap {
  display: flex;
  justify-content: center;
}

.subplan-description {
  text-align: center;
  max-width: 220px;
}

.subplans-additional-description-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.subplans-additional-description {
  text-align: center;
}

.subplan-no-wrapper {
  background-image: url('~@/assets/images/phmax-assets/plan-not-available.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subplan-no-country {
  font-size: 24px;
  font-weight: bold;
  margin-top: 1rem;
  max-width: 700px;
  text-align: center;
}

.active-plan {
  background-color: #1c1b55;
  color: #eff0f1;
}

.current-plan-text {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.subplan-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.partners-container {
  display: flex;
  justify-content: center;
}

.partners-wrapper {
  width: 80vw;
  max-width: 1511px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 4rem;

  span {
    font-size: 22px;
    font-weight: bold;
    color: #9d9cf7;
  }

  img {
    margin-top: 3rem;
  }
}

.tv-left-inner {
  display: flex;
  align-items: flex-end;

  span:last-child {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1220px) {
  .subplan-hero-wrapper {
    width: 95vw !important;
  }

  .subplan-power-wrapper {
    width: 95vw !important;
  }

  .subplan-platforms-wrapper {
    width: 92vw !important;
  }

  .core-layout {
    width: 92vw !important;
  }

  .subplans-wrapper {
    width: 92vw !important;
  }

  .power-img {
    min-width: 470px !important;

    .app-ltr & {
      margin-left: -3rem !important;
    }

    .app-rtl & {
      margin-right: 0 !important;
    }
  }

  .power-right {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 1000px) {
  .subplan-hero-outer-wrap {
    width: 100%;
    background-image: linear-gradient(
      90deg,
      #0d1117 20%,
      #171748 70%,
      rgba(#5a58f2, 0.1)
    );
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
  }

  .subplan-hero-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .subplan-hero {
    background-position: top;
    background-size: cover;
    padding: 0;

    .cloud-gaming {
      text-align: center;
    }

    .playhera-max {
      text-align: center;
    }

    .hero-copy {
      align-items: center;
    }

    .settings-prices {
      justify-content: center;
    }

    .buttons {
      justify-content: center;
    }

    .subplan-hero-left > span:last-child {
      text-align: center;
    }

    .subplan-hero-right {
      margin-top: 3rem;
    }
  }

  .subplan-power-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .power-up-head {
      text-align: center;
    }

    .power-img {
      min-width: auto !important;
      margin-left: 0 !important;
    }

    .power-left {
      align-items: center;
    }

    .power-right {
      padding-right: 0;
      max-width: 530px;

      .your-games-pic {
        padding-left: 0;
        padding-right: 0;
      }

      .your-games-logos {
        flex-direction: column-reverse !important;
        gap: 1rem;
      }

      .your-games {
        flex-direction: column;
        align-items: center;

        .your-games-already {
          padding-right: 0;
          padding-left: 0;
        }

        div:last-child {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            &:nth-child(2) {
              text-align: center;
            }
          }
        }
      }

      .no-downloads {
        flex-direction: column;
        align-items: center;

        div:last-child {
          display: flex;
          flex-direction: column;
          align-items: center;

          div:last-child {
            text-align: center;
          }
        }
      }
    }
  }

  .plenty-main {
    background-size: cover;
  }

  .plenty-wrapper {
    text-align: center;
  }

  .plenty-cards {
    flex-direction: column;
  }

  .tab-container {
    padding: 0 !important;
    padding-top: 1rem !important;
  }

  .browser-tab {
    flex-direction: column;
    padding: 1rem;

    .browser-left {
      width: 100%;
      max-width: 500px;
      margin-bottom: 1rem;
      align-items: flex-start;
    }

    .browser-right {
      width: 100%;
      max-width: 500px;
      padding: 1rem !important;

      .browsers {
        justify-content: space-between;
        max-width: 370px;
      }
    }
  }

  .tv-tab {
    flex-direction: column;
    padding: 1rem;

    .tv-left {
      max-width: 500px !important;
    }

    .tv-right {
      width: 100%;
      max-width: 500px;
      margin-top: 1rem;
    }
  }

  .mobile-tab {
    flex-direction: column;
    padding: 1rem;

    .mobile-left {
      max-width: 500px !important;
    }

    .mobile-right {
      width: 100%;
      max-width: 500px;
      margin-top: 1rem;
    }
  }

  .subplan-platforms-title {
    text-align: center;
  }

  .subplan-platforms-subtitle {
    text-align: center;
  }

  .core-wrap {
    flex-direction: column;
    gap: 0;
  }

  .bottom-line-mobile {
    display: flex;
  }
}

@media screen and (max-width: 820px) {
  .subplan-items {
    display: flex;
    flex-direction: column;
  }

  .subplan-item {
    display: flex;
    width: auto;
    max-width: none;

    &:first-child {
      @include auto-rtl(border-top-right-radius, 8px);
      @include auto-rtl(border-bottom-left-radius, 0px !important);
    }

    &:last-child {
      @include auto-rtl(border-bottom-left-radius, 8px);
      @include auto-rtl(border-top-right-radius, 0px !important);
    }
  }

  .subplan-no-wrapper {
    background-image: url('~@/assets/images/phmax-assets/plan-not-avail-mobile.png');
    min-height: 700px;
    text-align: center;
  }

  .playhera-max {
    font-size: 2.4rem !important;
  }

  .subplan-hero-left {
    min-width: auto !important;

    span:last-child {
      max-width: 300px;
    }
  }

  .hero-copy {
    font-size: 14px !important;
  }

  .settings-prices {
    font-size: 1.2rem !important;
  }

  .power-up-head {
    font-size: 32px;
  }

  .subplan-power-wrapper {
    gap: 1rem;
  }

  .plenty-title {
    font-size: 32px;
  }

  .plenty-cards {
    gap: 5rem;
  }

  .plenty-main {
    padding-bottom: 8rem;
  }

  .subplan-platforms-title {
    font-size: 32px !important;
  }

  .browser-left {
    font-size: 14px !important;
  }

  .tv-left > span {
    font-size: 14px !important;
  }

  .tv-left {
    div > span:first-child {
      font-size: 1.3rem !important;
    }

    div > span:last-child {
      margin-left: 0 !important;
    }

    div {
      display: flex;
      flex-direction: column;
    }
  }

  .mobile-left > span:last-child {
    font-size: 14px !important;
  }

  .mobile-left > span:first-child {
    font-size: 1.3rem !important;
  }

  .core-title {
    font-size: 32px;
  }

  .core-text {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .core-features {
    background-image: linear-gradient(
      89.94deg,
      #0d1117 2.22%,
      rgba(50, 23, 72, 0.455964) 50.61%,
      rgba(15, 5, 15, 0.692) 91.16%
    );
  }

  .subplans-top > span:first-child {
    font-size: 32px;
  }

  .subplans-outer-wrapper {
    padding-bottom: 3rem;
  }

  .partner-logo {
    max-width: 170px;
  }

  .plenty-text {
    font-size: 16px;
  }

  .plenty-img {
    height: 65px;
  }

  .tv-left-inner {
    align-items: flex-start;
  }
}

.spinner-middle {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .browser-tab {
    padding: 0;
  }

  .browser-left {
    padding: 1rem;
  }

  // .browser-right {
  //   margin-left: -1rem;
  // }

  .browsers > img {
    height: 60px;
  }
}

// animejs css prerequisites

@media screen and (min-width: 1001px) {
  .your-games-pic-new {
    opacity: 0;
  }

  .your-games > div:last-child {
    opacity: 0;
  }

  .no-downloads-pic {
    opacity: 0;
  }

  .no-downloads-new > div:last-child {
    opacity: 0;
  }

  .no-session {
    opacity: 0;
  }

  .no-session > div:last-child {
    opacity: 0;
  }

  .plenty-cards {
    opacity: 0;
  }

  .core-left {
    opacity: 0;
  }

  .core-right {
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .your-games {
    opacity: 0;
  }

  .no-downloads-new {
    opacity: 0;
  }

  .no-session {
    opacity: 0;
  }

  .plenty-cards {
    opacity: 0;
  }

  .core-wrap {
    opacity: 0;
  }
}
</style>

<style lang="scss">
.mobile-support {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  word-wrap: break-word;
}
.voucher-reedem {
  .playhera-label {
    margin-top: -26px;
    margin-bottom: 0;
  }
  display: flex;
  position: absolute;
  @include auto-rtl(right, 0);
  top: 0;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  @media screen and (max-width: 850px) {
    position: relative;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-top: 30px;
  }
}
.redeem_btn_sub {
  width: 174px !important;
  height: 48px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #30374a !important;
  opacity: 1;
  color: white !important;
  overflow: visible;
}
.voucher-input-wrap {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-top: 30px;
  }
}

.voucher-input {
  ::placeholder {
    color: #404a63 !important;
  }
  .input-form {
    margin-top: -7px;
    height: 48px;
    background-color: #1c212b !important;
    color: white;
  }
}
.redeem-enter-btn {
  width: 174px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #5a58f2 !important;
  color: white !important;
  opacity: 1;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}
</style>
