import { TOURNAMENT_STATUSES } from '@/constants/tournaments';

export const ACCOUNT = 'account';
export const PROFILE = 'profile';
export const ORGANIZATION = 'organization';
export const CLAN = 'clan';
export const TOURNAMENT = 'tournament';
export const BATTLE = 'battle';

export const CLAN_ROLES = {
  MEMBER: 'member',
  DEPUTY: 'deputy',
  OWNER: 'owner',
};
export const CLAN_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};
export const CLAN_JOIN_STATES = {
  NOT_ALLOWED: 'not_allowed',
  ALLOW_JOIN: 'allow_to_join',
  ALLOW_ACCEPT: 'allow_to_accept',
  PENDING: 'pending',
};

export const COUNT_FOLLOWERS = 'followers';
export const COUNT_FOLLOWING = 'followings';
export const COUNT_FRIENDS = 'friends';
export const COUNT_MEMBERS = 'members';

export const MANAGE_TEAM = 'manage_team';
export const LIST = 'list';

export const COUNT_BY_TYPES = {
  [PROFILE]: [COUNT_FOLLOWERS, COUNT_FOLLOWING, COUNT_FRIENDS],
  [ORGANIZATION]: [COUNT_FOLLOWERS, COUNT_FOLLOWING],
  [CLAN]: [COUNT_FOLLOWERS, COUNT_MEMBERS],
  [TOURNAMENT]: [],
};

/**
 * tournament statuses available for regular user
 */
export const TOURN_STATUSES = {
  ANNOUNCED: TOURNAMENT_STATUSES.ANNOUNCED,
  ARCHIVED: TOURNAMENT_STATUSES.ARCHIVED,
  REG_OPENED: TOURNAMENT_STATUSES.REG_OPENED,
  REG_CLOSED: TOURNAMENT_STATUSES.REG_CLOSED,
  QUALIFICATION_COMPLETED: TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
  STARTED: TOURNAMENT_STATUSES.STARTED,
  CLOSED: TOURNAMENT_STATUSES.CLOSED,
};
export const ACCOUNT_TYPES = [PROFILE, ORGANIZATION, CLAN, TOURNAMENT, BATTLE];

export const ORGANIZATION_TYPES = [
  'hera_admin',
  'federation',
  'media',
  'hardware_developer',
  'event_organizer',
  'college',
  // todo set in documentation
  // 'custom',
  // 'game_publisher',
  // 'sponsor',
];

export default {
  ACCOUNT,
  ACCOUNT_TYPES,
  PROFILE,
  ORGANIZATION,
  ORGANIZATION_TYPES,
  CLAN,
  TOURNAMENT,
  TOURN_STATUSES,
  BATTLE,

  COUNT_BY_TYPES,
  COUNT_FOLLOWERS,
  COUNT_FOLLOWING,
  COUNT_FRIENDS,
  COUNT_MEMBERS,

  MANAGE_TEAM,
};
