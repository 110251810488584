import { render, staticRenderFns } from "./Counter.vue?vue&type=template&id=75cb6974&functional=true&"
import script from "./Counter.vue?vue&type=script&lang=js&"
export * from "./Counter.vue?vue&type=script&lang=js&"
import style0 from "./Counter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Counter.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms&name=Counter&knobs=%7B%0A%20%20%20%20currentInput%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('currentInput'%2C%20'0')%0A%20%20%20%20%7D%2C%0A%20%20%20%20maxLength%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('maxLength'%2C%20100)%0A%20%20%20%20%7D%2C%0A%20%20%20%20isEditable%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('disabled'%2C%20false)%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports