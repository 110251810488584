export const REGISTRANTS = 'Registrants';
export const QUALIFIERS = 'Qualifiers';
export const RESERVED = 'Reserved';
export const ATTRIBUTES = 'Attributes';

export default {
  REGISTRANTS,
  QUALIFIERS,
  RESERVED,
  ATTRIBUTES,
};
