import api from '@/api';
import Vue from 'vue';
import {
  DEFAULT_STATUSES_INACTIVE,
  COMPETITION_STATUSES,
} from '@/constants/tournaments';
const defaultBlocksState = () => ({
  data: {},
  currentBlockBackup: {},
  currentBlockID: null,
  blockLoader: false,
});

const COMPETITION_STATUS_FLOW = [
  COMPETITION_STATUSES.DRAFT,
  COMPETITION_STATUSES.SCHEDULED,
  COMPETITION_STATUSES.PUBLISHED,
  COMPETITION_STATUSES.STARTED,
  COMPETITION_STATUSES.FINISHED,
];

const DISABLED_COMPETITION_FIELDS_BY_STATUS = {
  title: COMPETITION_STATUSES.SCHEDULED,
  players_required: COMPETITION_STATUSES.SCHEDULED,
  proceed_next: COMPETITION_STATUSES.SCHEDULED,
  deleteGroup: COMPETITION_STATUSES.SCHEDULED,
  delete_players: COMPETITION_STATUSES.FINISHED,
  swap_players: COMPETITION_STATUSES.FINISHED,
  round_date: COMPETITION_STATUSES.FINISHED,
  round_start: COMPETITION_STATUSES.FINISHED,
  round_finish: COMPETITION_STATUSES.FINISHED,
};

export default {
  state: defaultBlocksState(),
  getters: {
    isSingleBlock: (state, getters) =>
      getters.getBlocksByStageID(getters.currentTournamentStages[0].id)
        .length === 1,
    isCompetitionPastStatus: (state, getters) => (
      statusKey,
      block = getters.currentTournamentBlock
    ) => {
      const currentStatus = block.status;
      const statusIndex = COMPETITION_STATUS_FLOW.findIndex(
        s => s === statusKey
      );
      const currentIndex = COMPETITION_STATUS_FLOW.findIndex(
        s => s === currentStatus
      );
      return (
        statusIndex !== -1 && currentIndex !== -1 && statusIndex <= currentIndex
      );
    },
    isCompetitionFieldDisabled: (state, getters) => (fieldName, block) => {
      if (
        DEFAULT_STATUSES_INACTIVE.includes(getters.currentTournament.status)
      ) {
        return true;
      }
      const status = block ? block.status : null;
      const fieldStatus = DISABLED_COMPETITION_FIELDS_BY_STATUS[fieldName];
      if (!status || !fieldName || !fieldStatus) {
        return false;
      }
      return getters.isCompetitionPastStatus(fieldStatus, block);
    },
    getBlocksByStageID: state => stageID => {
      return Object.values(state.data)
        .filter(block => block.stage_id === stageID)
        .sort((a, b) => a.index - b.index);
    },
    getBlockByID: state => blockID => state.data[blockID],
    currentTournamentBlock: state => state.data[state.currentBlockID],
  },
  mutations: {
    setTournamentBlocks(state, payload) {
      state.data = {
        ...state.data,
        ...payload.reduce(
          (acc, block) => ({
            ...acc,
            [block.id]: block,
          }),
          {}
        ),
      };
    },
    setCurrentTournamentBlock(state, blockID) {
      state.currentBlockID = blockID;
    },
    addNewTournamentBlock(state, payload) {
      Vue.set(state.data, payload.id, payload);
    },
    updateCurrentTournamentBlock(state, payload) {
      Vue.set(state.data, state.currentBlockID, {
        ...state.data[state.currentBlockID],
        ...payload,
      });
    },
    updateTournamentBlockByID(state, payload) {
      Vue.set(state.data, payload.id, {
        ...state.data[payload.id],
        ...payload,
      });
    },
    removeTournamentBlock(state, payload) {
      Vue.delete(state.data, payload.blockID);
    },
    clearAllBlocks(state) {
      state.data = {};
    },
    clearCurrentTournamentBlock(state) {
      state.currentBlockID = null;
    },
    updatePlayersJoined(state, { blockID, delta = 0 }) {
      Vue.set(
        state.data[blockID],
        'players_joined',
        state.data[blockID].players_joined + delta
      );
    },
  },
  actions: {
    incrementBlockJoinedPlayers(
      {
        commit,
        getters: { currentTournamentStages, getBlocksByStageID },
      },
      { blockIndex, stageIndex }
    ) {
      const blockID = getBlocksByStageID(
        currentTournamentStages[stageIndex].id
      )[blockIndex].id;
      commit('updatePlayersJoined', { blockID, delta: 1 });
    },
    decrementBlockJoinedPlayers(
      {
        commit,
        getters: { currentTournamentStages, getBlocksByStageID },
      },
      { blockIndex, stageIndex }
    ) {
      const blockID = getBlocksByStageID(
        currentTournamentStages[stageIndex].id
      )[blockIndex].id;
      commit('updatePlayersJoined', { blockID, delta: -1 });
    },
    resetBlockBrackets(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID, blockID }
    ) {
      return api.tournaments
        .resetBrackets(tournamentID, stageID, blockID)(accessToken)
        .then(response => response.data);
    },
    shuffleBlockParticipants(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID, blockID }
    ) {
      return api.tournaments
        .shuffleParticipants(tournamentID, stageID, blockID)(accessToken)
        .then(response => response.data);
    },
    createTournamentBlock(
      {
        commit,
        getters: {
          accessToken,
          currentTournament,
          getStageInfo,
          getBlocksByStageID,
        },
      },
      { stageID, translator = new Vue().$i18n.translate }
    ) {
      const { index: stageIndex } = getStageInfo(stageID);
      const existingBlocks = getBlocksByStageID(stageID);
      const blockDefaults = existingBlocks.length
        ? {
            players_required:
              existingBlocks[existingBlocks.length - 1].players_required,
            proceed_next:
              existingBlocks[existingBlocks.length - 1].proceed_next,
            title: translator(
              '_web_tournament_group_editor_default_title',
              'Group {number}',
              { number: existingBlocks.length + 1 }
            ),
          }
        : {
            title: translator(
              '_web_tournament_group_editor_default_title',
              'Group {number}',
              { number: existingBlocks.length + 1 }
            ),
            players_required: 2,
            proceed_next: 1,
          };
      const newBlock = {
        players_joined: 0,
        index: existingBlocks.length,
        stage_index: stageIndex,
        stage_id: stageID,
        ...blockDefaults,
      };
      return api.tournaments
        .createTournamentBlock(currentTournament.id, stageID)(
          accessToken,
          newBlock
        )
        .then(response => response.data)
        .then(blockID => {
          commit('addNewTournamentBlock', {
            ...newBlock,
            id: blockID,
          });
          return blockID;
        });
    },
    fetchTournamentBlocks(
      {
        getters: { accessToken, currentTournament },
      },
      { stage, tournamentID }
    ) {
      return api.tournaments
        .getTournamentBlocks(currentTournament.id || tournamentID, stage)(
          accessToken
        )
        .then(response => {
          return response.data;
        });
    },
    updateTournamentBlock(
      {
        commit,
        getters: { accessToken, currentTournament, currentTournamentBlock },
      },
      blockInfo
    ) {
      const blockInfoSource = blockInfo || currentTournamentBlock;
      const tournament = currentTournament.id;
      const stage = blockInfoSource.stage_id;
      const block = blockInfoSource.id;
      commit('updateTournamentBlockByID', blockInfoSource);
      return api.tournaments
        .updateTournamentBlock(tournament, stage, block)(
          accessToken,
          blockInfoSource
        )
        .then(response => response.data);
    },
    deleteTournamentBlock(
      {
        commit,
        getters: { accessToken, currentTournament },
      },
      payload
    ) {
      return api.tournaments
        .deleteTournamentBlock(
          currentTournament.id,
          payload.stageID,
          payload.blockID
        )(accessToken)
        .then(response => response.data)
        .then(() => {
          commit('removeTournamentBlock', payload);
        });
    },
  },
};
