var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.buttonIsHidden),expression:"!buttonIsHidden"}]},[_c('div',{ref:"attachmentsPreview",staticClass:"attachments-preview mt-4",class:{
      'is-hidden': !_vm.attachments.length,
      'custom-scrollbar scrollable-attach': _vm.attachments.length > 1,
      'inserted-after': _vm.insertAfter,
    }},_vm._l((_vm.attachments),function(item){return _c('Attach',{key:item.id || item[item.type],class:[
        item.isApplication
          ? 'attachments-item-application'
          : 'attachments-item-media' ],attrs:{"data":item,"hide-preview":item.isApplication},on:{"deleteAttach":function($event){return _vm.deleteAttach(item)},"attachLoaded":function($event){return _vm.attachLoaded(item, $event)},"previewLoaded":function($event){return _vm.previewLoaded(item, $event)},"attachError":function($event){return _vm.attachError(item)}}})}),1),(_vm.buttonIsRendered)?_c('button',{staticClass:"d-flex align-items-center align-self-center h-mr-3 h-ml-2 btn btn-outline-secondary btn-upload",attrs:{"type":"button","disabled":_vm.isMaximumFilesCount}},[_c('AppIcon',{attrs:{"name":_vm.uploadBtnIcon,"width":_vm.iconSize}}),_c('label',[_c('input',{ref:"input",attrs:{"type":"file","accept":_vm.$options.accepts,"name":"attachments[]","multiple":""},on:{"change":_vm.onChange}})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }