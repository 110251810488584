<template>
  <icon
    v-if="showContent"
    class="notifAvatar"
    :name="getRegionCode(type)"
    :width="24"
  />
  <div v-else>
    <img
      v-if="dontRound"
      :style="cssProps"
      class="noRoundImg"
      :src="avatar"
      alt="avatar"
    />
    <ImageRounder
      v-else
      :size="size"
      :fill-color="fillColor"
      :alt="type || 'avatar'"
      :img-url="avatar"
      @imageLoadError="updateImage"
    />
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import ImageRounder from '@/components/atoms/ImageRounder';
import images from '@/constants/images';
import icon from '@/components/atoms/Icon';

export default {
  name: 'AvatarNoti',
  components: {
    ImageRounder,
    icon,
  },
  props: {
    dontRound: Boolean,
    size: prop(Number, 96),
    imgUrl: {
      type: null,
      default: '',
    },
    fillColor: prop(String),
    type: prop(String),
    autoSizes: prop(Boolean, true),
    isDeleted: prop(Boolean),
  },
  data() {
    const sourceSize = !this.autoSizes
      ? -1
      : this.size <= 100
      ? 100
      : this.size <= 300
      ? 300
      : this.size <= 500
      ? 500
      : -1;

    return {
      sourceSize,
      currentAvatar: '',
    };
  },
  computed: {
    cssProps() {
      console.log(this.size);
      return {
        '--avatar-size': this.size + 'px',
      };
    },
    url() {
      return String(this.imgUrl || '');
    },
    showContent() {
      return this.getRegionCode(this.type) !== false;
    },
    avatar() {
      return (
        (this.isDeleted ? this.getDeletedAvatar() : this.currentAvatar) ||
        (!this.url
          ? this.getDefaultAvatar()
          : this.checkAutoSize()
          ? this.url.replace('.original.', `.${this.sourceSize}.`)
          : this.url)
      );
    },
  },
  watch: {
    imgUrl() {
      this.currentAvatar = ''; // drop avatars if changed;
    },
  },
  methods: {
    checkAutoSize() {
      return (
        this.autoSizes &&
        this.url.includes('.original.') &&
        this.sourceSize !== -1
      );
    },
    updateImage() {
      this.currentAvatar = this.checkAutoSize()
        ? this.getNextSizeAvatar()
        : this.getDefaultAvatar();
    },
    getNextSizeAvatar() {
      this.sourceSize = -1;
      return this.url;
      /* commented @olsy
        if ((this.sourceSize += 200) > 500) {
          this.sourceSize = -1;
          return this.url;
        }
  
        return this.url.replace('.original.', `.${this.sourceSize}.`);
        */
    },
    getRegionCode() {
      switch (this.type) {
        case 'royale_battle_start_soon':
          return 'battle-noti';
        case 'battle_start_soon':
          return 'battle-noti';
        case 'tournament_private_invitational':
          return 'tournament-noti';
        case 'tournament_public_invitational':
          return 'tournament-noti';
        case 'tournament_qualification':
          return 'tournament-noti';
        case 'battle_cancelled':
          return 'battle-noti';
        case 'tournament_rejected':
          return 'tournament-noti';
        case 'tournament_activation_failed':
          return 'tournament-noti';
        case 'tournament_invitational_cancelled':
          return 'tournament-noti';
        case 'tournament_restarted':
          return 'tournament-noti';
        case 'battle_completed_tech_win':
          return 'battle-noti';
        case 'battle_completed_tech_loss':
          return 'battle-noti';
        case 'tournament_qualification_requested':
          return 'tournament-noti';
        case 'battle_scheduled':
          return 'battle-noti';
        case 'royale_battle_scheduled':
          return 'battle-noti';
        case 'tournament_stage_check_in_started':
          return 'tournament-noti';
        case 'tournament_stage_published':
          return 'tournament-noti';
        case 'tournament_announced':
          return 'tournament-noti';
        case 'tournament_finished':
          return 'tournament-noti';
        case 'tournament_finished_winner':
          return 'tournament-noti';
        case 'tournament_registration_opened':
          return 'tournament-noti';
        case 'tournament_started':
          return 'tournament-noti';
        case 'tournament_qualification_reserved':
          return 'tournament-noti';
        case 'tournament_published':
          return 'tournament-noti';
        case 'tournament_invitational':
          return 'tournament-noti';
        case 'tournament_entry_fee_success':
        case 'tournament_entry_fee_failed':
        case 'tournament_entry_fee_expired':
          return 'tournament-noti';
        case 'profile_password_changed':
          return 'user-noti';
        case 'friendship_requested':
          return 'user-noti';
        case 'friendship_rejected':
          return 'user-noti';
        case 'friendship_accepted':
          return 'user-noti';
        case 'profile_mentioned':
          return 'user-noti';
        case 'clan_mentioned':
          return 'clan-noti';
        case 'post_commented':
          return 'user-noti';
        case 'general_post':
          return 'generic-noti';
        case 'clan_post':
          return 'clan-noti';
        case 'external_subscription_activated':
          return 'generic-noti';
        case 'external_subscription_canceled':
          return 'generic-noti';
        case 'external_subscription_prolonged':
          return 'generic-noti';
        case 'clan_member_invited':
          return 'clan-noti';
        case 'clan_member_joined':
          return 'clan-noti';
        case 'clan_invite_rejected':
          return 'clan-noti';
        case 'clan_member_added':
          return 'clan-noti';
        case 'clan_member_requested':
          return 'clan-noti';
        case 'clan_member_removed':
          return 'clan-noti';
        case 'clan_member_updated':
          return 'clan-noti';
        case 'clan_validated':
          return 'clan-noti';
        case 'clan_unvalidated':
          return 'clan-noti';
        case 'clan_deleted':
          return 'clan-noti';
        case 'clan_founder_reassigned':
          return 'clan-noti';
        case 'clan_join_request_accepted':
          return 'clan-noti';
        case 'clan_request_rejected':
          return 'clan-noti';
        case 'member_left':
          return 'user-noti';
        case 'battle_started':
          return 'battle-noti';
        case 'royale_battle_completed':
          return 'battle-noti';
        case 'royale_battle_started':
          return 'battle-noti';
        case 'battle_match_result_updated':
          return 'battle-noti';
        case 'one_player_start_battle':
          return 'battle-noti';
        case 'battle_player_finished':
          return 'battle-noti';
        case 'player_group_up_requested':
          return 'battle-noti';
        case 'player_group_up_league_team_requested':
          return 'battle-noti';
        case 'battle_activated':
          return 'battle-noti';
        case 'battle_completed':
          return 'battle-noti';
        case 'battle_should_confirm':
          return 'battle-noti';
        default:
          return false;
      }
    },
    getDefaultAvatar() {
      switch (this.type) {
        case 'None':
          return images.defaultPHmax;
        case 'user':
        case 'profile':
          return images.defaultUser;
        case 'clan':
          return images.defaultClan;
        case 'federation':
        case 'organization':
          return images.defaultOrganization;
        case 'platform':
        case 'genre':
        case 'game':
          return images.defaultGame;
        case 'espl':
        case 'league':
          return images.defaultESPL;
        case 'channel':
          return images.defaultChannels;
        case 'group':
          return images.defaultGroup;
        case 'place':
          return images.defaultPlace;
        case 'admin':
          return images.defaultAdmin;
      }
      return '';
    },
    getDeletedAvatar() {
      switch (this.type) {
        case 'clan':
          return images.deletedClan;
      }
      return '';
    },
  },
};
</script>
<style>
.noRoundImg {
  height: var(--avatar-size);
  width: var(--avatar-size);
  border-radius: 5px;
  background-size: cover;
}
</style>
