<template>
  <div class="standings">
    <Widget
      :title="$t('_web_tournament_block_standings', 'Standings')"
      :actions-menu="actionMenuItems"
      class="mb-5"
      hide-search
    />
    <HorizontalTabs v-if="tabsVisible" v-bind="{ tabs, variant: 'folders' }" />
    <Panel
      class="standings-tables-wrapper"
      :class="{
        tabs: tabsVisible,
        'ph-panel--rounded-top-left-0 ph-panel--rounded-top-right-0': tabsVisible,
      }"
    >
      <div>
        <Icon
          class="h-mr-3"
          :name="currentStageType.key"
          :fill="$scss.secondaryTextBg"
          width="24"
        />
        <TextBlock
          :text="$$t(currentStageType.name, currentStageType.key)"
          variant="secondary"
        />
      </div>
      <HeraDummyList
        v-if="!isLoading && (!currentStage || !blocks || !blocks.length)"
        size="200"
        icon-name="dummy/members"
        :text-header="$$t('No participants', 'empty')"
        :text="$$t('', 'empty_subheader')"
      />
      <div v-else class="row position-relative">
        <div
          :class="[
            'col-12',
            { 'col-md-8 col-lg-6': isSingleElimination || isDE },
          ]"
        >
          <div class="row standings-tables">
            <div v-for="block in blocks" :key="block.id" class="mt-4 col-12">
              <GroupTable
                :id="block.id"
                :title="block.title"
                :type="currentStageType.type"
                :tie-breaker="
                  $lodash.get(
                    currentStage,
                    'block_configuration.tie_breaker_preset'
                  )
                "
                class="h-100 flex-grow-1 standings-table"
              />
            </div>
          </div>
        </div>
        <div
          class="d-flex pt-4 flex-column sticky-top"
          :class="[
            'col-12',
            { 'col-md-4 col-lg-6': isSingleElimination || isDE },
          ]"
        >
          <template v-if="currentTournament">
            <div
              v-if="
                currentTournament.prize &&
                  currentTournament.prize.sponsors &&
                  currentTournament.prize.sponsors.length
              "
              class="mb-4"
            >
              <TextBlock
                :text="$t('_web_tournament_sponsored-by', 'Sponsored by')"
                variant="secondary"
              />
              <TournamentSponsors :height="100" />
            </div>
            <div
              v-if="
                currentTournament.info.event_organizer &&
                  currentTournament.info.event_organizer.isAvailable
              "
            >
              <TextBlock
                :text="$t('_web_tournament_organized-by', 'Organized by')"
                variant="secondary"
              />
              <ListItemAccount
                :type="organizationType"
                :avatar="currentTournament.info.event_organizer.logo"
                :name="currentTournament.info.event_organizer.name"
                :size="36"
              />
            </div>
          </template>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  STAGE_TYPES,
  BLOCK_TYPES,
  STAGE_STATUSES,
} from '@/constants/tournaments';
import downloadFakeLink from '@/utils/downloadFile';
import paramsMixin from '@/mixins/queryParams';
import isLoading from '@/mixins/isLoading';
import { ORGANIZATION } from '@/constants/accounts';
import { translate } from '@/mixins/translate';
import listMixin from '@/mixins/lists';
import Icon from '@/components/atoms/Icon';
// import Title from '@/components/atoms/Title';
import Panel from '@/components/atoms/Panel';
import ListItemAccount from '@/components/common/ListItemAccount';
import TextBlock from '@/components/atoms/TextBlock';
import HeraDummyList from '@/components/common/HeraDummyList';
import HorizontalTabs from '@/components/common/HorizontalTabs';
import GroupTable from './GroupTable';
import TournamentSponsors from '@/views/tournaments/management/singleTournament/components/SponsorsListPreview';
import Widget from '@/views/tournaments/management/summaryWidgets/Widget';
export default {
  name: 'Standings',
  components: {
    Icon,
    // Title,
    Widget,
    Panel,
    TextBlock,
    HeraDummyList,
    HorizontalTabs,
    GroupTable,
    ListItemAccount,
    TournamentSponsors,
  },
  mixins: [
    ...listMixin,
    paramsMixin,
    isLoading,
    translate('_web_competitions_standings'),
  ],

  data() {
    return {
      organizationType: ORGANIZATION,
    };
  },

  computed: {
    ...mapGetters([
      'currentTournamentStages',
      'currentTournament',
      'getBlocksByStageID',
      'isStagePastStatus',
    ]),
    tabs() {
      return this.currentTournamentStages.map(s => ({
        labelStated: s.title,
        key: s.id,
        route: s.id,
      }));
    },
    tabsVisible() {
      return this.tabs.length > 1;
    },
    stageID() {
      return this.$route.params.stageID;
    },
    currentStage() {
      return this.currentTournamentStages.find(
        stage => stage.id === this.stageID
      );
    },
    currentStageType() {
      const type = this.$lodash.get(
        this.currentStage,
        'block_configuration.type',
        BLOCK_TYPES.SINGLE
      );
      return STAGE_TYPES.find(s => s.type === type);
    },
    isSingleElimination() {
      return this.currentStageType.type === BLOCK_TYPES.SINGLE;
    },
    isDE() {
      return this.currentStageType.type === BLOCK_TYPES.DOUBLE;
    },
    isBattleRoyale() {
      return this.currentStageType.type === BLOCK_TYPES.BATTLE_ROYALE;
    },
    isSwiss() {
      return this.currentStageType.type === BLOCK_TYPES.SWISS;
    },
    colGrid() {
      const blocksLen = this.blocks.length;
      if (!this.isSingleElimination || !blocksLen) {
        return 'col-12 col-lg-6';
      }

      return 'col-12 col-lg-6';
    },
    blocks() {
      return this.getBlocksByStageID(this.stageID);
    },
    CSVtooltipText() {
      return this.isStageFinished
        ? this.$t(
            '_web_tournament-block_results-export-csv-tooltip',
            'Export results to CSV file'
          )
        : this.$t(
            '_web_tournament-block_results-export-blocked-tooltip',
            'Results export is available only for finished competitions'
          );
    },
    actionMenuItems() {
      return [
        {
          key: 'players-export-csv',
          title: 'Export Users to CSV',
          subtitle: this.CSVtooltipText,
          icon: 'csv',
          handler: this.exportToCSV,
          disabled: !this.isStageFinished,
        },
      ];
    },
    isStageFinished() {
      return this.isStagePastStatus(STAGE_STATUSES.FINISHED, this.currentStage);
    },
  },
  watch: {
    stageID() {
      this.checkStageId();
    },
  },
  created() {
    this.checkStageId();
  },

  methods: {
    ...mapActions(['errorNotify', 'getTournamentResultsCSV']),
    exportToCSV() {
      this.setLoading();
      this.getTournamentResultsCSV({
        stage: this.stageID,
      })
        .then(res => {
          downloadFakeLink(new Blob([res], { type: 'text/csv' }), 'results');
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    checkStageId() {
      if (!this.stageID && this.currentTournamentStages) {
        this.$router.replace({
          ...this.$router,
          params: {
            ...this.$router.params,
            stageID: this.currentTournamentStages[0].id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.standings {
  &-tables-wrapper {
    &.tabs {
      @include auto-rtl(border-top-left-radius, 0);
    }
    .hera-table-wrap {
      background-color: var(--ph-input-bg);
    }
  }
  &-table {
    max-height: unset !important;
    .hera-table {
      border-collapse: collapse;

      .hera-text-block.text-block--secondary {
        font-family: 'Exo';
        text-transform: initial;
      }
      .hera-table-head th,
      th,
      td {
        background-color: var(--ph-input-bg);
      }
    }
  }
  .hera-title + .ph-panel--default {
    &.ph-panel--rounded-top-right-0,
    &.ph-panel--rounded-top-left-0 {
      //when no stage selection is shown
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }
  }
  .ph-panel--default {
    background-color: var(--ph-input-bg) !important;
    border: 1px solid var(--ph-input-borders);

    &.ph-panel-full-paddings {
      padding: 24px;
    }

    .ph-panel--stroked-secondary {
      border-color: var(--ph-input-borders) !important;
    }
  }
  .horizontal-tabs-wrapper.scroll-wrapper {
    position: relative;
    top: 1px;
  }
  .horizontal-tabs--folders .horizontal-tabs__item.router-link-exact-active {
    background-color: var(--ph-input-bg) !important;
  }
}
</style>
