<template>
  <div
    class="player-profile"
    @mouseenter="getPostAuthorProfile(data.login)"
    @mouseleave="showFollowButton = false"
  >
    <div class="search-item-list">
      <LinkAccount
        :id="data.login"
        class="avatar-container"
        :type="data.type"
        :vanity-id="data.vanity_id"
      >
        <Avatar
          v-bind="{
            size: 48,
            type: data.type || 'clan',
            imgUrl: data.avatar ? data.avatar : data.logo,
            isDeleted: data.deleted,
          }"
        />
      </LinkAccount>

      <div class="text-name-container">
        <LinkAccount
          :id="data.login"
          class="d-flex display-name m-0"
          :type="data.type"
          :vanity-id="data.vanity_id"
        >
          <TextLabel class=" text-name m-0">
            {{
              data.display_name
                ? data.display_name
                : data.name
                ? data.name
                : data.login
            }}
          </TextLabel>
        </LinkAccount>

        <div class="d-flex display-name">
          <TextLabel class="text-name text-name-second m-0">
            @
            {{
              data.login
                ? data.login
                : data.display_name
                ? data.display_name
                : data.name
            }}
          </TextLabel>
        </div>
      </div>
      <div class="" dir="rtl">
        <FollowBtn
          :model="model"
          class="follow-btn"
          :class="showFollowButton ? '' : 'follow-hidden'"
          dir="rtl"
          follow-class="btn-primary"
          hover-class="btn-secondary"
          unfollow-class="btn-secondary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/images/Avatar';
import { mapState } from 'vuex';
import TextLabel from '@/components/atoms/TextLabel';
import { prop } from '@/utils/factories';

import FollowBtn from '@/components/common/NewFollowBtn';
import LinkAccount from '@/components/links/Account';
import AccountModel from '@/models/Account';
import { API } from '@/api/config';

export default {
  components: { TextLabel, Avatar, FollowBtn, LinkAccount },
  props: {
    data: prop(Object, null),
  },

  data() {
    return {
      model: new AccountModel(),
      showFollowButton: false,
    };
  },

  computed: {
    ...mapState('current', ['account', 'accountId']),
    ...mapState('my', { myAccount: 'account' }),
  },
  methods: {
    async getPostAuthorProfile(id) {
      const response = await API(`/profiles/${id}`);
      this.model = new AccountModel(
        response.data.type,
        response.data.login
      ).update(response.data);
      this.showFollowButton = true;
    },
  },
};
</script>

<style lang="scss">
.search-item-list {
  width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  word-break: break-word;

  label {
    display: flex;
    text-align: left;
    cursor: pointer;
    color: $hr-text-primary;
    text-decoration: none;
  }
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.avatar-container {
  display: flex;
}

.player-profile {
  width: 100%;
  border-radius: 0.25rem;
  display: flex;
  height: 10rem;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 5px;
  word-break: normal;
  white-space: nowrap;

  label {
    cursor: pointer;
    width: 6rem;
    text-align: center;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    margin: 0;
  }
}

.text-name {
  font-size: 14px;
}

.text-name-second {
  color: $hr-text-secondary !important;
}

.display-name {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  text-overflow: clip;
  display: inline-block;
}

.display-name > label {
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: clip;
  display: inline-block;
}

.text-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.follow-btn {
  height: 2rem;
  min-width: 50px;
  width: 7.5rem;
  background-color: transparent !important;
  color: $hr-text-primary;
  border-radius: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid $new-bg-neutral-faded !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.follow-btn:hover {
  background-color: $new-bg-primary !important;
  border: 2px solid $new-bg-primary !important;
}
.follow-hidden {
  visibility: hidden;
  @media screen {
  }
}
</style>
