import { mapActions, mapGetters } from 'vuex';
export const shareLink = {
  computed: { ...mapGetters(['currentRegion']) },
  methods: {
    ...mapActions(['successNotify', 'openModal']),
    getSharingLink({ type, id, vanityId, token } = {}) {
      return (
        (vanityId
          ? `${this.$config.shareBaseURL}${vanityId}`
          : `${this.$config.shareBaseURL}${
              this.currentRegion
                ? this.currentRegion.id
                : this.$config.currentRegion
            }/${type}s/${id}`) +
        `${token ? '?registration_token=' + token : ''}`
      );
    },
    copyLinkToClipboard(linkText) {
      this.$copyText(linkText)
        .then(() => {
          this.successNotify({
            text: this.$t(
              '_web_link-copied-to-clipboard',
              'Link copied to clipboard'
            ),
          });
        })
        .catch(() => {
          this.openModal({
            name: 'HeraConfirmCopyLinkError',
            props: {
              link: linkText,
            },
          });
        });
    },
  },
};
export default [shareLink];
