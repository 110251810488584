<template>
  <div class="position-relative flex-grow-1 col-12 tournament-participants">
    <Widget
      v-model="filters.search_term"
      :title="$$t('Qualify Participants', 'list')"
      :indicators="indicators"
      :disabled="showStub && !filters.search_term"
      :actions-menu="actionsBtnItems"
      :indicators-centered="false"
      indicators-wrapper-class="justify-content-between h-pl-0"
      class="mb-6"
      status-btn-mode="participants"
      @input="onFilterChange"
    >
      <template #afterIndicators>
        <div class="d-flex justify-content-end align-items-center w-100">
          <label v-if="squadSize > 1" class="checkbox-label">
            <input v-model="isCheckedNew" type="checkbox" />
            <TextBlock tag="span" text="Show full teams" class=""></TextBlock>
          </label>
        </div>
      </template>
      <template #actionBtn>
        <Btn
          class="btn-accent-simple h-ml-6"
          :class="[
            ,
            {
              disabled:
                isStageFieldDisabled('qualify', currentTournamentStages[0]) ||
                showStub,
            },
          ]"
          :loading="qualifyLoading"
          :label="$$t('Qualify')"
          @click="generateFillParticipants('qualified')"
        />
      </template>
    </Widget>
    <!-- <InfoPanel>
      {{ infoPanelText }}
      <template v-if="!isDraft && !isAnnounced" #addons>
        <div class="d-flex align-items-center justify-content-end">
          <MultiRadio
            v-model="qualificationType"
            :items="qualificationTypes"
            :disabled="isTournamentFieldDisabled('qualification_type')"
          />

        </div>
      </template>
    </InfoPanel> -->
    <HeraDummyList
      v-if="showStub"
      class="mt-4 "
      size="200"
      icon-name="dummy/members"
      :header-text="$$t('You have no participants yet', 'grid_empty')"
      :text="
        isAnnounced
          ? $$t(
              'Registration will be opened in',
              'grid_will-start-in-description'
            )
          : ''
      "
    >
      <div v-if="canInvitePlayers" slot="ctaButton">
        <div class="d-inline-flex flex-column">
          <Btn
            class="btn-accent-simple mb-4"
            :label="$t('_web_tournament_invite-people', 'Invite People')"
            @click.stop="goToInvites"
          />
        </div>
        <div class="my-5 py-5"></div>
        <div class="my-5">
          <div
            class="row w-100 mx-auto justify-content-between"
            style="max-width: 500px;"
          >
            <div class="col-auto row justify-content-center">
              <div class="col-auto text-center">
                <ToolIcon
                  icon-name="qr-code"
                  icon-size="36"
                  :help-text="
                    $t(
                      '_web_qr-code-download-tournament-help',
                      'Download QR-code for tournament invitation link'
                    )
                  "
                  class="mb-3"
                  @click.stop="downloadQrCode"
                />
                <TextBlock
                  tag="div"
                  :text="$t('_web_qr-code-download', 'QR code')"
                />
                <QRCodeComponent
                  :options="qrFullOptions"
                  hidden
                  @qrReady="getQRSource"
                />
              </div>
            </div>
            <div class="col-auto row justify-content-center">
              <div class="col-auto text-center">
                <ToolIcon
                  icon-name="copy"
                  icon-size="36"
                  :help-text="
                    $t(
                      '_web_copy-tournament-link-tournament-help',
                      'Copy tournament invitation link to clipboard'
                    )
                  "
                  class="mb-3"
                  @click.stop="getInviteLink"
                />
                <TextBlock
                  tag="div"
                  :text="
                    $t('_web_copy-tournament-link-tournament-text', 'Copy link')
                  "
                />
              </div>
            </div>
            <div class="col-auto row justify-content-center">
              <div class="col-auto text-center">
                <ToolIcon
                  icon-name="social/fb"
                  icon-size="36"
                  :help-text="
                    $t(
                      '_web_share-fb-tournament-help',
                      'Share tournament link to Facebook'
                    )
                  "
                  class="mb-3"
                  @click.stop="shareLinkToFacebook"
                />
                <TextBlock tag="div" text="Facebook" />
              </div>
            </div>
            <div class="col-auto row justify-content-center">
              <div class="col-auto text-center">
                <ToolIcon
                  icon-name="social/twitter"
                  icon-size="36"
                  :help-text="
                    $t(
                      '_web_share-twitter-tournament-help',
                      'Share tournament link to Twitter'
                    )
                  "
                  class="mb-3"
                  @click.stop="shareLinkToTwitter"
                />
                <TextBlock tag="div" text="Twitter" />
              </div>
            </div>
          </div>
          <!-- <TextBlock tag="div" :text="$t('_web_or', 'or')" />
          <TextBlock
            tag="div"
            :text="
              $t(
                '_web_tournaments_share_link_help_text',
                'share the link with players you want to invite'
              )
            "
          />
          <Btn
            class="btn-accent-simple mt-4 mb-6"
            :label="$t('_web_invite_list_copy_invite_link', 'Copy invite link')"
            @click.stop="getInviteLink"
          /> -->
        </div>
      </div>
      <HeraBadge
        v-if="isAnnounced"
        slot="append"
        variant="bg-orange"
        class="h-ml-3"
      >
        <Countdown
          :date="currentTournament.schedule.registration_start"
          tag="span"
          :size="0.625"
          variant="primary"
        />
      </HeraBadge>
    </HeraDummyList>
    <div v-else class="tournament-participants-list mt-6">
      <Paginated
        v-bind="{
          totalItems: playersInfo.current_total,
          hidePagination: !players.length,
          pageSize,
          inContainer: true,
        }"
        class="mb-5"
        @change="fetchList"
      >
        <Table
          :columns="visibleColumns"
          :items="isChecked ? fullTeams : players"
          :loading="isLoading"
          :max-items-length="isTotalSelected ? 0 : totalItems"
          :selectable="true"
          :has-menu="true"
          :show-subheader="true"
          :menu-disabled="isLoading"
          :dummy-props="{
            size: 200,
            iconName: 'dummy/members',
            headerText: $t(
              '_web_tournament_players_empty',
              'You have no participants yet'
            ),
            text: $t(
              '_web_tournament_players_empty-exlanation-text',
              'When participants register or accept your invitation they will appear here'
            ),
          }"
          class="all-participants-table mb-5 col"
          @select-all="selectAllCheckboxHandler"
          @show-menu="onSettingsModalOpen"
          @clickOnRow="setActiveRow"
          @select="checkTotalCount"
        >
          <template #control>
            <transition name="translate-y">
              <PlayersTableControls
                class="w-100 h-mr-6"
                :selected="amountFooterCounter"
                :approved-to-list="distinctPlayersApprovedTo"
                :attributes-list="distinctPlayersAttributes"
                :stage-info="currentTournamentStages[0]"
                show-attributes
                show-approved
                @approvedToSet="
                  approvedTo => setSelectedPlayersExtendedData({ approvedTo })
                "
                @attributesSet="
                  attributes => setSelectedPlayersExtendedData({ attributes })
                "
              />
            </transition>
          </template>
          <template #head-item="{ column }">
            <PlayersTableHeadCell
              :column-name="column.name"
              :sort-icon="
                sortFields.field !== transformField(column.name)
                  ? 'sort'
                  : sortFields.order === 'incr'
                  ? 'sort-asc'
                  : 'sort-dsc'
              "
              @sort="handleSort"
            />
          </template>
          <template #subhead-item="{ column }">
            <CustomFilter
              v-if="
                distinctPlayersAttributes.length && column.name === 'attributes'
              "
              v-model="filters.attributes_filter"
              class="custom-filter"
              clear-if-selected-all
              :options="distinctPlayersAttributes"
              :disabled="isLoading"
              @close="onFilterChange"
            />
            <CustomFilter
              v-show="column.name === 'approvedTo'"
              v-model="filters.approved_to"
              clear-if-selected-all
              class="custom-filter"
              :options="distinctPlayersApprovedTo"
              :disabled="isLoading"
              custom-order
              @close="onFilterChange"
            />
            <CustomFilter
              v-show="column.name === 'check-in'"
              v-model="filters.checked_in"
              clear-if-selected-all
              class="custom-filter"
              :options="checkinOptions"
              :disabled="isLoading"
              @close="onFilterChange"
            />
          </template>
          <template #body-item="{ item, column }">
            <PlayersTableCell
              v-if="!item.isAddon"
              :column-name="column.name"
              :row="item"
              :is-clan="properties.is_clan"
              :all-attributes="properties.possible_attributes"
              @chat="startChat"
              @showRegistrationData="openRegDataModal"
              @qualify="qualifyPlayer"
              @attributesSet="setPlayersAttrtibutes(item, $event)"
              @checkIn="toggleCheckIn"
            />
          </template>
          <template #body-item-addon="{ item }">
            <td :colspan="visibleColumns.length" class="py-0">
              <Table
                :columns="memberColumns"
                :items="item.members"
                class="py-0"
              >
                <template #head-item="{column}">
                  <PlayersTableHeadCell :column-name="column.name" />
                </template>
                <template #body-item="{item: player, column}">
                  <PlayersTableUsernameCell
                    v-if="column.name === 'Username'"
                    :account="{ id: player.id, ...player.fields_data }"
                  />
                  <TextBlock v-else :text="player.fields_data[column.name]" />
                </template>
              </Table>
            </td>
          </template>
          <template #noUnitsCTA>
            <div>
              <div class="d-inline-flex flex-column">
                <Btn
                  class="btn-accent-simple mb-4"
                  :label="$t('_web_tournament_invite-people', 'Invite People')"
                  @click.stop="goToInvites"
                />
              </div>
              <div class="my-5 py-5"></div>
              <div class="my-5">
                <div
                  class="row w-100 mx-auto justify-content-between"
                  style="max-width: 500px;"
                >
                  <div class="col-auto row justify-content-center">
                    <div class="col-auto text-center">
                      <ToolIcon
                        icon-name="qr-code"
                        icon-size="36"
                        :help-text="
                          $t(
                            '_web_qr-code-download-tournament-help',
                            'Download QR-code for tournament invitation link'
                          )
                        "
                        class="mb-3"
                        @click.stop="downloadQrCode"
                      />
                      <TextBlock
                        tag="div"
                        :text="$t('_web_qr-code-download', 'QR code')"
                      />
                      <QRCodeComponent
                        :options="qrFullOptions"
                        hidden
                        @qrReady="getQRSource"
                      />
                    </div>
                  </div>
                  <div class="col-auto row justify-content-center">
                    <div class="col-auto text-center">
                      <ToolIcon
                        icon-name="copy"
                        icon-size="36"
                        :help-text="
                          $t(
                            '_web_copy-tournament-link-tournament-help',
                            'Copy tournament invitation link to clipboard'
                          )
                        "
                        class="mb-3"
                        @click.stop="getInviteLink"
                      />
                      <TextBlock
                        tag="div"
                        :text="
                          $t(
                            '_web_copy-tournament-link-tournament-text',
                            'Copy link'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="col-auto row justify-content-center">
                    <div class="col-auto text-center">
                      <ToolIcon
                        icon-name="social/fb"
                        icon-size="36"
                        :help-text="
                          $t(
                            '_web_share-fb-tournament-help',
                            'Share tournament link to Facebook'
                          )
                        "
                        class="mb-3"
                        @click.stop="shareLinkToFacebook"
                      />
                      <TextBlock tag="div" text="Facebook" />
                    </div>
                  </div>
                  <div class="col-auto row justify-content-center">
                    <div class="col-auto text-center">
                      <ToolIcon
                        icon-name="social/twitter"
                        icon-size="36"
                        :help-text="
                          $t(
                            '_web_share-twitter-tournament-help',
                            'Share tournament link to Twitter'
                          )
                        "
                        class="mb-3"
                        @click.stop="shareLinkToTwitter"
                      />
                      <TextBlock tag="div" text="Twitter" />
                    </div>
                  </div>
                </div>
                <!-- <TextBlock tag="div" :text="$t('_web_or', 'or')" />
                <TextBlock
                  tag="div"
                  :text="
                    $t(
                      '_web_tournaments_share_link_help_text',
                      'share the link with players you want to invite'
                    )
                  "
                />
                <Btn
                  class="btn-accent-simple mt-4"
                  :label="
                    $t('_web_invite_list_copy_invite_link', 'Copy invite link')
                  "
                  @click.stop="getInviteLink"
                /> -->
              </div>
            </div>
          </template>
        </Table>
      </Paginated>
    </div>
    <Popover
      class="table-settings-menu"
      :position="tableSettingsBtnPosition"
      :closest="$el"
      :float="['right']"
      @toggle="onMenuToggle"
    >
      <PlayersTableSettingsModal
        v-if="optionalColumns.length"
        :clan="properties.is_clan"
        :optional-columns="optionalColumns"
        @update="onSettingsModalClose"
      />
    </Popover>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, isEmpty } from '@/utils/lodashUtils';
import listMixin from '@/mixins/paginatedLists';
import shareQrCodeMixin from '@/mixins/shareQrCode';
import shareFacebookMixin from '@/mixins/shareToFacebook';
import shareTwitterMixin from '@/mixins/shareToTwitter';
import { translate } from '@/mixins/translate';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
import { TOURNAMENT_STATUSES } from '@/constants/tournaments';
import Popover from '@/components/popover';
import PlayersTableControls from '@/views/tournaments/management/participants/components/PlayersTableControls';
import PlayersTableSettingsModal from '@/views/tournaments/management/participants/components/PlayersTableSettingsModal';
import CustomFilter from '@/components/form/MultiSelectBox';
import paramsMixin from '@/mixins/queryParams';
import HeraDummyList from '@/components/common/HeraDummyList';
import Countdown from '@/components/common/Countdown';
import Btn from '@/components/atoms/HeraFormBtn';
// import InfoPanel from '@/components/molecules/InfoPanel';
import HeraBadge from '@/components/atoms/HeraBadge';
// import MultiRadio from '@/components/molecules/form/MultiRadio';
import Table from '@/components/molecules/Table';
import Widget from '@/views/tournaments/management/summaryWidgets/Widget';
import PlayersTableHeadCell from '@/views/tournaments/management/participants/components/PlayersTableHeadCell';
import PlayersTableCell from '@/views/tournaments/management/participants/components/PlayersTableCell';
import downloadFakeLink from '@/utils/downloadFile';
import PlayersTableUsernameCell from './components/PlayersTableUsernameCell';
import TextBlock from '@/components/atoms/TextBlock';
import Paginated from '@/components/common/Paginated';
import { shareLink } from '@/mixins/sharingLink';
import { TOURNAMENT } from '@/constants/accounts';
import QRCodeComponent from '@/components/common/QRCode';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';
export default {
  name: 'TournamentPlayers',
  components: {
    Paginated,
    PlayersTableControls,
    PlayersTableSettingsModal,
    CustomFilter,
    HeraDummyList,
    Popover,
    Countdown,
    Btn,
    // InfoPanel,
    HeraBadge,
    // MultiRadio,
    Table,
    Widget,
    PlayersTableHeadCell,
    PlayersTableCell,
    PlayersTableUsernameCell,
    TextBlock,
    QRCodeComponent,
    ToolIcon,
  },
  mixins: [
    ...listMixin,
    paramsMixin,
    tournamentHelpers,
    shareLink,
    ...shareQrCodeMixin,
    ...shareFacebookMixin,
    ...shareTwitterMixin,
    translate('_web_tournament_participants'),
  ],
  props: {
    tab: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      isLoading: true,
      qualifyLoading: false,
      shuffleLoading: false,
      selectedItemsAll: false,
      // total: 0,
      activeRow: null,
      optionsData: {},
      filters: {},
      players: [],
      columns: [],
      isChecked: false,
      sortFields: {
        field: null,
        order: null,
      },
      isSettingsModalActive: false,
      customFields: [],
      confirmationModal: {
        isShow: false,
        title: this.$t('_web_tournament_success_title', 'Success!'),
        subtitle: this.$t(
          '_web_tournament_success_subtitle',
          'Qualifiers list was successfully generated.'
        ),
      },
      resultShowTime: 800,
      resultShowErrorTime: 2000,
      fillRequestIconName: 'loading',
      isTotalSelected: false,
      tableSettingsBtnPosition: null,
      qualificationTypes: {
        random: this.$t('_web_tournament_additional_settings_random', 'Random'),
        first_registered: this.$t(
          '_web_tournament_additional_settings_first-registered',
          'First registered'
        ),
      },
      defaultColumns: {
        size: {
          name: 'size',
          is_visible: true,
          _tdClass: 'all-participants-table__chat-column',
          _thClass: 'all-participants-table__chat-column',
        },
        chat: {
          name: 'chat',
          is_visible: true,
          _tdClass: 'all-participants-table__chat-column',
          _thClass: 'all-participants-table__chat-column',
        },
        info: {
          name: 'info',
          is_visible: true,
          _tdClass: 'all-participants-table__chat-column',
          _thClass: 'all-participants-table__chat-column',
        },
        qualify: {
          name: 'qualify',
          is_visible: true,
          _tdClass: 'all-participants-table__qualify-column',
          _thClass: 'all-participants-table__qualify-column',
        },
        checkIn: {
          name: 'check-in',
          is_visible: true,
          _tdClass: 'all-participants-table__checkin-column',
          _thClass: 'all-participants-table__checkin-column',
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'playersTableSettings',
      'currentTournament',
      'currentTournamentStages',
      'isTournamentFieldDisabled',
      'isStageFieldDisabled',
      'hasCheckInInFirstStage',
      'isSingleStage',
      'isSingleBlock',
    ]),
    playersInfo() {
      return this.$lodash.get(this, 'currentTournament.players_info') || {};
    },
    activeTab() {
      return this.$route.params.tab || 'all';
    },
    squadSize() {
      return this.currentTournament.players_config.squad_size;
    },
    fullTeams() {
      const fullTeams = this.players.filter(
        player => player.members && player.members.length >= this.squadSize
      );
      return this.squadSize < 2 ? this.players : fullTeams;
    },
    isCheckedNew: {
      get() {
        return this.$data.isChecked;
      },
      set(value) {
        this.$data.isChecked = value;
      },
    },
    indicators() {
      return [
        /* {
          label: this.$$t('Invited'),
          // no data
          limit: 12,
          current: 2,
        }, */
        {
          label: this.$$t('Registered'),
          current: this.playersInfo.current_total,
          limit: this.currentTournament.players_config.squads,
          fraction: true,
        },
        {
          label: this.$$t('Qualified'),
          limit: this.currentTournament.players_config.squads,
          current: this.playersInfo.current_qualifiers,
        },
        {
          label: this.$$t('Reserved'),
          limit: this.playersInfo.current_total,
          current: this.playersInfo.current_reserves,
        },
        {
          label: this.$$t('Checked-in'),
          limit: this.currentTournament.players_config.squads,
          current: this.playersInfo.current_checked_in,
        },
        {
          label: this.$$t('Revoked'),
          limit: this.playersInfo.current_total,
          current: this.playersInfo.revoked,
        },
        {
          label: this.$$t('Full teams'),
          current: this.playersInfo.full_teams_total,
          limit: this.currentTournament.players_config.squads,
          fraction: true,
        },
      ];
    },
    showStub() {
      return (
        this.isDraft ||
        this.isAnnounced ||
        (this.isRegStarted &&
          !this.players.length &&
          !this.isLoading &&
          !this.filters)
      );
    },
    infoPanelText() {
      return this.isDraft || this.isAnnounced
        ? this.$$t(
            'Qualify participants who registered to your tournament (manually or automatically)',
            'hint-empty'
          )
        : this.$$t(
            'Qualify participants (manually. automatically or bulk) and make a draw',
            'hint'
          );
    },
    canInvitePlayers() {
      return this.isRegStarted || this.isAnnounced || this.isDraft;
    },
    distinctPlayersAttributes() {
      return (
        (Array.isArray(this.properties.possible_attributes) &&
          this.properties.possible_attributes.map(el => ({
            ...el,
            name: el.name
              ? this.$t(
                  `_web_tournament_players-attribute_${this.$lodash.kebabCase(
                    el.name
                  )}`,
                  el.name
                )
              : el.name,
          }))) ||
        []
      );
    },
    distinctPlayersApprovedTo() {
      return [
        {
          value: 'requested',
          id: 'requested',
          name: this.$$t('Registered'),
        },
        {
          value: 'qualified',
          id: 'qualified',
          name: this.$$t('Qualified'),
        },
        {
          value: 'reserved',
          id: 'reserved',
          name: this.$$t('Reserved'),
        },
        {
          value: 'revoked',
          id: 'revoked',
          name: this.$$t('Revoked'),
        },
      ];
    },
    checkinOptions() {
      return [
        { value: true, id: 'true', name: 'Checked-in' },
        { value: false, id: 'false', name: 'Not checked-in' },
      ];
    },
    amountFooterCounter() {
      return this.isTotalSelected ? this.totalItems : this.selectedItems.length;
    },
    actionsBtnItems() {
      const items = [
        {
          key: 'invite-people',
          title: this.$t('_web_tournament_invite-people', 'Invite People'),
          subtitle: this.$t(
            '_web_tournament_invite-people-subtitle',
            'Invite particular players you want to participate in your tournament and track invitations'
          ),
          icon: 'people',
          handler: this.goToInvites,
          disabled: false,
        },

        {
          key: 'players-export-csv',
          title: 'Export Users to CSV',
          subtitle: this.$t(
            '_web_stage_players-export-csv-tooltip',
            'All selected users will be exported to CSV file'
          ),
          icon: 'csv',
          handler: this.exportToCSV,
          disabled: !this.currentTournament.info.title,
        },
      ];
      if (
        this.hasCheckInInFirstStage &&
        this.isSingleStage &&
        this.isSingleBlock
      ) {
        items.splice(1, 0, {
          key: 'start-check-in',
          title: 'Start Check-in',
          subtitle: this.$t(
            '_web_tournament-stage-check-in-tooltip',
            'Press to manually start check-in in current stage'
          ),
          handler: this.confirmStartCheckIn,
          icon: 'check-mark',
          disabled:
            this.isLoading ||
            this.currentTournament.status !==
              TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED ||
            !this.currentTournamentStages[0].check_in_enabled ||
            (this.currentTournamentStages[0].check_in_enabled &&
              this.currentTournamentStages[0].check_in_start_date <=
                this.getNowSeconds()),
        });
      }
      return items;
    },
    isApprovedToFilterVisible() {
      return this.activeTab === 'all';
    },
    selectedItems() {
      return this.players.filter(player => player._selected);
    },
    selectedAll() {
      return this.selectedItems.length === this.players.length;
    },
    isEmptySelection() {
      return this.selectedItems.length === 0;
    },
    isIndeterminateSelection() {
      return !this.selectedAll && !this.isEmptySelection;
    },
    visibleColumns() {
      return this.columns.filter(c => c.is_visible);
    },
    memberColumns() {
      return this.properties.is_clan && this.players.length
        ? Object.keys(this.players[0].members[0].fields_data).reduce(
            (acc, el) => {
              return el === 'Avatar'
                ? acc
                : acc.concat([
                    {
                      name: el,
                      is_visible: true,
                      _tdClass: '',
                      _thClass: '',
                    },
                  ]);
            },
            []
          )
        : [];
    },
    optionalColumns() {
      return this.playersTableSettings;
    },
    qualificationType: {
      get() {
        const type = this.$lodash.get(
          this.currentTournament,
          'players_config.qualification_type',
          null
        );
        return type === 'none' ? 'first_registered' : type;
      },
      set(val) {
        this.updateCurrentTournament({
          qualification_type: val,
          component: 'players_config',
        });
      },
    },
  },
  watch: {
    tab(val) {
      this.clearPlayersState();
      this.fetchList(val);
    },
    'currentTournament.info': function() {
      this.setQrPayload();
    },
  },
  created() {
    if (this.currentTournament && this.currentTournament.info) {
      this.setQrPayload();
    }
    this.fetchColumnsSettings(this.tournamentID).then(() => {
      this.fetchTournamentCustomFieldsAll({ id: this.tournamentID })
        .then(data => {
          this.customFields = data.custom_fields;
        })
        .then(this.initializeColumns)
        .then(this.fetchList);
    });
    this.addSystemSubscriber(this.systemSubscriber);
  },
  destroyed() {
    this.removeSystemSubscriber(this.systemSubscriber);
  },
  methods: {
    ...mapActions([
      'fetchTournamentDraft',
      'fetchTournamentPlayers',
      'setTournamentPlayersExtendedData',
      'fetchCurrentTournamentAttributes',
      'errorNotify',
      'successNotify',
      'openModal',
      'closeModal',
      'fetchTournamentCustomFieldsAll',
      'startTournamentChat',
      'fetchColumnsSettings',
      'setColumnsSettings',
      'createTournamentPlayersCSV',
      'setFillParticipants',
      'fetchTournamentPlayersInfo',
      'checkInParticipant',
      'addSystemSubscriber',
      'removeSystemSubscriber',
      'startStageCheckIn',
    ]),
    setQrPayload() {
      this.qrPayload = this.generateInviteLink();
    },
    generateInviteLink() {
      return this.getSharingLink({
        type: TOURNAMENT,
        id: this.tournamentID,
        vanityId: this.currentTournament.vanity_id,
        token: this.currentTournament.info.registration_token,
      });
    },
    getInviteLink() {
      this.copyLinkToClipboard(this.generateInviteLink());
    },
    shareLinkToFacebook() {
      this.shareToFacebook({
        text: this.currentTournament.info.title,
        url: this.generateInviteLink(),
      });
    },
    shareLinkToTwitter() {
      this.shareToTwitter({
        text: this.currentTournament.info.title,
        url: this.generateInviteLink(),
      });
    },
    openRegDataModal(item) {
      this.openModal({
        name: 'PlayerRegistrationInfo',
        props: {
          playerId: item.id,
        },
        events: {
          cancel: this.closeModal,
        },
      });
    },
    confirmStartCheckIn() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-stage-check-in_confirm_modal_title',
            'This action will force check-in start. All participants will be notified about that.'
          ),
          isLoading: this.shuffleLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: () =>
            this.startStageCheckIn({
              stageID: this.currentTournamentStages[0].id,
              tournamentID: this.tournamentID,
            })
              .then(() => {
                this.successNotify(
                  this.$t(
                    '_web_tournament-stage-check-in_successful',
                    'Check-In is successfully opened'
                  )
                );
              })
              .catch(this.errorNotify)
              .finally(this.closeModal),
        },
      });
    },
    checkTotalCount(selected, item) {
      if (!selected) {
        this.isTotalSelected = false;
      }
      const index = this.players.findIndex(player => player.id === item.id);
      if (index > -1) {
        this.$set(this.players[index], '_selected', selected);
      }
    },
    setActiveRow(index) {
      const item = this.players[index];
      if (
        this.properties.is_clan &&
        !item.isAddon &&
        index !== this.activeRow
      ) {
        this.activeRow = index;
        const playersCopy = this.players.filter(item => !item.isAddon);
        const realIndex = playersCopy.findIndex(
          player => item.id === player.id
        );
        playersCopy.splice(realIndex + 1, 0, {
          members: item.members,
          isAddon: true,
          hideCheckbox: true,
        });
        this.players = this.$lodash.cloneDeep(playersCopy);
      } else {
        this.activeRow = null;
        this.players = this.players.filter(item => !item.isAddon);
      }
    },
    onSettingsModalOpen({ target }) {
      this.tableSettingsBtnPosition = target;
    },
    onMenuToggle(isVisible) {
      if (!isVisible) {
        this.tableSettingsBtnPosition = null;
      }
    },
    exportToCSV() {
      this.createTournamentPlayersCSV({
        ...this.filters,
        approved_to: this.tab === 'all' ? this.filters.approved_to : this.tab,
      }).then(res => {
        downloadFakeLink(
          new Blob([res], { type: 'text/csv' }),
          this.tournamentID
        );
      });
    },
    goToInvites() {
      this.$router.push({
        name: 'tournamentInvites',
        params: this.$route.params,
      });
    },
    goToStages() {
      this.$router.push({
        name: 'multiStage',
        params: this.$route.params,
      });
    },
    executeMenuItemHandler(item) {
      if (!item.disabled) {
        item.handler({
          ...item,
          ...item.defaultOptions,
          ...this.optionsData,
        });
      }
    },
    startChat(player) {
      this.startTournamentChat(player).catch(this.errorNotify);
    },
    getRealColumnName(name) {
      // WTF?
      // returns an element of array that equals the input parameter or input parameter
      // absolutely strange
      return Array.isArray(this.properties.columns_names)
        ? this.properties.columns_names.find(
            n => n.toLowerCase() === name.toLowerCase()
          ) || name
        : name;
    },
    initializeColumns(columns = this.optionalColumns) {
      let columnsClone = this.$lodash.cloneDeep(columns);
      const usernameColumn = columnsClone.find(c => c.name === 'username');
      usernameColumn._tdClass = 'all-participants-table__username-column';
      usernameColumn._thClass = 'all-participants-table__username-column';
      const sortedColumns = columnsClone
        .filter(c => c.name !== 'username')
        .sort((a, b) => a.index - b.index);

      columnsClone = this.properties.is_clan
        ? [
            {
              ...usernameColumn,
              name: 'Clan name',
            },
            ...sortedColumns,
          ]
        : [usernameColumn, ...sortedColumns];
      columnsClone.splice(columnsClone.length, 0, this.defaultColumns.size);
      columnsClone.splice(columnsClone.length, 0, this.defaultColumns.chat);
      columnsClone.splice(columnsClone.length, 0, this.defaultColumns.info);
      columnsClone.splice(columnsClone.length, 0, this.defaultColumns.qualify);
      if (this.hasCheckInInFirstStage) {
        columnsClone.splice(
          columnsClone.length,
          0,
          this.defaultColumns.checkIn
        );
      }
      this.columns = columnsClone;
    },
    onGridProceed() {
      this.$router.push({
        name: 'TournamentPlayoff',
        params: { tournamentID: this.$route.params.tournamentID },
      });
    },
    fetchList(pageNumber) {
      this.pageIndex = pageNumber;
      let orderByPostfix = this.sortFields.order === 'desc' ? ':desc' : '';
      return this.getPageData(
        'players',
        this.fetchTournamentPlayers({
          id: this.tournamentID,
          query: this.paramsWithPageNumber({
            order_by: this.sortFields.field
              ? `${this.sortFields.field}${orderByPostfix}`
              : null,
            ...this.filters,
            approved_to: this.filters.approved_to,
          }),
        }).then(data => ({
          ...data,
          items: data.items.map(item => ({
            ...item,
            _selected: this.isTotalSelected,
          })),
        }))
      );
    },
    clearPlayersState() {
      this.pageIndex = 0;
      this.players = [];
      this.activeRow = null;
    },
    onFilterChange() {
      this.clearPlayersState();
      // this.setQueryParams();
      this.setQueryParams({
        ...this.$lodash.cloneDeep(this.filters),
        page: this.pageIndex,
      });
      this.fetchList(this.tab);
    },

    selectAllCheckboxHandler(value) {
      this.isTotalSelected = value;
      this.players = this.players.map(player =>
        player.is_stage_added
          ? player
          : {
              ...player,
              _selected: value,
            }
      );
    },
    clearSelection(value) {
      this.selectAllCheckboxHandler(value);
      this.isTotalSelected = false;
    },
    qualifyPlayer(player) {
      this.setPlayersExtendedData([player], {
        approvedTo:
          player.fields_data.approvedTo === 'Qualified'
            ? 'Requested'
            : 'Qualified',
      });
    },

    setPlayersAttrtibutes(player, attributes) {
      this.setPlayersExtendedData([player], { attributes });
    },

    async setSelectedPlayersExtendedData(data) {
      let players = this.selectedItems;
      if (this.isTotalSelected) {
        let result = await this.fetchTournamentPlayers({
          id: this.tournamentID,
          query: {
            approved_to: this.filters.approved_to,
            page_number: 0,
            page_size: this.totalItems,
          },
        });
        players = result.items;
      }

      this.setPlayersExtendedData(players, data);
    },
    toggleCheckIn(playerData) {
      this.checkInParticipant({
        tournamentID: this.$route.params.tournamentID,
        //stageID: this.$route.params.stageID,
        playerID: playerData.id,
      })
        .then(() => {
          this.setPlayersExtendedData(
            [playerData],
            {
              checked_in: !playerData.fields_data.checked_in,
            },
            true
          );
        })
        .then(() => this.fetchTournamentDraft(this.tournamentID))
        .catch(this.errorNotify);
    },
    systemSubscriber({ type, extended }) {
      if (!extended || extended.tournament_id != this.tournamentID) {
        return;
      }

      if (type == 'tournament_stage_user_checked_in') {
        const { player_id, checked_in } = extended;
        const player = this.players.find(el => el.id === player_id);
        if (player) {
          this.setPlayersExtendedData(
            [player],
            {
              checked_in,
            },
            true
          );
          this.fetchTournamentDraft(this.tournamentID);
        }
      }
    },
    setPlayersExtendedData(players, data, localUpdate = false) {
      const playersCount = players.length;
      if (!playersCount || !data) return;

      const playersBackup = cloneDeep(this.players);

      const indexes = players.map(item =>
        this.players.findIndex(el => el.id === item.id)
      );
      const tempPlayers = cloneDeep(this.players);
      for (let i of indexes) {
        if (i > -1) {
          tempPlayers[i] = {
            ...tempPlayers[i],
            fields_data: {
              ...tempPlayers[i].fields_data,
              ...data,
            },
          };
        }
      }

      this.players =
        this.activeTab === 'all'
          ? tempPlayers
          : tempPlayers.filter(
              player =>
                player.fields_data.approvedTo.toLowerCase() === this.activeTab
            );
      if (!localUpdate) {
        this.isLoading = true;
        this.setTournamentPlayersExtendedData({
          id: this.tournamentID,
          players: players.map(player => player.id),
          ...data,
        })
          .then(() => this.fetchTournamentDraft(this.tournamentID))
          .then(() => {
            this.clearSelection();
          })
          .catch(error => {
            this.players = playersBackup;
            //this.properties = propertiesBackup;
            this.errorNotify(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    handleSort(field) {
      const transformedField = this.transformField(field);

      if (
        this.sortFields.order === 'incr' &&
        this.sortFields.field === transformedField
      ) {
        this.sortFields.order = 'desc';
      } else {
        this.sortFields.order = 'incr';
      }
      this.sortFields.field = transformedField;
      this.onFilterChange();
    },

    transformField(field) {
      const transformedField = field.replace(/\s+/g, '');
      return (
        transformedField.charAt(0).toLowerCase() + transformedField.substring(1)
      );
    },

    onSettingsModalClose(columns) {
      if (isEmpty(columns)) {
        this.isSettingsModalActive = false;
        return;
      }
      this.setColumnsSettings({
        id: this.tournamentID,
        columns,
      }).then(() => {
        let activeColumns = columns.filter(i => i.is_visible === true);
        this.isSettingsModalActive = false;
        if (activeColumns && Array.isArray(activeColumns)) {
          this.initializeColumns(columns);
          this.pageIndex = 0;
          this.players = [];
          this.activeRow = null;
          this.fetchColumnsSettings(this.tournamentID).then(this.fetchList);
        }
      });
    },
    generateFillParticipants(type) {
      this.qualifyLoading = true;
      const data = {
        include: {
          attributes_ids: [],
        },
        exclude: {
          attributes_ids: [],
        },
      };
      this.setFillParticipants({
        id: this.tournamentID,
        query: {
          approved_to: type,
        },
        data,
      })
        .then(() => {
          this.successNotify(this.confirmationModal.subtitle);
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.onFilterChange();
          this.fetchTournamentPlayersInfo(this.currentTournament.id)
            .catch(() => {
              return this.fetchTournamentDraft(this.currentTournament.id);
            })
            .finally(() => {
              this.qualifyLoading = false;
            });
        });
    },
    /*openFillModal(type) {
      this.fillModalType = type;
      this.isFillModalActive = true;
      this.isFillModalVisible = true;
    },

    startFillRequest() {
      this.isFillModalVisible = false;

      this.confirmationModal.isShow = true;
      this.confirmationModal.title = this.$t(
        '_web_tournament_loading_title',
        'Generate list of tournament players'
      );
      this.confirmationModal.subtitle = this.$t(
        '_web_tournament_loading_subtitle',
        'We are generating  qualifiers list. Please wait.'
      );
      this.fillRequestIconName = 'loading';
    },

    successFillRequest() {
      this.fillRequestIconName = 'check-mark';
      this.confirmationModal.title = this.$t(
        '_web_tournament_success_title',
        'Success!'
      );
      this.confirmationModal.subtitle = this.$t(
        '_web_tournament_success_subtitle',
        'Qualifiers list was successfully generated.'
      );
      setTimeout(() => {
        // this.fetchList();
        this.onFilterChange();
        this.closeFillModal();
        this.confirmationModal.isShow = false;
      }, this.resultShowTime);
    },

    errorFillRequest() {
      this.fillRequestIconName = 'error';
      this.confirmationModal.title = this.$t(
        '_web_tournament_error_title',
        'Ooops!'
      );
      this.confirmationModal.subtitle = this.$t(
        '_web_tournament_error_subtitle',
        'Qualifiers list was not generated - there were no participants to fill.'
      );
      setTimeout(() => {
        this.confirmationModal.isShow = false;
        this.closeFillModal();
      }, this.resultShowErrorTime);
    },
    closeFillModal() {
      this.isFillModalActive = false;
    },*/
  },
};
</script>
<style lang="scss">
input[type='checkbox'] {
  width: 20px;
  height: 20px;
  /* Remove most all native input styles */
  appearance: none;
  font: inherit;
  color: currentColor;
  border: 0.15em solid currentColor;
  border-radius: 5px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  clip-path: polygon(12% 42%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--ph-accent);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

.checkbox-label {
  padding-top: 8px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkbox-label :hover {
  cursor: pointer;
}

.tournament-participants {
  .all-participants-table {
    margin-left: 15px;
    margin-right: 15px;
    &__username-column {
      width: 230px;
    }
    &__datetime-column {
      width: 0px;
    }
    &__chat-column {
      width: 40px;
    }
    &__qualify-column {
      width: 140px;
    }
    tr.has-addon {
      background-color: $ph-card !important;
    }
  }
  .multi-radio-btn {
    margin-bottom: 0;
  }
  .hera-btn {
    min-width: 100px;
  }

  .hera-table {
    .selected-count {
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .popover {
    width: 8rem;
  }

  .popover-toggle {
    display: inline-block !important;
    background-color: transparent !important;
    color: $ph-accent !important;
    font-weight: bold;
    padding-left: 0;

    &:focus {
      box-shadow: none !important;
    }
  }

  .custom-filter {
    max-width: 12rem;
  }
  .dropdown-popover {
    min-width: 10rem !important;
  }
}
</style>
