<template>
  <div class="fallback-container">
    <div class="fallback-wrapper">
      <img src="@/assets/images/tugy.svg" alt="" />
      <span v-if="shopSearch" class="">
        {{
          $t('_web_shop_fallback_search', `Can't find "{keyword}"`, {
            keyword: shopSearch,
          })
        }}
      </span>
      <span v-if="shopSearch">
        {{
          $t(
            '_web_shop_fallback_search_another',
            'Try searching another keyword.'
          )
        }}
      </span>
      <span v-else>
        {{
          $t(
            '_web_shop_fallback_no_items',
            'Sorry, there are no items to show.'
          )
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Fallback',

  computed: {
    ...mapGetters(['shopSearch']),
  },
};
</script>

<style lang="scss" scoped>
.fallback-container {
  width: 100%;
  height: 60vh;
  //   background: radial-gradient(circle, #21212f, #151520, #0d1117, #0d1117);
  background: radial-gradient(
    circle,
    rgba(33, 33, 47, 1) 0%,
    rgb(29, 29, 41) 0%,
    rgba(13, 17, 23, 1) 50%,
    rgba(13, 17, 23, 1) 100%
  );

  img {
    margin-bottom: 0.6rem;
  }

  span:nth-child(2) {
    font-size: 16px;
  }

  .fallback-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-top: 8rem;
  }
}
</style>
