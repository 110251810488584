import {
  ACCOUNT,
  PROFILE,
  ORGANIZATION,
  CLAN,
  TOURNAMENT,
  BATTLE,
} from './accounts';
import {
  INFO,
  FEEDS,
  MY_TEAM,
  // PARTICIPANTS,
  TEAMS,
  PLAYERS,
  HASHTAGS,
  STANDINGS,
  COMPETITIONS,
  BATTLES,
  TOURNAMENTS,
  SETTINGS,
  ROSTER,
  SQUAD,
  RESULTS,
} from './router/account';

export default {
  TEAMS,
  PLAYERS,
  HASHTAGS,
  TOURNAMENTS,
  FEEDS,
  [PROFILE]: [
    { name: INFO, title: 'Info' },
    { name: FEEDS, title: 'Feeds' },
    { name: COMPETITIONS, title: 'Competitions' },
    { name: BATTLES, title: 'Battles' },
  ],
  [ORGANIZATION]: [
    { name: INFO, title: 'Info' },
    { name: FEEDS, title: 'Feeds' },
    { name: COMPETITIONS, title: 'Competitions', disabled: true },
    { name: BATTLES, title: 'Battles', disabled: true },
    { name: SETTINGS, title: 'Settings', ifAccountIsMy: true },
  ],
  [CLAN]: [
    { name: INFO, title: 'Info' },
    { name: FEEDS, title: 'Feeds' },
    // { name: TEAMS, title: 'Teams' },
    { name: COMPETITIONS, title: 'Competitions' },
    { name: BATTLES, title: 'Battles' },
  ],
  [TOURNAMENT]: [
    {
      name: BATTLES,
      title: 'My Matches',
      ifBattlesIsVisible: true,
      isDisabled: tournament =>
        !tournament.participantStatus ||
        tournament.participantStatus === 'revoked',
    },
    { name: ACCOUNT, title: 'Info' },
    { name: STANDINGS, title: 'Standings' },
    {
      name: MY_TEAM,
      title: 'Team',
      isDisabled: tournament =>
        tournament.maxPlayers < 2 ||
        (!tournament.participantStatus ||
          tournament.participantStatus === 'revoked'),
      ifTeamIsVisible: true,
    },
    // { name: PARTICIPANTS, title: 'Participants' },

    // { name: TEAMS, title: 'Teams' },
  ],
  [BATTLE]: [
    {
      name: RESULTS,
      title: 'Result',
      ifResultIsVisible: true,
      // isDisabled: battle => battle.type === 'battle_royale',
    },
    { name: ROSTER, title: 'Roster' },
    { name: SQUAD, title: 'Squad', ifSquadIsVisible: true },
    { name: INFO, title: 'Info' },
  ],
};
