var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isProfileEntity)?_c('div',{staticClass:"d-flex status-bar-user position-absolute align-items-center"},[_c('div',{staticClass:"d-flex align-items-end status-bar-user-avatar",class:{ 'cursor-pointer': _vm.account._avatar },on:{"click":_vm.avatarHandler}},[_c('Avatar',{attrs:{"type":_vm.accountType,"size":64,"img-url":_vm.account._avatar,"is-deleted":_vm.account.deleted}})],1),_c('div',{staticClass:"status-bar-user-info"},[_c('LinkAccount',{staticClass:"status-bar-user-name text-decoration-none",attrs:{"id":_vm.accountId,"type":_vm.accountType,"verified":_vm.account._verified,"vanity-id":_vm.account.vanityId,"title":_vm.account._name}},[_vm._v("\n        "+_vm._s(_vm.account._name)+"\n      ")]),(_vm.account.isProfile)?_c('span',{staticClass:"text-muted"},[_vm._v("@"+_vm._s(_vm.account._id))]):_vm._e(),(_vm.account.isClan)?_c('span',{staticClass:"text-muted text-uppercase"},[_vm._v(_vm._s(_vm.account.country))]):_vm._e()],1)]):_vm._e(),_c('EmbedBlock',{staticClass:"cover position-relative heroContainer hero-container-profile",class:[
      { active: _vm.isCoverActive, 'is-battle mb-5': _vm.account.isBattle },
      _vm.$route.path.includes('profiles') ||
      _vm.$route.path.includes('organizations') ||
      _vm.$route.path.includes('clans')
        ? 'smaller-screen-heroContainer'
        : '',
      _vm.$route.path.includes('battles')
        ? 'smaller-screen-heroContainer-battle'
        : '' ],style:(_vm.coverStyle),attrs:{"embed":{
      width: _vm.account.isTournament || _vm.account.isBattle ? 1152 : 805,
      height: _vm.account.isTournament || _vm.account.isBattle ? 600 : 290,
    }}},[(_vm.overlay)?_c(_vm.overlay,{tag:"component"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }