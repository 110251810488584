var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-wrapper"},[_c('div',{staticClass:"search-page"},[_c('div',{staticClass:"search-item-container"},[_c('div',{staticClass:"search-input-container"},[_c('div',{staticClass:"input-container-search"},[_c('HeraSearchInput',{staticClass:"search-input",attrs:{"id":"search-input","icon":"","debounce":700},on:{"input":_vm.searchData},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('div',{staticClass:"horizontal-tabs-container"},[(_vm.tabs)?_c('HorizontalTabs',{attrs:{"tabs":_vm.tabs,"active-tab":_vm.activeTab,"use-custom":""},on:{"change":_vm.setActiveTab}}):_vm._e()],1),_c('div',{staticClass:"search-results"},[(
            !_vm.isLoading &&
              _vm.activeTab !== 'feeds' &&
              (!_vm.currentList && !_vm.currentList.length)
          )?_c('HeraDummyList',{attrs:{"icon-name":"dummy/search-no-matches","size":"128"}},[_c('Title',{attrs:{"text":_vm.$t('_web_players_dummy-list', 'we couldn\'t find anything.')}}),_c('div',{},[_vm._v("\n            "+_vm._s(_vm.dummyText)+"\n          ")])],1):_c('ListPreloader',{attrs:{"is-loading":_vm.isLoading},on:{"reload":_vm.fetchData}},[_c('template',{slot:"list"},[(_vm.activeTab === 'feeds')?_c('PopularSearch',{attrs:{"loading":_vm.isLoading,"tournaments":_vm.tournamentsList,"teams":_vm.teamsList,"users":_vm.usersList,"posts":_vm.postsList},on:{"tab":_vm.setActiveTab}}):_vm._e(),(_vm.activeTab === 'feeds')?_c('TeamSearch',{attrs:{"loading":_vm.isLoading,"tournaments":_vm.tournamentsList,"teams":_vm.teamsList},on:{"tab":_vm.setActiveTab}}):_c('Paginated',_vm._b({staticClass:" d-flex flex-column py-5 pagination-search-route",on:{"change":_vm.fetchData}},'Paginated',{
                totalItems: _vm.totalItems,
                hidePagination: !_vm.currentList || !_vm.currentList.length,
                pageSize: _vm.pageSize,
                paginationProps: {
                  isDark: true,
                },
                inContainer: true,
                isLoading: _vm.isLoading,
              },false),[(_vm.activeTab === 'tournaments')?_c('ul',{staticClass:"paginated-list-search-tournaments m-0 p-0"},_vm._l((_vm.tournamentsList),function(tournament){return _c('TournamentCard',_vm._b({key:tournament.id,staticClass:"tournament-card"},'TournamentCard',{ tournament: tournament, forOrganizer: false },false))}),1):_vm._e(),(_vm.activeTab === 'players')?_c('ul',{staticClass:"paginated-list-search-players m-0 p-0"},_vm._l((_vm.currentList),function(item){return _c('SearchResultItem',{key:item.id,attrs:{"data":item}})}),1):_vm._e(),(_vm.activeTab === 'teams')?_c('ul',{staticClass:"paginated-list-search-teams m-0 p-0"},_vm._l((_vm.teamsList),function(item){return _c('TeamSearch',{key:item.id,attrs:{"data":item}})}),1):_vm._e()])],1)],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }