export const DEFAULT_SCORE = '-';
export const EMPTY_PLAYER = '-';
export const SCORE_SEPARATOR = ':';
export const WIN = 'W';
export const LOSE = 'L';

/**
 * battle/match statuses
 */
export const BATTLE_STATUSES = {
  started: 'started',
  pending: 'pending',
  completed: 'completed',
  scheduled: 'scheduled',
  cancelled: 'cancelled',
  judging: 'judging',
  upcoming: 'upcoming',
  ongoing: 'ongoing',
};

export const MATCH_STATUSES = [
  {
    name: BATTLE_STATUSES.started,
    key: '_web_battles_started',
    label: 'Started',
  },
  {
    name: BATTLE_STATUSES.pending,
    key: '_web_battles_scheduled',
    label: 'Scheduled',
  },
  {
    name: BATTLE_STATUSES.completed,
    key: '_web_battles_completed',
    label: 'Completed',
  },
  {
    name: BATTLE_STATUSES.scheduled,
    key: '_web_battles_scheduled',
    label: 'Scheduled',
  },
  {
    name: BATTLE_STATUSES.cancelled,
    key: '_web_battles_cancelled',
    label: 'Cancelled',
  },
  {
    name: BATTLE_STATUSES.judging,
    key: '_android_battle_judging',
    label: 'Dispute',
  },
  {
    name: BATTLE_STATUSES.ongoing,
    key: '_web_battles_ongoing',
    label: 'Ongoing',
  },
  {
    name: BATTLE_STATUSES.upcoming,
    key: '_web_battles_upcoming',
    label: 'Upcoming',
  },
];

/**
 * battle/match model player_{index}_result possible values
 */
export const POSSIBLE_RESULTS = {
  win: 'win',
  loss: 'loss',
  draw: 'tie',
  techWin: 'tech_win',
  techLoss: 'tech_loss',
  none: 'none',
};
