var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.columnName === 'username' ||
        _vm.columnName === 'Clan name' ||
        _vm.columnName === 'clan_name'
    )?[_c('PlayersTableUsernameCell',{attrs:{"account":Object.assign({}, _vm.row.fields_data.user,
        _vm.row),"is-clan":_vm.isClan}})]:(_vm.columnName === 'userLogin')?[_c('TextBlock',{attrs:{"text":_vm.row.id}})]:(_vm.columnName === 'check-in')?[_c('Btn',{class:_vm.row.fields_data.checked_in
          ? 'btn-secondary-simple'
          : 'btn-success-simple',attrs:{"disabled":!_vm.isStageActive ||
          !_vm.isCheckInStarted() ||
          _vm.row.fields_data.approvedTo === 'Requested',"label":_vm.getBtnTranslationByCheckInStatus(_vm.row.fields_data.checked_in)},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('checkIn', _vm.row)}}})]:(_vm.columnName === 'size')?[_c('TextBlock',{class:_vm.clanMemberSquadSize,attrs:{"text":_vm.row.members
          ? _vm.row.members.length + '/' + _vm.squadSize
          : _vm.squadSize + '/' + _vm.squadSize}})]:(_vm.columnName === 'info')?[_c('Icon',{staticClass:"cursor-pointer",attrs:{"name":"paper","width":"18","fill":_vm.$scss.secondaryTextBg},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showRegistrationData', _vm.row)}}})]:(_vm.columnName === 'chat')?[_c('Icon',{staticClass:"cursor-pointer",attrs:{"name":"chat","width":"18","fill":_vm.$scss.secondaryTextBg},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('chat', _vm.row)}}})]:(_vm.columnName === 'attributes')?[_c('PlayersTableAttributesCell',{attrs:{"attributes":_vm.row.fields_data[_vm.columnName],"all-attributes":_vm.allAttributes},on:{"attributesSet":function($event){return _vm.$emit('attributesSet', $event)}}})]:(_vm.columnName === 'applicationDate')?[_vm._v("\n    "+_vm._s(_vm._f("localDate")(_vm.row.fields_data[_vm.columnName],_vm.DATE_SHORT))+"\n  ")]:(_vm.columnName === 'applicationDatetime')?[_vm._v("\n    "+_vm._s(_vm._f("localDate")(_vm.row.fields_data['applicationDate'],_vm.DATETIME_SUBMED))+"\n  ")]:(_vm.columnName === 'applicationTime')?[_vm._v("\n    "+_vm._s(_vm._f("localDate")(_vm.row.fields_data[_vm.columnName],_vm.TIME_SIMPLE))+"\n  ")]:(_vm.columnName === 'blockIndex')?[_c('TextBlock',{attrs:{"text":_vm.getBlockByIndex(_vm.row.fields_data[_vm.columnName]).name || '-'}})]:(_vm.columnName === 'qualify')?[_c('Btn',{key:((_vm.row.id) + "-" + (_vm.row.fields_data.approvedTo) + "-qualify-btn"),staticClass:"w-100",class:_vm.isBtnActive(_vm.row) ? 'btn-accent-simple' : 'btn-secondary-simple',attrs:{"label":_vm.getBtnTranslationByStatus(_vm.row)},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('qualify', _vm.row)}}})]:(_vm.columnName === 'approvedTo')?[_c('TextBlock',{attrs:{"text":_vm.$$t(_vm.row.fields_data[_vm.columnName])}})]:[_c('TextBlock',{attrs:{"text":_vm.row.fields_data[_vm.columnName]
          ? _vm.row.fields_data[_vm.columnName]
          : _vm.row.fields_data[_vm.columnLabel]}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }