<template>
  <ol>
    <template v-for="(rule, index) in tieBreakerPresetTooltips[value]">
      <li v-if="rule.trim()" :key="`${rule}_${index}`" class="font-size-sm">
        {{ rule }}
      </li>
    </template>
  </ol>
</template>

<script>
import { TIE_GAMES } from '@/constants/tournaments';
import { translate } from '@/mixins/translate';
import { prop } from '@/utils/factories';

export default {
  name: 'TieBreakerRules',
  mixins: [translate('_web_tournament_tie-breaker-rules')],
  props: {
    value: prop(String),
  },
  data() {
    return {
      tieBreakerPresetItems: {
        [TIE_GAMES.PUBG]: this.$$t('PUBG preset:'),
        [TIE_GAMES.PUBG2]: this.$$t('PUBG v.2 preset:'),
        [TIE_GAMES.FORTNITE]: this.$$t('Fortnite preset:'),
      },
      tieBreakerPresetTooltips: {
        [TIE_GAMES.PUBG]: this.$$t(
          `
            Total accumulated kills across all Tournament Games[br]
            Best performing Tournament Game based on total points[br]
            Best performing Tournament Game based on total kills[br]
            Total kills in the last-played Tournament Game[br]
            Placement in the last-played Tournament Game[br]
          `,
          'tie-rules_pubg'
        ).split('[br]'),
        [TIE_GAMES.PUBG2]: this.$$t(
          `
            Total times of winning the first placement across all Tournament Games[br]
            Total accumulated placement points across all Tournament Games[br]
            Total accumulated kills across all Tournament Games[br]
            Placement in the most recent match of the Tournament[br]
          `,
          'tie-rules_pubg2'
        ).split('[br]'),
        [TIE_GAMES.FORTNITE]: this.$$t(
          `
            Total points scored[br]
            Total Victory Royales in the session[br]
            Average eliminations in the session[br]
            Average placement per match in the session[br]
          `,
          'tie-rules_fortnite'
        ).split('[br]'),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
ol {
  padding: 0 1rem;
}
</style>
