var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.reposted && _vm.author)?_c('div',{staticClass:"avatar-preview"},[_c('LinkAccount',{attrs:{"id":_vm.author && _vm.author._id,"type":_vm.author && _vm.author._type,"vanity-id":_vm.author && _vm.author.vanityId}},[_c('Avatar',{staticClass:"avatar-icon",attrs:{"img-url":_vm.author && _vm.author._avatar,"size":48,"type":_vm.author && _vm.author._type,"is-deleted":_vm.author && _vm.author.deleted}})],1),(_vm.isAuthorVisible && _vm.author)?_c('div',{staticClass:"row no-gutters mb-2"},[_c('div',{staticClass:"col-auto d-flex align-items-center author-spacing"},[_c('LinkAccount',{attrs:{"id":_vm.author && _vm.author._id,"type":_vm.author._type,"vanity-id":_vm.author.vanityId}},[_c('div',{staticClass:"author-name spacing-name-author"},[_c('span',{staticClass:"author-name-padding"},[_vm._v(_vm._s(_vm.author._name))]),_c('span',{staticClass:"author-tag"},[_vm._v("@"+_vm._s(_vm.author._type === 'organization' || 'clan'
                  ? _vm.author.name
                  : _vm.author.login))])]),_c('span',{staticClass:"author-time"},[_vm._v("\n            "+_vm._s(_vm._f("timeAgo")(_vm.post.created_on,_vm.$t))+"\n          ")])])],1)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"post-body",class:{ 'reposted h-pl-5 my-4': _vm.reposted }},[(_vm.reposted)?_c('div',[_c('LinkAccount',{attrs:{"id":_vm.author._id,"type":_vm.author._type,"vanity-id":_vm.author.vanityId}},[_c('div',{staticClass:"avatar-preview"},[_c('Avatar',{staticClass:"avatar-icon",attrs:{"img-url":_vm.author._avatar,"size":48,"type":_vm.author._type,"is-deleted":_vm.author.deleted}}),_c('div',{staticClass:"avatar-text"},[_c('div',{staticClass:"author-name"},[_vm._v("\n              "+_vm._s(_vm.author._name)+"\n              "),_c('span',{staticClass:"author-tag"},[_vm._v("@"+_vm._s(_vm.author._type === 'organization' || 'clan'
                    ? _vm.author.name
                    : _vm.author._id))])]),_c('span',{staticClass:"author-time"},[_vm._v("\n              "+_vm._s(_vm._f("timeAgo")(_vm.post.created_on,_vm.$t))+"\n            ")])])],1)])],1):_vm._e(),(_vm.type === _vm.GENERIC || _vm.type === _vm.POLL)?[(_vm.postText)?_c('TextContent',{staticClass:"my-3 font-size-sm",attrs:{"has-expander":"","content":_vm.postText}}):_vm._e(),(_vm.post.attachments && _vm.post.attachments.length)?[_c('MediaGrid',{attrs:{"media":_vm.post.attachments,"thumbnail":_vm.post.thumbnail}})]:(_vm.post.preview)?_c('LinkPreview',_vm._b({},'LinkPreview',Object.assign({}, _vm.post.preview),false)):_vm._e()]:_vm._e(),(_vm.type === _vm.POLL)?_c('Poll',{attrs:{"poll":_vm.post.poll}}):(
        _vm.type === _vm.TOURNAMENT_FINISHED || _vm.type === _vm.TOURNAMENT_ANNOUNCED
      )?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{
        path: ("/tournaments/" + (_vm.post.tournament.id)),
        params: { tournamentId: _vm.post.tournament.id },
      }}},[_c('Tournament',{attrs:{"tournament":_vm.post.tournament,"owner":_vm.post.author,"is-announced":_vm.type === _vm.TOURNAMENT_ANNOUNCED}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }