<template>
  <div ref="replyEl" class="post-comments replies">
    <div
      v-show="replyLoading && !inputActive"
      class="text-center my-5 block-loading"
    />
    <!--<div v-if="isUpdateCommentsVisible" class="text-center my-5">
      <ToolIcon
        icon-name="reload"
        icon-size="24"
        :help-text="$t('_web_post_update-comments', 'Update Comments')"
        @click.prevent="updateComments"
      />
    </div>-->
    <div
      v-if="inputActive"
      class="d-flex flex-column align-items-center comment-item form mt-5"
      :class="{ loading: replyLoading }"
    >
      <div class="d-flex align-items-top w-100">
        <Avatar
          :img-url="account._avatar"
          :size="34"
          :type="accountType"
          class="h-mr-4"
        />
        <div class="textarea-container">
          <TextInputMultiline
            id="replyInput"
            ref="replyTextareaEl"
            v-model="replyText"
            :placeholder="$t('_web_post_write_your_reply', 'Write your reply')"
            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
            class="w-100 px-3"
            :class="[this.$route.name === 'post' ? 'forPost' : '']"
            hide-label
            :detect-links="!linkPreview"
            :background="'new-background'"
            detect-tags
            detect-mentions
            :disable-focus="false"
            @linkDetected="debouncedPreviewRequest"
            @keyup.enter.exact.stop="publishComment"
            @keydown.enter.exact.prevent="newLineFix"
            @keyup.esc="resetForm"
          />
          <div class="textarea-bottom">
            <!-- v-if="$refs.replyEl"  goes in editableattachments but breaks image upload-->
            <div class="textarea-media-actions">
              <EditableAttachments
                v-if="editableRender && $refs.replyEl"
                :files="attachments"
                name="editable/upload"
                :icon-size="19"
                :parent-el="$refs.replyEl"
                :insert-before="true"
                class="comment-input-action"
                @input="prepareAttachData"
              />
              <div
                ref="gif"
                class="d-flex align-items-center position-relative"
              >
                <AppIcon
                  ref="gifPick"
                  name="editable/attach-gif"
                  width="19"
                  class="comment-input-action-gif"
                  @click="handleShowGiffs"
                />
                <GiffsPicker
                  v-if="showGiffs"
                  v-clickOutside="{
                    exclude: 'gif',
                    handler: 'closeGiffPicker',
                  }"
                  class="gif-emoji-rotate"
                  :class="[
                    this.$route.name === 'post'
                      ? 'forPost-gif'
                      : this.$route.name === 'feed'
                      ? 'feedEmoji'
                      : 'goUp',
                  ]"
                  @addGiff="publishGiff"
                />
              </div>
              <div
                ref="smile"
                class="d-flex align-items-center position-relative"
              >
                <AppIcon
                  ref="emoji"
                  name="editable/emoji"
                  width="19"
                  class="comment-input-action-emoji"
                  @click="handleShowEmoji"
                />
                <EmojiPicker
                  v-if="showEmoji"
                  v-clickOutside="{
                    exclude: 'smile',
                    handler: 'closeEmojiPicker',
                  }"
                  class="gif-emoji-rotate"
                  :class="[
                    this.$route.name === 'post'
                      ? 'forPost-emoji'
                      : this.$route.name === 'feed'
                      ? 'feedEmoji'
                      : 'goUp',
                  ]"
                  @select="addEmoji"
                />
              </div>
            </div>
            <Btn class="btn-primary" @click="publishComment">
              {{ $t('_web_reply', 'Reply') }}
            </Btn>
          </div>
        </div>
      </div>
    </div>

    <div ref="replies">
      <Comment
        v-for="item in comments"
        :id="item.id"
        :key="item.id"
        :comment="item"
        :post-id="postId"
        :is-reply="true"
        @reply="addReplyMention"
        @delete="removeComments"
        @refresh="updateComments"
      />
    </div>
    <div v-show="nextPageToken" ref="more" class="text-center my-5">
      <Btn
        class="btn-accent-border text-capitalize"
        @click.prevent="getComments"
      >
        {{ $t('_web_text-content_show_more', 'Show More') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Avatar from '@/components/images/Avatar';
import Btn from '@/components/atoms/HeraFormBtn.vue';
// import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';
import EditableAttachments from '@/components/form/contentEditable/Attachments';
import AppIcon from '@/components/atoms/Icon';
import EmojiPicker from '@/components/common/EmojiPicker';
import GiffsPicker from '@/components/common/GiffsPicker';
import TextInputMultiline from '@/components/molecules/form/TextInputMultilineWithActivators';

export default {
  name: 'Replies',
  components: {
    Btn,
    Avatar,
    AppIcon,
    // ToolIcon,
    EmojiPicker,
    GiffsPicker,
    EditableAttachments,
    TextInputMultiline,
  },
  props: {
    inputActive: {
      type: Boolean,
      required: true,
    },
    postId: {
      type: String,
      required: true,
    },
    comment: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showEmoji: false,
      showGiffs: false,
      comments: [],
      attachments: [],
      replyText: '',
      nextPageToken: '',
      page_size: 20,
      willShowReplies: false,
      replyLoading: false,
      linkPreview: null,
      debouncedPreviewRequest: null,
      editableRender: false,
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId', 'accountType']),
    isUpdateCommentsVisible() {
      return !this.isLoading && this.willShowReplies;
    },
    isBtnSendActive() {
      return !!(this.replyText.trim().length || this.attachments.length);
    },
  },
  watch: {
    comment(val) {
      if (val.id) {
        this.restoreCommentsDefaults();
        if (this.$route.params.id) {
          this.showComments(this.comment.comments_count);
        }
      }
    },
  },
  beforeCreate: function() {
    this.$options.components.Comment = require('./CommentItem.vue').default;
  },
  created() {
    if (this.quantity > 0) this.getComments();

    if (this.$route.params.id) {
      this.showComments(this.comment.comments_count);
    }
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
  },
  mounted() {
    this.$nextTick(() => {
      this.editableRender = true;
    });
  },
  methods: {
    handleShowEmoji() {
      this.showEmoji = !this.showEmoji;
      if (this.showGiffs) {
        this.showGiffs = !this.showGiffs;
      }
    },
    handleShowGiffs() {
      if (this.showEmoji) {
        this.showEmoji = !this.showEmoji;
      }
      this.showGiffs = !this.showGiffs;
    },
    newLineFix(e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    ...mapActions([
      'getOpenGraphDataByLink',
      'fetchComments',
      'postComment',
      'errorNotify',
      'successNotify',
    ]),

    getOpenGraphData(link) {
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },

    restoreCommentsDefaults() {
      this.nextPageToken = '';
      this.comments = [];
      this.isLoading = false;
      this.replyLoading = false;
      this.willShowReplies = false;
    },

    showComments(list) {
      if (!this.willShowReplies && list > 0) {
        this.comments = [];
        this.getComments();
      }

      if (this.willShowReplies) {
        this.restoreCommentsDefaults();
      } else {
        this.willShowReplies = true;
      }
    },

    getComments() {
      if (this.nextPageToken !== null) {
        this.toggleCommentLoading();
        this.isLoading = true;

        const data = {
          type: 'post',
          id: this.postId,
          threadId: this.comment.id,
          page: this.nextPageToken,
          page_size: this.page_size,
        };

        this.fetchComments(data)
          .then(res => {
            this.comments = this.$lodash.uniqBy(
              [...res.items, ...this.comments],
              'id'
            );
            this.nextPageToken = res.next_page_token;
            this.page_size = 15;
            this.isLoading = false;
            this.toggleCommentLoading();
          })
          .catch(err => {
            this.errorNotify(err);
            this.isLoading = false;
            this.toggleCommentLoading();
          });
      }
    },

    prepareAttachData(data) {
      if (Array.isArray(data)) {
        this.attachments = data;
      }
    },
    publishGiff(id, token) {
      const displayGiff = {
        type: 'giphy',
        id,
        token,
      };
      this.attachments = [token];
      this.publishComment(displayGiff);
      this.showGiffs = false;
    },
    publishComment(gif) {
      if (!this.replyLoading) {
        if (!this.isBtnSendActive) {
          const textareaEl = this.$lodash.get(
            this.$refs,
            'replyTextareaEl.$refs.textarea'
          );
          if (textareaEl) {
            textareaEl.focus();
          }
          return;
        }
        this.toggleCommentLoading();
        const text = this.replyText.substring(0, 1000); // 1000 is symbol limit

        const data = {
          display: {
            attachments: gif.type === 'giphy' ? [gif] : this.attachments,
            last_updated_on: Math.floor(new Date().getTime() / 1000),
            text: text,
            author: {
              name: this.account._name,
              login: this.accountId,
              avatar: this.account._avatar,
            },
            reactions: {
              like: { count: 0, reacted: false },
              dislike: { count: 0, reacted: false },
            },
          },

          send: {
            type: 'post',
            id: this.postId,
            threadId: this.comment.id,
            attachments: this.attachments.map(item => item.fileId || item),
            text,
          },
        };

        this.postComment(data.send)
          .then(res => {
            data.display.id = res.commentId;
            this.comments = [data.display, ...this.comments];
            this.comment.comments_count++;
            this.resetForm();
            this.$emit('replyAdded');
          })
          .then(this.toggleCommentLoading)
          .then(() => {
            document
              .getElementById(data.display.id)
              .scrollIntoView({ block: 'center', behavior: 'smooth' });
          })
          .catch(this.errorNotify);
      }
    },
    resetForm() {
      this.replyText = '';
      this.attachments = [];
      this.closeEmojiPicker();
      this.closeGiffPicker();
      this.$emit('toggleAddReply', false);
      this.getComments();
    },
    multiline(val) {
      return val.replace(/(?:\r\n*)|\n/gm, '<br>');
    },
    async addEmoji(emoji) {
      this.replyText = this.replyText + emoji.native;
    },
    closeEmojiPicker() {
      this.showEmoji = false;
    },
    closeGiffPicker() {
      this.showGiffs = false;
    },
    toggleCommentLoading() {
      this.replyLoading = !this.replyLoading;
    },
    addSelectionForMentionInReply() {
      this.$nextTick(() => {
        this.$refs.replyTextareaEl.focus();
      });
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNode(this.$refs.replyTextareaEl.$el);
      selection.addRange(range);
    },
    async addReplyMention(login) {
      await this.$emit('toggleAddReply', true);
      await this.addSelectionForMentionInReply();

      const node = document.createElement('a');
      node.href = `${
        this.account.type === 'profile' ? 'profile' : 'organization'
      }://${this.account.type === 'profile' ? login.login : login.login}`;
      node.innerText = `${String.fromCharCode(0x200e)}@${login.name}`;
      this.$refs.replyTextareaEl.put(node, 1);
    },
    removeComments(comment) {
      this.comments = this.comments.filter(item => item.id !== comment.id);
      this.comment.comments_count--;
    },
    updateComments() {
      this.comments = [];
      this.nextPageToken = '';
      this.getComments();
    },
  },
};
</script>

<style scoped lang="scss">
.replies {
  position: relative;
  // @include auto-rtl(margin-left, $ph-large-space);

  @media (max-width: 1100px) {
    // @include auto-rtl(margin-left, 1rem);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    @include auto-rtl(left, -20px);
    width: 1px;
    height: 100%;
    background-color: var(--ph-main);
    display: none;
  }

  .cancel-btn {
    background: none;
    background-color: transparent;
    border: none;
    border-color: none;
    border-radius: 0;
    width: 0;
    margin-top: 5px;
    margin-right: 16px;
    color: $hr-text-muted;
    font-size: $hr-text-tiny;
    &:active {
      background: none;
    }
    .app-rtl & {
      margin-right: 4px;
    }
  }

  .text-input-reply {
    width: 100%;
    height: 83px;
    background: none;
    align-items: flex-start;
    border-color: none;
    color: $hr-text-primary;
    justify-content: flex-start;
    background-color: $hr-bg-search;
    border: none;
    border-radius: 5px;
    vertical-align: text-top;
    .app-rtl & {
      // transform: scaleX(-1);
    }
    &::placeholder {
      position: absolute;
      top: 8px;
      left: 13px;
      .app-rtl & {
        right: 10px;
      }
    }
  }

  .textarea-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background-color: $hr-bg-search;
    border-radius: 5px;
    border: 2px solid #4b5672;

    .app-rtl & {
      // transform: scaleX(-1);
    }
  }

  textarea {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    .app-rtl & {
      left: 14px;
      padding-right: 8px;
    }
  }

  .comment-input-action-gif {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    .app-rtl & {
      // transform: scaleX(-1);
    }
  }

  .comment-input-action-emoji {
    cursor: pointer;
  }

  .add-reply {
    align-self: start;
  }

  .btn-primary {
    font-weight: 400;
    bottom: 0;
    right: 0;
    background: none;
    border: none;
    border-color: none;
    border-radius: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    &:active {
      background: none;
    }
    .app-rtl & {
      // transform: scaleX(-1);
    }
  }

  .gif-emoji-rotate {
    position: absolute;
    top: 30px;
    left: 0;
    .app-rtl & {
      // transform: scaleX(-1);
    }
  }

  .comment-input-action {
    &:active {
      background: none;
    }
    .app-rtl & {
      // transform: scaleX(-1);
    }
  }
  .btn-outline-secondary {
    border: none;
    background: none;
    &:active {
      background: none;
      border: none;
    }
  }
  .textarea-bottom {
    display: flex;
    justify-content: space-between;
  }

  .textarea-media-actions {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    border-bottom: 2px solid $hr-text-accent;
  }

  .block-loading {
    height: 50px;
    @include spinner(1.5rem);
  }
}
.goUp {
  position: absolute !important;
  top: -411px !important;
}
.forPost {
  display: flex;
  width: 100% !important;
  justify-self: stretch !important;
}
.forPost-gif {
  width: 285px !important;
  left: -75px !important;
  top: -410px !important;
}
.forPost-emoji {
  width: 285px !important;
  left: -100px !important;
  top: -410px !important;
}
.feedEmoji {
  top: -410px !important;
}
.comment-input-action {
  margin: 0px !important;
}

::v-deep .textarea {
  background-color: #181c25 !important;
}

::v-deep .text-input-container {
  // margin-right: 5% !important;
  background-color: #181c25 !important;
}
::v-deep .text-truncate {
  background-color: #181c25 !important;
}
::v-deep .textarea-container {
  background-color: #181c25 !important;
  // max-width: 60vw !important;
}
::v-deep .textarea-bottom {
  background-color: #181c25 !important;
}
</style>
