import { mapActions, mapGetters } from 'vuex';
import { MAIN_NEWSFEED } from '@/constants/router/mains';
import { POST } from '@/constants/router/';
import * as ACCOUNT from '@/constants/accounts';
const POST_TYPE = 'post';

// Metric tracking model
// {
//   type: post | profile | clan | organization | tournament (mandatory)
//   id: xzzxzxzxzzx (mandatory)
//   source: newsfeed | wall | list | details | clan | profile
//     (optional; profile, clan, organization: null; tournament: list or details or clan or profile)
//   client: ios | android | web (mandatory)
//   action: view | share | fb_share | twitter_share
// }
const ACTION_SOURCES = {
  NEWSFEED: 'newsfeed',
  WALL: 'wall',
  PROFILE: 'profile',
  CLAN: 'clan',
  DETAILS: 'details',
  LIST: 'list',
};
export default {
  computed: {
    ...mapGetters(['isAuthorized']),
  },
  methods: {
    ...mapActions(['handleViewTrack', 'errorNotify']),
    handleScrollIntoViewForViewsTracking(isVisible, entityId, entityType) {
      if (isVisible) {
        this.trackViewAction(entityId, entityType);
      }
    },
    getActionSourceByEntityType(type) {
      const isNewsfeedRoute = this.$route.name === MAIN_NEWSFEED;
      const isPostPage = this.$route.name === POST;
      const canParticipateInTournament =
        this.$route.params.accountType === ACCOUNT.PROFILE ||
        this.$route.params.accountType === ACCOUNT.CLAN;
      const isAllTournamentsRoute = this.$route.name === 'tournamentsList';
      const isPost = type === POST_TYPE;
      const isTournament = type === ACCOUNT.TOURNAMENT;
      return isPost && isNewsfeedRoute
        ? ACTION_SOURCES.NEWSFEED
        : isPost && isPostPage
        ? ACTION_SOURCES.DETAILS
        : isPost
        ? ACTION_SOURCES.WALL
        : isTournament && isAllTournamentsRoute
        ? ACTION_SOURCES.LIST
        : isTournament && canParticipateInTournament
        ? this.$route.params.accountType
        : isTournament
        ? ACTION_SOURCES.DETAILS
        : null;
    },
    trackViewAction(entityId, entityType) {
      if (!this.isAuthorized) return;
      const source = this.getActionSourceByEntityType(entityType);
      return this.handleViewTrack({
        type: entityType,
        entityId,
        client: 'web',
        source,
        action: 'view',
      }).catch(this.errorNotify);
    },
    trackShareAction(entityId, entityType, action = 'share') {
      const source = this.getActionSourceByEntityType(entityType);
      return this.handleViewTrack({
        type: entityType,
        entityId,
        client: 'web',
        source,
        action,
      }).catch(this.errorNotify);
    },
  },
};
