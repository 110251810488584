import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export const vanityUrlEdit = {
  inject: ['$validator'],
  computed: {
    ...mapGetters(['accountEditData']),
    ...mapState('current', ['account']),
    accountVanityId: {
      get() {
        return this.accountEditData.vanity_id;
      },
      set(id) {
        id = id.replace(/[<>=" ]/g, '');
        this.updateEditData({
          vanity_id: id,
        });
      },
    },
  },
  methods: {
    ...mapMutations(['updateEditData']),
    ...mapActions(['checkVanityUrlForExisting']),
    checkVanityId() {
      if (
        this.accountVanityId &&
        this.accountVanityId !== this.account.vanityId
      ) {
        this.checkVanityUrlForExisting(this.accountVanityId)
          .then(() => {
            this.$validator.errors.add({
              field: 'vanity_id',
              msg: this.$t(
                '_web_entity-edit-vanity-taken-error',
                '{field} is already taken',
                {
                  field: this.$t(
                    '_web_entity-edit-vanity-id-label',
                    'Vanity url'
                  ),
                }
              ),
              rule: 'custom',
            });
          })
          .catch(() => {
            this.errors.remove('vanity_id');
          });
      }
    },
  },
};

export default [vanityUrlEdit];
