const focus = {
  // When the bound element is inserted into the DOM...
  inserted: function(el) {
    // Focus the element
    if (el.hasAttribute('autofocus')) {
      el.focus();
    }
  },
};

let handleOutsideClick;
const clickOutside = {
  bind(el, binding, vnode) {
    handleOutsideClick = e => {
      e.stopPropagation();
      if (!el) return;
      // Get the handler method name and the exclude array from the object used in v-clickOutside
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      if (!clickedOnExcludedEl) {
        const excludedEl = vnode.context.$refs[exclude];
        clickedOnExcludedEl = excludedEl.contains(e.target);
      }
      //  check if the clicked element is not the picker element and not excluded
      if (!(el === e.target || el.contains(e.target)) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog and not the button, then call the outside-click handlerfrom the same component this directive is used in
        vnode.context[handler]();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },
  unbind() {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
};

export default {
  focus,
  clickOutside,
};
