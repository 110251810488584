import api from '@/api';
import { cloneDeep, isEmpty } from '@/utils/lodashUtils';

import { CONVERSATION_TYPE_GROUP } from '@/utils/getProps';

export const INITIAL_SlIDERCHAT_STATE = () => {
  return {
    currentChatId: '',
    currentChatParticipantsUpdated: false,
    sliderChatIsShow: false,
    sliderChatState: 'chatsList',
    sliderChatActiveChat: null,
    sliderChatIsLoaderShow: false,
    unreadCount: 0,
  };
};

const getters = {
  currentChatId: state => state.currentChatId,
  currentChatParticipantsUpdated: state => state.currentChatParticipantsUpdated,
  sliderChatIsShow: state => state.sliderChatIsShow,
  sliderChatState: state => state.sliderChatState,
  sliderChatActiveChat: state => state.sliderChatActiveChat,
  sliderChatIsLoaderShow: state => state.sliderChatIsLoaderShow,
  sliderChatUnreadCount: state => state.unreadCount,
};

const mutations = {
  reloadSliderChatState: state =>
    Object.assign(state, INITIAL_SlIDERCHAT_STATE()),
  setCurrentChatId: (state, id) => (state.currentChatId = id),
  clearCurrentChatId: state => (state.currentChatId = ''),
  setCurrentChatParticipantsUpdated: (state, updated) =>
    (state.currentChatParticipantsUpdated = updated),
  showSliderChat: state => (state.sliderChatIsShow = true),
  hideSliderChat: state => (state.sliderChatIsShow = false),
  toggleSliderChat: state => (state.sliderChatIsShow = !state.sliderChatIsShow),
  setSliderChatState: (state, sliderChatState) =>
    (state.sliderChatState = sliderChatState),
  setSliderChatActiveChat: (state, chat) => (state.sliderChatActiveChat = chat),
  setSliderChatUnreadCount: (state, count) => (state.unreadCount = count),
  clearSliderChatActiveChat: state => (state.sliderChatActiveChat = null),
  openConversationInSliderChat: (state, chat) => {
    if (!isEmpty(chat)) {
      state.sliderChatIsShow = true;
      state.sliderChatActiveChat = chat;
      state.currentChatId = chat.id;
      state.sliderChatState = 'chat';
    }
  },
  openConversationInSliderChatEditor: (
    state,
    { conversation, predefinedTitle, predefinedSubtitle }
  ) => {
    if (!isEmpty(conversation)) {
      state.sliderChatIsShow = true;
      state.sliderChatActiveChat = cloneDeep(conversation);
      state.sliderChatState =
        conversation.conversation_type === 'group_chat'
          ? 'addGroupChat'
          : 'addChannel';
      state.sliderChatActiveChat.title = predefinedTitle;
      state.sliderChatActiveChat.subtitle = predefinedSubtitle;
      state.currentChatId = conversation.id;
    }
  },
  setCurrentConversationTitle: (state, title) =>
    (state.sliderChatActiveChat.title = title),
  setCurrentConversationAvatar: (state, avatar) =>
    (state.sliderChatActiveChat.avatar = avatar),
  sliderChatToggleLoader: (state, visible) =>
    (state.sliderChatIsLoaderShow = visible),
};

const actions = {
  fetchCurrentChatParticipants({ getters: { accessToken, currentChatId } }) {
    if (!currentChatId) {
      return Promise.resolve().then(() => ({ items: [] }));
    } else {
      // TODO: change endpoint after implement on back https://isddesign.atlassian.net/browse/TH-7194
      // blocked by https://isddesign.atlassian.net/browse/TH-7195
      return api.conversations
        .getConversation(currentChatId)(accessToken, {
          params: {
            includeParticipants: true,
          },
        })
        .then(res => res.data.participants);
    }
  },

  fetchCurrentChatPossibleParticipants(
    {
      getters: { accessToken, currentTournament, currentChatId },
    },
    params
  ) {
    return api.tournaments
      .getTournamentConversationAvailablePlayers(currentTournament.id)(
        accessToken,
        {
          params: {
            ...params,
            ConversationId: currentChatId,
          },
        }
      )
      .then(res => res.data);
  },

  addAllPossibleParticipantsToCurrentChat(
    {
      getters: { accessToken, currentTournament, currentChatId },
    },
    payload
  ) {
    return api.tournaments
      .addAllTournamentConversationAvailablePlayers(currentTournament.id)(
        accessToken,
        {
          ...payload,
          ConversationId: currentChatId,
        }
      )
      .then(res => {
        // commit('setCurrentChatParticipantsUpdated', true);
        return res.data;
      });
  },

  addParticipantToCurrentChat(
    {
      getters: { accessToken, currentChatId },
    },
    participant
  ) {
    const participantId = participant.id || participant.login;
    return api.conversations
      .addParticipantToConversation(currentChatId, participantId)(accessToken)
      .then(res => res.data);
  },

  startTournamentChat(
    {
      commit,
      dispatch,
      getters: { accessToken, currentTournament },
    },
    payload
  ) {
    const participants = (payload instanceof Array ? payload : [payload]).map(
      ({ id }) => id
    );
    return dispatch('createTournamentConversation', {
      tournamentID: currentTournament.id,
      type: CONVERSATION_TYPE_GROUP,
      visible: false,
      participants,
    })
      .then(({ id }) => api.conversations.getConversation(id)(accessToken))
      .then(res =>
        commit('openConversationInSliderChatEditor', { conversation: res.data })
      );
  },
};

export default {
  state: INITIAL_SlIDERCHAT_STATE(),
  getters,
  mutations,
  actions,
};
