<template>
  <div>
    <HeraFormDropdown
      v-model="bestOfLower"
      v-validate="'required'"
      name="bestOfLower"
      :label="$$t('Best of (Lower):', 'best-of-lower_label')"
      :list="bestOfList"
      :class="{ 'is-invalid': errors.first('bestOfLower') }"
      variant="input-like"
      :view-mode="disabled"
      :disabled="isStageFieldDisabled('bestOfLower') || disabled"
      required
      :error="errors.first('bestOfLower')"
      :empty-text="$$t('Choose option', 'best-of-lower-placeholder')"
      :tooltip="
        $$t(
          'How many games within one match must be played between opponents (except a final match)',
          'best-of-lower-tooltip'
        )
      "
      show-label
      empty-option
    >
      <template slot="chosen" slot-scope="chosen">
        {{ chosen.chosenItem }}
      </template>

      <template slot="item" slot-scope="{ item }">
        {{ item }}
      </template>
    </HeraFormDropdown>
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormDropdown from '@/components/molecules/form/HeraFormDropdown';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageBestOfLowerSetting',
  components: {
    HeraFormDropdown,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    bestOfLower: {
      get() {
        return this.getBlockConfigurationByKey('best_of_lower');
      },
      set(newVal) {
        this.setBlockConfigurationByKey('best_of_lower', +newVal);
      },
    },
    bestOfLabel() {
      return this.isDoubleElimination
        ? this.$$t('Best of (Upper):', 'best-of-upper_label')
        : this.$$t('Best of:', 'best-of_label');
    },
    bestOfList() {
      return this.stageDefaults[this.tieMatchAllowed ? 'bestOfTie' : 'bestOf'];
    },
  },
};
</script>

<style></style>
