import NotificationsWrapper from '@/views/notifications/';
import Notifications from '@/views/notifications/list';

export default [
  {
    path: '/notifications',
    component: NotificationsWrapper,
    children: [
      {
        path: '/notifications/:id?',
        name: 'notifications',
        component: Notifications,
        meta: {
          inMainNav: false,
          inMainNavOrg: false,
          titleKey: '_web_left-sidebar_nav-notifications-link',
          icon: 'chat',
          fallbackLabel: 'Notifications',
          // badgeWatcher: 'NotificationsUnreadCount',
        },
      },
    ],
  },
];
