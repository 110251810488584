<template>
  <div
    class="container-fluid p-0 font-size-xs layout-wrapper main-layout-wrapper"
    :class="[{ 'navbar-is-open': navbarIsOpen }, localeDirection, $route.name]"
  >
    <div class="d-flex flex-column h-100">
      <div class="d-flex flex-grow-1 layout-content-wrapper">
        <div class="main-nav-wrapper position-relative">
          <div class="sticky-top">
            <div class="logo-wrapper mb-2 cursor-pointer">
              <router-link to="/" tag="div" class="h-pl-4 d-flex">
                <HeraLogo :type="navbarIsOpen ? 'full' : 'short'" animated />
                <!-- uncomment for MENA
                <Icon
                v-if="navbarIsOpen"
                class="zain-logo h-ml-auto"
                name="powered-zain"
                width="34"
                />
              -->
              </router-link>
            </div>
            <HeraSearchInput
              id="sidebar-search"
              v-model="searchQuery"
              :disabled="$router.currentRoute.name === 'search'"
              class-input="main-nav-search mb-4"
              @enter="searchRedirect"
            />
            <LeftSidebar
              v-if="myAccountFetched"
              :key="appId"
              :collapsed="!navbarIsOpen"
            />
            <div
              class="toggle-sidenav-icon-wrapper d-flex align-items-center"
              @click="toggleNav"
            >
              <Icon
                :rotate="toggleRotateAngle"
                class="toggle-sidenav-icon"
                name="arrow-s"
                width="10"
              />
              <div
                v-if="navbarIsOpen"
                class="toggle-sidenav-label text-nowrap font-size-xs secondary-font h-ml-4"
              >
                {{ $t('_web_toggle_collapse_panel', 'Collapse panel') }}
              </div>
              <!-- <Icon
          v-else
          class="toggle-sidenav-icon"
          name="menu-collapsed"
                width="30"
                :fill="$scss.secondaryTextColor"
                @click.native="toggleNav"
                /> -->
            </div>
          </div>
        </div>
        <div class="flex-grow-1 main-content-wrapper position-relative">
          <div class="main-content d-flex flex-column flex-grow-1 h-100">
            <HeraNavbar
              v-if="myAccountFetched"
              :key="appId"
              :class="{
                'highlights-navbar-style': $route.path.includes('posts'),
              }"
            />
            <div class="flex-grow-1 content">
              <slot v-if="myAccountFetched" />
            </div>
            <div
              v-if="
                (profileOpen && isAuthorized) ||
                  (notificationOpen && isAuthorized)
              "
              class="overlay-profile-open"
              @click="setProfileOpen"
            ></div>
            <div
              v-if="guestOpen && !isAuthorized"
              class="overlay-profile-open"
              @click="setGuestOpen"
            ></div>
            <div
              v-if="isFooterVisible"
              :class="{
                'highlights-navbar-style': $route.path.includes('posts'),
              }"
              class="footer-wrapper"
            >
              <div class="row">
                <portal-target
                  name="footer-portal"
                  class="d-flex w-100 justify-content-center"
                >
                </portal-target>
              </div>
              <div class="outer-wrap-side-profile">
                <div
                  v-if="isAuthorized"
                  ref="notifications"
                  class="side-profile-wrap custom-scrollbar"
                  :class="notificationOpen ? 'show-side-profile' : ''"
                >
                  <notificationSidebar v-if="isAuthorized" />
                </div>
              </div>
              <div class="outer-wrap-side-profile">
                <div
                  v-if="isAuthorized"
                  ref="profile"
                  class="side-profile-wrap custom-scrollbar"
                  :class="
                    profileOpen && account.country !== null
                      ? 'show-side-profile'
                      : ''
                  "
                >
                  <userProfileSidebar v-if="isAuthorized" />
                </div>
                <div
                  v-if="!isAuthorized"
                  ref="profile"
                  class="side-profile-wrap custom-scrollbar"
                  :class="guestOpen ? 'show-side-profile' : ''"
                >
                  <guestSidebar v-if="!isAuthorized" />
                </div>
              </div>
              <HeraDesktopFooter
                v-if="$route.path !== '/feed'"
                class="desktop-footer"
                :show-region-switcher="true"
              />
              <HeraMobileFooter
                v-if="isAuthorized && !$route.path.includes('profile-setup')"
                :class="{
                  'highlights-navbar-style': $route.path.includes('posts'),
                }"
                class="mobile-footer"
              />
            </div>
            <div v-if="!isAuthorized && contentLoaded" class="cta-message">
              <div class="cta-message-text">
                <h3>
                  {{ $t('_web_join', 'Join PLAYHERA') }}
                </h3>
                <p>
                  {{
                    $t(
                      '_web_cta_message_1',
                      'Play your favorite games anywhere and at any time.'
                    )
                  }}
                </p>
              </div>
              <div class="cta-message-button">
                <router-link
                  :to="{ path: `/signin?redirectTo=${$route.fullPath}` }"
                >
                  <button class="cta-message-button-login">
                    {{ $t('_web_log_in', 'Log In') }}
                  </button>
                </router-link>
                <router-link :to="{ name: 'signup' }">
                  <button class="cta-message-button-create">
                    {{ $t('_web_create_account_btn', 'Create Account') }}
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isAuthorized && myAccountFetched">
      <Hub :key="hubKey" />
      <PopupNotifications />
      <SliderChat v-if="sliderChatIsVisible" :key="`slider-chat_${appId}`" />
      <PopupChat v-if="popoverChatIsVisible" :key="`popup-chat_${appId}`" />
      <transition name="translate-y">
        <HubReloader v-if="isHubReloadRequired" />
      </transition>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import HeraLogo from '@/components/atoms/HeraLogo.vue';
import HeraNavbar from '@/components/navbar/HeraNavbar';
import HeraDesktopFooter from '@/components/footer/HeraDesktopFooter';
import HeraMobileFooter from '@/components/footer/HeraMobileFooter';
import LeftSidebar from '@/components/organisms/LeftSidebar.vue';
import Icon from '@/components/atoms/Icon';
import Hub from '@/components/common/Hub';
import PopupNotifications from '@/views/notifications/Popup';
import PopupChat from '@/views/conversations/Popup';
import SliderChat from '@/views/sliderchat/index';
import HeraSearchInput from '@/components/molecules/form/HeraSearchInput';
import notificationSidebar from '@/views/sidebars/notificationSidebar.vue';
import userProfileSidebar from '@/views/sidebars/userProfileSidebar';
import guestSidebar from '@/views/sidebars/guestSidebar';
const HubReloader = () => import(`@/components/common/HubReloader`);
import routeHelpers from '@/mixins/routeHelpers';
import { getMetaValue } from '@/views/tournaments/utils/meta';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';

const NAVBAR_IS_COLLAPSED_KEY = 'leftNavbarIsCollapsed';

export default {
  name: 'MainLayout',
  components: {
    HeraNavbar,
    HeraDesktopFooter,
    HeraMobileFooter,
    LeftSidebar,
    HeraLogo,
    Icon,
    Hub,
    PopupNotifications,
    HeraSearchInput,
    PopupChat,
    SliderChat,
    HubReloader,
    userProfileSidebar,
    notificationSidebar,
    guestSidebar,
  },
  mixins: [routeHelpers],
  data() {
    return {
      navbarIsOpen: true,
      isCreated: false,
      myAccountFetched: false,
      searchQuery: '',
      contentLoaded: false,
    };
  },
  computed: {
    ...mapGetters([
      'localeDirection',
      'hasCover',
      'hubKey',
      'isAuthorized',
      'appId',
      'isRTL',
    ]),
    ...mapGetters('sidebars', ['profileOpen', 'notificationOpen', 'guestOpen']),
    ...mapState('sidebars', ['profileOpen', 'notificationOpen', 'guestOpen']),
    ...mapState({
      isHubReloadRequired: state => state.notification.isHubReloadRequired,
    }),
    ...mapState('my', ['account']),
    isFooterVisible() {
      return !getMetaValue(this.$route, 'hideFooter');
    },
    sliderChatIsVisible() {
      return (
        this.$route.params.tournamentID &&
        this.getMetaFromParents('showAdminFunctions')
      );
    },
    popoverChatIsVisible() {
      return (
        this.isCreated &&
        this.$route.name !== 'conversations' &&
        this.$route.name !== 'conversationsRedirect'
        // this.$route.name !== 'TournamentSetup' &&
        // !this.sliderChatIsVisible
      );
    },
    toggleRotateAngle() {
      let angle = this.navbarIsOpen ? 90 : -90;
      return this.isRTL ? ~angle : angle;
    },
    isSmallScreen() {
      return window.innerWidth <= 770;
    },
  },
  watch: {
    isAuthorized() {
      if (!this.isAuthorized) {
        document.body.style.overflowY = 'unset';
      }
    },
    profileOpen() {
      if (this.isAuthorized) {
        (this.profileOpen || this.notificationOpen) && window.innerWidth <= 850
          ? (document.body.style.overflowY = 'hidden')
          : (document.body.style.overflowY = 'unset');
        this.$refs.profile.scrollTop = 0;
      }
    },
    notificationOpen() {
      if (this.isAuthorized) {
        (this.profileOpen || this.notificationOpen) && window.innerWidth <= 850
          ? (document.body.style.overflowY = 'hidden')
          : (document.body.style.overflowY = 'unset');
        this.$refs.notifications.scrollTop = 0;
      }
    },
    guestOpen() {
      if (!this.isAuthorized) {
        this.guestOpen && window.innerWidth <= 850
          ? (document.body.style.overflowY = 'hidden')
          : (document.body.style.overflowY = 'unset');
        this.$refs.profile.scrollTop = 0;
      }
    },
  },
  created() {
    this.contentLoaded = localStorage.getItem('region') ? true : false;
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }

    this.fetchPlatforms()
      .then(() => {
        return this.fetchGames(); // after and only after fetchPlatforms only
      })
      .catch(this.errorNotify);

    this.navbarIsOpen =
      localStorage.getItem(NAVBAR_IS_COLLAPSED_KEY) !== 'true';
  },
  mounted() {
    this.$nextTick(() => {
      this.isCreated = true;
    });
  },

  methods: {
    ...mapActions(['fetchPlatforms', 'fetchGames', 'errorNotify']),
    ...mapActions('my', ['fetchMyAccount']),
    ...mapActions('sidebars', ['setProfileOpen', 'setGuestOpen']),
    ...mapMutations(['setSearchQuery']),
    toggleNav() {
      this.navbarIsOpen = !this.navbarIsOpen;
      localStorage.setItem(
        NAVBAR_IS_COLLAPSED_KEY,
        `${this.navbarIsOpen ? '' : 'true'}`
      );
    },
    searchRedirect(event) {
      this.setSearchQuery(event.target.value);
      this.$router.push({ name: ROUTER_NAMES_ACCOUNT.SEARCH });
    },
  },
};
</script>

<style lang="scss">
$transition: 0.3s linear;
.mobile-footer {
  z-index: 500;
}

.layout-content-wrapper {
  background-color: $new-bg-page;
  max-width: 100vw;
}

.main-nav-wrapper {
  display: none;
  z-index: 2;

  // position: sticky;
  // z-index: 10;
  // top: 0;
  // bottom: auto;
  // height: 100vh;
  > div {
    // position: relative;
    @include auto-rtl(border-right, solid 1px var(--ph-input-borders));
    width: $navbar-width-collapse;
    min-height: 100vh;
    overflow: hidden;
    transition: width $transition;
    // background-color: $ph-card;
    background-size: $navbar-width-open 100%;

    .navbar-is-open & {
      width: $navbar-width-open;
    }
  }

  // box-shadow must be only to left or to right
  &::after {
    content: '';
    position: absolute;
    width: 2rem;
    top: 0;
    min-height: 100vh;
    height: 100%;
    z-index: -1;
    opacity: 0;
    box-shadow: 2px 0 24px rgba(0, 0, 0, 0.25);
    transition: opacity $transition;
    @include auto-rtl(left, 0);

    .navbar-is-open & {
      opacity: 1;
    }
  }

  .logo-wrapper {
    position: relative;
    // border-bottom: 1px solid $ph-secondary-text;
    height: $header-height;
    text-align: left;

    > div {
      position: relative;
      height: 100%;
    }
  }

  .toggle-sidenav-icon-wrapper {
    position: absolute;
    top: 100vh;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    @include auto-rtl(right);
    transform: translateY(-100%);

    .toggle-sidenav-icon {
      background-color: var(--ph-card);
      width: 36px;
      min-width: 36px;
      height: 36px;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      &:hover {
        background-color: var(--ph-hover);
      }
    }
  }
}

.main-content-wrapper {
  max-width: 100% !important;
}

.main-content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  box-sizing: border-box;

  .content {
    z-index: 2;
    /* padding-top: $hr-spacing-07; */
    /* padding-bottom: $hr-spacing-10; */
  }
}

.main-layout-wrapper {
  min-height: 100vh;
}

.main-nav-search {
  margin: 12px;

  input {
    background-color: var(--ph-bg);
    border: 0.5px solid $hr-clr-header;

    &:focus {
      border: 1px solid $hr-bg-accent;
    }
  }
}

.cta-message {
  position: sticky;
  gap: 25px;
  bottom: 0px;
  backdrop-filter: blur(10px);

  box-sizing: border-box;
  z-index: 100;
  height: 91px;
  max-height: 170px;
  width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0.8) -37.78%,
    rgba(235, 18, 187, 0.5) 100%
  );
  justify-content: space-around;
  align-items: center;

  .cta-message-text {
    h3 {
      margin: 0px !important;
      font-family: $hr-font-family;
      font-style: normal;
      font-weight: 700;
      font-size: $hr-heading-5;
      color: $hr-text-primary;
    }

    p {
      margin: 0px !important;
      font-family: $hr-font-family;
      font-style: normal;
      font-weight: 400;
      font-size: $hr-text-regular;
      color: $hr-text-primary;
    }
  }

  .cta-message-button {
    gap: 10px;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    &-login {
      border: 1px solid $hr-text-primary;
      border-radius: 100px;
      color: $hr-text-primary;
      background-color: transparent;
      font-weight: bold;
      font-size: $hr-text-small;
      text-align: center;
      width: 126px;
      height: 36px;
      margin: 0px !important;
    }

    &-create {
      font-family: 'Exo';
      font-style: normal;
      font-weight: 700;
      font-size: $hr-text-small;
      text-align: center;
      letter-spacing: 0.16px;
      color: #eb12bb;
      background-color: $hr-text-primary;
      border-radius: 100px;
      border: 1px solid $hr-text-primary;
      width: 126px;
      height: 36px;
      margin: 0px !important;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 800px) {
  .footer-wrapper {
    height: 4.75rem;
  }
}

@media screen and (max-width: 700px) {
  .cta-message {
    position: sticky;
    bottom: 0vh;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    z-index: 666;
    height: 1%;
    min-height: 60px;
    width: 100%;
    display: inline-flex;
    flex-flow: column;
    background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.8) -37.78%,
      rgba(235, 18, 187, 0.5) 100%
    );
    align-items: center;
    justify-content: center;
    padding: 10px;

    .cta-message-text {
      display: none;
    }

    .cta-message-button {
      gap: 10px;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      &-login {
        border: 1px solid $hr-text-primary;
        border-radius: 100px;
        color: $hr-text-primary;
        background-color: transparent;
        font-weight: bold;
        font-size: $hr-text-small;
        text-align: center;
        width: 126px;
        height: 36px;
        margin: 0px !important;
      }

      &-create {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 700;
        font-size: $hr-text-small;
        text-align: center;
        letter-spacing: 0.16px;
        color: #eb12bb;
        background-color: $hr-text-primary;
        border-radius: 100px;
        border: 1px solid $hr-text-primary;
        width: 126px;
        height: 36px;
        margin: 0px !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .cta-message {
    position: sticky;
    bottom: 0vh;
    box-sizing: border-box;
    z-index: 666;
    height: 1%;
    min-height: 60px;
    width: 100%;
    display: inline-flex;
    flex-flow: column;
    background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.8) -37.78%,
      rgba(235, 18, 187, 0.5) 100%
    );
    align-items: center;
    justify-content: center;
    padding: 10px;

    .cta-message-text {
      display: none;
    }

    .cta-message-button {
      gap: 10px;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      &-login {
        border: 1px solid $hr-text-primary;
        border-radius: 100px;
        color: $hr-text-primary;
        background-color: transparent;
        font-weight: bold;
        font-size: $hr-text-small;
        text-align: center;
        width: 126px;
        height: 36px;
        margin: 0px !important;
      }

      &-create {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 700;
        font-size: $hr-text-small;
        text-align: center;
        letter-spacing: 0.16px;
        color: #eb12bb;
        background-color: $hr-text-primary;
        border-radius: 100px;
        border: 1px solid $hr-text-primary;
        width: 126px;
        height: 36px;
        margin: 0px !important;
      }
    }
  }
}

.side-profile-wrap {
  margin-top: 56px;
  display: none;
  position: fixed;
  justify-content: center;
  width: 70vw;
  height: calc(100% - 4.75rem - 30px);
  background-color: #11171f;
  color: white;
  top: 10px;
  right: 0px;
  z-index: 3;
  transform: translateX(100%);

  overflow-y: auto;
  @media (max-width: 850px) {
    display: flex;
    width: 40vw;
  }
  @media (max-width: 700px) {
    width: 60vw;
  }
  @media (max-width: 470px) {
    width: 100vw;
  }
}
.outer-wrap-side-profile {
  overflow: hidden;
}
.show-side-profile {
  transition: transform 0.3s ease-in-out;
  transform: translateX(0%);
}
.overlay-profile-open {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #07090dd6;
  @media (min-width: 850px) {
    display: none;
  }
}
html[dir='rtl'] {
  .side-profile-wrap {
    right: unset;
    left: 0;
    transform: translateX(-100%);
  }
  .show-side-profile {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }
  .purple-bar {
    display: none;
  }
}

.highlights-navbar-style {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
</style>
