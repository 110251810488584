<template>
  <Badge
    class="status-label justify-content-center"
    :class="{
      'status-label--tournament': forTournament,
    }"
    :text="badgeText"
    :variant="variants[status]"
  />
</template>
<script>
import Badge from '@/components/atoms/HeraBadge';
import { tournamentStatuses } from '@/mixins/tournaments/tournamentStatuses';
import { stageStatuses } from '@/mixins/tournaments/stageStatuses';
import { playerStatuses } from '@/mixins/tournaments/playerStatuses';
import { matchStatuses } from '@/mixins/tournaments/matchStatuses';
import { competitionStatuses } from '@/mixins/tournaments/competitionStatuses';
import { prop } from '@/utils/factories';

export default {
  name: 'TournamentCardStatusLabel',
  components: { Badge },
  mixins: [
    tournamentStatuses(true),
    playerStatuses,
    matchStatuses,
    stageStatuses,
    competitionStatuses,
  ],
  props: {
    status: prop(String),
    forTournament: prop(Boolean),
  },
  data() {
    return {
      variants: {
        draft: '',
        announced: 'purple',
        registration_opened: 'blue',
        registration_closed: 'accent',
        qualification_completed: 'orange',
        started: 'green',
        rejected: 'red',
        none: '',
        requested: 'blue',
        registered: 'orange',
        revoked: 'red',
        qualified: 'green',
        reserved: 'green',
        notQualified: 'red',
        checkedIn: 'green',
        checkInRequired: 'yellow',
        pending: 'yellow',
        completed: 'blue',
        finished: 'gray',
        scheduled: 'orange',
        cancelled: 'red',
        judging: 'red',
        published: 'orange',
      },
    };
  },
  computed: {
    badgeText({ status, $filters }) {
      return (
        $filters.startCase(
          this.localizedTournamentsStatuses[status] ||
            this.localizedStageStatuses[status] ||
            this.localizedCompetitionStatuses[status] ||
            this.localizedPlayerStatuses[status] ||
            this.localizedMatchStatuses[status]
        ) || ''
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// .status-label {
//   min-width: 45px;
//   padding: $ph-small-space;
//   border-radius: $ph-pre-medium-radius;
//   &--tournament {
//     min-width: 50px;
//   }
// }
</style>
