import ChatWrapper from '@/views/conversations/';
import Chat from '@/views/conversations/Screen';
import ChatRedirect from '@/views/conversations/Redirect';
import { CONVERSATIONS } from '@/constants/router/';

export default [
  {
    path: '/conversations',
    component: ChatWrapper,
    children: [
      {
        path: '/conversations/:id?',
        name: CONVERSATIONS,
        component: Chat,
        meta: {
          // inMainNav: 20,
          // inMainNavOrg: 20,
          titleKey: '_web_left-sidebar_nav-Communication-link',
          icon: 'sidebar/chat',
          fallbackLabel: 'Communications',
          badgeWatcher: 'unreadConversations',
        },
      },
      {
        path: '/conversations-redirect/:login?',
        name: 'conversationsRedirect',
        component: ChatRedirect,
      },
    ],
  },
];
