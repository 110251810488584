var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget"},[_c('div',{staticClass:"d-flex justify-content-between align-items-start"},[_c('div',{staticClass:"d-flex flex-nowrap align-items-start"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('Subheader',{staticClass:"d-flex align-items-center mb-0 widget__title",attrs:{"text":_vm.title}}),_c('div',{staticClass:"h-ml-4"},[_vm._t("additionalInfo",[(_vm.helpText)?_c('InfoTooltip',{attrs:{"text":_vm.helpText,"icon-props":{
                  width: 18,
                }}}):_vm._e()])],2)],1),_vm._t("subtitle",[(_vm.subTitle)?_c('TextBlock',{attrs:{"variant":"muted","text":_vm.subTitle}}):_vm._e()])],2)]),_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.hideSearch)?_c('HeraSearchInput',{staticClass:"widget__searchfield",attrs:{"placeholder":_vm.searchPlaceholder || _vm.$t('_web_tournaments_search', 'Search'),"debounce":700,"read-only":_vm.searchDisabled},on:{"input":function (val) { return _vm.$emit('input', val); }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),_vm._t("default"),(_vm.statusBtnMode && _vm.statusBtnTop)?_c('div',{staticClass:"status-btn-wrapper h-ml-auto"},[_c('StatusBtn',{attrs:{"mode":_vm.statusBtnMode,"disabled":_vm.statusBtnDisabled}})],1):_vm._e(),(_vm.actionsMenu)?_vm._l((_vm.actionsMenu),function(menuItem){return _c('InfoTooltip',{key:("widget-menu-item-" + (menuItem.key)),staticClass:"h-ml-3",attrs:{"show-icon":false,"text":menuItem.subtitle}},[_c('Panel',{staticClass:"cursor-pointer p-3",class:{
              disabled: menuItem.disabled,
            },attrs:{"variant":[
              'transparent',
              'stroked',
              'stroked-secondary',
              'rounded',
              'rounded-medium' ]},on:{"click":function($event){$event.stopPropagation();return menuItem.handler($event)}}},[_c('Icon',{attrs:{"name":menuItem.icon,"width":"16"}})],1)],1)}):_vm._e()],2)]),_c('div',{staticClass:"d-flex justify-content-center",class:{
      'has-status-btn':
        ((_vm.statusBtnMode && _vm.statusBtnBottom) || _vm.$slots.actionBtn) &&
        _vm.indicatorsCentered,
      'mt-5':
        (_vm.indicators && _vm.indicators.length) ||
        (_vm.statusBtnMode && _vm.statusBtnBottom) ||
        _vm.$slots.actionBtn,
    }},[_vm._t("beforeIndicators"),_c('PerformanceIndicators',_vm._b({class:{
        'h-mr-auto':
          _vm.indicatorsCentered && (_vm.statusBtnMode || _vm.$slots.actionBtn),
      }},'PerformanceIndicators',{
        indicators: _vm.indicators,
      },false)),_vm._t("afterIndicators"),(_vm.$slots.actionBtn)?_c('div',{staticClass:"h-ml-auto",class:{ 'status-btn-wrapper': !(_vm.statusBtnMode && _vm.statusBtnBottom) }},[_vm._t("actionBtn")],2):_vm._e(),(_vm.statusBtnMode && _vm.statusBtnBottom)?_c('div',{staticClass:"status-btn-wrapper h-ml-auto"},[_c('StatusBtn',{attrs:{"mode":_vm.statusBtnMode,"disabled":_vm.statusBtnDisabled}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }