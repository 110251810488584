var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.heraShopLink)?_c('div',{staticClass:"bannerContainer",style:({ '--scrollWidth': _vm.scrollWidth })},[_c('div',{staticClass:"itemsContainer"},[_c('div',{staticClass:"bannerLeft"},[_c('h1',[_vm._v(_vm._s(_vm.$t('_web_shop-banner-title', 'From Shop')))]),_c('span',{staticClass:"bannerText"},[_vm._v(_vm._s(_vm.$t(
          '_web_shop-banner-text',
          'Everything for gaming in one place. Consoles, gaming equipment, hot deals, and more.'
        )))]),_c('a',{staticClass:"shopbutton-desktop",attrs:{"href":_vm.heraShopLink,"target":"_blank"}},[_c('div',{staticClass:"viewShop"},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('_web_shop-banner-button', 'View shop'))+"\n          ")]),_vm._m(0)])])]),_c('div',{staticClass:"image-wrapper"},[_c('div',{staticClass:"imageContainer"},_vm._l((_vm.shop),function(item){return _c('div',{key:item.category,staticClass:"d-flex container-item"},[_c('a',{staticClass:"d-flex linkItem",attrs:{"href":_vm.heraShopLink + item.link,"target":"_blank"}},[_c('img',{attrs:{"src":item.url,"alt":"banner image"}}),_c('p',{staticClass:"shopCategory",domProps:{"textContent":_vm._s(
                _vm.$t(
                  ("_web_shop-banner-" + (item.category.toLowerCase())),
                  item.category
                )
              )}})])])}),0)]),_c('a',{staticClass:"shopbutton-mobile",attrs:{"href":_vm.heraShopLink,"target":"_blank"}},[_c('div',{staticClass:"viewShop"},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('_web_shop-banner-button', 'View shop'))+"\n        ")]),_vm._m(1)])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewShopButton"},[_c('img',{staticClass:"shopArrow",attrs:{"src":require("@/assets/images/arrow-right-home.svg"),"alt":"shop arrow"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewShopButton"},[_c('img',{staticClass:"shopArrow",attrs:{"src":require("@/assets/images/arrow-right-home.svg"),"alt":"shop arrow"}})])}]

export { render, staticRenderFns }