<template>
  <div
    class="d-inline-block input-control form-radio"
    :class="[
      { checked: isChecked },
      { disabled: disabled },
      [`custom-${type}`, `form-radio--${variant}`],
    ]"
  >
    <label class="custom-control-label" tabindex="-1">
      <input
        v-model="computedVal"
        class="custom-control-input"
        :type="type"
        :name="name"
        :value="val"
        :disabled="disabled"
        :checked="isChecked"
      />
      <slot v-bind="{ label, val }">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'HeraRadio',
  model: {
    prop: 'value',
    event: 'radioChange',
  },
  props: {
    label: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    val: {
      type: null,
      default: null,
    },
    value: {
      type: null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'radio',
    },
    variant: {
      type: String,
      default: 'default',
      validator: val => {
        return ['default', 'flat'].indexOf(val) !== -1;
      },
    },
  },
  computed: {
    isChecked() {
      return this.value === this.val;
    },
    computedVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('radioChange', val);
      },
    },
  },
};
</script>

<style lang="scss">
.form-radio {
  min-height: 1rem;
  .custom-control-input {
    visibility: hidden;
  }
  &--default {
    &.custom-radio .custom-control-label {
      &::before,
      &::after {
        border-radius: 50%;
      }
    }
    .custom-control-label {
      @include auto-rtl(padding-left, 1.75rem);
      // @include auto-rtl(padding-right, 1rem);
      color: $white;
      cursor: pointer;
      font-size: $ph-body-font-size;
      font-weight: bold;
      &::before,
      &::after {
        top: 50%;
        transition: color 0.25s linear, transform 0.25s linear;
        background-color: transparent;
      }
      &::before {
        @include auto-rtl(left, 0);
        width: 1.125rem;
        height: 1.125rem;
        border: 2px solid $ph-secondary-text;
        transform: translateY(-50%);
      }
      &::after {
        @include auto-rtl(left, 5px);
        width: 0.5rem;
        height: 0.5rem;
        transform: translateY(-50%) scale(0);
      }
    }
    &:not(.checked) {
      .custom-control-label {
        .card.hera-card-panel--primary-card & {
          background-color: $ph-primary-text;
        }
      }
    }
    &.disabled {
      opacity: 0.5;
    }
    &.checked {
      .custom-control-label {
        &::before {
          border-color: transparent;
          background-color: $ph-accent;
        }
        &::after {
          background-color: $ph-primary-text;
          transform: translateY(-50%) scale(1);
        }
      }
    }
  }
  &--flat {
    margin: 0;

    &:first-child .custom-control-label {
      border-radius: $ph-medium-radius 0 0 $ph-medium-radius;
      .app-rtl & {
        border-radius: 0 $ph-medium-radius $ph-medium-radius 0;
      }
    }
    &:last-child .custom-control-label {
      border-radius: 0 $ph-medium-radius $ph-medium-radius 0;
      .app-rtl & {
        border-radius: $ph-medium-radius 0 0 $ph-medium-radius;
      }
    }
    .custom-control-label {
      padding: $ph-small-space;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $ph-secondary-input-bg-raw;
      cursor: pointer;
      &::before,
      &::after {
        display: none;
      }
    }
    &.checked {
      .custom-control-label {
        background-color: $ph-green;
      }
    }
    &:not(.checked) {
      .custom-control-label {
        .card.hera-card-panel--primary-card & {
          background-color: $ph-accent;
        }
      }
    }
    &.disabled {
      opacity: 0.5;

      .custom-control-label {
        cursor: unset;
      }
    }
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="HeraFormRadio"
  knobs="{
    label: {
      default: text('label', 'label')
    },
    disabled: {
      default: boolean('disabled', false)
    },
    variant: {
      default: select('variant', ['default', 'flat'], 'default')
    },
    cssClass: {
      default: select('checked', ['default', 'checked'], 'default')
    }
  }"
>
  <HeraFormRadio :class="cssClass" v-bind="{label, disabled, variant }" value="false" @radioChange="action('radioChange')"/>
</story>
