const DRAFT = 'draft';
const ANNOUNCED = 'announced';
const ARCHIVED = 'archived';
const REG_OPENED = 'registration_opened';
const REG_CLOSED = 'registration_closed';
const QUALIFICATION_COMPLETED = 'qualification_completed';
const SCHEDULED = 'scheduled';
const PUBLISHED = 'published';
const STARTED = 'started';
const REJECTED = 'rejected';
const CLOSED = 'closed';
const FINISHED = 'finished';

const NONE = 'none';
const REQUESTED = 'requested';
const REGISTERED = 'registered';
const REVOKED = 'revoked';
const QUALIFIED = 'qualified';
const RESERVED = 'reserved';
const NOTQUALIFIED = 'notQualified';
const CHECKEDIN = 'checkedIn';
const CHECKINREQUIRED = 'checkInRequired';
const PAYMENTPENDING = 'payment_pending';

export const PARTICIPANT_STATUSES = {
  NONE,
  REQUESTED,
  REGISTERED,
  REVOKED,
  QUALIFIED,
  RESERVED,
  NOTQUALIFIED,
  CHECKEDIN,
  CHECKINREQUIRED,
  PAYMENTPENDING,
};

export const PLAYER_STATUSES = [
  {
    name: PARTICIPANT_STATUSES.NONE,
    key: '_web_tournaments-player_status_none',
    label: 'None',
  },
  {
    name: PARTICIPANT_STATUSES.REQUESTED,
    key: '_web_tournaments-player_status_requested',
    label: 'Requested',
  },
  {
    name: PARTICIPANT_STATUSES.REGISTERED,
    key: '_web_tournaments-player_status_registered',
    label: 'Registered',
  },
  {
    name: PARTICIPANT_STATUSES.REVOKED,
    key: '_web_tournaments-player_status_revoked',
    label: 'Revoked',
  },
  {
    name: PARTICIPANT_STATUSES.QUALIFIED,
    key: '_web_tournaments-player_status_qualified',
    label: 'Qualified',
  },
  {
    name: PARTICIPANT_STATUSES.RESERVED,
    key: '_web_tournaments-player_status_reserved',
    label: 'Reserved',
  },
  {
    name: PARTICIPANT_STATUSES.NOTQUALIFIED,
    key: '_web_tournaments-player_status_notqualified',
    label: 'Not Qualified',
  },
  {
    name: PARTICIPANT_STATUSES.CHECKEDIN,
    key: '_web_tournaments-player_status_checkedin',
    label: 'Checked-in',
  },
  {
    name: PARTICIPANT_STATUSES.CHECKINREQUIRED,
    key: '_web_tournaments-list_status_checkinrequired',
    label: 'Check-in Required',
  },
];

export const TOURNAMENT_STATUSES = {
  DRAFT,
  ANNOUNCED,
  ARCHIVED,
  REG_OPENED,
  REG_CLOSED,
  QUALIFICATION_COMPLETED,
  SCHEDULED,
  PUBLISHED,
  STARTED,
  REJECTED,
  CLOSED,
};
export const STAGE_STATUSES = {
  DRAFT,
  PUBLISHED,
  STARTED,
  FINISHED,
};

export const STAGE_STATUSES_TEXT = [
  {
    name: STAGE_STATUSES.DRAFT,
    key: '_web_tournaments-list_status_draft',
    label: 'Draft',
  },
  {
    name: STAGE_STATUSES.PUBLISHED,
    key: '_web_tournaments-list_status_published',
    label: 'Published',
  },
  {
    name: STAGE_STATUSES.STARTED,
    key: '_web_tournaments-list_status_started',
    label: 'Started',
  },
  {
    name: STAGE_STATUSES.FINISHED,
    key: '_web_tournaments-list_status_finished',
    label: 'Finished',
  },
];

export const COMPETITION_STATUSES = {
  DRAFT,
  SCHEDULED,
  PUBLISHED,
  STARTED,
  FINISHED,
};

export const COMPETITION_STATUSES_TEXT = [
  {
    name: STAGE_STATUSES.DRAFT,
    key: '_web_tournaments-list_status_draft',
    label: 'Draft',
  },
  {
    name: STAGE_STATUSES.SCHEDULED,
    key: '_web_tournaments-list_status_scheduled',
    label: 'Scheduled',
  },
  {
    name: STAGE_STATUSES.PUBLISHED,
    key: '_web_tournaments-list_status_published',
    label: 'Published',
  },
  {
    name: STAGE_STATUSES.STARTED,
    key: '_web_tournaments-list_status_started',
    label: 'Started',
  },
  {
    name: STAGE_STATUSES.FINISHED,
    key: '_web_tournaments-list_status_finished',
    label: 'Finished',
  },
];

export const COMPETITION_FINISHED_STATUSES = [COMPETITION_STATUSES.FINISHED];

export const COMPETITION_SCHEDULED_STATUSES = [
  COMPETITION_STATUSES.SCHEDULED,
  COMPETITION_STATUSES.DRAFT,
];

export const DEFAULT_STATUSES_ACTIVE = [
  TOURNAMENT_STATUSES.DRAFT,
  TOURNAMENT_STATUSES.ANNOUNCED,
  TOURNAMENT_STATUSES.STARTED,
  TOURNAMENT_STATUSES.REG_OPENED,
  TOURNAMENT_STATUSES.REG_CLOSED,
  TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
];

export const DEFAULT_STATUSES_INACTIVE = [
  TOURNAMENT_STATUSES.REJECTED,
  TOURNAMENT_STATUSES.CLOSED,
  TOURNAMENT_STATUSES.ARCHIVED,
];

export const REJECTABLE_STATUSES = [
  TOURNAMENT_STATUSES.ANNOUNCED,
  TOURNAMENT_STATUSES.REG_OPENED,
  TOURNAMENT_STATUSES.REG_CLOSED,
  TOURNAMENT_STATUSES.SCHEDULED,
  TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
];

export const ARCHIVABLE_STATUSES = [
  TOURNAMENT_STATUSES.DRAFT,
  TOURNAMENT_STATUSES.CLOSED,
  TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
  TOURNAMENT_STATUSES.REJECTED,
];

export const GRID_TYPES = {
  UPPER: 'upper',
  LOWER: 'lower',
};

export const BLOCK_TYPES = {
  RR: 'round_robin',
  SINGLE: 'single_elimination',
  DOUBLE: 'double_elimination',
  SWISS: 'swiss_system',
  BATTLE_ROYALE: 'battle_royale',
};

export const STAGE_TYPES = [
  {
    name: 'Single Elimination',
    key: 'single-elimination',
    type: 'single_elimination',
    isEnabled: true,
    bestOf: [1, 2, 3, 5, 7],
  },
  {
    name: 'Double Elimination',
    key: 'double-elimination',
    type: 'double_elimination',
    isEnabled: true,
    bestOf: [1, 2, 3, 5, 7],
  },
  {
    name: 'Round Robin',
    key: 'round-robin',
    type: 'round_robin',
    isEnabled: true,
    bestOf: [1, 3, 5, 7],
    bestOfTie: [1, 2, 3, 4, 5, 6, 7, 8],
    rounds: [1, 2, 3],
    pointsForWin: 3,
    pointsForTie: 1,
    pointsForLose: 0,
  },
  {
    name: 'Swiss',
    key: 'swiss-system',
    type: 'swiss_system',
    isEnabled: true,
    bestOf: [1, 3, 5, 7],
    bestOfTie: [1, 2, 3, 4, 5, 6, 7, 8],
    pointsForWin: 3,
    pointsForTie: 1,
    pointsForLose: 0,
  },
  {
    name: 'Battle Royale',
    key: 'battle-royale',
    type: 'battle_royale',
    isEnabled: true,
  },
];

export const STATUSES = [
  {
    name: TOURNAMENT_STATUSES.DRAFT,
    key: '_web_tournaments-list_status_draft',
    label: 'Draft',
    getter: 'isDraft',
  },
  {
    name: TOURNAMENT_STATUSES.ANNOUNCED,
    key: '_web_tournaments-list_status_announced',
    label: 'Announced',
    getter: 'isAnnounced',
  },
  {
    name: TOURNAMENT_STATUSES.REG_OPENED,
    key: '_web_tournaments-list_status_registration_opened',
    label: 'Registration open',
    getter: 'isRegStarted',
  },
  {
    name: TOURNAMENT_STATUSES.REG_CLOSED,
    key: '_web_tournaments-list_status_registration_closed',
    label: 'Registration closed',
    getter: 'isRegClosed',
  },
  {
    name: TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
    key: '_web_tournaments-list_status_qualification',
    label: 'Qualification',
    getter: 'isScheduled',
  },
  {
    name: TOURNAMENT_STATUSES.STARTED,
    key: '_web_tournaments-list_status_started',
    label: 'Started',
    getter: 'isStarted',
  },
  {
    name: TOURNAMENT_STATUSES.CLOSED,
    key: '_web_tournaments-list_status_closed',
    label: 'Closed',
    getter: 'isClosed',
  },
  {
    name: TOURNAMENT_STATUSES.REJECTED,
    key: '_web_tournaments-list_status_rejected',
    label: 'Cancelled',
    getter: 'isRejected',
  },
  {
    name: TOURNAMENT_STATUSES.ARCHIVED,
    key: '_web_tournaments-list_status_archived',
    label: 'Archived',
    getter: 'isArchived',
  },
];

export const STATUSES_FOR_USER = STATUSES.filter(
  status =>
    status.name !== TOURNAMENT_STATUSES.ARCHIVED &&
    status.name !== TOURNAMENT_STATUSES.DRAFT
);

export const TIME_LABELS = {
  registration_start: [
    '_web_tournaments-list_status_registration_opens',
    'Registration opens in',
  ],
  registration_end: [
    '_web_tournaments-list_status_registration_closes',
    'Registration closes in',
  ],
  tournament_start: [
    '_web_tournaments-list_status_tournament_start',
    'Tournament starts in',
  ],
  tournament_end: [
    '_web_tournaments-list_status_tournament_end',
    'Tournament finishes in',
  ],
};

export const TIE_GAMES = {
  PUBG: 'pubg',
  PUBG2: 'pubg2',
  FORTNITE: 'fortnite',
};

export const CONSTRUCTOR_MODES = {
  CONSTRUCT: 'construct',
  PUBLISH: 'publish',
  MANAGE: 'manage',
};
