<template>
  <WrapperFormControl
    :id="inputID"
    :required="required"
    :error-in-tooltip="errorInTooltip"
    :view-mode="viewMode"
    :error="error"
    :label="label"
    :tooltip="tooltip"
    :hide-error-text="signup"
  >
    <div
      slot="control"
      class=""
      :class="{ 'd-flex align-items-center flex-nowrap': $slots.prepend }"
    >
      <span v-if="icon" class="icon-wrapper">
        <slot name="icon">
          <Icon
            :name="icon"
            :size="iconSize"
            original
            :disabled="isIconDisabled"
          />
        </slot>
      </span>
      <span v-if="!isFixedPlaceholderDisabled && fixedPlaceholder" class="">{{
        fixedPlaceholder
      }}</span>
      <slot name="prepend"></slot>
      <input
        :id="inputID"
        :type="type"
        :disabled="disabled"
        class="input-form"
        :class="{
          'is-invalid': error,
          'icon-input': icon,
          'view-mode': viewMode,
        }"
        :value="value"
        :readonly="readOnly || viewMode"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        v-bind="$attrs"
        :style="inputStyle"
        v-on="{
          ...$listeners,
          input: handleInput,
          change: handleChange,
        }"
      />
    </div>
  </WrapperFormControl>
</template>

<script>
import { prop } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl.vue';
import Icon from '@/components/atoms/Icon';
import { mapGetters } from 'vuex';

export default {
  name: 'HeraInput',

  components: {
    WrapperFormControl,
    Icon,
  },

  props: {
    viewMode: prop(Boolean),
    error: prop(String),
    errorInTooltip: prop(Boolean),
    label: prop(String),
    id: prop(String),
    placeholder: prop(String),
    value: prop([String, Number]),
    type: prop(String, 'text'),
    icon: prop(String, null),
    iconSize: prop([Number, String], 18),
    readOnly: prop(Boolean),
    disabled: prop(Boolean),
    required: prop(Boolean),
    min: prop(Number, NaN),
    max: prop(Number, NaN),
    maxlength: prop(Number, 524288), // default value in html standard
    tooltip: prop(String),
    fixedPlaceholder: prop(String, ''),
    inputLeftPadding: prop(String),
    signup: prop(Boolean),
  },
  data() {
    return {
      isIconDisabled: !this.value,
    };
  },

  computed: {
    ...mapGetters(['isRTL']),
    inputID() {
      return `InputField_${this.id || this.$lodash.uniqueId('id_')}`;
    },
    inputStyle() {
      if (this.inputLeftPadding) {
        return this.isRTL
          ? { 'padding-right': this.inputLeftPadding }
          : { 'padding-left': this.inputLeftPadding };
      }
      return null;
    },
  },

  watch: {
    newValue(val) {
      if (val && val.length) {
        this.fixedPlaceholder = '';
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.isFixedPlaceholderDisabled = !!val;
      },
    },
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
      this.isIconDisabled = !e.target.value.length;
    },
    handleChange(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
input::placeholder {
  color: $new-fg-neutral-faded;
}

.input-form {
  border-radius: 0.25rem;
  background-color: $new-bg-base;
  width: 25vw;
  min-width: 380px;
  max-width: 25rem;
  height: 2.25rem;
  border: 1px solid;
  border-color: $new-border-neutral;
  padding: 0.5rem;
  font-weight: 400;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
  &.icon-input {
    padding: 2px;
  }
  &.view-mode {
    pointer-events: none;
    border-color: $ph-secondary-text;
  }
}
</style>
<story
  options="{
        panelPosition: 'right'
      }"
  group="Common|Atoms/Form"
  name="HeraFormTextInput"
  knobs="{
        viewMode: {
          default: boolean('viewMode', false)
        },
        error: {
          default: text('error', '')
        },
        errorInTooltip: {
          default: boolean('errorInTooltip', false)
        },
        label: {
          default: text('label', 'label')
        },
        id: {
          default: text('id', 'id')
        },
        placeholder: {
          default: text('placeholder', 'placeholder')
        },
        value: {
          default: text('value', 'value')
        },
        type: {
          default: text('type', 'text')
        },
        icon: {
          default: text('icon', '')
        },
        readOnly: {
          default: boolean('readOnly', false)
        },
        disabled: {
          default: boolean('disabled', false)
        },
        required: {
          default: boolean('required', false)
        },
        min: {
          default: number('min', 10)
        },
        max: {
          default: number('max', 50)
        },
        maxlength: {
          default: number('maxlength', 524288)
        },
        tooltip: {
          default: text('tooltip', '')
        },
      }"
>
      <HeraFormTextInput v-bind="{viewMode, required, error, errorInTooltip, id, placeholder, value, type, label, readOnly, disabled, min, max, maxlength, tooltip}"
        @input="action('input')"
        @change="action('change')"
        @focus="action('focus')"
        @blur="action('blur')"
      />
    </story>
