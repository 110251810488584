<template>
  <div v-if="show" class="modalCreate">
    <div class="modal-createPostOverlay" @click="closeModal"></div>
    <div class="modal-createPostContainer">
      <div class="modal-createPostHeader">
        <h3>
          {{ $t(`_web_create-post-feed`, `${title}`) }}
        </h3>
      </div>
      <div class="modal-createPostBody">
        <div class="line-seperation line-top"></div>
        <div class="user-info-create-post">
          <LinkAccount
            :id="account.login"
            class="login"
            :type="account.type"
            :vanity-id="account.vanity_id"
          >
            <Avatar :img-url="account.avatar" :size="48" :type="account.type" />
          </LinkAccount>
          <div class="names-wrap">
            <LinkAccount
              :id="account.login"
              class=""
              :type="account.type"
              :vanity-id="account.vanity_id"
            >
              {{
                account._type !== 'organization'
                  ? account._data.display_name || account.login
                  : account.name
              }}
            </LinkAccount>
            <div class="hash-account">@{{ account.name || account.login }}</div>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Avatar from '@/components/images/Avatar';
import LinkAccount from '@/components/links/Account';
export default {
  components: {
    Avatar,
    LinkAccount,
  },
  props: {
    title: {
      type: String,
      default: 'Create a post',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('my', ['account']),
  },
  methods: {
    ...mapActions('feed', ['setCreatePostModal']),
    closeModal() {
      this.setCreatePostModal(false);
    },
  },
};
</script>

<style lang="scss">
.modalCreate {
  position: fixed;
  top: 40px;
  overflow-y: auto;
  left: 0;
  z-index: 199;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;

  justify-content: center;
  align-items: center;
  //   overflow-y: scroll;
}

.modal-createPostOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-createPostContainer {
  position: relative;
  background-color: #0d1117;
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 757px;
  max-width: 100%;
  // min-height: 450px;
  // max-height: 80%;
  padding-top: 20px;
  padding-bottom: 4.75rem;
  padding-left: 95px;
  padding-right: 95px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  @media (max-width: 900px) {
    padding-left: 5px;
    padding-right: 5px;
    min-height: 100%;
    width: 100%;
    max-height: 100dvh;
    overflow-y: auto;
    padding-bottom: 5.75rem;
    overflow-x: hidden;
  }
}

.modal-createPostHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $hr-spacing-05;
  margin-top: $hr-spacing-07;
  font-weight: 700;
  font-size: $hr-heading-6;
  line-height: 28px;
}

.cancel {
  border: none;
  background-color: transparent;
  font-size: $hr-text-small;
  line-height: 20px;
  margin-bottom: $hr-spacing-09;
  cursor: pointer;
  color: #9d9cf7;
  font-weight: 500;
}

.modal-createPostBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 620px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.modal-createPostFooter {
  display: flex;
}
.line-seperation {
  width: 100%;
  max-width: 562px;
  background-color: #30374a;
  display: flex;
  height: 1px;
}
.line-bottom {
  margin-bottom: $hr-spacing-07;
  margin-top: auto;
}
.line-top {
  margin-bottom: $hr-spacing-07;

  @media screen and (max-width: 620px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.user-info-create-post {
  display: flex;
  width: 100%;
  max-width: 562px;
  gap: 10px;
  color: white;
  font-weight: 700;
  line-height: 18.61px;
  font-size: $hr-text-small;
  margin-bottom: 16px;
}
.hash-account {
  color: #474a56;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.61px;
}
.names-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
