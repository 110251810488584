<template>
  <div class="sns-wrapper">
    <div class="sns-signup d-flex flex-column justify-content-center">
      <HeraLogo class="align-self-center" full-width="230" />
      <h1 class="mt-5 font-size-xl secondary-font">
        {{ $t('_web_auth_provide_username', 'Provide username') }}
      </h1>

      <section class="form-wrapper">
        <form
          class="remove-autofill-styles"
          @submit.prevent="formSubmit"
          @input="clearCustomError"
        >
          <InputUsername
            v-model.trim="login"
            v-validate="'required|usernamePattern'"
            name="login"
            type="text"
            :tabindex="2"
            required
            :label="$$t('Username', 'username-label')"
            :error="errors.first('login')"
            @input="value => debounceCredentials('login', value)"
          />
          <Country
            v-model="country"
            v-validate="'required'"
            name="country"
            :label="$$t('Country', 'country-label')"
            variant="primary"
            :tabindex="4"
            required
            :error="errors.first('country')"
          />
          <div class="row justify-content-center my-4">
            <div class="col-auto">
              <HeraFormSubmitBtn
                id="submitSignin"
                :tabindex="6"
                :label="$$t('Sign Up', 'submit-button')"
                :loading="isLoading"
                class="btn-primary submit-btn"
                :disabled="submitIsDisabled"
              />
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { Validator } from 'vee-validate';
import { translate } from '@/mixins/translate';
import isLoading from '@/mixins/isLoading.js';
import InputUsername from '@/components/form/InputUsername';
import Country from '@/components/molecules/form/autoComplete/Country';
import store from '@/store';
import HeraFormSubmitBtn from '@/components/form/HeraFormSubmitBtn';
import HeraLogo from '@/components/atoms/HeraLogo.vue';

const config = {
  usernameRegex: /^[a-z0-9_]+$/i,
};
const DEBOUNCE_TIME = 500; // ms

export default {
  name: 'SnsSignUp',
  components: {
    InputUsername,
    Country,
    HeraFormSubmitBtn,
    HeraLogo,
  },
  inject: ['$validator'],
  mixins: [isLoading, translate('_web_signup')],
  props: {
    networkType: {
      type: String,
      default: '',
    },
    payload: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      login: null,
      country: null,
      debounceCredentials: this.$lodash.debounce(
        this.checkCredentials,
        DEBOUNCE_TIME
      ),
    };
  },
  computed: {
    formData() {
      return {
        login: this.login,
        country: this.country && this.country.id,
      };
    },
    submitIsDisabled() {
      return !!(
        this.$lodash.get(this, 'errors.items.length', 0) || // validator has not error
        Object.values(this.formData).find(value => !value)
      ); // all items of form is not empty
    },
  },
  created() {
    Validator.extend('usernamePattern', {
      getMessage: this.$$t(
        'Username must contain only next symbols: {symbols} and {underline}',
        'username-pattern-error',
        { symbols: '(0-9), (a-z), (A-Z)', underline: '_' }
      ),
      validate: value => config.usernameRegex.test(value),
    });
  },
  mounted() {
    this.closeModal(false);
    this.errors.clear();
  },
  methods: {
    ...mapActions([
      'snsAuthRequest',
      'getCredentials',
      'createProfile',
      'closeModal',
    ]),
    checkCredentials(field, value) {
      this.$validator.validate(field).then(isValid => {
        if (!isValid) {
          return;
        }
        this.getCredentials(value).then(() => {
          this.$validator.errors.add({
            field,
            msg: this.$$t('{field} is already taken ', 'credentials-error', {
              field: this.$lodash.upperFirst(field),
            }),
            rule: 'custom',
          });
        });
      });
    },
    formSubmit() {
      this.$validator
        .validateAll()
        .then(() => {
          this.snsAuthRequest({
            networkType: this.networkType,
            payload: this.payload,
            login: this.login,
          }).then(() => {
            store.commit('saveRegData', {
              _countryObject: this.country,
              country: this.country.id,
              login: this.login,
            });
            this.createProfile().then(() => {
              this.$router.go({ path: '/' });
            });
          });
        })
        .catch(error => {
          // TODO: check backend response here (invalid now [fields=null]) https://isddesign.atlassian.net/browse/TH-7183
          this.unsetLoading();
          this.$validator.errors.add({
            field: 'customError',
            msg: error,
            rule: 'custom',
            id: 1,
          });
        });
    },
    clearCustomError() {
      this.errors.remove('customError');
    },
  },
};
</script>

<style lang="scss">
.snsSignup {
  width: 100vw;
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/images/background/dark-room.png');
  background-size: cover;

  .sns-signup {
    padding: 3rem;
    background: var(--ph-bg);
    border: 1px solid var(--ph-input-borders);
    box-sizing: border-box;
    box-shadow: 0 0 40px rgba(130, 97, 252, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 460px;
    .main-logo.playhera-icon {
      height: 40px;
    }
    label {
      font-size: 0.75rem !important;
      font-family: 'Exo' !important;
      font-weight: 600;
    }
    .is-invalid {
      input {
        border: solid 1px var(--ph-error) !important;
      }
    }
    #usernameHelp.text-danger {
      font-size: 0.75rem;
      color: var(--ph-error);
    }
    button {
      min-width: auto;
      margin-top: 1rem;
    }
  }
}
</style>
