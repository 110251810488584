import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
} from '@/api/requests';

const COMMENTS = 'comments';

export const getComments = (
  accessToken,
  { type, id, threadId, page, page_size }
) =>
  authorizedGetRequest(`${COMMENTS}/${type}/${id}`)(accessToken, {
    params: { threadId, page, page_size },
  });

export const postComment = (
  accessToken,
  { type, id, threadId, text, attachments }
) =>
  authorizedPostRequest(`${COMMENTS}/${type}/${id}`)(accessToken, {
    text,
    threadId,
    attachments,
  });

export const deleteComment = (accessToken, commentID) =>
  authorizedDeleteRequest(`${COMMENTS}/${commentID}`)(accessToken);

export const likeComment = id =>
  authorizedPostRequest(`${COMMENTS}/${id}/reactions`);

export default {
  getComments,
  postComment,
  deleteComment,
  likeComment,
};
