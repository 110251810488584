<template>
  <div
    class="horizontal-tabs-wrapper scroll-wrapper"
    :class="{
      'scrolled-to-left': scrolledToLeft,
      'scrolled-to-right': scrolledToRight,
    }"
  >
    <HorizontalScroller v-if="tabs.length" @update="onScroll">
      <ul
        class="nav horizontal-tabs d-flex flex-no-wrap p-0 mb-0"
        :class="[`horizontal-tabs--${variant}`, { 'not-exact': notExactMatch }]"
      >
        <template v-for="tab in tabs">
          <li
            :key="`horizontal-tab-${tab.key || tab.id}`"
            class="horizontal-tabs__item-wrapper"
            :class="tab.class"
          >
            <InfoTooltip :show-icon="false" :text="tab.helpText" class="h-100">
              <component
                :is="useCustom ? 'div' : 'router-link'"
                class="d-flex align-items-center cursor-hand justify-content-center horizontal-tabs__item h-100"
                :to="useCustom ? '' : tab.route"
                tag="div"
                :class="[
                  {
                    active:
                      (useCustom && activeTab === tab.key) || isSubActive(tab),
                    'not-allowed disabled': tab.disabled,
                    'flex-column':
                      $slots.beforeLabel ||
                      $slots.afterLabel ||
                      $scopedSlots.beforeLabel ||
                      $scopedSlots.afterLabel,
                    'flex-row flex-no-wrap': !(
                      $slots.beforeLabel ||
                      $slots.afterLabel ||
                      $scopedSlots.beforeLabel ||
                      $scopedSlots.afterLabel
                    ),
                  },
                ]"
                @[eventName]="$event => onTabClick($event, tab)"
              >
                <slot name="beforeLabel" v-bind="{ tab }"></slot>
                <div>
                  <span
                    v-if="tab.labelStated || tab.label"
                    class="text-nowrap"
                    >{{
                      tab.labelStated || $t(tab.key, tab.label, tab.params)
                    }}</span
                  >
                  <Icon
                    v-if="tab.icon || (tab.disabled && !hideDisableIcon)"
                    :name="
                      tab.disabled && !hideDisableIcon ? 'disable' : tab.icon
                    "
                    :width="variant === 'default' ? 16 : tab.iconWidth || 20"
                    :fill="
                      tab.disabled && !hideDisableIcon
                        ? $scss.colors.secondaryTextBg
                        : $scss.colors.primaryTextBg
                    "
                    :class="{ 'h-ml-3': tab.labelStated || tab.label }"
                  />
                </div>
                <slot name="afterLabel" v-bind="{ tab }"></slot>
              </component>
            </InfoTooltip>
          </li>
        </template>
      </ul>
    </HorizontalScroller>
  </div>
</template>
<script>
import { prop, propValidator, propRequired } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import HorizontalScroller from '@/components/common/HorizontalScroller';
import InfoTooltip from '@/components/molecules/InfoTooltip';
const TABS_VARIANTS = [
  'default',
  'folders',
  'segments',
  'panels',
  'buttons',
  'circles',
];
export default {
  name: 'HorizontalTabs',
  components: {
    Icon,
    HorizontalScroller,
    InfoTooltip,
  },
  props: {
    tabs: propRequired(Array),
    useCustom: prop(Boolean),
    useCustomRoute: prop(Boolean),
    notExactMatch: prop(Boolean),
    hideDisableIcon: prop(Boolean),
    activeTab: prop([String, Number]),
    variant: propValidator(String, 'default', val =>
      TABS_VARIANTS.includes(val)
    ),
  },
  data() {
    return {
      scrolledToLeft: true,
      scrolledToRight: false,
    };
  },
  computed: {
    eventName() {
      return this.useCustom ? 'click' : 'click.native';
    },
  },
  created() {
    if (!this.tabs.length) {
      this.scrolledToLeft = true;
      this.scrolledToRight = true;
    }
  },
  methods: {
    isSubActive(tab) {
      return (
        this.useCustomRoute &&
        this.$route.name === this.$lodash.get(tab, 'route.name')
      );
    },
    onScroll({ canBeScrolledLeft, canBeScrolledRight }) {
      if (this.scrolledToLeft !== !canBeScrolledLeft) {
        this.scrolledToLeft = !canBeScrolledLeft;
      }
      if (this.scrolledToRight !== !canBeScrolledRight) {
        this.scrolledToRight = !canBeScrolledRight;
      }
    },
    onTabClick(event, tab) {
      if (event.target && typeof event.target.scrollIntoView === 'function') {
        event.target.scrollIntoView({
          block: 'nearest',
        });
      }
      this.$emit('change', tab.key);
    },
  },
};
</script>
<style lang="scss">
.horizontal-tabs-wrapper.scroll-wrapper {
  margin: 0;
  background-color: transparent;
  &:after {
    content: '';
    z-index: 6;
    width: 2rem;
    @include auto-rtl(
      background,
      linear-gradient(to right, transparent 0%, $ph-bg 85%)
    );
    height: 100%;
    position: absolute;
    top: 0;
    @include auto-rtl(right, 0);
  }
  &.scrolled-to-right:after {
    background: transparent;
    content: unset;
    display: none;
  }
  &:before {
    content: '';
    z-index: 7;
    width: 2rem;
    @include auto-rtl(
      background,
      linear-gradient(to left, transparent 0%, $ph-bg 85%)
    );
    height: 100%;
    position: absolute;
    top: 0;
    @include auto-rtl(left, 0);
  }
  &.scrolled-to-left:before {
    background: transparent;
    content: unset;
    display: none;
  }
}
.horizontal-tabs {
  list-style: none;
  &__item-wrapper {
    @include auto-rtl(margin-left, $ph-tiny-space);
    &:first-of-type {
      @include auto-rtl(margin-left, 0 !important);
    }
    &:last-of-type {
      @include auto-rtl(margin-right, 0 !important);
    }
  }
  &__item {
    position: relative;
    min-height: 2rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: $ph-secondary-text;
    transition: color 0.1s ease-in;
    &.router-link-exact-active,
    &.active {
      color: $ph-primary-text;
    }
    &.not-allowed,
    &.not-allowed * {
      pointer-events: none;
      cursor: not-allowed !important;
    }
    > span {
      line-height: 1;
    }
  }
  &.not-exact {
    .horizontal-tabs__item {
      &.router-link-active {
        color: $ph-primary-text;
      }
    }
  }
  &--default {
    .horizontal-tabs__item {
      color: $ph-secondary-text;
      transition: color 0.1s ease-in;
      padding: $ph-medium-space - $ph-tiny-space;
      &:before {
        $border: 3px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        height: $border;

        background-color: $ph-secondary-text;
        border-radius: 50%;
        transition: background 0.1s ease-in;
      }
      &.router-link-exact-active,
      &.active {
        color: $ph-primary-text;
        &:before {
          background-color: $hr-bg-accent;
        }
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          color: $ph-primary-text;
          &:before {
            background-color: $hr-bg-accent;
          }
        }
      }
    }
  }
  &--folders {
    .horizontal-tabs__item {
      padding: $ph-medium-space $ph-large-space;
      flex-grow: 1;
      max-width: 160px;
      flex-basis: 0;
      border: 2px solid $ph-card;
      border-bottom-width: 0;
      border-top-left-radius: $ph-medium-radius;
      border-top-right-radius: $ph-medium-radius;
      &.router-link-exact-active,
      &.active {
        color: $ph-primary-text;
        background-color: $ph-card;
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          color: $ph-primary-text;
          background-color: $ph-card;
        }
      }
    }
  }
  &--panels {
    .horizontal-tabs__item-wrapper {
      @include auto-rtl(margin-left, $ph-small-space);
    }
    .horizontal-tabs__item {
      padding: $ph-small-space;
      flex-grow: 1;
      max-width: 160px;
      flex-basis: 0;
      border: 1px solid $ph-secondary-text;
      border-radius: $ph-medium-radius;
      &.router-link-exact-active,
      &.active {
        color: $ph-primary-text;
        background-color: $ph-card;
        border-color: $ph-accent;
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          color: $ph-primary-text;
          background-color: $ph-card;
          border-color: $ph-accent;
        }
      }
    }
  }
  &--circles {
    .horizontal-tabs__item {
      padding: $ph-small-space;
      flex-grow: 1;
      max-width: 160px;
      flex-basis: 0;
      border-radius: 50%;
      color: $ph-primary-text;
      background-color: transparent;
      width: 2rem;
      height: 2rem;
      text-align: center;
      @media (max-width: 450px) {
        width: 1.5rem;
        height: 1.5rem !important;
        min-height: 24px;
        max-width: 24px;
      }
      &.router-link-exact-active,
      &.active {
        background-color: #161d27;
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          background-color: transparent;
        }
      }
    }
  }
  &--buttons {
    .horizontal-tabs__item-wrapper {
      @include auto-rtl(margin-left, $ph-small-space);
    }
    .horizontal-tabs__item {
      padding: $ph-small-space;
      flex-grow: 1;
      max-width: 160px;
      flex-basis: 0;
      border: 1px solid $ph-input-bg;
      border-radius: $ph-medium-radius;
      background-color: $ph-input-bg;
      color: $ph-primary-text;
      &.router-link-exact-active,
      &.active {
        color: $ph-primary-text;
        background-color: $ph-accent;
        border-color: $ph-accent;
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          color: $ph-primary-text;
          background-color: $ph-accent;
          border-color: $ph-accent;
        }
      }
    }
  }
  &--segments {
    .horizontal-tabs__item-wrapper {
      margin: 0;
    }
    .horizontal-tabs__item {
      padding: $ph-small-space;
      min-width: 3rem;
      justify-content: center !important;
      text-align: center;
      border: none;
      background-color: $ph-input-bg;
      height: 2.5rem;
      cursor: pointer;

      &:first-child {
        @include auto-rtl(
          border-radius,
          $ph-medium-radius 0 0 $ph-medium-radius
        );
      }
      &:last-child {
        @include auto-rtl(
          border-radius,
          0 $ph-medium-radius $ph-medium-radius 0
        );
      }
      &.router-link-exact-active,
      &.active {
        background-color: $ph-accent;
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          background-color: $ph-accent;
        }
      }
    }
  }
  &.disabled {
    .horizontal-tabs__item {
      cursor: not-allowed;
      &.active,
      &.router-link-exact-active {
        color: $ph-secondary-text;
      }
      &:hover {
        color: $ph-secondary-text;
      }
    }
    &.not-exact {
      .horizontal-tabs__item {
        &.router-link-active {
          color: $ph-secondary-text;
        }
      }
    }
  }
}
</style>
