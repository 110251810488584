import scssVariables from '@/assets/scss/export.scss';
import colors from '@/assets/scss/variables/themes.scss';
import routerConstants from '@/constants/router/';
import * as times from '@/constants/time';

import * as ACCOUNT_CONSTANTS from './accounts';

const constants = {
  ...ACCOUNT_CONSTANTS,
  // ...etc
};

constants.install = function(Vue) {
  // Vue.prototype.$constants = (key) => constants[key];
  Vue.prototype.$routerConstants = Object.freeze(constants);
  Vue.prototype.$routerNames = Object.freeze(routerConstants);

  Vue.prototype.$times = Object.freeze(times);
  Vue.prototype.$scss = Object.freeze({
    ...scssVariables,
    colors,
  });
};
export default constants;
