export const MAIN_CLANS = 'clans';
export const MAIN_PROFILES = 'players';
export const MAIN_TOURNAMENTS = 'tournamentsList';
export const MAIN_COMPETITIONS = 'competitionsGroup';
export const MAIN_NEWSFEED = 'feed';
export const MAIN_MATCHES = 'myMatches';

export default {
  MAIN_CLANS,
  MAIN_PROFILES,
  MAIN_TOURNAMENTS,
  MAIN_COMPETITIONS,
  MAIN_NEWSFEED,
  MAIN_MATCHES,
};
