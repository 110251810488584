import MainLayout from './MainLayout.vue';
import AuthLayout from './AuthLayout.vue';
import MarketLayout from './MarketLayout.vue';
import EmptyLayout from './EmptyLayout.vue';

export default [
  {
    name: 'main-layout',
    component: MainLayout,
  },
  {
    name: 'auth-layout',
    component: AuthLayout,
  },
  {
    name: 'market-layout',
    component: MarketLayout,
  },
  {
    name: 'empty-layout',
    component: EmptyLayout,
  },
];
