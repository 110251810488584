<template>
  <div>
    <div
      class="date-time-wrapper align-items-end d-flex flex-row align-items-center"
      :class="{ 'date-time-wrapper--no-label': !dateLabel }"
    >
      <slot name="prepend-date" />
      <DatePicker
        v-model="valueModel"
        v-validate="{
          required,
        }"
        :name="key"
        :label="dateLabel"
        v-bind="{
          required,
          disabled,
          minDate,
          maxDate,
          fitContent,
          hideAsterisk,
          hideErrorText: true,
        }"
        :data-vv-name="key"
        :data-vv-as="dateLabel"
        :error="errors.first(key) || errors.first(lowerCase(key))"
        class="date-time-wrapper__date-input"
        :class="[datePickerClass]"
        v-on="eventListeners"
      />
      <slot name="prepend-time" />
      <TimePicker
        :ref="`${key}-time`"
        v-model="timeValueModel"
        v-validate="{
          required,
        }"
        :name="key"
        :label="timeLabel"
        v-bind="{
          required,
          disabled,
          minDate,
          maxDate,
          fitContent,
          hideAsterisk,
          hideErrorText: true,
        }"
        :data-vv-name="`${key}-time`"
        :data-vv-as="timeLabel"
        :error="errors.first(`${key}-time`) || errors.first(lowerCase(key))"
        placeholder="HH:mm"
        class="date-time-wrapper__time-input h-ml-3"
        :class="[timePickerClass, { 'mt-0': timeLabel }]"
        v-on="eventListeners"
      />
      <slot name="addon"></slot>
    </div>
    <div
      v-if="errors.first(key) || errors.first(lowerCase(key))"
      class="d-flex mt-2"
      :class="
        errors.first(key) || errors.first(lowerCase(key))
          ? 'justify-content-between'
          : 'justify-content-end'
      "
    >
      <TextBlock
        variant="error"
        :size="$scss.fontSizes_xs"
        line-height="0.875rem"
        :text="errors.first(key) || errors.first(lowerCase(key))"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/form/DatePicker';
import TimePicker from '@/components/form/TimePicker';
import TextBlock from '@/components/atoms/TextBlock';
import { lowerCase } from '@/utils/lodashUtils';
import { prop } from '@/utils/factories';
import valueModel from '@/mixins/valueModel';
import dateMethods from '@/mixins/dateMethods';

export default {
  name: 'DateTimePicker',
  components: { DatePicker, TimePicker, TextBlock },
  mixins: [valueModel, ...dateMethods],
  inject: ['$validator'],
  props: {
    value: prop([Number, String], null),
    minDate: prop(Number),
    maxDate: prop(Number),
    id: prop(String),
    required: prop(Boolean),
    disabled: prop(Boolean),
    duration: prop(Boolean),
    label: prop(String),
    dateLabel: prop(String),
    timeLabel: prop(String),
    datePickerClass: prop(String),
    timePickerClass: prop(String),
    fitContent: prop(Boolean),
    hideAsterisk: prop(Boolean),
    hideTimeErrorText: prop(Boolean),
  },
  data() {
    return { key: this.id, showTimePicker: false };
  },
  computed: {
    timeValueModel: {
      get() {
        return this.value || this.localValue;
      },
      set(newValue) {
        if (this.$lodash.isNumber(newValue)) {
          const { hour, minute } = this.secondsToDatetime(newValue);
          const withAdjastedTime = this.toSeconds(
            this.secondsToDatetime(this.valueModel).set({ hour, minute })
          );

          this.$emit('input', withAdjastedTime);
          this.localValue = withAdjastedTime;
        }
      },
    },
    eventListeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      return listeners;
    },
  },
  methods: {
    lowerCase,
  },
};
</script>

<style lang="scss">
.date-time-wrapper {
  &__time-input {
    margin-top: calc(0.875rem + #{$ph-small-space} - 1px);
    // margin-top: calc(0.875rem + 7px);
  }
  &--no-label {
    .date-time-wrapper__time-input {
      margin-top: 0px;
    }
  }
}
</style>
