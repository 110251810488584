export default {
  methods: {
    getMetaFromParents(metaKey) {
      let metaValue;
      this.$route.matched.find(({ meta }) => {
        if (meta.hasOwnProperty(metaKey)) {
          metaValue = meta[metaKey];
          return true;
        }
      });
      return metaValue;
    },
    checkIfRouteChildOf(routeName) {
      return this.$route.matched.some(({ name }) => {
        return name === routeName;
      });
    },
  },
};
