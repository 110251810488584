const state = {
  phDemoUsersData: false,
  phTempActivationCode: null,
};

const getters = {
  phDemoUsersData: state => state.phDemoUsersData,
  phTempActivationCode: state => state.phTempActivationCode,
};

const mutations = {
  setPhDemoUsersData(state, payload) {
    state.phDemoUsersData = payload;
  },
  setPhTempActivationCode(state, payload) {
    state.phTempActivationCode = payload;
  },
};

const actions = {
  setPhDemoUsersData({ commit }, payload) {
    commit('setPhDemoUsersData', payload);
  },
  setPhTempActivationCode({ commit }, payload) {
    commit('setPhTempActivationCode', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
