<template>
  <div class="googlebtn">
    <div v-if="googleConfigured" id="googleSignIn" class="mx-4 "></div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
// import VueJwtDecode from 'vue-jwt-decode';
import { SOCIAL_NETWORK } from '@/constants/auth';
import { GOOGLE_CLIENT_ID } from '@/api/config';
import jwt_decode from 'jwt-decode';

const GOOGLE_GSI_CLIENT_ID = 'googleGsiClient';
export default {
  name: 'GoogleSignIn',
  computed: {
    googleConfigured() {
      return Boolean(GOOGLE_CLIENT_ID);
    },
  },
  mounted() {
    if (this.googleConfigured) {
      const googleScript = document.createElement('script');
      googleScript.id = GOOGLE_GSI_CLIENT_ID;
      googleScript.setAttribute(
        'src',
        'https://accounts.google.com/gsi/client'
      );
      document.head.appendChild(googleScript);
      googleScript.onload = () => {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: this.handleGoogleSignIn,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignIn'),
          {
            type: 'icon',
            theme: 'outline',
            size: 'large',
            shape: 'circle',
            logo_alignment: 'center',
          }
        );
      };
    }
  },
  unmounted() {
    if (this.googleConfigured) {
      const googleScript = document.getElementById(GOOGLE_GSI_CLIENT_ID);
      googleScript.remove();
    }
  },
  methods: {
    ...mapActions(['getCredentials', 'snsAuthRequest']),
    handleGoogleSignIn(response) {
      var token = jwt_decode(response.credential);
      // var token = VueJwtDecode.decode(response.credential);
      this.getCredentials(token.email)
        .then(() => {
          this.snsAuthRequest({
            networkType: SOCIAL_NETWORK.GOOGLE,
            payload: response.credential,
          }).then(() => {
            this.$router.go({ path: '/' });
          });
        })
        .catch(err => {
          if (err.status === 404) {
            this.$router.push({
              name: 'snsSignup',
              params: {
                networkType: SOCIAL_NETWORK.GOOGLE,
                payload: response.credential,
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.googlebtn {
  max-height: 40px !important;
  width: 72px;
}
</style>
