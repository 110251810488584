<template>
  <component :is="tag" class="w-100 d-flex flex-column">
    <div class="paginated-list-wrapper row">
      <slot />
    </div>
    <div class="text-center pagination-class">
      <Pagination
        v-if="!hidePagination"
        v-bind="{
          ...paginationProps,
          total: totalItems,
          pageSize,
          updateLocally: inContainer,
        }"
        v-on="$listeners"
      />
    </div>
  </component>
</template>

<script>
import Pagination from '@/components/molecules/Pagination';
export default {
  name: 'Paginated',
  components: {
    Pagination,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: [Number, String],
      default: 0,
    },
    pageSize: {
      type: [Number, String],
      default: 20,
    },
    inContainer: {
      type: Boolean,
      default: false,
    },
    paginationProps: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
$spin-wrap-height: 5rem;
$spin-size: 2rem;
$spin-offset-y: 1.5rem; //calc(math.div($spin-wrap-height, 2) - math.div($spin-size, 2));

%spin-style {
  content: '';
  display: block;
  height: $spin-size;
  width: $spin-size;
  position: absolute;
  left: 50%;
  border: 2px solid transparent;
  border-left-color: $ph-accent;
  border-bottom-color: $ph-accent;
  border-radius: 50%;
  transform: translateX(-50%);
  @include rotate-element;
}

.infinite-wrapper {
  position: relative;

  &.fetching {
    &.loader-after {
      padding-bottom: $spin-wrap-height;
      &:after {
        @extend %spin-style !optional;
        bottom: $spin-offset-y;
      }
    }

    &.loader-before {
      padding-top: $spin-wrap-height;
      &:before {
        @extend %spin-style !optional;
        top: $spin-offset-y;
      }
    }
  }
}
</style>
