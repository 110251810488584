import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const createTournamentBlock = (tournamentID, stage) =>
  authorizedPostRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stage}/blocks`
  );
export const getTournamentBlocks = (tournamentID, stage) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stage}/blocks`,
    { preventCancelRequest: true }
  );
export const getTournamentBlockByID = (tournamentID, stageID, blockID) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/blocks/${blockID}`,
    { preventCancelRequest: true }
  );
export const updateTournamentBlock = (tournamentID, stage, block) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stage}/blocks/${block}`
  );
export const deleteTournamentBlock = (tournamentID, stage, block) =>
  authorizedDeleteRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stage}/blocks/${block}`
  );
export const resetBrackets = (tournamentID, stage, block) =>
  authorizedDeleteRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stage}/blocks/${block}/matches`,
    {
      preventCancelRequest: true,
    }
  );
export const shuffleParticipants = (tournamentID, stage, block) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stage}/blocks/${block}/shuffle`
  );

export default {
  createTournamentBlock,
  getTournamentBlocks,
  getTournamentBlockByID,
  updateTournamentBlock,
  deleteTournamentBlock,
  resetBrackets,
  shuffleParticipants,
};
