var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fallback-container"},[_c('div',{staticClass:"fallback-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/tugy.svg"),"alt":""}}),(_vm.shopSearch)?_c('span',{},[_vm._v("\n      "+_vm._s(_vm.$t('_web_shop_fallback_search', "Can't find \"{keyword}\"", {
          keyword: _vm.shopSearch,
        }))+"\n    ")]):_vm._e(),(_vm.shopSearch)?_c('span',[_vm._v("\n      "+_vm._s(_vm.$t(
          '_web_shop_fallback_search_another',
          'Try searching another keyword.'
        ))+"\n    ")]):_c('span',[_vm._v("\n      "+_vm._s(_vm.$t(
          '_web_shop_fallback_no_items',
          'Sorry, there are no items to show.'
        ))+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }