<template>
  <PlayerHeraList
    :data-source="fetchClans"
    :filters="filters"
    :dummy-props="{
      iconName: 'dummy/clans',
      headerText: $t('_web_clans_list_empty', 'No clans to show'),
    }"
  >
    <ClanItem slot-scope="{ item: clan }" :clan="clan" />
    <RouterLink
      v-if="permissions.createClan"
      slot="filterBefore"
      :to="{ name: 'createClanDetails' }"
      class="d-flex mb-4 text-decoration-none"
    >
      <HeraFormBtn variant="default" class="w-100 create-clan-button">
        {{ $t('_web_create-team-link', 'Create team') }}
      </HeraFormBtn>
    </RouterLink>
  </PlayerHeraList>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PlayerHeraList from '@/components/organisms/List';
import ClanItem from './ClanItem';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';

export default {
  name: 'ClansList',
  components: {
    HeraFormBtn,
    ClanItem,
    PlayerHeraList,
  },
  data() {
    return {
      filters: [
        {
          type: 'Search',
          label: this.$t('_web_clans-filter_name', ''),
          saveAs: 'contains', // todo other lists used name
          props: {
            icon: 'search',
            size: 16,
          },
        },
        {
          type: 'Country',
          label: this.$t('_web_clans-filter_country', 'Country'),
          saveAs: 'country',
        },
        {
          type: 'Games',
          label: this.$t('_web_clans-filter_games', 'Games'),
          saveAs: 'games',
        },
        {
          type: 'Genres',
          label: this.$t('_web_clans-filter_genres', 'Genres'),
          saveAs: 'genres',
        },
      ],
    };
  },
  computed: {
    ...mapState('my', ['accountId', 'account']),
    ...mapGetters(['games', 'permissions']),
  },
  methods: {
    ...mapActions(['fetchClans']),
  },
};
</script>

<style lang="scss" scoped>
.create-clan-button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--ph-accent) !important;
}
</style>
