import TermsEurope from '@/views/static/europe/terms.vue';
import TermsJapan from '@/views/static/japan/terms.vue';
import TermsMena from '@/views/static/mena/terms.vue';
import TermsOther from '@/views/static/other/terms.vue';
import RefundPolicyOther from '@/views/static/other/refundPolicy.vue';
import RefundPolicyMena from '@/views/static/mena/refundPolicy.vue';
import PrivacyPolicyEurope from '@/views/static/europe/privacypolicy.vue';
import PrivacyPolicyJapan from '@/views/static/japan/privacypolicy.vue';
import PrivacyPolicyMena from '@/views/static/mena/privacypolicy.vue';
import PrivacyPolicyOther from '@/views/static/other/privacypolicy.vue';
import PrivacyPolicyIndonesia from '@/views/static/indonesia/privacypolicy.vue';

export const STATIC_PAGES = [
  'terms-europe',
  'privacypolicy-europe',
  'terms-japan',
  'privacypolicy-japan',
  'terms-mena',
  'privacypolicy-mena',
  'terms-other',
  'privacypolicy-other',
  'refund-mena',
  'refund-other',
  'privacy-indonesia',
];
export default [
  {
    path: '/static/privacypolicy/indonesia',
    name: 'privacypolicy-indonesia',
    component: PrivacyPolicyIndonesia,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/terms/europe',
    name: 'terms-europe',
    component: TermsEurope,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/privacypolicy/europe',
    name: 'privacypolicy-europe',
    component: PrivacyPolicyEurope,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/terms/japan',
    name: 'terms-japan',
    component: TermsJapan,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/privacypolicy/japan',
    name: 'privacypolicy-japan',
    component: PrivacyPolicyJapan,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/terms/mena',
    name: 'terms-mena',
    component: TermsMena,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/privacypolicy/mena',
    name: 'privacypolicy-mena',
    component: PrivacyPolicyMena,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/terms/other',
    name: 'terms-other',
    component: TermsOther,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/refund/other',
    name: 'refund-other',
    component: RefundPolicyOther,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/refund/mena',
    name: 'refund-mena',
    component: RefundPolicyMena,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/privacypolicy/other',
    name: 'privacypolicy-other',
    component: PrivacyPolicyOther,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/static/*',
    redirect: '/',
  },
];
