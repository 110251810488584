import { render } from './render';
import {
  PROFILE,
  ORGANIZATION,
  CLAN,
  TOURNAMENT,
  BATTLE,
} from '@/constants/accounts';
import { prop } from '@/utils/factories';

const allAccountTypes = [PROFILE, ORGANIZATION, CLAN, TOURNAMENT, BATTLE];

export default {
  name: 'LinkAccount',
  functional: true,
  props: {
    id: prop(String),
    type: {
      type: String,
      required: true,
      validator: accountType => allAccountTypes.includes(accountType),
    },
    vanityId: prop(String),
    verified: prop(Boolean),
    target: prop(String),
  },
  render,
};
