var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header-fixed position-sticky"},[_c('nav',{staticClass:"navbar d-flex align-items-center",attrs:{"id":"nav"}},[_c('div',{key:_vm.appId,staticClass:"navbar-wrapper"},[_c('div',{staticClass:"nav-left"},[_c('div',{staticClass:"nav-logos"},[_c('router-link',{attrs:{"to":"/"}},[_c('picture',[_c('source',{attrs:{"srcset":require("@/assets/images/header/LEAP2024-logosm.svg"),"media":"(max-width: 945px)"}}),_c('img',{staticClass:"playhera-logo-nav",attrs:{"srcset":require("@/assets/images/header/LEAP2020Logo.svg"),"alt":"playhera-logo"}})])])],1),(_vm.omantelLogo)?_c('img',{staticClass:"prominent-logo",attrs:{"src":_vm.omantelLogo,"alt":"prominent logo"}}):(_vm.account && _vm.account.country === 'Oman')?_c('img',{staticClass:"prominent-logo",attrs:{"src":"https://static-mena.playhera.com/public-images/img/OMANTEL_LOGO.png","alt":"prominent logo"}}):_vm._e(),_c('div',{staticClass:"navigation-buttons"},[(_vm.isAuthorized)?_c('router-link',{staticClass:"btn-router-link",attrs:{"to":"/","exact-active-class":"active"}},[_c('img',{staticClass:"forYou-logo",attrs:{"src":require("@/assets/images/new-icons/Vector FORYOU.svg"),"alt":"foryou-logo"}}),_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_for_you_page', 'For You'))+"\n            ")])]):_vm._e(),_c('router-link',{staticClass:"btn-router-link",attrs:{"to":"/feed","active-class":"active"}},[_c('img',{staticClass:"forYou-logo",attrs:{"src":require("@/assets/images/new-icons/VectorFeed.svg"),"alt":"highlights-logo"}}),_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_newsfeed_title', 'Newsfeed'))+"\n            ")])]),(
              _vm.isAuthorized &&
                _vm.currentRegion &&
                _vm.currentRegion.feature_flags.phmax_url !== null &&
                _vm.account &&
                _vm.account._type === 'profile'
            )?_c('a',{staticClass:"max-btn",class:{ active: _vm.$route.path.includes('subscription') },attrs:{"active-class":"active"},on:{"click":_vm.redirectToPhMax}},[_c('img',{staticClass:"forYou-logo",attrs:{"src":require("@/assets/images/new-icons/VectorPHMAX.svg"),"alt":"max-logo"}}),_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_max_title', 'PLAYHERA Max'))+"\n            ")])]):(
              (_vm.currentRegion &&
                _vm.currentRegion.feature_flags.phmax_url &&
                _vm.account &&
                _vm.account._type === 'profile') ||
                (!_vm.account &&
                  _vm.currentRegion &&
                  _vm.currentRegion.feature_flags.phmax_url)
            )?_c('router-link',{staticClass:"max-btn",attrs:{"to":{ name: 'subscriptionSetup' },"active-class":"active"}},[_c('img',{staticClass:"forYou-logo",attrs:{"src":require("@/assets/images/new-icons/VectorPHMAX.svg"),"alt":"max-logo"}}),_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_max_title', 'PLAYHERA Max'))+"\n            ")])]):_vm._e(),(_vm.isLoading && _vm.isAuthorized)?_c('Spinner',{staticClass:"spinner-middle",attrs:{"modal":""}}):_vm._e(),(
              _vm.currentRegion &&
                _vm.currentRegion.feature_flags.marketplace_url &&
                (!_vm.account || _vm.account._type === 'profile')
            )?_c('router-link',{staticClass:"max-btn2",attrs:{"active-class":"active","to":"/shop/categories"}},[_c('img',{staticClass:"forYou-logo",attrs:{"src":require("@/assets/images/new-icons/VectorSHOP.svg"),"alt":"max-logo"}}),_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_playhera_shop', 'Shop'))+"\n            ")])]):_vm._e(),_c('router-link',{staticClass:"max-btn2",attrs:{"active-class":"active","to":"/tournaments"}},[_c('img',{staticClass:"forYou-logo",attrs:{"src":require("@/assets/images/new-icons/VectorTournaments.svg"),"alt":"max-logo"}}),_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_playhera_tournaments', 'Tournaments'))+"\n            ")])])],1),(!_vm.isAuthorized)?_c('div',{staticClass:"logout-view"},[(
              _vm.currentRegion &&
                _vm.currentRegion.feature_flags.phmax_url &&
                (!_vm.account || _vm.account._type === 'profile')
            )?_c('router-link',{staticClass:"max-btn",attrs:{"to":{ name: 'subscriptionSetup' },"active-class":"active"}},[_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_max_btn', 'Max'))+"\n            ")])]):_vm._e(),(_vm.isLoading && _vm.isAuthorized)?_c('Spinner',{staticClass:"spinner-middle",attrs:{"modal":""}}):_vm._e(),(
              _vm.currentRegion &&
                _vm.currentRegion.feature_flags.marketplace_url &&
                (!_vm.account || _vm.account._type === 'profile')
            )?_c('router-link',{staticClass:"max-btn2",attrs:{"active-class":"active","to":"/shop/categories"}},[_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_playhera_shop', 'Shop'))+"\n            ")])]):_vm._e(),_c('router-link',{staticClass:"max-btn",attrs:{"to":{ name: 'feed' },"active-class":"active"}},[_c('button',{staticClass:"header-btn"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_feed_btn', 'Feed'))+"\n            ")])])],1):_vm._e()]),_c('div',{staticClass:"nav-right"},[(_vm.isAuthorized)?_c('div',{staticClass:"search-and-icon"},[(_vm.search)?_c('HeraNavbarSearchInput',{ref:"sidebarSearchInput",staticClass:"searchinput",class:[
              _vm.isRTL ? 'newanimationclass' : '',
              { hidden: _vm.$route.name === 'search' },
              '' ],attrs:{"id":"sidebar-search","disabled":_vm.$router.currentRoute.name === 'search',"class-input":"main-nav-searchBar"},on:{"enter":_vm.searchRedirect},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e(),_c('AppIcon',{staticClass:"searchicon",attrs:{"name":"search","width":18},on:{"click":_vm.toggleSearch}})],1):_vm._e(),(_vm.isAuthorized)?_c('Notifications',{class:[{ disabled: _vm.$route.name === 'notifications' }, ''],attrs:{"id":"notifications"}}):_vm._e(),(_vm.isAuthorized)?_c('SideChat',{class:[{ disabled: _vm.$route.name === 'conversations' }, '']}):_vm._e(),(_vm.isAuthorized && _vm.account.country === null)?[_c('router-link',{attrs:{"to":{ path: "/profile-setup" }}},[(_vm.isAuthorized)?_c('UserProfileDropdown',{}):_vm._e()],1)]:_vm._e(),(_vm.isAuthorized && _vm.account.country !== null)?_c('UserProfileDropdown',{}):_vm._e(),(!_vm.isAuthorized)?[_c('router-link',{attrs:{"to":{ path: ("/signin?redirectTo=" + (_vm.$route.fullPath)) }}},[_c('HeraFormBtn',{staticClass:"sign-in sign-in-desktop"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_log_in', 'Log In'))+"\n            ")])],1)]:_vm._e(),(!_vm.isAuthorized)?[_c('button',{staticClass:"sign-in-mobile",on:{"click":function($event){$event.stopPropagation();return _vm.togglePopover($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/menu.svg"),"alt":"menu icon"}})])]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }