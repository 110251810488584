<template>
  <div class="msg">
    <LinkAccount
      v-if="item.sender_name"
      :id="item.sender_name"
      class="login link-account-hover font-size-xs mb-2"
      type="profile"
    >
      <div>
        <span class="sender-name">{{ item.sender_name }}</span>
      </div>
    </LinkAccount>
    <div class="w-100 mb-auto pb-1 msg-content">
      <TextContent :content="item.text" />
      <div v-if="item.translate" class="w-100 mb-auto pb-1 msg-translate">
        <TextContent :content="item.translate.text" />
        <!-- /* not work on backend
        <span
          class="mx-2 text-primary cursor-hand"
          @click="$emit('setDropdownTranslate', { $event, item })"
          >{{ item.translate.language }}</span
        >
        -->
      </div>
    </div>
    <Attachments
      v-if="item.attachments && item.attachments.length"
      class="p-0 w-100 mw-100 msg-attachments"
      :attachments="item.attachments"
    />
    <div v-else-if="!item.text" class="text-muted">
      {{ $t('_web_msg-content_msg-is-empty', 'Message is empty') }}
    </div>
    <div
      class="font-size-xs w-100 d-flex justify-content-end hera-muted msg-date"
      :class="{
        'justify-content-between': isPost,
      }"
    >
      <div
        v-if="isPost"
        class="slider-chat-message__comments d-flex"
        @click="$emit('openPost', item)"
      >
        <Icon name="comments" width="12px" class="mx-1" />
        <span v-if="!item.comments_count">
          {{ $t('_web_tournament_sidechat_no-comments', 'No comments') }}
        </span>
        <span v-else class="slider-chat-message__comments-count">
          {{ item.comments_count }}
          {{ $t('_web_tournament_sidechat_comments', 'comments') }}
        </span>
      </div>
      <!--<AppIcon v-if="item.read" name="checked_2" width="16" :original="true" class="h-mr-1"/>-->
      <span>{{ item.sent_date | localDate($times.DATETIME_SUBMED) }}</span>
    </div>
  </div>
</template>

<script>
import Attachments from '@/components/common/MsgAttachments';
import TextContent from '@/components/text/TextContent';
import Icon from '@/components/atoms/Icon';
import LinkAccount from '@/components/links/Account';

export default {
  name: 'MsgContent',
  components: { TextContent, Attachments, Icon, LinkAccount },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.link-account-hover:hover {
  color: unset !important;
}

.msg-attachments {
  min-width: unset;

  .filename {
    margin: 0.375rem 0 0 0;
  }
}
</style>
