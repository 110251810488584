<template>
  <div class="bannerContainer">
    <div class="itemsContainer">
      <div class="banner-text-section">
        <h1 class="banner-ps-vouchers-heading">
          {{ $t('_web_playhera-vouchers-title', 'Vouchers') }}
        </h1>
        <span class="banner-text"
          >{{
            $t(
              '_web_playhera-vouchers-text',
              'Redeem for anything on PLAYHERA Store.'
            )
          }}
        </span>

        <ShopVouchersBtn class="shop-vouchers-btn" />
      </div>
      <div
        class="image-container"
        :style="{
          backgroundImage: `linear-gradient(90deg, rgba(10,164,232,0.7) 0%, rgba(9,9,121,0.2) 25%, rgba(9,9,121,0.2) 100%), url(${
            psVoucher.url
          })`,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import ShopVouchersBtn from '@/components/common/ShopVouchersBtn';
export default {
  components: {
    ShopVouchersBtn,
  },
  data() {
    return {
      psVoucher: {
        url: [require('@/assets/images/advertisement-banners/PSN.png')],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import './variables';
.bannerContainer {
  font-family: 'Exo';
  width: 100%;
  height: $banner_wrapper_height_ps;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .app-rtl & {
    .app-ltr & {
      transform: scaleX(-1);
    }
  }
}

.itemsContainer {
  display: flex;
  height: $banner_height_ps;
  width: $hr-wrapper-width;
  max-width: $hr-wrapper-max-width;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #154ab4;
}

@media screen and (max-width: 1483px) {
  .itemsContainer {
    width: 92vw;
  }
}

.shop-vouchers-btn {
  margin-top: 27px;
}

.banner-ps-vouchers-heading {
  font-style: normal;
  font-weight: 700;
  font-size: $hr-heading-4;
  line-height: 110%;
  color: $hr-text-primary;
}
.banner-text-section {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  height: $banner_height_ps;
  justify-content: center;
  padding-left: 62px;
  .app-rtl & {
    padding-right: 62px;
  }
  z-index: 1;
  background: linear-gradient(89deg, #154ab4 30%, rgba(10, 164, 232, 0.8) 80%);
}
.banner-text {
  font-weight: 400;
  font-size: $hr-heading-5;
  line-height: 140%;
  color: $hr-text-primary;
  margin-top: 10px;

  @include auto-rtl(margin-right, auto);
}

.banner-text-bold {
  font-weight: 700;
  font-size: $hr-heading-5;
  line-height: 110%;
  color: $hr-text-primary;
}

.image-container {
  flex-basis: 40%;
  align-self: stretch;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 991px) {
  .image-container {
    display: none;
  }

  .banner-text-section {
    flex-basis: auto;
    width: 100%;
    padding: 3rem;
    height: auto;
  }

  .itemsContainer {
    height: auto;
  }

  .bannerContainer {
    height: auto;
    margin-top: 4rem;
  }
}

@media screen and (max-width: 640px) {
  .banner-text {
    font-size: 1rem;
  }
}
</style>
