'use strict';

import locale from 'browser-locale';

export const USER_LOCALE = locale();
export const WE_HAVE_USER_LOCALE = locales =>
  locales.find(locale => {
    return locale.key === USER_LOCALE.substr(0, 2);
  });

export default {
  USER_LOCALE,
  WE_HAVE_USER_LOCALE,
};
