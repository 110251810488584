<template>
  <transition name="slider-chat-channel-editor-transition">
    <div class="d-flex flex-column fill-border slider-chat-channel-editor">
      <div class="slider-chat-channel-editor__header d-flex align-items-center">
        <Icon
          v-if="editMode"
          name="back"
          width="18px"
          class="slider-chat-channel-editor__back-icon"
          @click="back"
        />
        <span class="slider-chat-channel-editor__title">{{ headerTitle }}</span>
      </div>
      <div class="slider-chat-channel-editor__info">
        <div class="d-flex mb-3">
          <Avatar
            class="h-mr-3"
            :img-uri="origAvatar"
            :type="conversationType"
            :size="60"
            editable
            :disabled="isLoading"
            @edited="avatarEditHandler"
          />
          <HeraFormTextInput
            v-model="title"
            v-validate="'required|min:1'"
            class="h-ml-2 slider-chat-channel-editor__channel-name-input"
            :label="nameInputTitle"
            :placeholder="nameInputTitle"
            data-vv-name="title"
            :data-vv-as="nameInputTitle"
            :error="errors.first('title')"
            :maxlength="50"
            @change="inputHandler"
          />
        </div>
        <HeraFormTextInput
          v-model="subtitle"
          :label="
            $t('_web_tournament_sidechat_channel-description', 'Description')
          "
          data-vv-name="subtitle"
          :data-vv-as="
            $t('_web_tournament_sidechat_channel-description', 'Description')
          "
          :error="errors.first('subtitle')"
          :maxlength="50"
          @change="inputHandler"
        />
      </div>
      <div class="d-flex flex-column slider-chat-channel-editor__partisipants">
        <ParticipantsList
          :label="
            $t('_web_tournament_sidechat_channel-participants', 'Participants')
          "
          :participants="participants"
          @addNew="$emit('fullWidth', true)"
          @deleteParticipant="$emit('deleteParticipant', $event)"
        />
      </div>
      <button
        v-if="editMode"
        class="slider-chat-channel-editor__delete-btn"
        @click="deleteBtnClickHandler"
      >
        <template v-if="conversationType === 'channel'">
          {{ $t('_web_tournament_sidechat_delete-channel', 'Delete Channel') }}
        </template>
        <template v-else>
          {{
            $t(
              '_web_tournament_sidechat_delete-group-chat',
              'Delete Group Chat'
            )
          }}
        </template>
      </button>
      <div v-else class="d-flex justify-content-center">
        <HeraFormBtn
          class="flex-fill m-3 h-mr-0 btn-secondary-simple"
          :disabled="isLoading"
          @click="$emit('back')"
        >
          {{ $t('_web_tournament_sidechat_cancel', 'Cancel') }}
        </HeraFormBtn>
        <HeraFormBtn
          class="flex-fill m-3 btn-accent-simple"
          :disabled="isLoading"
          @click="save(true, true)"
        >
          {{ addButtonTitle }}
        </HeraFormBtn>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Icon from '@/components/atoms/Icon';
import HeraFormTextInput from '@/components/atoms/form/HeraFormTextInput';
import Avatar from './EditableAvatar';
import ParticipantsList from './participants/List';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';

export default {
  components: {
    HeraFormBtn,
    Icon,
    HeraFormTextInput,
    Avatar,
    ParticipantsList,
  },
  inject: ['$validator'],
  props: {
    chat: {
      type: Object,
      default: null,
    },
    participants: {
      type: Array,
      default: () => [],
    },
    conversationType: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      subtitle: '',
      origTitle: '',
      origSubtitle: '',
      origAvatar: null,
      isParticipantsChanged: false,
      isLoading: false,
      avatarId: null,
      firstParticipantsLoad: true,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournamentInfo',
      'currentChatParticipantsUpdated',
      'currentChatId',
    ]),
    tournamentID() {
      return this.$route.params.tournamentID;
    },
    changed() {
      return (
        this.title !== this.origTitle ||
        this.subtitle !== this.origSubtitle ||
        this.isParticipantsChanged
      );
    },

    participantsIDList() {
      return this.participants.map(item => item.id);
    },
    headerTitle() {
      if (this.editMode) {
        return this.conversationType === 'channel'
          ? this.$t('_web_tournament_sidechat_channel-info', 'Channel info')
          : this.$t(
              '_web_tournament_sidechat_groupchat-info',
              'Group Chat info'
            );
      } else {
        return this.conversationType === 'channel'
          ? this.$t('_web_tournament_sidechat_channel-info', 'Create Channel')
          : this.$t(
              '_web_tournament_sidechat_groupchat-info',
              'Create Group Chat'
            );
      }
    },
    addButtonTitle() {
      return this.conversationType === 'channel'
        ? this.$t('_web_tournament_sidechat_add-new-channel', 'Add New Channel')
        : this.$t(
            '_web_tournament_sidechat_add-new-groupchat',
            'Create Group Chat'
          );
    },
    nameInputTitle() {
      return this.conversationType === 'channel'
        ? this.$t('_web_tournament_sidechat_channel-name', 'Channel Name')
        : this.$t('_web_tournament_sidechat_groupchat-name', 'Group Name');
    },
  },
  watch: {
    participants() {
      if (!this.editMode) return;
      if (this.firstParticipantsLoad)
        return (this.firstParticipantsLoad = false);
      this.save(true);
    },
    editMode() {
      this.clearState();
    },
    currentChatParticipantsUpdated(updated) {
      if (!updated) {
        return;
      }
      this.reloadParticipantsList();
      this.$store.commit('setCurrentChatParticipantsUpdated', false);
    },
  },
  mounted() {
    this.participanName();
  },
  created() {
    if (this.editMode || this.chat) {
      this.origTitle = this.title = this.chat.title;
      this.origSubtitle = this.subtitle = this.chat.subtitle;
      this.origAvatar = this.chat.avatar;
      this.reloadParticipantsList();
    } else {
      this.$emit('setParticipants', []);
      this.precreateConversation();
    }
  },
  methods: {
    ...mapActions([
      'createTournamentConversation',
      'fetchCurrentChatParticipants',
      'updateConversation',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    ...mapMutations([
      'setCurrentChatId',
      'setCurrentConversationAvatar',
      'setSliderChatActiveChat',
    ]),
    back() {
      if (this.editMode) {
        this.save()
          .catch(this.errorNotify)
          .finally(() => {
            this.$emit('back');
          });
      } else {
        this.$emit('back');
      }
    },
    participanName() {
      setTimeout(() => {
        const nonOrganizationParticipants = this.participants.filter(
          item => item.subjectType !== 'organization'
        );

        if (
          nonOrganizationParticipants.length > 0 &&
          this.conversationType !== 'channel'
        ) {
          this.title = nonOrganizationParticipants[0].name;
        } else {
          this.title = '';
        }
      }, 500);
    },
    save(forceSave, visible) {
      if (!forceSave && !this.changed) return Promise.resolve();
      if (visible) {
        this.isLoading = true;
      }
      return Promise.resolve()
        .then(() => this.$validator.validateAll())
        .then(isFieldsValide => {
          if (!isFieldsValide) {
            return Promise.reject('fieldsUnvalide');
          }
          const data = {
            title: this.title,
            subtitle: this.subtitle,
            participants: this.participantsIDList,
            avatar: this.avatarId,
          };
          if (visible !== undefined) {
            data.visible = visible;
          }
          return { id: this.currentChatId, data };
        })
        .then(this.updateConversation)
        .then(() => {
          this.$emit('setParticipantsCount', this.participants.length);
          this.$emit('setConversationTitle', this.title);
          if (visible) {
            this.$emit('back');
          }
        })
        .catch(err => {
          if (err !== 'fieldsUnvalide') {
            this.errorNotify(err);
          }
        })
        .finally(() => (this.isLoading = false));
    },
    deleteBtnClickHandler() {
      const chatId = this.currentChatId;
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament_sidechat_channel-delete-confirm',
            'Are you really want to delete this conversation?'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: () => {
            this.deleteConversation(chatId)
              .then(() => {
                this.closeModal();
              })
              .finally(() => (this.isLoading = false));
          },
        },
      });
    },
    deleteConversation(id) {
      return this.updateConversation({
        id,
        data: { visible: false },
      })
        .then(() => {
          this.$emit('delete');
        })
        .catch(this.errorNotify);
    },
    avatarEditHandler(avatarData) {
      this.avatarId = (avatarData && avatarData.file_id) || '';
      this.setCurrentConversationAvatar(avatarData && avatarData.display_uri);
      this.$emit('setNewAvatar', avatarData && avatarData.display_uri);

      if (this.editMode) this.save();
    },
    clearState() {
      this.title = '';
      this.subtitle = this.currentTournamentInfo.title || '';
      this.origTitle = '';
      this.origSubtitle = '';
      this.origAvatar = null;
      this.isParticipantsChanged = false;
      this.isLoading = false;
      this.avatarId = null;
      this.$emit('setParticipants', []);
      this.precreateConversation();
    },
    precreateConversation() {
      if (this.chat) {
        return;
      }
      this.isLoading = true;
      this.subtitle = this.currentTournamentInfo.title || '';
      this.createTournamentConversation({
        title: '',
        subtitle: this.subtitle,
        tournamentID: this.tournamentID,
        type: this.conversationType,
        visible: false,
      })
        .then(res => {
          this.setSliderChatActiveChat(res);
          this.setCurrentChatId(res.id);
        })
        .catch(err => {
          this.errorNotify(err);
          this.$emit('back');
        })
        .finally(() => (this.isLoading = false));
    },
    reloadParticipantsList() {
      if (!this.currentChatId) {
        return;
      }
      this.isLoading = true;
      this.fetchCurrentChatParticipants()
        .then(res => {
          this.firstParticipantsLoad = true;
          this.$emit('setParticipants', [...res]);
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
    inputHandler() {
      if (this.editMode) {
        this.save();
      }
    },
  },
};
</script>

<style lang="scss">
.slider-chat-channel-editor {
  background-color: $ph-card-raw;

  &-transition {
    &-enter-active,
    &-leave-active {
      transition: transform 0.4s;
    }
    &-enter,
    &-leave-to {
      transform: translateY(-100vh);
    }
  }

  &__title {
    font-weight: 700;
    font-size: 0.875rem;
  }

  &__header {
    height: 3.25rem;
    background-color: $ph-card;
    padding: 0 1rem;
  }

  &__back-icon {
    cursor: pointer;
    margin-right: 0.75rem;
  }

  &__info {
    padding: 0.75rem;
  }

  &__channel-name-input {
    flex: 1 auto;
  }

  &__partisipants {
    flex: 1 auto;
    padding: 0 0.75rem 0.75rem;
    position: relative;
  }

  &__delete-btn {
    height: 3.25rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  &__delete-btn {
    background-color: $ph-card;
    color: $ph-error;
    width: 100%;
  }
}
</style>
