import dateTime from './dateTime';
import string from './string';
import displayName from './displayName';
import thousandsShort from './thousandsShort';

export default {
  ...dateTime,
  ...string,
  ...displayName,
  ...thousandsShort,
};
