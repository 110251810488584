<template>
  <div v-if="TopUsers && TopUsers.length > 0">
    <h2 class="top-title">
      {{ $t('_web_feed-top-users-title', 'TOP USERS') }}
    </h2>
    <div
      v-for="(user, index) in TopUsers.slice(0, num)"
      :key="index"
      class="top-user"
    >
      <LinkAccount :id="user.login" class="login" :type="user.type">
        <Avatar :img-url="user.avatar" :type="user.type" :size="48" />
        <div class="top-container">
          <span class="top-name">{{ user.name }}</span>
          <span class="account-tag"
            >@{{ user.type === 'organization' ? user.name : user.login }}</span
          >
        </div>
      </LinkAccount>
    </div>
    <button
      v-if="TopUsers.length > 7 && num <= TopUsers.length"
      class="show-more-less"
      @click="() => (num = num + 7)"
    >
      {{ $t('_web_feed-following-See-more', 'See more') }}
    </button>
    <button v-else class="show-more-less" @click="() => (num = 7)">
      <span v-if="TopUsers.length > 7">
        {{ $t('_web_feed-following-Show-less', 'Show less') }}
      </span>
    </button>
    <div class="braking-line"></div>
  </div>
</template>

<script>
import { API } from '@/api/config';
import LinkAccount from '@/components/links/Account';
import Avatar from '@/components/images/Avatar';

export default {
  name: 'TopUsers',

  components: {
    LinkAccount,
    Avatar,
  },
  data() {
    return {
      TopUsers: [],
      num: 7,
    };
  },
  created() {
    this.fetchTopUsers();
  },
  methods: {
    async fetchTopUsers() {
      const response = await API(`/popularpeople`);
      this.TopUsers = response.data;
    },
  },
};
</script>

<style lang="scss">
.top-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  .account-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $new-fg-neutral-faded;
  }
}
.top-user {
  margin-bottom: 10px;
  .avatar {
    @include auto-rtl(margin-right, 10px);
  }
  .top-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #eff0f1;
  }
}
.top-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #eff0f1;
  margin-bottom: 24px;
}
</style>
