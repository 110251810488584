<template>
  <div class="popover-profile">
    <div class="popover-body p-0">
      <div
        slot="header"
        class="d-flex flex-column align-items-center popover-header"
      >
        <div class="bar-wrapper">
          <div class="purple-bar"></div>
        </div>
      </div>
      <!-- <hr class="hr-sidebar" /> -->
      <SidebarFooter />
      <!-- <hr class="hr-sidebar" /> -->
      <!-- <div
        slot="footer"
        class="profile-links d-flex justify-content-between border-bottom-0"
      >
        <router-link :to="{ name: 'signin' }">
          <button class="cta-message-button-login">
            {{ $t('_web_log_in', 'Log In') }}
          </button>
        </router-link>
        <router-link :to="{ name: 'signup' }">
          <button class="cta-message-button-create">
            {{ $t('_web_create_account_btn', 'Create Account') }}
          </button>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SidebarFooter from '@/components/footer/SidebarFooter.vue';

export default {
  name: 'GuestSidebar',
  components: {
    SidebarFooter,
  },
  data() {
    return {
      optionsPosition: null,
      showAll: false,
      dropdown: false,
    };
  },

  computed: {
    ...mapState('my', ['account', 'accountType']),
    ...mapGetters('sidebars', ['guestOpen']),
    heraShopLink() {
      const region = JSON.parse(localStorage.getItem('region'));
      const locale = JSON.parse(localStorage.getItem('locale'));
      return region.feature_flags.marketplace_url
        ? `${region.feature_flags.marketplace_url}${locale.key}-sa/`
        : null;
    },
  },

  methods: {
    ...mapActions('sidebars', ['setGuestOpen']),
    ...mapActions(['openModal']),
    popoverSwitched(isVisible) {
      if (!isVisible) {
        this.optionsPosition = null;
      }
    },
    togglePopover() {
      if (!this.isAuthorized) {
        this.openModal({
          name: 'PleaseSignIn',
          settings: {
            variant: 'fit-content',
          },
        });
        return;
      }
      this.optionsPosition = this.optionsPosition ? null : this.$el;
    },
  },
  //
  toggleSeeAll() {
    this.showAll = !this.showAll;
  },
};
</script>

<style lang="scss" scoped>
.cta-message-button {
  gap: 10px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  &-login {
    border: 1px solid $hr-text-primary;
    border-radius: 100px;
    color: $hr-text-primary;
    background-color: transparent;
    font-weight: bold;
    font-size: $hr-text-small;
    text-align: center;
    width: 100%;
    height: 36px;
    margin: 0px !important;
  }

  &-create {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    font-size: $hr-text-small;
    text-align: center;
    letter-spacing: 0.16px;
    color: #eb12bb;
    background-color: $hr-text-primary;
    border-radius: 100px;
    border: 1px solid $hr-text-primary;
    width: 100%;
    height: 36px;
    margin: 0px !important;
    white-space: nowrap;
  }
}
.popover-profile {
  position: relative;

  .profile-avi {
    border: solid 2px $hr-bg-accent;
  }

  .popover-trigger {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
  }

  .popover-body {
    right: -17rem;
    width: 40vw;
    min-height: 500px;
    padding-top: 1rem !important;
    padding-right: 10px !important;
    top: $hr-spacing-07 !important;
    @media (max-width: 700px) {
      width: 60vw;
      padding-left: 20px !important;
    }
    @media (max-width: 470px) {
      width: 100vw;
      padding-left: 20px !important;
    }
    .list-loader {
      .list-wrapper {
        border-radius: unset !important;
      }
      ul {
        background-color: $ph-card;
      }
      li {
        border-bottom: 1px solid $ph-input-bg;
        &:last-child {
          border-bottom: none;
        }
      }
      .media {
        white-space: normal;
      }
      .media-body {
        width: 100% !important;
      }
      .item-name {
        line-height: 1.25rem;
      }
      .item-name-multilines {
        @include text-multiline-ellipses(2, 24px);
      }
    }
    .btn-action {
      height: 32px;
      line-height: 32px;

      svg {
        height: 24px;
        margin-top: -4px;
      }
    }
    .popover-footer {
      display: flex;
      flex-direction: column;
    }
    .item-subtitle {
      font-size: 0.75rem;
      font-weight: 500;
      color: $ph-secondary-text;
      line-height: 1.2rem;
    }
  }
  .float-dropbox {
    .list-loader {
      min-height: unset !important;
      max-height: 50vh;
    }
    &:not(.loading-accounts) .list-loader {
      min-height: unset !important;
    }
    &.no-accounts .list-loader {
      display: none !important;
    }
  }
  .list-unstyled {
    padding: 0;
  }
}

.org-container {
  border-bottom: 1px solid $hr-clr-border1;
  :hover {
    background-color: $hr-bg-hover-notif;
  }
  div {
    padding-inline: 14px !important;
    .org-right-pad {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  &:hover {
    background-color: $hr-bg-hover-notif !important;
  }
}

.org-right {
  padding-left: 0px !important;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.org-right-inner {
  padding-inline: unset;
  padding-left: 0px !important;
  display: flex;
  flex-direction: column;
}

.media {
  padding-top: $hr-spacing-07;
}

.media-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: $hr-spacing-05;
  padding-right: $hr-spacing-05;
  padding-bottom: $hr-spacing-05;
  border-bottom: 1px solid $hr-clr-border1;
  width: 100%;
}

.profile-avatar {
  mask-image: none;
  border-radius: 10px;
  border: solid 3px $hr-bg-accent;
  margin-top: $hr-spacing-07;
}

.profile-id {
  color: $hr-text-quaternary;
}

.fav-games-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: $hr-spacing-05;
}

.teams-inner {
  overflow-x: hidden;
}

.fav-games-inner {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  justify-content: center;
}

.teams-container {
  width: 100%;
}

.teams-wrapper {
  width: 100%;
}

.profile-clans {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: $hr-spacing-05;
}

.element-label {
  font-size: $hr-text-small;
  padding-bottom: $hr-spacing-03;
}

.profile-d-games {
  padding-top: $hr-spacing-03;
}

.profile-bottom {
  width: 100%;
  height: $hr-spacing-10;
}

.profile-links {
  border-bottom: 1px solid $hr-clr-border1;
  font-size: $hr-text-small;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  padding: 20px;

  .prf-icon {
    padding: $hr-spacing-03;
    padding-left: 0;
    padding-right: 0.8rem;

    .app-rtl & {
      transform: scaleX(-1);
    }
  }

  a {
    font-weight: 500;

    &:hover {
      color: white;
    }
  }
}

.profile-logout {
  width: 100%;
  padding-bottom: 1.95rem;
}

.arrow-right {
  .app-rtl & {
    rotate: 180deg;
  }
}

.link-wrap {
  display: flex;
  align-items: center;
  padding: $hr-spacing-03 $hr-spacing-05 $hr-spacing-03 $hr-spacing-05;

  &:hover {
    background-color: $hr-bg-hover-notif;
  }
}

.logout-wrap {
  &:hover {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.img-wrap {
  width: 35px;
}

.org-avatar {
  @include auto-rtl(margin-right, $hr-spacing-03);
}

.bar-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.purple-bar {
  width: 18px;
  height: 4px;
  background-color: $hr-bg-accent;
  position: fixed;
  margin-top: 2px;
  @include auto-rtl(margin-right, 25px);
}

@media screen and (max-height: 834px) {
  .teams-wrapper {
    display: none;
  }

  .fav-games-wrapper {
    display: none;
  }
}
.hr-sidebar {
  margin: 0;
}
.purple-bar {
  display: none;
}
.profile-clans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 850px) {
  .nofix-style {
    display: none !important;
  }
}
</style>
