<template>
  <div>
    <HeraFormSwitch
      v-model="thirdPlaceMatch"
      v-validate="'required'"
      name="third_place_match"
      :label="$$t('Match for the 3rd place:', '3rd_place-label')"
      required
      :disabled="isStageFieldDisabled('third_place_match') || disabled"
      :error="errors.first('third_place_match')"
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import { prop } from '@/utils/factories';
export default {
  name: 'StageThirdPlaceMatchSetting',
  components: {
    HeraFormSwitch,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    thirdPlaceMatch: {
      get() {
        return this.getBlockConfigurationByKey('match_3rd_place');
      },
      set(newVal) {
        this.setBlockConfigurationByKey('match_3rd_place', newVal);
      },
    },
  },
};
</script>

<style></style>
