var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popover',_vm._b({staticClass:"dropbox-menu",style:(_vm.stylePopover),on:{"toggle":function($event){return _vm.$emit('toggle', $event)}}},'Popover',{
    position: _vm.positionReplaced,
    float: _vm.float,
    regard: _vm.regard,
    targetSelector: _vm.targetSelector,
    closest: _vm.closest,
    isFixed: _vm.isFixed,
    blockIsFixed: _vm.blockIsFixed,
    translate: _vm.translate,
    ignoreClicksOn: _vm.ignoreClicksOn,
    beforeCloseCallback: _vm.beforeCloseCallback,
  },false),[_vm._t("header"),(_vm.items && _vm.items.length)?_c('div',{staticClass:"list-wrapper custom-scrollbar"},[_c('div',{staticClass:"h-text-left list-custom",style:(_vm.styleContent)},[_c('ul',{staticClass:"list-unstyled mb-0 px-0"},_vm._l((_vm.items),function(item,index){return _c('li',_vm._g({key:(_vm.popoverMenuList + "_" + index)},_vm.$handler),[_vm._t("default",null,null,{ item: item, index: index })],2)}),0)])]):_vm._e(),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }