<template>
  <table class="w-100 battle-royale-points">
    <thead>
      <tr>
        <th>
          <TextLabel>{{ $$t('Rank') }}</TextLabel>
        </th>
        <th>
          <TextLabel>{{ $$t('Points Per Rank') }}</TextLabel>
        </th>
        <th v-if="!readOnly" class="battle-royale-points-actions"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(points, index) in pointsPerPlace" :key="`row_${index}`">
        <td>
          {{ index + 1 }}
        </td>
        <td>
          <FormInput
            v-model.number="pointsPerPlace[index]"
            v-validate="'required|min:0'"
            required
            :value="points"
            type="number"
            :min="1"
            step="1"
            size="3"
            class="w-auto battle-royale-points-input"
            :disabled="readOnly"
            :error="errors.first(`points-${index}`)"
            :data-vv-as="$$t('Points')"
            :data-vv-name="`points-${index}`"
            @input="emitPoints(pointsPerPlace)"
          />
        </td>
        <td v-if="!readOnly" class="battle-royale-points-actions">
          <div
            class="cursor-pointer text-center"
            :title="$$t('Remove field')"
            @click="removeField(index)"
          >
            <Icon name="delete" />
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="!readOnly">
      <tr>
        <th></th>
        <th class="pt-3" colspan="2">
          <Btn
            v-if="pointsPerPlace.length < 200"
            class="btn-secondary-simple"
            @click.native="addField"
          >
            <Icon name="add" width="20" class="h-mr-3" />
            <TextBlock :text="$$t('Add field')" />
          </Btn>
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { prop } from '@/utils/factories';
import { translate } from '@/mixins/translate';

import Btn from '@/components/atoms/HeraFormBtn';
import Icon from '@/components/atoms/Icon';
import FormInput from '@/components/atoms/form/HeraFormTextInput';
import TextLabel from '@/components/atoms/TextLabel';
import TextBlock from '@/components/atoms/TextBlock';
export default {
  name: 'BattleRoyaleTableOfPoints',
  components: { TextLabel, Icon, Btn, FormInput, TextBlock },
  mixins: [translate('_web_tournament_battle-royale_edit-points')],
  inject: ['$validator'],
  props: {
    value: prop(Array),
    readOnly: prop(Boolean),
  },
  computed: {
    pointsPerPlace: {
      get() {
        return this.$lodash.cloneDeep(this.value);
      },
      set(val) {
        this.emitPoints(val);
      },
    },
  },
  methods: {
    emitPoints(value = this.pointsPerPlace) {
      this.$emit('input', this.$lodash.cloneDeep(value));
    },
    addField() {
      if (!this.pointsPerPlace.length) {
        this.pointsPerPlace = [10];
        return;
      }

      const lastPoints = this.pointsPerPlace.slice(-1)[0];
      this.pointsPerPlace = [
        ...this.pointsPerPlace,
        Math.max(lastPoints - 1, 1),
      ];
    },
    removeField(index) {
      this.pointsPerPlace.splice(index, 1);
      this.emitPoints();
    },
  },
};
</script>

<style lang="scss">
.battle-royale-points {
  table-layout: fixed;
  td,
  th {
    @include auto-rtl(padding, 0.15rem 0.5rem 0.15rem 0);
    &:first-of-type {
      width: 4rem;
    }
    .input-control {
      max-width: 6rem;
    }
    .input-form {
      max-width: 55px;
    }
  }
  &-actions {
    width: 3rem;
  }
}
</style>
