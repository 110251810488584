<template>
  <component
    :is="gridComponent"
    v-if="gridComponent"
    :round-i-d="roundID"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'TableViewWrapper',
  props: {
    blockType: {
      type: String,
      default: 'single-elimination',
    },
    roundID: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    gridComponent() {
      return () => import(`./${this.blockType}/TableView`);
    },
  },
};
</script>
