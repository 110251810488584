<template>
  <div class="outer-wrapper-cta" :class="hide ? 'd-none' : ''">
    <div class="cta-wrapper-chat">
      <!-- <span class="cta-header-chat">CHAT WITH ADMINISTRATION</span> -->
      <span class="cta-content-chat"
        >{{ $t('_web_chat_type', 'Type') }}<strong> @admin </strong>
        {{
          $t('_web_chat_organizer-help', 'to notify tournament organiser.')
        }}</span
      >
      <button class="close-btn-chat" @click="hideCta">x</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CtaAdmin',
  data() {
    return {
      hide: false,
    };
  },
  methods: {
    hideCta() {
      this.hide = true;
    },
  },
};
</script>
<style>
.outer-wrapper-cta {
  z-index: 9999;
  margin-top: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta-wrapper-chat {
  position: relative;
  width: 95%;
  display: flex;
  min-height: 56px;
  background: #1c212b;
  padding: 10px;
  border: 1px solid #30374a;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}
.close-btn-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 24px;
  background-color: #30374a;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 700;
}
.cta-content-chat {
  max-width: 90%;
  color: #eff0f1;
  font-size: 14px;
  line-height: 20px;
}
.cta-header-chat {
  color: #9d9cf7;
  font-weight: 700;
}
</style>
