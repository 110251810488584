import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters(['popoverChatIsOpened']),
  },

  created() {
    const { accountType, accountId } = this.$route.params;

    this.toggleLockScreen(true);

    if (this.popoverChatIsOpened) {
      this.hidePopoverChat();
    }

    let isAccountSwitched = false;

    this.switchAccount({ accountId, accountType })
      .then(data => {
        isAccountSwitched = !!data;
      })
      .then(() => this.reconnectHub())
      .then(() => {
        return this.$router.push('/').then(() => {
          this.updateAppId();
        });
      })
      .catch(err => {
        const message = this.$lodash.get(err, 'message');
        if (message === 'Invalid security context') {
          //dirty hack for broken account swith in separate tab
          window.location.href = `${window.location.origin}/feed`;
        } else {
          this.errorNotify(err);
          if (isAccountSwitched) {
            this.$router.push({ name: 'signout' });
          } else {
            this.$router.back();
          }
        }
      })
      .finally(() => {
        this.toggleLockScreen(false);
      });
  },

  methods: {
    ...mapMutations(['hidePopoverChat', 'reconnectHub', 'updateAppId']),
    ...mapActions(['switchAccount', 'errorNotify', 'toggleLockScreen']),
  },

  render() {}, // not remove
};
