import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const PREFIX = 'ecommerce';
const PAYMENTS = 'payments';
const PRODUCTS = 'products';
const CARDS = 'cards';
const CARD = 'card';
const CATEGORIES = 'categories';
const ORDERS = 'orders';

// products and categories ***********************************************************
const getProducts = (
  accessToken,
  page_size = 12,
  page = null,
  page_number,
  category,
  search,
  cost_from,
  cost_to,
  lang
) =>
  authorizedGetRequest(`${PREFIX}/${PRODUCTS}/${CARDS}`, {
    preventCancelRequest: true,
  })(accessToken, {
    params: {
      page_size,
      page,
      page_number,
      category,
      search,
      cost_from,
      cost_to,
      lang,
    },
  });

const getIndividualCard = (accessToken, id, lang) =>
  authorizedGetRequest(`${PREFIX}/${PRODUCTS}/${CARDS}/${id}?lang=${lang}`, {
    preventCancelRequest: true,
  })(accessToken);
const getSingleCard = (accessToken, id, lang) =>
  authorizedGetRequest(`${PREFIX}/${PRODUCTS}/${CARD}/${id}?lang=${lang}`, {
    preventCancelRequest: true,
  })(accessToken);

const getCategories = (accessToken, lang) =>
  authorizedGetRequest(
    `${PREFIX}/${PRODUCTS}/${CARDS}/${CATEGORIES}?lang=${lang}`,
    {
      preventCancelRequest: true,
    }
  )(accessToken);

// orders ********************************************************************************
const getOrders = (
  accessToken,
  page_size = 12,
  page = null,
  page_number,
  status
) =>
  authorizedGetRequest(`${PREFIX}/${ORDERS}`, {
    preventCancelRequest: true,
  })(accessToken, {
    params: {
      page_size,
      page,
      page_number,
      status,
    },
  });
const checkOrderStatus = (accessToken, provider, id) =>
  authorizedGetRequest(`${PAYMENTS}/charge/${provider}/${id}`)(accessToken);
const getOrderById = (accessToken, id) =>
  authorizedGetRequest(`${PREFIX}/${ORDERS}/${id}`, {
    preventCancelRequest: true,
  })(accessToken);

const deleteOrder = (accessToken, id) =>
  authorizedDeleteRequest(`${PREFIX}/${ORDERS}/${id}`)(accessToken);

const orderFulfill = (accessToken, id, token, save_card) =>
  authorizedPostRequest(`${PAYMENTS}/${id}/charge`)(accessToken, {
    token,
    save_card,
  });

const applyCoupon = (accessToken, orderId, coupon) =>
  authorizedPutRequest(`${PREFIX}/${ORDERS}/${orderId}/coupon`)(accessToken, {
    coupon,
  });

const placeOrder = (accessToken, data) =>
  authorizedPostRequest(`${PREFIX}/shop`)(accessToken, data);

const getCards = acessToken =>
  authorizedGetRequest(`${PAYMENTS}/cards`)(acessToken);

const placeTournamentOrder = (accessToken, data) =>
  authorizedPostRequest(`${PREFIX}/tournament_entry`)(accessToken, data);

const tournamentRevokePaid = (accessToken, userID, tournamentID) =>
  authorizedGetRequest(
    `${PREFIX}/tournament_entry/revoked?player_id=${userID}&tournament_id=${tournamentID}`,
    {
      preventCancelRequest: true,
    }
  )(accessToken);
export default {
  getProducts,
  getIndividualCard,
  getCategories,
  getOrderById,
  getOrders,
  deleteOrder,
  orderFulfill,
  placeOrder,
  getCards,
  applyCoupon,
  checkOrderStatus,
  getSingleCard,
  placeTournamentOrder,
  tournamentRevokePaid,
};
