<template>
  <div
    :class="[
      bindClasses,
      {
        'swap-target': isSwapTarget,
        'swap-source': isSwapSource,
        'third-place-match flex-column': thirdPlace,
      },
    ]"
    class="d-flex align-items-center justify-content-center match-wrapper simple-card"
    :data-round-index="roundIndex"
    :data-match-index="matchIndex"
    :style="offsetStyles"
    dir="ltr"
  >
    <Panel
      class="match-card my-2 px-4 py-3 "
      :variant="
        isSwapSource
          ? [
              'stroked',
              'stroked-accent',
              'rounded',
              'rounded-medium',
              'dark-bg',
            ]
          : [
              'stroked',
              'stroked-secondary',
              'rounded',
              'rounded-medium',
              'dark-bg',
            ]
      "
      :class="{
        empty: !match.id,
      }"
      v-on="$listeners"
      @click="$emit('setActiveCard', $event)"
    >
      <template v-if="match.id">
        <div class="d-flex flex-column">
          <div v-if="showRound" class="d-flex align-items-center mr-2">
            <!-- <TextBlock class="mb-0" variant="secondary" :size="1">
              {{
                $t('_web_tournament_battle_id', '#{match}', {
                  match: match.index + 1,
                })
              }}
            </TextBlock> -->
            <TextLabel class="p-0 battle-id-label">
              {{ roundName }}
            </TextLabel>
            <TextLabel class="p-0 battle-id-label ml-auto">
              {{ $t('_web_tournament_battle_score', 'Score') }}
            </TextLabel>
          </div>
          <div class="flex-grow-1">
            <!-- <transition name="move-in" appear> -->
            <playerItem
              :class="{
                'swap-target': isSwapTarget,
                'swap-source': isSwapSource,
                'bolded-winner-brackets':
                  match.player_1_result === 'tech_win' ||
                  match.player_1_result === 'win',
                'bolded-loser-brackets':
                  match.player_1_result === 'tech_loss' ||
                  match.player_1_result === 'loss',
              }"
              :player="match.player_1"
              :player-score="playerOneScore"
              :can-swap="canSwapPlayerOne"
              :can-delete-player="canEditPlayerOne"
              :is-swap-target="isSwapTarget"
              :is-swap-source="isSwapSource"
              :swap-index="swapIndex"
              :player-index="1"
              :round-index="roundIndex"
              :match-index="matchIndex"
              :variant="playerOneVariant"
              :is-profile="isSingle"
              :chat-with="match.captain_1"
              :is-ready-for-battle="!match.is_detained || match.player_1_ready"
              :is-empty="match.player_empty_1"
              :hide-score="isMatchCancelled"
              class="p-0 mb-1"
              v-on="{
                swapCancel: onSwapCancel,
                swap: onSwap,
                deletePlayer: onDeletePlayer,
                addPlayer: $listeners.addPlayer || $lodash.noop,
                userMenuOpen: $listeners.userMenuOpen || $lodash.noop,
              }"
            />
            <!-- </transition> -->

            <!-- <transition name="move-in" appear> -->
            <playerItem
              :class="{
                'swap-target': isSwapTarget,
                'swap-source': isSwapSource,
                'bolded-winner-brackets':
                  match.player_2_result === 'tech_win' ||
                  match.player_2_result === 'win',
                'bolded-loser-brackets':
                  match.player_2_result === 'tech_loss' ||
                  match.player_2_result === 'loss',
              }"
              class="p-0"
              :player="match.player_2"
              :player-score="playerTwoScore"
              :can-swap="canSwapPlayerTwo"
              :can-delete-player="canEditPlayerTwo"
              :is-swap-target="isSwapTarget"
              :is-swap-source="isSwapSource"
              :swap-index="swapIndex"
              :player-index="2"
              :round-index="roundIndex"
              :match-index="matchIndex"
              :variant="playerTwoVariant"
              :is-profile="isSingle"
              :chat-with="match.captain_2"
              :is-ready-for-battle="!match.is_detained || match.player_2_ready"
              :hide-score="isMatchCancelled"
              :is-empty="match.player_empty_2"
              v-on="{
                swapCancel: onSwapCancel,
                swap: onSwap,
                deletePlayer: onDeletePlayer,
                addPlayer: $listeners.addPlayer || $lodash.noop,
                userMenuOpen: $listeners.userMenuOpen || $lodash.noop,
              }"
            />
            <!-- </transition> -->
          </div>
          <div class="d-flex align-items-center mt-2 small-badge">
            <StatusLabel :status="match.status" class="px-2 py-1" />
            <div v-if="!isMatchCancelled" class="started-date mx-3">
              {{ match.date_start | localDate($times.DATETIME_MED) }}
            </div>
          </div>
        </div>

        <!-- <TextLabel v-if="match.battle_id" class="p-0 mt-3 d-block text-left">
          {{
            $t('_web_tournament_battle_id', '#{match}', {
              match: match.index + 1,
            })
          }}
        </TextLabel> -->
      </template>
    </Panel>
    <TextLabel v-if="thirdPlace" class="my-0">
      {{ $t('web_tournament_third-place-match', 'Match for third Place') }}
    </TextLabel>
  </div>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import { BATTLE_STATUSES } from '@/constants/battles';
import { getPlayerScore } from '@/views/tournaments/utils/players';
import playerItem from '@/components/tournaments/playerItem.vue';
import { prop, propRequired } from '@/utils/factories';
import gridBinds from '@/mixins/tournaments/gridBinds';
import TextLabel from '@/components/atoms/TextLabel';
// import TextBlock from '@/components/atoms/TextBlock';
import StatusLabel from '@/components/molecules/StatusLabel';

export default {
  name: 'DuelMatchCard',
  components: {
    Panel,
    playerItem,
    TextLabel,
    // TextBlock,
    StatusLabel,
  },
  mixins: [gridBinds],
  props: {
    canSwapPlayers: prop(Boolean),
    canDeletePlayers: prop(Boolean),
    isSwapTarget: prop(Boolean),
    isSwapSource: prop(Boolean),
    isSwapping: prop(Boolean),
    isSingle: prop(Boolean),
    thirdPlace: prop(Boolean),
    roundIndex: propRequired(Number),
    hasNotPrevRound: prop(Boolean),
    hasNotNextRound: prop(Boolean),
    matchIndex: propRequired(Number),
    isTreeMode: prop(Boolean),
    roundName: prop(String, ''),
    showRound: prop(Boolean),
    match: {
      type: Object,
      default: () => ({
        date_end: null,
        date_start: null,
        id: '',
        index: 0,
        next_match_id: '',
        next_match_index: 0,
        player_1: {},
        player_1_score: null,
        player_2: {},
        player_2_score: null,
        round: 0,
        isLoading: false,
      }),
    },
  },
  data() {
    return {
      isVisible: false,
      loadCard: false,
      swapIndex: null,
      highlightIndex: null,
      uniqueId: this.$lodash.uniqueId('v_datetime'),
    };
  },
  computed: {
    matchID() {
      return this.match.id;
    },
    canSwapPlayerOne() {
      return (
        this.match.can_edit_player_1 &&
        this.canSwapPlayers &&
        !this.isMatchCompleted &&
        !this.isMatchCancelled
      );
    },
    canSwapPlayerTwo() {
      return (
        this.match.can_edit_player_2 &&
        this.canSwapPlayers &&
        !this.isMatchCompleted &&
        !this.isMatchCancelled
      );
    },
    canEditPlayerOne() {
      return (
        this.match.can_edit_player_1 &&
        this.canDeletePlayers &&
        !this.isMatchCompleted &&
        !this.isMatchCancelled
      );
    },
    canEditPlayerTwo() {
      return (
        this.match.can_edit_player_2 &&
        this.canDeletePlayers &&
        !this.isMatchCompleted &&
        !this.isMatchCancelled
      );
    },
    playerOneScore() {
      return getPlayerScore(this.match, 1);
    },

    playerTwoScore() {
      return getPlayerScore(this.match, 2);
    },

    playerOneVariant() {
      return this.match.is_detained && !this.match.player_1_ready
        ? 'error'
        : 'transparent';
    },

    playerTwoVariant() {
      return this.match.is_detained && !this.match.player_2_ready
        ? 'error'
        : 'transparent';
    },

    highlightedClass() {
      return `highlight_${this.highlightIndex}`;
    },

    swapIconName() {
      return this.isSwapTarget
        ? 'move-in'
        : this.isSwapSource
        ? 'move-out'
        : 'pick-for-move';
    },
    isScheduled() {
      return this.match.status === 'scheduled';
    },
    isMatchCompleted() {
      return this.match.status === BATTLE_STATUSES.completed;
    },
    isMatchCancelled() {
      return this.match.status === BATTLE_STATUSES.cancelled;
    },
    bindClasses() {
      const hasBinds = this.isTreeMode && !this.thirdPlace;
      const { toBind, fromBind } = this;
      fromBindReplaced;

      const fromBindReplaced =
        !this.hasNotPrevRound && hasBinds
          ? Number.isSafeInteger(fromBind)
            ? fromBind
            : 0
          : null;
      const toBindReplaced =
        !this.hasNotNextRound && hasBinds
          ? Number.isSafeInteger(toBind)
            ? toBind
            : this.matchIndex % 2 === 0
            ? 1
            : -1
          : null;

      return this.getClasses(fromBindReplaced, toBindReplaced);
    },
    offsetStyles() {
      return this.getOffset();
    },
  },
  watch: {
    isSwapping: function(newVal) {
      if (!newVal) {
        this.afterSwap();
      }
    },
  },
  methods: {
    onDeletePlayer(payload = {}) {
      this.$emit('playerDeleted', { matchID: this.matchID, ...payload });
    },
    onSwap(playerIndex) {
      // playerIndex starts from 1
      this.swapIndex = playerIndex;
      this.highlightIndex = playerIndex;
      if (this.isSwapTarget) {
        this.$emit('endSwap', playerIndex);
      } else {
        this.$emit('startSwap', playerIndex);
      }
    },

    onSwapCancel() {
      this.swapIndex = null;
      this.highlightIndex = null;
      this.$emit('swapCancelled');
    },

    afterSwap() {
      this.swapIndex = null;
      this.highlightIndex = null;
    },
    onMenuOpen(e) {
      this.$emit('toggleMenu', e);
    },
  },
};
</script>

<style lang="scss">
.match-wrapper.simple-card {
  min-height: $match-item-height;
  min-width: 294px;
  transition: all 0.2s ease-in;
  .match-card {
    position: relative;
    z-index: 1;
    width: 294px;
    height: auto;
    top: var(--current-y, 0);
  }

  &.third-place-match {
    height: 128px !important;
    position: absolute !important;
    top: calc(
      50% + 118px
    ); // half container height + card height + interval height
    transform: translateY(-50%);
    width: 100%;
  }

  .small-badge {
    .started-date {
      color: var(--ph-secondary-text);
    }
    .badge-hera {
      font-size: 0.5rem;
      line-height: 1rem;
      min-width: 60px;
      border-radius: 8px;
    }
  }
}
.bolded-winner-brackets {
  .player-iswinner {
    .team-name {
      .team-name-a {
        .winner-player-name {
          font-weight: 700;
        }
      }
    }
  }
  font-weight: 700;
}
.bolded-loser-brackets {
  .player-iswinner {
    .team-name {
      .team-name-a {
        .winner-player-name {
          font-weight: 400;
        }
      }
    }
  }
}
</style>
