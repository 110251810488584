<template>
  <div class="timeline-wrapper">
    <div class="shadow"></div>
    <div ref="timelineContainer" class="d-flex timeline">
      <div
        v-for="({ newProperty, value }, index) in times"
        :key="index"
        class="d-flex small-gap"
        :class="{ active: isActive === index }"
      >
        <div :key="index" class="d-flex align-items-center justify-content-end">
          <TimelineSvg :status="status" :index="index" />
        </div>
        <div class="event">
          <TextBlock
            :variant="isActive === index ? 'primary' : 'secondary'"
            :size="0.75"
            :line-height="1.5"
            class="status-name"
            :unset-styles="true"
          >
            {{ newProperty }}
          </TextBlock>
          <TextBlock
            v-if="value"
            class=""
            :size="0.75"
            line-height="1.4"
            :variant="isActive === index ? 'primary' : 'secondary'"
          >
            {{ value }}
          </TextBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import unixToMMMDDDayTime from '@/filters/unixToMMMDDDayTime';
import TextBlock from '@/components/atoms/TextBlock.vue';
import TimelineSvg from './TimelineSvg.vue';
const timeSteps = () => [
  'registration_start',
  'registration_end',
  'tournament_start',
  'tournament_end',
];

export default {
  name: 'Timeline',
  components: { TextBlock, TimelineSvg },
  computed: {
    ...mapGetters(['currentTournament']),
    currentTournamentTest() {
      return this.currentTournament;
    },
    status() {
      return this.currentTournament.status;
    },
    isActive() {
      const statusKey = this.currentTournament.status;
      const variants = {
        registration_opened: 0,
        registration_closed: 1,
        qualification_completed: 1,
        started: 2,
        closed: 3,
      };
      return variants[statusKey];
    },
    times() {
      const timeStepsObj = this.$lodash.pick(
        this.currentTournamentTest,
        timeSteps()
      );

      return Object.entries(timeStepsObj).map(([property, value]) => {
        property = this.$lodash.startCase(property);
        let newProperty = '';
        if (property === 'Registration Start') {
          newProperty = this.$t(
            `_web_tournament_registration_open`,
            'Registration open'
          );
        } else if (property === 'Registration End') {
          newProperty = this.$t(`_web_tournament_lineups`, 'Lineups');
        } else if (property === 'Tournament Start') {
          newProperty = this.$t(
            `_web_tournament_tournament-start`,
            'Tournament start'
          );
        } else {
          newProperty = this.$t(
            `_web_tournament_finished`,
            'Tournament finished'
          );
        }
        value = value ? unixToMMMDDDayTime(value) : null;

        return { newProperty, value };
      });
    },
  },
  updated() {
    if (window.innerWidth < 800 && this.$refs.timelineContainer) {
      this.$nextTick(() => {
        const activeStateEl = this.$refs.timelineContainer.querySelector(
          '.active'
        );
        if (activeStateEl) {
          const containerWidth = this.$refs.timelineContainer.offsetWidth;
          const activeStateElWidth = activeStateEl.offsetWidth;
          const activeStateElLeft = activeStateEl.offsetLeft;
          const containerScrollLeft =
            activeStateElLeft - containerWidth / 2 + activeStateElWidth / 2;
          this.$refs.timelineContainer.scrollTo({
            left: containerScrollLeft,
            behavior: 'smooth',
          });
        }
      });
    }
  },
};
</script>

<style lang="scss">
.timeline {
  position: relative;
  margin-top: 2em;
  height: 72px;
  background-color: black;
  border-radius: 5px;
  justify-content: space-evenly;
  gap: 2%;
  // overflow: auto;
  @media (max-width: 800px) {
    gap: 20px;
    overflow-x: scroll !important;
    max-width: 93vw !important;
    padding-left: 10% !important;
    padding-right: 15% !important;

    justify-content: unset !important;
    -ms-overflow-style: none; // IE
    scrollbar-width: none; // FireFox
    &::-webkit-scrollbar {
      // Chrome
      display: none;
    }
  }
}
.event {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
}

.small-gap {
  gap: 10px;
  @media (max-width: 800px) {
    min-width: max-content !important;
  }
}

.status-name {
  font-weight: 700;
}
.timeline-wrapper {
  content: '';
  background-color: black;
  width: 100%;
  position: relative;
  .shadow {
    @media (max-width: 800px) {
      &:before {
        content: '';
        position: absolute;
        width: 90px;
        height: 100%;
        z-index: 10;
        left: 0;
        top: 0;
        background: linear-gradient(to right, black, transparent);
      }
      &:after {
        content: '';
        position: absolute;
        width: 90px;
        height: 100%;
        z-index: 10;
        right: 0;
        top: 0;
        background: linear-gradient(to left, black, transparent);
      }
    }
  }
}
</style>
