<template>
  <WrapperFormControl
    v-bind="{
      hideLabel: !label,
      required: required && !hideAsterisk,
      label,
      tooltip,
      error,
      hideErrorText,
    }"
  >
    <template slot="control">
      <VueDatePicker
        v-model="valueModel"
        class="date-picker"
        :class="{
          'date-picker--fit-content': fitContent,
        }"
        value-type="timestamp"
        :format="dateFormat"
        type="datetime"
        :show-time-panel="false"
        :clearable="false"
        v-bind="{
          disabled,
          disabledDate,
          placeholder: placeholder || placeholderDefault,
          inputAttr: { size: !fitContent ? 20 : 9 },
        }"
        v-on="eventListeners"
      >
        <template slot="icon-calendar">
          <Icon name="arrow-s" class="dropdown-icon" width="16" />
        </template>
      </VueDatePicker>
    </template>
  </WrapperFormControl>
</template>

<script>
import VueDatePicker from 'vue2-datepicker';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
import Icon from '@/components/atoms/Icon.vue';

import { prop } from '@/utils/factories';
import dateMethods from '@/mixins/dateMethods';

export default {
  name: 'DatePicker',
  components: {
    VueDatePicker,
    WrapperFormControl,
    Icon,
  },
  mixins: [...dateMethods],
  props: {
    value: prop([Number, String], null),
    minDate: prop(Number),
    maxDate: prop(Number),
    name: prop(String),
    required: prop(Boolean),
    disabled: prop(Boolean),
    label: prop(String),
    placeholder: prop(String),
    error: prop(String),
    tooltip: prop(String),
    fitContent: prop(Boolean),
    hideAsterisk: prop(Boolean),
    hideErrorText: prop(Boolean),
    dateFormat: prop(String, 'MMM D'),
  },
  data() {
    return {
      placeholderDefault: this.$t(
        '_web_date-picker-default-placeholder',
        'Select Date'
      ),
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.secondsToMillis(this.value);
      },
      set(newValue) {
        this.$emit('input', this.millisToSeconds(newValue));
      },
    },
    eventListeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      return listeners;
    },
  },
  methods: {
    disabledDate(date) {
      date = this.dateToSeconds(date);
      let result = false;
      if (this.minDate) {
        const minDate = this.startOfDayObj(this.minDate).toSeconds();
        result = date < minDate;
      }
      if (this.maxDate) {
        const maxDate = this.startOfDayObj(this.maxDate).toSeconds();
        result = result || date > maxDate;
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
