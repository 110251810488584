<template>
  <div class="sub-plans-container">
    <div class="billing-details">
      <Panel
        v-if="currentPlan"
        :variant="[
          'transparent',
          'stroked',
          'stroked-input-borders',
          'rounded',
        ]"
        class="billings-details-wrapper"
      >
        <TextBlock
          tag="div"
          :size="1.25"
          class="secondary-font"
          variant="bold"
          >{{ $$t('Plan details') }}</TextBlock
        >
        <div class="plan-name mt-6">
          <TextBlock :size="0.825" class="h-mr-2">{{
            currentPlan.name
          }}</TextBlock>
          :
          <TextBlock :size="0.825" variant="bold" class="mx-2">{{
            currentPlan.currency
          }}</TextBlock>
          <TextBlock :size="0.825" variant="bold" class="mx-2">{{
            currentPlan.amount
          }}</TextBlock>
          /
          <TextBlock :size="0.825" variant="bold" class="mx-2">{{
            currentPlan.period
          }}</TextBlock>
        </div>
        <div
          v-if="
            currentPlan.period &&
              subCanceled.subscriptions &&
              subCanceled.subscriptions[0].next_transaction_cancelled
          "
          class="mt-5"
        >
          <TextBlock
            v-if="
              (subCanceled.subscriptions &&
                subCanceled.subscriptions[0].next_transaction_cancelled) ||
                !currentPlan.nextPaymentOn
            "
            :size="0.825"
            class="h-mr-2"
            >{{ $t('_web_sub_valid', 'Subscription valid until') }}</TextBlock
          >
          <TextBlock v-else :size="0.825" class="h-mr-2">{{
            $$t('Next payment date')
          }}</TextBlock>
          :
          <TextBlock :size="0.825" variant="bold" class="mx-2">{{
            currentPlan.activeTo | localDate($times.DATE_FULL)
          }}</TextBlock>
        </div>
        <div v-if="currentPlan.nextPaymentOn" class="mt-5">
          <TextBlock :size="0.825" class="h-mr-2">{{
            $$t('Next payment date')
          }}</TextBlock>
          :
          <TextBlock :size="0.825" variant="bold" class="mx-2">{{
            currentPlan.nextPaymentOn | localDate($times.DATE_FULL)
          }}</TextBlock>
        </div>
        <div
          v-if="
            subCanceled.subscriptions &&
              !subCanceled.subscriptions[0].next_transaction_cancelled &&
              currentPlan.nextPaymentOn
          "
          class="d-flex align-items-center mt-5 prolongation unsubscribe-billing"
        >
          <Btn
            class="btn-error-border btn-details-unsubscribe"
            :label="$$t('Unsubscribe')"
            @click="openConfirmCancel"
          />
        </div>
      </Panel>
      <HeraDummyList
        v-if="!currentPlan && !externalPlan && !activeVoucherPlan"
        icon-name="dummy/search"
        position="center"
        size="200"
        class="mb-5"
        :header-text="
          $t(
            '_web_subscription_no_user_subs',
            'Sorry, you don’t have any active subscriptions'
          )
        "
      >
        <template #ctaButton>
          <router-link :to="{ name: 'subscriptionSetup' }">
            <Btn class="btn btn-primary">
              {{ $t('_web_profile_popover-subscriptions', 'Subscriptions') }}
            </Btn>
          </router-link>
        </template>
      </HeraDummyList>
    </div>
    <div class="billing-details">
      <Panel
        v-if="externalPlan"
        :variant="[
          'transparent',
          'stroked',
          'stroked-input-borders',
          'rounded',
        ]"
        class="billings-details-wrapper"
      >
        <TextBlock
          tag="div"
          :size="1.25"
          class="secondary-font"
          variant="bold"
          >{{ $$t('Plan details') }}</TextBlock
        >
        <div class="plan-name mt-6">
          <TextBlock :size="0.825" variant="bold" class="h-mr-2">{{
            $t('_web_ext_sub', 'External subscription')
          }}</TextBlock>
        </div>
        <div v-if="externalPlan.expires" class="mt-5">
          <!-- <TextBlock :size="0.825" class="h-mr-2">{{
            $t('_web_sub_valid', 'Subscription valid until')
          }}</TextBlock> -->

          <TextBlock :size="0.825" variant="bold" class="mx-2">
            <TextBlock :size="0.825" class="h-mr-2">{{
              $t(
                '_web_sub_provider-details',
                'Please contact your provider for more details'
              )
            }}</TextBlock>

            <!-- {{ externalPlan.expires | localDate($times.DATE_FULL)
            }} -->
            {{
              // eslint-disable-next-line vue/no-parsing-error
              showCanceled ? ' ' + $t('_web_sub_canceled', '(canceled)') : ''
            }}</TextBlock
          >
        </div>
      </Panel>
      <Panel
        v-if="activeVoucherPlan"
        :variant="[
          'transparent',
          'stroked',
          'stroked-input-borders',
          'rounded',
        ]"
        class="billings-details-wrapper"
      >
        <TextBlock
          tag="div"
          :size="1.25"
          class="secondary-font"
          variant="bold"
          >{{ $$t('Plan details') }}</TextBlock
        >
        <div class="plan-name mt-6">
          <TextBlock
            v-if="activeVoucherPlan.title"
            :size="0.825"
            variant="bold"
            class="h-mr-2"
            >{{ activeVoucherPlan.title }}</TextBlock
          >
        </div>
        <div v-if="activeVoucherPlan.expires" class="mt-5">
          <TextBlock :size="0.825" class="h-mr-2">{{
            $t('_web_sub_valid', 'Subscription valid until')
          }}</TextBlock>
          :
          <TextBlock :size="0.825" variant="bold" class="mx-2">{{
            activeVoucherPlan.expires | localDate($times.DATE_FULL)
          }}</TextBlock>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import Panel from '@/components/atoms/Panel';
import { mapGetters, mapActions, mapState } from 'vuex';
import { translate } from '@/mixins/translate';
import TextBlock from '@/components/atoms/TextBlock.vue';
import Btn from '@/components/atoms/HeraFormBtn.vue';
import HeraDummyList from '@/components/common/HeraDummyList';
import { SSO_CLIENT_ID } from '@/api/config';

export default {
  name: 'CurrentPlanDetails',
  components: { Panel, TextBlock, Btn, HeraDummyList },
  mixins: [translate('_web_subscription_billing')],
  props: {
    handleLoading: { type: Function, default: () => {} },
  },
  data() {
    return {
      subCanceled: false,
      externalPlan: null,
      voucherSub: null,
      isSame: null,
    };
  },
  computed: {
    ...mapGetters(['subscriptions', 'currentRegion']),
    ...mapState('ph', ['phTempActivationCode']),
    ...mapState('my', { myAccountId: 'accountId' }),
    currentPlan() {
      return this.subscriptions.find(s => s.isActive);
    },
    ActiveExternalPlan() {
      return this.externalPlan &&
        this.externalPlan.status &&
        this.externalPlan.status !== 'inactive'
        ? true
        : false;
    },

    activeVoucherPlan() {
      return (
        this.voucherSub && this.voucherSub.find(s => s.status === 'active')
      );
    },

    showCanceled() {
      return new Date(this.externalPlan.expires) <= new Date().getTime() / 1000;
    },
  },
  created() {
    if (
      this.phTempActivationCode ||
      sessionStorage.getItem('activation_code')
    ) {
      this.handleLoading();
    }
    this.fetchUserSubscriptions().then(res => (this.subCanceled = res));
    this.fetchVoucherSubscriptions().then(
      res => (this.voucherSub = res.subscriptions)
    );
    this.fetchExternalSubscriptions().then(res => {
      if (res.subscriptions.length) {
        const activePlan = res.subscriptions.find(s => s.status === 'active');
        const inactivePlan = res.subscriptions.find(
          s => s.status === 'inactive'
        );
        this.externalPlan = activePlan || inactivePlan;
      } else {
        this.externalPlan = null;
      }

      this.isSame =
        res.subscriptions.find(s => s.status === 'active').activation_code ===
          this.phTempActivationCode ||
        res.subscriptions.find(s => s.status === 'active').activation_code ===
          sessionStorage.getItem('activation_code');
    });
  },
  mounted() {
    setTimeout(() => {
      this.fetchSubscriptions().then(() => {
        if (
          this.currentRegion &&
          this.currentRegion.id !== 'mena' &&
          (this.phTempActivationCode ||
            sessionStorage.getItem('activation_code'))
        ) {
          this.setPhTempActivationCode(null);
          sessionStorage.removeItem('activation_code');

          return this.errorNotify({
            message: this.$t(
              '_web_subs_ext_wrong_region',
              'Sorry, PLAYERA MAX is not available in your region yet. Please, follow our updates'
            ),
            inModal: true,
            yesText: this.$t('_web_popup_confim-ok', 'OK'),
          });
        } else {
          setTimeout(() => {
            this.startExternalSubProcess();
          }, 1000);
        }
      });
    }, 1500);
  },
  methods: {
    ...mapActions('ph', ['setPhTempActivationCode']),
    ...mapActions([
      'fetchSubscriptions',
      'subscribeToPlan',
      'fetchUserSubscriptions',
      'unsubscribe',
      'openModal',
      'closeModal',
      'unsubscribeFeedback',
      'fetchExternalSubscriptions',
      'subscribeToExternalPlan',
      'openModal',
      'closeModal',
      'errorNotify',
      'ssoSwitch',
      'fetchVoucherSubscriptions',
      // 'successNotify',
    ]),
    openConfirmCancel() {
      return this.openModal({
        name: 'CancelSubscription',
        props: {
          title: this.$t(
            '_web_cancel_subscription',
            'Please confirm you want to cancel your Playhera Max subscription.'
          ),
          cancelButtonText: this.$t('_web_post_delete-cancel', 'Cancel'),
          confirmButtonText: this.$t('_web_confirm', 'Confirm'),
          isLoading: this.isDeleting,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.unsubscribeFromPlan,
        },
        options: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    startExternalSubProcess() {
      if (
        this.phTempActivationCode ||
        sessionStorage.getItem('activation_code')
      ) {
        if (!this.currentPlan && !this.ActiveExternalPlan) {
          if (this.isSame) {
            // this.successNotify(
            //   this.$t(
            //     '_web_subs_ext_success',
            //     'Your external subscription has been successfully prolonged'
            //   )
            // );
            this.handleLoading();
            this.handleSubscribeToExternalPlan();
          } else if (
            !this.currentPlan &&
            !this.ActiveExternalPlan &&
            !this.isSame &&
            !this.voucherSub
          ) {
            this.handleLoading();
            return this.openModal({
              name: 'HeraConfirm',
              props: {
                title: this.$t(
                  '_web_subs_external',
                  'Do you want to activate your external PH MAX subscription?'
                ),
                cancelButtonText: this.$t('_web_post_delete-cancel', 'Cancel'),
                confirmButtonText: this.$t('_web_popup_confim-ok', 'OK'),
              },
              events: {
                cancel: this.closeModal,
                confirm: this.handleSubscribeToExternalPlan,
              },
              options: {
                clickOnBackdrop: false,
                escKey: false,
              },
            });
          } else {
            this.setPhTempActivationCode(null);
            sessionStorage.removeItem('activation_code');
            this.handleLoading();
            return (
              !this.isSame &&
              this.errorNotify({
                message: this.$t(
                  '_web_subs_ext_already-have',
                  'You have an active PLAYHERA MAX subscription. This external one cannot be activated.'
                ),
                inModal: true,
                yesText: this.$t('_web_popup_confim-ok', 'OK'),
              })
            );
          }
        }
      }
    },

    handleSubscribeToExternalPlan() {
      this.closeModal();
      this.handleLoading();
      this.subscribeToExternalPlan({
        code: this.phTempActivationCode
          ? this.phTempActivationCode
          : sessionStorage.getItem('activation_code'),
      })
        .then(() =>
          this.ssoSwitch({
            accountId: SSO_CLIENT_ID,
            accountType: 'thirdparty',
          })
            .then(resp => {
              window.location.href = resp.redirect;
            })
            .catch(err => {
              this.errorNotify(err);
            })
        )
        .then(
          window.dataLayer.push({
            event: 'PH Max subscription external',
            from: this.$route.path,
            userId: this.myAccountId,
            accountId: SSO_CLIENT_ID,
            activationCode: this.phTempActivationCode
              ? this.phTempActivationCode
              : sessionStorage.getItem('activation_code'),
          })
        )
        .catch(error => {
          this.handleLoading(),
            this.errorNotify({
              message:
                error && error.status === 404
                  ? this.$t(
                      '_web_ext_sub_not_exist',
                      'Sorry, the current subscription does not exist'
                    )
                  : error &&
                    error.message ===
                      'Sorry, this subscription was already activated previously'
                  ? this.$t(
                      '_web_ext_sub_already_activated',
                      'Sorry, this subscription was already activated previously'
                    )
                  : error &&
                    error.message ===
                      'Sorry, the current subscription is expired'
                  ? this.$t(
                      '_web_ext_sub_expired',
                      'Sorry, the current subscription is expired'
                    )
                  : error &&
                    error.message === 'user alredy has an active subscription'
                  ? this.$t(
                      '_web_ext_sub_active',
                      'user alredy has an active subscription'
                    )
                  : this.$t(
                      '_web_ext_sub_not_exist',
                      'Sorry, the current subscription does not exist'
                    ),

              inModal: true,
              yesText: this.$t('_web_popup_confim-ok', 'OK'),
            });
        })
        .finally(
          () => this.setPhTempActivationCode(null),
          sessionStorage.removeItem('activation_code')
        );
    },

    unsubscribeFromPlan() {
      this.unsubscribe({ subscriptionId: this.currentPlan.id })
        .then(() => {
          this.fetchSubscriptions();
        })
        .then(
          window.dataLayer.push({
            event: 'PH Max subscription cancellation',
            from: this.$route.path,
            userId: this.myAccountId,
            planId: this.currentPlan.id,
            planDuration: this.currentPlan.period,
            planPrice: this.currentPlan.amount,
            planCurrency: this.currentPlan.currency,
          })
        )
        .then(
          this.openModal({
            name: 'FormReport',
            props: {
              title: this.$t(
                '_web_subscription_canceled',
                'Your Playhera Max subscription has been cancelled. You are able to use the service until : {date}.',
                {
                  date: new Date(
                    this.subCanceled.subscriptions[0].expires * 1000
                  ).toDateString(),
                }
              ),
              subtitle: this.$t(
                '_web_reason-confirm',
                'Can you tell us the cancelation reason?'
              ),
              reasons: [
                this.$t('_web_subscription_reason_price', 'Price'),
                this.$t(
                  '_web_subscription_reason_games-library',
                  'Games Library'
                ),
                this.$t(
                  '_web_subscription_reason_gameplay-quality',
                  'Gameplay Quality'
                ),
                this.$t('_web_subscription_reason_experience', 'Experience'),
                this.$t(
                  '_web_subscription_reason_user-interface',
                  'User interface'
                ),
                this.$t('_web_subscription_reason_other', 'Other'),
              ],
              cancelButtonText: this.$t('_web_post_delete-cancel', 'Cancel'),
              confirmButtonText: this.$t('_web_confirm', 'Confirm'),
              subscriptionID: this.subCanceled.subscriptions[0].planId,
            },
            events: {
              cancel: this.closeModal,
              confirm: this.closeModal,
            },
            options: {
              clickOnBackdrop: false,
              escKey: false,
            },
          })
        )
        .catch(error => this.$store.dispatch('errorNotify', error));
    },
  },
};
</script>

<style lang="scss">
.billing-details {
  margin-bottom: 20px;
  .container-dummy {
    min-height: 250px !important;
  }
  @media only screen and (max-width: 505px) {
    .billings-details-wrapper {
      text-align: center !important;
    }
    .unsubscribe-billing {
      display: flex;
      justify-content: center !important;
    }
  }
}

.btn-details-unsubscribe:hover {
  background-color: $hr-bg-error !important;
}
</style>
