<template>
  <Panel class="tournament-info_organizer pb-0 pt-0" variant="transparent">
    <Subheader
      class="mb-4 text-uppercase font-weight-normal"
      :text="$t('_web_organiser', 'ORGANISER')"
      :text-block-props="{ size: 0.875, lineHeight: 1.5 }"
      variant="hr-secondary"
    />
    <div class="organizer d-flex">
      <img
        v-if="currentTournament.event_organizer_logo"
        :src="currentTournament.event_organizer_logo"
        :alt="currentTournament.event_organizer_name"
        class="organizer-logo"
      />
      <a
        v-if="currentTournament.event_organizer_name"
        class="links"
        :href="isAuthorized ? organizerProfileUrl : '#'"
      >
        <TextBlock
          :text="currentTournament.event_organizer_name"
          class="text-capitalize text-block"
          line-height="1.4"
        />
        <TextBlock
          :text="'@' + currentTournament.event_organizer_name"
          class="text-block"
          variant="muted"
          line-height="1.4"
        />
      </a>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import TextBlock from '@/components/atoms/TextBlock';
import { mapGetters } from 'vuex';
import Subheader from '@/components/atoms/Subheader';

export default {
  name: 'TournamentInfoOrganizer',
  components: {
    Panel,
    TextBlock,
    Subheader,
  },
  computed: {
    ...mapGetters(['currentTournament', 'isAuthorized']),
    organizerProfileUrl() {
      if (!this.currentTournament.owner) {
        return null;
      }
      return `/organizations/${this.currentTournament.owner}`;
    },
    dummy() {
      return !this.currentTournament.event_organizer_logo &&
        !this.currentTournament.event_organizer_name
        ? this.$t('_web_tournament-info_no-powered', 'No Organiser')
        : null;
    },
  },
};
</script>
<style lang="scss">
.organizer {
  align-items: center;
  justify-content: start;
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
}
.organizer-logo {
  width: 3rem;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  @include auto-rtl('margin-right', $hr-spacing-03);
}
.text-block {
  display: block;
}
</style>
