<template>
  <PopoverList
    :closest="popoverClosest"
    :position="popoverPosition"
    :items="menuItems"
    :regard="regard"
    :float="[floatY]"
    :width="200"
    :height="[10, 240]"
    auto-close
    :scroll-is-disable="scrollIsDisable"
    transfer-to-body
    :dummy-props="{ emptyListIconSize: 100 }"
    @scrollToBottom="fetch"
    @toggle="checkClosing"
  >
    <!--todo - TH-7100 READY BACKEND-->
    <template slot-scope="{ item, index }">
      <span
        :class="{ active: index === activeIndex }"
        @mousedown.prevent.stop="onPut(item)"
        ><Avatar
          v-if="item.avatar ? item.avatar : getDefaultAvatars(item.type)"
          :img-url="item.avatar ? item.avatar : getDefaultAvatars(item.type)"
          :size="30"
          style="margin:5px;"
        />
        {{ (item.type === 'profile' ? item.login : item.name) || item.text }}
      </span>
    </template>
  </PopoverList>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@/utils/lodashUtils';
import PopoverList from '@/components/popover/List';
import Avatar from '@/components/images/Avatar.vue';
import images from '@/constants/images';
import { REGEX_URL } from '@/constants/regexps';
const DEBOUNCE_TIME_INPUT = 500;
const ACTIVATOR_SYMBOL_WIDTH = 12;
const TAGS_ACTION = 'getTagsList';
const MENTIONS_ACTION = 'getMentionsList';

export default {
  name: 'EditablePutter',
  components: {
    PopoverList,
    Avatar,
  },
  model: {
    event: 'activated',
  },
  props: {
    input: {
      type: String,
      default: '',
    },
    tagsActivators: {
      type: Array,
      default: null,
    },
    mentionsActivators: {
      type: Array,
      default: null,
    },
    linksActivator: {
      type: RegExp,
      default: () => REGEX_URL,
    },
    enableLinkDetection: {
      type: Boolean,
      default: false,
    },
    inputRegExp: {
      type: RegExp,
      default: () => /^\w+$/,
    },
    minLenActivator: {
      type: Number,
      default: 0,
    },
    regard: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      detectInput: '',
      fetchDebounce: debounce(this.fetch, DEBOUNCE_TIME_INPUT),
      popoverClosest: null,
      floatY: 'top',
      popoverPosition: null,
      menuItems: null,
      currentRange: null,
      scrollIsDisable: false,
      action: '',
      isMention: false,
      isTag: false,
      isLink: false,
      pageToken: undefined,
      activeIndex: -1,
    };
  },
  computed: {
    ...mapGetters('adminMention', ['corelation']),
    activatorRegExp() {
      const allActivators = [
        ...(this.tagsActivators || []),
        ...(this.mentionsActivators || []),
        ...(this.enableLinkDetection &&
        this.linksActivator &&
        this.linksActivator.source
          ? [this.linksActivator.source]
          : []),
      ];
      return new RegExp(`(${allActivators.join('|')})`);
    },
  },
  watch: {
    input(v) {
      v = v.trim();
      if (v) {
        this.detect();
      } else {
        this.togglePopover();
        this.fetchDebounce.cancel();
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      const editable = this.$el.closest('.contenteditable');
      if (editable) {
        this.popoverClosest = editable.parentElement;
        this.floatY =
          editable.getBoundingClientRect().top > window.innerHeight / 2
            ? 'bottom'
            : 'top';
      }
    });
  },
  methods: {
    ...mapActions(['errorNotify']),
    getDefaultAvatars(type) {
      switch (type) {
        case 'user':
        case 'profile':
          return images.defaultUser;
        case 'clan':
          return images.defaultClan;
        case 'federation':
        case 'organization':
          return images.defaultOrganization;
        case 'platform':
        case 'genre':
        case 'game':
          return images.defaultGame;
        case 'espl':
        case 'league':
          return images.defaultESPL;
        case 'channel':
          return images.defaultChannels;
        case 'group':
          return images.defaultGroup;
        case 'place':
          return images.defaultPlace;
        case 'admin':
          return images.defaultAdmin;
      }
      return '';
    },
    detect() {
      const rangeData = this.getRange();

      if (!rangeData) {
        this.togglePopover();
        return;
      }

      const { range = null, input = '', activator } = rangeData;
      this.isTag = this.checkTag(activator);
      this.isMention = this.checkMention(activator);
      if (this.isLink) {
        this.$emit('linkDetected', activator);
        return;
      }
      if (this.isTag || this.isMention) {
        this.currentRange = range;

        // set bound for popover
        const rect = range.getBoundingClientRect();
        rect.width = ACTIVATOR_SYMBOL_WIDTH;

        // open popover
        this.togglePopover(rect);
        this.detectInput = input;

        this.action = this.isTag
          ? TAGS_ACTION
          : this.isMention
          ? MENTIONS_ACTION
          : '';

        this.fetchDebounce();
      }
    },
    checkTag(activator) {
      return this.tagsActivators && this.tagsActivators.includes(activator);
    },
    checkMention(activator) {
      return (
        this.mentionsActivators && this.mentionsActivators.includes(activator)
      );
    },
    checkLink(activator) {
      return (
        this.enableLinkDetection &&
        this.linksActivator &&
        new RegExp(this.linksActivator.source).test(activator)
      );
    },
    getRange() {
      // get selection

      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) {
        return null;
      }

      // getRange
      const selectionRange = selection.getRangeAt(0);
      if (!selectionRange) {
        return null;
      }

      // create range for activator
      const range = selectionRange.cloneRange();
      range.collapse(true);

      // unwrap links
      if (range.endContainer.parentElement.nodeName === 'A') {
        this.unwrapRange(range);
        return null;
      }

      // if it is already `Node` and not `Text`
      if (range.endContainer.nodeType !== Node.TEXT_NODE) {
        return null;
      }

      const text = range.endContainer.textContent.slice(0, range.endOffset);

      if (!text) {
        return null;
      }

      // ping activator
      const match = text
        .split(/\s+/)
        .slice(-1)[0]
        .match(this.activatorRegExp);

      if (!match || match.index !== 0) {
        return null;
      }

      // get input in current range and check validation
      this.isLink = this.checkLink(match.input);
      const input = this.isLink ? '' : match.input.slice(1);
      const activator = this.isLink ? match.input : match.input.slice(0, 1);
      if (
        (input.length < this.minLenActivator ||
          !this.inputRegExp.test(input)) &&
        this.minLenActivator + input.length !== 0
      ) {
        return null;
      }
      // set range for popover
      range.setStart(
        range.endContainer,
        Math.max(0, text.lastIndexOf(match.input) - 1)
      );
      if (!range) {
        return null;
      }

      return { range, input, activator };
    },
    unwrapRange(range) {
      const textNode = range.endContainer;
      const linkNode = textNode.parentNode;
      const parentNode = linkNode.parentNode;

      if (parentNode) {
        parentNode.insertBefore(textNode, linkNode);
        parentNode.removeChild(linkNode);
      }
    },
    togglePopover(position) {
      this.$emit('activated', !!position);
      this.menuItems = null;
      this.pageToken = undefined;
      this.activeIndex = -1;
      this.popoverPosition = position || null;
    },
    fetch() {
      this.scrollIsDisable = true;

      if (this.pageToken === null || !this.action) {
        return;
      }

      this.$store
        .dispatch(this.action, {
          query: this.detectInput,
          pageToken: this.pageToken,
        })
        .then(res => {
          this.menuItems =
            res && res.items
              ? [
                  this.corelation && {
                    login: 'admin',
                    display_name: 'Admin',
                    avatar: require('@/assets/images/defaults/admin.svg'),
                    name: 'Admin',
                    type: 'admin',
                    vanity_id: 'Admin',
                  },
                  ...(this.menuItems || []),
                  ...res.items,
                ]
              : [];
          this.pageToken = res.next_page_token;
          if (res.next_page_token) this.scrollIsDisable = false;
        })
        .catch(err => {
          this.pageToken = null;
          this.errorNotify(err);
        });
    },
    onPut(item) {
      const node = this.isTag
        ? this.createTagNode(item)
        : this.isMention
        ? this.createMentionNode(item)
        : null;

      if (node) {
        this.currentRange.deleteContents();
        this.$emit('put', node);
      }
    },
    createTagNode(item) {
      const node = document.createElement('a');
      node.href = `hashtag://${item.text.trim()}`;
      node.innerText = `${String.fromCharCode(0x200e)}#${item.text}`;

      return node;
    },
    createMentionNode(item) {
      const node = document.createElement('a');
      item.type !== 'admin'
        ? (node.href = `${item.type}://${item.login || item.id}`)
        : (node.href = `${item.type}://${item.type}`);

      node.innerText =
        item.type !== 'admin'
          ? `${String.fromCharCode(0x200e)}@${item.name ||
              item.login ||
              item.id}`
          : `${String.fromCharCode(0x200e)}@${item.type}`;

      return node;
    },
    checkClosing(open) {
      if (!open && !this.input.trim().endsWith('>') && this.isTag) {
        this.tagsActivators.forEach(char => {
          const activatorIdx = this.input.lastIndexOf(char);
          if (activatorIdx >= 0) {
            this.onPut({ text: this.input.substring(activatorIdx + 1).trim() });
          }
        });
      }
    },
    updateActivePopoverItem(step) {
      if (!this.menuItems) {
        return;
      }

      let activeIndex = this.activeIndex + step;
      const itemsCount = this.menuItems.length;

      if (activeIndex >= itemsCount) {
        activeIndex = 0; // first item
      } else if (activeIndex < 0) {
        activeIndex = itemsCount - 1; // last item
      }

      this.activeIndex = activeIndex;
      this.viewActiveItem();
    },
    selectActivePopoverItem() {
      const item = this.menuItems && this.menuItems[this.activeIndex];

      if (!item) {
        return;
      }

      this.onPut(item);
      this.togglePopover();
    },
    viewActiveItem() {
      const parent = this.$el.querySelector('.custom-scrollbar');
      const activeItem = parent.querySelector('li.active');

      if (!activeItem) {
        return;
      }

      const parentRect = parent.getBoundingClientRect();
      const activeItemRect = activeItem.getBoundingClientRect();

      const deltaBottom = activeItemRect.bottom - parentRect.bottom;
      if (deltaBottom > 0) {
        parent.scrollTop += deltaBottom;
        return;
      }

      const deltaTop = parentRect.top - activeItemRect.top;
      if (deltaTop > 0) {
        parent.scrollTop -= deltaTop;
      }
    },
  },
};
</script>
