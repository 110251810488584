<template>
  <div
    v-observe-visibility="{
      callback: visibilityHandler,
      intersection: {
        threshold: 0.4,
      },
    }"
    class="sc-post-attach-highlights-video d-flex position-relative"
    @click.prevent="clickHandler"
  >
    <div
      v-if="!isMetaLoaded"
      class="attach__loader-wrapper d-flex align-items-center"
    >
      <Loader :size="60" />
    </div>
    <!-- ? thumbnailFallback
          : require('@/assets/images/background/320.jpg') -->
    <video
      ref="video"
      preload="metada"
      class="sc-post-attach-highlights-video__video "
      v-bind="{ src }"
      :poster="
        thumbnail && thumbnail.image ? thumbnail.image : thumbnailFallback
      "
      muted
      playsinline
    ></video>
    <span class="sc-post-attach-highlights-video__duration">{{
      duration
    }}</span>
  </div>
</template>

<script>
import { Duration } from 'luxon';
import { mapMutations } from 'vuex';
import { get } from '@/utils/lodashUtils';
import { emptyArray } from '@/utils/factories';
import Loader from '@/components/common/Loader';

export default {
  components: { Loader },
  props: {
    src: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Object,
      default: () => {},
    },
    mediaGroup: {
      type: Array,
      default: emptyArray,
    },
  },

  data() {
    return {
      duration: '',
      isMetaLoaded: false,
      playAfterMetaLoading: false,
      thumbnailFallback: '',
    };
  },
  created() {
    this.getThumb();
  },
  mounted() {
    // console.log(this.src);
    this.$refs.video.addEventListener('loadedmetadata', () => {
      this.isMetaLoaded = true;
      const millisecsInSecond = 1000;
      const videoDuration = get(this, '$refs.video.duration', 0);
      this.duration = Duration.fromMillis(
        videoDuration * millisecsInSecond
      ).toFormat('hh:mm:ss');

      if (this.playAfterMetaLoading) {
        this.play();
      }
    });

    this.$refs.video.addEventListener('ended', myHandler, false);
    function myHandler() {
      // What you want to do after the event
      this.play();
    }
  },

  methods: {
    ...mapMutations('mediaplayer', ['openPlayer']),

    visibilityHandler(visible) {
      if (visible) {
        this.play();
      } else {
        this.stop();
      }
    },

    play() {
      if (!this.isMetaLoaded) {
        return (this.playAfterMetaLoading = true);
      }
      if (this.$refs.video) {
        /*  this.$refs.video.play(); */
      }
    },

    stop() {
      this.$refs.video.pause();
      this.playAfterMetaLoading = false;
    },
    async getThumb() {
      const response = await fetch(
        this.src.replace(/original\.(mp4|mov|3gp)/, 'thumbnail.jpg')
      );
      if (response.status === 200) {
        return (this.thumbnailFallback = this.src.replace(
          /original\.(mp4|mov|3gp)/,
          'thumbnail.jpg'
        ));
      } else {
        return;
      }
    },

    clickHandler() {
      /*
        this.stop();
        this.openPlayer({
          fullScreen: true,
          type: 'video',
          url: this.src,
          currentTime: this.$refs.video.currentTime,
          onHide: time => {
            this.$refs.video.currentTime = time;
            this.$refs.video.play();
          },
          mediaGroup: this.mediaGroup,
        }); */
    },
  },
};
</script>

<style lang="scss">
.sc-post-attach-highlights-video {
  cursor: pointer;

  &__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    height: 100%;
    /*  max-height: 258px; */
    border-radius: $ph-small-radius;
    background-color: $ph-card;
  }

  &__duration {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    font-size: $ph-caption-bold-font-size;
    line-height: $ph-caption-bold-line-height;
    padding: 0 $ph-tiny-space;
    color: $ph-secondary-text;
    background-color: $ph-card;
    border-bottom-right-radius: $ph-small-radius;
  }
}
</style>
<story
  options="{
      panelPosition: 'right'
    }"
  group="Common|Atoms"
  name="VideoPreview"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
      src: {
        default: text('src', 'https://interactive-examples.mdn.mozilla.net/media/examples/flower.mp4')
      },
    }"
>
    <div class="row">
      <div class="col-4 offset-4">
        <VideoPreview v-bind="{src}"/>
      </div>
    </div>
  </story>
