<template>
  <img v-bind="{ src: resizedImageUrl }" @click.prevent="clickHandler" />
</template>

<script>
import { mapMutations } from 'vuex';
import { emptyArray } from '@/utils/factories';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    mediaGroup: {
      type: Array,
      default: emptyArray,
    },
    thumbnailSize: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    resizedImageUrl() {
      return this.thumbnailSize
        ? this.src.replace('.original.', `.${this.thumbnailSize}.`)
        : this.src;
    },
  },
  methods: {
    ...mapMutations('mediaplayer', ['openPlayer']),

    clickHandler() {
      this.openPlayer({
        fullScreen: true,
        type: 'image',
        url: this.src,
        mediaGroup: this.mediaGroup,
      });
    },
  },
};
</script>
