import { upperFirst, isUndefined } from '@/utils/lodashUtils';

export const generateMutations = (...list) => {
  return list.reduce((acc, key) => {
    acc[`set${upperFirst(key)}`] = (state, payload) => (state[key] = payload);
    return acc;
  }, {});
};

export const generateToggleMutations = (...list) => {
  return list.reduce((acc, key) => {
    acc[`toggle${upperFirst(key)}`] = (state, payload) =>
      (state[key] = isUndefined(payload) ? !state[key] : !!payload);
    return acc;
  }, {});
};

export const generateGetters = (...list) => {
  return list.reduce((acc, key) => {
    acc[key] = state => state[key];
    return acc;
  }, {});
};
