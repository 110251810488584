<template>
  <div
    class="d-flex flex-column position-relative input-multilined contenteditable"
    :class="[
      `input-multilined--${variant}`,
      { 'input-multilined--invalid': error },
    ]"
  >
    <WrapperFormControl
      v-bind="{
        tooltip,
        required,
        error,
        label,
        hasCounter,
        maxLengthCounter: maxLength,
        currentCounter: value || '',
        hideLabel,
      }"
    >
      <Editable
        slot="control"
        ref="textarea"
        class="custom-scrollbar input-form textarea position-relative"
        v-bind="{
          maxLength,
          id,
          value,
          rows,
          placeholder,
          disabled,
          readOnly,
          disableAutofocus: disableFocus,
          mentionsActivators,
        }"
        :background="background"
        v-on="handlers"
      >
      </Editable>
    </WrapperFormControl>
    <Putter
      ref="putter"
      v-model="putterIsActive"
      v-bind="{
        input: value,
        tagsActivators,
        mentionsActivators,
        inputRegExp: /^\w+$/,
      }"
      :enable-link-detection="detectLinks"
      v-on="{ linkDetected: $event => $emit('linkDetected', $event), put }"
    />
  </div>
</template>

<script>
import { uniqueId } from '@/utils/lodashUtils';
import { prop, propValidator } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
import Putter from '@/components/form/contentEditable/Putter';
import Editable from '@/components/form/contentEditable/Editable';
const VARIANTS = ['default', 'input-like'];

export default {
  components: {
    WrapperFormControl,
    Putter,
    Editable,
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    error: prop(String),
    label: prop(String),
    value: prop(String),
    maxLength: prop(Number, null),
    variant: propValidator(String, 'default', val => VARIANTS.includes(val)),
    fixed: prop(Boolean),
    rows: prop(Number, 1),
    placeholder: prop(String),
    required: prop(Boolean),
    hasCounter: prop(Boolean),
    disabled: prop(Boolean),
    readOnly: prop(Boolean),
    hideLabel: prop(Boolean),
    detectLinks: prop(Boolean),
    detectTags: prop(Boolean),
    detectMentions: prop(Boolean),
    tooltip: prop(String),
    toggleActive: prop(Boolean),
    background: prop(String),
    disableFocus: { type: Boolean, default: true },
  },

  data() {
    return {
      id: uniqueId('textarea_'),
      computedStyles: null,
      minTextareaHeight: 0,
      putterIsActive: false,
      textareaRef: null,
    };
  },

  computed: {
    labelVariant() {
      return this.error ? 'error' : 'secondary';
    },
    handlers() {
      return {
        ...this.$listeners,
        input: this.inputHandler,
        change: this.changeHandler,
      };
    },
    tagsActivators() {
      return this.detectTags ? ['#', '＃'] : null;
    },
    mentionsActivators() {
      return this.detectMentions ? ['@', '＠'] : null;
    },
  },

  watch: {
    value(newVal) {
      if (newVal !== this.$refs.textarea.value) {
        this.$nextTick(this.setHeight);
      }
    },
    rows() {
      this.$nextTick(() => {
        this.calcMinHeight();
        this.setHeight();
      });
    },
  },

  mounted() {
    this.textareaRef = this.$refs.textarea.$refs.editable;
    this.computedStyles = window.getComputedStyle(this.textareaRef);
    this.calcMinHeight();
    this.setHeight();
  },

  methods: {
    setHeight() {
      if (this.fixed) {
        return;
      }

      this.textareaRef.style.height = '0';

      let newTextareaHeight =
        this.textareaRef.scrollHeight +
        parseInt(this.computedStyles.borderTopWidth) +
        parseInt(this.computedStyles.borderBottomWidth);

      if (this.rows > 1) {
        newTextareaHeight = Math.max(newTextareaHeight, this.minTextareaHeight);
      } else {
        newTextareaHeight = Math.min(18, this.minTextareaHeight);
      }
      this.textareaRef.style.height = `${newTextareaHeight}px`;
    },

    inputHandler(e) {
      this.$emit('input', e);
      this.setHeight();
    },

    changeHandler(e) {
      this.$emit('change', e);
    },

    calcMinHeight() {
      const oldHeight = this.textareaRef.style.height;
      this.textareaRef.style.height = null;
      if (this.$route.name !== 'post' || this.$route.name !== 'SinglePost') {
        this.minTextareaHeight =
          parseInt(this.computedStyles.height) * this.rows;
      }
      this.textareaRef.style.height = oldHeight;
    },

    blur() {
      this.$refs.textarea.$refs.editable.blur();
    },
    focus() {
      this.$refs.textarea.$refs.editable.focus();
    },
    put(node) {
      this.$refs.textarea.putNode(node, 1);
    },
  },
};
</script>

<style lang="scss">
.input-multilined {
  &.contenteditable {
    overflow: visible !important;
  }
  .textarea {
    // resize: none;
    // color: $ph-primary-text;
    transition: border-color 0.3s;
    min-height: unset !important;
    max-height: unset !important;
    overflow: hidden;
    line-height: 1.125rem;
  }

  // &--default {}

  // &--input-like {}

  &--invalid .textarea {
    color: $ph-error;
    border-color: $ph-error;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Molecules/Form"
  name="TextInputMultiline"
  knobs="{
    error: {
      default: text('error', '')
    },
    label: {
      default: text('label', '')
    },
    value: {
      default: text('value', '')
    },
    maxLength: {
      default: number('maxLength', 100)
    },
    variant: {
      default: select('variant', ['default', 'input-like'])
    },
    fixed: {
      default: boolean('fixed', false)
    },
    rows: {
      default: number('rows', 1)
    },
    placeholder: {
      default: text('placeholder', '')
    },
    required: {
      default: boolean('required', false)
    },
    hasCounter: {
      default: boolean('required', false)
    },
  }"
>
  <TextInputMultiline
    v-bind="{ error, label, value, maxLength, variant, fixed, rows, placeholder, required, hasCounter }"
    @input="val => action('input', val)"/>
</story>
