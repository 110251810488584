<script>
import * as signalR from '@aspnet/signalr';
import { mapMutations, mapActions } from 'vuex';

const HUB_NAME = 'notification';
const RECONNECTION_TIMEOUT = 5000; //ms
const MAX_INIT_CONNECTION_TRY_COUNT = 2;
const MAX_START_CONNECTION_TRY_COUNT = 10;

let initConnectoinTryCount = 0;

export default {
  name: 'SignalRHub',

  data() {
    return {
      hub: null,
    };
  },

  created() {
    console.log('HUB component created: before initializing connection');
    this.initConnection();
    console.log('HUB component created: before fetching counters');
    this.fetchCounters();
  },

  beforeDestroy() {
    console.log('HUB component: hub will be stopped');
    if (this.hub) {
      this.hub.stop();
      this.hub = null;
      console.log('HUB component hub stopped');
    }
  },

  methods: {
    ...mapMutations(['hubNext', 'addSubscriber', 'setIsHubReloadRequired']),
    ...mapActions([
      'systemNext',
      'addSystemSubscriber',
      'initHubConnection',
      'counterSubscriber',
      'fetchCounters',
      'errorNotify',
    ]),

    setConnectionInfo(payload) {
      let options = {
        accessTokenFactory: () => payload.info.access_key,
      };
      console.log('setting HUB connection info:', payload);
      return new signalR.HubConnectionBuilder()
        .withUrl(payload.info.endpoint, options)
        .configureLogging(signalR.LogLevel.Information)
        .build();
    },

    // start hub signalR flow
    startConnection() {
      console.log('HUB component: start connection');
      return new Promise(resolve => {
        let tryCount = 0;
        const start = () => {
          tryCount++;

          console.log('HUB component: try # ', tryCount);
          if (tryCount > MAX_START_CONNECTION_TRY_COUNT) {
            console.log(
              'HUB component: max connection retry reached, reinitialize now'
            );
            return this.initConnection();
          }
          if (this.hub) {
            console.log('HUB component: start WS');
            this.hub
              .start()
              .then(resolve)
              .catch(() => setTimeout(start, RECONNECTION_TIMEOUT));
          }
        };
        start();
      });
    },

    initConnection() {
      console.log('HUB component: start initialization');
      initConnectoinTryCount++;
      if (initConnectoinTryCount > MAX_INIT_CONNECTION_TRY_COUNT) {
        console.log('HUB component: initialization max retry reached');
        return this.setIsHubReloadRequired(true);
      }

      this.initHubConnection(HUB_NAME)
        .then(payload => this.setConnectionInfo(payload))
        .then(hub => {
          console.log('HUB component: hub created');
          this.hub = hub;
          return this.startConnection();
        })
        .then(() => {
          console.log('HUB component: add subscribers');
          this.addSystemSubscriber(this.counterSubscriber);

          this.hub.on('notify', data => {
            console.log('HUB component: has new notify', data);
            this.hubNext({ type: 'notifications', data });
          });
          this.hub.on('message', data => {
            console.log('HUB component: has new messages', data);
            this.hubNext({ type: 'messages', data });
          });
          this.hub.on('system', data => {
            console.log('HUB component: has new system message', data);
            this.hubNext({ type: 'system', data });
          });

          this.hub.onclose(() => {
            console.log('HUB component: hub closed -> reconnect');
            if (this.hub) {
              this.startConnection();
            }
          });

          initConnectoinTryCount = 0;
        })
        .catch(this.errorNotify);
    },
  },

  render(h) {
    return h;
  },
};
</script>
