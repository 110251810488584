<template>
  <div class="d-flex">
    <Panel :variant="['rounded-small', 'default']" class="w-50 mr-5 mb-5">
      <div class="stage-summary__title">
        <Subheader class="mb-5">
          {{ $$t('Matches: ', 'stage-block-matches') }}
        </Subheader>
        <h3 v-if="!(isSingleStage && isSingleBlock)">{{ summary.title }}</h3>
        <div class="d-flex flex-column">
          <GaugeChart
            v-bind="{
              data: stageChartData,
              options: stageChartOptions,
            }"
            class="h-100"
          />
          <div class="d-flex flex-column justify-content-center">
            <template v-if="hasOneGroup">
              <div
                v-for="setting in blockSummaryData(
                  Object.keys(summary.blocks)[0]
                )"
                :key="setting.key"
                class="stage-summary__type d-flex align-items-center px-3 mt-4"
                :class="[`stage-info__${setting.key}`]"
              >
                <!-- <Icon :name="setting.icon" class="h-mr-3" /> -->
                <div>
                  {{ setting.label }}
                </div>
                <div
                  v-if="
                    $lodash.isFinite(setting.value) &&
                      $lodash.isFinite(setting.limit)
                  "
                  class="h-ml-auto"
                >
                  {{ setting.value }}/{{ setting.limit }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="summary.blocks && !hasOneGroup"
          class="row no-gutters flex-wrap mt-4"
        >
          <div v-for="(block, id) in summary.blocks" :key="id" class="col-6">
            <div
              class="block-summary__type block-info__title d-flex align-items-center px-3"
            >
              <h4>
                {{ getBlockTitle(id) }}
              </h4>
            </div>
            <div class="row">
              <div class="col-6">
                <div
                  v-for="(setting, index) in blockSummaryData(id)"
                  :key="setting.key"
                  class="stage-summary__type d-flex align-items-center px-3"
                  :class="[`stage-info__${setting.key}`, { 'mt-4': index }]"
                >
                  <!-- <Icon :name="setting.icon" class="h-mr-3" /> -->
                  <div>
                    {{ setting.label }}
                  </div>
                  <div
                    v-if="
                      $lodash.isFinite(setting.value) &&
                        $lodash.isFinite(setting.limit)
                    "
                    class="h-ml-auto"
                  >
                    {{ setting.value }}/{{ setting.limit }}
                  </div>
                </div>
              </div>
              <div class="col-5">
                <GaugeChart
                  v-bind="{
                    data: blockChartData(id),
                    options: blockChartOptions,
                  }"
                  class="h-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel :variant="['rounded-small', 'default']" class="w-50 mb-5">
      <Subheader class="mb-5">{{ $t('_web_cases', 'Cases') }}:</Subheader>
      <div
        v-for="(setting, index) in stageSummaryData"
        :key="setting.key"
        class="stage-summary__type d-flex align-items-center px-3"
        :class="[`stage-info__${setting.key}`, { 'mt-4': index }]"
      >
        <div>
          {{ setting.label }}
        </div>
        <div class="h-ml-auto">
          {{ setting.value }}
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { translate } from '@/mixins/translate';
import { propRequired } from '@/utils/factories';
import GaugeChart from '@/components/atoms/GaugeChart';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
const DEFAULT_CHART_SETTINGS = {
  roundedCorners: false,
};

export default {
  name: 'MatchesSummaryCard',
  components: {
    Subheader,
    Panel,
    GaugeChart,
  },
  mixins: [translate('_web_tournament_dashboard')],
  props: {
    summary: propRequired(Object),
  },
  computed: {
    ...mapGetters(['isSingleStage', 'isSingleBlock', 'getBlockByID']),
    hasOneGroup() {
      return Object.keys(this.summary.blocks).length === 1;
    },
    stageChartData() {
      return {
        labels: [this.$$t('Stage progress')],
        datasets: [
          {
            data: [this.stageCompletePercentage],
            backgroundColor: [this.$scss.colors.blueBg],
            borderWidth: 0,
            label: this.$$t('Stage progress'),
          },
        ],
      };
    },
    stageChartOptions() {
      const options = this.$lodash.cloneDeep(DEFAULT_CHART_SETTINGS);
      options.centerArea = {
        displayText: true,
        text: value => {
          return value + '%';
        },
        fontColor: this.$scss.colors.blueBg,
        fontSize: '2.25rem',
        fontFamily: 'Exo, Arial, sans-serif',
        padding: 4,
      };
      return options;
    },
    stageCompletePercentage() {
      return !this.allMatchesCount
        ? 0
        : Math.round(
            (100 * this.allCompletedMatchesCount) / this.allMatchesCount
          );
    },
    stageSummaryData() {
      return [
        {
          key: 'disputes',
          value: this.allJudgingMatchesCount,
          label: this.$$t('Disputes', 'stage-disputes'),
        },
        {
          key: 'checkins',
          value: this.summary.checkinsCount,
          label: this.$$t('Check-ins', 'stage-checkins'),
        },
        {
          key: 'resultsProvided',
          value: this.allResultsProvidedCount,
          label: this.$$t('Results-provided', 'stage-results'),
        },
      ];
    },
    blockCompletePercentage() {
      return blockId => {
        const block = this.summary.blocks[blockId];
        const { completed } = block;
        const blockTotal = this.getBlockTotalMatches(block);
        return Math.round(100 * (!blockTotal ? 0 : completed / blockTotal));
      };
    },
    blockSummaryData() {
      return blockTitle => {
        const { completed, inProgress, notStarted } = this.summary.blocks[
          blockTitle
        ];
        const limit = this.getBlockTotalMatches(
          this.summary.blocks[blockTitle]
        );
        return [
          // {
          //   key: 'matches',
          //   value: '',
          //   label: this.$$t('Matches: ', 'stage-block-matches'),
          // },
          {
            key: 'completed',
            value: completed,
            limit,
            label: this.$$t('Completed', 'block-completed'),
          },
          {
            key: 'in-progress',
            value: inProgress,
            limit,
            label: this.$$t('In progress', 'block-in-progress'),
          },
          {
            key: 'not-started',
            value: notStarted,
            limit,
            label: this.$$t('Not started', 'block-not-started'),
          },
        ];
      };
    },
    blockChartData() {
      return blockId => ({
        labels: [
          this.$$t('{group} progress', 'group-progress', {
            group: this.getBlockTitle(blockId),
          }),
        ],
        datasets: [
          {
            data: [this.blockCompletePercentage(blockId)],
            backgroundColor: [this.$scss.colors.greenBg],
            borderWidth: 0,
            label: this.$$t('{group} progress', 'group-progress', {
              group: this.getBlockTitle(blockId),
            }),
          },
        ],
      });
    },
    blockChartOptions() {
      const options = this.$lodash.cloneDeep(DEFAULT_CHART_SETTINGS);
      options.centerArea = {
        displayText: true,
        text: value => {
          return value + '%';
        },
        fontColor: this.$scss.colors.greenBg,
        fontSize: '2rem',
      };
      return options;
    },
    allMatchesCount() {
      return this.blocksReducer((acc, blockTitle) => {
        return acc + this.getBlockTotalMatches(this.summary.blocks[blockTitle]);
      });
    },
    allCompletedMatchesCount() {
      return this.blocksReducer((acc, blockTitle) => {
        const { completed } = this.summary.blocks[blockTitle];
        return acc + completed;
      });
    },
    allJudgingMatchesCount() {
      return this.blocksReducer((acc, blockTitle) => {
        const { judgingCount } = this.summary.blocks[blockTitle];
        return acc + judgingCount;
      });
    },
    allResultsProvidedCount() {
      return this.blocksReducer((acc, blockTitle) => {
        const { resultsProvided } = this.summary.blocks[blockTitle];
        return acc + resultsProvided;
      });
    },
    getBlockTitle() {
      return id => this.$lodash.get(this.getBlockByID(id), 'title', '');
    },
  },
  methods: {
    ...mapMutations(['updateCurrentTournamentStageByID']),
    blocksReducer(fn = () => {}, initial = 0) {
      return Object.keys(this.summary.blocks).reduce(fn, initial);
    },
    getBlockTotalMatches(block) {
      const { completed, inProgress, notStarted } = block;
      return completed + inProgress + notStarted;
    },
  },
};
</script>
