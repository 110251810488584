<template>
  <div class="footer-outer-wrap-side">
    <h3>{{ $t('_web_footer_contact_us-sidebar', 'Contact') }}</h3>
    <div class="footer-sidebar-wrapper">
      <span class="privacy-policy">
        <a :href="isRTL ? '/static/privacypolicy/other' : privacyLink">
          {{ $t('_web_footer_privacy', 'Privacy') }}
        </a>
      </span>
      <span class="terms-and-co">
        <a :href="isRTL ? '/static/terms/other' : termsLink">
          {{ $t('_web_footer_terms-and-conditions', 'Terms & Conditions') }}
        </a>
      </span>
      <span class="terms-and-co">
        <a :href="isRTL ? '/static/refund/other' : '/static/refund/mena'">
          {{ $t('_web_footer_refund-policy', 'Refund Policy') }}
        </a>
      </span>
      <span class="online-child-safety">
        <a
          :href="isRTL ? childSafetyAr : childSafetyEn"
          download
          target="_blank"
        >
          {{ $t('_web_footer_online-child-safety', 'Online child safety') }}
        </a>
      </span>
    </div>
    <HeraLangSwitcher class="sidebar-language-switcher" />
    <HeraRegionSwitcher
      v-if="showRegionSwitcher"
      class="col text-center px-2 font-size-xs d-flex"
      bottom-position
    />
    <span class="copyrights">&copy; 2023 PLAYHERA</span>
    <div v-if="hasASponsor" class="powered-by-container">
      <img
        v-if="isPoweredByZain"
        src="@/assets/images/general-sponsors/zain.png"
        alt="Zain Logo"
        style="height: auto; width: auto;"
      />
      <img
        v-if="account && account.country === 'Oman'"
        src="@/assets/images/general-sponsors/omantel.png"
        alt="Zain Logo"
        style="height: auto; width: auto;"
      />
    </div>
  </div>
</template>
<script>
import { EMAIL_SUPPORT, PRIVACY_LINK, TERMS_LINK } from '@/api/config';
import { mapState, mapGetters } from 'vuex';
import HeraLangSwitcher from '@/components/common/HeraLangSwitcher';
import HeraRegionSwitcher from '@/components/common/HeraRegionSwitcher';

export default {
  name: 'SidebarFooter',
  components: {
    HeraLangSwitcher,
    HeraRegionSwitcher,
  },
  props: {
    showRegionSwitcher: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mailSupport: EMAIL_SUPPORT,
      termsLink: TERMS_LINK,
      privacyLink: PRIVACY_LINK,
      childSafetyAr:
        'https://heraqaindiastorage.blob.core.windows.net/public-images/Safety-Booklet-Ar.pdf',
      childSafetyEn:
        'https://heraqaindiastorage.blob.core.windows.net/public-images/Safety-Booklet-En.pdf',
      countriesMena: [
        'Morocco',
        'Algeria',
        'Libya',
        'Egypt',
        'Syria',
        'Lebanon',
        'Palestine',
        'Jordan',
        'Saudi Arabia',
        'UAE',
        'Bahrein',
        'Kuwait',
        'Iraq',
      ],
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['appId', 'isAuthorized', 'isRTL']),

    hasASponsor() {
      const region = JSON.parse(localStorage.getItem('region'));
      return region.id == 'mena';
    },

    isPoweredByZain() {
      return this.account && this.countriesMena.includes(this.account.country);
    },
  },
};
</script>

<style lang="scss">
.footer-sidebar-wrapper {
  //   padding: 30px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  a {
    font-weight: 400 !important;
  }
}

.sidebar-language-switcher {
  margin-bottom: 36px;
}

.footer-outer-wrap-side {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .playhera-icon {
    svg {
      --fill: #5a58f2 !important;
    }
  }
  .dropdown-lang-button {
    // margin-top: 34px !important;
    // margin-bottom: 26px !important;
    background-color: #eff0f1 !important;
    color: #5a58f2 !important;
    border-radius: 4px !important;
    width: 110px !important;
    height: 36px !important;
  }
  .region-switcher {
    width: fit-content;
    margin-bottom: 40px;

    button {
      width: 103px;
    }
  }
  padding: 20px;
}
.copyrights {
  color: #eff0f1 !important;
  font-weight: 400 !important;
}

.powered-by-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  color: #eff0f1 !important;
  font-weight: 400 !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 850px) {
  .dropdown-lang-menu {
    top: -16px !important;
  }
  .show {
    display: flex !important;
  }
}
@media (max-width: 850px) {
}
html[dir='rtl'] {
  .dropdown-lang-menu {
    left: unset !important;
    right: 0px !important;
  }
}
@media (max-width: 850px) {
  .custom-style {
    display: none !important;
  }
}
.sidebar-language-switcher .float-dropbox.show.float-dropbox-top.regard-top {
  margin-top: -14px;
}
@media screen and (max-width: 850px) {
  .sidebar-language-switcher .float-dropbox.show.float-dropbox-top.regard-top {
    margin-top: 14px;
  }
}
</style>
