<template>
  <Avatar v-bind="props" />
</template>

<script>
import { mapState } from 'vuex';
import Avatar from './Avatar';

export default {
  components: {
    Avatar,
  },
  computed: {
    ...mapState('my', ['account', 'accountType']),
    props() {
      return {
        ...this.$attrs,
        imgUrl: this.account ? this.account.avatar : '',
        type: this.accountType,
      };
    },
  },
};
</script>
