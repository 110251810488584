import { MATCH_STATUSES } from '@/constants/battles';

export const matchStatuses = {
  computed: {
    localizedMatchStatuses() {
      let statuses = MATCH_STATUSES.reduce((res, obj) => {
        res[obj.name] = this.$t(obj.key, obj.label);
        return res;
      }, {});
      return statuses;
    },
  },
};
