import { render, staticRenderFns } from "./PrimaryText.vue?vue&type=template&id=7680195a&"
import script from "./PrimaryText.vue?vue&type=script&lang=js&"
export * from "./PrimaryText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PrimaryText.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2FText&name=PrimaryText&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Text'%2C%20'Text%20for%20label')%0A%20%20%20%20%7D%2C%0A%20%20%20%20size%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('Size'%2C%201)%0A%20%20%20%20%7D%2C%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports