<template>
  <div>
    <HeraFormSwitch
      v-model="attachmentsRequired"
      v-validate="'required'"
      name="attachment_required"
      :label="$$t('Require results attachments?', 'required_label')"
      required
      :disabled="isStageFieldDisabled('attachment_required') || disabled"
      :error="errors.first('attachment_required')"
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageRequiredAttachmentSetting',
  components: {
    HeraFormSwitch,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    attachmentsRequired: {
      get() {
        return this.getBlockConfigurationByKey('attachment_required', false);
      },
      set(val) {
        this.setBlockConfigurationByKey('attachment_required', val);
      },
    },
  },
};
</script>

<style></style>
