var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-footer-wrapper .mobile-wrapper-footer",class:_vm.omantelLogo || (_vm.account && _vm.account.country === 'Oman')
      ? 'omantel'
      : 'mobile-wrapper-footer'},[_c('router-link',{staticClass:"footer-item",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/new-icons/Vector FORYOU.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('_web_for_you_page', 'For You')))])]),_c('router-link',{staticClass:"footer-item",attrs:{"to":"/feed"}},[_c('img',{attrs:{"src":require("@/assets/images/new-icons/VectorFeed.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('_web_feed_btn', 'Feed')))])]),(_vm.isAvailableInRegion && _vm.account._type === 'profile')?_c('div',{staticClass:"footer-item"},[(
        _vm.isAuthorized &&
          _vm.currentRegion &&
          _vm.currentRegion.feature_flags.phmax_url !== null &&
          _vm.account &&
          _vm.account._type === 'profile'
      )?_c('a',{staticClass:"footer-item",on:{"click":_vm.redirectToPhMax}},[_c('img',{staticClass:"ph-max-logo-mobile",attrs:{"src":require("@/assets/images/new-icons/VectorPHMAX.svg"),"alt":"ph max logo"}}),_c('p',{staticClass:"ph-max-btn-text-mobile-footer"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_max_btn', 'Max'))+"\n      ")])]):(
        (_vm.currentRegion &&
          _vm.currentRegion.feature_flags.phmax_url &&
          _vm.account &&
          _vm.account._type === 'profile') ||
          (!_vm.account && _vm.currentRegion && _vm.currentRegion.feature_flags.phmax_url)
      )?_c('router-link',{staticClass:"footer-item",attrs:{"to":("/signin?redirectTo=" + (_vm.$route.fullPath)),"active-class":"active"}},[_c('img',{staticClass:"ph-max-logo-mobile",attrs:{"src":require("@/assets/images/new-icons/VectorPHMAX.svg"),"alt":"ph-max-logo"}}),_c('p',{staticClass:"ph-max-btn-text-mobile-footer"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_max_btn', 'Max'))+"\n      ")])]):_vm._e(),(_vm.isLoading && _vm.isAuthorized)?_c('Spinner',{staticClass:"spinner-middle",attrs:{"modal":""}}):_vm._e()],1):_vm._e(),(
      _vm.currentRegion &&
        _vm.currentRegion.feature_flags.marketplace_url &&
        _vm.account &&
        _vm.account._type === 'profile'
    )?_c('router-link',{staticClass:"footer-item",attrs:{"to":"/shop/categories"}},[_c('img',{attrs:{"src":require("@/assets/images/new-icons/VectorSHOP.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('_web_playhera_shop', 'Shop')))])]):_vm._e(),_c('router-link',{staticClass:"footer-item",attrs:{"to":"/tournaments"}},[_c('img',{staticClass:"trophy",attrs:{"src":require("@/assets/images/new-icons/VectorTournaments.svg"),"alt":"trophy"}}),_c('p',[_vm._v(_vm._s(_vm.$t('_web_tournaments', 'Tournaments')))])]),_c('router-link',{staticClass:"footer-item",attrs:{"to":"/search"}},[_c('img',{attrs:{"src":require("@/assets/images/new-icons/VectorSearch.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('_web_search', 'Search')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }