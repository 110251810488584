var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"popoverCommentClosest",staticClass:"comment-item my-4 position-relative w-100"},[_c('div',{staticClass:"d-flex comment-section"},[_c('LinkAccount',{staticClass:"login",attrs:{"id":_vm.comment.author.login,"type":_vm.comment.author.type,"vanity-id":_vm.comment.author.vanity_id}},[_c('Avatar',{attrs:{"img-url":_vm.comment.author.avatar,"size":_vm.isReply ? 36 : 48,"type":_vm.comment.author.type}})],1),_c('div',{staticClass:"comment-header align-items-top w-100"},[_c('div',{staticClass:"d-flex replies-smaller"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('LinkAccount',{staticClass:"comment-author-name",attrs:{"id":_vm.comment.author.login,"type":_vm.comment.author.type,"vanity-id":_vm.comment.author.vanity_id}},[_vm._v("\n            "+_vm._s(_vm.comment.author.name || _vm.comment.author.login)+"\n          ")])],1),_c('div',{staticClass:"comment-timestamp ml-4 muted"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("timeAgo")(_vm.comment.last_updated_on,_vm.$t)))])])]),_c('div',{staticClass:"d-flex justify-content-between align-items-start"},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.comment.text)?_c('TextContent',{staticClass:"comment-text-cont w-100",attrs:{"has-expander":"","content":_vm.comment.text}}):_vm._e(),(_vm.comment.attachments && _vm.comment.attachments.length)?_c('Attachments',{staticClass:"media-item",attrs:{"attachments":_vm.comment.attachments}}):_vm._e()],1),(_vm.isAuthorized)?_c('AppIcon',{staticClass:"cursor-pointer comment-dots",attrs:{"name":"dots","width":"18","fill":_vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor},on:{"click":function (event) { return _vm.openPopoverMenu(event.currentTarget); }}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-start reply-action align-items-center"},[_c('a',{class:{
            active:
              _vm.comment && _vm.comment.reactions && _vm.comment.reactions.like.reacted,
          },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.likeClick($event)}}},[_c('div',{staticClass:"d-flex like-link align-items-center"},[(
                _vm.comment &&
                  _vm.comment.reactions &&
                  _vm.comment.reactions.like &&
                  !_vm.comment.reactions.like.reacted
              )?_c('img',{staticClass:"like-button-liked",attrs:{"src":require("@/assets/images/comments/like-empty.svg"),"alt":"like button"}}):_c('img',{staticClass:"like-button",attrs:{"src":require("@/assets/images/comments/like-filled.svg"),"alt":"like button"}}),_c('span',{staticClass:"like-count",class:{
                'like-count-reacted':
                  _vm.comment &&
                  _vm.comment.reactions &&
                  _vm.comment.reactions.like &&
                  _vm.comment.reactions.like.reacted,
              }},[_vm._v("\n              "+_vm._s(_vm.comment.reactions.like.count)+"\n              "+_vm._s(_vm.isRTL
                  ? _vm.comment.reactions.like.count > 10
                    ? _vm.$t('_web_comment_likes', 'likes')
                    : _vm.$t('_web_comment_like', 'like')
                  : _vm.comment.reactions.like.count === 1
                  ? _vm.$t('_web_comment_like', 'like')
                  : _vm.$t('_web_comment_likes', 'likes')))])])]),(_vm.isAuthorized)?_c('a',{staticClass:"add-reply ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addReplyAction($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('_web_reply', 'Reply'))+"\n        ")]):_vm._e()])])],1),_c('div',{staticClass:"comment-body"},[_c('div',{staticClass:"d-flex justify-content-between mt-4 post-actions"},[_c('div',{staticClass:"show-replies",class:{
          'view-more-tournament': _vm.$route.path.includes('tournament'),
        }},[(!_vm.isReply && _vm.comment.comments_count)?_c('a',{staticClass:"expand-replies",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleReplyBtn($event)}}},[(_vm.showReplies)?_c('img',{staticClass:"expand-arrow",attrs:{"src":require("@/assets/images/comments/arrow-up-new.svg"),"alt":"arrow up"}}):_c('img',{staticClass:"expand-arrow",attrs:{"src":require("@/assets/images/comments/arrow-down-new.svg"),"alt":"arrow down"}}),_c('span',{staticClass:"show-replies__link"},[_vm._v("\n            "+_vm._s(_vm.comment.comments_count)+"\n            "+_vm._s(_vm.isRTL
                ? _vm.comment.comments_count > 10
                  ? _vm.$t('_web_tournament_sidechat_replies', 'replies')
                  : _vm.$t('_web_tournament_sidechat_reply', 'reply')
                : _vm.comment.comments_count === 1
                ? _vm.$t('_web_tournament_sidechat_reply', 'reply')
                : _vm.$t('_web_tournament_sidechat_replies', 'replies'))+"\n          ")])]):_vm._e()])]),(_vm.showReplies || _vm.showAddReply)?_c('Replies',{ref:"reply",attrs:{"input-active":_vm.showAddReply,"post-id":_vm.postId,"comment":_vm.comment,"quantity":_vm.comment.comments_count},on:{"toggleAddReply":_vm.toggleAddReply,"replyAdded":_vm.replyAdded}}):_vm._e(),_c('Popover',{attrs:{"position":_vm.popoverCommentPosition,"closest":_vm.$refs.popoverCommentClosest,"post":_vm.comment,"owner":_vm.postowner},on:{"deleted":_vm.removeComment,"authorBlocked":function($event){return _vm.$emit('refresh')},"toggle":_vm.closePopover}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }