var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group autosuggest h-text-left",class:[
    ("autosuggest-" + _vm.variant),
    {
      'is-invalid': _vm.error,
      'is-disabled': _vm.disabled,
      'is-required': _vm.required,
      'playhera-input': _vm.isPrimary,
    } ],attrs:{"aria-invalid":!!_vm.error}},[_c('div',{ref:"dropdownActivator",staticClass:"autosuggest_input-wrapper",class:{ loading: _vm.loading }},[_c('TextInput',{staticClass:"w-100 autosuggest_input",attrs:{"id":_vm.inputID,"type":"text","error":_vm.error,"disabled":_vm.disabled,"placeholder":_vm.placeholderReplaced,"tooltip":_vm.tooltip,"label":_vm.label},on:{"focus":_vm.onFocus,"blur":_vm.onEsc,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onUp($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEsc($event)}]},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),(!_vm.loading && !_vm.error && _vm.searchText && _vm.searchText.length > 0)?_c('icon',{key:"close-icon",staticClass:"autosuggest_clear-icon",attrs:{"name":"close","width":"10"},on:{"click":_vm.clearSearchText}}):_vm._e(),_c('PopoverMenu',{staticClass:"dropdown-popover py-4",attrs:{"position":_vm.dropdownPosition,"closest":_vm.$el,"items":_vm.resultsList,"float":['center'],"regard":"top","width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [_c('span',{on:{"mousedown":function($event){return _vm.pickItem(index)}}},[_vm._t("item",[_vm._v("\n            "+_vm._s(item[_vm.displayProperty])+"\n          ")],null,{ item: item })],2)]}}],null,true)},[(_vm.resultsList.length === 0)?_c('span',{staticClass:"p-4",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t('_web_autosuggest_no_results', 'No results')))]):_vm._e()])],1),(_vm.multiple && !_vm.$lodash.isEmpty(_vm.pickedItems))?_c('div',{staticClass:"d-flex flex-wrap w-100 mt-3"},_vm._l((_vm.pickedItems),function(tag,index){return _c('AttributeTag',{key:tag[_vm.displayProperty],attrs:{"name":tag[_vm.displayProperty]},on:{"delete":function($event){return _vm.removeTag(index)}}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }