import dateMethods from '@/mixins/dateMethods';
import { mapActions, mapMutations } from 'vuex';
export default {
  mixins: [...dateMethods],
  created() {
    this.addSystemSubscriber(this.systemSubscriber);
  },
  destroyed() {
    this.removeSystemSubscriber(this.systemSubscriber);
  },
  methods: {
    ...mapActions([
      'addSystemSubscriber',
      'removeSystemSubscriber',
      'errorNotify',
    ]),
    ...mapMutations(['updateCurrentTournamentStageByID']),
    systemSubscriber({ type, extended }) {
      if (!extended || extended.tournament_id != this.tournamentID) {
        return;
      }

      if (type == 'tournament_stage_check_in_started_system') {
        const { stage_index } = extended;
        const stage = this.$lodash.cloneDeep(
          this.currentTournamentStages[stage_index]
        );
        if (stage) {
          this.updateCurrentTournamentStageByID({
            id: stage.id,
            stage: {
              ...stage,
              check_in_start_date: this.getNowSeconds(),
            },
          });
        }
      }
    },
  },
};
