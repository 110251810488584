<template>
  <div class="d-flex" :class="horizontal ? 'flex-row' : 'flex-column'">
    <template v-for="(item, index) in options">
      <label
        :key="index"
        class="checkbox-wrap cursor-pointer d-flex align-items-center"
      >
        <label class="custom-checkbox">
          <input
            v-model="valueModel"
            type="checkbox"
            class="custom-checkbox__input"
            :name="inputName || `input_${_uid}`"
            :value="item.id"
          />
          <span class="custom-checkbox__icon"></span>
        </label>
        <span class="text-truncate checkbox-label font-weight-bold">{{
          item.name
        }}</span>
      </label>
    </template>
  </div>
</template>
<script>
import { prop } from '@/utils/factories';
export default {
  props: {
    inputName: prop(String),
    horizontal: prop(Boolean),
    options: prop(Array),
    value: prop(Array),
  },
  computed: {
    valueModel: {
      get() {
        return this.value || [];
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.checkbox-wrap {
  @include auto-rtl(padding-left, $ph-medium-space);
  .flex-row & {
    @include auto-rtl(margin-right, $ph-medium-space);
  }
  .flex-column & {
    line-height: 2.5rem;
    margin-bottom: 0;
    &:hover {
      background-color: $ph-popover-bg-raw;
    }
  }
  .checkbox-label {
    color: $ph-primary-text;
    @include auto-rtl(
      (
        margin-left: $ph-medium-space,
        padding-right: $ph-medium-space,
      )
    );
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Molecules/form"
  name="MultiCheckbox"
  knobs="{
    horizontal: {
      default: boolean('horizontal', false)
    },
  }"
>
  <MultiCheckbox
    v-bind="{ horizontal }" @input="val => action('input', val)"
    :options="[
      {
        name: 'first',
        id: '1',
      },
      {
        name: 'second',
        id: '2',
      }
      ]"/>
</story>
