import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { CLAN } from '@/constants/accounts';
import isLoading from '@/mixins/isLoading';
import { isEmpty } from '@/utils/lodashUtils';

export default {
  mixins: [isLoading],
  data() {
    return {
      joinAllowed: true,
      afterJoin: this.$lodash.noop,
      localStatus: null,
      hasPhone: false,
      isSso: false,
      savedCards: [],
      isOpening: false,
      orderID: '',
      tournamentIDnew: '',
      teamIDnew: '',
      newProfile: '',
      allClans: '',
    };
  },
  computed: {
    ...mapState('my', {
      myAccount: 'account',
      myAccountId: 'accountId',
    }),

    ...mapGetters([
      'registrationTournament',
      'registrationData',
      'currentTournament',
      'currentTournamentStages',
    ]),
    ...mapGetters('my/clans', ['hasClans', 'clanList', 'hasMultipleClans']),
  },
  mounted() {
    this.fetchCards().then(res => (this.savedCards = res.items));
    let cdnId = 'tap-cdn';
    if (!document.getElementById(cdnId)) {
      let scriptTag = document.createElement('script');
      scriptTag.src = 'https://secure.gosell.io/js/sdk/tap.min.js';
      scriptTag.id = cdnId;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  },
  methods: {
    ...mapMutations(['setRegistrationTournament', 'setRegistrationData']),
    ...mapActions([
      'openModal',
      'postJoinTournament',
      'setTournamentSquadParticipants',
      'errorNotify',
      'successNotify',
      'validateJoinTournament',
      'fetchTournamentRulesText',
      'getTournamentSquadParticipants',
      'fetchCards',
      'openModal',
      'closeModal',
      'fulfillOrder',
      'postTournamentOrder',
      'cancelOrder',
      'errorNotify',
      'getCompetitionPlayersStatus',
    ]),
    goToRegistrationPage(tournamentID, teamId) {
      return this.$router.push({
        name: 'tournamentRegistrationForm',
        params: {
          tournamentID,
          teamId,
        },
        query: {
          ...this.$route.query,
        },
      });
    },

    goToClanCreate(tournament) {
      this.setRegistrationTournament({
        ...this.registrationTournament,
        ...tournament,
      });
      return this.$router.push({
        name: 'tournamentRegistrationWithNewTeam',
        params: {
          tournamentID: tournament.id,
        },
        query: {
          ...this.$route.query,
        },
      });
    },
    goToClanSelect(tournamentID) {
      return this.$router.push({
        name: 'tournamentRegistrationTeamSelect',
        params: {
          tournamentID,
        },
        query: {
          ...this.$route.query,
        },
      });
    },
    goToRosterSelect(tournamentId, teamId) {
      return this.$router.push({
        name: 'tournamentRegistrationCreateRoster',
        params: {
          tournamentId,
          teamId,
        },
        query: {
          ...this.$route.query,
        },
      });
    },
    setTeamIDForRegistration(teamId) {
      this.setRegistrationData({
        clanId: teamId,
      });
    },
    requiresRegistrationForm(tournament) {
      return Object.prototype.hasOwnProperty.call(
        tournament,
        'requiresRegistrationForm'
      )
        ? tournament.requiresRegistrationForm
        : tournament.requires_registration_form;
    },
    needTeamForRegistration(tournament) {
      return Object.prototype.hasOwnProperty.call(tournament, 'playerType')
        ? tournament.playerType === CLAN
        : tournament.player_type === CLAN;
    },
    canManageTeam(teamId) {
      const needTeam = this.needTeamForRegistration(teamId);

      const team = this.clanList.find(clan => clan.id === teamId);

      const canManageRoster = needTeam && team.canManage;

      return canManageRoster;
    },

    async selectJoinScenario(tournament, teamId = '') {
      if (!this.$store.getters.isAuthorized) {
        this.$router.push('/signin');
      }

      // const needTeam = this.needTeamForRegistration(tournament);
      // if (needTeam && !this.hasClans) {
      //   this.errorNotify({
      //     inModal: true,
      //     message: this.$t(
      //       '_web_server_errors-register-player-clan-is-null',
      //       'You don’t have a Clan - Please create your clan to participate or join the existing one'
      //     ),
      //   });
      //   return;
      // }

      // const needToGoToTeamSelect = needTeam && this.hasMultipleClans && !teamId;
      // countries_restrictions means allowed countries now, also could be countriesRestrictions
      const countries =
        tournament.countries_restrictions || tournament.countriesRestrictions;
      const rulesResult = await this.fetchTournamentRulesText(tournament.id);
      const needRules = rulesResult && rulesResult.rules;
      const noNeedRulesOnTeamSelect =
        this.$route.name === 'tournamentRegistrationTeamSelect';

      // const needRules =
      // rulesResult && rulesResult.rules&&!needToGoToTeamSelect;
      if (!isEmpty(countries)) {
        this.clanCountryCheck(
          countries,
          tournament,
          teamId,
          needRules,
          rulesResult.rules
        );
      } else if (needRules && !noNeedRulesOnTeamSelect) {
        this.openModal({
          name: 'RulesConfirmModal',
          props: {
            rules: rulesResult.rules,
            tournament: tournament,
            teamId: teamId,
          },
          events: {
            save: this.continueJoinScenario,
          },
        });
      } else {
        this.continueJoinScenario(tournament, teamId);
      }
    },
    continueJoinScenario(tournament, teamId = '') {
      this.setRegistrationData({});
      const needTeam = this.needTeamForRegistration(tournament);
      const needToGoToTeamSelect = needTeam && !teamId;
      const myTeamId = !needTeam
        ? undefined
        : this.hasClans && !teamId
        ? this.clanList[0].id
        : teamId;
      this.setRegistrationTournament({
        ...tournament,
        clan_id: myTeamId,
      });
      if (needToGoToTeamSelect) {
        this.goToClanSelect(tournament.id);
      } else if (this.requiresRegistrationForm(tournament)) {
        this.goToRegistrationPage(tournament.id, myTeamId);
      } else this.regularJoinProcedure(tournament.id, myTeamId);
    },
    clanCountryCheck(
      tournamentCountries,
      tournament,
      teamId,
      needRules,
      rules
    ) {
      const country = teamId
        ? this.clanList.find(clan => clan.id === teamId).country_id
        : this.myAccount.countryId;

      if (tournamentCountries.find(el => el.id === country)) {
        if (needRules) {
          this.openModal({
            name: 'RulesConfirmModal',
            props: {
              rules: rules,
              tournament: tournament,
              teamId: teamId,
            },
            events: {
              save: this.continueJoinScenario,
            },
          });
        } else {
          this.continueJoinScenario(tournament, teamId);
        }
      } else
        this.errorNotify({
          message: this.$t(
            '_web_tournaments-region-lock',
            'Not available in your country.'
          ),
          inModal: true,
          yesText: this.$t('_web_form_error_confirm-yes', 'OK'),
        });
    },
    regularJoinProcedure(tournamentId, profile, teamId) {
      // if (teamId) {
      //   this.setTeamIDForRegistration(teamId);
      // }
      this.tournamentIDnew = tournamentId;
      this.teamIDnew = teamId;
      this.newProfile = profile;
      this.joinToTournament(tournamentId, profile, teamId);
    },
    async prejoinCheck(tournamentId, teamId, bypassError = false) {
      // note: validateJoinTournament successfully validate countries for tournament with reg form, but not regular tournament
      try {
        return this.validateJoinTournament({ tournamentId, teamId });
      } catch (error) {
        this.joinAllowed = false;
        if (bypassError) {
          throw error;
        } else {
          this.errorNotify(error);
        }
      }
    },

    joinToTournament(tournamentID, teamId) {
      this.setRegistrationTournament({
        ...this.registrationTournament,
        id: tournamentID,
      });
      this.setLoading();
      return this.prejoinCheck(tournamentID, teamId, true)
        .then(() =>
          this.postJoinTournament(this.$route.query.registration_token)
        )
        .then(() => {
          this.hasClicked = !this.hasClicked;
          this.successNotify({
            text: this.$t(
              '_web_tournament_success-join-message',
              'Application form sent'
            ),
          });
        })
        .then(() => {
          if (this.currentTournament.entry_fee.required === true) {
            this.pay();
          } else {
            this.goToTournament(tournamentID);
          }
        })
        .catch(this.errorNotify)
        .finally(
          this.unsetLoading,
          window.dataLayer.push({
            event: 'tournament join',
            userID: this.myAccount._id,
            from: this.$route.path,
            tournamentId: this.currentTournament.id,
            gameName: this.currentTournament.game,
            squadSize: this.currentTournament.squad_size,
            tournamentName: this.currentTournament.title,
            tournamentFormat: this.currentTournamentStages[0]
              .block_configuration.type,
            tournamentCountry: this.currentTournament.country,
            tournamentPool: this.currentTournament.prize.prize_pool,
            regForm: this.currentTournament.requires_registration_form,
            tournamentPrivate:
              this.currentTournament.registration_type === 'closed'
                ? 'true'
                : 'false',
            tournamentOrganizer: this.currentTournament.event_organizer_name,
          })
        );
    },
    test() {
      this.getCompetitionPlayersStatus({
        id: this.$route.params.tournamentID,
      }).then(res => (this.allClans = res));
    },
    checkPaymentStatusPending() {
      const requestedClans = this.allClans.filter(
        clan => clan.registration_status === 'payment_pending'
      );
      const requestedClanIds = requestedClans.map(clan => clan.clan.id);
      return requestedClanIds.includes(this.clanID);
    },
    checkPaymentStatusRequested() {
      const requestedClans = this.allClans.filter(
        clan => clan.registration_status === 'requested'
      );
      const requestedClanIds = requestedClans.map(clan => clan.clan.id);
      return requestedClanIds.includes(this.clanID);
    },
    pay(tournament, userID) {
      this.isOpening = true;
      this.postTournamentOrder({
        tournament_id: tournament ? tournament.id : this.currentTournament.id,
        player_id: userID
          ? userID
          : this.currentTournament.max_players > 1
          ? this.newProfile
          : this.myAccountId,
      })
        .then(res => {
          this.isOpening = false;
          this.orderID = res.id;
          this.openModal({
            name: 'CheckoutTournament',
            props: {
              product: res.id,
              description: res.items[0],
              quantity: 1,
              cards: this.savedCards,
              provider: this.$flagsmith.flags.payment_provider.value,
            },
            events: {
              submit: (token, save_card) =>
                // this.saveSquad();
                {
                  this.processPayment(token, res.id, save_card);
                },

              errorStatus: () => {
                this.canceledPayment =
                  'something went wrong, please ty again later';
              },
            },
            settings: {
              clickOnBackdrop: false,
              escKey: false,
              variant: 'fit-content',
            },
          });
        })
        .catch(err => {
          this.errorNotify(err.message);
          this.cancelOrder(this.orderID);
          this.isOpening = false;
        });
    },
    async processPayment(token, res, save_card) {
      try {
        await this.fulfillOrder({ id: res, token, save_card }).then(res =>
          res.redirectUrl
            ? (window.location.href = res.redirectUrl)
            : (this.closeModal(),
              (this.canceledPayment = 'something went wrong'))
        );
      } catch (err) {
        this.closeModal();
        this.canceledPayment = 'Something went wrong';
        window.dataLayer.push({
          event: 'shop purchase failed',
        });
      }
    },
    goToTournament(accountId) {
      this.$router.push({
        path: `/tournament/${accountId}`,
        params: {
          accountId,
          accountType: this.$routerNames.TOURNAMENT,
        },
      });
    },
  },
};
