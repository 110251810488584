<template>
  <div class="position-relative" :dir="isRTL ? 'rtl' : 'ltr'">
    <HorizontalScroller
      ref="scroller"
      class="newsfeed-scroller"
      @update="scrollerUpdateHandler"
    >
      <ul class="d-flex p-0 m-0">
        <li
          v-for="(item, index) in items"
          :key="item.id || index"
          class="d-flex flex-grow-1 newsfeed-scroller_item"
        >
          <slot name="item" v-bind="{ item }" />
        </li>
      </ul>
    </HorizontalScroller>

    <div
      v-if="isRTL ? isRightArrowVisible : isLeftArrowVisible"
      class="position-absolute h-100 d-flex align-items-center cursor-pointer newsfeed-scroller_arrow newsfeed-scroller_arrow--left"
      @click="scrollLeft"
    >
      <Icon name="arrow-left" width="30" :fill="$scss.iconPrimaryColor" />
    </div>
    <div
      v-if="isRTL ? isLeftArrowVisible : isRightArrowVisible"
      class="position-absolute h-100 d-flex align-items-center justify-content-end cursor-pointer newsfeed-scroller_arrow newsfeed-scroller_arrow--right"
      @click="scrollRight"
    >
      <Icon name="arrow-left" width="30" :fill="$scss.iconPrimaryColor" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HorizontalScroller from '@/components/common/HorizontalScroller';
import Icon from '@/components/atoms/Icon';

export default {
  components: { HorizontalScroller, Icon },

  props: {
    items: {
      type: Array,
      required: true,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isArrowsVisible: false,
      isLeftArrowVisible: false,
      isRightArrowVisible: false,
    };
  },
  computed: {
    ...mapGetters(['isRTL']),
  },
  methods: {
    scrollerUpdateHandler({
      canBeScrolled,
      canBeScrolledLeft,
      canBeScrolledRight,
    }) {
      this.isArrowsVisible = canBeScrolled;
      this.isLeftArrowVisible = canBeScrolledLeft;
      this.isRightArrowVisible = canBeScrolledRight;
    },

    scrollLeft() {
      if (this.isRTL) {
        this.$refs.scroller.scrollRight();
      } else {
        this.$refs.scroller.scrollLeft();
      }
    },

    scrollRight() {
      if (this.isRTL) {
        this.$refs.scroller.scrollLeft();
      } else {
        this.$refs.scroller.scrollRight();
      }
    },
  },
};
</script>

<style lang="scss">
.newsfeed-scroller {
  &_item {
    list-style: none;
    @include auto-rtl(margin-right, 0.75rem);
    &:last-child {
      @include auto-rtl(margin-right, 0);
    }
  }

  &_arrow {
    top: 0;
    width: 3.125rem;

    &--left {
      left: 0;
      background: linear-gradient(90deg, black 0%, rgba(black, 0) 100%);
    }

    &--right {
      right: 0;
      background: linear-gradient(270deg, black 0%, rgba(black, 0) 100%);
      svg {
        transform: scaleX(-1);
      }
    }
  }
}
</style>
