import api from '@/api';
import Vue from 'vue';
import { getObjectDifference } from '@/mixins/helpers';
import {
  BLOCK_TYPES,
  STAGE_STATUSES,
  COMPETITION_STATUSES,
  DEFAULT_STATUSES_INACTIVE,
} from '@/constants/tournaments';
import { BATTLE_STATUSES } from '@/constants/battles';
import { cloneDeep, upperFirst, isEmpty } from '@/utils/lodashUtils';
import { ISOSecondsConvertion } from '@/mixins/dateMethods';
import { mapPlayersItems } from './utils.js';
const getEmptyMatchSummaryModel = () => ({
  total: 0,
  started: 0,
  completed: 0,
  not_started: 0,
  cancelled: 0,
  judging: 0,
});
const {
  methods: { dateToSeconds, toSeconds, startOfDayObj },
} = ISOSecondsConvertion;
export const newStageID = 'newStage';
const defaultStagesState = () => ({
  data: {},
  currentStageBackup: {},
  currentStageID: null,
  stageLoader: false,
  stagePlayersTableSettings: [],
});
const emptyStage = index => ({
  index,
  status: 'draft',
  attachment_required: false,
  availability_type: 'online',
  check_in_enabled: false,
  check_in_start_date: null,
  disputesCount: 0,
  block_configuration: {
    best_of: 1,
    best_of_final: 1,
    best_of_lower: 1,
    points_per_kill: 1,
    match_duration: 1800,
    type: BLOCK_TYPES.SINGLE,
    match_3rd_place: false,
  },
});

const STAGE_STATUS_FLOW = [
  STAGE_STATUSES.DRAFT,
  STAGE_STATUSES.PUBLISHED,
  STAGE_STATUSES.STARTED,
  STAGE_STATUSES.FINISHED,
];

const DISABLED_STAGE_FIELDS_BY_STATUS = {
  // published
  stage_date_start: STAGE_STATUSES.PUBLISHED,
  qualify: STAGE_STATUSES.PUBLISHED,
  fill_blocks: STAGE_STATUSES.PUBLISHED,
  addGroup: STAGE_STATUSES.PUBLISHED,
  // started
  stageType: STAGE_STATUSES.STARTED,
  stagePlaceType: STAGE_STATUSES.STARTED,
  rounds: STAGE_STATUSES.STARTED,
  deleteStage: STAGE_STATUSES.STARTED,
  bestOf: STAGE_STATUSES.STARTED,
  bestOfFinal: STAGE_STATUSES.STARTED,
  bestOfLower: STAGE_STATUSES.STARTED,
  third_place_match: STAGE_STATUSES.STARTED,
  tieMatchAllowed: STAGE_STATUSES.STARTED,
  tieGameAllowed: STAGE_STATUSES.STARTED,
  pointsPerKill: STAGE_STATUSES.STARTED,
  pointsForWin: STAGE_STATUSES.STARTED,
  pointsForLose: STAGE_STATUSES.STARTED,
  pointsForTie: STAGE_STATUSES.STARTED,
  swissHelp: STAGE_STATUSES.STARTED,
  pointsTable: STAGE_STATUSES.STARTED,
  tieBreakerRules: STAGE_STATUSES.STARTED,
  matchDuration: STAGE_STATUSES.STARTED,
  fullDoubleElimination: STAGE_STATUSES.STARTED,
  attachment_required: STAGE_STATUSES.STARTED,
  autostartBattle: STAGE_STATUSES.STARTED,
  fullTeams: STAGE_STATUSES.STARTED,
  checkin: STAGE_STATUSES.STARTED,
  stage_check_in_start_date: STAGE_STATUSES.STARTED,
  stage_check_in_end_date: STAGE_STATUSES.STARTED,
  // finished
  stage_date_end: STAGE_STATUSES.FINISHED,
  stageLocation: STAGE_STATUSES.FINISHED,
  stageMap: STAGE_STATUSES.FINISHED,
  stageName: STAGE_STATUSES.FINISHED,
  round_date: STAGE_STATUSES.FINISHED,
  round_start: STAGE_STATUSES.FINISHED,
  round_finish: STAGE_STATUSES.FINISHED,
};

export default {
  state: defaultStagesState(),
  getters: {
    currentStageID: state => state.currentStageID,
    stagesList: state => Object.keys(state.data),
    getStageInfo: state => id => state.data[id] || {},
    getStageDisputesCountByStageId: state => id =>
      (state.data[id] || {}).disputesCount || 0,
    getAllDisputesCount: state =>
      Object.keys(state.data).reduce(
        (acc, id) => acc + state.data[id].disputesCount,
        0
      ),
    getAllActiveMatches: (state, getters) =>
      getters.currentTournamentStages.reduce(
        (acc, stage) => [...acc, ...(stage.activeMatches || [])],
        []
      ),
    getActiveMatchesSummary: (state, getters) =>
      getters.getAllActiveMatches.reduce((acc, match) => {
        const date = toSeconds(startOfDayObj(match.dateStart));
        const getStatusKey = status =>
          [
            BATTLE_STATUSES.cancelled,
            BATTLE_STATUSES.started,
            BATTLE_STATUSES.judging,
            BATTLE_STATUSES.completed,
          ].includes(status)
            ? status
            : 'not_started';
        let el = acc[date];
        if (isEmpty(el)) {
          el = {
            ...getEmptyMatchSummaryModel(),
            total: 1,
            [getStatusKey(match.status)]: 1,
          };
        } else {
          el = {
            ...el,
            total: el.total + 1,
            [getStatusKey(match.status)]: el[getStatusKey(match.status)] + 1,
          };
        }
        return {
          ...acc,
          [date]: el,
        };
      }, {}),
    currentTournamentStages: state =>
      Object.values(state.data).sort((a, b) => a.index - b.index),
    currentTournamentStage: state =>
      state.data[state.currentStageID || newStageID] || {},
    isCurrentStageChanged: (state, getters) => {
      return (
        !isEmpty(
          getObjectDifference(
            state.currentStageBackup,
            getters.currentTournamentStage
          )
        ) ||
        !isEmpty(
          getObjectDifference(
            getters.currentTournamentStage,
            state.currentStageBackup
          )
        )
      );
    },
    isStageFieldDisabled: (state, getters) => (
      fieldName,
      stage = getters.currentTournamentStage
    ) => {
      if (
        DEFAULT_STATUSES_INACTIVE.includes(getters.currentTournament.status)
      ) {
        return true;
      }
      const status = stage.status;
      const fieldStatus = DISABLED_STAGE_FIELDS_BY_STATUS[fieldName];
      if (!status || !fieldName || !fieldStatus) {
        return false;
      }
      return getters.isStagePastStatus(fieldStatus, stage);
    },
    isStagePastStatus: (state, getters) => (
      statusKey,
      stage = getters.currentTournamentStage
    ) => {
      const currentStatus = stage.status;
      const statusIndex = STAGE_STATUS_FLOW.findIndex(s => s === statusKey);
      const currentIndex = STAGE_STATUS_FLOW.findIndex(
        s => s === currentStatus
      );
      return (
        statusIndex !== -1 && currentIndex !== -1 && statusIndex <= currentIndex
      );
    },
    getStageLoader: stage => stage.stageLoader,
    stagePlayersTableSettings: state => state.stagePlayersTableSettings,
    hasCheckInInFirstStage: (state, getters) =>
      getters.hasStages &&
      getters.getStageInfo(getters.stagesList[0]).check_in_enabled,
    checkIfCheckInInFirstStageStarted: (state, getters) => () => {
      const now = dateToSeconds(new Date());
      return (
        getters.hasCheckInInFirstStage &&
        getters.getStageInfo(getters.stagesList[0]).check_in_start_date <= now
      );
    },
    hasStages: (state, getters) => getters.stagesList.length > 0,
    isSingleStage: (state, getters) => getters.stagesList.length === 1,
    firstDraftStage: (state, getters) =>
      getters.hasStages
        ? Object.values(state.data).find(
            stage => stage.status === STAGE_STATUSES.DRAFT
          )
        : undefined,
    firstPublishedStage: (state, getters) =>
      getters.hasStages
        ? Object.values(state.data).find(
            stage => stage.status === STAGE_STATUSES.PUBLISHED
          )
        : undefined,
    firstPublishedStageWithoutBracket: (state, getters) =>
      getters.hasStages
        ? Object.values(state.data).find(
            stage =>
              stage.status === STAGE_STATUSES.PUBLISHED &&
              getters
                .getBlocksByStageID(stage.id)
                .some(block => block.status === COMPETITION_STATUSES.DRAFT)
          )
        : undefined,
    firstScheduledStage: (state, getters) =>
      getters.hasStages
        ? Object.values(state.data).find(
            stage =>
              stage.status === STAGE_STATUSES.DRAFT &&
              getters
                .getBlocksByStageID(stage.id)
                .every(block => block.status === COMPETITION_STATUSES.SCHEDULED)
          )
        : undefined,
    previousStageFinished: (state, getters) => {
      if (isEmpty(getters.firstDraftStage)) {
        return true;
      }
      const { index } = getters.firstDraftStage;
      return (
        !index ||
        (index > 0 &&
          getters.currentTournamentStages[index - 1].status ===
            STAGE_STATUSES.FINISHED)
      );
    },
  },
  mutations: {
    addNewStageInTournamentStages(state, payload) {
      Vue.set(state.data, payload.id, payload);
    },
    updateCurrentTournamentStageByID(state, payload) {
      Vue.set(state.data, payload.id, {
        ...state.data[payload.id],
        ...payload,
      });
    },
    updateCurrentTournamentStage(state, payload) {
      Vue.set(state.data, state.currentStageID, {
        ...state.data[state.currentStageID],
        ...payload,
      });
    },
    updateStageLoader(stage, payload) {
      stage.stageLoader = payload;
    },
    updateStageStatus(state, { stageID, status /*statusBlock*/ }) {
      Vue.set(state.data, stageID, {
        ...state.data[stageID],
        status,
      });
    },
    setCurrentTournamentStages(state, payload) {
      state.data = {
        ...payload.reduce(
          (acc, stage) => ({
            ...acc,
            [stage.id]: stage,
          }),
          {}
        ),
        ...(isEmpty(state.data.newStage)
          ? {}
          : {
              newStage: cloneDeep(state.data.newStage),
            }),
      };
    },
    clearAllStages(state) {
      state.data = {};
    },
    setCurrentTournamentStage(state, stageID) {
      state.currentStageID = stageID;
    },
    clearCurrentTournamentStage(state) {
      state.currentStageID = null;
    },
    createNewTournamentStage(state, payload = {}) {
      const length = Object.keys(state.data).length;
      Vue.set(state.data, newStageID, {
        ...emptyStage(length + 1),
        ...payload,
      });
      state.currentStageID = newStageID;
    },
    swapStageID(state, { newID, oldID = newStageID } = {}) {
      const copyOfData = cloneDeep(state.data);
      copyOfData[newID] = {
        ...cloneDeep(copyOfData[oldID]),
        id: newID,
      };
      delete copyOfData[oldID];
      state.data = copyOfData;
      if (state.currentStageID === oldID) {
        state.currentStageID = newID;
      }
    },
    deleteTournamentStageByID(state, stageID) {
      Vue.delete(state.data, stageID);
    },
    setStagePlayersTableSettings(state, payload) {
      state.stagePlayersTableSettings = payload;
    },
  },
  actions: {
    drawStage(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID }
    ) {
      return api.tournaments
        .drawStage(tournamentID, stageID)(accessToken)
        .then(response => response.data);
    },
    resetStageBrackets(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID }
    ) {
      return api.tournaments
        .resetAllBrackets(tournamentID, stageID)(accessToken)
        .then(response => response.data);
    },
    fetchTournamentStages(
      {
        commit,
        getters: { accessToken },
      },
      id
    ) {
      return api.tournaments
        .getTournamentStages(id)(accessToken)
        .then(response => response.data)
        .then(stages => {
          commit('setCurrentTournamentStages', stages);
          return stages;
        });
    },
    fetchTournamentStage(
      {
        commit,
        getters: { accessToken, currentTournament },
      },
      stageID
    ) {
      const { id: tournamentID } = currentTournament;
      return api.tournaments
        .getTournamentStage(tournamentID, stageID)(accessToken)
        .then(response => response.data)
        .then(stage => {
          commit('updateCurrentTournamentStageByID', { id: stageID, stage });
          return stage;
        });
    },
    createTournamentStage({
      commit,
      dispatch,
      getters: { accessToken, currentTournament, currentTournamentStage },
    }) {
      return api.tournaments
        .createTournamentStage(currentTournament.id)(
          accessToken,
          currentTournamentStage,
          {
            'follow-redirect': 10,
            maxBodyLength: 5e8,
            maxContentLength: 5e8,
          }
        )
        .then(response => response.data)
        .then(stageID => {
          commit('swapStageID', {
            newID: stageID,
          });
          return stageID;
        })
        .then(stageID => {
          return dispatch('createTournamentBlock', { stageID }).then(
            () => stageID
          );
        });
    },
    updateTournamentStage({
      commit,
      getters: { accessToken, currentTournament, currentTournamentStage },
    }) {
      const tournamentID = currentTournament.id;
      const stageID = currentTournamentStage.id;
      const stageModel = cloneDeep(currentTournamentStage);
      if (stageModel.map_of_venue) {
        stageModel.map_of_venue = stageModel.map_of_venue
          .filter(
            item => item.token || item.file_id || item.image || item.display_uri
          )
          .map(item => ({
            token: item.file_id,
            image: item.display_uri,
            type: 'image',
          }));
      }
      return api.tournaments
        .updateTournamentStage(tournamentID, stageID)(accessToken, stageModel, {
          'follow-redirect': 10,
          maxBodyLength: 5e8,
          maxContentLength: 5e8,
        })
        .then(() => {
          commit('updateCurrentTournamentStageByID', {
            ...currentTournamentStage,
            id: stageID,
          });
          return stageID;
        });
    },
    deleteTournamentStage(
      {
        commit,
        getters: { accessToken, currentTournament },
      },
      stageID
    ) {
      return api.tournaments
        .deleteTournamentStage(currentTournament.id, stageID)(accessToken)
        .then(() => {
          commit('deleteTournamentStageByID', stageID);
        });
    },
    startStage(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID }
    ) {
      return api.tournaments
        .startStage(tournamentID, stageID)(accessToken)
        .then(() => stageID);
    },
    publishStage(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID }
    ) {
      return api.tournaments
        .publishStage(tournamentID, stageID)(accessToken)
        .then(() => stageID);
    },
    unPublishStage(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID }
    ) {
      return api.tournaments
        .unPublishStage(tournamentID, stageID)(accessToken)
        .then(() => stageID);
    },
    fetchStagePlayers(
      {
        getters: { accessToken },
      },
      { stageID, tournamentID, query: params }
    ) {
      const endpoint =
        params.approved_to === 'default'
          ? 'getStagePlayersAllAppliciants'
          : 'getTournamentStagePlayers';
      //hotfix TH-6888
      params.approved_to =
        params.approved_to === 'default' ? null : params.approved_to;
      return api.tournaments[endpoint](tournamentID, stageID)(accessToken, {
        params,
      }).then(response => {
        let data = cloneDeep(response.data);
        let displayed_fields = [];
        let type = data.is_clan ? 'Clan Name' : 'Username';
        if (data.items && Array.isArray(data.items)) {
          data.items = mapPlayersItems(data.items);
          if (data.items[0]) {
            for (let key in data.items[0].fields_data) {
              if (data.items[0].fields_data.hasOwnProperty(key)) {
                const keyText = upperFirst(key);
                if (keyText === 'User') {
                  displayed_fields.push(type);
                } else {
                  displayed_fields.push(key.replace(/([A-Z])/g, ' $1'));
                }
              }
            }
          }
        }
        data.column_list = displayed_fields;
        return data;
      });
    },
    fetchStageSummaryData(
      {
        commit,
        getters: { accessToken },
      },
      { stageID, tournamentID }
    ) {
      return api.tournaments
        .getStageSummary(tournamentID, stageID)(accessToken)
        .then(response => {
          commit('updateCurrentTournamentStageByID', {
            id: stageID,
            disputesCount: Object.keys(response.data.blocks).reduce(
              (acc, blockID) =>
                acc + response.data.blocks[blockID].judgingCount,
              0
            ),
            activeMatches: Object.keys(response.data.blocks).reduce(
              (acc, blockID) => [
                ...acc,
                ...(response.data.blocks[blockID].activeMatches || []),
              ],
              []
            ),
          });
          return response.data;
        });
    },
    setStagePlayersExtendedData(
      {
        getters: { accessToken, currentTournamentID },
      },
      payload
    ) {
      let { stageID, players, filters, isTotalSelected, approvedTo } = payload;

      players = Array.isArray(players) ? players : [players];
      const data = players.map(item => ({
        approved_to: item.fields_data.approvedTo,
        attribute_ids: item.fields_data.attributes,
        block_index: item.fields_data.blockIndex,
        player_id: item.id,
      }));
      const apply_all_filters = {
        search_term: filters.search_term,
        attributes_filter: filters.attributes_filter,
        approved_to: approvedTo ? [approvedTo] : null,
      };

      return api.tournaments
        .setStagePlayersExtendedData(currentTournamentID, stageID)(
          accessToken,
          {
            items: data,
            apply_all_model: isTotalSelected ? data[0] : null,
            apply_all_filters,
          }
        )
        .then(response => response.data);
    },
    startStageCheckIn(
      {
        getters: { accessToken },
      },
      { stageID, tournamentID }
    ) {
      return api.tournaments
        .forceStartCheckIn(tournamentID, stageID)(accessToken)
        .then(() => stageID);
    },
    fetchStageColumnsSettings(
      {
        getters: { accessToken },
        commit,
      },
      { id: tournamentID, stageID }
    ) {
      return api.tournaments
        .getStagePlayersColumnsSettings(tournamentID, stageID)(accessToken)
        .then(response => {
          commit('setStagePlayersTableSettings', response.data);
          return response.data;
        });
    },
    fetchStageColumnsSettingsAllApplicants(
      {
        getters: { accessToken },
        commit,
      },
      { id: tournamentID, stageID }
    ) {
      return api.tournaments
        .getStagePlayersColumnsSettingsAllApplicants(tournamentID, stageID)(
          accessToken
        )
        .then(response => {
          commit('setStagePlayersTableSettings', response.data);
          return response.data;
        });
    },
    /**
     * Set stage columns settings order/column name
     * @param accessToken
     * @param {Object} payload
     * @returns {Promise} Promise object of query
     */
    setStageColumnsSettings(
      {
        getters: { accessToken },
      },
      { id: tournamentID, stageID, columns }
    ) {
      return api.tournaments
        .setStagePlayersColumnsSettings(tournamentID, stageID)(
          accessToken,
          columns
        )
        .then(response => response.data);
    },
    setStageAllAppliciantsStatuses(
      {
        getters: { accessToken, currentTournamentID },
      },
      payload
    ) {
      let { stageID, players } = payload;
      let players_ids = [];
      let approved_to = '';
      players = Array.isArray(players) ? players : [players];
      players.forEach(item => {
        players_ids.push(item.id);
        approved_to = item.fields_data.approvedTo;
      });
      const data = {
        players_ids,
        approved_to,
      };
      return api.tournaments
        .setStagePlayerParamsAllAppliciants(currentTournamentID, stageID)(
          accessToken,
          data
        )
        .then(response => response.data);
    },
  },
};
