<template>
  <div
    ref="tournamentWrapper"
    v-observe-visibility="{
      callback: isVisible =>
        !forOrganizer
          ? handleScrollIntoViewForViewsTracking(
              isVisible,
              tournament.id,
              'tournament'
            )
          : $lodash.noop(),
      intersection: {
        threshold: 0.1,
      },
    }"
    class="position-relative tournament-card-wrapper"
    :class="{
      'tournament-card-wrapper--for-player': !forOrganizer,
    }"
  >
    <component
      :is="linkComponent"
      v-bind="{
        id: tournament.id,
        vanityId: tournament.vanityId || tournament.vanity_id,
        ...(forOrganizer
          ? {
              to: {
                name: 'TournamentDashboard',
                params: { tournamentID: tournament.id },
              },
            }
          : null),
      }"
      class="d-block position-relative overflow-hidden tournament-card"
    >
      <Panel
        :variant="['rounded-medium']"
        class="ph-panel--input-bg position-relative p-4 h-100"
      >
        <TextBlock
          tag="h4"
          class="text-truncate mx-5 mb-4 text-center"
          :size="1.125"
          :line-height="1.4"
          :text="tournament.title"
        />
        <div
          v-if="prizeLabel"
          class="d-flex position-relative align-items-center justify-content-end"
        >
          <Badge
            class="inverse p-3 position-absolute tournament-card_prize-pool"
            :text="prizeLabel"
          />
        </div>
        <div
          class="tournament-card_img"
          :style="{ backgroundImage: coverURI }"
        ></div>
        <div
          class="d-flex align-items-center justify-content-end position-relative mb-5"
        >
          <StatusLabel
            class=""
            :status="tournament.status"
            :is-admin="forOrganizer"
            for-tournament
          />
        </div>
        <!-- <TimeLabel
          v-bind="{
            tournament,
          }"
          class="justify-content-center mb-3"
        /> -->
        <div
          class="d-flex align-items-center"
          :class="{ 'justify-content-between': forOrganizer }"
        >
          <GameLabel
            class=""
            v-bind="{
              tournament,
              variant: 'large',
              platformLogoSize: 24,
              gameLogoSize: 24,
            }"
          />
        </div>
        <div
          v-if="!forOrganizer"
          class="row mt-3 pt-2 align-items-center player-context-wrapper"
        >
          <!-- <div
            class="col-auto"
            :class="{
              'mx-auto': hasCTAAction,
              'h-ml-auto': !hasCTAAction,
            }"
          > -->
          <TournamentActions
            class="col-auto"
            :tournament="tournament"
            hide-additional-menu
            hide-brackets-button
            team-join-via-modal
            show-status
            @click.stop.prevent
          />
          <!-- </div> -->
        </div>
        <div class="row mt-3 pt-2">
          <template v-if="forOrganizer">
            <PerformanceIndicator
              :text="kpiWidget.label"
              :current="kpiWidget.current"
              :limit="kpiWidget.max"
              class="col-auto"
            />
          </template>
          <div
            v-else-if="tournament.is_event_organizer_available"
            class="col-auto"
          >
            <ListItemAccount
              :type="organizationType"
              :avatar="tournament.event_organizer_logo"
              :name="tournament.event_organizer_name"
              :vanity-id="tournament.event_organizer_vanity_id"
              :account-id="tournament.owner"
              :size="36"
            />
          </div>
          <div v-if="sponsors && sponsors.length" class="col">
            <TournamentSponsors
              :sponsors="sponsors"
              :height="34"
              class="justify-content-end"
            />
            <!-- <div
              class="d-flex w-100 align-items-center justify-content-end flex-nowrap overflow-hidden tournament-card_sponsors-wrap"
            >
              <span
                v-for="(logo, index) in tournament.sponsor_logo"
                :key="index"
                class="h-mr-3 tournament-card_partner-logo d-flex align-items-center w-auto"
              >
                <img :src="logo" />
              </span>
            </div> -->
          </div>
        </div>
      </Panel>
    </component>
    <span
      v-if="forOrganizer"
      class="position-absolute pt-4 pb-3 h-pr-3 h-mr-2 h-pl-3 tournament-card_menu"
    >
      <Icon
        role="button"
        :fill="$scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        name="dots"
        class=" cursor-pointer"
        :class="{ disabled: isMenuDisabled(tournament) }"
        @click.stop="openPopoverMenu($event, tournament)"
      />
    </span>
    <PopoverMenu
      :closest="$refs.tournamentWrapper"
      :position="optionsPosition"
      :items="popoverItems"
      :float="['top', 'right']"
      :auto-close="true"
      @toggle="onMenuToggle"
    >
      <template v-slot="{ item }">
        <TextBlock
          :variant="item.disabled ? 'muted' : 'primary'"
          :text="item.label"
          :class="{ disabled: item.disabled }"
          class="py-2 my-1"
          @click.native="menuHandler(item)"
        />
      </template>
    </PopoverMenu>
  </div>
</template>

<script>
import { get } from '@/utils/lodashUtils';
import { mapActions, mapGetters } from 'vuex';
import { ORGANIZATION } from '@/constants/accounts';
import { translate } from '@/mixins/translate';
import viewsTrackingMixin from '@/mixins/metrics';
import {
  TOURNAMENT_STATUSES,
  ARCHIVABLE_STATUSES,
  REJECTABLE_STATUSES,
  // PARTICIPANT_STATUSES,
} from '@/constants/tournaments';
import { DEFAULT } from '@/constants/common';
import TextBlock from '@/components/atoms/TextBlock';
import StatusLabel from '@/components/molecules/StatusLabel';
import ListItemAccount from '@/components/common/ListItemAccount';
import TextLabel from '@/components/atoms/TextLabel';
import LinkTournament from '@/components/links/Tournament';
import Panel from '@/components/atoms/Panel';
import GameLabel from './GameLabel';
import PerformanceIndicator from '@/views/tournaments/management/summaryWidgets/PerformanceIndicator';
import PopoverMenu from '@/components/popover/Menu';
import Icon from '@/components/atoms/Icon';
// import TimeLabel from './TimeLabel';
import Badge from '@/components/atoms/HeraBadge';
import TournamentActions from '@/views/account/header/statusBar/default/actions/Tournament.vue';
import TournamentSponsors from '@/views/tournaments/management/singleTournament/components/SponsorsListPreview';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
export default {
  components: {
    LinkTournament,
    TextBlock,
    GameLabel,
    StatusLabel,
    TextLabel,
    Panel,
    ListItemAccount,
    PerformanceIndicator,
    PopoverMenu,
    Icon,
    // TimeLabel,
    Badge,
    TournamentActions,
    TournamentSponsors,
  },
  mixins: [translate('_web_tournament'), tournamentHelpers, viewsTrackingMixin],
  props: {
    tournament: {
      type: Object,
      required: true,
    },
    forOrganizer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      organizationType: ORGANIZATION,
      popoverItems: null,
      optionsPosition: null,
    };
  },
  computed: {
    ...mapGetters(['isTournamentPastStatus', 'currentCountry']),
    linkComponent() {
      return this.forOrganizer ? 'router-link' : LinkTournament;
    },
    kpiWidget() {
      return this.isTournamentPastStatus(
        TOURNAMENT_STATUSES.STARTED,
        this.tournament
      )
        ? {
            label: this.$t(
              '_web_tournament_kpi_participants-label',
              'Participants'
            ),
            current: this.tournament.squads_joined,
            max: this.tournament.squads,
          }
        : this.isTournamentPastStatus(
            TOURNAMENT_STATUSES.REG_CLOSED,
            this.tournament
          )
        ? {
            label: this.$t('_web_tournament_kpi_qualifiers-label', 'Qualified'),
            current: this.tournament.squads_joined,
            max: this.tournament.squads,
          }
        : {
            label: this.$t(
              '_web_tournament_kpi_registrants-label',
              'Registered'
            ),
            current: this.tournament.squads_pending,
            max: this.tournament.squads,
          };
    },
    prizeLabel() {
      const prize = get(this, 'tournament.prize.prize_pool');
      const label = this.$t(
        '_web_tournament-card-prize-pool-label',
        'Prize pool:'
      );
      return prize ? `${label} ${prize}` : '';
    },

    isClanTournament() {
      return this.tournament.player_type === 'clan';
    },
    sponsors() {
      return (
        this.tournament.sponsor_logo[this.currentCountry] ||
        this.tournament.sponsor_logo[DEFAULT]
      );
    },
    players() {
      return this.tournament.players_quantity || this.tournament.squads;
    },

    squadSize() {
      return this.tournament.squad_size;
    },
    coverURI() {
      return `url(${encodeURI(
        (this.tournament.cover || '').replace('.original.', `.500.`)
      )})`;
    },
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),
    isMenuDisabled(tournament) {
      return tournament.status === TOURNAMENT_STATUSES.ARCHIVED;
    },
    openPopoverMenu(ev, tournament) {
      if (this.isMenuDisabled(tournament)) {
        return;
      }
      const generateHandler = (func, newStatus, confirmText) => () =>
        this.openModal({
          name: 'HeraConfirm',
          props: {
            text: confirmText,
          },
          events: {
            cancel: this.closeModal,
            confirm: () => {
              func(tournament.id).then(() => {
                const index = this.tournaments.findIndex(
                  t => t.id === tournament.id
                );
                if (index !== -1) {
                  this.$set(this.tournaments[index], `status`, newStatus);
                }
              });
              this.closeModal();
            },
          },
        });
      this.optionsPosition = ev.target;
      this.tournamentId = tournament.id;
      this.popoverItems = [
        {
          label: this.$t('_web_tournaments_menu-reject', 'Unpublish'),
          handler: generateHandler(
            this.onTournamentReject,
            TOURNAMENT_STATUSES.REJECTED,
            this.$t(
              '_web_tournaments_menu-reject_confirm',
              'Do you really want to cancel a tournament?'
            )
          ),
          disabled: !REJECTABLE_STATUSES.includes(tournament.status),
        },
        {
          label: this.$t('_web_tournaments_menu-archive', 'Archive'),
          handler: generateHandler(
            this.onTournamentArchive,
            TOURNAMENT_STATUSES.ARCHIVED,
            this.$t(
              '_web_tournaments_menu-archive_confirm',
              'Do you really want to archive a tournament?'
            )
          ),
          disabled: !ARCHIVABLE_STATUSES.includes(tournament.status),
        },
        {
          label: this.$t('_web_tournaments_menu-restart', 'Restart'),
          handler: generateHandler(
            this.onTournamentRestart,
            TOURNAMENT_STATUSES.DRAFT,
            this.$t(
              '_web_tournaments_menu-restart_confirm',
              'Do you really want to restart a tournament?'
            )
          ),
          disabled: TOURNAMENT_STATUSES.REJECTED !== tournament.status,
        },
      ];
    },
    onMenuToggle(visible) {
      if (!visible) {
        this.popoverItems = null;
        this.optionsPosition = null;
        this.tournamentId = null;
      }
    },
    menuHandler(item) {
      if (item.disabled) {
        return;
      }
      item.handler();
    },
  },
};
</script>

<style lang="scss">
.tournament-card-wrapper {
  .tournament-card {
    &:hover {
      text-decoration: none;
    }
    .player-context-wrapper {
      height: 42px;
    }
    &_menu {
      top: 0;
      @include auto-rtl(right, 0);
    }
    &_sponsors-wrap {
      height: auto;
      max-height: 1.5rem;
      width: 100%;
    }
    &_partner-logo {
      position: relative;
      width: 100%;
      max-width: 40px;
      height: auto;
      max-height: 2.5rem;
      //@include auto-rtl(margin-right, 0.75rem);
      text-align: center;
      display: block;
      img {
        display: block;
        max-height: 2.5rem;
        height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
    &_img {
      // width: 100%;
      height: 121px;
      background-position: center center;
      background-size: cover;
      margin-left: -12px;
      margin-right: -12px;
    }
    &_status_label {
      min-width: 50%;
      max-width: 100%;
      @include auto-rtl(border-top-right-radius, 0);
      @include auto-rtl(border-bottom-right-radius, 0);
      @include auto-rtl(margin-right, -12px);
    }
    &_prize-pool {
      text-transform: uppercase;
      transform: translateY(50%);
      @include auto-rtl(border-top-right-radius, 0);
      @include auto-rtl(border-bottom-right-radius, 0);
      @include auto-rtl(margin-right, -12px);
    }

    &:hover {
      color: $ph-primary-text;
    }

    color: $ph-primary-text;
    a,
    a:hover {
      color: $ph-primary-text;
    }
  }
  &--for-player {
    .tournament-card {
      height: 326px;
    }
  }
}
</style>
