import api from '@/api';

export const INITIAL_ORGANIZATION_STATE = () => {
  return {};
};

const getters = {};

const mutations = {};

const actions = {
  getOrganizationAdminsList({
    getters: { accessToken },
    rootState: {
      accounts: {
        my: { accountId },
      },
    },
  }) {
    return api.organizations
      .getOrganizationMembers(accountId)(accessToken)()
      .then(response => response.data);
  },
  setOrganizationMember(
    {
      getters: { accessToken },
      rootState: {
        accounts: {
          my: { accountId },
        },
      },
    },
    login
  ) {
    return api.organizations
      .setOrganizationMember(accessToken, {
        organizationId: accountId,
        memberId: login,
      })
      .then(response => response.data);
  },
  removeOrganizationMember(
    {
      getters: { accessToken },
      rootState: {
        accounts: {
          my: { accountId },
        },
      },
    },
    login
  ) {
    return api.organizations
      .deleteOrganizationMember(accountId, login)(accessToken)
      .then(response => response.data);
  },
};

export default {
  state: INITIAL_ORGANIZATION_STATE(),
  getters,
  mutations,
  actions,
};
