<template>
  <div class="d-flex justify-content-center">
    <div
      v-if="this.$route.query.purchase_success === 'False' && !toggleButton"
      class="toast-error"
    >
      {{ $t('_web_payment_failed', 'Payment failed') }}
      <button @click="() => (toggleButton = true)">
        <img src="@/assets/images/error-button-x.svg" alt="close error" />
      </button>
    </div>
    <div
      v-if="this.$route.query.purchase_success === 'True' && !toggleButton"
      class="toast-sucess"
    >
      {{ $t('_web_shop_order_sucess_message', 'Payment successfull.') }}
      <button @click="() => (toggleButton = true)">
        <img src="@/assets/images/error-button-x.svg" alt="close error" />
      </button>
    </div>
    <div class="shop-layout-wrap-hr">
      <aside class="aside-shop-wrapper">
        <div class="aside-search-filter">
          <ShopSearch />
          <img
            class="filter-icon"
            src="@/assets/images/icons/filter-list.svg"
            alt="Filter icon"
            @click="isToggleActive = !isToggleActive"
          />
        </div>
        <div v-if="categories.length === 0" class="shop-loader-sidebar-content">
          <Spinner class="categories-loader-shop" :size="'small'" />
        </div>
        <ShopCategories
          v-if="categories.length > 0"
          :collapsed="isToggleActive"
          :items="categories"
          :handle-open="handleMobileCats"
        />
      </aside>
      <div class="content-wrapper-shop-hr">
        <div
          v-if="paymentStatus === 0 && !toggleButton && $route.query.callback"
          class="toast-sucess"
        >
          {{ $t('_web_shop_order_sucess_message', 'Payment successfull.') }}
          <button @click="() => (toggleButton = true)">
            <img src="@/assets/images/error-button-x.svg" alt="close error" />
          </button>
        </div>
        <div
          v-if="
            paymentStatus === 1
              ? paymentStatus
              : '' && !toggleButton && this.$route.query.callback
          "
          class="toast-error"
        >
          {{ $t('_web_payment_failed', 'Payment failed') }}
          <button @click="() => (toggleButton = true)">
            <img src="@/assets/images/error-button-x.svg" alt="close error" />
          </button>
        </div>
        <div
          v-if="
            paymentStatus === 2
              ? paymentStatus
              : '' && !toggleButton && this.$route.query.callback
          "
          class="toast-processing"
        >
          {{
            $t(
              '_web_payment_processing',
              'Payment is being processed, please check your email'
            )
          }}
          <button @click="() => (toggleButton = true)">
            <img src="@/assets/images/error-button-x.svg" alt="close error" />
          </button>
        </div>
        <h1
          v-if="selectedCategory && selectedCategory.name"
          class="d-flex mb-5"
        >
          {{ selectedCategory.name }}
        </h1>
        <div v-else-if="shopSearch" class="search-query-shop">
          {{ $t('_web_shop_search_query', 'Search result for:') }}
          <span class="input-result-shop">{{ shopSearch }}</span>
        </div>
        <div v-if="isLoading" class="shop-loader-main-content">
          <Spinner />
        </div>
        <Fallback v-else-if="paginatedCardData.length === 0" />
        <div
          class="shop-card-container"
          :style="paymentStatus ? 'margin-top:40px' : ''"
        >
          <ShopCard
            v-for="(card, index) in paginatedCardData"
            :key="index"
            :card="card"
            :cover-image="card.image_url"
            :title="card.name"
            :product="card.id"
          />
        </div>
        <Paginated
          v-if="paginatedCardData.length > 0"
          :total-items="totalItems"
          :page-size="12"
          :in-container="true"
          class="h-100 w-auto"
          @change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debounce } from 'lodash';
//components
import ShopCategories from './ShopCategories';
import ShopCard from './ShopCard.vue';
import Fallback from './Fallback.vue';
import ShopSearch from './ShopSearch.vue';
import Paginated from '@/components/common/Paginated';
import Spinner from '@/views/main/components/Spinner.vue';
import { prop } from '@/utils/factories';

export default {
  name: 'Shop',
  components: {
    ShopCategories,
    ShopSearch,
    ShopCard,
    Paginated,
    Fallback,
    Spinner,
  },
  props: {
    paymentStatus: prop(Number),
  },

  data() {
    return {
      cardData: [],
      isToggleActive: false,
      currentPage: 0,
      totalItems: 0,
      isLoading: true,
      sucessPayment: '',
      toggleButton: false,
    };
  },

  computed: {
    ...mapGetters(['selectedCategory', 'categories', 'shopSearch', 'isRTL']),
    paginatedCardData() {
      if (this.cardData > 12) {
        const startIndex = this.currentPage * 12;
        const endIndex = startIndex + 12;
        return this.cardData
          .slice(startIndex, endIndex)
          .filter(item => item.min_price !== 0);
      } else {
        return this.cardData.filter(item => item.min_price !== 0);
      }
    },
    paymentStatusComputed() {
      if (this.$route.query.callback) {
        this.sucessPayment = this.$t(
          '_web_shop_order_sucess_message',
          'Payment successfull.'
        );
        window.dataLayer.push({
          event: 'shop purchase success',
        });
        setTimeout(() => {
          this.toggleButton = true;
        }, [20000]);
      }
    },
  },

  watch: {
    currentPage() {
      window.scrollTo(0, 0);
      this.getProducts();
    },
    shopSearch() {
      this.debouncedGetProducts();
      this.currentPage = 0;
    },
    selectedCategory() {
      this.handlePageChange(0);
      this.getProducts();
    },
    isRTL(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchCategories({ lang: this.isRTL ? 'ar' : 'en' });
        this.fetchCategoriesNew({ lang: this.isRTL ? 'ar' : 'en' }).then(
          res => {
            const selectedCategory = res.data.items.filter(
              item => item.id === this.selectedCategory.id
            );
            if (selectedCategory) {
              this.setSelectedCategory(selectedCategory[0]);
            }
          }
        );
        this.getProducts();
      }
    },
  },
  created() {
    if (!this.categories.length) {
      this.fetchCategories({ lang: this.isRTL ? 'ar' : 'en' });
    }
  },
  mounted() {
    this.getProducts();
    console.log('aaaaaaaaaaaaaaaa', this.paymentStatus);
  },

  methods: {
    ...mapActions(['fetchCategories', 'fetchProducts', 'fetchCategoriesNew']),
    ...mapMutations(['setSelectedCategory']),

    handleMobileCats(bool) {
      this.isToggleActive = bool;
    },

    getProducts() {
      this.fetchProducts({
        page_size: 12,
        page_number: this.currentPage,
        category:
          this.selectedCategory && this.selectedCategory.id
            ? this.selectedCategory.id
            : 425,
        // 425 is google play categori
        search: this.shopSearch,
        lang: this.isRTL ? 'ar' : 'en',
      }).then(
        res => (
          (this.cardData = [...res.items]),
          (this.totalItems = res.total),
          (this.isLoading = false)
        )
      );
    },
    debouncedGetProducts: debounce(function() {
      this.getProducts();
    }, 500),
    handlePageChange(pageIndex) {
      this.currentPage = pageIndex;
      this.$emit('change', pageIndex);
    },
  },
};
</script>
<style lang="scss">
.aside-shop-wrapper {
  width: 100%;
  max-width: 270px;
}

.search-query-shop {
  margin-bottom: 2.45rem;
  font-size: 14px;

  .input-result-shop {
    @include auto-rtl(margin-left, 1rem);
    color: #9d9cf7;
  }
}

.shop-card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 1fr));
  // padding-right: 20%;
  grid-gap: 40px 16px;
}

.content-wrapper-shop-hr {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 1100px) {
    width: 100%;
  }
}

.filter-icon {
  display: none;
}

.aside-search-filter {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 850px) {
  .shop-layout-wrap-hr {
    flex-direction: column;
    // padding-left: 1.5rem !important;
    // padding-right: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .search-input {
    max-width: 100% !important;
  }

  .aside-shop-wrapper {
    max-width: 100%;
  }

  .shop-card-container {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .filter-icon {
    display: flex;
    height: 100%;
    widows: 100%;
    @include auto-rtl(padding, 10px 0px 0px 10px);
    // padding: 10px 0px 0px 10px;
    cursor: pointer;
  }
}

.shop-layout-wrap-hr {
  position: relative;
  display: flex;
  gap: 3rem;
  padding-top: 3rem;
  justify-content: space-between;

  @media (max-width: 600px) {
    gap: 1rem;
  }
}

.banner-shop {
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 90px;
  margin-top: 3rem;
  height: auto;

  @media (max-width: 850px) {
    width: 100%;
  }
}

.subtitle-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 600px;
  text-align: center;
  margin-bottom: 90px;
  font-size: 2rem;

  @media (max-width: 850px) {
    width: 60%;
    font-size: 1.8em;
  }
}

.shop-loader-main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.shop-loader-sidebar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  max-height: 550px;
}
</style>
<style lang="scss" scoped>
.toast-error {
  z-index: 100;
  display: flex;
  width: 478px;
  max-width: 100%;
  min-height: 38px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  border-radius: 8px;
  background: var(--foreground-critical, #eb6666);
  /* Elevated */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.07),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--black-white-white, #fff);
    border: none;
    /* Base */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 850px) {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    width: 93%;
  }
}

.toast-sucess {
  z-index: 100;
  display: flex;
  width: 478px;
  max-width: 100%;
  min-height: 38px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  border-radius: 8px;
  background: rgb(75, 181, 67);
  color: white;
  border-radius: 8px;
  /* Elevated */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.07),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--black-white-white, #fff);
    border: none;
    /* Base */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 850px) {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    width: 93%;
  }
}

.toast-processing {
  z-index: 100;
  display: flex;
  width: 478px;
  max-width: 100%;
  min-height: 38px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  border-radius: 8px;
  background: rgb(255, 230, 0);
  color: black;
  border-radius: 8px;
  /* Elevated */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.07),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--black-white-white, #fff);
    border: none;
    /* Base */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 850px) {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    width: 93%;
  }
}
</style>
