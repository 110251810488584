<template>
  <Panel
    class="tournament-info_platform pb-0"
    :variant="['transparent', 'rounded-pre-medium']"
  >
    <Subheader
      class="mb-4 text-uppercase font-weight-normal"
      :text="$t('_web_tournament-info_platform', 'Platforms')"
      :text-block-props="{ size: 0.875, lineHeight: 1.5 }"
      variant="hr-secondary"
    />
    <InfoItem :value="platforms" />
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoItem from './infoItem';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
export default {
  name: 'Platform',
  components: {
    Panel,
    InfoItem,
    Subheader,
  },
  computed: {
    ...mapGetters(['currentTournament', 'games', 'getPlatformPropById']),
    platforms({ currentTournament: { platforms } }) {
      return (
        (platforms || [])
          .map(id => this.getPlatformPropById(id, 'name'))
          .join(', ') || '\u00A0'
      );
    },
  },
};
</script>

<style lang="scss"></style>
