var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clan-invitation"},[_c('div',{staticClass:"d-flex justify-content-between my-5 px-5 align-items-center"},[_c('Subheader',{staticClass:"secondary-font my-0",attrs:{"text":_vm.toExistingClan ? '' : _vm.$t('_web_create-team-link', 'Create team')}}),(!_vm.toExistingClan)?_c('div',{class:'navigation'},[_vm._v("\n      "+_vm._s(_vm.toExistingClan
          ? _vm.$$t('Finish')
          : _vm.$t('_web_create-clan_create', 'Create') + ' 2/2')+"\n    ")]):_vm._e()],1),_c('Panel',{staticClass:"clan-invitation-body",attrs:{"variant":['transparent']}},[_c('div',{staticClass:"row d-flex justify-content-between align-items-center"},[_c('Subheader',{staticClass:"col-12 col-lg-8",attrs:{"text":_vm.$$t('Invite people to your team', 'title-team')}}),_c('HeraSearchInput',{staticClass:"search-field col-12 col-lg-4 mb-5",attrs:{"debounce":700,"has-icon":""},on:{"input":_vm.searchPlayers},model:{value:(_vm.userFilter),callback:function ($$v) {_vm.userFilter=$$v},expression:"userFilter"}})],1),_c('ListPreloader',{attrs:{"is-loading":_vm.isPreloading,"is-crashed":_vm.isCrashed,"is-empty-list":_vm.usersList && !_vm.usersList.length,"empty-list-label":_vm.$t('_web_players_dummy-list', 'We couldn\'t find anything.'),"empty-list-icon":"dummy/friends"},on:{"reload":_vm.fetchData}},[_c('template',{slot:"list"},[_c('Paginated',_vm._b({staticClass:"mb-0 h-auto",on:{"change":_vm.fetchData}},'Paginated',{
            totalItems: _vm.totalItems,
            hidePagination: !_vm.usersList || !_vm.usersList.length,
            pageSize: _vm.pageSize,
            paginationProps: {
              isDark: true,
            },
            inContainer: true,
          },false),[_c('div',{staticClass:"col"},[_c('ul',{staticClass:"list-unstyled w-100"},_vm._l((_vm.usersList),function(user){return _c('MemberItem',_vm._b({key:user.login},'MemberItem',{ user: user },false))}),1)])])],1)],2)],1),_c('div',{staticClass:"create-clan-step-two mb-lg-0 mb-4"},[_c('HeraFormBtn',{staticClass:"btn-primary ",class:'create-team',attrs:{"label":_vm.toExistingClan
          ? _vm.$$t('Finish')
          : _vm.$t('_web_create-clan_create', 'Create'),"loading":_vm.inviteInProgress},on:{"click":function($event){$event.preventDefault();return _vm.nextRoute($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }