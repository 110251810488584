var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group",class:{ focused: _vm.isFocused }},[_c('SearchTextInput',_vm._b({staticClass:"w-100",on:{"blur":_vm.onBlur,"focus":_vm.onFocus},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)}},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}},'SearchTextInput',{
      type: 'search',
      disabled: _vm.disabled,
      id: _vm.inputID,
      icon: _vm.hasIcon ? 'search' : '',
      iconSize: _vm.size,
      class: _vm.classInput,
      placeholder: _vm.placeholderReplaced,
      readOnly: _vm.readOnly,
    },false),[(_vm.hasIcon)?_c('AppIcon',{class:_vm.classIcon,attrs:{"slot":"icon","name":"search","width":_vm.size,"fill":_vm.isFocused ? _vm.$scss.textColor : _vm.$scss.heraMuted},slot:"icon"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }