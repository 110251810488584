<template>
  <div class="d-flex flex-column slider-chat-pl">
    <HeraFormTextInput
      v-model="searchQuery"
      class="flex-shrink-1 mb-3"
      :label="label"
      :placeholder="$t('_web_tournament_sidechat_search', 'Search')"
    />

    <HeraFormBtn
      class="flex-shrink-1 m-1 mb-3 px-0 h-text-left btn-transparent"
      @click.native="addNewBtnHandler"
    >
      <Icon name="add" width="20" class="h-mr-4" />
      <TextBlock>
        {{
          $t(
            '_web_tournament_sidechat_add-new-participants',
            'Add new participants'
          )
        }}
      </TextBlock>
    </HeraFormBtn>
    <transition-group
      name="slider-chat-pl-transition"
      tag="div"
      class="h-100 px-1 custom-scrollbar"
    >
      <ListItemAccount
        v-for="item in participantsToRender"
        :key="item.id"
        class="mb-2"
        :account-id="item.id"
        :type="$routerConstants.PROFILE"
        :secondary-model="item"
      >
        <Icon
          v-if="item.id !== accountId"
          slot="action"
          class="cursor-pointer h-mr-1"
          name="delete"
          :width="18"
          @click="$emit('deleteParticipant', item)"
        />
      </ListItemAccount>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { prop, propRequired } from '@/utils/factories';
import HeraFormTextInput from '@/components/atoms/form/HeraFormTextInput';
import Icon from '@/components/atoms/Icon';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import TextBlock from '@/components/atoms/TextBlock';
import ListItemAccount from '@/components/common/ListItemAccount';

export default {
  components: {
    ListItemAccount,
    TextBlock,
    HeraFormBtn,
    HeraFormTextInput,
    Icon,
  },
  props: {
    label: prop(String),
    participants: propRequired(Array),
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState('my', ['accountId']),
    participantsToRender() {
      if (!this.searchQuery) {
        return this.participants;
      } else {
        return this.participants.filter(participant => {
          return (
            `${participant.id}${participant.name}${participant.display_name}`
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
          );
        });
      }
    },
  },
  methods: {
    addNewBtnHandler() {
      this.$emit('addNew');
    },
  },
};
</script>

<style lang="scss">
.slider-chat-pl {
  position: absolute;
  left: 0.75rem;
  top: 1.8rem;
  right: 0.75rem;
  bottom: 0.75rem;
}

.slider-chat-pl-transition {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
  &-enter-active,
  &-leave-active {
    transition: all 0.3s;
  }
  &-move {
    transition: transform 0.6s;
  }
}
</style>
