import { mapGetters, mapActions } from 'vuex';
export const shareQrCode = {
  data() {
    return {
      qrDataUrl: null,
      qrPayload: '',
      qrFileName: 'playhera-qr',
      saveQrAs: 'png',
    };
  },
  computed: {
    ...mapGetters(['qrOptions']),
    qrFullOptions() {
      return {
        ...this.qrOptions,
        text: this.qrPayload,
      };
    },
    saveQrAsVector() {
      return this.saveQrAs === 'svg';
    },
  },
  created() {
    this.fetchQrDefaultOptions();
  },
  methods: {
    ...mapActions(['fetchQrDefaultOptions']),
    downloadQrCode() {
      const link = document.createElement('a');
      link.download = `${this.qrFileName}.${this.saveQrAs}`;
      link.href = this.saveQrAsVector
        ? `data:image/svg+xml;base64,\n${btoa(this.qrDataUrl)}`
        : this.qrDataUrl;
      if (this.saveQrAsVector) {
        link['href-lang'] = 'image/svg+xml';
      }
      link.click();
      link.delete;
    },
    getQRSource(dataUrl) {
      this.qrDataUrl = dataUrl;
    },
  },
};

export default [shareQrCode];
