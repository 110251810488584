<template>
  <div class="performance-indicator d-flex">
    <div
      v-if="!noBar"
      class="performance-indicator-line position-relative flex-grow-0 h-mr-3"
      :style="{ '--height': `${indicatorHeight}%` }"
    />
    <div class="flex-grow-1 d-flex flex-column justify-content-between">
      <TextBlock :text="count" :size="$scss.fontSizes_md" />
      <TextLabel :text="text" variant="caption" />
    </div>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
import TextLabel from '@/components/atoms/TextLabel';

export default {
  name: 'PerformanceIndicator',
  components: {
    TextBlock,
    TextLabel,
  },
  props: {
    text: prop(String),
    current: prop([Number, String]),
    limit: prop(Number),
    currentOnly: prop(Boolean),
    noBar: prop(Boolean),
  },
  computed: {
    count() {
      return this.limit && !this.currentOnly
        ? `${this.current}/${this.limit}`
        : this.current;
    },
    indicatorHeight() {
      return Math.min((this.current / this.limit) * 100, 100);
    },
  },
};
</script>

<style lang="scss">
.performance-indicator {
  min-height: 2.5rem;
  &-line {
    overflow: hidden;
    background-color: $ph-secondary-text;
    border-radius: $ph-small-radius;
    &,
    &:before {
      width: 0.5rem;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: var(--height, 0);
      background-color: $ph-orange;
      transition: height 0.2s;
      content: '';
    }
  }
}
</style>
