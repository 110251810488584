<template>
  <TextBlock
    tag="a"
    :href="url"
    class="text-decoration-none post-link-preview-wrapper"
    target="_blank"
    rel="nofollow"
  >
    <Panel variant="transparent" class="p-0 mt-5 tesss">
      <!--<div class="row no-gutters mb-3">
        <Subheader v-if="title" class="col mb-0">{{ title }}</Subheader>
      </div>-->
      <div v-if="image" class="img-preview has-images mb-3">
        <div
          class="img-wrapper img-wrapper-1 position-relative"
          :class="{
            'img-wrapper--default': !imageUrlValid,
          }"
        >
          <AppIcon
            v-if="!imageUrlValid"
            name="picture"
            class="d-block text-center img--default"
            width="80"
            :fill="$scss.colors.secondaryTextBg"
          />
          <img
            v-else
            :src="image"
            :alt="description"
            @error="imageUrlValid = false"
          />
        </div>
      </div>
      <div class="body-graph-wrapper">
        <div class="url-wrapper">
          <span>{{ getDomain(url) }}</span>
        </div>
        <div class="row no-gutters">
          <Subheader v-if="title" class="title-link col">{{ title }}</Subheader>
        </div>
        <div class="d-flex align-items-start">
          <div v-if="description">
            <TextContent class="description-link" :content="description" />
          </div>
          <div v-if="editMode" class="flex-shrink-0 mx-2">
            <AppIcon
              name="delete"
              width="18"
              @click.prevent.stop="$emit('remove')"
            />
          </div>
        </div>
      </div>
    </Panel>
  </TextBlock>
</template>

<script>
import { prop } from '@/utils/factories';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
import TextBlock from '@/components/atoms/TextBlock';
import TextContent from '@/components/text/TextContent';
import AppIcon from '@/components/atoms/Icon';
export default {
  name: 'OpenGraphPreviewCard',
  components: {
    Panel,
    Subheader,
    TextBlock,
    TextContent,
    AppIcon,
  },
  props: {
    image: prop(String),
    title: prop(String),
    description: prop(String),
    url: prop(String),
    editMode: prop(Boolean),
  },
  data() {
    return {
      imageUrlValid: true,
    };
  },
  methods: {
    getDomain(url) {
      let domain = url.replace(/^(https?:\/\/)?(www\.)?/i, '');
      domain = domain.split('/')[0];
      return domain;
    },
  },
};
</script>

<style lang="scss">
.body-graph-wrapper {
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 17px;
}
.tesss {
  border: 1px solid #313139;
  border-radius: 5px;
}
.post-link-preview-wrapper {
  .img-preview {
    width: 100% !important;

    &.has-images {
      height: 280px !important;
    }

    .img-wrapper {
      &-1 {
        width: 100%;
        height: 100%;
      }

      &--default {
        background: $ph-input-bg;
        border-radius: $ph-medium-radius;
      }

      position: relative;

      img {
        object-fit: cover;
        width: 100% !important;
        height: 100%;
      }

      .img--default {
        position: relative;
        top: 100px;
        margin: 0px auto;
      }
    }
  }
}

.url-wrapper {
  margin-top: 27px;
  font-family: $hr-font-family;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  color: $hr-text-primary;
}

.description-link {
  font-family: $hr-font-family;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: $hr-text-link-graph;

  .text-block {
    &--primary {
      color: $hr-text-link-graph !important;
    }
  }
}

.title-link {
  font-family: $hr-font-family;
  font-weight: 700;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  margin-top: $hr-spacing-05;
}
</style>
