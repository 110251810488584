import api from '@/api';
import Vue from 'vue';
import { cloneDeep, isString } from '@/utils/lodashUtils';
import { getObjectDifference } from '@/mixins/helpers';

const mapLeagueObject = obj => {
  const league = {
    ...cloneDeep(obj),
    // logo: obj.logo.match(/^http/) ? undefined : obj.logo,
    // cover: obj.cover.match(/^http/) ? undefined : obj.cover,
    // portrait_cover: obj.portrait_cover.match(/^http/) ? undefined : obj.portrait_cover,
    // map_of_venue: obj.map_of_venue.filter(el => !isString(el)).map(el => el.base64),
    map_of_venue: Array.isArray(obj.map_of_venue_id)
      ? obj.map_of_venue_id.filter(el => el.length)
      : obj.map_of_venue.length
      ? obj.map_of_venue
      : undefined,
    map_of_venue_id: undefined,
    rules_link: isString(obj.rules_link)
      ? undefined
      : obj.rules_link
      ? obj.rules_link.file_id
      : undefined,
    sponsor_logo: Array.isArray(obj.sponsor_logo)
      ? obj.sponsor_logo.filter(el => el.length)
      : undefined,
    sponsors: undefined,
    powered_by: undefined,
  };
  return league;
};

export const DEFAULT_PRO_LEAGUE_STATE = () => ({
  currentProLeague: {},
  currentProLeagueBackup: {},
  imagesColSizes: {
    widths: [182, 134, 314], // in pixels from figma
    height: 178,
    padding: 20,
  },
});

// const dummy_leagues_list_response = {
//   "next_page_token": null,
//   "items": Array.from({length: 6 }, () => {
//     const competitionID = (Math.random() * 100000)^0
//     const seasonID = (Math.random() * 100000)^0
//     const leagueID = (Math.random() * 100000)^0
//     const start = (Date.now()/1000)^0
//     const end = ((Date.now() + 365 * 31 * 24 * 60 * 60 * 1000)/1000)^0
//     return {
//       "season": {
//         "id": seasonID,
//         "competition_id": `competition-${competitionID}`,
//         "name": `Season ${seasonID}`,
//         "status": "draft",
//         start,
//         end
//       },
//       "id": leagueID,
//       "title": "FIFA 19 SAFEIS All stars CUP",
//       "platforms": [
//         "XBOX",
//         "PSN"
//       ],
//       "game": "FIFA19",
//       "country_id": "UA",
//       "country": "Ukraine",
//       "logo": "https://is4-ssl.mzstatic.com/image/thumb/Purple118/v4/da/f2/6d/daf26d48-98aa-624e-b1fa-6bf11c7bd801/source/512x512bb.jpg",
//       "cover": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Alg%C3%A9rie_-_Arm%C3%A9nie_-_20140531_-_Equipe_d%27Alg%C3%A9rie.jpg/550px-Alg%C3%A9rie_-_Arm%C3%A9nie_-_20140531_-_Equipe_d%27Alg%C3%A9rie.jpg",
//       "portrait_cover": "https://static-cdn.jtvnw.net/ttv-boxart/FIFA%2019.jpg",
//       "sponsor_logo": [
//         "https://www.ces.tech/CES/media/logos-and-icons/CES-Logo-BW_696x401.png",
//         "https://assets.console-deals.com/images/nav/nintendoswitch/light-icon.svg",
//         "https://cdn.freebiesupply.com/logos/large/2x/visa-5-logo-black-and-white.png"
//       ],
//       "player_type": "clan",
//       "players_count": 14,
//       "squad_size": 2,
//       "powered_by": {
//         "id": "safeis",
//         "name": "SAFEIS",
//         "cover": "",
//         "logo": "https://www.safeis.sa/img/logo.png"
//       },
//       "prize": ""
//     }
//    }
//   )
// }

// const dummy_single_league = () => {
//     const competitionID = (Math.random() * 100000)^0
//     const seasonID = (Math.random() * 100000)^0
//     const leagueID = (Math.random() * 100000)^0
//     const start = (Date.now()/1000)^0
//     const end = ((Date.now() + 60 * 31 * 24 * 60 * 60 * 1000)/1000)^0
//     return {
//       "season": {
//         "id": seasonID,
//         "competition_id": `competition-${competitionID}`,
//         "name": `Season ${seasonID}`,
//         "status": "draft",
//         start,
//         end
//       },
//       "id": leagueID,
//       "title": "FIFA 19 SAFEIS All stars CUP",
//       "platforms": [
//         "XBOX",
//         "PSN"
//       ],
//       "game": "FIFA",
//       "country_id": "UA",
//       "country": "Ukraine",
//       "logo": "https://is4-ssl.mzstatic.com/image/thumb/Purple118/v4/da/f2/6d/daf26d48-98aa-624e-b1fa-6bf11c7bd801/source/512x512bb.jpg",
//       "cover": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Alg%C3%A9rie_-_Arm%C3%A9nie_-_20140531_-_Equipe_d%27Alg%C3%A9rie.jpg/550px-Alg%C3%A9rie_-_Arm%C3%A9nie_-_20140531_-_Equipe_d%27Alg%C3%A9rie.jpg",
//       "portrait_cover": "https://static-cdn.jtvnw.net/ttv-boxart/FIFA%2019.jpg",
//       "sponsor_logo": [
//         "https://www.ces.tech/CES/media/logos-and-icons/CES-Logo-BW_696x401.png",
//         "https://assets.console-deals.com/images/nav/nintendoswitch/light-icon.svg",
//         "https://cdn.freebiesupply.com/logos/large/2x/visa-5-logo-black-and-white.png"
//       ],
//       "player_type": "clan",
//       "players_count": 14,
//       "squad_size": 2,
//       "powered_by": {
//         "id": "safeis",
//         "name": "SAFEIS",
//         "cover": "",
//         "logo": "https://www.safeis.sa/img/logo.png"
//       },
//       "prize": "$ 175 000",
//       "description": "The Rangers needed a jolt for their first home game of their second-round series against the Ottawa Senators. Turning to Tanner Glass and his physical style, they may have found the catalyst. Glass was a decisive factor in the Rangers’ 4-1 win over the Senators in Game 3 on Tuesday. He hit nearly every Senator in sight without taking a penalty, and he helped set up a second-period goal by Oscar Lindberg that gave the Rangers a four-goal",
//       "game_mode": "Last Standing",
//       "city_id": "Dnipro",
//       "city": "Dnipro",
//       "location": 'Chino-shi, Nagano-ken 391-0211, Japan',
//       "map_of_venue": [
//         "http://www.agoracatania.eu/wp-content/uploads/2017/09/map.png",
//         "http://www.bostontri.com/wp-content/uploads/2018/07/2018-CTIBOSTRI-Map-Saturday2.png",
//         "http://www.johnabbott.qc.ca/wp-content/uploads/2018/01/Campus-Map_Web-bigger-180111.jpg"
//       ],
//       "rules_link": "http://esplswimming.org/uploads/6/3/2/4/63248261/2017_rules_for_competition-6may17.pdf",
//       "availability_type": "online",
//       "max_substitutes": 2,
//       "season_name": "string",
//       "season_start": 0,
//       "season_end": 0,
//       "prize_places": [
//         {
//           "place": 1,
//           "prize": "$ 100 000"
//         },
//         {
//           "place": 2,
//           "prize": "$ 50 000"
//         },
//         {
//           "place": 3,
//           "prize": "$ 25 000"
//         },
//       ],
//       "players_joined": 0,
//       "matches_count": 0,
//       "matches_finished": 0
//     }
//   }

const getters = {
  currentProLeague: state => state.currentProLeague,
  currentProLeagueBackup: state => state.currentProLeagueBackup,
  imagesColSizes: state => state.imagesColSizes,
};

const mutations = {
  setCurrentProLeague(state, payload) {
    state.currentProLeague = payload;
  },
  updateCurrentProLeague(state, payload) {
    for (let key in payload) {
      Vue.set(state.currentProLeague, key, payload[key]);
    }
  },
  clearCurrentProLeague(state) {
    state.currentProLeague = {};
  },
  backupCurrentProLeague(state) {
    state.currentProLeagueBackup = cloneDeep(state.currentProLeague);
  },
  clearCurrentProLeagueBackup(state) {
    state.currentProLeagueBackup = {};
  },
};

const actions = {
  getProLeagues: ({ getters: { accessToken } }, queryParams) =>
    api.proleagues
      .getList(accessToken, {
        params: { ...queryParams },
      })
      .then(response => response.data),
  // Promise
  //   .resolve(dummy_leagues_list_response),

  getProLeague: ({ getters: { accessToken }, commit }, leagueID) =>
    api.proleagues
      .getByID(leagueID)(accessToken)
      .then(response => {
        const league = response.data;
        commit('setCurrentProLeague', league);
        return league;
      }),

  editProLeague: ({
    getters: { accessToken, currentProLeague, currentProLeagueBackup },
    dispatch,
    commit,
  }) => {
    const league = mapLeagueObject(currentProLeague);
    const backup = mapLeagueObject(currentProLeagueBackup);
    let newLeague = getObjectDifference(league, backup);
    ['logo', 'portrait_cover', 'cover'].forEach(prop => {
      if (newLeague[`${prop}ID`]) {
        newLeague[prop] = newLeague[`${prop}ID`];
        delete newLeague[`${prop}ID`];
      }
    });
    return api.proleagues
      .edit(currentProLeague.id)(accessToken, newLeague)
      .then(() => dispatch('getProLeague', currentProLeague.id))
      .then(response => commit('updateCurrentProLeague', response));
  },

  createProLeague: () => {},

  addSeasonToProLeague: ({ getters: { accessToken, currentProLeague } }) =>
    api.proleagues.addSeason(currentProLeague.id)(accessToken),

  startProLeagueSeason: ({ getters: { accessToken, currentProLeague } }) =>
    api.proleagues.startSeason(currentProLeague.id)(accessToken),

  stopProLeagueSeason: ({ getters: { accessToken, currentProLeague } }) =>
    api.proleagues.stopSeason(currentProLeague.id)(accessToken),

  getProLeaguePlayers: ({ getters: { accessToken } }, payload) =>
    api.proleagues
      .getParticipants(payload.leagueID)(accessToken, {
        params: { ...payload.queryParams },
      })
      .then(response => response.data),

  addPraticipantToProLeague: ({ getters: { accessToken } }, payload) =>
    api.proleagues.addParticipant(payload.leagueID)(accessToken, {
      id: payload.participantID,
    }),

  removePraticipantFromProLeague: ({ getters: { accessToken } }, payload) =>
    api.proleagues.removeParticipant(payload.leagueID, payload.participantID)(
      accessToken
    ),
};

export default {
  state: DEFAULT_PRO_LEAGUE_STATE(),
  getters,
  mutations,
  actions,
};
