<template>
  <div class="clan-invitation">
    <!--fix render bug-->
    <div class="d-flex justify-content-between my-5 px-5 align-items-center">
      <Subheader
        class="secondary-font my-0"
        :text="toExistingClan ? '' : $t('_web_create-team-link', 'Create team')"
      />
      <div v-if="!toExistingClan" :class="'navigation'">
        {{
          toExistingClan
            ? $$t('Finish')
            : $t('_web_create-clan_create', 'Create') + ' 2/2'
        }}
      </div>
    </div>
    <Panel class="clan-invitation-body" :variant="['transparent']">
      <div class="row d-flex justify-content-between align-items-center">
        <Subheader
          class="col-12 col-lg-8"
          :text="$$t('Invite people to your team', 'title-team')"
        />
        <HeraSearchInput
          v-model="userFilter"
          class="search-field col-12 col-lg-4 mb-5"
          :debounce="700"
          has-icon
          @input="searchPlayers"
        />
      </div>
      <ListPreloader
        :is-loading="isPreloading"
        :is-crashed="isCrashed"
        :is-empty-list="usersList && !usersList.length"
        :empty-list-label="
          $t('_web_players_dummy-list', 'We couldn\'t find anything.')
        "
        empty-list-icon="dummy/friends"
        @reload="fetchData"
      >
        <template slot="list">
          <Paginated
            v-bind="{
              totalItems,
              hidePagination: !usersList || !usersList.length,
              pageSize,
              paginationProps: {
                isDark: true,
              },
              inContainer: true,
            }"
            class="mb-0 h-auto"
            @change="fetchData"
          >
            <div class="col">
              <ul class="list-unstyled w-100">
                <MemberItem
                  v-for="user in usersList"
                  :key="user.login"
                  v-bind="{ user }"
                />
              </ul>
            </div>
          </Paginated>
        </template>
      </ListPreloader>
    </Panel>
    <div class="create-clan-step-two mb-lg-0 mb-4">
      <HeraFormBtn
        class="btn-primary "
        :class="'create-team'"
        :label="
          toExistingClan
            ? $$t('Finish')
            : $t('_web_create-clan_create', 'Create')
        "
        :loading="inviteInProgress"
        @click.prevent="nextRoute"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import listMixin from '@/mixins/paginatedLists';
import { translate } from '@/mixins/translate';

import HeraSearchInput from '@/components/molecules/form/HeraSearchInput';
import ListPreloader from '@/components/common/HeraListPreloader';
import Subheader from '@/components/atoms/Subheader';
import Panel from '@/components/atoms/Panel';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import Paginated from '@/components/common/Paginated';
import MemberItem from './MemberItem';

export default {
  name: 'ClanModalInvite',
  components: {
    HeraFormBtn,
    Subheader,
    ListPreloader,
    HeraSearchInput,
    Paginated,
    MemberItem,
    Panel,
  },
  mixins: [...listMixin, translate('_web_clan-invite')],
  data() {
    return {
      inviteInProgress: false,
      isPreloading: true,
      usersList: [],
      invitedList: [],
      userFilter: '',
      allFriendsLoaded: false,
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId']),
    ...mapState('current', { clanAccount: 'account' }),
    clanId() {
      return this.clanAccount._id;
    },
    isFetchFriends() {
      return !this.userFilter && !this.allFriendsLoaded;
    },
    toExistingClan() {
      return this.$route.params.accountType && this.$route.params.accountId;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapMutations(['setOptions']),
    ...mapActions([
      'fetchPlayers',
      'fetchFriends',
      'errorNotify',
      'inviteToClan',
    ]),
    fetchData(newPage) {
      this.pageIndex = newPage;
      this.getPageData(
        'usersList',
        this.isFetchFriends
          ? this.fetchFriends({
              accountId: this.accountId,
              params: this.paramsWithPageNumber(),
            })
          : this.fetchPlayers(
              this.paramsWithPageNumber({
                contains: this.userFilter,
                subject_type: 'profile',
              })
            ),
        { uniqBy: 'login' }
      ).then(() => {
        if (this.isFetchFriends) {
          if (this.pageIndex * this.pageSize >= this.totalItems) {
            this.allFriendsLoaded = true;
            this.pageIndex = 0;
            this.fetchData();
          }
        }
        this.isPreloading = false;
      });
    },
    searchPlayers() {
      // this.nextPageToken = undefined;
      this.pageIndex = 0;
      this.isPreloading = true;
      this.usersList = null;
      this.allFriendsLoaded = false;
      this.fetchData();
    },
    nextRoute() {
      return this.$router.push({
        name: this.$routerNames.ACCOUNT,
        params: {
          accountId: this.clanId,
          accountType: this.$routerNames.CLAN,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.clan-invitation {
  &-body {
    .hover-text-danger {
      &:hover {
        color: $ph-error;
      }
    }
    .input-form {
      background-color: var(--ph-card);
    }
    .list-loader_wrapper {
      position: static;
      margin-top: $ph-huge-space;
    }
  }
  .btn {
    min-width: 9rem;
  }
}
.create-clan-step-two {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.navigation {
  font-size: 1rem;
}
.create-team {
  text-transform: uppercase !important;
}
</style>
