<template>
  <div>
    <MainLayout>
      <div class="refund-policy-mena">
        <div class="refundpolicy-container-mena">
          <div class="priv">
            <h1>سياسة رد الأموال</h1>
          </div>
          <div class="terms">
            <div class="title">
              <h2>الشروط والاحكام العامة</h2>
              <p>
                تخضع هذه الشروط والأحكام للقوانين والقرارات التي تنظم العمل
                التجاري بالمملكة العربية السعودية، كما تخضع لسلطة المحاكم
                السعودية في حال وجود أي نزاع قانوني
              </p>
              <p>
                بمجرد قيام العميل بالضغط على "أوافق على الشروط والأحكام" يكون
                العميل قد قرأ الشروط واستوعبها استيعاباً كاملا نافياً لأي لبس
                ووافق عليها
              </p>
              <p>
                يضاف للسعر النهائي لعملية الشراء مبلغ الشحن بناءاً على طلب
                العميل كرسوم توصيل داخل او خارج السعودية
              </p>
              <p>
                نحن غير ملزمين بأي عرض سعر ( إعلان ) منتهي سواء انتهت الكمية أو
                تاريخ العرض
              </p>
              <p>
                في حال حصول خلل في الموقع أو عدم توفر المنتج المطلوب يتم الغاء
                الطلب وارجاع كامل المبلغ للعميل
              </p>
              <p>
                يحق لشركة بلايهيرا رفض أي طلب ارجاع، استرداد او تبديل حسب تقييم
                الحالة
              </p>
              <h2>سياسة الارجاع</h2>
              <p>
                لدينا سياسة إرجاع لمدة 7 أيام ، مما يعني أنه لديك 7 ايام بعد
                استلام المنتج الخاص بك لطلب الإرجاع والاسترداد يمكن طلب عملية
                الإرجاع والاسترداد <br />
                في حالة عدم توفر المنتج المطلوب أو عدم تطابقه مع المواصفات
                المعلنة في الموقع أيضًا ، يمكن طلب عملية الإرجاع والاسترداد في
                غضون 7 أيام إذا كان <br />
                المنتج في حالته الأصلية وعبوته الأصلية ، وذلك لاستلام المبلغ
                كاملاً. لاحظ أن هذا لا يشمل سعر الشحن. لكي يتم قبول طلب الإرجاع
                ، يجب أن يكون المنتج الذي تم استلامه في نفس الحالة التي استلمته
                بها ، اي غير مستخدم وفي عبوته الأصلية. ستحتاج أيضًا إلى إيصال أو
                إثبات الشراء support@playhera.com لطلب الإرجاع ، يمكنك الاتصال
                بنا على إذا تم قبول طلبك، فسنرسل لك قسيمة شحن المرتجعات ،
                بالإضافة إلى إرشادات حول كيفية ومكان إرسال الطرد الخاص بك. لن
                يتم قبول المنتجات المرسلة إلينا دون طلب الإرجاع أولاً سنقوم
                بإعلامك بمجرد استلامنا وفحص طلبك، وسنعلمك إذا تمت الموافقة على
                رد الأموال أم لا. في حالة الموافقة ، سيتم استرداد أموالك
                تلقائيًا باستخدام طريقة الدفع الأصلية في غضون 14 إلى 21 يوم عمل.
                يُرجى تذكر أن الأمر قد يستغرق بعض الوقت حتى يتمكن البنك أو شركة
                بطاقة الائتمان التي تتعامل معها من معالجة رد الأموال وإرسالها
                أيضًا يمكنك دائمًا الاتصال بنا للاجابة على أي سؤال بخصوص طلبات
                الارجاع على support@playhera.com
              </p>
              <h2>الأضرار</h2>
              <p>
                يرجى فحص طلبك عند الاستلام والاتصال بنا على الفور إذا كان المنتج
                معيبًا أو تالفًا أو إذا تلقيت المنتج الخطأ ، حتى نتمكن من تقييم
                المشكلة وتصحيحها
              </p>
              <h2>استثناءات / سلع غير قابلة للإرجاع</h2>
              <p>
                لا يمكن إرجاع أنواع معينة من المنتجات ، مثل المنتجات المخصصة
                (مثل الطلبات المعدلة خصيصا لك) والمنتجات الرقمية لسوء الحظ ، لا
                يمكننا قبول إرجاع السلع المخفضة أو بطاقات الهدايا
              </p>
              <h2>التبديل</h2>
              <p>
                أسرع طريقة لضمان حصولك على ما تريده هي الاتصال بنا على
                support@playhera.com. أو الاتصال برقم CC لدينا
              </p>
              <p>
                مكن للعميل استبدال المنتج خلال 7 أيام من استلامه بشرط أن لا يكون
                قد تم استخدامه أو العبث به أو إزالة اللاصق أو وجود أي قطع في
                مغلف المنتج <br />
                وان يكون المنتج في حالته الاصلية وتغليفه الأصلي (ما عدا البطاقات
                الرقمية) يسمح بتبديل القطعة مرة واحدة فقط لكل طلب عملية
                الاستبدال تتطلب دفع رسوم <br />
                شحن إضافية في حالة استبدال المنتج وعدم وجود أي عيب مصنعي أو خطأ
                في المنتج
              </p>
              <h2>سياسة الضمان</h2>
              <p>
                الضمان هو للعيوب المصنعية فقط ولا يشمل الأعطال الأخرى مثل الكسور
                او الاضرار الناتجة عن سوء الاستخدام <br />
                لن ينطبق الضمان لأية أضرار ناجمة عن ( استخدام اكسسوارات غير
                متوافقة مع السلعة – عدم اتباع تعليمات الجهة المصنعة ) يعتبر
                الضمان لاغيا في حال استخدام المنتج بطريقة غير صحيحة مثل كسر سرعة
                المعالج او كرت الشاشة او رفع الحرارة أو تحميل برامج خطرة .
                محاولة صيانة المنتج عند اي مركز صيانة غير معتمد لدى الوكيل تلغي
                الضمان عن المنتج إذا ظهرت مشكلة في الجهاز عند الاستلام او خلال
                48 ساعة من استلامه فإن بلايهيرا تتحمل مصاريف الشحن من وإلى
                العميل واستبدال الجهاز في حال ثبوت العطل او العيب المصنعي. بعد
                ذلك، يكون الضامن هو الوكيل حسب العلامة التجارية معظم الأصناف
                تخضع لكفالة المُصنّع أو الوكيل، وفي هذه الحالة، يجب على العميل
                مراجعة المُصنّع نفسه أو وكيله في السعودية مباشرة بإمكانك ارسال
                المنتج الى مركز الصيانة المعتمد للشركة المصنعة او بإمكانك
                التواصل معنا لمساعدتك.
              </p>
            </div>
          </div>
          <div class="refundpolicy-container">
            <div class="priv">
              <h1>سياسة الشحن</h1>
            </div>
            <p>
              <br /><br />
              التوصيل مجاني للطلبات التي تتجاوز قيمتها 300 ريال فقط.
              <br /><br />
              تخضع الطلبات التي تقل عن 300 ريال لرسوم التوصيل والتي يتم تحديدها
              عن طريق الموقع. عملية توصيل المنتجات تتم عن طريق طرف ثالث (شركة
              شحن) خلال يومين إلى 6 أيام عمل حسب المنطقة ولا يعتبر يوم الجمعة من
              ضمن أيام العمل. <br /><br />
              تقع مسؤولية التحقق من استلام المنتجات بدون أي عيوب أو كسر على عاتق
              العميل عند استلام الطلب من مندوب شركة الشحن. <br /><br />
              توصيل المنتجات المرتجعة يتطلب من العميل دفع مبالغ إضافية لشركة
              الشحن في حال كان المنتج لا يحتوي على أي عيب مصنعي.
            </p>
            <p>
              توصيل المنتجات المرتجعة بسبب عيب مصنعي او غير مطابقة للمنتج الذي
              تم طلبه لا يتطلب من العميل دفع أي مبالغ إضافية.
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Refundpolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.priv {
  font-size: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refundpolicy-container-mena {
  max-width: 1511px;
  width: 80vw !important;
}
.refund-policy-mena {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .refundpolicy-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
