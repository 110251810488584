<template>
  <TournamentSponsors
    v-if="hasSponsors"
    :sponsors="sponsors"
    :height="height"
    :align="align"
    class="tournament-card_sponsors"
  />
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import TournamentSponsors from '@/views/tournaments/management/singleTournament/components/SponsorsListPreview';
import { mapGetters } from 'vuex';
import { DEFAULT } from '@/constants/common';

export default {
  name: 'TournamentCardSponsors',
  components: { TournamentSponsors },
  props: {
    tournament: propRequired(Object),
    height: prop(Number, 34),
    align: prop(String),
  },
  computed: {
    ...mapGetters(['currentCountry']),
    sponsors({ tournament }) {
      let sponsors = tournament.sponsor_logo || tournament.sponsorLogo || [];

      return sponsors[this.currentCountry] || sponsors[DEFAULT];
    },
    hasSponsors({ sponsors }) {
      return !!(sponsors && sponsors.length);
    },
  },
};
</script>

<style lang="scss">
@import '../variables';

.tournament-card_sponsors {
  max-width: 150px;
  width: 150px;
  @include auto-rtl('left', calc($card_padding + 4px));
}
</style>
