<template>
  <span
    class="playhera-icon"
    @mouseenter.self="isHovered = true"
    @mouseleave.self="isHovered = false"
    v-on="$listeners"
  >
    <svg
      v-bind="{
        viewBox,
        width: normalizedWidth,
        height: autoHeight ? '100%' : normalizedWidth,
      }"
      :style="{ '--fill': displayFill, '--rotate': `${rotate}deg` }"
    >
      <use :href="`#${displayName}`" />
    </svg>
  </span>
</template>

<script>
import sprite from 'svg-sprite-loader/runtime/browser-sprite.build';

import { prop, propRequired } from '@/utils/factories';

export default {
  name: 'Icon',
  props: {
    name: propRequired(String),
    fill: prop(String),
    width: prop([String, Number], '24'),
    hasFullWidth: prop(Boolean),
    autoHeight: prop(Boolean),
    rotate: prop(Number, 0),
    hoverName: prop(String),
    hoverFill: prop(String),
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    displayFill() {
      return (this.isHovered && this.hoverFill) || this.fill;
    },
    displayName() {
      return (this.isHovered && this.hoverName) || this.name;
    },
    normalizedWidth() {
      return this.hasFullWidth ? '100%' : `${parseInt(this.width)}px`;
    },
    viewBox() {
      return (sprite.symbols.find(({ id }) => id === this.displayName) || {})
        .viewBox;
    },
  },
};
</script>

<style lang="scss">
.playhera-icon {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  svg {
    --fill: #{$ph-primary-text};
    --rotate: 0;
    pointer-events: none;
    transform: rotate(var(--rotate));

    use {
      fill: var(--fill);
      stroke: none;
    }
    path {
      fill: none;
      stroke: none;
    }
  }

  &.is-loading {
    position: relative;
    svg {
      visibility: hidden;
    }
    @include spinner(calc(var(--width) / 2));
  }
}
</style>

<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/"
  name="Icon"
  knobs="{
    fill: {
      default: color('Fill', '#ffffff'),
    },
    hoverFill: {
      default: color('Hover Fill', '#ff0000'),
    },
    width: {
      default: number('Width', 32, {
         range: true,
         min: 8,
         max: 128,
         step: 4,
      })
    },
    rotate: {
      default: number('Rotate', 0, {
        range: true,
        min: 0,
        max: 360,
        step: 1,
      })
    },
    hoverName: {
      default: text('Hover Icon Name', '')
    }
  }"
>
  <div class="text-center">
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'action'}"/><br><span>action</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'add'}"/><br><span>add</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'add-channel'}"/><br><span>add-channel</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'add-gradient'}"/><br><span>add-gradient</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'add-to-clan'}"/><br><span>add-to-clan</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'add-to-clan-done'}"/><br><span>add-to-clan-done</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'ambassador-gradient'}"/><br><span>ambassador-gradient</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'appstore'}"/><br><span>appstore</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'appstore-logo'}"/><br><span>appstore-logo</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow'}"/><br><span>arrow</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow-b'}"/><br><span>arrow-b</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow-left'}"/><br><span>arrow-left</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow-right'}"/><br><span>arrow-right</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow-s'}"/><br><span>arrow-s</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow-t'}"/><br><span>arrow-t</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'arrow-t-b'}"/><br><span>arrow-t-b</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'attach'}"/><br><span>attach</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'back'}"/><br><span>back</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'bag'}"/><br><span>bag</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'bage'}"/><br><span>bage</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'block-in'}"/><br><span>block-in</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'bracket'}"/><br><span>bracket</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'calendar'}"/><br><span>calendar</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'camera'}"/><br><span>camera</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'cancel'}"/><br><span>cancel</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'cancel_2'}"/><br><span>cancel_2</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'challenger'}"/><br><span>challenger</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'channel'}"/><br><span>channel</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'chat'}"/><br><span>chat</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'checkbox-off'}"/><br><span>checkbox-off</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'checkbox-on'}"/><br><span>checkbox-on</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'checked'}"/><br><span>checked</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'checked_1'}"/><br><span>checked_1</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'checked_2'}"/><br><span>checked_2</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'check-mark'}"/><br><span>check-mark</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'check-on'}"/><br><span>check-on</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'chevron-triangle-down'}"/><br><span>chevron-triangle-down</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'chevron-triangle-up'}"/><br><span>chevron-triangle-up</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'close'}"/><br><span>close</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'comments'}"/><br><span>comments</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'controller'}"/><br><span>controller</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'copy'}"/><br><span>copy</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'cross'}"/><br><span>cross</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'crossplatform'}"/><br><span>crossplatform</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'csv'}"/><br><span>csv</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'danger'}"/><br><span>danger</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'dashboard'}"/><br><span>dashboard</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'delete'}"/><br><span>delete</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'delete-active'}"/><br><span>delete-active</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'deleted-clan'}"/><br><span>deleted-clan</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'dislike'}"/><br><span>dislike</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'done'}"/><br><span>done</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'dots'}"/><br><span>dots</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'double-elimination'}"/><br><span>double-elimination</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'draw'}"/><br><span>draw</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'dropdown'}"/><br><span>dropdown</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'edit'}"/><br><span>edit</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'enter'}"/><br><span>enter</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'error'}"/><br><span>error</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'exclamation'}"/><br><span>exclamation</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'eye_close'}"/><br><span>eye_close</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'eye_open'}"/><br><span>eye_open</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'file'}"/><br><span>file</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'fill'}"/><br><span>fill</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'filter'}"/><br><span>filter</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'full_logo'}"/><br><span>full_logo</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'gamepad'}"/><br><span>gamepad</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'gamepad2'}"/><br><span>gamepad2</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'globe'}"/><br><span>globe</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'googlestore'}"/><br><span>googlestore</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'grand'}"/><br><span>grand</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'group'}"/><br><span>group</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'group-fill'}"/><br><span>group-fill</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'group-invite'}"/><br><span>group-invite</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'info'}"/><br><span>info</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'leaderboards'}"/><br><span>leaderboards</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'leagues'}"/><br><span>leagues</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'like'}"/><br><span>like</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'link'}"/><br><span>link</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'loading'}"/><br><span>loading</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'logout'}"/><br><span>logout</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'map'}"/><br><span>map</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'master'}"/><br><span>master</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'medal-1'}"/><br><span>medal-1</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'medal-2'}"/><br><span>medal-2</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'medal-3'}"/><br><span>medal-3</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'menu'}"/><br><span>menu</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'minus'}"/><br><span>minus</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'more'}"/><br><span>more</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'move-in'}"/><br><span>move-in</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'move-out'}"/><br><span>move-out</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'msg'}"/><br><span>msg</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'next'}"/><br><span>next</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'notification'}"/><br><span>notification</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'not-ready-for-battle'}"/><br><span>not-ready-for-battle</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'organization-gradient'}"/><br><span>organization-gradient</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'people'}"/><br><span>people</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'person_step_icon'}"/><br><span>person_step_icon</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'phone'}"/><br><span>phone</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'pick-for-move'}"/><br><span>pick-for-move</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'picture'}"/><br><span>picture</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'pin'}"/><br><span>pin</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'platforms'}"/><br><span>platforms</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'play'}"/><br><span>play</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'play-2'}"/><br><span>play-2</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'play-button'}"/><br><span>play-button</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'players'}"/><br><span>players</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'professional'}"/><br><span>professional</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'profile'}"/><br><span>profile</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'publish'}"/><br><span>publish</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'question_mark'}"/><br><span>question_mark</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'ready-for-battle'}"/><br><span>ready-for-battle</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'reload'}"/><br><span>reload</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'reset'}"/><br><span>reset</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'revoke-invite'}"/><br><span>revoke-invite</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'round-robin'}"/><br><span>round-robin</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'search'}"/><br><span>search</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'search-mute'}"/><br><span>search-mute</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'send'}"/><br><span>send</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'settings'}"/><br><span>settings</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'settings-outline'}"/><br><span>settings-outline</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'setup'}"/><br><span>setup</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'share'}"/><br><span>share</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shield'}"/><br><span>shield</span></div>
  </div>
  <div class="row pb-3">
      <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shield-fill'}"/><br><span>shield-fill</span></div>
      <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'short_logo'}"/><br><span>short_logo</span></div>
      <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shuffle'}"/><br><span>shuffle</span></div>
      <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'single-elimination'}"/><br><span>single-elimination</span></div>
      <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'star'}"/><br><span>star</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'stats'}"/><br><span>stats</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'stop'}"/><br><span>stop</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'swiss-system'}"/><br><span>swiss-system</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'sword'}"/><br><span>sword</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'swords'}"/><br><span>swords</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'time'}"/><br><span>time</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'tournaments'}"/><br><span>tournaments</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'translate'}"/><br><span>translate</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'trash'}"/><br><span>trash</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'trash-active'}"/><br><span>trash-active</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'update'}"/><br><span>update</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'user'}"/><br><span>user</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'verified'}"/><br><span>verified</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'video'}"/><br><span>video</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'video-placeholder'}"/><br><span>video-placeholder</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'wall'}"/><br><span>wall</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'warning'}"/><br><span>warning</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'worker'}"/><br><span>worker</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'write'}"/><br><span>write</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'editable/emoji'}"/><br><span>editable/emoji</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'editable/submit'}"/><br><span>editable/submit</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'editable/upload'}"/><br><span>editable/upload</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'editable/attach-gif'}"/><br><span>editable/attach-gif</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shapes/button'}"/><br><span>shapes/button</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shapes/gradient'}"/><br><span>shapes/gradient</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shapes/mask'}"/><br><span>shapes/mask</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'shapes/primary'}"/><br><span>shapes/primary</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/discord'}"/><br><span>social/discord</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/facebook'}"/><br><span>social/facebook</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/fb'}"/><br><span>social/fb</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/instagram'}"/><br><span>social/instagram</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/openrec'}"/><br><span>social/openrec</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/telegram'}"/><br><span>social/telegram</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/twitch'}"/><br><span>social/twitch</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/twitter'}"/><br><span>social/twitter</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'social/youtube'}"/><br><span>social/youtube</span></div>
  </div>
  <div class="row pb-3">
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'playhera-logo'}"/><br><span>playhera-logo</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'playmarket'}"/><br><span>playmarket</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'plus'}"/><br><span>plus</span></div>
    <div class="col"><Icon v-bind="{fill,hoverFill, width, rotate, hoverName, name: 'twitter-sign-in'}"/><br><span>twitter-sign-in</span></div>
  </div>
  </div>
</story>
