<template>
  <div class="input-group" :class="{ focused: isFocused }">
    <SearchNavbarTextInput
      class="w-100"
      v-bind="{
        type: 'search',
        //disabled,
        id: inputID,
        icon: hasIcon ? 'search' : '',
        iconSize: size,
        class: classInput,
        placeholder: placeholderReplaced,
        readOnly,
      }"
      @keydown.enter.native="$emit('enter', $event)"
      @keyup.native="$emit('keyup', $event)"
      @blur="onBlur"
      @focus="onFocus"
    >
      <AppIcon
        v-if="hasIcon"
        slot="icon"
        name="search"
        :class="classIcon"
        :width="size"
        :fill="isFocused ? $scss.textColor : $scss.heraMuted"
      />
    </SearchNavbarTextInput>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import AppIcon from '@/components/atoms/Icon';
//import SearchTextInput from '@/components/atoms/form/HeraSearchTextInput';
import SearchNavbarTextInput from '@/components/atoms/form/HeraNavbarSearchTextInput';

export default {
  name: 'SearchNavbarInput',
  components: {
    SearchNavbarTextInput,
    AppIcon,
  },
  props: {
    value: prop(String),
    id: prop(String),
    placeholder: prop(String),
    hasIcon: prop(Boolean, true),
    classIcon: prop(String),
    classInput: prop(String),
    size: prop([Number, String], 18), //16
    debounce: prop(Number, 300),
    //disabled: prop(Boolean),
    readOnly: prop(Boolean),
  },
  data() {
    return {
      isFocused: false,
      inputObserver: null,
      inputElement: null,
      inputData: this.value,
      debouncedModelSet: null,
    };
  },
  computed: {
    inputID() {
      return this.id ? this.id : 'InputField' + this.getRandomInt(999);
    },
    placeholderReplaced() {
      return (
        this.placeholder ||
        this.$t(
          '_web_search_input_discover_placeholder',
          'Discover players, teams and more'
        )
      );
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (!oldVal && !this.inputData) {
        this.inputData = newVal;
      }
    },
  },
  created() {
    this.debouncedModelSet = this.$lodash.debounce(
      input => this.$emit('input', input),
      this.debounce
    );
  },
  methods: {
    onFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);
    },
    onBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e);
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
  },
};
</script>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Molecules/form"
  name="HeraNavbarSearchInput"
  knobs="{
    value: {
      default: text('value', '')
    },
    id: {
      default: text('id', '')
    },
    placeholder: {
      default: text('placeholder', '')
    },
    hasIcon: {
      default: boolean('hasIcon', true)
    },
    classIcon: {
      default: text('classIcon', '')
    },
    classInput: {
      default: text('classInput', '')
    },
    size: {
      default: number('size', 16)
    },
  }"
>
  <HeraNavbarSearchInput
    v-bind="{ value, id, placeholder, hasIcon, classIcon, classInput, size }" @input="val => action('input', val)"/>
</story>
