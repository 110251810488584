<template>
  <div>
    <MainLayout>
      <div class="terms">
        <div class="terms-container">
          <h1 class="text-center">
            تطبيق PLAYHERA للجوال - اتفاقية ترخيص المستخدم النهائي
          </h1>

          <p style="margin-right: 25px;">
            شركة المحتوى الترفيهي المحدودة هي شركة مسجلة في المملكة العربية
            السعودية تحت رقم 1010451300 ، ومكتبها المسجل في 3671 طريق الثمامة.
            حي الربيع – وحدة رقم 8
          </p>

          <p style="margin-right: 25px;">
            الرياض 13316 – 8536 رقم ضريبة القيمة المضافة 310174286700003 وتقوم
            شركة المحتوى الترفيهي بتشغيل تطبيق Playhera الرسمي للهاتف المحمول
            ("التطبيق").
          </p>

          <ul>
            <li>
              <p><b>فهم الشروط:</b></p>
              <ul>
                <li>
                  <p>
                    تصف اتفاقية ترخيص المستخدم النهائي هذه ("EolA") كيفية تنزيل
                    التطبيق والوصول إلى التطبيق والخدمات المتاحة من خلال التطبيق
                    ("الخدمات") واستخدامها. من خلال الوصول إلى التطبيق ، ستنطبق
                    اتفاقية EolA هذه عليك وأنت توافق على شروط اتفاقية EolA هذه.
                    لذلك يجب عليك قراءة المصطلحات والشروط بعناية قبل استخدام
                    التطبيق.
                  </p>
                </li>
                <li>
                  <p>
                    عند استخدام كلمات وعبارات معينة في EolA، يكون لها معاني
                    محددة (تُعرف باسم "المصطلحات المحددة"). يمكنك تحديد هذه
                    المصطلحات لأنها تبدأ بأحرف كبيرة (حتى لو لم تكن في بداية
                    الجملة). عند استخدام مصطلح محدد، يكون له المعنى المعطى له في
                    قسم EolA حيث تم تعريفه (يمكنك العثور على هذه المعاني من خلال
                    النظر في الجملة حيث تتضمن المصطلح المحدد بين قوسين وعلامات
                    الكلام).
                  </p>
                </li>
                <li>
                  <p>
                    في EolA هذا ، عندما نشير إلى "نحن" أو "انتم" أو "لنا"، فإننا
                    نعني شركة Playhera للمحتوى الترفيهي وعندما نشير إلى "أنت" أو
                    "لك" فإننا نعني:
                  </p>
                  <ul>
                    <li>
                      <p>
                        ذا كنت مستهلكًا ، الشخص الذي يستخدم التطبيق لغرض خارج
                        نطاق تجارته أو أعماله أو حرفته أو مهنته وايضا
                      </p>
                    </li>
                    <li>
                      <p>
                        إذا لم تكن مستهلكًا (على سبيل المثال ، إذا كنت لاعبًا
                        محترفًا في الرياضات الإلكترونية): (أ) الشركة التي لديك
                        سلطة للارتباط بها ؛ أو (ب) الفرد الذي يستخدم التطبيق
                        لأغراض لا تخرج كليًا أو بشكل أساسي عن تجارته أو أعماله
                        أو حرفته أو مهنته (في كل حالة "نشاط تجاري").
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    يتطلب هذا التطبيق هاتفًا ذكيًا أو جهازًا محمولاً يعمل بنظامي
                    التشغيل iOS أو Android (الجهاز الذي تستخدمه ، "الجهاز")
                    ولتنزيل التطبيق والوصول إلى أحدث الميزات ، ستحتاج إلى الوصول
                    إلى الإنترنت.
                  </p>
                </li>
                <li>
                  <p>
                    يرجى الملاحظة ، أن بعض الوظائف المتاحة على التطبيق تحكمها
                    شروط وأحكام إضافية ، بما في ذلك (على سبيل المثال) مشاركتك في
                    مسابقات الرياضات الإلكترونية. وأيضًا يمكننا من وقت لآخر ،
                    تقديم مميزات أو خدمات إضافية ، وسنخطرك بأي شروط وأحكام سارية
                    لاستخدامها.
                  </p>
                </li>
                <li>
                  <p>
                    بالإضافة إلى البند 1.5 أعلاه ، يرجى ملاحظة ما يلي:
                  </p>
                  <ul>
                    <li>
                      <p>
                        لتنزيل التطبيق ، يجب عليك أيضًا مراجعة شروط التطبيق
                        الإضافية والموافقة عليها في الملحق 1 من EolA وأي شروط
                        وأحكام أخرى يفرضها متجر التطبيقات الذي قمت بتنزيل
                        التطبيق منه. ونستخدم معلوماتك الشخصية فقط وفقاً لسياسة
                        الخصوصية الخاصة بنا ( متوفرة هنا , والتي توضح أيضاً
                        كيفية استخدامنا لملفات تعريف الارتباط).
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <p><b>التطبيق: </b></p>
              <ul>
                <li>
                  <p>
                    التطبيق متاح مجانًا ونحن لا نضمن أن التطبيق ، أو أي محتوى
                    عليه ، سيكون متاحًا دائمًا أو غير متقطع. يُسمح بالوصول إلى
                    التطبيق على أساس مؤقت. يجوز لنا تعليق التطبيق أو سحبه أو
                    إيقافه أو تغييره بالكامل أو أي جزء منه دون إشعار. لن نكون
                    مسؤولين تجاهك إذا كان التطبيق غير متاح لأي سبب من الأسباب في
                    أي وقت أو لأي فترة. يجوز لنا تحديث التطبيق وتغيير المحتوى
                    الموجود عليه في أي وقت.
                  </p>
                </li>
                <li>
                  <p>
                    أنت مسؤول عن اتخاذ جميع الترتيبات اللازمة لتتمكن من الوصول
                    إلى التطبيق. أنت مسؤول أيضًا عن التأكد من أن جميع الأشخاص
                    الذين يصلون إلى التطبيق من خلال الإنترنت الخاص بك على علم
                    باتفاقية EolA هذه وأنهم يمتثلون لها.
                  </p>
                </li>
                <li>
                  <p>
                    يتم توفير التطبيق والمحتوى الموجود به لأغراض المعلومات
                    العامة فقط. لا يُقصد منها تقديم المشورة التي يجب أن تعتمد
                    عليها.
                  </p>
                </li>
                <li>
                  <p>
                    الا اذا أنت شركة تنافس في مسابقة أو وفقًا لما أذنا به صراحةً
                    ، يمكنك فقط استخدام التطبيق للاستخدام المنزلي والخاص وغير
                    تجاري.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>حسابك وكلمة المرور: </b></p>
              <ul>
                <li>
                  <p>
                    ستحتاج إلى التسجيل معنا في التطبيق من أجل الوصول إلى خدمات
                    معينة متاحة على التطبيق. يمكنك التسجيل باستخدام التطبيق أو
                    عبر موقعنا الرسمي ( (www.playhera.comإذا قمت بالتسجيل،
                    سيُطلب منك تقديم معلومات معينة (مثل عنوان بريدك الإلكتروني)
                    وإنشاء كلمة مرور ، كجزء من إجراءاتنا الأمنية. يجب أن تتعامل
                    مع كلمة المرور هذه على أنها سرية ويجب ألا تفصح عنها لأي طرف
                    ثالث.
                  </p>
                </li>
                <li>
                  <p>
                    يحق لنا تعطيل أي حسابات أو كلمة مرور في أي وقت، إذا كان في
                    رأينا المعقول أنك لم تلتزم بأي من أحكام اتفاقية EolA هذه.
                  </p>
                </li>
                <li>
                  <p>
                    إذا كنت تعلم أو تشك في أن أي شخص آخر غيرك يعرف تفاصيل تسجيل
                    الدخول إلى حسابك ، فيجب عليك إخطارنا على الفور على العنوان
                    <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
                  </p>
                </li>
                <li>
                  <p>
                    أنت مسؤول عن أي استخدام غير مصرح به لتفاصيل تسجيل الدخول إلى
                    حسابك.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>الاستخدام المقبول</b></p>
              <ul>
                <li>
                  <p>
                    <i><u>عام </u></i>
                  </p>
                  <p>أنت توافق على:</p>
                  <ul>
                    <li>
                      عدم استخدام التطبيق بأي طريقة غير قانونية أو لأي غرض غير
                      قانوني أو بأي طريقة لا تتوافق مع اتفاقية EolA هذه.
                    </li>
                    <li>
                      عدم انتهاك حقوق الملكية الفكرية الخاصة بنا أو حقوق أي طرف
                      ثالث فيما يتعلق باستخدامك للتطبيق (إلى الحد الذي يكون فيه
                      هذا الاستخدام غير مرخص بموجب اتفاقية EolA هذه.
                    </li>
                    <li>
                      عدم إرسال أي مواد تشهيرية أو مسيئة أو غير مرغوب فيها فيما
                      يتعلق باستخدامك للتطبيق.
                    </li>
                    <li>
                      عدم استخدام التطبيق بالوسائل الآلية أو بطريقة أخرى او
                      استخراج أو الحصول على أي مادة من التطبيق لاستخدامها في
                      موقع أو تطبيق تابع لجهة خارجية.
                    </li>
                    <li>
                      عدم جمع أي معلومات أو بيانات من التطبيق أو أنظمتنا أو
                      محاولة فك تشفير أي نقل إلى الخوادم التي تشغل التطبيق.
                    </li>
                    <li>
                      عدم نسخ أو إعادة إنتاج أو إعادة بيع أي جزء من التطبيق ما
                      لم يُسمح بذلك صراحةً في اتفاقية EolA هذه.
                    </li>
                    <li>
                      عدم تفكيك أو فك أو إجراء هندسة عكسية أو إنشاء أعمال مشتقة
                      بناءً على كل أو أي جزء من التطبيق أو محاولة القيام بأي شيء
                      من هذا القبيل.
                    </li>
                    <li>
                      عدم تأجير التطبيق أو تأجيره أو ترخيصه أو إعارته أو ترجمته
                      أو دمجه أو تكييفه أو تغييره أو تعديله.
                    </li>
                    <li>
                      للاحتفاظ بجميع نسخ التطبيق آمنة وللحفاظ على سجلات دقيقة
                      ومحدثة لعدد ومواقع جميع نسخ التطبيق.
                    </li>
                    <li>
                      عدم توفير التطبيق أو إتاحته بطريقة أخرى كليًا أو جزئيًا
                      (بما في ذلك رمز المصدر) بأي شكل من الأشكال لأي شخص دون
                      موافقة كتابية مسبقة منا.
                    </li>
                    <li>
                      الامتثال لجميع قوانين ولوائح الرقابة على التكنولوجيا أو
                      التصدير التي تنطبق على التكنولوجيا المستخدمة أو التي
                      يدعمها التطبيق.
                    </li>
                  </ul>
                </li>

                <li>
                  <p>
                    أنت تقر بأنه ليس لديك الحق في الوصول إلى التطبيق في شكل شفرة
                    كود المصدر.
                  </p>
                </li>

                <li>
                  <p>
                    <i><u>User Generated Content</u></i>
                  </p>
                  <p>
                    إذا كانت هذه هي الحالة التي تقوم فيها بتحميل أي محتوى في
                    التطبيق - سواء كان ذلك من خلال الصور أو النصوص أو التسجيلات
                    الصوتية أو أي شيء آخر - يجب أن يتوافق المحتوى الذي تقدمه
                    ("<b>المحتوى الذي ينشئه المستخدم</b>") مع القوانين التالية:
                  </p>
                  <ul>
                    <li>
                      <p>يجب ألا يضايق شخصًا آخر</p>
                    </li>
                    <li>
                      <p>يجب أن يكون صادقًا</p>
                    </li>
                    <li>
                      <p>دم التشهير بأي شخص كان</p>
                    </li>
                    <li>
                      <p>
                        يجب ألا تستخدم المواد أو المحتوى التي تنتهك حقوق أو
                        خصوصية أي شخص آخر ؛ على سبيل المثال استخدام صور لشخصيات
                        أو لقطات أو موسيقى مشهورة (ما لم تكن صورتك الخاصة).
                      </p>
                    </li>
                    <li>
                      <p>
                        يجب ألا تحتوي على تفاصيل شخصية لشخص آخر أو معلومات سرية
                        تتعلق بأشخاص آخرين.
                      </p>
                    </li>
                    <li>
                      <p>
                        يجب ألا يشجع أو يتغاضى عن الإرهاب أو العنف أو السلوك غير
                        القانوني.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    نحن نحتفظ بالحق في قبول أو رفض أو التوقف عن استخدام أي محتوى
                    من إنشاء المستخدم يقدمه أي شخص نعتقد أنه يتعارض مع هذه
                    الشروط.
                  </p>
                </li>
                <li>
                  <p>
                    يحتوي التطبيق على ميزة الإبلاغ التي يمكنك استخدامها لإبلاغنا
                    بأي محتوى تشعر أنه لا يتوافق مع الأدوار المذكورة أعلاه ،
                    وسيتخذ المشغلون الإجراءات اللازمة في غضون 24 ساعة. وقد يتم
                    تعليق حسابات مستخدمي المحتوى المسيء.
                  </p>
                </li>
                <li>
                  <p>
                    بالإضافة إلى ذلك ، قد نقدم من وقت لآخر خدمات تفاعلية على
                    التطبيق والتي ستمكنك من تحميل المحتوى الذي ينشئه المستخدم ،
                    بما في ذلك ، على سبيل المثال لا الحصر:
                  </p>
                  <ul>
                    <li>
                      <p>-التعليق</p>
                    </li>
                    <li>
                      <p>-غرف الدردشة</p>
                    </li>
                    <li>
                      <p>
                        -اللوحات الإعلانية <br />-(معًا "الخدمات التفاعلية").
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    عندما نقدم خدمة تفاعلية ، سنبذل جهودًا معقولة لتزويدك
                    بمعلومات حول نوع الخدمة المقدمة وما إذا كانت خاضعة للإشراف.
                    ومع ذلك ، فإننا لسنا ملزمين بالإشراف على أي خدمة تفاعلية
                    نقدمها أو مراقبتها أو الإشراف عليها.
                  </p>
                </li>
                <li>
                  <p>
                    يخضع استخدام أي من خدماتنا التفاعلية من قبل قاصر لموافقة
                    الوالد أو الوصي. ننصح الآباء الذين يسمحون لأطفالهم باستخدام
                    خدمة تفاعلية أنه من المهم أن يتواصلوا مع أطفالهم بشأن
                    سلامتهم عبر الإنترنت. يجب أن يكون القاصرون الذين يستخدمون أي
                    خدمة تفاعلية على دراية بالمخاطر المحتملة عليهم.
                  </p>
                </li>
                <li>
                  <p>
                    <i><u>الفايروسات </u></i>
                  </p>
                  <p>
                    لا نضمن أن يكون التطبيق آمنًا تمامًا أو خاليًا من الأخطاء أو
                    الفيروسات. أنت مسؤول عن تكنولوجيا المعلومات وبرامج الكمبيوتر
                    والنظام الأساسي للوصول إلى التطبيق ونوصي باستخدام برنامج
                    الحماية من الفيروسات الخاص بك.
                  </p>
                </li>
                <li>
                  <p>
                    يجب ألا تسيء استخدام التطبيق عن طريق إدخال فيروسات أو مواد
                    أخرى ضارة أو ضارة تقنيًا. يجب ألا تحاول الحصول على وصول غير
                    مصرح به إلى التطبيق أو الخادم الذي تم تخزين التطبيق عليه أو
                    أي خادم أو كمبيوتر أو قاعدة بيانات متصلة بالتطبيق. يجب ألا
                    تهاجم التطبيق عبر هجوم الخدمة أو هجوم الخدمة الموزع. من خلال
                    خرق هذا الحكم. سنبلغ عن أي خرق من هذا القبيل إلى سلطات إنفاذ
                    القانون ذات الصلة وسنتعاون مع تلك السلطات من خلال الكشف عن
                    هويتك لهم. في حالة حدوث مثل هذا الانتهاك ، سيتوقف حقك في
                    استخدام التطبيق على الفور.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>لملكية الفكرية </b></p>
              <ul>
                <li>
                  <p>
                    نمنحك ترخيصًا غير قابل للتحويل وغير حصري وقابل للإلغاء
                    لاستخدام التطبيق على الأجهزة بشرط أن تلتزم بشروط اتفاقية
                    EolA والمستندات المشار إليها فيها. نحن نحتفظ بجميع الحقوق
                    الأخرى.
                  </p>
                </li>
                <li>
                  <p>
                    نحن (بينك وبيننا) المالك أو المرخص له لجميع حقوق الملكية
                    الفكرية في التطبيق ومحتوياته. هذه الأعمال محمية بقوانين
                    ومعاهدات الملكية الفكرية في جميع أنحاء العالم. هذه الحقوق
                    محفوظة.
                  </p>
                </li>
                <li>
                  <p>
                    لا يتم منحك أي حق للاستخدام ، ولا يجوز لك استخدام أي من حقوق
                    الملكية الفكرية الخاصة بنا بخلاف ما هو منصوص عليه في اتفاقية
                    EolA. ما لم تكن شركة تنافس في مسابقة ، أو وفقًا لما أذننا به
                    صراحةً ، يجب ألا تستخدم التطبيق (أو أي جزء منه أو محتواه)
                    لأغراض تجارية ؛ ومع ذلك ، يمكنك تنزيل المواد من التطبيق فقط
                    للاستخدام الشخصي غير التجاري بواسطتك.
                  </p>
                </li>
                <li>
                  <p>
                    لا يجوز نسخ أي جزء من التطبيق بما في ذلك ، على سبيل المثال
                    لا الحصر النص والتصاميم والرسومات والصور الفوتوغرافية والصور
                    الواردة فيه ، أو إعادة إنتاجها أو إعادة نشرها أو تحميلها أو
                    إعادة نشرها أو تعديلها أو نقلها أو توزيعها أو استخدامها بأي
                    شكل من الأشكال. لأي غرض غير شخصي أو عام أو تجاري بدون موافقة
                    خطية مسبقة منا.
                  </p>
                </li>
                <li>
                  <p>
                    سيتم التعامل مع أي تواصل أو مواد (أي محتوى ينشئه المستخدم)
                    ترسله إلينا عبر التطبيق عن طريق البريد الإلكتروني أو أي
                    وسيلة أخرى على أنها غير مملوكة وغير سرية (بخلاف التواصل فيما
                    يتعلق بطلبك إذا كنت استخدم التطبيق لشراء منتجات أو خدمات
                    منا). نحن أحرار في نشر وعرض ونشر وتوزيع واستخدام أي أفكار أو
                    اقتراحات أو مفاهيم أو تصميمات وغيرها من المعلومات الواردة في
                    هذه المواد لأي غرض ، بما في ذلك ، التطوير والتصنيع ،
                    والدعاية والتسويق لنا ومنتجاتنا.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>مسؤوليتنا </b></p>
              <ul>
                <li>
                  <p>
                    لا شيء في اتفاقية EolA هذه يستثني أو يحد من مسؤوليتنا عن:
                  </p>
                  <ul>
                    <li>
                      <p>الوفاة أو الإصابة الشخصية الناجمة عن إهمالنا</p>
                    </li>
                    <li>
                      <p>الاحتيال أو التحريف الاحتيالي</p>
                    </li>
                    <li>
                      <p>
                        أي مسألة فيما يتعلق سيكون من غير القانوني بالنسبة لنا
                        استبعاد أو تقييد مسؤوليتنا.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>إذا كنت مستهلكًا:</p>
                  <ul>
                    <li>
                      <p>
                        وفشلنا في الامتثال لاتفاقية ترخيص المستخدم هذه ، فنحن
                        مسؤولون عن الخسارة أو الضرر الذي تتعرض له والذي يكون
                        نتيجة متوقعة لخرقنا لـ EolA أو إهمالنا لكننا لسنا
                        مسؤولين عن أي خسارة أو ضرر غير متوقع.
                      </p>
                    </li>
                    <li>
                      <p>
                        يمكن توقع الخسارة أو الضرر إذا كان نتيجة واضحة لخرقنا أو
                        إذا تم التفكير فيه من قبلنا نحن وأنت في الوقت الذي قمت
                        فيه بالوصول إلى التطبيق لا شيء في اتفاقية EolA هذه يؤثر
                        على حقوقك القانونية.
                      </p>
                    </li>
                    <li>
                      <p>
                        تتوفر المشورة بشأن حقوقك القانونية من هيئة حماية
                        المستهلك المحلية ؛ و نحن نوفر التطبيق للاستخدام المحلي
                        والخاص فقط. أنت توافق على عدم استخدام التطبيق ، أو أي
                        محتوى على التطبيق ، لأي أغراض تجارية أو تجارية ، ولا
                        نتحمل أي مسؤولية تجاهك عن أي خسارة في الربح ، أو خسارة
                        في العمل ، أو توقف العمل ، أو فقدان فرصة العمل
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>إذا كنت من أصحاب الأعمال:</p>
                  <ul>
                    <li>
                      <p>
                        جميع الضمانات والشروط والأحكام والتعهدات والالتزامات
                        التي ينطوي عليها القانون أو القانون العام أو العرف أو
                        الاستخدام التجاري أو مسار التعامل أو غير ذلك ، (بما في
                        ذلك على سبيل المثال لا الحصر التعهدات الضمنية ذات الجودة
                        المرضية أو المطابقة مع الوصف أو الملاءمة لأي غرض مشترك
                        أو القدرة على تحقيق قرار خاص) مستبعدة بموجب هذا إلى أقصى
                        حد يسمح به القانون
                      </p>
                    </li>
                    <li>
                      <p>
                        لن نتحمل أي مسؤولية تجاهك تحت أي ظرف من الظروف ، سواء في
                        العقد أو المسؤولية التقصيرية (بما في ذلك الإهمال) أو خرق
                        الواجب القانوني أو غير ذلك ، الناشئة بموجب أو فيما يتعلق
                        بهذه الشروط من أجل: (أ) أي خسارة في الأرباح أو المبيعات
                        أو الأعمال ، أو الإيرادات ؛ (ب) فقدان أو تلف البيانات أو
                        المعلومات أو البرامج ؛ (ج) ضياع فرصة العمل ؛ (د) خسارة
                        المدخرات المتوقعة ؛ (هـ) فقدان الشهرة. أو (و) أي خسارة
                        غير مباشرة أو تبعية على الإطلاق .
                      </p>
                    </li>
                    <li>
                      <p>
                        دون التأثير على الفقرة 6.1 ، فإن الحد الأقصى لمسؤوليتنا
                        الإجمالية بموجب و فيما يتعلق باتفاقية ترخيص المستخدم هذه
                        أو استخدامك للتطبيق (سواء في العقد أو المسؤولية
                        التقصيرية (بما في ذلك الإهمال) أو غير ذلك) ، فيما يتعلق
                        بجميع أفعالنا وحالات الإغفال أو خرق للواجب القانوني أو
                        خرق الضمان ، بما في ذلك مسئولينا أو موظفينا أو وكلائنا
                        أو مقاولينا أو الشركات التابعة لنا ، يجب ألا يتجاوز 100
                        جنيه إسترليني
                      </p>
                    </li>
                    <li>
                      <p>
                        أنت تقر بأن الحل الوحيد لك فيما يتعلق بأي خرق لـ EolA من
                        قبلنا أو أي فعل أو إغفال آخر من جانبنا فيما يتعلق بـ
                        EolA و / أو التطبيق ، مع استبعاد أي وجميع سبل الانتصاف
                        الأخرى (بما في ذلك أي مطالبة في المسؤولية التقصيرية) ،
                        هي مطالبة تعاقدية لخرق اتفاقية EolA هذه
                      </p>
                    </li>
                    <li>
                      <p>
                        يجب عليك تعويضنا وتعويضنا عن أي وجميع الخسائر والنفقات
                        والمسؤوليات التي يتم حلها من جميع المطالبات والطلبات
                        والمسؤوليات والأضرار والتكاليف والنفقات التي تكبدناها أو
                        من قبل أي من مسؤولينا أو مقاولينا أو وكلائنا أو موظفينا
                        أو الشركات التابعة التي تنشأ عن استخدامك للتطبيق ،أو
                        خرقك لـ EolA.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    نحن لا نتحمل أي مسؤولية عن محتوى مواقع الويب أو تطبيقات
                    الهاتف المحمول المرتبطة من التطبيق (بما في ذلك الروابط إلى
                    الرعاة التجاريين والشركاء). يجب عدم تفسير هذه الروابط على
                    أنها تأييد من قبلنا لتلك المواقع أو تطبيقات الهاتف المحمول
                    المرتبطة. لن نكون مسؤولين عن أي خسارة أو ضرر قد ينشأ عن
                    استخدامك لها.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p><b>التعليق والغاء العقد </b></p>
              <ul>
                <li>
                  <p>
                    يجوز لنا إجراء تغييرات على شروط اتفاقية EolA هذه من وقت لآخر
                    (على سبيل المثال ، إذا كان هناك تغيير في القانون يعني أننا
                    بحاجة إلى تغيير EolA). يرجى التحقق من EolA بشكل رسمي للتأكد
                    من فهمك للشروط المحدثة التي تنطبق في وقت وصولك إلى التطبيق
                    واستخدامه. إذا قمنا بتحديث شروط اتفاقية EolA هذه ، فسيتم
                    تطبيق الشروط المحدثة على كل استخداماتك للتطبيق من تلك النقطة
                    فصاعدًا.
                  </p>
                </li>
                <li>
                  <p>
                    من وقت لآخر ، قد يتم إصدار تحديثات للتطبيق من خلال متجر
                    التطبيقات ذي الصلة. بناءً على التحديث ، قد لا تتمكن من
                    استخدام التطبيق حتى تقوم بتنزيل أحدث إصدار من التطبيق وتقبل
                    أي شروط جديدة.
                  </p>
                </li>
                <li>
                  <p>
                    يُفترض أنك حصلت على إذن من مالكي أي أجهزة تتحكم فيها ، ولكن
                    لا تملكها ، لتنزيل نسخة من التطبيق على الأجهزة. قد يتم تحصيل
                    رسوم منك ومن قبل مزودي الخدمة لديك مقابل الوصول إلى الإنترنت
                    على الأجهزة. أنت تقبل المسؤولية وفقًا لشروط اتفاقية EolA هذه
                    عن استخدام التطبيق أو الخدمات على أي جهاز أو فيما يتعلق به ،
                    سواء كان مملوكًا لك أم لا.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>التغييرات على: EolA </b></p>
              <ul>
                <li>
                  <p>
                    يجوز لنا إجراء تغييرات على شروط اتفاقية EolA هذه من وقت
                    لآخر. إذا كان هناك تغيير في القانون يعني أننا بحاجة إلى
                    تغيير EolA .يرجى التحقق من EolA بشكل رسمي للتأكد من فهمك
                    للشروط المحدثة التي تنطبق في وقت وصولك إلى التطبيق
                    واستخدامه. اذا قمنا بتحديث شروط اتفاقية EolA هذه ، فسيتم
                    تطبيق الشروط المحدثة على كل استخداماتك للتطبيق من تلك النقطة
                    فصاعدًا.
                  </p>
                </li>
                <li>
                  <p>
                    من وقت لآخر ، قد يتم إصدار تحديثات للتطبيق من خلال متجر
                    التطبيقات ذي الصلة. بناءً على التحديث، قد لا تتمكن من
                    استخدام التطبيق حتى تقوم بتنزيل أحدث إصدار من التطبيق وتقبل
                    أي شروط جديد.
                  </p>
                </li>
                <li>
                  <p>
                    يُفترض أنك حصلت على إذن من مالكي أي أجهزة تتحكم فيها ، ولكن
                    لا تملكها ، لتنزيل نسخة من التطبيق على الأجهزة. قد يتم تحصيل
                    رسوم منك ومن قبل مزودي الخدمة لديك مقابل الوصول إلى الإنترنت
                    على الأجهزة. أنت تقبل المسؤولية وفقًا لشروط اتفاقية EolA هذه
                    عن استخدام التطبيق أو الخدمات على أي جهاز أو فيما يتعلق به ،
                    سواء كان مملوكًا لك أم لا.
                  </p>
                </li>
                <li>
                  <p><b>معلومات هامة أخرى</b></p>
                </li>

                <li>
                  <p>
                    يعمل كل بند من بنود اتفاقية EolA هذه بشكل منفصل. إذا قررت أي
                    محكمة أو سلطة ذات صلة أن أيًا منها غير قانوني أو غير قابل
                    للتنفيذ ، فستظل البنود المتبقية سارية المفعول والتأثير.
                  </p>
                </li>
                <li>
                  <p>
                    إذا فشلنا في الإصرار على أنك تؤدي أيًا من التزاماتك بموجب
                    اتفاقية ترخيص المستخدم هذه ، أو إذا لم نفرض حقوقنا ضدك ، أو
                    إذا تأخرنا في القيام بذلك ، فلن يعني ذلك أننا تنازلنا عن
                    حقوقنا ضدك ولن يعني أنك لست مضطرًا للامتثال لتلك الالتزامات.
                    إذا تنازلنا عن تقصير من جانبك.
                  </p>
                </li>
                <li>
                  <p>
                    فسنقوم بذلك كتابيًا فقط ، وهذا لا يعني أننا سوف نتنازل
                    تلقائيًا عن أي تقصير لاحق من جانبك. إذا كنت ترغب في الحصول
                    على مزيد من المعلومات حول حل النزاعات عبر الإنترنت ، فيرجى
                    اتباع هذا الرابط إلى موقع الويب الخاص بالمفوضية الأوروبية
                    <a href="http://ec.europa.eu/consumers/odr/"
                      >http://ec.europa.eu/consumers/odr/</a
                    >. يتم توفير هذا الرابط على النحو المطلوب من قبل الصادر عن
                    البرلمان الأوروبي والمجلس الأوروبي ، لأغراض المعلومات فقط.
                    لسنا ملزمين بالمشاركة في حل النزاعات عبر الإنترنت
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>القانون والشريعة السائدة </b></p>
              <ul>
                <li>
                  <p>
                    تخضع هذه EolA للقانون الإنجليزي وهذا يعني أن تنزيلك للتطبيق
                    والوصول إليه واستخدامه وأي نزاع أو مطالبة تنشأ عنه أو تتعلق
                    به سيخضع للقانون الإنجليزي.
                  </p>
                </li>
                <li>
                  <p>
                    يمكنك رفع دعوى بخصوص اتفاقية EolA هذه في المحاكم الإنجليزية.
                    ومع ذلك ، إذا كنت مستهلكًا وتعيش في دولة أخرى عضو في الاتحاد
                    الأوروبي ، فيمكنك رفع دعوى قضائية بخصوص اتفاقية EolA هذه إما
                    في المحاكم الإنجليزية أو محاكم تلك الدولة.
                  </p>
                </li>
                <li>
                  <p>
                    إذا كنت مستهلكًا وأنت مقيم في الاتحاد الأوروبي وقمنا بتوجيه
                    هذا التطبيق إلى الدولة التي تقيم فيها ، فستستفيد من أي أحكام
                    إلزامية لقانون الدولة التي تقيم فيها. لا شيء في اتفاقية EolA
                    هذه ، بما في ذلك البند 10.1 ، يؤثر على حقوقك كمستهلك في
                    الاعتماد على مثل هذه الأحكام الإلزامية من القانون المحلي.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p>
                <b>الاتصال بنا</b>
                الاتصال بنا إذا كان لديك أي أسباب للشكوى ، فسنسعى لحل المشكلة
                وتجنب تكرارها في المستقبل. يمكنك دائمًا الاتصال بنا عن طريق
                إرسال بريد إلكتروني إلى
                <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a> أو
                باستخدام التفاصيل التالية:
                <br />
                عنوان:
                <br />
                شركة المحتوى الترفيهي المحدودة
                <br />
                3671 طريق الثمامة حي الربيع - وحدة 8
                <br />
                13316 - 8536 المملكة العربية السعودية
                <br />
                عنوان البريد الإلكتروني:
                <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
                <br />
                رقم الهاتف: 966112221023+ شكرًا لك. تم تحديث الشروط آخر مرة في
                20 فبراير 2019
              </p>
            </li>
          </ul>

          <h2 class="text-center">شروط وأحكام البطولة والجوائز</h2>

          <p>لا ترعى Apple أيًا من البطولات في PLAYHERA</p>

          <ul>
            <li>
              <p>
                يتم إبلاغ الفائزين عبر البريد الإلكتروني أو رسالة خاصة عبر تطبيق
                PLAYHERA بعد استلام الإخطار، يكون أمام الفائز أسبوعين للمطالبة
                بالجائزة. إذا فشل الفائز في الرد في غضون أسبوعين ، فقد يتم رفض
                أي حقوق للمطالبة بالجائزة.
              </p>
            </li>
            <li>
              <p>
                الجوائز لا توجد إمكانية للمطالبة بجائزة بديلة أو دفع بديل
                للجائزة نقدًا. بالإضافة إلى ذلك ، لا يمكن تداول الجائزة بأشياء
                أو قسائم أو سلع أخرى. المشاركة في البطولة ليست عقدًا بين المشارك
                و PLAYHERA. إذا تم تقديم الجائزة من قبل طرف ثالث ، فلن تكون
                PLAYHERA مسؤولة عن العيوب أو الجوائز غير الصحيحة أو المعلومات
                الخاطئة. الجوائز غير النقدية دفع الجوائز النقدية غير ممكن.
                الفائز مسؤول عن تقديم بيانات شحن صالحة وصحيحة من PLAYHERA. لذلك
                ، PLAYHERA ليست مسؤولة إذا لم يتم قبول الطرد اما الجوائز النقدية
                سيتم دفع أموال الجائزة عن طريق شيك أو تحويل مصرفي. لذلك يكون
                الفائز مسؤولاً عن تقديم بيانات صحيحة. سيتم تحويل أموال الجائزة
                بين 4 أسابيع إلى 6 أشهر. وقد يختلف هذا التاريخ.
              </p>
            </li>
            <li>
              <p>
                يتم استبعاد موظفي PLAYHERA وجميع الشركاء المعنيين والشركات
                التابعة لهم من المشاركة في البطولات.
              </p>
            </li>
            <li>
              <p>
                إذا كان الفائز فريقًا وليس فردًا، يجب على قائد الفريق التوقيع
                على استلام الجائزة.
              </p>
            </li>
            <li>
              <p>
                إذا كان الفائز أقل من 17 عامًا ، فيجب أن يتلقى الوالد أو الوصي
                القانوني الجائزة بحضور اللاعب الفائز بالبطولة.
              </p>
            </li>
            <li>
              <p>لا يوجد ملجأ قانوني</p>
            </li>
            <li>
              <p>
                تحتفظ PLAYHERA بالحق في إلغاء أو إنهاء البطولة في أي وقت وبدون
                إشعار مسبق، إذا كان التنفيذ المناسب والمنظم للبطولة غير ممكن
                بسبب مشاكل فنية أو قانونية.
              </p>
            </li>
          </ul>

          <p>
            في حال وجود استفسارات أو مزيد من المعلومات حول الجوائز والبطولات
            التي نرعاها ، يرجى الاتصال بفريق الدعم لدينا:
            <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
          </p>
          <p class="text-center"><b>الملحق 1 </b></p>
          <h2 class="text-center">شروط التطبيق الإضافية</h2>

          <p>
            تنطبق عليها الشروط والأحكام التالية على استخدامك للتطبيق بالإضافة
            إلى تلك المنصوص عليها في اتفاقية EolA هذه.
          </p>

          <p>
            لأغراض هذا الملحق 1، يُقصد بـ متجر التطبيقات الذي قمت بتنزيل التطبيق
            من خلاله (على سبيل المثال ، إذا قمت بتنزيل التطبيق من أبل ستور. قوقل
            بلاي اذا قمت بتحميل التطبيق من خلاله.
          </p>

          <ul>
            <li>
              <p>
                أنت تقر وتوافق على أن اتفاقية EolA هذه قد أبرمت بينك وبين
                Playhera ، وليس مع مزود متجر التطبيقات أنت تقر وتوافق على أن
                موفر متجر التطبيقات ليس مسؤولاً عن التطبيق ومحتوياته.
              </p>
            </li>
            <li>
              <p>
                أنت تقر وتوافق على أن موفر متجر التطبيقات ليس عليه أي التزام
                بتقديم أي صيانة أو دعم فيما يتعلق بالتطبيق. إذا كان لديك أي
                مشاكل في استخدام التطبيق ، يرجى الاتصال بـ Playhera على:
                <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a
                >.
              </p>
            </li>
            <li>
              <p>
                في حالة عدم توافق التطبيق مع أي ضمان منتج تقدمه EolA ، يجوز
                لموفر التطبيقات أن يعيد لك السعر الذي دفعته لشراء التطبيق (إن
                وجد).
              </p>
            </li>
            <li>
              <p>
                أنت تقر وتوافق على أن موفر التطبيقات لن يكون مسؤولاً عن معالجة
                أي مطالبات قد تكون لديك فيما يتعلق بالتطبيق بما في ذلك على سبيل
                المثال مطالبات مسؤولية المنتج أي ادعاء بأن التطبيق لا يتوافق مع
                أي مطلب قانوني أو تنظيمي معمول به والمطالبات الناشئة بموجب حماية
                المستهلك أو التشريعات المماثلة.
              </p>
            </li>
            <li>
              <p>
                في حالة ادعاء طرف ثالث أن التطبيق ينتهك حقوق الملكية الفكرية
                الخاصة به ، فإن Playhera وليس مزود التطبيقات ستكون وحدها
                المسؤولة عن التحقيق والدفاع والتسوية وإبراء ذمة هذه المطالبة.
              </p>
            </li>
            <li>
              <p id="app2" ref="appendix2">
                تتعهد وتقر بما يلي: (1) أنك لست مقيمًا في بلد خاضع لحظر تفرضه
                حكومة الولايات المتحدة أو تم تحديده من قبل حكومة الولايات
                المتحدة كدولة "داعمة للإرهاب" . (2) أنك غير مدرج في أي قائمة
                حكومية أمريكية للأطراف المحظورة.
              </p>
            </li>
          </ul>

          <p>
            إذا كان موفر التطبيقات هو أبل ستور ، فإنك تقر وتوافق على أن Apple
            والشركات التابعة لها هم أطراف ثالثة مستفيدة من EolA. عند موافقتك على
            اتفاقية EolA هذه ، سيكون لشركة Apple الحق في فرض اتفاقية EolA عليك
            بصفتك طرفًا ثالثًا مستفيدًا.
          </p>
          <p class="text-center"><b>الملحق 2 </b></p>
          <h1 class="text-center">شروط الخدمة-Playhera Shop</h1>
          <h2>نظرة عامة</h2>
          <p>
            يتم تشغيل هذا الموقع بواسطة PLAYHERA. في جميع أنحاء الموقع ، تشير
            مصطلحات "نحن" و "نحن" و "خاصتنا" إلى PLAYHERA. تقدم PLAYHERA هذا
            الموقع ، بما في ذلك جميع المعلومات والأدوات والخدمات المتاحة من هذا
            الموقع لك ، للمستخدم ، بشرط موافقتك على جميع الشروط والأحكام
            والسياسات والإشعارات المذكورة هنا.
          </p>
          <p>
            من خلال زيارة موقعنا و / أو شراء شيء منا ، فإنك تشارك في "الخدمة"
            الخاصة بنا وتوافق على الالتزام بالشروط والأحكام التالية ("شروط
            الخدمة" ، "الشروط") ، بما في ذلك تلك الشروط والأحكام والسياسات
            الإضافية المشار إليه هنا و / أو متاح من خلال ارتباط تشعبي. تنطبق
            شروط الخدمة هذه على جميع مستخدمي الموقع ، بما في ذلك على سبيل المثال
            لا الحصر المستخدمين من المتصفحات و / أو البائعين و / أو العملاء و /
            أو التجار و / أو المساهمين في المحتوى.
          </p>
          <p>
            يرجى قراءة شروط الخدمة هذه بعناية قبل الوصول إلى موقعنا الإلكتروني
            أو استخدامه. من خلال الوصول إلى أو استخدام أي جزء من الموقع ، فإنك
            توافق على الالتزام بشروط الخدمة هذه. إذا كنت لا توافق على جميع شروط
            وأحكام هذه الاتفاقية ، فلا يجوز لك الوصول إلى موقع الويب أو استخدام
            أي من الخدمات. إذا كانت شروط الخدمة هذه بمثابة عرض ، فإن القبول
            يقتصر صراحةً على شروط الخدمة هذه.
          </p>
          <p>
            تخضع أيضًا أي ميزات أو أدوات جديدة تضاف إلى المتجر الحالي لشروط
            الخدمة. يمكنك مراجعة أحدث إصدار من شروط الخدمة في أي وقت على هذه
            الصفحة. نحتفظ بالحق في تحديث أو تغيير أو استبدال أي جزء من شروط
            الخدمة هذه عن طريق نشر التحديثات و / أو التغييرات على موقعنا. تقع
            على عاتقك مسؤولية مراجعة هذه الصفحة بشكل دوري لمعرفة التغييرات.
            استمرار استخدامك أو الوصول إلى موقع الويب بعد نشر أي تغييرات يشكل
            قبولًا لهذه التغييرات.
          </p>
          <p>
            متجرنا مستضاف على Shopify Inc. وهم يزودوننا بمنصة التجارة
            الإلكترونية عبر الإنترنت التي تسمح لنا ببيع منتجاتنا وخدماتنا لك.
          </p>
          <h2>1 - شروط المتجر عبر الإنترنت</h2>
          <p>
            بالموافقة على شروط الخدمة هذه ، فإنك تقر بأنك على الأقل تبلغ سن
            الرشد في ولايتك أو إقليم إقامتك ، أو أنك تبلغ سن الرشد في ولايتك أو
            إقليم إقامتك وأنك منحتنا موافقتك على السماح لأي من المعالين القصر
            لاستخدام هذا الموقع. <br />
            لا يجوز لك استخدام منتجاتنا لأي غرض غير قانوني أو غير مصرح به ولا
            يجوز لك ، في استخدام الخدمة ، انتهاك أي قوانين في ولايتك القضائية
            (بما في ذلك على سبيل المثال لا الحصر قوانين حقوق النشر).<br />
            يجب ألا تنقل أي فيروسات متنقلة أو فيروسات أو أي رمز ذي طبيعة مدمرة.
            <br />
            سيؤدي خرق أو انتهاك أي من الشروط إلى الإنهاء الفوري لخدماتك.
          </p>
          <h2>2 - الشروط العامة</h2>
          <p>
            نحتفظ بالحق في رفض الخدمة لأي شخص لأي سبب وفي أي وقت. <br />
            أنت تدرك أن المحتوى الخاص بك (لا يشمل معلومات بطاقة الائتمان) ، قد
            يتم نقله بدون تشفير ويتضمن (أ) عمليات نقل عبر شبكات مختلفة ؛ و (ب)
            التغييرات للتوافق والتكيف مع المتطلبات الفنية لتوصيل الشبكات أو
            الأجهزة. يتم دائمًا تشفير معلومات بطاقة الائتمان أثناء النقل عبر
            الشبكات. <br />
            أنت تدرك أن المحتوى الخاص بك (لا يشمل معلومات بطاقة الائتمان) ، قد
            يتم نقله بدون تشفير ويتضمن (أ) عمليات نقل عبر شبكات مختلفة ؛ و (ب)
            التغييرات للتوافق والتكيف مع المتطلبات الفنية لتوصيل الشبكات أو
            الأجهزة. يتم دائمًا تشفير معلومات بطاقة الائتمان أثناء النقل عبر
            الشبكات.<br />
            تم تضمين العناوين المستخدمة في هذه الاتفاقية للراحة فقط ولن تقيد هذه
            الشروط أو تؤثر عليها. <br />
          </p>
          <h2>3 - دقة المعلومات واكتمالها وحداثتها</h2>
          <p>
            نحن لسنا مسؤولين إذا كانت المعلومات المتوفرة على هذا الموقع غير
            دقيقة أو كاملة أو حديثة. يتم توفير المواد الموجودة على هذا الموقع
            للحصول على معلومات عامة فقط ولا ينبغي الاعتماد عليها أو استخدامها
            كأساس وحيد لاتخاذ القرارات دون استشارة مصادر المعلومات الأولية أو
            الأكثر دقة أو الأكثر اكتمالًا أو في الوقت المناسب. أي اعتماد على
            المواد الموجودة على هذا الموقع هو على مسؤوليتك الخاصة. <br />
            قد يحتوي هذا الموقع على معلومات تاريخية معينة. المعلومات التاريخية ،
            بالضرورة ، ليست حديثة ويتم توفيرها للرجوع إليها فقط. نحتفظ بالحق في
            تعديل محتويات هذا الموقع في أي وقت ، لكننا لسنا ملزمين بتحديث أي
            معلومات على موقعنا. أنت توافق على أنه من مسؤوليتك مراقبة التغييرات
            على موقعنا. <br />
          </p>
          <h2>4 - تعديلات على الخدمة والأسعار</h2>
          <p>
            أسعار منتجاتنا عرضة للتغيير دون إشعار. <br />
            نحن نحتفظ بالحق في أي وقت في تعديل أو إيقاف الخدمة (أو أي جزء أو
            محتوى منها) دون إشعار في أي وقت. <br />
            لن نكون مسؤولين تجاهك أو تجاه أي طرف ثالث عن أي تعديل أو تغيير في
            الأسعار أو تعليق أو وقف للخدمة. <br />
          </p>
          <h2>5 - المنتجات أو الخدمات</h2>
          <p>
            قد تكون بعض المنتجات أو الخدمات متاحة حصريًا عبر الإنترنت من خلال
            موقع الويب. قد يكون لهذه المنتجات أو الخدمات كميات محدودة وتخضع
            للإرجاع أو الاستبدال فقط وفقًا لسياسة رد الأموال الخاصة بنا لقد
            بذلنا قصارى جهدنا لعرض ألوان وصور منتجاتنا التي تظهر في المتجر بأكبر
            قدر ممكن من الدقة. لا يمكننا ضمان دقة عرض شاشة الكمبيوتر لأي لون.
            <br />
            نحن نحتفظ بالحق ، ولكننا لسنا ملزمين ، بالحد من مبيعات منتجاتنا أو
            خدماتنا لأي شخص أو منطقة جغرافية أو ولاية قضائية. يجوز لنا ممارسة
            هذا الحق على أساس كل حالة على حدة. نحتفظ بالحق في تحديد كميات أي
            منتجات أو خدمات نقدمها. تخضع جميع أوصاف المنتجات أو أسعار المنتجات
            للتغيير في أي وقت دون إشعار ، وفقًا لتقديرنا الخاص. نحتفظ بالحق في
            إيقاف أي منتج في أي وقت. يعتبر أي عرض لأي منتج أو خدمة يتم تقديمه
            على هذا الموقع باطلاً إذا كان محظورًا. <br />
            نحن لا نضمن أن جودة أي من المنتجات أو الخدمات أو المعلومات أو المواد
            الأخرى التي اشتريتها أو حصلت عليها ستفي بتوقعاتك ، أو أنه سيتم تصحيح
            أي أخطاء في الخدمة.. <br />
          </p>
          <h2>6 - دقة الفواتير ومعلومات الحساب</h2>
          <p>
            نحتفظ بالحق في رفض أي طلب تضعه معنا. يجوز لنا ، وفقًا لتقديرنا الخاص
            ، تحديد أو إلغاء الكميات المشتراة لكل شخص أو لكل أسرة أو لكل طلب. قد
            تشمل هذه القيود الطلبات المقدمة من قبل أو تحته نفس حساب العميل و /
            أو نفس بطاقة الائتمان و / أو الطلبات التي تستخدم نفس عنوان الفوترة و
            / أو الشحن. في حالة قيامنا بإجراء تغيير أو إلغاء طلب ، فقد نحاول
            إخطارك عن طريق الاتصال بالبريد الإلكتروني و / أو عنوان إرسال
            الفواتير / رقم الهاتف المقدم في وقت تقديم الطلب. نحن نحتفظ بالحق في
            تقييد أو حظر الطلبات التي ، في حكمنا الوحيد ، يبدو أنها مقدمة من
            التجار أو البائعين أو الموزعين.
          </p>
          <p>
            أنت توافق على تقديم معلومات الشراء والحساب الحالية والكاملة والدقيقة
            لجميع عمليات الشراء التي تتم في متجرنا.
          </p>
          <p>
            أنت توافق على تحديث حسابك والمعلومات الأخرى على الفور ، بما في ذلك
            عنوان بريدك الإلكتروني وأرقام
          </p>
          <p>
            بطاقات الائتمان وتواريخ انتهاء الصلاحية ، حتى نتمكن من إكمال
            معاملاتك والاتصال بك حسب الحاجة.
          </p>
          <p>لمزيد من التفاصيل ، يرجى مراجعة سياسة رد الأموال لدينا</p>
          <p></p>
          <h2>7 - الأدوات الاختيارية</h2>
          <p>
            قد نوفر لك إمكانية الوصول إلى أدوات الجهات الخارجية التي لا نراقبها
            ولا نملك أي تحكم أو مدخلات.
          </p>
          <p>
            أنت تقر وتوافق على أننا نوفر الوصول إلى هذه الأدوات "كما هي" و "كما
            هي متوفرة" دون أي ضمانات أو إقرارات أو شروط من أي نوع ودون أي
            مصادقة. لن نتحمل أي مسؤولية من أي نوع تنشأ عن أو تتعلق باستخدامك
            لأدوات اختيارية تابعة لجهات خارجية.
          </p>
          <p>
            أي استخدام من جانبك للأدوات الاختيارية المقدمة من خلال الموقع يكون
            على مسؤوليتك الخاصة وتقديرك تمامًا ويجب عليك التأكد من أنك على دراية
            بالمصطلحات التي يتم توفير الأدوات من خلالها والموافقة عليها من قبل
            موفر (موفري) الطرف الثالث ذي الصلة.
          </p>
          <p>
            قد نقدم أيضًا ، في المستقبل ، خدمات و / أو ميزات جديدة من خلال موقع
            الويب (بما في ذلك إصدار أدوات وموارد جديدة). تخضع هذه الميزات و / أو
            الخدمات الجديدة أيضًا لشروط الخدمة
          </p>
          <h2>8 - روابط الطرف الثالث</h2>
          <p>
            قد تتضمن بعض المحتويات والمنتجات والخدمات المتوفرة عبر خدمتنا مواد
            من أطراف ثالثة.
          </p>
          <p></p>
          <p>
            قد توجهك روابط الجهات الخارجية الموجودة على هذا الموقع إلى مواقع ويب
            تابعة لجهات خارجية غير تابعة لنا. نحن لسنا مسؤولين عن فحص أو تقييم
            المحتوى أو الدقة ولا نضمن ولن نتحمل أي مسؤولية أو مسؤولية عن أي مواد
            أو مواقع خاصة بطرف ثالث ، أو عن أي مواد أو منتجات أو خدمات أخرى
            لأطراف ثالثة.
          </p>
          <p></p>
          <p>
            نحن لسنا مسؤولين عن أي ضرر أو أضرار تتعلق بشراء أو استخدام السلع أو
            الخدمات أو الموارد أو المحتوى أو أي معاملات أخرى تتم فيما يتعلق بأي
            مواقع ويب تابعة لجهات خارجية. يرجى مراجعة سياسات وممارسات الطرف
            الثالث بعناية والتأكد من فهمك لها قبل الدخول في أي معاملة. يجب توجيه
            الشكاوى أو المطالبات أو المخاوف أو الأسئلة المتعلقة بمنتجات الطرف
            الثالث إلى الجهة الخارجية.
          </p>
          <p></p>
          <h2>9 - تعليقات المستخدم وردود الفعل والطلبات الأخرى</h2>
          <p>
            إذا قمت ، بناءً على طلبنا ، بإرسال بعض عمليات الإرسال المحددة (على
            سبيل المثال إدخالات المسابقة) أو دون طلب منا ، فأنت ترسل أفكارًا أو
            اقتراحات أو مقترحات أو خططًا إبداعية أو مواد أخرى ، سواء عبر
            الإنترنت أو بالبريد الإلكتروني أو بالبريد العادي أو بخلاف ذلك (بشكل
            جماعي ، "التعليقات") ، فإنك توافق على أنه يجوز لنا ، في أي وقت ، دون
            قيود ، تعديل ونسخ ونشر وتوزيع وترجمة واستخدام أي تعليقات ترسلها
            إلينا بأي وسيلة أخرى. نحن ولسنا ملزمين (1) بالحفاظ على سرية أي
            تعليقات ؛ (2) لدفع تعويض عن أي تعليقات ؛ أو (3) للرد على أي تعليقات.
          </p>
          <p></p>
          <p>
            يجوز لنا ، ولكن ليس لدينا أي التزام بمراقبة أو تعديل أو إزالة
            المحتوى الذي نحدده وفقًا لتقديرنا الخاص بأنه غير قانوني أو مسيء أو
            مهدد أو تشهيري أو تشهيري أو إباحي أو فاحش أو مرفوض بأي شكل آخر أو
            ينتهك الملكية الفكرية لأي طرف أو شروط خدمة.
          </p>
          <p></p>
          <p>
            أنت توافق على أن تعليقاتك لن تنتهك أي حق لأي طرف ثالث ، بما في ذلك
            حقوق النشر أو العلامات التجارية أو الخصوصية أو الشخصية أو أي حقوق
            شخصية أو حقوق ملكية أخرى. أنت توافق أيضًا على أن تعليقاتك لن تحتوي
            على مواد تشهيرية أو غير قانونية أو مسيئة أو فاحشة ، أو تحتوي على أي
            فيروسات كمبيوتر أو برامج ضارة أخرى يمكن أن تؤثر بأي شكل من الأشكال
            على تشغيل الخدمة أو أي موقع ويب ذي صلة. لا يجوز لك استخدام عنوان
            بريد إلكتروني مزيف ، أو التظاهر بأنك شخص آخر غيرك ، أو تضليلنا أو
            تضليل الأطراف الثالثة فيما يتعلق بأصل أي تعليقات. أنت وحدك المسؤول
            عن أي تعليقات تدلي بها ودقتها. نحن لا نتحمل أي مسؤولية ولا نتحمل أي
            مسؤولية عن أي تعليقات تنشرها أنت أو أي طرف ثالث.
          </p>
          <p></p>
          <h2>10 - المعلومات الشخصية</h2>
          <p>
            يخضع إرسالك للمعلومات الشخصية عبر المتجر لسياسة الخصوصية الخاصة بنا
            ، والتي يمكن الاطلاع عليها
          </p>
          <p></p>
          <h2>11 - الأخطاء وعدم الدقة والسهو</h2>
          <p>
            من حين لآخر ، قد تكون هناك معلومات على موقعنا أو في الخدمة تحتوي على
            أخطاء مطبعية أو عدم دقة أو سهو قد يتعلق بأوصاف المنتج والتسعير
            والعروض الترويجية والعروض ورسوم شحن المنتج وأوقات العبور والتوافر.
            نحتفظ بالحق في تصحيح أي أخطاء أو عدم دقة أو سهو ، وتغيير المعلومات
            أو تحديثها أو إلغاء الطلبات إذا كانت أي معلومات في الخدمة أو على أي
            موقع ويب ذي صلة غير دقيقة في أي وقت دون إشعار مسبق (بما في ذلك بعد
            تقديمك لطلبك) .
          </p>
          <p></p>
          <p>
            نحن لا نتعهد بتحديث أو تعديل أو توضيح المعلومات في الخدمة أو على أي
            موقع ويب ذي صلة ، بما في ذلك على سبيل المثال لا الحصر ، معلومات
            التسعير ، باستثناء ما يقتضيه القانون. لا يوجد تحديث محدد أو تاريخ
            تحديث مطبق في الخدمة أو على أي موقع ويب ذي صلة ، يجب أن يؤخذ للإشارة
            إلى أنه تم تعديل أو تحديث جميع المعلومات الموجودة في الخدمة أو على
            أي موقع ويب ذي صلة.
          </p>
          <p></p>
          <h2>12 - الاستخدامات المحظورة</h2>
          <p>
            بالإضافة إلى المحظورات الأخرى المنصوص عليها في شروط الخدمة ، يُحظر
            عليك استخدام الموقع أو محتواه: (أ) لأي غرض غير قانوني ؛ (ب) حث
            الآخرين على أداء أو المشاركة في أي أعمال غير قانونية ؛ (ج) انتهاك أي
            لوائح أو قواعد أو قوانين أو مراسيم محلية دولية أو فيدرالية أو
            إقليمية أو خاصة بالولاية ؛ (د) التعدي على أو انتهاك حقوق الملكية
            الفكرية الخاصة بنا أو حقوق الملكية الفكرية للآخرين ؛ (هـ) المضايقة
            أو الإساءة أو الإهانة أو الأذى أو التشهير أو التشهير أو الاستخفاف أو
            التخويف أو التمييز على أساس الجنس أو التوجه الجنسي أو الدين أو العرق
            أو العرق أو السن أو الأصل القومي أو الإعاقة ؛ (و) تقديم معلومات
            خاطئة أو مضللة ؛ (ز) لتحميل أو نقل فيروسات أو أي نوع آخر من الشفرات
            الخبيثة التي سيتم استخدامها أو يمكن استخدامها بأي طريقة من شأنها
            التأثير على وظيفة أو تشغيل الخدمة أو أي موقع ويب ذي صلة أو مواقع ويب
            أخرى أو الإنترنت ؛ (ح) لجمع أو تتبع المعلومات الشخصية للآخرين ؛ (1)
            إرسال بريد عشوائي أو احتيالي أو فارم أو ذريعة أو عنكبوت أو زحف أو
            كشط ؛ (ي) لأي غرض فاحش أو غير أخلاقي ؛ أو (ك) للتدخل أو التحايل على
            ميزات الأمان للخدمة أو أي موقع ويب ذي صلة أو مواقع ويب أخرى أو
            الإنترنت. نحتفظ بالحق في إنهاء استخدامك للخدمة أو أي موقع ويب ذي صلة
            لانتهاك أي من الاستخدامات المحظورة
          </p>
          <p></p>
          <h2>13 - إخلاء المسؤولية عن الضمانات؛ تحديد المسؤولية</h2>
          <p>
            نحن لا نضمن أن استخدامك لخدمتنا سيكون دون انقطاع أو في الوقت المناسب
            أو آمنًا أو خاليًا من الأخطاء. نحن لا نضمن أن النتائج التي يمكن
            الحصول عليها من استخدام الخدمة ستكون دقيقة أو موثوقة. أنت توافق على
            أنه يجوز لنا من وقت لآخر إزالة الخدمة لفترات زمنية غير محددة أو
            إلغاء الخدمة في أي وقت دون إخطارك. أنت توافق صراحة على أن استخدامك
            للخدمة أو عدم قدرتك على استخدامها يكون على مسؤوليتك وحدك. الخدمة
            وجميع المنتجات والخدمات المقدمة لك من خلال الخدمة (باستثناء ما هو
            مذكور صراحة من قبلنا) مقدمة "كما هي" و "كما هي متوفرة" لاستخدامك ،
            دون أي تمثيل أو ضمانات أو شروط من أي نوع ، سواء كانت صريحة أو ضمنيًا
            ، بما في ذلك جميع الضمانات الضمنية أو شروط القابلية للتسويق والجودة
            التجارية والملاءمة لغرض معين والمتانة والملكية وعدم الانتهاك. لا
            تتحمل PLAYHERA بأي حال من الأحوال أو مديرينا أو مسؤولينا أو موظفينا
            أو الشركات التابعة لنا أو الوكلاء أو المقاولين أو المتدربين أو
            الموردين أو مقدمي الخدمات أو المرخصين المسؤولية عن أي إصابة أو خسارة
            أو مطالبة أو أي ضرر مباشر أو غير مباشر أو عرضي أو عقابي أو خاص أو
            الأضرار التبعية من أي نوع ، بما في ذلك ، على سبيل المثال لا الحصر ،
            الأرباح المفقودة ، أو الإيرادات المفقودة ، أو المدخرات المفقودة ، أو
            فقدان البيانات ، أو تكاليف الاستبدال ، أو أي أضرار مماثلة ، سواء
            كانت قائمة على العقد ، أو الضرر (بما في ذلك الإهمال) ، أو المسؤولية
            الصارمة أو غير ذلك ، الناشئة عن استخدام أي من الخدمة أو أي منتجات تم
            شراؤها باستخدام الخدمة ، أو لأي مطالبة أخرى تتعلق بأي شكل من الأشكال
            باستخدامك للخدمة أو أي منتج ، بما في ذلك ، على سبيل المثال لا الحصر
            ، أي أخطاء أو سهو في أي محتوى ، أو أي خسارة أو ضرر من أي نوع يحدث
            نتيجة لاستخدام الخدمة أو أي محتوى (أو منتج) يتم نشره أو نقله أو
            إتاحته بطريقة أخرى عبر الخدمة ، حتى لو تم التنبيه بإمكانية ذلك.
            نظرًا لأن بعض الولايات أو الولايات القضائية لا تسمح باستثناء أو
            تحديد المسؤولية عن الأضرار التبعية أو العرضية ، في مثل هذه الدول أو
            الولايات القضائية ، يجب أن تكون مسؤوليتنا محدودة إلى أقصى حد يسمح به
            القانون.
          </p>
          <p></p>
          <h2>14 - التعويض</h2>
          <p>
            أنت توافق على تعويض شركة PLAYHERA والدفاع عنها وحمايتها من الضرر
            والشركاء الأم والفروع والشركات التابعة والشركاء والمسؤولين والمديرين
            والوكلاء والمقاولين والمرخصين ومقدمي الخدمات والمقاولين من الباطن
            والموردين والمتدربين والموظفين ، من أي مطالبة أو طلب ، بما في ذلك
            المعقول أتعاب المحاماة ، التي يدفعها أي طرف ثالث بسبب أو الناشئة عن
            خرقك لشروط الخدمة هذه أو المستندات التي تتضمنها بالإحالة ، أو
            انتهاكك لأي قانون أو حقوق طرف ثالث.
          </p>
          <p></p>
          <h2>15 - القابلية للفصل</h2>
          <p>
            في حالة تحديد أي شرط من شروط الخدمة هذه على أنه غير قانوني أو باطل
            أو غير قابل للتنفيذ ، يجب أن يكون هذا الحكم قابلاً للتنفيذ إلى أقصى
            حد يسمح به القانون المعمول به ، ويعتبر الجزء غير القابل للتنفيذ
            منفصلاً عن شروط الخدمة ، لن يؤثر هذا القرار على صلاحية وإنفاذ أي
            أحكام أخرى متبقية.
          </p>
          <p></p>
          <h2>16 - إنهاء العقد</h2>
          <p>
            تظل التزامات ومسؤوليات الأطراف المتكبدة قبل تاريخ الإنهاء سارية بعد
            إنهاء هذه الاتفاقية لجميع الأغراض. شروط الخدمة هذه سارية المفعول ما
            لم يتم إنهاؤها من قبلك أو من قبلنا. يمكنك إنهاء شروط الخدمة هذه في
            أي وقت عن طريق إخطارنا بأنك لم تعد ترغب في استخدام خدماتنا ، أو
            عندما تتوقف عن استخدام موقعنا. إذا أخفقت في حكمنا الوحيد ، أو نشك في
            أنك فشلت ، في الامتثال لأي شرط أو شرط من شروط الخدمة هذه ، فيجوز لنا
            أيضًا إنهاء هذه الاتفاقية في أي وقت دون إشعار مسبق وستظل مسؤولاً عن
            جميع المبالغ المستحقة وإدراج تاريخ الإنهاء ؛ و / أو وفقًا لذلك قد
            يمنعك من الوصول إلى خدماتنا (أو أي جزء منها).
          </p>
          <p></p>
          <h2>17 - الاتفاق الكامل</h2>
          <p>
            لا يشكل إخفاقنا في ممارسة أو إنفاذ أي حق أو شرط من شروط الخدمة هذه
            تنازلاً عن هذا الحق أو الحكم. تشكل شروط الخدمة هذه وأي سياسات أو
            قواعد تشغيل منشورة من قبلنا على هذا الموقع أو فيما يتعلق بالخدمة
            الاتفاقية الكاملة والتفاهم بينك وبيننا ويحكم استخدامك للخدمة ، وتحل
            محل أي اتفاقيات واتصالات ومقترحات سابقة أو معاصرة سواء كانت شفهية أو
            كتابية ، بينك وبيننا (بما في ذلك ، على سبيل المثال لا الحصر ، أي
            إصدارات سابقة من شروط الخدمة). لا يجوز تفسير أي غموض في تفسير شروط
            الخدمة هذه على الجهة الصاغة.
          </p>
          <p></p>
          <h2>18 - القانون المعمول به</h2>
          <p>
            تخضع شروط الخدمة وأية اتفاقيات منفصلة نقدم بموجبها الخدمات لك وتفسر
            وفقًا لقوانين المملكة العربية السعودية
          </p>
          <p></p>
          <h2>19 - تغييرات على شروط الخدمة</h2>
          <p>يمكنك مراجعة أحدث إصدار من شروط الخدمة في أي وقت في هذه الصفحة</p>
          <p></p>
          <p>
            نحتفظ بالحق ، وفقًا لتقديرنا الخاص ، في تحديث أو تغيير أو استبدال أي
            جزء من شروط الخدمة هذه عن طريق نشر التحديثات والتغييرات على موقعنا.
            تقع على عاتقك مسؤولية التحقق من موقعنا بشكل دوري لمعرفة التغييرات.
            استمرار استخدامك أو الوصول إلى موقعنا على الويب أو الخدمة بعد نشر أي
            تغييرات على شروط الخدمة هذه يشكل قبولًا لهذه التغييرات.
          </p>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Terms',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  mounted() {
    this.scrollToApp2();
  },
  created() {
    this.scrollToApp2();
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
  methods: {
    scrollToApp2() {
      if (this.$route.hash === '#app2') {
        const intervalId = setInterval(() => {
          const app2Element = this.$refs.appendix2;
          if (app2Element) {
            clearInterval(intervalId);
            app2Element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    },
  },
};
</script>
<style lang="scss">
.terms-container {
  max-width: 1511px;
  width: 80vw !important;
}
.term {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.text-center {
  text-align: center !important;
}
.terms {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .terms-container {
    width: 90vw !important;
  }
}
</style>
