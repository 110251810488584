<template>
  <div
    class="tournament-menu scroll-wrapper"
    :class="{
      'scrolled-to-left': scrolledToLeft,
      'scrolled-to-right': scrolledToRight,
    }"
  >
    <div
      ref="scroller"
      class="stepper-wrapper scrollbars-hide"
      @scroll="onScroll"
    >
      <HorizontalTabs
        v-bind="{
          tabs: basicSectionsLinks,
          useCustomRoute: true,
          notExactMatch: true,
        }"
        class="stepper"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import { prop } from '@/utils/factories';
import HorizontalTabs from '@/components/common/HorizontalTabs';

export default {
  name: 'Menu',
  components: {
    HorizontalTabs,
  },
  mixins: [translate('_web_tournament_side_menu')],
  props: {
    disabled: prop(Boolean),
  },
  data() {
    return {
      activeIndex: -1,
      basicSectionsLinks: [
        {
          route: {
            name: 'TournamentDashboard',
            params: {
              ...this.$route.params,
              isScrollHandlingDisabled: false,
            },
          },
          key: 'dashboard',
          labelStated: 'Dashboard',
          disabled: this.disabled,
        },
        {
          route: {
            name: 'tournamentPlayers',
            params: {
              ...this.$route.params,
              isScrollHandlingDisabled: false,
            },
          },
          key: 'participants-tab',
          labelStated: 'Participants',
          disabled: this.disabled,
        },
        {
          route: {
            name: 'manageMatches',
            params: {
              ...this.$route.params,
              isScrollHandlingDisabled: false,
            },
          },
          key: 'manage-matches',
          labelStated: 'Brackets',
          disabled: this.disabled,
          // class: 'router-link-exact-active',
        },
        {
          route: {
            name: 'TournamentStandings',
            params: {
              ...this.$route.params,
              isScrollHandlingDisabled: false,
            },
          },
          key: 'brackets-setup',
          label: 'Standings',
          disabled: this.disabled,
        },
      ],
      scrolledToLeft: false,
      scrolledToRight: false,
    };
  },
  computed: {
    ...mapGetters(['currentTournament']),
  },
  watch: {
    $route() {
      this.$nextTick(this.checkActiveLink);
    },
  },
  mounted() {
    this.checkActiveLink();
    this.onScroll();
  },
  methods: {
    onScroll() {
      this.scrolledToLeft = this.$refs.scroller.scrollLeft < 10;
      this.scrolledToRight =
        this.$refs.scroller.scrollWidth - this.$refs.scroller.scrollLeft <
        this.$refs.scroller.clientWidth + 20;
    },
    checkActiveLink() {
      const activeIndex = [
        ...this.$el.querySelectorAll('.horizontal-tabs__item'),
      ].findIndex(
        ({ classList }) =>
          classList.contains('router-link-active') ||
          classList.contains('router-link-alias-active') ||
          classList.contains('router-link-exact-active')
      );

      if (activeIndex === this.activeIndex) {
        return;
      }

      this.activeIndex = activeIndex;

      this.basicSectionsLinks = this.basicSectionsLinks
        .map((item, index) => {
          item.active = this.activeIndex === index;
          return item;
        })
        .map((item, index) => {
          item.subactive = index < this.activeIndex;
          return item;
        });
    },
  },
};
</script>

<style lang="scss">
.tournament-menu.scroll-wrapper {
  position: relative;
  // margin: 0 -0.625rem;
  &:after {
    content: '';
    z-index: 6;
    width: 4rem;
    @include auto-rtl(
      background,
      linear-gradient(to right, transparent 0%, $ph-bg 85%)
    );
    height: 53px;
    position: absolute;
    top: 0;
    @include auto-rtl(right, 0);
  }

  &.scrolled-to-right:after {
    background: transparent;
    display: none;
    content: unset;
  }

  &:before {
    content: '';
    z-index: 7;
    width: 4rem;
    @include auto-rtl(
      background,
      linear-gradient(to left, transparent 0%, $ph-bg 85%)
    );
    height: 53px;
    position: absolute;
    top: 0;
    @include auto-rtl(left, 0);
  }

  &.scrolled-to-left:before {
    background: transparent;
    display: none;
    content: unset;
  }

  // &:not(.scrolled-to-right)::after {
  //   @include auto-rtl(
  //     background,
  //     linear-gradient(to right, transparent 0%, $ph-bg 85%)
  //   );
  // }
  // &:not(.scrolled-to-left)::after {
  //   @include auto-rtl(
  //     background,
  //     linear-gradient(to right, transparent 0%, $ph-bg 85%)
  //   );
  // }
}

.stepper-wrapper {
  background-color: $ph-bg;
  top: $header-height;
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  // margin: 0 -0.625rem;
  padding: 0 0.575rem 0 0.125rem;
  /*  .stepper {
      z-index: 6;
      margin-top: -11px;
      width: calc(100% * 5 / 6);
    }*/
}
</style>
