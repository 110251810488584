<template>
  <div
    class="container container-dummy"
    :class="{ 'unset-height': zeroHeight }"
  >
    <div
      class="row h-100 justify-content-center text-muted"
      :class="[`align-items-${position}`]"
    >
      <div class="col-12 text-center">
        <Title v-if="headerText" class="mt-6 d-block" :text="headerText" />
        <Subheader
          v-if="subheaderText"
          class="mt-4 d-block"
          :text="subheaderText"
        />
        <div class="w-100 font-size-base mt-5 mb-5">
          <slot>{{ text }}</slot>
          <slot name="append" />
        </div>
        <slot name="ctaButton" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/atoms/Title';
import { prop } from '@/utils/factories';
import Subheader from '@/components/atoms/Subheader';

export default {
  name: 'HeraDummyList',
  components: {
    Subheader,
    Title,
  },
  props: {
    iconName: prop(String),
    size: prop([String, Number], 28),
    position: prop(String, 'center'), // 'start', 'end', 'center', 'baseline', 'stretch'
    zeroHeight: prop(Boolean),
    text: prop(String),
    headerText: prop(String),
    subheaderText: prop(String),
  },
};
</script>

<style lang="scss">
.container-dummy {
  min-height: 320px;
  // max-height: 50vh;
  &.unset-height {
    height: unset;
    min-height: unset;
  }
}
</style>
