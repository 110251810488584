import api from '@/api';
import { uniqueId } from '@/utils/lodashUtils';
import Vue from 'vue';

const LIST_KEY = 'chatList_';

export const INITIAL_CONVERSATION = () => {
  return {
    activeConversationUnreadCount: 0,
    lastMsgInConversation: null,
    activeConversationInfo: null,
    activePopoverChat: '',
    popoverChatIsOpened: false,
    chatListKey: uniqueId(LIST_KEY),
    undeliveredMessages: [],
  };
};

const getters = {
  activeConversationUnreadCount: state => state.activeConversationUnreadCount,
  lastMsgInConversation: state => state.lastMsgInConversation,
  activeConversationInfo: state => state.activeConversationInfo,
  activePopoverChat: state => state.activePopoverChat,
  popoverChatIsOpened: state => state.popoverChatIsOpened,
  chatListKey: state => state.chatListKey,
  allUndeliveredMessages: state => state.undeliveredMessages,
};

const mutations = {
  checkUnreadBadge(state, count) {
    state.activeConversationUnreadCount = +count;
  },
  removeUnreadBadge(state) {
    state.activeConversationUnreadCount = 0;
  },
  setLastMsgInConversation(state, { id, data }) {
    state.lastMsgInConversation = { id, data };
  },
  clearToConversationsDefaultState(state) {
    const defaultState = INITIAL_CONVERSATION();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  setActivePopoverChat(state, id) {
    state.activePopoverChat = id;
    console.log('CHAT POPOVER component: active chat attached');
  },
  removeActivePopoverChat(state) {
    state.activePopoverChat = '';
    console.log('CHAT POPOVER component: active chat detached');
  },
  showPopoverChat(state) {
    let isChanged = !state.popoverChatIsOpened;
    state.popoverChatIsOpened = true;
    if (isChanged) {
      console.log('CHAT POPOVER component: chat opened');
      mutations.updateChatListKey(state);
    }
  },
  hidePopoverChat(state) {
    console.log('CHAT POPOVER component: chat closed');
    state.popoverChatIsOpened = false;
  },
  updateChatListKey(state) {
    state.chatListKey = uniqueId(LIST_KEY);
  },
  fetchUndeliveredMessages(state) {
    state.undeliveredMessages =
      JSON.parse(localStorage.getItem('undeliveredMessages')) || [];
  },
  addUndeliveredMessage(state, { message, id }) {
    const { text, attachments } = message;
    const time = Date.now();
    state.undeliveredMessages.unshift({
      id: `${id}-${time}`,
      sent_date: time / 1e3,
      conversationId: id,
      text,
      attachments,
    });
    localStorage.setItem(
      'undeliveredMessages',
      JSON.stringify(state.undeliveredMessages)
    );
  },
  removeUndeliveredMessage(state, id) {
    const index = state.undeliveredMessages.findIndex(
      message => message.id === id
    );
    state.undeliveredMessages.splice(index, 1);
    localStorage.setItem(
      'undeliveredMessages',
      JSON.stringify(state.undeliveredMessages)
    );
  },
};

const actions = {
  createConversation(
    {
      getters: { accessToken },
    },
    login
  ) {
    return api.conversations
      .createConversation(login)(accessToken)
      .then(res => res.data);
  },
  createGroupConversation(
    {
      getters: { accessToken },
    },
    data
  ) {
    return api.conversations
      .createGroupConversation(accessToken, data)
      .then(res => res.data);
  },
  fetchConversations(
    {
      commit,
      getters: { accessToken },
    },
    {
      pageToken = null,
      pageSize = 20,
      correlationId = null,
      correlationType = null,
      includeParticipants = false,
      hasMessages = false,
      Filter = null,
    } = {}
  ) {
    return api.conversations
      .getConversations(
        accessToken,
        pageToken,
        pageSize,
        correlationId,
        correlationType,
        includeParticipants,
        hasMessages,
        Filter
      )
      .then(res => {
        commit('setUnreadConversations', res.data.unread_count);
        if (correlationType === 'tournament') {
          commit('setSliderChatUnreadCount', res.data.unread_count);
        }
        return res.data;
      });
  },
  getConversationInfo(
    {
      commit,
      getters: { accessToken },
    },
    { conversationId, includeParticipants = true } = {}
  ) {
    return api.conversations
      .getConversation(conversationId)(accessToken, {
        params: {
          includeParticipants,
        },
      })
      .then(res => {
        commit('checkUnreadBadge', res.data.unread_count);
        return res.data;
      });
  },
  blockConversation(
    {
      getters: { accessToken },
    },
    { conversationId } = {}
  ) {
    return api.conversations
      .blockApiConversation(conversationId)(accessToken)
      .then(res => {
        return res.data;
      });
  },
  updateConversation(
    {
      getters: { accessToken },
    },
    { id, data }
  ) {
    return api.conversations
      .updateConversation(accessToken, id, data)
      .then(res => res.data);
  },
  deleteConversation(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api.conversations
      .deleteConversation(accessToken, id)
      .then(res => res.data);
  },
  fetchMessages(
    {
      getters: { accessToken },
    },
    { conversationId, pageToken, focus, focusDate }
  ) {
    return api.conversations
      .getMessages(accessToken, conversationId, pageToken, focus, focusDate)
      .then(res => res.data);
  },
  sendMessage(
    {
      commit,
      getters: { accessToken },
    },
    {
      id,
      data: { text, attachments = [], sent_date = 0 },
    }
  ) {
    return api.conversations
      .postMessage(
        accessToken,
        id,
        text,
        attachments.map(attach => attach.fileId)
      )
      .then(res => {
        commit('setLastMsgInConversation', {
          id,
          data: {
            last_message: text,
            last_active: sent_date,
          },
        });
        return res.data;
      })
      .catch(err => {
        commit('addUndeliveredMessage', { message: { text, attachments }, id });
        throw err;
      });
  },
  deleteMessage(
    {
      getters: { accessToken },
    },
    { chatId, msgId }
  ) {
    return api.conversations
      .deleteMessage(accessToken, chatId, msgId)
      .then(res => res.data);
  },
  checkConversationAsRead(
    {
      commit,
      getters: { accessToken },
    },
    id
  ) {
    return api.conversations.conversationAsRead(accessToken, id).then(res => {
      commit('removeUnreadBadge');
      return res.data;
    });
  },
  checkMessageAsRead(
    {
      commit,
      getters: { accessToken },
    },
    { chatId, msgId }
  ) {
    return api.conversations
      .messageAsRead(accessToken, chatId, msgId)
      .then(res => {
        if (res.read) {
          commit('addUnreadConversations', -1);
        }
        return res.data;
      });
  },
  translateMsg(
    {
      getters: { accessToken },
    },
    { conversationId, lang, message_ids }
  ) {
    return api.conversations
      .getTranslateMsg(accessToken, conversationId, lang, message_ids)
      .then(res => res.data);
  },

  /**
   *Create (or find) conversation in tournament round
   * @param accessToken
   * @param {String} tournamentId
   * @param matchId
   * @param {Number} round
   * @param {String} conversationType Enum "channel", "group_chat"
   * @param {String} targetGroup Enum "All", "Winners", "Losers"
   * @param {Boolean} captainsOnly
   * @param {Boolean} visible
   * @param {Boolean} blockId
   * @returns {Promise} Response with ID of new (or finded) conversation
   */
  createTournamentConversationBySignature(
    {
      getters: { accessToken },
    },
    {
      tournamentId,
      matchId,
      round,
      grid,
      conversationType,
      targetGroup,
      captainsOnly,
      visible,
      blockId,
    }
  ) {
    let chatId;
    return api.tournaments
      .createTournamentConversation(tournamentId)(accessToken, {
        conversation_type: conversationType,
        visible,
        participants: [],
      })
      .then(res => (chatId = res.data.id))
      .then(() =>
        api.tournaments.addAllTournamentConversationAvailablePlayers(
          tournamentId
        )(accessToken, {
          conversationId: chatId,
          category: 'Registrants',
          round,
          grid,
          matchId,
          captainsOnly,
          targetGroup: targetGroup,
          blockId,
        })
      )
      .then(() => api.conversations.getConversation(chatId)(accessToken))
      .then(res => res.data);
  },

  postComplaint(
    {
      getters: { accessToken },
    },
    { entityId, entityType }
  ) {
    return api.complaints
      .postComplaint(accessToken, {
        entity_type: entityType,
        entity_id: entityId,
      })
      .then(res => res.data);
  },
};

export default {
  state: INITIAL_CONVERSATION(),
  getters,
  mutations,
  actions,
};
