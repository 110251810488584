import { mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      drawStageIndex: null,
      drawStageID: null,
      drawLoading: false,
      needToCheckCounters: false,
    };
  },
  methods: {
    ...mapMutations(['updateModalProps', 'setTournamentBlocks']),
    ...mapActions([
      'fetchTournamentStage',
      'fetchTournamentBlocks',
      'drawStage',
      'successNotify',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    setStageDataForDraw(stage) {
      this.drawStageID = stage.id;
      this.drawStageIndex = stage.index;
    },
    clearStageDataForDraw() {
      this.drawStageID = null;
      this.drawStageIndex = null;
    },
    confirmReDraw(stage) {
      this.setStageDataForDraw(stage);
      this.showDrawConfirmModal(
        this.$t(
          '_web_tournament-block_qualifiers_redraw_brackets_modal_title',
          'This action will reset bracket and draw it with current qualifiers list.'
        )
      );
    },
    confirmDraw(stage) {
      this.setStageDataForDraw(stage);
      this.showDrawConfirmModal(
        this.$t(
          '_web_stage_qualifiers_draw_modal_title',
          `This action will trigger authomatic scheduling for this.
           Meanwhile you will have a possibility to edit stage grid
           before tournament starts. Do you want to continue?`
        )
      );
    },
    showDrawConfirmModal(text) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text,
          isLoading: this.drawLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.drawAction,
        },
      });
    },
    drawAction() {
      this.updateModalProps({ isLoading: true });
      return this.drawStage({
        tournamentID: this.$route.params.tournamentID,
        stageID: this.drawStageID,
      })
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament_players_was-drawn',
              'Stage was successfully drawn'
            )
          );
        })
        .catch(this.errorNotify)
        .then(this.refetchBlocks)
        .then(() => {
          if (this.onFilterChange) {
            this.onFilterChange();
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.updateModalProps({ isLoading: false });
          this.closeModal();
          if (this.updateRouteKey) {
            this.updateRouteKey();
          }
        });
    },
    refetchBlocks(index = this.drawStageIndex) {
      if (isFinite(index)) {
        return this.fetchTournamentBlocks({
          stage: index,
        }).then(blocks => {
          this.setTournamentBlocks(blocks);
          return blocks;
        });
      } else {
        throw new Error('wrong stage index');
      }
    },
  },
};
