<template>
  <Panel
    :variant="[
      'transparent',
      'stroked',
      'stroked-popover-bg',
      'stroked-dashed',
      'rounded-large',
    ]"
    class="add-block-cover d-flex justify-content-center align-items-center p-5 cursor-pointer"
  >
    <Icon
      class="d-block text-center h-mr-3"
      name="add"
      width="22"
      fill="white"
    />
    <TextBlock :size="0.875">
      <strong>{{
        $t('_web_tournament_stage_add-group-btn', 'Add Group')
      }}</strong>
    </TextBlock>
  </Panel>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import Panel from '@/components/atoms/Panel';
export default {
  name: 'AddTournamentBlockCover',
  components: { TextBlock, Icon, Panel },
};
</script>

<style lang="scss">
.add-block-cover {
  min-height: 70px;
}
</style>
