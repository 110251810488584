<template>
  <div>
    <component :is="activeComponent[accountType]" class="position-relative" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as ACCOUNT from '@/constants/accounts';

const Profile = () => import('./Profile');
const Clan = () => import('./Clan');
const ClanMember = () => import('./ClanMember');
const Organization = () => import('./Organization');
const Tournament = () => import('./Tournament');
//const Battle = () => import('./Battle');

export default {
  name: 'AccountStatusBarActions',
  computed: {
    ...mapState('current', ['accountType', 'account']),
    activeComponent() {
      return {
        [ACCOUNT.PROFILE]: Profile,
        [ACCOUNT.ORGANIZATION]: Organization,
        [ACCOUNT.CLAN]: this.account.isMember ? ClanMember : Clan,
        [ACCOUNT.TOURNAMENT]: Tournament,
        //[ACCOUNT.BATTLE]: Battle,
      };
    },
  },
};
</script>
