<template>
  <transition name="fade">
    <div v-if="value" class="lock-screen"></div>
  </transition>
</template>

<script>
import { prop } from '@/utils/factories';

export default {
  name: 'LockScreen',
  props: {
    value: prop(Boolean),
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
};
</script>

<style lang="scss">
.lock-screen {
  @include fill-border(fixed);
  z-index: 2000;
  background: rgba(0, 0, 0, 0.2);
}
</style>
