<template>
  <popover
    class="dropbox-list"
    v-bind="{
      position: positionReplaced,
      float,
      regard,
      targetSelector,
      closest,
      isFixed,
      blockIsFixed,
      translate,
    }"
    :style="stylePopover"
    :custom="custom"
    :nofix="nofix"
    @toggle="$emit('toggle', $event)"
  >
    <slot name="header"></slot>
    <ListPreloader
      class="h-100"
      :is-loading="loader ? !loader : !items"
      :is-empty-list="isEmptyList"
      :dummy-props="{
        iconName: emptyListIcon,
        subheaderText: $t('_web_popover-menu_is-empty', 'Not Found'),
        ...dummyProps,
      }"
    >
      <div slot="empty" class="popover-dummy">
        <slot v-if="!items" name="dummy-preload">
          {{ $t('_web_popover-menu_is-loading', 'Loading...') }}
        </slot>
      </div>
      <div
        v-show="items && items.length"
        slot="list"
        ref="listWrapper"
        class="overflow-hidden list-wrapper"
        tabindex="-3"
        @mousewheel.stop="focusIn"
      >
        <div
          class="position-relative h-text-left custom-scrollbar"
          :style="styleContent"
        >
          <InfiniteScroll
            ref="infinityScroll"
            tag="ul"
            class="list-unstyled mb-0 px-0 overflow-hidden"
            :list="items"
            :list-name="popoverList"
            :is-disabled="scrollIsDisable"
            in-container
            :activation-gap="50"
            @ScrollToBottom="$emit('scrollToBottom')"
          >
            <template slot-scope="{}">
              <li
                v-for="(item, index) in items"
                :key="`${popoverList}_${index}`"
                @click.prevent.stop="selected(item)"
              >
                <slot v-bind="{ item, index }"></slot>
              </li>
            </template>
          </InfiniteScroll>
        </div>
      </div>
    </ListPreloader>
    <slot name="footer"></slot>
  </popover>
</template>

<script>
import { uniqueId } from '@/utils/lodashUtils';
import { generateStyle } from '@/utils/getProps';
import popover from './';
import ListPreloader from '@/components/common/HeraListPreloader';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import { prop } from '@/utils/factories';

export default {
  name: 'PopoverList',
  components: {
    ListPreloader,
    InfiniteScroll,
    popover,
  },
  props: {
    loader: prop(Boolean),
    nofix: prop(String),
    custom: prop(String),
    position: {
      type: [Object, DOMRect, Node],
      default: null,
    },
    targetSelector: prop(String),
    closest: {
      type: Node, // closest element position must be 'relative'
      default: null,
    },
    float: prop(Array),
    regard: prop(String, 'top'),
    items: prop(Array, null),
    autoClose: prop(Boolean),
    isFixed: prop(Boolean),
    blockIsFixed: prop(Boolean),
    width: prop([Number, String, Array], null),
    height: prop([Number, String, Array], null),
    scrollIsDisable: prop(Boolean),
    emptyListIcon: prop(String, 'search-mute'),
    translate: prop([String, Number], 0),
    dummyProps: prop(Object),
    transferToBody: prop(Boolean),
  },
  data() {
    return {
      popoverList: uniqueId('popoverList_'),
      positionReplaced: this.position,
    };
  },
  computed: {
    isEmptyList() {
      return this.items && !this.items.length;
    },
    stylePopover() {
      return this.width ? generateStyle('width', this.width) : null;
    },
    styleContent() {
      return this.height ? generateStyle('height', this.height) : null;
    },
  },
  watch: {
    position(value) {
      this.positionReplaced = value;
    },
  },
  mounted() {
    if (this.transferToBody) {
      document.body.appendChild(this.$el);
    }
  },
  methods: {
    ping() {
      this.$refs.infinityScroll.debounceTestPage();
    },
    selected(item) {
      if (this.autoClose) {
        this.positionReplaced = null;
      }
      this.$emit('selected', item);
    },
    focusIn(event) {
      const wrapper = this.$refs.listWrapper;
      if (!wrapper) {
        return;
      }

      if (!document.activeElement.closest('.list-wrapper')) {
        event.preventDefault();
        wrapper.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.dropbox-list {
  & > div > div {
    position: relative;
    width: 100%;
  }
  .list-loader {
    min-height: 128px;
    .container {
      height: 128px;
    }
  }
  @include popover-menu-items;
}
</style>
