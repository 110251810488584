<template>
  <div>
    <div
      v-if="!createPostModal && isAuthorized"
      class="open-create-post-mobile"
      @click="setCreatePostModal(!createPostModal)"
    >
      +
    </div>
    <div
      v-if="createPostModal && isAuthorized"
      class="close-create-post-mobile"
      @click="setCreatePostModal(!createPostModal)"
    >
      +
    </div>
    <div v-if="isAuthorized" class="feed-category-wrap-mobile">
      <TrendingFollowing />
    </div>
    <HighlightsBanner></HighlightsBanner>
    <div class="feed-layout-wrap-hr">
      <aside class="aside-wrapper">
        <div class="aside-navigation custom-scrollbar">
          <TrendingFollowing />
          <div v-if="isAuthorized" class="braking-line"></div>
          <CreatePostFeed v-if="isAuthorized" />
          <div v-if="isAuthorized" class="braking-line"></div>
          <TopUsers v-if="feedType === 'trending' && isAuthorized" />
          <Following v-if="feedType === 'following'" />
          <div v-if="feedType === 'following'" class="braking-line"></div>
          <TopOrganisersFeed />
          <div class="braking-line"></div>
          <SidebarFooter ref="sidebarFooter" class="feedFooter" />
        </div>
      </aside>
      <div class="content-wrapper-feed-hr">
        <Modal :show="createPostModal" @close="setCreatePostModal(false)">
          <CreatePostNew
            v-if="isAuthorized"
            :author-model="account"
            class="mb-6"
            @post-created="prependNewPost"
          />
        </Modal>
        <!-- <div class="my-feed-page content-feed-wrap-hr">
        <div class=" py-0 justify-content-center">
          <div class="col-12 col-md-10 col-xl-8"> -->
        <div v-if="$route.params.tag" class="mb-5 tag">
          <GoBackBtn
            history-mode
            hide-label
            class="pl-0 pr-0 mr-5"
            style="min-width: 16px"
          />
          #{{ $route.params.tag }}
        </div>
        <!-- <CreatePost
          v-else
          :author-model="account"
          class="mb-6"
          @post-created="prependNewPost"
        /> -->
        <ListLoader
          :is-loading="isLoading"
          :is-empty-list="!currentList.length"
          :dummy-props="{
            iconName: 'dummy/newsfeed',
            size: 200,
            headerText: $route.params.tag
              ? $t('_web_players_dummy-list', 'We couldn\'t find anything.')
              : $t('_web_empty-wall', 'This feed is empty'),
            text: $t(
              '_web_empty-wall_subheader',
              'It seems there are no shared news, insights, or other interesting stuff'
            ),
          }"
          class="w-100"
          @reload="currentFetchMethod"
        >
          <template slot="list">
            <InfiniteScroll
              :list="filteredPosts"
              list-name="postsList"
              :class="'list-hr'"
              :is-disabled="isLoading || nextPageToken === null"
              :activation-gap="100"
              @ScrollToBottom="currentFetchMethod"
            >
              <div
                ref="popoverClosest"
                slot-scope="{ postsList }"
                class="position-relative list-hr "
              >
                <template v-for="post in postsList">
                  <PopularClansPost
                    v-if="post.post_type === POPULAR_CLANS && isAuthorized"
                    :key="post.key"
                  />
                  <PostBanner
                    v-else-if="post.post_type === BANNER"
                    :key="post.key"
                    :image="post.post.image"
                    :url="post.post.url"
                  />
                  <ClanReminder
                    v-else-if="
                      post.post_type === CLAN_REMINDER &&
                        isClanReminderVisible &&
                        isAuthorized
                    "
                    :key="post.key"
                  />
                  <CreatePostEdit
                    v-else-if="
                      isAuthorized &&
                        !$lodash.isEmpty(post.post || post) &&
                        postToEdit === post.post.id
                    "
                    :key="post.key"
                    :author-model="account"
                    :post-model="post.post"
                    class="mb-6"
                    @post-created="updatePost"
                    @post-cancel="cancelEdit"
                  />
                  <Post
                    v-else-if="!$lodash.isEmpty(post.post || post)"
                    :key="post.key"
                    v-observe-visibility="{
                      callback: isVisible =>
                        handleScrollIntoViewForViewsTracking(
                          isVisible,
                          post.key,
                          'post'
                        ),
                      intersection: {
                        threshold: 0.1,
                      },
                    }"
                    :post="post.post || post"
                    :type="post.post_type"
                    class="mb-5 post-adjust"
                    @deleted="removePost"
                    @dotsClick="event => openPopoverMenu(event, post)"
                    @reposted="onPostReposted"
                    @pinned="onPostPinned"
                  />
                </template>
                <Popover
                  :position="popoverPosition"
                  :closest="$refs.popoverClosest"
                  :post="popoverPost"
                  @edit="editPost"
                  @deleted="removePost"
                  @authorBlocked="refetchFeed"
                  @toggle="closePopover"
                />
              </div>
            </InfiniteScroll>
            <!-- <HeraDesktopFooter
              class="feedFooter"
              :show-region-switcher="true"
            /> -->
          </template>
        </ListLoader>
      </div>
    </div>
  </div>
  <!-- </div>    
  </div>
  </div> -->
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import CreatePostNew from '@/components/posts/CreatePostNew';
import CreatePostEdit from '@/components/posts/CreatePostEdit';
import TrendingFollowing from '@/views/myFeed/sidebar/TrendingFollowing.vue';
import Post from '@/components/posts/PostItem';
import HighlightsBanner from '@/components/homepage/HighlightsBanner';
import PopularClansPost from '@/components/posts/popularClans/Post';
import Popover from '@/components/posts/PostPopover';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import ListLoader from '@/components/common/HeraListPreloader';
import GoBackBtn from '@/components/common/GoBackBtn';
import listMixin from '@/mixins/lists';
import viewsTrackingMixin from '@/mixins/metrics';
import ClanReminder from '@/components/posts/ClanReminder';
import TopUsers from '@/views/myFeed/sidebar/TopUsers.vue';
import Following from '@/views/myFeed/sidebar/Following.vue';
import PostBanner from '@/components/posts/PostBanner.vue';
// import HeraDesktopFooter from '@/components/footer/HeraDesktopFooter';
import CreatePostFeed from '@/views/myFeed/sidebar/CreatePost.vue';
import Modal from '@/views/myFeed/sidebar/CreatePostModal.vue';
import SidebarFooter from '@/components/footer/SidebarFooter.vue';
import TopOrganisersFeed from '@/views/myFeed/sidebar/TopOrganisersFeed';

import { PLAY_MARKET, APP_STORE } from '@/constants/appLinks';
import {
  GENERIC,
  TOURNAMENT_FINISHED,
  TOURNAMENT_ANNOUNCED,
  POLL,
  POPULAR_CLANS,
  CLAN_REMINDER,
  BANNER,
} from '@/constants/postTypes';
const AVAILABLE_TYPES = [
  GENERIC,
  TOURNAMENT_FINISHED,
  TOURNAMENT_ANNOUNCED,
  POLL,
  POPULAR_CLANS,
  CLAN_REMINDER,
  BANNER,
];

export default {
  name: 'MyFeed',
  components: {
    CreatePostNew,
    CreatePostEdit,
    CreatePostFeed,
    Modal,
    HighlightsBanner,
    GoBackBtn,
    Post,
    Popover,
    InfiniteScroll,
    ListLoader,
    PopularClansPost,
    ClanReminder,
    PostBanner,
    // HeraDesktopFooter,
    TopUsers,
    Following,
    TrendingFollowing,
    SidebarFooter,
    TopOrganisersFeed,
  },
  mixins: [...listMixin, viewsTrackingMixin],
  data() {
    return {
      posts: [],
      postToEdit: '',
      popoverPosition: null,
      popoverPost: null,
      POPULAR_CLANS,
      CLAN_REMINDER,
      BANNER,
      lastKnownScrollPosition:
        JSON.parse(localStorage.getItem('feedScroll')) || 0,
      ticking: false,
    };
  },
  computed: {
    ...mapGetters(['modalIsShow']),
    ...mapGetters('feed', ['feedType', 'createPostModal']),
    ...mapGetters('my/clans', ['hasClans']),
    ...mapGetters(['isAuthorized']),

    ...mapState('my', ['account']),
    currentList() {
      return this.feedType === 'following'
        ? this.filteredPosts
        : this.trendingPosts;
    },
    currentFetchMethod() {
      return this.feedType === 'following'
        ? this.fetchFollowingPosts
        : this.fetchTrendingPosts;
    },
    trendingPosts() {
      return this.posts;
    },
    filteredPosts() {
      return this.posts.filter(({ post_type }) => {
        return AVAILABLE_TYPES.includes(post_type);
      });
    },
    isClanReminderVisible() {
      return !this.hasClans;
    },

    appLinks() {
      return [
        {
          title: this.$t(
            '_web_newsfeed_app-links-appstore',
            'Download from Appstore'
          ),
          href: APP_STORE,
          icon: 'appstore-logo',
        },
        {
          title: this.$t(
            '_web_newsfeed_app-links-playmarket',
            'Download from Google Play'
          ),
          href: PLAY_MARKET,
          icon: 'playmarket',
        },
      ];
    },
  },
  watch: {
    '$route.params.tag'() {
      this.nextPageToken = undefined;
      this.fetchPosts();
    },
    createPostModal() {
      document.body.style.overflowY === 'hidden'
        ? (document.body.style.overflowY = 'unset')
        : (document.body.style.overflowY = 'hidden');
    },
    feedType() {
      this.nextPageToken = undefined;
      this.posts = [];
      this.fetchPosts();
    },
  },
  created() {
    this.fetchPosts();
  },
  mounted() {
    this.fetchPosts();
    let lastPosition = this.lastKnownScrollPosition;
    if (lastPosition) {
      setTimeout(() => {
        window.scrollTo({ top: lastPosition, behavior: 'instant' });
      }, 500);
    }
    window.addEventListener('scroll', this.setScrollPosition);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setScrollPosition);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions([
      'fetchUserNewsFeeds',
      'fetchNewsFeedsByTag',
      'togglePostPin',
      'fetchTrendingNewsFeeds',
      'errorNotify',
    ]),
    ...mapActions('feed', ['setCreatePostModal']),

    setScrollPosition() {
      this.lastKnownScrollPosition = window.scrollY;
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          localStorage.setItem('feedScroll', this.lastKnownScrollPosition);
          this.ticking = false;
        });

        this.ticking = true;
      }
    },
    handleScroll() {
      const prevElement =
        this.$refs.sidebarFooter.$el.previousElementSibling.getBoundingClientRect()
          .y + window.scrollY;
      const sidebarFooter = this.$refs.sidebarFooter.$el;
      const scrollY = window.scrollY || window.pageYOffset;
      const stickyScrollY =
        prevElement >=
        this.$refs.sidebarFooter.$el.getBoundingClientRect().y + window.scrollY
          ? prevElement - 76
          : this.$refs.sidebarFooter.$el.getBoundingClientRect().y +
            window.scrollY -
            76;

      if (scrollY >= stickyScrollY) {
        sidebarFooter.classList.add('fixed');
      } else {
        sidebarFooter.classList.remove('fixed');
      }
    },

    prependNewPost(post) {
      post.key = this.$lodash.uniqueId('post_');
      this.posts = [post, ...this.posts];
    },
    editPost(post) {
      if (post) {
        this.postToEdit = post.id;
      }
    },
    updatePost(post) {
      if (post) {
        this.posts = this.posts.map(element => {
          if (element.post.id == post.id) {
            element.post.text = post.post.text;
            element.post.attachments = post.post.attachments;
            this.postToEdit = '';
          }
          return element;
        });
      }
    },
    cancelEdit(post) {
      if (post) this.postToEdit = '';
    },

    fetchPosts(first = true) {
      if (this.feedType === 'trending') {
        // fetch trending posts
        this.fetchTrendingPosts(first);
      } else {
        // fetch following posts
        this.fetchFollowingPosts(first);
      }
    },
    switchFeedType(type) {
      this.setFeedType(type);
      this.posts = []; // clear current posts
      this.nextPageToken = undefined; // reset pagination
      this.fetchPosts(); // fetch posts of new types
    },
    fetchFollowingPosts(first) {
      if (this.$route.params.tag && this.nextPageToken !== null) {
        this.isLoading = true;
        this.fetchNewsFeedsByTag({
          page: this.nextPageToken,
          tag: this.$route.params.tag,
        })
          .then(res => {
            this.nextPageToken = res.next_page_token;
            this.posts =
              typeof first === 'boolean'
                ? res.items
                : this.posts.concat(res.items);
          })
          .catch(this.errorNotify)
          .finally((this.isLoading = false));
      } else {
        if (!this.nextPageToken) this.posts = [];
        this.getNextPageData(
          'posts',
          this.fetchUserNewsFeeds({ page: this.nextPageToken }),
          {
            forEach(item, index) {
              item.key =
                item.post.id ||
                `${
                  item.post_type === 'banner'
                    ? Date.now() + '_' + item.post_type
                    : item.post_type
                }_${index}`;
            },
          }
        ).catch(this.errorNotify);
      }
    },
    fetchTrendingPosts(first) {
      if (this.$route.params.tag && this.nextPageToken !== null) {
        this.setLoading();
        this.fetchNewsFeedsByTag({
          page: this.nextPageToken,
          tag: this.$route.params.tag,
        })
          .then(res => {
            this.nextPageToken = res.next_page_token;
            this.posts =
              typeof first === 'boolean'
                ? res.items
                : this.posts.concat(res.items);
          })
          .catch(this.errorNotify)
          .finally(this.unsetLoading);
      } else {
        if (!this.nextPageToken) this.posts = [];
        this.getNextPageData(
          'posts',
          this.fetchTrendingNewsFeeds({ page: this.nextPageToken }),
          {
            forEach(item, index) {
              item.key =
                item.post.id ||
                `${
                  item.post_type === 'banner'
                    ? Date.now() + '_' + item.post_type
                    : item.post_type
                }_${index}`;
            },
          }
        ).catch(this.errorNotify);
      }
    },
    removePost(post) {
      this.posts = this.posts.filter(item => item.post.id !== post.id);
      this.closePopover(false);
    },
    onPostPinned(post) {
      const idx = this.posts.findIndex(el => el.post.id === post.id);

      if (!post.pinned) {
        const updatedPost = this.posts[idx];
        updatedPost.post.pinned = true;
        const newList = this.posts.filter(item => item.post.id !== post.id);
        newList.splice(1, 0, updatedPost);
        this.posts = newList;
        window.scrollTo(0, 0);
      } else {
        this.posts[idx].post.pinned = false;
      }
    },
    refetchFeed() {
      this.nextPageToken = undefined;
      this.posts = [];
      if (this.feedType === 'trending') {
        this.fetchTrendingPosts();
      } else {
        this.fetchFollowingPosts();
      }
    },
    closePopover(isVisible) {
      if (!isVisible && !this.modalIsShow) {
        this.popoverPosition = null;
        /* commented, because modal opened after this function and PostPopover can not delete post (=== null)
        this.popoverPost = null;
        */
      }
    },
    openPopoverMenu(position, { post }) {
      this.popoverPosition = position;
      this.popoverPost = post;
    },
    onPostReposted() {
      if (this.$route.params.tag) this.refetchFeed();
      // fetch the first page of the newsfeed to get repost
      this.fetchUserNewsFeeds().then(({ items }) => {
        this.posts = this.$lodash.uniqBy([...items, ...this.posts], 'post.id');
      });
    },
  },
};
</script>

<style lang="scss">
.tag {
  color: var(--ph-accent);
  font-family: $ph-font-family-secondary;
  font-size: $ph-xl-font-size;
  font-weight: 700;
}

.fixed {
  position: fixed !important;
  top: 0;
  width: fit-content;
  z-index: 10;
  padding-top: 100px;
}

@media (max-width: 767px) {
  .post-adjust {
    padding: 12px;
  }
}
.feed-layout-wrap-hr {
  position: relative;
  display: flex;
  max-width: 1510px !important;
  margin: auto;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .list-loader {
    max-width: 100% !important;
    max-width: -moz-available !important; /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
    max-width: stretch !important;
  }
}
.content-wrapper-feed-hr {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 1100px) {
    width: 100%;
  }
}
.braking-line {
  width: 343px;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background: #30374a;
}
.aside-navigation {
  top: 60px;
  padding-top: 20px;
  position: sticky;
  overflow: hidden;
  height: 100vh;
  width: 355px;
}
.aside-navigation:hover {
  overflow-y: auto;
}
.aside-wrapper {
  min-height: 100%;
  @media (max-width: 1100px) {
    display: none;
  }
}

.feed-category-wrap-mobile {
  padding-top: 0.8rem;
}

@media (min-width: 1100px) {
  .feed-category-wrap-mobile {
    display: none;
  }
}
.open-create-post-mobile {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 100px;
  z-index: 200;
  right: 20px;
  background-color: #5a58f2;
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  @media (min-width: 1100px) {
    display: none;
  }
}
.close-create-post-mobile {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 100px;
  z-index: 200;
  right: 20px;
  background-color: white;
  color: #5a58f2;
  transform: rotate(45deg);
  font-size: 2rem;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  @media (min-width: 1100px) {
    display: none;
  }
}
.post-body {
  @media (max-width: 768px) {
    padding-right: 50px !important;
  }
}
</style>

<style lang="scss" scoped>
// ::v-deep .textarea {
//   background-color: $new-bg-page !important;
// }
::v-deep .create-feed-postEdit .textarea {
  min-height: 2.25rem;
  background-color: #181c25 !important;
}
::v-deep .textarea-container {
  background-color: #181c25 !important;
}
// ::v-deep .comment-attachments {
//   background-color: $new-bg-page !important;
// }
::v-deep .modal-createPostBody {
  .textarea {
    background-color: $new-bg-elevated !important;
  }
  .textarea-container {
    background-color: $new-bg-elevated !important;
  }
  // .comment-attachments {
  //   background-color: $new-bg-elevated !important;
  // }
}
</style>

<style lang="scss" scoped>
::v-deep .feed-layout-wrap-hr {
  @media (max-width: 768px) {
    padding-left: 2%;
    padding-right: 2%;
    .author-tag {
      display: none;
    }
  }
}
::v-deep .post {
  .braking-lineing {
    @include auto-rtl(margin-left, 64px);
  }

  .repost-spacing {
    margin-left: 50px;
  }
  .post-dots {
    position: absolute;
    top: 0;
  }
  .follow-button-newsfeed {
    margin-left: 50px;
    margin-right: 50px;
  }
  .avatar-icon {
    @media (max-width: 768px) {
      width: 32px !important;
      height: 32px !important;
      min-width: unset !important;
    }
  }
  .post-body {
    // padding-right: 2% !important;
    @include auto-rtl(padding-right, 2%);
    @include auto-rtl(padding-left, 62px);
    // padding-left: 62px;
    @media (max-width: 768px) {
      padding-left: 50px !important;
    }
  }
  .img-grid-1 {
    height: 100%;
    img,
    video {
      max-height: 443px;
      object-fit: contain !important;
      width: fit-content;
      max-width: 100%;
      min-width: unset;
    }
    .video-preview {
      width: fit-content;
    }
  }
}

::v-deep .list-loader_wrapper {
  position: relative !important;
  top: 3rem;
}

.feedFooter {
  @include auto-rtl(margin-left, -20px);
}
</style>
