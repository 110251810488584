var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-chat-ps-list"},[_c('ListHeader',{attrs:{"label":_vm.listLabel,"active":_vm.isActive,"loading":_vm.isLoading},on:{"openToggle":_vm.toggleHandler,"addAll":_vm.addAll}}),_c('ListPreloader',{staticClass:"position-absolute",attrs:{"is-loading":_vm.isAdding}}),_c('transition',{attrs:{"name":"slider-chat-list-transition","duration":5000}},[(_vm.isActive)?_c('ul',{staticClass:"slider-chat-ps-list__list"},[_vm._l((_vm.list),function(item){return _c('ListItemAccount',{key:item.id,staticClass:"mb-2",attrs:{"tag":"li","type":_vm.$routerConstants.PROFILE,"secondary-model":item}},[_c('Icon',{staticClass:"cursor-pointer h-mr-1",attrs:{"slot":"action","name":_vm.isSelected(item) ? 'done' : 'add',"width":18},on:{"click":function($event){return _vm.$emit('addParticipant', item)}},slot:"action"})],1)}),_c('div',{ref:"trigger",staticClass:"position-relative slider-chat-ps-list_loader--fixed-height",class:{
          'd-none': !_vm.isLoading && _vm.list.length,
          'is-dummy': !_vm.list.length,
        }},[_c('ListPreloader',_vm._b({attrs:{"is-empty-list":!_vm.list.length && !_vm.isLoading,"dummy-props":{
            iconName: 'dummy/members',
            size: 64,
            subheaderText: _vm.$t(
              '_web_tournament_sidechat_participants-empty-text',
              'You have no participants yet'
            ),
            text: _vm.$t(
              '_web_tournament_sidechat_participants-empty-text_subheader',
              'When participants register or accept your invitation they will appear here'
            ),
          }}},'ListPreloader',{ isLoading: _vm.isLoading },false))],1)],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }