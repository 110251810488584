<script>
import { DateTime } from 'luxon';
import { prop } from '@/utils/factories';
import { translate } from '@/mixins/translate';
import TextBlock from '@/components/atoms/TextBlock';
import { addLeadingZero } from '@/utils';
import { ISOSecondsConvertion } from '@/mixins/dateMethods';

export default {
  name: 'Countdown',
  mixins: [translate('_web_countdown'), ISOSecondsConvertion],
  props: {
    date: {
      type: Number,
      required: true,
    },
    tag: prop(String, 'p'),
    size: prop(Number, 1),
    variant: prop(String, 'primary'),
    separator: prop(String, ' '),
  },
  data() {
    return {
      oneDay: false,
      timer: null,
      outputFormat: ['hours', 'minutes', 'seconds'],
      output: {
        days: '',
        hours: '',
        minutes: '00',
        seconds: '00',
      },
    };
  },
  computed: {
    countdownString() {
      if (this.$lodash.isEmpty(this.output)) {
        return;
      }
      const str = this.$lodash.reduce(
        this.outputFormat,
        (result, val, index) =>
          result +
          `${this.output[val] + this.getTranslation(val)}${
            index <= this.outputFormat.length - 2 ? this.separator : ' '
          }`, // todo incorrect (replace it)
        ''
      );
      return str.trim();
    },
  },
  created() {
    this.updateOutput();
    //TODO think about changing to requestAnimationFrame
    this.timer = setInterval(() => this.updateOutput(), 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getTranslation(t) {
      switch (t) {
        case 'days':
          return this.$$t('');
        case 'hours':
          return this.$$t('');
        case 'minutes':
          return this.$$t('');
        case 'seconds':
          return this.$$t('');
        default:
          return '';
      }
    },
    updateOutput() {
      const diff = DateTime.fromSeconds(this.date)
        .diffNow(this.outputFormat)
        .toObject();
      // const TEN_MINUTES = 600;
      let tempFormat = ['minutes', 'seconds'];
      // debugger;
      if (diff.seconds > 0) {
        tempFormat = ['minutes', 'seconds'];
      }
      if (diff.minutes > 0) {
        tempFormat = ['minutes', 'seconds'];
      }
      if (diff.hours > 0) {
        tempFormat = ['hours', 'minutes', 'seconds'];
      }
      if (diff.days > 0) {
        tempFormat = ['hours', 'minutes', 'seconds'];
      }

      this.outputFormat = tempFormat;
      this.output = this.outputFormat.reduce((acc, el) => {
        return {
          ...acc,
          [el]: addLeadingZero(diff[el] > 0 ? Math.floor(diff[el]) : 0),
        };
      }, {});
      // this.$lodash.each(diff, (val, key) => {
      //   const counter = val > 0 ? Math.floor(val) : 0;
      //   this.$set(this.output, `${key}s`, addLeadingZero(counter));
      // });
    },
  },
  render(createElement) {
    return createElement(
      TextBlock,
      {
        props: {
          tag: this.tag,
          size: this.size,
          variant: this.variant,
        },
      },
      [this.countdownString]
    );
  },
};
</script>
