var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.post)?_c('Panel',{staticClass:"position-relative post",attrs:{"variant":"secondary-card"}},[(_vm.isPinning)?_c('Loader'):_vm._e(),(_vm.post.type !== 'banner')?_c('div',{ref:"postContainer",staticClass:"actions-post-item position-absolute"},[(_vm.post.pinned || _vm.canPinPost)?_c('AppIcon',{staticClass:"cursor-pointer post-pin",class:{ disabled: !_vm.canPinPost },attrs:{"name":"pin","width":"15","fill":_vm.post.pinned ? _vm.$scss.primaryColor : _vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor},on:{"click":_vm.pinAction}}):_vm._e(),(_vm.showFollowButton && _vm.showButton && _vm.isAuthorized)?_c('FollowBtn',{key:_vm.post.id,staticClass:"follow-button-newsfeed",attrs:{"model":_vm.model,"follow-class":"btn-primary-new","hover-class":"btn-hover-new","unfollow-class":"btn-secondary-new"}}):_vm._e(),(_vm.isDotsMenuVisible)?_c('AppIcon',{staticClass:"cursor-pointer post-dots py-2",attrs:{"name":"dots","fill":_vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor},on:{"click":_vm.openPopoverMenu}}):_vm._e()],1):_vm._e(),_c('PostCard',_vm._b({},'PostCard',{ post: _vm.post, type: _vm.type },false)),(_vm.post.original_post)?_c('PostCard',{staticClass:"repost-spacing",attrs:{"post":_vm.post.original_post.post,"type":_vm.post.original_post.post_type,"reposted":""}}):_vm._e(),(_vm.post && _vm.post.reactions)?_c('div',{staticClass:"d-flex mt-5 post-actions"},[_c('a',{staticClass:"like-link",class:{
        active:
          _vm.post &&
          _vm.post.reactions &&
          _vm.post.reactions.like &&
          _vm.post.reactions.like.reacted,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.likeClick($event)}}},[(
          _vm.post &&
            _vm.post.reactions &&
            _vm.post.reactions.like &&
            !_vm.post.reactions.like.reacted
        )?_c('img',{staticClass:"likes",attrs:{"src":require("@/assets/images/comments/like-empty.svg"),"alt":"like button"}}):_c('img',{staticClass:"like-button",attrs:{"src":require("@/assets/images/comments/like-filled.svg"),"alt":"like button"}}),_c('div',{staticClass:"like-count-post",class:{
          'like-count-reacted':
            _vm.post &&
            _vm.post.reactions &&
            _vm.post.reactions.like &&
            _vm.post.reactions.like.reacted,
        }},[_vm._v("\n        "+_vm._s(_vm.post.reactions.like.count || 0)+"\n        "),_c('span',{staticClass:"mobile-hide"},[_vm._v("\n          "+_vm._s(_vm.isRTL
              ? _vm.post.reactions.like.count >= 10
                ? _vm.$t('_web_comment_likes', 'likes')
                : _vm.$t('_web_comment_like', 'like')
              : _vm.post.reactions.like.count === 1
              ? _vm.$t('_web_comment_like', 'like')
              : _vm.$t('_web_comment_likess', 'likes'))+"\n        ")])])]),_c('a',{staticClass:"h-ml-5 comments-link",class:{
        active: _vm.post && _vm.post.comments_count && _vm.post.comments_count === 0,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showComments = !_vm.showComments}}},[_c('AppIcon',{staticClass:"comments",attrs:{"name":"comments","width":"17"}}),_c('div',{staticClass:"text-bottom",class:!_vm.showComments ? '' : 'text-bottom-bold'},[_vm._v("\n        "+_vm._s((_vm.post && _vm.post.comments_count) || 0)+"\n        "),_c('span',{staticClass:"mobile-hide"},[_vm._v("\n          "+_vm._s(_vm.isRTL
              ? _vm.post.comments_count >= 10
                ? _vm.$t('_web_tournament_sidechat_comments', 'comments')
                : _vm.$t('_web_tournament_sidechat_comment', 'comment')
              : _vm.post.comments_count === 1
              ? _vm.$t('_web_tournament_sidechat_comment', 'comment')
              : _vm.$t('_web_tournament_sidechat_comments', 'comments')))])])],1),_c('a',{staticClass:"h-ml-5 repost-link",class:{ active: _vm.post.repost_count > 0 },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function ($event) { return _vm.openSharingMenu({
            event: $event,
            type: 'SinglePost',
            id: _vm.post.id,
            text: _vm.post.text,
          }); })($event)}}},[_c('AppIcon',{staticClass:"repost",attrs:{"name":"share","width":"15"}}),_c('div',{staticClass:"text-bottom"},[_vm._v("\n        "+_vm._s((_vm.post && _vm.post.repost_count) || 0)+"\n        "),_c('span',{staticClass:"mobile-hide"},[_vm._v("\n          "+_vm._s(_vm.isRTL
              ? _vm.post.repost_count > 10
                ? _vm.$t('_web_comments_share', 'share')
                : _vm.$t('_web_comments_shares', 'shares')
              : _vm.post.repost_count === 1
              ? _vm.$t('_web_comments_share', 'share')
              : _vm.$t('_web_comments_shares', 'shares')))])])],1)]):_vm._e(),(_vm.post)?_c('div',{staticClass:"braking-lineing"}):_vm._e(),(_vm.showComments)?_c('Comments',{staticClass:"comments-smaller",attrs:{"post":_vm.post}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }