import { isObject, escape, get } from '@/utils/lodashUtils';
import api from '@/api';
import Vue from 'vue';
import { Subject } from 'rxjs';
import { generateMutations } from '@/utils/store';
import { FIVE_SECONDS, TWO_SECONDS } from '@/constants/time';

export const NOTIFICATIONS_DEFAULT_STATE = () => ({
  notificationsSubject: new Subject(),
  isHubReloadRequired: false,
  lastMessage: '',
});

/**
 * Escape all HTML tags and restore only safe tags
 * @param {string} text
 * @returns {string}
 */
const escapeHTMLTags = text => {
  text = escape(text);
  const allowedTags = ['br', 'strong'];
  return text.replace(
    new RegExp(`&lt;(/?)(${allowedTags.join('|')})(/?)&gt;`, 'gi'),
    `<$1$2$3>`
  );
};

const getters = {
  notificationsSubject: state => state.notificationsSubject,
};

const mutations = {
  clearToNotificationsDefaultState(state) {
    const defaultState = NOTIFICATIONS_DEFAULT_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },

  ...generateMutations('lastMessage'),
};

const actions = {
  signInModal({ dispatch }) {
    dispatch('alerts/confirm', {
      text: 'Please Sign In',
      confirmButtonText: 'signin',
      cancelButtonText: 'cancel',
    });
  },
  modalNotify({ state, commit, dispatch }, payload) {
    if (payload.text !== state.lastMessage) {
      dispatch('alerts/confirm', {
        text: payload.message,
        textKey: payload.messageKey,
        confirmButtonText: payload.yesText,
        cancelButtonText: false,
      });
      commit('setLastMessage', payload.text);
      setTimeout(() => commit('setLastMessage', ''), TWO_SECONDS);
    }
  },
  systemNotify({ state, commit }, payload) {
    if (payload.text !== state.lastMessage) {
      payload.text = escapeHTMLTags(payload.text);
      Vue.notify(
        Object.assign(
          {},
          {
            group: 'system',
            duration: FIVE_SECONDS,
          },
          payload
        )
      );
      commit('setLastMessage', payload.text);
      setTimeout(() => commit('setLastMessage', ''), FIVE_SECONDS);
    }
  },
  errorNotify({ dispatch }, error) {
    const isSilent = isObject(error) && error.silent;
    const showInModal = isObject(error) && error.inModal;
    if (process.env.NODE_ENV !== 'production') {
      //eslint-disable-next-line
      console.warn(error);
    }
    if (error && !isSilent && showInModal) {
      const message =
        isObject(error) && error.hasOwnProperty('message')
          ? error.message
          : error;

      dispatch('modalNotify', { ...error, message });
    }
  },
  successNotify({ dispatch }, payload) {
    const text = get(payload, 'text', payload);
    if (text) {
      dispatch('systemNotify', {
        type: 'success',
        text,
      });
    }
  },
  getNotifications(
    {
      getters: { accessToken },
    },
    { pageSize = 3, pageToken }
  ) {
    return api.notifications
      .getNotifications(accessToken, {
        params: { page_size: pageSize, page: pageToken },
      })
      .then(res => res.data);
  },
  postNotificationAction(
    {
      getters: { accessToken },
    },
    { id, action }
  ) {
    return api.notifications
      .postNotificationAction(id, action)(accessToken)
      .then(res => res.data);
  },

  updateNotificationsStatus(
    {
      getters: { accessToken },
      dispatch,
    },
    IDs
  ) {
    return Promise.all(
      IDs.map(id =>
        api.notifications.updateNotificationStatus(id)(accessToken, {
          read: true,
        })
      )
    ).then(res => {
      dispatch('fetchCounters');
      return res;
    });
  },
  updateAllNotificationsStatuses({ getters: { accessToken } }) {
    return api.notifications.updateAllNotificationsStatus()(accessToken);
  },
  deleteNotificationItem(
    {
      getters: { accessToken },
    },
    id
  ) {
    return api.notifications.deleteNotification(id)(accessToken);
  },
};

export default {
  state: NOTIFICATIONS_DEFAULT_STATE(),
  getters,
  mutations,
  actions,
};
