<template>
  <div class="create-conversation">
    <HeraDummyList
      v-if="isLoading && !playersList.length"
      icon-name="dummy/search-not-found"
      :size="200"
      :subheader-text="
        $t('_web_create-conversation_user-list-searching', 'Searching...')
      "
    />
    <div v-else-if="playersList.length" class="custom-scrollbar">
      <InfiniteScroll
        :list="playersList"
        list-name="playersList"
        :is-disabled="isLoading || nextPageToken === null"
        :activation-gap="200"
        in-container
        @ScrollToBottom="update"
      >
        {{ selectedPlayersModel.length }}
        <div slot-scope="{}" class="font-size-sm">
          <ListItemAccount
            v-for="(player, index) in playersList"
            :key="`player_${index}`"
            :name="$filters.displayName(player)"
            :secondary-model="player"
            tag="label"
            class="d-block py-2 position-relative"
            target="_blank"
          >
            <div slot="action" class="h-ml-3 position-relative">
              <component
                :is="multiSelect ? 'Checkbox' : 'Radio'"
                v-model="selectedPlayers"
                name="selectedPlayers"
                :val="player.login"
              />
            </div>
          </ListItemAccount>
        </div>
      </InfiniteScroll>
    </div>
    <HeraDummyList
      v-else
      icon-name="dummy/members"
      :size="200"
      position="center"
      :subheader-text="
        $t(
          '_web_create-conversation_dummy-user-list',
          'We couldn\'t find anything'
        )
      "
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import HeraDummyList from '@/components/common/HeraDummyList';
import listMixins from '@/mixins/lists';
import Avatar from '@/components/images/Avatar';
import Radio from '@/components/atoms/form/HeraFormRadio';
import Checkbox from '@/components/form/HeraFormCheckbox';
import LinkAccount from '@/components/links/Account';
import ListItemAccount from '@/components/common/ListItemAccount';

export default {
  name: 'ConversationCreateUserList',
  components: {
    ListItemAccount,
    LinkAccount,
    Avatar,
    InfiniteScroll,
    HeraDummyList,
    Radio,
    Checkbox,
  },
  mixins: [...listMixins],
  model: {
    prop: 'selectedPlayersModel',
    event: 'update',
  },
  props: {
    selectedPlayersModel: {
      type: Array,
      default: () => [],
    },
    searchValue: {
      type: String,
      default: 'al',
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playersList: [],
    };
  },
  computed: {
    selectedPlayers: {
      get() {
        return this.multiSelect
          ? this.selectedPlayersModel
          : this.selectedPlayersModel[0];
      },
      set(val) {
        if (!this.multiSelect) {
          val = [val];
        }
        this.$emit('update', val);
      },
    },
  },
  watch: {
    searchValue() {
      this.nextPageToken = undefined;
      this.playersList = [];
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    ...mapActions(['fetchPlayers']),
    onScroll() {
      this.update();
    },
    update() {
      this.getNextPageData(
        'playersList',
        this.fetchPlayers({
          page: this.nextPageToken,
          contains: this.searchValue,
          needCommit: false,
        })
      );
    },
  },
};
</script>

<style lang="scss">
.create-conversation {
  > div {
    position: relative;
    height: calc(50vh - 74px) !important;
    &.custom-scrollbar {
      @include auto-rtl(margin-right, -15px); // bootstrap .row margin-x
    }
  }
  .custom-control-label {
    height: 1rem;
    position: relative;
  }
}
</style>
