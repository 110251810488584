<template>
  <div class="platform-squad-wrapper">
    <TournamentCardPlatform :tournament="tournament" :omit-dot="true" />
    <TournamentCardSquadSize :tournament="tournament" />
  </div>
</template>

<script>
import { propRequired } from '@/utils/factories';
import TournamentCardSquadSize from './TournamentCard/components/SquadSize';
import TournamentCardPlatform from './TournamentCard/components/Platform';

export default {
  name: 'TournamentPlatformAndSquad',
  components: {
    TournamentCardPlatform,
    TournamentCardSquadSize,
  },
  props: {
    tournament: propRequired(Object),
  },
};
</script>

<style lang="scss">
.platform-squad-wrapper {
}
</style>
