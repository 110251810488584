<template>
  <Panel class="w-100 mb-5 panel-account" :variant="variant" :class="join">
    <div class="row">
      <div :class="isThin ? 'col-12 col-xl-8 offset-xl-2' : 'col-12'">
        <Subheader v-if="header" class="mb-5 pb-5">
          {{ header }}
        </Subheader>
        <div v-if="$slots.headerButton" class="panel-account_header-button">
          <slot name="headerButton" />
        </div>
        <TextBlock v-if="dummy" :size="0.85" class="mt-4" variant="muted">
          {{ dummy }}
        </TextBlock>
        <slot v-else-if="!dummy || dynamicDummy" v-show="!dummy" />
      </div>
    </div>
  </Panel>
</template>

<script>
import Subheader from '@/components/atoms/Subheader';
import Panel from '@/components/atoms/Panel';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'AccountPanelWrapper',
  components: {
    Subheader,
    Panel,
    TextBlock,
  },
  props: {
    join: {
      type: String,
      default: '', // append, prepend, center
    },
    isThin: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
    dummy: {
      type: String,
      default: '',
    },
    dynamicDummy: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: [Array, String],
      default: () => ['default', 'rounded', 'half-paddings'],
    },
  },
};
</script>

<style lang="scss">
.panel-account {
  &.prepend {
    @include set-as-zero(
      border-bottom-left-radius,
      border-bottom-right-radius,
      border-bottom,
      padding-bottom,
      'margin-bottom!'
    );
  }
  &.center {
    @include set-as-zero(
      border-radius,
      border-bottom,
      border-top,
      padding-top,
      padding-bottom,
      'margin-bottom!'
    );
  }
  &.append {
    @include set-as-zero(
      border-top-left-radius,
      border-top-right-radius,
      border-top,
      padding-top
    );
  }
  .container-dummy {
    height: 4rem;
  }
  &_header-button {
    position: absolute;
    top: 0;
    @include auto-rtl(right, $ph-medium-space);
  }
}
</style>
