import { DateTime } from 'luxon';
import { NOTIFICATIONS_DATA } from '@/constants/notifications';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';
import { camelCase } from '@/utils/lodashUtils';
import store from '@/store';

const unknownCase = (obj, key) => {
  return (
    obj[key] ||
    obj[camelCase(key)] || // tournament_name => tournamentName
    // obj[snakeCase(key)] || // tournamentName => tournament_name // don't use it (slooooooow function)
    key
  );
};

const replaceData = (wrapObject, gets) => {
  return Object.entries(gets).reduce((res, item) => {
    res[item[0]] = unknownCase(wrapObject, item[1]);
    return res;
  }, {});
};

const createLink = (str, extended, props) => {
  const parseLink = str
    .trim()
    .replace(/^{:(.+?)}$/, '$1')
    .split(':');

  // str === {:foo:bar}  => parseLink.length === 2 => string `bar`
  // str === {:foo::bar} => parseLink.length === 3 => get property `params.bar`

  if (parseLink.length < 2) {
    return;
  }

  let name = parseLink[0] || parseLink[1];
  let params = replaceData(extended, props[name] || {});

  // replace account-link
  if (ROUTER_NAMES_ACCOUNT.ACCOUNT_TYPES.includes(name)) {
    params.accountType = name;
    name = ROUTER_NAMES_ACCOUNT.ACCOUNT;
  }

  const text = parseLink[1] || params[parseLink[2]];

  if (!name) {
    return {
      to: {},
      text,
    };
  }

  return {
    to: {
      name,
      params,
    },
    text,
  };
};

const setLinkParams = (link, props) => {
  const { name, params } = link;
  if (!name) {
    return null;
  }
  if (!params) {
    return link;
  }

  return {
    name,
    params: Object.entries(params).reduce((res, [key, prop]) => {
      res[key] = unknownCase(props, prop);
      return res;
    }, {}),
  };
};

export const notificationFilter = notifications => {
  return (notifications || []).filter(v => {
    return v && NOTIFICATIONS_DATA[v.type];
  });
};

export const generateNotificationByData = data => {
  const { type, extended } = data;
  const notificationData = NOTIFICATIONS_DATA[type];

  if (!notificationData) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable-next-line no-console */
      console.warn(`Notify type "${type}" not found`, data);
    }
    return null;
  }

  // UNIQUE LOGIC FOR MARKETPLACE RELEASE ADVERTISEMENT(TEMP)
  if (type === 'shop_collection_promotion') {
    notificationData.content = data.text;
    notificationData.title = data.subject;
    notificationData.link = store.getters.marketplaceLink + data.extended.url;
  }

  let { content, link } = notificationData;

  const matchRegEx = /{:([^:]+?):+.+?}/g;
  const propsLinkArr = content.match(matchRegEx);
  let links = {};

  if (propsLinkArr) {
    content = content.replace(matchRegEx, '{:$1}');

    Object.assign(
      links,
      propsLinkArr.reduce((res, prop) => {
        const linkProp = prop.replace(matchRegEx, '$1');
        res[linkProp] = createLink(
          prop,
          extended ? extended : {},
          notificationData.renderProps
        );
        return res;
      }, {})
    );
  }

  const linkReplaced = link ? setLinkParams(link, extended) : null;

  if (extended && extended.hasOwnProperty('startDate')) {
    const date = new Date((+extended.startDate || 0) * 1000);
    extended.startDate = DateTime.fromJSDate(date, { zone: 'UTC' }).toFormat(
      'LLLL d'
    );
  }
  if (extended && extended.thread_id) {
    content = content + ' comment in {:thread}';
    links = {
      ...links,
      thread: {
        text: 'thread',
        to: {
          name: 'reply',
          params: { id: extended.post_id, threadId: extended.thread_id },
        },
      },
    };
  }

  return {
    ...notificationData,
    content, // content with variables for use in $t
    contentProps: {
      // props for render in $t
      ...links,
      ...extended,
    },
    linkReplaced, // link for item (click on notification)
  };
};
