<template>
  <div
    :class="{ 'cursor-pointer': fieldIsSortable }"
    @click.stop="$emit('sort', columnName)"
  >
    <TextLabel
      :text="
        $t(
          `_web_participants-list_column-${columnName}`,
          $filters.startCase(columnName)
        )
      "
      variant="caption"
    />
    <Icon
      v-if="fieldIsSortable && sortIcon"
      :name="sortIcon"
      width="16"
      original
    />
  </div>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import TextLabel from '@/components/atoms/TextLabel';

export default {
  name: 'PlayersTableHeadCell',
  components: {
    Icon,
    TextLabel,
  },
  props: {
    columnName: propRequired(String),
    sortIcon: prop(String),
  },
  computed: {
    fieldIsSortable() {
      return (
        this.columnName === 'username' ||
        this.columnName === 'clan_name' ||
        this.columnName === 'approvedTo' ||
        this.columnName === 'blockIndex'
      );
    },
  },
};
</script>
