var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tournament-menu scroll-wrapper",class:{
    'scrolled-to-left': _vm.scrolledToLeft,
    'scrolled-to-right': _vm.scrolledToRight,
  }},[_c('div',{ref:"scroller",staticClass:"stepper-wrapper scrollbars-hide",on:{"scroll":_vm.onScroll}},[_c('HorizontalTabs',_vm._b({staticClass:"stepper"},'HorizontalTabs',{
        tabs: _vm.basicSectionsLinks,
        useCustomRoute: true,
        notExactMatch: true,
      },false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }