<template>
  <MainLayout>
    <div class="terms">
      <div class="terms-container" dir="ltr">
        <h1 class="text-center">
          PLAYHERA MOBILE APPLICATION - END USER LICENCE AGREEMENT
        </h1>

        <p>
          Entertainment Content Company LTD is a company registered in Saudi
          Arabia under company number 1010451300, with its registered office at
          3671 Thumama rd. Al Rabie District - Unit 8
        </p>

        <p>
          Riyadh 13316 - 8536 and with VAT number 310174286700003).
          Entertainment Content Company LTD operates this official Playhera
          mobile application (the "App").
        </p>

        <ol>
          <li>
            <p><b>Understanding these terms</b></p>
            <ol>
              <li>
                <p>
                  This end user licence agreement (this "EolA") describes how
                  you may download the App and access and use the App and the
                  services made available through the App (the "Services"). By
                  accessing the App, this EolA will apply to you and you agree
                  to the terms of this EolA. You shoold therefore read the terms
                  carefolly before using the App.
                </p>
              </li>
              <li>
                <p>
                  When certain words and phrases are used in this EolA, they
                  have specific meanings (these are known as "defined terms").
                  You can identify these defined terms because they start with
                  capital letters (even if they are not at the start of a
                  sentence). Where a defined term is used, it has the meaning
                  given to it in the section of this EolA where it was defined
                  (you can find these meanings by looking at the sentence where
                  the defined term is included in brackets and speech marks).
                </p>
              </li>
              <li>
                <p>
                  In this EolA, when we refer to "we", "us" or "our", we mean
                  Playhera and Entertainment Content Company LTD; and when we
                  refer to "you" or "your" we mean:
                </p>
                <ol>
                  <li>
                    <p>
                      if you are a consumer, the individual using the App for a
                      purpose that is wholly or mainly outside of their trade,
                      business, craft or profession; and
                    </p>
                  </li>
                  <li>
                    <p>
                      if you are not a consumer (for example if you are a
                      professional esports player): (a) the business that you
                      have the authority to bind; or (b) the individual using
                      the App for purposes that are not wholly or mainly outside
                      of their trade, business, craft or profession (in each
                      case a "Business").
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  This App requires a smartphone or other mobile device running
                  either the iOS or Android operating systems (the device you
                  use, the "Device") and, to download the App and to access the
                  latest features, you will need Internet access.
                </p>
              </li>
              <li>
                <p>
                  Please note, however, that certain functions made available on
                  the App are governed by additional terms and conditions,
                  including (for example) your participation in esports
                  competitions. We may also, from time to time, introduce
                  additional features and/or services, and will notify you of
                  any applicable terms and conditions of use of the same.
                </p>
              </li>
              <li>
                <p>
                  In addition to clause 1.5 above, please note that:
                </p>
                <ol>
                  <li>
                    <p>
                      to download the App, you must also review and agree to the
                      additional app terms set out in Appendix 1 to this EolA
                      and any other terms and conditions imposed by the app
                      store from which you have downloaded the App; and
                    </p>
                  </li>
                  <li>
                    <p>
                      we only use your personal information in accordance with
                      our privacy policy (available here, and which also details
                      how we use cookies).
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <p><b>The App</b></p>
            <ol>
              <li>
                <p>
                  The App is made available free of charge. We do not guarantee
                  that the App, or any content on it, will always be available
                  or be uninterrupted. Access to the App is permitted on a
                  temporary basis. We may suspend, withdraw, discontinue or
                  change all or any part of the App without notice. We will not
                  be liable to you if for any reason the App is unavailable at
                  any time or for any period. We may update the App and/or
                  change the content on it at any time.
                </p>
              </li>
              <li>
                <p>
                  You are responsible for making all arrangements necessary for
                  you to have access to the App. You are also responsible for
                  ensuring that all persons who access the App through your
                  internet connection are aware of this EolA and that they
                  comply with them.
                </p>
              </li>
              <li>
                <p>
                  The App and the content on it are provided for general
                  information purposes only. They are not intended to amount to
                  advice on which you shoold rely.
                </p>
              </li>
              <li>
                <p>
                  Unless you are a Business competing in a Competition or as
                  otherwise expressly authorised by us, you may only use the App
                  for your own domestic, private and non-commercial use.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Your account and password</b></p>
            <ol>
              <li>
                <p>
                  You will need to register an account with us on the App in
                  order to access certain services available on the App
                  ("Account"). You can register using the App or via our
                  official website (at www.playhera.com). If you register an
                  Account, you will be asked to provide certain information
                  (such as your email address) and to create a password, as part
                  of our security procedures. You must treat such password as
                  confidential and you must not disclose it to any third party.
                </p>
              </li>
              <li>
                <p>
                  We have the right to disable any Accounts and/or passwords, at
                  any time, if in our reasonable opinion you have failed to
                  comply with any of the provisions of this EolA.
                </p>
              </li>
              <li>
                <p>
                  If you know or suspect that anyone other than you knows your
                  Account login details, you must immediately notify us at
                  <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
                </p>
              </li>
              <li>
                <p>
                  You are responsible for any unauthorised use of your Account
                  login details.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Acceptable use</b></p>
            <ol>
              <li>
                <p>
                  <i><u>General</u></i>
                </p>
                <p>You agree:</p>
                <ol>
                  <li>
                    not to use the App in any unlawfol manner, for any unlawfol
                    purpose or in any manner inconsistent with this EolA;
                  </li>
                  <li>
                    not to infringe our intellectual property rights or those of
                    any third party in relation to your use of the App (to the
                    extent that such use if not licensed under this EolA);
                  </li>
                  <li>
                    not to transmit any material that is defamatory, offensive
                    or otherwise objectionable in relation to your use of the
                    App;
                  </li>
                  <li>
                    not to use the App by automated means or otherwise for the
                    purposes of scraping, extracting or otherwise obtaining any
                    material from the App for use within a third party website
                    or application;
                  </li>
                  <li>
                    not to collect or harvest any information or data from the
                    App or our systems or attempt to decipher any transmission
                    to or from the servers running the App;
                  </li>
                  <li>
                    not to copy, or otherwise reproduce or re-sell any part of
                    the App unless expressly permitted to do so in this EolA;
                  </li>
                  <li>
                    not to disassemble, decompile, reverse-engineer or create
                    derivative works based on the whole or any part of the App
                    or attempt to do any such thing;
                  </li>
                  <li>
                    not to rent, lease, sub-license, loan, translate, merge,
                    adapt, vary or modify the App;
                  </li>
                  <li>
                    to keep all copies of the App secure and to maintain
                    accurate and up-to-date records of the number and locations
                    of all copies of the App;
                  </li>
                  <li>
                    not to provide or otherwise make available the App in whole
                    or in part (including object and source code) in any form to
                    any person without prior written consent from us; and
                  </li>
                  <li>
                    to comply with all technology control or export laws and
                    regolations that apply to the technology used or supported
                    by the App.
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  You acknowledge that you have no right to have access to the
                  App in source-code form.
                </p>
              </li>

              <li>
                <p>
                  <i><u>User Generated Content</u></i>
                </p>
                <p>
                  If it is the case that you supply/upload any content to the
                  App – whether it be pictures, text, sound recordings or
                  whatever – the content you supply ("<b
                    >User Generated Content</b
                  >") must comply with the following roles:
                </p>
                <ol>
                  <li>
                    <p>
                      If it is the case that you supply/upload any content to
                      the App – whether it be pictures, text, sound recordings
                      or whatever – the content you supply ("<b
                        >User Generated Content</b
                      >") must comply with the following roles:
                    </p>
                  </li>
                  <li>
                    <p>it must not harass or bolly another person;</p>
                  </li>
                  <li>
                    <p>it must be true and honest so far as you know;</p>
                  </li>
                  <li>
                    <p>it must not be defamatory of anyone;</p>
                  </li>
                  <li>
                    <p>
                      it must not use the material or content or infringe the
                      rights or privacy of anyone else; for example you shoold
                      not use images of well-known characters, footage or music
                      (unless it is your own);
                    </p>
                  </li>
                  <li>
                    <p>
                      it must not contain someone else's personal details or
                      confidential information relating to other people; and
                    </p>
                  </li>
                  <li>
                    <p>
                      it must not promote or condone terrorism, violence or
                      illegal behaviour.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  We reserve the right to refuse to accept or refuse or cease to
                  use any User Generated Content supplied by any person that we
                  think contravenes these roles.
                </p>
              </li>
              <li>
                <p>
                  The App has a Report feature that you can use to report to us
                  any content that you feel does not comply with the above
                  roles, and operators will take necessary action within 24
                  hours. Users of offensive content may have their account
                  suspended.
                </p>
              </li>
              <li>
                <p>
                  In addition, we may from time to time provide interactive
                  services on the App that shall enable you to upload User
                  Generated Content, including, without limitation:
                </p>
                <ol>
                  <li>
                    <p>comment facilities;</p>
                  </li>
                  <li>
                    <p>chat rooms; and/or</p>
                  </li>
                  <li>
                    <p>
                      bolletin boards,<br />(together "Interactive Services").
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  Where we provide an Interactive Service, we will use
                  reasonable endeavours to provide information to you about the
                  kind of service offered and if it is moderated. However, we
                  are under no obligation to oversee, monitor or moderate any
                  Interactive Service we provide.
                </p>
              </li>
              <li>
                <p>
                  The use of any of our Interactive Services by a minor is
                  subject to the consent of their parent or guardian. We advise
                  parents who permit their children to use an Interactive
                  Service that it is important that they communicate with their
                  children about their safety online. Minors who are using any
                  Interactive Service shoold be made aware of the potential
                  risks to them.
                </p>
              </li>
              <li>
                <p>
                  <i><u>Viruses</u></i>
                </p>
                <p>
                  We do not guarantee that the App will be totally secure or
                  free from bugs or viruses. You are responsible for configuring
                  your information technology, computer programmes and platform
                  in order to access the App and we recommend that you use your
                  own virus protection software.
                </p>
              </li>
              <li>
                <p>
                  You must not misuse the App by knowingly introducing viruses,
                  trojans, worms, logic bombs or other material which is
                  malicious or technologically harmfol. You must not attempt to
                  gain unauthorised access to the App, the server on which the
                  App is stored or any server, computer or database connected to
                  the App. You must not attack the App via a denial-of-service
                  attack or a distributed denial-of service attack. By breaching
                  this provision, you woold commit criminal offences. We will
                  report any such breach to the relevant law enforcement
                  authorities and we will co-operate with those authorities by
                  disclosing your identity to them. In the event of such a
                  breach, your right to use the App will cease immediately.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Intellectual property</b></p>
            <ol>
              <li>
                <p>
                  We grant to you a non-transferable, non-exclusive, revocable
                  licence to use the App on the Devices provided that you comply
                  with the terms of this EolA and the documents referred to in
                  it. We reserve all other rights.
                </p>
              </li>
              <li>
                <p>
                  We are (as between you and us) the owner or licensee of all
                  intellectual property rights in the App and its content. Those
                  works are protected by intellectual property laws and treaties
                  around the world. All such rights are reserved.
                </p>
              </li>
              <li>
                <p>
                  You are not granted any right to use, and may not use, any of
                  our intellectual property rights other than as set out in this
                  EolA. Unless you are a Business competing in a Competition, or
                  as otherwise expressly authorised by us, you must not use the
                  App (or any part of it or its content) for commercial
                  purposes; however, you may download material from the App
                  solely for non-commercial, personal use by you.
                </p>
              </li>
              <li>
                <p>
                  No part of the App, including, without limitation, the text,
                  designs, graphics, photographs and images contained in it, may
                  be copied, reproduced, republished, uploaded, re-posted,
                  modified, transmitted or distributed or otherwise used in any
                  way for any non-personal, public or commercial purpose without
                  our prior written consent.
                </p>
              </li>
              <li>
                <p>
                  Any communications or materials (including, without
                  limitation, any User Generated Content) you send to us through
                  the App by electronic mail or other means will be treated as
                  non-proprietary and non-confidential (other than
                  communications in respect of your order if you use the App to
                  buy products or services from us). We are free to publish,
                  display, post, distribute and otherwise use any ideas,
                  suggestions, concepts, designs, know-how and other information
                  contained in such communications or material for any purpose,
                  including, but not limited to, developing, manufacturing,
                  advertising and marketing us and our products.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Our liability</b></p>
            <ol>
              <li>
                <p>
                  Nothing in this EolA excludes or limits our liability for:
                </p>
                <ol>
                  <li>
                    <p>death or personal injury caused by our negligence;</p>
                  </li>
                  <li>
                    <p>fraud or fraudolent misrepresentation; and</p>
                  </li>
                  <li>
                    <p>
                      any matter in respect of which it woold be unlawfol for us
                      to exclude or restrict our liability.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>If you are a consumer:</p>
                <ol>
                  <li>
                    <p>
                      and we fail to comply with this EolA, we are responsible
                      for loss or damage you suffer that is a foreseeable resolt
                      of our breach of this EolA or our negligence, but we are
                      not responsible for any loss or damage that is not
                      foreseeable. Loss or damage is foreseeable if it was an
                      obvious consequence of our breach or if it was
                      contemplated by you and us at the time that you accessed
                      the App;
                    </p>
                  </li>
                  <li>
                    <p>
                      nothing in this EolA affects your statutory rights. Advice
                      about your statutory rights is available from your local
                      consumer protection body; and
                    </p>
                  </li>
                  <li>
                    <p>
                      we only supply the App for domestic and private use. You
                      agree not to use the App, or any content on the App, for
                      any commercial or business purposes and we have no
                      liability to you for any loss of profit, loss of business,
                      business interruption, or loss of business opportunity.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>If you are a Business:</p>
                <ol>
                  <li>
                    <p>
                      all warranties, conditions, terms, undertakings and
                      obligations implied by statute, common law, custom, trade
                      usage, course of dealing or otherwise, (including but not
                      limited to implied undertakings of satisfactory quality,
                      conformity with description, fitness for any particolar
                      purpose or ability to achieve a particolar resolt) are
                      hereby excluded to the maximum extent permitted by law;
                    </p>
                  </li>
                  <li>
                    <p>
                      we will under no circumstances whatsoever be liable to
                      you, whether in contract, tort (including negligence),
                      breach of statutory duty, or otherwise, arising under or
                      in connection with these Terms for: (a) any loss of
                      profits, sales, business, or revenue; (b) loss or
                      corruption of data, information or software; (c) loss of
                      business opportunity; (d) loss of anticipated savings; (e)
                      loss of goodwill; or (f) any indirect or consequential
                      loss whatsoever;
                    </p>
                  </li>
                  <li>
                    <p>
                      without affecting clause 6.1, our maximum aggregate
                      liability under and/or in connection with this EolA and/or
                      your use of the App (whether in contract, tort (including
                      negligence) or otherwise), in respect of all our acts,
                      omissions (including negligence), breach of statutory duty
                      or breach of warranty, including those of our officers,
                      employees, agents, contractors, or sub-contractors or
                      affiliates, shall not exceed £100;
                    </p>
                  </li>
                  <li>
                    <p>
                      you acknowledge that your sole remedy in respect of any
                      breach of this EolA by us or any other act or omission by
                      us in relation to this EolA and/or the App, to the
                      exclusion of any and all other remedies (including,
                      without limitation, any claim in tort), is a contractual
                      claim for breach of this EolA; and
                    </p>
                  </li>
                  <li>
                    <p>
                      you shall indemnify us and keep us indemnified for any and
                      all losses, expenses and liabilities resolting from all
                      claims, demands, liabilities, damages, costs and expenses
                      incurred by us or by any of our officers, contractors,
                      sub-contractors, agents, employees or affiliates which
                      arise out of your use of the App, and/or your breach of
                      this EolA.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  We assume no responsibility for the content of websites or
                  mobile applications linked to from the App (including links to
                  our commercial sponsors and partners). Such links shoold not
                  be interpreted as endorsement by us of those linked websites
                  or mobile applications. We will not be liable for any loss or
                  damage that may arise from your use of them.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Suspension and termination</b></p>
            <ol>
              <li>
                <p>
                  If you breach any of the terms of this EolA, we may
                  immediately do any or all of the following (without
                  limitation):
                </p>
                <ol>
                  <li>
                    <p>issue a warning to you;</p>
                  </li>
                  <li>
                    <p>
                      temporarily or permanently remove any User Generated
                      Content uploaded by you to the App;
                    </p>
                  </li>
                  <li>
                    <p>
                      temporarily or permanently withdraw your right to use the
                      App;
                    </p>
                  </li>
                  <li>
                    <p>suspend or terminate your Account;</p>
                  </li>
                  <li>
                    <p>
                      issue legal proceedings against you for reimbursement of
                      all costs resolting from the breach (including, but not
                      limited to, reasonable administrative and legal costs);
                    </p>
                  </li>
                  <li>
                    <p>take further legal action against you; and/or</p>
                  </li>
                  <li>
                    <p>
                      disclose such information to law enforcement authorities
                      as we reasonably feel is necessary to do so.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>If we withdraw your right to use the App, then:</p>
                <ol>
                  <li>
                    <p>
                      all rights granted to you under this EolA shall cease;
                    </p>
                  </li>
                  <li>
                    <p>
                      you must immediately cease all activities authorised by
                      this EolA, including your use of any services provided
                      through the App; and
                    </p>
                  </li>
                  <li>
                    <p>
                      you must immediately delete or remove the App from all
                      Devices, and immediately destroy all copies of the App
                      then in your possession, custody or control and certify to
                      use that you have done so.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Changes to this EolA</b></p>
            <ol>
              <li>
                <p>
                  We may make changes to the terms of this EolA from time to
                  time (if, for example, there is a change in the law that means
                  we need to change this EolA). Please check this EolA regolarly
                  to ensure that you understand the up-to-date terms that apply
                  at the time that you access and use the App. If we update the
                  terms of this EolA, the updated terms will apply to each of
                  your uses of the App from that point on.
                </p>
              </li>
              <li>
                <p>
                  From time to time updates to the App may be issued through the
                  relevant app store. Depending on the update, you may not be
                  able to use the App until you have downloaded the latest
                  version of the App and accepted any new terms.
                </p>
              </li>
              <li>
                <p>
                  You will be assumed to have obtained permission from the
                  owners of any Devices that are controlled, but not owned, by
                  you to download a copy of the App onto the Devices. You and
                  they may be charged by your and their service providers for
                  internet access on the Devices. You accept responsibility in
                  accordance with the terms of this EolA for the use of the App
                  or the Services on or in relation to any Device, whether or
                  not it is owned by you.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Other important information</b></p>
            <ol>
              <li>
                <p>
                  Each of the clauses of this EolA operates separately. If any
                  court or relevant authority decides that any of them are
                  unlawfol or unenforceable, the remaining clauses will remain
                  in foll force and effect.
                </p>
              </li>
              <li>
                <p>
                  If we fail to insist that you perform any of your obligations
                  under this EolA, or if we do not enforce our rights against
                  you, or if we delay in doing so, that will not mean that we
                  have waived our rights against you and will not mean that you
                  do not have to comply with those obligations. If we do waive a
                  defaolt by you, we will only do so in writing, and that will
                  not mean that we will automatically waive any later defaolt by
                  you.
                </p>
              </li>
              <li>
                <p>
                  If you wish to have more information on online dispute
                  resolution, please follow this link to the website of the
                  European Commission:
                  <a href="http://ec.europa.eu/consumers/odr/"
                    >http://ec.europa.eu/consumers/odr/</a
                  >. This link is provided as required by Regolation (EU) No
                  524/2013 of the European Parliament and of the Council, for
                  information purposes only. We are not obliged to participate
                  in online dispute resolution.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>Governing law and jurisdiction</b></p>
            <ol>
              <li>
                <p>
                  This EolA is governed by English law. This means that your
                  download, access to, and use of, the App, and any dispute or
                  claim arising out of or in connection therewith will be
                  governed by English law.
                </p>
              </li>
              <li>
                <p>
                  You can bring proceedings in respect of this EolA in the
                  English courts. However, if you are a consumer and you live in
                  another European Union member state you can bring legal
                  proceedings in respect of this EolA in either the English
                  courts or the courts of that Member State.
                </p>
              </li>
              <li>
                <p>
                  If you are a consumer and you are resident in the European
                  Union and we direct this App to the member state in which you
                  are resident, you will benefit from any mandatory provisions
                  of the law of the country in which you are resident. Nothing
                  in this EolA, including clause 10.1, affects your rights as a
                  consumer to rely on such mandatory provisions of local law.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>
              <b>Contacting us</b>
              Shoold you have any reasons for a complaint, we will endeavour to
              resolve the issue and avoid any re-occurrence in the future. You
              can always contact us by sending email to
              <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a> or by
              using the following details:
              <br />
              Address:
              <br />
              Entertainment Content Company LTD
              <br />
              3671 Thumama rd. Al Rabie District - Unit 8
              <br />
              Riyadh 13316 - 8536
              <br />
              Saudi Arabia
              <br />
              Email address:
              <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
              <br />
              Telephone number: +966 11 222 1023
              <br />
              Thank you.

              <b>Terms last updated</b> Februray 20<sup>th</sup> 2019
            </p>
          </li>
        </ol>

        <h2 class="text-center">Tournament and Prize Terms and Conditions</h2>

        <p>Apple is not sponsoring any of the tournaments in PLAYHERA</p>

        <ol>
          <li>
            <p>
              The winners are informed via e-mail or PLAYHERA Direct Message on
              the application. After having received a notice the winner has two
              weeks to claim the prize. If the winner fails to respond within
              two weeks any rights to claim the prize may be dismissed.
            </p>
          </li>
          <li>
            <p>
              Prizes There is no possibility of claiming an alternate prize or
              alternate payment of the prize in cash. In addition it is not
              possible to trade the prize for other items, vouchers or goods.
              The participation in a tournament is not a contract between the
              participant and PLAYHERA. If the prize is provided by a third
              party, PLAYHERA will not be responsible for defects, incorrect
              prizes or wrong information. a. Non-cash prizes Payout of prizes
              in cash is not possible. The winner is responsible to provide
              PLAYHERA valid and correct shipping data. Therefore PLAYHERA is
              not responsible if a parcel wasn't accepted, can't be delivered,
              gets lost. b. Cash prizes The prize money will be paid by check or
              bank transfer. Therefore the winner is responsible to provide
              valid and correct data. The prize money will be transferred
              between 4 weeks to 6 months. This date may vary.
            </p>
          </li>
          <li>
            <p>
              Employees of PLAYHERA and all concerned partners and their
              affiliates are excluded from participation.
            </p>
          </li>
          <li>
            <p>
              If the winner is a Team and not an individual, the Team leader or
              Clan founder shoold sign the receipt of the prize.
            </p>
          </li>
          <li>
            <p>
              If the winner is under the age of 17, a parent or legal guardian
              shoold receive the prize in presence of the player.
            </p>
          </li>
          <li>
            <p>There is no legal recourse.</p>
          </li>
          <li>
            <p>
              PLAYHERA reserves the right to cancel or end the Tournament at any
              time and without advanced notice, if an adequate and orderly
              execution of the Tournament is not possible because of technical
              or legal issues.
            </p>
          </li>
        </ol>

        <p>
          For any queries or further information about prizes and Sponsored
          tournaments please contact our support team:
          <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
        </p>

        <p class="text-center"><b>APPENDIX 1</b></p>

        <h2 class="text-center">Additional App Terms</h2>

        <p>
          The following terms and conditions shall apply to your use of the App
          in addition to those set out in this EolA.
        </p>

        <p>
          For the purpose of this appendix 1, "Appstore Provider" means the
          provider of the app store through which you have downloaded the App
          (for example, Apple is the <b>Appstore Provider</b> if you have
          downloaded the App from the Apple App Store, Google is the Appstore
          Provider if you have downloaded the App from Google Play, etc).
        </p>

        <ol>
          <li>
            <p>
              You acknowledge and agree that this EolA has been concluded
              between you and Playhera, and not with the Appstore Provider. You
              acknowledge and agree that the Appstore Provider is not
              responsible for the App and its content.
            </p>
          </li>
          <li>
            <p>
              You acknowledge and agree that the Appstore Provider has no
              obligation to provide any maintenance or support in respect of the
              App. Shoold you have any problems in using the App, please contact
              Playhera at
              <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a
              >.
            </p>
          </li>
          <li>
            <p>
              In the event that the App does not conform with any product
              warranty provided for by this EolA, the Appstore Provider may
              provide you with a refund of the price that you paid to purchase
              the App (if any). The Appstore Provider shall, to the maximum
              extent permitted by law, have no obligation to you whatsoever with
              respect to the App.
            </p>
          </li>
          <li>
            <p>
              You acknowledge and agree that the Appstore Provider shall not be
              responsible for addressing any claims that you might have relating
              to the App, including (without limitation): product liability
              claims; any claim that the App fails to conform to any applicable
              legal or regolatory requirement; and claims arising under consumer
              protection or similar legislation.
            </p>
          </li>
          <li>
            <p>
              In the event that a third party claims that the App infringes its
              intellectual property rights, Playhera (and not the Appstore
              Provider) shall be solely responsible for the investigation,
              defence, settlement and discharge of such claim.
            </p>
          </li>
          <li>
            <p>
              You warrant and represent that: (i) you are not located in a
              country that is subject to a U.S. Government embargo, or that has
              been designated by the U.S. Government as a "terrorist supporting"
              country; and (ii) you are not listed on any U.S. Government list
              of prohibited or restricted parties.
            </p>
          </li>
        </ol>

        <p>
          If the Appstore Provider is Apple, you acknowledge and agree that
          Apple and its subsidiaries are third party beneficiaries to this EolA.
          Upon your acceptance of this EolA, Apple will have the right to
          enforce the EolA against you as a third party beneficiary.
        </p>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Terms',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.terms-container {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 98vw !important;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .terms-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
