<template>
  <Menu
    v-bind="{ ...props, closest }"
    :class="settings.menuClass"
    class="globalMenu"
    @toggle="toggleHandler"
  >
    <span
      slot-scope="{ item }"
      :class="[{ 'disabled not-allowed': item.disabled }, settings.itemClass]"
      @mousedown="mouseDownUpHandler"
      @mouseup="mouseDownUpHandler"
      @click="clickHandler(item, $event)"
    >
      {{ item.label }}
    </span>
  </Menu>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Menu from './Menu';

export default {
  name: 'GlobalMenu',
  components: {
    Menu,
  },
  props: {
    closest: {
      type: Node, // closest element position must be 'relative'
      default: null,
    },
  },
  computed: {
    ...mapState('popoverMenu', {
      settings: state => state.settings,
      items: state => state.items,
    }),
    ...mapGetters('popoverMenu', ['targetElement']),

    props() {
      return {
        position: this.targetElement,
        items: this.items,
        isFixed: this.settings.isFixed,
        blockIsFixed: this.settings.blockIsFixed,
        targetSelector: this.settings.targetSelector,
        float: this.settings.float,
        regard: this.settings.regard,
        autoClose: this.settings.autoClose,
        width: this.settings.width,
        height: this.settings.height,
        translate: this.settings.translate,
      };
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  methods: {
    ...mapMutations('popoverMenu', ['setTargetElement']),
    ...mapActions({ hidePopoverMenu: 'popoverMenu/hide' }),
    toggleHandler(isVisible) {
      if (!isVisible) {
        this.hidePopoverMenu();
      }
    },

    mouseDownUpHandler(event) {
      if (this.settings.stopItemClickBubbling) {
        event.stopPropagation();
      }
    },

    clickHandler(item, event) {
      if (this.settings.stopItemClickBubbling) {
        event.stopPropagation();
        if (this.settings.autoClose) {
          this.hidePopoverMenu();
        }
      }
      item.action();
    },
  },
};
</script>
<style lang="scss">
.globalMenu {
  z-index: 100001;
  .not-allowed,
  .not-allowed * {
    pointer-events: none;
    cursor: not-allowed !important;
  }
}
</style>
