<template>
  <div
    v-if="data"
    class="player-profile-teams"
    @mouseenter="getPostAuthorProfile(data.id)"
    @mouseleave="showFollowButton = false"
  >
    <router-link :to="profileLink">
      <li class="search-item-teams">
        <div class="avatar-container-teams">
          <Avatar
            v-bind="{
              size: 48,
              type: data.clan || 'clan',
              imgUrl: data.avatar ? data.avatar : data.logo,
              isDeleted: data.deleted,
            }"
          />
        </div>
        <div class="text-team-container">
          <div class="text-name-container-teams">
            <TextLabel class="text-name-teams">
              {{
                data.display_name
                  ? data.display_name
                  : data.name
                  ? data.name
                  : data.login
              }}
            </TextLabel>
          </div>
          <div class="d-flex team-count">
            <span class="number-tag">
              {{ data.members_count }}
              <span class="players-tag">{{
                $t('web_player_title', 'Players')
              }}</span>
            </span>
          </div>
        </div>
        <!--<div class="d-flex team-count">
        <span class="number-tag">
          {{ data.members_count }}
          <span class="players-tag">{{
            $t('web_player_title', 'Players')
          }}</span>
        </span>
      </div>-->
      </li>
    </router-link>
    <div class="follow-button-container-teams">
      <FollowBtn
        :model="model"
        class="follow-btn follow-btn-team"
        :class="showFollowButton ? '' : 'follow-hidden'"
        dir="rtl"
        follow-class="btn-primary"
        hover-class="btn-secondary"
        unfollow-class="btn-secondary"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/images/Avatar';
import TextLabel from '@/components/atoms/TextLabel';
import { mapState } from 'vuex';
import { prop } from '@/utils/factories';
import { ACCOUNT } from '@/constants/accounts';
import FollowBtn from '@/components/common/NewFollowBtn';
import AccountModel from '@/models/Account';
import { API } from '@/api/config';

export default {
  components: { TextLabel, Avatar, FollowBtn },
  props: {
    data: prop(Object, null),
  },
  data() {
    return {
      account: ACCOUNT,
      model: new AccountModel(),
      showFollowButton: false,
    };
  },

  computed: {
    profileLink() {
      return {
        name: ACCOUNT,
        params: {
          accountType: this.data.clan || 'clan',
          accountId: this.data.id || this.data.login,
        },
      };
    },
    ...mapState('current', ['account', 'accountId']),
    ...mapState('my', { myAccount: 'account' }),
  },
  methods: {
    async getPostAuthorProfile(id) {
      const response = await API(`/clans/${id}`);
      this.model = new AccountModel('clan', response.data.id).update(
        response.data
      );
      this.showFollowButton = true;
    },
  },
};
</script>

<style lang="scss">
.search-item-teams {
  width: 100%;

  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  word-break: normal;
  white-space: nowrap;
  label {
    cursor: pointer;
    width: 5rem;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    margin: 0;
  }
}

.avatar-container-teams {
  display: flex;
  border-radius: 0.5rem;
  height: 3rem;
  width: 3rem;
}
.player-profile-teams {
  background-color: $new-bg-page-faded;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.1875rem;
  height: 6rem;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 5px;
}

.follow-button-container {
  display: flex;
  flex-direction: row;
  width: 4rem;
  justify-content: center;
}
.text-team-container {
  display: flex;
  flex-direction: column;
}
.text-name-teams {
  justify-content: center;
  white-space: nowrap;
  font-weight: 700;
}

.text-name-container-teams > label {
  font-size: 14px !important;
  text-align: start;
}

.text-name-container-teams {
  display: flex;
}

.follow-span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.follow-btn-teams {
  height: 2.25rem;
  min-width: 50px;
  font-weight: 500 !important;
  color: $new-fg-neutral;
  width: 4rem;
  background-color: transparent !important;
  color: $hr-text-primary;
  border-radius: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid $new-bg-neutral-faded !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.players-tag {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: $new-bg-neutral-highlighted;
}

.number-tag {
  font-size: 14px;
  font-weight: 400;
}

.team-count {
  color: $hr-text-secondary;
}

.follow-btn-teams:hover {
  background-color: $new-bg-primary !important;
  border: 2px solid $new-bg-primary !important;
}

.follow-button-container-teams {
  display: flex;
}
.follow-btn-team {
  width: unset !important;
  padding: 0 5px 0 5px !important;
}
</style>
