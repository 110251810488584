import axios from 'axios';
import { errorMessagePropsFactory } from '@/utils/textError';
import {
  cloneDeep,
  snakeCase,
  isString,
  get,
  isEmpty,
} from '@/utils/lodashUtils';
import { isRequestCanceled } from './requestCancel';
import store from '@/store';

const updateTokenInConfig = function(config) {
  const newConfig = cloneDeep(config);
  if (newConfig.headers.common) {
    newConfig.headers.common['Authorization'] = this.$store.getters.accessToken;
  } else {
    newConfig.headers['Authorization'] = this.$store.getters.accessToken;
  }
  return newConfig;
};
export function onTokenFailure(error) {
  if (isRequestCanceled(error)) {
    throw error;
  }
  if (error.response) {
    const { status, config } = error.response;
    let refreshChain;
    const isRefreshRequest =
      config.headers['Authorization'] === this.$store.getters.refreshToken;
    let setToken = updateTokenInConfig.bind(this);
    // do we refreshing tokens right now?
    if (status === 401) {
      // we can't refresh token if we don't have one
      if (
        this.$store.getters.isTempTokenOnly ||
        this.$store.getters.areAllTokensInvalid
      ) {
        this.$store.commit('clearToken', 'tempToken');
        throw error;
      } else if (isRefreshRequest) {
        // if we have an error while refreshing token -> bypass this error further
        throw error;
      } else if (this.$store.getters.isRefreshProcessing) {
        // else if  it's another request so get refreshing promise
        refreshChain = this.$store.getters.refreshStopover;
      } else {
        // otherwise start new refresh process
        let refreshPromise = this.$store.dispatch('refreshAccessToken');
        this.$store.commit('changeRefreshStopover', refreshPromise);
        refreshChain = refreshPromise;
      }
      // after refresh we need to retry failed request
      return refreshChain
        .catch(refreshError => {
          // logout user on any refresh error
          this.$router.push({ name: 'signout' });
          throw refreshError;
        })
        .then(() => setToken(config))
        .then(axios.request)
        .catch(anotherError => {
          throw anotherError;
        });
    } else {
      throw error;
    }
  } else {
    throw error;
  }
}
export function onRequestSuccess(config) {
  const self = this;

  let setToken = updateTokenInConfig.bind(self);

  return new Promise((resolve, reject) => {
    const token = config.headers.common['Authorization'];

    // in the case when user delete the maintenance popup from DOM and try to interact with the app
    if (self.$store.getters.maintenance) {
      return reject({
        response: {
          status: 405,
          data: { message: 'You shall not pass!🧙' },
          config,
        },
      });
    }

    const onRefreshError = e => {
      reject({ message: e, url: config.url });
    };

    if (
      token &&
      token !== self.$store.getters.refreshToken &&
      self.$store.getters.isRefreshProcessing
    ) {
      self.$store.getters.refreshStopover
        .then(() => {
          return resolve(setToken(config));
        })
        .catch(onRefreshError);
    } else {
      resolve(config);
    }
  });
}

export function onResponseSuccess(response) {
  return response;
}

export function onResponseError(error) {
  if (isRequestCanceled(error)) {
    throw { silent: true, message: 'Request cancelled' };
  }
  let text = error.message;
  let silent = false;
  let inModal = false;
  let statusCode = 0;
  const yesText = this.$t('_web_form_error_confirm-yes', 'OK');
  if (error.response) {
    const { status, data, config } = error.response;

    if (error.config && error.config.data === 'unsetInterceptorReplacer') {
      throw { silent: true, message: data };
    }

    statusCode = status;
    const requestMethod = config.method.toUpperCase();
    const hasEndpoint = config.url.match(/\/api\/.*$/i);
    const isRefreshRequest =
      config.headers['Authorization'] === this.$store.getters.refreshToken;
    const endpoint = hasEndpoint ? hasEndpoint[0] : '';
    text = data.message || '';
    let textGenerated = '';
    switch (status) {
      case 400:
        if (!isEmpty(data.fields)) {
          Object.entries(data.fields).forEach(([key, value]) => {
            const fieldErrors = value.reduce((acc, fieldError) => {
              let fieldName;
              // get error message
              // context could be a string, if so, show such error
              const message = isString(fieldError.context)
                ? fieldError.context
                : get(fieldError, 'context.message', '');
              // if we have error message show it
              if (message) {
                textGenerated = key ? `${key}: ${message}` : message;
                fieldName = key.toLowerCase();
                acc.push(textGenerated);
              } else {
                // otherwise generate message by context data
                const {
                  translateKey,
                  translateDefault,
                  props = {},
                } = errorMessagePropsFactory(fieldError, key);
                // and translate it
                textGenerated = this.$t(translateKey, translateDefault, {
                  ...props,
                  field: key,
                }); // only 1 message (and last)
                fieldName = key.toLowerCase();
              }
              // add new errors to the $validator object if we have apropriate field
              if (this.$validator.fields.find({ name: fieldName })) {
                this.$validator.errors.add({
                  field: fieldName,
                  msg: textGenerated,
                });
              } else {
                //show message in modal otherwise
                inModal = true;
              }
              return acc;
            }, []);

            if (fieldErrors.length > 1) {
              text += `<br>${fieldErrors.join('<br>')}`;
            } else if (textGenerated) {
              text = textGenerated;
            }
          });
        } else {
          text = this.$t(`_web_server_errors-${data.message}`, data.message);
          inModal = true;
        }
        break;
      case 401:
        // do we refreshing tokens right now?
        if (
          isRefreshRequest ||
          this.$store.getters.isTempTokenOnly //||
          //this.$store.getters.areAllTokensInvalid
        ) {
          text = this.$t('_web_session_expired_error', 'Session expired');
          inModal = true;
        } else if (!this.$store.getters.isAuthorized) {
          // this.$router.push('/signin');
          //ako ga ovdje stavim bugga
        }
        //Ovdje je bio PleaseSignIn modal
        break;
      case 403:
        // fix for the specific case when template token was expired before user verify phone on 3 step of registration
        if (
          config.url.includes('auth/sendcode') &&
          config.data &&
          JSON.parse(config.data).type === 'phone'
        ) {
          text = this.$t(
            '_web_auth_sendcode_error',
            'Session for adding phone is expired. You will be able to add a phone number in you profile settings.'
          );
          inModal = true;
        } else {
          break;
          //ovdje je bio Unauthorized request, access denied modal
        }
        break;
      case 404:
        text = `<strong>${requestMethod} ${endpoint}</strong><br>${this.$t(
          '_web_404_error',
          'Wrong URI or endpoint not implemented yet'
        )}`;
        break;
      case 500:
        text = this.$t('_web_network_error', 'Internal server error');
        break;
      case 503:
        text = this.$t('_web_server_error', 'Service Unavailable');
        inModal = true;
        break;
      default:
        if (data.fields) {
          Object.keys(data.fields).forEach(key => {
            const fieldName = key.toLowerCase();
            data.fields[key].forEach(item => {
              if (typeof item.context === 'string') {
                if (this.$validator.fields.find({ name: fieldName })) {
                  this.$validator.errors.add({
                    field: fieldName,
                    msg: this.$t(
                      `_web_field_errors_${snakeCase(item.error)}`,
                      item.context
                    ),
                  });
                } else {
                  inModal = true;
                }
              }
            });
          });
        }
        text = this.$t(
          `_web_server_errors-${snakeCase(data.message)}`,
          data.message
        );
    }
  } else if (text === 'Network Error') {
    if (!store.getters.regionLoading) {
      text = this.$t(
        '_web_internet_connection_error',
        'Network error. Please check your internet connection.'
      );
      inModal = true;
    }
  }
  throw {
    silent,
    inModal,
    yesText,
    message: text,
    status: statusCode,
  };
}

export default {
  onRequestSuccess,
  onResponseSuccess,
  onResponseError,
  onTokenFailure,
};
