<template>
  <Panel
    class="p-0 create-feed-postEdit"
    :variant="!showActions ? 'transparent' : 'default'"
    :class="{ active: showActions }"
  >
    <div class="create-feed-post_wrapper d-flex">
      <Avatar
        :img-url="authorModel._avatar || authorModel.avatar"
        :size="48"
        :type="authorModel._type"
      />
      <div class="h-ml-4 create-feed-postEdit_body-wrapper overflow-hidden">
        <div class="d-flex flex-column">
          <div
            class="create-feed-postEdit_body"
            :class="{
              'create-feed-postEdit-home':
                this.$router.history.current.name === 'account',
            }"
          >
            <TextInputMultiline
              ref="postInput"
              v-model="text"
              class="mb-0 create-feed-postEdit_input"
              :class="{ 'create-feed-postEdit_input--collapsed': !showActions }"
              :placeholder="$t('_web_profile-placeholder', 'What’s happening?')"
              :has-counter="showActions"
              :rows="showActions ? INPUT_ROWS : 1"
              v-bind="{ maxLength }"
              hide-label
              :detect-links="!linkPreview"
              detect-tags
              detect-mentions
              @linkDetected="debouncedPreviewRequest"
              @isFocused="expand"
              @keyup.enter.alt="publish"
              @keyup.esc="resetForm"
            />

            <!--
          @TODO - TH-7124
          https://bitbucket.org/isd__design/hera-web/src/4c86da6d58faa4b6cbb61ebb6c3514624e92b1ee/src/components/form/contentEditable/index.vue
          -->

            <div
              v-if="uploadFilesList.length"
              class="img-preview"
              :class="{ 'has-images mt-5': uploadFilesList.length > 0 }"
            >
              <div
                v-for="(file, index) in uploadFilesList"
                :key="index"
                class="img-wrapper position-relative"
                :class="[
                  `img-wrapper-${uploadFilesList.length}`,
                  { loading: !file.uploaded },
                ]"
              >
                <template v-if="isValidFileFormat(file)">
                  <img
                    v-if="file.type.split('/')[0] === 'image'"
                    :src="file.obj"
                  />
                  <template v-else>
                    <video @loadedmetadata="setVideoDuration(index, $event)">
                      <source type="video/mp4" :src="file.obj" />
                    </video>
                    <span class="video-duration">{{
                      getVideoDuration(index) | formatTimeFromSeconds
                    }}</span>
                  </template>
                </template>

                <AppIcon
                  name="close"
                  class="btn-delete"
                  width="14"
                  @click.prevent.stop="removeFile(file)"
                />
                <ProgressBar
                  v-if="!file.uploaded"
                  :current="file.currentUpload"
                  :max="file.totalUpload"
                  show-value
                  class="mt-3"
                />
              </div>
            </div>
            <LinkPreview
              v-else-if="showActions && linkPreview && isLinkPreviewValid"
              v-bind="{ ...linkPreview, editMode: true }"
              @remove="removePreview"
            />
          </div>

          <PollOptionsEditor v-if="isPollPost" v-model="pollOptions" />
          <template
            v-if="
              showActions &&
                account &&
                account.isOrganization &&
                connectedSocials.length
            "
          >
            <div class="d-flex align-items-center mt-6">
              <TextBlock
                variant="secondary"
                :text="$t('_web_post_share-settings-label', 'Share to:')"
                class="h-mr-4"
              />
              <div
                v-for="network in connectedSocials"
                :key="network"
                class="d-flex align-items-center w-auto h-mr-4"
              >
                <SocialIcon
                  :name="`${network === 'facebook' ? 'fb' : network}`"
                  :size="24"
                />
                <!-- <TextBlock class="mx-3">{{ network | startCase }}</TextBlock> -->
                <Checkbox
                  :checked="getSocialValue(network)"
                  class="h-ml-3 flex-grow-0"
                  @change="$event => setSocialValue(network, $event)"
                />
              </div>
            </div>
          </template>
          <div
            v-if="showActions"
            class="d-flex justify-content-between align-items-center mt-6 post-actions"
            :class="{ disabled: isActionBarDisabled }"
            @click.prevent.stop
          >
            <div class="d-flex align-items-center">
              <label
                class="post-select-files"
                :class="{ disabled: isAttachBtnDisabled }"
                @click.stop
              >
                <AppIcon
                  name="editable/upload"
                  width="18"
                  :class="{ disabled: isAttachBtnDisabled }"
                />
                <input
                  type="file"
                  :accept="validFilesFormats"
                  multiple
                  @change="prepareImagesForUpload"
                />
              </label>
              <AppIcon
                v-if="!postModel"
                name="poll"
                width="24"
                class="h-ml-4 cursor-pointer"
                :class="{ disabled: isPollBtnDisabled }"
                @click="togglePollCreation"
              />
            </div>
            <div class="d-flex align-items-center poll-options">
              <HeraFormBtn
                v-if="isPollPost"
                class="btn-secondary-simple remove-btn"
                :label="$t('_web_create_post_remove-poll', 'Remove Poll')"
                @click.stop="removePoll"
              />
              <HeraFormBtn
                class="btn-accent-simple"
                :disabled="isShareBtnDisabled"
                :class="{
                  'is-loading': createPostProceed,
                }"
                :label="
                  !postModel
                    ? $t('_web_create_post_create-post', 'Create')
                    : $t('_web_create_post_save-post', 'Save')
                "
                @click.stop="publish"
              />
              <HeraFormBtn
                class="btn-cancel"
                :label="$t('web_create-clan_cancel', 'Cancel')"
                @click.prevent.stop="resetForm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppIcon from '@/components/atoms/Icon';
import Avatar from '@/components/images/Avatar.vue';
import TextInputMultiline from '@/components/molecules/form/TextInputMultilineWithActivators';
import Panel from '@/components/atoms/Panel';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import PollOptionsEditor from './components/PollOptionsEditor';
import ProgressBar from '@/components/atoms/ProgressBar';
import LinkPreview from '@/components/molecules/OpenGraphCard';
import SocialIcon from '@/components/images/Social';
import TextBlock from '@/components/atoms/TextBlock';
import Checkbox from '@/components/atoms/form/Checkbox';
import { removeTagsMention } from '@/filters/string';
import { GENERIC, POLL } from '@/constants/postTypes';

const INPUT_ROWS = 3;
const MAX_ATTACHMENTS = 4;
const POST_MAX_LENGTH = 1000;
const POLL_MAX_LENGTH = 255;

export default {
  name: 'CreatePostEdit',

  inject: ['$validator'],

  components: {
    AppIcon,
    Avatar,
    TextInputMultiline,
    Panel,
    HeraFormBtn,
    PollOptionsEditor,
    ProgressBar,
    LinkPreview,
    SocialIcon,
    TextBlock,
    Checkbox,
  },
  props: {
    authorModel: {
      type: Object,
      required: true,
    },
    postModel: {
      type: Object,
      default: () => null,
      required: false,
    },
  },

  data() {
    return {
      createPostProceed: false,
      showActions: false,
      allImagesUploaded: false,
      text: '',
      uploadFilesList: [],
      duration: {},
      validFilesFormats: [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'video/mp4',
        'video/mov',
        'video/3gp',
        'video/quicktime',
      ],

      isPollPost: false,
      pollOptions: {},
      linkPreview: null,
      // {
      //   image:
      //     'https://cdn2.unrealengine.com/14br-bplaunch-egs-s1-2560x1440-2560x1440-851249168.jpg',
      //   url: 'https://isddesign.atlassian.net/browse/TH-10462',
      //   title: 'link test title',
      //   description: 'jira task',
      // },
      INPUT_ROWS,
      isLinkPreviewValid: true,
      connectedSocials: [],
      repostToTwitter: false,
      repostToFacebook: false,
      debouncedPreviewRequest: null,
    };
  },
  computed: {
    ...mapState('my', ['account']),
    isActionBarDisabled() {
      return this.uploadFilesList.length > 0 && !this.allImagesUploaded;
    },

    isAttachBtnDisabled() {
      return this.uploadFilesList.length >= MAX_ATTACHMENTS || this.isPollPost;
    },

    isPollBtnDisabled() {
      return !!this.uploadFilesList.length;
    },

    isShareBtnDisabled() {
      return (
        (!this.uploadFilesList.length && !this.text) ||
        this.text.length > this.maxLength
      );
    },

    maxLength() {
      return this.isPollPost ? POLL_MAX_LENGTH : POST_MAX_LENGTH;
    },
  },
  mounted() {
    if (this.postModel) {
      setTimeout(() => {
        this.$refs.postInput.focus();
      }, 10);
    }
  },
  created() {
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
    if (this.postModel) {
      this.text = removeTagsMention(this.postModel.text);
      this.allImagesUploaded = true;
      this.uploadFilesList = this.postModel.attachments.map(f => {
        const uri = f.type == 'image' ? f.image : f.video;
        return {
          file_id: f.token || f.file_id,
          display_uri: uri,
          obj: uri,
          type: f.type + '/' + uri.split('.').pop(),
          uploaded: true,
        };
      });
    }
  },
  beforeDestroy() {
    delete this.fileReader;
  },

  methods: {
    ...mapActions([
      'createPost',
      'updatePost',
      'createPollPost',
      'sendFileDirect',
      'errorNotify',
      'getOpenGraphDataByLink',
      'fetchAccountSocialConnectionStatus',
    ]),

    expand() {
      if (!this.createPostProceed) {
        this.showActions = true;
        if (this.account && this.account.isOrganization) {
          this.fetchAccountSocialConnectionStatus()
            .then(connections => {
              if (connections) {
                const platforms = [];
                if (connections.facebookCredentialsDefined) {
                  platforms.push('facebook');
                  this.repostToFacebook = true;
                }
                if (connections.twitterCredentialsDefined) {
                  platforms.push('twitter');
                  this.repostToTwitter = true;
                }
                this.connectedSocials = platforms;
              }
            })
            .catch(this.errorNotify);
        }
      }
    },
    getSocialValue(social) {
      return this[`repostTo${this.$lodash.startCase(social)}`];
    },
    setSocialValue(social, value) {
      this[`repostTo${this.$lodash.startCase(social)}`] = value;
    },
    getOpenGraphData(link) {
      this.isLinkPreviewValid = true;
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            if (!response.image && !response.title && !response.description) {
              this.isLinkPreviewValid = false;
            }
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },
    resetForm() {
      if (this.isActionBarDisabled) {
        return;
      }
      this.$refs.postInput.blur();
      this.showActions = false;
      this.createPostProceed = false;
      this.allImagesUploaded = false;
      this.uploadFilesList = [];
      this.isPollPost = false;
      this.linkPreview = null;
      this.text = '';

      if (this.postModel) {
        this.$emit('post-cancel', this.postModel);
      }
    },
    removePreview() {
      this.linkPreview = null;
      this.isLinkPreviewValid = true;
    },
    removeFile(file) {
      const index = this.uploadFilesList.indexOf(file);
      this.uploadFilesList.splice(index, 1);
      this.uploadFilesList = [...this.uploadFilesList];
    },

    setVideoDuration(index, e) {
      this.$set(this.duration, index.toString(), Math.floor(e.target.duration));
    },

    getVideoDuration(index) {
      return this.duration[index.toString()];
    },

    prepareImagesForUpload({ target }) {
      const files = target.files;
      const isFilesValid = Array.from(files).every(this.isValidFileFormat);

      if (!files.length || !isFilesValid) {
        return;
      }

      this.allImagesUploaded = false;

      this.uploadFilesList = [
        ...this.uploadFilesList,
        ...[].map.call(files, el => {
          return {
            name: el.name,
            size: el.size,
            type: el.type,
            obj: window.URL.createObjectURL(el),
            uploaded: false,
            imageData: el,
          };
        }),
      ].slice(0, 4);

      let chain = Promise.resolve();

      this.uploadFilesList.forEach(el => {
        chain = chain.then(() => {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = e => resolve(e.target.result);
            reader.onerror = error => reject(error);
            reader.readAsArrayBuffer(el.imageData);
          })
            .then(response => (el.blob = response))
            .catch(this.errorNotify);
        });
      });
      chain.then(() => {
        this.uploadImages();
      });
    },
    updateFileUploadProgress(index, progress) {
      this.$set(this.uploadFilesList[index], 'currentUpload', progress.loaded);
      this.$set(this.uploadFilesList[index], 'totalUpload', progress.total);
    },

    uploadImages() {
      // TODO TH-7124 Rework this component
      const self = this;
      return Promise.all(
        this.uploadFilesList
          //.filter(({ uploaded }) => !uploaded)
          .map((file, index) =>
            file.uploaded
              ? file
              : this.sendFileDirect({
                  fileData: file,
                  config: {
                    onUploadProgress: function(progressEvent) {
                      self.updateFileUploadProgress(index, progressEvent);
                    },
                  },
                })
                  .then(res => {
                    if (!file.uploaded) {
                      file.display_uri = res.display_uri || res.objectURL;
                      file.file_id = res.file_id;
                      file.uploaded = true;
                    }
                  })
                  .catch(() => {
                    this.removeFile(file);
                    this.errorNotify(
                      this.$t('_web_upload-file_failed', 'File upload failed!')
                    );
                  })
          )
      ).finally(() => (this.allImagesUploaded = true));
    },
    removePoll() {
      this.pollOptions = {};
      this.isPollPost = !this.isPollPost;
    },
    addHashMentionsLinks(text) {
      const hashRegex = /(?:|^)(?:#(?!\d+(?:\s|$)))(\w+)(?=\s|#|$)/gi;
      const mentionRegex = /((?=[^\w!])@\w+\b)/g;

      const hashReplacer = query => {
        const replacementString = query.match(hashRegex)[0].trim();
        return `<a href="hashtag://${replacementString.substring(
          1
        )}">${String.fromCharCode(0x200e) + replacementString}</a>`;
      };

      const mentionReplacer = query => {
        const replacementString = query.match(mentionRegex)[0].trim();
        return `<a href="profile://${replacementString.substring(
          1
        )}">${String.fromCharCode(0x200e) + replacementString}</a>`;
      };

      const textWithHashLinks = text.replace(hashRegex, hashReplacer);
      return textWithHashLinks.replace(mentionRegex, mentionReplacer);
    },
    publish(value) {
      if (this.isActionBarDisabled) {
        return;
      }

      if (typeof value === 'string') {
        this.text = value;
      }

      this.createPostProceed = true;

      const postData = this.postModel
        ? this.addHashMentionsLinks(this.text)
        : this.text;

      const data = {
        id: this.postModel ? this.postModel.id : undefined,
        display: {
          author: {
            avatar: this.authorModel._avatar,
            login: this.authorModel._id,
            name: this.authorModel._name,
          },
          comments_count: 0,
          created_on: Math.floor(new Date().getTime() / 1000),
          reactions: {
            dislike: { count: 0, reacted: false },
            like: { count: 0, reacted: false },
          },
          preview:
            !this.uploadFilesList.length && this.linkPreview
              ? this.linkPreview
              : null,
          attachments: this.uploadFilesList.map(el => {
            let type = el.type.split('/')[0] || 'image';
            return {
              type: type,
              file_id: el.file_id,
              [type]: el.display_uri,
            };
          }),
          text: postData,
        },
        send: {
          ...(this.authorModel.isClan ? { clan_id: this.authorModel._id } : {}),
          text: postData,
          attachments: this.uploadFilesList.map(el => el.file_id),
          repostToTwitter: this.repostToTwitter,
          repostToFacebook: this.repostToFacebook,
          preview:
            !this.uploadFilesList.length &&
            this.linkPreview &&
            this.isLinkPreviewValid
              ? this.linkPreview
              : null,
        },
      };

      (this.isPollPost
        ? this.$validator.validateAll().then(valide => {
            if (valide) {
              return this.createPollPost({
                text: this.text,
                ...this.pollOptions,
                clan_id: data.send.clan_id,
              });
            } else {
              this.createPostProceed = false;
            }
          })
        : this.postModel
        ? this.updatePost(data)
        : this.createPost(data)
      )
        .then(post => {
          if (post) {
            this.$emit('post-created', {
              id: this.postModel ? this.postModel.id : undefined,
              post,
              post_type: this.isPollPost ? POLL : GENERIC,
            });
            this.resetForm();
          }
        })
        .then(
          window.dataLayer.push({
            from: this.$router.path,
            hasAttachments: this.uploadFilesList.length > 0,
            postAuthor: this.authorModel._name,
            postType: this.type,
            postId: this.postModel ? this.postModel.id : undefined,
          })
        )
        .then(window.dataLayer.push({ event: 'post updated' }))
        .catch(err => {
          this.errorNotify(err);
          this.createPostProceed = false;
        });
    },

    isValidFileFormat(file) {
      const isValidFormat = this.validFilesFormats.indexOf(file.type) !== -1;
      const isValidSize = true; // file.size / (1024 * 1024) <= 100 Check - is file bigger than 100Mb
      let errors = [];

      if (!isValidFormat) {
        errors.push(
          this.$t(
            '_web_create_post-file-format-error',
            'File format is invalid'
          )
        );
      }
      if (!isValidSize) {
        errors.push(
          this.$t(
            '_web_create_post-file-size-error',
            'File size is bigger than 100Mb'
          )
        );
      }

      if (errors.length) {
        this.errorNotify(errors.join('<br>'));
      }

      return isValidFormat && isValidSize;
    },

    removeInvalidFiles() {
      this.uploadFilesList = this.uploadFilesList.filter(
        this.isValidFileFormat
      );
    },

    togglePollCreation() {
      this.isPollPost = !this.isPollPost;
    },
  },
};
</script>

<style lang="scss">
.create-feed-postEdit {
  background-color: transparent !important;
  padding: 24px !important;
  .textarea {
    min-height: 2.25rem;
    background-color: #181c25 !important;
  }
  &.active {
    .create-feed-postEdit_wrapper {
      position: relative;
      z-index: 21;
    }
  }

  .create-feed-postEdit_body-wrapper {
    //width: calc(100% - 52px);
    width: 100%;
    position: relative;
  }

  .create-feed-postEdit_body {
    .form-group {
      margin: 0;
      padding: 0.5rem 0.75rem;
    }

    .img-preview {
      width: unset !important;
      height: unset !important;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      gap: 8px;
      .img-wrapper {
        &-1 {
          position: relative;
          width: 100%;
          height: 171px;
          @media (max-width: 600px) {
            width: 100%;
          }

          img {
            object-fit: cover;
            width: 100% !important;
            height: 100%;
          }
          span {
            span {
              position: absolute;
              top: 24px;
              @include auto-rtl(right, 22px);
            }
          }
        }

        &-2 {
          position: relative;
          width: 100%;
          height: 171px;
          @media (max-width: 600px) {
            width: 100%;
          }
          img {
            object-fit: cover;
            width: 100% !important;
            height: 100%;
          }
          span {
            span {
              position: absolute;
              top: 24px;
              @include auto-rtl(right, 22px);
            }
          }
        }

        &-3 {
          position: relative;
          width: 100%;
          height: 171px;
          @media (max-width: 600px) {
            width: 100%;
          }
          span {
            span {
              position: absolute;
              top: 24px;
              @include auto-rtl(right, 22px);
            }
          }
          img {
            object-fit: cover;
            width: 100% !important;
            height: 100%;
          }
        }

        &-4 {
          position: relative;
          width: 100%;
          height: 171px;
          @media (max-width: 600px) {
            width: 100%;
          }
          span {
            span {
              position: absolute;
              top: 24px;
              @include auto-rtl(right, 22px);
            }
          }
          img {
            object-fit: cover;
            width: 100% !important;
            height: 100%;
          }
        }
      }
    }
  }

  .post-actions {
    .btn-action {
      font-size: 0.875rem;
      color: $ph-primary-text;
      @include primary-background-gradient(135deg);
      border: none;
      border-radius: 1rem;
      outline: none;
      min-width: 150px;
      height: 2rem;
      line-height: 1.8rem;
      padding: 0;
      cursor: pointer;

      &[disabled] {
        color: $ph-secondary-text;
      }

      svg {
        margin: -2px 4px 0;
      }
    }

    label.post-select-files {
      position: relative;
      cursor: pointer;
      background: transparent;
      vertical-align: middle;
      margin-bottom: 0;

      input {
        position: absolute;
        top: -1000px; // do not remove it
        left: 0px;
        display: none; //this prevents scrolling to the top when uploading files
      }
    }

    button[disabled] {
      label {
        pointer-events: none;
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  &_input {
    textarea::placeholder {
      color: $ph-primary-text;
    }

    &--collapsed {
      textarea {
        background-color: transparent !important;
      }
    }
  }
}
.remove-btn {
  margin-right: 16px;
  @include auto-rtl(margin-right, 16px);
}
.create-feed-postEdit-home {
  .img-preview {
    width: unset !important;
    &.has-images {
      height: 280px !important;
    }

    video {
      border-radius: $ph-medium-radius;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-duration {
      font-size: 0.75rem;
      color: $ph-secondary-text;
      background: #000;
      line-height: 16px;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0 0.25rem;
    }

    .img-wrapper {
      &-1 {
        width: 100%;
        height: 100%;
        img {
          width: 100% !important;
        }
      }

      &-2 {
        width: 50%;
        height: 100%;
        img {
          width: 100% !important;
        }

        &:nth-child(1) {
          float: left;
          padding-right: $ph-small-space;
        }

        &:nth-child(2) {
          float: right;
          padding-left: $ph-small-space;
        }
      }

      &-3 {
        img {
          width: 100% !important;
        }
        &:nth-child(1) {
          width: 50%;
          height: 100%;
          float: left;
          padding-right: $ph-small-space;
        }

        &:nth-child(2) {
          width: 50%;
          height: 50%;
          float: right;
          padding-left: $ph-small-space;
          padding-bottom: $ph-small-space;
        }

        &:nth-child(3) {
          width: 50%;
          height: 50%;
          float: right;
          padding-left: $ph-small-space;
          padding-top: $ph-small-space;
        }
      }

      &-4 {
        padding-bottom: $ph-medium-space;
        img {
          width: 100% !important;
        }
        &:nth-child(2n + 1) {
          width: 50%;
          height: 50%;
          float: left;
          padding-right: $ph-small-space;
        }

        &:nth-child(2n) {
          width: 50%;
          height: 50%;
          float: right;
          padding-left: $ph-small-space;
        }

        &:nth-child(3),
        &:nth-child(4) {
          padding-bottom: 0;
        }
      }

      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: $ph-medium-radius;
      }

      .btn-delete {
        position: absolute !important;
        z-index: 10 !important;
        top: 10px !important;
        right: 10px !important;
        @include auto-rtl(right, 22px);
      }

      &.loading {
        img {
          opacity: 0.5;
          height: calc(100% - 34px);
        }

        video {
          height: calc(100% - 34px);
        }

        .video-duration {
          bottom: 34px;
        }

        .btn-delete {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .poll-options {
    flex-direction: column;
    gap: 10px;
  }
  .create-feed-postEdit_wrapper {
    padding: 0px !important;
  }

  .create-feed-postEdit {
    margin-left: -20px;
    margin-right: -20px;
  }

  .remove-btn {
    margin-right: 0px !important;

    @include auto-rtl(margin-right, 0px !important);
  }
}
.btn-delete {
  position: absolute !important;
  z-index: 10 !important;
  top: 10px !important;
  right: 10px !important;
  cursor: pointer;
  @include auto-rtl(right, 22px);
}
.create-feed-postEdit {
  video {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
</style>
