import { uniqueId } from '@/utils/lodashUtils';
import { generateMutations } from '@/utils/store';

const INITIAL_STATE = () => ({
  targetElementId: null,
  settings: {
    stopItemClickBubbling: false,
    popoverClass: 'font-size-xs',
    isFixed: false,
    targetSelector: '',
    float: [],
    regard: 'top',
    autoClose: false,
    translate: 0,
  },
  text: '',
  title: '',
  slot: '',
});

const getters = {
  targetElement({ targetElementId }) {
    return document.querySelector(`[data-popover-id="${targetElementId}"]`);
  },
  isVisible({ targetElementId }) {
    return !!targetElementId;
  },
};

const mutations = {
  ...generateMutations('text', 'title', 'slot'),

  setTargetElement(state, payload) {
    const currentTarget = document.querySelector(
      `[data-popover-id="${state.targetElementId}"]`
    );
    if (currentTarget instanceof Node) {
      delete currentTarget.dataset.popoverId;
    }
    state.targetElementId =
      payload instanceof Node
        ? (payload.dataset.popoverId = uniqueId('popover-id'))
        : payload;
  },

  setSettings(state, payload) {
    Object.assign(state.settings, payload);
  },
};

const actions = {
  open(
    { commit },
    {
      event = null,
      node = null,
      selector = null,
      settings = {},
      text,
      title,
      slot,
    }
  ) {
    const targetElement =
      (event && event.target) ||
      (selector && document.querySelector(selector)) ||
      node;
    commit('setTargetElement', targetElement);
    commit('setSettings', settings);
    commit('setText', text);
    commit('setTitle', title);
    commit('setSlot', slot);
  },
  hide({ commit }) {
    commit('setTargetElement', null);
  },
};

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  getters,
  mutations,
  actions,
};
