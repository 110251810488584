import api from '@/api';
import { BATTLE_STATUSES, POSSIBLE_RESULTS } from '@/constants/battles';
import { CLAN, PROFILE } from '@/constants/accounts';
import { cloneDeep, merge, pick, get } from '@/utils/lodashUtils';

const INITIAL_BATTLES_STATE = () => ({
  battle: null,
});

const findUserIn = (battle, myId, propName = '') => {
  const find = (list = []) =>
    list.findIndex(item => get(item, 'profile.login', item.login) === myId);
  if (!propName) {
    return find(battle.players);
  }

  const teamIndex = battle.players.findIndex(team => {
    return team ? find(team[propName]) > -1 : false;
  });
  return teamIndex > -1 ? teamIndex : null;
};

/**
 * Determines which team a player belongs to or under which number his profile
 * 1 -> player1 || 2 -> player2
 * @param {AccountModel} battle
 * @param {String} myId
 * @return {number|null}
 */
const getPlayerNumber = (battle, myId) => {
  if (battle.playerType === CLAN) {
    return (
      findUserIn(battle, myId, 'bench') || findUserIn(battle, myId, 'squad')
    );
  }
  const index = findUserIn(battle, myId);
  return index < 0 ? null : index;
};

/**
 * Returns the additional information about user permissions using myId for comparision
 * @param {String} myId
 * @param {AccountModel} battle
 * @return {Object} {
 *   isBetweenProfiles: boolean,
 *   isBetweenClans: boolean,
 *   myIndex: number - player team number, look getPlayerNumber,
 *   isParticipant: boolean - is a user presents in any clan or in profiles player1/2,
 *   canManageBattle: boolean, - can a user starts battle, provide result, etc.(player1/2 or captain of a team)
 *   isHost: boolean
 * }
 */
const getServiceInfo = (myId, battle) => {
  const info = {};
  info.isBetweenProfiles = battle.playerType === PROFILE;
  info.isBetweenClans = battle.playerType === CLAN;
  info.myIndex = getPlayerNumber(battle, myId);
  info.isParticipant = info.myIndex != null;
  info.canManageBattle = !!(
    (info.isBetweenProfiles && info.myIndex) ||
    (info.isBetweenClans && battle.captainIndex === info.myIndex)
  );
  info.isHost = !!(
    (info.isBetweenProfiles && info.myIndex != null) ||
    (info.isBetweenClans && info.canManageBattle && info.myIndex != null)
  );
  const isBeforeStart = [
    BATTLE_STATUSES.pending,
    BATTLE_STATUSES.scheduled,
  ].includes(battle.status);
  info.isSquadVisible = !!(info.isBetweenClans && isBeforeStart);
  info.isResultVisible = true; //!isBeforeStart;
  if (info.myIndex != null) {
    info.isTechWin =
      battle.players[info.myIndex].result === POSSIBLE_RESULTS.techWin;
    info.isTechLose =
      battle.players[info.myIndex].result === POSSIBLE_RESULTS.techLoss;
  }
  return info;
};

const getters = {
  dynamicBattleModel: state => state.battle,
};
const mutations = {
  setBattleModel(state, { myId, battle }) {
    const clonedModel = cloneDeep(battle);
    state.battle = merge(clonedModel, getServiceInfo(myId, clonedModel));
  },
  clearBattleModel(state) {
    state.battle = null;
  },
  updateBattleFromSubscriber(state, { battleId, myId, data }) {
    if (
      !state.battle ||
      !battleId ||
      !data ||
      (data.extended.battle_id !== battleId &&
        data.extended.battleId !== battleId)
    ) {
      return;
    }
    const pickCompletedProps = [
      'player1Scored',
      'player2Scored',
      'player1Result',
      'player2Result',
    ];
    let dataForUpdate;
    switch (data.type) {
      case 'battle_info_updated':
      case 'battle_player_finished':
        dataForUpdate = data.extended;
        break;
      case 'battle_completed_tech':
        dataForUpdate = {
          status: BATTLE_STATUSES.completed,
          ...pick(data.extended, pickCompletedProps),
        };
        break;
      case 'battle_activated':
        dataForUpdate = { status: BATTLE_STATUSES.pending };
        break;
      case 'battle_started':
        dataForUpdate = { status: BATTLE_STATUSES.started };
        break;
      case 'battle_completed':
        dataForUpdate = {
          status: BATTLE_STATUSES.completed,
          ...pick(data.extended, pickCompletedProps),
        };
        break;
      case 'battle_cancelled':
        dataForUpdate = { status: BATTLE_STATUSES.cancelled };
        break;
      case 'battle_match_result_updated':
        dataForUpdate = pick(data.extended, pickCompletedProps);
        break;
      case 'battle_should_confirm':
        dataForUpdate = { shouldConfirm: true };
        break;
    }
    //TODO investigate why tournament contains players array, but sends notification if separate fields
    if (dataForUpdate.player1Result)
      state.battle.players[0].result = dataForUpdate.player1Result;
    if (dataForUpdate.player1Scored)
      state.battle.players[0].score = dataForUpdate.player1Scored;
    if (dataForUpdate.player2Result)
      state.battle.players[1].result = dataForUpdate.player2Result;
    if (dataForUpdate.player2Result)
      state.battle.players[1].score = dataForUpdate.player2Scored;

    const updatedBattle = state.battle.update(dataForUpdate);

    const serviceInfo = getServiceInfo(myId, updatedBattle);
    state.battle = merge(updatedBattle, serviceInfo);
  },
  updateBattleModel(state, data) {
    state.battle = state.battle.update(data);
  },
};
const actions = {
  getMatchesByPlayer(
    {
      getters: { accessToken },
    },
    { accountId, page_size, page_number, page, battleStatuses }
  ) {
    return api.competitions
      .getMatchesByPlayer(accessToken, {
        params: {
          player: accountId,
          page_size,
          page_number,
          page,
          battle_status: battleStatuses,
        },
      })
      .then(({ data }) => data);
  },
  getBattles(
    {
      getters: { accessToken },
    },
    { accountId, eventId, page, battleStatuses }
  ) {
    return api.battles
      .getBattlesList()(accessToken, {
        params: {
          player: accountId,
          event_id: eventId,
          page,
          battle_status: battleStatuses,
        },
      })
      .then(response => response.data);
  },
  startBattle(
    {
      getters: { accessToken },
    },
    battleId
  ) {
    return api.battles
      .startBattle(battleId)(accessToken)
      .then(response => response.data);
  },
  updateBattleInfo(
    {
      getters: { accessToken },
    },
    { battleId, battleInfo: text }
  ) {
    return api.battles
      .updateBattleInfo(battleId)(accessToken, { text })
      .then(response => response.data);
  },
  // user only
  setBattleResults({ getters }, payload) {
    return api.battles.postResults(payload.battleID)(
      getters.accessToken,
      payload
    );
  },
  getBattleInfo({ getters }, battleID) {
    return api.battles
      .getById(battleID)(getters.accessToken)
      .then(response => response.data);
  },
  cancelBattle(
    {
      getters: { accessToken },
    },
    battleID
  ) {
    return api.battles.cancel(battleID)(accessToken, { status: 'cancelled' });
  },
  finishBattle(
    {
      getters: { accessToken },
    },
    battleID
  ) {
    return api.battles.completeBattle(battleID)(accessToken);
  },
  confirmResults(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.battles.confirmResults(payload.battleID)(accessToken, payload);
  },
  addPlayerToRoster(
    {
      getters: { accessToken },
    },
    { battleID, playerID }
  ) {
    return api.battles.addToRoster(battleID, playerID)(accessToken);
  },
  deletePlayerFromRoster(
    {
      getters: { accessToken },
    },
    { battleID, playerID }
  ) {
    return api.battles.deleteFromRoster(battleID, playerID)(accessToken);
  },
};

export default {
  state: INITIAL_BATTLES_STATE(),
  getters,
  mutations,
  actions,
};
