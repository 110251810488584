import api from '@/api';

const actions = {
  fetchPlayers: ({ getters: { accessToken } }, params) => {
    return api.players
      .getPlayers(accessToken, { params })
      .then(({ data }) => data);
  },
};

export default {
  actions,
};
