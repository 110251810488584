import SubscriptionPlans from '@/views/subscription/SubscriptionPlans.vue';
import SubscriptionAnnouncement from '@/views/subscription/DefaultPage.vue';
import Billing from '@/views/subscription/billing';
import { SUBSCRIPTION_SETUP } from '@/constants/router';
import { ifAuthorized } from './guards.js';
import router from '@/router';

// store.getters.
export default [
  {
    path: '/subscription/:confirm_validation?/:subscriptionId?/:tap_id?',
    name: SUBSCRIPTION_SETUP,
    // beforeEnter: beforeEnterCheckAuth,
    component: SubscriptionPlans,
    meta: {
      inMainNav: 90,
      inMainNavOrg: false,
      guestEnabled: true,
      // titleKey: '_web_left-sidebar_nav-ph-max-link',
      titleKey: '',
      icon: 'sidebar/phmax',
      fallbackLabel: 'PLAYHERA MAX',
    },
  },
  {
    path: '/subscription-announcement',
    name: 'subscriptionAnnouncement',
    beforeEnter: () => {
      router.push({ name: 'subscriptionSetup' });
    },
    component: SubscriptionAnnouncement,
    meta: {
      inMainNav: 90,
      inMainNavOrg: false,
      titleKey: '',
      icon: 'sidebar/phmax',
      fallbackLabel: 'PLAYHERA MAX',
    },
  },
  {
    path: '/billing',
    name: 'billing',
    beforeEnter: ifAuthorized,
    component: Billing,
    meta: {
      titleKey: '_web_left-sidebar_nav-billing-link',
      fallbackLabel: 'Billing',
    },
  },
];
