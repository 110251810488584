<template>
  <div class="row my-3 pb-3">
    <div class="col-auto">
      <HeraFormSwitch
        v-model="checkIn"
        name="checkIn"
        :label="$$t('Require Check-in:', 'stage-check-in-label')"
        :tooltip="
          $$t(
            'Require check-in from players before a competition starts',
            'stage-check-in-tooltip'
          )
        "
        :disabled="isStageFieldDisabled('checkin') || disabled"
      />
    </div>
    <div class="col-auto d-flex align-items-center">
      <TimePicker
        v-validate="
          !checkIn ||
          checkIfCheckInStarted() ||
          isStageFieldDisabled(`stage_check_in_start_date`) ||
          disabled
            ? {}
            : {
                required: true,
                ...(currentTournamentStage.date_end
                  ? {
                      before: calcMaxDate('check_in_start_date'),
                    }
                  : {}),
              }
        "
        duration
        :value="checkInDate"
        class="date-time-input"
        :required="checkInTimeSteps['check_in_start_date'].required || checkIn"
        :data-vv-name="$lodash.lowerCase('check_in_start_date')"
        :data-vv-as="
          $t(
            `_web_tournament_date-check_in_start_date`,
            checkInTimeSteps['check_in_start_date'].label
          )
        "
        :disabled="
          !checkIn ||
            isStageFieldDisabled(`stage_check_in_start_date`) ||
            disabled
        "
        :label="
          $t(
            `_web_tournament_date-check_in_start_date`,
            checkInTimeSteps['check_in_start_date'].label
          )
        "
        @input="onCheckInDateUpdate"
      />
      <TextBlock variant="muted" class="h-ml-3 mt-4">
        {{
          $t(
            '_web_tournament_stage_form_check_in_start_date-postfix',
            'before stage start'
          )
        }}
      </TextBlock>
    </div>
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import { HALF_HOUR } from '@/constants/time';
import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import TimePicker from '@/components/form/TimePicker';
import TextBlock from '@/components/atoms/TextBlock';
import { prop } from '@/utils/factories';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import dateMethods from '@/mixins/dateMethods';
const checkInTimeSteps = () => ({
  check_in_start_date: {
    value: null,
    label: 'Check-in begin:',
    required: false,
  },
  // check_in_end_date: {
  //   value: null,
  //   label: 'Check-in finish:',
  //   required: false,
  // },
});
export default {
  name: 'StageCheckInSetting',
  components: {
    HeraFormSwitch,
    TimePicker,
    TextBlock,
  },
  inject: ['$validator'],
  mixins: [
    translate('_web_tournament_stage_form'),
    ...dateMethods,
    stageSetupHelpers,
  ],
  props: {
    disabled: prop(Boolean),
  },
  data() {
    return {
      checkInTimeSteps: checkInTimeSteps(),
    };
  },
  computed: {
    checkIn: {
      get() {
        return Boolean(
          this.$lodash.get(
            this.currentTournamentStage,
            'check_in_enabled',
            false
          )
        );
      },
      set(newVal) {
        this.updateCurrentTournamentStage({ check_in_enabled: newVal });
      },
    },
    checkInDate() {
      const dateValue = this.$lodash.get(
        this.currentTournamentStage,
        'check_in_start_date'
      );
      const seconds = dateValue
        ? this.$lodash.get(this.currentTournamentStage, 'date_start') -
          dateValue
        : HALF_HOUR;

      return Math.floor(
        this.startOfDayObj()
          .plus({ seconds })
          .toSeconds()
      );
    },
  },
  methods: {
    onCheckInDateUpdate(newVal) {
      if (this.checkIn) {
        const pickedDate = this.secondsToDatetime(newVal);
        const diff = pickedDate
          .diff(this.startOfDayObj(), ['hours', 'minutes'])
          .shiftTo('seconds');
        newVal = diff.seconds;
        const { date_start } = this.currentTournamentStage;
        this.updateCurrentTournamentStage({
          check_in_start_date: date_start - newVal,
        });
      }
    },
    checkIfCheckInStarted() {
      const now = this.getNowSeconds();
      const checkInStart = this.$lodash.get(
        this.currentTournamentStage,
        'check_in_start_date',
        now
      );
      return this.checkIn && checkInStart < now;
    },
    calcMaxDate(key) {
      const {
        date_end,
        block_configuration = {},
      } = this.currentTournamentStage;
      const limit =
        key === 'check_in_start_date'
          ? date_end || null
          : key !== 'date_start'
          ? null
          : !date_end
          ? null
          : date_end - block_configuration.match_duration;
      return limit;
    },
  },
};
</script>

<style></style>
