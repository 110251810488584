<template>
  <!-- Match time options -->
  <TimePicker
    v-model="matchDuration"
    v-validate="{
      required,
      is_not: startOfDayObj().toSeconds(), //eslint-disable-line
    }"
    :name="key"
    duration
    :required="required && !disabled && !isInSafeMode"
    :label="label"
    :disabled="disabled || isInSafeMode"
    :data-vv-name="key"
    :data-vv-as="label"
    :error="errors.first(key)"
    :placeholder="$t('_web_tournament_match-duration-placeholder', 'Duration')"
    :tooltip="
      $t(
        '_web_tournament_stage_match_time-tooltip',
        'Time frame that defines when all matches of current stage must be held.'
      )
    "
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { cloneDeep, get } from '@/utils/lodashUtils';
import dateMethods from '@/mixins/dateMethods';
import { prop } from '@/utils/factories';

import TimePicker from '@/components/form/TimePicker';

const matchDurationStep = 3600;

export default {
  name: 'MatchDuration',
  components: {
    TimePicker,
  },
  mixins: [...dateMethods],
  props: {
    multiplier: prop(Number, 1),
    required: prop(Boolean, true),
    disabled: prop(Boolean),
  },
  inject: ['$validator'],
  data() {
    return {
      key: 'match_duration',
      label: this.$t(
        `_web_tournament_time-restrictions-${this.key}`,
        'Match Duration:'
      ),
    };
  },
  computed: {
    ...mapGetters(['currentTournamentStage']),
    isInSafeMode() {
      return Boolean(this.$route.meta.safeMode);
    },
    stageID() {
      return get(this.currentTournamentStage, 'id', '');
    },
    blockConfig() {
      return get(this.currentTournamentStage, 'block_configuration', {});
    },
    matchDuration: {
      get() {
        const duration = get(this.blockConfig, this.key, matchDurationStep);
        return this.initDuration(duration);
      },
      set(newVal, oldVal) {
        if (newVal !== oldVal) {
          const pickedDate = this.secondsToDatetime(newVal);
          const diff = pickedDate
            .diff(this.startOfDayObj(), ['hours', 'minutes'])
            .shiftTo('seconds');
          newVal = diff.seconds;
          let configuration = cloneDeep(this.blockConfig);
          configuration[this.key] = +newVal;
          this.updateCurrentTournamentStage({
            block_configuration: configuration,
          });
        }
      },
    },
  },
  watch: {
    multiplier(val) {
      if (val) {
        this.matchDuration = this.initDuration(matchDurationStep * val);
      }
    },
  },
  methods: {
    ...mapMutations(['updateCurrentTournamentStage']),
    initDuration(seconds) {
      return Math.floor(
        this.startOfDayObj()
          .plus({ seconds })
          .toSeconds()
      );
    },
  },
};
</script>

<style scoped></style>
