<template>
  <div>
    <div
      class="cover cover-edit"
      :style="height ? { height: heightReplaced } : null"
    >
      <div
        class="d-flex flex-row status-bar-user position-absolute align-items-end avatar-wrapper"
      >
        <div class="d-flex align-items-end status-bar-user-avatar">
          <ImageUpload
            v-model="accountAvatar"
            :placeholder="
              $t('_web_account_avatar-upload-placeholder', 'Upload avatar')
            "
            help="512×512px"
            class="image-avatar-edit"
            :style="{ height: '150px', width: '150px' }"
            hide-label
          />
        </div>
        <div class="mx-2 mb-5 status-bar-user-name">
          {{ account.isProfile ? accountId : account._name }}
        </div>
      </div>
      <ImageUpload
        v-model="accountCover"
        :height="290"
        :width="805"
        :placeholder="
          $t('_web_account_cover-upload-placeholder', 'Upload cover')
        "
        help="1080×380px"
        hide-label
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { computedStyle } from '@/utils/getProps.js';
import ImageUpload from '@/components/form/ImageUpload';

export default {
  name: 'AccountCoverEdit',
  components: {
    ImageUpload,
  },
  props: {
    height: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    ...mapState('current', ['account', 'accountType', 'accountId']),
    ...mapGetters(['accountEditData']),
    accountCover: {
      get() {
        return this.$lodash.get(
          this.accountEditData,
          '__cover.display_uri',
          this.account._cover
        );
      },
      set(cover) {
        this.updateEditData({
          __cover: cover,
        });
      },
    },
    accountAvatar: {
      get() {
        return this.$lodash.get(
          this.accountEditData,
          '__avatar.display_uri',
          this.account._avatar
        );
      },
      set(avatar) {
        this.updateEditData({
          __avatar: avatar,
        });
      },
    },
    heightReplaced() {
      return computedStyle(this.height);
    },
  },
  methods: {
    ...mapMutations(['updateEditData']),
  },
};
</script>
<style lang="scss">
.cover-edit {
  padding-bottom: 75px;
  background: none;
  .avatar-wrapper {
    bottom: 0;
    padding-bottom: 0;
    @include auto-rtl(padding-left, 48px);
  }
  .upload-image-wrapper {
    border-radius: $ph-medium-radius;
    label,
    .file-upload-field,
    .upload-image-preview {
      border-radius: $ph-medium-radius;
    }
  }
}
@media (max-width: 767px) {
  .status-bar-user-name {
    font-size: large !important;
  }
  .avatar-wrapper {
    padding-left: 0px !important;
    @include auto-rtl(padding-left, 0px !important);
    .wrapper-control {
      height: 100px !important;
      width: 100px !important;
    }
  }
}
</style>
