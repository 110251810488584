import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const CLANS = 'clans/';

const postClan = authorizedPostRequest(CLANS);

const getClan = clanID => authorizedGetRequest(CLANS + clanID);

const getClans = (accessToken, params) =>
  authorizedGetRequest(CLANS)(accessToken, { params });

const followClan = (accessToken, id, params) =>
  authorizedPostRequest(CLANS + `${id}/follows`)(accessToken, params);

const deleteClan = (accessToken, clanID) =>
  authorizedDeleteRequest(CLANS + `${clanID}`)(accessToken);

const deleteTeamInClan = (accessToken, { clanID, teamID }) =>
  authorizedDeleteRequest(CLANS + `${clanID}/teams/${teamID}`)(accessToken);

const inviteClanMember = (accessToken, clan, candidates) =>
  authorizedPostRequest(CLANS + `${clan}/invites`)(accessToken, { candidates });

const cancelInviteClanMember = (accessToken, clanId, candidates) =>
  authorizedDeleteRequest(CLANS + `${clanId}/invites`)(accessToken, {
    candidates,
  });

const cancelClanMember = (accessToken, clanID, login) =>
  authorizedDeleteRequest(CLANS + `${clanID}/members/${login}`)(accessToken);

const createTeamInClan = data =>
  authorizedPostRequest(CLANS + `${data.clanID}/teams`);

const updateClan = id => authorizedPutRequest(CLANS + `${id}`);

const updateTeamInClan = ({ clanID, teamID }) =>
  authorizedPutRequest(CLANS + `${clanID}/teams/${teamID}`);

const updateCaptainInTeam = ({ clanID, teamID }) =>
  authorizedPutRequest(CLANS + `${clanID}/teams/${teamID}/captain`);

const updateRoleInClan = ({ clanID, login }) =>
  authorizedPutRequest(CLANS + `${clanID}/members/${login}`);

const getClanMembers = clanID =>
  authorizedGetRequest(CLANS + `${clanID}/members`);

const getClanInvites = clanID =>
  authorizedGetRequest(CLANS + `${clanID}/invites`);

const getClanFollows = clan => authorizedGetRequest(CLANS + `${clan}/follows`);

const getClanRequests = () => authorizedGetRequest(`${CLANS}my/requests`); // O.o

const getClanTeams = clanId => authorizedGetRequest(CLANS + `${clanId}/teams`);

const getClanTeam = ({ clanID, teamID }) =>
  authorizedGetRequest(CLANS + `${clanID}/teams/${teamID}`);

const getClanTeamMembers = ({ clanID, teamID }) =>
  authorizedGetRequest(CLANS + `${clanID}/teams/${teamID}/members`);

const getPopularClans = authorizedGetRequest(`${CLANS}my/popular`);

export default {
  postClan,
  getClan,
  getClans,
  deleteClan,
  deleteTeamInClan,
  followClan,
  inviteClanMember,
  cancelInviteClanMember,
  cancelClanMember,
  updateClan,
  getClanTeams,
  getClanTeam,
  createTeamInClan,
  updateTeamInClan,
  getClanMembers,
  getClanInvites,
  getClanFollows,
  getClanRequests,
  getClanTeamMembers,
  updateCaptainInTeam,
  updateRoleInClan,
  getPopularClans,
};
