<template>
  <!--backend description: 'Contains list of participants. Up to 500 names, name < 50 characters.'-->
  <div>
    <div class="row justify-content-start pb-3">
      <div class="col-3">
        <Avatar :size="60" type="group" />
      </div>
      <div class="col align-self-center">
        <TextInput
          v-model="groupName"
          v-validate="{
            required: true,
            max: 50,
          }"
          class="input-group-name"
          :placeholder="
            $t('_web_communications_create_group-name', 'Enter Name')
          "
          :label="
            $t('_web_communications_create_group-name', 'Group Chat Name')
          "
          :label-font-size="0.75"
          name="group-name"
          :error="errors.first('group-name')"
        />
      </div>
    </div>
    <h5 class="font-size-sm">
      {{ $t('_web_communications_create_group_participants', 'Participants') }}
    </h5>
    <Search v-model="searchValue" class="mb-3" />
    <UsersList v-model="participants" v-bind="{ searchValue }" multi-select />
  </div>
</template>

<script>
import Avatar from '@/components/images/Avatar';
import TextInput from '@/components/atoms/form/HeraFormTextInput';
import Search from '@/components/form/HeraFormSearch';
import UsersList from './UsersList';

export default {
  name: 'ConversationCreateGroup',
  inject: ['$validator'],
  components: {
    Avatar,
    TextInput,
    UsersList,
    Search,
  },
  model: {
    prop: 'conversationData',
    event: 'update',
  },
  props: {
    conversationData: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchValue: '',
      participants: [],
    };
  },
  computed: {
    groupName: {
      get() {
        return this.conversationData.title || '';
      },
      set(title) {
        this.$emit('update', { ...this.conversationData, title });
      },
    },
  },
  watch: {
    participants: {
      handler(participants) {
        this.$emit('update', { ...this.conversationData, participants });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.input-group-name {
  label {
    margin-bottom: 0 !important;
  }
}
</style>
