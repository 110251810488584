var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"date-time-wrapper align-items-end d-flex flex-row align-items-center",class:{ 'date-time-wrapper--no-label': !_vm.dateLabel }},[_vm._t("prepend-date"),_c('DatePicker',_vm._g(_vm._b({directives:[{name:"validate",rawName:"v-validate",value:({
        required: _vm.required,
      }),expression:"{\n        required,\n      }"}],staticClass:"date-time-wrapper__date-input",class:[_vm.datePickerClass],attrs:{"name":_vm.key,"label":_vm.dateLabel,"data-vv-name":_vm.key,"data-vv-as":_vm.dateLabel,"error":_vm.errors.first(_vm.key) || _vm.errors.first(_vm.lowerCase(_vm.key))},model:{value:(_vm.valueModel),callback:function ($$v) {_vm.valueModel=$$v},expression:"valueModel"}},'DatePicker',{
        required: _vm.required,
        disabled: _vm.disabled,
        minDate: _vm.minDate,
        maxDate: _vm.maxDate,
        fitContent: _vm.fitContent,
        hideAsterisk: _vm.hideAsterisk,
        hideErrorText: true,
      },false),_vm.eventListeners)),_vm._t("prepend-time"),_c('TimePicker',_vm._g(_vm._b({directives:[{name:"validate",rawName:"v-validate",value:({
        required: _vm.required,
      }),expression:"{\n        required,\n      }"}],ref:(_vm.key + "-time"),staticClass:"date-time-wrapper__time-input h-ml-3",class:[_vm.timePickerClass, { 'mt-0': _vm.timeLabel }],attrs:{"name":_vm.key,"label":_vm.timeLabel,"data-vv-name":(_vm.key + "-time"),"data-vv-as":_vm.timeLabel,"error":_vm.errors.first((_vm.key + "-time")) || _vm.errors.first(_vm.lowerCase(_vm.key)),"placeholder":"HH:mm"},model:{value:(_vm.timeValueModel),callback:function ($$v) {_vm.timeValueModel=$$v},expression:"timeValueModel"}},'TimePicker',{
        required: _vm.required,
        disabled: _vm.disabled,
        minDate: _vm.minDate,
        maxDate: _vm.maxDate,
        fitContent: _vm.fitContent,
        hideAsterisk: _vm.hideAsterisk,
        hideErrorText: true,
      },false),_vm.eventListeners)),_vm._t("addon")],2),(_vm.errors.first(_vm.key) || _vm.errors.first(_vm.lowerCase(_vm.key)))?_c('div',{staticClass:"d-flex mt-2",class:_vm.errors.first(_vm.key) || _vm.errors.first(_vm.lowerCase(_vm.key))
        ? 'justify-content-between'
        : 'justify-content-end'},[_c('TextBlock',{attrs:{"variant":"error","size":_vm.$scss.fontSizes_xs,"line-height":"0.875rem","text":_vm.errors.first(_vm.key) || _vm.errors.first(_vm.lowerCase(_vm.key))}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }