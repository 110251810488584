// components
const EmptyRouteWrapper = {
  name: 'EmptyWrapper',
  render(h) {
    return h('router-view');
  },
};

import { CONSTRUCTOR_MODES } from '@/constants/tournaments';
import TournamentsWrapper from '@/views/tournaments/';
import TournamentsList from '@/views/tournaments/management/list';
import { beforeEnterCheckManagementAuth } from '../guards.js';

import SingleTournamentWrapper from '@/views/tournaments/management/singleTournament';
import MatchesWrapper from '@/views/tournaments/management/matches';
import StageEditor from '@/views/tournaments/management/singleTournament/stages/StageEditor';
const SingleStageSettings = () =>
  import('@/views/tournaments/management/singleTournament/stages/components/FullStageForm');
const TournamentNew = () =>
  import('@/views/tournaments/management/singleTournament/Setup');
const TournamentSettings = () =>
  import('@/views/tournaments/management/singleTournament/Settings');
import BracketsWrapper from '@/views/tournaments/management/brackets';
import Standings from '@/views/tournaments/management/standings/';
import PostEventReport from '@/views/tournaments/management/postEventReport/';
import Dashboard from '@/views/tournaments/management/singleTournament/Dashboard';
import StagesWrapper from '@/views/tournaments/management/singleTournament/stages';
import StagesList from '@/views/tournaments/management/singleTournament/stages/StagesList';
// import ConstructBracketsWrapper from '@/views/tournaments/management/singleTournament/ConstructBrackets';

// children routes
import allParticipantsRoutes from './allParticipants';
import StageParticipants from '@/views/tournaments/management/participants/StageParticipants';
import bracketsRoutes from './brackets';

import { NAVIGATION_BUTTONS } from '@/utils/tournamentsConstants';

export default [
  {
    path: '/tournaments-management',
    beforeEnter: beforeEnterCheckManagementAuth,
    component: TournamentsWrapper,
    meta: {
      showAdminFunctions: true,
    },
    children: [
      {
        path: '', // <domain>/tournaments-management
        name: 'tournaments',
        component: TournamentsList,
        meta: {
          hideFromUser: true,
          // inMainNav: 40,
          inMainNavOrg: 40,
          titleKey: '_web_left-sidebar_nav-tournaments-management-link',
          fallbackLabel: 'Tournaments Management',
          icon: 'sidebar/manage-tournaments',
          permission: 'editTournament',
          showAdminFunctions: true,
          hideFooter: false,
        },
      },
      {
        path: ':tournamentID?', // <domain>/tournaments-management/<ID> -> to setup
        component: SingleTournamentWrapper,
        meta: {
          showAdminFunctions: true,
          hideFooter: false,
        },
        children: [
          ...allParticipantsRoutes,
          {
            path: 'create-new', // <domain>/tournaments-management/<ID>/create-new
            name: 'TournamentSetup',
            component: TournamentNew,
            alias: '',
            meta: {
              titleKey: '_web_tournament_tournament-setup-page-title',
              fallbackLabel: 'Tournament Setup',
            },
          },
          {
            path: 'post-event-report', // <domain>/tournaments-management/<ID>/standings/<stageID>
            name: 'TournamentPostEventReport',
            component: PostEventReport,
            meta: {
              titleKey: '_web_tournament_tournament-post-event-page-title',
              fallbackLabel: 'Post Event Report',
              hideStatusBtn: true,
            },
          },
          {
            path: 'settings', // <domain>/tournaments-management/<ID>/settings
            name: 'TournamentSettings',
            component: TournamentSettings,
            meta: {
              titleKey: '_web_tournament_tournament-settings-page-title',
              fallbackLabel: 'Tournament Settings',
            },
          },
          {
            path: 'dashboard', // <domain>/tournaments-management/<ID>/standings/<stageID>
            name: 'TournamentDashboard',
            component: Dashboard,
            meta: {
              titleKey: '_web_tournament_tournament-dashboard-page-title',
              fallbackLabel: 'Dashboard',
              hideStatusBtn: true,
            },
          },
          {
            path: 'standings/:stageID?', // <domain>/tournaments-management/<ID>/standings/<stageID>
            name: 'TournamentStandings',
            component: Standings,
            meta: {
              titleKey: '_web_tournament_tournament-standings-page-title',
              fallbackLabel: 'Tournament standings',
              hideStatusBtn: true,
            },
          },
          {
            path: `matches`,
            component: MatchesWrapper,
            name: 'manageMatches',
            children: [
              {
                path: 'add-stage', // <domain>/tournaments-management/<ID>/construct-brackets/add-stage
                name: 'addStage',
                component: StageEditor,
                meta: {
                  safeMode: false,
                  titleKey: '_web_tournaments_stages-create-stage-title',
                  fallbackLabel: 'Add stage',
                  showAdminFunctions: true,
                  buttons: NAVIGATION_BUTTONS.editStage,
                  hideStatusBtn: true,
                },
              },
              {
                path: `:constructorMode(${Object.values(CONSTRUCTOR_MODES).join(
                  '|'
                )})/stages`,
                component: StagesWrapper,
                children: [
                  {
                    path: '', // <domain>/tournaments-management/<ID>/construct-brackets/stages
                    name: 'multiStage',
                    component: StagesList,
                    meta: {
                      titleKey: '_web_tournament_bracket-setup-page-title-test',
                      fallbackLabel: 'Brackets Settings',
                      showAdminFunctions: true,
                    },
                  },
                  {
                    path: 'single-stage', // <domain>/tournaments-management/<ID>/construct-brackets/single-stage
                    name: 'editSingleStage',
                    component: SingleStageSettings,
                    meta: {
                      titleKey: '_web_tournament_bracket-setup-page-title-test',
                      fallbackLabel: 'Brackets Settings',
                      showAdminFunctions: true,
                    },
                  },
                  {
                    path: ':stageID',
                    component: EmptyRouteWrapper, //StagesWrapper,
                    children: [
                      {
                        path: 'edit', // <domain>/tournaments-management/<ID>/construct-brackets/stages/<ID>
                        name: 'editStage',
                        component: StageEditor,
                        meta: {
                          safeMode: false,
                          titleKey: '_web_tournaments_stages-page-title',
                          fallbackLabel: '{stage} settings',
                          showAdminFunctions: true,
                          buttons: NAVIGATION_BUTTONS.editStage,
                          hideStatusBtn: true,
                        },
                      },
                      {
                        path: 'participants/:tab?', // <domain>/tournaments-management/<ID>/<constructorMode>/stages/<ID>/participants
                        name: 'stageParticipants',
                        component: StageParticipants,
                        props: true,
                        meta: {
                          titleKey:
                            '_web_tournaments_stage_participants-edit-title',
                          fallbackLabel: 'Stage Participants',
                          showAdminFunctions: true,
                          buttons: NAVIGATION_BUTTONS.stageParticipants,
                        },
                      },
                      {
                        path: 'blocks/:blockID/brackets/:blockType',
                        component: BracketsWrapper,
                        meta: {
                          titleKey:
                            '_web_tournaments_stage_brackets-title-test',
                          fallbackLabel: 'Matches',
                        },
                        children: [...bracketsRoutes],
                      },
                    ],
                  },
                ],
              },
              //   {
              //     path: ':constructorMode(construct-brackets)',
              //     component: EmptyRouteWrapper,
              //     children: [
              //       {
              //         path: '',
              //         name: 'constructBrackets',
              //         component: ConstructBracketsWrapper,
              //         children: [
              //
              //
              //         ],
              //       },
              //
              //     ],
              //   },
              //   {
              //     path: ':constructorMode(publish-brackets|manage-tournament)',
              //     component: StagesWrapper,
              //     children: [
              //       {
              //         path: 'stages', // <domain>/tournaments-management/<ID>/<constructorMode>/stages
              //         alias: '',
              //         name: 'stagesList',
              //         component: StagesList,
              //         meta: {
              //           showAdminFunctions: true,
              //         },
              //       },
              //
              //     ],
              //   },
            ],
          },
        ],
      },
    ],
  },
];
