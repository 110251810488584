<template>
  <div class="sc-user-info d-flex flex-column align-items-center">
    <ChatHeader
      class="sc-user-info__header"
      :title="$t('_web_tournament_sidechat_user-info-title', 'Info')"
      has-back-icon
      can-edit
      @back="$emit('close')"
    />
    <img :src="account._cover" alt class="sc-user-info__cover" />
    <Avatar
      class="sc-user-info__avatar"
      type="user"
      :size="80"
      :img-url="account._avatar"
    />
    <span class="sc-user-info__username">{{ account._name }}</span>
    <p class="h-text-left sc-user-info__description">
      {{ account.description }}
    </p>
    <nav class="sc-user-info__btn-group">
      <LinkProfile :id="userId">
        {{
          $t(
            '_web_tournament_sidechat_user-info-go-to-user',
            'Go to the user profile'
          )
        }}
      </LinkProfile>
      <button class="sc-user-info__button">
        {{
          $t(
            '_web_tournament_sidechat_user-info-send-private-msg',
            'Send Private Message'
          )
        }}
      </button>
    </nav>
  </div>
</template>

<script>
import { PROFILE } from '@/constants/accounts';
import { mapState, mapActions } from 'vuex';
import ChatHeader from './ChatHeader';
import Avatar from '@/components/images/Avatar';
import LinkProfile from '@/components/links/Profile';

export default {
  components: { LinkProfile, ChatHeader, Avatar },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('current', ['account']),
  },
  created() {
    this.fetchAccount({
      accountType: PROFILE,
      accountId: this.userId,
    });
  },
  methods: {
    ...mapActions(['fetchAccount']),
  },
};
</script>

<style lang="scss">
.sc-user-info {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $ph-input-bg;
  z-index: 3;

  &__header {
    width: 100%;
  }

  &__cover {
    width: 100%;
    height: 131px;
    object-fit: cover;
    background-image: url(~@/assets/images/defaults/cover.png);
  }

  &__avatar {
    position: relative;
    margin-top: -40px;
  }

  &__username {
    display: block;
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 700;
  }

  &__description {
    width: 287px;
    margin: 1.25rem 0 1.875rem 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $ph-secondary-text;
  }

  &__btn-group {
    min-width: 190px;
  }

  &__button {
    display: block;
    width: 100%;
    margin-bottom: 0.875rem;
    padding: 0 1.5rem;
    border: 1px solid rgba($ph-secondary-text, 0.15);
    border-radius: 1rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 2rem;
    color: $ph-primary-text;
    background: rgba($ph-input-bg, 0.15);
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: $ph-primary-text;
      @include primary-background-gradient(163deg);
    }
  }
}
</style>
