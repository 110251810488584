<template>
  <div
    v-if="sponsorLogos.length"
    class="d-flex align-items-center justify-content-end flex-nowrap tournament-sponsors-list-wrapper"
    :style="{ height: `${height}px` }"
    :class="
      `align-items-${align || 'center'} justify-content-${align || 'center'}`
    "
  >
    <div
      v-for="(logo, index) in sponsorLogos"
      :key="`sponsor_preview_${index}`"
      class="d-flex align-items-center w-auto h-mr-3 tournament-sponsors-logo"
    >
      <img :src="logo" :height="`${height}px`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { prop } from '@/utils/factories';

export default {
  name: 'SponsorsList',
  props: {
    sponsors: prop(Array),
    height: prop([String, Number], 40),
    align: prop(String),
  },
  computed: {
    ...mapGetters(['currentTournamentSponsorLogos', 'currentCountry']),
    sponsorLogos() {
      return !this.$lodash.isEmpty(this.sponsors)
        ? this.sponsors
        : this.currentTournamentSponsorLogos[this.currentCountry] || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-sponsors {
  &-logo {
    height: 100%;
    text-align: center;
    &:last-child {
      @include auto-rtl(margin-right, 0 !important);
    }
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
</style>
