// todo #6481 replace it

import ClansWrapper from '@/views/clans';
import CreateClanGeneral from '@/views/clans/create/General';
import CreateClanDetails from '@/views/clans/create/Details';
import InviteMembers from '@/views/clans/create/inviteMembers';
import Clans from '@/views/clans/list';
// import store from '@/store';
import { MAIN_CLANS } from '@/constants/router/mains';

export default [
  {
    path: 'clans',
    component: ClansWrapper,
    children: [
      {
        path: '',
        name: MAIN_CLANS,
        component: Clans,
        meta: {
          // inMainNav: 65,
          // inMainNavOrg: 65,
          icon: 'sidebar/clans',
          titleKey: '_web_left-sidebar_nav-clans-link',
          fallbackLabel: 'Clans',
        },
      },
    ],
  },
  {
    path: 'clan-create',
    component: CreateClanGeneral,
    meta: {
      titleKey: '_web_create-clan-link',
      fallbackLabel: 'Create clan',
      sidebarParent: MAIN_CLANS,
    },
    // beforeEnter(to, from, next) {

    //   next();
    // },
    children: [
      {
        path: '',
        name: 'createClanDetails',
        component: CreateClanDetails,
        meta: {
          backButtonInHeader: true,
          step: 1,
          stepsTotal: 2,
        },
      },
      {
        path: 'invite',
        name: 'inviteMembers',
        component: InviteMembers,
        meta: {
          backButtonInHeader: true,
          step: 2,
          stepsTotal: 2,
        },
      },
    ],
  },
];
