<template>
  <div
    v-if="isAvailableInRegion"
    class="bannerContainer"
    :style="{ '--scrollWidth': scrollWidth }"
  >
    <div
      class="itemsContainer"
      @click="isAuthorized ? redirectToPhMax() : loginRedidect()"
    >
      <img :src="bannerImagePath" alt="" class="ph-max-banner-image" />
      <div
        :class="isRTL ? 'ph-max-mobile-banner-ar' : 'ph-max-mobile-banner-en'"
      >
        <span>{{ $t('_web_max_title', 'PLAYHERA Max') }}</span>
        <button>{{ $t('_web_subscription_subscribe', 'SUBSCRIBE') }}</button>
      </div>
    </div>
    <Spinner v-if="isLoading && isAuthorized" modal class="spinner-middle" />
  </div>
</template>

<script>
import { API } from '@/api/config';
import { mapState, mapGetters, mapActions } from 'vuex';
import { SSO_CLIENT_ID } from '@/api/config';
import Spinner from '@/views/main/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      scrollWidth: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['appId', 'isAuthorized', 'isRTL', 'currentRegion']),
    isAvailableInRegion() {
      const region = JSON.parse(localStorage.getItem('region'));
      if (region.id == 'mena') {
        return true;
      } else {
        return false;
      }
    },
    bannerImagePath() {
      if (this.isRTL) {
        return require('@/assets/images/homepage/ph-max-banner-ar.jpg');
      } else {
        return require('@/assets/images/homepage/BannerLEAP.jpg');
      }
    },
  },
  mounted() {
    this.isAuthorized && this.fetchSubscriptions();
    this.getScrollBarWidth();
  },
  methods: {
    ...mapActions(['ssoSwitch']),

    loginRedidect() {
      this.$router.push({ name: 'subscriptionSetup' });
    },

    redirectToPhMax() {
      if (this.account && this.account._type !== 'profile') {
        return;
      } else {
        if (this.subscriptions[0].status !== 'inactive') {
          this.isLoading = true;
          this.ssoSwitch({
            accountId: SSO_CLIENT_ID,
            accountType: 'thirdparty',
          })
            .then(resp => {
              window.location.href = resp.redirect;
            })
            .catch(err => {
              this.errorNotify(err);
            });
        } else {
          this.$router.push({
            name: 'subscriptionSetup',
            path: '/subscription',
          });
        }
      }
    },
    async fetchSubscriptions() {
      try {
        const response = await API('/payments/mySubscriptions/');
        const data = response.data;
        this.subscriptions = data.subscriptions.filter(sub => {
          return sub.product === 'phmax';
        });
      } catch (error) {
        console.log(error);
      }
    },
    getScrollBarWidth() {
      let el = document.createElement('div');
      el.style.cssText =
        'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      el.remove();
      return (this.scrollWidth = width.toString() + 'px');
    },
  },
};
</script>
<style lang="scss" scoped>
@import './variables';
.bannerContainer {
  width: 100%;
  max-width: calc(100vw - var(--scrollWidth));
  // height: $banner_height;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  box-shadow: inset 0px 35px 30px -10px $new-bg-page,
    inset 0px -35px 30px -10px $new-bg-page;
}

.itemsContainer {
  display: flex;
  width: $hr-wrapper-width;
  max-width: $hr-wrapper-max-width;
  justify-content: space-between;
  align-items: center;
}

.ph-max-banner-image {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.ph-max-banner-image-mobile {
  display: none;
}

.spinner-middle {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.ph-max-mobile-banner-en {
  display: none;
}

.ph-max-mobile-banner-ar {
  display: none;
}

@media screen and (max-width: 1483px) {
  .itemsContainer {
    width: 92vw;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .itemsContainer::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 1251px) {
  .bannerContainer {
    height: auto;
    -webkit-box-shadow: inset 0 0 70px 60px $new-bg-page;
    box-shadow: inset 0 0 70px 60px $new-bg-page;
  }

  .itemsContainer {
    flex-direction: column;
    // padding-top: 6rem;
    // padding-bottom: 6rem;
  }
}

@media screen and (max-width: 991px) {
  .itemsContainer {
    width: calc(100vw - var(--scrollWidth));
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .image-wrapper::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 750px) {
  .itemsContainer {
    padding-left: 0;
    padding-right: 0;
  }
  .ph-max-banner-image {
    display: none;
  }

  .ph-max-banner-image-mobile {
    display: block;
    width: 92vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .ph-max-mobile-banner-en {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 92vw;
    height: 100%;
    // min-height: 300px;
    min-width: 300px;
    max-height: 400px;
    aspect-ratio: 4 / 3;
    border-radius: 5px;
    background: linear-gradient(90deg, #6300fa, rgba(#ffffff, 0.1)),
      url('~@/assets/images/homepage/ph-max-mobile-bg-en.jpg');
    background-size: cover;
    padding: 2.5rem;
    cursor: pointer;

    span {
      font-size: 40px;
      font-weight: bold;
      max-width: 240px;
      margin-bottom: 1.5rem;
      line-height: 2.8rem;
    }

    button {
      border: none;
      background: white;
      color: #5a58f2;
      padding: 0.8rem;
      max-width: 130px;
      font-size: 20px;
      border-radius: 10px;
      font-weight: 600;
    }
  }

  .ph-max-mobile-banner-ar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 92vw;
    height: 100%;
    // min-height: 300px;
    min-width: 300px;
    max-height: 400px;
    aspect-ratio: 4 / 3;
    border-radius: 5px;
    background: linear-gradient(270deg, #6300fa, rgba(#ffffff, 0.1)),
      url('~@/assets/images/homepage/ph-max-mobile-bg-ar.jpg');
    background-size: cover;
    padding: 2.5rem;
    cursor: pointer;

    span {
      font-size: 44px;
      font-weight: bold;
      max-width: 240px;
      margin-bottom: 1.5rem;
      line-height: 2.8rem;
    }

    button {
      border: none;
      background: white;
      color: #5a58f2;
      padding: 0.8rem;
      max-width: 130px;
      font-size: 20px;
      border-radius: 10px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
</style>
