import store from '@/store';
import { beforeEnterCheckAuth } from '../guards.js';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';

import StatusBar from '@/views/account/header/statusBar/default/'; // do not dynamic it
// const StatusBarEdit = () => import('@/views/account/header/statusBar/edit/');

import Cover from '@/views/account/header/cover/';
const CoverEdit = () => import('@/views/account/header/cover/Edit');

const Info = () => import('@/views/account/content/info');
const Edit = () => import('@/views/account/content/edit');
const Feeds = () => import('@/views/account/content/feeds');
const Competitions = () => import('@/views/account/content/competitions');
const Battles = () => import('@/views/account/content/battles');
const Roster = () => import('@/views/account/content/roster');
const Squad = () => import('@/views/account/content/squad');
const myTeam = () => import('@/views/account/content/tournamentTeamManagement');
const ResultsWrapper = () => import('@/views/account/content/results');
const ResultsList = () => import('@/views/account/content/results/list');
const ProvideBattleResults = () =>
  import('@/views/account/content/results/ProvideBattleResults');
const Deleted = () => import('@/views/account/content/deleted');

const Settings = () => import('@/views/account/content/settings');
const InviteAdmin = () =>
  import('@/views/account/content/settings/InviteAdmin');
const InviteClanMembers = () => import('@/views/clans/create/inviteMembers');
// const Teams = () => import('@/views/account/content/teams');
const Contacts = () => import('@/views/account/lists');
const Participants = () => import('@/views/account/content/participants');
const Standings = () => import('@/views/tournaments/management/standings');
// const CreateClanTeam = () =>
//   import('@/views/account/content/teams/clanTeams/createClanTeam');

const defaultHeader = {
  cover: Cover,
  statusBar: StatusBar,
};

export const subRoutesFactory = (prefix = '') => [
  // default route
  {
    path: '',
    components: {
      main: Feeds,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
    children: [
      // default path === 'info'
      {
        path: '',
        name: `${prefix}${ROUTER_NAMES_ACCOUNT.ACCOUNT}`,
        meta: {
          isScrollHandlingDisabled: false,
        },
      },
      {
        path: 'feeds',
        name: `${prefix}${ROUTER_NAMES_ACCOUNT.FEEDS}`,
        meta: {
          isScrollHandlingDisabled: true,
        },
      },
    ],
  },
  {
    path: 'contacts/:tab',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.CONTACTS}`,
    components: {
      main: Contacts,
      ...defaultHeader,
    },
    meta: {
      navbarIsHidden: true,
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'edit',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.EDIT}`,
    components: {
      main: Edit,
      cover: CoverEdit,
    },
    meta: {
      backButtonInHeader: true,
      navbarIsHidden: true,
      isScrollHandlingDisabled: false,
    },
  },
  {
    path: 'info',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.INFO}`,
    components: {
      main: Info,
      ...defaultHeader,
    },
    meta: {
      getCustomBreadcrumbLabel: () => store.getters.currentTournamentInfo.title,
    },
  },
  {
    path: 'competitions',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.COMPETITIONS}`,
    components: {
      main: Competitions,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'battles',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.BATTLES}`,
    components: {
      main: Battles,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'settings',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.SETTINGS}`,
    components: {
      main: Settings,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'invites',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.INVITES}`,
    components: {
      main: InviteAdmin,
      ...defaultHeader,
    },
    meta: {
      navbarIsHidden: true,
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'invite-members',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.CLAN_MEMBERS}`,
    components: {
      main: InviteClanMembers,
      ...defaultHeader,
    },
    meta: {
      navbarIsHidden: true,
      isScrollHandlingDisabled: true,
    },
  },
  // {
  //   path: 'teams',
  //   name: `${prefix}${ROUTER_NAMES_ACCOUNT.TEAMS}`,
  //   components: {
  //     main: Teams,
  //     ...defaultHeader,
  //   },
  //   meta: {
  //     isScrollHandlingDisabled: true,
  //   },
  // },
  {
    path: 'participants',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.PARTICIPANTS}`,
    components: {
      main: Participants,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'standings/:stageID?',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.STANDINGS}`,
    beforeEnter: beforeEnterCheckAuth,
    components: {
      main: Standings,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'my-team',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.MY_TEAM}`,
    components: {
      main: myTeam,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'roster',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.ROSTER}`,
    components: {
      main: Roster,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'squad',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.SQUAD}`,
    components: {
      main: Squad,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
  },
  {
    path: 'results',
    components: {
      main: ResultsWrapper,
      ...defaultHeader,
    },
    meta: {
      isScrollHandlingDisabled: true,
    },
    children: [
      {
        path: '',
        name: `${prefix}${ROUTER_NAMES_ACCOUNT.RESULTS}`,
        component: ResultsList,
        meta: {
          isScrollHandlingDisabled: true,
        },
      },
      {
        path: ':round',
        name: `${prefix}${ROUTER_NAMES_ACCOUNT.RESULTS_EDIT}`,
        component: ProvideBattleResults,
        meta: {
          navbarIsHidden: true,
          isScrollHandlingDisabled: true,
        },
      },
    ],
  },
  {
    path: 'deleted',
    name: `${prefix}${ROUTER_NAMES_ACCOUNT.DELETED}`,
    components: {
      main: Deleted,
      ...defaultHeader,
    },
    meta: {
      navbarIsHidden: true,
      statusbarControlsIsHidden: true,
      isScrollHandlingDisabled: true,
    },
  },
  // {
  //   path: 'create-team',
  //   name: `${prefix}${ROUTER_NAMES_ACCOUNT.CREATE_TEAM}`,
  //   components: {
  //     main: CreateClanTeam,
  //     ...defaultHeader,
  //   },
  //   meta: {
  //     navbarIsHidden: true,
  //     isScrollHandlingDisabled: true,
  //   },
  // },
  // {
  //   path: ':teamID/edit-team',
  //   name: `${prefix}${ROUTER_NAMES_ACCOUNT.EDIT_TEAM_MEMBERS}`,
  //   components: {
  //     main: CreateClanTeam,
  //     ...defaultHeader,
  //   },
  //   meta: {
  //     navbarIsHidden: true,
  //     isScrollHandlingDisabled: true,
  //   },
  // },
];
