var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',_vm._b({staticClass:"player-wrapper d-flex align-items-center"},'Panel',{
    variant: ("" + _vm.variant),
    class: [
      ("player-wrapper--" + _vm.variant),
      ("player_" + _vm.playerIndex),
      {
        selected: _vm.swapIndex === _vm.playerIndex,
      } ],
    key: ("player-item-panel-" + _vm.playerID),
    'data-player': _vm.playerIdAttr,
  },false),[_c('div',{staticClass:"d-flex align-items-center player-iswinner",class:{ 'flex-column player-wrapper--player-stacked': _vm.isStacked }},[(_vm.player)?_c('AccountLink',{attrs:{"id":_vm.playerID,"type":_vm.playerType,"vanity-id":_vm.playerVanityId}},[_c('Avatar',{attrs:{"size":_vm.avatarSize,"type":_vm.playerType,"img-url":_vm.playerAvatar,"avatar-type":_vm.avatarType,"background-fill":_vm.$scss.colors.darkBg}})],1):(_vm.canDeletePlayer && _vm.$route.meta.showAdminFunctions)?_c('icon',{key:"add",staticClass:"player-add-trigger flex-shrink-0",class:{
        disabled: _vm.isSwapSource || _vm.isSwapTarget,
      },attrs:{"name":"add","original":"","width":_vm.avatarSize,"fill":_vm.$scss.colors.greenBg},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onAddPlayer($event)}}}):_c('Avatar',{attrs:{"size":_vm.avatarSize,"background-fill":_vm.$scss.colors.darkBg}}),_c('div',{staticClass:"spacer pl-3"}),_c('div',{staticClass:"d-flex flex-column justify-content-center team-name w-100"},[(_vm.player)?_c('AccountLink',{staticClass:"d-block text-decoration-none team-name-a",attrs:{"id":_vm.player.login || _vm.player.id,"type":_vm.playerType,"vanity-id":_vm.playerVanityId}},[_c('TextBlock',{staticClass:"text-truncate secondary-font winner-player-name",attrs:{"tag":"div","variant":"primary","size":"0.75","line-height":1.375}},[_vm._v(_vm._s(_vm.playerName))])],1):_c('TextBlock',{staticClass:"text-truncate",class:{
          disabled: (_vm.isSwapSource || _vm.isSwapTarget) && _vm.canDeletePlayer,
        },attrs:{"tag":"div","variant":_vm.canDeletePlayer ? 'success' : 'primary',"line-height":1.375},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onAddPlayer($event)}}},[_vm._v(_vm._s(_vm.playerName))]),_vm._t("default")],2)],1),(!_vm.hideCheckin || _vm.canSwap || !_vm.hideScore)?_c('div',{staticClass:"filler mx-auto"}):_vm._e(),(!_vm.hideCheckin && _vm.checkedIn && _vm.$route.meta.showAdminFunctions)?[_c('div',{staticClass:"spacer pl-3"}),_c('InfoTooltip',{key:"check-mark",staticClass:"mx-1 flex-shrink-0",attrs:{"text":_vm.$t(
          '_web_participant-checked-in-tooltip',
          'Participant was checked-in'
        ),"icon-props":{
        name: 'check-mark',
        width: 20,
        fill: _vm.$scss.colors.greenBg,
      }}}),_c('div',{staticClass:"spacer pl-3"})]:_vm._e(),(_vm.canSwap || _vm.canDeletePlayer || !_vm.hideScore)?[(_vm.playerID && _vm.isPublishRoute && _vm.$route.meta.showAdminFunctions)?_c('icon',{key:"cancel",staticClass:"player-delete-trigger mx-1 flex-shrink-0",class:{
        disabled: _vm.isSwapSource || _vm.isSwapTarget || !_vm.canDeletePlayer,
      },attrs:{"name":"cancel","width":24,"fill":_vm.$scss.colors.errorBg},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onDeletePlayer($event)}}}):_vm._e(),_c('div',{staticClass:"spacer pl-3"}),(_vm.canSwap && _vm.$route.meta.showAdminFunctions)?_c('icon',{key:_vm.swapIconName,staticClass:"swap-trigger mx-1 flex-shrink-0",class:{
        disabled: !_vm.isSwapTarget && !_vm.isExactSwapSource && _vm.isSwapSource,
      },attrs:{"name":_vm.swapIconName,"width":_vm.avatarSize,"fill":_vm.swapIconFill},on:{"click":function($event){$event.stopPropagation();return _vm.onSwap($event)}}}):(!_vm.hideScore)?_c('SingleScore',{staticClass:"result-score mx-1 flex-shrink-0 secondary-font",class:{ 'h-mr-auto': _vm.isStacked },attrs:{"value":_vm.playerScore}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }