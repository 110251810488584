import Shop from '@/views/shop/components/Shop.vue';
import SingleProduct from '@/views/shop/components/SingleProduct.vue';
import ShopLand from '@/views/shop/index.vue';
import ShopSuccess from '@/views/shop/components/ShopSuccess.vue';

export default [
  {
    path: '/shop/success',
    name: 'ShopSuccess',
    component: ShopSuccess,
    meta: {
      guestEnabled: true,
      inMainNav: false,
      inMainNavOrg: false,
      fallbackLabel: 'Search',
      icon: 'search',
    },
  },
  {
    path: '/shop/categories',
    name: 'ShopLand',
    component: ShopLand,
    meta: {
      guestEnabled: true,
      inMainNav: false,
      inMainNavOrg: false,
      fallbackLabel: 'Search',
      icon: 'search',
    },
  },
  {
    path: '/shop/:productId',
    name: 'singleProduct',
    component: SingleProduct,
    meta: {
      guestEnabled: true,
      inMainNav: false,
      inMainNavOrg: false,
      fallbackLabel: 'Search',
      icon: 'search',
    },
  },
  {
    path: '/shop/',
    name: 'Shop',
    component: Shop,
    meta: {
      guestEnabled: true,
      inMainNav: false,
      inMainNavOrg: false,
      fallbackLabel: 'Search',
      icon: 'search',
    },
  },
];
