<template>
  <div>
    <div class="create-post-text">
      {{
        $t(
          '_web_crete_post-Create-post-title',
          'Upload your game highlights, share moments or start conversation about competitions.'
        )
      }}
    </div>
    <button class="create-post-button" @click="handleModalOpen(true)">
      <div class="plus-sign">+</div>
      {{ $t('_web_crete_post-Create-post', 'Create post') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'CreatePostFeed',
  components: {},
  computed: {
    ...mapGetters('feed', ['feedType', 'createPostModal']),
    ...mapGetters('my/clans', ['hasClans']),
    ...mapState('my', ['account']),
  },
  methods: {
    ...mapActions('feed', ['setCreatePostModal']),
    handleModalOpen(value) {
      this.setCreatePostModal(value);
    },
  },
};
</script>

<style lang="scss">
.create-post-text {
  display: flex;
  color: #c1c7d7;
  max-width: 320px;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}
.create-post-button {
  display: flex;
  background-color: #11171f;
  border: 1px solid #404a63;
  border-radius: 70px;
  width: 165px;
  height: 56px;
  justify-content: space-evenly;
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  margin-top: 19px;
}
.plus-sign {
  background-color: #5a58f2;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-weight: 400;
}
</style>
