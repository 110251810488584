<template>
  <div class="d-flex flex-column h-100 position-relative">
    <portal to="header-left-portal">
      <GoBackBtn
        history-mode
        hide-label
        class="pl-0 pr-0 mr-5"
        style="min-width: 16px"
      />
    </portal>
    <div
      v-if="currentTournament && currentTournament.id"
      class="d-flex align-items-center mb-5"
    >
      <Title :text="currentTournament.info.title" class="mb-0" />
      <StatusLabel :status="tournamentStatus" class="h-ml-5 h-mr-5" />
      <ToolIcon
        icon-name="settings-outline"
        :help-text="
          $t('_web_tournament_management_go_to_settings', 'Go to settings')
        "
        class="ml-auto flex-shrink-0"
        @click="goToSettings"
      />
      <ToolIcon
        icon-name="dots"
        class="h-ml-4 flex-shrink-0"
        @click="openHiddenActionsMenu"
      />
    </div>
    <div
      v-if="currentTournament && currentTournament.id"
      class="row no-gutters align-items-center sticky-top mb-5"
    >
      <div class="col">
        <Menu
          :disabled="canDraft || !currentTournament || !currentTournament.id"
          class="tournament-management-menu"
          :style="{ '--bg-color': $scss.colors.darkBg }"
        />
      </div>
    </div>
    <div class="flex-grow-1 row flex-column align-items-center">
      <router-view class="flex-grow-1 col-12"></router-view>
    </div>
    <div v-if="!isPlayersInvitesRoute" class="row">
      <NavigationButtons
        class="col create-tournament-btn"
        v-bind="{ isWaiting: isLoading }"
      />
    </div>
  </div>
</template>

<script>
// utils
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { defaultRepostSettings } from '@/store/modules/tournaments/admin';
import {
  CONSTRUCTOR_MODES,
  TOURNAMENT_STATUSES,
  REJECTABLE_STATUSES,
} from '@/constants/tournaments';
import { newStageID } from '@/store/modules/tournaments/adminStages';

// mixins
import tournamentsHelper from '@/mixins/tournaments/tournamentHelpers';
import isLoadingMixin from '@/mixins/isLoading';
import routeHelpers from '@/mixins/routeHelpers';
import checkInMixin from './mixins/checkin';

// components
import Title from '@/components/atoms/Title';
import GoBackBtn from '@/components/common/GoBackBtn.vue';
import NavigationButtons from './components/NavigationButtons';
import Menu from './components/Menu';
import StatusLabel from '@/components/molecules/StatusLabel';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';

export default {
  name: 'SimpleTournamentForm',
  inject: ['$validator'],
  components: {
    Menu,
    NavigationButtons,
    Title,
    GoBackBtn,
    StatusLabel,
    ToolIcon,
  },
  mixins: [...tournamentsHelper, isLoadingMixin, routeHelpers, checkInMixin],
  computed: {
    isPlayersInvitesRoute() {
      return this.$route.path.includes('/players/invites' || '/players/');
    },
    ...mapGetters([
      'canDraft',
      'canReopen',
      'canPause',
      'canArchive',
      'currentTournament',
      'currentTournamentStages',
      'isTournamentChanged',
      'isCurrentStageChanged',
      'currentTournamentBackup',
      'currentTournamentStage',
      'stagesList',
    ]),
    tournamentStatus() {
      return this.$route.params.tournamentID
        ? this.$lodash.get(this.currentTournament, 'status', '')
        : '';
    },
    title() {
      const titleKey = this.getMetaFromParents('titleKey');
      const fallbackLabel = this.getMetaFromParents('fallbackLabel');
      return this.$t(titleKey, fallbackLabel);
    },
    stageID() {
      if (this.$route.params.stageID) {
        return this.$route.params.stageID;
      } else if (this.$route.name === 'addStage') {
        return null;
      } else {
        return this.stagesList[0];
      }
    },
    hiddenActions() {
      return [
        {
          label: this.$t('_web_tournaments_menu-reject', 'Unpublish'),
          action: this.generateHandler(
            this.onTournamentReject,
            TOURNAMENT_STATUSES.REJECTED,
            this.$t(
              '_web_tournaments_menu-reject_confirm',
              'Do you really want to cancel a tournament?'
            )
          ),
          disabled: !REJECTABLE_STATUSES.includes(this.tournamentStatus),
        },
        {
          label: this.$t(
            '_web_tournament_match-menu-item-reopen-tournament',
            'Reopen tournament'
          ),
          action: this.confirmTournamentReopenModal,
          disabled: !this.canReopen,
        },
        {
          label: this.$t('_web_tournaments_menu-archive', 'Archive'),
          action: this.generateHandler(
            this.onTournamentArchive,
            TOURNAMENT_STATUSES.ARCHIVED,
            this.$t(
              '_web_tournaments_menu-archive_confirm',
              'Do you really want to archive a tournament?'
            )
          ),
          disabled: !this.canArchive,
        },
      ];
    },
  },
  watch: {
    'currentTournament.id': function(newID, oldID) {
      this.$route.params.tournamentID = newID;

      if (newID && !oldID) {
        this.backupCurrentTournament();
      }
    },
    '$route.name'(name) {
      if (
        ![
          'editSingleStage',
          'BlockBracketsGrid',
          'BlockBracketsTable',
          'BlockBracketsTableEditResults',
          'editStage',
          'addStage',
        ].includes(name) &&
        this.currentTournamentStage.id
      ) {
        this.clearCurrentTournamentStage();
      } else {
        this.setCurrentTournamentStage(this.stageID);
      }
    },
  },
  created() {
    this.setLoading();
    this.setTournamentReposts(defaultRepostSettings());
    if (this.currentTournament.id) {
      this.backupCurrentTournament();
    }
    if (this.tournamentID) {
      Promise.all([
        this.fetchTournamentDraft(this.tournamentID),
        this.fetchTournamentStages(this.tournamentID),
      ])
        .then(() => {
          return this.stagesList.map(id => {
            return this.fetchTournamentBlocks({
              stage: id,
            }).then(blocks => {
              this.setTournamentBlocks(blocks);
            });
          });
        })
        .then(blocksPromises =>
          Promise.all(blocksPromises).catch(this.errorNotify)
        )
        .then(() => {
          if (
            this.stageID &&
            (this.currentTournamentStage &&
              this.currentTournamentStage.id !== this.stageID)
          ) {
            this.setCurrentTournamentStage(this.stageID);
          }
          if (!this.isTournamentBackuped) {
            this.backupCurrentTournament();
          }
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    } else {
      this.unsetLoading();
    }
  },
  beforeRouteUpdate(to, from, next) {
    const tournamentDataUpdated = this.shouldSaveTournamentInfo(from.name);
    const stageDataUpdated = this.shouldSaveStageInfo(from.name);
    // const needShowAnnounceModal =
    //   to.name === 'constructBrackets' &&
    //   to.params.tournamentID &&
    //   from.name === 'TournamentSetup' &&
    //   !from.params.tournamentID;
    let needShowDiscardModal = true;
    const promises = [];
    if (to.params.discardChanges) {
      this.discardAllChanges();
      return next();
    }
    if (tournamentDataUpdated) {
      this.setLoading();
      promises.push(
        this.validateCurrentForm()
          .catch(error => {
            needShowDiscardModal = false;
            throw error;
          })
          .then(this.onUpdateTournament)
        // .then(() => {
        //   if (needShowAnnounceModal && this.currentTournament.id) {
        //     this.$nextTick(() => {
        //       this.showAnnounceModal(this.onAnnounceTournament.bind(this));
        //     });
        //   }
        // })
      );
    } else {
      const tournamentID = this.currentTournament.id || to.params.tournamentID;
      this.fetchTournamentPlayersInfo(tournamentID).catch(() => {
        this.fetchTournamentDraft(tournamentID);
      });
    }
    if (stageDataUpdated) {
      this.setLoading();
      promises.push(
        this.validateCurrentForm()
          .catch(error => {
            needShowDiscardModal = false;
            throw error;
          })
          .then(() => this.onUpdateStage(this.stageID))
      );
    }
    if (promises.length) {
      this.setTournamentUpdateStopover(
        Promise.all(promises)
          .then(() => {
            const props =
              !from.params.tournamentID && this.currentTournament.id
                ? {
                    name: to.name,
                    params: {
                      tournamentID: this.currentTournament.id,
                      constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
                    },
                  }
                : undefined;
            next(props);
          })
          .catch(error => {
            if (needShowDiscardModal) {
              this.showDiscardModal({
                confirm: () => {
                  this.discardAllChanges();
                  next();
                },
                customText: error.message,
              });
            } else {
              this.errorNotify(error);
            }
          })
          .finally(() => {
            this.clearTournamentUpdateStopover();
            this.unsetLoading();
          })
      );
      return;
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    const tournamentID = to.params.tournamentID;
    const tournamentDataUpdated = this.shouldSaveTournamentInfo(from.name);
    const stageDataUpdated = this.shouldSaveStageInfo(from.name);
    const clearAndGoNext = () => {
      this.deleteTournamentStageByID(newStageID);
      this.clearCurrentTournamentStage();
      this.clearTournamentInfo(tournamentID);
      if (!to.params.tournamentID) {
        this.clearAllStages();
        this.clearAllBlocks();
      }
      next();
    };
    if (!tournamentDataUpdated && !stageDataUpdated) {
      clearAndGoNext();
      return;
    }
    this.showSaveModal(() => {
      const promises = [];
      if (tournamentDataUpdated) {
        this.setLoading();
        promises.push(
          this.validateCurrentForm()
            .then(this.onUpdateTournament)
            .then(clearAndGoNext)
            .catch(this.errorNotify)
        );
      }
      if (stageDataUpdated) {
        this.setLoading();
        promises.push(this.onUpdateStage(this.stageID));
      }
      this.setTournamentUpdateStopover(
        Promise.all(promises).finally(() => {
          this.clearTournamentUpdateStopover();
          this.unsetLoading();
        })
      );
      return Promise.all(promises);
    }, clearAndGoNext);
  },
  methods: {
    ...mapActions([
      'fetchTournamentDraft',
      'fetchTournamentPlayersInfo',
      'fetchTournamentStages',
      'fetchTournamentBlocks',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    ...mapActions({
      openGlobalMenu: 'popoverMenu/open',
    }),
    ...mapMutations([
      'setTournamentBlocks',
      'clearAllStages',
      'clearAllBlocks',
      'backupCurrentTournament',
      'clearCurrentTournament',
      'clearCurrentTournamentBackup',
      'applyCurrentTournamentBackup',

      'setCurrentTournamentStage',
      'clearCurrentTournamentStage',
      'deleteTournamentStageByID',

      'setTournamentUpdateStopover',
      'clearTournamentUpdateStopover',
      'setTournamentReposts',
    ]),
    openHiddenActionsMenu(event) {
      this.openGlobalMenu({
        event,
        settings: {
          stopItemClickBubbling: true,
          isFixed: false,
          autoClose: true,
          targetSelector: 'svg',
        },
        items: this.hiddenActions,
      });
    },
    generateHandler(func, newStatus, confirmText) {
      return () =>
        this.openModal({
          name: 'HeraConfirm',
          props: {
            text: confirmText,
          },
          events: {
            cancel: this.closeModal,
            confirm: () => {
              func(this.tournamentID)
                .then(() => this.fetchTournamentDraft(this.tournamentID))
                .catch(this.errorNotify)
                .finally(() => {
                  if (newStatus === 'rejected') {
                    window.dataLayer.push({
                      event: 'tournament cancelled',
                      userID: this.account.name,
                      from: this.$route.path,
                      tournamentId: this.currentTournament.id,
                      gameName: this.currentTournament.game,
                      squadSize: this.currentTournament.squad_size,
                      tournamentName: this.currentTournament.title,
                      tournamentFormat: this.currentTournamentStages[0]
                        .block_configuration.type,
                      tournamentCountry: this.currentTournament.country,
                      tournamentPool: this.currentTournament.prize.prize_pool,
                      regForm: this.currentTournament
                        .requires_registration_form,
                      tournamentPrivate:
                        this.currentTournament.registration_type === 'closed'
                          ? 'true'
                          : 'false',
                      tournamentOrganizer: this.currentTournament
                        .event_organizer_name,
                    });
                  }
                });
              this.closeModal();
            },
          },
        });
    },
    goToSettings() {
      this.$router.push({
        name: 'TournamentSettings',
        params: this.$route.params,
      });
    },
    shouldSaveTournamentInfo(routeName) {
      //TODO think about use config in store or tmth else
      return (
        ['TournamentSetup', 'TournamentSettings'].includes(routeName) &&
        (this.isTournamentChanged || this.canDraft)
      );
    },
    shouldSaveStageInfo(routeName) {
      return (
        ['editSingleStage', 'editStage', 'addStage'].includes(routeName) &&
        this.isCurrentStageChanged
      );
    },
    discardAllChanges() {
      this.clearTournamentInfo(this.currentTournament.id);
      if (this.currentTournamentStage.id) {
        this.setCurrentTournamentStage(this.stageID);
      }
      // if (newStageID === this.currentTournamentStage.id) {
      this.clearCurrentTournamentStage();
      this.deleteTournamentStageByID(newStageID);
      // }
    },
    clearTournamentInfo(tournamentID) {
      if (tournamentID && !this.$lodash.isEmpty(this.currentTournamentBackup)) {
        this.applyCurrentTournamentBackup();
      } else if (!tournamentID) {
        this.clearCurrentTournament();
        this.clearCurrentTournamentBackup();
      }
      this.$validator.reset();
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss">
.create-tournament-item-wrapper {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #393949;
  border-radius: 3px;
}

.tournament-summary-wrapper {
  padding: 20px;
  background-color: #393949;
  border-radius: 3px;
}

.fixed {
  position: fixed;
  z-index: 99;
  top: 100px;
}

.sticky {
  padding-top: 0.75rem;
  position: sticky;
  z-index: 90;
  top: $header-height;
}
.wizard-actions-buttons {
  position: absolute;
  bottom: 0;
  background-color: $ph-input-bg;
}
.tournament-management-menu {
  .stepper-wrapper {
    padding-bottom: 0 !important;
  }
}

.create-tournament-btn {
  margin: 0;
  margin-bottom: 2rem;
  padding-bottom: $ph-large-space;
  position: fixed;
  bottom: 0;
  /* background: linear-gradient(180deg, rgba(11, 14, 19, 0) 0%, #0b0e13 100%); */
  width: 80% !important;
}
</style>
