var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"chatWrapper",staticClass:"position-relative"},[_c('router-link',{staticClass:"card chat-list-item",class:{
      active: _vm.isActive,
      'chat-inactive': !_vm.data.active || _vm.isUserBlocked,
    },attrs:{"tag":"li","to":{ name: 'conversations', params: { id: _vm.data.id } }},nativeOn:{"!click":function($event){return _vm.clickHandler($event)}}},[_c('a',{staticClass:"card-body media m-0 text-light",attrs:{"title":_vm.data.title}},[_c('div',{staticClass:"align-self-center h-mr-3"},[_c('Avatar',{attrs:{"size":48,"img-url":_vm.data.avatar,"type":_vm.avatarType}})],1),(+_vm.data.unread_count)?_c('HeraBadge',{staticClass:"font-size-xs chats-list-badge"},[_vm._v("\n        "+_vm._s(_vm.data.unread_count)+"\n      ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column chats-list-body"},[_c('div',{staticClass:"d-flex justify-content-between chat-list-msg-header"},[_c('div',{staticClass:"w-100 text-light font-size-base text-truncate chats-list-title"},[(_vm.conversationIcon)?_c('AppIcon',{attrs:{"name":_vm.conversationIcon,"width":"12"}}):_vm._e(),_vm._v("\n            "+_vm._s(((_vm.data.title) + " " + (!_vm.data.active || _vm.isUserBlocked
                  ? _vm.$t('_web_conversation_blocked', 'Blocked')
                  : '')))+"\n          ")],1),_c('div',{staticClass:"flex-shrink-0 text-muted ",class:_vm.data.active &&
              _vm.data.correlation_type === 'profile' &&
              !_vm.isUserBlocked
                ? 'text-ajdustment-conv'
                : ''},[_vm._v("\n            "+_vm._s(_vm._f("localDate")(_vm.data.last_activity,_vm.$times.DATE_SUBMED))+"\n          ")])]),_c('div',{staticClass:"d-flex justify-content-left text-muted font-size-sm chats-list-msg-preview"},[(_vm.data.last_message)?_c('span',[_vm._v("\n            "+_vm._s(_vm._f("removeTagsMention")(_vm.data.last_message))+"\n          ")]):_vm._e()])])],1)]),(
      _vm.data.active && _vm.data.correlation_type === 'profile' && !_vm.isUserBlocked
    )?_c('div',{class:_vm.miniMode ? 'icon-wrapper' : 'icon-wrapper-reg'},[_c('Icon',{staticClass:"cursor-pointer",attrs:{"role":"button","fill":_vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor,"name":"dots"},on:{"click":function($event){$event.stopPropagation();return _vm.openPopoverMenu($event)}}})],1):_vm._e(),_c('PopoverMenu',{attrs:{"closest":_vm.$refs.chatWrapper,"position":_vm.optionsPosition,"items":_vm.popoverItems,"float":['top', 'right'],"auto-close":true},on:{"toggle":_vm.onMenuToggle},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('TextBlock',{staticClass:"py-2",attrs:{"variant":item.disabled ? 'muted' : 'primary',"text":item.label},nativeOn:{"click":function($event){return _vm.menuHandler(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }