<template>
  <div
    class="mobile-footer-wrapper .mobile-wrapper-footer"
    :class="
      omantelLogo || (account && account.country === 'Oman')
        ? 'omantel'
        : 'mobile-wrapper-footer'
    "
  >
    <router-link class="footer-item" to="/">
      <img src="@/assets/images/new-icons/Vector FORYOU.svg" alt="" />
      <p>{{ $t('_web_for_you_page', 'For You') }}</p>
    </router-link>

    <router-link class="footer-item" to="/feed">
      <img src="@/assets/images/new-icons/VectorFeed.svg" alt="" />
      <p>{{ $t('_web_feed_btn', 'Feed') }}</p>
    </router-link>

    <div
      v-if="isAvailableInRegion && account._type === 'profile'"
      class="footer-item"
    >
      <a
        v-if="
          isAuthorized &&
            currentRegion &&
            currentRegion.feature_flags.phmax_url !== null &&
            account &&
            account._type === 'profile'
        "
        class="footer-item"
        @click="redirectToPhMax"
      >
        <img
          class="ph-max-logo-mobile"
          src="@/assets/images/new-icons/VectorPHMAX.svg"
          alt="ph max logo"
        />
        <!-- <img
          class="beta-logo-mobile"
          src="@/assets/images/header/LEAP2024-beta.svg"
          alt="ph max logo"
        /> -->
        <p class="ph-max-btn-text-mobile-footer">
          {{ $t('_web_max_btn', 'Max') }}
        </p>
      </a>

      <router-link
        v-else-if="
          (currentRegion &&
            currentRegion.feature_flags.phmax_url &&
            account &&
            account._type === 'profile') ||
            (!account && currentRegion && currentRegion.feature_flags.phmax_url)
        "
        class="footer-item"
        :to="`/signin?redirectTo=${$route.fullPath}`"
        active-class="active"
      >
        <img
          class="ph-max-logo-mobile"
          src="@/assets/images/new-icons/VectorPHMAX.svg"
          alt="ph-max-logo"
        />
        <!-- <img
          class="beta-logo-mobile"
          src="@/assets/images/header/LEAP2024-beta.svg"
          alt="ph max logo"
        /> -->
        <p class="ph-max-btn-text-mobile-footer">
          {{ $t('_web_max_btn', 'Max') }}
        </p>
      </router-link>

      <Spinner v-if="isLoading && isAuthorized" modal class="spinner-middle" />
    </div>

    <!-- <a
      v-if="heraShopLink && account._type === 'profile'"
      class="footer-item"
      :href="heraShopLink"
    >
      <img src="@/assets/images/mobile-navigation/shopMobile.svg" alt="" />
      <p>{{ $t('_web_playhera_shop', 'Shop') }}</p>
    </a> -->
    <router-link
      v-if="
        currentRegion &&
          currentRegion.feature_flags.marketplace_url &&
          account &&
          account._type === 'profile'
      "
      class="footer-item"
      to="/shop/categories"
    >
      <!-- <img
        class="beta-logo-mobile-nav-shop"
        src="@/assets/images/header/LEAP2024-beta.svg"
        alt="ph max logo"
      /> -->
      <img src="@/assets/images/new-icons/VectorSHOP.svg" alt="" />
      <p>{{ $t('_web_playhera_shop', 'Shop') }}</p>
    </router-link>
    <router-link class="footer-item" to="/tournaments">
      <img
        src="@/assets/images/new-icons/VectorTournaments.svg"
        class="trophy"
        alt="trophy"
      />
      <p>{{ $t('_web_tournaments', 'Tournaments') }}</p>
    </router-link>
    <router-link class="footer-item" to="/search">
      <img src="@/assets/images/new-icons/VectorSearch.svg" alt="" />
      <p>{{ $t('_web_search', 'Search') }}</p>
    </router-link>
  </div>
</template>

<script>
import { API } from '@/api/config';
import { mapState, mapGetters, mapActions } from 'vuex';
import { SSO_CLIENT_ID } from '@/api/config';
import Spinner from '@/views/main/components/Spinner.vue';
export default {
  name: 'HeraMobileFooter',
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      subscriptions: {},
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['appId', 'isAuthorized', 'isRTL', 'currentRegion']),
    ...mapGetters('sidebars', ['guestOpen']),
    ...mapGetters('ph', ['phDemoUsersData']),
    ...mapState('ph', ['phDemoUsersData']),
    heraShopLink() {
      const region = JSON.parse(localStorage.getItem('region'));
      const locale = JSON.parse(localStorage.getItem('locale'));
      return region.feature_flags.marketplace_url
        ? `${region.feature_flags.marketplace_url}${locale.key}-sa/`
        : null;
    },
    omantelLogo() {
      const region = JSON.parse(localStorage.getItem('region'));
      return region.prominent_logo;
    },

    isAvailableInRegion() {
      const region = JSON.parse(localStorage.getItem('region'));
      if (region.id == 'mena') {
        return true;
      } else {
        return false;
      }
    },
    phMaxLink() {
      const region = JSON.parse(localStorage.getItem('region'));
      return this.phDemoUsersData
        ? 'subscription/'
        : region.feature_flags.phmax_url;
    },
  },

  mounted() {
    this.fetchSubscriptions();
  },

  methods: {
    ...mapActions(['ssoSwitch']),
    ...mapActions('ph', ['setPhDemoUsersData']),
    async fetchPhDemoUsersData() {
      try {
        const response = await API('/payments/subscriptions/phmax/demo');
        const data = response.data;
        this.$store.dispatch('ph/setPhDemoUsersData', data);
      } catch (error) {
        console.log(error);
      }
    },
    redirectToPhMax() {
      if (this.subscriptions[0].status !== 'inactive') {
        this.isLoading = true;
        const ext = this.$route.query;
        this.ssoSwitch({
          accountId: SSO_CLIENT_ID,
          accountType: 'thirdparty',
          ext,
        })
          .then(resp => {
            window.location.href = resp.redirect;
          })
          .catch(err => {
            this.errorNotify(err);
          });
      } else {
        this.$router.push({
          name: 'subscriptionSetup',
          path: '/subscription',
          query: {
            view: true,
          },
        });
      }
    },
    async fetchSubscriptions() {
      try {
        const response = await API('/payments/mySubscriptions/');
        const data = response.data;
        this.subscriptions = data.subscriptions.filter(sub => {
          return sub.product === 'phmax';
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-footer-wrapper {
  justify-content: space-around;
  width: 100%;
  position: fixed;
  bottom: 0vh;
  height: 4.75rem;
  background-color: $new-bg-page-faded;

  .footer-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-decoration: none;

    &:focus {
      background-color: $new-bg-elevated !important;
    }

    &:active {
      background-color: $new-bg-elevated;
    }

    p {
      margin-bottom: 0;
      margin-top: 9px;
      white-space: nowrap;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 24px;
      height: 26px;
    }

    &:hover {
      background-color: $new-bg-elevated;
    }

    &.invisible {
      visibility: hidden;
    }
  }

  .phmax-button {
    display: flex;
    justify-content: center;

    &:active {
      background-color: $new-bg-elevated;
    }

    a:active {
      background-color: transparent !important;
    }
  }

  .spinner-middle {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
  }
}

.beta-logo-mobile {
  position: absolute;

  .app-ltr & {
    margin-left: 1.1rem;
  }

  .app-rtl & {
    margin-right: -1.1rem;
  }
  margin-top: -0.7rem;
}

.footer-item {
  cursor: pointer;
}

.ph-max-btn-text-mobile-footer {
  text-transform: uppercase;
}
.mobile-wrapper-footer {
  display: flex;
  @media screen and (min-width: 850px) {
    display: none;
  }
}
.omantel {
  display: flex;
  @media screen and (min-width: 850px) {
    display: none;
  }
}

.beta-logo-mobile-nav-shop {
  position: absolute;
  // @include auto-rtl(margin-left, -1.8rem);

  .app-ltr & {
    margin-left: 1.1rem;
  }

  .app-rtl & {
    margin-right: -1.1rem;
  }
  margin-top: -0.7rem;
}
.ph-max-logo-mobile {
  height: 26px;
  width: 34px !important;
}
.trophy {
  height: 26px !important;
  width: 34px !important;
}
</style>
