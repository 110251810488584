var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{class:[
    _vm.data.staticClass,
    { 'editable-counter pb-1 h-pr-2 ': _vm.props.isEditable } ]},[_c('span',{class:!_vm.props.isColored
        ? null
        : !_vm.props.currentInput
        ? 'text-secondary'
        : _vm.props.currentInput.length > _vm.props.maxLength
        ? 'text-danger'
        : _vm.props.currentInput.length === _vm.props.maxLength
        ? 'text-success'
        : 'text-warning'},[_vm._v(_vm._s(_vm.props.currentInput.length))]),(_vm.props.maxLength)?[_c('span',{staticClass:"text-secondary mx-2"},[_vm._v("/")]),_vm._v(_vm._s(_vm.props.maxLength)+"\n  ")]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }