<template>
  <div class="slider-chat-ps d-flex flex-column">
    <header
      v-flip="localeDirection"
      class="slider-chat-ps__header d-flex justify-content-between"
    >
      <span>{{
        $t(
          '_web_tournament_sidechat_add-new-participants',
          'Add new participants'
        )
      }}</span>
      <Icon
        name="cancel"
        class="slider-chat-ps__cancel-btn"
        width="24px"
        @click="$emit('back')"
      />
    </header>
    <HeraFormTextInput
      v-model="searchQuery"
      class="slider-chat-ps__search"
      :placeholder="$t('_web_tournament_sidechat_search', 'Search')"
    />
    <div class="slider-chat-ps__lists-container custom-scrollbar">
      <List
        v-for="item in lists"
        :key="item"
        :search-query="searchQuery"
        :category="item"
        :selected-participants="selectedParticipants"
        @addParticipant="$emit('addParticipant', $event)"
        @updateParticipants="$emit('updateParticipants', $event)"
      />
      <List
        v-for="item in attributes"
        :key="item.id"
        :search-query="searchQuery"
        :category="ATTRIBUTES"
        :attribute-id="item.id"
        :attribute-name="item.name"
        :selected-participants="selectedParticipants"
        @addParticipant="$emit('addParticipant', $event)"
        @updateParticipants="$emit('updateParticipants', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import flip from '@/directives/flip';
import Icon from '@/components/atoms/Icon';
import HeraFormTextInput from '@/components/atoms/form/HeraFormTextInput';
import List from './SelectorList';
import {
  ATTRIBUTES,
  QUALIFIERS,
  REGISTRANTS,
  RESERVED,
} from '@/constants/categoryParticipants';
export default {
  components: { Icon, HeraFormTextInput, List },
  directives: {
    flip,
  },
  props: {
    selectedParticipants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      lists: [REGISTRANTS, QUALIFIERS, RESERVED],
      attributes: [],
      ATTRIBUTES: ATTRIBUTES,
    };
  },
  computed: {
    ...mapGetters(['localeDirection', 'getCurrentTournamentAttributes']),
    tournamentID() {
      return this.$route.params.tournamentID;
    },
  },
  created() {
    this.fetchCurrentTournamentAttributes(this.tournamentID)
      .then(() => (this.attributes = this.getCurrentTournamentAttributes))
      .catch(this.errorNotify);
  },
  methods: {
    ...mapActions(['fetchCurrentTournamentAttributes', 'errorNotify']),
  },
};
</script>

<style lang="scss">
.slider-chat-ps {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $ph-input-bg;
  &__header {
    height: 3.5rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid $ph-accent;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 3.5rem;
    padding: 0 0.875rem;
  }
  &__cancel-btn {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }
  &__search {
    margin: 0 0.75rem 1.2rem 0.75rem;
  }
  &__lists-container {
    flex: 1 auto;
    padding: 0 0.5rem 0 0.75rem;
    margin: 0 0.25rem 0.5rem 0;
  }
}
</style>
