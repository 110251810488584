var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative chat-dialog-header-wrap"},[(_vm.conversationInfo)?_c('div',{staticClass:"chat-dialog-header"},[_c('AppIcon',{staticClass:"align-self-center cursor-hand  d-md-none p-3 mr-3 back-btn-chat",attrs:{"name":"arrow-right","width":20,"rotate":_vm.isRTL ? 0 : -180},on:{"click":_vm.goBack}}),(_vm.headerLink.params)?_c('LinkAccount',{attrs:{"id":_vm.headerLink.params.accountId,"type":_vm.headerLink.params.accountType}},[_c('ListItem',{class:'header-list-item-chat',attrs:{"avatar-props":{
          size: 48,
          imgUrl: _vm.conversationInfo.avatar,
          type: _vm.avatarType,
        },"title":_vm.conversationInfo.title}},[_c('div',{staticClass:"d-flex flex-nowrap",attrs:{"slot":"tail"},slot:"tail"},[_c('AppIcon',{staticClass:"cursor-hand",attrs:{"name":"translate","width":20},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showModalTranslate($event)}}}),(_vm.miniMode)?_c('AppIcon',{staticClass:"align-self-center cursor-hand h-ml-4",attrs:{"name":"arrow-left","width":20,"rotate":_vm.isRTL ? 0 : -180},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeActivePopoverChat($event)}}}):_c('ThreeDots',{staticClass:"h-ml-4",attrs:{"items":_vm.threeDotsItems,"hide-if-items-is-empty":""}})],1)])],1):_c('div',[_c('ListItem',{class:'header-list-item-chat',attrs:{"avatar-props":{
          size: 48,
          imgUrl: _vm.conversationInfo.avatar,
          type: _vm.avatarType,
        },"title":_vm.conversationInfo.title}},[_c('div',{staticClass:"d-flex flex-nowrap",attrs:{"slot":"tail"},slot:"tail"},[_c('AppIcon',{staticClass:"cursor-hand",attrs:{"name":"translate","width":20},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showModalTranslate($event)}}}),(_vm.miniMode)?_c('AppIcon',{staticClass:"align-self-center cursor-hand h-ml-4",attrs:{"name":"arrow-left","width":20,"rotate":_vm.isRTL ? 0 : -180},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeActivePopoverChat($event)}}}):_c('ThreeDots',{staticClass:"h-ml-4",attrs:{"items":_vm.threeDotsItems,"hide-if-items-is-empty":""}})],1)])],1)],1):_vm._e(),(_vm.conversationInfo)?_c('div',{staticClass:"show-hide-participants",class:_vm.miniMode ? 'dialogChat' : '',on:{"click":_vm.toggleShow}},[_vm._v("\n    "+_vm._s(_vm.show
        ? _vm.$t('_web_conversation_hide_participants', 'hide participants')
        : _vm.$t('_web_conversation_show_participants', 'show participants'))+"\n  ")]):_vm._e(),(_vm.show)?_c('div',{staticClass:"participants-list custom-scrollbar"},_vm._l((_vm.conversationInfo.participants),function(participant,index){return _c('div',{key:index},[_c('LinkAccount',{staticClass:"link-uncolor",attrs:{"id":participant.id,"type":participant.subjectType}},[_c('Avatar',{attrs:{"img-url":participant.avatar,"size":36,"type":participant.subjectType}}),_vm._v("\n        "+_vm._s(participant.name)+"\n      ")],1)],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }