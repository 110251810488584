<template>
  <div
    class="sc-avatar d-flex align-items-center justify-content-center"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <ImageUpload
      v-if="editable"
      v-model="avatarModel"
      :help="$t('_web_tournament_sidechat_upload_avater', 'Upload')"
      :style="{ height: `${size}px`, width: `${size}px` }"
      hide-label
    >
      <div
        slot="placeholder"
        class="d-flex align-items-center justify-content-center"
      >
        <Icon
          name="channel"
          :width="Math.floor(size / 3)"
          :fill="$scss.heraMuted"
        />
      </div>
    </ImageUpload>
    <Avatar v-else :img-url="imgUri" :type="avatarType" :size="size" />
  </div>
</template>

<script>
import ImageUpload from '@/components/form/ImageUpload';
import Avatar from '@/components/images/Avatar';
import { prop } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';

export default {
  components: { Icon, Avatar, ImageUpload },
  props: {
    type: prop(String, 'chat'),
    imgUri: prop(String),
    size: prop(Number, 60),
    editable: prop(Boolean),
    disabled: prop(Boolean),
  },
  data() {
    return {
      avatar: null,
    };
  },
  computed: {
    avatarType() {
      return this.type === 'chat'
        ? 'profile'
        : this.type === 'group_chat'
        ? 'group'
        : this.type === 'channel'
        ? 'channel'
        : '';
    },
    avatarModel: {
      get() {
        return this.avatar || this.imgUri;
      },
      set(newAvatar) {
        this.avatar = newAvatar && newAvatar.display_uri;
        this.$emit('edited', newAvatar);
      },
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.sc-avatar {
  position: relative;

  &__editor {
    position: relative;
    mask-image: url(~@/assets/images/icons/shapes/mask.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: top;
  }

  &__croppa_hide {
    opacity: 0;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__load-btn {
    position: absolute;
    bottom: 0;
    left: -0.5px;
    border: none;
    outline: none;
    background-color: $ph-accent;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  &__dropdown-menu {
    position: absolute;
    left: 0;
    margin-top: 8px;
    width: 120px;
    padding: 8px 12px;
    text-align: center;
    background-color: #393948;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 3;
    & a {
      font-size: 0.75rem;
      color: $ph-primary-text;
      line-height: 1.5rem;
      display: block;
      margin-bottom: 1px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 24px;
      top: -5px;
      display: block;
      width: 10px;
      height: 10px;
      background-color: #393948;
      border-radius: 2px;
      transform: rotate(45deg);
    }
  }

  &__dropdown-divider {
    background-color: $ph-input-bg;
    height: 1px;
    margin: 0.5rem 0;
  }
}
</style>
