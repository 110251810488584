import api from '@/api';
// The file with status-bar actions requests

const actions = {
  validateJoinTournament(
    {
      getters: { accessToken },
    },
    { tournamentId, teamId } = {}
  ) {
    return api.tournaments
      .validateTournament(tournamentId)(accessToken, {
        params: {
          clanId: teamId,
        },
        // data: 'unsetInterceptorReplacer',
      })
      .then(({ data }) => data);
  },
  //todo @olesk replace it
  clanInviteAccount({ dispatch }, { accountId, clanId }) {
    return dispatch('inviteToClan', {
      clan: clanId,
      candidates: [accountId],
    });
  },
  //todo @olesk replace it
  clanRemoveAccount({ dispatch }, { accountId, clanId }) {
    return dispatch('cancelInviteToClan', {
      clan: clanId,
      accountId,
    });
  },

  followAccount(
    {
      getters: { accessToken },
    },
    { accountType, accountId, follow }
  ) {
    return api.accounts
      .followAccount({
        accountType,
        accountId,
      })(accessToken, {
        login: accountId,
        follow,
      })
      .then(({ data }) => data);
  },
  blockAccount(
    {
      getters: { accessToken },
    },
    { accountType, accountId }
  ) {
    return api.accounts
      .blockAccount({
        accountType,
        accountId,
      })(accessToken, {
        login: accountId,
      })
      .then(({ data }) => data);
  },
  joinAccountClan(
    {
      getters: { accessToken },
    },
    { accountId }
  ) {
    return api.accounts
      .joinAccountClan({ accountId })(accessToken)
      .then(({ data }) => data);
  },

  acceptAccountClan(
    {
      getters: { accessToken },
    },
    { accountId }
  ) {
    return api.accounts
      .acceptAccountClan({ accountId })(accessToken)
      .then(({ data }) => data);
  },
  friendRequestAccount(
    {
      getters: { accessToken },
    },
    { accountType, accountId }
  ) {
    return api.accounts
      .friendRequestAccount({
        accountType,
        accountId,
      })(accessToken)
      .then(({ data }) => data);
  },
  friendRemoveAccount(
    {
      getters: { accessToken },
    },
    { accountType, accountId }
  ) {
    return api.accounts
      .friendRemoveAccount({
        accountType,
        accountId,
      })(accessToken)
      .then(({ data }) => data);
  },
  friendAcceptAccount(
    {
      getters: { accessToken },
    },
    { accountType, accountId }
  ) {
    return api.accounts
      .friendAcceptAccount({
        accountType,
        accountId,
      })(accessToken)
      .then(({ data }) => data);
  },
  friendCancelAccount(
    {
      getters: { accessToken },
    },
    { accountType, accountId }
  ) {
    return api.accounts
      .friendCancelAccount({
        accountType,
        accountId,
      })(accessToken)
      .then(({ data }) => {
        // commit('current/updateAccountModel', {  });
        return data;
      });
  },
};

export default {
  actions,
};
