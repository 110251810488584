<template>
  <Panel>
    <div class="d-flex">
      <Subheader v-if="title" :text="title" class="mb-4 flex-grow-1" />
      <slot name="titleAction" />
    </div>
    <slot />
  </Panel>
</template>

<script>
import { prop } from '@/utils/factories';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
export default {
  name: 'DashboardWidgetWrapper',
  components: {
    Panel,
    Subheader,
  },
  props: {
    title: prop(String, ''),
  },
};
</script>
