<template>
  <router-link :to="'/shop/' + product" class="card-preview">
    <div class="cover-image-card">
      <div class="shop-card-cover-image-container">
        <img
          class="shop-card-cover-image"
          :src="coverImage"
          alt="Cover Image"
        />
      </div>
    </div>
    <div class="bottom-card-shop">
      <div class="title-shop">{{ title }}</div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="prices-wrapper"></div>
        <HeraButton
          class="btn-primary shop-now"
          :label="$t('_web_shop_label_buy', 'BUY')"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import HeraButton from '@/components/atoms/HeraFormBtn';
export default {
  name: 'ShopCard',
  components: {
    HeraButton,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    card: {
      type: Object,
      required: false,
    },
    coverImage: {
      type: String,
      required: true,
    }, // eslint-disable-next-line vue/require-default-prop
    title: {
      type: String,
      required: true,
    },

    product: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.card-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}

.bottom-card-shop {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: rgba(24, 28, 37, 1);
  margin-top: 4px;
  border-radius: 5px;
  font-family: Exo;
  font-size: 16px;
}

.prices-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  color: rgba(193, 199, 215, 1);
}

.title-shop {
  font-weight: 700;
  color: rgba(239, 240, 241, 1);
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price-actual {
  font-size: 16px;
}

.regular-price {
  font-size: 12px;
}

.action-percent {
  position: absolute;
  top: 16px;
  left: 0;
  width: 65px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 0px 4px 4px 0px;
  .app-rtl & {
    border-radius: 4px 0px 0px 4px;
    right: 0;
  }
  background: #f8d53a;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: black;
    font-family: Exo;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
  }
}

.cover-image-card {
  position: relative;
  img {
    border-radius: 5px;
    width: 100%;
  }
}

.shop-now {
  color: var(--on-background-primary, #fff);
  font-family: Exo;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
}

.shop-card-cover-image-container {
  // height: 250px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  aspect-ratio: 16 / 9;
}

.shop-card-cover-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  // mix-blend-mode: multiply;
}
</style>
