<template>
  <div :class="{ animated }">
    <icon
      v-show="animated || isFull"
      name="full_logo"
      :width="fullWidth"
      fill="white"
      auto-height
      class="full-logo main-logo"
      :class="{ visible: isFull }"
    />
    <icon
      v-show="animated || !isFull"
      name="short_logo_beta"
      :width="shortWidth"
      fill="white"
      auto-height
      class="short-logo main-logo"
      :class="{ visible: !isFull }"
    />
  </div>
</template>
<script>
import icon from '../atoms/Icon';

export default {
  name: 'HeraLogo',
  components: {
    icon,
  },
  props: {
    type: {
      type: String,
      default: 'full',
    },
    animated: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: String,
      default: '189',
    },
    shortWidth: {
      type: String,
      default: '44',
    },
  },
  computed: {
    isFull() {
      return this.type === 'full';
    },
  },
};
</script>
<style lang="scss" scoped>
.animated {
  position: relative;
  height: 100%;

  .main-logo {
    position: absolute;
    opacity: 0;
    transition: all 0.3s linear;
    top: 50%;

    &.full-logo {
      @include auto-rtl(left, 0);
      transform: translateY(-50%);
    }

    &.short-logo {
      $half-navbar-width-collapse: math.div($navbar-width-collapse, 2);
      .ltr & {
        left: calc(#{$half-navbar-width-collapse} - 1rem);
        transform: translate(-50%, -50%);
      }

      .rtl & {
        right: calc(#{$half-navbar-width-collapse} - 1rem);
        transform: translate(50%, -50%);
      }
    }

    &.visible {
      opacity: 1;
    }
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="HeraLogo"
  knobs="{
    type: {
      default: text('type', 'full')
    },
    animated: {
      default: boolean('animated', false)
    },
    variant: {
      default: select('variant', ['strong', 'em', 'normal', 'secondary'], 'normal')
    },
    fullWidth: {
      default: text('fullWidth', '156')
    },
    shortWidth: {
      default: text('shortWidth', '17')
    },
  }"
>
  <HeraLogo v-bind="{type, animated, variant, fullWidth, shortWidth }"/>
</story>
