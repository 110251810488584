import {
  ACCOUNT,
  ACCOUNT_TYPES,
  PROFILE,
  ORGANIZATION,
  CLAN,
  TOURNAMENT,
  BATTLE,
} from '../accounts';

export const INFO = 'info',
  EDIT = 'edit',
  FEEDS = 'feeds',
  TEAMS = 'teams',
  PARTICIPANTS = 'participants',
  PLAYERS = 'players',
  STANDINGS = 'standings',
  CREATE_TEAM = 'clan-create-team',
  EDIT_TEAM_MEMBERS = 'clan-edit-team',
  SEARCH = 'search',
  HASHTAGS = 'hashtags',
  TOURNAMENTS = 'tournaments',
  CLANS = 'clans',
  COMPETITIONS = 'competitions',
  BATTLES = 'battles',
  SETTINGS = 'settings',
  ROSTER = 'roster',
  SQUAD = 'squad',
  MY_TEAM = 'my-team',
  RESULTS = 'results',
  RESULTS_EDIT = 'results-edit',
  DELETED = 'deleted',
  CONTACTS = 'contacts',
  INVITES = 'invites',
  CLAN_MEMBERS = 'inviteClanMembers';

export const TOURN_ROUTE_NAMES = {
  CREATE_BLOCK: 'createBlock',
  EDIT_BLOCK: 'editBlock',
  CREATE_STAGE: 'createStage',
  EDIT_STAGE: 'editStage',
  STAGE_PARTICIPANTS: 'stageParticipants',
  PLAYER_STAGES_LIST: 'playerStagesList',
  STAGES_LIST: 'multiStage',
  VIEW_STAGE: 'viewStage',
  VIEW_BLOCK: 'viewBlock',
};
export default {
  ACCOUNT,
  ACCOUNT_TYPES,
  PROFILE,
  ORGANIZATION,
  CLAN,
  CLANS,
  TOURNAMENT,
  BATTLE,

  SEARCH,
  FEEDS,
  TEAMS,
  PLAYERS,
  EDIT_TEAM_MEMBERS,
  PARTICIPANTS,
  STANDINGS,
  CREATE_TEAM,
  COMPETITIONS,
  BATTLES,
  INFO,
  EDIT,
  SETTINGS,
  TOURNAMENTS,
  TOURN_ROUTE_NAMES,
  ROSTER,
  SQUAD,
  MY_TEAM,
  RESULTS,
  RESULTS_EDIT,
  DELETED,
  CONTACTS,
  INVITES,
  CLAN_MEMBERS,
};
