import { cloneDeep } from '@/utils/lodashUtils';

export const mapPlayersItems = array =>
  array.map(e => {
    let fieldsData = cloneDeep(e.fields_data);
    fieldsData = {
      ...fieldsData,
      user: {
        name: fieldsData.username,
        avatar: fieldsData.avatar,
      },
      blockIndex: fieldsData.block_index || fieldsData.blockIndex,
      approvedTo: fieldsData.approved_to || fieldsData.approvedTo,
    };
    delete fieldsData.avatar;
    delete fieldsData.username;
    return {
      ...e,
      fields_data: fieldsData,
    };
  });
