<template functional>
  <div
    class="attrib-tag d-flex align-items-center"
    :style="{ 'background-color': props.color ? props.color : null }"
  >
    <strong>{{ props.name }}</strong>
    <component
      :is="injections.components.Icon"
      v-if="props.active"
      name="cancel"
      class="cursor-pointer ml-2"
      width="14"
      @click.stop="listeners.delete"
    >
    </component>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
export default {
  name: 'AttributeTag',
  inject: {
    components: {
      default: {
        Icon,
      },
    },
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.attrib-tag {
  position: relative;
  width: max-content;
  padding: 0 $ph-mini-space;
  margin: 0.04rem;
  height: 1.25rem;
  border-radius: $ph-small-radius;
  font-size: $ph-caption-bold-font-size;
  color: $ph-card;
  background-color: $ph-green;
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="AttributeTag"
  knobs="{
    active: {
      default: boolean('active', true)
    },
    name: {
      default: text('name', 'Seed')
    },
    color: {
      default: text('color', '')
    }
  }"
>
  <AttributeTag v-bind="{ active, name, color}" @delete="action('delete')"/>
</story>
