<template>
  <WrapperFormControl
    :key="label"
    v-bind="{
      required,
      label,
      readOnly: disabled,
      error: $attrs.error,
      name,
    }"
    :class="{ 'has-custom-input': customInput }"
    class="multi-radio-wrapper d-flex flex-column"
  >
    <div
      slot="control"
      class="align-items-center multi-radio flex-grow-1"
      :class="{
        row: isDefaultView || isEqualAndFlat,
        'm-0': isEqualAndFlat,
      }"
    >
      <Radio
        v-for="(text, val) in items"
        :key="`radio_${val}`"
        v-model="computedVal"
        v-bind="{ variant, disabled, name, label: text, val, value }"
        :class="[
          customClass,
          {
            'col-lg-1 col-md-2 col-sm-3 col-6': isDefaultView && !colsAuto,
            'col-auto': isDefaultView && colsAuto,
          },
        ]"
        class="multi-radio-btn"
        @radioChange="toggleCustom"
      >
        <slot v-bind="{ label: text, val }" />
      </Radio>
      <Radio
        v-if="customInput"
        key="radio_custom"
        v-model="custom"
        v-bind="{ disabled, name, val: 'custom' }"
        class="multi-radio-custom"
        :class="[customClass, { 'col-md-2 col-sm-3 col-6': !colsAuto }]"
        @radioChange="toggleCustom"
        @click.native="focusOnCustomField"
      >
        <TextInput
          v-model="customVal"
          :read-only="!customInput"
          :placeholder="customPlaceholder"
          :size="customInputSize"
          :class="{
            'with-custom-size': customInputSize,
          }"
          class="w-100 custom-input"
          @input="setCustom"
          @focus="() => toggleCustom('custom')"
        />
      </Radio>
    </div>
  </WrapperFormControl>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import Radio from '@/components/atoms/form/HeraFormRadio';
import TextInput from '@/components/atoms/form/HeraFormTextInput';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';

export default {
  name: 'MultiRadio',
  inject: ['$validator'],
  components: { WrapperFormControl, TextInput, Radio },
  model: {
    prop: 'value',
    event: 'multiRadioChange',
  },
  props: {
    value: prop([String, Number]),
    label: prop(String),
    items: propRequired([Object, Array]),
    name: prop([String, Number]),
    disabled: prop(Boolean),
    colsAuto: prop(Boolean, true),
    colsEqual: prop(Boolean),
    variant: prop(String, 'default'),
    required: prop(Boolean),
    customInput: prop(Boolean),
    customInputSize: prop(String),
    customClass: prop(String),
    customPlaceholder: prop(String),
  },
  data() {
    return {
      custom: null,
      customVal: null,
    };
  },
  computed: {
    isDefaultView() {
      return this.variant === 'default';
    },
    isEqualAndFlat() {
      return this.variant === 'flat' && this.colsEqual;
    },
    isItemsArray() {
      return Array.isArray(this.items);
    },
    computedVal: {
      get() {
        if (this.custom) {
          return null;
        }
        if (this.isItemsArray) {
          const index = this.items.findIndex(item => item === this.value);
          return index !== -1 ? index : null;
        } else {
          return this.value;
        }
      },
      set(val) {
        this.$emit(
          'multiRadioChange',
          this.isItemsArray ? this.items[val] || val : val
        );
      },
    },
  },
  watch: {
    value(newVal) {
      this.initCustom(newVal);
    },
  },
  created() {
    this.initCustom(this.value);
  },
  methods: {
    initCustom(newVal) {
      if (
        this.customInput &&
        (newVal || Number.isInteger(newVal)) &&
        (!this.computedVal && !Number.isInteger(this.computedVal))
      ) {
        this.custom = 'custom';
        this.customVal = newVal;
      }
    },
    focusOnCustomField() {
      const customEl = this.$el.querySelector('.custom-input input');
      if (!this.disabled && customEl) {
        customEl.focus();
      }
    },
    toggleCustom(val) {
      if (val === 'custom') {
        this.custom = val;
        this.computedVal = null;
      } else {
        this.custom = null;
        this.customVal = null;
      }
    },
    setCustom(val) {
      this.$emit('multiRadioChange', val);
    },
  },
};
</script>

<style lang="scss">
.multi-radio-wrapper {
  &.has-custom-input {
    .wrapper-control-label {
      // margin-bottom: 0px !important;
    }
    .with-custom-size {
      .input-form {
        width: unset !important;
      }
    }
  }
}
.multi-radio {
  &-btn,
  &-custom {
    margin-bottom: $ph-large-space;
    min-width: 70px;
  }
  &-custom .custom-control-label {
    width: 100%;
  }
}
</style>
