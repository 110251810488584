<template>
  <div v-if="hasOrganizer">
    <ListItemAccount
      :key="listItemBind.accountId"
      v-bind="listItemBind"
      :size="size"
      :class="putClass ? 'tournament-mini-card_organizer' : null"
    />
  </div>
</template>

<script>
import { propRequired } from '@/utils/factories';
import ListItemAccount from '@/components/common/ListItemAccount';
import { ORGANIZATION } from '@/constants/accounts';

export default {
  name: 'TournamentCardOrganizer',
  components: {
    ListItemAccount,
  },
  props: {
    tournament: propRequired(Object),
    size: {
      type: Number,
      default: 24,
    },
    putClass: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasOrganizer({ tournament }) {
      return (
        tournament['is_event_organizer_available'] ||
        tournament['isEventOrganizerAvailable']
      );
    },
    listItemBind({ tournament }) {
      return {
        type: ORGANIZATION,
        avatar:
          tournament.event_organizer_logo || tournament.eventOrganizerLogo,
        name: tournament.event_organizer_name || tournament.eventOrganizerName,
        vanityId:
          tournament.event_organizer_vanity_id ||
          tournament.eventOrganizerVanityId,
        accountId: tournament.owner,
        size: 36,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-mini-card_organizer {
  padding: 4px;
}
</style>
