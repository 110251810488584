<template>
  <span v-if="platformsList">
    <span v-if="!omitDot" class="mx-1">•</span>
    {{ platformsList }}
  </span>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import { mapGetters } from 'vuex';

export default {
  name: 'TournamentCardPlatform',
  props: {
    tournament: propRequired(Object),
    omitDot: prop(Boolean, false),
  },
  computed: {
    ...mapGetters(['platforms']),

    platformsList({ tournament, $lodash: { get } }) {
      if (!tournament.platforms) {
        return null;
      }
      // tournament.platforms
      //   .map(
      //     el =>
      //       get(this.platforms.find(({ id }) => id === el), 'name') || null
      //   )
      //   .join(', ');

      return tournament.platforms.length > 1
        ? this.$t('_web_platform_crossplatform', 'Crossplatform')
        : get(
            this.platforms.find(({ id }) => id === tournament.platforms[0]),
            'name'
          ) || null;
    },
  },
};
</script>
