var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center match-wrapper-simple--details",attrs:{"data-round-index":_vm.roundIndex,"data-match-index":_vm.matchIndex}},[_c('Panel',_vm._g({staticClass:"match-card my-0 px-4 py-3 cursor-pointer active",class:{
      empty: !_vm.match.id,
    },attrs:{"variant":[
      'transparent',
      'stroked',
      _vm.isActive ? 'stroked-accent' : 'stroked-secondary',
      'rounded',
      'rounded-medium' ]}},_vm.$listeners),[_c('Panel',{key:((_vm.match.id) + "-info"),staticClass:"p-0 mb-0 match-bottom-addons d-flex align-items-center justify-content-between",attrs:{"variant":['transparent']}},[(_vm.match.battle_id)?_c('TextLabel',{staticClass:"p-0 battle-id-label"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_tournament_battle_id', '# {match}', {
            match: _vm.match.index + 1,
          }))),_c('span',{staticClass:"mx-3"},[_vm._v("|")]),_vm._v(_vm._s(_vm.roundName)+"\n      ")]):_vm._e(),_c('TextLabel',{staticClass:"h-ml-auto h-mr-4",attrs:{"text":_vm.$$t('Rank')}}),_c('TextLabel',{staticClass:"mx-2",attrs:{"text":_vm.$$t('Kills')}})],1),_c('div',{staticClass:"mb-1 d-flex align-items-center"},[_c('PlayerItem',_vm._g({staticClass:"p-0 flex-grow-1",attrs:{"player":_vm.match.players[_vm.playerIndex].profile,"hide-score":"","hide-checkin":"","avatar-size":24,"player-score":_vm.playerOneScore,"round-index":_vm.roundIndex,"match-index":_vm.matchIndex,"player-index":1,"variant":_vm.playerOneVariant,"is-profile":_vm.isSingle,"chat-with":_vm.match.captain_1,"is-ready-for-battle":!_vm.match.is_detained || _vm.match.player_1_ready,"is-empty":_vm.match.player_empty_1}},{
          userMenuOpen: _vm.$listeners.userMenuOpen || _vm.$lodash.noop,
        })),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center mx-3 pt-1"},[_c('SingleScore',{staticClass:"mb-1",attrs:{"value":_vm.getRankText(),"borderless":""}})],1),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center pt-1"},[_c('SingleScore',{staticClass:"mb-1",attrs:{"value":_vm.playerOneScore,"borderless":""}})],1)],1),_c('div',{staticClass:"d-flex align-items-center mt-2 small-badge"},[_c('StatusLabel',{staticClass:"px-2 py-1",attrs:{"status":_vm.match.status}}),(!_vm.isMatchCancelled)?_c('div',{staticClass:"started-date mx-3"},[_vm._v("\n        "+_vm._s(_vm._f("localDate")(_vm.match.date_start,_vm.$times.DATETIME_MED))+"\n      ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }