<template>
  <TextBlock
    class="playhera-label secondary-font"
    :class="{
      'required h-pr-4': required,
      'registration-form-style': $route.path.includes('registration/form'),
    }"
    v-bind="{
      ...additionalParams,
      size: $options.fontSize,
      lineHeight: 1.2,
      text,
    }"
  >
    <slot />
  </TextBlock>
</template>

<script>
import { prop } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'TextLabel',
  components: {
    TextBlock,
  },
  props: {
    text: prop(String),
    forId: prop(String),
    variant: prop(String),
    required: prop(Boolean),
  },
  computed: {
    additionalParams() {
      return this.variant === 'caption'
        ? {
            variant: ['secondary', 'bold'],
            tag: 'span',
          }
        : {
            variant: 'secondary',
            tag: 'label',
            for: this.forId,
          };
    },
  },
  created() {
    this.$options.fontSize = parseFloat(this.$scss.fontSizes_xs);
  },
};
</script>

<style lang="scss">
.playhera-label {
  /* font-weight: bold; */
  // text-transform: uppercase;
  position: relative;
  &.required {
    white-space: nowrap;
    &::after {
      @include required-asterisk();
    }
  }
}

.registration-form-style {
  white-space: normal !important;
}
</style>

<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/Text"
  name="TextLabel"
  knobs="{
    text: {
      default: text('Text', 'Text for label')
    },
    forId: {
      default: text('forId', 'id')
    },
    required: {
      default: boolean('required', false)
    }
  }"
>
  <TextLabel v-bind="{text, forId, required}"/>
</story>
