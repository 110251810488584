<template>
  <ModalBackdrop :value="!!visibleAlert">
    <component
      :is="component"
      v-if="!!visibleAlert"
      v-bind="props"
      v-on="events"
    />
  </ModalBackdrop>
</template>

<script>
import { mapState } from 'vuex';
import { ALERT_COMPONENTS } from '@/constants/modal';
import ModalBackdrop from '@/components/common/ModalBackdrop';

export default {
  name: 'Alerts',
  components: { ModalBackdrop },
  computed: {
    ...mapState('alerts', ['visibleAlert']),
    component() {
      const type = (this.visibleAlert || {}).type;
      return type && ALERT_COMPONENTS[type];
    },
    props() {
      return (this.visibleAlert || {}).props;
    },
    events() {
      return (this.visibleAlert || {}).events;
    },
  },
};
</script>

<style></style>
