var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"flex-grow-1 pt-0 mt-0 h-100"},[_c('div',{staticClass:"flex-grow-1 h-100 mx-auto position-relative",class:{
      'content-wrapper':
        this.$router.history.current.name !== 'homepage' &&
        this.$router.history.current.name !== 'singleCompetition' &&
        this.$router.history.current.name !== 'post' &&
        this.$router.history.current.name !== 'SinglePost' &&
        this.$router.history.current.name !== 'subscriptionSetup' &&
        this.$router.history.current.name !== 'subscriptionAnnouncement' &&
        this.$router.history.current.name !== "playerTournamentBracket" &&
        this.$router.history.current.name !== 'playerStagesList' &&
        this.$router.history.current.name !== 'conversations' &&
        this.$router.history.current.name !== 'feed' &&
        this.$router.history.current.name !== 'Shop' &&
        this.$router.history.current.name !== 'ShopLand' &&
        this.$router.history.current.name !== 'singleProduct' &&
        this.$router.history.current.name !== 'ShopSuccess' &&
        this.$router.history.current.name !== 'search',
      'bottom-content-padding':
        this.$router.history.current.name !== 'post' &&
        this.$router.history.current.name !== 'SinglePost' &&
        this.$router.history.current.name !== 'subscriptionAnnouncement' &&
        this.$router.history.current.name !== 'conversations' &&
        this.$router.history.current.name !== 'search',
      'brackets-layout':
        this.$router.history.current.name === "playerTournamentBracket" ||
        this.$router.history.current.name === 'playerStagesList',
      'profile-page-wrapper':
        this.$router.history.current.path.includes('profiles') ||
        this.$router.history.current.path.includes('clans') ||
        this.$router.history.current.path.includes('organizations'),

      'content-outer-wrap':
        this.$router.history.current.name === 'billing' ||
        this.$router.history.current.path.includes('battles'),

      'notifications-class':
        this.$router.history.current.name === 'notifications',
    }},[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }