import ProfileSetup from '@/views/profileSetup';
import { PROFILE_SETUP } from '@/constants/router';

export default [
  {
    path: 'profile-setup/:step?',
    name: PROFILE_SETUP,
    component: ProfileSetup,
    meta: {
      statusbarControlsIsHidden: true,
    },
  },
];
