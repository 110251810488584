<template>
  <h4 class="subheader" :class="[`hera-subheader--${variant}`]">
    <TextBlock
      v-bind="{
        size: size || 1.125,
        variant: isSecondary ? 'secondary' : isAccent ? 'accent' : 'primary',
        lineHeight: size * 1.3 || 1.5,
        text,
        tag,
        ...textBlockProps,
      }"
    >
      <slot />
    </TextBlock>
  </h4>
</template>

<script>
import TextBlock from '@/components/atoms/TextBlock';
import { prop } from '@/utils/factories';

export default {
  name: 'Subheader',
  components: {
    TextBlock,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      validator(val) {
        return (
          [
            'strong',
            'em',
            'normal',
            'muted',
            'secondary',
            'hr-secondary',
          ].indexOf(val) !== -1
        );
      },
      default: 'normal',
    },
    textBlockProps: prop(Object, null),
  },
  computed: {
    // fail. Replace it!
    tag() {
      return this.variant === 'normal' ||
        this.variant === 'secondary' ||
        this.variant === 'hr-secondary' ||
        this.variant === 'muted'
        ? 'span'
        : this.variant;
    },
    isSecondary() {
      return this.variant === 'hr-secondary';
    },
    isAccent() {
      return this.variant === 'accent';
    },
  },
};
</script>

<style lang="scss">
.subheader {
  margin-bottom: $ph-medium-space;
  &--strong {
    * {
      font-weight: bold;
    }
    // fill styles for strong variant of the Title here
  }
  &--em {
    // fill styles for em variant of the Title here
  }
  &--normal {
    // fill styles for normal variant of the Title here
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/Text"
  name="Subheader"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    text: {
      default: text('Inner text', 'I am a text in the subheader ;)')
    },
    variant: {
      default: select('variant', ['strong', 'em', 'normal', 'muted', 'secondary','hr-secondary'], 'normal')
    }
  }"
>
  <Subheader v-bind="{text, variant}"/>
</story>
