<template>
  <div class="main-nav secondary-font" :class="{ collapsed }">
    <ul class="list-unstyled px-0 font-size-xs">
      <li
        v-for="({
          name,
          children,
          params = {},
          meta: {
            icon,
            menuGroup,
            disabled,
            titleKey = ' ',
            fallbackLabel = '_web_',
            badgeWatcher,
          },
        },
        index) in navLinks"
        :key="index"
      >
        <ul
          v-if="menuGroup"
          class="group list-unstyled px-0 font-size-xs"
          :class="{ expanded: expandedMenuItems.includes(menuGroup) }"
        >
          <li
            class="group-parent d-flex flex-nowrap text-decoration-none mb-4"
            @click="toggleExpand(menuGroup)"
          >
            <a
              class="d-flex flex-nowrap text-decoration-none"
              :class="{
                'router-link-active-secondary': flattenSubMenuItems(
                  children
                ).find(c => isActiveLink(c.name)),
              }"
            >
              <div class="text-center link-icon">
                <AppIcon
                  v-if="icon"
                  :name="icon"
                  width="20"
                  fill="currentColor"
                />
              </div>
              <div
                class="text-truncate h-text-left link-text"
                :class="{ visible: !collapsed }"
              >
                {{ $t(titleKey, fallbackLabel) }}
              </div>
              <AppIcon
                class="h-mr-5"
                :rotate="expandedMenuItems.includes(menuGroup) ? 0 : -180"
                name="arrow-s"
                width="12"
                fill="currentColor"
              />
            </a>
          </li>
          <li
            v-for="(subItem, subItemIndex) in flattenSubMenuItems(children)"
            :key="`${index}_${subItemIndex}`"
          >
            <router-link
              :to="{ name: subItem.name, params: subItem.params }"
              class="d-flex flex-nowrap text-decoration-none mb-4"
              :class="{
                group: menuGroup,
                disabled: subItem.meta.disabled,
                'router-link-active-secondary': isActiveLink(subItem.name),
              }"
            >
              <div class="text-center link-icon">
                <AppIcon :name="subItem.meta.icon" width="20" />
              </div>
              <div
                class="text-truncate h-text-left link-text"
                :class="{ visible: !collapsed }"
              >
                {{ $t(subItem.meta.titleKey, subItem.meta.fallbackLabel) }}
              </div>
              <div v-if="badgeContent({ badgeWatcher, name: subItem.name })">
                <Badge class="mx-2">
                  {{ badgeContent({ badgeWatcher, name: subItem.name }) }}
                </Badge>
              </div>
            </router-link>
          </li>
        </ul>
        <router-link
          v-else
          :to="{ name, params }"
          class="d-flex flex-nowrap text-decoration-none mb-4"
          :class="{
            group: menuGroup,
            disabled,
            'router-link-active-secondary': isActiveLink(name),
          }"
        >
          <div class="text-center link-icon">
            <AppIcon v-if="icon" :name="icon" width="20" fill="currentColor" />
          </div>
          <div
            class="text-truncate h-text-left link-text"
            :class="{ visible: !collapsed }"
          >
            {{ $t(titleKey, fallbackLabel) }}
          </div>
          <div v-if="badgeContent({ badgeWatcher, name })">
            <Badge class="mx-2">
              {{ badgeContent({ badgeWatcher, name }) }}
            </Badge>
          </div>
        </router-link>
      </li>
    </ul>
    <ul class="list-unstyled px-0 font-size-xs">
      <li>
        <a
          v-if="marketplaceLink"
          :href="marketplaceLink"
          target="_blank"
          class="secondary-font d-flex flex-nowrap text-decoration-none mb-4"
        >
          <div class="text-center link-icon">
            <AppIcon :name="'sidebar/cart'" width="20" fill="white" />
          </div>
          <div class="link-text" :class="{ visible: !collapsed }">
            {{ $t('_web_left-sidebar_nav-marketplace-link', 'Marketplace') }}
          </div>
        </a>
      </li>
      <!--      <li>
        <a
          v-if="phMaxLink"
          :href="phMaxLink"
          class="secondary-font d-flex flex-nowrap text-decoration-none mb-4"
        >
          <div class="text-center link-icon">
            <AppIcon :name="'sidebar/phmax'" width="20" fill="white" />
          </div>
          <div
            class="link-text"
            :class="{ visible: !collapsed }"
            style="
    white-space: nowrap;
"
          >
            {{ $t('_web_left-sidebar_nav-ph-max-link', 'PLAYHERA MAX') }}
          </div>
        </a>
      </li>-->
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getMetaValue } from '@/views/tournaments/utils/meta';
import { MAIN_COMPETITIONS } from '@/constants/router/mains';

import AppIcon from '@/components/atoms/Icon.vue';
import Badge from '@/components/atoms/HeraBadge';

export default {
  name: 'SideNav',
  components: {
    AppIcon,
    Badge,
  },
  props: {
    collapsed: Boolean,
  },
  data() {
    return {
      expandedMenuItems: [MAIN_COMPETITIONS],
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId']),
    ...mapGetters([
      'unreadConversations',
      'hasAccessByPermission',
      'marketplaceLink',
      'phMaxLink',
    ]),
    /*mainProfileLink() {
      const { isOrganization } = this.account;
      return {
        meta: {
          fallbackLabel: isOrganization ? 'Organization' : 'Profile',
          icon: isOrganization ? 'bag' : 'profile',
          titleKey: isOrganization
            ? '_web_left-sidebar_nav-organization-link'
            : '_web_left-sidebar_nav-profile-link',
        },
        name: this.$routerConstants.ACCOUNT,
        params: {
          accountType: this.account._type,
          accountId: this.accountId,
        },
      };
    },*/
    navLinks() {
      return this.$lodash.sortBy(
        this.$router.options.routes.reduce(this.reduceNav, []),
        ({ meta: { inMainNavOrg, inMainNav } }) =>
          this.account && this.account.isOrganization ? inMainNavOrg : inMainNav
      );
    },
  },
  mounted() {
    // var self = this;
    // setExpandedMenu();
    // function setExpandedMenu() {
    //   let routeName = self.$route.name;
    //   if (routeName === null) {
    //     setTimeout(() => {
    //       setExpandedMenu();
    //     }, 10);
    //     return;
    //   }
    //   let items = self.navLinks;
    //   items.forEach(element => {
    //     if (
    //       element.children &&
    //       self
    //         .flattenSubMenuItems(element.children)
    //         .find(c => self.isActiveLink(c.name))
    //     ) {
    //       self.toggleExpand(element.name);
    //     }
    //   });
    // }
  },
  methods: {
    ...mapActions(['logout', 'errorNotify']),
    checkPermission({ permission = false }) {
      return !permission || this.hasAccessByPermission(permission);
    },
    checkInMainNav({
      inMainNavOrg = false,
      inMainNav = false,
      guestEnabled = false,
    }) {
      if (this.account) {
        return this.hasAccessByPermission() || this.account.isOrganization
          ? inMainNavOrg
          : inMainNav;
      } else {
        return guestEnabled && inMainNav;
      }
    },
    reduceNav(navLinks, path) {
      const obj = this.$lodash.cloneDeep(path);
      const { meta, children } = obj;

      if (Array.isArray(children)) {
        children.reduce(this.reduceNav, navLinks);
      }

      if (
        !this.$lodash.isEmpty(meta) &&
        this.checkInMainNav(meta) !== false // checkInMainNav return index or false
      ) {
        obj.meta.disabled = !this.checkPermission(meta) || meta.disabled;
        navLinks.push(obj);
      }

      return navLinks;
    },
    badgeContent({ badgeWatcher, name }) {
      return badgeWatcher ? this[badgeWatcher || name] || 0 : 0;
    },
    isActiveLink(name) {
      const sidebarParent = getMetaValue(this.$route, 'sidebarParent');

      if (sidebarParent) {
        return sidebarParent === name;
      }

      const getSidebarParent = getMetaValue(this.$route, 'getSidebarParent');

      if (getSidebarParent) {
        return getSidebarParent(name);
      }

      return false;
    },
    isGroupActive(children) {
      return children.find(c => this.isActiveLink(c.name));
    },
    toggleExpand(name) {
      if (this.expandedMenuItems.includes(name)) {
        this.expandedMenuItems = this.expandedMenuItems.filter(
          item => item !== name
        );
      } else {
        this.expandedMenuItems.push(name);
      }
    },
    flattenSubMenuItems(routes) {
      let result = routes.reduce(
        (acc, item) => [
          ...acc,
          ...(item.children
            ? item.children.filter(c => {
                return this.showRouteSubItem(c);
              })
            : this.showRouteSubItem(item)
            ? [item]
            : []),
        ],
        []
      );
      return result;
    },
    showRouteSubItem(route) {
      return (
        route.meta &&
        route.meta.includeInParentMenu &&
        (this.account && this.account.isOrganization
          ? !route.meta.hideFromOrg
          : !route.meta.hideFromUser)
      );
    },
  },
};
</script>
<style lang="scss">
.main-nav {
  &.collapsed {
    a.big-link {
      margin: auto;
      height: auto;
      padding: auto;
      border: none;

      .link-text {
        opacity: 0;
        height: 0;
        pointer-events: none;
      }
    }
  }

  li {
    > a {
      position: relative;
      color: $ph-primary-text;
      line-height: $ph-h1-line-height;
      flex-grow: 1;
      &:hover {
        background-color: rgba($ph-light-gray, 0.05);

        .link-icon {
          .playhera-icon {
            background: var(--ph-hover);
          }
        }
      }
      &.router-link-active,
      &.router-link-active-secondary {
        font-weight: bold;
        // color: $ph-primary-text;
        .link-icon {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 4px;
            height: 100%;
            background: var(--ph-orange);
            @include auto-rtl(left);
            @include auto-rtl(
              border-radius,
              0px $ph-round-radius $ph-round-radius 0px
            );
          }

          .playhera-icon {
            background: var(--ph-orange);

            svg {
              --fill: white !important;
            }
          }
        }
      }
      .link-icon {
        width: $navbar-width-collapse;
        transition: margin 0.3s linear;

        .playhera-icon {
          background: var(--ph-card);
          transition: background-color 0.1s linear;
          padding: 0.5rem;
          border-radius: 8px;

          svg {
            --fill: var(--ph-secondary-text) !important;
          }
        }
      }
      .link-text {
        width: calc(100% - (#{$navbar-width-collapse}));
        opacity: 0;
        transition: opacity 0.3s linear;
        font-weight: bold;
        color: var(--ph-primary-text);
        &.visible {
          opacity: 1;
        }
      }
    }
  }
  li.group-parent {
    cursor: pointer;

    .link-icon {
      &::after {
        background: transparent !important;
      }
    }
    .link-text {
      width: unset;
      flex-grow: 1;
    }
  }
  ul.group:not(.expanded) {
    li {
      display: none;
    }
  }
  &:not(.collapsed) {
    .group {
      li:not(.group-parent) .link-icon {
        @include auto-rtl(margin-left, 2rem);
      }
    }
  }
}
</style>
