<template>
  <div>
    <button class="btn btn-outline-secondary" type="submit">
      <AppIcon
        name="editable/submit"
        width="24"
        original
        class="editable-submit-icon"
      />
    </button>
  </div>
</template>

<script>
import AppIcon from '@/components/atoms/Icon';

export default {
  name: 'EditableSubmit',
  components: {
    AppIcon,
  },
};
</script>

<style lang="scss">
// todo - TH-7106 template fix
.editable-submit-icon {
  g {
    opacity: 0.5;
    .btn:hover & {
      opacity: 0.8;
    }
  }
}
</style>
