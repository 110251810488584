var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-nowrap overflow-auto custom-scrollbar no-gutters pb-2"},_vm._l((_vm.stageTypes),function(item){return _c('div',{key:item.key,staticClass:"d-flex justify-content-center h-mr-5 stage-type-wrapper"},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-start text-center stage-simple-form-card w-100 p-3",class:{
          'cursor-pointer': item.isEnabled && !_vm.disabled,
          active: _vm.blockType === item.type,
          disabled: _vm.isStageFieldDisabled('stageType') || _vm.disabled,
        },on:{"click":function($event){return _vm.stageTypeClickHandler(item)}}},[_c('Icon',{staticClass:"stage-simple-form-card-icon mb-3",attrs:{"name":item.key,"fill":_vm.errors.first('stage-type')
              ? _vm.$scss.dangerColor
              : _vm.blockType === item.type
              ? _vm.$scss.textColor
              : _vm.$scss.heraMuted,"width":"32"}}),_c('TextBlock',{staticClass:"stage-simple-form-card-text text-uppercase text-center",attrs:{"variant":_vm.errors.first('stage-type')
              ? 'error'
              : _vm.blockType === item.type
              ? 'primary'
              : 'secondary',"size":0.625,"tag":"div"}},[_c('strong',[_vm._v("\n            "+_vm._s(_vm.$$t(item.name, item.key))+"\n          ")])])],1),_c('TextBlock',{staticClass:"text-center",attrs:{"variant":"secondary","text":item.description,"size":0.625,"tag":"div"}})],1)}),0),(_vm.errors.first('stage-type'))?_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col"},[_c('TextBlock',{staticClass:"col-12 d-block mt-1",attrs:{"size":0.75,"variant":"error"}},[_vm._v("\n        "+_vm._s(_vm.errors.first('stage-type'))+"\n      ")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }