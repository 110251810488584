<template>
  <div class="all-communications">
    <ContentLayout>
      <div class="col-12 d-none d-md-flex justify-content-between my-4">
        <Subheader class="col-md-4 pt-2 mb-0 header-chat-title">{{
          $t('_android_chats', 'Chats')
        }}</Subheader>
        <ChatCreate class="align-self-center" />
      </div>
      <div class="pb-0 flex-grow-0 flex-wrap chat-body">
        <div
          class="col-md-4 h-100 d-md-flex flex-column"
          :class="{
            'd-none': conversationId,
            'd-flex': !conversationId,
          }"
        >
          <Panel class="flex-grow-1 overflow-hidden p-4 position-initial-panel">
            <div
              class="d-md-none justify-content-between mb-4"
              :class="{
                'd-none': conversationId,
                'd-flex': !conversationId,
              }"
            >
              <h4 class="all-comms-text header-chat-title">
                {{ $t('_android_chats', 'Chats') }}
              </h4>
              <ChatCreate class="align-self-center" />
            </div>
            <div class="search-input">
              <ConversationSearch is-visible @change="getSearchValue" />
            </div>
            <div class="chats-container">
              <ChatsList
                :key="chatListKey"
                class="chat-list
               oveflow-hidden"
                has-search
                :search-value="searchValue"
              />
            </div>
          </Panel>
        </div>
        <div
          class="col-md-8 flex-grow-1 h-100"
          :class="{
            'd-none d-md-block': !conversationId,
            'd-block': conversationId,
          }"
        >
          <Panel
            :variant="[]"
            class="h-100 p-4 position-relative overflow-hidden"
          >
            <ChatDialog class="h-100 overflow-hidden" />
          </Panel>
        </div>
      </div>
    </ContentLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentLayout from '@/views/ContentCard';
import ChatsList from './list';
import ChatDialog from './correspondence';
import ChatCreate from './create';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
import ConversationSearch from './search';

export default {
  name: 'ConversationScreen',
  components: {
    Panel,
    ChatsList,
    ChatDialog,
    ChatCreate,
    ContentLayout,
    Subheader,
    ConversationSearch,
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    ...mapGetters(['chatListKey']),
    conversationId() {
      return this.$route.params.id;
    },
  },

  methods: {
    getSearchValue(value) {
      this.searchValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.all-communications {
  &_title {
    line-height: 1;
    * {
      line-height: 1 !important;
    }
  }
  .chat-body {
    display: flex;
    height: 100%;
    @media (max-width: 800px) {
      height: calc(100vh - 230px);
    }

    @media (max-width: 768px) {
      height: calc(100vh - 150px);
      // margin-top: 23px;
    }
    @media (max-width: 500px) {
      height: calc(96dvh - 120px);
      margin-top: 23px;
    }

    height: calc(100vh - 205px);
  }

  .chat-list {
    height: 100% !important;
    overflow: auto !important;
    @media (max-width: 768px) {
      height: calc(100% - 60px) !important;
    }
  }

  .all-comms-text {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 8px 0px 0px 15px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.search-input {
  margin-top: 17px;
  margin-bottom: 10px;
}
</style>

<style lang="scss">
.position-initial-panel {
  position: initial !important;
  // overflow: visible !important;
}
.chats-container {
  height: 90%;
  @media (max-width: 768px) {
    height: 100%;
    height: calc(100dvh - 13.9rem);
  }
}
.header-chat-title {
  span {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 768px) {
  .position-initial-panel {
    background: none !important;
  }
}
</style>
