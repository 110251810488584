import api from '@/api';

export const INITIAL_MAP_STATE = () => {
  return {
    mapQueryParams: {},
    myPosition: null,
  };
};

const getters = {
  mapQueryParams(state) {
    return {
      ...state.mapQueryParams,
    };
  },
  myPosition(state) {
    return {
      ...state.myPosition,
    };
  },
};

const mutations = {
  touchMap(state) {
    state.mapQueryParams = { ...state.mapQueryParams };
  },
  setMapQuery(state, query) {
    state.mapQueryParams = { ...state.mapQueryParams, ...query };
  },
  setMyPosition(state, data) {
    state.myPosition = {
      lat: +data.lat,
      lng: +data.lng,
    };
  },
};

const actions = {
  /* eslint-disable */
  createPin(state, url) {
    if (mapPins.has(url)) {
      return new Promise(resolve => {
        resolve(mapPins.get(url));
      });
    }

    return new Promise(resolve => {
      fetch(url)
        .then(response => response.blob())
        .then(
          blob =>
            new Promise((res, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => res(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        )
        .then(result => {
          let instance = new ComponentPin({
            propsData: {
              src: result,
            },
          });

          instance.$mount();

          let xml = new XMLSerializer();
          let str = xml.serializeToString(instance.$el);
          let res =
            'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(str);

          mapPins.set(url, res);

          resolve(res);
        });
    });
  },
  /* eslint-enable */
  fetchMyPosition({ commit }) {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const myPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          commit('setMyPosition', myPosition);
          resolve(myPosition);
        });
      } else {
        reject('User location is undefined');
      }
    });
  },
  fetchMap({ getters }) {
    return new Promise((resolve, reject) => {
      const params = getters.mapQueryParams;
      api.maps
        .getMapMarkers(getters.accessToken, { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  mapUpdateMyPosition({ getters }, params) {
    return new Promise((resolve, reject) => {
      api.maps
        .setMyPosition(getters.accessToken, params)
        .then(resolve)
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state: INITIAL_MAP_STATE(),
  getters,
  mutations,
  actions,
};
