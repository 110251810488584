const state = {
  corelation: '',
};

const getters = {
  corelation: state => state.corelation,
};

const mutations = {
  setCorelation(state, corelation) {
    state.corelation = corelation;
  },
};

const actions = {
  setCorelation({ commit }, corelation) {
    commit('setCorelation', corelation);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
