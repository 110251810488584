<template>
  <div>
    <h1>404</h1>
    <p>
      {{
        $t(
          '_web_error_page_message',
          "The page you're looking for could not be found."
        )
      }}
    </p>
    <router-link to="/">{{
      $t('_web_error_page_message_link', 'Go back to the home page')
    }}</router-link>
  </div>
</template>
<script>
export default {
  name: 'Error404',
};
</script>

<style lang="scss" scoped>
.error-page {
  text-align: center;
  margin-top: 50px;
}

h1 {
  font-size: 8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

p {
  font-size: 2rem;
  margin-bottom: 20px;
}
</style>
