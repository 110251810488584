var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-loader",class:{
    'content-blur': _vm.isCrashed || _vm.isLoading,
    'block-loading': !_vm.isCrashed && _vm.isLoading,
    'list-loader-shop': this.$router.history.current.name === 'shop',
  }},[(!_vm.hasList)?_c('div',{staticClass:"d-flex flex-column justify-content-center blur-none list-loader_wrapper",class:{ 'list-loader_wrapper--backdrop': _vm.hasBackdrop }},[(_vm.isCrashed)?_c('div',{staticClass:"d-flex align-self-center block-loading-crashed"},[_c('HeraFormBtn',{nativeOn:{"click":function($event){return _vm.$emit('reload')}}},[_vm._v("\n        "+_vm._s(_vm.$t('_web_list-preloader_btn-repeat', 'Try Again'))+"\n      ")])],1):(!_vm.isLoading && _vm.isEmptyList)?_c('HeraDummyList',_vm._b({},'HeraDummyList',Object.assign({}, {iconName: _vm.emptyListIcon,
        size: _vm.dummyProps.emptyListIconSize || _vm.emptyListIconSize,
        position: 'center',
        text: _vm.emptyListLabel},
        _vm.dummyProps),false)):_vm._e()],1):_vm._e(),[_vm._t("list")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }