<template>
  <div class="slider-chat-ps-list">
    <ListHeader
      :label="listLabel"
      :active="isActive"
      :loading="isLoading"
      @openToggle="toggleHandler"
      @addAll="addAll"
    />
    <ListPreloader :is-loading="isAdding" class="position-absolute" />
    <transition name="slider-chat-list-transition" :duration="5000">
      <ul v-if="isActive" class="slider-chat-ps-list__list">
        <ListItemAccount
          v-for="item in list"
          :key="item.id"
          class="mb-2"
          tag="li"
          :type="$routerConstants.PROFILE"
          :secondary-model="item"
        >
          <Icon
            slot="action"
            class="cursor-pointer h-mr-1"
            :name="isSelected(item) ? 'done' : 'add'"
            :width="18"
            @click="$emit('addParticipant', item)"
          />
        </ListItemAccount>
        <div
          ref="trigger"
          class="position-relative slider-chat-ps-list_loader--fixed-height"
          :class="{
            'd-none': !isLoading && list.length,
            'is-dummy': !list.length,
          }"
        >
          <ListPreloader
            v-bind="{ isLoading }"
            :is-empty-list="!list.length && !isLoading"
            :dummy-props="{
              iconName: 'dummy/members',
              size: 64,
              subheaderText: $t(
                '_web_tournament_sidechat_participants-empty-text',
                'You have no participants yet'
              ),
              text: $t(
                '_web_tournament_sidechat_participants-empty-text_subheader',
                'When participants register or accept your invitation they will appear here'
              ),
            }"
          />
        </div>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { debounce, uniqBy } from '@/utils/lodashUtils';
import flip from '@/directives/flip';
import ListPreloader from '@/components/common/HeraListPreloader';
import ListHeader from './SelectorListHeader';
import {
  ATTRIBUTES,
  QUALIFIERS,
  REGISTRANTS,
  RESERVED,
} from '@/constants/categoryParticipants';
import ListItemAccount from '@/components/common/ListItemAccount';
import Icon from '@/components/atoms/Icon';

export default {
  components: { Icon, ListItemAccount, ListHeader, ListPreloader },
  directives: {
    flip,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    attributeId: {
      type: String,
      default: '',
    },
    attributeName: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      required: true,
    },
    selectedParticipants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      nextPageToken: undefined,
      isLoading: false,
      isAdding: false,
      isActive: false,
      debouncedReloadList: function() {},
    };
  },
  computed: {
    listLabel() {
      let result;
      switch (this.category) {
        case REGISTRANTS:
          result = this.$t(
            '_web_tournament_sidechat_registrants',
            'Registrants'
          );
          break;
        case QUALIFIERS:
          result = this.$t('_web_tournament_sidechat_qualifiers', 'Qualifiers');
          break;
        case RESERVED:
          result = this.$t('_web_tournament_sidechat_reserved', 'Reserved');
          break;
        case ATTRIBUTES:
          result = this.attributeName;
          break;
      }
      return result;
    },
    tournamentID() {
      return this.$route.params.tournamentID;
    },
    participantsFilter() {
      return {
        Category: this.category,
        AttributeValue: this.category === ATTRIBUTES ? this.attributeId : null,
        Contains: this.searchQuery,
      };
    },
  },
  watch: {
    searchQuery() {
      this.debouncedReloadList();
    },
  },
  created() {
    const searchReloadDelay = 250;
    this.debouncedReloadList = debounce(this.reloadList, searchReloadDelay);
  },
  mounted() {
    this.$el.parentNode.addEventListener('scroll', this.scrollHandler);
  },
  methods: {
    ...mapActions([
      'fetchCurrentChatPossibleParticipants',
      'updateConversation',
      'addAllPossibleParticipantsToCurrentChat',
      'errorNotify',
    ]),
    async fetchAllPagesOfParticipants() {
      let participantsArray = [];

      while (this.nextPageToken !== null) {
        await this.fetchCurrentChatPossibleParticipants({
          page_size: 50,
          page: this.nextPageToken,
          ...this.participantsFilter,
        }).then(res => {
          this.nextPageToken = res.next_page_token;
          participantsArray = [...participantsArray, ...res.items];
        });
      }

      this.isLoading = false;
      return participantsArray;
    },
    fetchList() {
      if (this.isLoading || !this.isActive) return;
      this.isLoading = true;
      this.fetchAllPagesOfParticipants().then(res => {
        this.list = uniqBy(
          [...this.list, ...res.map(item => ({ ...item, id: item.login }))],
          'id'
        );
      });
    },
    reloadList() {
      this.nextPageToken = undefined;
      this.list = [];
      this.fetchList();
    },
    toggleHandler() {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
        this.reloadList();
      }
    },
    scrollHandler() {
      const can = this.isActive && !this.isLoading && this.nextPageToken;
      if (!can) return;
      const trigger = this.$refs.trigger.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const activationGap = 200;
      if (trigger.top - activationGap <= windowHeight) {
        this.fetchList();
      }
    },
    addAll() {
      this.isAdding = true;

      this.addAllPossibleParticipantsToCurrentChat(this.participantsFilter)
        .catch(this.errorNotify)
        .finally(() => {
          if (this.list.length) {
            const allParticipants = uniqBy(
              [...this.selectedParticipants, ...this.list],
              'id'
            );
            this.$emit('updateParticipants', allParticipants);
            this.isAdding = false;
          } else {
            this.fetchAllPagesOfParticipants().then(res => {
              const allParticipants = uniqBy(
                [
                  ...this.selectedParticipants,
                  ...res.map(item => {
                    item.id = item.login;
                    return item;
                  }),
                ],
                'id'
              );
              this.$emit('updateParticipants', allParticipants);
              this.isAdding = false;
            });
          }
        });
    },
    isSelected({ id: itemId }) {
      return this.selectedParticipants.find(({ id }) => id === itemId);
    },
  },
};
</script>

<style lang="scss">
.slider-chat-ps-list {
  margin-bottom: 0.75rem;

  &__list {
    list-style: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: $ph-card;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &_loader--fixed-height {
    height: 3rem;
    &.is-dummy {
      height: 16rem;
      .container-dummy {
        margin-top: -1.5rem;
      }
    }
  }

  &__placeholder {
    margin: 0;
    text-align: center;
    line-height: 2rem;
  }
}

.slider-chat-list-transition {
  &-enter-active,
  &-leave-active {
    overflow: hidden;
    transition: all 0.3s;
  }
  &-enter,
  &-leave-to {
    height: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
</style>
