<template>
  <Lottie
    class="loader"
    :options="loaderData"
    :width="size"
    :height="size"
    @animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from '@/components/common/lottie';
import * as animationData from '@/assets/json/logo-animated.json';

export default {
  name: 'Loader',
  components: {
    Lottie,
  },
  props: {
    size: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      anim: null,
      loaderData: {
        animationData: animationData.default,
      },
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
  },
};
</script>
