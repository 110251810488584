<template>
  <Panel class="stage-simple-form">
    <Subheader
      class="d-block mt-0"
      :text="$$t('Tournament type', 'section-title')"
    />

    <StageSettingStageType :disabled="safeMode || isAllDisabled" class="mb-6" />

    <div class="row">
      <div class="col">
        <div class="row">
          <div
            v-if="
              isSingleElimination ||
                isDoubleElimination ||
                isSwiss ||
                isRoundRobin
            "
            class="col-auto"
          >
            <StageSettingBestOf
              v-if="
                isSingleElimination ||
                  isDoubleElimination ||
                  isSwiss ||
                  isRoundRobin
              "
              class="mb-5"
              :disabled="safeMode || isAllDisabled"
            />
            <StageSettingBestOfLower
              v-if="isDoubleElimination"
              :disabled="safeMode || isAllDisabled"
            />
          </div>
          <div
            v-if="isSingleElimination || isDoubleElimination"
            class="col-auto d-flex"
            :class="{
              'align-items-center': isDoubleElimination,
            }"
          >
            <StageSettingBestOfFinal :disabled="safeMode || isAllDisabled" />
          </div>

          <div v-if="isSingleElimination" class="col-auto d-flex">
            <StageSettingBestOfThird
              v-if="thirdPlaceMatchAllowed"
              :disabled="safeMode || isAllDisabled"
            />
          </div>
          <StageSettingRounds
            v-if="isSwiss || isBattleRoyale || isRoundRobin"
            :disabled="safeMode || isAllDisabled"
            class="col-1"
          />
          <StageSettingPointsPerKill
            v-if="isBattleRoyale"
            :disabled="safeMode || isAllDisabled"
            class="col-auto"
          />
        </div>
        <div class="row mb-5">
          <StageSetting3rdPlaceMatch
            v-if="isSingleElimination"
            :disabled="safeMode || isAllDisabled"
            class="col-3 d-flex"
          />
          <template v-if="isSwiss || isRoundRobin">
            <StageSettingTieMatchAllowed
              :disabled="safeMode || isAllDisabled"
              class="col-2"
            />
            <StageSettingTieGameAllowed
              :disabled="safeMode || isAllDisabled"
              class="col-3"
            />
          </template>
        </div>
        <template v-if="isSwiss || isRoundRobin">
          <div class="row mb-5">
            <StageSettingPointsForWin
              :disabled="safeMode || isAllDisabled || !tieMatchAllowed"
              class="col-auto"
            />

            <StageSettingPointsForTie
              :disabled="safeMode || isAllDisabled || !tieMatchAllowed"
              class="col-auto"
            />

            <StageSettingPointsForLose
              :disabled="safeMode || isAllDisabled || !tieMatchAllowed"
              class="col-auto"
            />
          </div>
        </template>
        <div v-if="isBattleRoyale" class="row mt-5">
          <div class="col-12">
            <TieBreakerRules
              v-model="tieBreakerPreset"
              :safe-mode="safeMode"
              :class="{
                disabled:
                  isStageFieldDisabled('tieBreakerRules') ||
                  safeMode ||
                  isAllDisabled,
              }"
            />
          </div>
        </div>
        <div v-if="isBattleRoyale" class="row">
          <div class="col-3">
            <TableOfPoints
              v-model="pointsPerPlace"
              :read-only="safeMode"
              :class="{
                disabled:
                  isStageFieldDisabled('pointsTable') ||
                  safeMode ||
                  isAllDisabled,
              }"
            />
          </div>
        </div>
        <div class="row mt-5">
          <StageSettingFullDoubleElimination
            v-if="isDoubleElimination"
            :disabled="safeMode || isAllDisabled"
            class="col-3 d-flex"
          />
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { BLOCK_TYPES, STAGE_TYPES, TIE_GAMES } from '@/constants/tournaments';
import { translate } from '@/mixins/translate';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
import TableOfPoints from './TableOfPoints';
import TieBreakerRules from './TieBreakerRules';
import StageSettingStageType from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageType';
import StageSettingRounds from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageRounds';
import StageSettingPointsPerKill from '@/views/tournaments/management/singleTournament/stages/components/SettingsStagePointsPerKill';
import StageSettingPointsForWin from '@/views/tournaments/management/singleTournament/stages/components/SettingsStagePointsForWin';
import StageSettingPointsForTie from '@/views/tournaments/management/singleTournament/stages/components/SettingsStagePointsForTie';
import StageSettingPointsForLose from '@/views/tournaments/management/singleTournament/stages/components/SettingsStagePointsForLose';
// import SwissCalculator from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageSwissCalculatorHelper';
import StageSettingBestOf from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageBestOf';
import StageSettingBestOfLower from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageBestOfLower';
import StageSettingBestOfFinal from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageBestOfFinal';
import StageSettingBestOfThird from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageBestOfThird';
import StageSetting3rdPlaceMatch from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageThirdPlaceMatch';
import StageSettingTieMatchAllowed from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageTieMatchAllowed';
import StageSettingTieGameAllowed from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageTieGameAllowed';
import StageSettingFullDoubleElimination from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageFullDoubleElimination';

export default {
  name: 'StageSectionBlockTypePicker',
  components: {
    Panel,
    Subheader,
    TableOfPoints,
    TieBreakerRules,
    StageSettingStageType,
    StageSettingBestOf,
    StageSettingBestOfLower,
    StageSettingBestOfFinal,
    StageSettingBestOfThird,
    StageSettingRounds,
    StageSettingPointsPerKill,
    StageSettingPointsForWin,
    StageSettingPointsForTie,
    StageSettingPointsForLose,
    StageSetting3rdPlaceMatch,
    StageSettingTieMatchAllowed,
    StageSettingTieGameAllowed,
    // SwissCalculator,
    StageSettingFullDoubleElimination,
  },
  mixins: [translate('_web_tournament_stage-editor')],
  inject: ['$validator'],
  data() {
    return {
      STAGE_TYPES,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournamentStage',
      'isSingleStage',
      'isSingleBlock',
      'isStageFieldDisabled',
    ]),
    tieMatchAllowed() {
      return this.$lodash.get(
        this.currentTournamentStage,
        `block_configuration.tie_match`,
        false
      );
    },
    thirdPlaceMatchAllowed() {
      return this.$lodash.get(
        this.currentTournamentStage,
        `block_configuration.match_3rd_place`,
        false
      );
    },
    blockType() {
      return this.$lodash.get(
        this.currentTournamentStage,
        `block_configuration.type`,
        ''
      );
    },
    safeMode() {
      return Boolean(this.$route.meta.safeMode);
    },
    isAllDisabled() {
      return (
        this.$route.name === 'editSingleStage' &&
        !(this.isSingleStage && this.isSingleBlock)
      );
    },
    isRoundRobin() {
      return this.blockType === BLOCK_TYPES.RR;
    },
    isSingleElimination() {
      return this.blockType === BLOCK_TYPES.SINGLE;
    },
    isDoubleElimination() {
      return this.blockType === BLOCK_TYPES.DOUBLE;
    },
    isSwiss() {
      return this.blockType === BLOCK_TYPES.SWISS;
    },
    isBattleRoyale() {
      return this.blockType === BLOCK_TYPES.BATTLE_ROYALE;
    },
    pointsPerPlace: {
      get() {
        return this.getBlockConfigurationByKey(
          'points_per_place',
          Array.from({ length: 10 }, (v, i) => 10 - i)
        );
      },
      set(newVal) {
        this.setBlockConfigurationByKey('points_per_place', newVal);
      },
    },
    tieBreakerPreset: {
      get() {
        return this.getBlockConfigurationByKey(
          'tie_breaker_preset',
          TIE_GAMES.PUBG
        );
      },
      set(newVal) {
        this.setBlockConfigurationByKey('tie_breaker_preset', newVal);
      },
    },
  },
  methods: {
    ...mapMutations(['updateCurrentTournamentStage']),
    getBlockConfigurationByKey(key, defaultVal = null) {
      const currentValue = this.$lodash.get(
        this.currentTournamentStage,
        `block_configuration.${key}`,
        null
      );

      if (defaultVal !== null && currentValue === null) {
        this.setBlockConfigurationByKey(key, defaultVal);
        return defaultVal;
      }

      return currentValue;
    },
    setBlockConfigurationByKey(key, val) {
      const configuration =
        this.$lodash.cloneDeep(
          this.currentTournamentStage.block_configuration
        ) || {};
      configuration[key] = val;
      this.updateCurrentTournamentStage({
        block_configuration: configuration,
      });
    },
  },
};
</script>

<style lang="scss">
.stage-simple-form {
  .stage-type-wrapper {
    width: 6rem;
  }
  &-card-icon {
    min-height: 2rem;
  }
  &-card-text {
    font-size: $ph-caption-bold-font-size;
  }
  &-card.active {
    position: relative;
    &:before {
      $border: 0.187rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      height: $border;
      background-color: $ph-green;
      border-radius: $border $border 0 0;
    }
  }
  &-card.disabled {
    opacity: 0.65;
  }
  .form-radio--flat {
    flex-grow: 1;
    .custom-control-label {
      width: auto;
    }
  }
  .swiss-help-input,
  .swiss-help-input input {
    font-size: $ph-small-text-font-size !important;
  }
  // .match-duration-picker {
  //   .mx-datepicker {
  //     width: 100%;
  //   }
  // }
  .points-field-battle-royale,
  .tie-points-input {
    input {
      max-width: 55px;
    }
  }
}
</style>
