<template>
  <notifications
    group="system"
    class="system"
    position="bottom center"
    width="100%"
    :max="3"
  >
    <template slot="body" slot-scope="props">
      <div
        class="vue-notification row d-flex align-items-center"
        :class="[props.classes, props.item.type]"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="col-auto flex-grow-1 ml-0 pl-0 vue-notification-content"
          v-html="props.item.text"
        ></div>
        <!--eslint-enable-->
        <div class="close mr-0 pr-0" aria-label="Close" @click="props.close">
          <AppIcon name="close" width="10" original />
        </div>
        <div>{{ props.item.data }}</div>
      </div>
    </template>
  </notifications>
</template>

<script>
import AppIcon from '../atoms/Icon.vue';

export default {
  name: 'SystemNotifications',
  components: {
    AppIcon,
  },
};
</script>

<style lang="scss">
$transparency: 0.8;
.vue-notification-group {
  &.system {
    display: flex;
    align-items: center;
    justify-content: center;
    width: unset !important;
    left: 50% !important;
    transform: translateX(-50%);
    z-index: 10003;

    .vue-notification {
      padding: 0.75rem 1.5rem;
      margin: 0px 0px 1rem 0px;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #ffffff;
      background: rgba($ph-accent-raw, $transparency);
      border: none;
      border-radius: 4px;
      &.warn {
        background: rgba($warning, $transparency);
      }
      &.error {
        background: rgba($ph-error-raw, $transparency);
      }
      &.success {
        background: rgba($ph-green-raw, $transparency);
      }
      .close {
        cursor: pointer;
      }
      &-content {
        max-width: 520px;
      }
    }
  }
}
</style>
