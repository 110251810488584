<template>
  <div>
    <QRCodeComponent :options="qrFullOptions" hidden @qrReady="getQRSource" />
    <div class="d-flex">
      <ToolIcon
        icon-name="share"
        icon-size="18"
        class="h-mr-4"
        @click.native.prevent.stop="openLocalSharingMenu"
      />
      <ToolIcon
        icon-name="copy"
        icon-size="18"
        :help-text="
          $t(
            '_web_copy-tournament-link-tournament-help',
            'Copy tournament invitation link to clipboard'
          )
        "
        class="h-mr-4"
        @click.stop="getInviteLink"
      />
      <ToolIcon
        icon-name="qr-code"
        icon-size="18"
        :help-text="
          $t(
            '_web_qr-code-download-tournament-help',
            'Download QR-code for tournament invitation link'
          )
        "
        @click.stop="downloadQrCode"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { translate } from '@/mixins/translate';
import shareQrCodeMixin from '@/mixins/shareQrCode';
import { sharingMenu } from '@/mixins/sharingMenu';
import { shareLink } from '@/mixins/sharingLink';
import { TOURNAMENT } from '@/constants/accounts';
import QRCodeComponent from '@/components/common/QRCode';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';

export default {
  name: 'PromotionWidget',
  components: {
    QRCodeComponent,
    ToolIcon,
  },
  mixins: [
    ...shareQrCodeMixin,
    sharingMenu,
    shareLink,
    translate('_web_tournament_dashboard'),
  ],
  computed: {
    ...mapGetters(['currentTournament']),
  },
  watch: {
    'currentTournament.info': function() {
      this.setQrPayload();
    },
  },
  created() {
    if (this.currentTournament && this.currentTournament.info) {
      this.setQrPayload();
    }
  },
  methods: {
    ...mapActions(['errorNotify']),
    setQrPayload() {
      this.qrPayload = this.generateInviteLink();
    },
    generateInviteLink() {
      console.log(this.currentTournament);

      return this.getSharingLink({
        type: TOURNAMENT,
        id: this.currentTournament.id,
        vanityId: this.currentTournament.info.vanity_id,
        token: this.currentTournament.info.registration_token,
      });
    },
    getInviteLink() {
      this.copyLinkToClipboard(this.generateInviteLink());
    },
    openLocalSharingMenu(event) {
      this.openSharingMenu({
        event,
        type: TOURNAMENT,
        text: `${this.currentTournament.info.title} ${this.$t(
          '_web_' + TOURNAMENT,
          TOURNAMENT
        )} on `,
        id: this.currentTournament.id,
        vanityId: this.currentTournament.vanityId,
        skipItems: ['copy-link', 'repost'],
      });
    },
  },
};
</script>
