<template>
  <Wrapper :header="$t('_web_profile-info_clan-info', 'Clan Info')">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="row mb-5">
          <TextInput
            v-model="accountName"
            v-validate="{
              required: true,
              max: 32,
            }"
            class="col-12"
            required
            :label="$t('_web_create-team_clan-name-label', 'Team name')"
            name="name"
            :placeholder="$t('_web_team-create_name-placeholder', 'Team name')"
            :error="errors.first('name')"
          />
        </div>
        <div class="row">
          <MultiRadio
            v-model="clanInvitesType"
            class="col-12"
            :items="inviteTypes"
            variant="flat"
            :cols-equal="true"
            name="clan-invites-type"
            :label="$t('_web_create-clan_invites-label', 'Invites')"
          />
        </div>
        <div class="row mb-3">
          <AccountEditCountry class="col-12" />
        </div>
        <div class="row">
          <TextInput
            v-model="accountVanityId"
            v-validate="{ regex: /^[\w-_]+$/ }"
            class="col-12"
            :label="$t('_web_entity-edit-vanity-id-label', 'Vanity url')"
            :placeholder="
              $t('_web_entity-edit-vanity-id-placeholder', 'Vanity url')
            "
            name="vanity_id"
            :error="errors.first('vanity_id')"
            @blur="checkVanityId"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <Description :rows="15" :has-counter="false" :padding-bottom="'12px'" />
      </div>
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { CLAN_TYPES } from '@/constants/accounts.js';
import { vanityUrlEdit } from '@/views/account/content/edit/panels/mixins/vanityUrlEdit';
import Wrapper from '../../PanelWrapper';
import MultiRadio from '@/components/molecules/form/MultiRadio';
import TextInput from '@/components/atoms/form/HeraFormTextInput';
import AccountEditCountry from './elements/Country';
import Description from './Description';

export default {
  name: 'AccountEditInformationClan',
  components: {
    Wrapper,
    TextInput,
    MultiRadio,
    AccountEditCountry,
    Description,
  },
  inject: ['$validator'],
  mixins: [vanityUrlEdit],
  data() {
    return {
      inviteTypes: {
        [CLAN_TYPES.PRIVATE]: this.$t(
          '_web_create-clan_invite-private',
          'Invite Only'
        ),
        [CLAN_TYPES.PUBLIC]: this.$t(
          '_web_create-clan_invites-public',
          'Open Registration'
        ),
      },
    };
  },
  computed: {
    ...mapGetters(['accountEditData']),
    clanInvitesType: {
      get() {
        if (!this.accountEditData) {
          this.updateEditData({ clanType: CLAN_TYPES.PUBLIC });
        }

        return this.accountEditData.clanType;
      },
      set(clanType) {
        this.updateEditData({ clanType });
      },
    },
    currentCountryData() {
      return {
        id: this.accountEditData.country_id,
        name: this.accountEditData.country,
      };
    },
    accountName: {
      get() {
        return this.accountEditData.name || '';
      },
      set(name) {
        this.updateEditData({
          name,
        });
      },
    },
  },
  methods: {
    ...mapMutations(['updateEditData']),
  },
};
</script>
