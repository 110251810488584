import TournamentPlayers from '@/views/tournaments/management/participants/AllParticipants';
import TournamentPlayersWrapper from '@/views/tournaments/management/participants';
const InvitePlayers = () => import('@/views/tournaments/management/invites');
const RegistrationData = () =>
  import('@/views/tournaments/management/participants/RegistrationData');
import { NAVIGATION_BUTTONS } from '@/utils/tournamentsConstants';

export default [
  {
    path: 'players',
    component: TournamentPlayersWrapper,
    children: [
      {
        path: '',
        name: 'tournamentPlayers',
        component: TournamentPlayers,
        props: true,
        meta: {
          titleKey: '_web_tournaments_participants',
          fallbackLabel: 'Participants',
          showAdminFunctions: true,
        },
      },
      {
        path: 'applications',
        name: 'tournamentApplications',
        component: RegistrationData,
        meta: {
          titleKey: '_web_tournaments_participants-applications',
          fallbackLabel: 'Registration Data',
          buttons: NAVIGATION_BUTTONS.invitePlayers,
          showAdminFunctions: true,
        },
      },
      {
        path: 'invites',
        name: 'tournamentInvites',
        component: InvitePlayers,
        props: true,
        meta: {
          titleKey: '_web_tournaments_invite-participants',
          fallbackLabel: 'Invite participants',
          buttons: NAVIGATION_BUTTONS.invitePlayers,
          showAdminFunctions: true,
        },
      },
    ],
  },
];
