<template>
  <div
    class="d-flex align-items-center tournament-game-label w-100"
    :class="{ [`tournament-game-label_${variant}`]: variant }"
  >
    <div class="d-flex align-items-center justify-content-between w-100">
      <Avatar
        v-if="isCrossplatform"
        :img-url="$options.multiplatformIcon"
        :size="platformLogoSize"
        class="d-block"
      />
      <template v-else>
        <Avatar
          v-for="(platform, index) in platformsLogo"
          :key="index"
          :img-url="platform"
          :size="platformLogoSize"
          class="d-block"
        />
      </template>
      <TextBlock variant="secondary" :size="0.75" class="mx-3">
        {{ tournament.tournament_start | localDate($times.DATE_SUBMED) }}
      </TextBlock>
      <TextBlock
        class="position-relative font-weight-bold d-block text-truncate"
      >
        {{ gameName }}
      </TextBlock>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { prop } from '@/utils/factories';
import Avatar from '@/components/images/Avatar';
import TextBlock from '@/components/atoms/TextBlock';
import MultiPlatformIcon from '@/assets/images/icons/multiplatform.svg';
export default {
  components: {
    Avatar,
    TextBlock,
  },

  props: {
    tournament: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: '',
      validator: type => !type || type === 'large',
    },
    platformLogoSize: prop(Number, 24),
    gameLogoSize: prop(Number, 24),
  },
  computed: {
    ...mapGetters(['games', 'platforms', 'gameByID']),

    gameLogo() {
      return this.$lodash.get(this, 'game.logo') || null;
    },
    game() {
      return this.gameByID(this.tournament.game);
    },
    gameName() {
      return this.$lodash.get(this, 'game.name') || '';
    },

    isCrossplatform() {
      // Sometime backend send multiple platforms in single array item like `platforms: ["XBOX,PSN,STEAM"]`
      const platforms = this.$lodash.get(this, 'tournament.platforms') || [];
      return (
        Array.isArray(platforms) &&
        (platforms.length > 1 || (platforms[0] && platforms[0].includes(',')))
      );
    },

    platformsLogo() {
      if (this.isCrossplatform || !this.tournament.platforms) {
        return;
      }
      return this.tournament.platforms.map(el => {
        let platform = this.platforms.find(({ id }) => id === el);
        return this.$lodash.get(platform, 'logo.uri') || null;
      });
    },
  },
  created() {
    this.$options.multiplatformIcon = MultiPlatformIcon;
  },
};
</script>

<style lang="scss">
.tournament-game-label {
  font-size: $ph-small-text-font-size;
}
</style>
