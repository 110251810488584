<template>
  <div>
    <div v-if="categories.length === 0" class="landing-shop-loader">
      <Spinner class="categories-loader-shop" />
    </div>
    <div class="category-grid">
      <div
        v-for="item in categories"
        :key="item.name"
        :to="'shop/categories'"
        class="grid-item"
        @click="hanldeClick(item)"
      >
        <CategoryCard
          :cover-image="item.image"
          :title="item.name"
          :class="{ categoryItem: true, active: item === selectedCategory }"
        >
        </CategoryCard>
      </div>
    </div>
    <h1 class="safe-pay">
      {{ $t('_web_shop_landing_safe_pay', 'Safe pay with') }}
    </h1>
    <div class="safe-pay-wrap">
      <img src="@/assets/images/shop/mada-shop.svg" alt="" class="img-pay" />
      <img
        src="@/assets/images/shop/mastercard-shop.svg"
        alt=""
        class="img-pay"
      />
      <img src="@/assets/images/shop/visa-shop.svg" alt="" class="img-pay" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CategoryCard from './CategoryCard.vue';
import Spinner from '@/views/main/components/Spinner.vue';

export default {
  name: 'LandingShop',
  components: {
    CategoryCard,
    Spinner,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      activeCategory: null,
    };
  },
  computed: {
    ...mapGetters(['selectedCategory', 'categories', 'isRTL']),
    ...mapState('my', ['account']),
    ...mapState('my', { myLogin: 'accountId' }),
  },
  watch: {
    isRTL(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchCategories({ lang: this.isRTL ? 'ar' : 'en' });
      }
    },
  },
  created() {
    window.dataLayer.push({
      event: 'shop view',
      userID: this.myLogin,
    });
  },
  methods: {
    ...mapActions(['selectCategory', 'fetchCategories']),
    hanldeClick(item) {
      this.selectCategory(item);
      this.$router.push({ path: '/shop' });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 20%;
  padding-right: 20%;
  align-items: center;
  margin: auto;
  margin-bottom: 180px;
  @media (max-width: 1660px) {
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 80px;
  }
  @media (max-width: 850px) {
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 80px;
    justify-content: center;
  }
}

.grid-item {
  flex-basis: 25%;
  flex-grow: 0;
  padding: 5px;
  margin-top: 1rem;
  cursor: pointer;
  @media (max-width: 850px) {
    flex-basis: 100%;
  }
}

.category-grid :nth-child(-n + 2) {
  flex-basis: 50%;
  @media (max-width: 850px) {
    flex-basis: 100%;
  }
}
.safe-pay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 2.5rem;
  margin-bottom: 40px;
}
.img-pay {
  width: auto;
  height: 35px;
  @media (max-width: 850px) {
    width: 48px;
    height: 48px;
  }
}
.safe-pay-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 30px;
}

.landing-shop-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
