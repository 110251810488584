import { COMPETITION_STATUSES_TEXT } from '@/constants/tournaments';

export const competitionStatuses = {
  computed: {
    localizedCompetitionStatuses() {
      let statuses = COMPETITION_STATUSES_TEXT.reduce((res, obj) => {
        res[obj.name] = this.$t(obj.key, obj.label);
        return res;
      }, {});
      return statuses;
    },
  },
};
