var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 d-flex flex-column"},[(_vm.showAdminFunctions)?_c('div',{ref:"breadcrumbsWrapper",staticClass:"mx-0 breadcrumbs-wrapper w-100 h-pr-0 sticky-top",attrs:{"id":"breadcrumbsWrapper"}},[_c('Widget',{staticClass:"mb-5",attrs:{"title":_vm.pageTitle,"actions-menu":_vm.actionMenuItems,"help-text":!_vm.isPublishRoute ? _vm.infoText : '',"status-btn-mode":_vm.statusBtnMode,"status-btn-disabled":_vm.hasDummy,"status-btn-position":"bottom","hide-search":""},scopedSlots:_vm._u([(_vm.stageType)?{key:"subtitle",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('Icon',{staticClass:"h-mr-3",attrs:{"name":_vm.$lodash.kebabCase(_vm.stageType),"width":"24","fill":_vm.$scss.colors.secondaryTextBg}}),_c('TextBlock',{attrs:{"variant":"muted","text":_vm.stageTypeName}})],1)]},proxy:true}:null],null,true)})],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-5"},[(!_vm.showAdminFunctions && _vm.currentTournamentStages.length <= 1)?_c('GoBackBtn',{staticClass:"pl-0 pr-0 mr-5",staticStyle:{"min-width":"16px"},attrs:{"direct-path":("/tournament/" + (_vm.currentTournament.id))}}):(!_vm.showAdminFunctions && _vm.currentTournamentStages.length > 1)?_c('GoBackBtn',{staticClass:"pl-0 pr-0 mr-5",staticStyle:{"min-width":"16px"},attrs:{"history-mode":""}}):_vm._e(),(_vm.showAdminFunctions)?_c('HorizontalTabs',_vm._b({staticClass:"position-relative h-mr-6",attrs:{"id":"brackets-view-toggle"}},'HorizontalTabs',{
        tabs: _vm.routerSubPages,
        useCustomRoute: true,
        hideDisableIcon: true,
        variant: 'buttons',
      },false)):_vm._e(),(_vm.isManageRoute)?_c('Btn',{staticClass:"btn-accent-simple h-ml-3",attrs:{"disabled":_vm.isLoading || !_vm.isCompetitionScheduled,"label":_vm.hasDummy ? _vm.$$t('Build Brackets') : _vm.$$t('Re-build Brackets')},on:{"click":function($event){return _vm.confirmReDraw(_vm.currentTournamentStage)}}}):_vm._e(),(_vm.showAdminFunctions && _vm.infoText && _vm.isPublishRoute)?_c('div',{staticClass:"h-ml-auto"},[(_vm.goBackVisible)?_c('Btn',{staticClass:"h-ml-3",attrs:{"label":_vm.$t('_web_tournament_finish-btn', 'Go back')},on:{"click":_vm.goBack}}):_vm._e(),(_vm.isPublishRoute)?_c('Btn',{staticClass:"btn-accent-simple h-ml-3",attrs:{"disabled":_vm.isLoading || !_vm.isCompetitionScheduled,"label":_vm.hasDummy ? _vm.$$t('Build Brackets') : _vm.$$t('Re-build Brackets')},on:{"click":function($event){return _vm.confirmReDraw(_vm.currentTournamentStage)}}}):_vm._e()],1):_vm._e()],1),(!_vm.isManageRoute)?_c('div',[(
        _vm.stageType && _vm.currentCompetitionID && _vm.isBattleRoyale
          ? _vm.isBattleRoyale
          : _vm.isSwiss
          ? _vm.isSwiss
          : _vm.isRoundRobin
          ? _vm.isRoundRobin
          : false
      )?_c('div',{staticClass:"flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('Btn',{staticClass:"btn-accent-simple h-ml-3",attrs:{"disabled":_vm.showBracketsView},on:{"click":_vm.switchToBrackets}},[_vm._v("\n          Brackets\n        ")]),_c('Btn',{staticClass:"btn-accent-simple h-ml-3",attrs:{"disabled":!_vm.showBracketsView},on:{"click":_vm.switchToStanding}},[_vm._v("\n          Standing\n        ")])],1)]):_vm._e()]):_vm._e(),_c('Panel',{staticClass:"flex-grow-1",class:[
      _vm.isBattleRoyale ? 'pt-4' : 'pt-0',
      { 'p-0': _vm.isTableViewRoute, 'text-center': _vm.isEditResultsRoute } ],attrs:{"variant":_vm.wrapperVariant}},[(_vm.showBracketsView)?_c('div',[(_vm.stageType && _vm.currentCompetitionID)?_c('router-view',{key:_vm.routeKey,on:{"noDataToShow":function($event){_vm.hasDummy = true},"notEmpty":function($event){_vm.hasDummy = false}}}):_vm._e()],1):(!_vm.showBracketsView && _vm.stageType && _vm.currentCompetitionID)?_c('div',{staticClass:"row standings-tables"},_vm._l((_vm.blocks),function(block){return _c('div',{key:block.id,staticClass:"mt-4 col-12"},[_c('GroupTable',{staticClass:"h-100 flex-grow-1 standings-table",attrs:{"id":block.id,"title":block.title,"type":_vm.currentStageType.type,"tie-breaker":_vm.$lodash.get(
              _vm.currentStage,
              'block_configuration.tie_breaker_preset'
            )}})],1)}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }