<template>
  <Autosuggest
    v-bind="{
      value,
      label,
      minLength: 2,
      error,
      variant,
      displayProperty: 'text',
      source: suggestCountriesRequest,
      required,
      tabindex,
    }"
    @resultPicked="$emit('input', $event)"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { emptyObject, prop } from '@/utils/factories';
import Autosuggest from './HeraAutosuggest';

export default {
  components: {
    Autosuggest,
  },

  props: {
    value: {
      type: null,
      default: emptyObject,
    },
    label: prop(String),
    error: prop(String),
    variant: prop(String, 'default'),
    required: prop(Boolean),
    tabindex: prop(Number),
  },

  methods: {
    ...mapActions(['suggestCountriesRequest']),
  },
};
</script>
<style>
label {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  color: #ffffff;
}
</style>
