var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column position-relative input-multilined contenteditable",class:[
    ("input-multilined--" + _vm.variant),
    { 'input-multilined--invalid': _vm.error } ]},[_c('WrapperFormControl',_vm._b({},'WrapperFormControl',{
      tooltip: _vm.tooltip,
      required: _vm.required,
      error: _vm.error,
      label: _vm.label,
      hasCounter: _vm.hasCounter,
      maxLengthCounter: _vm.maxLength,
      currentCounter: _vm.value || '',
      hideLabel: _vm.hideLabel,
    },false),[_c('Editable',_vm._g(_vm._b({ref:"textarea",staticClass:"custom-scrollbar input-form textarea position-relative",attrs:{"slot":"control","background":_vm.background},slot:"control"},'Editable',{
        maxLength: _vm.maxLength,
        id: _vm.id,
        value: _vm.value,
        rows: _vm.rows,
        placeholder: _vm.placeholder,
        disabled: _vm.disabled,
        readOnly: _vm.readOnly,
        disableAutofocus: _vm.disableFocus,
        mentionsActivators: _vm.mentionsActivators,
      },false),_vm.handlers))],1),_c('Putter',_vm._g(_vm._b({ref:"putter",attrs:{"enable-link-detection":_vm.detectLinks},model:{value:(_vm.putterIsActive),callback:function ($$v) {_vm.putterIsActive=$$v},expression:"putterIsActive"}},'Putter',{
      input: _vm.value,
      tagsActivators: _vm.tagsActivators,
      mentionsActivators: _vm.mentionsActivators,
      inputRegExp: /^\w+$/,
    },false),{ linkDetected: function ($event) { return _vm.$emit('linkDetected', $event); }, put: _vm.put }))],1)}
var staticRenderFns = []

export { render, staticRenderFns }