<template>
  <Panel
    v-if="!account.isBattle && !account.isTournament"
    class="container-fluid d-flex flex-column justify-content-between status-bar px-0"
    variant="transparent"
  >
    <TournamentStatusBar v-if="account.isTournament" />
    <template v-else>
      <component
        :is="actionsComponent"
        v-if="!hideControls"
        class="status-bar-actions"
      />
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Actions from './actions';
import editActions from './editActions';

import AppIcon from '@/components/atoms/Icon';
import Panel from '@/components/atoms/Panel';

import Counter from '@/views/account/sidebarCounters/components/Counters';
import TournamentStatusBar from './TournamentStatusBar';

export default {
  name: 'AccountStatusBar',
  components: {
    TournamentStatusBar,
    Actions,
    AppIcon,
    Counter,
    Panel,
  },
  computed: {
    ...mapState('current', ['account', 'accountType', 'accountId']),
    actionsComponent() {
      return this.account._canEdit ? editActions : Actions;
    },
    hideControls() {
      return this.$route.meta.statusbarControlsIsHidden;
    },
  },
  methods: {
    ...mapMutations('mediaplayer', ['openPlayer']),

    avatarHandler() {
      if (this.account && this.account._avatar) {
        this.openPlayer({
          type: 'image',
          url: this.account._avatar,
        });
      }
    },
  },
};
</script>
