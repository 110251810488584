var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('popover',_vm._b({staticClass:"dropbox-list",style:(_vm.stylePopover),attrs:{"custom":_vm.custom,"nofix":_vm.nofix},on:{"toggle":function($event){return _vm.$emit('toggle', $event)}}},'popover',{
    position: _vm.positionReplaced,
    float: _vm.float,
    regard: _vm.regard,
    targetSelector: _vm.targetSelector,
    closest: _vm.closest,
    isFixed: _vm.isFixed,
    blockIsFixed: _vm.blockIsFixed,
    translate: _vm.translate,
  },false),[_vm._t("header"),_c('ListPreloader',{staticClass:"h-100",attrs:{"is-loading":_vm.loader ? !_vm.loader : !_vm.items,"is-empty-list":_vm.isEmptyList,"dummy-props":Object.assign({}, {iconName: _vm.emptyListIcon,
      subheaderText: _vm.$t('_web_popover-menu_is-empty', 'Not Found')},
      _vm.dummyProps)}},[_c('div',{staticClass:"popover-dummy",attrs:{"slot":"empty"},slot:"empty"},[(!_vm.items)?_vm._t("dummy-preload",[_vm._v("\n        "+_vm._s(_vm.$t('_web_popover-menu_is-loading', 'Loading...'))+"\n      ")]):_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.items && _vm.items.length),expression:"items && items.length"}],ref:"listWrapper",staticClass:"overflow-hidden list-wrapper",attrs:{"slot":"list","tabindex":"-3"},on:{"mousewheel":function($event){$event.stopPropagation();return _vm.focusIn($event)}},slot:"list"},[_c('div',{staticClass:"position-relative h-text-left custom-scrollbar",style:(_vm.styleContent)},[_c('InfiniteScroll',{ref:"infinityScroll",staticClass:"list-unstyled mb-0 px-0 overflow-hidden",attrs:{"tag":"ul","list":_vm.items,"list-name":_vm.popoverList,"is-disabled":_vm.scrollIsDisable,"in-container":"","activation-gap":50},on:{"ScrollToBottom":function($event){return _vm.$emit('scrollToBottom')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _vm._l((_vm.items),function(item,index){return _c('li',{key:(_vm.popoverList + "_" + index),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selected(item)}}},[_vm._t("default",null,null,{ item: item, index: index })],2)})}}],null,true)})],1)])]),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }