<template>
  <a :href="url" class="mb-5 banner-wrapper d-block">
    <img :src="image" width="100%" height="auto" />
  </a>
</template>

<script>
export default {
  name: 'PostBanner',
  props: {
    image: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.banner-wrapper {
  border-radius: $ph-small-radius;
  overflow: hidden;
}
</style>
