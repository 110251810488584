<template>
  <Panel
    class="stage-wrapper position-relative"
    :variant="['transparent', 'stroked', 'stroked-secondary', 'rounded-large']"
  >
    <div class="d-flex flex-column">
      <div class="stage-info mb-4 cursor-hand d-flex align-items-center">
        <div class="d-flex align-items-center overflow-hidden">
          <TextBlock
            tag="div"
            class="text-truncate"
            :size="1.125"
            :title="stageInfo.title"
          >
            <strong>{{ stageInfo.title }}</strong>
          </TextBlock>
        </div>
        <StatusLabel :status="stageInfo.status" class="h-ml-auto px-4 py-3" />
        <div v-if="showAdminFunctions && canEdit" class="flex-shrink-0">
          <!-- <HeraFormBtn
              class="h-ml-3 px-5 btn-accent-simple"
              @click="editStage"
            >
              <span>
                {{ $t('_web_tournaments_stage_edit-btn', 'Edit') }}
              </span>
            </HeraFormBtn> -->
          <Icon
            name="edit"
            width="18"
            class="h-ml-4"
            :fill="highlightManageIconColor"
            @click="editStage"
          />
          <Icon
            name="trash"
            width="22"
            class="h-ml-4"
            :class="{
              disabled: isStageFieldDisabled('deleteStage', stageInfo),
            }"
            :fill="highlightManageIconColor"
            @click="confirmRemoveStage"
          />
        </div>
      </div>
      <TextBlock
        tag="div"
        class="w-100 mb-4 stage-type text-uppercase"
        variant="secondary"
        :size="0.625"
      >
        <strong>{{ stageType | startCase }}</strong>
      </TextBlock>
      <template v-if="showAdminFunctions && !canEdit">
        <div class="mb-4 d-flex align-items-center justify-content-between">
          <TextBlock
            tag="div"
            class="h-mr-auto text-uppercase"
            variant="secondary"
            :size="0.625"
          >
            {{ $t('_web_tournaments_stage_start-label', 'Start') }}
          </TextBlock>
          <div>
            <Icon
              name="calendar"
              width="18"
              :fill="$scss.colors.secondaryTextBg"
            />
            <SecondaryText
              :size="0.625"
              class="text-nowrap font-weight-bold text-uppercase h-ml-2"
            >
              {{ stageInfo.date_start | localDate(dateFormat) }}
            </SecondaryText>
          </div>
          <div class="mx-3">
            <Icon name="time" width="18" :fill="$scss.colors.secondaryTextBg" />
            <SecondaryText
              :size="0.625"
              class="text-nowrap font-weight-bold text-uppercase h-ml-2"
            >
              {{ stageInfo.date_start | localDate(timeFormat) }}
            </SecondaryText>
          </div>
        </div>
        <div
          v-if="hasCheckInEnabled"
          class="mb-4 d-flex align-items-center justify-content-between"
        >
          <TextBlock
            tag="div"
            class="h-mr-auto text-uppercase"
            variant="secondary"
            :size="0.625"
          >
            {{ $t('_web_tournaments_check_in_start-label', 'Check-in') }}
          </TextBlock>
          <div>
            <Icon
              name="calendar"
              width="18"
              :fill="$scss.colors.secondaryTextBg"
            />
            <SecondaryText
              :size="0.625"
              class="text-nowrap font-weight-bold text-uppercase h-ml-2"
            >
              {{ stageInfo.check_in_start_date | localDate(dateFormat) }}
            </SecondaryText>
          </div>
          <div class="mx-3">
            <Icon name="time" width="18" :fill="$scss.colors.secondaryTextBg" />
            <SecondaryText
              :size="0.625"
              class="text-nowrap font-weight-bold text-uppercase h-ml-2"
            >
              {{ stageInfo.check_in_start_date | localDate(timeFormat) }}
            </SecondaryText>
          </div>
        </div>
        <div
          v-if="publishMode || canManageParticipants"
          class="mb-4 d-flex align-items-center justify-content-center"
        >
          <HeraFormBtn
            class="h-ml-3 px-5 btn-accent-simple"
            @click.stop="editPlayers"
          >
            <span>
              {{
                $t('_web_tournaments_stage_participants-btn', 'Participants')
              }}
            </span>
          </HeraFormBtn>
        </div>
      </template>
      <slot></slot>
      <div
        v-if="showAdminFunctions && !canEdit"
        class="d-flex flex-column mt-3"
      >
        <template>
          <HeraFormBtn
            v-if="canStageStart"
            fluid
            class="mb-2"
            :content-color="$scss.heraSuccess"
            :class="{ 'is-loading': btnLoader }"
            :disabled="stageStarted || !previousStageFinished"
            @click="startStage"
          >
            <slot name="prepend">
              <Icon class="h-mr-1" width="11" name="play-2" />
            </slot>
            <span>
              {{ $t('_web_tournaments_stage_start-btn', 'Start stage') }}
            </span>
          </HeraFormBtn>

          <HeraFormBtn
            v-if="canStagePublish"
            fluid
            class=" mb-2"
            :class="{ 'is-loading': btnLoader }"
            @click="publishStage"
          >
            <slot name="prepend">
              <Icon class="h-mr-1" name="publish" />
            </slot>
            {{ $t('_web_tournaments_stage_publish-btn', 'Publish') }}
          </HeraFormBtn>
          <HeraFormBtn
            v-if="canStartCheckIn() && !checkInStarted()"
            fluid
            class=" mb-2"
            :class="{ 'is-loading': btnLoader }"
            @click="startCheckIn"
          >
            {{
              $t('_web_tournaments_stage_start_check_in-btn', 'Start Check-in')
            }}
          </HeraFormBtn>

          <HeraFormBtn
            v-if="canStageStart"
            fluid
            class="mb-2"
            :content-color="$scss.heraWarning"
            :class="{ 'is-loading': btnLoader }"
            @click="unPublishStage"
          >
            <slot name="unPublish">
              <Icon class="h-mr-1" name="publish" />
            </slot>
            {{ $t('_web_tournaments_stage_unpublish-btn', 'Unpublish') }}
          </HeraFormBtn>
        </template>
      </div>
    </div>
  </Panel>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { TIME_SIMPLE, DATE_MED } from '@/constants/time';
import { STAGE_STATUSES, COMPETITION_STATUSES } from '@/constants/tournaments';
import TextBlock from '@/components/atoms/TextBlock';
import SecondaryText from '@/components/atoms/SecondaryText';
import dateMethods from '@/mixins/dateMethods';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
// import PopoverMenu from '@/components/popover/Menu';
import Panel from '@/components/atoms/Panel';
import Icon from '@/components/atoms/Icon';
import StatusLabel from '@/components/molecules/StatusLabel.vue';
import routeHelpers from '@/mixins/routeHelpers';

export default {
  name: 'TournamentStage',

  components: {
    Icon,
    TextBlock,
    HeraFormBtn,
    SecondaryText,
    // PopoverMenu,
    Panel,
    StatusLabel,
  },

  mixins: [...dateMethods, routeHelpers],

  props: {
    stageInfo: {
      required: true,
      type: Object,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      required: true,
      type: Boolean,
    },
    publishMode: {
      required: true,
      type: Boolean,
    },
    btnLoader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isStageStarting: false,
      isStagePublishing: false,
      dateFormat: DATE_MED,
      timeFormat: TIME_SIMPLE,
      actionButton: null,
      stageActions: [
        {
          label: this.$t('_web_tournament_stage_view-stage-btn', 'View stage'),
          action: this.viewStage,
          activeInSafeMode: false,
        },
        {
          label: this.$t('_web_tournament_stage_edit-stage-btn', 'Edit stage'),
          action: this.editStage,
          activeInSafeMode: true,
        },
        {
          label: this.$t(
            '_web_tournament_stage_remove-stage-btn',
            'Remove Stage'
          ),
          action: this.confirmRemoveStage,
          activeInSafeMode: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'isStageFieldDisabled',
      'currentTournamentStages',
      'getBlocksByStageID',
    ]),
    showAdminFunctions() {
      return this.getMetaFromParents('showAdminFunctions');
    },

    tournamentID() {
      return this.$route.params.tournamentID;
    },
    tournamentStageID() {
      return this.stageInfo.id;
    },
    stageType() {
      return this.$lodash.get(this.stageInfo, 'block_configuration.type', '');
    },
    blocks() {
      return this.getBlocksByStageID(this.stageInfo.id);
    },
    canStagePublish() {
      const hasBlock = this.blocks && this.blocks.length;
      return Boolean(
        hasBlock &&
          this.blocks[0].status === COMPETITION_STATUSES.SCHEDULED &&
          this.stageInfo.status === STAGE_STATUSES.DRAFT
      );
    },
    canManageParticipants() {
      return this.stageInfo.status === STAGE_STATUSES.DRAFT;
    },
    canStageStart() {
      return this.stageInfo.status === STAGE_STATUSES.PUBLISHED;
    },
    hasCheckInEnabled() {
      return this.stageInfo.check_in_enabled;
    },
    previousStageFinished() {
      return (
        this.stageInfo.index === 0 ||
        (this.stageInfo.index > 0 &&
          this.currentTournamentStages[this.stageInfo.index - 1].status ===
            STAGE_STATUSES.FINISHED)
      );
    },
    stageStarted() {
      return this.stageInfo.status === STAGE_STATUSES.STARTED;
    },
    onClickHandler() {
      return this.showAdminFunctions
        ? this.canEdit
          ? this.editStage
          : this.viewStage
        : this.goToPlayerStageView;
    },
    highlightManageIconColor() {
      return this.$route.name === 'stageParticipants'
        ? this.$scss.colors.accentBg
        : this.$scss.colors.primaryTextBg;
    },
  },

  methods: {
    ...mapActions([
      'fetchTournamentBlocks',
      'startStage',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    ...mapMutations(['updateStageStatus']),

    setPopoverPosition({ target }) {
      this.actionButton = target.getBoundingClientRect();
    },
    canStartCheckIn() {
      return (
        this.hasCheckInEnabled &&
        this.stageInfo.check_in_start_date > this.getNowSeconds()
      );
    },
    checkInStarted() {
      return (
        this.hasCheckInEnabled &&
        this.stageInfo.check_in_start_date <= this.getNowSeconds()
      );
    },
    editPlayers() {
      this.$router.push({
        name: 'stageParticipants',
        params: {
          ...this.$route.params,
          stageID: this.stageInfo.id,
          tab: 'qualified',
        },
      });
    },
    editStage() {
      this.$router.replace({
        name: 'editStage',
        params: {
          ...this.$route.params,
          stageID: this.tournamentStageID,
        },
      });
      this.actionButton = null;
    },
    publishStage() {
      this.$emit('publishStage', {
        tournamentID: this.tournamentID,
        stageID: this.tournamentStageID,
      });
    },
    startCheckIn() {
      this.$emit('startCheckIn', {
        tournamentID: this.tournamentID,
        stageID: this.tournamentStageID,
      });
    },
    unPublishStage() {
      this.$emit('unPublishStage', {
        tournamentID: this.tournamentID,
        stageID: this.tournamentStageID,
      });
    },
    startStage() {
      this.$emit('startStage', {
        tournamentID: this.tournamentID,
        stageID: this.tournamentStageID,
      });
    },
    removeStage() {
      this.$emit('deleteStage', { stageID: this.tournamentStageID });
      this.actionButton = null;
      this.closeModal();
    },
    confirmRemoveStage() {
      if (!this.blocks) {
        this.removeStage();
      } else {
        this.openModal({
          name: 'HeraConfirm',
          props: {
            title: this.$t(
              '_web_tournament_stage_remove-stage-confirm-title',
              'Confirm remove of the stage'
            ),
            text: this.$t(
              '_web_tournament_stage_remove-stage-confirm-subheader',
              'This stage has groups inside it. Stage will be deleted with all groups.'
            ),
          },
          events: {
            confirm: this.removeStage,
            cancel: this.closeModal,
          },
          settings: {
            clickOnBackdrop: true,
            escKey: false,
          },
        });
      }
    },
    goToPlayerStageView() {
      this.$router.push({
        name: 'playerViewStage',
        params: {
          ...this.$route.params,
          stageID: this.tournamentStageID,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.stage-wrapper {
  z-index: 1;

  &.is-active {
    box-shadow: 0 0 0 0.25rem $ph-accent;
  }

  &.is-disabled {
    opacity: 0.5;

    &::after {
      content: '';
      @include fill-border();
      cursor: not-allowed;
      z-index: 9;
    }
  }

  &:not(.is-active) .svg-icon-wrapper:hover svg {
    fill: $ph-accent;
    transition: all 0.1s ease-in;
  }
  .stage-info .hera-text-block:hover {
    color: $ph-accent;
  }
}
</style>
