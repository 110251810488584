var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WrapperFormControl',_vm._b({},'WrapperFormControl',{
    hideLabel: !_vm.label,
    required: _vm.required && !_vm.hideAsterisk,
    label: _vm.label,
    tooltip: _vm.tooltip,
    error: _vm.error,
    hideErrorText: _vm.hideErrorText,
  },false),[_c('template',{slot:"control"},[_c('VueDatePicker',_vm._g(_vm._b({staticClass:"date-picker",class:{
        'date-picker--fit-content': _vm.fitContent,
      },attrs:{"value-type":"timestamp","format":_vm.dateFormat,"type":"datetime","show-time-panel":false,"clearable":false},model:{value:(_vm.valueModel),callback:function ($$v) {_vm.valueModel=$$v},expression:"valueModel"}},'VueDatePicker',{
        disabled: _vm.disabled,
        disabledDate: _vm.disabledDate,
        placeholder: _vm.placeholder || _vm.placeholderDefault,
        inputAttr: { size: !_vm.fitContent ? 20 : 9 },
      },false),_vm.eventListeners),[_c('template',{slot:"icon-calendar"},[_c('Icon',{staticClass:"dropdown-icon",attrs:{"name":"arrow-s","width":"16"}})],1)],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }