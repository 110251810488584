<template>
  <li class="pb-5 px-0">
    <TextLabel v-if="user.isLabel">{{ user.text }}</TextLabel>
    <ListItemAccount
      v-else
      :key="user.login"
      :approved="userModel.verified"
      :model="userModel"
      class="w-100"
      target="_blank"
    >
      <div
        slot="action"
        class="p-2 cursor-pointer clan-invitation-actions"
        @click="addToClan"
      >
        <Icon
          :name="isInvited ? 'checked' : 'plus'"
          :width="14"
          :fill="isInvited ? $scss.accentBg : $scss.primaryTextBg"
          original
          :class="{ 'is-loading': isLoading }"
        />
      </div>
    </ListItemAccount>
  </li>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AccountModel from '@/models/Account';
import { propRequired } from '@/utils/factories';

import isLoadingMixin from '@/mixins/isLoading';

import ListItemAccount from '@/components/common/ListItemAccount';
import TextLabel from '@/components/atoms/TextLabel';
import Icon from '@/components/atoms/Icon';

export default {
  components: {
    TextLabel,
    ListItemAccount,
    Icon,
  },
  mixins: [isLoadingMixin],
  props: {
    user: propRequired(Object),
  },
  data() {
    return {
      isInvited: false,
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId']),
    ...mapState('current', { clanAccount: 'account' }),
    clanId() {
      return this.clanAccount._id;
    },
    userModel() {
      const { type, login: id } = this.user;
      return new AccountModel(type, id).update(this.user);
    },
  },
  methods: {
    ...mapActions(['inviteToClan', 'cancelInviteToClan', 'errorNotify']),
    ...mapActions('alerts', ['confirm']),

    addToClan() {
      if (this.isLoading || this.isInvited) {
        return;
      }

      const confirmOptions = {
        text: this.$t(
          '_web_clan-invite_confirm',
          'Do you want to send invite to @{username}',
          { username: this.userModel._name }
        ),
        confirmButtonText: this.$t('_web_clan-invite_confirm-send', 'Send'),
      };

      const invitationData = {
        clan: this.clanId,
        candidates: [this.userModel._id],
      };

      this.setLoading()
        .then(() => this.confirm(confirmOptions))
        .then(() => this.inviteToClan(invitationData))
        .then(() => (this.isInvited = true))
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
  },
};
</script>
