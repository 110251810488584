import { render, staticRenderFns } from "./HeraFormBtn.vue?vue&type=template&id=41dd6014&"
import script from "./HeraFormBtn.vue?vue&type=script&lang=js&"
export * from "./HeraFormBtn.vue?vue&type=script&lang=js&"
import style0 from "./HeraFormBtn.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./HeraFormBtn.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms&name=HeraFormBtn&notes=%23%23%20this%20component%20has%20a%20slot%20which%20could%20hold%20any%20other%20component%20inside%20it&knobs=%7B%0A%20%20%20%20label%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('label'%2C%20'label%20for%20button')%0A%20%20%20%20%7D%2C%0A%20%20%20%20name%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Name'%2C%20'name')%0A%20%20%20%20%7D%2C%0A%20%20%20%20loading%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('loading'%2C%20false)%0A%20%20%20%20%7D%2C%0A%20%20%20%20contentColor%3A%20%7B%0A%20%20%20%20%20%20default%3A%20color('contentColor'%2C%20'%23ffffff')%0A%20%20%20%20%7D%2C%0A%20%20%20%20disabled%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('disabled'%2C%20false)%0A%20%20%20%20%7D%2C%0A%20%20%20%20id%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('id'%2C%20'id')%0A%20%20%20%20%7D%2C%0A%20%20%20%20variant%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('variant'%2C%20'')%0A%20%20%20%20%7D%2C%0A%20%20%20%20cssClass%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('class'%2C%20%5B'btn-primary'%2C%20'btn-secondary'%2C%20'btn-primary-simple'%2C%20'btn-secondary-simple'%5D%2C%20'btn-primary')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports