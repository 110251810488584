import $routerNames from '@/constants/router/account';
import { VANITY_PREFIX } from '@/constants/router';
// import * as ACCOUNT from '@/constants/accounts';

// const isProfileEntity = accountType => {
//   return [ACCOUNT.PROFILE, ACCOUNT.ORGANIZATION, ACCOUNT.CLAN].includes(
//     accountType
//   );
// };
export const render = (
  createElement,
  { props: { id, type, verified, target, vanityId }, data, scopedSlots }
) => {
  if (!id) {
    return createElement(
      'a',
      {
        ...data,
        attrs: { target, href: '#' },
        class: { verified },
      },
      scopedSlots.default ? scopedSlots.default() : ''
    );
  }
  return createElement(
    'router-link',
    {
      ...data,
      attrs: { target },
      class: { verified },
      props: {
        to: {
          name: `${vanityId ? VANITY_PREFIX : ''}${$routerNames.ACCOUNT}`,
          params: {
            accountType: type,
            accountId: id,
            vanityId,
          },
        },
      },
    },
    scopedSlots.default ? scopedSlots.default() : id
  );
};

export default accountType => {
  return {
    functional: true,
    props: {
      id: {
        type: String,
        required: true,
      },
      vanityId: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: accountType,
      },
      verified: {
        type: Boolean,
        default: false,
      },
    },
    render,
  };
};
