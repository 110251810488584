<template>
  <p v-if="resendTimer">
    {{
      $t(timeoutTextTranslateKey, timeoutTextDefault, { timer: resendTimer })
    }}
  </p>
  <invisible-recaptcha
    v-else
    class="captcha-class-btn"
    :sitekey="recaptchaKey"
    :callback="resend"
    ><TextWithLinks
      tag="p"
      class="verify-msg-submit"
      :class="{ 'is-loading': isLoadingResend }"
      :content="resendTextDefault"
      :content-props="{
        resend: {
          text: 'Resend',
          fn: resend,
        },
      }"
      :translate-key="resendTextTranslateKey"
  /></invisible-recaptcha>
</template>

<script>
import { mapActions } from 'vuex';
import { prop } from '@/utils/factories';
import { loader } from '@/mixins/isLoadingAlt';
import { RECAPTCHA_KEY } from '@/api/config';
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import TextWithLinks from '@/components/text/TextWithLinks';

export default {
  name: 'ResendCode',
  components: { 'invisible-recaptcha': InvisibleRecaptcha, TextWithLinks },
  mixins: [loader('isLoadingResend')],
  props: {
    action: prop(Function),
    actionData: prop(Object),
    timeoutTextTranslateKey: prop(String),
    timeoutTextDefault: prop(String), // must contain a {timer}
    resendTextTranslateKey: prop(String),
    resendTextDefault: prop(String), // must contain a {:resend}
  },
  data() {
    return {
      recaptchaKey: RECAPTCHA_KEY,
      resendTimer: 0,
      //helper
      isLoadingResend: false,
    };
  },
  methods: {
    ...mapActions(['errorNotify']),
    blockResendTimeout() {
      this.resendTimer = 30; // seconds
      this.intervalTimer = window.setInterval(() => {
        this.resendTimer = this.resendTimer - 1; // do not use increment --
        if (this.resendTimer <= 0) {
          window.clearInterval(this.intervalTimer);
          this.resendTimer = 0;
        }
      }, 1e3);
    },
    resend(token) {
      if (!this.actionData) {
        return;
      }
      this.$emit('resended', token);
      const captcha_response = token;
      this.setLoading('isLoadingResend');
      return this.action({ ...this.actionData, captcha_response })
        .then(() => {
          this.$emit('resended');
          this.blockResendTimeout();
        })
        .catch(err => {
          this.resendTimer = 0;
          this.errorNotify(err);
        })
        .finally(() => {
          this.unsetLoading('isLoadingResend');
        });
    },
  },
};
</script>

<style lang="scss">
.captcha-class-btn {
  background-color: transparent;
  border: none;
  color: #6c757d;
  cursor: default;
}
</style>
