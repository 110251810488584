<template>
  <Panel :variant="['transparent', 'no-padding']" class="sidebar">
    <Organiser class="mb-4" />
    <Sponsors class="mb-4" />
    <Prizes class="mb-4" />
    <Format class="mb-4" />
    <Platform class="mb-4" />
    <Game class="mb- mb-lg-4" />
  </Panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Panel from '@/components/atoms/Panel';
import Organiser from '../Organizer.vue';
import Sponsors from '../Sponsors.vue';
import Prizes from './Prizes';
import Platform from './Platform.vue';
import Format from './Format.vue';
import Game from './Game.vue';

export default {
  name: 'TournamentInfo',
  components: {
    Panel,
    Sponsors,
    Platform,
    Prizes,
    Organiser,
    Format,
    Game,
  },
  props: {
    titleTranslationKey: {
      type: String,
      default: '_web_tournaments_info_default_title',
    },
  },
  computed: {
    ...mapGetters([
      'currentTournament',
      'isTournamentBackuped',
      'currentTournamentStage',
      'currentTournamentStages',
    ]),
    tournamentInfo() {
      return {
        organizer: this.currentTournament.organizer,
        sponsors: this.currentTournament.sponsor_logo,
        prizes: this.currentTournament.prizes,
        format: this.currentTournament.format,
        platforms: this.currentTournament.platforms,
        game: this.currentTournament.game,
      };
    },
  },
  methods: {
    ...mapActions([
      'fetchTournamentBlocks',
      'fetchGames',
      'fetchPlatforms',
      'errorNotify',
    ]),
    ...mapMutations([
      'setTournamentBlocks',
      'backupCurrentTournament',
      'clearCurrentTournament',
      'clearCurrentTournamentBackup',
      'clearAllStages',
      'clearAllBlocks',
      'updateStageLoader',
      'setCurrentTournamentStage',
    ]),
  },
};
</script>
<style lang="scss">
.sidebar {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  @media (max-width: 800px) {
    background-color: #11171f !important;
    padding-top: 24px;
  }
}
</style>
