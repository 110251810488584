import { mapMutations, mapGetters } from 'vuex';
import { STAGE_TYPES, BLOCK_TYPES } from '@/constants/tournaments';
export default {
  computed: {
    ...mapGetters(['currentTournamentStage', 'isStageFieldDisabled']),
    stageTypes() {
      return STAGE_TYPES;
    },
    blockType() {
      return this.getBlockConfigurationByKey('type', '');
    },
    isSingleElimination() {
      return this.blockType === BLOCK_TYPES.SINGLE;
    },
    isDoubleElimination() {
      return this.blockType === BLOCK_TYPES.DOUBLE;
    },
    isRoundRobin() {
      return this.blockType === BLOCK_TYPES.RR;
    },
    isSwiss() {
      return this.blockType === BLOCK_TYPES.SWISS;
    },
    isBattleRoyale() {
      return this.blockType === BLOCK_TYPES.BATTLE_ROYALE;
    },
    stageDefaults() {
      return STAGE_TYPES.find(el => el.type === this.blockType);
    },
    tieMatchAllowed() {
      return this.getBlockConfigurationByKey('tie_match', false);
    },
  },
  methods: {
    ...mapMutations(['updateCurrentTournamentStage']),
    getBlockConfigurationByKey(key, defaultVal = null) {
      const currentValue = this.$lodash.get(
        this.currentTournamentStage,
        `block_configuration.${key}`,
        null
      );

      if (
        defaultVal !== null &&
        currentValue === null &&
        !this.$lodash.isEmpty(this.currentTournamentStage)
      ) {
        this.setBlockConfigurationByKey(key, defaultVal);
        return defaultVal;
      }

      return this.$lodash.isEmpty(this.currentTournamentStage)
        ? defaultVal
        : currentValue;
    },
    setBlockConfigurationByKey(key, val) {
      const configuration =
        this.$lodash.cloneDeep(
          this.currentTournamentStage.block_configuration
        ) || {};
      configuration[key] = val;
      this.updateCurrentTournamentStage({
        block_configuration: configuration,
      });
    },
  },
};
