<template>
  <div
    v-if="firebaseConfigured"
    id="twitterSignIn"
    class="twitter-sign-in mx-4 d-flex align-items-center justify-content-center"
    @click="handleTwitterSignIn"
  >
    <Icon name="twitter-sign-in" width="22" fill="#03A9F4" />
  </div>
  <div
    v-else
    class="twitter-sign-in mx-4 d-flex align-items-center justify-content-center"
    style="background-color: transparent"
  ></div>
</template>
<script>
import { mapActions } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';
import { SOCIAL_NETWORK } from '@/constants/auth';
import { FIREBASE_API_KEY, FIREBASE_PROJECT_ID } from '@/api/config';
import Icon from '@/components/atoms/Icon';

const twitterProvider = new TwitterAuthProvider();

export default {
  name: 'TwitterSignIn',
  components: {
    Icon,
  },
  data() {
    return {
      auth: null,
    };
  },
  computed: {
    firebaseConfigured() {
      return Boolean(FIREBASE_API_KEY && FIREBASE_PROJECT_ID);
    },
  },
  mounted() {
    if (this.firebaseConfigured) {
      const firebaseConfig = {
        apiKey: FIREBASE_API_KEY,
        authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
        projectId: FIREBASE_PROJECT_ID,
      };
      const app = initializeApp(firebaseConfig, 'TWITTER');
      this.auth = getAuth(app);
    }
  },
  methods: {
    ...mapActions(['getCredentials', 'snsAuthRequest']),
    handleTwitterSignIn() {
      signInWithPopup(this.auth, twitterProvider).then(result => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const user = result.user;
        const email = user.email || user.providerData[0].email;
        if (!email) {
          return;
        }
        var twitterPayload = JSON.stringify({
          accessToken: credential.accessToken,
          secret: credential.secret,
        });
        this.getCredentials(email)
          .then(() => {
            this.snsAuthRequest({
              networkType: SOCIAL_NETWORK.TWITTER,
              payload: twitterPayload,
            }).then(() => {
              this.$router.go({ path: '/' });
            });
          })
          .catch(err => {
            if (err.status === 404) {
              this.$router.push({
                name: 'snsSignup',
                params: {
                  networkType: SOCIAL_NETWORK.TWITTER,
                  payload: twitterPayload,
                },
              });
            }
          });
      });
    },
  },
};
</script>
<style lang="scss">
.twitter-sign-in {
  background-color: white;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>
