<template>
  <div class="mb-3">
    <TextBlock class="text-muted mb-1" :size="0.75" tag="h6">
      {{ property }}
    </TextBlock>
    <TextBlock
      v-if="property !== 'Tie-braker'"
      :class="[`text-white ${type && 'type-box'}`]"
      :size="0.875"
      line-height="1.5"
      tag="div"
    >
      {{ value }}
    </TextBlock>
    <TieBreakerRules v-else v-model="value" />
  </div>
</template>

<script>
import TextBlock from '@/components/atoms/TextBlock';
import { prop } from '@/utils/factories';
import TieBreakerRules from './TieBreakerRules.vue';

export default {
  name: 'InfoItem',
  components: { TextBlock, TieBreakerRules },
  props: {
    property: prop(),
    value: prop(),
    type: prop(),
  },
};
</script>

<style lang="scss">
.type-box {
  border: solid 1px white;
  border-radius: $hr-radius-01;
  padding: 2px 7px 2px 7px;
  margin-top: 2px;
  align-self: flex-start;
}
</style>
