import { EMAIL_SUPPORT } from '@/api/config';

export const fields = {
  data() {
    return {
      mailSupport: EMAIL_SUPPORT,
    };
  },
};

export default [fields];
