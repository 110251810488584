<template>
  <StatusLabel
    class="py-3 tournament-card_status-label"
    :status="tournament.status"
    :is-admin="isOrganizer"
    for-tournament
  />
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import StatusLabel from '@/components/molecules/StatusLabel';

export default {
  name: 'TournamentCardStatusLabel',
  components: { StatusLabel },
  props: {
    tournament: propRequired(Object),
    isOrganizer: prop(Boolean),
  },
};
</script>

<style lang="scss">
@import '../variables';

.tournament-card_status-label {
  position: absolute;
  z-index: 1;
  top: $cover_height;
  @include auto-rtl(right, 0);
  transform: translateY(calc(#{$card_padding} - 100%));
  &.badge-hera {
    border-radius: 4px 0 4px 0;
    padding: 8px;
    line-height: 16px;
  }
}
</style>
