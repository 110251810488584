<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column">
      <div class="shop-landing-mobile-view-wrap">
        <ShopSearch class="shop-landing-mobile-view" />
      </div>
      <div class="banner-shop">
        <img
          class="banner-full"
          src="@/assets/images/banner-shop-new.png"
          alt="shop banner"
        />
        <img
          class="banner-mobile"
          src="@/assets/images/banner-shop-mobile.png"
          alt="shop banner"
        />
      </div>
      <h1 class="subtitle-landing">
        {{
          $t(
            '_web_shop_landing_title',
            'Apps, games, music, movies, TV shows, iCloud+, and more. This gift card does it all.'
          )
        }}
      </h1>
      <div class="d-flex input-search-wrap justify-content-center">
        <ShopSearch class="shop-landing-desktop-view" />
      </div>
      <LandingShop />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
//components

import LandingShop from './components/LandingShop.vue';

//other Data
import categoryCardsMockedData from './components/categoryCardsMockedData.json';
import ShopSearch from './components/ShopSearch.vue';

export default {
  name: 'ShopLand',

  components: {
    LandingShop,
    ShopSearch,
  },

  data() {
    return {
      cardData: categoryCardsMockedData,
      isToggleActive: false,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(['selectedCategory', 'categories', 'shopSearch', 'isRTL']),
    totalItems() {
      return this.cardData.length;
    },
    paginatedCardData() {
      console.log(this.currentPage);
      const startIndex = this.currentPage;
      const endIndex = startIndex + 12;
      return this.cardData.slice(startIndex, endIndex);
    },
  },

  created() {
    if (!this.categories.length) {
      this.fetchCategories({ lang: this.isRTL ? 'ar' : 'en' });
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['fetchCategories']),

    handlePageChange(pageIndex) {
      this.currentPage = pageIndex;
    },
  },
};
</script>
<style lang="scss">
.aside-shop-wrapper {
  width: 100%;
  max-width: 260px;
}

.shop-card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 40px 16px;
}

.content-wrapper-shop-hr {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 1100px) {
    width: 100%;
  }
}

.filter-icon {
  display: none;
}

.aside-search-filter {
  display: flex;
  flex-direction: row;
}

@media (max-width: 850px) {
  .shop-layout-wrap-hr {
    flex-direction: column;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .search-input {
    max-width: 100% !important;
  }

  .aside-shop-wrapper {
    max-width: 100%;
  }

  .shop-card-container {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .filter-icon {
    display: flex;
    height: 100%;
    widows: 100%;
    padding: 10px 0px 0px 10px;
  }
}

.shop-layout-wrap-hr {
  position: relative;
  display: flex;
  width: 80vw;
  max-width: 1510px !important;
  padding-top: 3rem;
  justify-content: space-between;

  @media (max-width: 600px) {
    width: 100%;
  }
}
.banner-shop {
  width: 80%;
  max-width: 1600px;
  background: transparent;
  display: flex;
  justify-content: center;
  height: auto;
  margin-bottom: 45px;
  margin-top: 2rem;

  @media (max-width: 850px) {
    width: 100%;
  }
  .banner-mobile {
    display: none;
  }
  .banner-full {
    width: 100%;
  }
  @media (max-width: 550px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 2rem;
    .banner-full {
      display: none;
      width: 100%;
    }
    .banner-mobile {
      display: block;
      min-width: 100%;
    }
  }
}
.subtitle-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 820px;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  @media (max-width: 850px) {
    width: 60%;
    font-size: 2rem;
  }

  @media (max-width: 550px) {
    width: 80%;
    font-size: 1.8em;
    margin-bottom: 1rem;
  }
}
</style>
<style lang="scss" scoped>
.shop-search-input {
  max-width: 670px;
}
.input-search-wrap {
  padding-left: 20%;
  padding-right: 20%;
  margin-bottom: 50px;

  @media (max-width: 1660px) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (max-width: 850px) {
    padding-left: 3%;
    padding-right: 3%;
    display: none;
  }
}

.shop-landing-mobile-view-wrap {
  display: none;

  @media (max-width: 850px) {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.shop-landing-mobile-view {
  display: none;

  @media (max-width: 850px) {
    display: flex;
    width: 100%;
    margin-bottom: 0;
  }
}

.shop-landing-desktop-view {
  width: 100%;
  @media (max-width: 850px) {
    display: none;
  }
}
</style>
