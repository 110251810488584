<template>
  <div class="position-relative">
    <!-- TODO: uncomment after implement group chat creation https://isddesign.atlassian.net/browse/TH-7181
    <HeraFormBtn class="btn-primary" @click.native="openPopoverMenu($event)">
      {{ $t('_web_communications_create', 'Create New') }}
    </HeraFormBtn>
    <PopoverMenu
      :position="popoverPosition"
      :closest="$el"
      :float="['right', 'top']"
      regard="top"
      :items="popoverMenuItems"
      auto-close
    >
      <span slot-scope="{ item }" @click="() => setType(item.type)">
        {{ item.title }}
      </span>
    </PopoverMenu> -->
    <ToolIcon
      v-if="miniMode"
      icon-name="edit"
      icon-size="18"
      class="h-mr-4"
      :help-text="
        $t('_web_communications_create-chat-new', 'Create a new Chat')
      "
      style="display: flex;justify-content: center;align-items: center;"
      @click.stop="() => setType(TYPE_DIALOG)"
    />
    <!-- <a
      v-if="miniMode"
      href="#"
      class="p-4 strong"
      @click.prevent="() => setType(TYPE_DIALOG)"
    >
      <AppIcon name="edit" :width="20" />
    </a> -->
    <HeraFormBtn
      v-else
      class="btn-accent-simple"
      @click.native="() => setType(TYPE_DIALOG)"
    >
      {{ $t('_web_communications_create-chat', 'Create New Chat') }}
    </HeraFormBtn>
    <ModalBackdrop
      v-model="modalModel"
      :click-on-backdrop="!isLoading"
      esc-key
      variant="narrow-list"
      container-class="modal-create-conversation-wrap d-flex flex-column"
      :class="{ 'block-loading': isLoading }"
    >
      <h1 class="modal-title w-100 text-center">
        <template v-if="conversationType === TYPE_DIALOG">
          {{ $t('_web_communications_create_dialog', 'Create new chat') }}
        </template>
        <template v-else-if="conversationType === TYPE_GROUP">
          {{ $t('_web_communications_create_group', 'Create new group chat') }}
        </template>
      </h1>

      <component
        :is="conversationType"
        v-model="conversationData"
        class="my-5"
      />
      <div class="d-flex justify-content-between p-0 flex-shrink-0">
        <HeraFormBtn class="btn-secondary" @click.prevent="closeModal">
          {{ $t('_web_communications_create_button-cancel', 'Cancel') }}
        </HeraFormBtn>
        <HeraFormBtn
          class="btn-accent"
          :disabled="!hasParticipants"
          :loading="isLoading"
          @click.native="createChat"
        >
          {{ $t('_web_communications_create_button-create', 'Create') }}
        </HeraFormBtn>
      </div>
    </ModalBackdrop>
  </div>
</template>

<script>
const TYPE_DIALOG = 'Dialog'; // dialog-component name
const TYPE_GROUP = 'Group'; // group-component name

import { mapActions, mapMutations } from 'vuex';
import AppIcon from '@/components/atoms/Icon';
import PopoverMenu from '@/components/popover/Menu';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import Dialog from './Dialog';
import Group from './Group';
import ModalBackdrop from '@/components/common/ModalBackdrop';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';

export default {
  name: 'ConversationCreate',
  inject: ['$validator'],
  components: {
    AppIcon,
    PopoverMenu,
    HeraFormBtn,
    Dialog,
    Group,
    ModalBackdrop,
    ToolIcon,
  },
  props: {
    miniMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPE_DIALOG,
      TYPE_GROUP,
      defaultGroupConversationData: {
        title: '',
        subtitle: '',
        avatar: '',
        conversation_type: 'group_chat',
        participants: [],
      },
      showModalTranslate: false,
      popoverPosition: null,
      popoverMenuItems: [
        {
          title: this.$t('_web_create-conversation_group', 'Group Chat'),
          type: TYPE_GROUP,
        },
        {
          title: this.$t('_web_create-conversation_dialog', 'Chat'),
          type: TYPE_DIALOG,
        },
      ],
      conversationType: '',
      conversationData: {}, // conversationData: {participants: [], title: '', avatar: '', type: ''}
      isLoading: false,
    };
  },
  computed: {
    modalModel: {
      set() {
        this.conversationType = '';
      },
      get() {
        return !!this.conversationType;
      },
    },
    hasParticipants() {
      const { participants } = this.conversationData;
      return !!(participants && participants.length);
    },
  },
  methods: {
    ...mapMutations(['updateChatListKey', 'setActivePopoverChat']),
    ...mapActions([
      'createConversation',
      'createGroupConversation',
      'errorNotify',
      'getConversationInfo',
    ]),
    setType(type) {
      this.conversationType = type;
      this.conversationData = { type }; // reload data
    },
    closeModal() {
      this.modalModel = false;
    },
    openPopoverMenu(event) {
      this.popoverPosition = event.srcElement.getBoundingClientRect();
    },
    createChat() {
      let promise;
      this.isLoading = true;
      const { title, subtitle, avatar, conversation_type, participants } = {
        ...this.defaultGroupConversationData,
        ...this.conversationData,
      };
      switch (this.conversationData.type) {
        case TYPE_GROUP:
          promise = this.createGroupConversation({
            title,
            subtitle,
            avatar,
            conversation_type,
            participants,
          });
          break;
        case TYPE_DIALOG:
        default:
          promise = this.createConversation(
            this.conversationData.participants[0]
          );
          break;
      }

      promise
        .then(({ id }) => {
          this.updateChatListKey();
          this.closeModal();

          if (this.miniMode) {
            return this.getConversationInfo({ conversationId: id })
              .then(({ id }) => {
                this.setActivePopoverChat(id);
              })
              .catch(this.errorNotify);
          }

          this.$router.push({
            name: 'conversations',
            params: {
              id,
            },
          });
        })
        .catch(this.errorNotify)
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.modal-create-conversation-wrap {
  width: 100%;
  .modal-title {
    font-size: 1.5rem;
  }
}
</style>
