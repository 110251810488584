<template>
  <div
    v-observe-visibility="{
      callback: visibilityHandler,
      intersection: {
        threshold: 0.4,
      },
    }"
    class="sc-post-attach-video d-flex position-relative"
    @click.prevent="clickHandler"
  >
    <div
      v-if="!isMetaLoaded"
      class="attach__loader-wrapper d-flex align-items-center"
    >
      <Loader :size="60" />
    </div>
    <video
      ref="video"
      :class="childclass ? childclass : 'sc-post-attach-video__video'"
      v-bind="{ src }"
      :poster="
        thumbnail && thumbnail.image
          ? thumbnail.image
          : thumbnailFallback
          ? thumbnailFallback
          : require('@/assets/images/background/320.jpg')
      "
      muted
      playsinline
    ></video>
    <span class="sc-post-attach-video__duration">{{ duration }}</span>
    <div class="overlay-on-video">
      <img
        src="@/assets/images/icons/play-icon.svg"
        alt="play"
        class="play-btn-video"
      />
    </div>
  </div>
</template>

<script>
import { Duration } from 'luxon';
import { mapMutations } from 'vuex';
import { get } from '@/utils/lodashUtils';
import { emptyArray } from '@/utils/factories';
import Loader from '@/components/common/Loader';

export default {
  components: { Loader },
  props: {
    childclass: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      required: true,
    },
    mediaGroup: {
      type: Array,
      default: emptyArray,
    },
    thumbnail: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      duration: '',
      isMetaLoaded: false,
      playAfterMetaLoading: false,
      thumbnailFallback: '',
    };
  },

  mounted() {
    this.getThumb();
    this.$refs.video.addEventListener('loadedmetadata', () => {
      this.isMetaLoaded = true;
      const millisecsInSecond = 1000;
      const videoDuration = get(this, '$refs.video.duration', 0);
      this.duration = Duration.fromMillis(
        videoDuration * millisecsInSecond
      ).toFormat('hh:mm:ss');

      if (this.playAfterMetaLoading) {
        this.play();
      }
    });
  },

  methods: {
    ...mapMutations('mediaplayer', ['openPlayer']),

    visibilityHandler(visible) {
      if (visible) {
        this.play();
      } else {
        this.stop();
      }
    },

    play() {
      if (!this.isMetaLoaded) {
        // return (this.playAfterMetaLoading = true);
      }
      if (this.$refs.video) {
        // this.$refs.video.play();
      }
    },

    stop() {
      this.$refs.video.pause();
      this.playAfterMetaLoading = false;
    },
    async getThumb() {
      const response = await fetch(
        this.src.replace(/original\.(mp4|mov|3gp)/, 'thumbnail.jpg')
      );
      if (response.status === 200) {
        return (this.thumbnailFallback = this.src.replace(
          /original\.(mp4|mov|3gp)/,
          'thumbnail.jpg'
        ));
      } else {
        return;
      }
    },
    clickHandler() {
      this.stop();
      this.openPlayer({
        fullScreen: true,
        type: 'video',
        url: this.src,
        currentTime: this.$refs.video.currentTime,
        //onHide: time => {
        // this.$refs.video.currentTime = time;
        // this.$refs.video.play();
        //},
        mediaGroup: this.mediaGroup,
      });
    },
  },
};
</script>

<style lang="scss">
.sc-post-attach-video {
  cursor: pointer;

  &__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 258px;
    border-radius: $ph-small-radius;
    background-color: $ph-card;
  }

  &__duration {
    position: absolute;
    bottom: 0;
    display: block;
    font-size: $ph-caption-bold-font-size;
    line-height: $ph-caption-bold-line-height;
    padding: 0 $ph-tiny-space;
    color: $ph-secondary-text;
    background-color: $ph-card;
    border-bottom-right-radius: $ph-small-radius;
  }
}
.overlay-on-video {
  position: absolute !important;
  background-color: rgba(0, 0, 0, 0.377);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.055);
  }
  .play-btn-video {
    width: 40px !important;
    height: 40px !important;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="VideoPreview"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    src: {
      default: text('src', 'https://interactive-examples.mdn.mozilla.net/media/examples/flower.mp4')
    },
  }"
>
  <div class="row">
    <div class="col-4 offset-4">
      <VideoPreview v-bind="{src}"/>
    </div>
  </div>
</story>
