<template>
  <div class="d-flex w-100 flex-column justify-content-center">
    <div class="verify-code-container">
      <div v-if="regEmail">
        <h2 class="text-center">
          {{ $$t('Verify your email') }}
        </h2>
      </div>
      <div v-else-if="regPhone">
        <p class="mb-0">
          {{
            $$t(
              'Please, enter confirmation code here. We have sent SMS to your phone:',
              'footer-text-phone'
            )
          }}
        </p>
        <p class="text-light font-weight-bold font-size-pre-lg">
          +{{ regPhone }}
        </p>
      </div>
      <ResendCode
        v-if="resendCodeBind"
        v-bind="resendCodeBind"
        :action="sendCode"
        :action-data="templateRegData"
        @resended="refreshCode"
      />
      <InputVerify
        v-model="code"
        class=" "
        tag="form"
        auto-send
        :count="$options.verifyCodeCount"
        @onSubmit="formSubmit"
        @error="formError"
      />
      <div class="text-danger text-center">
        {{
          errorText ||
            (errorRequest &&
              $$t(
                'Invalid code. Please try again.',
                '_web_server_errors_invalid_verification_code'
              ))
        }}
      </div>
    </div>

    <ModalBackdrop v-model="isLoading" class="verify-code-loader">
      <Loader />
    </ModalBackdrop>
    <div></div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import isLoading from '@/mixins/isLoading';
import InputVerify from '@/components/form/InputVerify';
import Loader from '@/components/common/Loader';
import ModalBackdrop from '@/components/common/ModalBackdrop';
import ResendCode from '@/components/text/ResendCode';
// import HeraLogo from '@/components/atoms/HeraLogo';
// import BackBtn from '@/components/common/GoBackBtn';

let routerNameTo;

export default {
  name: 'Verification',
  components: {
    ResendCode,
    ModalBackdrop,
    Loader,
    InputVerify,
    // BackBtn,
    // HeraLogo,
  },
  inject: ['$validator'],
  mixins: [isLoading, translate('_web_verify-code')],
  data() {
    return {
      code: '',
      errorRequest: null,
      errorText: null,
      resendTimer: 0,
      intervalTimer: 0,
      sectionWidth: 0,
    };
  },
  computed: {
    ...mapGetters(['templateRegData']),
    regEmail() {
      return this.$lodash.get(this, 'templateRegData.email') || '';
    },
    regPhone() {
      return this.$lodash.get(this, 'templateRegData.phone') || '';
    },
    resendCodeBind() {
      if (this.regEmail) {
        return {
          timeoutTextTranslateKey: this.$$tCreateKey('timeout-text_email'),
          timeoutTextDefault:
            'We got your request. Re-send button will be available in {timer} sec.',
          resendTextTranslateKey: this.$$tCreateKey('retry-text_email'),
          resendTextDefault:
            'We sent you the verification code on your email. Enter the code to verify your account. Didn`t receive the code? {:resend}',
        };
      } else if (this.regPhone) {
        return {
          timeoutTextTranslateKey: this.$$tCreateKey('timeout-text_phone'),
          timeoutTextDefault:
            'We got your request. Re-send button will be available in {timer} sec.',
          resendTextTranslateKey: this.$$tCreateKey('retry-text_phone'),
          resendTextDefault: "If you didn't get SMS, try to {:resend} it.",
        };
      }

      return null;
    },
  },
  beforeRouteLeave({ name }, from, next) {
    routerNameTo = name;
    this.errors.clear();
    next();
  },
  created() {
    this.$options.verifyCodeCount = 4;
  },
  destroyed() {
    if (
      routerNameTo &&
      routerNameTo !== 'signup' &&
      routerNameTo !== 'passwordReset'
    ) {
      this.saveRegData(null);
    }
  },
  methods: {
    ...mapActions(['verifyUser', 'createProfile', 'sendCode', 'errorNotify']),
    ...mapMutations(['saveRegData']),
    formSubmit(code) {
      this.sectionWidth = this.$el.closest('.view-content-wrapper').offsetWidth;
      this.setLoading();
      this.errorRequest = null;
      this.$validator
        .validateAll()
        .then(
          result =>
            result
              ? Promise.resolve({
                  code, // or code: this.code
                  from: this.$route.params.from || '',
                  ...this.templateRegData,
                })
              : Promise.reject() // eslint-disable-line
        )
        .then(this.verifyUser)
        .then(this.unsetLoading)
        .then(() => {
          if (this.$route.params.from === 'signup') {
            this.createProfile().then(() => {
              // this.$router.push(this.$config.defaultRoute);
              this.$router.push({ name: 'profileSetup' });
            });
          } else {
            this.$router.push({ name: 'submitPassword' });
          }
        })
        .catch(err => {
          this.unsetLoading();
          this.code = '';
          this.errorRequest = err.message || err;
          const from = this.$route.params.from;
          if (
            err === this.$t('_web_session_expired_error', 'Session expired') &&
            from
          ) {
            this.errorNotify(err);
            this.$nextTick(() => this.$router.push({ name: from }));
          }
        });
    },
    formError(err) {
      this.code = '';
      this.errorText = err;
    },
    refreshCode() {
      this.code = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.verify-code-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.verify-code-loader {
  width: 100vw !important;
}
</style>
