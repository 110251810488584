import api from '@/api';

const state = {
  posts: [],
};
const mutations = {
  addPost(state, data) {
    state.posts = [data, ...state.posts];
  },
  deletePost(state, id) {
    state.posts = state.posts.filter(item => item.id !== id);
  },

  setPosts(state, data) {
    if (data.items) {
      state.posts = [...state.posts, ...data.items];
    }
  },
};

const actions = {
  getPostDetails(
    {
      getters: { accessToken },
    },
    postId
  ) {
    return new Promise((resolve, reject) => {
      api.posts
        .getPost(accessToken, postId)
        .then(res => resolve(res.data), err => reject(err));
    });
  },
  fetchUserNewsFeeds(
    {
      getters: { accessToken },
    },
    params = {}
  ) {
    return api.newsfeeds
      .getNewsFeeds(accessToken, { params })
      .then(res => res.data);
  },

  fetchTrendingNewsFeeds(
    {
      getters: { accessToken },
    },
    params = {}
  ) {
    return api.newsfeeds
      .getNewsFeedTrending(accessToken, { params })
      .then(res => res.data);
  },

  fetchNewsFeedsByTag(
    {
      getters: { accessToken },
    },
    params = {}
  ) {
    return api.newsfeeds
      .getNewsByTag(accessToken, { params })
      .then(res => res.data);
  },

  fetchPopularPlayers({ getters: { accessToken } }) {
    return api.newsfeeds.getPopularPlayers(accessToken).then(res => res.data);
  },

  createPost({ commit, getters }, post) {
    return new Promise((resolve, reject) => {
      api.posts
        .createPost(getters.accessToken, post.send)
        .then(response => {
          let newPost = { ...post.display, id: response.data.post_Id };
          commit('addPost', newPost);
          resolve(newPost);
        })
        .catch(error => reject(error));
    });
  },
  updatePost({ commit, getters }, post) {
    return new Promise((resolve, reject) => {
      api.posts
        .updatePost(post.id)(getters.accessToken, post.send)
        .then(response => {
          let newPost = { ...post.display, id: response.data.post_Id };
          commit('addPost', newPost);
          resolve(newPost);
        })
        .catch(error => reject(error));
    });
  },
  createPollPost(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.posts
      .createPollPost(accessToken, payload)
      .then(({ data: { post_Id } }) => api.posts.getPost(accessToken, post_Id))
      .then(({ data: { post } }) => post);
  },
  votePollPost(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.posts.votePollPost(payload)(accessToken);
  },

  deletePost({ commit, getters }, id) {
    return api.posts
      .deletePost(id)(getters.accessToken)
      .then(response => {
        commit('deletePost', id);
        return response;
      })
      .catch(error => error);
  },
  togglePostPin({ getters }, post) {
    const action = post.pinned ? 'unpinPost' : 'pinPost';

    return api.posts[action](post.id)(getters.accessToken)
      .then(response => response)
      .catch(error => error);
  },
  likePost({ getters }, post) {
    let params = post.reacted
      ? [getters.accessToken, { type: 'none' }]
      : [getters.accessToken, { type: post.type }];

    return new Promise((resolve, reject) => {
      api.posts
        .likePost(post.id)(...params, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  fetchUserWall(
    {
      getters: { accessToken },
    },
    { userName, pageToken }
  ) {
    return api.posts.getWall(userName, pageToken)(accessToken);
  },

  repost(
    {
      getters: { accessToken },
    },
    params
  ) {
    return api.posts.postRepost(accessToken, params);
  },
  getOpenGraphDataByLink(
    {
      getters: { accessToken },
    },
    url
  ) {
    return api.posts
      .getLinkPreviewData(accessToken, { params: { url } })
      .then(({ data }) => data);
  },
};

export default {
  state,
  mutations,
  actions,
};
