<template>
  <div>
    <MainLayout>
      <div class="privacy-policy-mena">
        <div class="privacypolicy-container">
          <h1 class="text-center"><b>سياسة الخصوصية في :Playhera </b></h1>
          <h2><b>ملخص لكيفية استخدامنا لبياناتك </b></h2>
          <ul class="disc">
            <li>
              شركة المحتوى الترفيهي يشار إليها أيضًا باسم "Playhera" "نحن"
              ")تستخدم بياناتك الشخصية لتشغيل منصة "Playhera" ( المتوفرة حاليًا
              كمتجر ioS أو Android أو على [www.playhera.com] او من خلال التطبيق
            </li>
            <li>
              عندما تلعب على المنصة، سنستخدم بياناتك بشكل خاص للسماح بتسجيلك
              للمنصة، ولإدارة مشاركتك في المسابقات المنظمة على المنصة ، وللترويج
              والاحتفاظ بسجلات المسابقات التي تتم إدارتها على المنصة. ولإعلامك
              بالمسابقات أو الأحداث القادمة التي قمت بالتسجيل فيها ولإدارة أي
              نزاعات بين اللاعبين أو اتصالات أو شكاوى أخرى. نحن نستخدم التحقق
              عبر الرسائل القصيرة ، من خلال شركة تدعى Twilio ، كجزء من عملية
              التسجيل لدينا.
            </li>
            <li>
              ستتم مشاركة بياناتك مع جهات خارجية عند استخدامك للمنصة. على وجه
              الخصوص ، ستتم مشاركة معلوماتك مع الآخرين الذين تتفاعل معهم في
              التطبيق ، أو أولئك الذين تختار اللعب ضدهم. عندما تنضم إلى
              المسابقات أو الأحداث ، ستتم مشاركة معلوماتك مع منظمي تلك المسابقات
              أو الأحداث. قد يسمح هذا للمنظمين بالاتصال بك أو إرسال إشعارات أو
              تحديثات إليك. يمكن أيضًا مشاركة المعلومات مع الشرطة أو السلطات
              الأخرى عندما يكون هناك دليل على سلوك إجرامي أو عندما تتطلب
              الإجراءات المدنية ذلك.
            </li>
            <li>
              عندما نعتمد موافقتك، مثل التسويق المباشر أو مشاركة موقعك لخدماتنا
              القائمة على الموقع ، يمكنك سحب هذه الموافقة في أي وقت.
            </li>
            <li>
              Playheraوهي منصة عالمية قد يتم نقل معلوماتك إلى جميع أنحاء العالم
              للسماح لك بالمشاركة في تطبيقنا وأحداثنا ومسابقاتنا. تتخذ Playhera
              الخطوات المناسبة لحماية بياناتك.
            </li>
            <li>
              تحدد سياسة الخصوصية الخاصة بنا مزيدًا من التفاصيل حول هذه المعالجة
              ، بما في ذلك تفاصيل حقوق حماية البيانات الخاصة بك ، بما في ذلك حقك
              في الاعتراض على معالجة معينة.
            </li>
          </ul>
          <h2><b>ماذا تغطي هذه السياسة؟ </b></h2>
          <p>
            توضح هذه السياسة كيف ستستخدم Playhera بياناتك عند استخدامك للمنصة ،
            وإذا كنت مشاركًا ، كيف سنستخدم بياناتك عندما تشارك في اي مسابقة.
            وتصف أيضًا حقوق حماية البيانات الخاصة بك ، بما في ذلك الحق في
            الاعتراض على بعض المعالجة التي تنفذها Playhera ولمزيد من المعلومات
            حول حقوقك ، وكيفية ممارستها ، مذكورة في "ما هي الحقوق التي أمتلكها؟"
          </p>
          <h2>ما هي المعلومات التي نقوم بجمعها؟</h2>
          <p>
            نقوم بجمع ومعالجة البيانات الشخصية الخاصة بك عند تنزيل تطبيقاتنا
            واستخدامه لأول مرة، أو عند التسجيل على المنصة ، أو عند استخدام
            التطبيق أو عند إرسال معلومات لاستخدامها في أنشطة الرياضات
            الإلكترونية الخاصة بك. عادةً ما يتم توفير ذلك مباشرةً بواسطتك ، وقد
            يتضمن المعلومات التي تقدمها عند التسجيل مثل اسمك وأسماء المستخدمين
            ورقم هاتفك وعنوان بريدك الإلكتروني وتفضيلاتك. سيتم توضيح التفاصيل
            التي تقدمها في النماذج التي تكملها أو ستقدمها مباشرة على سبيل المثال
            في المحتوى الاجتماعي أو نتائج الرياضات الإلكترونية.
          </p>
          <h2>ما هي المعلومات التي نتلقاها من الطرف الثالث؟</h2>
          <ul class="disc">
            <li>
              حسب الضرورة لإدارة وتنفيذ أي عقد بيننا ، وعلى وجه الخصوص:
            </li>
            <li>
              <ul>
                <li>
                  إدارة أي مسابقات تديرها Playhera و تحديد الأهلية والفائزين
                  وتسليم الجوائز في المسابقات التي تديرها Playhera
                </li>
              </ul>
            </li>
            <li>
              وفقًا لما تتطلبه Playhera لإجراء أعمالنا ومتابعة مصالحنا المشروعة
              ، على وجه الخصوص:
            </li>
            <li>
              <ul>
                <li>
                  سنستخدم معلوماتك لتوفير وإدارة المنصة ، بما في ذلك الإشراف على
                  التطبيق والرد على أي تعليقات أو شكاوى قد ترسلها إلينا
                </li>
                <li>
                  نحن نراقب استخدام موقعنا الإلكتروني وخدماتنا عبر الإنترنت ،
                  ونستخدم معلوماتك لمساعدتنا في مراقبة منتجاتنا ومحتوياتنا
                  وخدماتنا وموقعنا الإلكتروني وتحسينه وحمايته ، سواء عبر
                  الإنترنت أو عبر الأوفلاين
                </li>
                <li>
                  ستخدم المعلومات التي تقدمها لتخصيص موقعنا الإلكتروني أو
                  منتجاتنا أو خدماتنا لك
                </li>
                <li>
                  نراقب حسابات اللاعبين لدينا لمنع ، والتحقيق و / أو الإبلاغ عن
                  الاحتيال أو الإرهاب أو التحريف أو الحوادث الأمنية أو السلوك
                  غير القانوني ، وفقًا للقانون المعمول به
                </li>
                <li>
                  نستخدم المعلومات التي تقدمها للتحقيق في أي شكاوى وردت منك أو
                  من الآخرين ، حول المنصة أو حول سلوك اللاعبين على المنصة (بما
                  فيهم أنت)
                </li>
                <li>
                  سنستخدم البيانات فيما يتعلق بالمطالبات القانونية والامتثال
                  والأغراض التنظيمية والتحقيق حسب الضرورة (بما في ذلك الكشف عن
                  هذه المعلومات فيما يتعلق بالإجراءات القانونية أو التقاضي)
                </li>
                <li>
                  نستخدم بيانات بعض الأفراد لدعوتهم للمشاركة في أبحاث السوق
                </li>
                <li>
                  استخدام لوحة النتائج والجائزة ومعلومات اللاعب الأخرى للترويج
                  للمنصة.
                </li>
              </ul>
            </li>
            <li>حين تمنحنا موافقتك:</li>
            <li>
              <ul>
                <li>
                  سوف نرسل لك إشعارات تسويقية أو إخطارات متعلقة بالمنصة ، أو
                  نسمح لمنظمي الأحداث أو المسابقات بإرسال إخطار أو تسويق عن
                  أحداثهم
                </li>
                <li>
                  نضع ملفات تعريف الارتباط ونستخدم تقنيات مماثلة ، وفقًا لقسم
                  ملفات تعريف الارتباط والتقنيات المماثلة.
                </li>
                <li>
                  في المناسبات الأخرى التي نطلب فيها منك الموافقة ، سنستخدم
                  البيانات للغرض الذي نشرحه في ذلك الوقت
                </li>
              </ul>
            </li>
            <li>الأغراض التي يقتضيها القانون:</li>
            <li>
              <ul>
                <li>
                  حيث نحتاج إلى موافقة الوالدين لتقديم خدمات عبر الإنترنت
                  للأطفال دون سن 16 عامًا.
                </li>
                <li>
                  استجابة لطلبات الحكومة أو سلطات إنفاذ القانون التي تجري
                  تحقيقًا
                </li>
                <li>الاحتفاظ بأي سجلات كما يقتضي القانون.</li>
              </ul>
            </li>
          </ul>
          <h2>سحب الموافقة أو الاعتراض</h2>
          <p>
            أينما نعتمد على موافقتك ستتمكن دائمًا من سحب هذه الموافقة ، على
            الرغم من أنه قد يكون لدينا أسباب قانونية أخرى لمعالجة بياناتك لأغراض
            أخرى ، مثل تلك المذكورة أعلاه. يمكنك القيام بذلك عن طريق اتباع
            التعليمات الواردة في الاتصال حيث تكون هذه رسالة إلكترونية ، أو عن
            طريق الاتصال بنا باستخدام التفاصيل الموضحة أدناه.
          </p>
          <h2>ملفات تعريف الارتباط والتقنيات:</h2>
          <p>
            ملفات تعريف الارتباط هي أجزاء صغيرة من المعلومات يرسلها خادم الويب
            إلى متصفح الويب مما يسمح للخادم بتحديد المتصفح بشكل فريد في كل صفحة.
            تُستخدم أيضًا تقنيات التتبع الأخرى التي تشبه ملفات تعريف الارتباط.
            يمكن أن يشمل ذلك علامات البكسل وعناوين URL للتتبع. يشار إلى كل هذه
            التقنيات معًا في هذه السياسة باسم
            <strong>"ملفات تعريف الارتباط".</strong>.
          </p>
          <p>
            أنواع ملفات تعريف الارتباط التي قد نستخدمها على موقعنا ، والأغراض
            التي تستخدم من أجلها ، موضحة أدناه:
          </p>
          <ul>
            <li>
              <strong>ملفات تعريف الارتباط الضرورية: </strong>تعد ملفات تعريف
              الارتباط هذه ضرورية: لتمكينك من التنقل في موقعنا واستخدام ميزاته ،
              مثل الوصول إلى المناطق الآمنة في موقعنا. بدون ملفات تعريف الارتباط
              هذه ، لا يمكن توفير أي خدمات ترغب في الوصول إليها على منصتنا.
            </li>
            <li>
              <strong>ملفات تعريف الارتباط التحليلية / الخاصة بالأداء:</strong>
              تجمع ملفات تعريف الارتباط هذه معلومات حول كيفية استخدامك أنت
              والزائرين الآخرين لمنصتنا ، على سبيل المثال الصفحات التي تزورها
              غالبًا ، وما إذا كنت تتلقى رسائل خطأ من صفحات الويب. نحن نستخدم
              البيانات من ملفات تعريف الارتباط هذه للمساعدة في اختبار التصميمات
              ولضمان الحفاظ على الشكل والمظهر المتسقين أثناء زيارتك للمنصة. يتم
              تجميع جميع المعلومات التي تجمعها ملفات تعريف الارتباط هذه. يتم
              استخدامه فقط لتحسين كيفية عمل النظام الأساسي. نحن نستخدم Google
              Analytics ، على سبيل المثال ، لتتبع استخدام موقع الويب ونشاطه بشكل
              مجهول.
            </li>
            <li>
              <strong>ملفات تعريف الارتباط الوظيفية: </strong> تسمح ملفات تعريف
              الارتباط هذه لموقعنا الإلكتروني بتذكر الاختيارات التي تقوم بها
              (مثل اسم المستخدم أو اللغة أو المنطقة التي تتواجد فيها) وتوفير
              ميزات محسنة وأكثر شخصية. يمكن أيضًا استخدام ملفات تعريف الارتباط
              هذه لتذكر التغييرات التي أجريتها على حجم النص والخطوط وأجزاء أخرى
              من صفحات الويب التي يمكنك تخصيصها. قد تكون المعلومات التي تجمعها
              ملفات تعريف الارتباط هذه مجهولة المصدر ولا يمكنها تتبع نشاط التصفح
              الخاص بك على مواقع الويب الأخرى.
            </li>
            <li>
              نحن ، على سبيل المثال ، نستخدم ملف تعريف الارتباط Doubleclick.
              تُستخدم ملفات تعريف الارتباط هذه أيضًا للحد من عدد المرات التي
              تشاهد فيها إعلانًا وكذلك للمساعدة في قياس فعالية الحملة الإعلانية.
              عادة ما يتم وضعها عن طريق شبكات الإعلانات بإذن من مشغل موقع الويب.
              يتذكرون أنك قمت بزيارة موقع ويب وتتم مشاركة هذه المعلومات مع
              منظمات أخرى مثل المعلنين. غالبًا ما يتم ربط ملفات تعريف الارتباط
              المستهدفة أو الإعلانية بوظيفة الموقع التي توفرها المؤسسة الأخرى.
            </li>
            <li>
              <strong
                >لفات تعريف الارتباط الخاصة بوسائل التواصل الاجتماعي:</strong
              >
              تتيح لك ملفات تعريف الارتباط هذه مشاركة ما كنت تفعله على موقعنا
              على الشبكات الاجتماعية مثل فيس-بوك و تويتر. ملفات تعريف الارتباط
              هذه ليست ضمن سيطرتنا. يرجى الرجوع إلى سياسات الخصوصية الخاصة
              بكيفية عمل ملفات تعريف الارتباط الخاصة بهم.
            </li>
            <li>
              <strong>علامات البكسل:</strong> تُعرف أيضًا باسم GIF. هذه علامات
              غير مرئية يتم وضعها على صفحات معينة من موقعنا على الويب ولكن ليس
              على جهاز الكمبيوتر الخاص بك. عند الوصول إلى هذه الصفحات ، تنشئ
              علامات البكسل إشعارًا عامًا لتلك الزيارة. تعمل عادةً جنبًا إلى جنب
              مع ملفات تعريف الارتباط ، حيث تقوم بالتسجيل عندما يزور جهاز معين
              صفحة معينة. يتم استخدامها ، من بين أشياء أخرى ، لتتبع إجراءات
              مستخدمي خدماتنا (بما في ذلك مستلمي البريد الإلكتروني) ، وقياس نجاح
              حملاتنا التسويقية وتجميع إحصاءات حول استخدام خدماتنا ومعدلات
              الاستجابة. إذا قمت بإيقاف تشغيل ملفات تعريف الارتباط ، فستكتشف
              علامة البكسل ببساطة زيارة موقع ويب مجهول. إذا كنت لا تريد السماح
              بملفات تعريف الارتباط على الإطلاق ، أو ترغب فقط في السماح باستخدام
              ملفات تعريف معينة ، فيرجى الرجوع إلى إعدادات المستعرض الخاص بك.
              يمكنك أيضًا استخدام إعدادات المستعرض الخاص بك لسحب موافقتك على
              استخدامنا لملفات تعريف الارتباط في أي وقت وحذف ملفات تعريف
              الارتباط التي تم تعيينها بالفعل. يرجى ملاحظة أنه من خلال حذف ملفات
              تعريف الارتباط الخاصة بنا أو تعطيل ملفات تعريف الارتباط المستقبلية
              ، قد لا تتمكن من الوصول إلى مناطق أو ميزات معينة على موقعنا.
            </li>
          </ul>
          <p>
            لمعرفة المزيد حول ملفات تعريف الارتباط ، يرجى زيارة:
            <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>
            أو الاطلاع على
            <a href="http://www.youronlinechoices.eu"
              >www.youronlinechoices.eu</a
            >
            الذي يحتوي على مزيد من المعلومات حول الإعلان السلوكي والخصوصية عبر
            الإنترنت.
          </p>
          <h2>كيف تتم مشاركة البيانات وأين ومتى؟</h2>
          <p>
            سنشارك بياناتك مع منظمي المسابقات والفعاليات حيث تختار المشاركة في
            مسابقاتهم وأحداثهم. سيسمح لهم ذلك بإدارة مشاركتك ، والتواصل معك داخل
            المنصة و (في حالة موافقتك على الإشعارات) لإرسال الإشعارات والتذكيرات
            لك. نمنح أيضًا إمكانية الوصول إلى بياناتك لاتحادات الرياضات
            الإلكترونية ورعاة الأحداث حيث يكونون مرتبطين بمسابقة أو حدث دخلت فيه
            حتى يتمكنوا من إجراء تحليل للسوق . تُمنح اتحادات الرياضات
            الإلكترونية أيضًا وصولاً أوسع إلى المعلومات حول المستخدمين في
            أراضيها حتى يتمكنوا من فهم لاعبيهم بشكل أفضل. يمكن مشاركة البيانات
            الشخصية مع السلطات الحكومية و / أو مسؤولي إنفاذ القانون إذا لزم
            الأمر للأغراض المذكورة أعلاه ، إذا كان القانون ينص على ذلك أو إذا
            لزم الأمر للحماية القانونية لمصالحنا المشروعة بما يتوافق مع القوانين
            المعمول بها.يمكن مشاركة البيانات الشخصية مع مؤسسات أبحاث السوق حتى
            يتمكنوا من مساعدتنا في فهم أفضل للاعبينا واهتماماتهم. ستتم أيضًا
            مشاركة البيانات الشخصية مع مزودي خدمات الطرف الثالث ، الذين
            سيعالجونها نيابة عن Playhera للأغراض المحددة أعلاه. على وجه الخصوص ،
            نستخدم مزودي تطوير التطبيقات وموفري خدمة إدارة حملات البريد واستضافة
            مواقع الويب وخدمات التحقق من الرسائل القصيرة وحلول دعم العملاء من
            جهات خارجية. في حالة بيع النشاط التجاري أو دمجه مع عمل آخر ، سيتم
            الكشف عن التفاصيل الخاصة بك لمستشارينا وأي مستشار مشتري محتمل وسيتم
            تمريرها إلى المالكين الجدد للشركة.
          </p>
          <h2>ما هي الحقوق التي أمتلكها؟</h2>
          <p>
            اعتمادًا على بلدك ، قد يكون لديك عدد من الحقوق المختلفة. في بعض
            البلدان ، يحق لك أن تطلب منا نسخة من بياناتك الشخصية ؛ لتصحيح أو حذف
            أو تقييد (إيقاف أي معالجة نشطة) لبياناتك الشخصية وللحصول على
            البيانات الشخصية التي تقدمها لنا لعقد أو بموافقتك بتنسيق منظم ومقروء
            آليًا ومطالبتنا بمشاركة (نقل) هذه البيانات إلى وحدة تحكم أخرى.
            بالإضافة إلى ذلك ، قد تتمكن أيضًا من الاعتراض على معالجة بياناتك
            الشخصية في بعض الظروف (على وجه الخصوص ، حيث لا يتعين علينا معالجة
            البيانات لتلبية متطلبات تعاقدية أو متطلبات قانونية أخرى ، أو عندما
            نستخدم البيانات للتسويق المباشر).
          </p>
          <p>
            قد تكون هذه الحقوق محدودة ، على سبيل المثال إذا كان تنفيذ طلبك سيؤدي
            إلى الكشف عن بيانات شخصية عن شخص آخر ، حيث قد تنتهك حقوق طرف ثالث
            (بما في ذلك حقوقنا) أو إذا طلبت منا حذف المعلومات التي يطلب منا
            القانون القيام بها للاحتفاظ بها أو لديك مصالح مشروعة مقنعة في الحفاظ
            عليها. يتم تضمين الإعفاءات ذات الصلة في القوانين المحلية. سنبلغك
            بالإعفاءات ذات الصلة التي نعتمد عليها عند الرد على أي طلب تقدمه.
            لممارسة أي من هذه الحقوق ، يمكنك الاتصال بنا باستخدام التفاصيل
            الموضحة أدناه. إذا كانت لديك مخاوف لم يتم حلها ، فيحق لك تقديم شكوى
            إلى سلطة مختصة. قد تكون هذه سلطة حماية البيانات في المكان الذي تعيش
            فيه أو تعمل فيه أو حيث تعتقد أن الانتهاك قد يكون قد حدث وسنخبرك
            دائمًا بالمعلومات التي تقدمها إلزامية. المعلومات التي يتم توفيرها
            لإكمال عمليات التسجيل لدينا ، بما في ذلك التحقق من الرسائل القصيرة
            إلزامية كما هي نتيجة دقيقة للتركيبات المنظمة على المنصة. عدم تقديم
            هذه المعلومات يعني أنك غير قادر على التسجيل أو أنك تنتهك شروط
            الاستخدام الخاصة بنا.
          </p>
          <h2>كيف يمكنني التواصل معك؟</h2>
          <p>
            نأمل أن نتمكن من تلبية الاستفسارات التي قد تكون لديك حول الطريقة
            التي نعالج بها بياناتك. إذا كانت لديك أي مخاوف بشأن كيفية معالجتنا
            لبياناتك ، أو ترغب في إلغاء الاشتراك فيمكنك الاتصال على
            <a :href="`mailto:${mailSupport}`">[{{ mailSupport }}]</a> أو عن
            طريق الكتابة إلى العنوان:
          </p>
          <address>
            3671 طريق الثمامة حي الربيع - وحدة 8 <br />
            الرياض 13316 - 8536 <br />
            المملكة العربية السعودية <br />
            اسم الشركة: Entertainment Content Co <br />
          </address>
          <p></p>
          <h2>كم من الوقت سوف تحتفظ ببياناتي؟</h2>
          <p>
            نقوم بتخزين المعلومات التي قدمتها على المنصة حتى تصبح غير ضرورية
            للسماح لك بالوصول إلى خدماتنا أو حتى يتم حذف حسابك - أيهما يأتي
            أولاً. عندما نعالج البيانات الشخصية لأغراض تسويقية أو بموافقتك ،
            فإننا نعالج البيانات حتى تطلب منا التوقف ولفترة قصيرة بعد ذلك
            (للسماح لنا بتنفيذ طلباتك). نحتفظ أيضًا بسجل لحقيقة أنك طلبت منا عدم
            إرسال رسائل تسويقية مباشرة أو معالجة بياناتك إلى أجل غير مسمى حتى
            نتمكن من احترام طلبك في المستقبل.
          </p>
          <p>
            سيتم الاحتفاظ بالمعلومات حول نتائج Playhera (مثل من سجل النقاط)
            والصور الثابتة لأسلوب اللعب المقدم من المستخدم إلى أجل غير مسمى
            للاحتفاظ بسجل للمسابقات التي تم لعبها على المنصة والتأكد من إمكانية
            التحقق من النتائج.
          </p>
          <h2>
            وفقًا لما تتطلبه Playhera لإجراء أعمالنا ومتابعة مصالحنا المشروعة ،
            على وجه الخصوص:
          </h2>
          <ul>
            <li>
              سنستخدم معلوماتك لتوفير وإدارة المنصة ، بما في ذلك الإشراف على
              منتدياتنا الاجتماعية ، والرد على أي تعليقات أو شكاوى قد ترسلها
              إلينا ؛
            </li>
            <li>
              نحن نراقب استخدام مواقعنا الإلكترونية وخدماتنا عبر الإنترنت ،
              ونستخدم معلوماتك لمساعدتنا في مراقبة منتجاتنا ومحتوياتنا وخدماتنا
              ومواقعنا الإلكترونية وتحسينها وحمايتها ، سواء عبر الإنترنت أو في
              وضع عدم الاتصال ؛
            </li>
            <li>
              نستخدم المعلومات التي تقدمها لتخصيص موقعنا الإلكتروني أو منتجاتنا
              أو خدماتنا لك ؛
            </li>
            <li>
              نراقب حسابات اللاعبين لدينا لمنع ، والتحقيق و / أو الإبلاغ عن
              الاحتيال أو الإرهاب أو التحريف أو الحوادث الأمنية أو السلوك غير
              القانوني ، وفقًا للقانون المعمول به ؛
            </li>
            <li>
              نستخدم المعلومات التي تقدمها للتحقيق في أي شكاوى وردت منك أو من
              الآخرين ، حول المنصة أو حول سلوك اللاعبين على المنصة (بما فيهم
              أنت) ؛
            </li>
          </ul>
          <p>
            سنستخدم البيانات فيما يتعلق بالمطالبات القانونية والامتثال والأغراض
            التنظيمية والتحقيق حسب الضرورة (بما في ذلك الكشف عن هذه المعلومات
            فيما يتعلق بالإجراءات القانونية أو التقاضي) ؛ نستخدم بيانات بعض
            الأفراد لدعوتهم للمشاركة في أبحاث السوق ؛ و استخدام لوحة النتائج
            والجائزة ومعلومات اللاعب الأخرى للترويج للمنصة.
          </p>
          <p class="text-center"><b>الملحق 1 </b></p>
          <h1 class="text-center">سياسة خصوصية متجر Playhera</h1>
          <ul>
            <li>
              <p>
                قد يتم تغيير أو تحديث سياسات الموقع الإلكتروني والشروط والأحكام
                من حين لآخر لتلبية المتطلبات والمعايير. لذلك يتم تشجيع العملاء
                على زيارة هذه الأقسام بشكل متكرر حتى يتم تحديثهم بشأن التغييرات
                على موقع الويب. ستكون التعديلات سارية المفعول في يوم نشرها.
              </p>
            </li>
            <li>
              <p>
                يتم تحديد بعض الإعلانات التي تراها على الموقع وتسليمها بواسطة
                جهات خارجية ، مثل شبكات الإعلانات ووكالات الإعلان والمعلنين
                وموفري شرائح الجمهور. قد تجمع هذه الجهات الخارجية معلومات عنك
                وعن أنشطتك عبر الإنترنت ، إما على الموقع أو على مواقع الويب
                الأخرى ، من خلال ملفات تعريف الارتباط وإشارات الويب والتقنيات
                الأخرى في محاولة لفهم اهتماماتك وتقديم إعلانات مخصصة لك. يرجى
                تذكر أننا لا نستطيع الوصول إلى المعلومات التي قد تجمعها هذه
                الأطراف الثالثة أو التحكم فيها. لا تغطي سياسة الخصوصية هذه
                ممارسات المعلومات الخاصة بهذه الأطراف الثالثة.
              </p>
            </li>
            <li>
              <p>باستخدام موقعنا ، فإنك توافق على سياسة الخصوصية الخاصة بنا.</p>
            </li>
          </ul>
          <h2>معالجة بيانات العميل في شركتنا</h2>
          <p>
            ("Playhera" أو "نحن") ، في تشغيل منصة Playhera (المتوفرة كتطبيق iOS
            / Android أو حاليًا على [www.playhera.com]) ("النظام الأساسي"):
            نستخدم بياناتك الشخصية. عند اللعب على المنصات ، تُستخدم بياناتك بشكل
            أساسي للسماح بالتسجيل ، وتشغيل المنتديات الاجتماعية والإشراف عليها ،
            وإدارة إدخالات المنافسة ، والحفاظ على العروض الترويجية وسجلات
            المنافسة ، وإخطارك بالمسابقات والأحداث التي سجلت فيها ، وتستخدم
            لإدارة الخلافات والتفاعلات الأخرى بين اللاعبين أو الشكاوى. نحن
            نستخدم مصادقة الرسائل القصيرة مع Twilio أثناء عملية التسجيل. عند
            استخدام المنصة ، ستتم مشاركة بياناتك مع أطراف ثالثة. سيكون المستلمون
            الأساسيون هم أولئك الذين تتفاعل معهم في منتدياتنا الاجتماعية والخصوم
            المختارين. إذا كنت تشارك في مسابقات أو أحداث ، فستتم مشاركة معلوماتك
            مع هؤلاء المنظمين. يسمح هذا لمنظمي المباريات والأحداث بإرسال
            المراسلات والإشعارات والتحديثات إليك. يجوز لنا أيضًا مشاركة معلوماتك
            مع الشرطة أو السلطات الأخرى حيث يوجد دليل على نشاط إجرامي أو عند
            الاقتضاء في الإجراءات المدنية. فيما يتعلق بمشاركة المعلومات بناءً
            على موافقتك ، مثل مشاركة الموقع للتسويق المباشر أو توفير خدماتنا
            المستندة إلى الموقع ، يمكنك سحب هذه الموافقة في أي وقت. Playhera هي
            منصة عالمية. لذلك ، قد ننقل معلوماتك حول العالم حتى تتمكن من
            المشاركة في منتدياتنا وفعالياتنا ومسابقاتنا. في مثل هذه الحالات ،
            ستتخذ Playhera الخطوات المناسبة لحماية بياناتك. توفر سياسة الخصوصية
            الخاصة بنا تفاصيل حول هذه المعالجة ، بما في ذلك معلومات حول حقوق
            حماية البيانات الخاصة بك ، بما في ذلك حقك في الاعتراض على معالجة
            معينة.
          </p>
          <h2>ماذا تغطي هذه السياسة؟</h2>
          <p>
            تشرح هذه السياسة كيفية استخدام Playhera لبياناتك عندما تستخدم منصتنا
            للتسجيل في البطولات، شراء السلع، او الاشتراك بالخدمات يصف أيضًا حقوق
            حماية البيانات الخاصة بك ، بما في ذلك الحق في الاعتراض على معالجة
            معينة تقوم بها Playhera. يمكن العثور على مزيد من المعلومات حول حقوقك
            وكيفية ممارسة هذه الحقوق في "ما هي الحقوق التي أمتلكها؟" الجزء.
          </p>
          <h2>ما هي المعلومات التي تجمعها Playhera؟</h2>
          <p>
            عندما تقوم بتنزيل تطبيقنا واستخدامه لأول مرة ، عندما تقوم بالتسجيل
            على منصتنا ، وعندما تستخدم منتدياتنا الاجتماعية ، وعندما ترسل
            معلومات حول أنشطة الرياضات الإلكترونية الخاصة بك ، أو شراء السلع، او
            الاشتراك بالخدمات فإننا نجمع بياناتك الشخصية ونعالجها. عادةً ما يتم
            توفير هذه المعلومات مباشرةً بواسطتك وتتضمن المعلومات التي قدمتها
            أثناء التسجيل ، مثل اسمك واسم المستخدم ورقم الهاتف وعنوان البريد
            الإلكتروني ومعلومات الدفع الالكتروني وعنوان شحن البضائع والتفضيلات
            التسويقية. قد نقوم بجمع هذه المعلومات منك باستكمال النماذج أو من
            المحتوى الاجتماعي أو نتائج دورات الرياضات الإلكترونية. <br />
            ما هي المعلومات التي تتلقاها Playhera من الأطراف الثالثة؟ قد نتلقى
            معلومات عنك من أطراف ثالثة. قد تكون هذه الجهات الخارجية ، على سبيل
            المثال ، خصومك في المسابقات أو الأحداث ، أو الأشخاص الذين تفاعلت
            معهم عبر منتدياتنا الاجتماعية ، أو الذين قدموا شكاوى بشأن سلوكك.
            يمكن أن يكون شخصًا. <br />
            نقوم أيضًا بجمع معلومات عنك من خلال استخدام ملفات تعريف الارتباط ،
            كما هو موضح في قسم "ملفات تعريف الارتباط والتقنيات المماثلة" أدناه.
            نحن نستخدم ملفات تعريف الارتباط في Google Analytics بشكل أساسي.
          </p>
          <h2>لماذا نستخدم هذه المعلومات وعلى أي أساس قانوني؟</h2>
          <p>نقوم بمعالجة البيانات الشخصية للأغراض التالية:</p>
          <ul>
            <li><p>إدارة وتنفيذ العقود المبرمة بيننا وبينك.</p></li>
            <li><p>تشغيل وإدارة المباريات التي يتم لعبها على المنصة.</p></li>
            <li>
              <p>
                تحديد الأهلية وتحديد الفائزين ومنح الجوائز في المسابقات التي
                تديرها Playhera ؛
              </p>
            </li>
            <li><p>أكمل عملية الشراء عبر الإنترنت</p></li>
            <li><p>الاشتراك في خدمة</p></li>
            <li><p>أداء أعمالنا والسعي وراء مصالحنا المشروعة.</p></li>
            <li>
              <p>
                توفير منصتنا وتشغيلها ، بما في ذلك الإشراف على المنتديات
                الاجتماعية والرد على التعليقات والشكاوى التي ترسلها إلينا ؛
              </p>
            </li>
            <li>
              <p>
                مراقبة استخدامك لمواقعنا الإلكترونية وخدماتنا عبر الإنترنت
                واستخدام معلوماتك لمراقبة منتجاتنا ومحتوياتنا وخدماتنا ومواقعنا
                الإلكترونية وتحسينها وحمايتها ، سواء عبر الإنترنت أو في وضع عدم
                الاتصال ؛
              </p>
            </li>
            <li>
              <p>
                إضفاء الطابع الشخصي على موقعنا الإلكتروني ومنتجاتنا وخدماتنا
                بناءً على المعلومات التي تقدمها
              </p>
            </li>
            <li>
              <p>
                مراقبة حسابات اللاعبين لمنع الاحتيال والإرهاب والتحريف والحوادث
                الأمنية والأنشطة غير القانونية والتحقيق فيها والإبلاغ عنها وفقًا
                للقانون المعمول به ؛
              </p>
            </li>
            <li>
              <p>
                التحقيق في الشكاوى المتعلقة بالمنصة أو سلوك اللاعبين (بما فيهم
                أنت) على المنصة ؛
              </p>
            </li>
            <li>
              <p>
                استخدام البيانات ذات الصلة للمطالبات القانونية والامتثال
                والأغراض التنظيمية والتحقيق (بما في ذلك الكشف عن المعلومات
                المطلوبة في الإجراءات القانونية أو التقاضي) ؛
              </p>
            </li>
            <li>
              <p>
                استخدام بيانات عن أفراد معينين وتشجيعهم على المشاركة في أبحاث
                السوق.
              </p>
            </li>
            <li>
              <p>
                استخدام المعلومات حول لاعبينا (مثل لوحات النتائج والجوائز)
                للترويج لمنصتنا ؛
              </p>
            </li>
            <li>
              <p>
                استخدام المعلومات حول لاعبينا (مثل لوحات النتائج والجوائز)
                للترويج لمنصتنا ؛ بموافقتك ، قدِّم معلوماتك إلى منظمي الأحداث
                والمسابقات حتى يتمكنوا من إرسال إشعارات تسويقية وإشعارات متعلقة
                بالمنصة أو إرسال إشعارات الأحداث والعروض الترويجية إليك. ضع
                ملفات تعريف الارتباط واستخدم تقنيات مماثلة كما هو موضح في قسم
                "ملفات تعريف الارتباط والتقنيات المماثلة" أدناه ؛
              </p>
            </li>
            <li><p>تعليمات لك عند السعي للحصول على موافقات أخرى.</p></li>
            <li><p>تحقيق الأغراض التي يقتضيها القانون.</p></li>
            <li>
              <p>
                عندما تكون موافقة الوالدين مطلوبة لتقديم خدمات عبر الإنترنت
                للأطفال دون سن 16 عامًا ؛
              </p>
            </li>
            <li>
              <p>
                بناء على طلب الكشف عن البيانات من قبل سلطة التحقيق الحكومية /
                الشرطة.
              </p>
            </li>
            <li><p>الاحتفاظ بالسجلات كما يقتضي القانون.</p></li>
          </ul>
          <p>
            سحب موافقتك أو رفض التسويق المباشر بأي طريقة أخرى عندما حصلنا على
            موافقتك على استخدام بياناتك ، يمكنك سحب موافقتك في أي وقت ، باستثناء
            الأسباب القانونية الموضحة أعلاه ، يمكنك على سبيل المثال ، إلغاء
            الاشتراك في التسويق المباشر أو التنميط الذي نقوم به لإجراء التسويق
            في أي وقت. إنه حقك المطلق. إذا كان التسويق المباشر عن طريق البريد
            الإلكتروني ، فيمكنك إلغاء الاشتراك باتباع الإرشادات الواردة في
            الرسالة أو عن طريق الاتصال بنا على معلومات الاتصال الخاصة بنا
            الواردة أدناه.
          </p>
          <br />
          <br />
          <p>ملفات تعريف الارتباط والتقنيات المشابهة ملف تعريف الارتباط:</p>
          <p>
            هو جزء صغير من المعلومات يرسلها خادم ويب إلى متصفح ويب يسمح للخادم
            بتحديد المتصفح بشكل فريد في كل صفحة. نستخدم أيضًا تقنيات تتبع مشابهة
            لملفات تعريف الارتباط ، مثل علامات البكسل وعناوين URL للتتبع. في هذه
            السياسة ، يشار إلى هذه التقنيات مجتمعة باسم "ملفات تعريف الارتباط".
          </p>
          <h2>
            فيما يلي أنواع ملفات تعريف الارتباط التي قد نستخدمها على موقعنا
            الإلكتروني واستخداماتها.
          </h2>
          <p>
            ملفات تعريف الارتباط الضرورية للغاية: ملفات تعريف الارتباط الضرورية
            للوصول إلى المناطق الآمنة أو التنقل خلالها في موقع الويب ولاستخدام
            وظائف الموقع. بدون ملفات تعريف الارتباط هذه ، لن تتمكن من الوصول إلى
            أي من الخدمات الموجودة على نظامنا الأساسي <br /><br /><br />. ملفات
            تعريف الارتباط التحليلية / الأداء: تجمع ملفات تعريف الارتباط هذه
            معلومات حول كيفية استخدامك أنت والزائرين الآخرين للمنصة ، مثل
            الصفحات التي تزورها كثيرًا ومتى تتلقى رسائل خطأ من صفحات الويب. تسمح
            لنا البيانات التي تم الحصول عليها من ملفات تعريف الارتباط هذه
            باختبار التصميم وتقديم شكل ومظهر متسقين في كل مرة تزور فيها المنصة.
            يتم تجميع المعلومات التي يتم جمعها بواسطة ملفات تعريف الارتباط هذه
            واستخدامها فقط لتحسين وظائف النظام الأساسي. نحن نتتبع استخدام موقع
            الويب ونشاطه على أساس مجهول الهوية ، مثل Google Analytics <br />
            <br /><br />. ملفات تعريف الارتباط الوظيفية: تسمح ملفات تعريف
            الارتباط هذه لموقعنا الإلكتروني بتذكر الاختيارات التي تقوم بها (اسم
            المستخدم ، واللغة ، والمنطقة ، وما إلى ذلك) وتقديم ميزات محسّنة
            وأكثر تخصيصًا. يمكنه أيضًا تذكر التغييرات التي تجريها على أجزاء
            قابلة للتخصيص من صفحات الويب ، مثل حجم النص والخطوط. يمكن أن تكون
            المعلومات التي تم جمعها بواسطة ملفات تعريف الارتباط هذه مجهولة
            المصدر. <br /><br /><br />
            يرجى ملاحظة أنه لا يمكن تتبع نشاط التصفح الخاص بك على مواقع الويب
            الأخرى. ملفات تعريف الارتباط المستهدفة: تُستخدم ملفات تعريف الارتباط
            هذه لتقديم إعلانات أكثر صلة بك وباهتماماتك. نحن نستخدم ملف تعريف
            الارتباط Doubleclick. <br /><br />
            تُستخدم ملفات تعريف الارتباط هذه أيضًا للحد من عدد المرات التي تشاهد
            فيها إعلانًا وقياس فعالية الحملات الإعلانية ، وعادةً ما يتم وضعها
            بواسطة شبكات الإعلانات بإذن من مشغل موقع الويب. . يتم تذكر مواقع
            الويب التي تزورها وتتم مشاركة هذه المعلومات مع مؤسسات أخرى ، مثل
            المعلنين. غالبًا ما ترتبط ملفات تعريف الارتباط الخاصة بالاستهداف /
            الإعلانات بوظيفة الموقع التي توفرها المؤسسات الأخرى. ملفات تعريف
            الارتباط الخاصة بوسائل التواصل الاجتماعي: تسمح لنا ملفات تعريف
            الارتباط هذه بمشاركة أفعالك على مواقع التواصل الاجتماعي مثل Facebook
            و Twitter. <br /><br />
            لا تخضع ملفات تعريف الارتباط هذه لسيطرتنا ، لذا يرجى الرجوع إلى
            سياسة الخصوصية الخاصة بكل من وسائل التواصل الاجتماعي من أجل وظيفتها.
            علامات البكسل: علامات البكسل ، المعروفة أيضًا باسم ملفات GIF
            <br /><br />
            الواضحة أو إشارات الويب ، هي علامات غير مرئية يتم وضعها على صفحات
            معينة من موقعنا على الويب ولا تظهر على شاشة الكمبيوتر. ستؤدي زيارة
            صفحة بها هذه العلامات إلى إنشاء إشعار عام لتلك الزيارة. تعمل علامات
            البكسل عادةً جنبًا إلى جنب مع ملفات تعريف الارتباط لاكتشاف الزيارات
            إلى صفحات معينة بواسطة أجهزة معينة. <br /><br />
            تُستخدم هذه العلامات بشكل أساسي لتتبع سلوك مستخدمي الخدمة (بما في
            ذلك مستلمي البريد الإلكتروني) ، وقياس نجاح الحملات التسويقية ،
            وتجميع إحصاءات حول استخدام الخدمة ومعدلات الاستجابة. إذا قمت بتعطيل
            ملفات تعريف الارتباط ، فلن تكتشف علامات البكسل سوى الزيارات المجهولة
            إلى موقعنا على الويب. إذا كنت ترغب في رفض تطبيق ملفات تعريف الارتباط
            تمامًا أو ترغب في استخدام ملفات تعريف ارتباط محددة فقط ، فيرجى
            الرجوع إلى إعدادات المتصفح الخاص بك. يمكنك سحب موافقتك على استخدامنا
            لملفات تعريف الارتباط في أي وقت من خلال إعدادات متصفحك. يمكنك أيضًا
            حذف ملفات تعريف الارتباط الموجودة. ومع ذلك ، إذا قمت بحذف / تعطيل
            ملفات تعريف الارتباط التي قمنا بتعيينها ، فقد لا تتمكن من الوصول إلى
            مناطق أو وظائف معينة على موقعنا.
          </p>
          <h2>متى وأين وكيف تتم مشاركة البيانات؟</h2>
          <p>
            نشارك بياناتك مع منظمي المسابقات / الأحداث التي تختار المشاركة فيها.
            سيتمكن هؤلاء المنظمون بعد ذلك من إدارة مشاركتك ، والتفاعل معك على
            المنصة ، وإرسال الإشعارات والتذكيرات إليك (إذا وافقت عليها).
            بالإضافة إلى ذلك ، إذا كانت المنافسة / الحدث الذي شاركت فيه مع منظمة
            الرياضات الإلكترونية أو راعي الحدث ، فقد نستخدم هذه المنظمات لتحليل
            السوق والتسويق المباشر ذي الصلة (إذا كنت قد وافقت).
          </p>
          <p>
            أيضًا ، نوفر لاتحادات الرياضات الإلكترونية إمكانية الوصول إلى مجموعة
            متنوعة من المعلومات حول المستخدمين داخل مناطقهم حتى يتمكنوا من فهم
            لاعبيهم بشكل أفضل.
          </p>
          <p>
            قد نكشف عن معلوماتك الشخصية ومعلومات الدفع عبر الإنترنت إذا لزم
            الأمر للوفاء بعملية الشراء أو الاشتراكات عبر الإنترنت على منصتنا..
          </p>
          <p>
            يجوز لنا الكشف عن معلوماتك الشخصية إذا لزم الأمر لتحقيق الأغراض
            المذكورة أعلاه أو لحماية مصالحنا المشروعة قانونًا وفقًا للقانون
            المعمول به ، أو إذا كان هذا الكشف مطلوبًا بموجب القانون. يمكن مشاركة
            البيانات مع مسؤولي الحكومة / الشرطة.
          </p>
          <p>
            قد نشارك أيضًا بياناتك الشخصية مع وكالات أبحاث السوق لمساعدتنا على
            فهم أفضل للاعبينا واهتماماتهم.
          </p>
          <p>
            بالإضافة إلى ذلك ، قد نشارك بياناتك الشخصية مع مزودي خدمة من الأطراف
            الثالثة الذين يعالجون البيانات نيابة عن Playhera لتحقيق الأغراض
            الموضحة أعلاه. نحن نستخدم بشكل أساسي موفري خدمات من جهات خارجية
            للحلول المتعلقة بتطوير التطبيقات وإدارة حملات البريد الإلكتروني
            واستضافة مواقع الويب وخدمات مصادقة الرسائل القصيرة ودعم العملاء.
          </p>
          <p>
            في حالة بيع الأعمال أو الاندماج مع شركة أخرى ، سنقوم بالكشف عن
            معلوماتك ليس فقط لمستشارينا ، ولكن أيضًا لمستشاري أي مستحوذ محتمل ،
            الذين سينقلون العمل في النهاية إلى مالكه الجديد.
          </p>
          <h2>ما هي الحقوق التي أمتلكها؟</h2>
          <p>
            حقوقك تختلف حسب البلد. تشمل هذه الحقوق الحق في طلب نسخة من بياناتك
            الشخصية منا ، والحق في تصحيح / حذف بياناتك الشخصية ، والحق في تقييد
            المعالجة (أو إيقاف المعالجة قيد التقدم) ، والحق في التعاقد على الحق
            في الحصول على تنسيق منظم وقابل للقراءة آليًا لبياناتك الشخصية
            المقدمة إلينا من خلال موافقتك أو بموافقتك ؛ لديك الحق في الطلب
          </p>
          <p>
            يمكنك أيضًا الاعتراض على معالجة بياناتك الشخصية في بعض الحالات (خاصة
            فيما يتعلق بالبيانات التي لا يلزم معالجتها لتلبية المتطلبات
            التعاقدية أو المتطلبات القانونية الأخرى أو التي نستخدمها للتسويق
            المباشر). يقدر على.
          </p>
          <p>
            ومع ذلك ، إذا كان هناك خطر من كشف البيانات الشخصية للآخرين أو انتهاك
            حقوق طرف ثالث (بما في ذلك شركتنا) من خلال الرد على طلبك ، أو إذا
            كانت شركتنا ملزمة بموجب القانون بالاحتفاظ بها. قد تكون الحقوق
            المذكورة أعلاه محدودة إذا كنت كذلك ، أو إذا طلبت منا إزالة المعلومات
            التي قد تحصل من أجلها على مصلحة مقنعة ومشروعة في الاحتفاظ بها. يتم
            توفير الإعفاءات ذات الصلة بموجب القانون المحلي. عند الرد على طلبك ،
            سنبلغك بأي استثناءات ذات صلة نعتمد عليها.
          </p>
          <p>
            إذا كنت ترغب في ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا باستخدام
            تفاصيل الاتصال أدناه. إذا ظلت مخاوفك دون حل ، فيمكنك تقديم شكوى إلى
            السلطة المختصة ، مثل سلطة حماية البيانات في مكان إقامتك أو مكان عملك
            أو مكان حدوث الانتهاك. سنخبرك دائمًا عندما يكون من الضروري لك
            تزويدنا بالمعلومات. تتطلب عملية التسجيل ، بما في ذلك التحقق من
            الرسائل القصيرة ، توفير معلومات دقيقة ، وكذلك نتائج المسابقات
            المقامة على المنصة. قد يؤدي عدم القيام بذلك إلى استحالة التسجيل
            ويمكن اعتباره انتهاكًا لشروط الاستخدام الخاصة بنا.
          </p>
          <h2>ما هي مدة الاحتفاظ ببياناتك الشخصية؟</h2>
          <p>
            سنحتفظ بالمعلومات التي تزودنا بها فقط عندما لا تكون ضرورية لتمكينك
            من الوصول إلى خدماتنا أو عند حذف حسابك. سيتم حفظها على النظام
            الأساسي حتى أيهما يأتي أولاً. ستستمر أي معالجة للبيانات الشخصية التي
            نقوم بها لأغراض التسويق أو بموافقتك حتى فترة قصيرة من الوقت بعد طلبك
            لإيقافها (حتى نتمكن من الامتثال لطلبك). سنقوم أيضًا بتسجيل طلبك
            والاحتفاظ به لوقف التسويق المباشر أو التوقف عن معالجة بياناتك
            الشخصية حتى نتمكن من الاستمرار في تلبية رغباتك في المستقبل.
          </p>
          <p>
            يجوز للشركة استخدام معلومات نتائج Playhera (مثل من حصل على النقاط
            ومتى تم ربحها) والصور الثابتة لأسلوب اللعب المقدم من المستخدمين كسجل
            للمباريات التي تم إجراؤها على المنصة وللتحقق لاحقًا من النتائج.
            سنحتفظ بها إلى أجل غير مسمى حتى نتمكن من ذلك.
          </p>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Privacypolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.priv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.text-center {
  text-align: center !important;
}
.privacypolicy-container {
  max-width: 1511px;
  width: 80vw !important;
}
.privacy-policy-mena {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .privacypolicy-container {
    width: 90vw !important;
  }
}
</style>
