var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"overflow-hidden group-table px-0",attrs:{"variant":[
    'transparent',
    'stroked',
    'stroked-secondary',
    'rounded',
    'half-paddings' ]}},[_c('div',{staticClass:"position-relative h-100"},[_c('Paginated',_vm._b({staticClass:"container custom-scrollbar",on:{"change":_vm.goToPage}},'Paginated',{
        totalItems: _vm.totalItems,
        hidePagination: !_vm.standings.length,
        pageSize: _vm.pageSize,
        inContainer: true,
        paginationProps: {
          isDark: true,
        },
      },false),[_c('div',{staticClass:"col"},[_c('Table',{attrs:{"columns":_vm.filteredColumns,"items":_vm.pagedStandings,"loading":_vm.isLoading,"dummy-props":{
            iconName: 'dummy/members',
            size: 320,
            headerText: _vm.$$t(
              'There are no participants yet',
              'list-empty-state'
            ),
            text: _vm.$$t('', 'list-empty-state_subheader'),
          }},scopedSlots:_vm._u([(_vm.title)?{key:"control",fn:function(){return [_c('div',{staticClass:"d-flex w-100 group-table-header"},[_c('TextBlock',{attrs:{"text":_vm.title,"size":_vm.$scss.fontSizes_md}})],1)]},proxy:true}:null,{key:"head-item",fn:function(ref){
          var column = ref.column;
return _c('div',{staticClass:"d-flex w-100 align-items-center",class:[
              {
                'cursor-pointer': column.sort,
              },
              column.key === 'participants'
                ? 'justify-content-start'
                : 'justify-content-center' ],on:{"click":function () { return _vm.onSortBy(column); }}},[_c('TextBlock',{attrs:{"size":0.75,"line-height":1.4,"variant":"secondary"}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  ("_web_competitions_tournaments-list_col-title-" + (column.key)),
                  column.label
                ))+"\n            ")]),(column.tooltip)?_c('InfoTooltip',{staticClass:"d-inline-flex group-table-infotip",attrs:{"title":column.tooltip.title,"text":column.tooltip.text,"float":['right', 'bottom']}}):_vm._e(),(column.sort)?_c('SortIcon',{attrs:{"sort-order":_vm.sortOrder,"name":column.key,"icon-props":{ fill: _vm.$scss.colors.secondaryTextBg }},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}):_vm._e()],1)}},{key:"body-item",fn:function(ref){
                var ref_item = ref.item;
                var profile = ref_item.profile;
                var score = ref_item.score;
                var key = ref.column.key;
return [(key === 'participants')?[_c('PlayersTableUsernameCell',{key:_vm.$filters.displayName(profile),attrs:{"account":profile,"is-clan":profile.type === 'clan'}})]:(key === 'index')?_c('TextBlock',{attrs:{"text":score.place}}):(key === 'points' && _vm.isBattleRoyale)?_c('PHBadge',{staticClass:"ml-auto text-white font-size-sm",attrs:{"variant":"inverse","text":("" + (score[key]))}}):_c('TextBlock',{attrs:{"text":_vm.convertToPercentages(key, score[key])}})]}}],null,true)})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }