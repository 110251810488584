import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const POSTS = 'posts';
const POLL_POSTS = `${POSTS}/my/polls`;
const VOTE_POLL_POST = `${POSTS}/my/vote`;

// todo unused #6481
const getWall = (login = '', pageToken = null) =>
  authorizedGetRequest(
    `${POSTS}/${login}/wall/` + (pageToken ? `?page=${pageToken}` : '')
  );

// todo unused #6481
const getClanWall = (clanID, pageToken = null) =>
  authorizedGetRequest(
    `${POSTS}/clans/${clanID}/wall/` + (pageToken ? `?page=${pageToken}` : '')
  );

const getPost = (accessToken, postId) =>
  authorizedGetRequest(`${POSTS}/${postId}`)(accessToken);

const createPost = authorizedPostRequest(POSTS);

const updatePost = id => authorizedPutRequest(`${POSTS}/${id}`);

const deletePost = postID => authorizedDeleteRequest(`${POSTS}/${postID}`);

const likePost = postID =>
  authorizedPostRequest(`${POSTS}/${postID}/reactions`);

const pinPost = postID => authorizedPutRequest(`${POSTS}/${postID}/pin`);
const unpinPost = postID => authorizedPutRequest(`${POSTS}/${postID}/unpin`);

const postRepost = (accessToken, params) =>
  authorizedPostRequest(POSTS + '/my/reposts')(accessToken, params);

const createPollPost = authorizedPostRequest(POLL_POSTS);

const votePollPost = ({ pollId, optionId }) =>
  authorizedPostRequest(`${VOTE_POLL_POST}/${pollId}/${optionId}`);

const getLinkPreviewData = authorizedGetRequest(`${POSTS}/link/preview`);

export default {
  getWall,
  getClanWall,
  getPost,
  createPost,
  updatePost,
  deletePost,
  likePost,
  pinPost,
  unpinPost,
  postRepost,
  createPollPost,
  votePollPost,
  getLinkPreviewData,
};
