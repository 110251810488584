var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'd-flex': _vm.isSwiss }},[(_vm.isRoundRobin)?_c('HeraFormDropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"rounds-field",class:{ 'is-invalid': _vm.errors.first('rounds') },attrs:{"view-mode":_vm.disabled,"disabled":_vm.isStageFieldDisabled('rounds') || _vm.disabled,"name":"rounds","label":_vm.$$t('Rounds:', 'rounds-dropdown-label'),"list":_vm.stageDefaults.rounds || [],"variant":"input-like","required":"","error":_vm.errors.first('rounds'),"empty-text":_vm.$$t('Choose option', 'rounds-dropdown-placeholder'),"tooltip":_vm.$$t(
        'How many times participants play with each other',
        'rounds-dropdown-tooltip'
      ),"show-label":"","empty-option":""},scopedSlots:_vm._u([{key:"chosen",fn:function(chosen){return [_vm._v("\n      "+_vm._s(chosen.chosenItem)+"\n    ")]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n      "+_vm._s(item)+"\n    ")]}}],null,false,1206773473),model:{value:(_vm.rounds),callback:function ($$v) {_vm.rounds=_vm._n($$v)},expression:"rounds"}}):_c('HeraFormInput',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric|decimal:0|min_value:1'),expression:"'required|numeric|decimal:0|min_value:1'"}],key:"rounds-field-swiss",class:{ 'is-invalid': _vm.errors.first('rounds') },attrs:{"label":_vm.$$t('Rounds:', 'rounds_label'),"type":"number","required":"","disabled":_vm.isStageFieldDisabled('rounds') || _vm.disabled,"view-mode":_vm.disabled,"name":"rounds","error":_vm.errors.first('rounds'),"tooltip":_vm.$$t(
        'A number of rounds in a current stage, equal for each group. In other words, how many matches will be played by each participant',
        'rounds-tooltip'
      ),"placeholder":_vm.$$t('Number of rounds', 'rounds-placeholder')},model:{value:(_vm.rounds),callback:function ($$v) {_vm.rounds=_vm._n($$v)},expression:"rounds"}}),(_vm.isSwiss)?_c('ToolIcon',{staticClass:"h-ml-3 mt-5 pt-2",attrs:{"help-text":_vm.$$t('Calculate rounds', 'swiss-calculator-tooltip'),"disabled":_vm.isStageFieldDisabled('rounds') || _vm.disabled,"icon-name":"calculator"},on:{"click":function($event){$event.stopPropagation();return _vm.openCalculatorModal($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }