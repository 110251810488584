<template>
  <div
    class="w-100 h-pl-4 d-flex align-items-center justify-content-between slider-chat-chat-header"
    :class="{ 'slider-chat-chat-header_active': active }"
  >
    <div class="d-flex align-items-center w-75">
      <Icon
        name="back"
        width="18px"
        class="h-mr-2 cursor-hand"
        @click="$emit('back')"
      />
      <Avatar
        v-if="avatar"
        class="h-mr-2"
        :type="avatar"
        :size="36"
        :img-url="avatarUri"
      />
      <div class="text-truncate">
        <span>{{ title }}</span>
        <span v-if="subtitle" class="d-flex slider-chat-chat-header_subtitle">{{
          subtitle
        }}</span>
      </div>
    </div>
    <a
      v-if="canEdit"
      href="#"
      class="h-mr-3 font-size-base font-weight-bold ph-link"
      @click.prevent="clickHandler"
    >
      {{ $t('_web_tournament_sidechat_details', 'Details') }}
    </a>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import Avatar from '@/components/images/Avatar';

export default {
  components: { Icon, Avatar },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    avatarUri: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasBackIcon: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler() {
      if (this.active) {
        this.$emit('action');
      }
    },
  },
};
</script>

<style lang="scss">
.slider-chat-chat-header {
  height: 3.25rem;
  min-height: 3.25rem;
  max-height: 3.25rem;
  background-color: $ph-card;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  &_subtitle {
    opacity: 0.6;
  }
}
</style>
