import api from '@/api';
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { getObjectDifference } from '@/mixins/helpers';
import { ISOSecondsConvertion } from '@/mixins/dateMethods';
import playerSwapping from './playerSwapping';
import { mapPlayersItems } from './utils.js';
import { newStageID } from './adminStages';
import stages from './adminStages';
import blocks from './adminBlocks';
const {
  methods: { dateToSeconds },
} = ISOSecondsConvertion;
import { cloneDeep, upperFirst, set, get, isEmpty } from '@/utils/lodashUtils';
import {
  BLOCK_TYPES,
  DEFAULT_STATUSES_ACTIVE,
  DEFAULT_STATUSES_INACTIVE,
  REJECTABLE_STATUSES,
  TOURNAMENT_STATUSES,
} from '@/constants/tournaments';
import { DEFAULT } from '@/constants/common';

const DEFAULT_TOURNAMENTS_FILTERS = {
  name: '',
  status: DEFAULT_STATUSES_ACTIVE,
  game: [],
  platforms: [],
};

const TOURNAMENTS_FILTER_KEY = 'tournaments_filter';

const mapTournamentObject = (obj, fieldsToIgnore = []) => {
  const eventOrganizer =
    obj.info && obj.info.event_organizer
      ? {
          ...obj.info.event_organizer,
          logo: obj.info.event_organizer.uploaded_manually
            ? obj.info.event_organizer.logo
            : null,
        }
      : {};
  const clonedObj = cloneDeep(obj);
  if (clonedObj.tempId) {
    clonedObj.id = clonedObj.tempId;
    delete clonedObj.tempId;
  }
  const sponsorLogos = get(clonedObj, 'prize.sponsors', {});
  var countryKeys = Object.keys(sponsorLogos);

  if (sponsorLogos && countryKeys.length) {
    countryKeys.forEach(country => {
      sponsorLogos[country] = sponsorLogos[country].map(image => {
        return image.file_id ? image.file_id : image;
      }, []);
    });
    set(clonedObj, 'prize.sponsors', sponsorLogos);
  }
  return {
    ...clonedObj,
    max_qualifiers: obj.squads,
    info: {
      ...obj.info,
      event_organizer: eventOrganizer,
      info_custom_fields: get(obj, 'info.info_custom_fields', []).filter(
        el => el && el.title && el.content
      ),
    },
    images: Object.keys(obj.images).reduce((acc, image) => {
      acc[image] = get(
        obj.images,
        `${image}.file_id`,
        obj.images[image] || null
      );
      return acc;
    }, {}),
    ...fieldsToIgnore.reduce((acc, field) => ({ ...acc, [field]: null }), {}),
  };
};

const emptyTournament = () => ({
  id: null,
  status: 0,
  info: {},
  images: {},
  schedule: {},
  registration_form: {},
  prize: {},
  restrictions: {},
  // welcome_messages: {},
  players_config: {},
  entry_fee: {},
});

export const defaultRepostSettings = () => ({
  repostToFacebook: true,
  repostToTwitter: true,
});

const TOURNAMENT_STATUS_FLOW = [
  TOURNAMENT_STATUSES.DRAFT,
  TOURNAMENT_STATUSES.ANNOUNCED,
  TOURNAMENT_STATUSES.REG_OPENED,
  TOURNAMENT_STATUSES.REG_CLOSED,
  TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
  TOURNAMENT_STATUSES.SCHEDULED,
  TOURNAMENT_STATUSES.STARTED,
  TOURNAMENT_STATUSES.CLOSED,
  TOURNAMENT_STATUSES.ARCHIVED,
];

const DISABLED_FIELDS_BY_STATUS = {
  // announced
  game: TOURNAMENT_STATUSES.ANNOUNCED,
  // registration opened
  max_substitutes: TOURNAMENT_STATUSES.REG_OPENED,
  registration_start: TOURNAMENT_STATUSES.REG_OPENED,
  squad_size: TOURNAMENT_STATUSES.REG_OPENED,
  full_teams: TOURNAMENT_STATUSES.STARTED,
  // registration closed
  countries_restrictions: TOURNAMENT_STATUSES.REG_CLOSED,
  registration_form: TOURNAMENT_STATUSES.REG_CLOSED,
  // welcome_messages: TOURNAMENT_STATUSES.REG_CLOSED,
  registration_type: TOURNAMENT_STATUSES.REG_CLOSED,
  // qualification completed
  platform: TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
  registration_end: TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
  // started
  squads: TOURNAMENT_STATUSES.STARTED,
  qualification_type: TOURNAMENT_STATUSES.STARTED,
  tournament_start: TOURNAMENT_STATUSES.STARTED,
  // closed
  country: TOURNAMENT_STATUSES.CLOSED,
  cover: TOURNAMENT_STATUSES.CLOSED,
  description: TOURNAMENT_STATUSES.CLOSED,
  event_organizer: TOURNAMENT_STATUSES.CLOSED,
  info_custom_fields: TOURNAMENT_STATUSES.CLOSED,
  logo: TOURNAMENT_STATUSES.CLOSED,
  portrait_cover: TOURNAMENT_STATUSES.CLOSED,
  prize_line: TOURNAMENT_STATUSES.CLOSED,
  prize_pool: TOURNAMENT_STATUSES.CLOSED,
  promo_video: TOURNAMENT_STATUSES.CLOSED,
  rules_link: TOURNAMENT_STATUSES.CLOSED,
  sponsors: TOURNAMENT_STATUSES.CLOSED,
  stream_url: TOURNAMENT_STATUSES.CLOSED,
  title: TOURNAMENT_STATUSES.CLOSED,
  addStage: TOURNAMENT_STATUSES.CLOSED,
};

export default {
  modules: {
    playerSwapping,
    stages,
    blocks,
  },
  state: () => ({
    tournamentBackup: {},
    tournamentRepostSettings: defaultRepostSettings(),
    tournamentUpdateStopover: Promise.resolve(),
    currentTournament: emptyTournament(),
    currentGroupParticipants: [],
    currentTournamentAttributes: [],
    playersTableSettings: [],
    tournamentsListStoredFilters: {
      ...DEFAULT_TOURNAMENTS_FILTERS,
      ...sessionStorage.getObjectFromItem(TOURNAMENTS_FILTER_KEY),
    },
  }),

  actions: {
    generateTempTournamentId({ commit }) {
      commit('updateCurrentTournament', {
        tempId: uuidv4(),
      });
    },
    generateTempTournamentInvitationToken({ commit }) {
      const newToken = uuidv4()
        .split('-')
        .pop();
      commit('updateCurrentTournament', {
        component: 'info',
        registration_token: newToken,
      });
    },
    fetchTournamentRulesText(
      {
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .getTournamentRulesText(tournamentID)(accessToken)
        .then(response => response.data);
    },
    updateTournamentRulesText(
      {
        getters: { accessToken },
      },
      { tournamentID, rulesText }
    ) {
      return api.tournaments.setTournamentRulesText(tournamentID)(accessToken, {
        rules: rulesText,
      });
    },
    createTournament({
      commit,
      dispatch,
      getters: { accessToken, currentTournament, currentTournamentStage },
    }) {
      const tournament = mapTournamentObject(currentTournament);
      const rulesText = cloneDeep(currentTournament.info.rulesText);
      const hasRulesText = !isEmpty(rulesText);
      if (!isEmpty(currentTournamentStage)) {
        const isRR =
          currentTournamentStage.block_configuration.type === BLOCK_TYPES.RR;
        const isSwiss =
          currentTournamentStage.block_configuration.type === BLOCK_TYPES.SWISS;
        const isBR =
          currentTournamentStage.block_configuration.type ===
          BLOCK_TYPES.BATTLE_ROYALE;
        const needRoundsField = isRR || isSwiss || isBR;
        tournament.stage = {
          block_type: currentTournamentStage.block_configuration.type,
          match_duration:
            currentTournamentStage.block_configuration.match_duration,
          require_results_attachment:
            currentTournamentStage.block_configuration.attachment_required,
          autocomplete_match:
            currentTournamentStage.block_configuration.autostart_battle,
          allow_player_results:
            currentTournamentStage.block_configuration.allow_player_results,
          ...(currentTournamentStage.check_in_enabled
            ? {
                require_checkin: true,
                checkin_datetime: currentTournamentStage.check_in_start_date,
              }
            : {
                require_checkin: false,
              }),
          ...(!needRoundsField
            ? {}
            : {
                rounds: isBR ? 3 : isRR ? 1 : isSwiss ? 5 : null,
              }),
        };
      }
      if (hasRulesText) {
        delete tournament.info.rulesText;
      }
      let tournamentID;
      let tournamentData;
      return api.tournaments
        .createTournament(accessToken, tournament, {
          'follow-redirect': 10,
          maxBodyLength: 5e8,
          maxContentLength: 5e8,
        })
        .then(response => response.data)
        .then(responseData => {
          tournamentID = responseData.id;
          tournamentData = responseData;

          //backend response weird inconsistency
          //on create API returns sponsors in another field than on get tournament draft.
          if (!tournamentData.prize.sponsors) {
            tournamentData.prize.sponsors = tournamentData.sponsor_logo;
            tournamentData.sponsor_logo = undefined;
          }

          return hasRulesText
            ? dispatch('updateTournamentRulesText', {
                tournamentID,
                rulesText,
              })
            : Promise.resolve();
        })
        .then(() => {
          commit('clearCurrentTournamentStage');
          commit('deleteTournamentStageByID', newStageID);
          commit('updateCurrentTournament', {
            ...tournamentData,
            id: tournamentID,
            stages: [],
          });
          return dispatch('fetchTournamentStages', tournamentID);
        })
        .then(stages => {
          return stages.map(stage => {
            return dispatch('fetchTournamentBlocks', {
              stage: stage.index,
            }).then(blocks => {
              commit('setTournamentBlocks', blocks);
            });
          });
        })
        .then(blocksPromises =>
          Promise.all(blocksPromises)
            .then(() => {
              commit('backupCurrentTournament');
              return tournamentID;
            })
            .catch(this.errorNotify)
        );
    },
    updateTournament({
      commit,
      dispatch,
      getters: {
        accessToken,
        currentTournament,
        currentTournamentBackup,
        isTournamentPastStatus,
      },
    }) {
      const fieldsToIgnore = Object.keys(DISABLED_FIELDS_BY_STATUS).filter(
        key => isTournamentPastStatus(DISABLED_FIELDS_BY_STATUS[key])
      );
      const rulesText = cloneDeep(currentTournament.info.rulesText);
      const hasRulesText = !isEmpty(rulesText);
      const tournament = mapTournamentObject(currentTournament, fieldsToIgnore);
      const backup = mapTournamentObject(
        currentTournamentBackup,
        fieldsToIgnore
      );
      let newData = getObjectDifference(tournament, backup, 0);
      if (hasRulesText && newData.info) {
        delete newData.info.rulesText;
      }
      return isEmpty(newData)
        ? Promise.resolve()
            .then(() => {
              return hasRulesText
                ? dispatch('updateTournamentRulesText', {
                    tournamentID: tournament.id,
                    rulesText,
                  })
                : Promise.resolve();
            })
            .then(() => tournament.id)
        : api.tournaments
            .updateTournament(tournament.id)(accessToken, newData, {
              'follow-redirect': 10,
              maxBodyLength: 5e8,
              maxContentLength: 5e8,
            })
            .then(() => {
              return hasRulesText
                ? dispatch('updateTournamentRulesText', {
                    tournamentID: tournament.id,
                    rulesText,
                  })
                : Promise.resolve();
            })
            .then(() => {
              commit('updateCurrentTournament', {
                tournament: mapTournamentObject(currentTournament),
                images: currentTournament.images,
              });
              commit('backupCurrentTournament');
              return tournament.id;
            });
    },
    fetchTournamentDraft(
      {
        commit,
        getters: { accessToken },
      },
      id
    ) {
      return api.tournaments
        .getTournamentDraft(id)(accessToken)
        .then(response => {
          commit('setCurrentTournament', response.data);
          return id;
        });
    },
    fetchTournamentPlayersInfo(
      {
        commit,
        getters: { accessToken },
      },
      id
    ) {
      return api.tournaments
        .getTournamentPlayersInfo(id)(accessToken)
        .then(response => {
          commit('updateCurrentTournament', {
            players_info: response.data,
          });
          return id;
        });
    },
    fetchTournamentPlayersStatistics(
      {
        getters: { accessToken },
      },
      id
    ) {
      return api.tournaments
        .getPlayersStatistics(id)(accessToken)
        .then(response => response.data);
    },
    getAdminTournaments(
      {
        getters: { accessToken },
      },
      payload = {}
    ) {
      return api.tournaments
        .getAdminTournaments(accessToken, { params: payload })
        .then(response => response.data)
        .then();
    },
    currentTournament({ commit }, payload) {
      commit('setCurrentTournament', payload);
    },

    publishTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .publishTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', { status: 'published' });
          commit('backupCurrentTournament');
        });
    },

    announceTournament(
      {
        commit,
        getters: { accessToken },
        rootState: {
          accounts: {
            my: { socialConnections },
          },
        },
        state: { tournamentRepostSettings },
      },
      tournamentID
    ) {
      const payload = { ...tournamentRepostSettings };
      if (!socialConnections.facebookCredentialsDefined) {
        delete payload.repostToFacebook;
      }
      if (!socialConnections.twitterCredentialsDefined) {
        delete payload.repostToTwitter;
      }
      return api.tournaments
        .announceTournament(tournamentID)(accessToken, payload)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.ANNOUNCED,
          });
          commit('backupCurrentTournament');
        });
    },

    openRegTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .openRegTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.REG_OPENED,
          });
          commit('backupCurrentTournament');
        });
    },

    closeRegTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .closeRegTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.REG_CLOSED,
          });
          commit('backupCurrentTournament');
        });
    },

    restartTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .restartTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.DRAFT,
          });
          commit('backupCurrentTournament');
        });
    },

    pauseTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .pauseTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
          });
          commit('backupCurrentTournament');
        });
    },

    reopenTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .reopenTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.STARTED,
          });
          commit('backupCurrentTournament');
        });
    },

    archiveTournament(
      {
        commit,
        getters: { accessToken },
      },
      tournamentID
    ) {
      return api.tournaments
        .archiveTournament(tournamentID)(accessToken)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.ARCHIVED,
          });
          commit('backupCurrentTournament');
        });
    },

    rejectTournament(
      {
        commit,
        getters: { accessToken },
      },
      payload
    ) {
      const { tournamentID, reason } = payload;
      const data = { reason };
      return api.tournaments
        .rejectTournament(tournamentID)(accessToken, data)
        .then(() => {
          commit('updateCurrentTournament', {
            status: TOURNAMENT_STATUSES.REJECTED,
          });
          commit('backupCurrentTournament');
        });
    },

    drawTournament(
      {
        commit,
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .drawTournament(payload.tournamentID)(accessToken, {
          partial_quantity: payload.isPartial,
        })
        .then(() => {
          commit('updateCurrentTournament', { status: 'scheduled' });
          commit('backupCurrentTournament');
        });
    },

    fetchTournamentPlayers(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .getTournamentPlayers(payload.id)(accessToken, {
          params: payload.query,
        })
        .then(response => {
          let data = cloneDeep(response.data);
          data.properties.columns_names.splice(2, 0, 'block');
          if (data.items && Array.isArray(data.items)) {
            data.items = mapPlayersItems(data.items);
          }
          data.properties.column_list = cloneDeep(
            data.properties.columns_names
          );
          return data;
        });
    },

    fetchInviteListPlayers(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .getInvitedPlayers(payload.id)(accessToken, { params: payload.query })
        .then(response => response.data);
    },
    sendPrivateInvite(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .sendPrivateInvite(payload.tournamentID)(accessToken, {
          players_ids: [payload.id],
        })
        .then(response => response.data);
    },
    cancelPrivateInvite(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .cancelPrivateInvite(payload.tournamentID)(
          accessToken,
          {
            players_ids: [payload.id],
          },
          true
        )
        .then(response => response.data);
    },
    fetchTournamentCustomFields(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .getTournamentCustomFields(payload.id)(accessToken)
        .then(response => response.data);
    },
    fetchTournamentCustomFieldsAll(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .getTournamentCustomFieldsAll(payload.id)(accessToken)
        .then(response => response.data);
    },
    fetchPlayersRestrictions(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .getPlayersRestrictions(payload.id)(accessToken)
        .then(response => response.data);
    },
    setFillParticipants(
      {
        getters: { accessToken },
        dispatch,
      },
      { id, stageID, data, query }
    ) {
      return dispatch('updateTournament').then(() =>
        stageID
          ? api.tournaments.setFillBlockParticipants(id, stageID)(
              accessToken,
              data,
              {
                params: query,
              }
            )
          : api.tournaments
              .setFillParticipants(id)(accessToken, data, {
                params: query,
              })
              .then(response => response.data)
      );
    },
    setTournamentPlayerApprovedTo(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .setTournamentPlayerParams(payload.id, payload.login)(accessToken, {
          approved_to: payload.status,
        })
        .then(response => response.data);
    },

    /*    setTournamentPlayerStatus(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api
        .setTournamentPlayerParams(payload.id, payload.login)(accessToken, {
          status: payload.status,
        })
        .then(response => response.data);
    },*/

    /**
     * Set tournament players extended data
     *
     * @param {String} accessToken
     * @param {Object} payload
     * @param {string} payload.id Tournament ID
     * @param {Array<string>} payload.players Players IDs
     * @param {string} [payload.status] Status
     * @param {string} [payload.approvedTo] Approved to
     * @param {Array<string>} [payload.attributes] Attributes IDs
     *
     * @returns {Promise} Promise object of query
     */
    setTournamentPlayersExtendedData(
      {
        getters: { accessToken },
      },
      payload
    ) {
      const data = {};
      const { status, approvedTo, attributes } = payload;

      if (status) data.status = status;
      if (approvedTo) data.approved_to = approvedTo;
      if (attributes) data.attribute_ids = attributes;

      data.players_ids = [...payload.players];

      return api.tournaments
        .setTournamentPlayersExtendedData(payload.id)(accessToken, data)
        .then(response => response.data);
    },
    /**
     * Set tournament player attribute
     * @param {String} accessToken
     * @param {Object} payload
     * @returns {Promise} Promise object of query
     */
    setTournamentPlayerAttributes(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .setTournamentPlayerParams(payload.id, payload.login)(accessToken, {
          attribute_ids: payload.attributes,
        })
        .then(response => response.data);
    },

    /**
     * Fetch current tournament attributes
     * @param {Function} commit
     * @param {String} accessToken
     * @param {string} id Tournament ID
     */
    fetchCurrentTournamentAttributes(
      {
        commit,
        getters: { accessToken },
      },
      id
    ) {
      return api.tournaments
        .getTournamentAttributes(id)(accessToken)
        .then(response => {
          commit('setCurrentTournamentAttributes', response.data);
        });
    },

    createTournamentConversation(
      {
        getters: { accessToken },
      },
      { tournamentID, title, subtitle, participants, avatar, type, visible }
    ) {
      return api.tournaments
        .createTournamentConversation(tournamentID)(accessToken, {
          title,
          subtitle,
          conversation_type: type, // enum 'group_chat' or 'channel'
          participants: participants ? participants : [],
          avatar,
          visible,
        })
        .then(response => response.data);
    },
    createTournamentPlayersCSV(
      {
        getters: { accessToken, currentTournamentID },
      },
      payload
    ) {
      return api.tournaments
        .createTournamentPlayersCSV(currentTournamentID)(accessToken, {
          params: payload,
        })
        .then(response => response.data);
    },
    getTournamentResultsCSV(
      {
        getters: { accessToken, currentTournamentID },
      },
      payload
    ) {
      return api.tournaments
        .getTournamentResults(currentTournamentID)(accessToken, {
          params: payload,
        })
        .then(response => response.data);
    },
    fetchColumnsSettings(
      {
        getters: { accessToken },
        commit,
      },
      id
    ) {
      return api.tournaments
        .getPlayersColumnsSettings(id)(accessToken)
        .then(response => {
          commit('setPlayersTableSettings', response.data);
          return response.data;
        });
    },

    /**
     * Set tournament columns settings order/column name
     * @param accessToken
     * @param {Object} payload
     * @returns {Promise} Promise object of query
     */
    setColumnsSettings(
      {
        getters: { accessToken },
      },
      payload
    ) {
      return api.tournaments
        .setPlayersColumnsSettings(payload.id)(accessToken, payload.columns)
        .then(response => response.data);
    },
    checkInParticipant(
      {
        getters: { accessToken },
      },
      { tournamentID, stageID: stage_id, playerID: player_id }
    ) {
      return api.tournaments
        .checkInParticipant(tournamentID)(accessToken, {
          stage_id,
          player_id,
        })
        .then(() => stage_id);
    },
    setTournamentCompleteQualification({
      getters: { accessToken, currentTournamentID },
    }) {
      return api.tournaments
        .completeTournamentQualification(currentTournamentID)(accessToken)
        .then(response => response.data);
    },
  },

  mutations: {
    setTournamentReposts(state, payload = {}) {
      state.tournamentRepostSettings = {
        ...state.tournamentRepostSettings,
        ...payload,
      };
    },
    setTournamentUpdateStopover(state, payload) {
      state.tournamentUpdateStopover = payload;
    },
    clearTournamentUpdateStopover(state) {
      state.tournamentUpdateStopover = Promise.resolve();
    },
    clearToTournamentsDefaultState(state) {
      sessionStorage.removeItem(TOURNAMENTS_FILTER_KEY);
      state.tournamentsListStoredFilters = DEFAULT_TOURNAMENTS_FILTERS;
    },
    setTournamentsListFilter(state, payload) {
      let filters = {
        ...state.tournamentsListStoredFilters,
        ...payload,
      };
      state.tournamentsListStoredFilters = filters;
      sessionStorage.setObjectToItem(TOURNAMENTS_FILTER_KEY, filters);
    },
    setPlayersTableSettings(state, payload) {
      state.playersTableSettings = payload;
    },
    clearCurrentTournament(state) {
      state.currentTournament = emptyTournament();
      state.currentTournamentStage = {};
      // state.currentTournamentParticipants = {};
    },
    // setCurrentTournamentParticipants(state, payload) {
    //   const { current_total, current_qualifiers, current_reserves } = payload;
    //   state.currentTournamentParticipants = [
    //     { title: 'All participants', value: current_total, key: 'all' },
    //     { title: 'Qualifiers', value: current_qualifiers, key: 'qualifiers' },
    //     { title: 'Reserve', value: current_reserves, key: 'reserve' },
    //   ];
    // },
    setCurrentTournament(state, payload) {
      state.currentTournament = {
        ...state.currentTournament,
        ...cloneDeep(payload),
      };
    },
    updateCurrentTournament(state, payload) {
      // let obj = cloneDeep(state.currentTournament);
      const updater = payloadItem => {
        let path = get(payloadItem, 'component');
        let keysForUpdate = cloneDeep(payloadItem);
        if (path) {
          delete keysForUpdate.component;
        }
        for (let key in keysForUpdate) {
          if (keysForUpdate.hasOwnProperty(key)) {
            Vue.set(
              path ? state.currentTournament[path] : state.currentTournament,
              key,
              cloneDeep(keysForUpdate[key])
            );
          }
        }
      };
      if (Array.isArray(payload)) {
        payload.forEach(updater);
      } else {
        updater(payload);
      }
      // state.currentTournament = obj;
    },
    deleteCurrentTournamentProp(state, { prop, component } = {}) {
      if (component) {
        Vue.delete(state.currentTournament[component], prop);
      } else {
        Vue.delete(state.currentTournament, prop);
      }
    },
    backupCurrentTournament(state) {
      state.tournamentBackup = cloneDeep(state.currentTournament);
    },
    clearCurrentTournamentBackup(state) {
      state.tournamentBackup = {};
    },
    applyCurrentTournamentBackup(state) {
      state.currentTournament = cloneDeep(state.tournamentBackup);
    },
    setCurrentTournamentAttributes(state, payload) {
      state.currentTournamentAttributes = payload;
    },
  },

  getters: {
    tournamentRepostSettings: state => platform =>
      state.tournamentRepostSettings[`repostTo${upperFirst(platform)}`],
    tournamentUpdateStopover: state => state.tournamentUpdateStopover,
    currentTournament: state => state.currentTournament,
    currentTournamentInfo: state => state.currentTournament.info,
    currentTournamentImages: state =>
      Object.keys(state.currentTournament.images).reduce((acc, key) => {
        acc[key] = get(
          state.currentTournament.images,
          `${key}.display_uri`,
          state.currentTournament.images[key]
        );
        return acc;
      }, {}),
    currentTournamentSponsorLogos: state => {
      if (
        state.currentTournament.sponsor_logo &&
        state.currentTournament.sponsor_logo.length
      )
        return state.currentTournament.sponsor_logo;

      const sponsors = cloneDeep(
        get(state.currentTournament, 'prize.sponsors', {})
      );

      if (!sponsors[DEFAULT]) {
        sponsors[DEFAULT] = [];
      }

      Object.keys(sponsors).forEach(country => {
        sponsors[country] = sponsors[country].map(
          logo => logo.display_uri || logo
        );
      });
      return sponsors;
    },
    currentTournamentHasSponsors: state => {
      // todo: add the same check if currentTournamentHasSponsors will be used
      const sponsors = get(state.currentTournament, 'prize.sponsors', []);
      return Boolean(sponsors.length);
    },
    currentTournamentParticipants: state =>
      state.currentTournament.players_config,
    currentPaidTournament: state => state.currentTournament.entry_fee,
    currentTournamentSchedule: state => state.currentTournament.schedule,
    currentTournamentRestrictions: state =>
      state.currentTournament.restrictions,
    currentTournamentPrize: state => state.currentTournament.prize,
    currentTournamentID: state => state.currentTournament.id,
    hasCheckIn: (state, getters) =>
      getters.hasStages &&
      getters.currentTournamentStages.some(el => el.check_in_enabled),
    checkIfCheckInStarted: (state, getters) => () => {
      const now = dateToSeconds(new Date());
      return (
        getters.hasCheckIn &&
        getters.currentTournamentStages
          .filter(stage => stage.check_in_enabled)
          .map(stage => stage.check_in_start_date <= now)[0]
      );
    },
    currentTournamentBackup: state => state.tournamentBackup,
    isTournamentBackuped: state => !isEmpty(state.tournamentBackup),
    currentTournamentStatus: state => state.currentTournament.status,
    playersTableSettings: state => state.playersTableSettings,
    hasGroupStage: state =>
      ['round_robin_playoff'].includes(state.currentTournamentBlock.type),
    isDraft: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.DRAFT,
    isAnnounced: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.ANNOUNCED,
    isRejected: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.REJECTED,
    isScheduled: state =>
      get(state, 'currentTournament.status', '') ===
      TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
    isStarted: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.STARTED,
    isClosed: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.CLOSED,
    isArchived: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.ARCHIVED,
    isRegStarted: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.REG_OPENED,
    isRegClosed: state =>
      state.currentTournament &&
      state.currentTournament.status === TOURNAMENT_STATUSES.REG_CLOSED,
    isSingle: state =>
      get(state, 'currentTournament.players_config.squad_size', 1) === 1,
    canReject: state =>
      REJECTABLE_STATUSES.includes(state.currentTournament.status),
    canAnnounce: state =>
      TOURNAMENT_STATUSES.DRAFT === state.currentTournament.status ||
      !state.currentTournament.status,
    canPublish: state =>
      TOURNAMENT_STATUSES.SCHEDULED === state.currentTournament.status,
    canPause: state =>
      TOURNAMENT_STATUSES.STARTED === state.currentTournament.status,
    canReopen: state =>
      TOURNAMENT_STATUSES.CLOSED === state.currentTournament.status,
    canRestart: state =>
      TOURNAMENT_STATUSES.REJECTED === state.currentTournament.status,
    canSave: state =>
      [
        TOURNAMENT_STATUSES.DRAFT,
        TOURNAMENT_STATUSES.ANNOUNCED,
        TOURNAMENT_STATUSES.REG_OPENED,
        TOURNAMENT_STATUSES.REG_CLOSED,
        TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
        TOURNAMENT_STATUSES.STARTED,
        TOURNAMENT_STATUSES.REJECTED,
      ].includes(state.currentTournament.status),
    canArchive: state =>
      [
        TOURNAMENT_STATUSES.DRAFT,
        TOURNAMENT_STATUSES.CLOSED,
        TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
        TOURNAMENT_STATUSES.REJECTED,
      ].includes(state.currentTournament.status),
    canDraft: state => !state.currentTournament.id,
    currentTournamentParticipantsSummary: state => {
      return [
        {
          title: 'All participants',
          value: get(
            state.currentTournament,
            'players_info.current_total',
            'n/a'
          ),
          key: 'all',
        },
        {
          title: 'Qualifiers',
          value: get(
            state.currentTournament,
            'players_info.current_qualifiers',
            'n/a'
          ),
          key: 'qualifiers',
        },
        {
          title: 'Reserve',
          value: get(
            state.currentTournament,
            'players_info.current_reserves',
            'n/a'
          ),
          key: 'reserve',
        },
      ];
    },
    getCurrentTournamentAttributes: state => state.currentTournamentAttributes,
    hasGrid: state =>
      state.currentTournament && state.currentTournament.status
        ? ![
            TOURNAMENT_STATUSES.DRAFT,
            TOURNAMENT_STATUSES.ANNOUNCED,
            TOURNAMENT_STATUSES.REG_OPENED,
            TOURNAMENT_STATUSES.REG_CLOSED,
            TOURNAMENT_STATUSES.REJECTED,
          ].includes(state.currentTournament.status)
        : false,
    currentGroupParticipants: state => state.currentGroupParticipants,
    canSaveNewTournament: state =>
      !state.currentTournament.id &&
      state.currentTournament.info.game &&
      state.currentTournament.info.title &&
      (state.currentTournament.info.platforms || '').length &&
      state.currentTournament.players_config.squad_size &&
      state.currentTournament.players_config.squads &&
      state.currentTournament.players_config.full_teams &&
      state.currentTournament.schedule.tournament_start &&
      state.currentTournament.schedule.registration_start &&
      state.currentTournament.schedule.registration_end,
    isTournamentPastStatus: state => (
      statusKey,
      tournament = state.currentTournament
    ) => {
      const currentStatus = tournament.status;
      const statusIndex = TOURNAMENT_STATUS_FLOW.findIndex(
        s => s === statusKey
      );
      const currentIndex = TOURNAMENT_STATUS_FLOW.findIndex(
        s => s === currentStatus
      );
      return (
        statusIndex !== -1 && currentIndex !== -1 && statusIndex <= currentIndex
      );
    },
    isTournamentFieldDisabled: (state, getters) => fieldName => {
      const status = state.currentTournament.status;
      if (DEFAULT_STATUSES_INACTIVE.includes(status)) {
        return true;
      }
      const fieldStatus = DISABLED_FIELDS_BY_STATUS[fieldName];
      if (!status || !fieldName || !fieldStatus) {
        return false;
      }
      if (
        !state.currentTournament.id &&
        fieldName !== 'game' &&
        !state.currentTournament.info.game
      ) {
        return true;
      }
      return getters.isTournamentPastStatus(fieldStatus);
    },
    isTournamentChanged: state => {
      if (isEmpty(state.tournamentBackup)) {
        return (
          !isEmpty(state.currentTournament.info) ||
          !isEmpty(state.currentTournament.images) ||
          !isEmpty(state.currentTournament.players_config)
        );
      }
      const mappedCurrent = mapTournamentObject(state.currentTournament);
      const mappedBackup = mapTournamentObject(state.tournamentBackup);
      const diff = getObjectDifference(mappedCurrent, mappedBackup);
      const diffRevers = getObjectDifference(mappedBackup, mappedCurrent);
      delete diff.status;
      delete diff.stages;
      delete diffRevers.stages;
      delete diffRevers.status;
      return !isEmpty(diff) || !isEmpty(diffRevers);
    },
  },
};
