import store from '@/store';
import axios from 'axios';
import { cloneDeep } from '@/utils/lodashUtils';

// const excludeEndPointsList = ['profiles', 'settings', 'conversations', 'notification', 'platforms'];

const createCancelToken = () => {
  return axios.CancelToken.source();
};

const updateRequestCancelToken = (requestUrl, requestId) => {
  const token = createCancelToken();
  store.commit('addToken', { requestUrl, token, requestId });
  return token;
};

const cancelRequest = cancelToken => {
  cancelToken.cancel('Operation canceled due to new request');
  return cancelToken.token.promise;
};

const resetRequestCancelToken = url => {
  const cancelTokens = store.getters.tokensByUrl(url);
  cancelTokens.forEach(item => {
    console.log('Request cancelling: locate duplicated requests for ', url);
    return cancelRequest(item.token);
  });
};

const removeTokenFromStore = requestId => {
  store.commit('deleteToken', requestId);
};

const optionsWithCancelToken = (options, url, requestId) => {
  resetRequestCancelToken(url);
  const token = updateRequestCancelToken(url, requestId).token;
  const props = cloneDeep(options || {});
  props.cancelToken = token;
  return props;
};

const isRequestCanceled = response => axios.isCancel(response);

const cancelAllRequests = () => {
  return Promise.all(
    [...store.getters.requestsTokens.values()].map(requestData => {
      console.log(
        'Request cancelling: locate duplicated requests for ',
        requestData.requestUrl
      );
      cancelRequest(requestData.token);
    })
  );
};

const isRequestPending = url => store.getters.tokensByUrl(url).length > 0;

export {
  optionsWithCancelToken,
  removeTokenFromStore,
  isRequestCanceled,
  cancelAllRequests,
  isRequestPending,
};
