<template>
  <Panel :variant="variant" class="collaps">
    <div class="d-flex cursor-pointer align-items-center" @click="toggle">
      <slot name="trigger">
        <Icon
          name="dropdown"
          class="collaps-header-icon"
          :class="{ active: isVisible }"
          width="16"
        />
        <Subheader :text="label" class="mb-0 h-ml-4" />
      </slot>
      <slot name="labelSuffix"></slot>
    </div>
    <transition name="fade">
      <div v-if="isVisible" class="mt-4">
        <slot />
      </div>
    </transition>
  </Panel>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';

export default {
  name: 'CollapsiblePanel',
  components: {
    Icon,
    Panel,
    Subheader,
  },
  props: {
    label: propRequired(String),
    visible: prop(Boolean, false),
    variant: prop([Array, String], () => [
      'default',
      'rounded',
      'half-paddings',
    ]),
  },
  data() {
    return {
      isVisible: this.visible,
    };
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal;
    },
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
      this.$emit('change', this.isVisible);
    },
  },
};
</script>

<style lang="scss">
.collaps {
  &-header-icon {
    transition: transform 0.2s;
    transform: rotate(-90deg);
    #{$selector-rtl} & {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotateZ(0);
    }
  }
}
</style>

<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/"
  name="CollapsiblePanel"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    label: {
      default: text('Label text', 'Click me')
    },
    visible: {
      default: boolean('Visibility', false),
    },
    background: {
      default: select('Variant|background-type', ['transparent',
        'dark-bg',
        'default',
        'input-bg',
        'white',
        'secondary',
        'accent',
        'error',
        'green',
        ], 'default')
    },
    strokes: {
      default: select('Variant|strokes-type', ['',
        'stroked',
        'stroked-dark',
        'stroked-input-bg',
        'stroked-white',
        'stroked-secondary',
        'stroked-accent',
        'stroked-error',
        'stroked-green',
        ], '')
    },
    rounded: {
      default: select('Variant|border-radius-type', [
        'rounded',
        'rounded-small',
        'rounded-large',
        'rounded-full'], 'rounded')
    },
  }"><CollapsiblePanel v-bind="{variant: [background, strokes ? 'stroked' : '', strokes, rounded ], label, visible}">
  <div>
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. <br>
    Magnam, quos illo cumque, praesentium minus, necessitatibus error aliquam velit <br>
    sapiente perferendis id maxime temporibus molestias esse eveniet architecto ratione officia aliquid?
  </div>
</CollapsiblePanel>
</story>
