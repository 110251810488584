<template>
  <transition-group
    name="notifications-list"
    tag="div"
    class="d-flex flex-column notifications-popup-wrap"
  >
    <NotificationItemMini
      v-for="item in notifications"
      :key="item.id"
      class="mx-2 mt-2 mb-n1 p-3 notifications-popup-item"
      :data="item"
      :close="true"
      is-popup
      @removed="removeNotification"
      @mousedown.native="markAsRead(item)"
      @mouseenter.native="removeDelay(item)"
      @mouseleave.native="setDelay(item)"
    />
  </transition-group>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationItemMini from '@/views/notifications/list/NotificationItemMini.vue';

const NOTIFY_DELAY = 10000;

export default {
  name: 'NotificationsPopup',
  components: {
    NotificationItemMini,
  },
  data() {
    return {
      notifications: [],
    };
  },
  created() {
    this.addNotificationsSubscriber(res => {
      this.addNotification(res);
    });
  },
  methods: {
    ...mapActions(['updateNotificationsStatus', 'addNotificationsSubscriber']),
    setDelay(item) {
      if (item.hasOwnProperty('timeout')) {
        this.removeDelay(item);
      }
      item.timeout = setTimeout(() => {
        this.removeNotification(item.id);
      }, NOTIFY_DELAY);
    },
    removeDelay(item) {
      clearTimeout(item.timeout);
    },
    addNotification(item) {
      if (item && !this.notifications.some(data => item.id === data.id)) {
        this.notifications.unshift(item);
        this.setDelay(item);
      }
    },
    removeNotification(id) {
      const index = this.notifications.findIndex(item => item.id === id);
      if (index >= 0) {
        this.notifications.splice(index, 1);
      }
    },
    markAsRead(item) {
      this.updateNotificationsStatus([item.id]);
    },
  },
};
</script>

<style lang="scss">
.notifications-popup-wrap {
  position: fixed;
  z-index: 2000;
  top: 0;
  @include auto-rtl(right, 0);
  width: 1px;
  height: 1px;
  .notifications-popup-item {
    $width: 21rem;
    position: relative;
    @include auto-rtl(left, -$width - 1rem);
    width: $width;
    background: $ph-card;
    border-radius: 0.25rem;
    box-shadow: $ph-box-shadow;

    &.notifications-list-enter-active,
    &.notifications-list-leave-active {
      transition: opacity 0.3s;
    }
    &.notifications-list-enter,
    &.notifications-list-leave-to {
      opacity: 0;
    }
  }
}
</style>
