import { DateTime } from 'luxon';

export const TWO_SECONDS = 2e3;
export const FIVE_SECONDS = 5e3;
export const TEN_SECONDS = 1e4;
export const ONE_MINUTE = 60;
export const TEN_MINUTES = ONE_MINUTE * 10;
export const ONE_HOUR = 3600;
export const HALF_HOUR = ONE_HOUR / 2;
export const ONE_DAY_IN_SECONDS = ONE_HOUR * 24;
export const DATE_FULL = DateTime.DATE_FULL;
export const DATE_MED = DateTime.DATE_MED;
export const DATE_SUBMED = { month: 'short', day: 'numeric' };
export const DATE_MONTH = { month: 'long', day: 'numeric' };
export const DATE_SHORT = DateTime.DATE_SHORT;
export const DATETIME_FULL = DateTime.DATETIME_FULL;
export const DATETIME_MED = DateTime.DATETIME_MED;
export const DATETIMEWEEKDAY_MED = {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};
export const DATETIME_SUBMED = {
  ...DateTime.TIME_SIMPLE,
  month: 'short',
  day: 'numeric',
};
export const DATETIME_SHORT = DateTime.DATETIME_SHORT;
export const TIME_SIMPLE = DateTime.TIME_SIMPLE;
export const TIME_WITH_SECONDS = DateTime.TIME_WITH_SECONDS;
export const TIME_WITH_LONG_OFFSET = DateTime.TIME_WITH_LONG_OFFSET;
