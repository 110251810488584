<template>
  <div>
    <div
      :style="{ 'background-image': cover }"
      class="single-competition-poster-wrapper"
    >
      <div
        v-if="
          tournament.status === `started` &&
            tournament.participant_status !== `qualified`
        "
        class="label-wrapper-hero"
      >
        <TextBlock class="label-started-hero">{{
          $t('_web_status_competition-started', 'COMPETITION STARTED')
        }}</TextBlock>
      </div>
      <div v-if="tournament.status === `closed`" class="label-wrapper-hero">
        <TextBlock class="label-finished-hero">{{
          $t('_web_status_competition-finished', 'COMPETITION FINISHED')
        }}</TextBlock>
      </div>
      <div
        v-if="
          (tournament.status === `started` &&
            tournament.participant_status !== `qualified`) ||
            tournament.status === `closed`
        "
        class="overlay-hero-single"
      ></div>
      <div class="shareButton share-button-wrapper">
        <TournamentActions
          :tournament="tournament"
          :hide-brackets-button="true"
          :hide-join-actions="true"
          @revoked="addRevokedStatus"
        />
      </div>
      <div class="heroInfo">
        <div class="heroName">
          <ItemStatusLabel
            v-if="tournament.status === 'announced'"
            :tournament="tournament"
            class="arenaStatus"
          />
          <span v-if="tournament.status === 'announced'" class="arenaDate">
            {{ formatDate(tournament.tournament_start * 1000, true) }}
          </span>
          <span
            v-if="tournament.status === 'registration_opened'"
            class="arenaDate"
          >
            {{ formatDate(tournament.tournament_start * 1000, true) }}
          </span>
          <span
            v-if="tournament.status === 'registration_closed'"
            class="arenaDate"
          >
            {{ formatDate(tournament.tournament_start * 1000, true) }}
          </span>
          <span
            v-if="tournament.status === 'qualification_completed'"
            class="arenaDate"
          >
            <span>{{
              formatDate(tournament.tournament_start * 1000, true)
            }}</span>
          </span>
          <span v-if="tournament.status === 'started'" class="arenaDate">
            <span>{{
              formatDate(tournament.tournament_start * 1000, true)
            }}</span>
          </span>
          <span v-if="tournament.status === 'closed'" class="arenaDate">
            <span>{{
              formatDate(tournament.tournament_start * 1000, true)
            }}</span>
          </span>
          <TournamentCardTitle class="arenaTitle" :tournament="tournament" />
        </div>
        <div class="heroButtons">
          <ActionNew class="" :tournament="tournament" />
        </div>
      </div>
    </div>
    <div class="heroInfo-mobile">
      <div class="heroName-mobile">
        <ItemStatusLabel
          v-if="tournament.status === 'announced'"
          :tournament="tournament"
          class="arenaStatus"
        />
        <span v-if="tournament.status === 'announced'" class="arenaDate">
          {{ formatDate(tournament.tournament_start * 1000, true) }}
        </span>
        <span
          v-if="tournament.status === 'registration_opened'"
          class="arenaDate"
        >
          {{ formatDate(tournament.tournament_start * 1000, true) }}
        </span>
        <span
          v-if="tournament.status === 'registration_closed'"
          class="arenaDate"
        >
          {{ formatDate(tournament.tournament_start * 1000, true) }}
        </span>
        <span
          v-if="tournament.status === 'qualification_completed'"
          class="arenaDate"
        >
          <span>{{
            formatDate(tournament.tournament_start * 1000, true)
          }}</span>
        </span>
        <span v-if="tournament.status === 'started'" class="arenaDate">
          <span>{{
            formatDate(tournament.tournament_start * 1000, true)
          }}</span>
        </span>
        <span v-if="tournament.status === 'closed'" class="arenaDate">
          <span>{{
            formatDate(tournament.tournament_start * 1000, true)
          }}</span>
        </span>
        <TournamentCardTitle class="arenaTitle" :tournament="tournament" />
      </div>
      <div class="heroButtons">
        <ActionNew class="" :tournament="tournament" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { propRequired } from '@/utils/factories';
import ItemStatusLabel from './components/StatusLabel';
import TextBlock from '@/components/atoms/TextBlock.vue';
import TournamentCardTitle from '@/components/common/MyTournamentCard/Title';
import ActionNew from './components/ActionNew.vue';
import TournamentActions from '@/views/account/header/statusBar/default/actions/Tournament.vue';

export default {
  name: 'TournamentsListPinnedItem',
  components: {
    TournamentCardTitle,
    TournamentActions,
    ItemStatusLabel,
    ActionNew,
    TextBlock,
  },
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    ...mapGetters(['currentTournament']),
    currentTournamentTest() {
      return this.currentTournament;
    },
    status() {
      return this.currentTournament.status;
    },
    formattedDate() {
      return new Date(this.tournament.tournament_start).toLocaleDateString();
    },
    cover({ tournament: { cover = '' } }) {
      return `url(${encodeURI(cover)})` || null;
    },
  },
  methods: {
    addRevokedStatus(status) {
      this.tournament.participant_status = status;
    },
    formatDate(date, showTime) {
      if (showTime) {
        return new Date(date).toLocaleString('default', {
          day: 'numeric',
          month: 'short',
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
          hourCycle: 'h12',
        });
      }
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
<style lang="scss" scoped>
.single-competition-poster-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  padding: 10px;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
}
.heroInfo {
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  background: rgba(3, 3, 3, 0.43);
  backdrop-filter: blur(50px);
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 10px;
  @media (max-width: 800px) {
    display: none;
  }
}

.shareButton :first-child {
  align-self: flex-start;
  z-index: 4;
}

.heroName-mobile {
  display: none;
  flex-direction: column;
  @media (max-width: 800px) {
    display: flex;
  }
}
.heroInfo-mobile {
  display: none;
  background-color: #ffffff19;
  padding: 16px 12px 16px 12px;
  margin-top: 10px;
  border-radius: 5px;
  flex-direction: column;

  @media (max-width: 800px) {
    display: flex;
  }
}
.heroName {
  margin-left: 18px;
  margin-top: 29px;
  flex-basis: 50%;
  line-height: 140%;
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 1300px) {
    flex-basis: unset;
    margin-left: 0px !important;
  }

  .app-rtl & {
    margin-right: 18px;
  }
  .app-ltr & {
    margin-left: 18px;
  }
}

.heroButtons {
  margin-right: 18px;
  flex-basis: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    justify-content: center;
    display: block;
  }
  @media (max-width: 1300px) {
    flex-basis: unset;
    margin-right: 0px;
  }
}
.arenaTitle {
  @media (max-width: 800px) {
    margin-bottom: 0px !important;
    // justify-content: center !important;
  }
  @media (max-width: 1300px) {
    font-size: 1.3rem !important;
  }
}
.arenaStatus {
  margin-top: 20px;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ab74ff;
}

.arenaDate {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 1px;
  margin-top: 29px !important;
  color: #ffffff;
  text-transform: uppercase;
  @media (max-width: 800px) {
    margin-top: 0px !important;
  }
}
// .tournament-list_pinned_carousel {
//   .tournament-list_pinned-item {
//     &_panel {
//       .tournament-card_sponsors {
//         top: -2.5rem;
//       }
//     }
//   }
// }

// .tournament-list_pinned-item {
//   position: relative;
//   background-size: cover;
//   background-position: top center;

//   &:before {
//     content: '';
//     position: absolute;
//     inset: 0;
//     background-size: cover;
//     background-position: top center;
//     background-image: var(--background-image);
//     transition: transform 0.2s;
//   }
//   &.paused:before {
//     transform: scale(1.04);
//   }

//   &,
//   &:hover,
//   &:active,
//   a,
//   a:hover,
//   a:active {
//     text-decoration: none;
//     color: $ph-primary-text;
//   }

//   &_panel {
//     position: absolute;
//     z-index: 1;
//     bottom: 0;
//     width: 100%;
//     padding: 3rem;

//     .badge-hera {
//       border-radius: 8px;
//     }

//     .tournament-card_sponsors {
//       position: relative;
//       z-index: 1;
//       top: 0;
//       padding: 0 0.5rem;
//       transform: none;
//       max-width: 246px;
//       width: unset;
//       align-self: flex-end;
//     }
//   }

//   // replace default position
//   &_prize {
//     .tournament-card_prize,
//     .tournament-card_prize_tail {
//       top: 0;
//     }
//   }

//   &_organizer {
//     // replace default position
//     position: absolute;
//     top: 3rem;
//     z-index: 1;
//     @include auto-rtl('left', 3rem);
//   }

//   &_title {
//     line-height: 2.25rem !important;
//     justify-content: start;
//   }

//   &:after {
//     content: '';
//     position: absolute;
//     z-index: 0;
//     inset: 0;
//     background: linear-gradient(
//         180deg,
//         rgba(11, 14, 19, 0.31) 0%,
//         rgba(11, 14, 19, 0) 100%
//       ),
//       linear-gradient(
//         180deg,
//         rgba(11, 14, 19, 0) 30.57%,
//         rgba(11, 14, 19, 0.9) 81.59%
//       );
//   }

.status-actions {
  .status-bar-actions {
    @include auto-rtl(margin-left, 1rem !important);
    > span:first-child {
      @include auto-rtl(margin-left, unset !important);
    }
  }
}
.share-button-wrapper {
  background: rgba(3, 3, 3, 0.43);
  border-radius: 30px;
  padding: 0px 15px 0px 15px;
  @media (max-width: 800px) {
    background: unset;
  }
}
.overlay-hero-single {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0a0a0a56;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 0px;
  top: 0px;
}
.label-started-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #078549;
  color: white;
  max-width: 153px;
  height: 57px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
}
.label-finished-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0d1117;
  color: white;
  max-width: 153px;
  height: 57px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
}
.label-wrapper-hero {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
// }
</style>
