export const displayName = item => {
  if (item.event && item.event.name) {
    return item.event.name;
  }
  const PROPS = [
    '_name', // account
    'display_name',
    'name',
    'login',
    'id',
  ];
  return item[PROPS.find(prop => item[prop])];
};

export default {
  displayName,
};
