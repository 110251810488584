<template>
  <div class="text-center">
    <h2>
      {{
        message ||
          translatedMessage ||
          this.$t('_web_404_error', 'Wrong URI or endpoint not implemented yet')
      }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    message: {
      type: String,
      default: null,
    },
  },
  computed: {
    translatedMessage() {
      let meta = this.$route.meta;
      if (meta && meta.messageKey) {
        return this.$t(meta.messageKey, meta.messageFallback);
      }
      return '';
    },
  },
};
</script>
