<template>
  <div
    class="wrapper-control position-relative"
    :aria-invalid="!!error"
    :class="[{ 'read-only': readOnly, error }]"
  >
    <div
      v-if="!hideLabel && (label || tooltip || hasCounter)"
      class="d-flex justify-content-between wrapper-control-label"
    >
      <TextLabel
        v-if="label"
        :for="id"
        :text="label"
        :required="required && !viewMode"
        :class="{ 'h-mr-2': tooltip || hasCounter }"
      />
      <Counter
        v-if="hasCounter"
        class="counter-form h-ml-auto"
        :class="{ 'h-mr-3': tooltip }"
        :current-input="currentCounter"
        :max-length="maxLengthCounter"
      />
      <InfoTooltip v-if="tooltip" class="tooltip-form h-mr-0" :text="tooltip" />
    </div>
    <slot name="control"></slot>
    <div
      v-if="error && !errorInTooltip"
      class="d-flex mt-2"
      :class="error ? 'justify-content-between' : 'justify-content-end'"
    >
      <TextBlock
        v-if="!hideErrorText && error && !errorInTooltip"
        variant="error"
        :size="$scss.fontSizes_xs"
        line-height="0.875rem"
        :text="error"
        @focus="test"
      />
    </div>

    <InfoTooltip
      v-if="error && errorInTooltip"
      :text="error"
      :show-icon="false"
      class="position-absolute error-tooltip"
    >
      <AppIcon name="danger" width="16" :fill="$scss.errorColor" />
    </InfoTooltip>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import InfoTooltip from '@/components/molecules/InfoTooltip';
import TextBlock from '@/components/atoms/TextBlock';
import TextLabel from '@/components/atoms/TextLabel';
import AppIcon from '@/components/atoms/Icon.vue';
import Counter from '@/components/atoms/form/Counter.vue';
export default {
  name: 'WrapperFormControl',
  components: {
    TextBlock,
    InfoTooltip,
    TextLabel,
    AppIcon,
    Counter,
  },
  props: {
    hideLabel: prop(Boolean),
    hideErrorText: prop(Boolean),
    errorInTooltip: prop(Boolean),
    required: prop(Boolean),
    viewMode: prop(Boolean),
    readOnly: prop(Boolean),
    error: prop(String),
    label: prop(String),
    tooltip: prop(String),
    id: prop(String),
    currentCounter: prop(String),
    maxLengthCounter: prop(Number, NaN),
    hasCounter: prop(Boolean, false),
  },
  methods: {
    test() {
      console.log('ss');
    },
  },
};
</script>

<style lang="scss">
.wrapper-control {
  position: relative;
  label {
    margin-bottom: 0;
  }
  .tooltip-form {
    @include auto-rtl(margin-right, $ph-mini-space);
  }
  .error-tooltip {
    top: 50%;
    transform: translateY(-50%);
    @include auto-rtl(right, 0.25rem);
    .info-tooltip_content {
      @include auto-rtl(right, 0);
    }
  }
  * {
    transition: all 0.15s ease-out;
  }
  .wrapper-control-label {
    margin-bottom: $ph-small-space;
  }
  &.read-only {
    * {
      pointer-events: none;
    }
    input {
      color: $ph-secondary-text;
      opacity: 0.6;
    }
  }
  &[aria-invalid='true'] {
    input {
      border-color: $ph-error;
    }
  }
  .counter-form {
    font-size: $ph-small-text-font-size;
    line-height: $ph-small-text-line-height;
    color: $ph-secondary-text;
  }
}
</style>
