<template>
  <div class="font-size-sm popup-chat-wrap smaller-screen">
    <div class="container">
      <div class="d-flex justify-content-end">
        <div
          class="popup-chat"
          :class="{ 'popup-chat-open': popoverChatIsOpened }"
        >
          <div
            class="d-flex d-flex justify-content-between ph-bg-input-bg popup-chat-header"
          >
            <div
              class="d-flex flex-grow-1 strong popup-chat-header-toggle"
              @click="togglePopup"
            >
              <AppIcon
                class="flex-shrink-1 p-4"
                :rotate="popoverChatIsOpened ? -90 : 90"
                name="arrow-left"
                :fill="$scss.iconPrimaryColor"
                :width="20"
              />
              <b class="flex-grow-1 text-center">
                {{ $t('_web_popup-chat_messaging', 'Messaging') }}
                <template v-if="unreadCountString">
                  ({{ unreadCountString }})
                </template>
              </b>
            </div>
            <ToolIcon
              icon-name="expand-message"
              icon-size="18"
              :help-text="$t('_web_chat_tooltip', 'Open chat in a new tab')"
              class="h-mr-5"
              style="display: flex;justify-content: center;align-items: center;"
              @click.stop="openInNewTab"
            />
            <div
              class="px- popup-chat-header-options"
              style="display: flex;justify-content: center;align-items: center;"
            >
              <ChatCreate class="popup-chat-header-btn" mini-mode />
            </div>
          </div>
          <ChatsList
            :key="chatListKey"
            class="popup-chat-list"
            mini-mode
            :has-search="searchPanelIsVisible"
          />
          <transition name="fade">
            <div
              v-if="activePopoverChat"
              class="h-mr-0 popup-chat-dialog overflow-hidden"
            >
              <ChatDialog mini-mode />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AppIcon from '@/components/atoms/Icon';
import ChatsList from './list';
import ChatDialog from './correspondence';
import ChatCreate from './create';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';

export default {
  name: 'ConversationPopover',
  components: {
    AppIcon,
    ChatDialog,
    ChatsList,
    ToolIcon,
    ChatCreate,
  },
  data() {
    return {
      maxUnreadCount: 99,
      searchPanelIsVisible: false,
      isTooltipVisible: false,
      tooltipTimer: null,
    };
  },
  computed: {
    ...mapGetters([
      'unreadConversations',
      'activePopoverChat',
      'popoverChatIsOpened',
      'chatListKey',
    ]),
    unreadCountString() {
      return this.unreadConversations > this.maxUnreadCount
        ? `${this.maxUnreadCount}+`
        : Math.max(this.unreadConversations, 0);
    },
  },
  watch: {
    activePopoverChat(id) {
      if (id && !this.popoverChatIsOpened) {
        this.togglePopup();
      }
    },
  },
  methods: {
    ...mapMutations([
      'removeActivePopoverChat',
      'showPopoverChat',
      'hidePopoverChat',
    ]),
    // toggleSearchPanel() {
    //   this.searchPanelIsVisible = !this.searchPanelIsVisible;
    // },
    togglePopup() {
      this[this.popoverChatIsOpened ? 'hidePopoverChat' : 'showPopoverChat']();
      if (!this.popoverChatIsOpened) {
        this.removeActivePopoverChat();
      }
    },
    showTooltipWithDelay() {
      this.tooltipTimer = setTimeout(() => {
        this.isTooltipVisible = true;
      }, 1500);
    },
    hideTooltip() {
      clearTimeout(this.tooltipTimer);
      this.isTooltipVisible = false;
    },
    openInNewTab() {
      window.open('/conversations', '_blank');
    },
  },
};
</script>
<style lang="scss">
$animation-duration: 0.15s;
$popup-chat-height: 450px;
$popup-chat-header-height: 60px;

.popup-chat-header {
  background: $new-bg-base;
}

.popup-chat-wrap {
  position: fixed;
  z-index: 22;
  top: 100vh; // do not use 100% or bottom: 0;
  right: 0;
  left: 0;
  height: 0;

  .popup-chat {
    position: relative;
    width: 292px;
    height: $popup-chat-height;
    bottom: 0; // hide popup (bottom: $popup-chat-header-height;)
    transition: bottom $animation-duration;
    border-radius: $ph-large-radius $ph-large-radius 0 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    &-open {
      bottom: $popup-chat-height;
      .popup-chat-header-toggle {
        opacity: 1;
      }
    }
    &-header {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: $ph-large-radius $ph-large-radius 0 0;
      height: $popup-chat-header-height;
      line-height: $popup-chat-header-height;
      position: relative;
      z-index: 2;
      &-toggle {
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
      &-btn {
        color: inherit;
        vertical-align: top;
        opacity: 0.8;
        &:hover,
        &:focus,
        &:active,
        &.active {
          opacity: 1;
        }
      }
    }
    &-list {
      position: absolute;
      width: 292px;
      height: $popup-chat-height - $popup-chat-header-height;
      bottom: 0;
      background: $new-bg-base;
      @include auto-rtl(right, 0);
    }

    &-dialog {
      position: absolute;
      z-index: 2;
      width: 396px;
      height: $popup-chat-height - $popup-chat-header-height;
      bottom: 0;
      background: $new-bg-base;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
      @include auto-rtl(border-radius, $ph-large-radius 0 0 0);
      transition: opacity $animation-duration,
        transform ($animation-duration * 2);

      .list-loader {
        min-height: 170px;
      }
      &.fade-enter-active,
      &.fade-leave-active {
        z-index: -1;
      }

      &.fade-enter,
      &.fade-leave-to {
        opacity: 0;
        @include auto-rtl(transform, translateX(100%));
      }

      @include auto-rtl(
        (
          left: unset,
          right: 292px,
        )
      );
    }
  }
}

.expand-chat-icon {
  width: 20px;
  height: 20px;
}
@media (max-width: 767px) {
  .smaller-screen {
    display: none;
  }
}
.image-container {
  position: relative;
}
.tooltip {
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3a3a4f;
  color: #fff;
  padding: 0px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
</style>
