// import Vue from 'vue';

export const SEARCH_DEFAULT_STATE = () => {
  return {
    searchQuery: '',
  };
};

const getters = {
  searchQuery: state => state.searchQuery,
};

const mutations = {
  setSearchQuery(state, query) {
    state.searchQuery = query;
  },

  // clearToSearchDefaultState(state) {
  //   const defaultState = SEARCH_DEFAULT_STATE();
  //
  //   Object.keys(defaultState).forEach(key => {
  //     Vue.set(state, key, defaultState[key]);
  //   });
  // },
};

export default {
  state: SEARCH_DEFAULT_STATE(),
  getters,
  mutations,
};
