<template>
  <div
    v-if="$options.renderData && !isHidden"
    class="text-decoration-none text-light p-0 navbar-notify-item"
    :class="{
      'block-loading content-blur': isLoading,
      'd-none': isHidden,
    }"
  >
    <div class="notif-item-contain" @click="redirect">
      <div class="avatar-notif">
        <ListItemNoti
          :avatar-props="$options.avatarProps"
          :read="data.read"
          class=""
        />
      </div>
      <div class="d-flex flex-column">
        <div>
          <TextWithLinks
            tag="div"
            class="notify-text"
            :content="$options.renderData.content"
            :content-props="$options.renderData.contentProps"
            :translate-key="`_web_notifications_desc_${data.type}`"
          />
          <div class="d-flex align-items-start">
            <div
              v-if="data.actions && data.actions.length"
              class="d-flex flex-grow-1 notify-buttons"
            >
              <FormBtn
                v-for="action in data.actions"
                :key="`action-${data.id}-${action}`"
                class="my-3 ml-4 text-capitalize"
                :class="[
                  isConfirmAction(action)
                    ? 'action-btn-color'
                    : 'cancle-btn-color',
                ]"
                @click.native.prevent.stop="postAction(action)"
              >
                {{ $$t(action, `action_${action}`) }}
              </FormBtn>
            </div>
          </div>
        </div>
        <ListItem :read="data.read">
          <template slot="subtitle">
            <div class="text-muted notify-date">
              {{ data.sent_date | localDate($times.DATETIME_SUBMED) }}
            </div>
          </template>
        </ListItem>
      </div>
    </div>
    <div v-if="close" class="close-btn-notify" @click="deleteAction">x</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { generateNotificationByData } from '@/utils/textLink';
import { translate } from '@/mixins/translate';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';

import TextWithLinks from '@/components/text/TextWithLinks';
import FormBtn from '@/components/atoms/HeraFormBtn';
import ListItem from '@/components/common/ListItem';
import ListItemNoti from '@/components/common/ListItemNoti';

import { BATTLE, ACCOUNT } from '@/constants/accounts';
import { REPLY } from '@/constants/router';

const ACTIONS = {
  confirm: 'Confirm',
  reject: 'Reject',
};

export default {
  name: 'NotificationItemMini',
  components: {
    ListItem,
    TextWithLinks,
    FormBtn,
    ListItemNoti,
  },
  mixins: [translate('_web_notification')],
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    markAsRead: {
      type: Function,
      default() {},
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isHidden: false,
    };
  },
  computed: {
    title() {
      return this.$options.renderData
        ? this.$$t(this.$options.renderData.title, `title_${this.data.type}`)
        : null;
    },
  },
  watch: {
    data() {
      this.createData();
    },
  },
  created() {
    this.createData();
  },
  methods: {
    ...mapActions([
      'postNotificationAction',
      'deleteNotificationItem',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),
    ...mapActions('my', ['fetchMyAccount']),
    createData() {
      this.$options.renderData = generateNotificationByData(this.data);
      if (this.$options.renderData) {
        const { sender } = this.data;
        const { helper } = this.$options.renderData;

        if (sender && (sender.id || sender.login)) {
          this.$options.avatarProps = {
            size: 42,
            imgUrl: sender.avatar,
            type: sender.avatar ? sender.type : this.data.type,
            ...((helper && helper.avatar) || {}),
          };
        } else {
          this.$options.avatarProps = {
            size: 42,
            type: this.data.type,
            ...((helper && helper.avatar) || {}),
          };
        }
      }
    },
    isConfirmAction(action) {
      return action === ACTIONS.confirm;
    },
    postAction(action) {
      this.isLoading = true;

      this.postNotificationAction({
        id: this.data.id,
        action: action.toLowerCase(),
      })
        .then(() =>
          this.actionCallback(this.data, this.isConfirmAction(action))
        )
        .catch(err => {
          this.actionErrorCallback(
            this.data,
            this.isConfirmAction(action),
            err
          );
        })
        .finally(this.hide);
    },
    deleteAction() {
      this.isLoading = true;
      if (this.isPopup) {
        this.hide();
      } else {
        this.deleteNotificationItem(this.data.id)
          .then(this.hide)
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    //todo #TH-8393 create universal callback for actions
    actionCallback({ type }, isConfirm) {
      if (type === 'clan_member_invited' && isConfirm) {
        // update my Model
        this.fetchMyAccount();
      }
    },
    //todo #TH-8393 create universal callback for actions
    actionErrorCallback({ type }, isConfirm, err) {
      if (type === 'clan_member_invited' && isConfirm) {
        const translateSuffix = 'clan_can-not-accept';

        return this.openModal({
          name: 'HeraConfirm',
          props: {
            title: this.$$t(
              "You can't approve invitation",
              `${translateSuffix}_title`
            ),
            text: this.$$t(
              'You have to leave current team first',
              `${translateSuffix}_text`
            ),
            cancelButtonText: false,
            confirmButtonText: this.$$t('Got It', `${translateSuffix}_button`),
          },
          events: {
            confirm: this.closeModal,
          },
          settings: {
            clickOnBackdrop: false,
          },
        });
      }

      this.errorNotify(err);
    },
    hide() {
      this.isLoading = false;
      this.$emit('removed', this.data.id);
    },
    redirect() {
      !this.data.read && this.$emit('markAsRead', this.data);
      if (this.data.type === 'shop_collection_promotion') {
        window.open(this.$options.renderData.link, '_blank', 'noreferrer');
        return;
      }

      let accountType =
        this.$lodash.get(
          this.$options.renderData,
          'linkReplaced.params.accountType'
        ) || this.$lodash.get(this.$options.renderData, 'linkReplaced.name');

      if (accountType === 'post') {
        let thread = this.$lodash.get(
          this.$options.renderData,
          'linkReplaced.params.threadId'
        );
        if (thread === 'thread_id') thread = null;

        this.$router.push({
          name: thread ? REPLY : 'SinglePost',
          params: {
            accountType: accountType,
            id: this.$lodash.get(
              this.$options.renderData,
              'linkReplaced.params.id'
            ),
            threadId: thread,
          },
        });
      } else if (accountType === 'billing') {
        this.$router.push({
          name: 'billing',
        });
      } else if (accountType === 'tournaments') {
        this.$router
          .push({
            name: 'singleCompetition',
            params: {
              accountType: accountType,
              tournamentID:
                this.$lodash.get(
                  this.$options.renderData,
                  'linkReplaced.params.accountId'
                ) ||
                this.$lodash.get(
                  this.$options.renderData,
                  'linkReplaced.params.id'
                ),
            },
          })
          .then(window.location.reload());
      } else if (accountType === 'battle') {
        this.$router.push({
          name: ROUTER_NAMES_ACCOUNT.RESULTS,
          params: {
            accountType: BATTLE,
            accountId: this.$lodash.get(
              this.$options.renderData,
              'linkReplaced.params.accountId'
            ),
          },
        });
      } else {
        this.$router
          .push({
            name: ACCOUNT,
            params: {
              accountType: accountType.replace(/s\b/gi, ''),
              accountId:
                this.$lodash.get(
                  this.$options.renderData,
                  'linkReplaced.params.accountId'
                ) ||
                this.$lodash.get(
                  this.$options.renderData,
                  'linkReplaced.params.id'
                ),
            },
          })
          .then(window.location.reload());
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-notify-item {
  position: relative;
  border-bottom: 0;

  &:hover {
    background-color: $new-bg-page !important;
  }

  .avatar {
    margin-top: 0.25rem;
  }

  .list-item-title {
    @include auto-rtl(margin-right, 0 !important);
    max-width: 100%;
    font-size: $ph-basic-font-size;
    line-height: 1.2rem;
  }

  .notify-date {
    font-size: $ph-body-font-size;
  }

  .notify-text {
    font-size: 14px;
    white-space: normal;
    line-height: normal;

    a {
      font-weight: bold;
      color: inherit;
    }
  }

  .notify-actions-wrapper {
    height: 2.56rem;
  }

  .notify-buttons {
    .btn {
      min-width: unset !important;
      opacity: 0.8;
      padding: 0 1rem !important;
      min-height: 2rem !important;
      @include inject-pseudo {
        opacity: 1;
      }
    }

    .btn-bordered {
      background-color: transparent !important;
    }
  }

  .navbar-notify-close {
    position: absolute;
    z-index: 2;
    top: 0.25rem;
    height: auto;
    @include auto-rtl(right, 0.5rem);
    /*opacity: 0;*/
  }

  /*&:hover {*/
  /*.navbar-notify-close {*/
  /*  opacity: 1;*/
  /*}*/
  /*}*/
}

.notif-item-contain {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.notify-date {
  font-size: 12px !important;
}

.avatar-notif {
  .avatar {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    background-color: $hr-bg-notif-avatar !important;
  }
}
.action-btn-color {
  background-color: #5a58f2 !important;
}
.cancle-btn-color {
  background-color: grey !important;
}
.close-btn-notify {
  position: absolute;
  top: 10px;
  @include auto-rtl(right, 10px);
  font-size: 1rem;
  z-index: 1000;
  cursor: pointer;
}
</style>
