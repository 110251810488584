<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConversationRedirect',
  created() {
    const login = this.$route.params.login;

    if (!login) {
      return this.$router.push({
        name: 'conversations',
      });
    }

    this.getConversationInfo({ conversationId: login })
      .then(data => {
        this.$router.push({
          name: 'conversations',
          params: {
            id: data.id,
          },
        });
      })
      .catch(err => {
        this.errorNotify(err);
        this.$router.push({
          name: 'conversations',
        });
      });
  },
  methods: {
    ...mapActions(['getConversationInfo', 'errorNotify']),
  },
};
</script>
