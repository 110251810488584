var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-comments"},[(_vm.$route.name !== 'post')?_c('hr'):_vm._e(),(!_vm.$route.path.includes('feed'))?_c('div',[(!_vm.total && _vm.dataReady)?_c('h2',{staticClass:"post-comments-sum mt-5",class:{
        'com-sum-mobile': _vm.$route.path.includes('posts'),
        'com-sum-mobile-single': _vm.$route.path.includes('SinglePosts'),
        'post-comments-sum-tour':
          _vm.$route.path.includes('tournament') ||
          _vm.$route.path.includes('tournaments'),
      }},[_vm._v("\n      "+_vm._s(_vm.$t('_web_comment_no_comments_yet', 'No comments yet'))+"\n    ")]):(_vm.total > 0)?_c('h2',{staticClass:"post-comments-sum mt-5",class:{
        'com-sum-mobile': _vm.$route.path.includes('posts'),
        'com-sum-mobile-single': _vm.$route.path.includes('SinglePosts'),
        'post-comments-sum-tour':
          _vm.$route.path.includes('tournament') ||
          _vm.$route.path.includes('tournaments'),
      }},[_c('span',[_vm._v(_vm._s(_vm.post.comments_count ? _vm.post.comments_count : _vm.total))]),_vm._v("\n      "+_vm._s(_vm.total === 1
          ? _vm.$t('_web_comment', 'Comment')
          : _vm.$t('_web_comments', 'Comments'))+"\n    ")]):_c('h2',{staticClass:"post-comments-sum mt-5 invisible",class:{
        'com-sum-mobile': _vm.$route.path.includes('posts'),
        'com-sum-mobile-single': _vm.$route.path.includes('SinglePosts'),
        'post-comments-sum-tour':
          _vm.$route.path.includes('tournament') ||
          _vm.$route.path.includes('tournaments'),
      }},[_vm._v("\n      A\n    ")]),(_vm.$route.name === "post")?_c('hr',{staticClass:"mobile-hide-hr"}):_vm._e()]):_vm._e(),(_vm.isAuthorized)?_c('div',{ref:"commentEl",staticClass:"d-flex flex-column comment-item form mt-5 y commentRTL",class:{
      loading: _vm.commentLoading,
      'highlights-input-style':
        _vm.$route.path.includes('posts') && !_vm.$route.path.includes('SinglePosts'),
    }},[_c('hr',{staticClass:"header-comment-item mb-3 d-none"}),_c('div',{staticClass:"d-flex align-items-start commentRTL "},[_c('Avatar',{staticClass:"h-mr-3 avatar-mobile-comment commentRTL",attrs:{"img-url":_vm.account._avatar,"size":24,"type":_vm.accountType}}),_c('div',{ref:"commentAreaNew",staticClass:"text-input-container commentRTL",class:{
          'border-input-singlepost': _vm.$route.name === 'SinglePost',
          'focus-border-input': _vm.isFocused,
          'tour-comments-style':
            _vm.$route.path.includes('tournament') && !_vm.isFocused,
        }},[_c('div',{staticClass:"textarea-container",class:{ 'focus-input-field': _vm.isFocused },on:{"click":function($event){_vm.isFocused = true},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.stopPropagation();return _vm.executeKeyUpEvent($event)}}},[_c('TextInputMultiline',{ref:"commentTextareaEl",class:{
              'active-input-style-textarea': _vm.isFocused,
              'active-input-style-posts-route': _vm.$route.path.includes('posts'),
            },attrs:{"placeholder":_vm.$route.name === 'SinglePost'
                ? _vm.$t('_web_post_write_your_comment', 'Write your comment')
                : _vm.$t(
                    '_web_post_write_something_here',
                    'Write something here...'
                  ),"hide-label":"","detect-links":!_vm.linkPreview,"detect-tags":"","detect-mentions":"","is-focused":_vm.isFocused},on:{"linkDetected":_vm.debouncedPreviewRequest,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.stopPropagation();return _vm.publishComment($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.resetForm($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.newLineFix($event)}},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}})],1),(_vm.isFocused)?_c('div',{staticClass:"comment-attachments",class:{ 'comments-att-active': _vm.isFocused }},[_c('div',{staticClass:"d-flex editable-container "},[(_vm.$refs.commentEl)?_c('EditableAttachments',{staticClass:"comment-input-action",attrs:{"tabindex":"30","files":_vm.attachments,"upload-btn-icon":"editable/upload","icon-size":19,"parent-el":_vm.$refs.commentEl,"insert-before":true},on:{"input":_vm.prepareAttachData}}):_vm._e(),_c('div',{ref:"gifIcon",staticClass:"d-flex align-items-center position-relative "},[_c('AppIcon',{ref:"gifPick",staticClass:"comment-input-action",attrs:{"name":"editable/attach-gif","width":"19"},on:{"click":_vm.handleShowGiffs}}),(_vm.showGiffs)?_c('GiffsPicker',{directives:[{name:"clickOutside",rawName:"v-clickOutside",value:({
                  exclude: 'gifIcon',
                  handler: 'closeGiffPicker',
                }),expression:"{\n                  exclude: 'gifIcon',\n                  handler: 'closeGiffPicker',\n                }"}],staticClass:"gif-emoji-rotate",class:_vm.$route.name === 'feed'
                    ? 'goUp centerUp'
                    : _vm.$route.name === 'post' || _vm.$route.name === 'SinglePost'
                    ? ''
                    : _vm.goGiffUp,on:{"addGiff":_vm.publishGiff}}):_vm._e()],1),_c('div',{ref:"smileIcon",staticClass:"d-flex align-items-center position-relative emoji-icon"},[_c('AppIcon',{ref:"emoji",staticClass:"comment-input-action",attrs:{"name":"editable/emoji","width":"19"},on:{"click":_vm.handleShowEmoji}}),(_vm.showEmoji)?_c('EmojiPicker',{directives:[{name:"clickOutside",rawName:"v-clickOutside",value:({
                  exclude: 'smileIcon',
                  handler: 'closeEmojiPicker',
                }),expression:"{\n                  exclude: 'smileIcon',\n                  handler: 'closeEmojiPicker',\n                }"}],staticClass:"gif-emoji-rotate emoji-window-newsfeed",class:_vm.$route.name === 'feed'
                    ? 'goUp'
                    : _vm.$route.name === 'post' || _vm.$route.name === 'SinglePost'
                    ? ''
                    : _vm.goEmojiUp,on:{"select":_vm.addEmoji}}):_vm._e()],1)],1),_c('button',{ref:"postButton",staticClass:"comment-main-post-btn",on:{"click":_vm.publishComment}},[_vm._v("\n            "+_vm._s(_vm.$t('_web_add_post', 'Post'))+"\n          ")])]):_vm._e()])],1),_c('div',{staticClass:"comment-input-footer w-100 mt-4",class:{ active: _vm.isActive }})]):_vm._e(),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.comments.length),expression:"comments.length"}]}),_c('div',{ref:"comments",class:{
      'highlight-comments-style': _vm.$route.path.includes('posts'),
    }},_vm._l((_vm.comments),function(item){return _c('Comment',{key:item.id,attrs:{"id":item.id,"comment":item,"post-id":_vm.post.id,"postowner":_vm.owner},on:{"delete":_vm.removeComments,"refresh":_vm.updateComments}})}),1),(_vm.$route.path.includes('feed'))?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nextPageToken),expression:"nextPageToken"}],ref:"more",staticClass:"my-5"},[_c('p',{staticClass:"btn-accent-border show-more-feed",on:{"click":function($event){$event.preventDefault();return _vm.getComments($event)}}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")])])]):_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nextPageToken),expression:"nextPageToken"}],ref:"more",staticClass:"text-center my-5"},[_c('Btn',{staticClass:"btn-accent-border text-capitalize",on:{"click":function($event){$event.preventDefault();return _vm.getComments($event)}}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }