<template>
  <div
    :key="counterKey"
    class="row text-center status-bar-stats d-flex flex-column"
  >
    <router-link
      v-for="(count, countType) in account.countsStrings"
      :key="`sideBar_${countType}`"
      :to="{
        name: 'contacts',
        params: {
          ...account.params,
          ...$route.params,
          tab: countType,
        },
      }"
      class="col mb-5 text-smaller-screens"
      :class="[{ disabled }, `col-auto`]"
    >
      <template>
        <div class="h-text-left hover-target">
          <TextLabel class="parameter-label">
            {{ $t(`_web_sidebar_${countType}`, countType) }}
          </TextLabel>
          <TextBlock class="parameter-value" :size="1.125" tag="div">{{
            isProfileRemoved ? 0 : count
          }}</TextBlock>
        </div>
      </template>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TextLabel from '@/components/atoms/TextLabel';
import TextBlock from '@/components/atoms/TextBlock';
export default {
  name: 'AccountStatusBarCounters',
  components: {
    TextLabel,
    TextBlock,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('current', ['account', 'accountType']),
    ...mapGetters(['modalIsShow', 'counterKey']),
    isProfileRemoved() {
      return this.$lodash.get(this, 'account.deleted');
    },
  },
  watch: {
    // modalIsShow(isShow) {
    //   if (!isShow) {
    //     this.$router.replace({
    //       query: {
    //         ...this.$lodash.pickBy(
    //           this.$route.query,
    //           (value, key) => key !== 'tab'
    //         ),
    //       },
    //     });
    //   }
    // },
  },
};
</script>

<style lang="scss">
.status-bar-stats {
  a {
    line-height: 1 !important;
    color: $ph-secondary-text;
    @include inject-pseudo() {
      color: $ph-primary-text;
      text-decoration: none;
    }

    &.disabled {
      pointer-events: none;
    }
  }
}
.hover-target {
  &:hover .parameter-value,
  &:hover .parameter-label {
    cursor: pointer;
    font-weight: bolder;
  }
}

@media (max-width: 767px) {
  .status-bar-stats {
    flex-direction: row !important;
  }
  .text-smaller-screens {
    min-width: 33%;
    max-width: 33%;
    overflow: hidden;
  }
}
</style>
