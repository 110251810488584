<template>
  <div
    class="d-flex justify-content-center hera-dropper"
    :class="{ 'has-drag': isDrag }"
    @drop="onDrop"
    @dragover="toggleDrag(true)"
    @dragleave="toggleDrag(false)"
  >
    <span class="align-self-center font-size-lg">
      {{ $t('_web_conversation_placeholder_drop-files', 'Drop Files Here') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Dropper',
  data() {
    return {
      isDrag: false,
    };
  },
  methods: {
    toggleDrag(isDrag) {
      this.isDrag = !!isDrag;
    },
    onDrop(event) {
      this.toggleDrag(false);

      const { dataTransfer } = event;
      if (!dataTransfer) {
        return;
      }

      if (dataTransfer.files && dataTransfer.files.length) {
        this.$emit('drop', dataTransfer);
      }
    },
  },
};
</script>

<style lang="scss">
.hera-dropper {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.25rem;
  border: 3px dotted $ph-accent;
  background: rgba($ph-card, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s;

  .is-dragging.is-dragging-files & {
    visibility: visible;
    opacity: 1;
  }
}
</style>
