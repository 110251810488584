<template>
  <div class="sso-container auth-sso d-flex flex-column justify-content-center">
    <SignIn modal sso @authorized="onAuthorized" />
    <div v-if="isAuthorized" class="sso-loader">
      <Spinner />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { prop } from '@/utils/factories';
import SignIn from './Signin';
import Spinner from '../main/components/Spinner.vue';

export default {
  name: 'SsoSignIn',
  components: {
    SignIn,
    Spinner,
  },
  props: {
    client: prop(String),
    state: prop(String),
    // appId: prop(String),
    // redirect: prop(String),
  },
  computed: {
    ...mapGetters(['isAuthorized', 'accessToken', 'refreshToken']),
  },
  mounted() {
    // var text = this.$t(
    //   '_web_sso_confirm',
    //   'You agree to give access to your profile by external Application?'
    // );
    if (this.isAuthorized) {
      this.redirectOpener();
      // this.openModal({
      //   name: 'HeraConfirm',
      //   props: {
      //     text,
      //   },
      //   events: {
      //     cancel: this.close,
      //     confirm: this.redirectOpener,
      //   },
      // });
    }
  },
  methods: {
    ...mapActions(['openModal', 'closeModal', 'ssoSwitch', 'errorNotify']),
    onAuthorized() {
      if (this.isAuthorized) {
        this.redirectOpener();
      }
    },
    redirectOpener() {
      const accountType = 'thirdparty';
      const accountId = this.client;
      const stateId = this.state;
      const ext = this.$route.query;

      console.info(`sso.vue: ${accountId}, ${stateId}`);

      this.ssoSwitch({ accountId, accountType, stateId, ext })
        .then(resp => {
          let redirectUrl = new URL(resp.redirect);
          let queryString = new URLSearchParams(redirectUrl.search);
          //queryString.set("access_token", resp.access_token);
          //queryString.set("refresh_token", resp.refresh_token);
          redirectUrl.search = queryString.toString();
          window.location.href = redirectUrl.toString();
        })
        .catch(err => {
          // if (err.status === 401) {
          //   this.closeModal();
          // }
          this.errorNotify(err);
        });
    },
    close() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.sso-container {
  width: 100%;
  height: 100vh;
}
.sso-loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
