<template v-if="isAuthorized">
  <div class="post-comments">
    <!-- <div v-if="isUpdateCommentsVisible" class="text-center my-5">
      <ToolIcon
        icon-name="reload"
        icon-size="24"
        :help-text="$t('_web_post_update-comments', 'Update Comments')"
        @click.prevent="updateComments"
      />
    </div> -->

    <hr v-if="$route.name !== 'post'" />
    <div v-if="!$route.path.includes('feed')">
      <h2
        v-if="!total && dataReady"
        class="post-comments-sum mt-5"
        :class="{
          'com-sum-mobile': $route.path.includes('posts'),
          'com-sum-mobile-single': $route.path.includes('SinglePosts'),
          'post-comments-sum-tour':
            $route.path.includes('tournament') ||
            $route.path.includes('tournaments'),
        }"
      >
        {{ $t('_web_comment_no_comments_yet', 'No comments yet') }}
      </h2>
      <h2
        v-else-if="total > 0"
        class="post-comments-sum mt-5"
        :class="{
          'com-sum-mobile': $route.path.includes('posts'),
          'com-sum-mobile-single': $route.path.includes('SinglePosts'),
          'post-comments-sum-tour':
            $route.path.includes('tournament') ||
            $route.path.includes('tournaments'),
        }"
      >
        <span>{{ post.comments_count ? post.comments_count : total }}</span>
        {{
          total === 1
            ? $t('_web_comment', 'Comment')
            : $t('_web_comments', 'Comments')
        }}
      </h2>
      <!-- h2 below to preserve loading transition height, no need for translations, just a character for height reference -->
      <h2
        v-else
        class="post-comments-sum mt-5 invisible"
        :class="{
          'com-sum-mobile': $route.path.includes('posts'),
          'com-sum-mobile-single': $route.path.includes('SinglePosts'),
          'post-comments-sum-tour':
            $route.path.includes('tournament') ||
            $route.path.includes('tournaments'),
        }"
      >
        A
      </h2>
      <hr v-if="$route.name === `post`" class="mobile-hide-hr" />
    </div>
    <div
      v-if="isAuthorized"
      ref="commentEl"
      class="d-flex flex-column comment-item form mt-5 y commentRTL"
      :class="{
        loading: commentLoading,
        'highlights-input-style':
          $route.path.includes('posts') && !$route.path.includes('SinglePosts'),
      }"
    >
      <hr class="header-comment-item mb-3 d-none" />
      <div class="d-flex align-items-start commentRTL ">
        <Avatar
          :img-url="account._avatar"
          :size="24"
          :type="accountType"
          class="h-mr-3 avatar-mobile-comment commentRTL"
        />

        <div
          ref="commentAreaNew"
          class="text-input-container commentRTL"
          :class="{
            'border-input-singlepost': $route.name === 'SinglePost',
            'focus-border-input': isFocused,
            'tour-comments-style':
              $route.path.includes('tournament') && !isFocused,
          }"
        >
          <div
            class="textarea-container"
            :class="{ 'focus-input-field': isFocused }"
            @click="isFocused = true"
            @keyup.enter.exact.stop="executeKeyUpEvent"
          >
            <TextInputMultiline
              ref="commentTextareaEl"
              v-model="commentText"
              :class="{
                'active-input-style-textarea': isFocused,
                'active-input-style-posts-route': $route.path.includes('posts'),
              }"
              :placeholder="
                $route.name === 'SinglePost'
                  ? $t('_web_post_write_your_comment', 'Write your comment')
                  : $t(
                      '_web_post_write_something_here',
                      'Write something here...'
                    )
              "
              hide-label
              :detect-links="!linkPreview"
              detect-tags
              detect-mentions
              :is-focused="isFocused"
              @linkDetected="debouncedPreviewRequest"
              @keyup.enter.exact.stop="publishComment"
              @keydown.enter.exact.prevent="newLineFix"
              @keyup.esc="resetForm"
            />
          </div>
          <div
            v-if="isFocused"
            class="comment-attachments"
            :class="{ 'comments-att-active': isFocused }"
          >
            <div class="d-flex editable-container ">
              <EditableAttachments
                v-if="$refs.commentEl"
                tabindex="30"
                :files="attachments"
                upload-btn-icon="editable/upload"
                :icon-size="19"
                :parent-el="$refs.commentEl"
                :insert-before="true"
                class="comment-input-action"
                @input="prepareAttachData"
              />
              <div
                ref="gifIcon"
                class="d-flex align-items-center position-relative "
              >
                <AppIcon
                  ref="gifPick"
                  name="editable/attach-gif"
                  width="19"
                  class="comment-input-action"
                  @click="handleShowGiffs"
                />
                <GiffsPicker
                  v-if="showGiffs"
                  v-clickOutside="{
                    exclude: 'gifIcon',
                    handler: 'closeGiffPicker',
                  }"
                  class="gif-emoji-rotate"
                  :class="
                    $route.name === 'feed'
                      ? 'goUp centerUp'
                      : $route.name === 'post' || $route.name === 'SinglePost'
                      ? ''
                      : goGiffUp
                  "
                  @addGiff="publishGiff"
                />
              </div>
              <div
                ref="smileIcon"
                class="d-flex align-items-center position-relative emoji-icon"
              >
                <AppIcon
                  ref="emoji"
                  name="editable/emoji"
                  width="19"
                  class="comment-input-action"
                  @click="handleShowEmoji"
                />
                <EmojiPicker
                  v-if="showEmoji"
                  v-clickOutside="{
                    exclude: 'smileIcon',
                    handler: 'closeEmojiPicker',
                  }"
                  class="gif-emoji-rotate emoji-window-newsfeed"
                  :class="
                    $route.name === 'feed'
                      ? 'goUp'
                      : $route.name === 'post' || $route.name === 'SinglePost'
                      ? ''
                      : goEmojiUp
                  "
                  @select="addEmoji"
                />
              </div>
            </div>
            <button
              ref="postButton"
              class="comment-main-post-btn"
              @click="publishComment"
            >
              {{ $t('_web_add_post', 'Post') }}
            </button>
          </div>
        </div>
      </div>
      <div
        :class="{ active: isActive }"
        class="comment-input-footer w-100 mt-4"
      ></div>
    </div>
    <hr v-show="comments.length" />

    <div
      ref="comments"
      :class="{
        'highlight-comments-style': $route.path.includes('posts'),
      }"
    >
      <Comment
        v-for="item in comments"
        :id="item.id"
        :key="item.id"
        :comment="item"
        :post-id="post.id"
        :postowner="owner"
        @delete="removeComments"
        @refresh="updateComments"
      />
    </div>
    <div v-if="$route.path.includes('feed')">
      <div v-show="nextPageToken" ref="more" class="my-5">
        <p
          class="btn-accent-border show-more-feed"
          @click.prevent="getComments"
        >
          {{ buttonText }}
        </p>
      </div>
    </div>
    <div v-else>
      <div v-show="nextPageToken" ref="more" class="text-center my-5">
        <Btn
          class="btn-accent-border text-capitalize"
          @click.prevent="getComments"
        >
          {{ buttonText }}
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
// import { GENERIC } from '@/constants/postTypes';
import Avatar from '@/components/images/Avatar';
import Btn from '@/components/atoms/HeraFormBtn.vue';
// import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';
import EditableAttachments from '@/components/form/contentEditable/Attachments';
import Comment from './CommentItem.vue';
import AppIcon from '@/components/atoms/Icon';
import EmojiPicker from '@/components/common/EmojiPicker';
import GiffsPicker from '@/components/common/GiffsPicker';
// import { API } from '@/api/config';
import TextInputMultiline from '@/components/molecules/form/TextInputMultilineWithActivators';

export default {
  name: 'Comments',
  components: {
    GiffsPicker,
    EmojiPicker,
    Comment,
    Avatar,
    Btn,
    AppIcon,
    // ToolIcon,
    EditableAttachments,
    TextInputMultiline,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'post',
    },
    owner: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      dataReady: false,
      total: 0,
      isActive: false,
      showGiffs: false,
      showEmoji: false,
      inputSelection: {},
      comments: [],
      attachments: [],
      commentText: '',
      nextPageToken: '',
      page_size: 20,
      willShowComments: false,
      commentLoading: false,
      linkPreview: null,
      debouncedPreviewRequest: null,
      borderColor: 'none',
      goEmojiUp: '',
      goGiffUp: '',
      isFocused: false,
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId', 'accountType']),
    ...mapGetters([
      'isAuthorized',
      'currentTournament',
      'currentTournamentStages',
    ]),

    isUpdateCommentsVisible() {
      return !this.isLoading && this.willShowComments && this.isAuthorized;
    },
    isBtnSendActive() {
      return !!(this.commentText.trim().length || this.attachments.length);
    },
    buttonText() {
      const remainingComments = this.total - this.comments.length;
      if (remainingComments > 0) {
        return this.$t(
          '_web_post_more-comments-new',
          `Show ${remainingComments} more comment${
            remainingComments !== 1 ? 's' : ''
          }`
        );
      } else {
        return '';
      }
    },
    $eventHandler() {
      return {
        ...this.$listeners,
        input: this.onInput,
        paste: this.onPaste,
        focus: this.onFocus,
        blur: this.onBlur,
        drop: this.onDrop,
        selectionchange: this.onSelectionchange,
        keydown: this.onDelete,
        ...(this.maxLength
          ? {
              keydown: this.checkMaxLength,
            }
          : null),
      };
    },
  },
  watch: {
    post(val) {
      this.getComments();
      if (val.id) {
        this.restoreCommentsDefaults();
        if (this.$route.params.id) {
          this.showComments(this.post.comments_count);
        }
      }
    },
    showGiffs() {
      // if (this.showEmoji) {
      //   this.showEmoji = !this.showEmoji;
      //   this.showGiffs = !this.showGiffs;
      // }
      this.goGiffUp =
        this.$refs.gifPick.$el.getBoundingClientRect().y - window.pageYOffset >
        80
          ? 'goUp'
          : '';
    },
    showEmoji() {
      // if (this.showGiffs) {
      //   this.showGiffs = !this.showGiffs;
      //   this.showEmoji = !this.showEmoji;
      // }
      this.goEmojiUp =
        this.$refs.emoji.$el.getBoundingClientRect().y - window.pageYOffset > 80
          ? 'goUp'
          : '';
    },
    comments() {
      // this.getCommentsCount();
    },
  },
  created() {
    // this.getCommentsCount();

    if (this.$route.params.id) {
      this.showComments(this.post.comments_count);
    }
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
  },
  mounted() {
    this.getComments();
    if (this.isAuthorized) {
      document.addEventListener('click', this.closeDropdowns);
      // this.getCommentsCount();
    }
    document.addEventListener('click', this.handleClickOutside);
  },

  methods: {
    handleClickOutside(event) {
      if (
        this.$refs.commentAreaNew &&
        !this.$refs.commentAreaNew.contains(event.target)
      ) {
        this.isFocused = false;
      }
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('isFocused');
      const { endContainer } = this.getRange();
      this.setPos(endContainer.length, endContainer);
    },
    onBlur() {
      this.isFocused = false;
      this.$emit('lostFocus');
    },
    handleShowEmoji() {
      this.showEmoji = !this.showEmoji;
      if (this.showGiffs) {
        this.showGiffs = !this.showGiffs;
      }
    },
    handleShowGiffs() {
      if (this.showEmoji) {
        this.showEmoji = !this.showEmoji;
      }
      this.showGiffs = !this.showGiffs;
    },
    newLineFix(e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    closeDropdowns(event) {
      if (
        this.$refs.gifIcon &&
        !this.$refs.gifIcon.contains(event.target) &&
        this.showGiffs
      ) {
        this.showGiffs = false;
      }
      if (
        this.$refs.smileIcon &&
        !this.$refs.smileIcon.contains(event.target) &&
        this.showEmoji
      ) {
        this.showEmoji = false;
      }
    },
    ...mapActions([
      'getOpenGraphDataByLink',
      'fetchComments',
      'postComment',
      'errorNotify',
      'successNotify',
    ]),
    toggleActive() {
      // console.log('a');
      this.isActive = !this.isActive;
    },
    getOpenGraphData(link) {
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },

    restoreCommentsDefaults() {
      this.nextPageToken = '';
      this.comments = [];
      this.isLoading = false;
      this.commentLoading = false;
      this.willShowComments = false;
    },

    showComments(list) {
      if (!this.willShowComments && list > 0) {
        this.comments = [];
        // this.getComments();
      }

      if (this.willShowComments) {
        this.restoreCommentsDefaults();
      } else {
        this.willShowComments = true;
      }
    },

    async getComments() {
      if (this.nextPageToken !== null && this.post.id) {
        this.toggleCommentLoading();
        this.isLoading = true;

        const data = {
          type:
            this.$route.name === 'singleCompetition' ? 'tournament' : 'post',
          id: this.post.id,
          page: this.nextPageToken,
          page_size: this.page_size,
        };

        this.fetchComments(data)
          .then(res => {
            this.comments = this.$lodash.uniqBy(
              [...this.comments, ...res.items],
              'id'
            );
            this.total = res.total;
            this.nextPageToken = res.next_page_token;
            this.dataReady = true;
            this.page_size = 15;
            this.toggleCommentLoading();
            this.isLoading = false;
          })
          .catch(err => {
            this.errorNotify(err);
            this.isLoading = false;
            this.toggleCommentLoading();
          });
      }
    },
    // async getCommentsCount() {
    //   try {
    //     const response = await API(`/comments/post/${this.post.id}`);
    //     // console.log(response);
    //     return (this.total = response.data.total);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    prepareAttachData(data) {
      if (Array.isArray(data)) {
        this.attachments = data;
      }
    },

    publishGiff(id, token) {
      const displayGiff = {
        type: 'giphy',
        id,
        token,
      };
      this.attachments = [token];
      this.publishComment(displayGiff);
      this.showGiffs = false;
    },

    publishComment(gif) {
      if (!this.commentLoading) {
        if (!this.isBtnSendActive) {
          const textareaEl = this.$lodash.get(
            this.$refs,
            'commentTextareaEl.$refs.textarea'
          );
          if (textareaEl) {
            textareaEl.focus();
          }
          return;
        }
        this.toggleCommentLoading();
        const text = this.commentText.substring(0, 1000); // 1000 is symbol limit

        const data = {
          display: {
            attachments: gif.type === 'giphy' ? [gif] : this.attachments,
            last_updated_on: Math.floor(new Date().getTime() / 1000),
            text: text,
            author: {
              name: this.account._name,
              login: this.accountId,
              avatar: this.account._avatar,
              type: this.account._type,
            },
            reactions: {
              like: { count: 0, reacted: false },
              dislike: { count: 0, reacted: false },
            },
          },
          send: {
            type:
              this.$route.name === 'singleCompetition' ? 'tournament' : 'post',
            id: this.post.id,
            attachments: this.attachments.map(item => item.fileId || item),
            text,
          },
        };

        this.postComment(data.send)
          .then(
            res => {
              data.display.id = res.commentId;

              this.comments = [data.display, ...this.comments];
              this.post.comments_count++;
              this.total++;
              this.resetForm();
            },
            () => {
              /* TODO - TH-7126 add error notification*/
            }
          )
          .then(this.toggleCommentLoading)
          .then(() => {
            document
              .getElementById(data.display.id)
              .scrollIntoView({ block: 'center', behavior: 'smooth' });
          })
          .then(() => {
            if (this.$route.path.includes('/feed')) {
              window.dataLayer.push({
                event: 'post commented',
                hasAttachments: !!this.post.attachments,
                postAuthtor: this.post.author.name,
                postId: this.post.id,
                postType: this.post.poll
                  ? 'poll'
                  : this.post.tournament
                  ? 'tournament'
                  : 'regular',
                userID: this.accountId,
                from: this.$route.path,
              });
            } else {
              window.dataLayer.push({
                event: 'torunament comment',
                userID: this.accountId,
                from: this.$route.path,
                tournamentId: this.currentTournament.id,
                gameName: this.currentTournament.game,
                squadSize: this.currentTournament.squad_size,
                tournamentName: this.currentTournament.title,
                tournamentFormat: this.currentTournamentStages[0]
                  .block_configuration.type,
                tournamentCountry: this.currentTournament.country,
                tournamentPool: this.currentTournament.prize.prize_pool,
                regForm: this.currentTournament.requires_registration_form,
                tournamentPrivate:
                  this.currentTournament.registration_type === 'closed'
                    ? 'true'
                    : 'false',
                tournamentOrganizer: this.currentTournament
                  .event_organizer_name,
              });
            }
          })
          .catch(this.errorNotify);
        this.isFocused = false;
      }
    },

    resetForm() {
      this.commentText = '';
      this.attachments = [];
      this.closeEmojiPicker();
      this.closeGiffPicker();
      // this.getComments();
      document.activeElement.blur();
    },
    multiline(val) {
      return val.replace(/(?:\r\n*)|\n/gm, '<br>');
    },
    addEmoji(emoji) {
      this.commentText += emoji.native;
      this.$refs.commentTextareaEl.focus();
      this.$refs.commentTextareaEl.put(
        document.createTextNode(emoji.native),
        0
      );
    },
    closeEmojiPicker() {
      this.showEmoji = false;
    },
    closeGiffPicker() {
      this.showGiffs = false;
    },
    beforeDestroy() {
      document.removeEventListener('click', this.closeDropdowns);
      document.removeEventListener('click', this.handleClickOutside);
    },
    toggleCommentLoading() {
      this.commentLoading = !this.commentLoading;
    },
    removeComments(comment) {
      this.comments = this.comments.filter(item => item.id !== comment.id);
      this.post.comments_count--;
    },
    updateComments() {
      this.comments = [];
      this.nextPageToken = '';
      // this.getComments();
    },
  },
};
</script>

<style lang="scss">
.text-input-container {
  display: flex;
  flex-direction: column;
  max-width: 100% !important;
  justify-content: space-between;

  .editable {
    top: 3px;
    height: 100% !important;
    word-break: break-word;
  }
  .textarea-container {
    width: 100% !important;
    height: 100%;
  }
  .textarea {
    min-height: 36px !important;
    padding: 5px !important;

    max-width: 100%;
    .comment-input {
      max-width: 100% !important;
    }
  }
  // &:focus-within {
  //   border: 2px solid $new-bg-primary !important;
  // }
}
.focus-border-input {
  border: 2px solid #4b5672 !important;
}
.focus-input-field {
  @include auto-rtl(padding-left, 0.8rem);
}

.sc-post-attach__media {
  padding-left: 15px !important;
  padding-bottom: 10px;
}
</style>

<style scoped lang="scss">
.gif-emoji-rotate {
  position: absolute;
  top: 30px;
  right: -280px;
  @media (max-width: 850px) {
    right: -180px !important;
  }
  .app-rtl & {
    right: auto;
    left: -280px;
  }
}
.goUp {
  position: absolute;
  top: -411px !important;
}
.comment-input {
  position: relative;
  width: 100% !important;
  .app-rtl & {
    padding-right: 8px;
  }
}

.comment-attachments {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1.5rem 0.8rem 1.2rem;
}

.comments-att-active {
  background-color: #181c25 !important;
}

textarea {
  &::placeholder {
    top: 4px;
    left: 8px;
  }
}
.text-input-container {
  display: flex !important;
  background-color: #181c25 !important;
  border-radius: 5px !important;
  flex-grow: 3;
  max-width: 100% !important;

  // .app-rtl & {
  //   // flex-direction: row-reverse;
  //   margin-left: 5%;
  //   margin-right: 0 !important;
  // }
}
.comment-group {
  display: flex;
  justify-content: space-between;
  height: 36px;
  border-radius: $hr-radius-01 !important;
  background-color: $new-bg-elevated;
}
.commentInput::placeholder {
  border-radius: 5px;
  text-align: start;
  font-size: 14px;
  line-height: inherit;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  z-index: 0;
  min-height: 1.25rem;
  line-height: 1.25rem;
  color: var(--ph-secondary-text);
}
@media (max-width: 850px) {
  .gif-container {
    z-index: 200;
    // right: -26px;
    max-width: 98vw;
  }
}
.centerUp {
  z-index: 200;
  right: -50px;
  max-width: 98vw;
}
.commentRTL {
  .app-rtl & {
    // transform: scaleX(-1);
  }
}

.highlight-comments-style {
  @include auto-rtl(padding-left, 34px);
  @include auto-rtl(padding-right, 34px);
}

.highlights-input-style {
  @include auto-rtl(padding-left, 34px !important);
}

.avatar-mobile-comment {
  margin-top: 6px;
}

::v-deep .textarea {
  background-color: $new-bg-page;
}

.active-input-style-posts-route {
  ::v-deep .textarea {
    background-color: #11171f !important;
  }
}

.active-input-style-textarea {
  ::v-deep .textarea {
    background-color: #181c25 !important;
  }
}

.comment-main-post-btn {
  background: none;
  border: none;
  color: #80889d;
}

.post-comments-sum {
  display: none;
}

.post-comments-sum-tour {
  display: block;
  padding: 0 !important;

  @media screen and (max-width: 800px) {
    @include auto-rtl(margin-left, 5%);
  }

  @media screen and (max-width: 767px) {
    padding-top: 15px !important;
  }
}

@media screen and (max-width: 1100px) {
  .post-comments-sum {
    display: block;
  }
}

.tour-comments-style {
  ::v-deep .text-truncate {
    background-color: #11171f !important;
  }
}

.emoji-window-newsfeed {
  z-index: 9999;
}
.show-more-feed {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #c1c7d7;
  cursor: pointer;
}
.border-input-singlepost {
  border: 1px solid #4b5672 !important;
}

.com-sum-mobile-single {
  display: none;
}
</style>
