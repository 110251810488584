import { authorizedPostRequest } from '@/api/requests';

const PLATFORMS = 'platforms';

export const postPlatformLogin = (accessToken, { platformID, login }) =>
  authorizedPostRequest(`${PLATFORMS}/${platformID}`)(accessToken, { login });

export default {
  postPlatformLogin,
};
