var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column p-5 settings-widget",style:({
    background: ("linear-gradient(0deg, rgba(11, 14, 19, 0.95), rgba(11, 14, 19, 0.95)), " + _vm.cover),
  })},[_c('div',{staticClass:"d-flex"},[_c('Subheader',{staticClass:"mb-4 flex-grow-1"},[_vm._v("\n      "+_vm._s(_vm.$$t('Tournament settings'))+"\n    ")]),_c('PromotionWidget')],1),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-6 w-100"},[_c('TournamentCard',_vm._b({},'TournamentCard',{ tournament: _vm.tournament, forOrganizer: false },false)),_c('router-link',{staticClass:"d-flex justify-content-center mt-4",attrs:{"to":{
          name: 'TournamentSettings',
          params: _vm.$route.params,
          hash: '#settings-appearance',
        }}},[_c('div',[_vm._v(_vm._s(_vm.$t('_web_change', 'Change')))]),_c('Icon',{staticClass:"ml-1",attrs:{"name":"arrow-triangle-rounded","width":8}})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('TournamentAutoSettingsWidget')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto mb-3"},[_c('ParticipantsWidget')],1),_c('div',{staticClass:"col-auto"},[_c('WidgetWrapper',{staticClass:"bg-transparent d-flex flex-column",attrs:{"title":_vm.$t('_web_interactions', 'Interactions')}},[_c('PerformanceIndicators',_vm._b({},'PerformanceIndicators',{
            indicators: _vm.indicators,
            numbersOnly: true,
          },false))],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }