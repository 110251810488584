var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.color},[(_vm.isRegStarted && _vm.pendingUsers.length > 2)?_c('div',{staticClass:"wrapper-universal-home regStarted-status-info",class:_vm.color},[_c('div',{staticClass:"slots-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/Union.svg"),"width":"24px","height":"24px","alt":"Slots"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('_web_single_competition_status-info-slots', 'SLOTS')))]),_c('h3',{staticClass:"d-block"},[_vm._v("\n        "+_vm._s(_vm.currentTournament.squads_pending)+"/"+_vm._s(_vm.currentTournament.squads)+"\n      ")])]),_c('div',{staticClass:"gray-line-player-card"}),_c('div',{staticClass:"imgs-avatar-outer-wrapper"},[_c('div',{staticClass:"imgs-avatar-wrapper"},_vm._l((_vm.pendingAvatars),function(avatar,index){return _c('img',{key:index,staticClass:"small-image-avatar",attrs:{"src":avatar,"alt":"user","width":"48px","height":"48px"}})}),0)]),(_vm.numberOfPendingUsers > 4)?_c('div',{staticClass:"players-joined-wrapper"},[_vm._v("\n      "+_vm._s(_vm.lastToJoinPending)+"\n      "),_c('div',{staticClass:"inner-text-player-join"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_single_competition_status-info-and', 'and'))+"\n        "),_c('b',[_vm._v(_vm._s(_vm.numberOfPendingUsers)+"\n          "+_vm._s(_vm.$t('_web_single_competition_status-info-more', 'more')))]),_vm._v("\n        "+_vm._s(_vm.$t('_web_single_competition_status-info-joined', 'recently joined.'))+"\n      ")])]):(_vm.numberOfPendingUsers > 0)?_c('div',{staticClass:"players-joined-wrapper"},[_vm._v("\n      "+_vm._s(_vm.lastToJoinPending)+"\n      "),_c('div',{staticClass:"inner-text-player-join"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_single_competition_status-info-joined', 'recently joined.'))+"\n      ")])]):_vm._e()]):_vm._e(),(
      (_vm.isRegClosed && _vm.users.length > 3) ||
        (_vm.users.length > 2 && _vm.matches.length < 3)
    )?_c('div',{staticClass:"wrapper-universal-home regStarted-status-info",class:_vm.color},[_c('div',{staticClass:"slots-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/Union.svg"),"width":"24px","height":"24px","alt":"Slots"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('_web_single_competition_status-info-slots', 'SLOTS')))]),_c('h3',{staticClass:"d-block"},[_vm._v("\n        "+_vm._s(_vm.currentTournament.squads_joined)+"/"+_vm._s(_vm.currentTournament.squads)+"\n      ")])]),_c('div',{staticClass:"gray-line-player-card"}),_c('div',{staticClass:"main-avatar-checked-in-wrap"},[_c('div',{staticClass:"imgs-avatar-outer-wrapper"},[_c('div',{staticClass:"imgs-avatar-wrapper"},_vm._l((_vm.avatars),function(avatar,index){return _c('img',{key:index,staticClass:"small-image-avatar",attrs:{"src":avatar,"alt":"user","width":"24px","height":"24px"}})}),0)]),(_vm.numberOfUsers > 4)?_c('div',{staticClass:"players-joined-wrapper"},[_vm._v("\n        "+_vm._s(_vm.lastToJoinUsers)+"\n        "),_c('div',{staticClass:"inner-text-player-join"},[_vm._v("\n          "+_vm._s(_vm.$t('_web_single_competition_status-info-and', 'and'))+"\n          "),_c('b',[_vm._v(_vm._s(_vm.numberOfUsers)+"\n            "+_vm._s(_vm.$t('_web_single_competition_status-info-more', 'more')))]),_vm._v("\n          "+_vm._s(_vm.$t(
              '_web_single_competition_status-info-checked-in',
              'recently checked in.'
            ))+"\n        ")])]):(_vm.numberOfUsers > 0)?_c('div',{staticClass:"players-joined-wrapper"},[_vm._v("\n        "+_vm._s(_vm.lastToJoinUsers)+"\n        "),_c('div',{staticClass:"inner-text-player-join"},[_vm._v("\n          "+_vm._s(_vm.$t(
              '_web_single_competition_status-info-checked-in',
              'recently checked in.'
            ))+"\n        ")])]):_vm._e()]),(_vm.matches.length)?_c('div',{staticClass:"bracket-button-wrap-home"},[_c('router-link',{staticClass:"brackets-button",attrs:{"to":_vm.bracketsLink},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_vm._v("\n        "+_vm._s(_vm.$t('_web_single_competition_status-info-brackets', 'brackets'))+"\n        "),_c('img',{attrs:{"src":require("@/assets/images/icons/arror-right-new-cro.svg"),"alt":"brackets","width":"14px","height":"12px"}})])],1):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.matches[2] && _vm.dataReady)?_c('div',{staticClass:"wrapper-universal-home ",class:_vm.color},[_c('div',{staticClass:"wrapper-inner"},[_c('button',{staticClass:"button-left",class:{ 'btn-disabled': _vm.cardIndex === 0 },on:{"click":_vm.moveLeft}},[_c('img',{staticClass:"arrow-left",attrs:{"src":require("@/assets/images/homepage/arrowNew.svg"),"alt":"arrow left"}})]),_c('div',{staticClass:"all-card-wrap"},[_c('div',{staticClass:"card-results-wrapper",class:_vm.cardIndex === 0 ? 'match-active' : 'match-hidden'},[_c('p',{staticClass:"round-title"},[_vm._v(_vm._s(_vm.roundsToText(_vm.matches[2])))]),_c('div',{staticClass:"players-wrap"},[_c('div',{staticClass:"player-one"},[_c('img',{staticClass:"player-avatar",attrs:{"src":_vm.matches[2].player1avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[2].player1avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[2].player1avatar,"alt":"player","width":"16px","height":"16px"}}),_vm._v("\n                "+_vm._s(_vm.matches[2].player1name)+"\n                "),_c('p',[_vm._v(_vm._s(_vm.matches[2].player1score))])]),_c('div',{staticClass:"player-two"},[_c('img',{staticClass:"player-avatar",attrs:{"src":_vm.matches[2].player2avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[2].player2avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[2].player2avatar,"alt":"player","width":"16px","height":"16px"}}),_vm._v("\n                "+_vm._s(_vm.matches[2].player2name)+"\n                "),_c('p',[_vm._v(_vm._s(_vm.matches[2].player2score))])])])]),_c('div',{staticClass:"gray-line-player-card"}),_c('div',{staticClass:"card-results-wrapper",class:_vm.cardIndex === 1 ? 'match-active' : 'match-hidden'},[_c('p',{staticClass:"round-title"},[_vm._v(_vm._s(_vm.roundsToText(_vm.matches[1])))]),_c('div',{staticClass:"players-wrap"},[_c('div',{staticClass:"player-one"},[_c('img',{staticClass:"player-avatar",attrs:{"src":_vm.matches[1].player1avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[1].player1avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[1].player1avatar,"alt":"player","width":"16px","height":"16px"}}),_vm._v("\n                "+_vm._s(_vm.matches[1].player1name)+"\n                "),_c('p',[_vm._v(_vm._s(_vm.matches[1].player1score))])]),_c('div',{staticClass:"player-two"},[_c('img',{staticClass:"player-avatar",attrs:{"src":_vm.matches[1].player2avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[1].player2avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[1].player2avatar,"alt":"player","width":"16px","height":"16px"}}),_vm._v("\n                "+_vm._s(_vm.matches[1].player2name)+"\n                "),_c('p',[_vm._v(_vm._s(_vm.matches[1].player2score))])])])]),_c('div',{staticClass:"gray-line-player-card"}),_c('div',{staticClass:"card-results-wrapper",class:_vm.cardIndex === 2 ? 'match-active' : 'match-hidden'},[_c('p',{staticClass:"round-title"},[_vm._v(_vm._s(_vm.roundsToText(_vm.matches[0])))]),_c('div',{staticClass:"players-wrap"},[_c('div',{staticClass:"player-one"},[_c('img',{staticClass:"player-avatar",attrs:{"src":_vm.matches[0].player1avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[0].player1avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[0].player1avatar,"alt":"player","width":"16px","height":"16px"}}),_vm._v("\n                "+_vm._s(_vm.matches[0].player1name)+"\n                "),_c('p',[_vm._v(_vm._s(_vm.matches[0].player1score))])]),_c('div',{staticClass:"player-two"},[_c('img',{staticClass:"player-avatar",attrs:{"src":_vm.matches[0].player2avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[0].player2avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : _vm.matches[0].player2avatar,"alt":"player","width":"16px","height":"16px"}}),_vm._v("\n                "+_vm._s(_vm.matches[2].player2name)+"\n                "),_c('p',[_vm._v(_vm._s(_vm.matches[2].player2score))])])])]),_c('div',{staticClass:"bracket-button-wrap-home bracket-desktop-btn"},[_c('router-link',{staticClass:"messages-button",attrs:{"to":_vm.bracketsLink},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('img',{staticClass:"matchesImg",attrs:{"src":require("@/assets/images/matchesIcon.png"),"alt":"brackets","width":"24px","height":"24px"}}),_vm._v("\n              "+_vm._s(_vm.$t('_web_single_competition_status-info-matches', 'Matches'))+"\n            ")])],1)]),_c('button',{staticClass:"button-right",class:{ 'btn-disabled': _vm.cardIndex === 2 },on:{"click":_vm.moveRight}},[_c('img',{staticClass:"arrow-right",attrs:{"src":require("@/assets/images/homepage/arrowNew.svg"),"alt":"arrow right"}})])]),_c('div',{staticClass:"bracket-button-wrap-home bracket-mobile-btn"},[_c('router-link',{staticClass:"messages-button",attrs:{"to":_vm.bracketsLink},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('img',{staticClass:"matchesImg",attrs:{"src":require("@/assets/images/matchesIcon.png"),"alt":"brackets","width":"24px","height":"24px"}}),_vm._v("\n          "+_vm._s(_vm.$t('_web_single_competition_status-info-matches', 'Matches'))+"\n        ")])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }