import api from '@/api';

const INITIAL_COMMENTS = () => {
  return {
    undeliveredComments: [],
  };
};

const getters = {
  allUndeliveredComments: state => state.undeliveredComments,
};

const mutations = {
  fetchUndeliveredComments(state) {
    state.undeliveredComments =
      JSON.parse(localStorage.getItem('undeliveredComments')) || [];
  },
  addUndeliveredComment(state, comment) {
    const time = Date.now();
    state.undeliveredComments.unshift({
      commentId: `${comment.id}-${time}`,
      last_updated_on: time / 1e3,
      ...comment,
    });
    localStorage.setItem(
      'undeliveredComments',
      JSON.stringify(state.undeliveredComments)
    );
  },
  removeUndeliveredComment(state, id) {
    const index = state.undeliveredComments.findIndex(
      comment => comment.commentId === id
    );
    state.undeliveredComments.splice(index, 1);
    localStorage.setItem(
      'undeliveredComments',
      JSON.stringify(state.undeliveredComments)
    );
  },
};

const actions = {
  deleteComment(
    {
      getters: { accessToken },
    },
    id
  ) {
    return new Promise((resolve, reject) => {
      api.comments
        .deleteComment(accessToken, id)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  },
  fetchComments(
    {
      getters: { accessToken },
    },
    params
  ) {
    return api.comments.getComments(accessToken, params).then(res => res.data);
  },
  postComment(
    {
      getters: { accessToken },
      commit,
    },
    { id, threadId, channelId, text, attachments, type }
  ) {
    const comment = {
      text,
      attachments,
      type,
      threadId,
      id: type === 'channel_message' ? `${channelId},${id}` : id,
    };
    return api.comments
      .postComment(accessToken, comment)
      .then(res => res.data)
      .catch(err => {
        commit('addUndeliveredComment', comment);
        throw err;
      });
  },
  likeComment({ getters }, comment) {
    let params = comment.reacted
      ? [getters.accessToken, { type: 'none' }]
      : [getters.accessToken, { type: comment.type }];

    return new Promise((resolve, reject) => {
      api.comments
        .likeComment(comment.id)(...params, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
};

export default {
  state: INITIAL_COMMENTS(),
  getters,
  mutations,
  actions,
};
