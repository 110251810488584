import api from '@/api';

const actions = {
  getTagsList(
    {
      getters: { accessToken },
    },
    { query, pageToken }
  ) {
    const prefix = query ? query.trim() : null;

    return api.tags
      .getTags(accessToken, { params: { prefix, page: pageToken } })
      .then(response => response.data);
  },
  getMentionsList(
    {
      getters: { accessToken },
    },
    { query, pageToken }
  ) {
    const prefix = query ? query.trim() : null;

    return api.tags
      .getMentions(accessToken, { params: { prefix, page: pageToken } })
      .then(response => response.data);
  },
};

export default {
  actions,
};
