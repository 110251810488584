import { render, staticRenderFns } from "./AttributeTag.vue?vue&type=template&id=226d239a&functional=true&"
import script from "./AttributeTag.vue?vue&type=script&lang=js&"
export * from "./AttributeTag.vue?vue&type=script&lang=js&"
import style0 from "./AttributeTag.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AttributeTag.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms&name=AttributeTag&knobs=%7B%0A%20%20%20%20active%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('active'%2C%20true)%0A%20%20%20%20%7D%2C%0A%20%20%20%20name%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('name'%2C%20'Seed')%0A%20%20%20%20%7D%2C%0A%20%20%20%20color%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('color'%2C%20'')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports