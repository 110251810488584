<template>
  <div>
    <component
      :is="activeComponent[accountType]"
      class="h-text-right d-flex justify-content-end align-items-center"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as ACCOUNT from '@/constants/accounts';

const Profile = () => import('./Profile');
const Clan = () => import('./Clan');
const Organization = () => import('./Organization');
const Tournament = () => import('./Tournament');
//const Battle = () => import('./Battle');

export default {
  name: 'AccountStatusBarActionsEdit',
  computed: {
    ...mapState('current', ['accountType']),

    activeComponent() {
      return {
        [ACCOUNT.PROFILE]: Profile,
        [ACCOUNT.ORGANIZATION]: Organization,
        [ACCOUNT.CLAN]: Clan,
        [ACCOUNT.TOURNAMENT]: Tournament,
        //[ACCOUNT.BATTLE]: Battle,
      };
    },
  },
};
</script>
