import Search from '@/views/search/';
import { SEARCH } from '@/constants/router';

export default [
  {
    path: '/search',
    name: SEARCH,
    component: Search,
    meta: {
      guestEnabled: true,
      inMainNav: false,
      inMainNavOrg: false,
      titleKey: '_web_tournament_sidechat_search',
      fallbackLabel: 'Search',
      icon: 'search',
    },
  },
];
