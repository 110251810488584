<template>
  <WrapperFormControl
    v-bind="{
      id: inputID,
      viewMode: disabled,
      disabled,
      required,
      errorInTooltip,
      error,
      label,
      tooltip,
      readOnly,
    }"
    class="w-100"
  >
    <div slot="control" class="segment-control d-table" :class="{ disabled }">
      <label
        v-for="option in options"
        :id="inputID"
        :key="option.id || option.value"
        :class="[
          `is-1-of-${options.length}`,
          { active: $lodash.isEqual(value, option.value) },
        ]"
        class="segment-control-btn d-table-cell align-middle"
        @click="switchVal(option.value)"
      >
        <strong class="d-block align-middle text-center text-truncate">{{
          option.label
        }}</strong>
      </label>
    </div>
  </WrapperFormControl>
</template>

<script>
import { prop } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';

export default {
  name: 'SegmentControl',
  components: {
    WrapperFormControl,
  },
  props: {
    value: prop(null),
    label: prop(String),
    name: prop(String),
    error: prop(String),
    errorInTooltip: prop(Boolean),
    readOnly: prop(Boolean),
    disabled: prop(Boolean),
    required: prop(Boolean),
    tooltip: prop(String),
    options: prop(Array),
  },
  computed: {
    inputID() {
      return `input_field_${this.id || this.$lodash.uniqueId('id_')}`;
    },
  },
  methods: {
    switchVal(value) {
      if (this.readOnly) {
        return;
      }
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="scss">
.segment-control {
  max-width: 100%;
  table-layout: fixed;
  & &-btn {
    padding: $ph-small-space;
    background-color: #1c212b;
    height: 2.5rem;
    cursor: pointer;
    @for $i from 1 through 8 {
      &.is-1-of-#{$i} {
        width: math.floor(math.div(100%, $i));
        min-width: 60px;
      }
    }
    &:first-child {
      @include auto-rtl(border-radius, $ph-medium-radius 0 0 $ph-medium-radius);
    }
    &:last-child {
      @include auto-rtl(border-radius, 0 $ph-medium-radius $ph-medium-radius 0);
    }
    &.active {
      background-color: $ph-accent;
    }
    > strong {
      max-width: 200px; //random big size
      height: 1.5rem;
      line-height: 1.5rem;
    }
  }
}
</style>
