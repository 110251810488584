<template>
  <HeraFormBtn
    v-if="!newIcon"
    v-bind="{
      ...(!hideLabel ? { label: $t('_web_tournament_back', 'Back') } : {}),
      ...(!hideIcon
        ? {
            icon: isRTL ? 'next' : 'back',
            iconSize: iconSize,
          }
        : {}),
    }"
    @click="goBack"
  />
  <img
    v-else
    src="@/assets/images/back-btn-new.svg"
    alt="back-icon"
    class="new-back-btn-icon"
    @click="goBack"
  />
</template>

<script>
import { cloneDeep } from '@/utils/lodashUtils';
import { mapGetters } from 'vuex';
import HeraFormBtn from '@/components/atoms/HeraFormBtn.vue';
export default {
  components: { HeraFormBtn },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    historyMode: {
      type: Boolean,
      default: false,
    },
    backRouteName: {
      type: String,
      default: '',
    },
    directPath: {
      type: Object,
      default: null,
    },
    newIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isRTL']),
  },
  methods: {
    goBack() {
      if (this.historyMode) {
        this.$router.go(-1);
      } else if (this.directPath) {
        this.$router.push(this.directPath);
      } else {
        const parentRoute = this.findParentRoute();
        this.$router.push({
          name: parentRoute.resolved.name,
          params: {
            ...cloneDeep(parentRoute.location.params),
            ...cloneDeep(parentRoute.resolved.params),
          },
          meta: cloneDeep(parentRoute.resolved.meta),
        });
      }
    },
    findParentRoute() {
      let findedRoute = null;
      for (let i = this.$route.matched.length - 2; i > 0; i--) {
        let path = this.$route.matched[i].path;
        // TODO - TH-7084 May work not well with GET params in URI. Like a .../players?sort=Username. Investigation and testing required.
        Object.keys(this.$route.params).forEach(param => {
          path = path.replace(
            RegExp(`:${param}\\??`, 'i'),
            this.$route.params[param]
          );
        });
        const resolved = this.$router.resolve(path);
        if (resolved.route.name !== this.$route.name) {
          if (this.backRouteName) {
            if (resolved.route.name === this.backRouteName) {
              findedRoute = resolved;
              break;
            }
          } else {
            findedRoute = resolved;
            break;
          }
        }
      }
      if (!findedRoute) {
        return this.$router.resolve('/');
      } else {
        return findedRoute;
      }
    },
  },
};
</script>

<style>
.auth-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.auth-close-btn {
  background: none;
  border: none;
}

.new-back-btn-icon {
  cursor: pointer;
  width: 28px;
  height: 28px;
}
</style>
