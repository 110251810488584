<template>
  <WrapperFormControl
    :id="inputID"
    :required="required"
    :error-in-tooltip="errorInTooltip"
    :view-mode="viewMode"
    :error="error"
    :label="label"
    :tooltip="tooltip"
  >
    <div
      slot="control"
      class="position-relative"
      :class="{ 'd-flex align-items-center flex-nowrap': $slots.prepend }"
    >
      <!-- Input polje - pozazati ga kad se klikne na ikonu -->
      <input
        :id="inputID"
        :type="type"
        :disabled="disabled"
        class="input-form"
        :class="{
          'is-invalid': error,
          'icon-input': icon,
          'view-mode': viewMode,
        }"
        :value="value"
        :readonly="readOnly || viewMode"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        v-bind="$attrs"
        :style="inputStyle"
        v-on="{
          ...$listeners,
          input: handleInput,
          change: handleChange,
        }"
      />

      <span v-if="fixedPlaceholder" class="fixed-placeholder font-size-sm">{{
        fixedPlaceholder
      }}</span>
      <slot name="prepend"></slot>
      <!-- <span v-if="icon" class="icon-wrapper">
        <slot name="icon">
          <Icon
            :name="icon"
            :size="iconSize"
            original
            :disabled="isIconDisabled"
          />
        </slot>
      </span>-->
    </div>
  </WrapperFormControl>
</template>

<script>
import { prop } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HeraInput',

  components: {
    WrapperFormControl,
    //Icon,
  },

  props: {
    viewMode: prop(Boolean),
    error: prop(String),
    errorInTooltip: prop(Boolean),
    label: prop(String),
    id: prop(String),
    placeholder: prop(String),
    value: prop([String, Number]),
    type: prop(String, 'text'),
    icon: prop(String, null),
    iconSize: prop([Number, String], 18),
    readOnly: prop(Boolean),
    disabled: prop(Boolean),
    required: prop(Boolean),
    min: prop(Number, NaN),
    max: prop(Number, NaN),
    maxlength: prop(Number, 524288), // default value in html standard
    tooltip: prop(String),
    fixedPlaceholder: prop(String, ''),
    inputLeftPadding: prop(String),
  },
  data() {
    return {
      isIconDisabled: !this.value,
    };
  },

  computed: {
    ...mapGetters(['isRTL']),
    inputID() {
      return `InputField_${this.id || this.$lodash.uniqueId('id_')}`;
    },
    inputStyle() {
      if (this.inputLeftPadding) {
        return this.isRTL
          ? { 'padding-right': this.inputLeftPadding }
          : { 'padding-left': this.inputLeftPadding };
      }
      return null;
    },
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
      this.isIconDisabled = !e.target.value.length;
    },
    handleChange(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  border-color: $hr-clr-header;
  background-color: $hr-bg-search;
  width: 100%;
  height: $input-height;
  padding-left: 10px !important;
  width: 255px !important;
  &.icon-input {
    @include auto-rtl(padding-left, $ph-huge-space);
  }
  &.view-mode {
    pointer-events: none;
    border-color: $ph-secondary-text;
  }
}
.icon-wrapper {
  width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  @include auto-rtl(left, $ph-small-space);
}

.fixed-placeholder {
  position: absolute;
  top: 9.5px;
  color: var(--ph-secondary-text);
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/Form"
  name="HeraFormTextInput"
  knobs="{
    viewMode: {
      default: boolean('viewMode', false)
    },
    error: {
      default: text('error', '')
    },
    errorInTooltip: {
      default: boolean('errorInTooltip', false)
    },
    label: {
      default: text('label', 'label')
    },
    id: {
      default: text('id', 'id')
    },
    placeholder: {
      default: text('placeholder', 'placeholder')
    },
    value: {
      default: text('value', 'value')
    },
    type: {
      default: text('type', 'text')
    },
    icon: {
      default: text('icon', '')
    },
    readOnly: {
      default: boolean('readOnly', false)
    },
    disabled: {
      default: boolean('disabled', false)
    },
    required: {
      default: boolean('required', false)
    },
    min: {
      default: number('min', 10)
    },
    max: {
      default: number('max', 50)
    },
    maxlength: {
      default: number('maxlength', 524288)
    },
    tooltip: {
      default: text('tooltip', '')
    },
  }"
>
  <HeraFormTextInput v-bind="{viewMode, required, error, errorInTooltip, id, placeholder, value, type, label, readOnly, disabled, min, max, maxlength, tooltip}"
    @input="action('input')"
    @change="action('change')"
    @focus="action('focus')"
    @blur="action('blur')"
  />
</story>
