<template>
  <div class="input-group input-search" :class="{ focused: isFocused }">
    <div class="input-group-prepend">
      <div
        class="input-group-text bg-transparent border-0 text-light"
        :class="classIcon"
      >
        <AppIcon name="search" />
      </div>
    </div>
    <input
      ref="searchInput"
      v-model="value"
      type="search"
      class="form-control bg-transparent border-0 text-light"
      :placeholder="placeholderReplaced"
      :class="classInput"
      @blur="toggleFocus(false)"
      @focus="toggleFocus(true)"
    />
  </div>
</template>

<script>
import AppIcon from '@/components/atoms/Icon';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

export default {
  name: 'HeraFormSearch',
  components: {
    AppIcon,
  },
  model: {
    prop: 'inputValue',
    event: 'input',
  },
  props: {
    inputValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    debounce: {
      type: Number,
      default: 500,
    },
    classIcon: {
      type: String,
      default: '',
    },
    classInput: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFocused: false,
      observer: null,
      value: this.inputValue,
    };
  },
  computed: {
    placeholderReplaced() {
      return (
        this.placeholder || this.$t('_web_form-search_placeholder', 'Search...')
      );
    },
  },
  watch: {
    inputValue(val) {
      this.value = val;
    },
  },
  mounted() {
    const $input = this.$refs.searchInput;

    if (!this.observer) {
      this.observer = fromEvent($input, 'input')
        .pipe(
          debounceTime(this.debounce),
          map(() => $input.value)
        )
        .subscribe(value => {
          this.$emit('input', value);
        });
    }
  },
  methods: {
    /**
     * external focus trigger
     */
    focusIn() {
      this.$refs.searchInput.focus();
    },
    toggleFocus(isFocused) {
      this.isFocused = isFocused;
    },
  },
};
</script>

<style lang="scss">
.input-search {
  border-bottom: 1px solid $ph-secondary-text;
  &.focused {
    border-bottom-color: $ph-accent;
  }
  .form-control {
    box-shadow: none !important;
    &:focus {
      border-color: #000;
    }
  }
}
</style>
