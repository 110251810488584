<template>
  <div :class="{ 'd-flex': isSwiss }">
    <HeraFormDropdown
      v-if="isRoundRobin"
      key="rounds-field"
      v-model.number="rounds"
      v-validate="'required'"
      :view-mode="disabled"
      :disabled="isStageFieldDisabled('rounds') || disabled"
      name="rounds"
      :label="$$t('Rounds:', 'rounds-dropdown-label')"
      :list="stageDefaults.rounds || []"
      :class="{ 'is-invalid': errors.first('rounds') }"
      variant="input-like"
      required
      :error="errors.first('rounds')"
      :empty-text="$$t('Choose option', 'rounds-dropdown-placeholder')"
      :tooltip="
        $$t(
          'How many times participants play with each other',
          'rounds-dropdown-tooltip'
        )
      "
      show-label
      empty-option
    >
      <template slot="chosen" slot-scope="chosen">
        {{ chosen.chosenItem }}
      </template>

      <template slot="item" slot-scope="{ item }">
        {{ item }}
      </template>
    </HeraFormDropdown>
    <HeraFormInput
      v-else
      key="rounds-field-swiss"
      v-model.number="rounds"
      v-validate="'required|numeric|decimal:0|min_value:1'"
      :label="$$t('Rounds:', 'rounds_label')"
      type="number"
      required
      :disabled="isStageFieldDisabled('rounds') || disabled"
      :class="{ 'is-invalid': errors.first('rounds') }"
      :view-mode="disabled"
      name="rounds"
      :error="errors.first('rounds')"
      :tooltip="
        $$t(
          'A number of rounds in a current stage, equal for each group. In other words, how many matches will be played by each participant',
          'rounds-tooltip'
        )
      "
      :placeholder="$$t('Number of rounds', 'rounds-placeholder')"
    />
    <ToolIcon
      v-if="isSwiss"
      :help-text="$$t('Calculate rounds', 'swiss-calculator-tooltip')"
      :disabled="isStageFieldDisabled('rounds') || disabled"
      icon-name="calculator"
      class="h-ml-3 mt-5 pt-2"
      @click.stop="openCalculatorModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { translate } from '@/mixins/translate';
import HeraFormInput from '@/components/atoms/form/HeraFormTextInput';
import HeraFormDropdown from '@/components/molecules/form/HeraFormDropdown';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageRoundsSetting',
  components: {
    HeraFormInput,
    HeraFormDropdown,
    ToolIcon,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    rounds: {
      get() {
        return this.getBlockConfigurationByKey(
          'rounds',
          this.isBattleRoyale
            ? 3
            : this.isRoundRobin
            ? 1
            : this.isSwiss
            ? 5
            : null
        );
      },
      set(newVal) {
        this.setBlockConfigurationByKey('rounds', +newVal);
      },
    },
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),
    openCalculatorModal() {
      this.openModal({
        name: 'TournamentSwissCalculatorModal',
      });
    },
  },
};
</script>

<style></style>
