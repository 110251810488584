<template>
  <Panel
    :variant="[
      'transparent',
      'stroked',
      'stroked-popover-bg',
      'stroked-dashed',
      'rounded-large',
    ]"
    class="add-stage-cover d-flex flex-grow-1 justify-content-center align-items-center cursor-pointer"
    @click.native="createStage"
  >
    <Icon
      class="d-block text-center h-mr-3"
      name="add"
      width="22"
      fill="white"
    />
    <TextBlock :size="0.875">
      <strong>{{
        $t('_web_tournament_stage_add-stage-btn', 'Add Stage')
      }}</strong>
    </TextBlock>
  </Panel>
</template>

<script>
import { mapMutations } from 'vuex';
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import Panel from '@/components/atoms/Panel';

export default {
  name: 'AddStageCover',

  components: { TextBlock, Icon, Panel },

  props: {},
  computed: {
    isActive() {
      return this.$route.name === 'createStage';
    },
  },
  methods: {
    ...mapMutations(['clearCurrentTournamentStage']),
    createStage() {
      this.clearCurrentTournamentStage();
      this.$router.replace({
        name: 'addStage',
        params: {
          tournamentID: this.$route.params.tournamentID,
        },
      });
    },
  },
};
</script>
