<template>
  <CongratulationPopup
    v-bind="{ redirectTo, subTitle, isVisible }"
    @leave="clearEditData"
  >
    <Avatar
      v-if="account._avatar"
      :type="account._type"
      :img-url="account._avatar"
      :size="61"
    />
  </CongratulationPopup>
</template>

<script>
import { mapMutations } from 'vuex';
import CongratulationPopup from '@/components/popup/CongratulationPopup';
import Avatar from '@/components/images/Avatar';
import { propRequired, prop } from '@/utils/factories';

export default {
  name: 'CreatedPopup',
  components: { Avatar, CongratulationPopup },
  props: {
    account: propRequired(Object),
    isVisible: prop(Boolean),
    redirectTo: prop(Object),
    subTitle: prop(String),
  },
  methods: {
    ...mapMutations(['clearEditData']),
  },
};
</script>
