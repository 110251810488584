<template>
  <div class="tournament-card_actions">
    <span v-if="isOrganizer">
      <Icon
        role="button"
        :fill="$scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        name="dots"
        class=" cursor-pointer"
        :class="{ disabled: isMenuDisabled }"
        @click.stop="openPopoverMenu($event)"
      />
    </span>
    <PopoverMenu
      :closest="$el"
      :position="optionsPosition"
      :items="popoverItems"
      :float="['top', 'right']"
      :auto-close="true"
      @toggle="onMenuToggle"
    >
      <template v-slot="{ item }">
        <TextBlock
          :variant="item.disabled ? 'muted' : 'primary'"
          :text="item.label"
          :class="{ disabled: item.disabled }"
          class="py-2 my-1"
          @click.native="menuHandler(item)"
        />
      </template>
    </PopoverMenu>
  </div>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import PopoverMenu from '@/components/popover/Menu';
import Icon from '@/components/atoms/Icon';
import {
  ARCHIVABLE_STATUSES,
  REJECTABLE_STATUSES,
  TOURNAMENT_STATUSES,
} from '@/constants/tournaments';
import { mapActions } from 'vuex';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'TournamentCardActions',
  components: {
    TextBlock,
    PopoverMenu,
    Icon,
  },
  mixins: [tournamentHelpers],
  props: {
    tournament: propRequired(Object),
    isOrganizer: prop(Boolean),
  },
  data() {
    return {
      optionsPosition: null,
      popoverItems: null,
    };
  },
  computed: {
    isMenuDisabled({ tournament: { status } }) {
      return status === TOURNAMENT_STATUSES.ARCHIVED;
    },
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),

    onMenuToggle(visible) {
      if (!visible) {
        this.popoverItems = null;
        this.optionsPosition = null;
      }
    },
    menuHandler(item) {
      if (item.disabled) {
        return;
      }
      item.handler();
    },
    openPopoverMenu(ev) {
      if (this.isMenuDisabled) {
        return;
      }

      const { status: currentStatus, id: tournamentId } = this.tournament;

      const generateHandler = (callback, status, confirmText) => () =>
        this.openModal({
          name: 'HeraConfirm',
          props: {
            text: confirmText,
          },
          events: {
            cancel: this.closeModal,
            confirm: () =>
              callback(tournamentId)
                .then(() => this.$emit('update', { status }))
                .finally(this.closeModal),
          },
        });
      this.optionsPosition = ev.target;

      this.popoverItems = [
        {
          label: this.$t('_web_tournaments_menu-reject', 'Unpublish'),
          handler: generateHandler(
            this.onTournamentReject,
            TOURNAMENT_STATUSES.REJECTED,
            this.$t(
              '_web_tournaments_menu-reject_confirm',
              'Do you really want to cancel a tournament?'
            )
          ),
          disabled: !REJECTABLE_STATUSES.includes(currentStatus),
        },
        {
          label: this.$t('_web_tournaments_menu-archive', 'Archive'),
          handler: generateHandler(
            this.onTournamentArchive,
            TOURNAMENT_STATUSES.ARCHIVED,
            this.$t(
              '_web_tournaments_menu-archive_confirm',
              'Do you really want to archive a tournament?'
            )
          ),
          disabled: !ARCHIVABLE_STATUSES.includes(currentStatus),
        },
        {
          label: this.$t('_web_tournaments_menu-restart', 'Restart'),
          handler: generateHandler(
            this.onTournamentRestart,
            TOURNAMENT_STATUSES.DRAFT,
            this.$t(
              '_web_tournaments_menu-restart_confirm',
              'Do you really want to restart a tournament?'
            )
          ),
          disabled: TOURNAMENT_STATUSES.REJECTED !== currentStatus,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '../variables';

.tournament-card_actions {
  position: absolute;
  z-index: 2;
  top: $card_padding * 2;
  @include auto-rtl('right', $card_padding * 1.5);
}
</style>
