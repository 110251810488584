<template>
  <div v-if="product" class="single-product-wrapper">
    <div v-if="canceledPayment" class="toast-error">
      {{ canceledPayment }}
      <button @click="() => (canceledPayment = '')">
        <img src="@/assets/images/error-button-x.svg" alt="close error" />
      </button>
    </div>
    <div
      v-if="paymentStatus === 0 && !toggleButton && this.$route.query.callback"
      class="toast-sucess"
    >
      {{ sucessPayment }}
      <button @click="() => (toggleButton = true)">
        <img src="@/assets/images/error-button-x.svg" alt="close error" />
      </button>
    </div>
    <div
      v-if="paymentStatus === 1 && !toggleButton && this.$route.query.callback"
      class="toast-error"
    >
      {{ $t('_web_payment_failed', 'Payment failed') }}
      <button @click="() => (toggleButton = true)">
        <img src="@/assets/images/error-button-x.svg" alt="close error" />
      </button>
    </div>
    <div
      v-if="paymentStatus === 2 && !toggleButton && this.$route.query.callback"
      class="toast-processing"
    >
      {{
        $t(
          '_web_payment_processing',
          'Payment is being processed, please check your email'
        )
      }}
      <button @click="() => (toggleButton = true)">
        <img src="@/assets/images/error-button-x.svg" alt="close error" />
      </button>
    </div>

    <div class="single-product-inner">
      <!-- <aside class="aside-shop-wrapper"> -->
      <!-- <div class="aside-search-filter"> -->
      <!-- <ShopSearch /> -->
      <!-- <img
          class="filter-icon"
          src="@/assets/images/icons/filter-list.svg"
          alt="Filter icon"
          @click="isToggleActive = !isToggleActive"
        />
      </div> -->
      <!-- <ShopCategories :collapsed="isToggleActive" :items="categories" /> -->
      <!-- </aside> -->
      <!-- <div class="single-prod-breadcrumbs">
        <span @click="goToCat(null)">All categories</span>
        <img
          src="@/assets/images/breadcrumb-icon.svg"
          alt="breadcrumb-icon"
          class="breadcrumb-icon"
        />
        <span @click="goToCat(product.cardCategory)">
          {{ product.cardCategory.name }}
        </span>
        <img
          src="@/assets/images/breadcrumb-icon.svg"
          alt="breadcrumb-icon"
          class="breadcrumb-icon"
        />
        <span>{{ product.name }}</span>
      </div> -->
      <div class="single-prod-content">
        <div class="d-flex align-items-center">
          <router-link to="/shop/categories">
            <img
              src="@/assets/images/back-btn-new.svg"
              alt="back-icon"
              class="new-back-btn-shop"
            />
          </router-link>
          <!-- <BackBtn
            v-else
            :new-icon="true"
            :history-mode="true"
            class="new-back-btn-shop"
          /> -->
          <h1 class="product-title">
            {{ singleProduct ? singleProduct.name : product.name }}
          </h1>
          <!-- <h1 class="category-title-mobile">{{ product.cardCategory.name }}</h1> -->
          <h1 class="category-title-mobile">
            {{ $t('_web_shop_all_categories', 'Categories') }}
          </h1>
        </div>
        <div class="single-prod-content-inner">
          <div class="single-product">
            <div class="single-product-image">
              <img
                class="cover-image-single"
                :src="
                  singleProduct ? singleProduct.image_url : product.image_url
                "
                alt="Cover Image"
              />
            </div>
            <div style="margin-top: 4rem" class="product-info">
              <div
                v-if="singleProduct ? singleProduct.details : product.details"
              >
                <h1>
                  {{
                    $t('_web_shop_single_description', 'Product description')
                  }}
                </h1>
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="product-description"
                  v-html="
                    processText(
                      singleProduct ? singleProduct.details : product.details
                    )
                  "
                ></p>
                <!-- eslint-enable vue/no-v-html -->
              </div>
              <div
                v-if="
                  singleProduct
                    ? singleProduct.instructions
                    : product.instructions
                "
              >
                <h1>{{ $t('_web_shop_single_usage', 'How to use') }}</h1>
                <!-- eslint-disable vue/no-v-html -->

                <p
                  class="product-description"
                  v-html="
                    processText(
                      singleProduct
                        ? singleProduct.instructions
                        : product.instructions
                    )
                  "
                ></p>
                <!-- eslint-disable vue/no-v-html -->
              </div>
              <div v-if="singleProduct ? singleProduct.tos : product.tos">
                <h1>
                  {{ $t('_web_shop_single_terms', 'Terms and conditions') }}
                </h1>
                <!-- eslint-disable vue/no-v-html -->

                <p
                  class="product-description"
                  v-html="
                    processText(singleProduct ? singleProduct.tos : product.tos)
                  "
                ></p>
                <!-- eslint-disable vue/no-v-html -->
              </div>
            </div>

            <h1 class="product-title-mobile">
              {{ singleProduct ? singleProduct.name : product.name }}
            </h1>

            <div class="prices-info-mobile">
              <div class="prices">
                <span
                  v-if="
                    singleProduct &&
                      singleProduct.cost &&
                      singleProduct.cost.discount_amount
                  "
                  class="discount mb-3"
                >
                  <span>{{
                    `${singleProduct.cost.discount_amount} %`
                  }}</span></span
                >
                <span class="sale-price"
                  >{{
                    selectedRegion
                      ? `${selectedPrice.final_price} ${
                          selectedPrice.currency
                        } `
                      : `${pricePlaceholder.final_price} ${
                          pricePlaceholder.currency
                        }`
                  }}
                </span>
                <del
                  v-if="
                    singleProduct &&
                      singleProduct.cost &&
                      singleProduct.cost.discount_amount
                  "
                  class="full-price"
                >
                  {{ singleProduct.cost.base_price }}
                  {{ singleProduct.cost.currency }}
                </del>
              </div>
            </div>
            <div
              v-if="singleProduct ? singleProduct.details : product.details"
              class="mobile-description-new-single"
            >
              <h1>
                {{ $t('_web_shop_single_description', 'Product description') }}
              </h1>
              <!-- eslint-disable vue/no-v-html -->
              <p
                class="product-description"
                v-html="
                  processText(
                    singleProduct ? singleProduct.details : product.details
                  )
                "
              ></p>
              <!-- eslint-enable vue/no-v-html -->
            </div>
            <div class="purchase-mobile">
              <div class="quantity">
                <span>{{ $t('_web_shop_single_quantity', 'Qty:') }}</span>
                <div class="quantity-counter">
                  <button :disabled="quantity <= 1" @click="decrementQuantity">
                    <span>-</span>
                  </button>
                  <span>{{ quantity }}</span>
                  <button
                    :disabled="
                      singleProduct
                        ? quantity >= singleProduct.max_quantity
                        : quantity >= Math.ceil(500 / selectedPrice.final_price)
                    "
                    @click="incrementQuantity"
                  >
                    <span>+</span>
                  </button>
                </div>
              </div>
              <!-- <span class="single-product-note-mobile">
                {{
                  $t(
                    '_web_shop_high_demand',
                    'Item purchase is limited to one per customer.'
                  )
                }}
              </span> -->
              <span
                v-if="
                  singleProduct
                    ? quantity >= singleProduct.max_quantity
                    : quantity >= Math.ceil(500 / selectedPrice.final_price)
                "
                class="single-product-note-mobile"
              >
                {{
                  $t(
                    '_web_shop_max_value',
                    'Note: Maximum value 500.00 {currency}',
                    { currency: selectedPrice.currency }
                  )
                }}
              </span>
              <div class="pickers-mobile">
                <div class="gap-control">
                  <ShopDropdown
                    v-model="selectedRegion"
                    :list="regions"
                    :empty-option="true"
                    :could-be-empty="true"
                    :empty-text="$t('_web_shop_selectt', 'Select')"
                    :label="$t('_web_shop_label_region', 'Region')"
                  >
                    <template slot="item" slot-scope="options">
                      <span class="piceker-option-item">
                        {{ options.item }}
                      </span>
                    </template>
                  </ShopDropdown>
                </div>
                <div class="gap-control">
                  <ShopDropdown
                    v-model="selectedPrice"
                    :list="filteredPrices ? filteredPrices : []"
                    :disabled="selectedRegion === ''"
                    :empty-text="$t('_web_shop_selectt', 'Select')"
                    :label="$t('_web_shop_label_amount', 'Amount')"
                  >
                    <template slot="item" slot-scope="options">
                      <div class="chevron-divider">
                        <span>
                          <span v-if="options.item.value">
                            {{ `${options.item.value} - ` }}
                          </span>

                          {{ options.item.currency }}
                          {{ options.item.final_price }}
                        </span>
                        <icon name="chevron-down-small" width="16" />
                      </div>
                    </template>
                  </ShopDropdown>
                </div>
              </div>
              <HeraButton
                class="btn-primary shop-now buy-now-btn-mobile-shop"
                :label="$t('_web_shop_label_buy_now', 'BUY NOW')"
                :loading="isOpening"
                @click="buyNow"
              />
              <!-- <div>
          <h1>{{ $t('_web_shop_single_terms', 'Terms and conditions') }}</h1>
          <p class="product-description">{{ product.terms }}</p>
        </div> -->
            </div>
            <div
              v-if="
                singleProduct
                  ? singleProduct.instructions
                  : product.instructions
              "
              class="mobile-description-new-single mobile-descr-howto"
            >
              <h1>{{ $t('_web_shop_single_usage', 'How to use') }}</h1>
              <!-- eslint-disable vue/no-v-html -->

              <p
                class="product-description"
                v-html="
                  processText(
                    singleProduct
                      ? singleProduct.instructions
                      : product.instructions
                  )
                "
              ></p>
              <!-- eslint-disable vue/no-v-html -->
            </div>
            <div
              v-if="singleProduct ? singleProduct.tos : product.tos"
              class="mobile-description-new-single"
            >
              <h1>
                {{ $t('_web_shop_single_terms', 'Terms and conditions') }}
              </h1>
              <!-- eslint-disable vue/no-v-html -->

              <p
                class="product-description"
                v-html="
                  processText(singleProduct ? singleProduct.tos : product.tos)
                "
              ></p>
              <!-- eslint-disable vue/no-v-html -->
            </div>
          </div>
          <div class="single-product-sidebar">
            <div class="prices">
              <span
                v-if="
                  singleProduct &&
                    singleProduct.cost &&
                    singleProduct.cost.discount_amount
                "
                class="discount mb-3"
              >
                <span>{{
                  `${singleProduct.cost.discount_amount} %`
                }}</span></span
              >
              <span class="sale-price"
                >{{
                  selectedRegion
                    ? `${selectedPrice.final_price} ${selectedPrice.currency} `
                    : `${pricePlaceholder.final_price} ${
                        pricePlaceholder.currency
                      }`
                }}
              </span>
              <del
                v-if="
                  singleProduct &&
                    singleProduct.cost &&
                    singleProduct.cost.discount_amount
                "
                class="full-price"
              >
                {{ singleProduct.cost.base_price }}
                {{ singleProduct.cost.currency }}
              </del>
            </div>
            <div class="quantity">
              <span>{{ $t('_web_shop_single_quantity', 'Qty:') }}</span>
              <div class="quantity-counter">
                <button :disabled="quantity <= 1" @click="decrementQuantity">
                  <span>-</span>
                </button>
                <span>{{ quantity }}</span>
                <button
                  :disabled="
                    singleProduct
                      ? quantity >= singleProduct.max_quantity
                      : quantity >= Math.ceil(500 / selectedPrice.final_price)
                  "
                  @click="incrementQuantity"
                >
                  <span>+</span>
                </button>
              </div>
            </div>

            <span
              v-if="
                singleProduct
                  ? quantity >= singleProduct.max_quantity
                  : quantity >= Math.ceil(500 / selectedPrice.final_price)
              "
              class="single-product-note"
            >
              {{
                $t(
                  '_web_shop_max_value',
                  'Note: Maximum value 500.00 {currency}',
                  { currency: selectedPrice.currency }
                )
              }}
            </span>
            <div class="pickers">
              <div class="gap-control">
                <ShopDropdown
                  v-model="selectedRegion"
                  :list="regions"
                  :empty-option="true"
                  :could-be-empty="true"
                  :empty-text="$t('_web_shop_selectt', 'Select')"
                  :label="$t('_web_shop_label_region', 'Region')"
                >
                  <template slot="item" slot-scope="options">
                    <span class="piceker-option-item">
                      {{ options.item }}
                    </span>
                  </template>
                </ShopDropdown>
              </div>
              <div class="gap-control">
                <ShopDropdown
                  v-model="selectedPrice"
                  :list="filteredPrices ? filteredPrices : []"
                  :empty-text="$t('_web_shop_selectt', 'Select')"
                  :label="$t('_web_shop_label_amount', 'Amount')"
                  class="lg-price-picker"
                >
                  <template slot="item" slot-scope="options">
                    <div class="chevron-divider">
                      <span>
                        <span v-if="options.item.value">
                          {{ `${options.item.value} - ` }}
                        </span>
                        {{ options.item.currency }}
                        {{ options.item.final_price }}
                      </span>
                      <icon
                        name="chevron-down-small"
                        width="16"
                        class="ml-2 mr-2"
                      />
                    </div>
                  </template>
                </ShopDropdown>
              </div>
            </div>
            <HeraButton
              class="btn-primary shop-now"
              :label="$t('_web_shop_label_buy_now', 'BUY NOW')"
              :loading="isOpening"
              :disabled="
                selectedRegion === '' || (InfoIsLoading && isAuthorized)
              "
              @click="buyNow"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import HeraButton from '@/components/atoms/HeraFormBtn';
// import BackBtn from '@/components/common/GoBackBtn';
import { prop } from '@/utils/factories';
import ShopDropdown from './ShopDropdown.vue';
import icon from '@/components/atoms/Icon';
import flagsmithMixin from '@/mixins/flagsmithMixin';

// import ShopCategories from './ShopCategories';
// import ShopSearch from './ShopSearch';

export default {
  name: 'SingleProduct',
  components: {
    HeraButton,
    ShopDropdown,
    icon,
    // BackBtn,
    // ShopCategories,
    // ShopSearch,
  },
  mixins: [flagsmithMixin],
  props: {
    productPropId: prop(String),
    paymentStatus: prop(Number),
  },
  data() {
    return {
      product: null,
      singleProduct: null,
      isToggleActive: false,
      quantity: 1,
      savedCards: [],
      canceledPayment: '',
      sucessPayment: '',
      toggleButton: false,
      isOpening: false,
      hasPhone: false,
      isSso: false,
      orderId: '',
      InfoIsLoading: false,
      selectedRegion: '',
      selectedPrice: [],
      pricePlaceholder: {},
    };
  },
  computed: {
    ...mapGetters([
      'selectedCategory',
      'categories',
      'shopSearch',
      'isAuthorized',
      'isRTL',
    ]),
    ...mapState('my', ['account']),
    ...mapState('my', { myLogin: 'accountId' }),
    // eslint-disable-next-line vue/return-in-computed-property
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    /* eslint-disable vue/no-side-effects-in-computed-properties */
    // eslint-disable-next-line
    regions() {
      let regionSet = new Set(
        this.product ? this.product.options.map(option => option.region) : []
      );
      let regions = [...regionSet].filter(region => region !== '');
      if (regions.includes(this.isRTL ? 'السعودية' : 'Saudi Arabia')) {
        this.selectedRegion = this.isRTL ? 'السعودية' : 'Saudi Arabia';
      } else {
        this.selectedRegion = regions[0] || 'All region';
      }
      return regions;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filteredPrices() {
      let options = this.product ? this.product.options : [];
      let regionToFilter =
        this.selectedRegion === 'All region' ? '' : this.selectedRegion;
      options = options.filter(option => option.region === regionToFilter);
      const sortedArr = options.sort((a, b) => a.final_price - b.final_price);
      this.selectedPrice = sortedArr[0] || {};
      return sortedArr;
    },
  },
  watch: {
    selectedPrice(newPrice, oldPrice) {
      if (newPrice !== oldPrice && newPrice.card_id) {
        this.fetchProductDetailsNew(newPrice.card_id);
        this.quantity = 1;
      }
    },
    isRTL(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchProductDetails();
      }
    },
    selectedRegion(newRegion) {
      if (newRegion) {
        const filteredOptions = this.product.options.filter(
          option => option.region === newRegion
        );
        this.filteredPrices = filteredOptions;
        if (this.filteredPrices.length > 0) {
          this.selectedPrice = this.filteredPrices[0];
        }
      } else {
        this.filteredPrices = [];
        this.selectedPrice = '';
      }
    },
    shopSearch() {
      setTimeout(() => {
        this.$router.push({ name: 'Shop' });
      }, 100);
    },
    paymentStatus() {
      if (this.$route.query.callback) {
        this.sucessPayment = this.$t(
          '_web_shop_order_sucess_message',
          'Payment successfull.'
        );

        window.dataLayer.push({
          event: 'shop purchase success',
          productName: this.product.name,
          singleProductSalesPrice: this.selectedPrice.final_price,
          discountValue: null,
          discountType: null,
          quantity: this.quantity,
          totalPrice: this.quantity * this.selectedPrice.final_price,
        });

        setTimeout(() => {
          this.toggleButton = true;
        }, [20000]);
      }
    },
  },
  mounted() {
    this.InfoIsLoading = true;
    this.getIdentity().then(res => {
      this.hasPhone = res.phone === null ? false : true;
      this.isSso = res.sns_signin_only;
      this.InfoIsLoading = false;
    });
    // if (this.$route.query.callback) {
    //   this.sucessPayment = this.$t(
    //     '_web_shop_order_sucess_message',
    //     'Payment successfull.'
    //   );

    //   setTimeout(() => {
    //     this.toggleButton = true;
    //   }, [20000]);
    // }
    this.fetchCards().then(res => (this.savedCards = res.items));
    if (!this.categories.length) {
      this.fetchCategories({ lang: this.isRTL ? 'ar' : 'en' });
    }
    this.fetchProductDetails();
    let cdnId = 'tap-cdn';
    if (!document.getElementById(cdnId)) {
      let scriptTag = document.createElement('script');
      scriptTag.src = 'https://secure.gosell.io/js/sdk/tap.min.js';
      scriptTag.id = cdnId;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  },
  methods: {
    ...mapActions([
      'fetchIndividualCard',
      'fetchSingleCard',
      'fetchCategories',
      'postOrder',
      'openModal',
      'closeModal',
      'fetchCards',
      'fulfillOrder',
      'getIdentity',
      'cancelOrder',
    ]),
    ...mapMutations(['updateModalProps']),
    fetchProductDetails() {
      const productId = this.$route.params.productId;
      this.fetchIndividualCard({
        id: productId || this.productPropId,
        lang: this.isRTL ? 'ar' : 'en',
      }).then(res => {
        return (this.product = res);
      });
    },
    fetchProductDetailsNew(productId) {
      this.fetchSingleCard({
        id: productId,
        lang: this.isRTL ? 'ar' : 'en',
      }).then(res => {
        this.singleProduct = res;
        window.dataLayer.push({
          event: 'Product view',
          productID: res.id,
          productName: res.name,
          userID: this.myLogin,
          categoryName: res.category.name,
          categoryID: res.category.id,
        });
      });
    },
    processText(text) {
      if (!text) return '';
      text = text.replace(/\\n/g, '<br>');
      return text.replace(/\\$/, '');
    },

    incrementQuantity() {
      if (this.quantity >= 1) {
        this.quantity++;
      }
    },

    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    goToCat(category) {
      this.$router.push({ path: '/shop' });
      this.selectCategory(category);
    },
    async processPayment(token, res, save_card) {
      try {
        await this.fulfillOrder({ id: res, token, save_card }).then(res =>
          res.redirectUrl
            ? (window.location.href = res.redirectUrl)
            : (this.closeModal(),
              (this.canceledPayment = 'something went wrong'))
        );
      } catch (err) {
        this.closeModal();
        this.canceledPayment = 'Something went wrong';
        window.dataLayer.push({
          event: 'shop purchase failed',
        });
      }
    },
    buyNow() {
      if (!this.hasPhone && this.isAuthorized) {
        // this.getIdentity().then(
        //   res => (this.hasPhone = res.phone === null ? false : true)
        // );
        this.openModal({
          name: 'MobileProvide',
          props: {
            isSso: this.isSso,
          },
          settings: {
            variant: 'fit-content',
            clickOnBackdrop: false,
            escKey: false,
          },
          events: {
            success: () => {
              this.getIdentity().then(
                res => (this.hasPhone = res.phone === null ? false : true)
              );
              this.pay();
            },
          },
        });
      } else {
        this.pay();
      }
    },
    updateSelectedRegion(newRegion) {
      this.selectedRegion = newRegion;
    },
    pay() {
      this.isOpening = true;
      window.dataLayer.push({
        event: 'shop purchase attempt',
        productName: this.product.name,
        singleProductSalesPrice: this.selectedPrice.final_price,
        discountValue: null,
        discountType: null,
        quantity: this.quantity,
        totalPrice: this.quantity * this.selectedPrice.final_price,
      });
      if (!this.$store.getters.isAuthorized || !this.isAuthorized) {
        this.isOpening = false;
        this.openModal({
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
        });
      } else {
        this.postOrder({
          order_items: [
            {
              card_id: this.selectedPrice.card_id,
              quantity: this.quantity,
            },
          ],
        })
          .then(res => {
            this.isOpening = false;
            this.OrderId = res.id;
            this.openModal({
              name: 'CheckoutShop',
              props: {
                product: res.id,
                description: res.items[0].card,
                quantity: this.quantity,
                cards: this.savedCards,
                provider: this.$flagsmith.flags.payment_provider.value,
              },
              events: {
                submit: (token, save_card) => {
                  this.processPayment(token, res.id, save_card);
                },
                errorStatus: () => {
                  this.canceledPayment =
                    'something went wrong, please ty again later';
                },
              },
              settings: {
                clickOnBackdrop: false,
                escKey: false,
                variant: 'fit-content',
              },
            });
          })
          .catch(err => {
            this.canceledPayment = err.message;
            this.cancelOrder(this.orderId);
            this.isOpening = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single-product-wrapper {
  position: relative;
  display: flex;
  // max-width: 1510px !important;
  margin: auto;
  gap: 2rem;
  padding-top: 3rem;
  justify-content: center;

  @media (max-width: 850px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.single-product-inner {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 1511px;

  @media (max-width: 850px) {
    width: 100%;
    padding: 1rem;
  }
}

.category-title-mobile {
  display: none;

  @media (max-width: 850px) {
    display: block;
    margin-bottom: 0;
    @include auto-rtl(margin-left, 1rem);
  }
}

.single-prod-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  // margin-top: 1.5rem;

  @media (max-width: 850px) {
    gap: 2rem;
  }
}

.single-prod-content-inner {
  display: flex;
  justify-content: space-between;
  gap: 10%;

  @media (max-width: 850px) {
    gap: 4rem;
    flex-direction: column;
  }
}

.single-product {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 850px) {
    width: 100%;
  }
}

.product-title {
  font-family: 'Exo';
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;
  @include auto-rtl(margin-left, 1.5rem);

  @media (max-width: 850px) {
    display: none;
  }
}

.product-title-mobile {
  @include auto-rtl(text-align, left !important);
  font-size: 28px;
  display: none;

  @media (max-width: 850px) {
    display: flex;
    margin-bottom: 1.8rem;
    margin-top: 1.8rem;
  }
}

.prices-info-mobile {
  display: none;

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 3rem;
  }
}

.mobile-description-new-single {
  display: none;

  @media (max-width: 850px) {
    display: block;
    margin-bottom: 54px;

    h1 {
      @include auto-rtl(text-align, left !important);
    }
  }
}

.cover-image-single {
  width: 100%;
}

.product-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;

  h1 {
    @include auto-rtl(text-align, left !important);
  }

  @media (max-width: 850px) {
    width: 100%;
    display: none;

    p {
      font-size: 16px;
    }
  }
}

.product-description {
  font-size: 14px;
}

.single-product-sidebar {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media (max-width: 850px) {
    display: none;
  }
}

.discount {
  padding: 0.3rem 1.5rem;
  @include auto-rtl(margin-right, auto);
  flex-shrink: 0;
  border-radius: 0px 4px 4px 0px;

  .app-rtl & {
    border-radius: 4px 0px 0px 4px;
    right: 0;
  }

  background: #f8d53a;
  display: flex;
  align-items: center;

  span {
    color: black;
    font-family: Exo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}

.prices {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.sale-price {
  font-family: 'Exo';
  font-weight: 600;
  font-size: 36px;
  line-height: 35.2px;
}

.full-price {
  display: flex;
  font-family: 'Exo';
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  padding: 0px 5px;
}

.shop-now {
  width: 123px;
  text-transform: uppercase;
}

.quantity {
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.single-product-note {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  // color: #9ca0ad99;
  color: red;
}

.quantity-counter {
  display: flex;
  // gap: 32px;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 48px;
  border: 1px solid white;
  border-radius: 5px;

  span {
    width: 65px;
    text-align: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;

    span {
      font-weight: 700;
      font-size: 26px;
    }
  }
}

.purchase-mobile {
  display: none;

  h1 {
    text-align: left !important;
  }

  .single-product-note-mobile {
    font-size: 16px;
    font-weight: 400px;
    line-height: 24px;
    // color: #9ca0ad99;
    color: red;
  }

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    gap: 14px;
    // margin-top: 54px;
  }
}

.single-close-btn {
  height: 60px;
  border: none;
  background-color: transparent;
  color: white;
}

.single-prod-breadcrumbs {
  font-size: 14px;
  color: #c1c7d7;

  span:nth-child(1),
  :nth-child(3) {
    cursor: pointer;
  }

  .breadcrumb-icon {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  @media (max-width: 850px) {
    display: none;
  }
}

.toast-error {
  z-index: 100;
  display: flex;
  width: 478px;
  max-width: 100%;
  min-height: 38px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  border-radius: 8px;
  background: var(--foreground-critical, #eb6666);
  /* Elevated */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.07),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--black-white-white, #fff);
    border: none;
    /* Base */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 850px) {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    width: 93%;
  }
}

.toast-sucess {
  z-index: 100;
  display: flex;
  width: 478px;
  max-width: 100%;
  min-height: 38px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  border-radius: 8px;
  background: rgb(75, 181, 67);
  color: white;
  border-radius: 8px;
  /* Elevated */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.07),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--black-white-white, #fff);
    border: none;
    /* Base */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 850px) {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    width: 93%;
  }
}

.toast-processing {
  z-index: 100;
  display: flex;
  width: 478px;
  max-width: 100%;
  min-height: 38px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  border-radius: 8px;
  background: rgb(255, 230, 0);
  color: black;
  border-radius: 8px;
  /* Elevated */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.07),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--black-white-white, #fff);
    border: none;
    /* Base */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 850px) {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    width: 93%;
  }
}
</style>

<style lang="scss">
.modalbackdrop {
  .ph-panel {
    overflow: auto;
    scrollbar-color: $ph-secondary-text $ph-card;
    scrollbar-width: 12px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      margin: -8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $ph-secondary-text;
      border-radius: 6px;

      &:hover {
        background-color: $ph-secondary-text;
      }
    }
  }
}

.new-back-btn-shop {
  .app-rtl & {
    transform: scaleX(-1);
  }
}

.buy-now-btn-mobile-shop {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.single-product-image {
  width: 100%;
  background: #f8f8f8;
  max-height: 500px;
  display: flex;
  padding: 1rem;
  border-radius: 10px;
  aspect-ratio: 16 / 9;
  overflow: hidden;

  img {
    mix-blend-mode: multiply;
    object-fit: contain;
  }
}

.mobile-card-shop {
  display: block;

  @media (max-width: 850px) {
    display: none;
  }
}

.mobile-descr-howto {
  margin-top: 54px;
}

.pickers {
  display: flex;
  gap: 24px;
}

.pickers-mobile {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .gap-control {
    .dropdown {
      .float-dropbox {
        @media (max-width: 850px) {
          width: 100% !important;
          min-width: unset;
          max-width: unset;
        }
      }

      .dropdown_activator {
        width: 100%;
      }
    }
  }
}
.lg-price-picker {
  .wrapper-control {
    .shop-drp-btn {
      width: unset;
      min-width: 168px;
      max-width: 200px;
    }
  }
}

.gap-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chevron-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 130px;

  @media (max-width: 850px) {
    min-width: fit-content;
  }
}
</style>
