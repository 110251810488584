import flagsmith from 'flagsmith';
export default {
  install(Vue, options) {
    flagsmith.init({
      environmentID: options.environmentID,
    });

    Vue.prototype.$flagsmith = flagsmith;
  },
};
