<template>
  <div
    ref="tournamentWrapper"
    v-observe-visibility="observeVisibility"
    class="position-relative tournament-card-wrapper tournament-search"
    :class="slider"
  >
    <component
      :is="linkComponent"
      v-bind="linkComponentBind"
      class="d-block position-relative overflow-hidden tournament-card"
    >
      <Panel variant="no-padding" class="position-relative">
        <TournamentCardCover :tournament="tournament" />
        <div class="card-bottom-panel">
          <div class="card-top">
            <TournamentCardTitle :tournament="tournament" />
            <StartsOn
              :tournament-start-time="tournament.tournament_start || 0"
              :card-style="'tournament-card-style'"
            />
            <TournamentCardEntry :tournament="tournament" />
          </div>
          <div class="card-bottom">
            <TournamentStatusIcon
              :tournament-status="tournament.status || 'draft'"
              :mini="true"
            />
            <TournamentPlatformAndSquad
              :tournament="tournament"
              class="tournament-squad"
            />
          </div>
        </div>
      </Panel>
    </component>
    <TournamentCardActions
      :tournament="tournament"
      :is-organizer="forOrganizer"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { prop, propRequired } from '@/utils/factories';
import { ORGANIZATION } from '@/constants/accounts';
import { translate } from '@/mixins/translate';
import viewsTrackingMixin from '@/mixins/metrics';
import LinkTournament from '@/components/links/Tournament';
import Panel from '@/components/atoms/Panel';
import TournamentCardTitle from './components/Title';
import TournamentCardEntry from './components/Entry';
import TournamentCardSubtitle from './components/Subtitle';
import TournamentCardSponsors from './components/Sponsors';
import TournamentCardCover from './components/Cover';
import TournamentCardStatusLabel from './components/StatusLabel';
import TournamentCardPrize from './components/Prize';
import TournamentCardActions from './components/Actions';
import TournamentCardOrganizer from './components/Organizer';
import StartsOn from '@/components/common/StartsOn.vue';
import TournamentStatusIcon from '@/components/common/TournamentStatusIcon.vue';
import TournamentPlatformAndSquad from '@/components/common/TournamentPlatformAndSquad.vue';

export default {
  components: {
    Panel,
    LinkTournament,
    TournamentCardPrize,
    TournamentCardStatusLabel,
    TournamentCardCover,
    TournamentCardTitle,
    TournamentCardEntry,
    TournamentCardSubtitle,
    TournamentCardSponsors,
    TournamentCardActions,
    TournamentCardOrganizer,
    StartsOn,
    TournamentStatusIcon,
    TournamentPlatformAndSquad,
  },
  mixins: [translate('_web_tournament'), viewsTrackingMixin],
  props: {
    tournament: propRequired(Object),
    forOrganizer: prop(Boolean),
    slider: prop(String),
  },
  computed: {
    ...mapGetters(['isTournamentPastStatus', 'currentTournament']),
    linkComponent() {
      return 'router-link';
    },
    linkComponentBind({ forOrganizer, tournament = {} }) {
      const { id, vanity_id, vanityId = vanity_id } = tournament;

      return {
        id,
        vanityId,
        ...(forOrganizer
          ? {
              to: {
                name: 'TournamentSetup',
                params: { tournamentID: id },
              },
            }
          : {
              to: {
                name: 'singleCompetition',
                params: { tournamentID: id },
              },
            }),
      };
    },
    itemAccountBind({ tournament = {} }) {
      const {
        event_organizer_logo,
        event_organizer_name,
        event_organizer_vanity_id,
        owner,
      } = tournament;
      return {
        type: ORGANIZATION,
        avatar: event_organizer_logo,
        name: event_organizer_name,
        vanityId: event_organizer_vanity_id,
        accountId: owner,
        size: 36,
      };
    },
    observeVisibility({
      forOrganizer,
      handleScrollIntoViewForViewsTracking,
      tournament: { id } = {},
    }) {
      return {
        callback: isVisible =>
          !forOrganizer
            ? handleScrollIntoViewForViewsTracking(isVisible, id, 'tournament')
            : this.$lodash.noop(),
        intersection: {
          threshold: 0.1,
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import './variables';
.tournament-search {
  min-width: 300px;
}

.tournament-card-wrapper {
  .tournament-card {
    &,
    &:hover,
    &:active,
    a,
    a:hover,
    a:active {
      text-decoration: none;
      color: $ph-primary-text;
    }

    &_organizer {
      @include auto-rtl('left', $card_padding * 1.5);
    }

    &:hover {
      .card-bottom-panel {
        background-color: $new-bg-elevated;
      }
      .tournament-card_cover {
        &:after {
          background: linear-gradient(
            360deg,
            rgba(17, 11, 29, 0.596) 30%,
            rgba(5, 4, 10, 0.466) 80%
          );
        }
      }
    }
  }
}

.card-slider {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0.5rem;
}

@media screen and (max-width: 1274px) {
  .card-slider {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding: 0.5rem;
  }
}

.card-bottom-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $new-bg-base;
  width: 100%;
  padding: $hr-spacing-05;
  padding-bottom: $hr-spacing-05 + 0.25rem;
  margin-top: $hr-spacing-02;
  border-radius: $hr-radius-01;
  transition: 0.2s;
  .card-top {
    .tournament-card-price {
      .free {
        font-family: Exo;
        font-size: 12px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        color: #9290ff !important;
      }
      .price {
        display: flex;
        gap: 5px;
        font-family: Exo;
        font-size: 12px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        color: #9290ff !important;
      }
    }
  }
}

.card-bottom {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.tournament-squad {
  @include auto-rtl(margin-left, $hr-spacing-03);
}
</style>
