<template>
  <component :is="tag" class="d-flex justify-content-start align-items-center">
    <slot name="before"></slot>
    <div
      v-if="$slots.avatar || avatarProps"
      class="flex-shrink-1 list-item-avatar h-mr-3"
    >
      <slot name="avatar">
        <div
          class="position-relative cursor-hand "
          :class="[read ? 'text-muted' : 'has-unread']"
        >
          <Avatar v-if="avatarProps" v-bind="avatarProps" />
        </div>
      </slot>
    </div>
    <div
      class="d-flex flex-column flex-fill w-100 overflow-hidden list-item-title"
    >
      <span class="text-truncate">
        <slot name="title">{{ title }}</slot>
      </span>
      <slot name="subtitle">
        <span v-if="subtitle" class="d-block text-muted mt-1 role">{{
          subtitle
        }}</span>
      </slot>
    </div>
    <div
      v-if="$slots.tail"
      class="d-flex justify-content-center align-items-center h-text-right"
    >
      <slot name="tail">{{ tail }}</slot>
    </div>
  </component>
</template>

<script>
import Avatar from '@/components/images/Avatar';
import { prop } from '@/utils/factories';
export default {
  name: 'ListItem',
  components: { Avatar },
  props: {
    tag: prop(String, 'div'),
    title: prop(String),
    subtitle: prop(String),
    tail: prop(String),
    avatarProps: prop(Object, null),
    read: prop(Boolean),
  },
};
</script>
<style lang="scss" scoped>
.list-item-title {
  font-size: 0.875rem;
  .text-truncate {
    font-family: $ph-font-family-secondary;
  }
}
</style>
