import { CONSTRUCTOR_MODES } from '@/constants/tournaments';

export const MAX_NUMBER_OF_PRIZE_FIELDS = {
  default: 3,
  singleElimWithout3thPlace: 2,
};

export const NAVIGATION_BUTTONS = {
  invitePlayers: {
    prev: {
      name: 'tournamentPlayers',
      key: '_web_tournament_nav_buttons_qualify-participants',
      default: 'Qualify Participants',
      params: { constructorMode: CONSTRUCTOR_MODES.PUBLISH },
    },
  },
  stageParticipants: {
    prev: {
      name: 'multiStage',
      key: '_web_tournament_nav_buttons_back',
      default: 'Back',
      params: { constructorMode: CONSTRUCTOR_MODES.PUBLISH },
    },
  },
  editStage: {
    prev: {
      name: 'multiStage',
      key: '_web_tournament_nav_buttons_cancel',
      default: 'Cancel',
      replace: true,
      params: {
        constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
        discardChanges: true,
      },
    },
    primary: {
      name: 'multiStage',
      key: '_web_tournament_nav_buttons_save',
      replace: true,
      default: 'Save',
      params: { constructorMode: CONSTRUCTOR_MODES.CONSTRUCT },
    },
  },
};

export default {
  MAX_NUMBER_OF_PRIZE_FIELDS,
};
