<template>
  <div :class="color">
    <!--start of view two -->
    <!--start of view two -->
    <div
      v-if="
        (!isClosed && isRegStarted && pendingUsers.length > 2 && !showJoined) ||
          (!isClosed &&
            isRegStarted &&
            info &&
            pendingUsers.length > 2 &&
            !checked)
      "
      :class="color"
      class="wrapper-universal regStarted-status-info"
      @click="participansModal"
    >
      <div class="slots-wrapper">
        <img
          src="@/assets/images/Union.svg"
          width="24px"
          height="24px"
          alt="Slots"
        />
        <h2>{{ $t('_web_single_competition_status-info-slots', 'SLOTS') }}</h2>
        <h3 class="d-block">
          {{ numberOfParticipans }}/{{ currentTournament.squads }}
        </h3>
      </div>
      <div class="gray-line-player-card"></div>
      <!--after gray line-->
      <!--after gray line-->
      <div class="imgs-avatar-outer-wrapper">
        <div class="imgs-avatar-wrapper">
          <img
            v-for="(avatar, index) in pendingAvatars"
            :key="index"
            :src="avatar"
            alt="user"
            width="48px"
            height="48px"
            class="small-image-avatar"
          />
        </div>
      </div>
      <div v-if="numberOfPendingUsers > 4" class="players-joined-wrapper">
        {{ lastToJoinPending }}
        <div class="inner-text-player-join">
          {{ $t('_web_single_competition_status-info-and', 'and') }}
          <b
            >{{ numberOfParticipans - 4 }}
            {{ $t('_web_single_competition_status-info-more', 'more') }}</b
          >
          {{
            $t('_web_single_competition_status-info-joined', 'recently joined.')
          }}
        </div>
      </div>
      <div v-else-if="numberOfPendingUsers > 0" class="players-joined-wrapper">
        {{ lastToJoinPending }}
        <div class="inner-text-player-join">
          {{
            $t('_web_single_competition_status-info-joined', 'recently joined.')
          }}
        </div>
      </div>
    </div>
    <!--end of view two-->
    <!--end of view two-->
    <!--start of view 3 -->
    <!--start of view 3 -->

    <div
      v-if="
        (isRegClosed && !checked && users.length >= 2 && !isRegStarted) ||
          (!info && users.length >= 2 && !checked && !isRegStarted) ||
          (!isClosed && !isRegStarted && !checked && info && users.length > 0)
      "
      :class="color"
      class="wrapper-universal regStarted-status-info"
      @click="participansModal"
    >
      <div class="slots-wrapper">
        <img
          src="@/assets/images/Union.svg"
          width="24px"
          height="24px"
          alt="Slots"
        />
        <h2>
          {{ $t('_web_single_competition_status-info-slots', 'SLOTS') }}
        </h2>
        <h3 class="d-block">
          {{ numberOfParticipans }}/{{ currentTournament.squads }}
        </h3>
      </div>
      <!--after gray line-->
      <!--after gray line-->
      <!--after gray line-->
      <div class="gray-line-player-card"></div>
      <div class="main-avatar-checked-in-wrap" @click="participansModal">
        <div class="imgs-avatar-outer-wrapper">
          <div class="imgs-avatar-wrapper">
            <img
              v-for="(avatar, index) in avatars"
              :key="index"
              :src="avatar"
              alt="user"
              width="24px"
              height="24px"
              class="small-image-avatar"
            />
          </div>
        </div>
        <div v-if="numberOfUsers > 4" class="players-joined-wrapper">
          {{ lastToJoinUsers }}
          <div class="inner-text-player-join">
            {{ $t('_web_single_competition_status-info-and', 'and') }}
            <b
              >{{ numberOfUsers }}
              {{ $t('_web_single_competition_status-info-more', 'more') }}</b
            >
            {{
              $t(
                '_web_single_competition_status-info-checked-in',
                'recently checked in.'
              )
            }}
          </div>
        </div>
        <div v-else-if="numberOfUsers > 0" class="players-joined-wrapper">
          {{ lastToJoinUsers }}
          <div class="inner-text-player-join">
            {{
              $t(
                '_web_single_competition_status-info-checked-in',
                'recently checked in.'
              )
            }}
          </div>
        </div>
      </div>
      <div
        v-if="
          currentTournamentStages[0].block_configuration.type ===
          'battle_royale'
            ? !info
            : !info &&
              currentTournament.status !== 'closed' &&
              currentTournamentStages[0].status === 'published'
        "
        class="bracket-button-wrap"
        @click.prevent.stop
      >
        <router-link :to="bracketsLink" class="brackets-button" @click.stop>
          {{ $t('_web_single_competition_status-info-brackets', 'brackets') }}
          <img
            src="@/assets/images/icons/arror-right-new-cro.svg"
            alt="brackets"
            width="14px"
            height="12px"
          />
        </router-link>
      </div>
    </div>
    <!--end of view 3-->
    <!--start of view 4-->
    <transition name="fade" mode="out-in">
      <div
        v-if="matches !== undefined && matches[2] && dataReady && !showMatches"
        :class="color"
        class="wrapper-universal"
      >
        <div
          :class="$route.name === `singleCompetition` ? `matches-mobile` : ``"
          class="all-card-wrap"
        >
          <div class="card-results-wrapper">
            <p class="round-title">{{ roundsToText(matches[2]) }}</p>
            <div class="players-wrap">
              <div
                class="player-one"
                :class="
                  matches[2].player1result === 'tech_win' ||
                  matches[2].player1result === 'win'
                    ? 'bolded-winner'
                    : ''
                "
              >
                <img
                  :src="
                    matches[2].player1avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[2].player1avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[2].player1avatar
                  "
                  alt="player"
                  width="16px"
                  height="16px"
                  class="player-avatar"
                />
                {{ matches[2].player1name }}
                <p>{{ matches[2].player1score }}</p>
              </div>
              <div
                class="player-two"
                :class="
                  matches[2].player2result === 'tech_win' ||
                  matches[2].player2result === 'win'
                    ? 'bolded-winner'
                    : ''
                "
              >
                <img
                  :src="
                    matches[2].player2avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[2].player2avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[2].player2avatar
                  "
                  alt="player"
                  width="16px"
                  height="16px"
                  class="player-avatar"
                />
                {{ matches[2].player2name }}
                <p>{{ matches[2].player2score }}</p>
              </div>
            </div>
          </div>
          <div
            class="gray-line-player-card"
            :class="
              $route.name === `singleCompetition`
                ? `gray-line-player-card-matches`
                : ``
            "
          ></div>
          <div class="card-results-wrapper">
            <p class="round-title">{{ roundsToText(matches[1]) }}</p>
            <div class="players-wrap">
              <div
                class="player-one"
                :class="
                  matches[1].player1result === 'tech_win' ||
                  matches[1].player1result === 'win'
                    ? 'bolded-winner'
                    : ''
                "
              >
                <img
                  :src="
                    matches[1].player1avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[1].player1avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[1].player1avatar
                  "
                  alt="player"
                  width="16px"
                  height="16px"
                  class="player-avatar"
                />
                {{ matches[1].player1name }}
                <p>{{ matches[1].player1score }}</p>
              </div>
              <div
                class="player-two"
                :class="
                  matches[1].player2result === 'tech_win' ||
                  matches[1].player2result === 'win'
                    ? 'bolded-winner'
                    : ''
                "
              >
                <img
                  :src="
                    matches[1].player2avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[1].player2avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[1].player2avatar
                  "
                  alt="player"
                  width="16px"
                  height="16px"
                  class="player-avatar"
                />
                {{ matches[1].player2name }}
                <p>{{ matches[1].player2score }}</p>
              </div>
            </div>
          </div>
          <div
            class="gray-line-player-card"
            :class="
              $route.name === `singleCompetition`
                ? `gray-line-player-card-matches`
                : ``
            "
          ></div>
          <div class="card-results-wrapper">
            <p class="round-title">{{ roundsToText(matches[0]) }}</p>
            <div class="players-wrap">
              <div
                class="player-one"
                :class="
                  matches[0].player1result === 'tech_win' ||
                  matches[0].player1result === 'win'
                    ? 'bolded-winner'
                    : ''
                "
              >
                <img
                  :src="
                    matches[0].player1avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[0].player1avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[0].player1avatar
                  "
                  alt="player"
                  width="16px"
                  height="16px"
                  class="player-avatar"
                />
                {{ matches[0].player1name }}
                <p>{{ matches[0].player1score }}</p>
              </div>
              <div
                class="player-two"
                :class="
                  matches[0].player2result === 'tech_win' ||
                  matches[0].player2result === 'win'
                    ? 'bolded-winner'
                    : ''
                "
              >
                <img
                  :src="
                    matches[0].player2avatar === ''
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[0].player2avatar === undefined
                      ? require('@/assets/images/defaults/user.svg')
                      : matches[0].player2avatar
                  "
                  alt="player"
                  width="16px"
                  height="16px"
                  class="player-avatar"
                />
                {{ matches[0].player2name }}
                <p>{{ matches[0].player2score }}</p>
              </div>
            </div>
          </div>
          <div class="bracket-button-wrap" @click.prevent.stop>
            <router-link :to="bracketsLink" class="brackets-button" @click.stop>
              {{
                $t('_web_single_competition_status-info-brackets', 'brackets')
              }}
              <img
                src="@/assets/images/icons/arror-right-new-cro.svg"
                alt="brackets"
                width="14px"
                height="12px"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="
          !showMatches &&
            matches.length < 3 &&
            $route.name === 'singleCompetition'
        "
        class="no-matches"
      >
        {{
          $t(
            '_web_single_competition_status-info-no-matches',
            'There are currently no matches.'
          )
        }}
      </div>
    </transition>
    <!--end of view 4-->
    <!--end of view 4-->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { tournamentHelpers } from '@/mixins/tournaments/tournamentHelpers';
import matchHelper from '@/views/tournaments/management/brackets/mixins/matchHelper';
import { translate } from '@/mixins/translate';
import { API } from '@/api/config';
import { prop } from '@/utils/factories';
import images from '@/constants/images';

export default {
  name: 'StatusInfo',
  components: {},
  mixins: [
    tournamentHelpers,
    matchHelper,
    translate('_web_single_competition_status-info'),
  ],
  props: {
    color: prop(String),
    tournamentprop: prop(String), //prepaired for rendering on homepage component
    showMatches: prop(Boolean),
    checked: prop(Boolean),
    showJoined: prop(Boolean),
    info: prop(Boolean),
  },
  data() {
    return {
      hours: '',
      minutes: '',
      seconds: '',
      lastToJoinUsers: '',
      numberOfUsers: 0,
      avatars: [],
      users: [],
      pendingUsers: [],
      pendingAvatars: [],
      lastToJoinPending: '',
      numberOfPendingUsers: 0,
      blocks: [],
      matches: [],
      numberOfRounds: 0,
      dataReady: false,
      numberOfParticipans: 0,
      modalData: [],
    };
  },

  computed: {
    ...mapState('current', ['account']),
    ...mapState('my', ['account']),
    ...mapGetters([
      'currentTournament',
      'currentTournamentStages',
      'registrationTournament',
      'registrationMembers',
      'getBlocksByStageID',
      'stagesList',
    ]),

    stages({ currentTournament, currentTournamentStages }) {
      return (currentTournament && currentTournamentStages) || null;
    },

    bracketsLink() {
      const stagesList = {
        name: 'playerStagesList',
        params: {
          tournamentID: this.tournamentprop
            ? this.tournamentprop
            : this.tournamentID,
        },
      };
      const firstStage = this.currentTournamentStages[0];
      if (!firstStage) {
        return stagesList;
      }
      return this.hasOneStage && this.hasOneBlock
        ? {
            name: 'playerTournamentBracket',
            params: {
              tournamentID: this.tournamentprop
                ? this.tournamentprop
                : this.tournamentID,
            },
          }
        : stagesList;
    },
  },

  watch: {
    matches() {
      this.dataReady = true;
    },
  },

  async created() {
    this.fetchTournament(this.tournamentprop);
  },

  async mounted() {
    if (this.isAuthorized) {
      const users = await this.getTournamentParticipants({
        id: this.tournamentprop ? this.tournamentprop : this.tournamentID,
      });

      //getting number of qualified
      const allUsers = users.properties;
      const approvedUser = users.items.filter(user => {
        return user.fields_data.approvedTo === 'Qualified';
      });
      this.modalData = users.items;
      //getting all participants data
      //getting approved users
      this.numberOfParticipans = users.total - allUsers.total_revoked;
      this.users = approvedUser;
      //getting pending users
      this.pendingUsers = users.items;
      this.pendingAvatars = users.items
        .filter(user => {
          return user.fields_data.avatar === null
            ? user.members === null
              ? (user.fields_data.avatar = images.defaultUser)
              : (user.fields_data.avatar = images.defaultClan)
            : user.fields_data.avatar;
        })
        .filter(user => user.fields_data.approvedTo !== 'Revoked')
        .map(user => {
          return user.fields_data.avatar === null
            ? '@/assets/images/icons/dummy/members.svg'
            : user.fields_data.avatar;
        })
        .slice(0, 4);

      this.lastToJoinPending = users.items
        .filter(user => user.fields_data.approvedTo !== 'Revoked')
        .slice(0, 4)
        .map(user => {
          return user.fields_data.username
            ? user.fields_data.username
            : user.id;
        })
        .join(', ');
      this.numberOfPendingUsers =
        allUsers.totally_found - 4 > 0
          ? allUsers.totally_found - 4
          : allUsers.totally_found;
      //checkedIn status
      this.avatars = approvedUser
        .filter(user => {
          return user.fields_data.avatar === null
            ? user.members === null
              ? (user.fields_data.avatar = images.defaultUser)
              : (user.fields_data.avatar = images.defaultClan)
            : user.fields_data.avatar;
        })
        .map(user => {
          return user.fields_data.avatar === null
            ? '@/assets/images/icons/dummy/members.svg'
            : user.fields_data.avatar;
        })
        .slice(0, 4);
      //getting the numbers of rest users in user array for displaying text in DOM
      this.lastToJoinUsers = approvedUser
        .slice(0, 3)
        .map(user => {
          return user.fields_data.username
            ? user.fields_data.username
            : user.id;
        })
        .join(', ');
      this.numberOfUsers =
        allUsers.currently_qualified - 3 > 0
          ? allUsers.currently_qualified - 3
          : allUsers.currently_qualified;

      //fetching all matches
      const finishedMatches = await this.getMatchesData(
        this.tournamentprop ? this.tournamentprop : this.tournamentID
      );

      this.matches = finishedMatches
        ? finishedMatches &&
          finishedMatches.data.items
            .map(item => {
              if (item) {
                return {
                  player1result: item.player_1_result,
                  player1score: item.player_1_score,
                  player1name:
                    item.player_1 === null || item.player_1 === undefined
                      ? this.$t(
                          '_web_single_competition_status-info-no-player',
                          'no player'
                        )
                      : item.player_1.name,
                  player1avatar:
                    item.player_1 === null
                      ? ''
                      : item.player_1 === undefined
                      ? ''
                      : item.player_1.avatar,
                  player2result: item.player_2_result,
                  player2score: item.player_2_score,
                  player2name:
                    item.player_2 === null || item.player_2 === undefined
                      ? this.$t(
                          '_web_single_competition_status-info-no-player',
                          'no player'
                        )
                      : item.player_2.name,
                  player2avatar:
                    item.player_2 === null
                      ? ''
                      : item.player_1 === undefined
                      ? ''
                      : item.player_2.avatar,
                  round: item.round,
                  type: item.type,
                  rank: item.match_rank ? item.match_rank : '',
                  squads: item.event.squads / 2,
                };
              }
            })
            .reverse()
        : [];
    }
  },

  methods: {
    ...mapActions([
      'getTournamentParticipants',
      'fetchTournament',
      'fetchTournamentStage',
      'getCompetitionMatches',
      'getLastMatchesAction',
      'isAuthorized',
      'openModal',
      'closeModal',
    ]),
    participansModal() {
      this.openModal({
        name: 'ParticipansModal',
        props: {
          playerType:
            this.currentTournament.max_players > 1 ? 'clan' : 'profile',
        },
        settings: {
          variant: 'fit-content',
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },

    roundsToText(item) {
      if (item) {
        return item.rank === 'final'
          ? this.$t('_web_single_competition_status-info-final', 'FINAL')
          : item.rank === 'third_place'
          ? this.$t('_web_single_competition_status-info-third', 'THIRD PLACE')
          : item.rank === 'semi_final'
          ? this.$t('_web_single_competition_status-info-semi', 'SEMI FINAL')
          : item.round === 0 && item.rank === 'regular'
          ? `${this.$t(
              '_web_single_competition_status-info-round1',
              'Round 1/'
            )}${item.squads}`
          : `${this.$t(
              '_web_single_competition_status-info-round1',
              'Round 1/'
            )}${Math.pow(2, item.round)}`;
      }
    },

    async getMatchesData(TournamentID) {
      try {
        const response = await API(
          `/competitions/matches/search?event_id=${TournamentID}&battle_status=completed&order_by=dateEnd `
        );
        return await response;
      } catch (err) {
        console.log(err.message);
      }
    },
  },
};
</script>

<style lang="scss">
.wrapper-universal {
  margin-top: 20px;
  font-family: $hr-font-family;
  width: 100%;
  min-height: 103px;
  // width: 729px;
  border: none;
  border-radius: 5px;
  background-color: $new-bg-base;

  @media (max-width: 800px) {
    background-color: inherit;
  }
}

.matches-mobile {
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column !important;
    margin: 0px !important;
    background-color: #11171f;
  }
}

.state-wait-to-start {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  h3 {
    margin-bottom: 0px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
  }
}

.time-till-start {
  display: flex;
  height: 48px;
  width: 206px;
  border-radius: 5px;
  background-color: #05285199;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.user-names-in-tournament {
  display: inline-block;
}

.regStarted-status-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.slots-wrapper {
  position: relative;
  padding-left: 9px;
  height: 103px;
  font-family: $hr-font-family;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  min-width: 75px;
  align-items: center;

  img {
    margin-bottom: 7px;
  }

  @media (max-width: 800px) {
    padding-left: 0px;
  }

  h2 {
    font-weight: 400;

    color: $hr-text-secondary;
    font-size: $hr-text-tiny;
    margin: 0;
    line-height: 15.95px;
    margin-bottom: 2px;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    size: $hr-text-tiny;
    line-height: 13.2px;
  }

  .gray-line {
    background-color: $hr-text-secondary;
    width: 1px;
    height: 64px;
    position: absolute;
    right: 22px;
  }
}

.players-joined-wrapper {
  min-width: 450px;
  padding: 20px;
  // flex-grow: 3;
  font-weight: 700;
  display: inline-flex;
  flex-direction: column;
  font-size: $hr-text-small;

  .inner-text-player-join {
    font-weight: 400;

    b {
      font-weight: 700;
    }
  }

  @media (max-width: 800px) {
    min-width: unset;
    width: 100%;
  }

  @media (max-width: 1300px) {
    padding: 1%;
  }
}

.small-image-avatar {
  // width: 48px;
  // height: 48px;
  border-radius: 50px;
  overflow: hidden;
  border: 3px solid $hr-bgselected;
}

.imgs-avatar-outer-wrapper {
  padding-left: 20px;
  min-width: 150px;
  height: 48px;

  .imgs-avatar-wrapper {
    position: relative;

    img:nth-child(1) {
      position: absolute;
      z-index: 4;
      position: absolute;
    }

    img:nth-child(2) {
      z-index: 3;
      position: absolute;
      transform: translateX(50%);
    }

    img:nth-child(3) {
      z-index: 2;
      position: absolute;
      transform: translateX(100%);
    }

    img:nth-child(4) {
      position: absolute;
      z-index: 1;
      transform: translateX(150%);
    }
  }

  @media (max-width: 950px) {
    display: none;
  }
}

.brackets-button {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  align-items: center;
  justify-self: end;
  border: 1px solid white;
  border-radius: 24px;
  width: 126px;
  height: 36px;
  background-color: transparent;
  color: white;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.bracket-button-wrap {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  padding: 30px;
  margin-right: 43px;

  @media (max-width: 1300px) {
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-avatar-checked-in-wrap {
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    flex-grow: 3;
  }

  .players-joined-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .imgs-avatar-outer-wrapper {
    height: 24px;
    padding-left: 20px;
  }
}

.messages-button {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  align-items: center;
  justify-self: end;
  border: 1px solid white;
  border-radius: 5px;
  width: 126px;
  height: 36px;
  background-color: transparent;
  color: white;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.card-results-wrapper {
  justify-content: space-between;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  font-family: $hr-font-family;
  width: 155px;
  height: 100%;
  padding: 23px;

  // padding-left: 23px;
  // padding-right: 23px;
  // padding-bottom: 23px;
  p {
    margin-bottom: 0;
  }

  @media (max-width: 1300px) {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.all-card-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 50px;

  @media (max-width: 1300px) {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.round-title {
  font-size: $hr-text-tiny;
  color: #b8bcbe;
  padding-bottom: 8px;
}

.player-avatar {
  border-radius: 5px;
}

.player-one {
  p {
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
  }

  display: flex;
  align-items: center;
  font-size: $hr-text-tiny;
  font-weight: 400;
  gap: 7px;
}

.player-two {
  p {
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
  }

  display: flex;
  align-items: center;
  font-size: $hr-text-tiny;
  font-weight: 400;
  gap: 7px;
}

.players-wrap {
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 110%;

  @media (max-width: 800px) {
    width: 100%;
  }
}

.gray-line-player-card {
  width: 1px;
  height: 66px;
  background-color: #474a56;
  margin-left: 3px;
  margin-right: 3px;
}

.gray-line-player-card-matches {
  width: 1px;
  height: 66px;
  background-color: #474a56;
  margin-left: 3px;
  margin-right: 3px;

  @media (max-width: 800px) {
    width: 90vw !important;
    height: 1px !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-duration: 2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition-duration: 2s;

  @media (max-width: 800px) {
    transition-duration: 0s;
  }
}

html[dir='rtl'] {
  .all-card-wrap {
    margin-left: 0;
    margin-right: 30px;
  }

  .slots-wrapper {
    margin-right: 18px;
    padding-left: 0;
  }

  .imgs-avatar-outer-wrapper {
    padding-right: 20px;
    padding-left: 0px;
  }

  .imgs-avatar-wrapper {
    position: relative;

    img:nth-child(1) {
      position: absolute;
      z-index: 1;
      position: absolute;
    }

    img:nth-child(2) {
      z-index: 2;
      position: absolute;
      transform: translateX(-50%);
    }

    img:nth-child(3) {
      z-index: 3;
      position: absolute;
      transform: translateX(-100%);
    }

    img:nth-child(4) {
      position: absolute;
      z-index: 4;
      transform: translateX(-150%);
    }
  }
}

.heroPageSingleInfo {
  .bracket-button-wrap {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .main-avatar-checked-in-wrap {
    margin-bottom: auto;
    margin-top: auto;
  }

  .gray-line-player-card {
    margin-bottom: auto;
    margin-top: auto;
  }

  .all-card-wrap {
    justify-content: space-between;
    flex-grow: 1;
  }

  .bracket-button-wrap {
    justify-content: center;
    flex-grow: 0;
  }

  height: fit-content;
  margin: 0;
  width: 100%;
  z-index: 40;
  right: 20px;
  bottom: 20px;
  background: #262633b2;
  display: flex;
  // justify-content: center;
  align-items: flex-end;
}

.matchesImg {
  width: 19.94px;
  height: 13.83px;
}

.bolded-winner {
  font-weight: 700 !important;
}

.messages-button {
  &:hover {
    background-color: white;
    color: black;

    .matchesImg {
      filter: invert(1);
    }
  }
}

.no-matches {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 800px) {
    display: none;
  }
}
</style>
