<template>
  <div
    v-if="heraShopLink"
    class="bannerContainer"
    :style="{ '--scrollWidth': scrollWidth }"
  >
    <div class="itemsContainer">
      <div class="bannerLeft">
        <h1>{{ $t('_web_shop-banner-title', 'From Shop') }}</h1>
        <span class="bannerText">{{
          $t(
            '_web_shop-banner-text',
            'Everything for gaming in one place. Consoles, gaming equipment, hot deals, and more.'
          )
        }}</span>

        <a :href="heraShopLink" target="_blank" class="shopbutton-desktop">
          <div class="viewShop">
            <p>
              {{ $t('_web_shop-banner-button', 'View shop') }}
            </p>
            <div class="viewShopButton">
              <img
                class="shopArrow"
                src="@/assets/images/arrow-right-home.svg"
                alt="shop arrow"
              />
            </div>
          </div>
        </a>
      </div>
      <div class="image-wrapper">
        <div class="imageContainer">
          <div
            v-for="item in shop"
            :key="item.category"
            class="d-flex container-item"
          >
            <a
              :href="heraShopLink + item.link"
              target="_blank"
              class="d-flex linkItem"
            >
              <img :src="item.url" alt="banner image" />
              <p
                class="shopCategory"
                v-text="
                  $t(
                    `_web_shop-banner-${item.category.toLowerCase()}`,
                    item.category
                  )
                "
              ></p>
            </a>
          </div>
        </div>
      </div>
      <a :href="heraShopLink" target="_blank" class="shopbutton-mobile">
        <div class="viewShop">
          <p>
            {{ $t('_web_shop-banner-button', 'View shop') }}
          </p>
          <div class="viewShopButton">
            <img
              class="shopArrow"
              src="@/assets/images/arrow-right-home.svg"
              alt="shop arrow"
            />
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollWidth: null,
      shop: [
        {
          url: [require('@/assets/images/playhera-shop-banner/KEYBOARD.png')],
          category: 'Keyboards',
          link: 'accessories.html?cat=201',
        },
        {
          url: [require('@/assets/images/playhera-shop-banner/MOUSE.png')],
          category: 'Mice',
          link: 'accessories/nintendo.html',
        },
        {
          url: [require('@/assets/images/playhera-shop-banner/MONITORS.png')],
          category: 'Monitors',
          link: 'accessories/monitors.html',
        },
        {
          url: [require('@/assets/images/playhera-shop-banner/HEADSETS.png')],
          category: 'Headsets',
          link: 'accessories/playstation.html',
        },
      ],
    };
  },
  computed: {
    heraShopLink() {
      const region = JSON.parse(localStorage.getItem('region'));
      const locale = JSON.parse(localStorage.getItem('locale'));
      return region.feature_flags.marketplace_url
        ? `${region.feature_flags.marketplace_url}${locale.key}-sa/`
        : null;
    },
  },
  mounted() {
    this.getScrollBarWidth();
  },
  methods: {
    getScrollBarWidth() {
      let el = document.createElement('div');
      el.style.cssText =
        'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      el.remove();
      return (this.scrollWidth = width.toString() + 'px');
    },
  },
};
</script>
<style lang="scss" scoped>
@import './variables';

h1 {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 110%;
  color: #ffffff;
}

p {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  color: #000000;
}
.bannerContainer {
  width: 100%;
  max-width: calc(100vw - var(--scrollWidth));
  height: $banner_height;
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0.8) -37.78%,
    rgba(235, 18, 187, 0.5) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  box-shadow: inset 0px 35px 30px -10px $new-bg-page,
    inset 0px -35px 30px -10px $new-bg-page;
}

.bannerLeft {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-basis: 25%;
  min-width: 280px;
  height: 204.99px;
}

.bannerText {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  margin-top: 10px;
}
a {
  text-decoration: none;
}

.banner {
  display: flex;
}

.viewShop {
  background-color: #eff0f1;
  padding: 1rem;
  border-radius: 30px;
  margin-top: 15px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.viewShopButton {
  display: flex;
  align-items: center;
}

.shopbutton-desktop {
  @include auto-rtl(margin-right, auto);
}
.viewShop p {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: black;
  margin-bottom: 0;
}

.itemsContainer {
  display: flex;
  width: $hr-wrapper-width;
  max-width: $hr-wrapper-max-width;
  justify-content: space-between;
  align-items: center;
}

.imageContainer {
  display: flex;
  gap: 1.563rem;
  flex-basis: 70%;
}

.linkItem:hover .shopCategory {
  color: #dfdfdf;
}

.container-item {
  position: relative;
  width: 100%;
}

.linkItem {
  position: relative;
  width: 100%;
}

.container-item img {
  max-width: 280px;
  max-height: 280px;
  min-width: 200px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.shopCategory {
  display: flex;
  padding: 10px;
  position: absolute;
  height: 23.43px !important;
  border-radius: 5px;
  color: white;
  align-items: center;
  justify-content: center;
  .app-rtl & {
    bottom: 1%;
    right: 3%;
  }
  .app-ltr & {
    bottom: 1%;
    left: 0;
  }
}

html[dir='rtl'] {
  .shopArrow {
    transform: rotate(180deg);
  }
  .viewShop:hover .shopArrow {
    transform: translateX(-15px) rotate(180deg);
  }
}

.shopbutton-mobile {
  display: none;
}

@media screen and (max-width: 1483px) {
  .itemsContainer {
    width: 92vw;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .itemsContainer::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 1251px) {
  .bannerContainer {
    height: auto;
    -webkit-box-shadow: inset 0 0 70px 60px $new-bg-page;
    box-shadow: inset 0 0 70px 60px $new-bg-page;
  }

  .itemsContainer {
    flex-direction: column;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .shopbutton-desktop {
    display: none;
  }

  .shopbutton-mobile {
    display: block;
  }

  .bannerLeft > h1 {
    text-align: center;
  }

  .bannerText {
    text-align: center;
  }

  .bannerLeft {
    max-width: 400px;
  }

  .imageContainer {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .itemsContainer {
    width: calc(100vw - var(--scrollWidth));
  }
  .image-wrapper {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .image-wrapper::-webkit-scrollbar {
    width: 0;
  }

  .imageContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 280px;
  }

  .bannerLeft {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
