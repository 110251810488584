<template>
  <div class="tournament-card_cover" :style="{ backgroundImage: cover }">
    <div class="tournament-card_wrapper">
      <TournamentCardOrganizer
        class="tournament-card_organizer"
        :tournament="tournament"
        :size="25"
      />
      <TournamentCardSponsors :tournament="tournament" :align="'end'" />
    </div>
  </div>
</template>

<script>
import { propRequired } from '@/utils/factories';
import TournamentCardOrganizer from '@/components/common/TournamentCard/components/Organizer.vue';
import TournamentCardSponsors from '@/components/common/TournamentCard/components/Sponsors.vue';

export default {
  name: 'TournamentCardCover',
  components: {
    TournamentCardOrganizer,
    TournamentCardSponsors,
  },
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    cover({ tournament: { cover } }) {
      return `url(${encodeURI((cover || '').replace('.original.', `.500.`))})`;
    },
  },
};
</script>

<style lang="scss">
@import '../variables';

.tournament-card_cover {
  position: relative;
  z-index: 0;
  height: $cover_height;
  margin: 0;
  background-position: top center;
  background-size: cover;
  border-radius: $hr-radius-01;

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    inset: 0;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.1) 30%,
      rgba(0, 0, 0, 0.5) 80%
    );
  }
}

.tournament-card_wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: $hr-spacing-04;
}
</style>
