import { shareLink } from '@/mixins/sharingLink';
import { shareTwitterLink } from '@/mixins/shareToTwitter';
import { shareFacebookLink } from '@/mixins/shareToFacebook';
import { mapActions, mapState, mapGetters } from 'vuex';
import metricsTracking from '@/mixins/metrics';

export const sharingMenu = {
  mixins: [shareLink, shareTwitterLink, shareFacebookLink, metricsTracking],
  computed: {
    ...mapState('my', ['account', 'accountId', 'accountType']),
    ...mapGetters(['currentTournament', 'currentTournamentStages']),
  },
  methods: {
    ...mapActions({
      openGlobalMenu: 'popoverMenu/open',
    }),
    ...mapActions(['errorNotify']),
    gtmPush(id) {
      if (this.$route.path.includes('tournament')) {
        window.dataLayer.push({
          event: 'tournament share',
          userID: this.accountId,
          tournamentId: this.currentTournament.id,
          gameName: this.currentTournament.game,
          squadSize: this.currentTournament.squad_size,
          tournamentName: this.currentTournament.title,
          tournamentFormat: this.currentTournamentStages[0].block_configuration
            .type,
          tournamentCountry: this.currentTournament.country,
          tournamentPool: this.currentTournament.prize.prize_pool,
          regForm: this.currentTournament.requires_registration_form,
          tournamentPrivate:
            this.currentTournament.registration_type === 'closed'
              ? 'true'
              : 'false',
          tournamentOrganizer: this.currentTournament.event_organizer_name,
          shareType: 'Copy to clipboard',
        });
      } else {
        window.dataLayer.push({
          event: 'post sharing',
          postId: id,
          userID: this.accountId,
          shareType: 'Copy to clipboard',
          from: this.$route.path,
        });
      }
    },
    openSharingMenu({ event, type, text, id, vanityId, skipItems = [] } = {}) {
      const items = [
        ...(typeof this.onRepost === 'function'
          ? [
              {
                id: 'repost',
                label: this.$t('_web_post-repost-label', 'Repost'),
                action: this.onRepost.bind(this),
              },
            ]
          : []),
      ];
      const isSmallerScreen = window.innerWidth < 800;

      if (isSmallerScreen && navigator.share) {
        items.unshift({
          id: 'share-navigator',
          label: this.$t('_web_share-navigator-label', 'Share'),
          action: () => {
            const linkText = this.getSharingLink({
              type,
              id,
              vanityId,
            });
            navigator
              .share({
                text: text,
                url: linkText,
              })
              .then(() => {
                if (this.$route.path.includes('tournament')) {
                  window.dataLayer.push({
                    event: 'tournament share',
                    userID: this.accountId,
                    tournamentId: this.currentTournament.id,
                    gameName: this.currentTournament.game,
                    squadSize: this.currentTournament.squad_size,
                    tournamentName: this.currentTournament.title,
                    tournamentFormat: this.currentTournamentStages[0]
                      .block_configuration.type,
                    tournamentCountry: this.currentTournament.country,
                    tournamentPool: this.currentTournament.prize.prize_pool,
                    regForm: this.currentTournament.requires_registration_form,
                    tournamentPrivate:
                      this.currentTournament.registration_type === 'closed'
                        ? 'true'
                        : 'false',
                    tournamentOrganizer: this.currentTournament
                      .event_organizer_name,
                    shareType: 'Mobile share',
                  });
                } else {
                  window.dataLayer.push({
                    event: 'post sharing',
                    postId: id,
                    userID: this.accountId,
                    shareType: 'Mobile share',
                    from: this.$route.path,
                  });
                }
              })
              .catch(error => {
                console.error('Error sharing:', error);
              });
          },
        });
      } else {
        items.unshift(
          {
            id: 'twitter-share',
            label: this.$t('_web_share-twitter-label', 'Share to Twitter'),
            action: () => {
              const linkText = this.getSharingLink({
                type,
                id,
                vanityId,
              });
              this.trackShareAction(id, type, 'twitter_share')
                .catch(this.errorNotify)
                .finally(() => {
                  if (this.$route.path.includes('tournament')) {
                    window.dataLayer.push({
                      event: 'tournament share',
                      userID: this.accountId,
                      tournamentId: this.currentTournament.id,
                      gameName: this.currentTournament.game,
                      squadSize: this.currentTournament.squad_size,
                      tournamentName: this.currentTournament.title,
                      tournamentFormat: this.currentTournamentStages[0]
                        .block_configuration.type,
                      tournamentCountry: this.currentTournament.country,
                      shareType: 'Twitter share',
                      tournamentPool: this.currentTournament.prize.prize_pool,
                      regForm: this.currentTournament
                        .requires_registration_form,
                      tournamentPrivate:
                        this.currentTournament.registration_type === 'closed'
                          ? 'true'
                          : 'false',
                      tournamentOrganizer: this.currentTournament
                        .event_organizer_name,
                    });
                  } else {
                    window.dataLayer.push({
                      event: 'post sharing',
                      postId: id,
                      userID: this.accountId,
                      shareType: 'Twitter share',
                      from: this.$route.path,
                    });
                  }
                });
              this.shareToTwitter({
                url: linkText,
                text,
              });
            },
          },
          {
            id: 'facebook-share',
            label: this.$t('_web_share-facebook-label', 'Share to Facebook'),
            action: () => {
              const linkText = this.getSharingLink({
                type,
                id,
                vanityId,
              });
              this.trackShareAction(id, type, 'fb_share')
                .catch(this.errorNotify)
                .finally(() => {
                  if (this.$route.path.includes('tournament')) {
                    window.dataLayer.push({
                      event: 'tournament share',
                      userID: this.accountId,
                      tournamentId: this.currentTournament.id,
                      gameName: this.currentTournament.game,
                      squadSize: this.currentTournament.squad_size,
                      tournamentName: this.currentTournament.title,
                      tournamentFormat: this.currentTournamentStages[0]
                        .block_configuration.type,
                      tournamentCountry: this.currentTournament.country,
                      shareType: 'Facebook share',
                      tournamentPool: this.currentTournament.prize.prize_pool,
                      regForm: this.currentTournament
                        .requires_registration_form,
                      tournamentPrivate:
                        this.currentTournament.registration_type === 'closed'
                          ? 'true'
                          : 'false',
                      tournamentOrganizer: this.currentTournament
                        .event_organizer_name,
                    });
                  } else {
                    window.dataLayer.push({
                      event: 'post sharing',
                      postId: id,
                      userID: this.accountId,
                      shareType: 'Facebook share',
                      from: this.$route.path,
                    });
                  }
                });
              this.shareToFacebook({
                url: linkText,
                text,
              });
            },
          },
          {
            id: 'copy-link',
            label: this.$t('_web_get-share-link-label', 'Copy link'),
            action: () => {
              const linkText = this.getSharingLink({
                type,
                id,
                vanityId,
              });
              if (this.$route.path.includes('tournament')) {
                window.dataLayer.push({
                  event: 'tournament share',
                  userID: this.accountId,
                  tournamentId: this.currentTournament.id,
                  gameName: this.currentTournament.game,
                  squadSize: this.currentTournament.squad_size,
                  tournamentName: this.currentTournament.title,
                  tournamentFormat: this.currentTournamentStages[0]
                    .block_configuration.type,
                  tournamentCountry: this.currentTournament.country,
                  tournamentPool: this.currentTournament.prize.prize_pool,
                  regForm: this.currentTournament.requires_registration_form,
                  tournamentPrivate:
                    this.currentTournament.registration_type === 'closed'
                      ? 'true'
                      : 'false',
                  tournamentOrganizer: this.currentTournament
                    .event_organizer_name,
                });
              } else {
                window.dataLayer.push({
                  event: 'post sharing',
                  postId: id,
                  userID: this.accountId,
                  shareType: 'Copy to clipboard',
                  from: this.$route.path,
                });
              }
              this.copyLinkToClipboard(linkText);
            },
          }
        );
      }
      this.openGlobalMenu({
        event,
        settings: {
          stopItemClickBubbling: true,
          isFixed: false,
          autoClose: true,
          targetSelector: '',
        },
        items: items.filter(el => !skipItems.includes(el.id)),
      });
    },
  },
};

export default [sharingMenu];
