<template>
  <div>
    <HighlightsBanner></HighlightsBanner>
    <MaxBannerNew />
    <div
      v-if="recomended.length || featuredTournament.length"
      class="home-hero-wrapper"
    >
      <HomepageHeroTournament
        v-if="
          (isAuthorized && recomended.length > 0 && !fetching) ||
            (featuredTournament[0] && !fetching)
        "
        :tournament="
          isAuthorized && recomended.length > 0
            ? recomended[0]
            : featuredTournament[0]
        "
        for-organizer:false
      />
      <Spinner v-else />
    </div>
    <div class="homepage-sliders-container">
      <div v-if="nextup.length > 0" class="home-slider-wrapper">
        <CardSlider
          :tournaments="nextup"
          :label-key="'_web_homepage-next-up'"
          :label-fallback="'Next up'"
          :view-all="true"
        />
      </div>
      <div v-if="trending.length > 0" class="home-slider-wrapper">
        <CardSlider
          :tournaments="trending"
          :label-key="'_web_dashboard_overview_tournaments_ongoing'"
          :label-fallback="'Ongoing'"
          :view-all="true"
        />
      </div>
    </div>
    <!-- <PlayheraMaxBanner /> -->
    <TopOrganisers v-if="heraShopAvilable" />
    <div v-if="featured[0]" class="home-featured-wrapper">
      <FeaturedHeroTournament
        v-if="featured[0]"
        :tournament="featured[0]"
        for-organizer:false
      />
    </div>
    <div class="home-slider-wrapper-finished">
      <CardSlider
        :tournaments="finished"
        :label-key="'_web_homepage-recently-finished'"
        :label-fallback="'Recently finished'"
        :view-full="true"
        class="homepage-finished-cards"
      />
    </div>
    <CreateTeamBanner v-if="isAuthorized" />
    <PlayheraShopBanner v-if="heraShopAvilable" />
    <PlaystationVouchers v-if="heraShopAvilable" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import HighlightsBanner from '@/components/homepage/HighlightsBanner';
import HomepageHeroTournament from '@/components/homepage/HomepageHeroTournament';
import CardSlider from '@/components/homepage/CardSlider.vue';
// import PlayheraMaxBanner from '@/components/homepage/PlayheraMaxBanner';
import MaxBannerNew from '@/components/homepage/MaxBannerNew';
import TopOrganisers from '@/components/homepage/TopOrganisers';
import FeaturedHeroTournament from '@/components/homepage/FeaturedHeroTournament';
import CreateTeamBanner from '@/components/homepage/CreateTeamBanner';
import PlayheraShopBanner from '@/components/homepage/PlayheraShopBanner';
import PlaystationVouchers from '@/components/homepage/PlaystationVouchers';
import Spinner from './components/Spinner.vue';
import { API } from '@/api/config';
export default {
  name: 'Homepage',
  components: {
    HighlightsBanner,
    HomepageHeroTournament,
    CardSlider,
    // PlayheraMaxBanner,
    MaxBannerNew,
    TopOrganisers,
    FeaturedHeroTournament,
    CreateTeamBanner,
    PlayheraShopBanner,
    PlaystationVouchers,
    Spinner,
  },
  data() {
    return {
      nextup: [],
      recomended: [],
      fetching: false,
      trending: [],
      finished: [],
      featured: [],
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters('ph', ['phTempActivationCode']),
    ...mapGetters(['isAuthorized', 'isTournamentPastStatus']),
    featuredTournament() {
      if (this.nextup.length > 0) {
        return this.nextup.filter(item => {
          return this.nextup.filter(item => item.pinned).length > 0
            ? item.pinned === true
            : this.nextup.filter(item => item.registration_type === 'open')
                .length > 0
            ? item.registration_type === 'open'
            : item.registration_type === 'closed';
        });
      } else {
        return this.trending.filter(item => {
          return this.trending.filter(item => item.status === 'started')
            .length > 0
            ? item.status === 'started'
            : item.status === 'registration_closed' ||
                item.status === 'qualification_completed';
        });
      }
    },
    heraShopAvilable() {
      const region = JSON.parse(localStorage.getItem('region'));
      if (region.id == 'mena') {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    if (
      (this.phTempActivationCode && this.isAuthorized) ||
      sessionStorage.getItem('activation_code')
    ) {
      this.$router.push({ name: 'billing' });
    }
    if (this.isAuthorized) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'user profile init',
        username: this.account._id,
        Identity: this.account.login,
        Name: this.account.login,
        displayName: this.account.displayName,
        gender: this.account.gender,
        DoB: this.account.birthDate,
        country: this.account.country,
        registrationDate: new Date(
          this.account.registrationDate * 1000
        ).getFullYear(),
        selectedLanguage: this.account.countryId,
        favouriteGames: this.account.games,
        friendsCount: this.account.socialMetadata.friends,
        followersCount: this.account.socialMetadata.followed_by,
      });
    }
    this.getNextup();
    this.getBottomHero();
    if (this.isAuthorized) {
      this.getRecommended();
    } else {
      this.fetching = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.clearCurrentTournament();
    next();
  },
  methods: {
    ...mapActions(['getCompetitionsAction']),
    ...mapMutations(['clearCurrentTournament']),
    async getRecommended() {
      try {
        const response = await API('/tournaments/my/recommended');
        return [
          (this.recomended = response.data.items),
          (this.fetching = false),
        ];
      } catch (error) {
        console.log(error);
        return (this.fetching = false);
      }
    },
    async getBottomHero() {
      try {
        const resp = await API(
          '/tournaments?status=started&order_by=squads_joined:desc&page_size=10'
        );
        return (this.featured = resp.data.items.sort((a, b) => {
          return b.squads_joined - a.squads_joined;
        }));
      } catch (error) {
        console.log(error);
      }
    },
    getNextup: function() {
      this.getCompetitionsAction({
        status: ['announced', 'registration_opened'],
        order_by: 'tournament_start:desc',
        page_size: 12,
      })
        .then(res => (this.nextup = res.items))
        .then(() => this.getTrending());
    },
    getTrending: function() {
      this.trending = this.getCompetitionsAction({
        status: ['started', 'registration_closed', 'qualification_completed'],
        order_by: 'tournament_start:desc',
        page_size: 12,
      })
        .then(res => (this.trending = res.items))
        .then(() => this.getFinished());
    },
    getFinished: function() {
      this.getCompetitionsAction({
        status: 'closed',
        order_by: 'tournament_start:desc',
        page_size: 4,
      }).then(res => (this.finished = res.items));
    },
  },
};
</script>

<style lang="scss" scoped>
.home-hero-wrapper {
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-sliders-container {
  padding-top: $hr-spacing-05;
  padding-bottom: $hr-spacing-11;
}

.home-slider-wrapper {
  width: 100%;
  min-height: 451.8px;
}

.home-slider-wrapper-finished {
  width: 100%;
  min-height: 499.8px;
}
.homepage-finished-cards {
  padding-top: $hr-spacing-11;
}

.home-featured-wrapper {
  width: 100%;
  min-height: 600px;
}

@media screen and (max-width: 1095px) {
  .home-hero-wrapper {
    min-height: auto;
  }
}
</style>
