<template>
  <div
    class="d-flex flex-column justify-content-between h-pl-0 chat-dialog-wrap"
    :class="{
      'mini-mode': miniMode,
      'screen-mode': !miniMode,
      'form-is-visible': formIsVisible,
    }"
  >
    <Header
      class="w-100 flex-shrink-0"
      v-bind="{ miniMode, conversationInfo, hasItems: !!items.length }"
      v-on="{ translateChat }"
    />
    <CtaAdmin v-if="isTournamentRelated" />
    <Post
      v-if="openedPost"
      :data="openedPost"
      :can-edit="false"
      class="mt-auto mb-0 w-100 overflow-hidden post-wrap"
      @closePost="closePost"
    />
    <ListLoader
      v-else
      class="d-flex align-items-stretch justify-content-center w-100 h-100 overflow-hidden"
      :class="{
        'align-self-end mt-auto mb-0': items.length,
        'align-self-center my-auto': !items.length,
      }"
      :is-loading="isLoading && !items.length"
      :is-crashed="isCrashed"
      :is-empty-list="!items.length"
      :dummy-props="{
        iconName: 'dummy/chat',
        size: miniMode ? 160 : 200,
        [miniMode ? 'subheaderText' : 'headerText']: conversationInfo
          ? $t('_web_chat-dialog_is-empty', 'No Messages with {title} ', {
              title: conversationInfo.title,
            })
          : $t(
              '_web_chat-dialog_no-selected',
              'Select a chat to start messaging'
            ),
        class: 'custom-scrollbar w-100',
      }"
      @reload="reloadDialog(conversationId)"
    >
      <div
        v-show="conversationInfo && items.length"
        slot="list"
        ref="chatDialog"
        class="w-100 h-100 custom-scrollbar chat-dialog"
        tabindex="-1"
        @wheel.passive.capture="focusIn"
      >
        <InfiniteScroll
          reverse
          :list="items"
          list-name="messages"
          :is-disabled="scrollIsDisabled || !nextPageToken"
          :in-container="true"
          class="d-flex chat-dialog-scroller"
          :activation-gap="200"
          @ScrollToBottom="getMessages"
        >
          <div
            slot-scope="{}"
            class="d-flex align-self-end flex-column-reverse w-100"
          >
            <template v-for="item in items">
              <div
                v-if="conversationInfo && accountId !== item.sender"
                :key="item.id"
                class="msg-item msg-left"
              >
                <div class="media justify-content-start msg-wrap">
                  <router-link
                    v-if="isGroupChat || !miniMode"
                    :to="`/profiles/${item.sender_name}`"
                    class="text-center h-mr-3 align-self-end"
                  >
                    <Avatar
                      :size="50"
                      :img-url="item.avatar || item.sender_avatar"
                      :type="item.group_chat ? 'clan' : 'user'"
                    />
                    <!-- <p class="text-center msg-sender-name mb-0 mt-3">
                      {{ item.sender_name || item.sender }}
                    </p> -->
                  </router-link>
                  <MsgContent
                    :item="item"
                    :is-post="isChannel"
                    class="media-body media-body-fix text-light ml-3 font-size-sm d-flex align-items-start align-self-end flex-column"
                    @openPost="openPost"
                  />
                </div>
              </div>
              <div v-else :key="item.id" class="msg-item msg-right">
                <div
                  class="media justify-content-end msg-wrap"
                  :class="{
                    'msg-muted': item.isShadowMsg,
                    'msg-flash': !!item.flashIt,
                    'msg-flash-out': !!item.fadeOut,
                  }"
                >
                  <div
                    class="d-flex align-items-center align-self-stretch mx-2 msg-options"
                    tabindex="-1"
                    @click="setDropdownOptions($event, item)"
                  >
                    <AppIcon name="dots" />
                  </div>
                  <MsgContent
                    class="media-body media-body-fix text-light font-size-sm d-flex align-items-start flex-column"
                    :item="item"
                    :is-post="isChannel"
                    @openPost="openPost"
                  />
                  <MyAvatar
                    v-if="!miniMode"
                    :size="50"
                    class="align-self-end h-ml-4 d-none d-sm-inline-block"
                  />
                </div>
              </div>
            </template>
            <!-- not work on backend
            <PopoverMenu
              :position="translatePosition"
              :closest="$refs.chatDialog"
              regard="bottom"
              :items="languages"
              auto-close
            >
              <span
                slot-scope="{ item }"
                :class="{
                  active:
                    translateMsgObject &&
                    translateMsgObject.translate &&
                    translateMsgObject.translate.language ===
                      item.key.split('-')[0],
                }"
                @click="translateOneMsg(item.key.split('-')[0])"
              >
                {{ item.nativeTitle }}
              </span>
            </PopoverMenu>
            -->
            <PopoverMenu
              :position="optionsPosition"
              :closest="$refs.chatDialog"
              :float="['right']"
              regard="bottom"
              :items="[1]"
              auto-close
            >
              <span @click="deleteMsg">{{
                $t('_web_conversation_delete', 'Delete')
              }}</span>
            </PopoverMenu>
          </div>
        </InfiniteScroll>
      </div>
    </ListLoader>
    <div
      v-if="formIsVisible"
      class="align-self-end flex-shrink-0 chat-dialog-form pt-4 p-md-4 h-pl-0"
    >
      <FormEditable
        :grid="['Attachments', 'Editable']"
        :placeholder="
          $t(
            '_web_conversation_placeholder_enter-message',
            'Enter Your message'
          )
        "
        has-drop
        :show-button="true"
        has-tags
        has-mentions
        upload-btn-icon="photo"
        @submit="sendMsg"
      />
      <!-- <button @click="sendMsg">send</button> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { uniqBy, uniqueId } from '@/utils/lodashUtils';
import { scrollToBottom } from '@/utils';
import {
  CONVERSATION_TYPE_CHANNEL,
  CONVERSATION_TYPE_GROUP,
  CONVERSATION_TYPE_CLAN,
} from '@/utils/getProps';
import isLoading from '@/mixins/isLoading';
import CtaAdmin from '@/views/conversations/correspondence/ctaAdmin.vue';
import ListLoader from '@/components/common/HeraListPreloader';
import Avatar from '@/components/images/Avatar';
import MyAvatar from '@/components/images/MyAvatar';
import AppIcon from '@/components/atoms/Icon';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import PopoverMenu from '@/components/popover/Menu';
import Post from '@/views/sliderchat/Post';
import Header from './Header';
import MsgContent from './MsgContent';
import FormEditable from '@/components/form/contentEditable/';

export default {
  name: 'ConversationCorrespondence',
  components: {
    MsgContent,
    PopoverMenu,
    InfiniteScroll,
    AppIcon,
    Avatar,
    MyAvatar,
    ListLoader,
    Header,
    FormEditable,
    Post,
    CtaAdmin,
  },
  mixins: [isLoading],
  props: {
    miniMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      conversationInfo: null,

      nextPageToken: '',
      scrollIsDisabled: false,

      chatLang: '',
      // translatePosition: null,
      // translateMsgObject: null,
      openedPost: null,
      optionsPosition: null,
      optionsMsg: null,

      listIsShifted: false,
      fadeOutAnimation: 300,
    };
  },
  computed: {
    ...mapState('my', ['accountId', 'account']),
    ...mapGetters(['activePopoverChat', 'locales']),
    ...mapGetters('adminMention', ['corelation']),
    languages() {
      return this.locales;
    },
    conversationId() {
      return this.miniMode ? this.activePopoverChat : this.$route.params.id;
    },
    formIsVisible() {
      return !!(
        this.conversationInfo &&
        this.conversationInfo.active &&
        this.conversationInfo.conversation_type !== CONVERSATION_TYPE_CHANNEL
      );
    },
    corelationType() {
      return this.conversationInfo.correlation_type;
    },
    isTournamentRelated() {
      return (
        this.conversationInfo.isTournamentRelated &&
        this.conversationInfo.conversation_type !== CONVERSATION_TYPE_CHANNEL
      );
    },
    isGroupChat() {
      return (
        this.conversationInfo.conversation_type === CONVERSATION_TYPE_GROUP ||
        this.conversationInfo.conversation_type === CONVERSATION_TYPE_CLAN
      );
    },
    isChannel() {
      return (
        this.conversationInfo.conversation_type === CONVERSATION_TYPE_CHANNEL
      );
    },
  },
  watch: {
    conversationId(id) {
      this.conversationInfo = null;
      this.openedPost = null;
      this.reloadDialog(id);
    },
  },
  created() {
    this.addMessageSubscriber(this.messagesSubscriber);
    this.addSystemSubscriber(this.systemSubscriber);
  },
  updated() {
    if (
      this.conversationInfo &&
      this.conversationInfo.correlation_type !== null
    ) {
      this.setCorelation(this.conversationInfo.isTournamentRelated);
    }
  },
  mounted() {
    this.reloadDialog(this.conversationId);
  },
  destroyed() {
    this.removeMessageSubscriber(this.messagesSubscriber);
    this.removeSystemSubscriber(this.systemSubscriber);
    this.setCorelation('');
  },
  methods: {
    ...mapActions([
      'addSystemSubscriber',
      'addMessageSubscriber',
      'removeMessageSubscriber',
      'removeSystemSubscriber',
      'fetchMessages',
      'getConversationInfo',
      'sendMessage',
      'deleteMessage',
      'checkMessageAsRead',
      'checkConversationAsRead',
      'translateMsg',
      'errorNotify',
    ]),
    ...mapActions('adminMention', ['setCorelation']),
    messagesSubscriber(res) {
      console.log('CHAT: messages subscriber has new data', res);
      if (
        res.type === 'chat_message_sent' &&
        res.extended.conversationId === this.conversationId
      ) {
        this.newMessageHandler(res.extended);
      }
    },
    systemSubscriber(res) {
      console.log('CHAT: system subscriber has new data', res);
      switch (res.type) {
        case 'chat_message_translated':
          this.translateItem(res.extended);
          break;
        case 'chat_message_deleted':
          this.removeMessage(res.extended);
          break;
      }
    },
    newMessageHandler(msg) {
      console.log('CHAT: new message received for current chat', msg);

      if (!this.items.find(el => el.id === msg.messageId)) {
        this.items = [
          {
            attachments: msg.attachments,
            chat_id: msg.conversationId,
            id: msg.messageId,
            read: false,
            sender: msg.senderId,
            sender_name: msg.sender,
            sender_avatar: msg.avatar,
            sent_date: msg.sentDate,
            text: msg.text,
          },
          ...this.items,
        ];
      }

      this.conversationInfo = {
        ...this.conversationInfo,
        last_message: msg.text,
        // avatar: msg.avatar,
      };
      //before fixing render avatar
      this.checkMessageAsRead({
        chatId: msg.conversationId,
        msgId: msg.messageId,
      });
    },
    focusIn() {
      if (this.$refs.chatDialog !== document.activeElement) {
        this.$refs.chatDialog.focus();
      }
    },
    getMessages() {
      if (this.nextPageToken === null) return;

      this.scrollIsDisabled = true;
      this.fetchMessages({
        conversationId: this.conversationId,
        pageToken: this.nextPageToken,
      })
        .then(res => {
          this.nextPageToken =
            this.nextPageToken === res.next_page_token
              ? null
              : res.next_page_token;
          if (this.listIsShifted) {
            this.items = uniqBy([...this.items, ...res.items], 'id');
          } else {
            this.items = [...this.items, ...res.items];
          }

          if (this.chatLang) {
            this.translateChat();
          }

          this.scrollIsDisabled = false;
        })
        .catch(this.errorNotify);
    },
    reloadDialog(id) {
      if (id) {
        this.toggleLoading(true);
        this.toggleCrashed(false);
        this.listIsShifted = false;
        this.chatLang = '';
        this.items = [];

        this.getConversationInfo({ conversationId: id })
          .then(res => {
            this.conversationInfo = { ...res };
            return this.fetchMessages({ conversationId: id });
          })
          .then(res => {
            this.items = [...res.items];
            scrollToBottom(this.$refs.chatDialog);

            this.nextPageToken = res.next_page_token;

            if (this.items.find(msg => !msg.read)) {
              this.checkConversationAsRead(id);
            }

            this.toggleLoading(false);
          })
          .catch(err => {
            this.items = [];
            this.conversationInfo = null;
            this.toggleCrashed(true);
            this.errorNotify(err);
          })
          .finally(this.unsetLoading);
      } else {
        this.items = [];
        this.conversationInfo = null;
      }
    },
    translateChat(lang) {
      const message_ids = (this.items || [])
        .filter(
          d =>
            (!d.translate || (d.translate && d.translate.language !== lang)) &&
            this.accountId !== d.sender
        )
        .map(d => d.id);
      if (lang && message_ids.length) {
        this.translateMsg({
          conversationId: this.conversationId,
          lang: lang.toLowerCase(),
          message_ids,
        });
      } else {
        this.items.forEach(item => {
          this.$set(item, 'translate', null);
        });
      }
    },
    /* not work on backend
    translateOneMsg(lang) {
      if (lang && this.translateMsgObject) {
        this.translateMsg({
          conversationId: this.conversationId,
          lang: lang.toLowerCase(),
          message_ids: [this.translateMsgObject.id],
        });
      }
    },
    */
    translateItem(data) {
      if (data) {
        let item = this.items.find(
          d => data.conversationId === d.chat_id && data.messageId === d.id
        );
        this.$set(item, 'translate', data);
      }
    },
    generateNewMsg({ id, text, attachments, date }) {
      return {
        id,
        text,
        attachments: (attachments || []).map(attach => {
          attach[attach.isApplication ? 'pdf' : attach.type] =
            attach.displayUri;
          attach.fileOriginalName = attach.file.name;
          return attach;
        }),
        read: false,
        sender: this.accountId,
        sender_name: this.account.displayName,
        sent_date: date,
        isShadowMsg: true,
      };
    },
    sendMsg(data) {
      const id = uniqueId('templ_key_');

      return new Promise((resolve, reject) => {
        const { text, attachments } = data;

        if (!this.conversationId || (!text && !attachments)) {
          reject(
            this.$t(
              '_web_conversations_error-send-message',
              'Unable to send message'
            )
          );
        }

        this.listIsShifted = true;

        const date = Math.floor(Date.now() / 1e3);
        const msg = this.generateNewMsg({ id, text, attachments, date });

        this.items = [msg, ...this.items];
        scrollToBottom(this.$refs.chatDialog);

        resolve(msg);
      })
        .then(({ text, attachments }) => {
          return this.sendMessage({
            id: this.conversationId,
            data: {
              text,
              attachments,
            },
          });
        })
        .then(res => {
          const currentMsg = this.items.find(msg => msg.id === id);

          if (!currentMsg) {
            return new Error(
              this.$t(
                '_web_conversations_error-send-message',
                'Unable to send message'
              )
            );
          }

          Object.assign(currentMsg, {
            id: res.id,
            chat_id: res.chat_id,
            isShadowMsg: false,
            flashIt: true,
          });

          this.$root.$emit('moveActiveConversationToUp', this.conversationId);
        })
        .then(
          window.dataLayer.push({
            event: 'Chat messages',
            userID: this.accountId,
          })
        )
        .catch(this.errorNotify);
    },
    removeMessage(msg, removeItem = null) {
      if (!removeItem && this.conversationId === msg.chat && this.items) {
        removeItem = this.items.find(item => item.id === msg.id);
      }

      if (!removeItem) {
        return;
      }

      this.$set(removeItem, 'fadeOut', true);

      window.setTimeout(() => {
        const index = this.items.indexOf(removeItem);
        this.items.splice(index, 1);
      }, this.fadeOutAnimation);
    },
    deleteMsg() {
      if (this.optionsMsg) {
        this.listIsShifted = true;
        this.$set(this.optionsMsg, 'isShadowMsg', true);

        this.deleteMessage({
          chatId: this.optionsMsg.chat_id,
          msgId: this.optionsMsg.id,
        })
          .then(() => {
            this.removeMessage(null, this.optionsMsg);
          })
          .catch(this.errorNotify);
      }
    },
    setDropdownOptions(event, msgData = null) {
      const $node = event.target.closest(
        '.msg-wrap:not(.msg-muted) .msg-options'
      );

      this.optionsPosition = $node.firstElementChild.getBoundingClientRect();
      this.optionsMsg = msgData;
    },
    openPost(post) {
      this.openedPost = post;
    },
    closePost(newCommentsCount) {
      this.openedPost.comments_count += newCommentsCount;
      this.openedPost = null;
    },
    /* not work on backend
    setDropdownTranslate({ $event, msgData = null }) {
      this.translatePosition = $event.currentTarget.getBoundingClientRect();
      this.translateMsgObject = msgData;
    },
    */
  },
};
</script>

<style lang="scss">
.chat-dialog-wrap {
  .post-wrap {
    padding-top: 89px;
  }
  &.mini-mode {
    position: relative;
    height: 100%;
    .chat-dialog {
      padding: 0 0.5rem;
    }
    .chat-dialog-empty {
      height: 50%;
      max-height: 50vh;
    }
    .post-wrap {
      padding-top: 4rem;
    }
  }
  &.screen-mode {
    line-height: 1.4;
    .chat-dialog-empty {
      height: 600px;
      max-height: 50vh;
    }
    .list-loader {
      min-height: auto;
      &_wrapper {
        width: 100%;
        position: relative;
        > .container-dummy {
          min-height: auto;
        }
      }
    }
  }

  .chat-dialog-scroller {
    position: relative;
    padding-top: 5rem;
    min-height: 100%;
  }
  .form-control.form-control-editable .placeholder {
    .app-rtl & {
      left: unset !important;
    }
  }
}

.msg-wrap {
  transition: opacity 0.1s;
  @media (max-width: 768px) {
    padding: 0px;
  }
  &.msg-muted {
    opacity: 0.7;
    pointer-events: none;
  }
  &.msg-flash {
    .msg {
      animation: pulse-bounce 0.3s;
    }
    @keyframes pulse-bounce {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.02);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  &.msg-flash-out {
    .msg {
      animation: pulse-out 0.3s;
    }

    @keyframes pulse-out {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.02);
        opacity: 0;
      }
    }
  }
  .msg {
    --msg-background: #{$new-bg-base};
    --msg-background-my: #{$new-bg-primary};

    .mini-mode & {
      --msg-background: #{$new-bg-neutral-faded};
      --msg-background-my: #{$new-bg-primary};
    }

    .screen-mode & {
      width: 80%;
    }

    .navbar-is-open & {
      width: 65% !important;
      max-width: 400px;
    }

    .msg-right & {
      .screen-mode & {
        width: 68%;

        @media (max-width: 860px) {
          width: 63%;
        }

        @media (max-width: 768px) {
          width: 65%;
        }
        @media (max-width: 576px) {
          width: 60%;
        }
        @media (max-width: 350px) {
          width: 55%;
        }
      }
    }

    padding: 12px 12px 4px 12px;
    border-radius: $ph-medium-radius;
    min-height: 54px;
    position: relative;
    flex: unset;
    overflow-wrap: break-word;

    .msg-right & {
      background-color: var(--msg-background-my);
    }
    .msg-left & {
      background-color: var(--msg-background);
    }
    .msg-date {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 24px;
      height: 22px;

      .msg-left & {
        background-color: var(--msg-background);
        @include auto-rtl(
          (
            left: 0,
            border-bottom-left-radius: 6px,
            transform: skewX(-45deg),
          )
        );
      }
      .msg-right & {
        background-color: var(--msg-background-my);
        @include auto-rtl(
          (
            right: 0,
            border-bottom-right-radius: 6px,
            transform: skewX(45deg),
          )
        );
      }
    }

    .msg-translate {
      font-size: $ph-body-bold-font-size !important;
      color: $ph-secondary-text;

      font-style: italic;
      a {
        font-weight: bold;
        font-style: normal;
      }
    }
  }
  .msg-options {
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.1s;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  &:hover {
    .msg-options {
      opacity: 0.6;
    }
  }
}

.msg-item {
  &.msg-left {
    @include auto-rtl(margin-left, 0.5rem);
  }
  &.msg-right {
    @include auto-rtl(margin-right, 0.5rem);
  }
  .screen-mode & {
    margin-bottom: 1rem;
  }
  .mini-mode & {
    margin-bottom: 0.5rem;
  }
}

.chat-dialog-form {
  position: relative;
  margin-bottom: 0;
  width: 100%;

  .mini-mode & {
    border-top: 1px solid rgba($black, 0.075); // bootstrap
    margin-top: 0;

    .contenteditable:focus-within {
      box-shadow: none;
    }
  }
  .screen-mode {
    margin-top: 0.5rem;
  }
}

.msg-sender-name {
  font-size: 0.875rem;
  line-height: 0.575rem;
  color: $ph-secondary-text;
}

.media-body2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $hr-clr-border1;
}

.media-body-fix {
  border-bottom: none !important;
}

.editable {
  max-width: 80%;
  .app-rtl & {
    direction: rtl;
  }
}
.chat-body {
  @media (max-width: 550px) {
    margin-top: 5px !important;
    > div {
      padding: 0 !important;
    }
  }
}

.msg-right {
  .msg-date {
    color: rgb(211, 211, 211);
  }
}
</style>
