<template>
  <div
    class="list-loader"
    :class="{
      'content-blur': isCrashed || isLoading,
      'block-loading': !isCrashed && isLoading,
      'list-loader-shop': this.$router.history.current.name === 'shop',
    }"
  >
    <div
      v-if="!hasList"
      class="d-flex flex-column justify-content-center blur-none list-loader_wrapper"
      :class="{ 'list-loader_wrapper--backdrop': hasBackdrop }"
    >
      <div
        v-if="isCrashed"
        class="d-flex align-self-center block-loading-crashed"
      >
        <HeraFormBtn @click.native="$emit('reload')">
          {{ $t('_web_list-preloader_btn-repeat', 'Try Again') }}
        </HeraFormBtn>
      </div>
      <HeraDummyList
        v-else-if="!isLoading && isEmptyList"
        v-bind="{
          iconName: emptyListIcon,
          size: dummyProps.emptyListIconSize || emptyListIconSize,
          position: 'center',
          text: emptyListLabel,
          ...dummyProps,
        }"
      />
    </div>
    <template>
      <slot name="list" />
    </template>
  </div>
</template>

<script>
import HeraDummyList from '@/components/common/HeraDummyList';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import { prop } from '@/utils/factories';

export default {
  name: 'ListPreloader',
  components: { HeraFormBtn, HeraDummyList },
  props: {
    isLoading: prop(Boolean),
    isCrashed: prop(Boolean),
    isEmptyList: prop(Boolean),
    emptyListLabel: prop(String), // use dummyProps
    emptyListIcon: prop(String), // use dummyProps
    emptyListIconSize: prop([String, Number], 200), // use dummyProps
    dummyProps: prop(Object),
    hasBackdrop: prop(Boolean),
  },
  computed: {
    hasList() {
      return !this.isEmptyList && !this.isCrashed && !this.isLoading;
    },
  },
};
</script>

<style lang="scss">
.list-loader {
  border-radius: inherit;
  min-height: 320px;

  &_wrapper {
    @include fill-border;
    z-index: 5;
    border-radius: inherit;

    &--backdrop {
      background-color: rgba(black, 0.8);
    }
  }
}

.list-loader-shop {
  min-height: 0;
}
</style>
