var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mainContainer",staticClass:"main-container "},[_c('div',{staticClass:"p-6 close-box-wrap"},[_c('div',{staticClass:"close-box"},[_c('a',{staticClass:"btn-back",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goBack($event)}}},[_c('AppIcon',{attrs:{"name":"close","width":"16"}})],1)])]),_c('div',{staticClass:"p-20 hr-post-media-content"},[_c('div',{staticClass:"paggination-actions"},[_c('button',{staticClass:"paggination-arrow",attrs:{"disabled":_vm.currentPosition === 0},on:{"click":_vm.getPrevPost}},[_c('Icon',{attrs:{"name":"arrow-left","width":"30"}})],1),_c('button',{staticClass:"paggination-arrow",attrs:{"disabled":_vm.lastPage},on:{"click":_vm.getNextPost}},[_c('Icon',{attrs:{"name":"arrow-left","width":"30","rotate":180}})],1)]),_c('div',{staticClass:"media-container"},[(
          _vm.post[_vm.currentPosition] &&
            _vm.post[_vm.currentPosition].tournament &&
            _vm.post[_vm.currentPosition].tournament.cover
        )?_c('img',{staticClass:"hr-video-element",attrs:{"src":_vm.post[_vm.currentPosition].tournament.cover,"alt":""}}):(
          _vm.post[_vm.currentPosition] &&
            (!_vm.post[_vm.currentPosition].attachments ||
              _vm.post[_vm.currentPosition].attachments.length === 0)
        )?_c('div',[_c('Loader')],1):(
          _vm.post[_vm.currentPosition] &&
            _vm.post[_vm.currentPosition].attachments &&
            _vm.post[_vm.currentPosition].attachments[0].type === 'image'
        )?_c('img',{staticClass:"hr-video-element",attrs:{"src":_vm.post[_vm.currentPosition].attachments[0].image,"alt":""}}):(
          _vm.post[_vm.currentPosition] &&
            _vm.post[_vm.currentPosition].attachments &&
            _vm.post[_vm.currentPosition].attachments[0].type !== 'image'
        )?_c('video',{staticClass:"hr-video-element highlights-video-component",attrs:{"src":_vm.post[_vm.currentPosition].attachments[0].video,"controls":"","autoplay":"","muted":"","playsinline":""},domProps:{"muted":true}}):_vm._e(),_c('div',{staticClass:"highlights-mobile-info-bottom"},[(!_vm.$lodash.isEmpty(_vm.post[_vm.currentPosition]))?_c('HRPostMobile',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
            callback: function (isVisible) { return _vm.handleScrollIntoViewForViewsTracking(
                isVisible,
                _vm.post[_vm.currentPosition].id,
                'post'
              ); },
            intersection: {
              threshold: 0.1,
            },
          }),expression:"{\n            callback: isVisible =>\n              handleScrollIntoViewForViewsTracking(\n                isVisible,\n                post[currentPosition].id,\n                'post'\n              ),\n            intersection: {\n              threshold: 0.1,\n            },\n          }"}],attrs:{"hide-cover":_vm.post[_vm.currentPosition].tournament,"post":_vm.post[_vm.currentPosition],"type":_vm.postType[_vm.currentPosition]},on:{"dotsClick":function (event) { return _vm.openPopoverMenu(event, _vm.post[_vm.currentPosition]); },"pinned":_vm.onPostPinned}}):_vm._e()],1)])]),_c('div',{ref:"popoverClosest",staticClass:"hr-post-side-content position-relative"},[(
        !_vm.$lodash.isEmpty(_vm.post[_vm.currentPosition]) &&
          _vm.postToEdit === _vm.post[_vm.currentPosition].id
      )?_c('CreatePost',{key:_vm.post[_vm.currentPosition].key,staticClass:"mb-6",attrs:{"author-model":_vm.account,"post-model":_vm.post[_vm.currentPosition]},on:{"post-created":_vm.updatePost,"post-cancel":_vm.cancelEdit}}):_vm._e(),(!_vm.$lodash.isEmpty(_vm.post[_vm.currentPosition]) && !_vm.postToEdit)?_c('HRPost',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: function (isVisible) { return _vm.handleScrollIntoViewForViewsTracking(
            isVisible,
            _vm.post[_vm.currentPosition].id,
            'post'
          ); },
        intersection: {
          threshold: 0.1,
        },
      }),expression:"{\n        callback: isVisible =>\n          handleScrollIntoViewForViewsTracking(\n            isVisible,\n            post[currentPosition].id,\n            'post'\n          ),\n        intersection: {\n          threshold: 0.1,\n        },\n      }"}],attrs:{"hide-cover":_vm.post[_vm.currentPosition].tournament,"post":_vm.post[_vm.currentPosition],"type":_vm.postType[_vm.currentPosition]},on:{"dotsClick":function (event) { return _vm.openPopoverMenu(event, _vm.post[_vm.currentPosition]); },"pinned":_vm.onPostPinned}}):_vm._e(),_c('Popover',{attrs:{"position":_vm.popoverPosition,"closest":_vm.$refs.popoverClosest,"post":_vm.popoverPost},on:{"toggle":_vm.closePopover,"edit":_vm.editPost}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }