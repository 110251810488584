import tokens from './authTokens.js';
import settings, { INITIAL_USER_SETTINGS_STATE } from './settings.js';
import api from '@/api';
import { isTokenValid } from '@/utils';

const modules = {
  tokens,
  settings,
};
const getters = {};
const mutations = {
  logout(state) {
    state.accounts = {};
    state.settings = INITIAL_USER_SETTINGS_STATE();

    state.tokens.tempToken = {};
    localStorage.removeItem('tempToken');
    state.tokens.accessToken = {};
    localStorage.removeItem('accessToken');
    state.tokens.refreshToken = {};
    localStorage.removeItem('refreshToken');
    // Remove all saved data from sessionStorage
    sessionStorage.clear();
  },
};

const actions = {
  logout: ({ getters, commit, dispatch }) => {
    return new Promise(resolve => {
      dispatch('cancelAllRequests');
      if (isTokenValid(getters.tokenObject('accessToken'))) {
        api.auth.signOut(getters.accessToken);
      }
      commit('clearFlags');
      commit('clearPermissions');
      dispatch('clearAllTokens');
      dispatch('setDefaultState');
      resolve();
    });
  },
  setDefaultState: ({ rootState, commit }) => {
    // commit('clearToClansDefaultState')
    // commit('clearToTranslationsDefaultState')
    // commit('clearToFlagsDefaultState')
    // commit('clearToSettingsDefaultState')
    // commit('clearToRegistrationsDefaultState')
    if (rootState.notification) {
      commit('clearToNotificationsDefaultState');
    }
    if (rootState.conversation) {
      commit('clearToConversationsDefaultState');
    }
    if (rootState.counter) {
      commit('clearToCounterDefaultState');
    }
    // commit('clearToConversationsDefaultState')
    if (rootState.tournaments) {
      commit('clearToTournamentsDefaultState');
    }
    commit('my/removeAccountModel');
    commit('my/clans/deleteClans');
    commit('current/clans/deleteClans');

    // commit('clearToBattlesDefaultState')
    commit('setRefreshProcessing', false);
    commit('changeRefreshStopover');
  },
};

export default {
  modules,
  getters,
  mutations,
  actions,
};
