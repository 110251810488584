import routerConstants from '@/constants/router'; // it is router names
const {
  ACCOUNT,
  PROFILE,
  CLAN,
  CLANS,
  TOURNAMENTS,
  TOURNAMENT,
  BATTLE,
  POST,
  CONVERSATIONS,
  EVENT,
} = routerConstants;
/**
 * WARN: if extend-data use camelCase for props (ex: tournamentName, userId, leagueTitle) -
 * set that in snake_case (ex: tournament_name, user_id, league_title) ALWAYS!
 *
 * notifications data. Ex:
 * content: `foo` => `foo`
 *
 * (bar = 2),
 * content: `foo {bar}` => `foo 2`,
 *
 * renderProps: { profile: { userName: 'user_id' } }, // get `user_id` from "extends" data
 * content: `foo {:bar:User}` => `foo <router-link :to="parse-from-renderProps">User</router-link`
 *
 * renderProps: { profile: { userName: 'user_id' } }, // get `user_id` from "extends" data
 * content: `foo {:bar::userName}` => `foo <router-link :to="parse-from-renderProps">{{user_id}}</router-link`
 *
 * link: {to, params} // params props we use from extend-data. Ex: link: {name: ACCOUNT, params: {accountType: PROFILE, accountId: 'user_name'}},
 *
 * @type {Object}
 */

const linkProfile = {
    name: ACCOUNT,
    params: { accountType: PROFILE, accountId: 'user_id' },
  },
  linkClan = {
    name: ACCOUNT,
    params: { accountType: CLANS, accountId: 'clan_id' },
  },
  linkTournament = {
    name: ACCOUNT,
    params: { accountType: TOURNAMENTS, accountId: 'tournament_id' },
  },
  linkBilling = {
    name: 'billing',
  },
  linkBattle = {
    name: ACCOUNT,
    params: { accountType: BATTLE, accountId: 'battle_id' },
  },
  linkPost = { name: POST, params: { id: 'post_id', threadId: 'thread_id' } },
  linkConversation = { name: CONVERSATIONS, params: { id: 'conversation_id' } };

export const NOTIFICATIONS_DATA = {
  // tournaments
  royale_battle_start_soon: {
    // battle_start_soon
    title: 'Your battle is about to start',
    content: `Battle "{:${BATTLE}::accountId}" against {opponent_name} is about to start.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  battle_start_soon: {
    // royale_battle_start_soon
    title: 'Your battle is about to start',
    content: `Battle "{:${BATTLE}::accountId}" against {opponent_name} is about to start.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  tournament_private_invitational: {
    title: 'Invitation to private tournament',
    content: `You are invited to a private {:${TOURNAMENT}::tournamentName} tournament.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_public_invitational: {
    title: 'Tournament invitation',
    content: `You are invited to public {:${TOURNAMENT}::tournamentName} tournament.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_qualification: {
    title: 'You are in qualifiers list',
    content: `Congratulations, you become a qualifier in a tournament {:${TOURNAMENT}::tournamentName} {:${EVENT}::stageName}.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      [EVENT]: {
        stageName: 'stage_name',
      },
    },
  },
  battle_cancelled: {
    title: 'Battle Cancelled',
    content: `"{:${BATTLE}::accountId}" is cancelled. Please contact support to find out more details about the match`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  tournament_rejected: {
    title: 'Tournament is rejected',
    content: `Tournament {:${TOURNAMENT}::tournamentName} was cancelled by organiser {:${
      TOURNAMENT.extended
    }::organiserName}`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      [TOURNAMENT.extended]: {
        organiserName: 'organiser_name',
      },
    },
  },
  tournament_activation_failed: {
    title: 'Check your tournament',
    content: `Tournament {:${TOURNAMENT}::tournamentName} need your attention`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_invitational_cancelled: {
    title: 'Tournament invitation canceled',
    content: `Your invitation to {:${TOURNAMENT}::tournamentName} was cancelled. Please contact{:${
      TOURNAMENT.extended
    }::organiserName}`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      [TOURNAMENT.extended]: {
        organiserName: 'organiser_name',
      },
    },
  },
  tournament_restarted: {
    title: 'Tournament is restarted',
    content: `Congratulations! {:${TOURNAMENT}::tournamentName} is restarted!`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  battle_completed_tech_win: {
    title: 'Match is completed',
    content: `Match "{:${BATTLE}::accountId}" has been ended due to Technical Win.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  battle_completed_tech_loss: {
    title: 'Battle Completed',
    content: `Match "{:${BATTLE}::accountId}" has been ended due to Technical Loss.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  tournament_qualification_requested: {
    title: 'You did not get to qualifiers',
    content: `Sorry, this time you did not get to qualifiers for {:${TOURNAMENT}::tournamentName} {:${
      TOURNAMENT.extended
    }::stageName}.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      [TOURNAMENT.extended]: {
        stageName: 'stage_name',
      },
    },
  },
  battle_scheduled: {
    title: 'Next Battle',
    content: `Your next match within "{:${BATTLE}::eventName}" is scheduled.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: {
        eventName: 'event_name',
        accountId: 'battle_id',
      },
    },
  },
  royale_battle_scheduled: {
    title: 'Battle Scheduled',
    content: `Your next battle "{:${BATTLE}::accountId}" within "{:${TOURNAMENT}::tournamentName}" is scheduled`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      /*
      [TOURNAMENT]: {
        accountId: 'tournament_id', // undefined
        tournamentName: 'tournament_name',
      },
      */
    },
  },
  tournament_stage_check_in_started: {
    title: 'Check-in started',
    content: `Check-in request for you in a tournament {:${
      routerConstants.TOURNAMENT
    }::tournamentName}.`,
    link: linkTournament,
    renderProps: {
      [routerConstants.TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_title',
      },
    },
  },
  tournament_stage_published: {
    title: 'Draw and schedule',
    content: `Draw and schedule for {:${TOURNAMENT}::tournamentName} is ready.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_announced: {
    title: 'New tournament created',
    content: `{:${TOURNAMENT}::tournamentName} tournament for {game_name} game is created.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_finished: {
    title: 'Tournament is finished',
    content: `Tournament {:${TOURNAMENT}::tournamentName} finished!`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_finished_winner: {
    title: 'Tournament is finished',
    content: `{:${TOURNAMENT}::tournamentName} is finished. Congrats you finished in {place} place`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_registration_opened: {
    title: 'Tournament registration opened',
    content: `Registration on {:${TOURNAMENT}::tournamentName} tournament by {:${
      TOURNAMENT.extended
    }::organiserName} is opened.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      [TOURNAMENT.extended]: {
        organiserName: 'organiser_name',
      },
    },
  },
  tournament_started: {
    title: 'Tournament is started',
    content: `Tournament {:${TOURNAMENT}::tournamentName} started! Please check your matches.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_qualification_reserved: {
    title: 'You are in reserve list',
    content: `You become a reserve player in a tournament {:${TOURNAMENT}::tournamentName} {stageName}.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_published: {
    title: 'Tournament Starts Soon',
    content: `Tournament {:${TOURNAMENT}::tournamentName} starts soon! Your first battle "{:${BATTLE}::accountId}" with {:${PROFILE}::accountId} begins at {startDate}.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
      [BATTLE]: { accountId: 'battle_id' },
      [PROFILE]: { accountId: 'opponent_name' },
    },
  },
  tournament_invitational: {
    title: 'Tournament invitation',
    content: `You were invited to join {:${TOURNAMENT}::tournamentName}.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_entry_fee_success: {
    title: 'Your entry fee has been processed successfully',
    content: `The entry fee for {:${TOURNAMENT}::tournamentName} has been processed successfully.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_entry_fee_failed: {
    title: 'Your entry fee has failed',
    content: `The entry fee for {:${TOURNAMENT}::tournamentName} has failed. Please try again.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },
  tournament_entry_fee_expired: {
    title: 'Your entry fee period has expired',
    content: `The entry fee period for {:${TOURNAMENT}::tournamentName} has expired. Tournament registration is canceled.`,
    link: linkTournament,
    renderProps: {
      [TOURNAMENT]: {
        accountId: 'tournament_id',
        tournamentName: 'tournament_name',
      },
    },
  },

  // social
  profile_password_changed: {
    title: 'User password changed',
    content: `{:${PROFILE}::accountId} your password was changed. Please contact support if you haven't changed it.`,
    link: linkProfile,
    renderProps: { [PROFILE]: { accountId: 'userName' } },
  },
  friendship_requested: {
    title: 'Friendship Requested',
    content: `{:${PROFILE}::accountId} requests to become your friend.`,
    link: linkProfile,
    renderProps: { [PROFILE]: { accountId: 'user_id' } },
  },
  friendship_rejected: {
    title: 'Friendship Rejected',
    content: `{:${PROFILE}::accountId} rejected your friend request.`,
    link: linkProfile,
    renderProps: { [PROFILE]: { accountId: 'user_id' } },
  },
  friendship_accepted: {
    title: 'Friendship Accepted',
    content: `{:${PROFILE}::accountId} accepted your friend request.`,
    link: linkProfile,
    renderProps: { [PROFILE]: { accountId: 'user_id' } },
  },
  // replaced {:post:post} with post ALPH-75
  profile_mentioned: {
    title: 'Profile Mentioned',
    content: `{:${PROFILE}::accountId} mentioned you in the {:post:post}`,
    link: linkPost,
    renderProps: {
      [PROFILE]: { accountId: 'sender_name' },
      post: {
        id: 'post_id',
      },
    },
  },
  clan_mentioned: {
    title: 'Clan Mentioned',
    content: `{:${PROFILE}::accountId} mentioned your clan in the {:post:post}`,
    link: linkPost,
    renderProps: {
      [PROFILE]: { accountId: 'sender_name' },
      post: {
        id: 'post_id',
      },
    },
  },
  post_commented: {
    title: 'Post Commented',
    content: `{:${PROFILE}::accountId} left a comment to your {:post:post}`,
    link: linkPost,
    renderProps: {
      [PROFILE]: { accountId: 'display_name' },
      post: {
        id: 'post_id',
      },
    },
  },
  general_post: {
    title: 'New post',
    content: `{:${PROFILE}::accountId} published a new {:post:post}`,
    link: linkPost,
    renderProps: {
      post: {
        id: 'post_id',
      },
      [PROFILE]: { accountId: 'userName' },
    },
  },
  clan_post: {
    title: 'New post',
    content: `{:${CLAN}::clanName} published a new {:post:post}.`,
    link: linkPost,
    renderProps: {
      [CLAN]: { clanName: 'clan_name' },
      post: {
        id: 'post_id',
      },
    },
  },

  //advertisement
  shop_collection_promotion: {},

  //subscriptions

  external_subscription_activated: {
    title: 'external subscription activated',
    content: `Your external subscription is successfully activated`,
    link: linkBilling,
  },
  external_subscription_canceled: {
    title: 'external subscription canceled',
    content: `Your external subscription was canceled`,
    link: linkBilling,
    renderProps: {},
  },
  external_subscription_prolonged: {
    title: 'external subscription prolonged',
    content: `Your external subscription was successfully prolonged`,
    link: linkBilling,
    renderProps: {},
  },
  // clan
  clan_member_invited: {
    title: 'Clan Member Invited',
    content: `{:${PROFILE}::accountId} invites you to become their member of {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'display_name' },
    },
  },
  clan_member_joined: {
    title: 'Clan Member Joined',
    content: `{:${PROFILE}::accountId} has joined to the {:${CLAN}::clanName} clan.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  clan_invite_rejected: {
    title: 'Clan Invite Rejected',
    content: `{:${PROFILE}::accountId} has rejected request to join to {:${CLAN}::clanName} clan.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  clan_member_added: {
    title: 'Clan Member Added',
    content: `{:${PROFILE}::accountId} accepted your invitation to {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  clan_member_requested: {
    title: 'Clan Member Requested',
    content: `{:${PROFILE}::accountId} has requested to join {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  clan_member_removed: {
    title: 'Member Left a Clan',
    content: `{:${PROFILE}::accountId} left a {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  clan_member_updated: {
    title: 'You Assigned',
    content: `You were assigned as deputy in {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
    },
  },
  clan_validated: {
    title: 'Clan Validation',
    content: `{:${CLAN}::clanName} validation confirmed.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
    },
  },
  clan_unvalidated: {
    title: 'Clan Validation',
    content: `{:${CLAN}::clanName} validation rejected.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
    },
  },
  clan_deleted: {
    title: 'Clan Deleted',
    content: `Unfortunately, your clan "{:${CLAN}::clanName}" has been deleted.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
    },
    helper: {
      avatar: {
        isDeleted: true,
        type: CLAN,
      },
    },
  },
  clan_founder_reassigned: {
    title: 'Clan Founder Reassigned',
    content: `You have been assigned as {:${CLAN}::clanName} founder.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
    },
  },
  clan_join_request_accepted: {
    title: 'Clan join request',
    content: `{:${PROFILE}::accountId} requested to join your {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  clan_request_rejected: {
    title: 'Clan join response',
    content: `{:${PROFILE}::accountId} requested to join your {:${CLAN}::clanName}.`,
    link: linkClan,
    renderProps: {
      [CLAN]: { accountId: 'clan_id', clanName: 'clan_name' },
      [PROFILE]: { accountId: 'user_id' },
    },
  },
  member_left: {
    title: 'Chat Member Left',
    content: `{:${PROFILE}::accountId} has left from {:conversations:Group Chat}.`,
    link: linkConversation,
    renderProps: {
      [PROFILE]: { accountId: 'user_id' },
      [CONVERSATIONS]: {
        conversationTitle: 'conversation',
        id: 'conversation_id',
      },
    },
  },

  // battles
  battle_started: {
    title: 'Battle Started',
    content: `Battle "{:${BATTLE}::accountId}" has been started. Have a nice game!`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  royale_battle_completed: {
    title: 'Battle Completed',
    content: `Battle "{:${BATTLE}::accountId}" has been completed. Rank: {rank} Kills: {kills}`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  royale_battle_started: {
    title: 'Battle Started',
    content: `Battle "{:${BATTLE}::accountId}" has been started.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  battle_match_result_updated: {
    title: 'Battle Result was Updated',
    content: `Battle "{:${BATTLE}::accountId}" results was updated.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  one_player_start_battle: {
    title: 'Started a Battle',
    content: `Your opponent has started a battle "{:${BATTLE}::accountId}".`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  battle_player_finished: {
    title: 'Battle Player Finished',
    content: `{:${PROFILE}::accountId} has finished battle "{:${BATTLE}::accountId}".`,
    link: linkBattle,
    renderProps: {
      [PROFILE]: { accountId: 'player_completed' },
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  player_group_up_requested: {
    title: 'GroupUp Requested',
    content: `{:${PROFILE}::accountId} calls to play {game_name} in battle "{:${BATTLE}::accountId}".`,
    link: linkBattle,
    renderProps: {
      [PROFILE]: { accountId: 'user_id' },
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  player_group_up_league_team_requested: {
    title: 'GroupUpLeagueTeam Requested',
    content: `{:${PROFILE}::accountId} calls to play battle "{:${BATTLE}::accountId}" in League {:viewProLeague::leagueTitle}.`,
    link: linkBattle,
    renderProps: {
      [PROFILE]: { accountId: 'user_id' },
      [BATTLE]: { accountId: 'battle_id' },
      viewProLeague: {
        leagueID: 'league_id',
        leagueTitle: 'league_title',
      },
    },
  },
  battle_activated: {
    title: 'Battle Activated',
    content: `Battle "{:${BATTLE}::accountId}" has been activated. You have 5 minutes to start the battle.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  battle_completed: {
    title: 'Battle Completed',
    content: `Battle "{:${BATTLE}::accountId}" has been completed ({player_1_score} : {player_2_score}).`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  battle_should_confirm: {
    title: 'Battle Confirm',
    content: `Battle "{:${BATTLE}::accountId}" should confirm.`,
    link: linkBattle,
    renderProps: {
      [BATTLE]: { accountId: 'battle_id' },
    },
  },
  /*
   * TODO: check notification types https://isddesign.atlassian.net/browse/TH-7190
   * ClanFounderReassignedEvent
   * opponent_founded
   * battle_squad_updated
   * player_group_up_status_changed
   */
};

// check notifications:
if (process.env.NODE_ENV !== 'production') {
  const props = ['title', 'content', 'link', 'renderProps'];
  Object.entries(NOTIFICATIONS_DATA).forEach(([type, notification]) => {
    props
      .filter(prop => !notification[prop])
      .forEach(val =>
        /* eslint-disable-next-line no-console */
        console.warn(`Notification "${type}": "${val}" not defined`)
      );
  });
}
