<template>
  <WrapperFormControl
    :id="id"
    :required="isRequired"
    :view-mode="disabled"
    :error="errors.first(id)"
    :label="label"
    :tooltip="tooltip"
    :hide-label="hideLabel"
  >
    <div slot="control" class="position-relative file-upload-field">
      <input
        v-if="predefinedFileUrl.length"
        :id="id"
        ref="fileInput"
        v-validate="validationRule"
        type="file"
        :multiple="multiple"
        :value="predefinedFileUrl"
        :accept="fileAcceptString"
        :name="name"
        :data-vv-as="validateAs"
        tabindex="0"
        :disabled="disabled"
        @change="onChange($event.target.files)"
      />
      <input
        v-else
        :id="id"
        ref="fileInput"
        v-validate="validationRule"
        type="file"
        :multiple="multiple"
        :accept="fileAcceptString"
        :name="name"
        :data-vv-as="validateAs"
        tabindex="0"
        :disabled="disabled"
        @change="onChange($event.target.files)"
      />

      <label
        :for="id"
        class="mb-0 cursor-pointer d-flex flex-column justify-content-center"
        :class="{
          'is-loading': isLoading && !hideDefaultLoader,
          invalid: errors.first(id),
        }"
      >
        <slot name="placeholder">
          <TextBlock
            v-if="!isLoading"
            class="d-flex align-items-center justify-content-center "
            :variant="disabled ? 'muted' : 'primary'"
          >
            <Icon
              class="h-mr-3"
              name="add"
              width="22"
              :fill="disabled ? $scss.heraMuted : 'white'"
            />
            {{
              placeholder || $t('_web_upload-file-form_upload-btn', 'Upload')
            }}
          </TextBlock>
        </slot>
        <slot name="help" />
        <ProgressBar
          v-if="isLoading"
          :current="currentUpload"
          :max="totalUpload"
          show-value
          class="w-100 mt-auto"
        />
      </label>
    </div>
  </WrapperFormControl>
</template>

<script>
import { mapActions } from 'vuex';
import { cloneDeep } from '@/utils/lodashUtils';
import { prop } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
import Icon from '@/components/atoms/Icon';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';

import ProgressBar from '@/components/atoms/ProgressBar';
export default {
  inject: ['$validator'],
  components: {
    TextBlock,
    WrapperFormControl,
    Icon,
    ProgressBar,
  },
  props: {
    done: {
      type: Function,
      default: () => {},
    },
    outputAs: {
      type: String,
      validator: val => ['binary', 'base64'].indexOf(val) !== -1,
      default: 'base64',
    },

    name: prop(String),
    label: prop(String),
    id: prop(String),
    accept: prop(Array, () => []),
    multiple: prop(Boolean),
    validateAs: prop(String, 'File Input'),
    sizeCeil: prop(Number),
    predefinedFileUrl: prop(String),
    preupload: prop(Boolean, true),
    hideDefaultLoader: prop(Boolean, false),
    disabled: prop(Boolean),
    hideLabel: prop(Boolean),
    isRequired: prop(Boolean),
    placeholder: prop(String),
    help: prop(String),
    tooltip: prop(String),
    dropFileData: {
      type: Event,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      currentUpload: 0,
      totalUpload: 0,
    };
  },
  computed: {
    validationRule() {
      let rule = [];
      if (this.isRequired) {
        rule.push('required');
      }
      if (this.sizeCeil) {
        rule.push(`size:${this.sizeCeil}`);
      }
      if (this.accept && this.accept.length) {
        rule.push(`ext:${this.accept.join(',')}`);
      }
      return rule.join('|');
    },
    fileAcceptString() {
      return this.accept ? this.accept.map(ext => `.${ext}`).join(',') : '';
    },
    isBinary() {
      return this.outputAs === 'binary';
    },
  },
  watch: {
    dropFileData(e) {
      this.onChange(e.dataTransfer.files);
    },
  },
  beforeDestroy() {
    if (this.fileInfo) {
      URL.revokeObjectURL(this.fileInfo.objectURL);
    }
  },
  methods: {
    ...mapActions(['errorNotify', 'sendFileDirect']),
    updateFileUploadProgress(progress) {
      this.currentUpload = progress.loaded;
      this.totalUpload = progress.total;
    },
    generateOutputInfo(file) {
      return {
        name: file.name,
        type: file.type,
        rawSize: file.size,
        size: Math.round(file.size / 1000) + ' kB',
        file: file,
        objectURL: URL.createObjectURL(file),
      };
    },
    clearFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
        this.$emit('clear');
      }
    },
    onChange(e) {
      let filesInfo = [];
      const self = this;
      this.$validator
        .validate(this.name)
        .then(result =>
          result
            ? Promise.resolve(e)
            : Promise.reject(this.errors.first(this.name))
        )
        .then(files => {
          this.isLoading = true;
          let promises = [...files].map(file => {
            return new Promise(resolve => {
              let reader = new FileReader();
              if (this.isBinary) {
                reader.readAsArrayBuffer(file);
              } else {
                reader.readAsDataURL(file);
              }
              reader.onload = () => {
                resolve({
                  ...cloneDeep(this.generateOutputInfo(file)),
                  outputType: this.outputType,
                  blob: this.isBinary
                    ? new Blob([reader.result], { type: file.type })
                    : reader.result,
                });
              };
            });
          });
          return Promise.all(promises);
        })
        .then(files => {
          filesInfo = cloneDeep(files);
          this.$emit('filesSelected', filesInfo);
          const getConfig = () => ({
            onUploadProgress: function(progressEvent) {
              self.updateFileUploadProgress(progressEvent);
            },
          });
          return Promise.all(
            filesInfo.map(file =>
              this.preupload
                ? this.sendFileDirect({
                    fileData: {
                      file_name: file.name,
                      file_size: file.rawSize,
                      ...file,
                    },
                    config: getConfig(file.name),
                  })
                : Promise.resolve(file)
            )
          );
        })
        .then(preUploadsInfo => {
          let results = preUploadsInfo.map((el, i) => {
            return {
              ...el,
              ...cloneDeep(filesInfo[i]),
              display_uri: el.display_uri || el.objectURL,
            };
          });
          this.$emit('filesUploaded', results);
        })
        .catch(error => {
          this.$emit('uploadError');
          this.errorNotify(error);
        })
        .then(() => {
          this.isLoading = false;
        })
        .catch(this.errorNotify);
    },
  },
};
</script>
<style lang="scss" scoped>
.file-upload-field {
  background-color: $ph-bg;
  border-radius: $ph-large-radius;
  height: 100%;
  overflow: hidden;
  border: 1px dashed $ph-secondary-text;

  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 0.625rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold !important;
    border-radius: $ph-large-radius;

    &.invalid {
      border: 1px solid $danger;
    }
    &.is-loading {
      color: transparent !important;
      pointer-events: none;
      position: relative;

      // &:after {
      //   @include rotate-element;
      //   border: 2px solid #dbdbdb;
      //   border-radius: 290486px;
      //   border-right-color: transparent;
      //   border-top-color: transparent;
      //   content: '';
      //   display: block;
      //   height: 1rem;
      //   width: 1rem;
      //   left: calc(50% - (1rem / 2));
      //   top: calc(50% - (1rem / 2));
      //   position: absolute !important;
      // }
    }

    &.is-loading {
      &:after {
        border-color: transparent transparent #fff #fff !important;
      }
    }
  }

  &:focus {
    outline: none;

    label {
      box-shadow: inset 0 1px 1px rgba($ph-accent, 0.075),
        0 0 0 0.2rem rgba($ph-accent, 0.25) !important;
    }
  }

  &:focus-within {
    outline: none;

    label {
      box-shadow: inset 0 1px 1px rgba($ph-accent, 0.075),
        0 0 0 0.2rem rgba($ph-accent, 0.25) !important;
    }
  }

  .form-text {
    font-size: 0.75rem;
  }
}
</style>
