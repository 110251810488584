import Vue from 'vue';
import { Subject } from 'rxjs';
import { uniqueId } from '@/utils/lodashUtils';
import api from '@/api';

const HUB_KEY_PREFIX = 'hub_';

export const HUB_DEFAULT_STATE = () => {
  return {
    hubs: {},
    hubKey: uniqueId(HUB_KEY_PREFIX),
    isHubReloadRequired: false,
    notifications: new Subject(),
    messages: new Subject(),
    system: new Subject(),
    subscribers: {},
  };
};

const getters = {
  hubs: state => state.hubs,
  hubKey: state => state.hubKey,
  notifications: state => state.notificationsSubject,
};

const mutations = {
  hubNext(state, { type, data }) {
    state[type].next(data);
  },
  addSubscriber(state, { type, subscriber }) {
    if (!state.subscribers[type]) {
      state.subscribers[type] = [];
    }
    if (!state.subscribers[type].find(s => s.func === subscriber)) {
      const subscription = state[type].subscribe(subscriber);
      state.subscribers[type].push({ func: subscriber, subscription });
    }
  },
  removeSubscriber(state, { type, subscriber }) {
    const index = state.subscribers[type].findIndex(s => s.func === subscriber);
    if (index === -1) {
      return;
    }
    state.subscribers[type][index].subscription.unsubscribe();
    state.subscribers[type].splice(index, 1);
  },
  createHub(state, hub) {
    if (!state.hubs[hub]) {
      Vue.set(state.hubs, hub, {});
    }
  },
  setHubInfo(state, payload) {
    if (state.hubs[payload.hub]) {
      state.hubs[payload.hub] = { ...state.hubs[payload.hub], ...payload.info };
    }
  },
  reconnectHub(state) {
    state.hubKey = uniqueId(HUB_KEY_PREFIX);
  },
  setIsHubReloadRequired(state, payload) {
    state.isHubReloadRequired = payload;
  },
};

const actions = {
  addMessageSubscriber({ commit }, subscriber) {
    commit('addSubscriber', { type: 'messages', subscriber });
  },
  addNotificationsSubscriber({ commit }, subscriber) {
    commit('addSubscriber', { type: 'notifications', subscriber });
  },
  addSystemSubscriber({ commit }, subscriber) {
    commit('addSubscriber', { type: 'system', subscriber });
  },
  removeMessageSubscriber({ commit }, subscriber) {
    commit('removeSubscriber', { type: 'messages', subscriber });
  },
  removeNotificationsSubscriber({ commit }, subscriber) {
    commit('removeSubscriber', { type: 'notifications', subscriber });
  },
  removeSystemSubscriber({ commit }, subscriber) {
    commit('removeSubscriber', { type: 'system', subscriber });
  },
  getHubConnectionInfo({ commit, getters }, hub) {
    let token = getters.accessToken;
    return !token
      ? Promise.reject('Invalid auth token')
      : api.notifications
          .getHubConnection(hub)(token)
          .then(response => {
            commit('createHub', hub);
            return response.data;
          });
  },
  initHubConnection({ commit, dispatch }, hub) {
    console.log('HUB actions: getHubConnectionInfo');
    return dispatch('getHubConnectionInfo', hub).then(hubInfo => {
      const payload = {
        hub: hub,
        info: hubInfo,
      };
      commit('setHubInfo', payload);
      console.log('HUB actions: getHubConnectionInfo response', hubInfo);
      return payload;
    });
  },
};

export default {
  state: HUB_DEFAULT_STATE(),
  getters,
  mutations,
  actions,
};
