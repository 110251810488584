var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasButtons)?_c('div',{staticClass:"navigation-buttons d-flex justify-content-center"},[(_vm.prevStep)?_c('router-link',{class:{
      'h-mr-5':
        _vm.primaryBtn || _vm.isTournamentSetup || _vm.isTournamentSettings || _vm.nextStep,
    },attrs:{"to":{
      name: _vm.prevStep.name,
      params: Object.assign({}, _vm.routeParams, _vm.prevStep.params),
    },"replace":_vm.prevStep.replace,"event":_vm.disabled ? '' : 'click'}},[_c('HeraFormBtn',{attrs:{"disabled":_vm.disabled}},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.prevStep.key, _vm.prevStep.default))+"\n    ")])],1):_vm._e(),(_vm.primaryBtn)?_c('router-link',{class:{
      'h-mr-5': _vm.isTournamentSetup || _vm.isTournamentSettings || _vm.nextStep,
      disabled: _vm.isWaiting,
    },attrs:{"to":{
      name: _vm.primaryBtn.name,
      params: Object.assign({}, _vm.routeParams, _vm.primaryBtn.params),
    },"replace":_vm.primaryBtn.replace,"event":_vm.disabled ? '' : 'click'}},[_c('HeraFormBtn',{staticClass:"btn-primary",class:{ 'is-loading': _vm.isWaiting },attrs:{"disabled":_vm.disabled}},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.primaryBtn.key, _vm.primaryBtn.default))+"\n    ")])],1):_vm._e(),(_vm.isTournamentSetup || _vm.isTournamentSettings)?_c('HeraFormBtn',{staticClass:"btn-primary",class:{ 'h-mr-5': _vm.nextStep },attrs:{"disabled":!_vm.$lodash.get(_vm.currentTournament, 'info.game', null)},on:{"click":_vm.save}},[_vm._v("\n    "+_vm._s(_vm.$t('_web_tournaments_save-btn', 'Save'))+"\n  ")]):_vm._e(),(_vm.nextStep)?_c('router-link',{class:{
      'h-mr-5':
        _vm.primaryBtn || _vm.isTournamentSetup || _vm.isTournamentSettings || _vm.prevStep,
    },attrs:{"to":{
      name: _vm.nextStep.name,
      params: Object.assign({}, _vm.routeParams, _vm.nextStep.params),
    },"event":_vm.disabled ? '' : 'click'}},[_c('HeraFormBtn',{attrs:{"disabled":_vm.disabled}},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.nextStep.key, _vm.nextStep.default))+"\n    ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }