import api from '@/api';

export const DEFAULT_BATTLES_STATE = () => ({});

const getters = {};

const mutations = {};

const actions = {
  fetchStatisticDraft: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticDraft(accessToken, payload)
      .then(response => response.data),

  // payload = { countryId: <string>, queryParams: <object>}
  fetchStatisticRegistrations: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticRegistrations(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchStatisticTournaments: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticTournaments(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchStatisticPlatforms: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticPlatforms(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchStatisticLeagues: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticLeagues(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchStatisticPlayers: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticPlayers(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchStatisticCities: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticCities(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchStatisticGames: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticGames(payload.countryId)(accessToken, {
        params: payload.queryParams,
      })
      .then(response => response.data),
  fetchPostEventReport: ({ getters: { accessToken } }, tournamentId) =>
    api.statistic
      .getPostEventReport(tournamentId)(accessToken)
      .then(response => response.data),
  fetchStatisticTournamentPromotion: ({ getters: { accessToken } }, payload) =>
    api.statistic
      .statisticTournamentPromotion(payload)(accessToken)
      .then(response => response.data),
};

export default {
  state: DEFAULT_BATTLES_STATE(),
  getters,
  mutations,
  actions,
};
