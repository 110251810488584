<template functional>
  <div
    :class="[
      data.staticClass,
      { 'editable-counter pb-1 h-pr-2 ': props.isEditable },
    ]"
  >
    <span
      :class="
        !props.isColored
          ? null
          : !props.currentInput
          ? 'text-secondary'
          : props.currentInput.length > props.maxLength
          ? 'text-danger'
          : props.currentInput.length === props.maxLength
          ? 'text-success'
          : 'text-warning'
      "
      >{{ props.currentInput.length }}</span
    >
    <template v-if="props.maxLength">
      <span class="text-secondary mx-2">/</span>{{ props.maxLength }}
    </template>
  </div>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';

export default {
  name: 'Counter',
  props: {
    currentInput: propRequired(String),
    maxLength: prop(Number),
    isEditable: prop(Boolean),
    isColored: prop(Boolean),
  },
};
</script>

<style lang="scss">
.editable-counter {
  font-size: $ph-body-font-size;
  position: absolute;
  z-index: 28;
  @include auto-rtl(right);
  top: 100%;
  transform: translateY(-100%);
  opacity: 0;
  .contenteditable:focus-within & {
    opacity: 1;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="Counter"
  knobs="{
    currentInput: {
      default: text('currentInput', '0')
    },
    maxLength: {
      default: number('maxLength', 100)
    },
    isEditable: {
      default: boolean('disabled', false)
    }
  }"
>
  <Counter v-bind="{ currentInput, maxLength, isEditable}"/>
</story>
