var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-nav secondary-font",class:{ collapsed: _vm.collapsed }},[_c('ul',{staticClass:"list-unstyled px-0 font-size-xs"},_vm._l((_vm.navLinks),function(ref,index){
        var name = ref.name;
        var children = ref.children;
        var params = ref.params; if ( params === void 0 ) params = {};
        var ref_meta = ref.meta;
        var icon = ref_meta.icon;
        var menuGroup = ref_meta.menuGroup;
        var disabled = ref_meta.disabled;
        var titleKey = ref_meta.titleKey; if ( titleKey === void 0 ) titleKey = ' ';
        var fallbackLabel = ref_meta.fallbackLabel; if ( fallbackLabel === void 0 ) fallbackLabel = '_web_';
        var badgeWatcher = ref_meta.badgeWatcher;
return _c('li',{key:index},[(menuGroup)?_c('ul',{staticClass:"group list-unstyled px-0 font-size-xs",class:{ expanded: _vm.expandedMenuItems.includes(menuGroup) }},[_c('li',{staticClass:"group-parent d-flex flex-nowrap text-decoration-none mb-4",on:{"click":function($event){return _vm.toggleExpand(menuGroup)}}},[_c('a',{staticClass:"d-flex flex-nowrap text-decoration-none",class:{
                'router-link-active-secondary': _vm.flattenSubMenuItems(
                  children
                ).find(function (c) { return _vm.isActiveLink(c.name); }),
              }},[_c('div',{staticClass:"text-center link-icon"},[(icon)?_c('AppIcon',{attrs:{"name":icon,"width":"20","fill":"currentColor"}}):_vm._e()],1),_c('div',{staticClass:"text-truncate h-text-left link-text",class:{ visible: !_vm.collapsed }},[_vm._v("\n                "+_vm._s(_vm.$t(titleKey, fallbackLabel))+"\n              ")]),_c('AppIcon',{staticClass:"h-mr-5",attrs:{"rotate":_vm.expandedMenuItems.includes(menuGroup) ? 0 : -180,"name":"arrow-s","width":"12","fill":"currentColor"}})],1)]),_vm._l((_vm.flattenSubMenuItems(children)),function(subItem,subItemIndex){return _c('li',{key:(index + "_" + subItemIndex)},[_c('router-link',{staticClass:"d-flex flex-nowrap text-decoration-none mb-4",class:{
                group: menuGroup,
                disabled: subItem.meta.disabled,
                'router-link-active-secondary': _vm.isActiveLink(subItem.name),
              },attrs:{"to":{ name: subItem.name, params: subItem.params }}},[_c('div',{staticClass:"text-center link-icon"},[_c('AppIcon',{attrs:{"name":subItem.meta.icon,"width":"20"}})],1),_c('div',{staticClass:"text-truncate h-text-left link-text",class:{ visible: !_vm.collapsed }},[_vm._v("\n                "+_vm._s(_vm.$t(subItem.meta.titleKey, subItem.meta.fallbackLabel))+"\n              ")]),(_vm.badgeContent({ badgeWatcher: badgeWatcher, name: subItem.name }))?_c('div',[_c('Badge',{staticClass:"mx-2"},[_vm._v("\n                  "+_vm._s(_vm.badgeContent({ badgeWatcher: badgeWatcher, name: subItem.name }))+"\n                ")])],1):_vm._e()])],1)})],2):_c('router-link',{staticClass:"d-flex flex-nowrap text-decoration-none mb-4",class:{
            group: menuGroup,
            disabled: disabled,
            'router-link-active-secondary': _vm.isActiveLink(name),
          },attrs:{"to":{ name: name, params: params }}},[_c('div',{staticClass:"text-center link-icon"},[(icon)?_c('AppIcon',{attrs:{"name":icon,"width":"20","fill":"currentColor"}}):_vm._e()],1),_c('div',{staticClass:"text-truncate h-text-left link-text",class:{ visible: !_vm.collapsed }},[_vm._v("\n            "+_vm._s(_vm.$t(titleKey, fallbackLabel))+"\n          ")]),(_vm.badgeContent({ badgeWatcher: badgeWatcher, name: name }))?_c('div',[_c('Badge',{staticClass:"mx-2"},[_vm._v("\n              "+_vm._s(_vm.badgeContent({ badgeWatcher: badgeWatcher, name: name }))+"\n            ")])],1):_vm._e()])],1)}),0),_c('ul',{staticClass:"list-unstyled px-0 font-size-xs"},[_c('li',[(_vm.marketplaceLink)?_c('a',{staticClass:"secondary-font d-flex flex-nowrap text-decoration-none mb-4",attrs:{"href":_vm.marketplaceLink,"target":"_blank"}},[_c('div',{staticClass:"text-center link-icon"},[_c('AppIcon',{attrs:{"name":'sidebar/cart',"width":"20","fill":"white"}})],1),_c('div',{staticClass:"link-text",class:{ visible: !_vm.collapsed }},[_vm._v("\n            "+_vm._s(_vm.$t('_web_left-sidebar_nav-marketplace-link', 'Marketplace'))+"\n          ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }