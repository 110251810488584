<template>
  <div
    class="scrollbars-hide horizontal-slider"
    :class="{ 'horizontal-slider--draggable': isDraggable }"
    @wheel="wheelHandler"
    @scroll="update"
    @click.capture="clickHandler"
    @mousedown.prevent="mousedownHandler"
    @dragstart.prevent
  >
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalEventListener } from '@/utils/globalEvent';
import { uniqueId } from '@/utils/lodashUtils';
import { prop } from '@/utils/factories';
const DELAY_BEFORE_DRAG = 150; //ms

export default {
  props: {
    scrollSpeed: prop(Number, 100),
    scrollByItem: prop(Boolean),
  },

  data() {
    return {
      id: uniqueId(`horizontalscroller_${this._uid}`),
      isMouseDown: false,
      isDraggable: false,
      canBeScrolled: false,
      canBeScrolledLeft: false,
      canBeScrolledRight: false,
    };
  },

  computed: {
    ...mapGetters(['isRTL']),
  },

  mounted() {
    this.update();
    globalEventListener('add', `resize.${this.id}`, this.update);
    globalEventListener('add', `mouseup.${this.id}`, this.mouseupHandler);
    globalEventListener('add', `mousemove.${this.id}`, this.mousemoveHandler);
  },

  beforeDestroy() {
    globalEventListener('remove', `resize.${this.id}`, this.update);
    globalEventListener('remove', `mouseup.${this.id}`, this.mouseupHandler);
    globalEventListener(
      'remove',
      `mousemove.${this.id}`,
      this.mousemoveHandler
    );
  },

  methods: {
    wheelHandler(e) {
      if (!this.canBeScrolled) {
        return;
      }

      const isScrollDown = e.wheelDelta < 0;

      const canBeScrolledDown = this.isRTL
        ? this.canBeScrolledLeft
        : this.canBeScrolledRight;
      const canBeScrolledUp = this.isRTL
        ? this.canBeScrolledRight
        : this.canBeScrolledLeft;
      if (isScrollDown && !canBeScrolledDown) {
        return;
      }
      if (!isScrollDown && !canBeScrolledUp) {
        return;
      }
      if (this.scrollByItem) {
        e.preventDefault();
        const scrollStep = this.scrollSpeed * (this.isRTL ? -1 : 1);
        this.$el.scrollLeft += isScrollDown ? scrollStep : -scrollStep;
      }
    },

    update() {
      this.$nextTick(() => {
        this.canBeScrolled = this.$el.offsetWidth < this.$el.scrollWidth;
        this.canBeScrolledLeft = this.$el.scrollLeft > 0;
        this.canBeScrolledRight =
          this.$el.scrollLeft < this.$el.scrollWidth - this.$el.offsetWidth;
        this.$emit('update', {
          scrollLeft: this.$el.scrollLeft,
          scrollWidth: this.$el.scrollWidth,
          offsetWidth: this.$el.offsetWidth,
          canBeScrolled: this.canBeScrolled,
          canBeScrolledLeft: this.canBeScrolledLeft,
          canBeScrolledRight: this.canBeScrolledRight,
        });
      });
    },

    setScroll(value) {
      this.$el.scrollLeft = value;
    },

    scrollLeft(value) {
      this.$el.scrollLeft -= value || this.scrollSpeed;
    },

    scrollRight(value) {
      this.$el.scrollLeft += value || this.scrollSpeed;
    },

    mousedownHandler() {
      this.isMouseDown = true;
      setTimeout(() => {
        if (this.isMouseDown) {
          this.isDraggable = true;
        }
      }, DELAY_BEFORE_DRAG);
    },

    mouseupHandler() {
      this.isMouseDown = false;
      this.isDraggable = false;
    },

    mousemoveHandler(e) {
      if (this.isDraggable) {
        this.$el.scrollLeft -= e.movementX;
      }
    },

    clickHandler(e) {
      if (this.isDraggable) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss">
.horizontal-slider {
  overflow-x: scroll;
  scroll-behavior: smooth;
  user-select: none;

  &--draggable {
    scroll-behavior: unset;
    cursor: grabbing;
    * {
      cursor: grabbing;
    }
  }
}
</style>
