<template>
  <header class="header-fixed position-sticky">
    <nav id="nav" class="navbar d-flex align-items-center">
      <div :key="appId" class="navbar-wrapper">
        <div class="nav-left">
          <div class="nav-logos">
            <router-link to="/">
              <picture>
                <source
                  srcset="@/assets/images/header/LEAP2024-logosm.svg"
                  media="(max-width: 945px)"
                />
                <img
                  srcset="@/assets/images/header/LEAP2020Logo.svg"
                  alt="playhera-logo"
                  class="playhera-logo-nav"
                />
              </picture>
            </router-link>
          </div>

          <img
            v-if="omantelLogo"
            :src="omantelLogo"
            alt="prominent logo"
            class="prominent-logo"
          />

          <img
            v-else-if="account && account.country === 'Oman'"
            src="https://static-mena.playhera.com/public-images/img/OMANTEL_LOGO.png"
            alt="prominent logo"
            class="prominent-logo"
          />

          <div class="navigation-buttons">
            <router-link
              v-if="isAuthorized"
              to="/"
              exact-active-class="active"
              class="btn-router-link"
            >
              <img
                src="@/assets/images/new-icons/Vector FORYOU.svg"
                alt="foryou-logo"
                class="forYou-logo"
              />
              <button class="header-btn">
                {{ $t('_web_for_you_page', 'For You') }}
              </button>
            </router-link>
            <router-link
              to="/feed"
              active-class="active"
              class="btn-router-link"
            >
              <img
                src="@/assets/images/new-icons/VectorFeed.svg"
                alt="highlights-logo"
                class="forYou-logo"
              />
              <button class="header-btn">
                {{ $t('_web_newsfeed_title', 'Newsfeed') }}
              </button>
            </router-link>
            <!--
            <router-link
              v-if="!phMaxLink"
              :to="{ name: 'subscriptionAnnouncement' }"
              active-class="active"
              class="max-btn"
            >
              <img
                src="@/assets/images/maxIcon.svg"
                alt="max-logo"
                class="forYou-logo"
              />
              <button class="header-btn">
                {{ $t('_web_max_title', 'PLAYHERA Max') }}
              </button>
            </router-link>
            -->
            <a
              v-if="
                isAuthorized &&
                  currentRegion &&
                  currentRegion.feature_flags.phmax_url !== null &&
                  account &&
                  account._type === 'profile'
              "
              active-class="active"
              class="max-btn"
              :class="{ active: $route.path.includes('subscription') }"
              @click="redirectToPhMax"
            >
              <img
                src="@/assets/images/new-icons/VectorPHMAX.svg"
                alt="max-logo"
                class="forYou-logo"
              />
              <!-- <img
                class="beta-logo-mobile-nav"
                src="@/assets/images/header/LEAP2024-beta.svg"
                alt="ph max logo"
              /> -->
              <button class="header-btn">
                {{ $t('_web_max_title', 'PLAYHERA Max') }}
              </button>
            </a>
            <router-link
              v-else-if="
                (currentRegion &&
                  currentRegion.feature_flags.phmax_url &&
                  account &&
                  account._type === 'profile') ||
                  (!account &&
                    currentRegion &&
                    currentRegion.feature_flags.phmax_url)
              "
              :to="{ name: 'subscriptionSetup' }"
              active-class="active"
              class="max-btn"
            >
              <img
                src="@/assets/images/new-icons/VectorPHMAX.svg"
                alt="max-logo"
                class="forYou-logo"
              />
              <!-- <img
                class="beta-logo-mobile-nav"
                src="@/assets/images/header/LEAP2024-beta.svg"
                alt="ph max logo"
              /> -->
              <button class="header-btn">
                {{ $t('_web_max_title', 'PLAYHERA Max') }}
              </button>
            </router-link>
            <Spinner
              v-if="isLoading && isAuthorized"
              modal
              class="spinner-middle"
            />

            <!-- <a
              v-if="
                heraShopLink &&
                  ((account && account._type === 'profile') || !account)
              "
              :href="heraShopLink"
              class="max-btn2"
            >
              <img
                src="@/assets/images/shopIcon.svg"
                alt="max-logo"
                class="forYou-logo"
              />
              <button class="header-btn">
                {{ $t('_web_playhera_shop', 'Shop') }}
              </button>
            </a> -->
            <router-link
              v-if="
                currentRegion &&
                  currentRegion.feature_flags.marketplace_url &&
                  (!account || account._type === 'profile')
              "
              class="max-btn2"
              active-class="active"
              to="/shop/categories"
            >
              <!-- <img
                class="beta-logo-mobile-nav-shop"
                src="@/assets/images/header/LEAP2024-beta.svg"
                alt="ph max logo"
              /> -->
              <img
                src="@/assets/images/new-icons/VectorSHOP.svg"
                alt="max-logo"
                class="forYou-logo"
              />
              <button class="header-btn">
                {{ $t('_web_playhera_shop', 'Shop') }}
              </button>
            </router-link>
            <router-link
              class="max-btn2"
              active-class="active"
              to="/tournaments"
            >
              <!-- <img
                class="beta-logo-mobile-nav-shop"
                src="@/assets/images/header/LEAP2024-beta.svg"
                alt="ph max logo"
              /> -->
              <img
                src="@/assets/images/new-icons/VectorTournaments.svg"
                alt="max-logo"
                class="forYou-logo"
              />
              <button class="header-btn">
                {{ $t('_web_playhera_tournaments', 'Tournaments') }}
              </button>
            </router-link>
          </div>

          <div v-if="!isAuthorized" class="logout-view">
            <!-- <router-link
              v-if="!phMaxLink"
              :to="{ name: 'subscriptionAnnouncement' }"
              active-class="active"
              class="max-btn"
            >
              <button class="header-btn">
                {{ $t('_web_max_title', 'PLAYHERA Max') }}
              </button>
            </router-link> -->

            <router-link
              v-if="
                currentRegion &&
                  currentRegion.feature_flags.phmax_url &&
                  (!account || account._type === 'profile')
              "
              :to="{ name: 'subscriptionSetup' }"
              active-class="active"
              class="max-btn"
            >
              <button class="header-btn">
                {{ $t('_web_max_btn', 'Max') }}
              </button>
            </router-link>
            <Spinner
              v-if="isLoading && isAuthorized"
              modal
              class="spinner-middle"
            />
            <!-- <a
              v-if="heraShopLink"
              :href="heraShopLink"
              class="max-btn2"
              target="_blank"
            >
              <button class="header-btn">
                {{ $t('_web_playhera_shop', 'Shop') }}
              </button>
            </a> -->
            <router-link
              v-if="
                currentRegion &&
                  currentRegion.feature_flags.marketplace_url &&
                  (!account || account._type === 'profile')
              "
              class="max-btn2"
              active-class="active"
              to="/shop/categories"
            >
              <button class="header-btn">
                {{ $t('_web_playhera_shop', 'Shop') }}
              </button>
            </router-link>
            <router-link
              :to="{ name: 'feed' }"
              active-class="active"
              class="max-btn"
            >
              <button class="header-btn">
                {{ $t('_web_feed_btn', 'Feed') }}
              </button>
            </router-link>
          </div>
        </div>
        <!--<div class="nav-center"></div>-->
        <div class="nav-right">
          <div v-if="isAuthorized" class="search-and-icon">
            <HeraNavbarSearchInput
              v-if="search"
              id="sidebar-search"
              ref="sidebarSearchInput"
              v-model="searchQuery"
              class="searchinput"
              :disabled="$router.currentRoute.name === 'search'"
              class-input="main-nav-searchBar"
              :class="[
                isRTL ? 'newanimationclass' : '',
                { hidden: $route.name === 'search' },
                '',
              ]"
              @enter="searchRedirect"
            />
            <AppIcon
              name="search"
              class="searchicon"
              :width="18"
              @click="toggleSearch"
            />
          </div>
          <Notifications
            v-if="isAuthorized"
            id="notifications"
            :class="[{ disabled: $route.name === 'notifications' }, '']"
          />
          <SideChat
            v-if="isAuthorized"
            :class="[{ disabled: $route.name === 'conversations' }, '']"
          />
          <template v-if="isAuthorized && account.country === null">
            <router-link :to="{ path: `/profile-setup` }">
              <UserProfileDropdown v-if="isAuthorized" class="" />
            </router-link>
          </template>
          <UserProfileDropdown
            v-if="isAuthorized && account.country !== null"
            class=""
          />
          <template v-if="!isAuthorized">
            <router-link
              :to="{ path: `/signin?redirectTo=${$route.fullPath}` }"
            >
              <HeraFormBtn class="sign-in sign-in-desktop">
                {{ $t('_web_log_in', 'Log In') }}
              </HeraFormBtn>
            </router-link>
          </template>
          <template v-if="!isAuthorized">
            <button class="sign-in-mobile" @click.stop="togglePopover">
              <img src="@/assets/images/menu.svg" alt="menu icon" />
            </button>
          </template>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { API } from '@/api/config';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { getMetaValue } from '@/views/tournaments/utils/meta';
import AppIcon from '@/components/atoms/Icon';
// import HeraLogo from '@/components/atoms/HeraLogo.vue';
// import TitleHeader from './TitleHeader';
//import Search from './searchBar';
import Notifications from './notifications';
import UserProfileDropdown from './userProfile';
import SideChat from './SideChat';
// import ChatButton from './ChatButton';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import HeraNavbarSearchInput from '@/components/molecules/form/HeraNavbarSearchInput';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';
import { SSO_CLIENT_ID } from '@/api/config';
import Spinner from '@/views/main/components/Spinner.vue';
// import router from '@/constants/router';
// import BackBtn from '@/components/common/GoBackBtn';

export default {
  name: 'HeraNavbar',
  inject: ['$validator'],
  components: {
    AppIcon,
    // HeraLogo,
    // ChatButton,
    SideChat,
    UserProfileDropdown,
    Notifications,
    HeraNavbarSearchInput,
    // TitleHeader,
    HeraFormBtn,
    Spinner,
    // BackBtn,
    // Messages
  },
  data() {
    return {
      subscriptions: {},
      isActiveClass: 'active',
      search: false,
      userMenuItems: [
        { text: 'Profile' },
        { text: 'Settings' },
        { text: 'Log Out' },
      ],
      menuItem: {},
      searchQuery: '',
      optionsPosition: null,
      showAll: false,
      dropdown: false,
      isLoading: false,
      externalPlan: null,
    };
  },

  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['appId', 'isAuthorized', 'isRTL', 'currentRegion']),
    ...mapGetters('sidebars', ['guestOpen']),
    // ...mapGetters('ph', ['phDemoUsersData']),
    // ...mapState('ph', ['phDemoUsersData']),
    ActiveExternalPlan() {
      return this.externalPlan &&
        this.externalPlan.status &&
        this.externalPlan.status !== 'inactive'
        ? true
        : false;
    },
    routes() {
      let routes = [];
      for (let i in this.$router.options.routes) {
        if (!this.$router.options.routes.hasOwnProperty(i)) {
          continue;
        }
        let route = this.$router.options.routes[i];
        if (route.hasOwnProperty('title')) {
          routes.push(route);
        }
      }
      return routes;
    },
    isBackButtonVisible() {
      return getMetaValue(this.$route, 'backButtonInHeader');
    },
    omantelLogo() {
      const region = JSON.parse(localStorage.getItem('region'));
      return region.prominent_logo;
    },
    heraShopLink() {
      const region = JSON.parse(localStorage.getItem('region'));
      const locale = JSON.parse(localStorage.getItem('locale'));
      return region.feature_flags.marketplace_url
        ? `${region.feature_flags.marketplace_url}${locale.key}-sa/`
        : null;
    },
    // phMaxLink() {
    //   const region = JSON.parse(localStorage.getItem('region'));
    //   return this.phDemoUsersData
    //     ? 'subscription/'
    //     : region.feature_flags.phmax_url;
    // },
  },
  watch: {
    $route() {
      this.$store.dispatch('sidebars/setGuestOpen', true);
    },
  },

  mounted() {
    this.isAuthorized && this.fetchSubscriptions();
    this.isAuthorized &&
      this.fetchExternalSubscriptions().then(
        res =>
          (this.externalPlan = res.subscriptions.length
            ? res.subscriptions[res.subscriptions.length - 1]
            : null)
      ),
      this.resetSearch();
  },

  beforeRouteLeave(to, from, next) {
    this.search = false;
    next();
  },
  methods: {
    ...mapMutations(['setSearchQuery']),
    ...mapActions('sidebars', ['setGuestOpen']),
    ...mapActions(['ssoSwitch', 'fetchExternalSubscriptions']),
    ...mapActions('ph', ['setPhDemoUsersData']),
    resetSearch() {
      this.search == false;
    },
    toggleSearch() {
      this.search = !this.search;
      if (window.innerWidth < 1152) {
        this.$router.push({ name: 'search' });
      } else {
        this.$nextTick(() => {
          this.$refs.sidebarSearchInput.$el
            .querySelector('#InputField_sidebar-search')
            .focus();
        });
      }
    },
    searchRedirect(event) {
      this.setSearchQuery(event.target.value);
      this.$router.push({ name: ROUTER_NAMES_ACCOUNT.SEARCH });
      this.toggleSearch(event) == true;
      this.search = false;
    },
    togglePopover() {
      this.setGuestOpen(
        this.optionPosition !== null ? this.guestOpen : !this.guestOpen
      );
      // if (!this.isAuthorized) {
      //   this.openModal({
      //     name: 'PleaseSignIn',
      //     settings: {
      //       variant: 'fit-content',
      //     },
      //   });
      //   return;
      // }
      this.optionsPosition = this.optionsPosition ? null : this.$el;
    },
    redirectToPhMax() {
      if (
        this.subscriptions[0].status !== 'inactive' ||
        this.ActiveExternalPlan
      ) {
        this.isLoading = true;
        const ext = this.$route.query;
        this.ssoSwitch({
          accountId: SSO_CLIENT_ID,
          accountType: 'thirdparty',
          ext,
        })
          .then(resp => {
            window.location.href = resp.redirect;
          })
          .catch(err => {
            this.errorNotify(err);
          });
      } else {
        this.$router.push({
          name: 'subscriptionSetup',
          path: '/subscription',
          query: { view: true },
        });
      }
    },
    async fetchSubscriptions() {
      try {
        const response = await API('/payments/mySubscriptions/');
        const data = response.data;
        this.subscriptions = data.subscriptions.filter(sub => {
          return sub.product === 'phmax';
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-middle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
header.header-fixed {
  top: 0;
  // template в
  width: auto;
  z-index: 3;
  left: 0;
  right: 0;

  #nav {
    width: 100%;
    height: $header-height;
    background-color: $hr-bg-header;
    border-bottom: 0.5px solid $hr-gray-header-border;

    .header-container {
      padding: 0 20px;
      max-width: 1920px;

      .header-back-button {
        width: 30px;
        min-width: unset;
        margin: 0;
        @include auto-rtl(margin-right, 20px);

        &:focus {
          box-shadow: none;
        }
      }

      .nav-dropdown {
        margin: 0 1.125rem;
        position: relative;
      }

      .icon-group {
        height: 1.125rem;
        position: relative;
        top: 6px;

        svg {
          margin: 0 1.125rem;
          cursor: pointer;

          &.avatar {
            position: relative;
          }
        }
      }
    }

    .navbar__user-actions-wrapper {
      top: 0;
      bottom: 0;
      @include auto-rtl(
        (
          right: $ph-huge-space,
        )
      );

      .btn {
        background-color: var(--ph-main);
        border-radius: 200px;
        border: solid 1px;
        border-color: var(--ph-main);
        min-height: 3rem;
        min-width: 110px;

        &.sign-up {
          margin: 0 1em;
          background-color: var(--ph-bg);
        }
      }
    }
  }

  .navbar-wrapper {
    display: flex;
    align-self: stretch;
    width: 100%;
    align-items: center;
    padding: 0 2.3rem;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  .nav-left {
    margin-right: auto;
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 1.5rem;
    flex: 1 1 0%;
  }

  .navigation-buttons {
    margin-right: auto;
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 1.5rem;
    flex: 1 1 0%;
    justify-content: center;
  }

  .nav-right {
    // margin-left: auto;
    display: flex;
    // flex: 1 1 0%;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-end;
  }

  .nav-logos {
    display: flex;
    min-width: 135px;
    align-self: stretch;
    align-items: center;
    align-content: stretch;
  }

  .main-nav-searchBar {
    border: 0.5px solid #0a0d13;
    color: #0a0d13;

    input {
      background-color: black;
      border: 0.5px solid $hr-clr-header;

      color: $hr-clr-header;

      &:focus {
        text-decoration: none;
        border: 1px solid $hr-bg-accent;
        color: $ph-secondary-text;
      }
    }
  }

  .logout-view {
    width: 100%;
    display: flex;
    gap: 15px;
  }

  .sign-in-mobile {
    display: none;
    padding: 0;
  }

  @media screen and (min-width: 1401px) {
    .logout-view {
      display: none;
    }
  }

  @media screen and (max-width: 1400px) {
    .navbar,
    .navbar-wrapper {
      width: 100%;
    }
    .playhera-logo-nav {
      object-fit: contain;
      height: 18px;
    }

    .nav-logos {
      min-width: 135px;
      display: flex;
    }

    .logout-view {
      display: none;
    }
  }

  @media screen and (max-width: 1153px) {
    #InputField_sidebar-search {
      display: none;
    }
  }

  @media screen and (max-width: 945px) {
    .navbar,
    .navbar-wrapper {
      width: 100vw;
    }

    .playhera-logo-nav {
      object-fit: contain;
    }

    .nav-logos {
      min-width: 30px;
      display: flex;
    }

    .sign-in {
      padding: 7px;
      border: solid 1px white;
      border-radius: 100px;
    }
  }

  @media screen and (max-width: 850px) {
    .navbar,
    .navbar-wrapper {
      width: 100vw;
    }

    .playhera-logo-nav {
      object-fit: contain;
    }

    .nav-logos {
      min-width: 30px;
      display: flex;
    }

    .navigation-buttons,
    .searchicon {
      display: none;
    }

    .sign-in {
      padding: 7px;
      border: solid 1px white;
      border-radius: 100px;
    }

    .logout-view {
      display: flex;
    }

    .sign-in-desktop {
      display: none;
    }

    .sign-in-mobile {
      display: block;
      background: none;
      border: none;
    }
  }

  @media screen and (max-width: 800px) {
  }

  .highLights-logo {
    height: auto;
    width: auto;
    align-self: center;
    padding: 0.5rem;
  }

  .forYou-logo {
    height: auto;
    // max-height: 22px;
    width: auto;
    width: 36px;
    height: 24px;
    align-self: center;
  }

  .playhera-logo-nav {
    height: 18px;
    width: auto;
  }

  .header-btn {
    font-family: 'Exo';
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    background-color: transparent;
    border: none;
    color: $hr-text-primary;
    font-size: $hr-text-small;
    min-width: max-content;
    align-self: center;
    justify-content: space-between;
  }

  button.header-btn {
    padding: 0 !important;
  }

  .active {
    button {
      font-weight: 600 !important;
    }

    border-bottom: solid white 2px !important;
  }

  .noactive {
    border-bottom: solid $hr-bg-header 2px !important;
  }

  .btn-router-link {
    border-top: 2px solid $hr-bg-header;
    border-bottom: 2px solid $hr-bg-header;
    gap: 10px;
    display: flex;
    align-self: stretch;
    justify-content: space-evenly;
    text-decoration: none;

    &:active {
      font-weight: bold !important;
      border-bottom: solid white 2px !important;
    }
  }

  .max-btn {
    border-top: 2px solid $hr-bg-header;
    border-bottom: 2px solid $hr-bg-header;
    gap: 10px;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-decoration: none;

    &:active {
      font-weight: bold !important;
      border-bottom: solid white 2px !important;
    }
  }

  .max-btn2 {
    border-top: 2px solid $hr-bg-header;
    border-bottom: 2px solid $hr-bg-header;
    display: flex;
    gap: 10px;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-decoration: none;

    &:active {
      font-weight: bold !important;
      border-bottom: solid white 2px !important;
    }
  }

  .for-you {
    width: 66px;
  }

  .sign-in {
    border: solid 1px white;
    border-radius: 100px;
  }
}

.search-and-icon {
  padding-left: 20px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  cursor: pointer;
  @include auto-rtl(padding-right, 4px !important);
  @include auto-rtl(padding-left, 20px !important);
}

#InputField_sidebar-search {
  font-family: 'Exo' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 140% !important;
  letter-spacing: normal !important;
  width: 255px !important;
  padding-left: 10px !important;
  gap: 15px;
  @include auto-rtl(padding-left, 15px !important);
}
.searchicon {
  padding-left: 10px;
  @include auto-rtl(padding-left, 10px !important);
  @include auto-rtl(padding-right, 0px !important);
}
.shopclass {
  width: 35px !important;
}

.playhermaxclass {
  width: 120px !important;
}

.hidden {
  display: none;
}

.searchinput {
  animation: searchanimationLTR 0.5s ease 1 normal forwards;

  .app-rtl & {
    animation: searchanimationRTL 0.5s ease 1 normal forwards;
  }
}

@keyframes searchanimationLTR {
  0% {
    transform: translateX(2.5rem);
    opacity: 0;
  }

  50% {
  }

  100% {
    transform: translateX(-0rem);
    opacity: 1;
  }
}
@keyframes searchanimationRTL {
  0% {
    transform: translateX(-2.5rem);
    opacity: 0;
  }

  50% {
  }

  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

.prominent-logo {
  max-height: 30px;
  @include auto-rtl(margin-left, -30px);
}

.beta-logo-mobile-nav {
  position: absolute;
  @include auto-rtl(margin-left, -5.8rem);
  margin-bottom: -0.9rem;
}

.max-btn {
  cursor: pointer;
}

.beta-logo-mobile-nav-shop {
  position: absolute;
  // @include auto-rtl(margin-left, -1.8rem);

  .app-ltr & {
    margin-left: -1.8rem;
  }

  .app-rtl & {
    margin-left: 3.3rem;
  }
  margin-bottom: -0.9rem;
}
</style>
