<template>
  <div class="search-wrapper">
    <div class="search-page">
      <!-- <div class="vertical-tabs-container">
        <VerticalTabs
          v-if="tabs"
          class="vertical-tabs"
          :tabs="tabs"
          :active-tab="activeTab"
          use-custom
          @change="setActiveTab"
        />
      </div> -->

      <div class="search-item-container">
        <div class="search-input-container">
          <div class="input-container-search">
            <HeraSearchInput
              id="search-input"
              v-model="searchQuery"
              icon=""
              :debounce="700"
              class="search-input"
              @input="searchData"
            />
          </div>
        </div>
        <div class="horizontal-tabs-container">
          <HorizontalTabs
            v-if="tabs"
            :tabs="tabs"
            :active-tab="activeTab"
            use-custom
            @change="setActiveTab"
          />
        </div>
        <div class="search-results">
          <HeraDummyList
            v-if="
              !isLoading &&
                activeTab !== 'feeds' &&
                (!currentList && !currentList.length)
            "
            icon-name="dummy/search-no-matches"
            size="128"
          >
            <Title
              class=""
              :text="
                $t('_web_players_dummy-list', 'we couldn\'t find anything.')
              "
            />
            <div class="">
              {{ dummyText }}
            </div>
          </HeraDummyList>
          <ListPreloader v-else :is-loading="isLoading" @reload="fetchData">
            <template slot="list">
              <PopularSearch
                v-if="activeTab === 'feeds'"
                :loading="isLoading"
                :tournaments="tournamentsList"
                :teams="teamsList"
                :users="usersList"
                :posts="postsList"
                @tab="setActiveTab"
              />
              <TeamSearch
                v-if="activeTab === 'feeds'"
                :loading="isLoading"
                :tournaments="tournamentsList"
                :teams="teamsList"
                @tab="setActiveTab"
              />
              <Paginated
                v-else
                v-bind="{
                  totalItems,
                  hidePagination: !currentList || !currentList.length,
                  pageSize,
                  paginationProps: {
                    isDark: true,
                  },
                  inContainer: true,
                  isLoading,
                }"
                class=" d-flex flex-column py-5 pagination-search-route"
                @change="fetchData"
              >
                <ul
                  v-if="activeTab === 'tournaments'"
                  class="paginated-list-search-tournaments m-0 p-0"
                >
                  <TournamentCard
                    v-for="tournament in tournamentsList"
                    :key="tournament.id"
                    v-bind="{ tournament, forOrganizer: false }"
                    class="tournament-card"
                  />
                </ul>
                <ul
                  v-if="activeTab === 'players'"
                  class="paginated-list-search-players m-0 p-0"
                >
                  <SearchResultItem
                    v-for="item in currentList"
                    :key="item.id"
                    :data="item"
                  />
                </ul>
                <ul
                  v-if="activeTab === 'teams'"
                  class="paginated-list-search-teams m-0 p-0"
                >
                  <TeamSearch
                    v-for="item in teamsList"
                    :key="item.id"
                    :data="item"
                  />
                </ul>
              </Paginated>
            </template>
          </ListPreloader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import listMixin from '@/mixins/paginatedLists';
import CONSTANTS from '@/constants/accountNavMenuRoutes';
import { isEmpty } from '@/utils/lodashUtils';
import { uniqBy } from 'lodash';
import Title from '@/components/atoms/Title';
import ListPreloader from '@/components/common/HeraListPreloader';
import Paginated from '@/components/common/Paginated';
import HeraSearchInput from '@/components/molecules/form/HeraSearchInput';
import HeraDummyList from '@/components/common/HeraDummyList';
import HorizontalTabs from './HorizontalTabsSearch.vue';
import SearchResultItem from './SearchResultItem';
import TournamentCard from '@/components/common/TournamentCard';
import PopularSearch from '@/views/search/PopularSearch';
import TeamSearch from '@/views/search/TeamSearch';

const POPULAR_PAGE_SIZE = {
  TOURNAMENTS: 3,
  PLAYERS: 14,
  TEAMS: 12,
  POSTS: 8,
  DEFAULT: 27,
};

export default {
  name: 'Search',
  components: {
    PopularSearch,
    TournamentCard,
    Title,
    HeraDummyList,
    SearchResultItem,
    HeraSearchInput,
    ListPreloader,
    Paginated,
    TeamSearch,
    HorizontalTabs,
  },
  mixins: [...listMixin],
  data() {
    return {
      activeTab: CONSTANTS.FEEDS,
      tabs: [
        {
          key: CONSTANTS.FEEDS,
          labelStated: this.$t('_web_genre-select_all', 'All'),
          class: 'search-page__tab',
          list: 'all',
          labelKey: '_web_genre-select_all',
          labelFallback: 'All',
        },
        {
          key: CONSTANTS.PLAYERS,
          labelStated: this.$t('web_player_title', 'Players'),
          class: 'search-page__tab',
          list: 'usersList',
          labelKey: 'web_player_title',
          labelFallback: 'Players',
        },
        {
          key: CONSTANTS.TEAMS,
          labelStated: this.$t('_web_clans_сlans', 'Teams'),
          class: 'search-page__tab_teams',
          list: 'teamsList',
          labelKey: '_web_clans_сlans',
          labelFallback: 'Teams',
        },
        {
          key: CONSTANTS.TOURNAMENTS,
          labelStated: this.$t('_web_tournaments', 'Tournaments'),
          class: 'search-page__tab',
          list: 'tournamentsList',
          labelKey: '_web_tournaments',
          labelFallback: 'Tournaments',
        },
      ],
      searchQuery: '',
      usersList: [],
      teamsList: [],
      postsList: [],
      tournamentsList: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ basicSearchQuery: 'searchQuery' }),
    currentListName() {
      return this.tabs.find(tab => tab.key === this.activeTab).list;
    },
    currentList() {
      return this[this.currentListName];
    },
    dummyText() {
      return this.activeTab === CONSTANTS.PLAYERS
        ? this.$t(
            '_web_create-conversation_dummy-user-list',
            'We cannot find any person matching your search.'
          )
        : this.activeTab === CONSTANTS.TEAMS
        ? this.$t(
            '_web_search_dummy-clan-list',
            'We cannot find any clan matching your search.'
          )
        : null;
    },
  },
  created() {
    this.searchQuery = this.basicSearchQuery;
    this.fetchPopular();
  },
  methods: {
    ...mapActions([
      'fetchPlayers',
      'fetchClans',
      'getTournaments',
      'fetchNewsFeedsByTag',
      'errorNotify',
    ]),
    fetchPosts() {
      this.isLoading = true;
      this.fetchNewsFeedsByTag({
        page: this.pageIndex,
        tag: this.searchQuery.trim(),
      })
        .then(res => {
          this.pageIndex = res.next_page_token;
          if (!isEmpty(this.postsList) && !isEmpty(res.items)) {
            this.postsList = uniqBy(
              [...this.postsList, ...res.items],
              'post.id'
            );
          } else {
            this.postsList = res.items;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchPopular(newPage) {
      this.isLoading = true;
      this.pageIndex = newPage;

      return Promise.all([
        this.getPageData(
          'usersList',
          this.fetchPlayers(
            this.paramsWithPageNumber({
              contains: this.searchQuery.trim(),
              page_size: POPULAR_PAGE_SIZE.PLAYERS,
            })
          ),
          {
            uniqBy: 'login',
          }
        ),
        this.getPageData(
          'teamsList',
          this.fetchClans(
            this.paramsWithPageNumber({
              contains: this.searchQuery.trim(),
              page_size: POPULAR_PAGE_SIZE.TEAMS,
            })
          ),
          {
            uniqBy: 'name',
          }
        ),
        this.getPageData(
          'tournamentsList',
          this.getTournaments(
            this.paramsWithPageNumber({
              name: this.searchQuery.trim(),
              order_by: 'tournament_start',
              page_size: POPULAR_PAGE_SIZE.TOURNAMENTS,
            })
          ),
          {
            uniqBy: 'id',
          }
        ),
        this.fetchNewsFeedsByTag({
          tag: this.searchQuery.trim(),
          page_size: POPULAR_PAGE_SIZE.POSTS,
        }).then(res => {
          this.postsList = res.items;
        }),
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    fetchData(newPage) {
      this.isLoading = true;
      this.pageSize = POPULAR_PAGE_SIZE.DEFAULT;
      this.pageIndex = newPage;
      if (this.activeTab === CONSTANTS.HASHTAGS) {
        this.fetchPosts();
      } else {
        this.getPageData(
          this.currentListName,

          this.activeTab === CONSTANTS.PLAYERS
            ? this.fetchPlayers(
                this.paramsWithPageNumber({
                  contains: this.searchQuery.trim(),
                  page_size: 28,
                })
              )
            : this.activeTab === CONSTANTS.TOURNAMENTS
            ? this.getTournaments(
                this.paramsWithPageNumber({
                  name: this.searchQuery.trim(),
                  order_by: 'tournament_start',
                })
              )
            : this.fetchClans(
                this.paramsWithPageNumber({
                  contains: this.searchQuery.trim(),
                  page_size: 20,
                })
              ),
          {
            uniqBy: this.activeTab === CONSTANTS.TOURNAMENTS && 'id',
          }
        ).finally(() => {
          this.isLoading = false;
        });
      }
    },
    searchData() {
      this.usersList = null;
      this.teamsList = null;
      this.postsList = null;
      this.tournamentsList = null;
      this.pageIndex = 0;
      this.activeTab === CONSTANTS.FEEDS
        ? this.fetchPopular()
        : this.fetchData();
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.activeTab === CONSTANTS.FEEDS
        ? this.fetchPopular()
        : this.fetchData();
    },
    goBack() {
      if (window.history.length > this.historyLimit) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss">
.search-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  justify-content: center;
  display: flex;
  background-color: $new-bg-page;
}
.search-page {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 60%;

  @media (max-width: 860px) {
    flex-direction: column;
    width: 80%;
    justify-content: center;
  }

  .search-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    @media (max-width: 859px) {
      width: 100%;
      justify-content: center;
    }
  }
  .vertical-tabs-container {
    display: flex;
    flex-basis: 25%;
    justify-content: center;
    @media (max-width: 860px) {
      display: none;
    }
  }
  .horizontal-tabs-container {
    display: flex;
    justify-content: center;
    min-height: 40px;
    padding: 0;

    @media (max-width: 450px) {
      overflow-x: scroll;
      justify-content: flex-start;
    }
  }

  .horizontal-tabs {
    display: flex;
    flex-direction: row;
  }

  .search-input-container {
    padding: 2rem 0;
  }

  .input-container-search {
    border: 1px solid $new-bg-neutral;
    border-radius: 0.25rem;
    width: 100%;
    background-color: $new-bg-neutral-faded;
    font-weight: 500;

    #InputField_search-input {
      background-color: $new-bg-neutral-faded;
    }
  }
  .search-results > .list-loader {
    width: 100%;
  }
  .search-results {
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 1rem 1rem;
    width: 100%;
    .paginated-list-search-tournaments {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 0.5rem;
      width: 100%;
    }
    .paginated-list-search-teams {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
      gap: 1rem;
    }
    .paginated-list-search-players {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(112px, 1fr));
      gap: 1rem;
    }
    @media (max-width: 860px) {
      justify-content: center;
    }
  }

  .paginated- .tournament-card {
    width: 25rem;
    max-width: 360px;
    min-width: unset;
    @media (max-width: 480px) {
      max-width: 280px;
    }
  }
}
</style>
