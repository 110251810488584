import api from '@/api';

const actions = {
  handleViewTrack(
    {
      getters: { accessToken },
    },
    payload
  ) {
    return api.metrics.postViewsRecord(accessToken, payload);
  },
};

export default {
  actions,
};
