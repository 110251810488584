<template>
  <div class="auth-forgotpassword d-flex justify-content-center">
    <section class="form-wrapper d-flex">
      <form
        class="remove-autofill-styles d-flex flex-column"
        @submit.prevent="formSubmit"
        @input="formChanged"
      >
        <div class="row justify-content-center">
          <div class="form-container">
            <div class="text-container">
              <h1 class="font-size-32">
                {{ $t('_web_forgotpassword_title', 'Forgot your password?') }}
              </h1>
              <span class="font-size-16">{{
                $$t("We've sent verification code to your phone.", 'subtitle')
              }}</span>
            </div>

            <HorizontalTabs
              :tabs="$options.tabs"
              :active-tab="activeTab"
              use-custom
              @change="setActiveTab"
            />
            <div class="input-container">
              <InputPhone
                v-if="forgotByPhone"
                ref="phoneInput"
                v-model.trim="phone"
                v-validate="'required|numeric'"
                saving-key="passwordReset"
                name="phone"
                :label="$$t('Phone Number', 'phone_input_label')"
                :error="errors.first('phone')"
                :input-props="{
                  autofocus: true,
                  tabindex: 1,
                }"
              />

              <InputEmail
                v-else
                v-model.trim="email"
                v-focus
                v-validate="'required|email|max:128'"
                class=""
                name="login"
                type="email"
                :label="$$t('Email', 'email-label')"
                :tabindex="1"
                :placeholder="$t('_web_email_placeholder', 'your@email.com')"
                autofocus
                autocomplete-off
                :error="errors.first($$t('Email', 'email-label'))"
              />
            </div>
            <div class="button-container">
              <invisible-recaptcha
                class="btn-primary submit-btn mt-2 new-confirm-btn"
                :sitekey="recaptchaKey"
                :validate="() => formSubmit"
                :callback="handleRecaptchaResponse"
                :loading="isLoading"
                >{{ $$t('Reset Password', 'submit_button') }}
              </invisible-recaptcha>
              <div>
                <router-link
                  :tabindex="3"
                  :to="{ name: 'signin' }"
                  class="back-signin"
                >
                  {{ $t('web_forgotpassword_signin_label', 'Back to sign in') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { translate } from '@/mixins/translate';
import { RECAPTCHA_KEY } from '@/api/config';
import InputPhone from '@/components/form/InputPhoneNew';
import InputEmail from '@/components/form/InputEmail';

import isLoading from '@/mixins/isLoading';
import HorizontalTabs from '@/components/common/HorizontalTabsNew';
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
const TABS = {
  EMAIL: 'tab_email',
  PHONE: 'tab_phone',
};
let routerNameFrom;
export default {
  name: 'ForgotPassword',
  components: {
    HorizontalTabs,
    InputPhone,
    InputEmail,
    'invisible-recaptcha': InvisibleRecaptcha,
  },
  mixins: [isLoading, translate('_web_forgotpassword')],
  inject: ['$validator'],
  data() {
    return {
      email: null,
      recaptchaKey: RECAPTCHA_KEY,
      phone: null,
      activeTab: TABS.PHONE,
    };
  },
  computed: {
    ...mapGetters(['templateRegData']),
    forgotByPhone() {
      return this.activeTab === TABS.PHONE;
    },
  },
  beforeRouteEnter(to, { name }, next) {
    routerNameFrom = name;
    next();
  },
  beforeRouteLeave(to, from, next) {
    const phoneInput = this.$refs.phoneInput;
    if (to.name !== 'verifyCode') {
      this.saveRegData(null);
      if (phoneInput) {
        phoneInput.clearSavedPhone();
      }
    } else {
      if (phoneInput) {
        phoneInput.savePhone();
      }
      this.saveRegData({
        phone: this.forgotByPhone ? this.phone : null,
        email: this.forgotByPhone ? null : this.email,
        activeTab: this.activeTab,
        by: this.$route.name,
      });
    }
    this.unsetLoading();
    next();
  },
  created() {
    this.$options.tabs = [
      {
        key: TABS.EMAIL,
        labelStated: this.$$t('Email', 'tab_email'),
      },
      {
        key: TABS.PHONE,
        labelStated: this.$$t('Phone number', 'tab_phone'),
      },
    ];
  },
  mounted() {
    const phoneInput = this.$refs.phoneInput;
    this.closeModal(false);
    this.errors.clear();
    if (routerNameFrom !== 'verifyCode') {
      this.saveRegData(null);
      if (phoneInput) {
        phoneInput.clearSavedPhone();
      }
    } else {
      const { templateRegData } = this;
      if (templateRegData && templateRegData.by === this.$route.name) {
        if (Object.values(TABS).includes(templateRegData.activeTab)) {
          this.activeTab = templateRegData.activeTab;
          if (templateRegData.activeTab === TABS.PHONE) {
            if (phoneInput) {
              phoneInput.setSavedPhone();
            }
          } else {
            this.email = templateRegData.email || null;
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['saveRegData']),
    ...mapActions(['requestPass', 'errorNotify', 'closeModal']),
    handleRecaptchaResponse(captcha_response) {
      this.recaptchaResponse = captcha_response;
      this.formSubmit();
    },
    formSubmit() {
      if (!this.errors.count()) {
        this.setLoading();
        this.$validator
          .validateAll()
          .then(
            result =>
              result
                ? Promise.resolve(
                    this.forgotByPhone ? `+${this.phone}` : this.email
                  )
                : Promise.reject('Invalid login') // eslint-disable-line
          )
          .then(login => {
            const payload = {
              login,
              captcha_response: this.recaptchaResponse,
            };
            return this.$store.dispatch('requestPass', payload);
          })
          .then(() => {
            this.$router.push({
              name: 'verifyCode',
              params: { from: 'reset' },
            });
          })
          .catch(this.errorNotify)
          .finally(this.unsetLoading);
      }
    },

    formChanged() {
      this.errors.clear();
      this.$validator.validate('login');
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss">
.auth-forgotpassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
  .text-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  .button-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .new-confirm-btn {
    width: 100%;
    height: 2.5rem;
    background-color: $new-bg-primary !important;
    border-radius: 0.5rem;
    border: none;
    min-width: 275px !important;
  }
  .back-btn {
    width: 25%;
    min-width: 6rem;
    height: 2.5rem;
    border: solid 1px $new-bg-primary;
    background: $new-bg-page;
  }
  button.hera-btn.submit-btn {
    min-width: auto;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  .input-container {
    width: 25vw;
    min-width: 380px;
    max-width: 25rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 650px) {
      width: 60vw;
      @media (max-width: 475px) {
        width: 80vw;
        min-width: 275px;
      }
    }
  }
}
.button-recaptcha {
  background-color: var(--ph-main);
}
</style>
