<template>
  <div>
    <div class="flex-column-container">
      <button
        v-for="item in items"
        :key="item.name"
        :class="{ categoryItem: true, active: item === selectedCategory }"
        @click="selectCategoryHandle(item)"
      >
        {{ item.name }}
      </button>
    </div>
    <div class="flex-column-container-mobile">
      <div v-if="collapsed">
        <button
          v-for="item in items"
          :key="item.name + 'mobile'"
          :class="{ categoryItem: true, active: item === selectedCategory }"
          @click="selectCategoryHandle(item)"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ShopCategories',
  props: {
    collapsed: {
      type: Boolean,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    }, // eslint-disable-next-line vue/require-default-prop
    handleOpen: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      activeCategory: null,
    };
  },
  computed: {
    ...mapGetters(['selectedCategory']),
  },
  watch: {
    selectedCategory() {
      setTimeout(() => {
        this.$route.name !== 'Shop' && this.$router.push({ name: 'Shop' });
      }, 400);
    },
  },
  mounted() {
    if (this.selectCategory) {
      this.selectCategory(this.selectedCategory);
    }
  },
  methods: {
    ...mapActions(['selectCategory', 'setSearchShop']),
    selectCategoryHandle(item) {
      this.selectCategory(item);
      this.setSearchShop('');
      this.handleOpen(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-column-container {
  display: flex;
  flex-direction: column;
  // width: 251px;
}

.flex-column-container-mobile {
  display: flex;
  flex-direction: column;
}
.sample-categories {
  width: 100%;
}

@media (max-width: 850px) {
  .flex-column-container {
    display: none;
  }
  .flex-column-container-mobile {
    display: flex;
  }
  .categoryItem.active {
    width: 90% !important;
  }
}

@media (min-width: 850px) {
  .flex-column-container-mobile {
    display: none;
  }
}

.categoryItem {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  width: 100%;
  height: 40px;
  margin: 5px;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Exo';
  padding: 8px 8px 8px 12px;
}

.categoryItem.active {
  background-color: #1c1b55;
  color: #9d9cf7;
  outline: none;
  border-radius: 4px;
  width: 100%;
}
</style>
