<template>
  <transition name="fade">
    <div
      class="py-2 px-6 row no-gutters d-flex align-items-center justify-content-end pt-constrol"
    >
      <div class="col pt-footer__label">
        <TextBlock
          class="selected-count"
          :text="
            $t('_web_tournament_players_list-selected-count', 'Selected:') +
              selected
          "
        />
      </div>
      <div v-if="attributesList.length && showAttributes" class="col-3 h-ml-5">
        <CustomSelector
          v-model="selectedAttributes"
          :options="attributesList"
          clear-if-selected-all
          not-selected-by-default
          label-key="name"
          :label="
            $t('_web_tournament_players_list-assign-attributes', 'Set tags')
          "
          item-translation-key-prefix="_web_tournament_players-attribute"
          :disabled="!attributesList.length || !selected"
          dark
          @close="attributesCloseHandler"
        />
      </div>
      <div v-if="approvedToList.length && showApproved" class="col-3 h-ml-5">
        <HeraFormDropdown
          v-model="selectedApprovedTo"
          :list="approvedToList"
          :label="$t('_web_tournament_players_list-approve-to', 'Approve to')"
          :disabled="
            isStageFieldDisabled('qualify', stageInfo) ||
              !approvedToList.length ||
              !selected
          "
          variant="input-like"
          show-label
          empty-option
          :empty-text="
            $t('_web_tournament_players_list-approve-empty', 'Choose')
          "
          @input="approvedToSelectHandler"
        >
          <template #chosen="{ chosenItem }">
            {{ chosenItem.name }}
          </template>
          <template #item="{ item }">
            <TextBlock :text="item.name" />
          </template>
        </HeraFormDropdown>
      </div>
      <div v-if="blocksList.length && showBlocks" class="col-3 h-ml-5">
        <HeraFormDropdown
          v-model="selectedBlock"
          :list="blocksList"
          :label="
            $t('_web_tournament_players_list-assign-block', 'Assign to group')
          "
          :disabled="
            isStageFieldDisabled('fill_blocks', stageInfo) ||
              !blocksList.length ||
              !selected
          "
          variant="input-like"
          show-label
          empty-option
          :empty-text="
            $t('_web_tournament_players_list-assign-empty', 'Choose')
          "
          @input="assignBlockSelectHandler"
        >
          <template #chosen="{ chosenItem }">
            {{ chosenItem.name }}
          </template>
          <template #item="{ item }">
            <TextBlock :text="item.name" />
          </template>
        </HeraFormDropdown>
      </div>
    </div>
  </transition>
</template>

<script>
import TextBlock from '@/components/atoms/TextBlock';
import CustomSelector from '@/components/form/MultiSelectBox';
import HeraFormDropdown from '@/components/molecules/form/HeraFormDropdown';
import { mapGetters } from 'vuex';
export default {
  name: 'PlayersTableControls',

  components: { TextBlock, CustomSelector, HeraFormDropdown },

  props: {
    selected: {
      type: Number,
      default: 0,
    },
    approvedToList: {
      type: Array,
      default: () => [],
    },
    attributesList: {
      type: Array,
      default: () => [],
    },
    blocksList: {
      type: Array,
      default: () => [],
    },
    showAttributes: {
      type: Boolean,
      default: false,
    },
    showApproved: {
      type: Boolean,
      default: false,
    },
    showBlocks: {
      type: Boolean,
      default: false,
    },
    stageInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selectedAttributes: null,
      selectedApprovedTo: null,
      selectedBlock: null,
    };
  },
  computed: {
    ...mapGetters(['isStageFieldDisabled']),
  },
  methods: {
    attributesCloseHandler() {
      if (!this.selectedAttributes || !this.selectedAttributes.length) return;
      this.$emit('attributesSet', this.selectedAttributes);
    },
    approvedToSelectHandler(val) {
      this.$emit('approvedToSet', val.value);
    },
    assignBlockSelectHandler(val) {
      this.$emit('blockSet', val);
    },
  },
};
</script>

<style lang="scss">
.pt-constrol {
  position: sticky;
  top: $header-height + 70px;
  background-color: $ph-card;
  z-index: 1;
}
</style>
