import { BLOCK_TYPES } from '@/constants/tournaments';

export default {
  methods: {
    getMatchRoundName({ type, round, gridType, match_rank } = {}) {
      const isDE = type === BLOCK_TYPES.DOUBLE;
      return match_rank === 'grand_final'
        ? this.$t(
            '_web_tournaments_double-eliminations_grand_final',
            'Grand Final'
          )
        : isDE && gridType === 'upper' && match_rank === 'final'
        ? this.$t(
            '_web_tournaments_double-eliminations_winners-final',
            'Winners final'
          )
        : isDE && gridType === 'lower' && match_rank === 'final'
        ? this.$t(
            '_web_tournaments_double-eliminations_losers-final',
            'Losers final'
          )
        : match_rank === 'final'
        ? this.$t('_web_tournaments-tableview_final', 'Final')
        : match_rank === 'third_place'
        ? this.$t('web_tournament_third-place-match', 'Third place match')
        : match_rank === 'semi_final'
        ? this.$t('_web_tournaments-tableview_semifinal', 'Semi-final')
        : isDE && gridType === 'upper' && match_rank === 'regular'
        ? this.$t(
            '_web_tournaments_double-eliminations_upper-round',
            'Upper Round {num}',
            {
              num: round < 2 ? +round + 1 : (+round + 3) / 2,
            }
          )
        : isDE && gridType === 'lower' && match_rank === 'regular'
        ? this.$t(
            '_web_tournaments_double-eliminations_lower-round',
            'Lower Round {num}',
            {
              num: round,
            }
          )
        : this.$t(
            '_web_tournaments-tableview_round-basic-name',
            'Round {number}',
            { number: +round + 1 }
          );
    },
  },
};
