<template>
  <div
    class="slider-chat-ps-lh d-flex align-items-center justify-content-between"
    :class="{ 'slider-chat-ps-lh_active': active }"
  >
    <span class="slider-chat-ps-lh__label" @click="$emit('openToggle')">
      <Icon class="mx-1" name="arrow-s" width="10" />
      {{ label }}
    </span>
    <HeraFormBtn
      v-if="currentChatId"
      class="btn-accent-simple slider-chat-ps-lh__button"
      :disabled="loading"
      @click.native="$emit('addAll')"
    >
      {{ $t('_web_tournament_sidechat_add-all', 'Add All') }}
    </HeraFormBtn>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import { mapGetters } from 'vuex';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';

export default {
  components: { HeraFormBtn, Icon },
  props: {
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currentChatId']),
  },
};
</script>

<style lang="scss">
.slider-chat-ps-lh {
  width: 100%;
  height: 2.6rem;
  background-color: $ph-card;
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 0 0.75rem;

  svg {
    transition: all 0.3s;
  }

  &_active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid $ph-input-bg;
    svg {
      transform: rotate(180deg);
    }
  }

  &__label {
    cursor: pointer;
  }

  &__button {
    height: 1.75rem !important;
  }
}
</style>
