<template>
  <div class="img-grid my-4" :class="`img-grid-${media.length}`">
    <div v-for="(item, index) in media" :key="index" class="img-wrapper">
      <ImagePreview
        v-if="item.type === 'image'"
        class="cursor-pointer"
        :src="item.image"
        :media-group="media"
      />
      <HighlightsVideoPreview
        v-else
        class="video-preview cursor-pointer"
        :src="item.video"
        :thumbnail="thumbnail"
        :media-group="media"
      />
    </div>
  </div>
</template>

<script>
import { emptyArray } from '@/utils/factories';

import HighlightsVideoPreview from '@/components/atoms/HighlightsVideoPreview';
import ImagePreview from '@/components/common/ImagePreview';

export default {
  name: 'HighlightsMediaGrid',

  components: {
    HighlightsVideoPreview,
    ImagePreview,
  },

  props: {
    media: {
      type: Array,
      default: emptyArray,
    },
    thumbnail: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // console.log(
    //   this.media.map(item => item.type != 'image' && console.log(item.video))
    // );
  },
};
</script>

<style lang="scss" scoped>
.img-grid {
  display: flex;
  flex-direction: row;
}

.img-grid-1 {
  width: 100%;
  height: 100%;

  .img-wrapper {
    width: 100%;
    min-width: 160px;
    height: 100%;

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: $ph-small-radius;
    }

    .video-preview {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      .btn-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      .video-duration {
        font-size: 0.875rem;
        color: #b0b0b6;
        background: #000;
        height: 1.25rem;
        line-height: 1.25rem;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 0.25rem;
      }
    }
  }
}

.img-grid-2 {
  .img-wrapper {
    width: 50%;
    min-width: 160px;

    height: 100%;

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      float: right;
      padding-left: $ph-small-space;
    }
  }
}

.img-grid-3 {
  .img-wrapper {
    &:nth-child(1) {
      width: 33%;
      min-width: 160px;

      height: 100%;
      float: left;
    }

    &:nth-child(2) {
      width: 33%;
      min-width: 160px;

      height: 100%;
      float: right;
      padding-left: $ph-small-space;
    }

    &:nth-child(3) {
      width: 33%;
      min-width: 160px;

      height: 100%;
      float: right;
      padding-left: $ph-small-space;
    }
  }
}

.img-grid-4 {
  .img-wrapper {
    padding-bottom: $ph-medium-space;

    &:nth-child(2n + 1) {
      width: 25%;
      height: 100%;
    }

    &:nth-child(2n) {
      width: 25%;
      height: 100%;
      padding-left: $ph-small-space;
    }

    &:nth-child(3) {
      width: 25%;
      height: 100%;
      padding-left: $ph-small-space;
    }

    &:nth-child(4) {
      width: 25%;
      height: 100%;
      padding-bottom: 16px;
    }
  }
}

.video-player {
  width: 640px;

  video {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 870px) {
  .img-grid {
    flex-wrap: wrap;
  }
  .img-grid-4 {
    .img-wrapper {
      padding-bottom: $ph-medium-space;

      &:nth-child(2n + 1) {
        width: 25%;
        height: 100%;
      }

      &:nth-child(2n) {
        width: 25%;
        height: 100%;
        padding-left: $ph-small-space;
      }

      &:nth-child(3) {
        width: 25%;
        height: 100%;
        padding-left: $ph-small-space;
      }

      &:nth-child(4) {
        width: 25%;
        height: 100%;
        padding-bottom: 16px;
      }
    }
  }
}
.img-grid-4 {
  .img-wrapper {
    &:nth-child(2n + 1) {
      min-width: 150px;
    }

    &:nth-child(2n) {
      min-width: 150px;
    }

    &:nth-child(3) {
      min-width: 150px;
    }

    &:nth-child(4) {
      min-width: 150px;
    }
  }
}
</style>
