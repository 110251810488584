var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.statusNum === 0)?_c('div',[(_vm.hasJoined || _vm.hasClicked)?[(_vm.isSolo)?_c('div',{staticClass:"d-flex flex-column text-information"},[_c('span',{staticClass:"first-line"},[_vm._v(_vm._s(_vm.$t('_web_single_action_solo-joined', _vm.regOpen.soloJoined.firstLine)))]),_c('span',{staticClass:"second-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action_solo-joined-second',
            _vm.regOpen.soloJoined.secondLine
          )))]),_c('span',{staticClass:"app-dl"},[_vm._v(_vm._s(_vm.$t('_web_single_action_anouncement', _vm.regOpen.soloJoined.announcment)))])]):(!_vm.isSolo)?_c('div',{staticClass:"d-flex flex-column text-information"},[_c('span',{staticClass:"first-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action-joined-first',
            _vm.regOpen.notSoloJoined.firstLine
          )))]),_c('br'),_c('span',{staticClass:"second-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action-joined-second',
            _vm.regOpen.notSoloJoined.secondLine
          )))]),_c('span',{staticClass:"app-dl"},[_vm._v(_vm._s(_vm.$t('_web_single_action_anouncement', _vm.regOpen.soloJoined.announcment)))])]):_vm._e()]:[(
          _vm.myAccount._type !== 'organization' &&
            !_vm.isSolo &&
            !_vm.hasJoined &&
            _vm.toManyRevokes === '' &&
            !_vm.paymentPending &&
            (_vm.currentTournament.registration_type !== 'closed' ||
              _vm.$route.query.registration_token ||
              _vm.PlayerCompStatus.join_access === 'Invited')
        )?_c('div',{staticClass:"d-flex flex-row flex-wrap join-buttons"},[(
            _vm.currentTournament.entry_fee.required === true &&
              _vm.maxPlayers &&
              _vm.toManyRevokes === '' &&
              !_vm.paymentPending
          )?_c('div',{staticClass:"tournament-paid-entry"},[_c('span',[_vm._v(_vm._s(_vm.$t('_web_tournament_entry', 'Entry')))]),_c('div',[_c('span',{staticClass:"h-mr-2"},[_vm._v(_vm._s(_vm.currentTournament.entry_fee.currency))]),_c('span',[_vm._v(_vm._s(_vm.currentTournament.entry_fee.cost))])]),_c('span',[_vm._v(" | ")])]):_vm._e(),(_vm.maxPlayers && _vm.toManyRevokes === '' && !_vm.paymentPending)?_c('div',{staticClass:"d-flex flex-row flex-wrap join-buttons"},[_c('Button',{staticClass:"team-button p-3",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selectJoinScenario(_vm.tournament)}}},[_vm._v("\n            "+_vm._s(_vm.$t('_web_single_action_join-squad', 'Join with team'))+"\n          ")])],1):_vm._e()]):_c('div',{staticClass:"tournament-join-wrapper"},[(
            _vm.currentTournament.entry_fee.required === true &&
              _vm.maxPlayers &&
              _vm.toManyRevokes === '' &&
              !_vm.paymentPending
          )?_c('div',{staticClass:"tournament-paid-entry"},[_c('span',[_vm._v(_vm._s(_vm.$t('_web_tournament_entry', 'Entry')))]),_c('div',[_c('span',{staticClass:"h-mr-2"},[_vm._v(_vm._s(_vm.currentTournament.entry_fee.currency))]),_c('span',[_vm._v(_vm._s(_vm.currentTournament.entry_fee.cost))])]),_c('span',[_vm._v(" | ")])]):_vm._e(),(
            _vm.myAccount._type !== 'organization' &&
              _vm.isSolo &&
              _vm.maxPlayers &&
              !_vm.paymentPending &&
              _vm.toManyRevokes === '' &&
              (_vm.currentTournament.registration_type !== 'closed' ||
                _vm.$route.query.registration_token ||
                _vm.currentTournament.join_access === 'Invited')
          )?_c('Button',{staticClass:"playButton join-button-small",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selectJoinScenario(_vm.tournament)}}},[_vm._v(_vm._s(_vm.$t('_web_single_action-join-btn', _vm.regOpen.notJoined)))]):(
            _vm.myAccount._type !== 'organization' &&
              !_vm.maxPlayers &&
              !_vm.paymentPending &&
              _vm.toManyRevokes === '' &&
              (_vm.currentTournament.registration_type !== 'closed' ||
                _vm.$route.query.registration_token ||
                _vm.currentTournament.join_access === 'Invited')
          )?_c('span',{staticClass:"invite-only"},[_vm._v(_vm._s(_vm.$t(
              '_web_single_action-limit-reached',
              'Player number limit reached'
            )))]):(
            _vm.myAccount._type !== 'organization' &&
              _vm.maxPlayers &&
              _vm.paymentPending &&
              _vm.toManyRevokes === '' &&
              (_vm.currentTournament.registration_type !== 'closed' ||
                _vm.$route.query.registration_token ||
                _vm.currentTournament.join_access === 'Invited' ||
                _vm.PlayerCompStatus.registration_status === 'payment_pending')
          )?_c('Button',{staticClass:"compleat-button  join-button-small",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.pay(
              _vm.tournament,
              _vm.tournament.max_players !== 1 ? _vm.idForPayment : _vm.accountId
            )}}},[_vm._v("\n          "+_vm._s(_vm.$t('_web_single_action_pay', 'Complete payment'))+"\n        ")]):(
            _vm.maxPlayers &&
              !_vm.paymentPending &&
              _vm.toManyRevokes === '' &&
              _vm.myAccount._type !== 'organization' &&
              _vm.PlayerCompStatus.join_access !== 'Joined'
          )?_c('span',{staticClass:"invite-only"},[_vm._v(_vm._s(_vm.$t(
              '_web_single_action-invite-only',
              'This is an invite-only tournament'
            )))]):(
            _vm.myAccount._type !== 'organization' &&
              _vm.maxPlayers &&
              !_vm.paymentPending &&
              _vm.toManyRevokes === 'too_many_clan_entries' &&
              (_vm.currentTournament.registration_type !== 'closed' ||
                _vm.$route.query.registration_token ||
                _vm.currentTournament.join_access === 'Invited')
          )?_c('span',{staticClass:"invite-only"},[_vm._v(_vm._s(_vm.$t('_web_single_action-much-revokes', 'Too many revokes')))]):_vm._e()],1)]],2):_vm._e(),(_vm.statusNum === 1)?_c('div',{staticClass:"mobile-view-text"},[(!_vm.hasJoined)?[(_vm.showCountDown)?_c('span',{staticClass:"first-line"},[_vm._v(_vm._s(_vm.$t('_web_single_action-line-first-line', _vm.LineUps.NotJoined.firstLine)))]):_vm._e(),_vm._v("\n      "+_vm._s(' ')+"\n      "),(_vm.showCountDown)?_c('Countdown',{class:'counter',attrs:{"separator":':',"date":_vm.currentTournament.tournament_start}}):_vm._e(),_c('br'),_c('span',{staticClass:"second-line"},[_vm._v(_vm._s(_vm.$t(
          '_web_single_action-line-second-line',
          _vm.LineUps.NotJoined.secondLine
        )))])]:_vm._e(),(_vm.hasJoined)?[(_vm.hasQualified)?_c('div',[(_vm.showCountDown)?_c('span',{staticClass:"first-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action-line-qualified-first-line',
            _vm.LineUps.soloJoined.qualified.firstLine
          )))]):_vm._e(),_vm._v("\n        "+_vm._s(' ')+"\n        "),(_vm.showCountDown)?_c('Countdown',{class:'counter',attrs:{"separator":':',"date":_vm.currentTournament.tournament_start}}):_vm._e(),_c('br'),_c('span',{staticClass:"second-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action-line-qualified-second-line',
            _vm.LineUps.soloJoined.qualified.secondLine
          )))])],1):(_vm.hasJoineded)?_c('div',[_c('span',{staticClass:"second-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action-line-not-qualified-lineNew',
            _vm.LineUps.soloJoined.notQualifiedNew
          )))])]):_c('div',[_c('span',{staticClass:"second-line"},[_vm._v(_vm._s(_vm.$t(
            '_web_single_action-line-not-qualified-line',
            _vm.LineUps.soloJoined.notQualified
          )))])])]:_vm._e()],2):_vm._e(),(_vm.statusNum === 2)?_c('div',{staticClass:"d-flex flex-column p-4 two-button"},[(_vm.hasQualified)?[_c('div',{staticClass:"label-btn-wrap"},[_c('Button',{staticClass:"playButton",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$router.push('/my-matches')}}},[_vm._v(_vm._s(_vm.$t('_web_single_action-play', _vm.started.soloJoined.button))+"\n        ")])],1)]:[_c('span',{staticClass:"first-line"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_status_started-first-line', _vm.started.firstLine))+"\n      ")]),_c('span',{staticClass:"second-line"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_status_finished-second-line', _vm.started.secondLine))+"\n      ")])]],2):_vm._e(),(_vm.statusNum === 3)?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"winner-finished"},[_c('span',{staticClass:"first-line"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_status_finished-first-line', _vm.finished.firstLine))+"\n      ")]),_c('span',{staticClass:"second-line"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_status_finished-second-line', _vm.finished.secondLine))+"\n      ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }