<template>
  <div class="empty-layout" :class="$route.name">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Empty',
};
</script>

<style lang="scss">
.empty-layout {
  font-size: 14px;
  /* padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem; */
}

.empty-layout .text-center {
  text-align: center;
}

.empty-layout ol {
  list-style: none;
  counter-reset: li;
  padding-left: 30px;
}
.empty-layout li {
  position: relative;
  margin-bottom: 5px;
}
.empty-layout ol li::before {
  position: absolute;
  top: 5px;
  right: calc(100% + 20px);
  counter-increment: li;
  content: counters(li, '.');
  font-size: 0.9em;
}

.empty-layout h1,
.empty-layout h2 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.empty-layout p {
  line-height: 1.5;
  margin-bottom: 15px;
}

.empty-layout p b {
  font-size: 1.1em;
}
</style>
