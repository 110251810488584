import { mapGetters } from 'vuex';
import { STATUSES, STATUSES_FOR_USER } from '@/constants/tournaments';

export const tournamentStatuses = (
  isAdmin = true,
  removeCancelled = false
) => ({
  computed: {
    ...mapGetters(['hasAccessByPermission']),
    localizedTournamentsStatuses() {
      let statuses = (isAdmin ? STATUSES : STATUSES_FOR_USER).reduce(
        (res, obj) => {
          res[obj.name] = this.$t(obj.key, obj.label);
          return res;
        },
        {}
      );

      if (!this.hasAccessByPermission(this.$route.meta.permission)) {
        delete statuses.draft;
        delete statuses.archived;
      }
      if (removeCancelled) {
        delete statuses.rejected;
      }

      return statuses;
    },
    localizedTournamentsStatusesAsArray() {
      let item = this.localizedTournamentsStatuses;
      let options = [];
      options = Object.keys(item)
        .map(key => ({
          id: String(key),
          name: String(item[key]),
        }))
        .map(o => {
          switch (o.id) {
            case 'announced':
              o.order = 0;
              break;
            case 'registration_opened':
              o.order = 1;
              break;
            case 'registration_closed':
              o.order = 2;
              break;
            case 'qualification_completed':
              o.order = 3;
              break;
            case 'started':
              o.order = 4;
              break;
            case 'closed':
              o.order = 5;
              break;
          }
          return o;
        });

      return this.$lodash.orderBy(options, 'order');
    },
  },
});
