import { isEmpty } from '@/utils/lodashUtils';

export const INITIAL_MEDIAPLAYER_STATE = () => ({
  isVisible: false,
  type: '',
  url: '',
  currentTime: null,
  onHide: null,
  mediaGroup: null,
  fullScreen: false,
});

const mutations = {
  openPlayer(state, payload) {
    const { mediaGroup = [] } = payload;
    const normalizedMediaGroup = mediaGroup.map(item => ({
      type: item.type,
      url: item[item.type] || item.url || item.src || item.displayUri,
    }));
    Object.assign(state, {
      ...payload,
      isVisible: true,
      fullScreen: true,
      mediaGroup: normalizedMediaGroup,
    });
  },
  setSrc(state, payload) {
    state.url = payload;
  },
  setType(state, payload) {
    state.type = payload;
  },
  closePlayer(state) {
    Object.assign(state, INITIAL_MEDIAPLAYER_STATE());
  },

  next(state, step = 1) {
    const { mediaGroup, url } = state;

    if (isEmpty(mediaGroup) || mediaGroup.length < 2) {
      return;
    }

    const currentIndex = mediaGroup.findIndex(item => item.url === url);
    const maxIndex = mediaGroup.length - 1;
    const nextIndex = currentIndex + step;

    let nextMedia;

    if (nextIndex > maxIndex) {
      nextMedia = mediaGroup[0];
    } else if (nextIndex < 0) {
      nextMedia = mediaGroup[maxIndex];
    } else {
      nextMedia = mediaGroup[nextIndex];
    }

    Object.assign(state, { ...nextMedia, currentTime: null });
  },
  prev(state, step = 1) {
    const { mediaGroup, url } = state;

    if (isEmpty(mediaGroup) || mediaGroup.length < 2) {
      return;
    }

    const currentIndex = mediaGroup.findIndex(item => item.url === url);
    const minIndex = 0;
    const maxIndex = mediaGroup.length - 1;
    const prevIndex = currentIndex - step;

    let nextMedia;

    if (prevIndex < minIndex) {
      nextMedia = mediaGroup[maxIndex];
    } else if (prevIndex > maxIndex) {
      nextMedia = mediaGroup[minIndex];
    } else {
      nextMedia = mediaGroup[prevIndex];
    }

    Object.assign(state, { ...nextMedia, currentTime: null });
  },
};

const actions = {
  openImage({ commit }, url) {
    commit('openPlayer', { type: 'image', url });
  },
};

export default {
  namespaced: true,
  state: INITIAL_MEDIAPLAYER_STATE(),
  mutations,
  actions,
};
