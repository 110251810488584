<template>
  <div class="support-wrapper">
    <h1 class="heading-support-page">
      {{
        $t(
          `_web_support_welcome-contact-support`,
          'Welcome to Our Support Page'
        )
      }}
    </h1>
    <div style="width: 100%;">
      <h1>{{ $t(`_web_support_need-help`, 'Need Help?') }}</h1>
      <p>
        {{
          $t(
            `_web_support_need-help-text`,
            `Our dedicated support team is here to assist you with any questions, concerns, or issues you may encounter while using our platform. Whether you're experiencing technical difficulties, have inquiries about our services, or need guidance on navigating our portal, we're here to help you every step of the way.`
          )
        }}
      </p>
      <!-- <h3 @click="showForm = !showForm" class="reach-us">
        {{ $t(`_web_support_reach-us`, 'Reach us') }}
      </h3> -->
      <HeraFormBtn
        tabindex="0"
        :label="$t(`_web_support_reach-us`, 'Reach us')"
        class="btn-accent-simple report-form_submit button-cancel width-adjustment-support"
        style="width: 117px !important;"
        @click.prevent="showForm = !showForm"
      />
      <form v-if="showForm" class="support-form" @submit.prevent="submitForm">
        <HeraInput
          v-model="subject"
          :label="$t(`_web_support_subject`, 'Subject :')"
          class="width-adjustment-support"
          :placeholder="$t(`_web_support_subject_enter`, 'Enter subject')"
        />
        <div class="width-adjustment-support">
          <label for="message">{{
            $t(`_web_support_message`, 'Message: ')
          }}</label>
          <textarea
            id="message"
            v-model="message"
            class="input-form width-adjustment-support"
            :placeholder="$t(`_web_support_message_enter`, 'Enter message')"
            rows="4"
            required
          ></textarea>
        </div>

        <HeraFormBtn
          tabindex="0"
          :label="$t(`_web_support_submit`, 'Submit')"
          class="btn-accent-simple report-form_submit button-cancel width-adjustment-support"
          @click.prevent="submitForm"
        />
      </form>
    </div>
    <div style="width: 100%;">
      <h1>{{ $t(`_web_support_feedback-support`, 'Feedback') }}</h1>
      <p>
        {{
          $t(
            `_web_support_feedback-support-text`,
            `We value your feedback and strive to continuously improve our platform based on your suggestions and experiences. If you have any feedback or suggestions for us, please don't hesitate to let us know. Your input helps us enhance our services and better meet your needs.`
          )
        }}
      </p>
    </div>
    <div style="width: 100%;">
      <h1>{{ $t(`_web_support_get-in-touch`, 'Get in Touch') }}</h1>
      <p>
        {{
          $t(
            `_web_support_get-in-touch-text`,
            `Don't hesitate to reach out to us if you need assistance or have any questions. We're here to ensure your experience with our platform is smooth, efficient, and enjoyable.`
          )
        }}
      </p>
      <p>
        {{
          $t(
            `_web_support_get-in-touch-text-goodbay`,
            `Thank you for choosing our platform. We appreciate you and look forward to assisting you!`
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
import HeraInput from '@/components/atoms/form/HeraFormTextInput';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';

export default {
  name: 'Support',
  components: {
    HeraInput,
    HeraFormBtn,
  },
  data() {
    return {
      showForm: false,
      subject: '',
      message: '',
    };
  },
  methods: {
    submitForm() {
      const email = 'servicedesk@playhera.com';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        this.subject
      )}&body=${encodeURIComponent(this.message)}`;
      window.location.href = mailtoLink;
    },
  },
};
</script>
<style lang="scss">
.support-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50%;
  margin: auto;
  @media (max-width: 471px) {
    margin: unset;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }
}
.heading-support-page {
  margin-top: 4rem;

  @media (max-width: 471px) {
    margin-top: 1rem;
  }
}

.support-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
.width-adjustment-support {
  width: 350px;
  @media (max-width: 471px) {
    width: 250px;
  }
}
.reach-us {
  cursor: pointer;
}
.reach-us:hover {
  color: var(--ph-accent);
}
</style>
