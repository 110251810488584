<template>
  <div
    id="org"
    class="main-wrapper-organisers"
    :style="{ '--scrollWidth': scrollWidth }"
  >
    <div class="content-wrap-organisers">
      <div class="top-organisers-title">
        <h2>{{ $t('_web_tournament-organisers-title', 'Top Organisers') }}</h2>
        <p>
          {{
            $t(
              '_web_tournament-organisers-sub-title',
              'Best Organisations on PLAYHERA. Hosting 1000+ events.'
            )
          }}
        </p>
      </div>
      <div class="organisers-list-wrapper">
        <div class="inner-wrap-organisator">
          <div
            v-for="(organisator, index) in organisators"
            :key="index"
            class="organisers-card"
          >
            <a :href="isAuthorized ? linkPrefix + organisator.id : '#org'">
              <div
                :style="organisator.bgColor"
                class="img-organisator-container"
              >
                <img
                  :src="
                    require(`@/assets/images/organisators/${
                      organisator.avatar
                    }`)
                  "
                  width="120px"
                  height="120px"
                  :style="organisator.color"
                  class="avatar-organisations"
                  alt="avatar"
                />
              </div>
              <h3>
                {{
                  $t(
                    `_web_tournament-organisers-${organisator.translation}`,
                    `${organisator.title}`
                  )
                }}
                <p></p>
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
export default {
  name: 'TopOrganisers',
  mixins: [translate('_web_tournament')],
  data() {
    return {
      scrollWidth: null,
      linkPrefix: 'https://playhera.com/organizations/',
      organisators: [
        {
          id: '3211e1d1-6036-42f6-aead-9828964d1204',
          avatar: 'playheraLogo.png',
          title: 'PLAYHERA MENA',
          color: 'border: 1.5px solid #4A4A4A',
          translation: 'play-hera-top',
        },
        {
          id: 'f9178b7e-2319-4104-9101-65a4e8d2525d/',
          avatar: 'Al-Hilal.svg',
          title: 'ALHILAL ESPORTS',
          color: 'height: 64px;',
          bgColor: 'background-color: white',
          translation: 'Al-Hilal-top',
        },
        {
          id: '01dd9a7a-8ece-451a-b2d7-0ed7b82bba96/',
          avatar: 'ALSHABAB.svg',
          title: 'AL SHABAB',
          color: 'background-color : black',
          translation: 'al-shabab-top',
        },
        {
          id: '4602408d-0ae5-4b74-ac66-ad6547b8dd16/',
          avatar: 'ZainKSA.svg',
          title: 'ZAIN KSA',
          color: 'background-color : black',
          translation: 'zain-top',
        },
        {
          id: '74b88b2b-3f84-4891-878e-af3301f3ee49/',
          avatar: 'R8Soon.svg',
          title: 'R8 Esports',
          color: 'background-color : black',
          translation: 'r8-esports-top',
        },
        {
          id: '6a0c81cf-8656-489b-88db-ca178848d529/',
          avatar: 'zainEsports.webp',
          title: 'Zain Esports - Iraq',
          color: 'background-color : black',
          translation: 'zain-esports-top',
        },
        {
          id: 'e06c82e4-d287-4d61-a399-60d70a4f0286/',
          avatar: 'gcon.svg',
          title: 'GCON EVENTS',
          color: 'background-color : #189F8F ; height: 20px;',
          translation: 'gcon-top',
        },
      ],
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['isAuthorized']),
  },
  mounted() {
    this.getScrollBarWidth();
  },
  methods: {
    getScrollBarWidth() {
      let el = document.createElement('div');
      el.style.cssText =
        'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      el.remove();
      return (this.scrollWidth = width.toString() + 'px');
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper-organisers {
  font-family: $hr-font-family;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: calc(100vw - var(--scrollWidth));
  height: 25.313rem;
}
.content-wrap-organisers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  max-width: 1511px;
  height: 100%;
}

.top-organisers-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 25%;

  h2 {
    margin-bottom: 17px;
    font-size: 30px;
    font-style: bold;
    line-height: 33px;
    line-height: 110%;
  }
  p {
    margin: 0;
    font-size: 18px;
    line-height: 20px;
    color: #727288;
    font-weight: 400;
    width: 307px;
    line-height: 140%;
  }
}
.organisers-list-wrapper {
  display: flex;
  flex-basis: 70%;
  justify-content: space-between;

  .inner-wrap-organisator {
    justify-content: space-between;
    display: flex;
    width: 100%;
    // gap: 48px;
    max-width: 1511px;
  }
  .avatar-organisations {
    border-radius: 100px;
    background-color: white;
    height: 120px;
  }
  .organisers-card {
    a {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      gap: 14px;
      &:hover {
        text-decoration: none;
        color: white;
      }
      h3 {
        text-transform: uppercase;
        text-align: center;
        margin: 0px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #797979;
          margin: 0px;
        }
      }
    }
  }

  .organisers-card:hover h3 {
    color: #dfdfdf;
  }
}
.img-organisator-container {
  display: flex;
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  background-color: #189f8f;
  border-radius: 100px;
}
@media (max-width: 1600px) {
  .organisers-card:last-child {
    display: none;
  }
}

@media screen and (max-width: 1483px) {
  .content-wrap-organisers {
    width: 92vw;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .content-wrap-organisers::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 1251px) {
  .content-wrap-organisers {
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 6rem;
  }

  .inner-wrap-organisator {
    gap: 2rem;
  }

  .organisers-list-wrapper {
    flex-basis: auto;
    padding-top: 2rem;
  }

  .main-wrapper-organisers {
    height: auto;
  }

  .top-organisers-title > h2 {
    text-align: center;
  }

  .top-organisers-title > p {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .content-wrap-organisers {
    width: calc(100vw - var(--scrollWidth));
  }

  .organisers-list-wrapper {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .organisers-list-wrapper::-webkit-scrollbar {
    width: 0;
  }

  .inner-wrap-organisator {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
