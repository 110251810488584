import GridViewWrapper from '@/views/tournaments/management/brackets/GridViewWrapper';
import TableViewWrapper from '@/views/tournaments/management/brackets/TableViewWrapper';
import EditResults from '@/views/tournaments/management/brackets/components/EditResultsModal_v2';

export default [
  {
    path: '',
    name: 'BlockBracketsGrid',
    component: GridViewWrapper,
    props: true,
    meta: {
      titleKey: '_web_tournaments_grid-bracket',
      fallbackLabel: 'Playoff brackets',
      showAdminFunctions: true,
    },
  },
  {
    path: 'table/:roundID?/:grid(upper|lower)?',
    name: 'BlockBracketsTable',
    component: TableViewWrapper,
    props: true,
    meta: {
      titleKey: '_web_tournaments_grid-table',
      fallbackLabel: 'Table view',
    },
  },
  {
    path: 'table/:roundID/:grid(upper|lower)?/edit-results/:matchIndex',
    name: 'BlockBracketsTableEditResults',
    component: EditResults,
    props: true,
    meta: {
      titleKey: '_web_tournaments_grid-table',
      fallbackLabel: 'Edit Results',
    },
  },
];
