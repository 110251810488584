var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({ref:"tooltip",staticClass:"position-relative tooltip-wrapper",class:{ 'cursor-help': !_vm.isEmpty }},Object.assign({}, (_vm.isEmpty && !_vm.forceOpen
      ? null
      : _vm.openAfterClick
      ? {
          click: _vm.toggle,
        }
      : {
          mouseout: _vm.hidePopover,
          mouseover: _vm.open,
        }))),[(_vm.showIcon)?_c('Icon',_vm._b({},'Icon',Object.assign({}, {name: 'info',
      class: 'align-top',
      fill: _vm.$scss.disabledColor,
      width: 9},
      _vm.iconProps),false)):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }