var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gif-container"},[_c('HeraSearchInput',{attrs:{"class-input":"gif-search"},on:{"input":_vm.reload},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('div',{ref:"scrollableRef",staticClass:"custom-scrollbar position-relative",on:{"scroll":function($event){$event.stopPropagation();}}},[_c('ListLoader',{attrs:{"is-loading":_vm.isLoading,"is-empty-list":!_vm.isLoading && !_vm.gifsList.length,"dummy-props":{
        iconName: 'dummy/newsfeed',
        size: 150,
        headerText: _vm.$t(
          '_web_players_dummy-list',
          'We couldn\'t find anything.'
        ),
      }},on:{"reload":_vm.reload}},[_c('template',{slot:"list"},[_c('InfiniteScroll',{staticClass:"gif-gallery",attrs:{"in-container":true,"list":_vm.gifsList,"list-name":"gifs","is-disabled":_vm.isLoading,"activation-gap":100,"container-element":_vm.$refs.scrollableRef},on:{"ScrollToBottom":_vm.fetchNewPage},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _c('div',{},_vm._l((_vm.gifsList),function(gif){return _c('div',{key:gif.id + Math.random() * Math.floor(99),on:{"click":function($event){$event.preventDefault();return _vm.addGif(gif)}}},[_c('img',{attrs:{"src":gif.url,"alt":gif.title,"width":"100%","height":"auto"}})])}),0)}}])})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }