import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { CLAN_JOIN_STATES, CLAN_TYPES } from '@/constants/accounts.js';

export const follow = {
  data() {
    return {
      waitingFollow: false,
    };
  },
  computed: {
    ...mapGetters(['hasAccessByPermission']),
  },
  methods: {
    ...mapActions(['followAccount', 'openModal', 'closeModal']),
    ...mapMutations(['updateCounterKey']),
    canFollow() {
      return this.hasAccessByPermission('followClan');
    },
    followClanConfirm(clanAccount) {
      if (clanAccount.isFollowing) {
        this.openModal({
          name: 'HeraConfirm',
          props: {
            text: this.$t(
              '_web_clan_unfollow-confirm',
              'Do you really want to unfollow {clan} team',
              {
                clan: clanAccount.name,
              }
            ),
          },
          events: {
            cancel: this.closeModal,
            confirm: () => {
              this.followClanAction(clanAccount);
              this.closeModal();
            },
          },
        });
      } else {
        this.followClanAction(clanAccount);
      }
    },
    followClanAction(clanAccount) {
      if (
        this.$lodash.isEmpty(clanAccount) &&
        this.hasAccessByPermission('followClan')
      ) {
        return;
      }
      this.waitingFollow = true;
      this.followAccount({
        ...clanAccount.params,
        follow: !clanAccount.isFollowing,
      })
        .then(() => {
          clanAccount.isFollowing = !clanAccount.isFollowing;
          if (clanAccount.isModel) {
            clanAccount.updateCounters({
              followers: clanAccount.isFollowing ? +1 : -1,
            });
          }
        })
        .finally(() => {
          this.waitingFollow = false;
          this.updateCounterKey();
        });
    },
    followClanText(clanAccount) {
      return clanAccount.isFollowing
        ? this.$t('_web_clan_actions_unfollow', 'Unfollow')
        : this.$t('_web_clan_actions_follow', 'Follow');
    },
  },
};

export const join = {
  data() {
    return {
      waitingJoin: false,
    };
  },
  computed: {
    ...mapState('my', { myAccount: 'account' }),
    ...mapGetters(['hasAccessByPermission']),
  },
  methods: {
    ...mapActions(['joinAccountClan', 'acceptAccountClan']),
    ...mapActions('my', ['fetchMyAccount']),
    ...mapMutations(['updateCounterKey']),
    isPending(clanAccount) {
      return clanAccount.joiningState === CLAN_JOIN_STATES.PENDING;
    },
    isAccept(clanAccount) {
      return clanAccount.joiningState === CLAN_JOIN_STATES.ALLOW_ACCEPT;
    },
    canJoin({ joiningState = CLAN_JOIN_STATES.NOT_ALLOWED, clanType }) {
      return (
        this.hasAccessByPermission('joinClan') &&
        this.myAccount.isProfile &&
        // !this.myAccount.clanModel &&
        clanType === CLAN_TYPES.PUBLIC &&
        joiningState !== CLAN_JOIN_STATES.NOT_ALLOWED
      );
    },
    joinClanAction(clanModel) {
      if (this.$lodash.isEmpty(clanModel) || this.isPending(clanModel)) {
        return;
      }
      this.waitingJoin = true;
      const isAccept = this.isAccept(clanModel);

      return this[isAccept ? 'acceptAccountClan' : 'joinAccountClan'](
        clanModel.params
      )
        .then(() => {
          if (clanModel.isModel) {
            if (isAccept) {
              clanModel.updateCounters({
                members: +1,
              });
              clanModel.isMember = true;
            } else {
              clanModel.joiningState = CLAN_JOIN_STATES.PENDING;
            }

            clanModel.updateCounters({
              followers: clanModel.isFollowing ? 0 : +1,
            });
            clanModel.isFollowing = true;
          }
          return clanModel;
        })
        .then(clanModel => {
          if (isAccept) {
            return this.fetchMyAccount();
          }
          return clanModel;
        })
        .finally(() => {
          this.waitingJoin = false;
          this.updateCounterKey();
        });
    },
    joinClanText(clanAccount) {
      return this.isPending(clanAccount)
        ? this.$t('_web_clan_join_sent', 'Request Sent')
        : this.isAccept(clanAccount)
        ? this.$t('_web_clan_join_accept', 'Accept')
        : this.$t('_web_clan_join', 'Join');
    },
  },
};

export const invite = {
  data() {
    return {
      waitingInvite: false,
    };
  },
  computed: {
    ...mapState('current', ['accountId']), // current clan_id
  },
  methods: {
    ...mapActions(['inviteToClan', 'cancelInviteToClan', 'errorNotify']),
    inviteClanAction({
      account = null,
      accountId = account ? account._id : '',
    }) {
      if (this.$lodash.isEmpty(account) || !accountId) {
        return Promise.reject();
      }

      this.waitingInvite = true;

      return this[account.isInvited ? 'cancelInviteToClan' : 'inviteToClan']({
        clan: this.accountId,
        candidates: [accountId],
      })
        .then(() => {
          account.isInvited = !account.isInvited;
          return account.isInvited;
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.waitingInvite = false;
        });
    },
    inviteClanText(account) {
      return account.isInvited
        ? this.$t('_web_clan-manage_invite-cancel', 'Cancel')
        : this.$t('_web_clan-manage_invite', 'Invite');
    },
    inviteClanBtnClass(account) {
      return account.isInvited ? 'btn-secondary-simple' : 'btn-accent-simple';
    },
  },
};

export default [follow];
