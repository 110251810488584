<template>
  <div
    v-if="accountEditData"
    id="create_clan"
    class="clan-create-details w-100"
  >
    <div class="d-flex justify-content-between my-5 px-5 align-items-center">
      <Subheader class="secondary-font" :text="headerForm" />

      <div :class="'navigation'">
        {{
          $route.params.tournamentID
            ? $t('_web_create-clan-join_submit-button', 'Create and join')
            : hasClanStub
            ? $t('_web_create-clan_create', 'Create') + ' 1/2'
            : $t('_web_profile-edit_update', 'Update')
        }}
      </div>
    </div>
    <div>
      <AccountCoverEdit />
      <!-- <AccountStatusBarEdit is-light-mode :avatar-size="96" /> -->
      <AccountEditClan class="mt-2" />
    </div>
    <CreatedPopup
      v-bind="{
        account,
        redirectTo,
        subTitle,
        isVisible: congratulationPopupIsVisible,
      }"
    />
    <div class="create-clan-btn">
      <HeraFormSubmitBtn
        :label="
          $route.params.tournamentID
            ? $t('_web_create-clan-join_submit-button', 'Create and join')
            : hasClanStub
            ? $t('_web_create-clan_create-next', 'NEXT')
            : $t('_web_profile-edit_update', 'Update')
        "
        :loading="isLoading"
        :class="'btn-next'"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { CLAN, CLAN_TYPES } from '@/constants/accounts.js';
import HeraFormSubmitBtn from '@/components/form/HeraFormSubmitBtn';
import AccountCoverEdit from '@/views/account/header/cover/Edit';
// import AccountStatusBarEdit from '@/views/account/header/statusBar/edit';
import AccountEditClan from '@/views/account/content/edit/Clan';
import CreatedPopup from '@/components/popup/CreatedPopup';
import joinAction from '@/views/tournaments/mixins/joinTournamentAction';
import Subheader from '@/components/atoms/Subheader';
import store from '@/store';

const DEFAULT_CLAN_MODEL = {
  clanType: CLAN_TYPES.PUBLIC,
  name: '',
  description: '',
  country: '',
  country_id: '',
  social: {},
  streams: {},
};

export default {
  name: 'CreateClanDetails',
  components: {
    CreatedPopup,
    HeraFormSubmitBtn,
    AccountCoverEdit,
    // AccountStatusBarEdit,
    AccountEditClan,
    Subheader,
  },
  mixins: [joinAction],
  inject: ['$validator'],
  data() {
    return {
      isLoading: false,
      subTitle: '',
      congratulationPopupIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['accountEditData', 'accountChangedData']),
    ...mapGetters(['registrationTournament']),
    ...mapState('current', ['account']),
    ...mapState('current', ['account']),
    ...mapState('my', { myAccount: 'account' }),
    redirectTo() {
      return this.$route.params.tournamentID
        ? null
        : {
            name: 'inviteMembers',
          };
    },
    hasClanStub() {
      return this.account && !this.account._id;
    },
    headerForm() {
      return this.$route.params.tournamentID || this.hasClanStub
        ? this.$t('_web_create-team-link', 'Create team')
        : this.$t('_web_clans_edit-team', 'Edit team');
    },
  },
  beforeRouteEnter(to, from, next) {
    const currentAccount = store.state.accounts.current.account;
    if (
      !currentAccount ||
      currentAccount._type !== CLAN ||
      currentAccount._id
    ) {
      store.commit('current/createAccountModel', {
        accountType: 'clan',
        data: {},
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$lodash.isEmpty(this.accountChangedData)) {
      return this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_profile_confirm-cancel-changes',
            'If you navigate away from this page without saving your data, the changes will be lost'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: () => {
            this.closeModal();
            next();
          },
        },
      });
    }
    next();
  },
  created() {
    this.createEditModel(DEFAULT_CLAN_MODEL);
  },

  methods: {
    ...mapMutations(['createEditModel', 'clearEditData']),
    ...mapActions([
      'errorNotify',
      'saveAccountChanges',
      'closeModal',
      'openModal',
    ]),

    save() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(isValidate =>
          isValidate
            ? Promise.resolve()
            : Promise.reject(
                this.$t('_web_form-validation-error', 'Form validation failed')
              )
        )
        .then(this.saveAccountChanges)
        .then(() => {
          this.subTitle = this.$t(
            '_web_clan_created_text',
            'Your clan "{name}" has been created!',
            {
              name: this.account._name,
            }
          );
          this.congratulationPopupIsVisible = true;

          if (this.$route.params.tournamentID) {
            this.clearEditData();
            this.afterJoin = () =>
              this.$router.push({
                name: this.$routerNames.MY_TEAM,
                params: {
                  accountId: this.$route.params.tournamentID,
                  accountType: this.$routerNames.TOURNAMENT,
                },
              });
            this.goToClanSelect(this.$route.params.tournamentID);
          }
        })
        .then(
          window.dataLayer.push({
            event: 'team create',
            teamName: this.accountChangedData.name,
            teamCountry: this.accountChangedData.country,
            username: this.myAccount.login,
            from: this.$route.path,
            membersCount: this.account.membersCount,
          })
        )
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
/*use src/assets/scss/components/status-bar.scss*/
.clan-create-details {
  .btn-cancel {
    width: 14rem;
  }
}
.create-clan-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.btn-next {
  border-radius: 5px !important;
  margin: auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: $hr-bg-accent !important;
  span {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.navigation {
  font-size: 1rem;
}
</style>
