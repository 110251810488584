<template>
  <MainLayout>
    <div class="terms">
      <div class="terms-container">
        <h1 class="text-center">
          PLAYHERAユーザー利用規約
        </h1>

        <ol>
          <li>
            <p><b>これらの用語を理解すること</b></p>
            <ol>
              <li>
                <p>
                  本利用規約（「本規約」）は、アプリをダウンロードしアプリを通じて利用可能になったサービス（以下「サービス」）にアクセスして使用する方法を説明しています。アプリにアクセスすることにより、本規約があなたに適用され、あなたは本規約の条件に同意します。したがって、アプリを使用する前に条件を注意深くお読みください。
                </p>
              </li>
              <li>
                <p>
                  本規約で特定の単語やフレーズが使用される場合、それらには特定の意味があります（これらは「定義された用語」として知られています）。定義された用語は、大文字で始まるため（それらが文の先頭にない場合でも）識別できます。定義された用語が使用される場合、本規約のセクション内で定義された意味を持ちます（定義された用語は括弧および音声マークに含まれている文からこれらの意味を見つけることができます）。
                </p>
              </li>
              <li>
                <p>
                  本規約で言及される「私たち」とは、Playhera and Entertainment
                  Content Company LTD
                  を意味します。そして、「あなた」を指すときは、私たちは以下のように意味します。
                </p>
                <ol>
                  <li>
                    <p>
                      あなたが消費者である場合は、完全にまたは主に取引、ビジネス、クラフト、または職業以外の目的でアプリを使用している個人を意味します。
                    </p>
                  </li>
                  <li>
                    <p>
                      あなたが消費者でない場合は（例えば、あなたがプロのeスポーツ選手である場合）、（a）あなたが拘束する権限を持っているビジネスを意味します。もしくは（b）完全にあるいは主に取引、ビジネス、クラフト、または職業といった目的でアプリを使用している個人を意味します。（それぞれ「ビジネス」）
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  このアプリには、iOSまたはAndroidオペレーティングシステム（あなたが使用している「デバイス」）を実行するスマートフォンまたはその他のモバイルデバイスが必要です。アプリをダウンロードして最新機能にアクセスするには、インターネットアクセスが必要です。
                </p>
              </li>
              <li>
                <p>
                  ただしアプリで利用できる特定の機能には、例えばeスポーツ競技への参加など、追加条件が適用されることに注意してください。また、追加機能やサービスを随時導入する場合があります。該当する使用条件があれば通知します。
                </p>
              </li>
              <li>
                <p>
                  上記の条項1.5に加えて、次のことに注意してください。
                </p>
                <ol>
                  <li>
                    <p>
                      アプリをダウンロードするには、本規約の付録1に記載されている追加のアプリ条件、およびアプリのダウンロード元のアプリストアによって課されるその他の条件も確認して同意する必要があります。
                    </p>
                  </li>
                  <li>
                    <p>
                      私たちはプライバシーポリシー（ここから入手でき、Cookieの使用方法の詳細も記載）に従った個人情報のみを使用します。
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <p><b>アプリ</b></p>
            <ol>
              <li>
                <p>
                  アプリは無料で利用できます。アプリまたはそのコンテンツが常に利用可能であることまたは中断されないことを私たちは保証しません。アプリへのアクセスは暫定的に許可されています。アプリのすべてまたは一部を予告なしに一時停止、撤回、中止または変更する場合があります。何らかの理由でアプリがいつ、またはいかなる期間利用できない場合も私達は責任を負いません。私たちはアプリをいつでも更新および/またはコンテンツを変更できます。
                </p>
              </li>
              <li>
                <p>
                  アプリにアクセスするために必要な手配をする責任はあなたにあります。また、あなたのインターネット接続を介してアプリにアクセスするすべての人が本規約を認識し、それらに準拠していることをあなたは確認する責任があります。
                </p>
              </li>
              <li>
                <p>
                  アプリとそのコンテンツは、一般的な情報提供のみを目的として提供されています。それらはあなたが頼るべきアドバイスになることを意図していません。
                </p>
              </li>
              <li>
                <p>
                  あなたが競合ビジネスである場合は私たちが明示的に許可している場合を除き、アプリは家庭用、私的および非営利目的でのみ使用できます。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>あなたのアカウントとパスワード</b></p>
            <ol>
              <li>
                <p>
                  アプリで利用可能な特定のサービス（「アカウント」）にアクセスするには、アプリでアカウントを登録する必要があります。
                  アプリを使用するか、当社の公式Webサイト（www.playhera.com）から登録できます。アカウントを登録する場合、セキュリティ手順の一環として特定の情報（電子メールアドレスなど）を提供し、パスワードを作成するよう求められます。このようなパスワードは機密として扱う必要があり、第三者に開示しないでください。
                </p>
              </li>
              <li>
                <p>
                  あなたが本規約の規定のいずれかを遵守しなかったと私たちが合理的に判断した場合、私達はアカウントおよび/またはパスワードをいつでも無効にする権利を有します。
                </p>
              </li>
              <li>
                <p>
                  あなた以外の誰かがあなたのアカウントのログイン情報を知っていると分かった場合また疑いがある場合は、すぐに
                  <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
                  までご連絡ください。
                </p>
              </li>
              <li>
                <p>
                  アカウントのログイン情報の不正使用については、あなたが責任を負います。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b> 許容できる使用</b></p>
            <ol>
              <li>
                <p>
                  <i><u>一般</u></i>
                </p>
                <p>あなたは以下に同意します。</p>
                <ol>
                  <li>
                    違法な方法、違法な目的、または本規約と矛盾する方法でアプリを使用しないこと。
                  </li>
                  <li>
                    アプリの使用に関して私達の知的財産権または第三者の知的財産権を侵害しないこと（そのような使用が本規約で認可されていない場合）。
                  </li>
                  <li>
                    アプリの使用に関して中傷的、不快、または好ましくないコンテンツを送信しないこと。
                  </li>
                  <li>
                    自動化された手段またはその他の方法でアプリを使用しないこと、ないしはスクレイピングや抽出する目的で使用しないこと、あるいは第三者のWebサイトまたはアプリケーションを使用してアプリからマテリアルを取得したりしないこと。
                  </li>
                  <li>
                    アプリまたは当社のシステムから情報またはデータを収集したり、アプリを実行しているサーバーとの間の送信を解読しようとしたりしないこと。
                  </li>
                  <li>
                    本規約で明示的に許可されている場合を除き、アプリの一部をコピー、複製、再販売しないこと。
                  </li>
                  <li>
                    アプリの全体または一部に基づいて分解、デコンパイル、リバースエンジニアリング、または派生物を作成したり、そのようなことを試みたりしないこと。
                  </li>
                  <li>
                    アプリをレンタル、リース、サブライセンス、ローン、翻訳、マージ、適応、変更、修正しないこと。
                  </li>
                  <li>
                    アプリの全体または一部を変更または修正したり、他のプログラムと組み合わせたり組み込んだりしないこと。
                  </li>
                  <li>
                    アプリのすべてのコピーを安全に保ち、コピーの数と場所の最新記録を維持すること。
                  </li>
                  <li>
                    当社からの事前の書面による同意なしに、いかなる形式においても、アプリの全体または一部（オブジェクトおよびソースコードを含む）を誰かに提供もしくは利用可能にしないこと。
                  </li>
                  <li>
                    アプリで使用またはサポートされる技術に適用されるすべての技術管理または輸出に関する法律および規制を遵守すること。
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  あなたはソースコード形式でアプリにアクセスする権利がないことを認めること。
                </p>
              </li>

              <li>
                <p>
                  <i><u>ユーザー生成コンテンツ</u></i>
                </p>
                <p>
                  アプリにコンテンツを提供/アップロードする場合（写真、テキスト、サウンドレコーディングなど）、提供するコンテンツ（「<b>ユーザー生成コンテンツ</b>」）は次のルールに従う必要があります。
                </p>
                <ol>
                  <li>
                    <p>
                      わいせつ、虐待、攻撃的、人種差別的であってはならず、いかなる人に対する憎悪や身体的危害を助長または提案してはなりません。
                    </p>
                  </li>
                  <li>
                    <p>他の人に嫌がらせやいじめをしてはなりません。</p>
                  </li>
                  <li>
                    <p>
                      あなたが知っている限り、それは真実で正直でなければなりません。
                    </p>
                  </li>
                  <li>
                    <p>誰に対しても中傷してはなりません。</p>
                  </li>
                  <li>
                    <p>
                      マテリアルやコンテンツを使用したり、他人の権利やプライバシーを侵害してはなりません。たとえば、有名なキャラクター、映像、音楽の画像を使用しないでください（自分のものでない限り）。
                    </p>
                  </li>
                  <li>
                    <p>
                      他人の個人情報や他人に関する機密情報を含めないでください。
                    </p>
                  </li>
                  <li>
                    <p>
                      テロ、暴力、または違法行為を助長または容認してはなりません。
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  私達は、これらの規則に違反すると思われる人物が提供するユーザー生成コンテンツの受け入れを拒否、または使用を停止する権利を留保します。
                </p>
              </li>
              <li>
                <p>
                  アプリには、あなたが上記のルールに準拠していないと感じたコンテンツを当社に報告するために使用できるレポート機能があり、オペレーターは24時間以内に必要な措置を講じます。不快なコンテンツを提供したユーザーのアカウントは停止される場合があります。
                </p>
              </li>
              <li>
                <p>
                  加えて私達は、ユーザー生成コンテンツのアップロードを可能にするインタラクティブなサービスをアプリ上で随時提供する場合があります。
                </p>
                <ol>
                  <li>
                    <p>コメント機能</p>
                  </li>
                  <li>
                    <p>チャットルーム、そして/あるいは</p>
                  </li>
                  <li>
                    <p>
                      掲示板<br />（「<b>インタラクティブサービス</b>」と一緒に）。
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  私達がインタラクティブサービスを提供する場合、サービスの種類およびモデレートされているかどうかについての情報をあなたに提供するために合理的な努力をします。ただし、提供するインタラクティブサービスを監督、監視、または管理する義務はありません。
                </p>
              </li>
              <li>
                <p>
                  未成年者によるインタラクティブサービスの使用は、親または保護者の同意が必要です。お子様にインタラクティブサービスの使用を許可する保護者の方は、お子様とオンラインの安全性についてコミュニケーションを取ることが重要です。インタラクティブサービスを使用している未成年者には、潜在的なリスクを認識させる必要があります。
                </p>
              </li>
              <li>
                <p>
                  <i><u>ウイルス</u></i>
                </p>
                <p>
                  私たちはアプリが完全に安全であること、またはバグやウイルスがないことを保証しません。アプリにアクセスするには、情報技術、コンピュータープログラム、およびプラットフォームを構成する必要があります。独自のウイルス対策ソフトウェアを使用することをお勧めします。
                </p>
              </li>
              <li>
                <p>
                  故意にウイルス、トロイの木馬、ワーム、論理爆弾、または悪意のある技術的に有害なその他のマテリアルを導入することにより、アプリを誤用してはなりません。アプリ、アプリが保存されているサーバー、またはアプリに接続されているサーバー、コンピューター、データベースへの不正アクセスを試みてはなりません。DoS攻撃またはDDoS攻撃を介してアプリを攻撃してはなりません。この規定に違反すると、犯罪を犯すことになります。このような違反は関連する法執行機関に報告し、あなたの身元を開示することでそれらの機関と協力します。そのような違反が発生した場合、アプリを使用するあなたの権利は直ちに停止されます。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>知的財産</b></p>
            <ol>
              <li>
                <p>
                  本規約および本規約内に言及されている文書の条件を順守することを条件に、デバイス上でアプリを使用するための譲渡不可で非独占的、かつ取り消し可能なライセンスをあなたに付与します。他のすべての権利は私たちが留保します。
                </p>
              </li>
              <li>
                <p>
                  私たちは（あなたと私たちの間で）アプリとそのコンテンツのすべての知的財産権の所有者またはライセンシーです。これらは知的財産法および条約によって世界中で保護されています。そのような権利は全て留保されます。
                </p>
              </li>
              <li>
                <p>
                  あなたは、本規約に記載されている以外の知的財産権を使用する権利は付与されておらず、使用することはできません。あなたが競合ビジネスである場合は私たちが明示的に許可している場合を除き、商用目的でアプリ（またはその一部またはコンテンツ）を使用しないでください。ただし、非商業的、個人的な使用においてはアプリからマテリアルをダウンロードできます。
                </p>
              </li>
              <li>
                <p>
                  アプリに含まれるテキスト、デザイン、グラフィック、写真、画像を含むがこれらに限定されないアプリのいかなる部分も、書面による事前の同意なしに、非個人的、公共的または商業的な目的のためにコピー、複製、再公開、アップロード、再投稿、変更、送信、配布、またはその他の方法で使用することはできません。
                </p>
              </li>
              <li>
                <p>
                  電子メールまたはその他の手段でアプリを通じて私達に送られた通信または資料（ユーザー生成コンテンツを含むがこれらに限定されない）は、非独占的かつ非機密として扱われます（アプリを使用して当社から製品またはサービスを購入する場合の注文に関する連絡以外）。私たちは、アイデア、提案、コンセプト、デザイン、ノウハウ、当社製品の開発、製造、広告、マーケティングを含むがこれらに限定されないあらゆる目的のためのコミュニケーションやマテリアルにおける情報を自由に公開、表示、投稿、配布、使用します。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>私たちの責任</b></p>
            <ol>
              <li>
                <p>
                  本規約のいかなる条項も、以下に対する私達の責任を除外または制限しません。
                </p>
                <ol>
                  <li>
                    <p>過失による死亡または人身傷害。</p>
                  </li>
                  <li>
                    <p>詐欺または詐欺的な虚偽表示。</p>
                  </li>
                  <li>
                    <p>
                      弊社の責任を除外または制限することが違法となる問題。
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>もしあなたが消費者であるなら</p>
                <ol>
                  <li>
                    <p>
                      私たちが本規約を順守し損ねた場合、本規約の違反または過失から予見可能な結果としてあなたが被る損失または損害について責任を負いますが、予見できない損失または損害については責任を負いません。明らかに私達の違反による結果であった場合、あるいはあなたがアプリにアクセスした時点であなたと私達によって予期できた場合、損失と損害は予見可能とします。
                    </p>
                  </li>
                  <li>
                    <p>
                      本規約はあなたの法的権利に影響しません。法的権利に関するアドバイスは、地元の消費者保護団体から入手できます。
                    </p>
                  </li>
                  <li>
                    <p>
                      家庭用および私的使用のためにのみアプリを提供しています。ユーザーは、商業目的またはビジネス目的でアプリまたはアプリのコンテンツを使用しないことに同意し、利益の損失、ビジネスの損失、ビジネスの中断、またはビジネス機会の損失について私たちは責任を負いません。
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>あなたがビジネス利用の場合は</p>
                <ol>
                  <li>
                    <p>
                      法令、慣習法、慣習、取引慣行、取引の過程またはその他の方法によって暗示されるすべての保証、遵守事項、条件、約束および義務（満足のいく品質、説明の適合、特定の目的への適合性、または特定の結果を達成する能力といった暗黙の約束を含むがこれらに限定されない）はこれにより、法律で許可される最大限の範囲で除外されます。
                    </p>
                  </li>
                  <li>
                    <p>
                      契約、不法行為（過失を含む）、法定義務違反、またはその他の条件に関わらず、これらの条件に基づいてまたはこれらに関連して発生する以下の状況については、いかなる状況においても責任を負いません。（a）利益、販売、ビジネス、または収益の損失。（b）データ、情報またはソフトウェアの損失または破損。（c）ビジネス機会の損失。（d）予想される貯蓄の損失。（e）暖簾の損失。または（f）間接的または結果的な損失。
                    </p>
                  </li>
                  <li>
                    <p>
                      条項6.1に影響を与えることなく、本規約および/またはあなたのアプリの使用に関連する私たちの最大責任総額は（契約中、不法行為（過失を含む）に関わらず）、私達のすべての行為、不作為（過失を含む）、法定義務違反または保証違反に関して、役員、従業員、代理人、請負業者、または下請け業者関連会社の違反を含め、100ポンドを超えないものとします。
                    </p>
                  </li>
                  <li>
                    <p>
                      あなたは、私達による本規約の違反、または本規約および/またはアプリに関連する行為または不作為に関する唯一の救済策は、他のすべての救済策（不法行為の主張を含むがこれに限定されない）を除外して、本規約の違反に対する契約上の主張であることを認めます。
                    </p>
                  </li>
                  <li>
                    <p>
                      すべての請求、要求、負債、損害、費用、出費に起因する損失、費用、負債、私達または当社の役員、請負業者、下請業者、代理店、従業員、関連会社のいずれかが、アプリの使用および/または本規約の違反により発生した損害を補償し、補償を維持するものとします。
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  アプリからリンクされているWebサイトまたはモバイルアプリケーションのコンテンツ（商用スポンサーおよびパートナーへのリンクを含む）について私達は一切責任を負いません。そのようなリンクは、リンクされたWebサイトまたはモバイルアプリケーションが推奨されていると解釈されるべきではありません。
                  私たちは、それらの使用から生じる可能性のある損失や損害について責任を負いません。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>停止と終了</b></p>
            <ol>
              <li>
                <p>
                  あなたが本規約のいずれかの条件に違反した場合、私たちは以下のいずれかまたはすべてを直ちに（制限なしに）行うことができます。
                </p>
                <ol>
                  <li>
                    <p>あなたに警告を発行します。</p>
                  </li>
                  <li>
                    <p>
                      ユーザーがアプリにアップロードしたユーザー生成コンテンツを一時的または永久に削除します。
                    </p>
                  </li>
                  <li>
                    <p>
                      アプリを使用するあなたの権利を一時的または永久に撤回します。
                    </p>
                  </li>
                  <li>
                    <p>あなたのアカウントを一時停止または終了します。</p>
                  </li>
                  <li>
                    <p>
                      違反に起因するすべての費用（合理的な管理費用および法的費用を含むが、これらに限定されない）の払い戻しのために、あなたに対して法的手続きを行います。
                    </p>
                  </li>
                  <li>
                    <p>
                      あなたに対してさらなる法的措置を講じます。および/または
                    </p>
                  </li>
                  <li>
                    <p>
                      必要があると合理的に判断した場合はそのような情報を法執行機関に開示します。
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>あなたのアプリを使用する権利を撤回する場合</p>
                <ol>
                  <li>
                    <p>
                      本規約に基づいてあなたに付与されたすべての権利は停止されます。
                    </p>
                  </li>
                  <li>
                    <p>
                      アプリを介して提供されるサービスの使用を含め、あなたは本規約によって承認されたすべての活動を直ちに中止する必要があります。
                    </p>
                  </li>
                  <li>
                    <p>
                      あなたはアプリをすべてのデバイスから直ちに削除し、アプリのすべてのコピーを直ちに破棄した後で、あなたの所有で保管、管理し、あなたがそのようにして利用したと証明する必要があります。
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <p><b>本規約の変更</b></p>
            <ol>
              <li>
                <p>
                  本規約の条件は随時変更される場合があります（例えば、法律に変更があり本規約を変更する必要がある場合）。本規約を定期的に確認して、アプリにアクセスして使用するときに適用される最新の条件を理解するようにしてください。本規約の条件を更新する場合、更新された条件はそれ以降アプリの各使用に適用されます。
                </p>
              </li>
              <li>
                <p>
                  アプリの更新は、関連するアプリストアを通じて随時発行される場合があります。アップデートによっては、アプリの最新バージョンをダウンロードして新しい条件に同意するまで、アプリを使用できない場合があります。
                </p>
              </li>
              <li>
                <p>
                  あなたのデバイスにアプリのコピーをダウンロードすることで、制御されているデバイスの所有者（持ち主ではない）から許可を得たものと見なされます。あなたと彼らは、サービスプロバイダーによってデバイス上のインターネットアクセスに対して課金される場合があります。あなたは、所有しているかどうかに関わらずデバイス上またはデバイスに関連するアプリまたはサービスの使用について、本規約の条項に従って責任を負うものとします。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>他の重要な情報</b></p>
            <ol>
              <li>
                <p>
                  本規約の各条項は個別に働きます。いずれかの裁判所または関連当局が、これらの条項いずれかが違法または執行不能であると判断した場合、残りの条項は引き続き完全に効力を持ちます。
                </p>
              </li>
              <li>
                <p>
                  あなたが本規約に基づく義務のいずれかを履行しないと主張した場合、または私たちがあなたの権利を行使しない場合、または履行を遅らせた場合、あなたに対する私達の権利を放棄したことを意味するのではなく、これらの義務をあなたが順守する必要がないということでもありません。私たちがあなたの不履行を免除する場合は、書面でのみ実行します。これは、私たちが自動的にその後もあなたの不履行を免除することを意味するものではありません。
                </p>
              </li>
              <li>
                <p>
                  オンライン紛争解決に関する詳細情報が必要な場合は、次のリンクから欧州委員会のWebサイト<a
                    href="http://ec.europa.eu/consumers/odr/"
                    >http://ec.europa.eu/consumers/odr/</a
                  >
                  にアクセスしてください。このリンクは情報提供のみを目的としており、欧州議会および理事会の規則（EU）No
                  524/2013により必要に応じて提供されます。私たちがオンライン紛争解決に参加する義務はありません。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p><b>準拠法および管轄</b></p>
            <ol>
              <li>
                <p>
                  本規約は英国の法律に準拠しています。これはアプリのダウンロード、アクセス、使用、およびアプリに起因または関連する紛争または請求が、英国の法律に準拠することを意味します。
                </p>
              </li>
              <li>
                <p>
                  本規約に関する訴訟は英国の裁判所に持ち込むことができます。ただし、消費者であり別の欧州連合加盟国に住んでいる場合は、英国の裁判所またはその加盟国の裁判所のいずれかで、本規約に関する法的手続きを行うことができます。
                </p>
              </li>
              <li>
                <p>
                  あなたが消費者で欧州連合に居住しており、私たちがあなたの居住している加盟国向けにアプリを運営する場合、あなたには居住国の法律の強行規定が適用されます。条項10.1を含む本規約のいかなる条項も、現地法のそのような強行規定に依存する消費者としての権利には影響しません。
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>
              <b>お問い合わせ</b>
              苦情がある場合、私たちは問題を解決し将来の再発を回避するよう努めます。<a
                :href="`mailto:${mailSupport}`"
                >{{ mailSupport }}</a
              >
              にメール を送信するか、下記宛にいつでもご連絡ください。 住所：
              <br />
              70 Sir John Rogerson’s Quay
              <br />
              Dublin 2
              <br />
              D02 R296 , Ireland
              <br />
              メールアドレス:
              <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
              <br />
              +966 11 222 1023
              <br />
              宜しくお願い致します。
              <br />
              <b>最終更新日</b>2019年２月２０日
            </p>
          </li>
        </ol>

        <h2 class="text-center">トーナメントと賞品の利用規約</h2>

        <p>AppleはPLAYHERAのトーナメントのスポンサーではありません。</p>

        <ol>
          <li>
            <p>
              勝者は、アプリケーションの電子メールまたはPLAYHERAダイレクトメッセージで通知されます。通知を受け取った後、勝者が賞金を請求するための期間は2週間です。受賞者が2週間以内に回答しなかった場合、賞品を請求する権利は却下される場合があります。
            </p>
          </li>
          <li>
            <p>
              参加者は、特に電子メールアドレス、名前、年齢といった情報の正確性について責任を負います。参加するには、必要なすべての情報が有効である必要があります。有効でない場合、参加者はトーナメントから除外されます。
              さらに参加者は、提供する住所データについて完全に責任を負います。勝者の名前と写真はPLAYHERAとトーナメントのスポンサーに公開される可能性があることに同意します。
            </p>
          </li>
          <li>
            <p>
              代替賞品または現金での代替支払いを求めることはできません。また、賞品を他のアイテム、バウチャー、商品と交換することはできません。トーナメントへの参加は、参加者とPLAYHERA間の契約ではありません。賞品が第三者から提供された場合、PLAYHERAはその賞品の欠陥、賞品の間違い、または間違った情報について責任を負いません。a.現金以外の賞品を現金で支払うことはできません。勝者は、PLAYHERAに有効で正しい送り先情報を提供する責任があります。したがって、小包が受け取られなかった場合、配達できない場合、紛失した場合PLAYHERAは責任を負いません。b.賞金は小切手または銀行振込で支払われます。したがって、勝者は有効で正しいデータを提供する責任があります。賞金は4週間から6か月の間に送金されます。この日程は異なる場合があります。
            </p>
          </li>
          <li>
            <p>
              PLAYHERAの従業員および関係するすべてのパートナーとその関連会社は参加から除外されます。
            </p>
          </li>
          <li>
            <p>
              勝者が個人ではなくチームである場合、チームリーダーまたはクラン創設者は賞品の受領書に署名する必要があります。
            </p>
          </li>
          <li>
            <p>
              勝者が17歳未満の場合、親または法定後見人がプレーヤーの面前で賞品を受け取る必要があります。
            </p>
          </li>
          <li>
            <p>
              法的手段はありません。
            </p>
          </li>
          <li>
            <p>
              PLAYHERAは、技術的または法的問題のためにトーナメントの適切かつ秩序ある実行が不可能な場合、いつでも予告なしにトーナメントをキャンセルまたは終了する権利を留保します。
            </p>
          </li>
        </ol>

        <p>
          賞品やスポンサートーナメントに関する質問や詳細については、サポートチーム
          <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
          までお問い合わせください。
        </p>

        <p class="text-center"><b>付録1</b></p>

        <h2 class="text-center">追加のアプリ規約</h2>

        <p>
          本規約に記載されている条件に加えて、アプリの使用には次の条件が適用されます。
        </p>

        <p>
          この付録1の目的として、「<b>Appstoreプロバイダー</b>」とは、アプリをダウンロードしたアプリストアのプロバイダーを意味します（例えばApple
          のAppstoreからアプリをダウンロードした場合はAppleがAppstoreプロバイダーであり、Google
          Playからアプリをダウンロードした場合はGoogle
          がAppstoreプロバイダーです）。
        </p>

        <ol>
          <li>
            <p>
              あなたは本規約がAppstoreプロバイダーとではなく、あなたとPlayheraの間で締結されたことを認め、同意します。またあなたは、Appstore
              プロバイダーがアプリとそのコンテンツに対して責任を負わないことを認め、同意します。
            </p>
          </li>
          <li>
            <p>
              あなたは、Appstore
              プロバイダーがアプリに関するメンテナンスまたはサポートを提供する義務を負わないことを認め、同意します。アプリの使用中に問題が発生した場合は、<a
                :href="`mailto:${mailSupport}`"
                >{{ mailSupport }}</a
              >
              からPlayheraに連絡してください。
            </p>
          </li>
          <li>
            <p>
              アプリが本規約によって提供される製品保証に適合しない場合、Appstoreプロバイダーは、アプリを購入するために支払った価格（もしある場合）の払い戻しをあなたにする場合があります。Appstoreプロバイダーは、法律で許可される最大限の範囲で、アプリに関してユーザーへの一切の義務を負わないものとします。
            </p>
          </li>
          <li>
            <p>
              あなたは、Appstore
              プロバイダーがアプリに関連する可能性のある次のクレームを（これらに限定されない）対処する責任を負わないことを認め、同意します。製造物責任クレーム、アプリが適用される法的要件または規制要件に準拠していないというクレーム、
              消費者保護または同様の法律に基づいて生じるクレーム。
            </p>
          </li>
          <li>
            <p>
              アプリが知的財産権を侵害していると第三者によってクレームが入った場合、Playhera（Appstoreプロバイダーではなく）が、その調査、防御、解決、および免責に対して単独で責任を負うものとします。
            </p>
          </li>
          <li>
            <p>
              あなたは次項を保証し表明します。（i）米国政府の禁輸措置の対象となる国に居住していないか、米国政府によって「テロ支援国」として指定されている国に居住していない。（ii）禁止または制限された米国政府リストに記載されていない。
            </p>
          </li>
        </ol>

        <p>
          AppstoreプロバイダーがAppleである場合、Appleおよびその子会社が本規約の第三受益者であることを認め、同意します。本規約に同意すると、Appleは第三受益者として本規約を施行する権利を有します。
        </p>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Terms',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
};
</script>
