<template>
  <div class="position-relative">
    <ModalBackdrop v-model="isStatusLoading" class="modal-backdrop-shop">
      <Spinner class="spiner-payment" />
    </ModalBackdrop>
    <!-- <SingleProduct
      v-if="productId"
      :payment-status="status"
      :product-prop-id="productId.items[0].card.id"
    /> -->
    <Shop :payment-status="status" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Shop from './Shop.vue';
import Spinner from '@/views/main/components/Spinner.vue';
import ModalBackdrop from '@/components/common/ModalBackdrop.vue';

export default {
  name: 'ShopSuccess',
  components: { Spinner, ModalBackdrop, Shop },
  data() {
    return {
      productId: null,
      iteration: 0,
      status: null,
      isStatusLoading: true,
    };
  },

  created() {
    if (this.$route.query.callback) {
      this.fetchOrder();
    }
  },
  mounted() {
    this.getStatus();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions(['fetchOrderById', 'fetchOrderStatus']),

    fetchOrder() {
      const orderId = this.$route.query.orderId;
      this.fetchOrderById({ id: orderId }).then(res => (this.productId = res));
    },
    checkStatus() {
      this.fetchOrderStatus({
        provider: this.$route.query.providerName,
        id: this.$route.query.tap_id,
      })
        .then(res => {
          if (res === 0 || res === 1) {
            this.status = res;
            clearInterval(this.intervalId);
            this.isStatusLoading = false;
          } else if (this.iteration >= 12) {
            this.status = 2;
            clearInterval(this.intervalId);
            this.isStatusLoading = false;
          } else {
            this.status = res;
            this.iteration++;
          }
        })
        .catch(error => {
          console.error('Error fetching order:', error);
        })
        .finally(() => {});
    },

    getStatus() {
      this.isStatusLoading = true;
      this.iteration = 1;

      if (this.status !== 0 || this.status !== 1) {
        this.intervalId = setInterval(() => {
          this.checkStatus();
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.position-spinner {
  position: absolute;
  bottom: -50%;
  left: 50%;
}
</style>
<style lang="scss">
.modal-backdrop-shop {
  > div {
    width: auto;
  }
  .order-status {
    width: unset !important;
    background-color: transparent !important;
  }
}
</style>
