<template>
  <div
    class="d-flex flex-column align-items-center position-relative w-100 py-4 clan-card"
  >
    <Preloader
      v-if="popularClansIsLoading"
      :is-loading="popularClansIsLoading"
      has-backdrop
    />
    <template v-if="account">
      <LinkClan
        :id="`${clan.id}`"
        :vanity-id="clan.vanityId || clan.vanity_id"
        class="d-flex flex-column align-items-center mb-3"
      >
        <Avatar
          class="clan-card_avatar"
          type="clan"
          :img-url="clan.logo"
          :size="80"
        />
        <TextBlock
          class="d-block mw-100 px-3 mb-0 text-truncate clan-card_name"
          :text="name"
          :size="0.875"
        />
      </LinkClan>
      <FollowBtn class="mx-2 position-relative" :model="account" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AccountModel from '@/models/Account';
import { CLAN } from '@/constants/accounts.js';

import Preloader from '@/components/common/HeraListPreloader';
import Avatar from '@/components/images/Avatar';
import TextBlock from '@/components/atoms/TextBlock';
import FollowBtn from '@/components/common/FollowBtn';
import LinkClan from '@/components/links/Clan';
import { propRequired } from '@/utils/factories';

export default {
  components: { LinkClan, Preloader, Avatar, TextBlock, FollowBtn },

  props: {
    clan: propRequired(Object),
  },
  computed: {
    ...mapGetters(['popularClansIsLoading']),
    name() {
      return this.clan.name || '';
    },
    account() {
      if (!this.clan.id) {
        return null;
      }
      return new AccountModel(CLAN, this.clan.id).update(this.clan);
    },
  },
};
</script>

<style lang="scss">
.clan-card {
  background-color: $ph-card;
  border-radius: 0.25rem;
  height: 12.25rem;
  min-width: 10rem;
  margin-right: 1.25rem;
  padding: 0.75rem;

  &_avatar {
    margin-bottom: 0.75rem;
  }

  &_name {
    margin-bottom: 1.25rem;
  }

  a {
    color: $ph-primary-text;
  }
}
</style>
