import { render, staticRenderFns } from "./ImageRounder.vue?vue&type=template&id=648044cc&"
import script from "./ImageRounder.vue?vue&type=script&lang=js&"
export * from "./ImageRounder.vue?vue&type=script&lang=js&"
import style0 from "./ImageRounder.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ImageRounder.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms&name=ImageRounder&notes=%23%23%20this%20component%20has%20a%20slot%20which%20could%20hold%20any%20other%20component%20inside%20it&knobs=%7B%0A%20%20%20%20fillColor%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('fillColor'%2C%20'%23727288')%0A%20%20%20%20%7D%2C%0A%20%20%20%20size%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('size'%2C%2064)%0A%20%20%20%20%7D%2C%0A%20%20%20%20imgUrl%3A%20%7B%0A%20%20%20%20%20%20%20default%3A%20text('imgUrl'%2C%20'')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports