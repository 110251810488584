<template>
  <div
    class="chart-wrapper chart-wrapper--gauge h-100 w-100 d-flex align-items-center"
    dir="ltr"
  >
    <canvas></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
//eslint-disable-next-line
import GaugeChart from 'chartjs-chart-radial-gauge';
import { prop, propRequired } from '@/utils/factories';
export default {
  name: 'GaugeChart',
  props: {
    data: propRequired(Object),
    options: prop(Object, {}),
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  computed: {
    config() {
      return {
        type: 'radialGauge',
        data: this.data,
        options: {
          responsive: true,
          trackColor: this.$scss.colors.darkBg,
          aspectRatio: 1,
          centerPercentage: 80,
          title: {
            display: false,
          },
          ...this.options,
        },
      };
    },
  },
  mounted() {
    this.chartInstance = new Chart(
      this.$el.querySelector('canvas').getContext('2d'),
      this.config
    );
  },
};
</script>
