var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column position-relative input-multilined",class:[
    ("input-multilined--" + _vm.variant),
    { 'input-multilined--invalid': _vm.error } ]},[_c('WrapperFormControl',_vm._b({},'WrapperFormControl',{
      tooltip: _vm.tooltip,
      required: _vm.required,
      error: _vm.error,
      label: _vm.label,
      hasCounter: _vm.hasCounter,
      maxLengthCounter: _vm.maxlength,
      currentCounter: _vm.value || '',
      hideLabel: _vm.hideLabel,
    },false),[_c('textarea',_vm._g(_vm._b({ref:"textarea",staticClass:"custom-scrollbar input-form",style:({ 'padding-bottom': _vm.paddingBottom }),attrs:{"slot":"control"},slot:"control"},'textarea',{ maxlength: _vm.maxlength, id: _vm.id, value: _vm.value, rows: _vm.rows, placeholder: _vm.placeholder, disabled: _vm.disabled, readOnly: _vm.readOnly },false),_vm.handlers))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }