var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: function (isVisible) { return !_vm.forOrganizer
        ? _vm.handleScrollIntoViewForViewsTracking(
            isVisible,
            _vm.tournament.id,
            'tournament'
          )
        : _vm.$lodash.noop(); },
    intersection: {
      threshold: 0.1,
    },
  }),expression:"{\n    callback: isVisible =>\n      !forOrganizer\n        ? handleScrollIntoViewForViewsTracking(\n            isVisible,\n            tournament.id,\n            'tournament'\n          )\n        : $lodash.noop(),\n    intersection: {\n      threshold: 0.1,\n    },\n  }"}],ref:"tournamentWrapper",staticClass:"position-relative tournament-card-wrapper",class:{
    'tournament-card-wrapper--for-player': !_vm.forOrganizer,
  }},[_c(_vm.linkComponent,_vm._b({tag:"component",staticClass:"d-block position-relative overflow-hidden tournament-card"},'component',Object.assign({}, {id: _vm.tournament.id,
      vanityId: _vm.tournament.vanityId || _vm.tournament.vanity_id},
      (_vm.forOrganizer
        ? {
            to: {
              name: 'TournamentDashboard',
              params: { tournamentID: _vm.tournament.id },
            },
          }
        : null)),false),[_c('Panel',{staticClass:"ph-panel--input-bg position-relative p-4 h-100",attrs:{"variant":['rounded-medium']}},[_c('TextBlock',{staticClass:"text-truncate mx-5 mb-4 text-center",attrs:{"tag":"h4","size":1.125,"line-height":1.4,"text":_vm.tournament.title}}),(_vm.prizeLabel)?_c('div',{staticClass:"d-flex position-relative align-items-center justify-content-end"},[_c('Badge',{staticClass:"inverse p-3 position-absolute tournament-card_prize-pool",attrs:{"text":_vm.prizeLabel}})],1):_vm._e(),_c('div',{staticClass:"tournament-card_img",style:({ backgroundImage: _vm.coverURI })}),_c('div',{staticClass:"d-flex align-items-center justify-content-end position-relative mb-5"},[_c('StatusLabel',{attrs:{"status":_vm.tournament.status,"is-admin":_vm.forOrganizer,"for-tournament":""}})],1),_c('div',{staticClass:"d-flex align-items-center",class:{ 'justify-content-between': _vm.forOrganizer }},[_c('GameLabel',_vm._b({},'GameLabel',{
            tournament: _vm.tournament,
            variant: 'large',
            platformLogoSize: 24,
            gameLogoSize: 24,
          },false))],1),(!_vm.forOrganizer)?_c('div',{staticClass:"row mt-3 pt-2 align-items-center player-context-wrapper"},[_c('TournamentActions',{staticClass:"col-auto",attrs:{"tournament":_vm.tournament,"hide-additional-menu":"","hide-brackets-button":"","team-join-via-modal":"","show-status":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1):_vm._e(),_c('div',{staticClass:"row mt-3 pt-2"},[(_vm.forOrganizer)?[_c('PerformanceIndicator',{staticClass:"col-auto",attrs:{"text":_vm.kpiWidget.label,"current":_vm.kpiWidget.current,"limit":_vm.kpiWidget.max}})]:(_vm.tournament.is_event_organizer_available)?_c('div',{staticClass:"col-auto"},[_c('ListItemAccount',{attrs:{"type":_vm.organizationType,"avatar":_vm.tournament.event_organizer_logo,"name":_vm.tournament.event_organizer_name,"vanity-id":_vm.tournament.event_organizer_vanity_id,"account-id":_vm.tournament.owner,"size":36}})],1):_vm._e(),(_vm.sponsors && _vm.sponsors.length)?_c('div',{staticClass:"col"},[_c('TournamentSponsors',{staticClass:"justify-content-end",attrs:{"sponsors":_vm.sponsors,"height":34}})],1):_vm._e()],2)],1)],1),(_vm.forOrganizer)?_c('span',{staticClass:"position-absolute pt-4 pb-3 h-pr-3 h-mr-2 h-pl-3 tournament-card_menu"},[_c('Icon',{staticClass:" cursor-pointer",class:{ disabled: _vm.isMenuDisabled(_vm.tournament) },attrs:{"role":"button","fill":_vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor,"name":"dots"},on:{"click":function($event){$event.stopPropagation();return _vm.openPopoverMenu($event, _vm.tournament)}}})],1):_vm._e(),_c('PopoverMenu',{attrs:{"closest":_vm.$refs.tournamentWrapper,"position":_vm.optionsPosition,"items":_vm.popoverItems,"float":['top', 'right'],"auto-close":true},on:{"toggle":_vm.onMenuToggle},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('TextBlock',{staticClass:"py-2 my-1",class:{ disabled: item.disabled },attrs:{"variant":item.disabled ? 'muted' : 'primary',"text":item.label},nativeOn:{"click":function($event){return _vm.menuHandler(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }