<template>
  <div
    class="row ph-list"
    :class="this.$route.name === 'clans' ? 'clans-sm' : ''"
  >
    <div
      :class="[
        hideFilters || filtersOnTop ? 'col-12' : 'col-lg-9 col-md-8 col-sm-7',
        filtersOnTop ? 'order-2' : '',
        this.$route.name === 'clans' ? 'pagi-sm' : '',
      ]"
    >
      <Paginated
        v-bind="{
          totalItems,
          hidePagination: !itemsList.length,
          pageSize,
          inContainer: true,
        }"
        class="h-100 w-auto"
        :class="this.$route.name === 'clans' ? 'pagination-sm' : ''"
        @change="fetchList"
      >
        <template slot-scope="{}">
          <template v-if="itemsList.length">
            <div
              v-for="(item, itemIndex) in itemsList"
              :key="`item_${itemIndex}`"
              class="mb-5"
              :class="`col-md-${12 / grid}`"
            >
              <slot :item="item" />
              <!--use skeleton-->
            </div>
          </template>
          <HeraDummyList
            v-else
            v-bind="{
              size: 210,
              iconName: 'empty-search',
              ...dummyProps,
              ...(isSearchLoading
                ? { headerText: $$t('Loading'), subheaderText: '', text: '' }
                : null),
            }"
          />
        </template>
      </Paginated>
    </div>
    <div
      v-if="!hideFilters"
      :class="[
        filtersOnTop ? 'col-12 order-1' : 'col-lg-3 col-md-4 col-sm-5',
        this.$route.name === 'clans' ? 'filter-sm' : '',
      ]"
    >
      <slot name="filterBefore" />
      <Panel
        :variant="
          filtersOnTop
            ? ['transparent']
            : ['default', 'rounded', 'half-paddings']
        "
        :class="{ 'p-0': filtersOnTop }"
      >
        <Filters
          ref="filters"
          :filters="filters"
          :class="{ 'row d-flex align-items-start': filtersOnTop }"
          class="mb-5"
          @input="filtersHandler"
        />
      </Panel>
    </div>
  </div>
</template>

<script>
import { propRequired, prop } from '@/utils/factories';
import listMixin from '@/mixins/paginatedLists';
import {
  addGlobalEventListener,
  removeGlobalEventListener,
} from '@/utils/globalEvent';
import { translate } from '@/mixins/translate';

import Paginated from '@/components/common/Paginated';
import Panel from '@/components/atoms/Panel';
import Filters from '@/components/common/Filters';
import HeraDummyList from '@/components/common/HeraDummyList';

export default {
  name: 'PlayerHeraList',
  components: {
    HeraDummyList,
    Panel,
    Paginated,
    Filters,
  },

  mixins: [...listMixin, translate('_web_list')],
  props: {
    dataSource: propRequired(Function),
    filters: propRequired(Array),
    dummyProps: prop(null),
    hideFilters: prop(Boolean),
    filtersOnTop: prop(Boolean),
  },
  data() {
    return {
      queryParams: {},
      itemsList: [],
      grid: 3,
      isSearchLoading: true,
    };
  },
  created() {
    this.pageSize = 18;
    this.reloadTournaments();
    this.calcGrid();
    addGlobalEventListener('resize.grid_cards', this.calcGrid);
  },
  beforeDestroy() {
    removeGlobalEventListener('.grid_cards');
  },
  methods: {
    fetchList(newPage) {
      this.pageIndex = newPage;
      return this.getPageData(
        'itemsList',
        this.dataSource(
          this.paramsWithPageNumber({
            ...this.queryParams,
          })
        )
      ).then(response => {
        if (response) this.isSearchLoading = false;
      });
      /*// todo check height
      .then(() => {
        this.$nextTick(() => {
        });
      });
      */
    },
    reloadTournaments() {
      this.isSearchLoading = true;
      this.itemsList = [];
      this.pageIndex = 0;
      this.fetchList();
    },
    filtersHandler(data) {
      this.queryParams = this.filters.reduce((acc, { saveAs }) => {
        acc[saveAs] =
          saveAs === 'order_by'
            ? `${data[saveAs].value.prop}:${data[saveAs].value.order}`
            : this.$lodash.get(
                data[saveAs],
                saveAs === 'city' ? 'value' : 'id'
              ) ||
              data[saveAs] ||
              null;
        return acc;
      }, {});
      this.reloadTournaments();
    },
    calcGrid() {
      this.grid = window.innerWidth >= 1100 ? 3 : 2;
    },
  },
};
</script>

<style lang="scss">
.ph-list {
  .tournament-search {
    min-width: 0px !important;
  }
  .input-group input {
    background-color: $new-bg-base !important;
    border: 1px solid $new-border-neutral !important;
    text-overflow: ellipsis !important;
  }
  .input-group {
    margin-top: 28px !important;
    padding-bottom: 12px !important;
  }
  main .content-wrapper > div {
    padding: 0px !important;
  }
  .pagination-wrapper {
    margin-bottom: 6% !important;
  }
  @media screen and (max-width: 768px) {
    .input-group {
      margin-top: 25px !important;
    }
    .float-dropbox.show.float-dropbox-top.regard-top {
      width: 100% !important;
      right: 70 !important;
    }
  }
  .container-dummy > div > div {
    transform: translateY(-5vh);
    .playhera-icon {
      margin-top: 2rem;
    }
  }
}
@media (max-width: 767px) {
  .clans-sm {
    flex-wrap: wrap-reverse;
  }
  .filter-sm {
    margin-bottom: 16px;
  }

  .pagination-sm {
    .pagination-class {
      .hand-smallscreen-r {
        margin-right: 0px !important;
        @include auto-rtl(margin-right, 0px !important);
      }
      .hand-smallscreen-l {
        margin-left: 0px !important;
        @include auto-rtl(margin-left, 0px !important);
      }
    }
  }
}
</style>
