export default {
  data() {
    return {
      filters: {}, //default acc
    };
  },
  created() {
    Object.entries(this.getQueryParams()).forEach(([key, val]) => {
      if (val && key !== 'page') {
        const value = decodeURI(val);
        // todo: dirty code input use string
        this.filters[key] = key === 'name' ? value : value.split(',');
      }
    });
  },
  methods: {
    getQueryParams() {
      return this.$route.query;
    },
    setQueryParams(_params = this.filters) {
      const params = Object.entries(_params).reduce((obj, [key, val]) => {
        obj[key] = encodeURI(`${val}`);
        return obj;
      }, {});

      const queryParams = Object.entries({
        ...this.$route.query,
        ...params,
      }).reduce((obj, [key, val]) => {
        if (val) {
          obj[key] = val;
        }
        return obj;
      }, {});

      //set new query params to url
      this.$router.replace({
        ...this.$router,
        query: queryParams,
      });
    },
  },
};
