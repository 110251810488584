<template>
  <div>
    <HeraFormSwitch
      v-model="fullDoubleElimination"
      v-validate="'required'"
      name="fullDoubleElimination"
      :label="
        $$t('Full double elimination:', 'full-double-elimination-allowed')
      "
      required
      :disabled="isStageFieldDisabled('fullDoubleElimination') || disabled"
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';

import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageFullDESetting',
  components: {
    HeraFormSwitch,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    fullDoubleElimination: {
      get() {
        return this.getBlockConfigurationByKey('full_double', false);
      },
      set(newVal) {
        this.setBlockConfigurationByKey('full_double', newVal);
      },
    },
  },
};
</script>

<style></style>
