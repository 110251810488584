'use strict';

import axios from 'axios';
import qs from 'qs';

export const REGION_STORAGE_KEY = 'region';
const currentRegion = localStorage.getObjectFromItem(REGION_STORAGE_KEY) || {};
const regionStaticDir = currentRegion.id ? currentRegion.id : 'other';
const onboardingVideoName = currentRegion.id
  ? `onboarding_${currentRegion.id}.mp4`
  : 'onboarding_mena.mp4';

export const CONFIG = require(`${process.env.VUE_APP_HERA_ENV}`);
export const BASE_URL = CONFIG.baseURL;
export const SHARE_URL = CONFIG.shareBaseURL;
export const ONBOARDING_VIDEO = CONFIG.onboardingVideoURL + onboardingVideoName;
export const TERMS_LINK = CONFIG.termsLink + regionStaticDir;
export const CONDITIONS_LINK = CONFIG.termsLink + regionStaticDir; // now conditions and terms are combined, CONDITIONS var not used
export const PRIVACY_LINK = CONFIG.privacyLink + regionStaticDir;
export const EMAIL_SUPPORT = currentRegion.support_email;
export const GOOGLE_CLIENT_ID = CONFIG.googleClientId;
export const FIREBASE_API_KEY = CONFIG.firebaseApiKey;
export const FIREBASE_PROJECT_ID = CONFIG.firebaseProjectID;
export const TAP_PUBLIC_KEY = CONFIG.tapPublicKey;
export const GIPHY_PRIVATE_KEY = CONFIG.giphyPrivateKey;
export const SSO_CLIENT_ID = CONFIG.ssoClientId;
export const RECAPTCHA_KEY = CONFIG.reCaptchaKey;
export const FLAGSMITH_ENV = CONFIG.flagsmithEnv;

const paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });

export const API = axios.create({
  baseURL: currentRegion.server ? `${currentRegion.server}api` : BASE_URL,
  paramsSerializer,
});
export const BASE_REGION_URL = currentRegion.server
  ? `${currentRegion.server}api/`
  : BASE_URL;
export const REGIONS_API = axios.create({
  baseURL: BASE_URL,
  paramsSerializer,
});

export default {
  CONFIG,
  BASE_URL,
  BASE_REGION_URL,
  TERMS_LINK,
  CONDITIONS_LINK,
  PRIVACY_LINK,
  EMAIL_SUPPORT,
  API,
  REGIONS_API,
  GOOGLE_CLIENT_ID,
  FIREBASE_API_KEY,
  FIREBASE_PROJECT_ID,
  TAP_PUBLIC_KEY,
  RECAPTCHA_KEY,
  FLAGSMITH_ENV,
};
