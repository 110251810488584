<template>
  <div class="sc-loader d-flex align-items-center justify-content-center">
    <LottieLoader />
  </div>
</template>

<script>
import LottieLoader from '@/components/common/Loader';

export default {
  components: { LottieLoader },
};
</script>

<style lang="scss">
.sc-loader {
  @include fill-border;
  z-index: 7;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
