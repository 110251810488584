<template>
  <MultiRadio
    v-model="model"
    class="tiebreaker-rules-wrapper"
    :class="{ 'tiebreaker-rules-wrapper-safe': safeMode }"
    :items="tieBreakerPresetItems"
    name="tie_breaker_preset"
    :label="$$t('Tie-breaker Rules')"
  >
    <template slot-scope="{ label, val }">
      <div class="text-center h-pr-5">
        <img
          :src="imageUrls[val]"
          width="150"
          height="150"
          class="mb-5 mt-3 h-mr-2"
        />
      </div>
      <TextBlock variant="primary" line-height="1.6rem" class="h-ml-2">
        {{ label }}
      </TextBlock>
      <ol class="my-2 text-muted px-0">
        <template v-for="(rule, index) in tieBreakerPresetTooltips[val]">
          <li
            v-if="rule.trim()"
            :key="`rule_${val}_${index}`"
            class="font-size-sm"
          >
            {{ rule }}
          </li>
        </template>
      </ol>
    </template>
  </MultiRadio>
</template>

<script>
import { mapGetters } from 'vuex';
import { TIE_GAMES } from '@/constants/tournaments';
import { translate } from '@/mixins/translate';
import MultiRadio from '@/components/molecules/form/MultiRadio';
import TextBlock from '@/components/atoms/TextBlock';
import { prop } from '@/utils/factories';
import PUBGpresetImage from '@/assets/images/PUBGpreset.png';
import FORTNITEpresetImage from '@/assets/images/FORTNITEpreset.png';
export default {
  name: 'TieBreakerRules',
  components: {
    TextBlock,
    MultiRadio,
  },
  mixins: [translate('_web_tournament_tie-breaker-rules')],
  props: {
    value: prop(String),
    safeMode: prop(Boolean),
  },
  data() {
    return {
      tieBreakerPresetItems: {
        [TIE_GAMES.PUBG]: this.$$t('PUBG preset:'),
        [TIE_GAMES.PUBG2]: this.$$t('PUBG v.2 preset:'),
        [TIE_GAMES.FORTNITE]: this.$$t('Fortnite preset:'),
      },
      tieBreakerPresetTooltips: {
        [TIE_GAMES.PUBG]: this.$$t(
          `
            Total accumulated kills across all Tournament Games[br]
            Best performing Tournament Game based on total points[br]
            Best performing Tournament Game based on total kills[br]
            Total kills in the last-played Tournament Game[br]
            Placement in the last-played Tournament Game[br]
          `,
          'tie-rules_pubg'
        ).split('[br]'),
        [TIE_GAMES.PUBG2]: this.$$t(
          `
            Total times of winning the first placement across all Tournament Games[br]
            Total accumulated placement points across all Tournament Games[br]
            Total accumulated kills across all Tournament Games[br]
            Placement in the most recent match of the Tournament[br]
          `,
          'tie-rules_pubg2'
        ).split('[br]'),
        [TIE_GAMES.FORTNITE]: this.$$t(
          `
            Total points scored[br]
            Total Victory Royales in the session[br]
            Average eliminations in the session[br]
            Average placement per match in the session[br]
          `,
          'tie-rules_fortnite'
        ).split('[br]'),
      },
    };
  },
  computed: {
    ...mapGetters(['publishedGames']),
    imageUrls() {
      return {
        [TIE_GAMES.PUBG]: PUBGpresetImage,
        [TIE_GAMES.PUBG2]: PUBGpresetImage,
        [TIE_GAMES.FORTNITE]: FORTNITEpresetImage,
      };

      // this.publishedGames
      //   .filter(el => el.name === 'PUBG' || el.name === 'FORTNITE')
      //   .reduce(
      //     (acc, el) => ({
      //       ...acc,
      //       [el.name.toLowerCase()]: el.logo,
      //     }),
      //     {}
      //   );
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss">
.tiebreaker-rules-wrapper {
  .multi-radio {
    align-items: flex-start !important;
  }
  .wrapper-control-label {
    padding: 0.25rem 0;
  }
  .input-control {
    display: block !important;
    flex-grow: 1;
    flex-basis: 50%;
    position: relative;
    max-width: 50%;
  }
  .custom-control-label {
    border: 1px solid $ph-secondary-text;
    border-radius: $ph-medium-radius;
    display: block !important;
    min-height: 325px;
    padding: 0 1.75rem;
    &:before,
    &:after {
      top: calc(1.25rem); // title lineHeight = 1.6 + borderWidth (2px)
    }
    &:before {
      @include auto-rtl(left, 0.75rem);
    }
    &:after {
      @include auto-rtl(left, calc(0.75rem + 5px));
    }
    img {
      filter: grayscale(100%);
      opacity: 0.7;
    }
  }
  .checked {
    .custom-control-label {
      border-color: $ph-accent;
    }
    img {
      filter: unset;
      opacity: 1;
    }
  }
  ol {
    // padding: 0 !important;
    // @include auto-rtl(margin-left, -1.7rem);
    // width: calc(100% + 1.7rem);
    li {
      @include auto-rtl(
        (
          margin-left: 1.2rem,
          padding-left: 0.5rem,
        )
      );
    }
  }

  &-safe {
    .custom-control-label {
      outline: none;
      &:before,
      &:after {
        content: none;
      }
      // .hera-text-block {
      //   margin-left: -1.7rem;
      // }
    }
    .input-control:not(.checked) {
      pointer-events: none; // dirty hack for child <label>
      display: none !important;
    }
  }
}
</style>
