import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  fileDownload,
  simpleGetRequest,
} from '@/api/requests';

const PAYMENTS = 'payments';
const INVOICES = 'invoices';

const getSubscriptions = countryCode =>
  authorizedGetRequest(`${PAYMENTS}/subscriptions/${countryCode}`);

const getSubscriptionsGuest = countryCode =>
  simpleGetRequest(`${PAYMENTS}/subscriptions/?country_id=${countryCode}`);
const getSubscriptionsGuestLocale = () =>
  simpleGetRequest(`${PAYMENTS}/subscriptions/`);

const getExternalSubscriptions = () =>
  authorizedGetRequest(`/subscriptions/external/mysubscriptions`, {
    preventCancelRequest: true,
  });

const getUserSubscriptions = () =>
  authorizedGetRequest(`${PAYMENTS}/mysubscriptions`);

const verifyCard = (accessToken, data) =>
  authorizedPostRequest(`${PAYMENTS}/verify_card`)(
    accessToken,
    data.token ? data : {}
  );

const activateExternalSubscription = ({ code }) =>
  authorizedPutRequest(`subscriptions/external/${code}/activate`);

const unsubscribeFeedback = subscriptionId => (accessToken, data) =>
  authorizedPostRequest(`${PAYMENTS}/${subscriptionId}/unsubscribe_reason`)(
    accessToken,
    data
  );
const subscribe = subscriptionId => (accessToken, data) =>
  authorizedPostRequest(`${PAYMENTS}/${subscriptionId}/subscribe`)(
    accessToken,
    data
  );

const exportInvoice = (accessToken, id, fileName) =>
  fileDownload(accessToken, `${INVOICES}/user/${id}/download`, fileName);

const unsubscribe = subscriptionId =>
  authorizedPostRequest(`${PAYMENTS}/${subscriptionId}/unsubscribe`);

const generateTokenPayfort = subscriptionId => (accessToken, data) =>
  authorizedPostRequest(
    `${PAYMENTS}/payfort/subscription/${subscriptionId}/token`
  )(accessToken, data);

const generateShopTokenPayfort = orderId => (accessToken, data) =>
  authorizedPostRequest(`${PAYMENTS}/payfort/purchase/${orderId}/token`)(
    accessToken,
    data
  );

const checkCoupon = (accessToken, subscriptionId, params) =>
  authorizedGetRequest(`${PAYMENTS}/${subscriptionId}/price`)(accessToken, {
    params,
  });

const getMyVoucherSubscriptions = () =>
  authorizedGetRequest(`${PAYMENTS}/voucher/mysubscriptions`);

const activateVoucherSubscription = ({ code }) =>
  authorizedPutRequest(`${PAYMENTS}/voucher/${code}/activate`);

const getCardsPayfort = () => authorizedGetRequest(`${PAYMENTS}/payfort/cards`);

const savedCardsPayfortPay = orderId => (accessToken, data) =>
  authorizedPostRequest(`${PAYMENTS}/payfort/purchase/${orderId}`)(
    accessToken,
    data
  );
const savedCardsPayfortSubs = subscriptionId => (accessToken, data) =>
  authorizedPostRequest(`${PAYMENTS}/payfort/subscription/${subscriptionId}`)(
    accessToken,
    data
  );
const getPaymentsHistory = () => authorizedGetRequest(`${PAYMENTS}/history`);
const getProcessedInvoicesHistory = () =>
  authorizedGetRequest(`${INVOICES}/user/processed_invoices`);

const postMobilePayment = orderId => (accessToken, data) =>
  authorizedPostRequest(`payments/dcb/purchase/${orderId}/token`)(
    accessToken,
    data
  );
const mobileCharge = () => (accessToken, data) =>
  authorizedPostRequest(`payments/dcb/purchase/charge`)(accessToken, data);
const postMobileSubPayment = subscriptionId => (accessToken, data) =>
  authorizedPostRequest(`payments/dcb/subscriptions/${subscriptionId}/token`)(
    accessToken,
    data
  );
const mobileSubCharge = () => (accessToken, data) =>
  authorizedPostRequest(`payments/dcb/subscriptions/charge`)(accessToken, data);
const transactionResend = () => (accessToken, data) =>
  authorizedPostRequest(`/payments/dcb/verification/resend`)(accessToken, data);
export default {
  generateShopTokenPayfort,
  getSubscriptions,
  unsubscribeFeedback,
  getUserSubscriptions,
  getCardsPayfort,
  verifyCard,
  subscribe,
  unsubscribe,
  checkCoupon,
  getPaymentsHistory,
  getProcessedInvoicesHistory,
  savedCardsPayfortPay,
  exportInvoice,
  activateExternalSubscription,
  getExternalSubscriptions,
  getSubscriptionsGuest,
  getSubscriptionsGuestLocale,
  generateTokenPayfort,
  savedCardsPayfortSubs,
  postMobilePayment,
  mobileCharge,
  postMobileSubPayment,
  mobileSubCharge,
  transactionResend,
  getMyVoucherSubscriptions,
  activateVoucherSubscription,
};
