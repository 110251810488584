var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row ph-list",class:this.$route.name === 'clans' ? 'clans-sm' : ''},[_c('div',{class:[
      _vm.hideFilters || _vm.filtersOnTop ? 'col-12' : 'col-lg-9 col-md-8 col-sm-7',
      _vm.filtersOnTop ? 'order-2' : '',
      this.$route.name === 'clans' ? 'pagi-sm' : '' ]},[_c('Paginated',_vm._b({staticClass:"h-100 w-auto",class:this.$route.name === 'clans' ? 'pagination-sm' : '',on:{"change":_vm.fetchList},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.itemsList.length)?_vm._l((_vm.itemsList),function(item,itemIndex){return _c('div',{key:("item_" + itemIndex),staticClass:"mb-5",class:("col-md-" + (12 / _vm.grid))},[_vm._t("default",null,{"item":item})],2)}):_c('HeraDummyList',_vm._b({},'HeraDummyList',Object.assign({}, {size: 210,
            iconName: 'empty-search'},
            _vm.dummyProps,
            (_vm.isSearchLoading
              ? { headerText: _vm.$$t('Loading'), subheaderText: '', text: '' }
              : null)),false))]}}],null,true)},'Paginated',{
        totalItems: _vm.totalItems,
        hidePagination: !_vm.itemsList.length,
        pageSize: _vm.pageSize,
        inContainer: true,
      },false))],1),(!_vm.hideFilters)?_c('div',{class:[
      _vm.filtersOnTop ? 'col-12 order-1' : 'col-lg-3 col-md-4 col-sm-5',
      this.$route.name === 'clans' ? 'filter-sm' : '' ]},[_vm._t("filterBefore"),_c('Panel',{class:{ 'p-0': _vm.filtersOnTop },attrs:{"variant":_vm.filtersOnTop
          ? ['transparent']
          : ['default', 'rounded', 'half-paddings']}},[_c('Filters',{ref:"filters",staticClass:"mb-5",class:{ 'row d-flex align-items-start': _vm.filtersOnTop },attrs:{"filters":_vm.filters},on:{"input":_vm.filtersHandler}})],1)],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }