<template>
  <!--TODO - TH-7105 FEATURE-->
  <div ref="smiles">
    <button class="btn btn-outline-secondary" type="button">
      <AppIcon name="editable/emoji" />
    </button>
    <Popover
      ref="popover"
      :position="$refs.smiles"
      :closest="popoverClosest"
      :float="[]"
    >
      <span>123</span>
    </Popover>
  </div>
</template>

<script>
import Popover from '@/components/popover/index';
import AppIcon from '@/components/atoms/Icon';

export default {
  name: 'EditableSmiles',
  components: {
    AppIcon,
    Popover,
  },
  props: {
    parentEl: {
      type: Node,
      default: null,
    },
  },
  data() {
    return {
      popoverClosest: null,
    };
  },
  // mounted() {
  //     const $closestElement = (this.parentEl || this.$el.parentElement);
  //     this.popoverClosest = $closestElement.parentElement;
  //
  //     this.popoverClosest.insertBefore(this.$refs.popover, $closestElement);
  // },
  // beforeDestroy() {
  //   this.$refs.popover.remove(); // remove from parentElement
  // },
};
</script>
