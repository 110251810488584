import { upperFirst } from '@/utils/lodashUtils';
import { ACCOUNT, CLAN, ORGANIZATION } from '@/constants/accounts';

/**
 * conversation types
 * @type {string}
 */
export const CONVERSATION_TYPE_DIALOG = 'chat';
export const CONVERSATION_TYPE_GROUP = 'group_chat';
export const CONVERSATION_TYPE_CHANNEL = 'channel';
export const CONVERSATION_TYPE_CLAN = 'clan_chat';

/**
 * get avatar prop `type` for component `@/components/images/Avatar`
 * @param conversationType {string} - conversation type by backend
 * @returns {string}
 */
export const getAvatarTypeByConversationType = conversationType => {
  return conversationType === CONVERSATION_TYPE_CHANNEL
    ? 'channel'
    : conversationType === CONVERSATION_TYPE_GROUP
    ? 'clan'
    : conversationType === CONVERSATION_TYPE_DIALOG
    ? 'user'
    : ''; // defaults `?`
};

/**
 * get avatar prop `type` for component `@/components/images/Avatar`
 * @param conversation {Object} - conversation
 * @returns {string}
 */
export const getAvatarTypeByConversation = ({
  correlation_type,
  conversation_type,
}) => {
  return correlation_type === ORGANIZATION
    ? 'organization'
    : correlation_type === 'none'
    ? 'clan'
    : getAvatarTypeByConversationType(conversation_type);
};

/**
 * get icon prop `name` for component `@/components/atoms/Icon`
 * @param conversationType
 * @returns {string}
 */
export const getIconByConversationType = conversationType => {
  switch (conversationType) {
    case CONVERSATION_TYPE_CHANNEL:
      return 'channel';
    case CONVERSATION_TYPE_CLAN:
      return 'shield-fill';
    case CONVERSATION_TYPE_GROUP:
      return 'group-fill';
  }
};

/**
 * get icon prop `name` for component `@/components/atoms/Icon`
 * @param conversationType
 * @returns {string}
 */
export const getIconByConversation = ({
  correlation_type,
  conversation_type,
}) => {
  return correlation_type === ORGANIZATION
    ? 'bag'
    : getIconByConversationType(conversation_type);
};

/**
 * generate object for router-link by conversationData
 * @returns {Object} - params | Empty object (link to current page)
 */
export const generateLinkByConversationData = conversationData => {
  const type = conversationData.conversation_type;
  const isClanChat = type === CONVERSATION_TYPE_CLAN;
  let linkParams = {};
  if (type === CONVERSATION_TYPE_DIALOG || isClanChat) {
    linkParams = {
      name: ACCOUNT,
      params: {
        accountType: isClanChat ? CLAN : conversationData.correlation_type,
        accountId: isClanChat
          ? conversationData.id
          : conversationData.correlationId,
        vanityId: conversationData.correlationVanityId,
      },
    };
  }
  return linkParams;
};

export const computedStyle = val => {
  return Number.isSafeInteger(val) ? `${val}px` : val === null ? 'unset' : val;
};

export const generateStyle = (prop, val) => {
  const result = {};
  const propUpperFirst = upperFirst(prop);

  if (Array.isArray(val)) {
    Object.assign(result, {
      [`min${propUpperFirst}`]: computedStyle(val[0]),
      [`max${propUpperFirst}`]: computedStyle(val[1]),
    });
  } else {
    const valReplaced = computedStyle(val);

    Object.assign(result, {
      [`${prop}`]: valReplaced,
      [`max${propUpperFirst}`]: valReplaced,
    });
  }

  return result;
};

export const getNumberWord = (number, translate) =>
  translate(`_web_number-${number}`, number.toString());

export const replaceLink = (link, ...props) => {
  return String(link).replace(/\$([0-9]+)/g, (match, num) => {
    return props[num];
  });
};
