var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"py-2 px-6 row no-gutters d-flex align-items-center justify-content-end pt-constrol"},[_c('div',{staticClass:"col pt-footer__label"},[_c('TextBlock',{staticClass:"selected-count",attrs:{"text":_vm.$t('_web_tournament_players_list-selected-count', 'Selected:') +
            _vm.selected}})],1),(_vm.attributesList.length && _vm.showAttributes)?_c('div',{staticClass:"col-3 h-ml-5"},[_c('CustomSelector',{attrs:{"options":_vm.attributesList,"clear-if-selected-all":"","not-selected-by-default":"","label-key":"name","label":_vm.$t('_web_tournament_players_list-assign-attributes', 'Set tags'),"item-translation-key-prefix":"_web_tournament_players-attribute","disabled":!_vm.attributesList.length || !_vm.selected,"dark":""},on:{"close":_vm.attributesCloseHandler},model:{value:(_vm.selectedAttributes),callback:function ($$v) {_vm.selectedAttributes=$$v},expression:"selectedAttributes"}})],1):_vm._e(),(_vm.approvedToList.length && _vm.showApproved)?_c('div',{staticClass:"col-3 h-ml-5"},[_c('HeraFormDropdown',{attrs:{"list":_vm.approvedToList,"label":_vm.$t('_web_tournament_players_list-approve-to', 'Approve to'),"disabled":_vm.isStageFieldDisabled('qualify', _vm.stageInfo) ||
            !_vm.approvedToList.length ||
            !_vm.selected,"variant":"input-like","show-label":"","empty-option":"","empty-text":_vm.$t('_web_tournament_players_list-approve-empty', 'Choose')},on:{"input":_vm.approvedToSelectHandler},scopedSlots:_vm._u([{key:"chosen",fn:function(ref){
            var chosenItem = ref.chosenItem;
return [_vm._v("\n          "+_vm._s(chosenItem.name)+"\n        ")]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('TextBlock',{attrs:{"text":item.name}})]}}],null,false,1995152029),model:{value:(_vm.selectedApprovedTo),callback:function ($$v) {_vm.selectedApprovedTo=$$v},expression:"selectedApprovedTo"}})],1):_vm._e(),(_vm.blocksList.length && _vm.showBlocks)?_c('div',{staticClass:"col-3 h-ml-5"},[_c('HeraFormDropdown',{attrs:{"list":_vm.blocksList,"label":_vm.$t('_web_tournament_players_list-assign-block', 'Assign to group'),"disabled":_vm.isStageFieldDisabled('fill_blocks', _vm.stageInfo) ||
            !_vm.blocksList.length ||
            !_vm.selected,"variant":"input-like","show-label":"","empty-option":"","empty-text":_vm.$t('_web_tournament_players_list-assign-empty', 'Choose')},on:{"input":_vm.assignBlockSelectHandler},scopedSlots:_vm._u([{key:"chosen",fn:function(ref){
            var chosenItem = ref.chosenItem;
return [_vm._v("\n          "+_vm._s(chosenItem.name)+"\n        ")]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('TextBlock',{attrs:{"text":item.name}})]}}],null,false,1995152029),model:{value:(_vm.selectedBlock),callback:function ($$v) {_vm.selectedBlock=$$v},expression:"selectedBlock"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }