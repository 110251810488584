import {
  camelCase,
  cloneDeep,
  merge,
  isArray,
  mapValues,
  // isEmpty,
} from '@/utils/lodashUtils';
import ACCOUNT_ROUTERS from '@/constants/accountNavMenuRoutes';
import {
  PROFILE,
  ORGANIZATION,
  ORGANIZATION_TYPES,
  CLAN,
  TOURNAMENT,
  COUNT_BY_TYPES,
  COUNT_FOLLOWERS,
  COUNT_FOLLOWING,
  COUNT_FRIENDS,
  COUNT_MEMBERS,
  CLAN_ROLES,
  BATTLE,
} from '@/constants/accounts';
import { countReplace } from '@/filters/string.js';

class AccountModel {
  constructor(accountType, accountId) {
    this._id = accountId;
    this._type = ORGANIZATION_TYPES.includes(accountType)
      ? ORGANIZATION
      : accountType;
    this._data = {};
    this.isModel = true;
    this.isProfile = this._type === PROFILE;
    this.isOrganization = this._type === ORGANIZATION;
    this.isClan = this._type === CLAN;
    this.isTournament = this._type === TOURNAMENT;
    this.isBattle = this._type === BATTLE;

    this.socialMetadata = {}; // fix for lists
    return this;
  }

  accountHas(countType, count) {
    return COUNT_BY_TYPES[this['_type']].includes(countType)
      ? { [countType]: +(count || 0) }
      : {};
  }

  get countsStrings() {
    return mapValues(this.counts, count => {
      return countReplace(count);
    });
  }

  get counts() {
    const socialMetadata = this.socialMetadata || {};

    return {
      ...this.accountHas(
        COUNT_FOLLOWERS,
        this.isClan ? this.followersCount : socialMetadata['followed_by']
      ),
      ...this.accountHas(COUNT_FOLLOWING, socialMetadata['following']),
      ...this.accountHas(COUNT_FRIENDS, socialMetadata['friends']),
      ...this.accountHas(
        COUNT_MEMBERS,
        this.isClan ? this.membersCount : socialMetadata['members']
      ),
    };
  }

  updateCounters(countsDiff) {
    if (this.isClan) {
      if (countsDiff.followers) {
        this.followersCount += countsDiff.followers;
      }
      if (countsDiff.members) {
        this.membersCount += countsDiff.members;
      }
    } else {
      const entries = Object.entries(countsDiff);
      entries.forEach(([key, diff]) => {
        this.socialMetadata[key] += diff;
      });
    }

    return this.counts;
  }

  get params() {
    return {
      accountId: this['_id'],
      accountType: this['_type'],
      vanityId: this['vanityId'],
    };
  }

  get routers() {
    return ACCOUNT_ROUTERS[this['_type']];
  }

  get canManage() {
    return this.role === CLAN_ROLES.OWNER || this.role === CLAN_ROLES.DEPUTY;
  }

  get _name() {
    return (
      this.displayName || // profile name (if isVisible)
      this.name || // organization, tournament
      this.login || // profile
      this.title || // clan
      (this.event && this.event.name) || // battle
      this._id // hardcore!
    );
  }

  get _avatar() {
    return encodeURI(this.logo || this.avatar || '');
  }
  set _avatar(value) {
    if (this.isClan) {
      this.logo = value;
    } else {
      this.avatar = value;
    }
  }

  get _cover() {
    const cover = this._type === BATTLE ? this.tournament.cover : this.cover;
    return encodeURI(cover || '');
  }
  set _cover(value) {
    this.cover = value;
  }

  get _games() {
    return isArray(this.games) ? this.games : [];
  }
  get _platforms() {
    // replace platforms from {a: '', b: '', c: ''} to [a, b, c]
    // if (this.isProfile && !isArray(this.platforms)) {
    //   return Object.keys(this.platforms || {}); // todo backend
    // }

    return this.platforms || {};
  }

  get _country() {
    return {
      id: this.countryId,
      name: this.country,
    };
  }

  get _verified() {
    return this.verified || this.approved || this.isAmbassador || false;
  }

  get _canEdit() {
    return this.canEdit || (this.isClan && this.canManage);
  }

  update(data, deep = false) {
    // if (this.isProfile) {
    //   // replace platforms from {a: '', b: '', c: ''} to [a, b, c]
    //   if (data.platforms && !Array.isArray(data.platforms)) {
    //     data.platforms = Object.keys(data.platforms || {}); // todo backend
    //   }
    // }

    this._data = merge(this._data, data);

    if (data.platforms) {
      this._data.platforms = data.platforms;
    }

    Object.entries(data).forEach(([key, value]) => {
      // get/set-variable not replaced [warning]
      this[key.indexOf('_') === 0 ? key : camelCase(key)] =
        typeof value === 'object'
          ? deep
            ? merge({}, this[key], value)
            : cloneDeep(value)
          : value;
    });

    return this;
  }
}

export default AccountModel;
