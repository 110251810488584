import { cancelAllRequests } from '@/api/requestCancel';

export const INITIAL_REQUESTS_TOKENS_STATE = () => ({
  requestsTokens: new Map(),
});

const getters = {
  requestsTokens: state => state.requestsTokens,
  tokensByUrl: state => url => {
    return Array.from(state.requestsTokens.values()).filter(
      ({ requestUrl }) => requestUrl === url
    );
  },
};

const mutations = {
  addToken(state, { requestId, requestUrl, token }) {
    state.requestsTokens.set(requestId, { requestUrl, token });
  },
  deleteToken(state, id) {
    state.requestsTokens.delete(id);
  },
};

const actions = {
  cancelAllRequests,
};

export default {
  state: INITIAL_REQUESTS_TOKENS_STATE(),
  getters,
  mutations,
  actions,
};
