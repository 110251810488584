<template>
  <div class="gif-container">
    <HeraSearchInput
      v-model="searchQuery"
      class-input="gif-search"
      @input="reload"
    />
    <div
      ref="scrollableRef"
      class="custom-scrollbar position-relative"
      @scroll.stop
    >
      <ListLoader
        :is-loading="isLoading"
        :is-empty-list="!isLoading && !gifsList.length"
        :dummy-props="{
          iconName: 'dummy/newsfeed',
          size: 150,
          headerText: $t(
            '_web_players_dummy-list',
            'We couldn\'t find anything.'
          ),
        }"
        @reload="reload"
      >
        <template slot="list">
          <InfiniteScroll
            :in-container="true"
            :list="gifsList"
            list-name="gifs"
            :is-disabled="isLoading"
            :activation-gap="100"
            class="gif-gallery"
            :container-element="$refs.scrollableRef"
            @ScrollToBottom="fetchNewPage"
          >
            <div slot-scope="{}">
              <div
                v-for="gif in gifsList"
                :key="gif.id + Math.random() * Math.floor(99)"
                @click.prevent="addGif(gif)"
              >
                <img
                  :src="gif.url"
                  :alt="gif.title"
                  width="100%"
                  height="auto"
                />
              </div>
            </div>
          </InfiniteScroll>
        </template>
      </ListLoader>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { makeGiphyUrl } from '@/utils';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { GIPHY_PRIVATE_KEY } from '@/api/config';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import ListLoader from '@/components/common/HeraListPreloader';
import HeraSearchInput from '@/components/molecules/form/HeraSearchInput';

const gf = new GiphyFetch(GIPHY_PRIVATE_KEY);
const limit = 10;

export default {
  name: 'GiffsPicker',
  components: { InfiniteScroll, ListLoader, HeraSearchInput },
  props: {},
  data() {
    return {
      isLoading: true,
      offset: 0,
      searchQuery: '',
      trending: [],
      searchResults: [],
    };
  },
  computed: {
    gifsList() {
      return this.searchResults.length ? this.searchResults : this.trending;
    },
  },
  mounted() {
    this.fetchGifs();
  },
  methods: {
    ...mapActions(['errorNotify']),
    async fetchGifs() {
      this.isLoading = true;
      if (!this.searchQuery) {
        const { data: gifs } = await gf.trending({
          offset: this.offset,
          limit,
        });
        this.trending = await this.buildGifs(gifs);
        this.isLoading = false;
      } else {
        const { data: gifs } = await gf.search(this.searchQuery, {
          offset: this.offset,
          limit,
        });
        this.searchResults = await this.buildGifs(gifs);
        this.isLoading = false;
      }
    },
    addGif(data) {
      this.$emit('addGiff', data.id, btoa('giphy;' + data.id));
    },
    buildGifs(data) {
      return data.map(gif => ({
        ...gif,
        url: makeGiphyUrl(gif.id),
      }));
    },
    fetchNewPage() {
      this.offset = this.offset + limit;
      return this.fetchGifs();
    },
    reload() {
      this.offset = 0;
      this.trending = [];
      this.searchResults = [];
      return this.fetchGifs();
    },
  },
};
</script>
