<template>
  <div
    class="d-flex flex-column p-5 settings-widget"
    :style="{
      background: `linear-gradient(0deg, rgba(11, 14, 19, 0.95), rgba(11, 14, 19, 0.95)), ${cover}`,
    }"
  >
    <div class="d-flex">
      <Subheader class="mb-4 flex-grow-1">
        {{ $$t('Tournament settings') }}
      </Subheader>
      <PromotionWidget />
    </div>

    <div class="row mb-4">
      <div class="col-12 col-md-6 w-100">
        <TournamentCard v-bind="{ tournament, forOrganizer: false }" />
        <router-link
          :to="{
            name: 'TournamentSettings',
            params: $route.params,
            hash: '#settings-appearance',
          }"
          class="d-flex justify-content-center mt-4"
        >
          <div>{{ $t('_web_change', 'Change') }}</div>
          <Icon name="arrow-triangle-rounded" :width="8" class="ml-1" />
        </router-link>
      </div>
      <div class="col-12 col-md-6">
        <TournamentAutoSettingsWidget />
      </div>
    </div>
    <div class="row">
      <div class="col-auto mb-3">
        <ParticipantsWidget />
      </div>
      <div class="col-auto">
        <WidgetWrapper
          :title="$t('_web_interactions', 'Interactions')"
          class="bg-transparent d-flex flex-column"
        >
          <PerformanceIndicators
            v-bind="{
              indicators,
              numbersOnly: true,
            }"
          />
        </WidgetWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { translate } from '@/mixins/translate';
import Icon from '@/components/atoms/Icon';
import WidgetWrapper from '@/views/tournaments/management/dashboard/components/WidgetWrapper';
import TournamentCard from '@/components/common/TournamentCard';
import PerformanceIndicators from '@/views/tournaments/management/summaryWidgets/PerformanceIndicatorsSet';
import ParticipantsWidget from '@/views/tournaments/management/dashboard/components/WidgetParticipants';
import PromotionWidget from '@/views/tournaments/management/dashboard/components/WidgetPromotion';
import TournamentAutoSettingsWidget from '@/views/tournaments/management/dashboard/components/WidgetTournamentAutoSettings';
import Subheader from '@/components/atoms/Subheader';

export default {
  name: 'TournamentSettingsWidget',
  components: {
    Subheader,
    WidgetWrapper,
    TournamentCard,
    Icon,
    PerformanceIndicators,
    ParticipantsWidget,
    PromotionWidget,
    TournamentAutoSettingsWidget,
  },
  mixins: [translate('_web_tournament_dashboard')],
  data() {
    return {
      rulesText: '',
      tournament: {},
      views: 0,
      shares: 0,
      profileViews: 0,
    };
  },
  computed: {
    ...mapGetters(['games', 'platforms', 'gameByID', 'currentTournament']),
    cover({ tournament: { cover } }) {
      return `url(${encodeURI((cover || '').replace('.original.', `.500.`))})`;
    },
    gameLogo() {
      return this.$lodash.get(this, 'game.logo') || null;
    },
    game() {
      return this.gameByID(this.currentTournament.info.game);
    },
    platformsLogo() {
      if (!this.currentTournament || !this.currentTournament.info.platforms) {
        return;
      }
      return this.currentTournament.info.platforms.map(el => {
        let platform = this.platforms.find(({ id }) => id === el);
        return this.$lodash.get(platform, 'logo.uri') || null;
      });
    },
    rulesUri() {
      return this.$lodash.get(this.currentTournament, 'info.rules_link');
    },
    hasRules() {
      return this.rulesUri || this.rulesText;
    },
    indicators() {
      return [
        {
          label: this.$$t('Reach'),
          current: this.views,
        },
        {
          label: this.$$t('Views'),
          current: this.profileViews,
        },
        {
          label: this.$$t('Shares'),
          current: this.shares,
        },
      ];
    },
  },
  watch: {
    'currentTournament.info.event_organizer'() {
      this.initTournament();
    },
    'currentTournament.status'(value) {
      this.tournament.status = value;
    },
  },
  created() {
    if (this.currentTournament.info.event_organizer) this.initTournament();

    this.fetchTournamentRulesText(this.$route.params.tournamentID)
      .then(response => {
        this.rulesText = response.rules;
      })
      .catch(this.errorNotify);

    this.fetchStatisticTournamentPromotion(this.$route.params.tournamentID)
      .then(response => {
        this.views = response.views;
        this.shares = response.shares;
        this.profileViews = response.profileViews;
      })
      .catch(this.errorNotify);
  },
  methods: {
    ...mapActions([
      'fetchTournamentRulesText',
      'fetchStatisticTournamentPromotion',
      'openModal',
      'closeModal',
    ]),
    initTournament() {
      this.tournament = {
        ...this.currentTournament.info,
        ...this.currentTournament,
        tournament_end: this.currentTournament.schedule.tournament_end,
        tournament_start: this.currentTournament.schedule.tournament_start,
        squad_size: this.currentTournament.players_config.squad_size,
        logo: this.currentTournament.logo || this.currentTournament.images.logo,
        cover:
          this.currentTournament.cover || this.currentTournament.images.cover,
        portrait_cover:
          this.currentTournament.images.portrait_cover ||
          this.currentTournament.cover ||
          this.currentTournament.images.cover,
        sponsor_logo: this.currentTournament.prize.sponsors,
        event_organizer_name: this.currentTournament.info.event_organizer.name,
        event_organizer_logo: this.currentTournament.info.event_organizer.logo,
        is_event_organizer_available: this.currentTournament.info
          .event_organizer.isAvailable,
      };
    },
    /*    openRulesModal() {
      this.openModal({
        name: 'TournamentRules',
        props: {
          rulesText: this.rulesText,
          rulesLink: this.rulesUri,
        },
        events: {
          cancel: this.closeModal,
        },
        settings: {
          variant: 'fit-content',
        },
      });
    },*/
  },
};
</script>

<style lang="scss" scoped>
.settings-widget {
  border-radius: $ph-pre-medium-radius;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
</style>
