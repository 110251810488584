import '@babel/polyfill';
import 'intersection-observer';
import 'geometry-polyfill';
import '@/utils/extends';

import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import store from './store';

import * as lodash from '@/utils/lodashUtils';
import constants from '@/constants/getConstant';

import router from './router';
import { Validator, Rules } from 'vee-validate';
import VeeValidate from 'vee-validate';
import Notifications from 'vue-notification';
import vuexI18n from 'vuex-i18n';
import VueTheMask from 'vue-the-mask';
import Croppa from 'vue-croppa';
import VueAnime from 'vue-animejs';
import VueScrollTo from 'vue-scrollto';
import ScrollSync from 'vue-scroll-sync';
import VueObserveVisibility from 'vue-observe-visibility';
import interceptors from '@/api/interceptors';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import Datetime from 'vue-datetime';
import FlagsmithPlugin from '@/mixins/flagsmith';

import { WE_HAVE_USER_LOCALE } from '@/api/user-locale';
import { API, FLAGSMITH_ENV } from '@/api/config';
import api from '@/api';
import layouts from '@/layouts/';
import messagesEnCustom from './strings/en.js';
import messagesAr from './strings/ar.js';
import messagesJa from './strings/ja.js';
// TODO: delete fr and ko languages https://isddesign.atlassian.net/browse/TH-7090
import messagesFr from './strings/fr.js';
import messagesKo from './strings/ko.js';
//
// Init global filters
//
import filters from './filters/';
import directives from './directives/common';
import config from './config';
import { CONFIG } from './api/config';
import { isEmpty } from '@/utils/lodashUtils';

// Don't replace with static import! Needs for safari correct work
import(/* webpackChunkName: "icons-sprite" */ './icons');

API.interceptors.response.use(
  interceptors.onResponseSuccess,
  interceptors.onTokenFailure.bind({ $store: store, $router: router })
);

layouts.forEach(layout => {
  Vue.component(layout.name, layout.component);
});

Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

Vue.prototype.$lodash = Object.freeze(lodash);
Vue.prototype.$config = Object.freeze({ ...config, ...CONFIG });
Validator.extend('after', {
  ...Rules.after,
  // copied from vee-validate source code
  getMessage(field, ref) {
    const target = ref[0];
    const inclusion = ref[1];
    // custom message target handling
    return (
      'The ' +
      field +
      ' must be after ' +
      (inclusion ? 'or equal to ' : '') +
      filters.localDate(target)
    );
  },
});
Validator.extend('before', {
  ...Rules.before,
  // copied from vee-validate source code
  getMessage(field, ref) {
    const target = ref[0];
    const inclusion = ref[1];
    // custom message target handling
    return (
      'The ' +
      field +
      ' must be before ' +
      (inclusion ? 'or equal to ' : '') +
      filters.localDate(target)
    );
  },
});
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  locale: 'en',
  dictionary: {
    en: { messages: messagesEnCustom },
    ar: { messages: messagesAr },
    fr: { messages: messagesFr },
    ko: { messages: messagesKo },
    ja: { messages: messagesJa },
  },
  inject: false,
});

const vuexI18nOptions = {
  moduleName: 'i18n',
};

/*
to turn on auto translation mode
add in localStorage `translateMode`. Ex:
localStorage.setItem('translateMode', 1);
*/
if (localStorage.hasOwnProperty('translateMode')) {
  let translatingInProgress = false;
  const translatePromises = [];
  const translateKeys = new Set();
  const putTranslates = () => {
    const translateArguments = translatePromises.splice(0, 1)[0];

    api.translations
      .putTranslation(translateArguments.splice(0, 1)[0])(
        store.getters.accessToken,
        ...translateArguments
      )
      .finally(() => {
        if (translatePromises.length) {
          putTranslates();
        } else {
          translatingInProgress = false;
        }
      });
  };

  vuexI18nOptions.onTranslationNotFound = (locale, key, value) => {
    if (
      (locale === 'en' || locale === 'en-US') &&
      key &&
      value &&
      !translateKeys.has(key)
    ) {
      translateKeys.add(key);
      translatePromises.push([
        locale,
        { [key]: value },
        { headers: { 'Content-Type': 'application/json' } },
      ]);

      if (!translatingInProgress && store.getters.accessToken) {
        translatingInProgress = true;
        putTranslates();
      }
    }
    return `${key}`;
  };
}

Vue.use(VueCompositionAPI);

Vue.use(vuexI18n.plugin, store, {
  ...vuexI18nOptions,
});

Vue.use(Datetime);
Vue.use(VueScrollTo);
Vue.use(ScrollSync);
Vue.use(VueAnime);
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(Croppa);
Vue.use(VueClipboard);
Vue.use(PortalVue);
Vue.use(VueObserveVisibility);
Vue.use(constants);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAH6fkES4pXC0BeDd0ZD088-yvD4Q3h45c',
    libraries: 'places', // This is required if you use the Autocomplete plugin {'places,drawing', 'places,drawing,visualization'}
  },
});

Vue.use(FlagsmithPlugin, {
  environmentID: FLAGSMITH_ENV,
});

for (let name in filters) {
  Vue.filter(name, filters[name]);
}
Vue.prototype.$filters = Vue.options.filters;
for (let name in directives) {
  Vue.directive(name, directives[name]);
}

window.addEventListener('storage', ({ key, newValue }) => {
  const tokenKeys = ['refreshToken', 'accessToken', 'tempToken'];
  if (!tokenKeys.includes(key) || store.getters[key] === newValue) {
    return;
  }

  if (!newValue) {
    router.push({ name: 'signout' });
  } else {
    const token = JSON.parse(newValue);
    store.commit('setTokenInStore', { tokenKey: key, token });
  }
});

const vm = new Vue({
  router,
  store,
  created() {
    API.interceptors.request.use(interceptors.onRequestSuccess.bind(this));
    API.interceptors.response.use(
      interceptors.onResponseSuccess.bind(this),
      interceptors.onResponseError.bind(this)
    );
    this.$store
      .dispatch('fetchLocales')
      .then(locales =>
        Promise.all(
          locales.map(locale =>
            api.translations
              .getTranslationByLocale(locale.key.toLowerCase())()
              .catch(error => this.$store.dispatch('errorNotify', error))
          )
        )
      )
      .then(results => {
        if (results.every(item => !item)) {
          throw Error(
            this.$t(
              '_web_errors_no-translations',
              "Can't get any translation (fallback to English locale only)"
            )
          );
        } else {
          return new Promise(resolve => {
            results.forEach((translation, index) => {
              if (translation && translation.data) {
                this.$i18n.add(
                  this.$store.getters.locales[index].key,
                  translation.data.translations
                );
              }
            });
            resolve();
          })
            .then(() => {
              const { currentLocale, locales } = store.getters;
              const userLocale = WE_HAVE_USER_LOCALE(locales);
              const DEFAULT_LOCALE = !isEmpty(userLocale)
                ? userLocale.key
                : 'en';
              const wantedLocale = !isEmpty(currentLocale)
                ? currentLocale.key
                : DEFAULT_LOCALE;
              this.$i18n.set(wantedLocale);
              this.$validator.localize(wantedLocale);
            })
            .catch(error => {
              throw error;
            });
        }
      })
      .catch(error => {
        this.$store.dispatch('errorNotify', error);
        this.$i18n.set('en');
        this.$validator.localize('en');
      })
      .finally(() => {
        vm.$mount('#app');
      });
  },
  render: h => h(App),
});
