import api from '@/api';
import { isEqual } from '@/utils/lodashUtils';
import { generateMutations, generateGetters } from '@/utils/store';

const state = {
  regions: [],
  regionLoading: !localStorage.getObjectFromItem('region'),
};
const getters = {
  ...generateGetters('regions'),
  currentCountry: (state, getters) => {
    const region = getters.currentRegion;
    const profile = getters.myProfile;
    return profile ? profile.countryId : region.ip_country;
  },
  regionLoading: state => {
    return state.regionLoading;
  },
};
const mutations = {
  ...generateMutations('regions'),
};
const actions = {
  fetchRegions({ commit, getters: { currentRegion } }) {
    return new Promise((resolve, reject) => {
      api.regions
        .getRegions()()
        .then(response => {
          if (response.data) {
            commit('setRegions', response.data);

            if (currentRegion) {
              //in case some properties were changed for currentRegion, apply them to store
              let region = response.data.find(r => r.id == currentRegion.id);
              if (!isEqual(region, currentRegion)) {
                commit('setRegion', region);
              }
            }
          }
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
