<template>
  <div class="content-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ContentLayout',
};
</script>

<style lang="scss">
.content-layout {
  border-radius: 2px !important;
  position: relative;
}

#{$selector-rtl} .layout-wrapper {
  .row,
  .dir {
    display: flex;
    text-align: right;
  }
}
</style>
