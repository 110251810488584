<template>
  <div
    class="form-group autosuggest m-0"
    :class="[
      `autosuggest-${variant}`,
      {
        'is-invalid': error,
        'is-disabled': disabled,
        'is-required': required,
        'playhera-input': isPrimary,
      },
    ]"
    :aria-invalid="!!error"
  >
    <div
      ref="dropdownActivator"
      class="autosuggest_input-wrapper"
      :class="{ loading, 'red-outline': error }"
    >
      <TextInput
        :id="inputID"
        v-model="searchText"
        class="w-100 country_input"
        type="text"
        :error="error"
        :disabled="disabled"
        :placeholder="placeholderReplaced"
        :tooltip="tooltip"
        :label="label"
        :signup="signup"
        @focus="onFocus"
        @blur="onEsc"
        @keydown.up="onUp"
        @keydown.down="onDown"
        @keydown.enter="onEnter"
        @keydown.esc="onEsc"
      />
      <icon
        v-if="!loading && !error && searchText && searchText.length > 0"
        key="close-icon"
        name="close"
        class="autosuggest_clear-icon"
        width="12"
        @click="clearSearchText"
      />
      <span v-show="error" class="form-text text-danger error-help">{{
        error
      }}</span>
      <PopoverMenu
        :position="dropdownPosition"
        :closest="$el"
        :items="resultsList"
        :float="['center']"
        regard="top"
        class="dropdown-popover py-2"
      >
        <span v-if="resultsList.length === 0" slot="header" class="p-4">{{
          $t('_web_autosuggest_no_results', 'No results')
        }}</span>
        <template slot-scope="{ item, index }">
          <span @mousedown="pickItem(index)">
            <slot name="item" v-bind="{ item }">
              {{ item[displayProperty] }}
            </slot>
          </span>
        </template>
      </PopoverMenu>
    </div>
    <div
      v-if="multiple && !$lodash.isEmpty(pickedItems)"
      class="d-flex flex-wrap w-100 mt-3"
    >
      <AttributeTag
        v-for="(tag, index) in pickedItems"
        :key="tag[displayProperty]"
        :name="tag[displayProperty]"
        @delete="removeTag(index)"
      />
    </div>
  </div>
</template>

<script>
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import icon from '@/components/atoms/Icon';
import { prop } from '@/utils/factories';
import AttributeTag from '@/components/atoms/AttributeTag';
import PopoverMenu from '@/components/popover/Menu';
import TextInput from '@/components/form/HeraCountryInput.vue';
export default {
  name: 'AuthAutosuggestInput',
  components: {
    icon,
    AttributeTag,
    PopoverMenu,
    TextInput,
  },
  model: {
    prop: 'value',
    event: 'resultPicked',
  },
  props: {
    multiple: prop(Boolean),
    error: prop(String),
    signup: prop(Boolean),
    label: prop(String),
    disabled: prop(Boolean),
    required: prop(Boolean),
    showOnFocus: prop(Boolean),
    id: prop(String),
    placeholder: prop(String),
    tooltip: prop(String),
    displayProperty: prop(String, 'text'),
    source: {
      type: null,
      default: () => {},
    },
    value: {
      type: null,
      default: '',
    },
    onlyUnique: prop(Boolean),
    minLength: prop(Number),
    variant: prop(String, 'default'),
  },
  data() {
    return {
      showResults: false,
      searchText: null,
      pickedItems: null,
      selectedItemIndex: -1,
      resultsList: [],
      inputObserver: null,
      inputElement: null,
      enabled: true,
      loading: false,
      tags: [],
      dropdownPosition: null,
    };
  },
  computed: {
    inputID() {
      return this.id ? this.id : 'country_input' + this.getRandomInt(999);
    },
    labelVariant() {
      return this.error ? 'error' : 'secondary';
    },
    placeholderReplaced() {
      return (
        this.placeholder ||
        this.$t('_web_autosuggest_default_placeholder', 'Start typing')
      );
    },
    isPrimary() {
      return this.variant === 'primary';
    },
  },
  watch: {
    searchText(newVal) {
      if (!newVal || !newVal.length) {
        this.clearSearch();
      }
    },
    value(newVal, oldVal) {
      const isEmpty = Array.isArray(oldVal) ? !oldVal.length : !oldVal;
      const isPickedItemsEmpty = Array.isArray(this.pickedItems)
        ? !this.pickedItems.length
        : !this.pickedItems;
      const newSearchText =
        typeof newVal === 'object' && newVal
          ? newVal[this.displayProperty] || ''
          : newVal;
      if (isEmpty && newVal && isPickedItemsEmpty) {
        this.initialize();
      } else if (this.searchText !== newSearchText) {
        this.searchText = newSearchText;
      }
      if (!newVal) {
        this.clearSearchText();
      }
    },
    showResults(newVal) {
      this.togglePopover(newVal);
    },
  },
  created() {
    if (this.value) {
      this.pickedItems = this.value;
      if (this.multiple && Array.isArray(this.value)) {
        this.clearSearch();
        // this.tags = this.tags.concat(this.value.map(el => el[this.displayProperty]))
      } else {
        // TODO - TH-7108 while we dont have fetch country by ID endpoint, show ID (for filter selected items)
        this.searchText =
          typeof this.pickedItems === 'object'
            ? this.pickedItems[this.displayProperty] || ''
            : this.pickedItems;
      }
    }
  },
  mounted() {
    // console.log(this.value.id)
    this.$nextTick(function() {
      if (!this.inputElement) {
        this.inputElement = this.$el.querySelector('.country_input');
      }
      if (!this.inputObserver && this.inputElement) {
        this.inputObserver = fromEvent(this.inputElement, 'input')
          .pipe(
            debounceTime(700),
            map(event => event.target.value)
          )
          .subscribe(value => {
            this.sendRequest(value);
          });
      }
    });
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    removeTag(index) {
      this.pickedItems.splice(index, 1);
      this.$emit('resultPicked', this.pickedItems);
    },
    pickItem(index) {
      index =
        typeof index === 'number' && index > -1
          ? index
          : this.selectedItemIndex;
      if (this.resultsList && this.resultsList[index]) {
        this.enabled = false;
        this.selectedItemIndex = index;
        if (this.multiple) {
          this.pickedItems = this.pickedItems
            ? this.checkUniqueItems(
                this.pickedItems,
                this.resultsList,
                this.selectedItemIndex
              )
            : [].concat([this.resultsList[this.selectedItemIndex]]);
          // this.tags = this.tags.concat([this.resultsList[this.selectedItemIndex]])
          this.clearSearchText();
        } else {
          this.pickedItems = this.resultsList[this.selectedItemIndex];
          this.searchText = this.pickedItems[this.displayProperty];
          this.closeResults();
        }
        this.$emit('resultPicked', this.pickedItems);
        // this.clearSearchText()
      }
    },
    checkUniqueItems(pickedItems, resultsList, selectedItemIndex) {
      let item = resultsList[selectedItemIndex];
      if (this.onlyUnique) {
        const isDuplicateItem = pickedItems.some(function(elem) {
          return elem.id === resultsList[selectedItemIndex].id;
        });
        if (isDuplicateItem) {
          item = null;
        }
      }
      if (item) {
        pickedItems.push(item);
      }
      return pickedItems;
    },
    initialize() {
      this.pickedItems = this.value;
      this.searchText = this.pickedItems[this.displayProperty];
    },
    clearSearch() {
      if (!this.multiple) {
        this.pickedItems = null;
        this.$emit('resultPicked', null);
      }
    },
    clearSearchText() {
      this.searchText = null;
      this.closeResults();
      if (!this.multiple) {
        this.$emit('resultPicked', null);
      }
    },
    closeResults() {
      this.showResults = false;
    },
    onUp() {
      if (this.resultsList.length > 0) {
        this.selectedItemIndex =
          this.selectedItemIndex < 1
            ? this.resultsList.length - 1
            : this.selectedItemIndex - 1;
      }
    },
    onDown() {
      if (this.resultsList.length > 0) {
        this.selectedItemIndex =
          this.selectedItemIndex < 0 ||
          this.selectedItemIndex === this.resultsList.length - 1
            ? 0
            : this.selectedItemIndex + 1;
      }
    },

    onFocus() {
      if (this.showOnFocus) {
        // this.togglePopover(true);
        this.showResults = true;
        this.sendRequest(this.searchText !== null ? this.searchText : 'all');
      }
    },
    onEnter() {
      if (this.resultsList.length && this.selectedItemIndex > -1) {
        this.pickItem();
      }
    },
    onEsc() {
      if (!this.pickedItems) {
        this.clearSearchText();
      }
      var self = this;
      setTimeout(() => {
        self.closeResults();
      }, 150);
    },
    sendRequest(value) {
      if (
        !this.enabled &&
        (!this.pickedItems ||
          (this.pickedItems &&
            this.pickedItems[this.displayProperty] !== value))
      )
        this.enabled = true;

      if (Array.isArray(this.source)) {
        this.resultsList = this.source.filter(
          item =>
            item[this.displayProperty]
              .toLowerCase()
              .indexOf(value.toLowerCase(), 0) === 0
        );
        this.showResults = true;
        return;
      }

      if (
        (this.searchText && this.searchText.length >= this.minLength) ||
        !this.minLength
      ) {
        if (this.enabled && !this.loading) {
          this.loading = true;
          this.enabled = false;
          Promise.resolve(this.source)
            .then(apiCall => {
              if (typeof apiCall === 'function') {
                return apiCall(this.searchText);
              } else {
                throw new Error('Wrong API call prop type');
              }
            })
            .then(response => {
              this.resultsList = response;
              this.showResults = true;
            })
            .catch(error => {
              throw new Error(error);
            })
            .then(() => {
              this.selectedItemIndex = -1;
              this.loading = false;
              this.enabled = true;
            });
        }
      } else {
        this.showResults = false;
      }
    },
    togglePopover(isShow) {
      isShow
        ? (this.dropdownPosition = this.$refs.dropdownActivator)
        : (this.dropdownPosition = null);
    },
  },
};
</script>
<style lang="scss" scoped>
.autosuggest_clear-icon {
  position: relative;
  padding: 0.25rem 0;
}
.dropdown-popover {
  width: 100%;
  min-width: 380px;
  max-width: 25rem;
  background-color: $new-bg-base;
  border: 1px solid;
  border-color: $new-border-neutral;

  @media (max-width: 650px) {
    min-width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
}
.red-outline {
  border-color: red;
}
</style>
