<template>
  <span class="h-text-secondary" :style="{ fontSize: `${size}rem` }">
    <slot>{{ text }}</slot>
  </span>
</template>

<script>
export default {
  name: 'SecondaryText',
  props: {
    text: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/Text"
  name="SecondaryText"
  knobs="{
    text: {
      default: text('Text', 'Text for label')
    },
    size: {
      default: number('Size', 1)
    },
  }"
>
  <SecondaryText v-bind="{text, size}"/>
</story>
