var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hr-post-wrapper custom-scrollbar"},[_c('Panel',{staticClass:"position-relative hr-post",attrs:{"variant":"secondary-card"}},[(_vm.isPinning)?_c('Loader'):_vm._e(),(_vm.isDotsMenuVisible && _vm.isAuthorized)?_c('AppIcon',{staticClass:"cursor-pointer post-dots position-absolute py-2",attrs:{"name":"dots","fill":_vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor},on:{"click":_vm.openPopoverMenu}}):_vm._e(),(_vm.post.pinned || _vm.canPinPost)?_c('AppIcon',{staticClass:"cursor-pointer position-absolute post-pin",class:{ disabled: !_vm.canPinPost },attrs:{"name":"pin","width":"15","fill":_vm.post.pinned ? _vm.$scss.primaryColor : _vm.$scss.heraMuted,"hover-fill":_vm.$scss.hoverColor},on:{"click":_vm.pinAction}}):_vm._e(),_c('HRPostCard',_vm._b({attrs:{"hide-cover":_vm.hideCover}},'HRPostCard',{ post: _vm.post, type: _vm.type },false)),(_vm.post.original_post)?_c('HRPostCard',{staticClass:"h-ml-5 h-pl-4",attrs:{"hide-cover":_vm.hideCover,"post":_vm.post.original_post.post,"type":_vm.post.original_post.post_type,"reposted":""}}):_vm._e()],1),_c('hr',{staticClass:"hr-hr m-0"}),_c('div',{staticClass:"d-flex justify-content-between mt-5 post-actions hr-post-actions"},[_c('div',{staticClass:"d-flex w-75"},[_c('a',{staticClass:"like-link h-ml-5",class:{ active: _vm.post.reactions.like.reacted },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.like($event)}}},[(
            _vm.post &&
              _vm.post.reactions &&
              _vm.post.reactions.like &&
              !_vm.post.reactions.like.reacted
          )?_c('img',{staticClass:"likes",attrs:{"src":require("@/assets/images/comments/like-empty.svg"),"alt":"like button"}}):_c('img',{staticClass:"like-button",attrs:{"src":require("@/assets/images/comments/like-filled.svg"),"alt":"like button"}}),_c('span',{staticClass:"like-count-post",class:{
            'like-count-reacted':
              _vm.post &&
              _vm.post.reactions &&
              _vm.post.reactions.like &&
              _vm.post.reactions.like.reacted,
          }},[_vm._v("\n          "+_vm._s(_vm.post.reactions.like.count)+"\n          "+_vm._s(_vm.isRTL
              ? _vm.post.reactions.like.count > 10
                ? _vm.$t('_web_comment_likes', 'likes')
                : _vm.$t('_web_comment_like', 'like')
              : _vm.post.reactions.like.count === 1
              ? _vm.$t('_web_comment_like', 'like')
              : _vm.$t('_web_comment_likes', 'likes')))])]),_c('a',{staticClass:"h-ml-5 comments-link",class:{ active: _vm.post.comments_count.length > 0 },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showComments = !_vm.showComments}}},[_c('AppIcon',{staticClass:"comments",attrs:{"name":"comments","width":"17"}}),_c('span',{staticClass:"actions-label"},[_vm._v(_vm._s(_vm.post.comments_count || 0)+"\n          "+_vm._s(_vm.$t('_web_comments', 'comments'))+"\n        ")])],1),_c('a',{staticClass:"h-ml-5 repost-link",class:{ active: _vm.post.repost_count > 0 },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function ($event) { return _vm.openSharingMenu({
              event: $event,
              type: 'post',
              id: _vm.post.id,
              text: _vm.post.text,
            }); })($event)}}},[_c('AppIcon',{staticClass:"repost",attrs:{"id":"share-icon","name":"share","width":"15"}}),_c('span',{staticClass:"actions-label",on:{"click":_vm.shareClick}},[_vm._v("\n          "+_vm._s(_vm.post.repost_count || 0)+"\n          "+_vm._s(_vm.$t('_web_comments_shares', 'shares'))+"\n        ")])],1)])]),_c('div',{staticClass:"ml-5 hr-comments-wrap"},[_c('Comments',{key:_vm.post.id,attrs:{"post":_vm.post}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }