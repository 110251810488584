import { render, staticRenderFns } from "./Subheader.vue?vue&type=template&id=143c8caa&"
import script from "./Subheader.vue?vue&type=script&lang=js&"
export * from "./Subheader.vue?vue&type=script&lang=js&"
import style0 from "./Subheader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Subheader.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2FText&name=Subheader&notes=%23%23%20this%20component%20has%20a%20slot%20which%20could%20hold%20any%20other%20component%20inside%20it&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Inner%20text'%2C%20'I%20am%20a%20text%20in%20the%20subheader%20%3B)')%0A%20%20%20%20%7D%2C%0A%20%20%20%20variant%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('variant'%2C%20%5B'strong'%2C%20'em'%2C%20'normal'%2C%20'muted'%2C%20'secondary'%2C'hr-secondary'%5D%2C%20'normal')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports