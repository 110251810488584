<template>
  <div class="w-100">
    <section class="">
      <form
        class="remove-autofill-styles d-flex flex-column"
        @submit.prevent="formSubmit"
        @input="clearCustomError"
      >
        <div class="signup-container">
          <div class="signup-title text-center">
            <h1 class="font-size-32 m-0 mb-3">
              {{ $t('_web_join', 'Join PLAYHERA') }}
            </h1>
            <h1 style="font-size: 1rem; font-weight: 500" class="m-0">
              {{ $t('_web_already_have', 'Already have an account?') }}
              <router-link
                style="
                  color: white;
                  font-weight: 500;
                  text-decoration: underline;
                "
                :to="{ name: 'signin' }"
              >
                {{ $t('_web_already_login', 'Login') }}</router-link
              >
            </h1>
          </div>
          <div v-if="customError" class="text-danger">
            <!-- Server error -->
            {{
              customError &&
                $$t(
                  'Something went wrong.',
                  '_web_server_errors_internal_server_error'
                )
            }}
          </div>
          <div class="input-container">
            <InputUsername
              v-model.trim="login"
              v-validate="'required|usernamePattern'"
              :placeholder="$t('_web_username_placeholder', 'Username')"
              :name="$t('_web_tournament_players_column-username', 'username')"
              type="text"
              :tabindex="0"
              required
              :label="$$t('Username', 'username-label') + '*'"
              :error="
                errors.first(
                  this.$t('_web_tournament_players_column-username', 'username')
                )
              "
              @input="
                value =>
                  debounceCredentials(
                    this.$t(
                      '_web_tournament_players_column-username',
                      'username'
                    ),
                    value
                  )
              "
            />
            <InputEmail
              v-model.trim="email"
              v-focus
              v-validate="'required|email|max:128'"
              :placeholder="$t('_web_email_placeholder', 'your@email.com')"
              :name="$$t('Email', 'email-label')"
              type="email"
              :label="$$t('Email', 'email-label') + '*'"
              :tabindex="0"
              autofocus
              required
              :error="errors.first(this.$$t('Email', 'email-label'))"
              @input="
                value =>
                  debounceCredentials(this.$$t('Email', 'email-label'), value)
              "
            />
            <InputPassword
              v-model.trim="password"
              v-validate="'required|min:8|max:64'"
              :placeholder="$t('_web_password_placeholder', 'Password')"
              :name="$t('_web_password_placeholder', 'Password')"
              :tabindex="0"
              required
              :label="$t('_web_choose_password', 'Choose your password') + '*'"
              :error="errors.first($t('_web_password_placeholder', 'Password'))"
            />
            <Country
              v-model="country"
              v-validate="'required'"
              :label="$t('_web_country-label', 'Where are you from?') + '*'"
              :name="$t('_web_country_error', 'country')"
              variant="primary"
              :tabindex="2"
              required
              :error="errors.first($t('_web_country_error', 'country'))"
            />
            <HeraFormCheckbox
              v-model="agreements"
              v-validate="'required'"
              class="new-checkbox m-0"
              :name="$t('_web_signup_agreement', 'agreement')"
              :tabindex="0"
              :error="errors.first($t('_web_signup_agreement', 'agreement'))"
              :val="true"
            >
              <TextWithLinks
                :content="
                  $t(
                    '_web_signup_agreement_label_new',
                    'I agree to {:terms} and {:privacy}.'
                  )
                "
                :content-props="agreementsTextProps"
                class=""
              />
            </HeraFormCheckbox>

            <invisible-recaptcha
              class="text-truncate new-signup-btn"
              :sitekey="recaptchaKey"
              :validate="() => formSubmit"
              :callback="formSubmit"
              :disabled="submitIsDisabled"
              :loading="isLoading"
              >{{ $t('_web_register', 'Register') }}
            </invisible-recaptcha>

            <div class="d-flex row justify-content-center align-items-center">
              <GoogleSignIn />
              <TwitterSignIn />
              <AppleSignIn />
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { Validator } from 'vee-validate';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { translate } from '@/mixins/translate';
import isLoading from '@/mixins/isLoading.js';
import {
  PRIVACY_LINK,
  TERMS_LINK,
  RECAPTCHA_KEY,
  GOOGLE_CLIENT_ID,
  FIREBASE_API_KEY,
  FIREBASE_PROJECT_ID,
} from '@/api/config';
import InputEmail from '@/components/form/InputEmail';
import InputUsername from '@/components/form/InputUsername';
import InputPassword from '@/components/form/InputPassword';
import HeraFormCheckbox from '@/components/form/HeraFormCheckBoxAuth';
import TextWithLinks from '@/components/text/TextWithLinks';
import Country from '@/components/form/HeraCountrySelect.vue';
import GoogleSignIn from '@/components/auth/GoogleSignIn';
import TwitterSignIn from '@/components/auth/TwitterSignIn';
import AppleSignIn from '@/components/auth/AppleSignIn';
import InvisibleRecaptcha from 'vue-invisible-recaptcha';

const config = {
  usernameRegex: /^[a-z0-9_]+$/i,
};
const DEBOUNCE_TIME = 500; // ms
let routerNameFrom;

export default {
  name: 'SignUp',
  components: {
    Country,
    InputEmail,
    InputUsername,
    InputPassword,
    HeraFormCheckbox,
    TextWithLinks,
    GoogleSignIn,
    TwitterSignIn,
    AppleSignIn,
    'invisible-recaptcha': InvisibleRecaptcha,
  },
  inject: ['$validator'],
  mixins: [isLoading, translate('_web_signup')],
  data() {
    return {
      email: null,
      recaptchaKey: RECAPTCHA_KEY,
      login: null,
      password: null,
      country: null,
      agreements: [],
      agreementsTextProps: {
        terms: {
          text: this.$t(
            '_web_footer_terms-and-conditions',
            'Terms, Conditions'
          ),
          colour: 'red',
          href: TERMS_LINK,
        },

        privacy: {
          text: this.$t(
            '_web_signup_privacy_and_policy',
            'Privacy and Cookies policy'
          ),
          href: PRIVACY_LINK,
        },
      },
      haveAccountTextProps: {
        login: {
          text: this.$$t('Login'),
          to: {
            name: 'signin',
          },
        },
      },
      debounceCredentials: this.$lodash.debounce(
        this.checkCredentials,
        DEBOUNCE_TIME
      ),
    };
  },
  computed: {
    ...mapGetters(['templateRegData']),
    formData() {
      return {
        email: this.email,
        login: this.login,
        password: this.password,
        country: this.country && this.country.id,
        by: this.$route.name,
      };
    },
    firebaseConfigured() {
      return Boolean(FIREBASE_API_KEY && FIREBASE_PROJECT_ID);
    },
    googleConfigured() {
      return Boolean(GOOGLE_CLIENT_ID);
    },
    submitIsDisabled() {
      // return !!(
      //   this.$lodash.get(this, 'errors.items.length', 0) || // validator has not error
      //   Object.values(this.formData).find((value) => !value) || // all items of form is not empty
      //   !this.agreements[0]
      // ); // `I agree to ...`
      return !this.agreements[0];
    },

    customError() {
      return this.errors.first('customError');
    },
  },
  beforeRouteEnter(to, { name }, next) {
    routerNameFrom = name;
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'verifyCode') {
      this.saveRegData(null);
    }

    const savedData = { ...this.formData };
    delete savedData.password;
    savedData._countryObject = this.country;
    savedData.agreements = !!this.agreements[0];

    this.saveRegData(savedData);
    this.unsetLoading();
    next();
  },
  created() {
    Validator.extend('usernamePattern', {
      getMessage: this.$t(
        '_web_signup_username_pattern_error',
        'Username must contain only next symbols: {symbols} and {underline}',
        { symbols: '(0-9), (a-z), (A-Z)', underline: '_' }
      ),
      validate: value => config.usernameRegex.test(value),
    });
  },
  mounted() {
    this.closeModal(false);
    this.errors.clear();
    if (routerNameFrom !== 'verifyCode') {
      this.saveRegData(null);
    } else {
      const { templateRegData } = this;

      if (templateRegData && templateRegData.by === this.$route.name) {
        this.password = null;
        this.email = templateRegData.email || '';
        this.login = templateRegData.login || '';
        this.country = templateRegData._countryObject || null;
        this.agreements = [!!templateRegData.agreements];
      }
    }
  },
  methods: {
    ...mapMutations(['saveRegData']),
    ...mapActions(['createUser', 'getCredentials', 'closeModal']),

    formSubmit(token) {
      this.$validator.validateAll();
      Validator.extend('usernamePattern', {
        getMessage: this.$t(
          '_web_signup_username_pattern_error',
          'Username must contain only next symbols: {symbols} and {underline}',
          { symbols: '(0-9), (a-z), (A-Z)', underline: '_' }
        ),
        validate: value => config.usernameRegex.test(value),
      });
      this.checkCredentials(
        this.$t('_web_tournament_players_column-username', 'username'),
        this.login
      );
      const captcha_response = token;
      if (!this.errors.count()) {
        this.setLoading();

        this.$validator
          .validateAll()
          .then(result =>
            result ? Promise.resolve(this.formData) : Promise.reject(result)
          )
          .then(formData => {
            return this.createUser({ ...formData, captcha_response });
          })
          .then(() => {
            this.$router.push({
              name: 'verifyCode',
              params: { from: 'signup' },
            });
          })
          .then(
            window.dataLayer.push({
              event: 'signup',
              username: this.login,
              email: this.email,
              country: this.country.value,
              phone: this.phone,
              from: this.$route.path,
            })
          )
          .catch(error => {
            this.unsetLoading();
            this.$validator.errors.add({
              field: 'customError',
              msg: error,
              rule: 'custom',
              id: 1,
            });
          });
      }
    },

    checkCredentials(field, value) {
      this.$validator.validate(field).then(isValid => {
        if (!isValid) {
          return;
        }
        this.getCredentials(value).then(() => {
          this.$validator.errors.add({
            field,
            msg: `${this.$t('credentials-error', {
              field: field,
            })}`,
            rule: 'custom',
          });
        });
      });
    },
    clearCustomError() {
      this.errors.remove('customError');
    },
  },
};
</script>

<style lang="scss">
.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.input-container {
  width: 25vw;
  min-width: 380px;
  max-width: 25rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
}

.new-signup-btn {
  width: 100%;
  height: 2.5rem;
  background-color: $new-bg-primary !important;
  border-radius: 0.5rem;
  color: white !important;
  border: none;
}
label {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 140% !important;
  color: $new-fg-neutral !important;
}

.signup-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25vw;
  min-width: 380px;
  gap: 0.5rem;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
}

.new-checkbox {
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
  width: 25vw;
  min-width: 380px;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
}

.grecaptcha-badge {
  display: inline-block;
  box-shadow: $new-fg-neutral;
}

.grecaptcha-badge:hover {
  visibility: visible;
}

.grecaptcha-badge > div {
  width: 14px;
  height: 14px;
  margin: 0;
  border-radius: 0.25rem;
  background-color: $new-bg-page;
  border: 1px solid $new-bg-page;
}

.grecaptcha-badge > div > div {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $new-bg-page;
}

.grecaptcha-badge:hover > div {
  background-color: $new-bg-page;
  border-color: $new-bg-page;
}

.grecaptcha-badge:hover > div > div {
  background-color: $new-bg-page;
}
.recaptc {
  width: 452px;
  height: 40px;
  border-radius: 5px;
  color: white;
  background-color: #6300fa !important;
}
</style>
