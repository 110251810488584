<template>
  <div
    class="slider-chat d-flex"
    :class="{
      'slider-chat_show': isShow,
      'slider-chat_full-width': isFullWidth,
    }"
    @scroll.stop
  >
    <div class="slider-chat__first-screen d-flex flex-column">
      <ShowButton />
      <Header
        :state="state"
        @addChannel="addChannel"
        @addGroupChat="addGroupChat"
      />
      <HeraFormTextInput
        v-model="searchMessageFilter"
        :placeholder="'Search'"
      />
      <div class="slider-chat__container">
        <List
          v-if="state === 'chatsList'"
          :key="listKey"
          :search-message-filter="searchMessageFilter"
          @openChat="openChat"
        />
        <ConversationEditor
          v-else-if="isEditorActive"
          :key="state"
          :chat="activeChat"
          :conversation-type="editorMode"
          :participants="activeChannelParticipants"
          :edit-mode="isEditMode"
          @back="hideConversationEditor"
          @delete="hideConversationEditor(true)"
          @fullWidth="fullWidthHandler"
          @setParticipants="setParticipants"
          @deleteParticipant="deleteParticipant"
          @setParticipantsCount="setParticipantsCount"
          @setConversationTitle="setConversationTitle"
        />
        <Chat
          v-else-if="state === 'chat'"
          :chat="activeChat"
          :filter="searchMessageFilter"
          :participants-count="activeChannelParticipantsCount"
          @closeChat="closeChat"
          @editChat="editChat"
        />
      </div>
      <Loader v-if="isLoaderShow" />
    </div>
    <div class="slider-chat__second-screen d-flex flex-column">
      <ParticipantsSelector
        v-if="isFullWidth"
        :selected-participants="activeChannelParticipants"
        @back="fullWidthHandler(false)"
        @addParticipant="addParticipant"
        @updateParticipants="updateParticipants"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { uniqBy } from '@/utils/lodashUtils';
import ShowButton from './ShowButton';
import Header from './Header';
import List from './List';
import ConversationEditor from './ConversationEditor';
import Chat from './Chat';
import ParticipantsSelector from './participants/Selector';
import Loader from './Loader';
import HeraFormTextInput from '@/components/atoms/form/HeraFormTextInput';

const setListKey = () => `sliderchat-list${Date.now()}`;
export default {
  name: 'SliderChat',
  components: {
    ShowButton,
    Header,
    List,
    ConversationEditor,
    Chat,
    ParticipantsSelector,
    Loader,
    HeraFormTextInput,
  },

  data() {
    return {
      searchMessageFilter: '',
      activeChannelParticipants: [],
      activeChannelParticipantsCount: 0,
      isFullWidth: false,
      listKey: setListKey(),
    };
  },
  computed: {
    ...mapGetters({
      state: 'sliderChatState',
      isShow: 'sliderChatIsShow',
      activeChat: 'sliderChatActiveChat',
      isLoaderShow: 'sliderChatIsLoaderShow',
    }),
    tournamentID() {
      return this.$route.params.tournamentID;
    },
    isEditorActive() {
      return (
        this.state === 'addChannel' ||
        this.state === 'editChannel' ||
        this.state === 'addGroupChat' ||
        this.state === 'editGroupChat'
      );
    },
    editorMode() {
      if (this.state === 'addChannel' || this.state === 'editChannel') {
        return 'channel';
      } else if (
        this.state === 'addGroupChat' ||
        this.state === 'editGroupChat'
      ) {
        return 'group_chat';
      } else {
        return null;
      }
    },
    isEditMode() {
      return this.state === 'editChannel' || this.state === 'editGroupChat';
    },
  },
  watch: {
    tournamentID() {
      this.clearState();
    },
    isShow(newVal) {
      if (!newVal && this.isEditorActive) {
        this.clearState();
      }
    },
  },
  beforeDestroy() {
    this.reloadSliderChatState();
  },
  methods: {
    ...mapMutations([
      'setCurrentChatId',
      'clearCurrentChatId',
      'hideSliderChat',
      'setSliderChatState',
      'setSliderChatActiveChat',
      'clearSliderChatActiveChat',
      'setCurrentConversationTitle',
      'reloadSliderChatState',
    ]),
    addChannel() {
      this.setSliderChatState('addChannel');
      this.clearSliderChatActiveChat();
      this.clearCurrentChatId();
    },
    addGroupChat() {
      this.setSliderChatState('addGroupChat');
      this.clearSliderChatActiveChat();
      this.clearCurrentChatId();
    },
    hideConversationEditor(deleted) {
      if (
        this.state === 'addChannel' ||
        this.state === 'addGroupChat' ||
        deleted
      ) {
        this.setSliderChatState('chatsList');
        this.clearSliderChatActiveChat();
        this.clearCurrentChatId();
        this.activeChannelParticipants = [];
        this.activeChannelParticipantsCount = 0;
        this.listKey = setListKey();
      } else if (
        this.state === 'editChannel' ||
        this.state === 'editGroupChat'
      ) {
        this.setSliderChatState('chat');
      }
      this.isFullWidth = false;
    },
    openChat(chat) {
      this.setSliderChatActiveChat(chat);
      this.setCurrentChatId(chat.id);
      this.setSliderChatState('chat');
    },
    closeChat() {
      this.setSliderChatState('chatsList');
      this.clearSliderChatActiveChat(null);
      this.clearCurrentChatId();
    },
    editChat() {
      this.setSliderChatState(
        this.activeChat.conversation_type === 'group_chat'
          ? 'editGroupChat'
          : 'editChannel'
      );
    },
    setParticipants(participants) {
      this.activeChannelParticipants = [...participants];
      this.setParticipantsCount(participants.length);
    },
    fullWidthHandler(fullWidth) {
      this.isFullWidth = fullWidth;
    },
    addParticipant(participant) {
      if (
        this.activeChannelParticipants.find(({ id }) => id === participant.id)
      ) {
        return;
      }

      this.activeChannelParticipants = uniqBy(
        [...this.activeChannelParticipants, participant],
        'id'
      );
    },
    updateParticipants(participants) {
      this.activeChannelParticipants = participants;
    },
    deleteParticipant(participant) {
      const index = this.activeChannelParticipants.indexOf(participant);
      if (index === -1) return;
      this.activeChannelParticipants.splice(index, 1);
    },
    setParticipantsCount(count) {
      this.activeChannelParticipantsCount = count;
    },

    setConversationTitle(title) {
      if (!this.activeChat) return;
      this.setCurrentConversationTitle(title);
    },
    clearState() {
      this.hideSliderChat();
      this.setSliderChatState('chatsList');
      this.clearSliderChatActiveChat();
      this.clearCurrentChatId();
      this.activeChannelParticipants = [];
      this.activeChannelParticipantsCount = 0;
      this.isFullWidth = false;
    },
  },
};
</script>

<style lang="scss">
.slider-chat {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 39.375rem;
  height: 100vh;
  background-color: $ph-card-raw;
  transition: right 0.4s, left 0.4s;

  @include auto-rtl(
    (
      right: -39.375rem,
      border-left: 2px solid $ph-accent-raw,
    )
  );

  &_show {
    @include auto-rtl(
      (
        right: -18.75rem,
        box-shadow: -4px 0px 4px rgba(45, 45, 59, 0.5),
      )
    );
  }

  &_full-width {
    @include auto-rtl(
      (
        right: 0,
      )
    );
  }

  &__container {
    position: relative;
    height: calc(100vh - 3.5rem);
  }

  &__first-screen {
    position: relative;
    width: 20.625rem;
    height: 100vh;
    z-index: 1;
    box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.15);
  }

  &__second-screen {
    position: relative;
    width: 18.75rem;
    height: 100vh;
  }
}
</style>
