<template>
  <div
    class="d-flex flex-column position-relative input-multilined"
    :class="[
      `input-multilined--${variant}`,
      { 'input-multilined--invalid': error },
    ]"
  >
    <WrapperFormControl
      v-bind="{
        tooltip,
        required,
        error,
        label,
        hasCounter,
        maxLengthCounter: maxlength,
        currentCounter: value || '',
        hideLabel,
      }"
    >
      <textarea
        slot="control"
        ref="textarea"
        class="custom-scrollbar input-form"
        v-bind="{ maxlength, id, value, rows, placeholder, disabled, readOnly }"
        :style="{ 'padding-bottom': paddingBottom }"
        v-on="handlers"
      ></textarea>
    </WrapperFormControl>
  </div>
</template>

<script>
import { uniqueId } from '@/utils/lodashUtils';
import { prop, propValidator } from '@/utils/factories';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
const VARIANTS = ['default', 'input-like'];

export default {
  components: {
    WrapperFormControl,
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    error: prop(String),
    label: prop(String),
    value: prop(String),
    maxlength: prop(Number, null),
    variant: propValidator(String, 'default', val => VARIANTS.includes(val)),
    fixed: prop(Boolean),
    rows: prop(Number, 1),
    placeholder: prop(String),
    required: prop(Boolean),
    hasCounter: prop(Boolean),
    disabled: prop(Boolean),
    readOnly: prop(Boolean),
    hideLabel: prop(Boolean),
    tooltip: prop(String),
    paddingBottom: prop(String, '8px'),
  },

  data() {
    return {
      id: uniqueId('textarea_'),
      computedStyles: null,
      minTextareaHeight: 0,
    };
  },

  computed: {
    labelVariant() {
      return this.error ? 'error' : 'secondary';
    },
    handlers() {
      return {
        ...this.$listeners,
        input: this.inputHandler,
        change: this.changeHandler,
      };
    },
  },

  watch: {
    value(newVal) {
      if (newVal !== this.$refs.textarea.value) {
        this.$nextTick(this.setHeight);
      }
    },

    rows() {
      this.$nextTick(() => {
        this.calcMinHeight();
        this.setHeight();
      });
    },
  },

  mounted() {
    this.computedStyles = window.getComputedStyle(this.$refs.textarea);
    this.calcMinHeight();
    this.setHeight();
  },

  methods: {
    setHeight() {
      if (this.fixed) {
        return;
      }

      this.$refs.textarea.style.height = '0';

      let newTextareaHeight =
        this.$refs.textarea.scrollHeight +
        parseInt(this.computedStyles.borderTopWidth) +
        parseInt(this.computedStyles.borderBottomWidth);

      if (this.rows > 1) {
        newTextareaHeight = Math.max(newTextareaHeight, this.minTextareaHeight);
      }

      this.$refs.textarea.style.height = `${newTextareaHeight}px`;
    },

    inputHandler(e) {
      this.$emit('input', e.target.value);
      this.setHeight();
    },

    changeHandler(e) {
      this.$emit('change', e.target.value);
    },

    calcMinHeight() {
      const oldHeight = this.$refs.textarea.style.height;
      this.$refs.textarea.style.height = null;
      this.minTextareaHeight = parseInt(this.computedStyles.height);
      this.$refs.textarea.style.height = oldHeight;
    },

    blur() {
      this.$refs.textarea.blur();
    },
  },
};
</script>

<style lang="scss">
.input-multilined {
  textarea {
    padding: $ph-small-space;
    width: 100%;
    resize: none;
    color: $ph-primary-text;
    transition: border-color 0.3s;
    line-height: 1.125rem;
  }

  // &--default {}

  // &--input-like {}

  &--invalid textarea {
    color: $ph-error;
    border-color: $ph-error;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Molecules/Form"
  name="TextInputMultiline"
  knobs="{
    error: {
      default: text('error', '')
    },
    label: {
      default: text('label', '')
    },
    value: {
      default: text('value', '')
    },
    maxlength: {
      default: number('maxlength', 100)
    },
    variant: {
      default: select('variant', ['default', 'input-like'])
    },
    fixed: {
      default: boolean('fixed', false)
    },
    rows: {
      default: number('rows', 1)
    },
    placeholder: {
      default: text('placeholder', '')
    },
    required: {
      default: boolean('required', false)
    },
    hasCounter: {
      default: boolean('required', false)
    },
  }"
>
  <TextInputMultiline
    v-bind="{ error, label, value, maxlength, variant, fixed, rows, placeholder, required, hasCounter }"
    @input="val => action('input', val)"/>
</story>
