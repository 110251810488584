<template>
  <div class="account-edit clan-edit">
    <div class="row">
      <div class="col col-12">
        <InformationClan />
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <SocialLinks />
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from './panels/SocialLinks';
import InformationClan from '@/views/account/content/edit/panels/InformationClan';

export default {
  name: 'AccountEditClan',
  components: {
    InformationClan,
    SocialLinks,
  },
  props: {
    changeName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.clan-edit {
  .input-form {
    background-color: var(--ph-card);
    font-weight: normal;
  }
}
</style>
