export const getMetaValue = ({ meta, matched }, name) => {
  if (!meta || !matched || !matched.length) {
    return null;
  }

  for (let i = matched.length - 1; i >= 0; i--) {
    let matchedMeta = matched[i].meta;

    if (matchedMeta && matchedMeta.hasOwnProperty(name)) {
      return matchedMeta[name];
    }
  }
  return null;
};
