import api from '@/api';
import { generateMutations, generateGetters } from '@/utils/store';

const state = {
  subscriptions: [],
  userHasActiveSubscription: false,
};
const getters = {
  ...generateGetters('subscriptions', 'userHasActiveSubscription'),
};
const mutations = {
  ...generateMutations('subscriptions', 'userHasActiveSubscription'),
};
const actions = {
  exportInvoice(
    {
      getters: { accessToken },
    },
    { invoiceId }
  ) {
    return api.payments.exportInvoice(
      accessToken,
      invoiceId,
      `${invoiceId}.pdf`
    );
  },

  fetchSubscriptions({
    commit,
    getters: { currentRegion, accessToken },
    rootState: {
      accounts: { my },
    },
  }) {
    return new Promise((resolve, reject) => {
      api.payments
        .getSubscriptions(
          my && my.account && my.account.countryId
            ? my.account.countryId
            : currentRegion.ip_country
        )(accessToken)
        .then(response => {
          if (response.data) {
            commit('setSubscriptions', response.data);
          }
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  fetchSubscriptionsGuest(
    {
      getters: { accessToken },
    },
    region
  ) {
    return new Promise((resolve, reject) => {
      api.payments
        .getSubscriptionsGuest(region)(accessToken)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  fetchSubscriptionsGuestLocale({ getters: { accessToken } }) {
    return new Promise((resolve, reject) => {
      api.payments
        .getSubscriptionsGuestLocale()(accessToken)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },

  fetchExternalSubscriptions({ getters: { accessToken } }) {
    return new Promise((resolve, reject) => {
      api.payments
        .getExternalSubscriptions()(accessToken)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => reject(error));
    });
  },

  fetchUserSubscriptions({ commit, getters: { accessToken } }) {
    return new Promise((resolve, reject) => {
      api.payments
        .getUserSubscriptions()(accessToken)
        .then(({ data }) => {
          if (data.subscriptions && data.subscriptions.length) {
            if (data.subscriptions.find(el => el.status === 'active'))
              commit('setUserHasActiveSubscription', true);
          }
          resolve(data);
        })
        .catch(error => reject(error));
    });
  },
  verifyCard(
    {
      getters: { accessToken },
    },
    query
  ) {
    return api.payments
      .verifyCard(accessToken, query)
      .then(response => response.data);
  },
  collectEmails(state, { email }) {
    return api.notifications
      .collectEmails({ email })
      .then(response => response);
  },
  subscribeToPlan(
    {
      getters: { accessToken },
    },
    { subscriptionId, token, coupon }
  ) {
    return api.payments
      .subscribe(subscriptionId)(accessToken, { token, coupon })
      .then(response => response);
  },

  subscribeToExternalPlan(
    {
      getters: { accessToken },
    },
    { code }
  ) {
    return api.payments
      .activateExternalSubscription({ code })(accessToken)
      .then(response => response);
  },

  sendFeedbackUnsubscribe(
    {
      getters: { accessToken },
    },
    { subscriptionId, cancelation_reason, reason }
  ) {
    return api.payments
      .unsubscribeFeedback(subscriptionId)(accessToken, {
        cancelation_reason,
        reason,
      })
      .then(response => response);
  },
  unsubscribe(
    {
      getters: { accessToken },
    },
    { subscriptionId }
  ) {
    return api.payments
      .unsubscribe(subscriptionId)(accessToken)
      .then(response => response.data);
  },
  getPaymentsHistory(
    {
      getters: { accessToken },
    },
    { query }
  ) {
    return api.payments
      .getPaymentsHistory()(accessToken, { params: query })
      .then(({ data }) => data);
  },
  getUserInvoices({ getters: { accessToken } }) {
    return api.payments
      .getProcessedInvoicesHistory()(accessToken)
      .then(({ data }) => data);
  },
  checkCoupon(
    {
      getters: { accessToken },
    },
    { subscriptionId, coupon }
  ) {
    return api.payments
      .checkCoupon(accessToken, subscriptionId, { coupon })
      .then(({ data }) => data);
  },
  createToken(
    {
      getters: { accessToken },
    },
    { subscriptionId, coupon }
  ) {
    return api.payments
      .generateTokenPayfort(subscriptionId)(accessToken, {
        coupon,
      })
      .then(response => response);
  },

  createTokenShop(
    {
      getters: { accessToken },
    },
    { orderId, coupon }
  ) {
    return api.payments
      .generateShopTokenPayfort(orderId)(accessToken, {
        coupon,
      })
      .then(response => response);
  },
  getCardsPayfort({ getters: { accessToken } }) {
    return api.payments
      .getCardsPayfort()(accessToken)
      .then(({ data }) => data);
  },
  saveCardPayfortPay(
    {
      getters: { accessToken },
    },
    { orderId, card_id, card_security_code, coupon }
  ) {
    return api.payments
      .savedCardsPayfortPay(orderId)(accessToken, {
        card_id,
        card_security_code,
        coupon,
      })
      .then(response => response.data);
  },
  saveCardPayfortPaySub(
    {
      getters: { accessToken },
    },
    { subscriptionId, card_id, card_security_code, coupon }
  ) {
    return api.payments
      .savedCardsPayfortSubs(subscriptionId)(accessToken, {
        card_id,
        card_security_code,
        coupon,
      })
      .then(response => response.data);
  },
  mobilePayment(
    {
      getters: { accessToken },
    },
    { orderId, phone, coupon, provider }
  ) {
    return api.payments
      .postMobilePayment(orderId)(accessToken, {
        phone,
        coupon,
        provider,
      })
      .then(response => response.data);
  },
  mobileCharge(
    {
      getters: { accessToken },
    },
    { transaction, code }
  ) {
    return api.payments
      .mobileCharge()(accessToken, {
        transaction,
        code,
      })
      .then(response => response.data);
  },
  mobileSubPayment(
    {
      getters: { accessToken },
    },
    { subscriptionId, phone, coupon, provider }
  ) {
    return api.payments
      .postMobileSubPayment(subscriptionId)(accessToken, {
        phone,
        coupon,
        provider,
      })
      .then(response => response.data);
  },
  mobileSubCharge(
    {
      getters: { accessToken },
    },
    { transaction, code }
  ) {
    return api.payments
      .mobileSubCharge()(accessToken, {
        transaction,
        code,
      })
      .then(response => response.data);
  },
  resendCodeDCB(
    {
      getters: { accessToken },
    },
    { transaction }
  ) {
    return api.payments
      .transactionResend()(accessToken, {
        transaction,
      })
      .then(response => response.data);
  },

  subscribeWithVoucher(
    {
      getters: { accessToken },
    },
    { code }
  ) {
    return api.payments
      .activateVoucherSubscription({ code })(accessToken)
      .then(response => response);
  },

  fetchVoucherSubscriptions({ getters: { accessToken } }) {
    return api.payments
      .getMyVoucherSubscriptions()(accessToken)
      .then(({ data }) => data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
