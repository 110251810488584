<template>
  <div
    v-if="!isAuthorized || account._type === 'profile'"
    class="position-relative region-switcher px-2"
  >
    <button
      v-if="currentRegion"
      ref="chosenLocale"
      class="btn-transparent text-light min-h-100"
      @click="togglePopover"
    >
      {{ currentRegion.name }}
      <!-- <span class="logo">
        <img
          v-if="currentRegion.logo"
          :src="currentRegion.logo"
          class="mx-3"
          loading="lazy"
          :style="{
            width: '40px',
            height: '20px',
          }"
        />
      </span> -->
      <AppIcon name="dropdown" width="10" />
    </button>
    <PopoverMenu
      v-if="regions.length"
      :position="popoverPosition"
      :closest="$el"
      :items="regions"
      :float="[bottomPosition ? 'bottom' : 'top', 'left']"
      regard="top"
      @toggle="togglePopoverVisible"
    >
      <template slot-scope="{ item }">
        <span
          :style="{
            width: '124px',
          }"
          class="region-item"
          @click="changeRegion(item)"
          >{{ item.name }}
          <!-- <img
            v-if="item.logo"
            :src="item.logo"
            class="mx-3 mb-1"
            loading="lazy"
            :style="{
              width: '40px',
              height: '20px',
              '--background-color': 'transparent',
            }"
          /> -->
        </span>
      </template>
    </PopoverMenu>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import AppIcon from '../atoms/Icon';
import PopoverMenu from '@/components/popover/Menu';

export default {
  name: 'HeraRegionSwitcher',
  components: {
    AppIcon,
    PopoverMenu,
  },
  props: {
    bottomPosition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoverPosition: null,
      popoverIsVisible: false,
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['regions', 'currentRegion', 'locales', 'isAuthorized']),
    ...mapGetters(['isAuthorized']),
  },
  methods: {
    ...mapMutations(['setRegion', 'setLocale']),
    togglePopover() {
      this.popoverPosition = this.popoverIsVisible
        ? null
        : this.$refs.chosenLocale.getBoundingClientRect();
    },
    togglePopoverVisible(isVisible) {
      this.popoverIsVisible = isVisible;
    },
    changeRegion(region) {
      this.popoverPosition = null;
      if (region && region !== this.currentRegion) {
        this.setRegion(region);

        const locale = this.locales.find(
          l => l.key.toLowerCase() === region.locale.slice(0, 2).toLowerCase()
        );
        if (locale) this.setLocale(locale);
        console.log(`Changing the region to ${region.name}.`);
        window.location.href = `/?changeRegion=${region.name}`;
      }
    },
  },
};
</script>
<style lang="scss">
.region-switcher {
  height: 40px;
  border: 1px solid $ph-input-borders-raw;
  border-radius: $ph-medium-radius;
  button {
    width: 82px;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    .logo {
      width: 64px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.btn-transparent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.region-item {
  padding: 0.25rem 0.75rem !important;
}
</style>
