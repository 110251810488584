const flip = {
  bind: function(el, binding) {
    el.classList.add('d-flex', 'align-items-center');

    if (binding.modifiers.between) {
      el.classList.add('justify-content-between');
    } else if (binding.modifiers.center) {
      el.classList.add('justify-content-center');
    } else {
      el.classList.add('justify-content-start');
    }

    if (binding.value === 'rtl') {
      el.classList.toggle('flex-row-reverse');
    }
  },
  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      el.classList.toggle('flex-row-reverse');
    }
  },
  unbind: function() {},
};

export default flip;
