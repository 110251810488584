<template>
  <div
    class="form-group d-flex flex-column  hera-form-checkbox"
    :class="{ disabled }"
    @click="$emit('click', $event)"
  >
    <label class="d-flex align-content-center">
      <input
        type="checkbox"
        :class="{ indeterminate }"
        :name="name"
        :value="val"
        :checked="isChecked"
        :title="label"
        :tabindex="tabindex"
        @change="onChange"
      />
      <span class="mx-2 hera-checkbox"></span>
      <span class="newCheckboxText">
        {{ label }}
        <slot />
      </span>
    </label>
    <span
      v-show="error"
      class="form-text text-danger error-helper ml-5 mt-2"
      v-text="error"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'HeraCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    val: {
      type: null,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.value.includes(this.val) || this.checked;
    },
  },
  methods: {
    onChange() {
      if (this.isChecked) {
        this.value.splice(this.value.findIndex(el => el === this.val), 1);
      } else {
        this.value.push(this.val);
      }
      this.$emit('change', ...this.value);
    },
  },
};
</script>

<style lang="scss">
.newCheckboxText {
  display: flex;
  align-items: center;
  margin-top: 0.9px;
  margin-left: 8px;
  font-size: 14px;
}

.hera-form-checkbox {
  input {
    position: absolute;
    z-index: -2;
    transform: scale(0);
    opacity: 0;

    &:focus {
      ~ .hera-checkbox::before {
        box-shadow: $btn-focus-box-shadow;
      }
    }
    &:checked {
      ~ .hera-checkbox::before {
        border-color: $hr-bg-accent;
        background-color: $hr-bg-accent;
        background-image: url('~@/assets/images/icons/checked.svg');
      }
    }
    &.indeterminate {
      ~ .hera-checkbox::before {
        border-color: $hr-bg-accent;
        background-color: $hr-bg-accent;
        background-image: url('~@/assets/images/checkbox-indeterminate.svg');
      }
    }
  }
  .hera-checkbox::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid $ph-primary-text;
    background: center no-repeat;
    background-size: contain;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  label {
    margin: 0 -0.5rem;
  }
}
</style>
