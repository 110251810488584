<template>
  <TextBlock tag="div" class="mb-3 py-1 text-truncate text-muted" :size="0.875">
    <span v-if="gameName">{{ gameName }}</span>
    <TournamentCardPlatform :tournament="tournament" />
    <TournamentCardSquadSize :tournament="tournament" />
    <!--    <template v-if="hasTimeInfo">
      <span class="mx-1">•</span>
      <span>{{ timeLabel }}</span>
      <Countdown
        :date="timeValue"
        tag="span"
        :size="0.875"
        class="mb-0 h-ml-2"
        variant="secondary"
      />
    </template>-->
  </TextBlock>
</template>

<script>
import { propRequired } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
// import Countdown from '@/components/common/Countdown';
import TournamentCardSquadSize from './SquadSize';
import TournamentCardPlatform from './Platform';
import { mapGetters } from 'vuex';

/*import { TIME_LABELS, TOURNAMENT_STATUSES } from '@/constants/tournaments';

const {
  ANNOUNCED,
  REG_OPENED,
  REG_CLOSED,
  QUALIFICATION_COMPLETED,
  STARTED,
} = TOURNAMENT_STATUSES;*/

export default {
  name: 'TournamentCardSubtitle',
  components: {
    TextBlock,
    // Countdown,
    TournamentCardPlatform,
    TournamentCardSquadSize,
  },
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    ...mapGetters(['gameByID']),
    game() {
      return this.gameByID(this.tournament.game);
    },
    gameName() {
      return this.$lodash.get(this, 'game.name') || '';
    },

    /*    timeStatus({ tournament: { status } = {} }) {
      return (
        (status === ANNOUNCED && 'registration_start') ||
        (status === REG_OPENED && 'registration_end') ||
        ((status === REG_CLOSED || status === QUALIFICATION_COMPLETED) &&
          'tournament_start') ||
        (status === STARTED && 'tournament_end') ||
        ''
      );
    },
    timeLabel({ timeStatus, $lodash: { get } }) {
      return this.$t(...get(TIME_LABELS, timeStatus));
    },
    timeValue({ tournament, timeStatus }) {
      return this.$lodash.get(tournament, timeStatus);
    },
    hasTimeInfo({ timeValue }) {
      return timeValue - Date.now() / 1000 > 0;
    },*/
  },
};
</script>
