<template>
  <WidgetWrapper
    :title="$$t('Participants')"
    class="bg-transparent d-flex flex-column"
  >
    <PerformanceIndicators
      v-bind="{
        indicators,
      }"
    />
    <!--
    <div class="mt-5 d-flex align-items-center justify-content-end">
      <router-link
        :to="{
          name: 'tournamentApplications',
          params: this.$route.params,
        }"
      >
        {{ $$t('Registration Data') }}
      </router-link>
    </div>-->
  </WidgetWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import PerformanceIndicators from '@/views/tournaments/management/summaryWidgets/PerformanceIndicatorsSet';
import WidgetWrapper from '@/views/tournaments/management/dashboard/components/WidgetWrapper';
export default {
  name: 'ParticipantsWidget',
  components: {
    WidgetWrapper,
    PerformanceIndicators,
  },
  mixins: [translate('_web_tournament_dashboard')],
  computed: {
    ...mapGetters(['currentTournament']),
    playersInfo() {
      return this.$lodash.get(this, 'currentTournament.players_info') || {};
    },
    playersConfig() {
      return this.$lodash.get(this, 'currentTournament.players_config') || {};
    },
    indicators() {
      return [
        {
          label: this.$$t('Applicants'),
          current: this.playersInfo.current_total,
          limit: this.playersConfig.squads,
          fraction: true,
        },
        {
          label: this.$$t('Invited'),
          // no data
          limit: this.playersInfo.invites,
          current: this.playersInfo.invites_accepted,
          fraction: true,
        },
        {
          label: this.$$t('Qualified'),
          limit: this.playersConfig.squads,
          current: this.playersInfo.current_qualifiers,
          fraction: true,
        },
        {
          label: this.$$t('Reserved'),
          limit: this.playersInfo.current_total,
          current: this.playersInfo.current_reserves,
        },
        {
          label: this.$$t('Checked-in'),
          limit: this.playersConfig.squads,
          current: this.playersInfo.current_checked_in,
        },
        {
          label: this.$$t('Revoked'),
          limit: this.playersInfo.current_total,
          current: this.playersInfo.revoked,
        },
      ];
    },
  },
};
</script>
