<template>
  <div class="d-flex flex-column">
    <Title
      :text="$t($route.meta.titleKey, $route.meta.fallbackLabel)"
      class="mb-5"
    />
    <!--    <div class="mb-5 row">-->
    <!--      <div class="col-3"><PrizesWidget class="h-100" /></div>-->
    <!--    </div>-->
    <div class="mb-5 row">
      <div class="col col-md-8 pl-0 pr-5">
        <TournamentSettingsWidget class="mb-5" />
        <TournamentStructureWidget class="mb-5" />
        <TournamentLocationsWidget />
      </div>
      <div class="col col-md-4 p-0">
        <TournamentActionsWidget class="mb-5" />
        <TournamentTimelineWidget />
      </div>
    </div>
    <!--    <div class="mb-5 row">
      <div class="col"><TournamentSponsorsWidget /></div>
    </div>-->
  </div>
</template>
<script>
// import PrizesWidget from '@/views/tournaments/management/dashboard/components/WidgetPrizes';
import TournamentSettingsWidget from '@/views/tournaments/management/dashboard/components/WidgetTournamentSettings';
import TournamentStructureWidget from '@/views/tournaments/management/dashboard/components/WidgetStructureSummary';
import TournamentActionsWidget from '@/views/tournaments/management/dashboard/components/WidgetActions';
// import TournamentSponsorsWidget from '@/views/tournaments/management/dashboard/components/WidgetSponsors';
import TournamentTimelineWidget from '@/views/tournaments/management/dashboard/components/WidgetTimeline';
const TournamentLocationsWidget = () =>
  import('@/views/tournaments/management/dashboard/components/WidgetAudienceLocation');

export default {
  name: 'TournamentDashboard',
  components: {
    // PrizesWidget,
    TournamentSettingsWidget,
    TournamentStructureWidget,
    TournamentActionsWidget,
    // TournamentSponsorsWidget,
    TournamentLocationsWidget,
    TournamentTimelineWidget,
    // TournamentAgesWidget,
  },
};
</script>
