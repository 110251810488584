import {
  authorizedGetRequest,
  simpleHeadRequest,
  simpleGetRequest,
} from '@/api/requests';

const VANITY_BASE = 'routing/vanity';

const getEntityByUrl = id => authorizedGetRequest(`${VANITY_BASE}/${id}`);
const getEntityByUrlNoAuth = id => simpleGetRequest(`${VANITY_BASE}/${id}`);

const checkUrl = id => simpleHeadRequest(`${VANITY_BASE}/${id}`);

export default {
  getEntityByUrl,
  getEntityByUrlNoAuth,
  checkUrl,
};
