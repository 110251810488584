import api from '@/api';
import { cloneDeep, isArray } from '@/utils/lodashUtils';
import Vue from 'vue';
import { getLangNameFromCode } from 'language-name-map';

const state = {
  countries: JSON.parse(localStorage.getItem('countries')) || [],
  // translations: {},
  maintenance: false,
  genres: [],
  platforms: [],
  games: [],
  locales: [],
  genders: ['Male', 'Female'],
  fetchGenresPromise: null,
  fetchGamesPromise: null,
  fetchPlatformsPromise: null,
};

const getters = {
  countries: state => state.countries,
  countryByCode: state => code => state.countries.find(el => el.code === code),
  maintenance: state => state.maintenance,
  genres: state => state.genres,
  platforms: state =>
    state.platforms.map(p => ({
      id: p.id,
      logo: p.logo,
      name: p.name,
    })),
  publishedPlatforms: state =>
    state.platforms.filter(({ published }) => published),
  games: state => state.games,
  publishedGames: state => state.games.filter(game => game.published),
  gamesByPlatform: state => platformId => {
    let platform = state.platforms.find(el => el.id === platformId);
    return platform ? platform.games : [];
  },
  genders: state => state.genders,
  getPlatformLogoById: state => platformId => {
    const platform = state.platforms.find(item => {
      return item.id === platformId;
    });

    return platform ? platform.logo : '';
  },
  getGamePropById: state => (gameId, prop) => {
    const game = state.games.find(item => {
      return item.id === gameId;
    });

    return game ? game[prop] || '' : '';
  },
  getPlatformPropById: state => (platformId, prop) => {
    const platform = state.platforms.find(item => {
      return item.id === platformId;
    });

    return platform ? platform[prop] || '' : '';
  },
  platformByID: state => ID => state.platforms.find(el => el.id === ID),
  gameByID: state => ID => state.games.find(el => el.id === ID),
  locales: state => state.locales,
};

const mutations = {
  setLocales(state, payload = []) {
    if (!isArray(payload)) {
      return;
    }
    state.locales = payload.reduce((acc, localeKey) => {
      const localeInfo = getLangNameFromCode(localeKey);
      return localeInfo
        ? [
            ...acc,
            {
              key: localeKey,
              title: localeInfo.name,
              nativeTitle: localeInfo.native,
              logo: `${localeKey}.png`,
              direction: localeInfo.dir,
            },
          ]
        : acc;
    }, []);
  },
  setCountries(state, payload = []) {
    state.countries = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.countries, i, {});
      state.countries[i] = { ...state.countries[i], ...payload[i] };
    }
    // TODO: use setObjectToItem util here https://isddesign.atlassian.net/browse/TH-7201
    localStorage.setItem('countries', JSON.stringify(payload));
  },
  setPlatforms(state, payload) {
    state.platforms = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.platforms, i, {});
      state.platforms[i] = { ...state.platforms[i], ...payload[i] };
    }
    // TODO: think about caching platforms list to reduce initial app loading https://isddesign.atlassian.net/browse/TH-7200
    // localStorage.setItem('platforms', JSON.stringify(payload));
  },
  setFetchPlatformsPromise(state, promise) {
    state.fetchPlatformsPromise = promise;
  },
  setGames(state, payload) {
    state.games = payload.map(game => {
      return {
        ...cloneDeep(game),
        nameLowerCase: game.name.toLowerCase(), // use for searchesByContains
      };
    });
    // TODO: think about caching games list to reduce initial app loading https://isddesign.atlassian.net/browse/TH-7199
    // localStorage.setItem('games', JSON.stringify(payload));
  },
  setFetchGamesPromise(state, promise) {
    state.fetchGamesPromise = promise;
  },
  setGenres(state, payload) {
    state.genres = cloneDeep(payload);
    // TODO: think about caching games list to reduce initial app loading https://isddesign.atlassian.net/browse/TH-7199
    // localStorage.setItem('games', JSON.stringify(payload));
  },
  setFetchGenresPromise(state, promise) {
    state.fetchGenresPromise = promise;
  },
  setMaintenance(state, val) {
    state.maintenance = val;
  },
  addLocaleToStore(state, payload) {
    let keys = Object.keys(payload.data);
    keys.forEach(key => {
      if (!state.translations[key]) {
        Vue.set(state.translations, key, {});
      }
      Vue.set(state.translations[key], payload.locale, payload.data[key]);
    });
  },
  setGenders(state, payload) {
    state.genders = payload;
  },
};
const actions = {
  fetchLocales({ commit, state }) {
    return api.translations
      .getLocales()
      .then(response => (response.data ? response.data.locales : []))
      .then(allLocales => {
        commit('setLocales', allLocales);
      })
      .then(() => state.locales);
  },
  fetchCallingCodes({ commit, dispatch }) {
    api.settings
      .getCallingCodes()
      .then(data => commit('setCountries', data.data))
      .catch(error => {
        dispatch('errorNotify', error);
      });
  },
  fetchPlatforms({ commit, getters: { platforms, accessToken }, state }) {
    if (platforms.length) {
      return Promise.resolve(platforms);
    }

    if (!state.fetchPlatformsPromise) {
      commit(
        'setFetchPlatformsPromise',
        api.settings.getPlatforms(accessToken)
      );
    }

    return state.fetchPlatformsPromise.then(({ data }) => {
      commit('setPlatforms', data);
      return data;
    });
  },
  fetchGenres({ commit, getters: { genres, accessToken }, state }) {
    if (genres.length) {
      return Promise.resolve(genres);
    }

    if (!state.fetchGenresPromise) {
      commit(
        'setFetchGenresPromise',
        api.settings.getGenres(true)(accessToken)
      );
    }

    return state.fetchGenresPromise.then(({ data }) => {
      commit('setGenres', data);
      return data;
    });
  },
  fetchGames({ commit, getters: { games, accessToken }, state }) {
    if (games.length) {
      return Promise.resolve(games);
    }

    if (!state.fetchGamesPromise) {
      commit('setFetchGamesPromise', api.settings.getGames(true)(accessToken));
    }

    return state.fetchGamesPromise.then(({ data }) => {
      commit('setGames', data);
      return data;
    });
  },
  fetchMaintenance({ commit }) {
    return api.settings.getMaintenance().then(({ data }) => {
      commit('setMaintenance', data.maintenance);
      return data;
    });
  },
  getCountryByCodeRequest(moduleState, code) {
    return api.settings
      .getCountryByCode(code)()
      .then(response => response.data);
  },
  suggestCountriesRequest(moduleState, query) {
    return api.settings
      .suggestCountries(query)()
      .then(response => response.data);
  },
  suggestCitiesRequest(moduleState, payload) {
    return api.settings
      .suggestCities(payload.countryId, payload.query)()
      .then(response => response.data);
  },
  fetchClans(
    {
      getters: { accessToken },
    },
    params
  ) {
    return api.clans.getClans(accessToken, params).then(res => res.data);
  },
  fetchMyClans({ dispatch }) {
    return dispatch('fetchClans', { profile: 'my' });
  },
  inviteUserToMyClan(
    {
      getters: { accessToken },
      dispatch,
    },
    login
  ) {
    return new Promise((resolve, reject) => {
      dispatch('fetchMyClans')
        .then(response =>
          response && response.items.length
            ? api.clans.inviteClanMember(accessToken, response.items[0].id, [
                login,
              ])
            : reject(new Error('You have no clan'))
        )
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  },
  cancelUserFromMyClan(
    {
      getters: { accessToken },
      rootState,
      commit,
    },
    { clanID, login }
  ) {
    return api.clans.cancelClanMember(accessToken, clanID, login).then(res => {
      if (rootState.accounts.my.accountId === login) {
        commit('my/clans/deleteClan', clanID);
        commit('current/clans/deleteClan', clanID);
      }
      return res.data;
    });
  },

  // getTranslationsByLocale ({commit}, locale) {
  //   let localeArray = []
  //   if(locale.length) {
  //     localeArray = [...locale]
  //   } else {
  //     localeArray.push(locale)
  //   }

  //   let localePromises = localeArray.map((el,i) => api
  //     .getTranslationByLocale(el)().catch(error=>{ return error })
  //     )

  //   return Promise.all(localePromises)
  //     .then(
  //       responseArray =>{
  //         // console.log(responseArray)
  //         return new Promise((resolve) => responseArray.forEach(
  //             (data, i) => {
  //               commit(
  //                 'addLocaleToStore',
  //                 {locale: localeArray[i], data: data.data.translations}
  //               )
  //               resolve()
  //             })
  //           )
  //         }
  //       )
  //     .catch(error => {
  //       console.log('error getting translations')
  //       console.log(error)
  //     })
  // },
  // saveTranslation ({commit, getters}, translation) {
  //   commit('updateTranslation', translation)
  //   return api
  //     .putTranslation(
  //       translation.locale,
  //       translation.key)(
  //       getters.accessToken,
  //       translation.value,
  //       {headers: {"Content-Type": "application/json"}})
  //     .then(
  //       data => Promise.resolve(data),
  //       error => Promise.reject(error)
  //     )
  //     .catch(error => {
  //       console.log('error getting translations')
  //       console.log(error)
  //     })
  // }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
