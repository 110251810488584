<template>
  <div
    class="float-dropbox text-light"
    :class="[
      { show: !isHidden, 'position-fixed': isFixed },
      positionClass,
      `regard-${regard}`,
      custom,
      nofix,
    ]"
    :style="calculatePosition"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { intersected } from '@/utils/index';
import { computedStyle } from '@/utils/getProps';
import {
  addGlobalEventListener,
  removeGlobalEventListener,
} from '@/utils/globalEvent';
import { prop } from '@/utils/factories';

const FLOATS_X = ['left', 'center', 'right'];
const FLOATS_Y = ['top', 'bottom'];

export default {
  name: 'Popover',
  props: {
    nofix: prop(String),
    custom: prop(String),
    position: {
      type: [Object, DOMRect, Node],
      default: null,
    },
    targetSelector: {
      type: String,
      default: '',
    },
    closest: {
      type: Node,
      default: null,
    },
    blockIsFixed: prop(Boolean),
    float: {
      type: Array,
      default: () => [], // ex: ['center', 'bottom'], ['top'], ['left'],
      /*
      validator(value) {
        return !value.filter(
          float => !(FLOATS_X.includes(float) || FLOATS_Y.includes(float))
        ).length;
      },
      */
    },
    regard: {
      type: String,
      default: 'top', // top or bottom
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: [String, Number],
      default: 0,
    },
    withoutAutoClose: {
      type: Boolean,
      default: false,
    },
    // selector, DOM node, or a list of selectors or DOM nodes, or their mix
    ignoreClicksOn: {
      type: [String, Array],
      default: '',
    },
    beforeCloseCallback: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      positionClass: '',
      calculatePosition: null,
      isHidden: true,
      preventClose: false,
    };
  },
  computed: {
    ...mapGetters(['isRTL']),
    closestReplaced() {
      return (
        this.closest ||
        (this.isFixed
          ? document.documentElement
          : (this.$parent && this.$parent.$el) || document.body)
      );
    },
    floatReplaced() {
      return this.isRTL && this.float.length
        ? this.float.map(float => {
            return float === 'right'
              ? 'left'
              : float === 'left'
              ? 'right'
              : float;
          })
        : this.float;
    },
  },
  watch: {
    position() {
      this.relocatePopover();
    },
    closestReplaced() {
      this.relocatePopover();
    },
    isHidden(hidden, visible) {
      this.$emit('toggle', visible);
    },
  },
  mounted() {
    this.relocatePopover();
    if (!this.withoutAutoClose) {
      addGlobalEventListener(
        `mousedown.popover_${this._uid}`,
        this.hideDropBox
      );
    }
  },
  beforeDestroy() {
    removeGlobalEventListener(`mousedown.popover_${this._uid}`);
  },
  methods: {
    getIgnoreElements() {
      const selectors =
        typeof this.ignoreClicksOn === 'string'
          ? this.ignoreClicksOn.split(' ')
          : this.ignoreClicksOn instanceof Node
          ? [this.ignoreClicksOn]
          : Array.isArray(this.ignoreClicksOn)
          ? this.ignoreClicksOn
          : [];
      return selectors
        .reduce(
          (acc, selector) =>
            !selector
              ? acc
              : acc.concat([
                  selector instanceof Node
                    ? selector
                    : document.body.querySelector(selector),
                ]),
          []
        )
        .filter(el => el);
    },
    getBounds(position, target = null) {
      return position instanceof Node
        ? (target
            ? position.querySelector(target)
            : position
          ).getBoundingClientRect()
        : position;
    },
    hideDropBox(e) {
      this.preventClose = false;
      const hasClickInsideBounds = this.inBounds(
        this.getBounds(this.position),
        {
          x: e.pageX,
          y: e.pageY,
        }
      );
      const hasClickOnSelf =
        this.$el === e.target || this.$el.contains(e.target);

      const hasClickOnIgnoredEl = this.getIgnoreElements().some(
        el => el === e.target || el.contains(e.target)
      );

      if (
        !this.isHidden &&
        !hasClickOnSelf &&
        !hasClickInsideBounds &&
        !hasClickOnIgnoredEl
      ) {
        this.beforeCloseCallback(this);
        this.isHidden = !this.preventClose;
      }
    },
    inBounds(bounds, coors) {
      return bounds
        ? bounds.left < coors.x &&
            bounds.right > coors.x &&
            bounds.top < coors.y &&
            bounds.bottom > coors.y
        : false;
    },
    relocatePopover() {
      if (!this.position) {
        this.preventClose = false;
        this.beforeCloseCallback(this);
        return this.preventClose
          ? (this.isHidden = false)
          : (this.isHidden = true);
      }
      const position = this.getBounds(this.position, this.targetSelector);
      const closest = this.closestReplaced;
      if (!closest) {
        return;
      }

      this.isHidden = false;

      let classList = [];

      if (this.floatReplaced.length) {
        classList = [
          ...this.floatReplaced.map(float => `float-dropbox-${float}`),
        ];
      }

      this.calculatePosition = {
        '--width': `${position.width}px`,
        '--height': `${position.height}px`,
      };

      const dialogBounds = this.blockIsFixed
        ? { top: 0, bottom: 0, left: 0, right: 0 }
        : closest.getBoundingClientRect();
      const scrollLeft = this.blockIsFixed
        ? 0
        : (this.isFixed ? -1 : 1) * closest.scrollLeft;
      const scrollTop = this.blockIsFixed
        ? 0
        : (this.isFixed ? -1 : 1) * closest.scrollTop;
      // const positionTop = this.blockIsFixed ? 0 :

      if (this.regard === 'top') {
        this.calculatePosition['--top'] = `${position.top -
          dialogBounds.top +
          scrollTop}px`;
      } else if (this.regard === 'bottom') {
        this.calculatePosition['--bottom'] = `${closest.scrollHeight -
          scrollTop -
          dialogBounds.height +
          (dialogBounds.bottom - position.bottom)}px`;
      } else if (this.regard === 'over') {
        this.calculatePosition['--top'] = `${position.top -
          dialogBounds.top +
          scrollTop +
          position.height / 2}px`;
      }

      this.calculatePosition['--left'] = `${position.left -
        dialogBounds.left +
        scrollLeft}px`;

      if (this.translate) {
        this.calculatePosition['--prop-offset-x'] = computedStyle(
          this.translate
        );
      }
      if (this.regard !== 'over') {
        if (!intersected(this.floatReplaced, FLOATS_X)) {
          const floatX =
            position.left < dialogBounds.left + dialogBounds.width / 2
              ? 'left'
              : 'right';
          classList.push(`float-dropbox-${floatX}`);
        }

        if (!intersected(this.floatReplaced, FLOATS_Y)) {
          const floatY =
            position.top < dialogBounds.top + dialogBounds.height / 2
              ? 'top'
              : 'bottom';
          classList.push(`float-dropbox-${floatY}`);
        }
      }

      this.positionClass = classList.join(' ');
    },
  },
};
</script>

<style lang="scss">
.float-dropbox {
  --top: 0;
  --left: 0;
  --bottom: 0;

  --width: 0;
  --height: 0;

  --translate-x: 0;
  --translate-y: 0;

  --prop-offset-x: 0rem; // please not remove units (px, rem) - it is for calc()
  $popover-border-width: 1px;
  $arrow-offset-x: 1.25rem;
  $arrow-size: 14px;

  @mixin dropbox-style {
    background-color: $new-bg-base;
    border: $popover-border-width solid $hr-clr-border1;
  }

  display: none;
  position: absolute;
  z-index: 9999;
  min-width: 100px;
  max-width: 250px;
  border-radius: $ph-medium-radius;
  box-shadow: $ph-box-shadow;
  @include dropbox-style;
  transform: translate(var(--translate-x), var(--translate-y));

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: $arrow-size;
    height: $arrow-size;
    @include dropbox-style;
  }
  &.show {
    left: calc(var(--left) + calc(var(--width) / 2));
    display: block;
    border-radius: 5px;

    &.float-dropbox-left {
      --translate-x: calc(#{-$arrow-offset-x} + var(--prop-offset-x));
      &:after {
        left: 0;
        transform: translateX(calc(#{$arrow-offset-x} - var(--prop-offset-x)))
          rotate(45deg);
        margin-left: -#{math.div($arrow-size, 2) + $popover-border-width};
      }
    }
    &.float-dropbox-right {
      --translate-x: calc(-100% + #{$arrow-offset-x} - var(--prop-offset-x));
      &:after {
        right: 0;
        transform: translateX(calc(#{-$arrow-offset-x} + var(--prop-offset-x)))
          rotate(45deg);
        margin-right: -#{math.div($arrow-size, 2) + $popover-border-width};
      }
    }
    &.float-dropbox-center {
      --translate-x: -50%;
      &:after {
        left: calc(50% - #{$popover-border-width});
        transform: translateX(-50%) rotate(45deg);
      }
    }
    &.regard-over {
      --translate-y: -50%;
      top: var(--top);
    }
    &.float-dropbox-top {
      &.regard-top {
        top: calc(var(--top) + var(--height));
        margin-top: $arrow-size;
      }
      &.regard-bottom {
        top: unset;
        bottom: var(--bottom);
        --translate-y: 100%;
        margin-bottom: #{0-$arrow-size};
      }
      &:after {
        top: -#{math.div($arrow-size, 2) + $popover-border-width};
        transform: translateX(calc(#{-$arrow-offset-x} + var(--prop-offset-x)))
          rotate(45deg);
        margin-left: calc(#{$arrow-offset-x}* 2);
        @include dropbox-style;
        border-bottom-width: 0;
        border-right-width: 0;
      }
    }
    &.float-dropbox-bottom {
      &.regard-top {
        top: var(--top);
        --translate-y: -100%;
        margin-top: #{0-$arrow-size};
      }
      &.regard-bottom {
        top: unset;
        bottom: calc(var(--bottom) + var(--height));
        margin-bottom: $arrow-size;
      }

      &:after {
        bottom: -#{math.div($arrow-size, 2) + $popover-border-width};
        border-top-width: 0;
        border-left-width: 0;
      }
    }
  }
  &.float-dropbox--transparent-wrapper {
    max-width: unset;
    border-width: 0px;
    background: transparent;
    &:after {
      content: unset;
    }
  }
}

.custom-style {
  border-color: $hr-clr-border1;
  background-color: $new-bg-page-faded;

  &::after {
    content: none;
  }
}

.nofix-style {
  &.show {
    left: auto;
  }

  &.regard-top {
    top: auto;
    --translate-y: 0% !important;
    margin-top: 1rem !important;
  }
}
</style>
