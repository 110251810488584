<template>
  <LinkAccount
    :id="account._id"
    :type="account._type"
    :vanity-id="account.vanityId"
    class="link-uncolor"
  >
    <slot v-bind="{ account, accountId }">{{ account._name }}</slot>
  </LinkAccount>
</template>

<script>
import { mapState } from 'vuex';
import LinkAccount from '@/components/links/Account';
export default {
  name: 'LinkAccountMy',
  components: { LinkAccount },
  computed: {
    ...mapState('my', ['account', 'accountId', 'accountType']),
  },
};
</script>
