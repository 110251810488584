<template>
  <div>
    <HeraFormDropdown
      key="best-of-third-field"
      v-model="bestOfThird"
      v-validate="'required'"
      :view-mode="disabled"
      :disabled="isStageFieldDisabled('bestOfThird') || disabled"
      name="bestOfThird"
      :label="$$t('Best of (third):', 'best-of-third_label')"
      :list="bestOfList"
      :class="{ 'is-invalid': errors.first('bestOfFinal') }"
      variant="input-like"
      required
      :error="errors.first('bestOfThird')"
      :empty-text="$$t('Choose option', 'best-of-third-placeholder')"
      :tooltip="
        $$t(
          'How many games within 3rd place playoff must be played',
          'best-of-third-tooltip'
        )
      "
      show-label
      empty-option
    >
      <template slot="chosen" slot-scope="chosen">
        {{ chosen.chosenItem }}
      </template>

      <template slot="item" slot-scope="{ item }">
        {{ item }}
      </template>
    </HeraFormDropdown>
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormDropdown from '@/components/molecules/form/HeraFormDropdown';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageBestOfThirdSetting',
  components: {
    HeraFormDropdown,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    bestOfThird: {
      get() {
        return this.getBlockConfigurationByKey('best_of_third_place');
      },
      set(newVal) {
        this.setBlockConfigurationByKey('best_of_third_place', +newVal);
      },
    },
    bestOfList() {
      return this.stageDefaults[this.tieMatchAllowed ? 'bestOfTie' : 'bestOf'];
    },
  },
};
</script>

<style></style>
