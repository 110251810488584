var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityHandler,
    intersection: {
      threshold: 0.4,
    },
  }),expression:"{\n    callback: visibilityHandler,\n    intersection: {\n      threshold: 0.4,\n    },\n  }"}],staticClass:"sc-post-attach-video d-flex position-relative",on:{"click":function($event){$event.preventDefault();return _vm.clickHandler($event)}}},[(!_vm.isMetaLoaded)?_c('div',{staticClass:"attach__loader-wrapper d-flex align-items-center"},[_c('Loader',{attrs:{"size":60}})],1):_vm._e(),_c('video',_vm._b({ref:"video",class:_vm.childclass ? _vm.childclass : 'sc-post-attach-video__video',attrs:{"poster":_vm.thumbnail && _vm.thumbnail.image
        ? _vm.thumbnail.image
        : _vm.thumbnailFallback
        ? _vm.thumbnailFallback
        : require('@/assets/images/background/320.jpg'),"muted":"","playsinline":""},domProps:{"muted":true}},'video',{ src: _vm.src },false)),_c('span',{staticClass:"sc-post-attach-video__duration"},[_vm._v(_vm._s(_vm.duration))]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay-on-video"},[_c('img',{staticClass:"play-btn-video",attrs:{"src":require("@/assets/images/icons/play-icon.svg"),"alt":"play"}})])}]

export { render, staticRenderFns }