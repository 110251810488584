import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import blocksEndpoints from './tournamentsBlocks';
import stagesEndpoints from './tournamentsStages';
import playersEndpoints from './tournamentsPlayers';
import registrationFormEndpoints from './tournamentsRegistrationForm';
import conversationsEndpoints from './tournamentsConversations';
import invitesEndpoints from './tournamentsInvitePlayers';

export const TOURNAMENTS = 'tournaments';

export const createTournament = authorizedPostRequest(`${TOURNAMENTS}`);

export const getTournament = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}`);

export const getTournamentDraft = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/draft`);

export const getTournamentRulesText = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/rules`);

export const setTournamentRulesText = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/rules`);

export const getTournamentPlayersInfo = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players_info`);

export const updateTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}`);

export const announceTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/announce`);

export const openRegTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/registration/start`);

export const closeRegTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/registration/end`);

export const drawTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/draw`);

export const completeTournamentQualification = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/qualify`);

export const publishTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/publish`);

export const rejectTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/reject`);

export const pauseTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/pause`);

export const reopenTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/reopen`);

export const restartTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/restart`);

export const archiveTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/archive`);

export const getTournamentResults = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/results`);

export const checkInParticipant = tournamentID =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/check_in`);

export const getTournaments = authorizedGetRequest(`${TOURNAMENTS}`, {
  ignoreParams: true,
});
export const getPinnedTournaments = authorizedGetRequest(`${TOURNAMENTS}`);

export const getAdminTournaments = authorizedGetRequest(
  `${TOURNAMENTS}/draft/my`,
  {
    ignoreParams: true,
  }
);
export const getPlayersStatistics = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players/statistics`);
export default {
  createTournament,
  getTournament,
  getTournamentRulesText,
  setTournamentRulesText,
  getTournamentResults,
  getTournamentDraft,
  getTournamentPlayersInfo,
  updateTournament,
  announceTournament,
  openRegTournament,
  closeRegTournament,
  publishTournament,
  rejectTournament,
  restartTournament,
  pauseTournament,
  reopenTournament,
  archiveTournament,
  drawTournament,
  completeTournamentQualification,
  checkInParticipant,
  getTournaments,
  getPinnedTournaments,
  getAdminTournaments,
  getPlayersStatistics,
  //conversations
  ...conversationsEndpoints,
  //registration form
  ...registrationFormEndpoints,
  //players
  ...playersEndpoints,
  //invites,
  ...invitesEndpoints,
  //stages
  ...stagesEndpoints,
  //blocks
  ...blocksEndpoints,
};
