import { render, staticRenderFns } from "./HeraBadge.vue?vue&type=template&id=9cff3da2&"
import script from "./HeraBadge.vue?vue&type=script&lang=js&"
export * from "./HeraBadge.vue?vue&type=script&lang=js&"
import style0 from "./HeraBadge.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./HeraBadge.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms&name=HeraBadge&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('text'%2C%20'bage')%0A%20%20%20%20%7D%2C%0A%20%20%20%20variant%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('variant'%2C%20%5B'default'%2C%20'orange'%2C%20'green'%2C%20'blue'%2C%20'bg-orange'%5D%2C%20'default')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports