<template>
  <MainLayout>
    <div class="privacy-policy">
      <div class="privacypolicy-container">
        <h1><b>Playheraのプライバシーポリシー</b></h1>
        <h2><b>当社におけるお客様データの取り扱いについて</b></h2>

        <p>
          Entertainment Content Company
          (「Playhera」または「当社」といいます)は、(
          iOS/Androidアプリケーションとして、あるいは現在[www.playhera.com]で提供している)
          Playheraプラットフォーム
          (「プラットフォーム」)の運営において、お客様の個人データを使用します。
          プラットフォーム上でプレイされる場合、お客様データは主に、登録の許可、ソーシャルフォーラムの運営とモデレーション、対戦へのエントリーの管理、プロモーションおよび対戦記録の保持、登録されている対戦やイベントに関する通知、プレイヤー間の意見の相違やその他のやり取り、あるいは苦情の管理に使用されます。当社は、登録の過程でTwilioによるSMS認証を使用しています。
          プラットフォームのご使用時、お客様データは第三者と共有されます。主な共有先は、お客様が当社のソーシャルフォーラム上でやり取りされる相手や、選択された対戦相手となります。対戦やイベントに参加される場合には、お客様情報は、それらの主催者と共有されます。これにより、対戦やイベントの主催者は、連絡や通知、最新情報をお客様に送信できるようになります。また、犯罪行為の証拠が存在する場合や、民事手続きにおいて求められる場合には、当社は警察その他の当局とお客様情報を共有することがあります。
          ダイレクトマーケティングや、当社のロケーションベースのサービスの提供を目的とした、位置情報の共有など、お客様の同意に基づく情報の共有に関しては、かかる同意をいつでも撤回していただくことができます。
          Playheraはグローバルなプラットフォームです。したがって、当社のフォーラム、イベント、対戦に参加していただけるように、お客様情報を世界中に転送させていただく場合があります。そのような場合、Playheraは適切な手順を踏み、データの保護に努めます。
          当社のプライバシーポリシーには、特定の処理を拒否する権利を含む、お客様におけるデータ保護の権利に関する情報など、この処理の詳細が記載されています。
        </p>

        <br />

        <h2><b>このポリシーでは、何がカバーされているのですか?</b></h2>
        <p>
          このポリシーでは、お客様がプラットフォームを使用される時や、対戦に参加される時に、Playheraがお客様データを使用する方法について説明しています。
        </p>
        <br />
        <p>
          また、Playheraが実行する特定の処理を拒否する権利を含む、お客様におけるデータ保護の権利についても説明しています。お客様の権利や、それらの権利を行使する方法に関する詳細は、「私には、どのような権利がありますか?」のセクションに記載されています。
        </p>

        <h2><b>Playheraが収集するのは、どのような情報ですか?</b></h2>
        <p>
          当社は、お客様が当社のアプリケーションをダウンロードして最初に使用される時、プラットフォームに登録される時、当社のソーシャルフォーラムを使用される時、ご自身のeスポーツ活動に関する情報を送信される時に、お客様の個人データを収集し、処理します。これらの情報は通常、お客様から直接提供していただくものであり、氏名、ユーザー名、電話番号、Eメールアドレス、マーケティングにおけるプレファレンスなど、登録時に提供していただいた情報が含まれます。これらの情報は、お客様によるフォームへの記入によって、あるいはソーシャルコンテンツやeスポーツ大会の結果から収集させていただく場合があります。
        </p>
        <br />
        <p>
          Playheraが第三者から受け取る情報には、どのようなものがありますか？
          当社は、お客様に関する情報を、第三者から受け取ることがあります。そのような第三者は、例えば、大会やイベントにおけるお客様の対戦相手であったり、お客様が当社のソーシャルフォーラムを介してやり取りされた相手であったり、お客様の行動に対して苦情を申し立ててきた人であったりします。
        </p>
        <br />
        <p>
          また、下記の「Cookieとその類似技術」のセクションで説明しているように、当社はCookieの使用によって、お客様に関する情報を収集しています。当社が主に使用しているのはGoogle
          AnalyticsのCookieです。
        </p>
        <br />
        <p>
          Playheraは、親または法的保護者の明示的な許可なしに、13歳未満の児童から意図的に情報を収集し、処理することはありません。
        </p>
        <br />
        <p>
          なんのために、どのような法的根拠に基づいて、これらの情報を使用するのですか？
        </p>

        <p>当社は、以下の目的で、個人データの処理を行います。</p>
        <ul class="disc">
          <li>当社とお客様との間で取り交わされた契約の管理および履行;</li>
          <li>プラットフォーム上で行われる対戦の運営と管理;</li>
          <li>Playheraが運営する対戦における参加資格や勝者の決定、賞の授与;</li>
          <li>当社によるビジネスの履行、正当な利益の追求;</li>
          <li>
            ソーシャルフォーラムのモデレーションや、お客様が当社に送信されたコメントや苦情への対応を含む、プラットフォームの提供と運営;
          </li>
          <li>
            お客様による当社のWebサイトおよびオンラインサービスの使用を監視し、オンラインとオフラインの両方において、製品、コンテンツ、サービス、Webサイトの監視、改善、保護に、お客様情報を役立てる;
          </li>
          <li>
            お客様からいただいた情報に基づいた、Webサイト、製品、サービスのパーソナライズ;
          </li>
          <li>
            適用法に従って、プレイヤーのアカウントを監視し、不正、テロ、虚偽表示、セキュリティインシデント、違法行為を防止、調査、報告する;
          </li>
          <li>
            プラットフォームや、プラットフォーム上のプレイヤー(お客様を含む)の行動に対する苦情を調査する;
          </li>
          <li>
            法的請求、コンプライアンス、規制、調査の目的に応じて、関連するデータを使用する(法的手続きや訴訟において求められる情報の開示を含む);
          </li>
          <li>
            特定の個人のデータを使用し、それらの人々による市場調査への参加を促す;
          </li>
          <li>
            プレイヤーに関する情報(スコアボードや賞など)を、プラットフォームの宣伝に使用する;
          </li>
          <li>
            お客様の同意に基づき、ダイレクトマーケティングや、プラットフォームに関連する通知を送信する、あるいは、イベントに関する通知や宣伝をお客様に送信することができるように、対戦やイベントの主催者にお客様情報を提供する。
            下記の「Cookieとその類似技術」のセクションで述べているように、Cookieを配置し、類似技術を使用する;
          </li>
          <li>その他の同意を求める際の、お客様に対する説明;</li>
          <li>法律によって求められる目的の遂行;</li>
          <li>
            16歳未満のお子様へのオンラインサービスの提供において親の同意が必要な場合;
          </li>
          <li>調査を行う政府/警察当局からデータ開示の要請があった場合;</li>
          <li>法律で義務付けられている記録の保持;</li>
        </ul>

        <br />
        <p>
          お客様による同意の撤回や別の方法によるダイレクトマーケティングの拒否
          上記のような法的根拠がある場合を除き、当社がお客様の同意を得たうえでデータを使用する場合には、かかる同意をいつでも撤回していただくことができます。例えば、ダイレクトマーケティングまたは、ダイレクトマーケティングを展開するために当社が行うプロファイリングは、いつでもオプトアウトすることができます。それは、お客様における絶対的な権利です。ダイレクトマーケティングがEメールによるものであれば、メッセージ内の指示に従うことによって、あるいは以下に掲載している当社の連絡先に連絡することによって、オプトアウトすることができます。
        </p>

        <br />
        <p>
          Cookieとその類似技術
          CookieはWebサーバーからWebブラウザに送信される小さな情報であり、これによってサーバーは各ページのブラウザを一意に識別することができます。当社は、ピクセルタグやトラッキングURL
          など、Cookieと類似した追跡技術も使用しています。本ポリシーでは、これらの技術をまとめて「Cookie」と呼びます。
        </p>

        <br />
        <p>
          当社がWebサイト上で使用することがあるCookieの種類とその用途を以下に記します。
        </p>

        <br />
        <p>
          必要不可欠なCookie:
          Webサイト上のセキュアな領域へのアクセスや移動、Webサイトの機能の利用に不可欠なCookieをいいます。これらのCookieがなければ、当社のプラットフォーム上のいずれのサービスにもアクセスしていただくことはできません。
        </p>

        <br />
        <p>
          分析/パフォーマンスCookie:
          これらのCookieは、訪問回数が最も多いページや、Webページからのエラーメッセージの受信情報など、お客様やその他の訪問者によるプラットフォームの使用方法を収集します。これらのCookieから取得したデータによって、デザインをテストし、お客様がプラットフォームを訪問される度に一貫性のあるルック・アンド・フィールを提供することができます。これらのCookieによって収集された情報は集約され、プラットフォーム機能の改善にのみ使用されます。当社はGoogle
          Analyticsなどを使用して、匿名化されたWebサイトの使用状況やアクティビティーを追跡します。
        </p>

        <br />
        <p>
          機能性Cookie:
          これらのCookieによって、当社のWebサイトは、お客様による選択(ユーザー名、言語、地域など)を記憶し、さらに充実した、よりパーソナルな機能を提供することができるようになります。そのほか、テキストのサイズやフォントなど、Webページにおけるカスタマイズ可能な部分にお客様が加えられた変更の記憶も可能になります。これらのCookieによって収集された情報は、匿名化することができます。なお、他のWebサイト上でのお客様のブラウジング活動は、追跡することができません。
        </p>

        <br />
        <p>
          ターゲティングCookie:
          これらのCookieは、お客様や、お客様の関心事との関連性が高い広告を配信するために使用されます。当社が使用しているのはcookie
          Doubleclickです。これらのCookieはまた、お客様が広告を目にする回数の制限や、広告キャンペーンの効果の測定にも用いられ、通常はアドネットワークによって、Webサイトの運営者の許可を得たうえで配置されます。お客様が訪問されたWebサイトは記憶され、その情報は、広告主などの他の組織と共有されます。多くの場合、ターゲティング/広告Cookieは、他の組織が提供するサイトの機能にリンクされています。
        </p>

        <br />
        <p>
          ソーシャルメディアCookie:
          これらのCookieを使用すると、FacebookやTwitterといったソーシャルメディアサイト上でのお客様の行動の共有が可能になります。これらのCookieは当社の管理下にはありませんので、その機能に関しては、各ソーシャルメディアのプライバシーポリシーを参照してください。
        </p>

        <br />
        <p>
          ピクセルタグ:
          クリアGIFまたはWebビーコンとも呼ばれているピクセルタグは、当社のWebサイトの特定のページに配置されている、お客様のコンピュータ画面には表示されない、視認不可能なタグをいいます。これらのタグが埋め込まれたページにアクセスすると、当該の訪問に関するジェネリック型の通知が生成されます。ピクセルタグは通常、Cookieと連動し、特定のデバイスによる特定のページへの訪問を検知します。これらのタグは主に、サービスの利用者(Eメール受信者を含む)の行動の追跡、マーケティングキャンペーンの成功度の測定のほか、サービスの利用および応答率に関する統計をまとめるために使用されます。Cookieを無効にすると、ピクセルタグは、Webサイトへの匿名化された訪問を検出するにとどまります。
        </p>

        <br />
        <p>
          Cookieの適用を完全に拒否される場合や、特定のCookieのみの使用を望まれる場合には、ブラウザの設定を参照してください。ブラウザの設定により、当社によるCookieの使用に対するお客様の同意を、いつでも撤回していただくことができます。また、既存のCookieを削除していただくことも可能です。但し、当社が設定したCookieを削除/無効化されると、当社のWebサイト上の特定の領域や機能へのアクセスが不可能になる場合があります。
        </p>

        <br />
        <p>
          Cookieの詳細は、<a
            target="_blank"
            href="https://www.allaboutcookies.org"
            >www.allaboutcookies.org</a
          >
          でご確認ください。
        </p>
        <p>
          もしくは、行動ターゲティング広告やオンラインプライバシーに関する詳細が掲載されている
          <a target="_blank" href="http://www.youronlinechoices.eu"
            >www.youronlinechoices.eu</a
          >
          をご覧ください。
        </p>
        <h2>
          <b>データはいつ、どこで、どのような方法で共有されるのですか?</b>
        </h2>

        <p>
          当社は、お客様が参加を選択された対戦/イベントの主催者と、お客様のデータを共有します。それにより、これらの主催者は、お客様による参加を管理し、プラットフォーム上でお客様とやり取りし、(お客様が同意されている場合には)お客様に通知とリマインダーを送信できるようになります。さらに、お客様がエントリーされた対戦/イベントにeスポーツ団体やイベントスポンサーが関与している場合には、市場分析や、関連するダイレクトマーケティング(お客様が同意されている場合)を目的として、それらの組織にお客様データへのアクセスを提供します。eスポーツ連盟には、プレイヤーに対する理解を深めることができるように、テリトリー内のユーザーに関する様々な情報へのアクセスを提供します。
        </p>

        <br />
        <p>
          当社は、上記の目的を果たすために、または適用法に準ずる正当な利益を法的に保護するために必要であれば、あるいは法律によって、かかる開示が義務付けられている場合においては、お客様の個人データを政府/警察当局者と共有することができます。
        </p>

        <br />
        <p>
          また、プレイヤーとその関心事に対する理解を深めるために、お客様の個人データを市場調査機関と共有することもできます。
        </p>

        <br />
        <p>
          さらに、上記の目的を果たすために、Playheraに代わってデータの処理を行うサードパーティのサービスプロバイダと、個人データを共有することもできます。当社は主に、アプリケーション開発、Eメールキャンペーンの管理、Webサイトのホスティング、SMS認証サービス、顧客サポートに関連するソリューションに、サードパーティのサービスプロバイダを使用しています。
        </p>

        <br />
        <p>
          事業の売却や他企業との統合に際しては、当社の顧問だけでなく、あらゆる買収予定者の顧問にもお客様情報を開示し、最終的に事業の新たな所有者に引き渡します。
        </p>

        <br />
        <p>
          私には、どのような権利がありますか?
          お客様に与えられる権利は、国によって異なります。そのような権利には、当社に自己の個人データのコピーを求める権利、自己の個人データを修正/削除する権利、処理を制限する権利(または実行中の処理を停止させる権利)、契約のために、または同意のうえで当社に提出された自己の個人データを、構造化された機械可読形式で入手する権利、自己の個人データを他のコントローラーと共有(に移植)するように、当社に依頼する権利があります。
        </p>

        <br />
        <p>
          お客様はさらに、場合によっては(とりわけ、契約やその他の法的要件を満たすために処理する必要がないデータや、当社がダイレクトマーケティングに使用するデータに関しては)、自己の個人データの処理を拒否することができます。
        </p>

        <br />
        <p>
          但し、お客様のご依頼に応じることにより他者の個人データが曝露される、または第三者(当社を含む)の権利が侵害されるおそれがある場合や、当社が法律によってその保持を義務付けられている場合、あるいは保持することによって、説得力のある適正な利益を得ることができる情報の削除を依頼される場合につきましては、上記の権利は制限される場合があります。これに関連する適用除外項目は現地の法律で定められています。当社は、お客様からのご依頼に応じる際には、当社が依存している、関連する適用除外項目についてお伝えします。
        </p>

        <br />
        <p>
          これらの権利のいずれかの行使を望まれる場合には、下記の連絡先までご連絡ください。懸念が解消されない場合には、お客様の居住地、職場、あるいは違反の発生地におけるデータ保護当局など、管轄当局に苦情を申し立てていただくことができます。当社は、お客様による情報提供が必須である場合には、必ずその旨をお伝えします。SMS認証を含む登録プロセスにおいては、プラットフォーム上で開催される大会の結果と同様に、正確な情報の提供が義務付けられています。それが守られない場合は、登録は不可能であり、当社の利用規約への違反とみなされる場合もあります。
        </p>

        <br />
        <p>
          Playheraへの連絡方法を教えてください。
          当社は、お客様データの処理方法に関するお客様の疑問が解消されることを願っております。当社によるデータの処理方法について懸念をお持ちの場合や、ダイレクトマーケティングのオプトアウトを希望される場合には、Eメール
          <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
          で、または下記の住所まで書面でご連絡ください。
        </p>

        <br />
        <p>70 Sir John Rogerson’s Quay</p>
        <p>Dublin 2</p>
        <p>D02 R296 , Ireland</p>

        <br />
        <p>会社名: Entertainment Content Co</p>

        <br />
        <p>
          個人データは、どれくらいの期間保持されるのですか?
          お客様からいただいた情報は、お客様による当社のサービスへのアクセスを可能にする必要がなくなった時点、あるいはお客様のアカウントが削除された時点の、いずれか早い方の時点までプラットフォーム上に保存されます。当社がマーケティングを目的として、またはお客様の同意を得て行う個人データの処理は、お客様から停止を依頼され、その後短期間が経過するまで(お客様のご依頼に応じることが可能になるまで)実行されます。また、お客様のご要望を今後も尊重し続けることができるように、ダイレクトマーケティングの停止のご依頼や、個人データの処理の停止のご依頼を記録に残し、保持します。
        </p>

        <br />
        <p>
          当社は、Playheraの結果情報(ポイント獲得者や獲得した日時など)やユーザーから提出されたゲームプレイの静止画像を、プラットフォーム上で行われた対戦の記録として、また、後に結果を検証することができるように、無期限で保持します。
        </p>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Privacypolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
};
</script>
