import {
  // authorizedDeleteRequest,
  // authorizedGetRequest,
  authorizedPostRequest,
} from '@/api/requests';

const STATISTICS = 'statistics';

export const postViewsRecord = authorizedPostRequest(
  `${STATISTICS}/usageevents`
);
// authorizedGetRequest(`${COMMENTS}/${type}/${id}`)(accessToken, {
//   params: { page, page_size },
// });

// export const postComment = (accessToken, { type, id, text }) =>
//   authorizedPostRequest(`${COMMENTS}/${type}/${id}`)(accessToken, { text });

// export const deleteComment = (accessToken, commentID) =>
//   authorizedDeleteRequest(`${COMMENTS}/${commentID}`)(accessToken);

export default {
  postViewsRecord,
};
