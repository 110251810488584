<template>
  <div class="create-feed-post-wrapper-new d-flex">
    <div class=" create-feed-post_body-wrapper-new custom-scrollbar">
      <div class="d-flex flex-column text-input-create">
        <div
          :class="{
            'create-feed-post_body-new': this.$router.history.current.path.includes(
              'feed'
            ),
          }"
        >
          <TextInputMultiline
            ref="postInput"
            v-model="text"
            :class="'text-input-feed-modal'"
            class="mb-0 create-feed-post_input"
            :placeholder="
              $t('_web_post_write_something_here', 'Write something here...')
            "
            :has-counter="showActions"
            :rows="INPUT_ROWS"
            v-bind="{ maxLength }"
            hide-label
            :detect-links="!linkPreview && activeButton === ''"
            detect-tags
            detect-mentions
            @linkDetected="debouncedPreviewRequest"
            @isFocused="expand"
            @keyup.enter.alt="publish"
            @keyup.esc="resetForm"
          />
        </div>
        <div class="text-input-wrap">
          <!--
            @TODO - TH-7124
          https://bitbucket.org/isd__design/hera-web/src/4c86da6d58faa4b6cbb61ebb6c3514624e92b1ee/src/components/form/contentEditable/index.vue
          -->
          <div
            class="img-preview"
            :class="{
              'mt-5': uploadFilesList.length > 0,
              'img-preview-single': uploadFilesList.length === 0,
            }"
          >
            <div
              v-for="(file, index) in uploadFilesList"
              :key="index"
              :class="[
                activeButton === 'video' ? 'videos' : '',
                `img-wrapper-${uploadFilesList.length}`,
                { loading: !file.uploaded },
              ]"
            >
              <template v-if="isValidFileFormat(file)">
                <img
                  v-if="file.type.split('/')[0] === 'image'"
                  :src="file.obj"
                />
                <template v-else>
                  <video @loadedmetadata="setVideoDuration(index, $event)">
                    <source type="video/mp4" :src="file.obj" />
                  </video>
                  <span class="video-duration">{{
                    getVideoDuration(index) | formatTimeFromSeconds
                  }}</span>
                </template>
              </template>
              <span>
                <AppIcon
                  name="close"
                  class="btn-delete"
                  width="14"
                  @click.prevent.stop="removeFile(file)"
                />
              </span>
              <ProgressBar
                v-if="!file.uploaded"
                :current="file.currentUpload"
                :max="file.totalUpload"
                show-value
                class="mt-1"
              />
            </div>
            <div
              v-if="activeButton === 'image'"
              class="media-upload-placeholder"
              :class="[
                uploadFilesList.length === 0
                  ? 'media-upload-placeholder-preview mt-5'
                  : uploadFilesList.length === 4
                  ? 'd-none'
                  : '',
              ]"
            >
              <label class="image-upload-label" for="imageUpload"></label>
              <img
                src="@/assets/images/icons/add-plus-button.svg"
                alt="add button"
              />
              <h2 class="mt-3">
                {{ $t('_web_upload_image', 'Upload image') }}
              </h2>
              <p :class="uploadFilesList.length >= 1 ? 'font-adjustment' : ''">
                {{
                  $t(
                    '_web_max_size-images-mb',
                    'Maximum file size 15 MB (4 images)'
                  )
                }}
              </p>
              <input
                id="imageUpload"
                class="d-none"
                name="imageUpload"
                type="file"
                :accept="validImagesFormats"
                multiple
                @change="prepareImagesForUpload"
              />
            </div>
          </div>

          <LinkPreview
            v-if="linkPreview && isLinkPreviewValid"
            v-bind="{ ...linkPreview, editMode: true }"
            @remove="removePreview"
          />
        </div>

        <PollOptionsEditor v-if="isPollPost" v-model="pollOptions" />
        <template
          v-if="account && account.isOrganization && connectedSocials.length"
        >
          <div class="d-flex align-items-center mt-6">
            <TextBlock
              variant="secondary"
              :text="$t('_web_post_share-settings-label', 'Share to:')"
              class="h-mr-4"
            />
            <div
              v-for="network in connectedSocials"
              :key="network"
              class="d-flex align-items-center w-auto h-mr-4"
            >
              <SocialIcon
                :name="`${network === 'facebook' ? 'fb' : network}`"
                :size="24"
              />
              <!-- <TextBlock class="mx-3">{{ network | startCase }}</TextBlock> -->
              <Checkbox
                :checked="getSocialValue(network)"
                class="h-ml-3 flex-grow-0"
                @change="$event => setSocialValue(network, $event)"
              />
            </div>
          </div>
        </template>
        <!--<div
          class="d-flex justify-content-between align-items-center mt-6 post-actions"
          :class="{ disabled: isActionBarDisabled }"
          @click.prevent.stop
        >
          <div class="d-flex align-items-center"></div>
        </div>-->
      </div>
      <div
        v-if="activeButton === 'video'"
        class="media-upload-placeholder-video mt-5"
        :class="uploadFilesList.length === 1 ? 'd-none' : ''"
      >
        <label class="image-upload-label" for="videoUpload"></label>
        <img src="@/assets/images/icons/add-plus-button.svg" alt="add button" />
        <h2 class="mt-3">{{ $t('_web_upload_video', 'Upload video') }}</h2>
        <p>{{ $t('_web_max_size-mb', 'Maximum file size 125 MB') }}</p>
        <input
          id="videoUpload"
          class="d-none"
          name="imageUpload"
          type="file"
          :accept="validVideoFormats"
          @change="prepareImagesForUpload"
        />
      </div>
      <div
        v-if="activeButton === 'video'"
        :class="uploadFilesList.length === 1 ? 'thumbnail-section' : ''"
      >
        <h2 class="add-thumbnails">
          {{ $t('_web_add_thumbnail', 'ADD THUMBNAILS') }}
        </h2>
        <div class="thumb-upload-wrap">
          <div
            :style="
              thumbnailFile[0]
                ? `background-image: url(${thumbnailFile[0].obj})`
                : ''
            "
            class="thumb-landscape"
          >
            <label class="thumb-upload-label" for="thumbnail"></label>
            <input
              id="thumbnail"
              class="d-none"
              name="imageUpload"
              type="file"
              :accept="validImagesFormats"
              multiple
              @change="prepareThumbnailForUpload"
            />
            <img
              src="@/assets/images/icons/add-plus-button.svg"
              alt="add button"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="action-buttons-wrapper">
      <button
        class="action-buttons"
        :class="[activeButton === 'image' ? 'action-active' : '']"
        @click="imagePost"
      >
        <img src="@/assets/images/icons/image-button.svg" alt="image post" />
        {{ $t('_web_add_image', 'Add Image') }}
      </button>
      <button
        class="action-buttons"
        :class="[activeButton === 'video' ? 'action-active' : '']"
        @click="videoPost"
      >
        <img src="@/assets/images/icons/video-button.svg" alt="video post" />
        {{ $t('_web_add_video', 'Add Video') }}
      </button>
      <button
        class="action-buttons"
        :class="[activeButton === 'poll' && isPollPost ? 'action-active' : '']"
        @click="togglePollCreation"
      >
        <img src="@/assets/images/icons/poll-button.svg" alt="poll post" />
        {{ $t('_web_create_poll', 'Create Poll') }}
      </button>
    </div>
    <div class="line-seperation line-bottom"></div>

    <div class="publish-wrapper-buttons d-flex">
      <HeraFormBtn
        class="btn-post-new"
        :disabled="isShareBtnDisabled"
        :class="{
          'is-loading': createPostProceed,
        }"
        :label="
          !postModel
            ? $t('_web_create_post_publish-post', 'Publish')
            : $t('_web_create_post_save-post', 'Save')
        "
        @click.stop="publish"
      />
      <button class="cancel-btn-modal" @click="closeModal">
        {{ $t('web_create-clan_cancel', 'Cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppIcon from '@/components/atoms/Icon';
import TextInputMultiline from '@/components/molecules/form/TextInputMultilineWithActivators';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import PollOptionsEditor from './components/PollOptionsEditor';
import ProgressBar from '@/components/atoms/ProgressBar';
import LinkPreview from '@/components/molecules/OpenGraphCard';
import SocialIcon from '@/components/images/Social';
import TextBlock from '@/components/atoms/TextBlock';
import Checkbox from '@/components/atoms/form/Checkbox';
import { removeTagsMention } from '@/filters/string';
import { GENERIC, POLL } from '@/constants/postTypes';

const INPUT_ROWS = 4;
const MAX_ATTACHMENTS = 4;
const POST_MAX_LENGTH = 1000;
const POLL_MAX_LENGTH = 255;

export default {
  name: 'CreatePost',

  inject: ['$validator'],

  components: {
    AppIcon,
    TextInputMultiline,
    HeraFormBtn,
    PollOptionsEditor,
    ProgressBar,
    LinkPreview,
    SocialIcon,
    TextBlock,
    Checkbox,
  },
  props: {
    authorModel: {
      type: Object,
      required: true,
    },
    postModel: {
      type: Object,
      default: () => null,
      required: false,
    },
  },

  data() {
    return {
      activeButton: '',
      createPostProceed: false,
      showActions: true,
      allImagesUploaded: false,
      allThumbnailUploaded: false,
      thumbnailFile: [],
      text: '',
      uploadFilesList: [],
      duration: {},
      validImagesFormats: [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'image/gif',
      ],
      validVideoFormats: [
        'video/mp4',
        'video/mov',
        'video/3gp',
        'video/quicktime',
      ],
      validFilesFormats: [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'video/mp4',
        'video/mov',
        'video/3gp',
        'video/quicktime',
      ],

      isPollPost: false,
      pollOptions: {},
      linkPreview: null,
      // {
      //   image:
      //     'https://cdn2.unrealengine.com/14br-bplaunch-egs-s1-2560x1440-2560x1440-851249168.jpg',
      //   url: 'https://isddesign.atlassian.net/browse/TH-10462',
      //   title: 'link test title',
      //   description: 'jira task',
      // },
      INPUT_ROWS,
      isLinkPreviewValid: true,
      connectedSocials: [],
      repostToTwitter: false,
      repostToFacebook: false,
      debouncedPreviewRequest: null,
    };
  },
  computed: {
    ...mapState('my', ['account']),
    isActionBarDisabled() {
      return this.uploadFilesList.length > 0 && !this.allImagesUploaded;
    },

    isAttachBtnDisabled() {
      return this.uploadFilesList.length >= MAX_ATTACHMENTS || this.isPollPost;
    },

    isPollBtnDisabled() {
      return !!this.uploadFilesList.length;
    },

    isShareBtnDisabled() {
      switch (this.activeButton) {
        case '':
          return !this.text || this.text.length > this.maxLength;
        case 'image':
        case 'video':
          return !this.uploadFilesList.length;
        default:
          return false;
      }
    },

    maxLength() {
      return this.isPollPost ? POLL_MAX_LENGTH : POST_MAX_LENGTH;
    },
  },
  watch: {
    activeButton(newVal, oldVal) {
      if (
        (newVal === 'image' && oldVal === 'video') ||
        (newVal === 'video' && oldVal === 'image') ||
        (newVal === 'poll' && oldVal === 'image') ||
        (newVal === 'poll' && oldVal === 'video')
      ) {
        this.uploadFilesList = []; // Reset the uploadFilesList to an empty array
      }
    },
  },
  mounted() {
    if (this.postModel) {
      setTimeout(() => {
        this.$refs.postInput.focus();
      }, 10);
    }
  },
  created() {
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
    if (this.postModel) {
      this.text = removeTagsMention(this.postModel.text);
      this.allImagesUploaded = true;
      this.thumbnailFile = this.preSetUploadFile(this.postModel.thumbnail);
      this.uploadFilesList = this.preSetUploadFile(this.postModel.attachments);
    }
  },
  beforeDestroy() {
    delete this.fileReader;
  },

  methods: {
    ...mapActions([
      'createPost',
      'updatePost',
      'createPollPost',
      'sendFileDirect',
      'errorNotify',
      'getOpenGraphDataByLink',
      'fetchAccountSocialConnectionStatus',
    ]),
    ...mapActions('feed', ['setCreatePostModal']),
    closeModal() {
      this.setCreatePostModal(false);
    },
    proccesFile(file) {
      return file.map(el => {
        let type = el.type.split('/')[0] || 'image';
        return {
          type: type,
          file_id: el.file_id,
          [type]: el.display_uri,
        };
      });
    },
    preSetUploadFile(file) {
      return file.map(f => {
        const uri = f.type === 'image' ? f.image : f.video;
        return {
          file_id: f.token || f.file_id,
          display_uri: uri,
          obj: uri,
          type: f.type + '/' + uri.split('.').pop(),
          uploaded: true,
        };
      });
    },
    expand() {
      if (!this.createPostProceed) {
        if (this.account && this.account.isOrganization) {
          this.fetchAccountSocialConnectionStatus()
            .then(connections => {
              if (connections) {
                const platforms = [];
                if (connections.facebookCredentialsDefined) {
                  platforms.push('facebook');
                  this.repostToFacebook = true;
                }
                if (connections.twitterCredentialsDefined) {
                  platforms.push('twitter');
                  this.repostToTwitter = true;
                }
                this.connectedSocials = platforms;
              }
            })
            .catch(this.errorNotify);
        }
      }
    },
    getSocialValue(social) {
      return this[`repostTo${this.$lodash.startCase(social)}`];
    },
    setSocialValue(social, value) {
      this[`repostTo${this.$lodash.startCase(social)}`] = value;
    },
    getOpenGraphData(link) {
      this.isLinkPreviewValid = true;
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            if (!response.image && !response.title && !response.description) {
              this.isLinkPreviewValid = false;
            }
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },
    resetForm() {
      if (this.isActionBarDisabled) {
        return;
      }
      this.$refs.postInput.blur();

      this.createPostProceed = false;
      this.allImagesUploaded = false;
      this.allThumbnailUploaded = false;
      this.uploadFilesList = [];
      this.thumbnailFile = [];
      this.isPollPost = false;
      this.linkPreview = null;
      this.text = '';

      if (this.postModel) {
        this.$emit('post-cancel', this.postModel);
      }
    },
    videoPost() {
      this.activeButton === 'video'
        ? (this.activeButton = '')
        : (this.activeButton = 'video');
      this.isPollPost = false;
    },
    imagePost() {
      this.activeButton === 'image'
        ? (this.activeButton = '')
        : (this.activeButton = 'image');
      this.isPollPost = false;
    },
    removePreview() {
      this.linkPreview = null;
      this.isLinkPreviewValid = true;
    },
    removeFile(file) {
      const index = this.uploadFilesList.indexOf(file);
      this.uploadFilesList.splice(index, 1);
      this.uploadFilesList = [...this.uploadFilesList];
    },

    setVideoDuration(index, e) {
      this.$set(this.duration, index.toString(), Math.floor(e.target.duration));
    },

    getVideoDuration(index) {
      return this.duration[index.toString()];
    },
    prepareThumbnailForUpload({ target }) {
      const files = Array.from(target.files);
      const validFiles = files.filter(this.isValidFileFormat);
      if (validFiles.length === 0) {
        return;
      }
      this.allThumbnailUploaded = false;
      const thumbnailPromises = validFiles.slice(0, 1).map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = event =>
            resolve({
              name: file.name,
              size: file.size,
              type: file.type,
              obj: window.URL.createObjectURL(file),
              uploaded: false,
              imageData: file,
              blob: event.target.result,
            });
          reader.onerror = error => reject(error);
          reader.readAsArrayBuffer(file);
        }).catch(this.errorNotify);
      });
      Promise.all(thumbnailPromises).then(thumbnails => {
        this.thumbnailFile = thumbnails;
        this.uploadThumbnail();
      });
    },
    updateThumbnailUploadProgress(index, progress) {
      this.$set(this.thumbnailFile[index], 'currentUpload', progress.loaded);
      this.$set(this.thumbnailFile[index], 'totalUpload', progress.total);
    },
    async uploadThumbnail() {
      try {
        await Promise.all(
          this.thumbnailFile.map(async (file, index) => {
            if (file.uploaded) {
              return file;
            } else {
              try {
                const res = await this.sendFileDirect({
                  fileData: file,
                  config: {
                    onUploadProgress: progressEvent => {
                      this.updateThumbnailUploadProgress(index, progressEvent);
                    },
                  },
                });

                if (!file.uploaded) {
                  file.display_uri = res.display_uri || res.objectURL;
                  file.file_id = res.file_id;
                  file.uploaded = true;
                }
              } catch (error) {
                this.removeFile(file);
                this.thumbnailFile = [];
                this.errorNotify(
                  this.$t('_web_upload-file_failed', 'File upload failed!')
                );
              }
            }
          })
        );
      } finally {
        this.allThumbnailUploaded = true;
      }
    },
    prepareImagesForUpload({ target }) {
      const files = target.files;
      const isFilesValid = Array.from(files).every(this.isValidFileFormat);

      if (!files.length || !isFilesValid) {
        return;
      }

      this.allImagesUploaded = false;

      this.uploadFilesList = [
        ...this.uploadFilesList,
        ...[].map.call(files, el => {
          return {
            name: el.name,
            size: el.size,
            type: el.type,
            obj: window.URL.createObjectURL(el),
            uploaded: false,
            imageData: el,
          };
        }),
      ].slice(0, 4);

      let chain = Promise.resolve();

      this.uploadFilesList.forEach(el => {
        chain = chain.then(() => {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = e => resolve(e.target.result);
            reader.onerror = error => reject(error);
            reader.readAsArrayBuffer(el.imageData);
          })
            .then(response => (el.blob = response))
            .catch(this.errorNotify);
        });
      });
      chain.then(() => {
        this.uploadImages();
      });
    },
    updateFileUploadProgress(index, progress) {
      this.$set(this.uploadFilesList[index], 'currentUpload', progress.loaded);
      this.$set(this.uploadFilesList[index], 'totalUpload', progress.total);
    },

    async uploadImages() {
      const self = this;
      return Promise.all(
        this.uploadFilesList.map((file, index) =>
          file.uploaded
            ? file
            : this.sendFileDirect({
                fileData: file,
                config: {
                  onUploadProgress: function(progressEvent) {
                    self.updateFileUploadProgress(index, progressEvent);
                  },
                },
              })
                .then(res => {
                  if (!file.uploaded) {
                    file.display_uri = res.display_uri || res.objectURL;
                    file.file_id = res.file_id;
                    file.uploaded = true;
                  }
                })
                .catch(() => {
                  this.removeFile(file);
                  this.errorNotify(
                    this.$t('_web_upload-file_failed', 'File upload failed!')
                  );
                })
        )
      ).finally(() => (this.allImagesUploaded = true));
    },
    removePoll() {
      this.pollOptions = {};
      this.isPollPost = !this.isPollPost;
    },
    addHashMentionsLinks(text) {
      const hashRegex = /(?:|^)(?:#(?!\d+(?:\s|$)))(\w+)(?=\s|#|$)/gi;
      const mentionRegex = /((?=[^\w!])@\w+\b)/g;

      const hashReplacer = query => {
        const replacementString = query.match(hashRegex)[0].trim();
        return `<a href="hashtag://${replacementString.substring(
          1
        )}">${String.fromCharCode(0x200e) + replacementString}</a>`;
      };

      const mentionReplacer = query => {
        const replacementString = query.match(mentionRegex)[0].trim();
        return `<a href="profile://${replacementString.substring(
          1
        )}">${String.fromCharCode(0x200e) + replacementString}</a>`;
      };

      const textWithHashLinks = text.replace(hashRegex, hashReplacer);
      return textWithHashLinks.replace(mentionRegex, mentionReplacer);
    },
    publish(value) {
      if (this.isActionBarDisabled) {
        return;
      }

      if (typeof value === 'string') {
        this.text = value;
      }

      this.createPostProceed = true;

      const postData = this.postModel
        ? this.addHashMentionsLinks(this.text)
        : this.text;

      const data = {
        id: this.postModel ? this.postModel.id : undefined,
        display: {
          author: {
            avatar: this.authorModel._avatar,
            login: this.authorModel._id,
            name: this.authorModel._name,
          },
          comments_count: 0,
          created_on: Math.floor(new Date().getTime() / 1000),
          reactions: {
            dislike: { count: 0, reacted: false },
            like: { count: 0, reacted: false },
          },
          preview:
            !this.uploadFilesList.length && this.linkPreview
              ? this.linkPreview
              : null,
          attachments: this.proccesFile(this.uploadFilesList),
          thumbnail: this.proccesFile(this.thumbnailFile),
          text: postData,
        },
        send: {
          ...(this.authorModel.isClan ? { clan_id: this.authorModel._id } : {}),
          text: postData,
          attachments: this.uploadFilesList.map(el => el.file_id),
          repostToTwitter: this.repostToTwitter,
          repostToFacebook: this.repostToFacebook,
          thumbnail: this.thumbnailFile.map(el => el.file_id)[0],
          preview:
            !this.uploadFilesList.length &&
            this.linkPreview &&
            this.isLinkPreviewValid
              ? this.linkPreview
              : null,
        },
      };

      (this.isPollPost
        ? this.$validator.validateAll().then(valide => {
            if (valide) {
              return this.createPollPost({
                text: this.text,
                ...this.pollOptions,
                clan_id: data.send.clan_id,
              });
            } else {
              this.createPostProceed = false;
            }
          })
        : this.postModel
        ? this.updatePost(data)
        : this.createPost(data)
      )
        .then(post => {
          if (post) {
            this.$emit('post-created', {
              id: this.postModel ? this.postModel.id : undefined,
              post,
              post_type: this.isPollPost ? POLL : GENERIC,
            });

            this.resetForm();
            this.closeModal();

            window.dataLayer.push({
              event: 'post created',
              from: this.$route.path,
              hasAttachments: post.attachments.length > 0,
              postType: this.isPollPost ? 'poll' : 'regular',
              postID: post.id,
              userID: this.account._id,
            });
          }
        })
        .catch(err => {
          this.errorNotify(err);
          this.createPostProceed = false;
        });
    },

    isValidFileFormat(file) {
      const isValidFormat = this.validFilesFormats.indexOf(file.type) !== -1;
      const isValidSize = true; // file.size / (1024 * 1024) <= 100 Check - is file bigger than 100Mb
      let errors = [];

      if (!isValidFormat) {
        errors.push(
          this.$t(
            '_web_create_post-file-format-error',
            'File format is invalid'
          )
        );
      }
      if (!isValidSize) {
        errors.push(
          this.$t(
            '_web_create_post-file-size-error',
            'File size is bigger than 100Mb'
          )
        );
      }

      if (errors.length) {
        this.errorNotify(errors.join('<br>'));
      }

      return isValidFormat && isValidSize;
    },

    removeInvalidFiles() {
      this.uploadFilesList = this.uploadFilesList.filter(
        this.isValidFileFormat
      );
    },

    togglePollCreation() {
      this.isPollPost = !this.isPollPost;
      this.activeButton = 'poll';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input-create {
  width: 562px !important;
  max-width: 100% !important;
  border-radius: 5px;
}

.create-feed-post {
  border-radius: $ph-large-radius;

  .textarea {
    min-height: 2.25rem;
  }

  &_overlay {
    background: $ph-bg;
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0.5;
  }

  .create-feed-post_body-wrapper-new {
    //width: calc(100% - 52px);
    max-width: 100% !important;
  }

  .create-feed-post_body-new {
    .form-group {
      margin: 0;
      padding: 0.5rem 0.75rem;
    }

    .img-preview {
      video {
        border-radius: $ph-medium-radius;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video-duration {
        font-size: 0.75rem;
        color: $ph-secondary-text;
        background: #000;
        line-height: 16px;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 0.25rem;
      }
    }
  }

  .post-actions {
    .btn-action {
      font-size: 0.875rem;
      color: $ph-primary-text;
      @include primary-background-gradient(135deg);
      border: none;
      border-radius: 1rem;
      outline: none;
      min-width: 150px;
      height: 2rem;
      line-height: 1.8rem;
      padding: 0;
      cursor: pointer;

      &[disabled] {
        color: $ph-secondary-text;
      }

      svg {
        margin: -2px 4px 0;
      }
    }

    label.post-select-files {
      position: relative;
      cursor: pointer;
      background: transparent;
      vertical-align: middle;
      margin-bottom: 0;

      input {
        position: absolute;
        top: -1000px; // do not remove it
        left: 0px;
        display: none; //this prevents scrolling to the top when uploading files
      }
    }

    button[disabled] {
      label {
        pointer-events: none;
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  &_input {
    textarea::placeholder {
      color: $ph-primary-text;
    }

    &--collapsed {
      textarea {
        background-color: transparent !important;
      }
    }
  }
}

.line-bottom {
  margin-bottom: 43px;
  margin-top: auto;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.action-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 999px;
  background-color: #181c25;
  border: 1px solid #30374a;
  width: 115px;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
}

.action-buttons-wrapper {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 414px) {
    .action-buttons:last-child {
      margin-top: 0;
    }
  }
}

.publish-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: $hr-spacing-07;
}

.cancel-btn-modal {
  border: none;
  color: #9d9cf7;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
}

.btn-publish {
  background-color: #5a58f2 !important;
  border-radius: 8px !important;
  width: 87px !important;
  height: 48px !important;
  padding: 0 !important;
}

// .publish-wrapper-buttons {
//   margin-bottom: 60px;
// }
.text-input-feed-modal {
  max-width: 100% !important;

  .input-form {
    background-color: #181c25 !important;
    color: #4b5672 !important;
    min-height: 94px !important;
    max-height: 94px !important;
    max-width: 100% !important;
    overflow-y: scroll;
  }

  .custom-scrollbar {
    // hide scrollbars but still scrollable
    -ms-overflow-style: none; // IE
    scrollbar-width: none; // FireFox

    &::-webkit-scrollbar {
      // Chrome
      display: none;
    }
  }
}

.action-active {
  background-color: #1c1b55;
}

.media-upload-placeholder-preview {
  width: 100% !important;
}

.font-adjustment {
  font-size: 9px !important;
}

.media-upload-placeholder-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid #474a56;
  border-radius: 5px;
  min-height: 171px;
  margin-bottom: 28px;
  cursor: pointer;

  h2 {
    font-weight: 400;
    color: white;
    line-height: 14.4px;
    font-size: 17px;
  }

  p {
    font-size: 12px;
    color: #474a56;
    max-width: 270px;
  }
}

.media-upload-placeholder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // width: 100%;
  border: 1px solid #474a56;
  border-radius: 5px;
  // height: 171px;
  margin-bottom: 28px;
  min-height: 171px;
  cursor: pointer;

  @media (max-width: 600px) {
    // width: 100%;
  }

  h2 {
    font-weight: 400;
    color: white;
    line-height: 14.4px;
    font-size: 17px;
  }

  p {
    font-size: 12px;
    color: #474a56;
    max-width: 270px;
  }
}

.image-upload-label {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.create-feed-post-wrapper-new {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.create-feed-post_body-wrapper-new {
  display: flex;
  flex-direction: column;
  // max-height: 10rem !important;
  overflow-y: auto !important;
  display: flex;
  // padding: 5px;
  max-height: 30vh;

  @media (max-width: 900px) {
    max-height: 100%;
    // max-height: 44vh;
  }

  // max-height: 380px !important;
}

.add-thumbnails {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 400;
  color: #929395;
}

.thumb-landscape {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 177px;
  height: 93px;
  border-radius: 5px;
  border: 1px solid #474a56;
  gap: 10px;
  cursor: pointer;
}

.thumb-port {
  cursor: pointer;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 177px;
  border: 1px solid #474a56;
  border-radius: 5px;
}

.thumb-upload-wrap {
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
}

.thumbnail-section {
  margin-top: 35px;
}

.text-input-wrap {
  .img-preview {
    width: unset !important;
    height: unset !important;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 8px;

    .img-wrapper {
      &-1 {
        position: relative;
        width: 100%;
        height: 171px;

        @media (max-width: 600px) {
          width: 100%;
        }

        img {
          object-fit: cover;
          width: 100% !important;
          height: 100%;
        }

        span {
          span {
            position: absolute;
            top: 24px;
            @include auto-rtl(right, 22px);
          }
        }
      }

      &-2 {
        position: relative;
        width: 100%;
        height: 171px;

        @media (max-width: 600px) {
          width: 100%;
        }

        img {
          object-fit: cover;
          width: 100% !important;
          height: 100%;
        }

        span {
          span {
            position: absolute;
            top: 24px;
            @include auto-rtl(right, 22px);
          }
        }
      }

      &-3 {
        position: relative;
        width: 100%;
        height: 171px;

        @media (max-width: 600px) {
          width: 100%;
        }

        span {
          span {
            position: absolute;
            top: 24px;
            @include auto-rtl(right, 22px);
          }
        }

        img {
          object-fit: cover;
          width: 100% !important;
          height: 100%;
        }
      }

      &-4 {
        position: relative;
        width: 100%;
        height: 171px;

        @media (max-width: 600px) {
          width: 100%;
        }

        span {
          span {
            position: absolute;
            top: 24px;
            @include auto-rtl(right, 22px);
          }
        }

        img {
          object-fit: cover;
          width: 100% !important;
          height: 100%;
        }
      }
    }
  }

  .img-preview-single {
    display: block;
    width: 100%;
  }
}

.videos {
  width: 300px !important;
  height: 200px !important;

  .video-duration {
    position: absolute;
    left: 0px;
    bottom: 0px;
    @include auto-rtl(left, 0px);
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.thumb-upload-label {
  position: absolute;
  min-width: 100%;
  z-index: 5;
  min-height: 100%;
  cursor: pointer;
}
</style>
