<template>
  <a
    :href="link"
    :download="fileName"
    class="filename d-flex align-items-center"
  >
    <span class="filename__icon h-mr-1">
      <Icon :name="icon" :fill="$scss.iconPrimaryColor" />
    </span>
    <span class="filename__text text-truncate flex-shrink-1">
      {{ getFileNameWithoutExt(fileName) }}
    </span>
    <span class="filename__text">.{{ getFileExt(fileName) }}</span>
  </a>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import { getFileExt, getFileNameWithoutExt } from '@/utils';

export default {
  components: { Icon },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'attach',
    },
  },
  methods: {
    getFileExt,
    getFileNameWithoutExt,
  },
};
</script>

<style lang="scss">
.filename {
  direction: ltr;
  color: $ph-primary-text;
  text-decoration: underline;
  &__text {
    transition: all 0.3s;
  }
  &:hover {
    color: $ph-accent;
    .filename__text {
      color: $ph-primary-text;
    }
  }
}
.rtl .filename__icon {
  order: 3;
}
</style>
