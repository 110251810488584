<template>
  <ModalBackdrop
    v-model="isVisibleModel"
    :click-on-backdrop="true"
    :esc-key="true"
    no-wrapper
    class="mediaplayer"
    :variant="fullScreen ? 'full-width' : 'default'"
    ><div class="mobile-close-btn" @click="isVisibleModel = false">
      <div class="pagi-box">
        <Icon name="close" width="16" />
      </div>
    </div>
    <div class="media-player d-flex align-items-stretch">
      <!-- v-show for correct ref work -->
      <div
        v-if="hasMultipleItems"
        class="arrow-wrapper flex-shrink-0 flex-grow-0 px-3 cursor-pointer d-flex align-items-center"
        @click="onPrev"
      >
        <div class="pagi-box">
          <Icon name="arrow-left" width="30" />
        </div>
      </div>
      <div class="img-preview-wrapper">
        <img
          v-show="type === 'image'"
          :src="src"
          class="cursor-hand"
          alt="image"
          @click="imageClickHandler"
        />
        <video
          v-show="type === 'video'"
          ref="video"
          :src="src"
          autoplay
          controls
        ></video>
        <div v-if="hasMultipleItems" class="preview-sm">
          <div
            v-for="(item, index) in mediaGroup"
            :key="index"
            class="img-wrapper-preview"
            @click="imageClickHandler"
          >
            <img
              v-if="item.type === 'image'"
              class="cursor-pointer img-preview"
              :src="item.url"
              @click="imageClickHandler(index)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="hasMultipleItems"
        class="arrow-wrapper flex-shrink-0 flex-grow-0 px-3 cursor-pointer d-flex align-items-center"
        @click="onNext"
      >
        <div class="pagi-box">
          <Icon name="arrow-left" width="30" :rotate="180" />
        </div>
      </div>
    </div>
  </ModalBackdrop>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { globalEventListener } from '@/utils/globalEvent';
import ModalBackdrop from '@/components/common/ModalBackdrop';
import Icon from '@/components/atoms/Icon';
const NEXT_KEYCODE = 'ArrowRight';
const PREV_KEYCODE = 'ArrowLeft';

export default {
  components: {
    ModalBackdrop,
    Icon,
  },
  computed: {
    ...mapState('mediaplayer', {
      isVisible: state => state.isVisible,
      type: state => state.type,
      src: state => state.url,
      preview: state => state.preview,
      currentTime: state => state.currentTime,
      onHide: state => state.onHide,
      fullScreen: state => state.fullScreen,
      mediaGroup: state => state.mediaGroup,
    }),
    hasMultipleItems() {
      return this.mediaGroup && this.mediaGroup.length > 1;
    },
    isVisibleModel: {
      get() {
        return this.isVisible;
      },
      set() {
        if (typeof this.onHide === 'function') {
          this.onHide(this.$refs.video.currentTime);
        }
        this.closePlayer();
      },
    },
  },

  watch: {
    currentTime(time) {
      if (this.type === 'video' && this.$refs.video) {
        this.$refs.video.currentTime = time;
      }
    },

    isVisible(visible) {
      if (visible) {
        globalEventListener('add', 'keydown.mediaplayer', this.keypressHander);
      } else {
        globalEventListener('remove', 'keydown.mediaplayer');
      }
    },
  },

  methods: {
    ...mapMutations('mediaplayer', [
      'closePlayer',
      'next',
      'prev',
      'setSrc',
      'setType',
    ]),

    keypressHander({ key }) {
      if (key === NEXT_KEYCODE) {
        this.next();
      } else if (key === PREV_KEYCODE) {
        this.next(-1);
      }
    },

    imageClickHandler(index) {
      if (typeof index !== 'undefined') {
        const selectedItem = this.mediaGroup[index];
        this.setSrc(selectedItem.url);
        this.setType(selectedItem.type);
      } else {
        this.onNext();
      }
    },

    onNext() {
      this.next();
    },
    onPrev() {
      this.prev();
    },
  },
};
</script>

<style lang="scss">
.media-player {
  img,
  video {
    width: 100%;
    height: 100%;
    max-width: 85vw;
    max-height: 80vh;
  }
  .arrow-wrapper {
    flex: 1;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

.mediaplayer.modalbackdrop--full-width {
  .modalbackdrop_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }
  .media-player {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    img,
    video {
      width: auto;
      height: auto;
    }
  }
}

.mediaplayer.modalbackdrop--full-width {
  .modalbackdrop_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }
  .media-player {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    img,
    video {
      width: auto;
      height: auto;
    }
  }
}
.mobile-close-btn {
  display: block;
  position: fixed;
  top: 1rem;

  @include auto-rtl(left, 1rem);
  z-index: 10001;
  cursor: pointer;
}
@media (max-width: 767px) {
  .preview-sm {
    display: none;
  }
  .media-player {
    img,
    video {
      width: 100%;
      height: 100%;
      max-width: 82vw;
      max-height: 90vh;
      object-fit: contain;
    }
  }
  .pagi-box {
    width: 27px !important;
    height: 27px !important;
  }
  .mobile-close-btn {
    position: fixed;
    top: 1rem;
    @include auto-rtl(left, 1rem);
  }
}
.img-wrapper-preview {
  display: block;
  border-radius: 25px;
}
.img-preview {
  width: 102px !important;
  height: 102px !important;
  img {
    width: fit-content !important;
  }
}
.img-preview-wrapper {
  display: flex;
  flex-direction: column;

  img {
    border-radius: 5px;
    object-fit: cover;
  }
}
.preview-sm {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .preview-sm {
    display: none;
  }
}
.pagi-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90%;
  background-color: #30374a;
  width: 52px;
  height: 52px;
  .app-rtl & {
    rotate: 180deg;
  }
}
</style>
