<template>
  <div>
    <HeraFormInput
      key="points-for-lose-field"
      v-model="pointsForLose"
      v-validate="'required|numeric|decimal:0|min_value:0'"
      :label="$$t('Number of points for lose:', 'points-for-lose')"
      required
      size="2"
      class="tie-points-input"
      :class="{ 'is-invalid': errors.first('points-for-lose') }"
      :view-mode="disabled"
      :disabled="isStageFieldDisabled('pointsForLose') || disabled"
      name="points-for-lose"
      :error="errors.first('points-for-lose')"
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormInput from '@/components/atoms/form/HeraFormTextInput';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StagePointsForLoseSetting',
  components: {
    HeraFormInput,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    pointsForLose: {
      get() {
        return this.getBlockConfigurationByKey('points_loss');
      },
      set(newVal) {
        this.setBlockConfigurationByKey('points_loss', newVal);
      },
    },
  },
};
</script>

<style></style>
