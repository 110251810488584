var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row my-3 pb-3"},[_c('div',{staticClass:"col-auto"},[_c('HeraFormSwitch',{attrs:{"name":"checkIn","label":_vm.$$t('Require Check-in:', 'stage-check-in-label'),"tooltip":_vm.$$t(
          'Require check-in from players before a competition starts',
          'stage-check-in-tooltip'
        ),"disabled":_vm.isStageFieldDisabled('checkin') || _vm.disabled},model:{value:(_vm.checkIn),callback:function ($$v) {_vm.checkIn=$$v},expression:"checkIn"}})],1),_c('div',{staticClass:"col-auto d-flex align-items-center"},[_c('TimePicker',{directives:[{name:"validate",rawName:"v-validate",value:(
        !_vm.checkIn ||
        _vm.checkIfCheckInStarted() ||
        _vm.isStageFieldDisabled("stage_check_in_start_date") ||
        _vm.disabled
          ? {}
          : Object.assign({}, {required: true},
              (_vm.currentTournamentStage.date_end
                ? {
                    before: _vm.calcMaxDate('check_in_start_date'),
                  }
                : {}))
      ),expression:"\n        !checkIn ||\n        checkIfCheckInStarted() ||\n        isStageFieldDisabled(`stage_check_in_start_date`) ||\n        disabled\n          ? {}\n          : {\n              required: true,\n              ...(currentTournamentStage.date_end\n                ? {\n                    before: calcMaxDate('check_in_start_date'),\n                  }\n                : {}),\n            }\n      "}],staticClass:"date-time-input",attrs:{"duration":"","value":_vm.checkInDate,"required":_vm.checkInTimeSteps['check_in_start_date'].required || _vm.checkIn,"data-vv-name":_vm.$lodash.lowerCase('check_in_start_date'),"data-vv-as":_vm.$t(
          "_web_tournament_date-check_in_start_date",
          _vm.checkInTimeSteps['check_in_start_date'].label
        ),"disabled":!_vm.checkIn ||
          _vm.isStageFieldDisabled("stage_check_in_start_date") ||
          _vm.disabled,"label":_vm.$t(
          "_web_tournament_date-check_in_start_date",
          _vm.checkInTimeSteps['check_in_start_date'].label
        )},on:{"input":_vm.onCheckInDateUpdate}}),_c('TextBlock',{staticClass:"h-ml-3 mt-4",attrs:{"variant":"muted"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          '_web_tournament_stage_form_check_in_start_date-postfix',
          'before stage start'
        ))+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }