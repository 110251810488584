<template>
  <div>
    <router-link
      v-if="canEditTournaments"
      :to="{
        name: 'TournamentDashboard',
        params: { tournamentID: id },
      }"
      ><slot
    /></router-link>
    <LinkAccount v-else :id="id" :vanity-id="vanityId" :type="TOURNAMENT">
      <slot />
    </LinkAccount>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TOURNAMENT } from '@/constants/accounts';
import LinkAccount from '@/components/links/Account';

export default {
  name: 'TournamentLink',
  components: {
    LinkAccount,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vanityId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      TOURNAMENT,
    };
  },
  computed: {
    ...mapGetters(['hasAccessByPermission']),
    canEditTournaments() {
      return this.hasAccessByPermission('editTournament');
    },
  },
};
</script>
