import { startCase, isString } from '@/utils/lodashUtils';

export const separatedWithComma = value => {
  let result = [];
  if (isString(value)) {
    result.push(value);
  } else if (Array.isArray(value)) {
    result = value;
  } else {
    throw new Error('Invalid parameter type: should be an array or string');
  }
  return result.join(', ');
};

/**
 * turncate text ({ "Loooooooong text" | truncate(8)} -> "Loooo...")
 * @param {string} input
 * @param {number} length
 * @returns {string}
 */
export const truncate = (input, length) => {
  if (typeof input !== 'string') return '';
  return input.length > length + 3 ? input.slice(0, length - 3) + '...' : input;
};

const multiline = val => {
  return val.replace(/(?:\r\n*)|\n/gm, '<br>');
};

export const countReplace = count => {
  const countSuffixes = [
    '', // custom
    'k', // kilo-
    'm', // mega-
    'g', // giga- (...tera-, peta-, exa-, zetta-, yotta- etc)
  ];
  let suffixIndex = 0;
  count = +count;

  while (count > 1e3) {
    count /= 1e3;
    suffixIndex++;
  }

  return `${Math.floor(count)}${countSuffixes[suffixIndex] || ''}`;
};

export const removeTagsMention = str => {
  return String(str).replace(
    /<a href=(?:"|')(?:hashtag|profile|clan|organization|admin):\/\/[\u0621-\u064A0-9一-龠ぁ-ゔ々〆〤ヶァ-ヴーａ-ｚＡ-Ｚ０-９/\w]+(?:"|')>([^<]+)<\/a>/gi,
    '$1'
  );
};

export const unescapeHTML = str => {
  return String(str)
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
};

export default {
  separatedWithComma,
  truncate,
  startCase,
  multiline,
  countReplace,
  removeTagsMention,
  unescapeHTML,
};
