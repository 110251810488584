var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"standings"},[_c('Widget',{staticClass:"mb-5",attrs:{"title":_vm.$t('_web_tournament_block_standings', 'Standings'),"actions-menu":_vm.actionMenuItems,"hide-search":""}}),(_vm.tabsVisible)?_c('HorizontalTabs',_vm._b({},'HorizontalTabs',{ tabs: _vm.tabs, variant: 'folders' },false)):_vm._e(),_c('Panel',{staticClass:"standings-tables-wrapper",class:{
      tabs: _vm.tabsVisible,
      'ph-panel--rounded-top-left-0 ph-panel--rounded-top-right-0': _vm.tabsVisible,
    }},[_c('div',[_c('Icon',{staticClass:"h-mr-3",attrs:{"name":_vm.currentStageType.key,"fill":_vm.$scss.secondaryTextBg,"width":"24"}}),_c('TextBlock',{attrs:{"text":_vm.$$t(_vm.currentStageType.name, _vm.currentStageType.key),"variant":"secondary"}})],1),(!_vm.isLoading && (!_vm.currentStage || !_vm.blocks || !_vm.blocks.length))?_c('HeraDummyList',{attrs:{"size":"200","icon-name":"dummy/members","text-header":_vm.$$t('No participants', 'empty'),"text":_vm.$$t('', 'empty_subheader')}}):_c('div',{staticClass:"row position-relative"},[_c('div',{class:[
          'col-12',
          { 'col-md-8 col-lg-6': _vm.isSingleElimination || _vm.isDE } ]},[_c('div',{staticClass:"row standings-tables"},_vm._l((_vm.blocks),function(block){return _c('div',{key:block.id,staticClass:"mt-4 col-12"},[_c('GroupTable',{staticClass:"h-100 flex-grow-1 standings-table",attrs:{"id":block.id,"title":block.title,"type":_vm.currentStageType.type,"tie-breaker":_vm.$lodash.get(
                  _vm.currentStage,
                  'block_configuration.tie_breaker_preset'
                )}})],1)}),0)]),_c('div',{staticClass:"d-flex pt-4 flex-column sticky-top",class:[
          'col-12',
          { 'col-md-4 col-lg-6': _vm.isSingleElimination || _vm.isDE } ]},[(_vm.currentTournament)?[(
              _vm.currentTournament.prize &&
                _vm.currentTournament.prize.sponsors &&
                _vm.currentTournament.prize.sponsors.length
            )?_c('div',{staticClass:"mb-4"},[_c('TextBlock',{attrs:{"text":_vm.$t('_web_tournament_sponsored-by', 'Sponsored by'),"variant":"secondary"}}),_c('TournamentSponsors',{attrs:{"height":100}})],1):_vm._e(),(
              _vm.currentTournament.info.event_organizer &&
                _vm.currentTournament.info.event_organizer.isAvailable
            )?_c('div',[_c('TextBlock',{attrs:{"text":_vm.$t('_web_tournament_organized-by', 'Organized by'),"variant":"secondary"}}),_c('ListItemAccount',{attrs:{"type":_vm.organizationType,"avatar":_vm.currentTournament.info.event_organizer.logo,"name":_vm.currentTournament.info.event_organizer.name,"size":36}})],1):_vm._e()]:_vm._e()],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }