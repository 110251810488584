<template>
  <div class="slider-chat-chat d-flex flex-column">
    <ChatHeader
      :title="chat.title"
      :subtitle="
        `${participantsCountToRender} ${$t(
          '_web_tournament_sidechat_participants',
          'participants'
        )}`
      "
      :avatar="avatarType"
      :avatar-uri="newAvatar ? newAvatar : chat.avatar"
      active
      has-back-icon
      can-edit
      @back="$emit('closeChat')"
      @action="$emit('editChat')"
    />
    <div
      ref="messages"
      class="slider-chat-chat__messages custom-scrollbar d-flex flex-column-reverse align-items-center"
      @scroll="scrollHandler"
    >
      <ListLoader
        class="h-100 w-100"
        :is-loading="isLoading && !messages.length"
        :is-empty-list="!undeliveredMessages.length && !messages.length"
        :dummy-props="{
          iconName: 'dummy/chat',
          size: 200,
          subheaderText: $t(
            '_web_sidechat-dialog_is-empty',
            'You have no messages at the moment'
          ),
          text: $t(
            '_web_sidechat-dialog_is-empty_subheader',
            'Do not hesitate and send a first one'
          ),
        }"
      >
        <div
          v-show="messages && messages.length"
          slot="list"
          ref="chatDialog"
          class="w-100 h-100 custom-scrollbar chat-dialog"
          tabindex="-1"
        >
          <InfiniteLoadBothSides
            :list="messages"
            list-name="messages"
            :is-disabled="isLoading || !prevPageToken"
            :is-disabled-bottom="isLoading || !nextPageToken"
            :in-container="true"
            :activation-gap="1"
            @ScrollToTop="fetchMessageList"
            @ScrollToBottom="fetchMessageListTop"
          >
            <div slot-scope="{}" class="d-flex flex-column-reverse">
              <Message
                v-for="(item, i) in undeliveredMessages"
                :key="'item-' + 'index' + '-' + i"
                :data="item"
                :is-post="isChannel"
                undelivered
                @delete="removeUndeliveredMessage(item.id)"
                @resend="resendUndeliveredMessage(item)"
              />
              <Message
                v-for="(item, i) in messages"
                :key="'item-' + 'index' + '-' + i"
                :ref="item.id"
                :class="item.id === chat.focus && 'colorchange'"
                :data="item"
                :is-post="isChannel"
                @delete="removeMessage(item)"
                @openComments="openComments(item)"
                @openUserInfo="openUserInfo(item.sender)"
              />
            </div>
          </InfiniteLoadBothSides>
        </div>
      </ListLoader>
    </div>
    <SCInput
      v-if="!openedPost && !userInfo.visible"
      @submit="messageSendHandler"
    />
    <Post
      v-if="openedPost"
      :data="openedPost"
      can-edit
      @closePost="closeComments"
    />
    <UserInfo
      v-if="userInfo.visible"
      :user-id="userInfo.id"
      @close="closeUserInfo"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { uniqBy } from '@/utils/lodashUtils';
import { scrollToBottom, scrollToTop } from '@/utils/index';
import ChatHeader from './ChatHeader';
import Message from './Message';
import Post from './Post';
import SCInput from './Input';
import InfiniteLoadBothSides from '@/components/common/InfiniteLoadBothSides';
import UserInfo from './UserInfo';
import ListLoader from '@/components/common/HeraListPreloader';
import { getAvatarTypeByConversation } from '@/utils/getProps';
// import phrasesFill from '@/__mock__/phrasesFill';

export default {
  components: {
    ChatHeader,
    Message,
    Post,
    InfiniteLoadBothSides,
    SCInput,
    UserInfo,
    ListLoader,
  },
  props: {
    filter: {
      type: String,
      default: '',
    },
    // date: {
    //   type: Number,
    //   default: false,
    // },
    chat: {
      type: Object,
      required: true,
    },
    participantsCount: {
      type: Number,
      default: 0,
    },
    newAvatar: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      messages: [],
      openedPost: null,
      newMessageText: '',
      isLoading: false,
      nextPageToken: '',
      prevPageToken: '',
      userInfo: {
        id: '',
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters(['allUndeliveredMessages', 'sliderChatUnreadCount']),
    ...mapGetters('adminMention', ['corelation']),
    ...mapState('my', ['account']),
    participantsCountToRender() {
      return this.participantsCount !== 0
        ? this.participantsCount
        : this.chat.participants && !this.chat.participants_count
        ? this.chat.participants.length
        : this.chat.participants_count;
    },
    isChannel() {
      return this.chat.conversation_type === 'channel';
    },
    undeliveredMessages() {
      return this.allUndeliveredMessages.filter(
        msg => msg.conversationId === this.chat.id
      );
    },
    avatarType() {
      return getAvatarTypeByConversation(this.chat);
    },
  },
  watch: {
    messages() {
      if (
        this.messages.length > 0 &&
        this.prevPageToken &&
        this.messages.length < 10
      ) {
        this.fetchMessageListTop();
      }
    },
  },
  created() {
    this.fetchMessageList();
    this.setCorelation(this.chat.isTournamentRelated);

    this.checkConversationAsRead(this.chat.id).catch(this.errorNotify);
    this.setSliderChatUnreadCount(
      Math.max(0, this.sliderChatUnreadCount - this.chat.unread_count)
    );
    this.addMessageSubscriber(this.messagesSubscriber);
    this.fetchUndeliveredMessages();
    // for (let i = 0; i < phrasesFill.length; i++) {
    //   setTimeout(() => {
    //     this.postMessage(phrasesFill[i]);
    //   }, 3500);
    // }
  },

  destroyed() {
    this.removeMessageSubscriber(this.messagesSubscriber);
    this.setCorelation('');
  },
  methods: {
    ...mapMutations(['setSliderChatUnreadCount']),
    ...mapActions([
      'addMessageSubscriber',
      'removeMessageSubscriber',
      'sendMessage',
      'fetchMessages',
      'deleteMessage',
      'checkConversationAsRead',
      'errorNotify',
    ]),
    ...mapActions('adminMention', ['setCorelation']),
    ...mapMutations(['fetchUndeliveredMessages', 'removeUndeliveredMessage']),
    scrollHandler(e) {
      const activationGap = 0;
      if (
        e.target.scrollTop <= activationGap &&
        !this.isLoading &&
        this.nextPageToken !== null
      ) {
        this.fetchMessageList();
      }
    },
    scrollToElement() {
      this.$nextTick(() => {
        const element = this.$refs[`${this.chat.focus}`][0].$el;

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 600);
        }
      });
    },
    fetchMessageList() {
      this.isLoading = true;

      this.fetchMessages({
        conversationId: this.chat.id,
        pageToken: this.nextPageToken,
        focus:
          this.nextPageToken || this.prevPageToken ? null : this.chat.focus,
      })
        .then(res => {
          this.nextPageToken = res.next_page_token ? res.next_page_token : null;
          this.prevPageToken = res.prev_page_token ? res.prev_page_token : null;
          this.messages = uniqBy([...this.messages, ...res.items], 'id');
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
          if (this.chat.focus) {
            this.scrollToElement();
            this.isLoading = false;
          } else {
            this.prevPageToken
              ? (this.$refs.chatDialog.scrollTop =
                  this.$refs.chatDialog.scrollTop + 200)
              : (this.$refs.chatDialog.scrollTop = this.$refs.chatDialog.scrollHeight);
          }
        });
    },
    fetchMessageNew() {
      this.isLoading = true;
      this.fetchMessages({
        conversationId: this.chat.id,
        pageToken: null,
        focus:
          this.nextPageToken || this.prevPageToken ? null : this.chat.focus,
      })
        .then(res => {
          this.messages = [];
          this.nextPageToken = res.next_page_token ? res.next_page_token : null;
          this.prevPageToken = res.prev_page_token ? res.prev_page_token : null;
          this.messages = uniqBy([...this.messages, ...res.items], 'id');
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
          this.$refs.chatDialog.scrollTop = this.$refs.chatDialog.scrollHeight;
        });
    },
    fetchMessageListTop() {
      this.isLoading = true;
      this.fetchMessages({
        conversationId: this.chat.id,
        pageToken: this.prevPageToken,
        focus:
          this.nextPageToken || this.prevPageToken ? null : this.chat.focus,
      })
        .then(res => {
          this.nextPageToken = res.next_page_token ? res.next_page_token : null;
          this.prevPageToken = res.prev_page_token ? res.prev_page_token : null;
          this.messages = uniqBy([...res.items, ...this.messages], 'id');
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    messagesSubscriber(res) {
      if (
        res.type === 'chat_message_sent' &&
        res.extended.conversationId === this.chat.id
      ) {
        this.newMessageHandler(res);
      }
    },
    newMessageHandler({
      extended: { attachments: _attachments } = {},
      sender,
      sent_date,
      attachments = _attachments,
      id,
      extended: { sender: senderName, avatar, text },
    }) {
      if (!this.messages.find(el => el.id === id)) {
        this.addNewMessage(
          senderName,
          avatar,
          sent_date,
          text,
          attachments,
          id,
          sender.clan,
          sender.id
        );
      }
      this.checkConversationAsRead(this.chat.id).catch(this.errorNotify);
    },
    addNewMessage(
      sender_name,
      sender_avatar,
      sent_date,
      text,
      attachments,
      id,
      clanName,
      sender
    ) {
      const newMessage = {
        sender,
        sender_name,
        sender_avatar,
        chat_id: this.chat.id,
        text,
        sent_date,
        read: true,
        comments_count: 0,
        attachments,
        id,
        clanName,
      };
      this.messages.unshift(newMessage);
      return newMessage;
    },
    openComments(post) {
      this.openedPost = post;
    },
    closeComments(newCommentsCount) {
      this.openedPost.comments_count += newCommentsCount;
      this.openedPost = null;
    },
    messageSendHandler({ text, attachments = [] }) {
      this.postMessage(
        text,
        attachments.map(attach => ({
          type: attach.type,
          [attach.type]: attach.displayUri,
          fileOriginalName: attach.file.name,
          fileId: attach.fileId,
        }))
      );
    },
    postMessage(text, attachments, scrollAfterSend = true) {
      const newMessage = this.addNewMessage(
        this.account.name || this.account.login,
        null,
        Date.now() / 1e3,
        text,
        attachments
      );
      if (scrollAfterSend) {
        this.$nextTick(() => {
          scrollToBottom(this.$refs.messages) ||
            scrollToTop(this.$refs.messages);
        });
      }
      return this.sendMessage({ id: this.chat.id, data: { text, attachments } })
        .then(res => (this.fetchMessageNew(), (newMessage.id = res.id)))
        .then(scrollToBottom(this.$refs.messages))
        .catch(error => {
          this.messages.splice(this.messages.indexOf(newMessage), 1);
          this.errorNotify(error);
        });
    },
    removeMessage(msg) {
      if (!msg.id) return;
      this.deleteMessage({ chatId: this.chat.id, msgId: msg.id })
        .then(() => {
          const index = this.messages.indexOf(msg);
          this.messages.splice(index, 1);
        })
        .catch(this.errorNotify);
    },
    resendUndeliveredMessage({ text, attachments, id }) {
      this.postMessage(text, attachments, false);
      this.removeUndeliveredMessage(id);
    },
    openUserInfo(userId) {
      this.userInfo.visible = true;
      this.userInfo.id = userId;
    },
    closeUserInfo() {
      this.userInfo.visible = false;
    },
  },
};
</script>

<style lang="scss">
.slider-chat-chat {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $ph-input-bg;

  &__messages {
    position: relative;
    flex: 1 auto;
    margin: 0.75rem 0.25rem 0.75rem 0;
  }
}
.colorchange {
  bdi {
    background-color: orange !important;
    color: black !important;
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
