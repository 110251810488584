import api from '@/api';
import { isTokenValid } from '@/utils';
import PermissionsModel from '@/models/Permissions';
import { BASE_PERMISSIONS } from '@/constants/permissions';
import { mapValues } from '@/utils/lodashUtils';
const state = {
  status: '',
  tempToken: localStorage.getObjectFromItem('tempToken') || {},
  accessToken: localStorage.getObjectFromItem('accessToken') || {},
  refreshToken: localStorage.getObjectFromItem('refreshToken') || {},
  templateRegData: sessionStorage.getObjectFromItem('templateRegData') || null,
  isAdmin: false, //always false for WEB_USER portal
  isRefreshProcessing: false,
  refreshStopover: Promise.resolve(),
  isOrganization: !!localStorage.getItem('isOrganization'),
  permissions: localStorage.getObjectFromItem('permissions') || {},
};

const getters = {
  tempToken: state => state.tempToken.token,
  accessToken: state => state.accessToken.token,
  refreshToken: state => state.refreshToken.token,
  permissions: state => state.permissions,
  templateRegData: state => state.templateRegData,
  hasToken: state => tokenType => Boolean(state[tokenType].token),
  isRefreshProcessing: state => state.isRefreshProcessing,
  tokenObject: state => tokenType => state[tokenType],
  isAuthorized: (state, getters) => {
    return getters.hasToken('accessToken') && getters.hasToken('refreshToken');
  },
  isAuthenticated: (state, getters) => {
    return isTokenValid(getters.tokenObject('tempToken'));
  },
  isPartiallyAuthorized(state, getters) {
    return isTokenValid(getters.tokenObject('accessToken')) && !state.isAdmin;
  },
  isTempTokenOnly(state, getters) {
    return getters.tempToken && !getters.isAuthorized;
  },
  areAllTokensInvalid(state, getters) {
    return !getters.isAuthenticated && !getters.isAuthorized;
  },
  refreshStopover: state => state.refreshStopover,
  authStatus: state => state.status,
  isAdmin: state => state.isAdmin,
  isOrganization: state => state.isOrganization,
  isOrganizationToken: state => state.isOrganization,
};

const mutations = {
  /* DONT use here (Attention for merge with WEB_ADMIN portal files!)

  setIsAdmin(state, payload){
    state.isAdmin = payload
    payload
      ? localStorage.setItem('isAdmin', payload)
      : localStorage.removeItem('isAdmin')
  },
  */
  setToken(state, payload) {
    let token = {
      token: payload.token,
      expires: payload.expires,
    };
    localStorage.setItem(payload.type, JSON.stringify(token));
    state[payload.type] = token;
  },
  setTokenInStore(state, { tokenKey, token }) {
    state[tokenKey] = token;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  codeVerify(state) {
    state.status = 'verifying code';
  },
  codeRequest(state, { temporary_token, expires }) {
    // receive token expires in seconds
    // need to convert to milliseconds
    const token = {
      token: temporary_token,
      expires: expires * 1000,
    };
    localStorage.setItem('tempToken', JSON.stringify(token));
    state.tempToken = { ...state.tempToken, ...token };
    state.status = 'code requested';
  },
  saveRegData(state, data) {
    if (!data) {
      sessionStorage.removeItem('templateRegData');
      state.templateRegData = {};
      return;
    }

    const currentData = { ...state.templateRegData, ...data };

    sessionStorage.setObjectToItem('templateRegData', currentData);
    state.templateRegData = currentData;
  },
  authRequest(state) {
    state.status = 'checking if user exist';
  },
  userCreateRequest(state) {
    state.status = 'creating new user';
  },
  authSuccess(state, payload) {
    //set flag to local storage
    if (payload.context.type === 'organization') {
      state.isOrganization = true;
      localStorage.setItem('isOrganization', true);
    } else {
      state.isOrganization = false;
      localStorage.removeItem('isOrganization');
    }
    // receive token expires in seconds
    // need to convert to miliseconds
    const accessToken = {
      token: payload.access_token,
      expires: payload.access_token_expires * 1000,
    };
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    state.accessToken = { ...state.accessToken, ...accessToken };

    if (payload.refresh_token) {
      state.tempToken = {};
      localStorage.removeItem('tempToken');
      // receive token expires in seconds
      // need to convert to miliseconds
      const refreshToken = {
        token: payload.refresh_token,
        expires: payload.refresh_token_expires * 1000,
      };
      localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
      state.refreshToken = { ...state.refreshToken, ...refreshToken };
    }
    state.status = 'autorized';
  },
  authError(state, error) {
    state.status = error;
    // localStorage.removeItem('temp-token')
    // localStorage.removeItem('access-token')
    // localStorage.removeItem('refresh-token')
    // console.log(error)
  },
  requestPass(state) {
    state.status = 'user forgot his password';
  },
  submitPass(state) {
    state.status = 'user create new password';
  },
  refreshingTokens(state) {
    state.status = 'Refreshing Tokens';
  },
  passwordCreationError(state) {
    state.status = 'password not saved';
  },
  clearToken(state, tokenType) {
    state[tokenType] = {};
    localStorage.removeItem(tokenType);
  },
  setRefreshProcessing(state, payload) {
    state.isRefreshProcessing = payload;
  },
  changeRefreshStopover(state, promise) {
    state.refreshStopover = promise || Promise.resolve();
  },
  setPermissions(state, payload) {
    //modify permission response before use it
    // from ['Join', 'Invite'] -> { Join: true, Invite: true}

    const mappedPermissions = mapValues(payload.permissions, data => {
      return Array.isArray(data)
        ? data.reduce((acc, el) => ({ ...acc, [el]: true }), {})
        : data;
    });
    const accountType = payload.context.type;
    let permissions = new PermissionsModel(mappedPermissions);
    //set permissions only once (useful for reload page)
    permissions = {
      ...permissions,
      ...BASE_PERMISSIONS[accountType],
    };
    state.permissions = permissions;
    localStorage.setObjectToItem('permissions', permissions);
  },
  clearPermissions(state) {
    state.permissions = {};
    localStorage.removeItem('permissions');
  },
  clearFlags(state) {
    state.isOrganization = false;
    localStorage.removeItem('isOrganization');
  },
};

const actions = {
  clearAllTokens: ({ commit }) => {
    commit('clearToken', 'tempToken');
    commit('clearToken', 'accessToken');
    commit('clearToken', 'refreshToken');
  },
  snsAuthRequest: ({ commit }, data) => {
    return api.auth.snsSignIn(data).then(response => {
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires * 1000,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires * 1000,
      });
      commit('setStatus', 'authorized');
      commit('setPermissions', response.data);
    });
  },
  authRequest: ({ commit }, user) => {
    return api.auth.signIn(user).then(response => {
      // commit('authSuccess', {data: response.data, from: 'signin'});
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires * 1000,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires * 1000,
      });
      commit('setStatus', 'authorized');
      // commit('clearToken', 'tempToken');
      commit('setPermissions', response.data);
      return response.data;
    });
  },
  getCredentials(state, value) {
    return api.auth
      .getCredentials(value)()
      .then(({ status }) => status === 200);
  },
  setCredentials(
    {
      getters: { accessToken },
    },
    { source, password }
  ) {
    const data = {
      source,
      password,
    };
    return api.auth.setCredentials(accessToken, data).then(res => res.data);
  },
  getIdentity() {
    return api.auth.getIdentity().then(({ data }) => data);
  },

  createUser: ({ getters, commit, dispatch }, formData) => {
    const {
      email,
      phone,
      country,
      login,
      password,
      captcha_response,
    } = formData;

    commit('userCreateRequest');
    commit('saveRegData', {
      email: email,
      country: country,
      login: login,
    });
    return api.auth
      .signUp({ email, phone, login, password })
      .then(({ data }) => commit('codeRequest', data))
      .then(() => {
        return getters.tempToken
          ? dispatch('sendCode', { email, phone, captcha_response })
          : Promise.reject(new Error('Auth Error: No temporary token!'));
      })
      .then(({ data }) => data);
  },

  sendCode(
    {
      getters: { tempToken },
    },
    { email, phone, captcha_response, providedToken }
  ) {
    const isIdentityByEmail = !!email;
    const identity = isIdentityByEmail
      ? email
      : `${phone.indexOf('+') === 0 ? '' : '+'}${phone}`;

    return api.auth.sendCode(providedToken ? providedToken : tempToken, {
      identity,
      type: isIdentityByEmail ? 'email' : 'phone',
      captcha_response,
    });
  },

  verifyUser: (
    { getters: { tempToken }, commit },
    { code, email, phone, from, providedTemp }
  ) => {
    commit('codeVerify');
    const isIdentityByEmail = !!email;
    const identity = isIdentityByEmail
      ? email
      : `${phone.indexOf('+') === 0 ? '' : '+'}${phone}`;
    const isAuth = from === 'verify-phone';

    return api.auth
      .verifyCode(providedTemp ? providedTemp : tempToken, {
        code,
        identity,
        type: isIdentityByEmail ? 'email' : 'phone',
      })
      .then(({ data }) => {
        if (isAuth) {
          return data;
        }
        commit('setToken', {
          type: 'accessToken',
          token: data.access_token,
          expires: data.access_token_expires * 1000,
        });
        commit('setToken', {
          type: 'refreshToken',
          token: data.refresh_token,
          expires: data.refresh_token_expires * 1000,
        });

        if (from === 'reset') {
          return data;
        }

        commit('setStatus', 'authorized');
        // commit('clearToken', 'tempToken');
        commit('setPermissions', data);
        return data;
      });
  },

  deleteProfileRequest: ({ getters: { accessToken }, commit }) => {
    return api.auth.deleteProfileRequest(accessToken).then(({ data }) => {
      commit('codeRequest', data);
    });
  },

  deleteProfileSubmit: ({ getters: { accessToken } }) => {
    return api.auth.deleteProfileSubmit(accessToken).then(({ data }) => {
      return data;
    });
  },

  createProfile: ({ getters: { accessToken, templateRegData } }) => {
    var data = {
      country: templateRegData._countryObject.value,
      country_id: templateRegData.country,
      display_name: templateRegData.login,
      incomplete: false,
      social: {},
    };
    return api.profiles.putMyDetails(accessToken, data);
  },
  requestPass: ({ getters, commit, dispatch }, { login, captcha_response }) => {
    return new Promise((resolve, reject) => {
      commit('requestPass');
      api.auth
        .resetPass({ login })
        .then(
          ({ data }) => {
            commit('codeRequest', data);
            return Promise.resolve();
          },
          error => Promise.reject(error)
        )
        .then(
          () => {
            return getters.tempToken
              ? dispatch('sendCode', {
                  [login.includes('@') ? 'email' : 'phone']: login,
                  captcha_response,
                })
              : Promise.reject(new Error('No temporary token!'));
          },
          error => Promise.reject(error)
        )
        .then(
          response => resolve(response.data),
          error => Promise.reject(error)
        )
        .catch(error => {
          commit('authError', error);
          reject(error);
        });
    });
  },

  submitPass: ({ getters, commit }, newPassword) => {
    return new Promise((resolve, reject) => {
      commit('submitPass');
      api.auth
        .submitPass(getters.accessToken, newPassword)
        .then(resolve, reject)
        .catch(error => {
          commit('authError', error);
        });
    });
  },
  refreshAccessToken: ({ getters, commit, state }) => {
    if (state.isRefreshProcessing) {
      return Promise.resolve();
    }
    commit('setRefreshProcessing', true);
    return api.auth.refreshToken(getters.refreshToken).then(response => {
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires * 1000,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires * 1000,
      });
      commit('setStatus', 'token pare refreshed');
      commit('setRefreshProcessing', false);
      commit('changeRefreshStopover');
    });
  },
  clearExpiredTokens({ getters }) {
    if (!getters.isTokenValid('tempToken')) {
      // this.$store.commit('clearToken', 'tempToken');
    }
    if (!getters.isTokenValid('accessToken')) {
      this.$store.commit('clearToken', 'accessToken');
    }
    if (!getters.isTokenValid('refreshToken')) {
      this.$store.commit('clearToken', 'refreshToken');
    }
  },
  switchAccount(
    {
      getters: { accessToken },
      commit,
    },
    { accountId, accountType }
  ) {
    return api.auth
      .switchAccount(accessToken, { id: accountId, type: accountType })
      .then(({ data }) => {
        commit('authSuccess', data);
        commit('setPermissions', data);
        commit('my/clans/deleteClans');
        commit('current/clans/deleteClans');
        return data;
      });
  },
  ssoSwitch(
    {
      getters: { accessToken },
    },
    { accountId, accountType, stateId, ext }
  ) {
    console.info(`authtokens.js ${accountId}, ${accountType}, ${stateId}`);
    return api.auth
      .switchAccount(accessToken, {
        id: accountId,
        type: accountType,
        state: stateId || '',
        ext: ext,
      })
      .then(({ data }) => {
        return data;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
