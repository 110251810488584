import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const CONVERSATIONS = 'conversations';
const MESSAGES = 'messages';

const createConversation = login =>
  authorizedGetRequest(`${CONVERSATIONS}/${login}`, {
    preventCancelRequest: true,
  });

const createGroupConversation = (
  accessToken,
  {
    title,
    subtitle = '',
    avatar = '',
    conversation_type = 'group_chat',
    participants,
  }
) =>
  authorizedPostRequest(CONVERSATIONS)(accessToken, {
    title,
    subtitle,
    avatar,
    conversation_type,
    participants,
  });

const getConversations = (
  accessToken,
  page = null,
  page_size = 20,
  correlation_id,
  correlation_type,
  includeParticipants,
  hasMessages,
  Filter,
  focus,
  focusDate
) =>
  authorizedGetRequest(CONVERSATIONS, {
    preventCancelRequest: true,
  })(accessToken, {
    params: {
      page,
      page_size,
      correlation_id,
      correlation_type,
      includeParticipants,
      hasMessages,
      Filter,
      focus,
      focusDate,
    },
  });

const getConversation = id => authorizedGetRequest(`${CONVERSATIONS}/${id}`);
const blockApiConversation = id =>
  authorizedPostRequest(`${CONVERSATIONS}/${id}/block`);

const getMessages = (
  accessToken,
  id,
  pageToken = null,
  focus = null,
  focusDate = null
) =>
  authorizedGetRequest(`${CONVERSATIONS}/${id}/${MESSAGES}`, {
    preventCancelRequest: true,
  })(accessToken, { params: { page: pageToken, focus, focusDate } });

const postMessage = (accessToken, id, text, attachments = null) =>
  authorizedPostRequest(`${CONVERSATIONS}/${id}/${MESSAGES}`)(accessToken, {
    text,
    attachments,
  });

const deleteMessage = (accessToken, chat_id, msg_id) =>
  authorizedDeleteRequest(`${CONVERSATIONS}/${chat_id}/${MESSAGES}/${msg_id}`)(
    accessToken
  );

const conversationAsRead = (accessToken, id) =>
  authorizedPutRequest(`${CONVERSATIONS}/${id}/${MESSAGES}`)(accessToken, {
    read: true,
  });

const messageAsRead = (accessToken, chat_id) =>
  authorizedPutRequest(`${CONVERSATIONS}/${chat_id}/${MESSAGES}`)(accessToken);

// const messageAsRead = (accessToken, chat_id, msg_id) =>
// authorizedPutRequest(`${CONVERSATIONS}/${chat_id}/${MESSAGES}/${msg_id}`)(
//   accessToken,
//   { read: true }
// );
// this was before fix

const getTranslateMsg = (accessToken, conversationId, lang, message_ids) =>
  authorizedPostRequest(
    `${CONVERSATIONS}/${conversationId}/${MESSAGES}/${lang}`
  )(accessToken, { message_ids });

const updateConversation = (accessToken, id, data) =>
  authorizedPutRequest(`${CONVERSATIONS}/${id}`)(accessToken, data);

const deleteConversation = (accessToken, id) =>
  authorizedDeleteRequest(`${CONVERSATIONS}/${id}`)(accessToken);

const addParticipantToConversation = (conversationId, participantId) =>
  authorizedPostRequest(
    `${CONVERSATIONS}/${conversationId}/participant/${participantId}`
  );

const deleteParticipantFromConversation = (conversationId, participantId) =>
  authorizedDeleteRequest(
    `${CONVERSATIONS}/${conversationId}/participant/${participantId}`
  );

const createTournamentConversationBySignature = (accessToken, params) =>
  authorizedPostRequest(`${CONVERSATIONS}/signature`)(accessToken, null, {
    params,
  });

const getMessagesCounters = memberId =>
  authorizedGetRequest(`${CONVERSATIONS}/participant/${memberId}/unread`);

export default {
  createConversation,
  createGroupConversation,
  getConversations,
  getConversation,
  blockApiConversation,
  getMessages,
  postMessage,
  deleteMessage,
  conversationAsRead,
  messageAsRead,
  getTranslateMsg,
  updateConversation,
  deleteConversation,
  addParticipantToConversation,
  deleteParticipantFromConversation,
  createTournamentConversationBySignature,
  getMessagesCounters,
};
