<template>
  <div
    ref="tooltip"
    class="position-relative tooltip-wrapper"
    :class="{ 'cursor-help': !isEmpty }"
    v-on="{
      ...(isEmpty && !forceOpen
        ? null
        : openAfterClick
        ? {
            click: toggle,
          }
        : {
            mouseout: hidePopover,
            mouseover: open,
          }),
    }"
  >
    <Icon
      v-if="showIcon"
      v-bind="{
        name: 'info',
        class: 'align-top',
        fill: $scss.disabledColor,
        width: 9,
        ...iconProps,
      }"
    />
    <slot />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { prop } from '@/utils/factories';
import Icon from '@/components/atoms/Icon.vue';

export default {
  name: 'InfoTooltip',
  components: { Icon },
  props: {
    forceOpen: prop(Boolean),
    text: prop(String),
    title: prop(String),
    showIcon: prop(Boolean, true),
    iconProps: prop(null),
    float: prop(Array, () => []),
    openAfterClick: prop(Boolean),
    popoverClass: prop(String),
  },
  data() {
    return {
      popoverIsVisible: false,
      toolTipPosition: null,
    };
  },
  computed: {
    ...mapGetters('popover', ['isVisible']),
    isEmpty() {
      return !this.text;
    },
  },
  beforeDestroy() {
    if (this.isVisible) {
      this.hidePopover();
    }
  },
  methods: {
    ...mapActions({
      openPopover: 'popover/open',
      hidePopover: 'popover/hide',
    }),
    open(event) {
      this.openPopover({
        event,
        text: this.text,
        title: this.title,
        slot: this.$slots.content,
        settings: {
          targetSelector: 'svg',
          popoverClass: `p-2 tooltip-popover ${this.popoverClass}`,
          fontSize: 0.875,
          float: this.float.length ? this.float : null,

          titleBlock: {
            size: 0.75,
            tag: 'div',
            class: 'text-truncate',
          },
          textBlock: {
            size: 0.875,
            tag: 'div',
            class: 'tooltip-popover-body',
          },
        },
      });
    },
    toggle(event) {
      if (this.isVisible) {
        return this.hidePopover();
      }
      this.open(event);
    },
  },
};
</script>
<style lang="scss">
.tooltip-wrapper {
  font-size: 0.75rem;
  line-height: 1;
}
.tooltip-popover {
  max-width: 20rem;
  border-color: $ph-secondary-text-raw !important;
  &:after {
    border-color: $ph-secondary-text-raw !important;
  }
  &-body {
    word-wrap: break-word;
  }
  hr {
    border-color: currentColor;
    opacity: 0.8;
    margin: 0.25rem 0;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Molecules"
  name="InfoTooltip"
  knobs="{
    text: {
      default: text('text', 'text')
    },
    showIcon: {
      default: boolean('readOnly', true)
    },
  }"
>
  <InfoTooltip v-bind="{text, showIcon}" style="width: 20px;"/>
</story>
