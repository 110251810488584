<template>
  <Popover
    class="dropbox-menu"
    v-bind="{
      position: positionReplaced,
      float,
      regard,
      targetSelector,
      closest,
      isFixed,
      blockIsFixed,
      translate,
      ignoreClicksOn,
      beforeCloseCallback,
    }"
    :style="stylePopover"
    @toggle="$emit('toggle', $event)"
  >
    <slot name="header"></slot>
    <div v-if="items && items.length" class="list-wrapper custom-scrollbar">
      <div class="h-text-left list-custom" :style="styleContent">
        <ul class="list-unstyled mb-0 px-0">
          <li
            v-for="(item, index) in items"
            :key="`${popoverMenuList}_${index}`"
            v-on="$handler"
          >
            <slot v-bind="{ item, index }"></slot>
          </li>
        </ul>
      </div>
    </div>
    <slot name="footer"></slot>
  </Popover>
</template>

<script>
import { uniqueId } from '@/utils/lodashUtils';
import { generateStyle } from '@/utils/getProps';
import Popover from './index';
import { prop } from '@/utils/factories';

export default {
  name: 'PopoverMenu',
  components: {
    Popover,
  },
  props: {
    position: {
      type: [Object, DOMRect, Node],
      default: null,
    },
    targetSelector: {
      type: String,
      default: '',
    },
    closest: {
      type: Node, // closest element position must be 'relative'
      default: null,
    },
    float: {
      type: Array,
      default: () => [], // ex: ['right', 'bottom'], ['top'], ['left']
    },
    regard: {
      type: String,
      default: 'top', // top or bottom
    },
    items: {
      type: Array, // each item: {}
      default: null,
    },
    autoClose: prop(Boolean),
    isFixed: prop(Boolean),
    blockIsFixed: prop(Boolean),
    width: {
      type: [Number, String, Array],
      default: null,
    },
    height: {
      type: [Number, String, Array],
      default: null,
    },
    translate: {
      type: [String, Number],
      default: 0,
    },
    ignoreClicksOn: {
      type: [String, Array],
      default: '',
    },
    beforeCloseCallback: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      popoverMenuList: uniqueId('popoverMenu_'),
      positionReplaced: this.position,
    };
  },
  computed: {
    $handler() {
      if (this.autoClose) {
        const $this = this;

        return {
          click() {
            $this.positionReplaced = null;
          },
        };
      }

      return null;
    },
    stylePopover() {
      return this.width ? generateStyle('width', this.width) : null;
    },
    styleContent() {
      return this.height ? generateStyle('height', this.height) : null;
    },
  },
  watch: {
    position(value) {
      this.positionReplaced = value;
    },
  },
};
</script>

<style lang="scss">
.dropbox-menu {
  & > div {
    position: relative;
    max-height: 200px;
    min-height: 10px;
    width: 100%;
  }
  @include popover-menu-items;
}

.list-wrapper {
  overflow-y: auto;
}
.list-custom {
  overflow-y: hidden;
}
</style>
