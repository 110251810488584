var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid p-0 font-size-xs layout-wrapper main-layout-wrapper",class:[{ 'navbar-is-open': _vm.navbarIsOpen }, _vm.localeDirection, _vm.$route.name]},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"d-flex flex-grow-1 layout-content-wrapper"},[_c('div',{staticClass:"main-nav-wrapper position-relative"},[_c('div',{staticClass:"sticky-top"},[_c('div',{staticClass:"logo-wrapper mb-2 cursor-pointer"},[_c('router-link',{staticClass:"h-pl-4 d-flex",attrs:{"to":"/","tag":"div"}},[_c('HeraLogo',{attrs:{"type":_vm.navbarIsOpen ? 'full' : 'short',"animated":""}})],1)],1),_c('HeraSearchInput',{attrs:{"id":"sidebar-search","disabled":_vm.$router.currentRoute.name === 'search',"class-input":"main-nav-search mb-4"},on:{"enter":_vm.searchRedirect},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(_vm.myAccountFetched)?_c('LeftSidebar',{key:_vm.appId,attrs:{"collapsed":!_vm.navbarIsOpen}}):_vm._e(),_c('div',{staticClass:"toggle-sidenav-icon-wrapper d-flex align-items-center",on:{"click":_vm.toggleNav}},[_c('Icon',{staticClass:"toggle-sidenav-icon",attrs:{"rotate":_vm.toggleRotateAngle,"name":"arrow-s","width":"10"}}),(_vm.navbarIsOpen)?_c('div',{staticClass:"toggle-sidenav-label text-nowrap font-size-xs secondary-font h-ml-4"},[_vm._v("\n              "+_vm._s(_vm.$t('_web_toggle_collapse_panel', 'Collapse panel'))+"\n            ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"flex-grow-1 main-content-wrapper position-relative"},[_c('div',{staticClass:"main-content d-flex flex-column flex-grow-1 h-100"},[(_vm.myAccountFetched)?_c('HeraNavbar',{key:_vm.appId,class:{
              'highlights-navbar-style': _vm.$route.path.includes('posts'),
            }}):_vm._e(),_c('div',{staticClass:"flex-grow-1 content"},[(_vm.myAccountFetched)?_vm._t("default"):_vm._e()],2),(
              (_vm.profileOpen && _vm.isAuthorized) ||
                (_vm.notificationOpen && _vm.isAuthorized)
            )?_c('div',{staticClass:"overlay-profile-open",on:{"click":_vm.setProfileOpen}}):_vm._e(),(_vm.guestOpen && !_vm.isAuthorized)?_c('div',{staticClass:"overlay-profile-open",on:{"click":_vm.setGuestOpen}}):_vm._e(),(_vm.isFooterVisible)?_c('div',{staticClass:"footer-wrapper",class:{
              'highlights-navbar-style': _vm.$route.path.includes('posts'),
            }},[_c('div',{staticClass:"row"},[_c('portal-target',{staticClass:"d-flex w-100 justify-content-center",attrs:{"name":"footer-portal"}})],1),_c('div',{staticClass:"outer-wrap-side-profile"},[(_vm.isAuthorized)?_c('div',{ref:"notifications",staticClass:"side-profile-wrap custom-scrollbar",class:_vm.notificationOpen ? 'show-side-profile' : ''},[(_vm.isAuthorized)?_c('notificationSidebar'):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"outer-wrap-side-profile"},[(_vm.isAuthorized)?_c('div',{ref:"profile",staticClass:"side-profile-wrap custom-scrollbar",class:_vm.profileOpen && _vm.account.country !== null
                    ? 'show-side-profile'
                    : ''},[(_vm.isAuthorized)?_c('userProfileSidebar'):_vm._e()],1):_vm._e(),(!_vm.isAuthorized)?_c('div',{ref:"profile",staticClass:"side-profile-wrap custom-scrollbar",class:_vm.guestOpen ? 'show-side-profile' : ''},[(!_vm.isAuthorized)?_c('guestSidebar'):_vm._e()],1):_vm._e()]),(_vm.$route.path !== '/feed')?_c('HeraDesktopFooter',{staticClass:"desktop-footer",attrs:{"show-region-switcher":true}}):_vm._e(),(_vm.isAuthorized && !_vm.$route.path.includes('profile-setup'))?_c('HeraMobileFooter',{staticClass:"mobile-footer",class:{
                'highlights-navbar-style': _vm.$route.path.includes('posts'),
              }}):_vm._e()],1):_vm._e(),(!_vm.isAuthorized && _vm.contentLoaded)?_c('div',{staticClass:"cta-message"},[_c('div',{staticClass:"cta-message-text"},[_c('h3',[_vm._v("\n                "+_vm._s(_vm.$t('_web_join', 'Join PLAYHERA'))+"\n              ")]),_c('p',[_vm._v("\n                "+_vm._s(_vm.$t(
                    '_web_cta_message_1',
                    'Play your favorite games anywhere and at any time.'
                  ))+"\n              ")])]),_c('div',{staticClass:"cta-message-button"},[_c('router-link',{attrs:{"to":{ path: ("/signin?redirectTo=" + (_vm.$route.fullPath)) }}},[_c('button',{staticClass:"cta-message-button-login"},[_vm._v("\n                  "+_vm._s(_vm.$t('_web_log_in', 'Log In'))+"\n                ")])]),_c('router-link',{attrs:{"to":{ name: 'signup' }}},[_c('button',{staticClass:"cta-message-button-create"},[_vm._v("\n                  "+_vm._s(_vm.$t('_web_create_account_btn', 'Create Account'))+"\n                ")])])],1)]):_vm._e()],1)])])]),(_vm.isAuthorized && _vm.myAccountFetched)?[_c('Hub',{key:_vm.hubKey}),_c('PopupNotifications'),(_vm.sliderChatIsVisible)?_c('SliderChat',{key:("slider-chat_" + _vm.appId)}):_vm._e(),(_vm.popoverChatIsVisible)?_c('PopupChat',{key:("popup-chat_" + _vm.appId)}):_vm._e(),_c('transition',{attrs:{"name":"translate-y"}},[(_vm.isHubReloadRequired)?_c('HubReloader'):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }