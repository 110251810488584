<template>
  <div>
    <HeraFormSwitch
      v-model="autofinishBattle"
      name="autostartBattle"
      :label="$$t('Autocomplete match:', 'autocomplete-match-label')"
      :disabled="isStageFieldDisabled('autostartBattle') || disabled"
      :tooltip="
        $$t(
          'System automatically completes a match when match duration is over. All matches without provided results will be cancelled',
          'match-autocomplete-tooltip'
        )
      "
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'StageAutocompleteMatchSetting',
  components: {
    HeraFormSwitch,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage_form'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    autofinishBattle: {
      get() {
        return this.getBlockConfigurationByKey('autostart_battle', false);
      },
      set(newVal) {
        this.setBlockConfigurationByKey('autostart_battle', newVal);
      },
    },
  },
};
</script>

<style></style>
