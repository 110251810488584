<template>
  <div class="d-flex flex-column">
    <Title :text="title" class="mb-5" />
    <FullStageForm />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '@/components/atoms/Title';

import FullStageForm from '@/views/tournaments/management/singleTournament/stages/components/FullStageForm';

export default {
  name: 'EditStage',
  components: {
    Title,
    FullStageForm,
  },
  computed: {
    ...mapGetters(['currentTournamentStage', 'currentTournamentStages']),
    stageID() {
      return this.$route.params.stageID;
    },
    title() {
      const staticCurrentStageData = this.currentTournamentStages.find(
        s => s.id === this.currentTournamentStage.id
      );
      return this.$t(
        this.$route.meta.titleKey,
        this.$route.meta.fallbackLabel,
        { stage: this.$lodash.get(staticCurrentStageData, 'title', '') }
      );
    },
  },
};
</script>

<style lang="scss"></style>
