<template>
  <div class="prizes-container">
    <ListItem :subtitle="role" v-bind="{ tag }">
      <slot slot="before" name="action-before" />

      <!-- <LinkAccount
        slot="avatar"
        v-bind="$options.linkBinds"
        :verified="false"
        :type="$options.type"
        v-on="$options.eventHandler"
      >
      </LinkAccount> -->

      <LinkAccount
        slot="title"
        class="text-truncate link-uncolor account-link-name"
        :title="$options.name"
        v-bind="$options.linkBinds"
        :type="$options.type"
        :class="noname && 'show-no-name'"
        v-on="$options.eventHandler"
      >
        {{ noname ? null : $options.name }}
      </LinkAccount>
    </ListItem>
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import ListItem from './ListItem';
import LinkAccount from '@/components/links/Account';
export default {
  name: 'ListItemAccount',
  components: { LinkAccount, ListItem },
  props: {
    model: prop(Object, null),
    secondaryModel: prop(Object, null), // backend model
    type: prop(String),
    avatar: prop(String),
    name: prop(String),
    accountId: prop(String),
    role: prop(String),
    size: prop(Number, 36),
    approved: prop(Boolean),
    tag: prop(String, 'div'),
    target: prop(String),
    vanityId: prop(String),
    isDeleted: prop(Boolean),
    noname: prop(Boolean, false),
  },
  created() {
    const { model } = this;

    if (model) {
      Object.assign(this.$options, {
        type: model._type,
        avatar: model._avatar,
        verified: model._verified,
        accountId: model._id,
        name: model._name,
        vanityId: model.vanityId,
        deleted: model.deleted,
      });
    } else {
      const {
        type = '',
        logo = '',
        avatar = logo,
        approved,
        verified = approved || false,
        id,
        login: accountId = id,
        display_name,
        name = display_name || accountId,
        deleted = false,
        vanity_id,
      } = this.secondaryModel || {};

      Object.assign(this.$options, {
        type: this.type || type,
        avatar: this.avatar || avatar,
        verified: this.approved || verified,
        accountId: this.accountId || accountId,
        vanityId: this.vanityId || vanity_id,
        name: this.name || name || accountId,
        deleted: this.isDeleted || deleted,
      });
    }

    this.$options.linkBinds = {
      id: this.$options.accountId,
      type: this.$options.type,
      verified: this.$options.verified,
      vanityId: this.$options.vanityId,
      target: this.target || null,
    };
  },
};
</script>

<style lang="scss" scoped>
.show-no-name {
  visibility: hidden;
}
</style>
