<template>
  <label class="custom-checkbox">
    <input
      type="checkbox"
      class="custom-checkbox__input"
      v-bind="{
        ...$attrs,
        checked,
        class: [{ indeterminate }, color],
        disabled,
      }"
      v-on="{
        ...$listeners,
        change: $event => $emit('change', $event.target.checked),
      }"
    />
    <span class="custom-checkbox__icon"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.custom-checkbox {
  $size: 1.125rem;
  margin: 0;
  display: inline-block;
  position: relative;
  width: $size;
  min-width: $size; // fix for flexbox
  height: $size;
  cursor: pointer;

  &__input {
    position: absolute;
    transform: scale(0);
    &:disabled {
      ~ .custom-checkbox__icon {
        background-color: $ph-secondary-text;
        border-color: $ph-secondary-text;
        opacity: 0.5;
      }
    }
    &:checked,
    &.indeterminate {
      ~ .custom-checkbox__icon {
        border-color: $ph-accent;
        background-color: $ph-accent;
      }
    }
    &:checked {
      ~ .custom-checkbox__icon::after {
        background-image: url(~@/assets/images/checkbox-checked.svg);
      }
    }
    &.indeterminate {
      ~ .custom-checkbox__icon::after {
        background-image: url(~@/assets/images/checkbox-indeterminate.svg);
      }
    }
    &.green {
      ~ .custom-checkbox__icon {
        border-color: $ph-green;
      }
      &:checked {
        ~ .custom-checkbox__icon {
          background-color: $ph-green;
        }
      }
    }
    &.orange {
      ~ .custom-checkbox__icon {
        border-color: $ph-orange;
      }
      &:checked {
        ~ .custom-checkbox__icon {
          background-color: $ph-orange;
        }
      }
    }
    &.blue {
      ~ .custom-checkbox__icon {
        border-color: $ph-blue;
      }
      &:checked {
        ~ .custom-checkbox__icon {
          background-color: $ph-blue;
        }
      }
    }
  }

  &__icon {
    display: block;
    position: absolute;
    width: $size;
    height: $size;
    border: 1px solid $ph-secondary-text;
    border-radius: $ph-small-radius;
    transition: all 0.3s;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transform: translateX(-1px) translateY(-1px);
      top: 0;
      width: $size;
      height: $size;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="Checkbox"
  knobs="{
    disabled: {
      default: boolean('disabled', false)
    },
    indeterminate: {
      default: boolean('indeterminate', false)
    },
    color: {
      default: select('color', ['default', 'green', 'orange', 'blue'], 'default')
    }
  }"
>
  <Checkbox
    v-bind="{ disabled, indeterminate, color}"
    @input="val => action('input event data', val)"
    @change="val => action('change event data', val)"/>
</story>
