var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 flex-column justify-content-center"},[_c('div',{staticClass:"verify-code-container"},[(_vm.regEmail)?_c('div',[_c('h2',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$$t('Verify your email'))+"\n      ")])]):(_vm.regPhone)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("\n        "+_vm._s(_vm.$$t(
            'Please, enter confirmation code here. We have sent SMS to your phone:',
            'footer-text-phone'
          ))+"\n      ")]),_c('p',{staticClass:"text-light font-weight-bold font-size-pre-lg"},[_vm._v("\n        +"+_vm._s(_vm.regPhone)+"\n      ")])]):_vm._e(),(_vm.resendCodeBind)?_c('ResendCode',_vm._b({attrs:{"action":_vm.sendCode,"action-data":_vm.templateRegData},on:{"resended":_vm.refreshCode}},'ResendCode',_vm.resendCodeBind,false)):_vm._e(),_c('InputVerify',{staticClass:" ",attrs:{"tag":"form","auto-send":"","count":_vm.$options.verifyCodeCount},on:{"onSubmit":_vm.formSubmit,"error":_vm.formError},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"text-danger text-center"},[_vm._v("\n      "+_vm._s(_vm.errorText ||
          (_vm.errorRequest &&
            _vm.$$t(
              'Invalid code. Please try again.',
              '_web_server_errors_invalid_verification_code'
            )))+"\n    ")])],1),_c('ModalBackdrop',{staticClass:"verify-code-loader",model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('Loader')],1),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }