<template>
  <form class="stage-edit-form">
    <BlockSettingsForm class="mb-5" />
    <SectionMatchSetup />
    <CollapsiblePanel
      :label="
        $t(
          '_web_tournament_stage_additional-options-title',
          'Additional options'
        )
      "
      class="mt-5 stage-edit-form__additional"
    >
      <div class="row my-3 pb-3">
        <StageSettingTitle
          class="col-auto"
          :disabled="safeMode || isAllDisabled"
        />
      </div>
      <div class="row my-3 pb-3">
        <StageSettingAvailabilityType
          class="col-auto"
          :disabled="safeMode || isAllDisabled"
        />
      </div>
      <StageSettingSchedule :disabled="safeMode || isAllDisabled" />
      <StageSettingCheckIn :disabled="safeMode || isAllDisabled" />
    </CollapsiblePanel>
  </form>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { isEmpty, get } from '@/utils/lodashUtils';
import { translate } from '@/mixins/translate';
import { ONE_DAY_IN_SECONDS, ONE_HOUR } from '@/constants/time';
import dateMethods from '@/mixins/dateMethods';
import SectionMatchSetup from '@/views/tournaments/management/singleTournament/stages/components/SectionMatchSetup';
import CollapsiblePanel from '@/components/atoms/CollapsiblePanel';

import BlockSettingsForm from '@/views/tournaments/management/singleTournament/stages/components/SectionBlockTypeSetup';
import StageSettingCheckIn from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageCheckIn.vue';
import StageSettingTitle from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageTitle.vue';
import StageSettingAvailabilityType from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageAvailabilityType.vue';
import StageSettingSchedule from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageSchedule.vue';

export default {
  components: {
    CollapsiblePanel,
    // Panel,
    BlockSettingsForm,
    SectionMatchSetup,
    StageSettingCheckIn,
    StageSettingTitle,
    StageSettingAvailabilityType,
    StageSettingSchedule,
  },
  inject: ['$validator'],
  mixins: [...dateMethods, translate('_web_tournament_stage_form')],
  computed: {
    ...mapGetters([
      'currentTournament',
      'currentTournamentStage',
      'currentTournamentStages',
      'isSingleStage',
      'isSingleBlock',
      'isStageFieldDisabled',
    ]),
    stageID() {
      return this.$route.params.stageID || this.currentTournamentStage.id;
    },
    isAllDisabled() {
      return (
        this.$route.name === 'editSingleStage' &&
        !(this.isSingleStage && this.isSingleBlock)
      );
    },
    safeMode() {
      return Boolean(this.$route.meta.safeMode);
    },
  },
  // watch: {
  //   currentTournamentStage() {
  //     this.initializeForm();
  //   },
  // },
  created() {
    this.initializeForm();
    if (this.safeMode) {
      this.disableConfirmLeave();
    }
  },
  beforeRouteEnter(to, from, next) {
    to.params.blockID = null;
    next();
  },
  methods: {
    ...mapMutations([
      'setCurrentTournamentStage',
      'updateCurrentTournamentStage',
      'createNewTournamentStage',
    ]),
    initializeForm() {
      this.errors.clear();
      if (this.stageID) {
        if (
          this.currentTournamentStage &&
          this.currentTournamentStage.id !== this.stageID
        ) {
          this.setCurrentTournamentStage(this.stageID);
        }
        // this.setCheckInDefaults();
        // this.initializeDates();
      } else {
        if (this.$route.name === 'addStage') {
          this.createNewTournamentStage({
            ...this.getDefaultTitle(this.currentTournamentStages.length + 1),
            ...this.getDefaultDates(),
            ...this.getCheckInDefaults(),
          });
          // this.setDefaultDates();
        }
      }
    },
    getDefaultTitle(index = 1) {
      return {
        title: this.$t(
          '_web_tournament_stage_editor_default_title',
          'Stage {index}',
          { index }
        ),
      };
    },
    getDefaultDates() {
      const stagesCount = this.currentTournamentStages.length;
      // last stage is recently created one, but we need dates from previous so
      // stagesCount - 2 -> index of last stage before creating a new one
      const lastStageDateEnd =
        stagesCount > 1
          ? Math.max(
              this.currentTournamentStages[stagesCount - 2].date_end,
              this.currentTournamentStages[stagesCount - 2].date_start +
                ONE_DAY_IN_SECONDS,
              this.getNowSeconds()
            )
          : this.getNowSeconds();
      return {
        date_start: lastStageDateEnd + ONE_DAY_IN_SECONDS,
        date_end: lastStageDateEnd + 2 * ONE_DAY_IN_SECONDS,
      };

      // this.updateCurrentTournamentStage({
      //   date_start: lastStageDateEnd + ONE_DAY_IN_SECONDS,
      // });
      // this.updateCurrentTournamentStage({
      //   date_end: lastStageDateEnd + 2 * ONE_DAY_IN_SECONDS,
      // });
    },
    setCheckInDefaults() {
      if (
        !isEmpty(this.currentTournamentStage) &&
        !this.currentTournamentStage.check_in_start_date
      ) {
        this.$nextTick(() => {
          this.updateCurrentTournamentStage(this.getCheckInDefaults());
        });
      }
    },
    getCheckInDefaults() {
      const { date_start } = this.getDefaultDates();
      return {
        check_in_start_date: date_start - ONE_HOUR,
      };
    },
    // used from parent via $refs
    validateForm() {
      if (
        isEmpty(get(this.currentTournamentStage, 'block_configuration.type'))
      ) {
        this.errors.add({
          field: 'stage-type',
          msg: this.$t(
            '_web_invalid-field-validation',
            'The {field} field is required.',
            {
              field: this.$t(
                '_web_tournament_stage-type-section-title',
                'Stage type'
              ),
            }
          ),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.stage-edit-form {
  &__additional {
    margin-bottom: 85px;
  }

  .date-view input {
    padding: 0;
    border: 0;
    color: $ph-primary-text;
  }
  .date-time-input {
    .date-time-wrapper > div:not(:last-child) {
      @include auto-rtl(
        (
          margin-right: 1.25rem,
        )
      );
    }
  }
  .date-time-wrapper {
    &__date-input {
      width: 58%;
      max-width: 130px;
      .date-picker.mx-datepicker {
        width: 100%;
      }
    }
    &__time-input {
      width: 40%;
      max-width: 160px;
    }
  }
}
</style>
