import { render, staticRenderFns } from "./HighlightsVideoPreview.vue?vue&type=template&id=80d43f42&"
import script from "./HighlightsVideoPreview.vue?vue&type=script&lang=js&"
export * from "./HighlightsVideoPreview.vue?vue&type=script&lang=js&"
import style0 from "./HighlightsVideoPreview.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./HighlightsVideoPreview.vue?vue&type=custom&index=0&blockType=story&options=%7B%0D%0A%20%20%20%20%20%20panelPosition%3A%20'right'%0D%0A%20%20%20%20%7D&group=Common%7CAtoms&name=VideoPreview&notes=%23%23%20this%20component%20has%20a%20slot%20which%20could%20hold%20any%20other%20component%20inside%20it&knobs=%7B%0D%0A%20%20%20%20%20%20src%3A%20%7B%0D%0A%20%20%20%20%20%20%20%20default%3A%20text('src'%2C%20'https%3A%2F%2Finteractive-examples.mdn.mozilla.net%2Fmedia%2Fexamples%2Fflower.mp4')%0D%0A%20%20%20%20%20%20%7D%2C%0D%0A%20%20%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports