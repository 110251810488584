<template>
  <Panel
    :variant="['transparent']"
    class="action-item-wrapper overflow-hidden px-4 pb-4 pt-0"
    :class="{
      disabled: action.disabled || action.isPast,
      automated: action.automated,
      'cursor-pointer': action.handler,
    }"
  >
    <div class="action h-100">
      <div
        class="action__label"
        :class="{ 'action__done-text': action.isPast }"
      >
        <Icon
          class="mr-4"
          width="18"
          :name="
            action.isPast
              ? 'check-mark-charped'
              : action.disabled
              ? 'time'
              : 'arrow-right-big'
          "
          :fill="action.disabled ? $scss.heraMuted : 'white'"
        />
        {{ action.label }}
      </div>

      <div
        class="d-flex align-items-center h-100 flex-grow-1"
        style="margin-left: 38px"
      >
        <div v-if="action.automated" class="action__auto">
          {{ $t('_web_auto', 'auto') }}
        </div>
        <div class="action__date">
          {{ action.date | localDate($times.DATETIMEWEEKDAY_MED) }}
        </div>
      </div>
      <!--      <div
        v-if="action.navigationHandler"
        class="action__addon h-100 d-flex align-items-center"
      >
        <div class="action__separator"></div>
        <div
          class="action__arrow h-ml-0 d-flex align-items-center"
          @click.prevent.stop="action.navigationHandler"
        >
          <Icon name="arrow-left" :rotate="180" />
        </div>
      </div>-->
    </div>
    <Btn
      v-if="!action.disabled"
      class="mt-3 ml-6 btn-primary"
      @click="action.handler"
    >
      {{ action.label }}
    </Btn>
  </Panel>
</template>

<script>
import { translate } from '@/mixins/translate';
import { propRequired } from '@/utils/factories';
import Panel from '@/components/atoms/Panel';
import Btn from '@/components/atoms/HeraFormBtn';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ActionItem',
  components: { Btn, Icon, Panel },
  mixins: [translate('_web_tournament_dashboard')],
  props: {
    action: propRequired(Object),
  },
};
</script>

<style lang="scss">
.action-item-wrapper {
  &:last-of-type {
    background: linear-gradient(180deg, rgba(11, 14, 19, 0) 0%, #0b0e13 100%);
  }

  .action {
    &__dot {
      width: 1.5rem;
      &:after {
        content: '';
        display: block;
        position: relative;
        margin: 0 auto;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 1rem;
        border: 1px solid $ph-secondary-text;
      }
      &--active {
        &:after {
          border: 1px solid $ph-accent;
          background: $ph-accent;
        }
      }
    }
    &__separator {
      height: 100%;
      width: 2px;
      background: $ph-bg;
    }
    &__label {
      font-family: $ph-font-family-secondary;
      font-weight: 600;
      font-size: $ph-body-font-size;
      margin-bottom: 8px;
    }
    &__btn {
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
    &__auto {
      font-family: $ph-font-family-secondary;
      color: $ph-green;
      text-transform: capitalize;
      margin-right: 12px;
    }
    &__date {
      font-family: $ph-font-family;
      font-size: $ph-small-text-font-size;
      color: var(--ph-secondary-text);
    }
    &__done-text {
      text-decoration-line: line-through;
    }
    /*    &__arrow {
      height: 100%;
      padding: math.div($ph-large-space, 2);
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }*/
  }
}
</style>
