import { authorizedPostRequest, authorizedPutRequest } from '@/api/requests';
import axios from 'axios';

const FILES_BASE = 'files/';
const PREUPLOAD = FILES_BASE + 'preupload';
const DIRECT_PREUPLOAD = FILES_BASE + 'direct_preupload';
const COMMIT_UPLOAD = FILES_BASE + 'commit_upload';
const ADMIN_PREUPLOAD = FILES_BASE + 'admin_preupload';

//not used on web from now, but needed for mobile
const filePreupload = authorizedPostRequest(PREUPLOAD);
//not used on web from now, but needed for mobile
const filePreuploadForAdmin = authorizedPostRequest(ADMIN_PREUPLOAD);
//not used on web from now, but needed for mobile
const fileUpload = (url, accessToken, blob, config = {}) =>
  authorizedPutRequest(url)(accessToken, blob, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'x-ms-blob-type': 'BlockBlob',
    },
    ...config,
  });

const fileDirectPreupload = authorizedPostRequest(DIRECT_PREUPLOAD);
const fileDirectUpload = (url, data, config = {}) =>
  axios.put(url, data, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'x-ms-blob-type': 'BlockBlob',
    },
    ...config,
  });
const fileCommitUpload = authorizedPostRequest(COMMIT_UPLOAD);

export default {
  filePreuploadForAdmin,
  filePreupload,
  fileUpload,
  fileDirectPreupload,
  fileDirectUpload,
  fileCommitUpload,
};
