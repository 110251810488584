import api from '@/api';

const generateFetches = (...data) => {
  return data.reduce((acc, [name, endpointSpace, endpointName = name]) => {
    acc[name] = ({ getters: { accessToken } }, { accountId, params }) => {
      return api[endpointSpace][endpointName](accountId)(accessToken, {
        params,
      }).then(({ data }) => data);
    };

    return acc;
  }, {});
};

const actions = {
  ...generateFetches(
    // profile && organization
    ['fetchFriends', 'requests'],
    ['fetchFollowers', 'requests'],
    ['fetchFollowings', 'requests'],
    ['fetchFriendRequests', 'requests'],

    // clan
    ['fetchClanMembers', 'clans', 'getClanMembers'],
    ['fetchClanFollows', 'clans', 'getClanFollows'],
    ['fetchClanInvites', 'clans', 'getClanInvites'],
    ['fetchClanRequests', 'clans', 'getClanRequests']
  ),
};

export default {
  actions,
};
