<template>
  <div
    v-if="attachments"
    class="sc-post-attach d-flex justify-content-between"
    :class="{ 'sc-post-attach_media-odd': mediaAttachments.length % 2 }"
  >
    <div
      v-for="(item, index) in mediaAttachments"
      :key="`media_${index}`"
      class="sc-post-attach__media"
    >
      <div
        v-if="item.type === 'image'"
        class="embed-responsive embed-responsive-16by9"
      >
        <ImagePreview
          class="embed-responsive-item"
          :src="item.image || item.displayUri"
          :media-group="mediaAttachments"
        />
      </div>
      <ImagePreview
        v-if="item.type === 'giphy'"
        class="embed-responsive-item sc-post-attach__gif"
        :src="makeGiphyUrl(item.id)"
        :media-group="mediaAttachments"
      />
      <VideoPreview
        v-else-if="item.type === 'video'"
        class="d-block embed-responsive-item"
        :src="item.video || item.displayUri"
        :media-group="mediaAttachments"
      />
    </div>
    <FileName
      v-for="(item, index) in applicationAttachments"
      :key="`application_${index}`"
      :file-name="item.fileOriginalName || item.file_original_name"
      :link="item.pdf || item.application"
      class="sc-post-attach__document mw-100"
    />
  </div>
</template>

<script>
import { makeGiphyUrl } from '@/utils';
import FileName from '@/components/common/FileName';
import VideoPreview from '@/components/atoms/VideoPreview';
import ImagePreview from '@/components/common/ImagePreview';

export default {
  components: { FileName, VideoPreview, ImagePreview },
  props: {
    attachments: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    mediaAttachments() {
      return this.attachments.filter(
        attach =>
          attach.type === 'image' ||
          attach.type === 'video' ||
          attach.type === 'giphy'
      );
    },
    applicationAttachments() {
      return this.attachments.filter(
        attach =>
          attach.type === 'application/pdf' || attach.type === 'application'
      );
    },
  },
  methods: {
    makeGiphyUrl,
  },
};
</script>

<style lang="scss">
.sc-post-attach {
  flex-flow: row wrap;
  max-width: 258px;
  padding: 0 0.75rem;
  margin: 0.5rem 0 0.25rem 0;

  &__media {
    margin-bottom: $ph-tiny-space;

    width: calc(50% - #{math.div($ph-tiny-space, 2)});

    border-radius: 4px;
    /*background-color: $ph-card;*/

    img {
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &_media-odd {
    .sc-post-attach__media:nth-child(1) {
      width: 100%;
    }
  }

  &__gif {
    width: 50%;
  }

  &__document {
    margin: 0.5rem 0 0.25rem 0;
  }
}
</style>
