var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product)?_c('div',{staticClass:"single-product-wrapper"},[(_vm.canceledPayment)?_c('div',{staticClass:"toast-error"},[_vm._v("\n    "+_vm._s(_vm.canceledPayment)+"\n    "),_c('button',{on:{"click":function () { return (_vm.canceledPayment = ''); }}},[_c('img',{attrs:{"src":require("@/assets/images/error-button-x.svg"),"alt":"close error"}})])]):_vm._e(),(_vm.paymentStatus === 0 && !_vm.toggleButton && this.$route.query.callback)?_c('div',{staticClass:"toast-sucess"},[_vm._v("\n    "+_vm._s(_vm.sucessPayment)+"\n    "),_c('button',{on:{"click":function () { return (_vm.toggleButton = true); }}},[_c('img',{attrs:{"src":require("@/assets/images/error-button-x.svg"),"alt":"close error"}})])]):_vm._e(),(_vm.paymentStatus === 1 && !_vm.toggleButton && this.$route.query.callback)?_c('div',{staticClass:"toast-error"},[_vm._v("\n    "+_vm._s(_vm.$t('_web_payment_failed', 'Payment failed'))+"\n    "),_c('button',{on:{"click":function () { return (_vm.toggleButton = true); }}},[_c('img',{attrs:{"src":require("@/assets/images/error-button-x.svg"),"alt":"close error"}})])]):_vm._e(),(_vm.paymentStatus === 2 && !_vm.toggleButton && this.$route.query.callback)?_c('div',{staticClass:"toast-processing"},[_vm._v("\n    "+_vm._s(_vm.$t(
        '_web_payment_processing',
        'Payment is being processed, please check your email'
      ))+"\n    "),_c('button',{on:{"click":function () { return (_vm.toggleButton = true); }}},[_c('img',{attrs:{"src":require("@/assets/images/error-button-x.svg"),"alt":"close error"}})])]):_vm._e(),_c('div',{staticClass:"single-product-inner"},[_c('div',{staticClass:"single-prod-content"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{attrs:{"to":"/shop/categories"}},[_c('img',{staticClass:"new-back-btn-shop",attrs:{"src":require("@/assets/images/back-btn-new.svg"),"alt":"back-icon"}})]),_c('h1',{staticClass:"product-title"},[_vm._v("\n          "+_vm._s(_vm.singleProduct ? _vm.singleProduct.name : _vm.product.name)+"\n        ")]),_c('h1',{staticClass:"category-title-mobile"},[_vm._v("\n          "+_vm._s(_vm.$t('_web_shop_all_categories', 'Categories'))+"\n        ")])],1),_c('div',{staticClass:"single-prod-content-inner"},[_c('div',{staticClass:"single-product"},[_c('div',{staticClass:"single-product-image"},[_c('img',{staticClass:"cover-image-single",attrs:{"src":_vm.singleProduct ? _vm.singleProduct.image_url : _vm.product.image_url,"alt":"Cover Image"}})]),_c('div',{staticClass:"product-info",staticStyle:{"margin-top":"4rem"}},[(_vm.singleProduct ? _vm.singleProduct.details : _vm.product.details)?_c('div',[_c('h1',[_vm._v("\n                "+_vm._s(_vm.$t('_web_shop_single_description', 'Product description'))+"\n              ")]),_c('p',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                  _vm.processText(
                    _vm.singleProduct ? _vm.singleProduct.details : _vm.product.details
                  )
                )}})]):_vm._e(),(
                _vm.singleProduct
                  ? _vm.singleProduct.instructions
                  : _vm.product.instructions
              )?_c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t('_web_shop_single_usage', 'How to use')))]),_c('p',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                  _vm.processText(
                    _vm.singleProduct
                      ? _vm.singleProduct.instructions
                      : _vm.product.instructions
                  )
                )}})]):_vm._e(),(_vm.singleProduct ? _vm.singleProduct.tos : _vm.product.tos)?_c('div',[_c('h1',[_vm._v("\n                "+_vm._s(_vm.$t('_web_shop_single_terms', 'Terms and conditions'))+"\n              ")]),_c('p',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                  _vm.processText(_vm.singleProduct ? _vm.singleProduct.tos : _vm.product.tos)
                )}})]):_vm._e()]),_c('h1',{staticClass:"product-title-mobile"},[_vm._v("\n            "+_vm._s(_vm.singleProduct ? _vm.singleProduct.name : _vm.product.name)+"\n          ")]),_c('div',{staticClass:"prices-info-mobile"},[_c('div',{staticClass:"prices"},[(
                  _vm.singleProduct &&
                    _vm.singleProduct.cost &&
                    _vm.singleProduct.cost.discount_amount
                )?_c('span',{staticClass:"discount mb-3"},[_c('span',[_vm._v(_vm._s(((_vm.singleProduct.cost.discount_amount) + " %")))])]):_vm._e(),_c('span',{staticClass:"sale-price"},[_vm._v(_vm._s(_vm.selectedRegion
                    ? ((_vm.selectedPrice.final_price) + " " + (_vm.selectedPrice.currency) + " ")
                    : ((_vm.pricePlaceholder.final_price) + " " + (_vm.pricePlaceholder.currency)))+"\n              ")]),(
                  _vm.singleProduct &&
                    _vm.singleProduct.cost &&
                    _vm.singleProduct.cost.discount_amount
                )?_c('del',{staticClass:"full-price"},[_vm._v("\n                "+_vm._s(_vm.singleProduct.cost.base_price)+"\n                "+_vm._s(_vm.singleProduct.cost.currency)+"\n              ")]):_vm._e()])]),(_vm.singleProduct ? _vm.singleProduct.details : _vm.product.details)?_c('div',{staticClass:"mobile-description-new-single"},[_c('h1',[_vm._v("\n              "+_vm._s(_vm.$t('_web_shop_single_description', 'Product description'))+"\n            ")]),_c('p',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                _vm.processText(
                  _vm.singleProduct ? _vm.singleProduct.details : _vm.product.details
                )
              )}})]):_vm._e(),_c('div',{staticClass:"purchase-mobile"},[_c('div',{staticClass:"quantity"},[_c('span',[_vm._v(_vm._s(_vm.$t('_web_shop_single_quantity', 'Qty:')))]),_c('div',{staticClass:"quantity-counter"},[_c('button',{attrs:{"disabled":_vm.quantity <= 1},on:{"click":_vm.decrementQuantity}},[_c('span',[_vm._v("-")])]),_c('span',[_vm._v(_vm._s(_vm.quantity))]),_c('button',{attrs:{"disabled":_vm.singleProduct
                      ? _vm.quantity >= _vm.singleProduct.max_quantity
                      : _vm.quantity >= Math.ceil(500 / _vm.selectedPrice.final_price)},on:{"click":_vm.incrementQuantity}},[_c('span',[_vm._v("+")])])])]),(
                _vm.singleProduct
                  ? _vm.quantity >= _vm.singleProduct.max_quantity
                  : _vm.quantity >= Math.ceil(500 / _vm.selectedPrice.final_price)
              )?_c('span',{staticClass:"single-product-note-mobile"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  '_web_shop_max_value',
                  'Note: Maximum value 500.00 {currency}',
                  { currency: _vm.selectedPrice.currency }
                ))+"\n            ")]):_vm._e(),_c('div',{staticClass:"pickers-mobile"},[_c('div',{staticClass:"gap-control"},[_c('ShopDropdown',{attrs:{"list":_vm.regions,"empty-option":true,"could-be-empty":true,"empty-text":_vm.$t('_web_shop_selectt', 'Select'),"label":_vm.$t('_web_shop_label_region', 'Region')},scopedSlots:_vm._u([{key:"item",fn:function(options){return [_c('span',{staticClass:"piceker-option-item"},[_vm._v("\n                      "+_vm._s(options.item)+"\n                    ")])]}}],null,false,2620484378),model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}})],1),_c('div',{staticClass:"gap-control"},[_c('ShopDropdown',{attrs:{"list":_vm.filteredPrices ? _vm.filteredPrices : [],"disabled":_vm.selectedRegion === '',"empty-text":_vm.$t('_web_shop_selectt', 'Select'),"label":_vm.$t('_web_shop_label_amount', 'Amount')},scopedSlots:_vm._u([{key:"item",fn:function(options){return [_c('div',{staticClass:"chevron-divider"},[_c('span',[(options.item.value)?_c('span',[_vm._v("\n                          "+_vm._s(((options.item.value) + " - "))+"\n                        ")]):_vm._e(),_vm._v("\n\n                        "+_vm._s(options.item.currency)+"\n                        "+_vm._s(options.item.final_price)+"\n                      ")]),_c('icon',{attrs:{"name":"chevron-down-small","width":"16"}})],1)]}}],null,false,2040261572),model:{value:(_vm.selectedPrice),callback:function ($$v) {_vm.selectedPrice=$$v},expression:"selectedPrice"}})],1)]),_c('HeraButton',{staticClass:"btn-primary shop-now buy-now-btn-mobile-shop",attrs:{"label":_vm.$t('_web_shop_label_buy_now', 'BUY NOW'),"loading":_vm.isOpening},on:{"click":_vm.buyNow}})],1),(
              _vm.singleProduct
                ? _vm.singleProduct.instructions
                : _vm.product.instructions
            )?_c('div',{staticClass:"mobile-description-new-single mobile-descr-howto"},[_c('h1',[_vm._v(_vm._s(_vm.$t('_web_shop_single_usage', 'How to use')))]),_c('p',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                _vm.processText(
                  _vm.singleProduct
                    ? _vm.singleProduct.instructions
                    : _vm.product.instructions
                )
              )}})]):_vm._e(),(_vm.singleProduct ? _vm.singleProduct.tos : _vm.product.tos)?_c('div',{staticClass:"mobile-description-new-single"},[_c('h1',[_vm._v("\n              "+_vm._s(_vm.$t('_web_shop_single_terms', 'Terms and conditions'))+"\n            ")]),_c('p',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                _vm.processText(_vm.singleProduct ? _vm.singleProduct.tos : _vm.product.tos)
              )}})]):_vm._e()]),_c('div',{staticClass:"single-product-sidebar"},[_c('div',{staticClass:"prices"},[(
                _vm.singleProduct &&
                  _vm.singleProduct.cost &&
                  _vm.singleProduct.cost.discount_amount
              )?_c('span',{staticClass:"discount mb-3"},[_c('span',[_vm._v(_vm._s(((_vm.singleProduct.cost.discount_amount) + " %")))])]):_vm._e(),_c('span',{staticClass:"sale-price"},[_vm._v(_vm._s(_vm.selectedRegion
                  ? ((_vm.selectedPrice.final_price) + " " + (_vm.selectedPrice.currency) + " ")
                  : ((_vm.pricePlaceholder.final_price) + " " + (_vm.pricePlaceholder.currency)))+"\n            ")]),(
                _vm.singleProduct &&
                  _vm.singleProduct.cost &&
                  _vm.singleProduct.cost.discount_amount
              )?_c('del',{staticClass:"full-price"},[_vm._v("\n              "+_vm._s(_vm.singleProduct.cost.base_price)+"\n              "+_vm._s(_vm.singleProduct.cost.currency)+"\n            ")]):_vm._e()]),_c('div',{staticClass:"quantity"},[_c('span',[_vm._v(_vm._s(_vm.$t('_web_shop_single_quantity', 'Qty:')))]),_c('div',{staticClass:"quantity-counter"},[_c('button',{attrs:{"disabled":_vm.quantity <= 1},on:{"click":_vm.decrementQuantity}},[_c('span',[_vm._v("-")])]),_c('span',[_vm._v(_vm._s(_vm.quantity))]),_c('button',{attrs:{"disabled":_vm.singleProduct
                    ? _vm.quantity >= _vm.singleProduct.max_quantity
                    : _vm.quantity >= Math.ceil(500 / _vm.selectedPrice.final_price)},on:{"click":_vm.incrementQuantity}},[_c('span',[_vm._v("+")])])])]),(
              _vm.singleProduct
                ? _vm.quantity >= _vm.singleProduct.max_quantity
                : _vm.quantity >= Math.ceil(500 / _vm.selectedPrice.final_price)
            )?_c('span',{staticClass:"single-product-note"},[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_shop_max_value',
                'Note: Maximum value 500.00 {currency}',
                { currency: _vm.selectedPrice.currency }
              ))+"\n          ")]):_vm._e(),_c('div',{staticClass:"pickers"},[_c('div',{staticClass:"gap-control"},[_c('ShopDropdown',{attrs:{"list":_vm.regions,"empty-option":true,"could-be-empty":true,"empty-text":_vm.$t('_web_shop_selectt', 'Select'),"label":_vm.$t('_web_shop_label_region', 'Region')},scopedSlots:_vm._u([{key:"item",fn:function(options){return [_c('span',{staticClass:"piceker-option-item"},[_vm._v("\n                    "+_vm._s(options.item)+"\n                  ")])]}}],null,false,2749867034),model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}})],1),_c('div',{staticClass:"gap-control"},[_c('ShopDropdown',{staticClass:"lg-price-picker",attrs:{"list":_vm.filteredPrices ? _vm.filteredPrices : [],"empty-text":_vm.$t('_web_shop_selectt', 'Select'),"label":_vm.$t('_web_shop_label_amount', 'Amount')},scopedSlots:_vm._u([{key:"item",fn:function(options){return [_c('div',{staticClass:"chevron-divider"},[_c('span',[(options.item.value)?_c('span',[_vm._v("\n                        "+_vm._s(((options.item.value) + " - "))+"\n                      ")]):_vm._e(),_vm._v("\n                      "+_vm._s(options.item.currency)+"\n                      "+_vm._s(options.item.final_price)+"\n                    ")]),_c('icon',{staticClass:"ml-2 mr-2",attrs:{"name":"chevron-down-small","width":"16"}})],1)]}}],null,false,655262920),model:{value:(_vm.selectedPrice),callback:function ($$v) {_vm.selectedPrice=$$v},expression:"selectedPrice"}})],1)]),_c('HeraButton',{staticClass:"btn-primary shop-now",attrs:{"label":_vm.$t('_web_shop_label_buy_now', 'BUY NOW'),"loading":_vm.isOpening,"disabled":_vm.selectedRegion === '' || (_vm.InfoIsLoading && _vm.isAuthorized)},on:{"click":_vm.buyNow}})],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }