<template>
  <Panel
    class="d-flex flex-column position-relative p-4 cursor-pointer tournament-block tournament-block-wrapper"
    :class="{ 'ph-panel--with-loader': isLoading }"
    :variant="['dark-bg', 'rounded-large']"
  >
    <Panel
      v-if="isEditMode"
      class="d-flex align-items-center justify-content-center p-0"
      :variant="'transparent'"
    >
      <HeraFormInput
        v-model="title"
        v-validate.disable="'required'"
        variant="flat"
        class="flex-grow-1"
        required
        :placeholder="$$t('Enter block name', 'placeholder')"
        :error="errors.first(`block-${tournamentBlockID}.title`)"
        :data-vv-as="$$t('Group Name', 'block_name')"
        data-vv-name="title"
        :data-vv-scope="`block-${tournamentBlockID}`"
        :disabled="isCompetitionFieldDisabled('title', blockInfo)"
        @blur="saveBlock"
      />
      <Icon
        class="h-ml-4 remove-block-icon"
        name="trash"
        :title="$$t('Delete group')"
        :class="{
          disabled:
            !(isEditMode && (blockInfo.stage_index || blockInfo.index)) ||
            isCompetitionFieldDisabled('deleteGroup', blockInfo),
        }"
        :width="24"
        @click.native="removeBlock"
      />
    </Panel>
    <!-- <Panel
      class="py-4 px-0 d-flex align-items-center justify-content-center"
      :variant="'transparent'"
    >
      <Icon :name="blockTypeIcon" has-full-width />
    </Panel> -->
    <Panel
      v-if="isEditMode"
      class="d-flex flex-column align-items-center justify-content-center p-0 mt-4"
      :variant="'transparent'"
    >
      <div class="row align-items-end">
        <div class="col">
          <HeraFormInput
            v-model="playersRequired"
            v-validate.disable="'required|numeric|min_value:2'"
            variant="flat"
            :error="errors.first(`block-${tournamentBlockID}.players_required`)"
            type="number"
            required
            icon="arrow-to-right"
            icon-size="18"
            :label="$$t('Participants:')"
            :class="{
              'is-invalid': errors.first(
                `block-${tournamentBlockID}.players_required`
              ),
            }"
            :placeholder="
              $t('_web_tournament_squads-placeholder', 'Total Participant')
            "
            :data-vv-as="$$t('Income', 'income_name')"
            :data-vv-scope="`block-${tournamentBlockID}`"
            :data-vv-name="'players_required'"
            :disabled="
              isCompetitionFieldDisabled('players_required', blockInfo)
            "
            @blur="saveBlock"
          />
        </div>
        <div class="col">
          <HeraFormInput
            v-model="proceedNext"
            v-validate.disable="
              playersRequired
                ? `required|numeric|min_value:1|max_value:${playersRequired}`
                : `required|numeric|min_value:1`
            "
            variant="flat"
            :error="errors.first(`block-${tournamentBlockID}.proceed_next`)"
            type="number"
            required
            icon="arrow-from-left"
            icon-size="18"
            :class="{
              'is-invalid': errors.first(
                `block-${tournamentBlockID}.proceed_next`
              ),
            }"
            :placeholder="
              $t(
                '_web_tournament_stage_proceed-next-placeholder',
                'Proceed next'
              )
            "
            :data-vv-as="$$t('Outcome', 'outcome_name')"
            :data-vv-scope="`block-${tournamentBlockID}`"
            :data-vv-name="'proceed_next'"
            :disabled="isCompetitionFieldDisabled('proceed_next', blockInfo)"
            @blur="saveBlock"
          />
        </div>
      </div>
    </Panel>
    <Panel
      v-else
      class="d-flex flex-column align-items-center justify-content-center py-0 px-0"
      :variant="'transparent'"
    >
      <TextBlock
        tag="div"
        class="text-truncate w-100"
        variant="bold"
        :size="0.875"
      >
        {{ blockInfo.title }}
      </TextBlock>
      <div class="row mt-4 no-gutters w-100">
        <div class="col d-flex align-items-center justify-content-start">
          <Icon
            name="arrow-to-right"
            :width="18"
            class="h-mr-3"
            :fill="$scss.colors.secondaryTextBg"
          />
          <TextBlock :variant="blockInHighlight">{{
            blockInfo.players_joined
          }}</TextBlock>
          <span class="text-secondary">&nbsp;/&nbsp;</span>
          <TextBlock>{{ blockInfo.players_required }}</TextBlock>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <TextBlock :text="blockInfo.proceed_next" />
          <Icon
            name="arrow-from-left"
            :width="18"
            class="h-ml-3"
            :fill="$scss.colors.secondaryTextBg"
          />
        </div>
      </div>
      <div class="mt-4 w-100 text-center">
        <StatusLabel :status="blockInfo.status" class="h-ml-auto px-4 py-3" />
      </div>
    </Panel>
  </Panel>
</template>

<script>
import { translate } from '@/mixins/translate';
import { mapActions, mapGetters } from 'vuex';
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import routeHelpers from '@/mixins/routeHelpers';
import HeraFormInput from '@/components/atoms/form/HeraFormTextInput';
import Panel from '@/components/atoms/Panel';
import StatusLabel from '@/components/molecules/StatusLabel.vue';
export default {
  name: 'TournamentBlockEditable',

  components: {
    Icon,
    TextBlock,
    HeraFormInput,
    Panel,
    StatusLabel,
  },
  inject: ['$validator'],
  mixins: [routeHelpers, translate('_web_tournament_stage_block')],

  props: {
    blockInfo: {
      required: true,
      type: Object,
    },
    blockType: {
      required: true,
      type: String,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '',
      playersRequired: 0,
      proceedNext: 0,
    };
  },
  computed: {
    ...mapGetters(['isCompetitionFieldDisabled']),
    isFull() {
      return this.blockInfo.players_joined === this.blockInfo.players_required;
    },
    isOversupply() {
      return this.blockInfo.players_joined > this.blockInfo.players_required;
    },
    tournamentBlockID() {
      return this.blockInfo.id;
    },
    tournamentStageID() {
      return this.blockInfo.stage_id;
    },
    blockInHighlight() {
      return this.blockInfo.players_joined === 0
        ? 'secondary'
        : !this.isFull || this.isOversupply
        ? 'success'
        : 'warning';
    },
    blockTypeIcon() {
      return `brackets-${this.blockType.replace('_', '-')}`;
    },
  },
  watch: {
    blockInfo(newVal) {
      this.updateLocalState(newVal);
    },
  },
  created() {
    this.updateLocalState(this.blockInfo);
  },
  methods: {
    ...mapActions([
      'deleteTournamentBlock',
      'updateTournamentBlock',
      'errorNotify',
    ]),
    updateLocalState(newVal) {
      if (this.isLoading) {
        return;
      }
      this.title = newVal.title;
      this.playersRequired = newVal.players_required;
      this.proceedNext = newVal.proceed_next;
    },
    saveBlock() {
      if (this.isLoading) {
        return;
      }
      this.$validator
        .validate(`block-${this.tournamentBlockID}.*`)
        .then(isValid => {
          if (!isValid) {
            throw this.$t(
              '_web_tournament=blocks-has-invalid-fields',
              'The config of block {blockName} is incorrect',
              { blockName: this.title }
            );
          }
        })
        .then(() => {
          const fields = this.$validator.fields.items.filter(
            field => field.scope === `block-${this.tournamentBlockID}`
          );
          const needToUpdate = fields.some(field => field.flags.changed);
          if (needToUpdate) {
            this.$emit('change', {
              ...this.blockInfo,
              title: this.title,
              players_required: this.playersRequired,
              proceed_next: this.proceedNext,
            });
          }
        })
        .catch(this.errorNotify);
    },
    removeBlock() {
      this.$emit('deleteBlock', {
        stageID: this.tournamentStageID,
        blockID: this.tournamentBlockID,
      });
    },
  },
};
</script>

<style lang="scss">
.tournament-block {
  &:last-child {
    margin-bottom: 0 !important;
  }
  &.tournament-block-wrapper.is-disabled {
    opacity: 0.5;

    &::after {
      content: '';
      @include fill-border();
      cursor: not-allowed;
      z-index: 9;
    }
  }
}
</style>
