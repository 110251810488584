import { flow } from '@/utils/lodashUtils';

export const emptyObject = () => Object.create(null);

export const array = (length = 0) => Array.from({ length });

export const emptyArray = array;

export const fillArray = predicate => array => array.map(predicate);

export const emptyString = () => '';

export const arrayOfEmptyObject = () => flow([array, fillArray(emptyObject)]);

const generateDefaultProp = type => {
  if (!type) {
    return null; // {type: null, default: null}
  }

  if (type[0]) {
    type = type[0];
  }
  return type === Object ? emptyObject : type === Array ? array : type();
};
export const prop = (type, _default = generateDefaultProp(type)) => ({
  type,
  default: _default,
});
export const propValidator = (
  type,
  _default = generateDefaultProp(type),
  validator = null
) => ({
  type,
  default: _default,
  validator,
});

export const propRequired = (type = null, validator = null) => ({
  type,
  validator,
  required: true,
});
