var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'mini-mode': _vm.miniMode, 'screen-mode': !_vm.miniMode }},[_c('ListLoader',{staticClass:"align-items-stretch chats-list-wrap",attrs:{"is-loading":_vm.isLoading,"is-crashed":_vm.isCrashed,"is-empty-list":!_vm.items.length,"dummy-props":( _obj = {
      iconName: 'dummy/chat',
      size: _vm.miniMode ? 160 : 200
    }, _obj[_vm.miniMode ? 'subheaderText' : 'headerText'] = _vm.$t(
        '_web_chat-list_is-empty',
        'You have no chats at the moment'
      ), _obj.text = _vm.$t(
        '_web_chat-list_is-empty_subheader',
        'Start a new chat and browse profiles to find someone to talk with'
      ), _obj )},on:{"reload":_vm.reload}},[_c('template',{slot:"list"},[_c('div',{staticClass:"d-flex align-items-stretch flex-column h-100 flex-grow-1"},[_c('div',{ref:"chatList",staticClass:"custom-scrollbar chats-list h-100",attrs:{"tabindex":"-1"},on:{"&!wheel":function($event){return _vm.focusIn($event)}}},[_c('InfiniteScroll',{staticClass:"list-unstyled mb-0 h-auto",attrs:{"tag":"ul","list":_vm.items,"list-name":"conversations","is-disabled":_vm.scrollIsDisabled || !_vm.nextPageToken,"in-container":true},on:{"ScrollToBottom":_vm.fetchChats},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(!_vm.miniMode)?_c('div',_vm._l((_vm.items),function(item){return _c('ChatsListItem',{key:item.id + Math.random() * 9,attrs:{"data":item,"mini-mode":_vm.miniMode,"is-active":_vm.activeConversationId === item.id}})}),1):_c('div',_vm._l((_vm.items),function(item){return _c('ChatsListItem',{key:item.id,attrs:{"data":item,"mini-mode":_vm.miniMode,"is-active":_vm.activeConversationId === item.id}})}),1)]}}])})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }