<script>
const filterComponents = {
  Search: () => import('@/components/molecules/form/HeraSearchInput'),
  Country: () => import('@/components/molecules/form/autoComplete/Country'),
  City: () => import('@/components/molecules/form/autoComplete/City'),
  TournamentPlayerType: () => import('./TournamentPlayerType'),
  Games: () => import('./Games'),
  GamesAutocomplete: () => import('./GamesAutocomplete'),
  SortBy: () => import('./SortBy'),
  Genres: () => import('./Genres'),
  Platforms: () => import('./Platforms'),
  PlatformsMultiselect: () => import('./PlatformsMultiselect'),
  TournamentType: () => import('./TournamentType'),
  Status: () => import('@/components/tournaments/StatusFilter'),
};

export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      values: {},
    };
  },
  created() {
    this.setDefaultState();
    this.debounceEmit = this.$lodash.debounce(this.emitChanges, 500);
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.values);
    },
    setDefaultState() {
      this.filters.forEach(({ type, saveAs }) => {
        this.$set(this.values, saveAs, this.isCheckbox(type) ? [] : null);
      });
    },
    isCheckbox(type) {
      return type === 'Platforms' || type === 'Games';
    },
    resetFilters() {
      this.setDefaultState();
      this.emitChanges();
    },
    getProps(filter) {
      const setValue = event => {
        this.$set(this.values, filter.saveAs, event);
        if (filter.saveOn === 'input') {
          this.debounceEmit();
        }
      };
      const emitEvent = event => {
        setValue(event);
        this.debounceEmit();
      };
      const props = {
        value: this.values[filter.saveAs],
        label: filter.label,
        ...(filter.props || null),
      };
      if (filter.saveAs === 'city') {
        props.country = this.values.country;
      }
      return {
        class: filter.class,
        attrs: filter.attrs,
        props,
        on: {
          input: setValue,
          [filter.saveOn]: emitEvent,
        },
      };
    },
  },

  render(createElement) {
    const renderComponents = this.filters.reduce((result, filter) => {
      result.push(
        createElement(filterComponents[filter.type], this.getProps(filter))
      );
      return result;
    }, []);
    return createElement(
      'div',
      { class: { 'ph-filters': true } },
      renderComponents
    );
  },
};
</script>

<style lang="scss">
.ph-filters {
  .sort-by-filter button {
    width: 100% !important;
  }
  .dropdown-full-width {
    width: 100% !important;
  }
  .wrapper-control-label {
    margin-bottom: 0.25rem !important;
    > label {
      margin-bottom: 0 !important;
    }
  }
  .segment-control {
    width: auto;
  }
  &.in-line {
    .segment-control {
      width: 100% !important;
    }
    > *:not(:last-child) {
      margin-bottom: 2.375rem !important;
    }
  }

  input,
  button {
    background: $new-bg-base;
    border: 1px solid $new-border-neutral;
  }

  input::placeholder {
    color: $new-fg-neutral-faded !important;
  }

  input:focus {
    border: 1px solid $new-border-primary !important;
  }

  button:focus {
    border: 1px solid $new-border-primary !important;
  }

  label {
    padding-bottom: 5px;
    font-weight: 700;
    font-size: $hr-text-small !important;
  }

  .attrib-tag {
    color: var(--ph-primary-text);
    background-color: $new-bg-base !important;
  }
}
</style>
