import { Subject } from 'rxjs';
import Vue from 'vue';
import api from '@/api';

export const COUNTER_DEFAULT_STATE = () => {
  return {
    unreadConversations: 0,
    unreadNotifications: 0,
    system: new Subject(),
  };
};

const getters = {
  unreadConversations: state => state.unreadConversations,
  unreadNotifications: state => state.unreadNotifications,
  system: state => state.system,
};

const mutations = {
  setUnreadConversations(state, count) {
    state.unreadConversations = +count;
  },
  setUnreadNotifications(state, count) {
    state.unreadNotifications = +count;
  },
  addUnreadConversations(state, count) {
    state.unreadConversations = Math.max(state.unreadConversations + count, 0);
  },
  /* unused
  addUnreadNotifications(state, count) {
    state.unreadNotifications = Math.max(state.unreadNotifications + count, 0);
  },
  */
  setUnread(state, data) {
    if (data.hasOwnProperty('pending_messages')) {
      state.unreadConversations = +data['pending_messages'] || 0;
    }

    if (data.hasOwnProperty('pending_notifications')) {
      state.unreadNotifications = +data['pending_notifications'] || 0;
    }
  },
  clearToCounterDefaultState(state) {
    const defaultState = COUNTER_DEFAULT_STATE();

    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
};

const actions = {
  fetchCounters({
    getters: { accessToken },
    rootState: {
      accounts: {
        my: { accountId },
      },
    },
    commit,
    dispatch,
  }) {
    return Promise.all([
      api.notifications
        .getNotificationCounters(accountId)(accessToken)
        .then(({ data }) => commit('setUnread', data)),
      api.conversations
        .getMessagesCounters(accountId)(accessToken)
        .then(({ data }) => commit('setUnread', data)),
    ]).catch(err => dispatch('errorNotify', err));
  },
  counterSubscriber({ commit }, data) {
    const availableUpdateTypes = [
      'profile_counters_updated',
      'notification_counter_updated',
      'participant_counter_updated',
    ];
    if (availableUpdateTypes.includes(data.type)) {
      commit('setUnread', data.extended);
      return data.extended;
    }
  },
};

export default {
  state: COUNTER_DEFAULT_STATE(),
  getters,
  mutations,
  actions,
};
