import { beforeEnterCheckAuth } from './guards.js';
import tournaments from './tournaments';
import tournamentsManagement from './tournaments-management';
import BattlesList from '@/views/account/content/battles/BattlesList';
const MyTournamentsList = () => import('@/views/tournaments/list/my');
const SpecialTournamentsList = () => import('@/views/tournaments/list/special');

import { PROFILE } from '@/constants/accounts';
import { MAIN_COMPETITIONS, MAIN_MATCHES } from '@/constants/router/mains';

const EmptyRouteWrapper = {
  render(h) {
    return h('router-view');
  },
};

export default [
  {
    path: '',
    name: MAIN_COMPETITIONS,
    component: EmptyRouteWrapper,
    children: [
      ...tournaments,
      ...tournamentsManagement,
      {
        path: 'my-tournaments',
        beforeEnter: beforeEnterCheckAuth,
        name: 'myTournaments',
        component: MyTournamentsList,
        meta: {
          includeInParentMenu: MAIN_COMPETITIONS,
          hideFromOrg: true,
          excludeFromBreadcrumbs: true,
          titleKey: '_web_left-sidebar_nav-my-tournaments-link',
          fallbackLabel: 'My Tournaments',
          icon: 'sidebar/my-tournaments',
          sidebarParent: 'myTournaments',
        },
      },
      {
        path: 'specialevents',
        beforeEnter: beforeEnterCheckAuth,
        name: 'specialEvents',
        component: SpecialTournamentsList,
        meta: {
          includeInParentMenu: MAIN_COMPETITIONS,
          excludeFromBreadcrumbs: true,
          titleKey: '_web_left-sidebar_nav-special-events-link',
          fallbackLabel: 'Special Events',
          icon: 'sidebar/special',
          sidebarParent: 'specialEvents',
        },
      },
      {
        path: 'my-matches',
        beforeEnter: beforeEnterCheckAuth,
        name: MAIN_MATCHES,
        component: BattlesList,
        props: { type: PROFILE, id: 'my' },
        meta: {
          includeInParentMenu: MAIN_COMPETITIONS,
          hideFromOrg: true,
          excludeFromBreadcrumbs: true,
          titleKey: '_web_left-sidebar_nav-my-matches-link',
          fallbackLabel: 'My Matches',
          icon: 'sidebar/matches',
          sidebarParent: MAIN_MATCHES,
        },
      },
    ],
    meta: {
      menuGroup: MAIN_COMPETITIONS,
      guestEnabled: true,
      inMainNav: 1,
      inMainNavOrg: 1,
      excludeFromBreadcrumbs: true,
      titleKey: '_web_left-sidebar_nav-competitions-link',
      fallbackLabel: 'Competitions',
      icon: 'sidebar/tournaments',
    },
  },
];
