<template>
  <div class="card-slider-container" :style="{ '--scrollWidth': scrollWidth }">
    <div class="card-slider-wrapper">
      <div class="card-slider-label-container">
        <span class="card-slider-label">{{ $t(labelKey, labelFallback) }}</span>
        <router-link
          v-if="viewFull"
          :to="{ name: 'tournamentsList', params: { closed: true } }"
          class="view-all-desktop"
        >
          <span class="card-slider-view-all">{{
            $t('_web_homepage-view-full', 'View full archive')
          }}</span>
        </router-link>
        <router-link
          v-if="viewAll"
          :to="{ name: 'tournamentsList' }"
          class="view-all-desktop"
        >
          <span class="card-slider-view-all">
            {{ $t('_web_homepage-view-all', 'View all') }}
          </span>
        </router-link>
      </div>
      <div class="card-slider-inner">
        <button :class="{ 'btn-disabled': cardIndex === 0 }" @click="moveLeft">
          <img
            src="@/assets/images/homepage/arrow-slider.svg"
            class="arrow-left"
            alt="arrow left"
          />
        </button>
        <div class="card-slider-inner2">
          <div class="card-slider-inner3" :style="{ '--cardIndex': cardIndex }">
            <template v-for="(tournament, tournamentIndex) in tournaments">
              <TournamentCard
                v-if="tournament"
                :key="`tournament_${tournament.id || tournamentIndex}`"
                v-bind="{ tournament, forOrganizer: false }"
                :slider="'card-slider'"
              />
            </template>
          </div>
        </div>
        <button
          :class="{
            'btn-disabled':
              cardIndex === tournaments.length - 4 || tournaments.length <= 4,
          }"
          @click="moveRight"
        >
          <img
            src="@/assets/images/homepage/arrow-slider.svg"
            class="arrow-right"
            alt="arrow right"
          />
        </button>
      </div>
      <router-link
        v-if="viewFull"
        :to="{ name: 'tournamentsList', params: { closed: true } }"
        class="view-all-mobile"
      >
        <span v-if="viewFull" class="card-slider-view-all">{{
          $t('_web_homepage-view-full', 'View full archive')
        }}</span>
      </router-link>
      <router-link
        v-if="viewAll"
        :to="{ name: 'tournamentsList' }"
        class="view-all-mobile"
      >
        <span v-if="viewAll" class="card-slider-view-all">{{
          $t('_web_homepage-view-all', 'View all')
        }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import TournamentCard from '@/components/common/TournamentCard';

export default {
  name: 'CardSlider',
  components: {
    TournamentCard,
  },

  props: {
    tournaments: propRequired(Array),
    labelKey: prop(String),
    labelFallback: prop(String),
    viewAll: prop(Boolean),
    viewFull: prop(Boolean),
  },
  data() {
    return {
      scrollWidth: null,
      cardIndex: 0,
    };
  },
  mounted() {
    this.getScrollBarWidth();
  },
  methods: {
    moveLeft() {
      if (this.cardIndex === 0) {
        return;
      } else {
        this.cardIndex -= 1;
      }
    },
    moveRight() {
      const max = this.tournaments.length - 4;
      if (this.cardIndex === max) {
        return;
      } else {
        this.cardIndex += 1;
      }
    },
    getScrollBarWidth() {
      let el = document.createElement('div');
      el.style.cssText =
        'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      el.remove();
      return (this.scrollWidth = width.toString() + 'px');
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  --scrollbar-width: calc(100vw - 100%);
}
.card-slider-container {
  width: 100%;
  max-width: calc(100vw - var(--scrollWidth));
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.card-slider-label-container {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  margin-left: calc(40px + 0.5rem);
  margin-right: calc(40px + 0.5rem);
  @media screen and (max-width: 991px) {
    margin-left: calc(0.5rem);
    margin-right: calc(0.5rem);
  }

  .card-slider-label {
    font-size: $hr-text-large;
    text-transform: uppercase;
  }

  .card-slider-view-all {
    font-size: 14px;
    width: 100px;
    font-weight: bold;
    background: #5a58f2;
    border-radius: 3px;
    color: white;
    padding: 0.5rem 1rem 0.5rem 1rem;
    min-height: 20px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: #3b39cc;
    }
  }
}
.card-slider-inner {
  display: flex;
  max-width: calc(100vw - var(--scrollWidth));
  justify-content: center;
  align-items: center;
  margin-top: $hr-spacing-05;
}

.card-slider-inner2 {
  overflow: hidden;
}

.card-slider-inner3 {
  width: calc($hr-wrapper-width + 1rem);
  max-width: calc($hr-wrapper-max-width + 1rem);
  display: flex;
  transition: transform 0.4s ease;
  .app-rtl & {
    transform: translateX(calc(var(--cardIndex) * +25%));
  }

  .app-ltr & {
    transform: translateX(calc(var(--cardIndex) * -25%));
  }
}

.arrow-left {
  width: 100%;
  .app-ltr & {
    rotate: 180deg;
  }

  .app-rtl & {
    rotate: 0deg;
  }
}

.arrow-right {
  width: 100%;

  .app-rtl & {
    rotate: 180deg;
  }
}

button {
  align-self: stretch;
  z-index: 4;
  width: 40px;
  background: none;
  border: none;
}

.btn-disabled {
  opacity: 0;
  pointer-events: none;
}

.view-all-mobile {
  display: none;
}

@media screen and (max-width: 1483px) {
  .card-slider-inner3 {
    width: 92vw;
  }
}

@media screen and (max-width: 1274px) {
  .card-slider-inner3 {
    .app-rtl & {
      transform: translateX(calc(var(--cardIndex) * +33.3333%));
    }

    .app-ltr & {
      transform: translateX(calc(var(--cardIndex) * -33.3333%));
    }
  }
}

@media screen and (max-width: 991px) {
  .card-slider-label-container {
    @include auto-rtl(margin-left, 1rem);
  }

  .card-slider-inner > button {
    display: none;
  }

  .card-slider-inner2 {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .card-slider-inner2::-webkit-scrollbar {
    width: 0;
  }

  .card-slider-inner3 {
    width: calc(100vw - 17px);
    padding-left: 0.5rem;
  }

  // .view-all-desktop {
  //   display: none;
  // }

  .view-all-mobile {
    display: block;
    @include auto-rtl(margin-left, 1rem);
    margin-top: 1rem;
    font-size: 16px;
    color: #9d9cf7;
    display: none;

    &:hover {
      color: #6300fa;
    }
  }
}
</style>
