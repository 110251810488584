<template>
  <div
    class="contenteditable"
    tabindex="-1"
    @keydown="onKeyPressPutter($event)"
    @focus="onFocus"
    @blur="onBlur"
  >
    <form class="input-group p-0 m-0" @submit.prevent="submit">
      <template v-if="parentEl">
        <component
          :is="item.componentName"
          v-for="(item, index) in gridReplaced"
          :ref="item.componentName"
          :key="`${item.componentName}-${item.className}-${index}`"
          v-model="models[item.componentName]"
          v-bind="binder(item)"
          :disable-autofocus="disableAutofocus"
          @submit="submit"
          @busy="setComponentAsBusy($event, item.componentName)"
          @input="$emit('update', $event)"
          @isFull="
            isFull => $emit('isFull', { isFull, component: item.componentName })
          "
          @isOverlay="
            isOverlay =>
              $emit('isFull', { isOverlay, component: item.componentName })
          "
        />
      </template>
    </form>
    <Dropper v-if="hasDrop" v-on="{ drop }" />
    <Putter
      v-if="hasPutter"
      ref="putter"
      v-model="putterIsActive"
      v-bind="{
        input: models[COMPONENT_EDITABLE],
        tagsActivators: hasTags ? ['#', '＃'] : null,
        mentionsActivators: hasMentions ? ['@', '＠'] : null,
        inputRegExp: /^\w+$/,
      }"
      regard="over"
      v-on="{ put }"
    />
    <Counter
      v-if="hasCounter"
      :current-input="models[COMPONENT_EDITABLE]"
      :max-length="maxLength"
      is-editable
    />
  </div>
</template>

<script>
import { unescapeHTML } from '@/filters/string';
import { prop } from '@/utils/factories';

import Editable from './Editable';
import Attachments from './Attachments';
import Smiles from './Smiles';
import Submit from './Submit';

import Dropper from './Dropper';
import Putter from './Putter';
import Counter from '@/components/atoms/form/Counter';

const COMPONENT_EDITABLE = 'Editable';
const COMPONENT_ATTACHMENTS = 'Attachments';
const COMPONENT_SMILES = 'Smiles';
const COMPONENT_SUBMIT = 'Submit';

export default {
  name: 'ContentEditable',
  components: {
    Editable,
    Attachments,
    Smiles,
    Submit,

    Putter,
    Dropper,
    Counter,
  },
  props: {
    showButton: prop(Boolean),
    placeholder: prop(String),
    metaSend: prop(Boolean),
    maxLength: prop(Number),
    rows: {
      type: [Number, String, Array],
      default: () => [1, 3],
    },
    grid: {
      type: Array,
      default: () => [
        COMPONENT_ATTACHMENTS,
        COMPONENT_EDITABLE,
        // COMPONENT_SMILES,
        COMPONENT_SUBMIT,
      ],
    },
    //you should specify models in same order as in grid
    gridModels: {
      type: Array,
      default: () => [],
    },
    hasDrop: prop(Boolean),
    hasTags: prop(Boolean),
    hasMentions: prop(Boolean),
    hasCounter: prop(Boolean),
    uploadBtnIcon: prop(String, 'editable/upload'),
    attachmentsPositionIsAfter: prop(Boolean),
    attachmentsButtonIsHidden: prop(Boolean),
    notAutoReset: prop(Boolean),
    disableAutofocus: prop(Boolean, false),
    closest: {
      type: Node,
      default: null,
    },
    filesAccepts: {
      type: Array,
      default: null,
    },
  },
  data() {
    const emptyModel = {
      [COMPONENT_EDITABLE]: '',
      [COMPONENT_ATTACHMENTS]: [],
      [COMPONENT_SMILES]: '',
      [COMPONENT_SUBMIT]: '',
    };
    const models = {};
    this.grid.forEach((componentName, index) => {
      if (this.gridModels[index]) {
        models[componentName] = this.gridModels[index];
        delete emptyModel[componentName];
      }
    });
    return {
      COMPONENT_EDITABLE,
      COMPONENT_ATTACHMENTS,
      COMPONENT_SMILES,
      COMPONENT_SUBMIT,

      emptyModel,
      models: { ...this.$lodash.cloneDeep(emptyModel), ...models },

      parentEl: null,
      busyComponents: new Set(),
      putterIsActive: false,
    };
  },
  computed: {
    hasPutter() {
      return this.hasTags || this.hasMentions;
    },
    gridReplaced() {
      let className = 'input-group-prepend';

      return this.$lodash
        .uniq(this.grid)
        .map(componentName => {
          if (componentName === COMPONENT_EDITABLE) {
            className = 'input-group-append';

            return { componentName };
          } else if (
            componentName === COMPONENT_ATTACHMENTS ||
            componentName === COMPONENT_SMILES ||
            componentName === COMPONENT_SUBMIT
          ) {
            return {
              componentName,
              className,
            };
          } else {
            return null;
          }
        })
        .filter(item => item);
    },
  },
  mounted() {
    this.parentEl = this.closest || this.$el.parentElement;
  },
  beforeDestroy() {
    this.parentEl = null; // clear memory (tested)
  },
  methods: {
    getRef(name, method, ...args) {
      const component = this.$lodash.get(this, `$refs[${name}][0]`);
      if (component) {
        if (method) {
          return component[method](...args);
        }
        return component;
      }
      return null;
    },
    binder({ className, componentName }) {
      const bind = {
        class: className,
      };

      switch (componentName) {
        case COMPONENT_EDITABLE:
          Object.assign(bind, {
            showButton: this.showButton,
            placeholder: this.placeholder,
            rows: this.rows,
            metaSend: this.metaSend,
            maxLength: this.maxLength,
          });
          break;
        case COMPONENT_ATTACHMENTS:
          Object.assign(bind, {
            parentEl: this.parentEl,
            uploadBtnIcon: this.uploadBtnIcon,
            insertAfter: this.attachmentsPositionIsAfter,
            buttonIsHidden: this.attachmentsButtonIsHidden,
            accepts: this.filesAccepts,
          });
          break;
        case COMPONENT_SMILES:
          Object.assign(bind, {
            parentEl: this.parentEl,
          });
          break;
        case COMPONENT_SUBMIT:
        default:
          break;
      }
      return bind;
    },
    setComponentAsBusy(isBusy, componentName) {
      this.busyComponents[isBusy ? 'add' : 'delete'](componentName);
    },
    submit() {
      if (
        !this.busyComponents.size ||
        (!this.busyComponents.has(COMPONENT_ATTACHMENTS) &&
          this.models[COMPONENT_ATTACHMENTS] &&
          this.models[COMPONENT_ATTACHMENTS].length) // only attachments
      ) {
        this.$emit('submit', {
          text: unescapeHTML(this.models[COMPONENT_EDITABLE]),
          attachments: this.models[COMPONENT_ATTACHMENTS],
        });

        if (!this.notAutoReset) {
          this.reset();
        }
      }
    },
    reset() {
      Object.keys(this.models).forEach(key => {
        this.models[key] = key === COMPONENT_ATTACHMENTS ? [] : '';
      });
    },
    drop(dataTransfer) {
      return this.getRef(COMPONENT_ATTACHMENTS, 'onDrop', dataTransfer.files);
    },
    put(node) {
      this.getRef(COMPONENT_EDITABLE, 'putNode', node, 1);
    },
    onSelectFiles() {
      return this.getRef(COMPONENT_ATTACHMENTS, 'onSelectFiles');
    },
    onKeyPressPutter(event) {
      if (this.hasPutter && this.putterIsActive) {
        const componentPutter = this.$refs.putter;
        if (!componentPutter) {
          return;
        }

        switch (event.code) {
          case 'ArrowDown':
            componentPutter.updateActivePopoverItem(1);
            event.preventDefault();
            break;
          case 'ArrowUp':
            componentPutter.updateActivePopoverItem(-1);
            event.preventDefault();
            break;
          case 'Enter':
            componentPutter.selectActivePopoverItem();
            event.preventDefault();
            break;
        }
      }
    },
    onFocus({ target }) {
      if (!target.isContentEditable) {
        this.getRef(COMPONENT_EDITABLE, 'focus', true);
      }
    },
    onBlur() {
      document.activeElement && document.activeElement.blur();
    },
    isEmpty(checkOnlyText) {
      const textIsEmpty = !this.models[COMPONENT_EDITABLE];

      if (checkOnlyText) {
        return textIsEmpty;
      }

      return textIsEmpty && this.getRef(COMPONENT_ATTACHMENTS, 'isEmpty');
    },
  },
};
</script>

<style lang="scss">
.contenteditable {
  font-size: $ph-body-font-size;
  overflow: hidden;
  border-radius: 0.25rem;
  width: 100%;
  transition: box-shadow 0.15s;
  height: auto;

  .input-group {
    height: auto;
    position: relative;
    z-index: 9;
  }

  .input-group-prepend:first-of-type {
    // @include auto-rtl(margin-left, 0.5rem);
  }

  .input-group-append:last-of-type {
    @include auto-rtl(margin-right, 0.5rem);
  }

  .input-group-prepend,
  .input-group-append {
    padding: $ph-tiny-space 0.5rem;

    & > .btn {
      line-height: $ph-h2-line-height !important;
      padding: $ph-tiny-space 0.5rem;
      margin: (-$ph-tiny-space) (-0.5rem);
      border: none !important;
      height: calc(#{$ph-tiny-space * 2} + #{$ph-h2-line-height});
      &:hover,
      &:focus,
      &:active {
        background: unset !important;
        color: $ph-primary-text !important;
        box-shadow: none !important;
      }
    }
  }
}
</style>
