<template>
  <div class="match-attachments">
    <div
      class="cursor-pointer h-auto"
      :class="{
        disabled: !hasAttachments,
      }"
      @click.stop="openAttachments"
    >
      <AppIcon
        name="picture"
        :fill="$scss.colors.primaryTextBg"
        :width="iconWidth"
      />
    </div>
    <Info
      :class="{
        disabled: !hasComment,
      }"
      :text="data.text"
      :show-icon="false"
      :float="['top']"
      class="h-ml-3"
    >
      <AppIcon
        name="comments"
        :fill="$scss.colors.primaryTextBg"
        class=""
        :width="iconWidth"
      />
    </Info>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import AppIcon from '@/components/atoms/Icon';
import Info from '@/components/molecules/InfoTooltip.vue';
import { prop, propRequired } from '@/utils/factories';
import { computedStyle } from '@/utils/getProps';
const ATTACH = 'attach';
const COMMENT = 'comment';
//don't delete
//import mock from '@/__mock__/matches.js'
export default {
  name: 'MatchAttachments',
  components: {
    AppIcon,
    Info,
  },
  props: {
    value: propRequired(Object),
    itemWidth: prop([Number, String], 42),
    itemMaxWidth: prop([Number, String], null),
    itemHeight: prop([Number, String], 30),
    itemMaxHeight: prop([Number, String], null),
    iconWidth: prop([Number, String], 16),
    infoFloat: prop(String),
    seeOnly: prop(String, '', val => [ATTACH, COMMENT].includes(val)),
  },
  computed: {
    data() {
      return {
        ...this.value,
      };
    },
    hasAttachments() {
      return this.data && this.data.attachments && this.data.attachments.length;
    },
    attachVisible() {
      return this.hasAttachments || (this.seeOnly && this.seeOnly === ATTACH);
    },
    hasComment() {
      return this.data && this.data.text;
    },
    commentVisible() {
      return this.hasComment || (this.seeOnly && this.seeOnly === COMMENT);
    },
    cssVariables() {
      return {
        '--width': computedStyle(this.itemWidth),
        '--height': computedStyle(this.itemHeight),
        '--max-width': computedStyle(this.itemMaxWidth),
        '--max-height': computedStyle(this.itemMaxHeight),
      };
    },
  },
  methods: {
    ...mapMutations('mediaplayer', ['openPlayer']),
    openAttachments() {
      if (!this.hasAttachments) {
        return;
      }
      const isImage = this.data.attachments[0].image;
      this.openPlayer({
        fullScreen: true,
        type: isImage ? 'image' : 'video',
        url: isImage
          ? this.data.attachments[0].image
          : this.data.attachments[0].video,
        mediaGroup: this.data.attachments
          .map(item => ({
            type: item.image ? 'image' : 'video',
            ...item,
          }))
          .sort((a, b) => (a.type > b.type ? 1 : -1)),
      });
    },
  },
};
</script>
<style lang="scss">
.match-attachments {
  /*defaults*/
  --width: 'unset';
  --height: 'unset';
  --max-width: 'unset';
  --max-height: 'unset';

  height: var(--height);
  min-width: var(--width);

  %sizes {
    width: var(--width);
    height: var(--height);
    max-width: var(--max-width);
    max-height: var(--max-height);
  }
  > * {
    line-height: 1rem;
  }
  .thumb {
    position: relative;
    display: inline-block;
    @extend %sizes;
    background: $ph-card 100% 100% no-repeat;
    overflow: hidden;
    background-size: cover;

    img {
      width: 100%;
      height: 100%;
      max-width: var(--max-width);
      max-height: var(--max-height);
      object-fit: cover;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
