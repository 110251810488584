<template>
  <div class="shop-search-input">
    <img
      class="search-icon"
      src="@/assets/images/icons/search-shop.svg"
      alt="Search icon"
    />
    <input
      ref="searchInput"
      v-model="searchQuerry"
      class="shop-search-field"
      :placeholder="$t('_web_search', 'Search')"
      autofocus
      @keyup.enter="onEnterPressed"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      searchQuerry: '',
    };
  },
  computed: {
    ...mapGetters(['selectedCategory', 'categories', 'shopSearch']),
  },
  watch: {
    searchQuerry() {
      this.setSearchShop(this.searchQuerry);
      this.selectCategory(null);
    },
    shopSearch(newShopSearch) {
      this.searchQuerry = newShopSearch;
      this.setSearchShop(newShopSearch);
      this.selectCategory(null);
    },
  },
  created() {
    this.searchQuerry = this.shopSearch;
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    ...mapActions(['setSearchShop', 'selectCategory']),

    setFocus() {
      this.$route.name !== 'singleProduct' &&
        this.searchQuerry.length > 0 &&
        this.$refs.searchInput.focus();
    },

    onEnterPressed() {
      if (this.shopSearch.length !== 0) {
        setTimeout(() => {
          this.$router.push({ name: 'Shop' });
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss">
.shop-search-input {
  // max-width: 259px;
  margin-bottom: 16px;
  display: flex;
  flex-grow: 1;
  border: 1px solid $new-bg-neutral;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  @media (max-width: 850px) {
    max-width: unset;
  }
}

.shop-search-input:focus-within {
  border: 1px solid $new-fg-primary;
  transition: all 0.3s linear;
}

.search-icon {
  margin-inline: 8px;
  display: flex;
  height: 20px;
  width: 20px;
  .app-rtl & {
    transform: scaleX(-1);
  }
}

.shop-search-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: $new-fg-neutral;
  font-size: $hr-text-small;
  font-family: $hr-font-family;
  width: 100%;
}

.shop-search-field::placeholder {
  color: $new-fg-disabled;
  font-size: $hr-text-small;
}
</style>
