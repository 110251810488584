import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests.js';

export const BATTLES_BASE = 'battles';

export default {
  getBattlesList: () =>
    authorizedGetRequest(BATTLES_BASE, {
      preventCancelRequest: true,
    }),
  getById: battleID => authorizedGetRequest(`${BATTLES_BASE}/${battleID}`),
  getConflicted: () => authorizedGetRequest(`${BATTLES_BASE}/conflicted`),
  getResults: battleID =>
    authorizedGetRequest(`${BATTLES_BASE}/${battleID}/results`, {
      preventCancelRequest: true,
    }),
  getResultsAll: competitionID =>
    authorizedGetRequest(`${BATTLES_BASE}/grid/${competitionID}`, {
      ignoreParams: true,
    }),
  resolve: (tournamentId, stageId, blockId, matchId) =>
    authorizedPostRequest(
      `tournaments/${tournamentId}/stages/${stageId}/blocks/${blockId}/matches/${matchId}/resolution`
    ),
  cancel: battleID =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/cancel`),
  // will be deprecated soon
  provideOfflineResult: battleID =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/results/offline`),
  postResults: battleID =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/results`),
  startBattle: battleID =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/start`),
  completeBattle: battleID =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/complete`),
  confirmResults: battleID =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/confirm_results`),
  addToRoster: (battleID, playerID) =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/squads/${playerID}`),
  deleteFromRoster: (battleID, playerID) =>
    authorizedDeleteRequest(`${BATTLES_BASE}/${battleID}/squads/${playerID}`),
  updateBattleInfo: battleID =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/battle_info`),
};
