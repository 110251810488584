<template>
  <Icon
    v-if="isVisible"
    role="button"
    :fill="$scss.primaryText"
    :hover-fill="$scss.hoverColor"
    name="dots"
    class="p-2 cursor-pointer"
    @click="clickHandler"
  />
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ThreeDotsMenu',
  components: {
    Icon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    hideIfItemsIsEmpty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisible() {
      return this.hideIfItemsIsEmpty ? !!this.items.length : true;
    },
  },
  methods: {
    ...mapActions({
      openPopoverMenu: 'popoverMenu/open',
    }),
    clickHandler(event) {
      this.$emit('click', event);
      this.$nextTick(() => {
        this.openPopoverMenu({
          event,
          items: this.items,
          settings: {
            targetSelector: 'svg',
            autoClose: true,
          },
        });
      });
    },
  },
};
</script>
