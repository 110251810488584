import api from '@/api';
import Vue from 'vue';
export const DEFAULT_QR_STATE = () => ({
  size: 250,
  errorCorrectionCapability: 3,
  dotScale: 0.7,
  darkColor: '#000000',
  lightColor: '#FFFFFF',
  borderColor: '#000000',
  transparentBorders: false,
  borderSize: 0,
  logo: '',
  logoSize: 100,
  logoTransparentBackground: false,
  background: '',
  backgroundImageAlpha: 0.3,
  saveFormat: 'png',
});

const getters = {
  qrOptionByKey: state => key => state[key],
  qrOptions: state => ({
    width: +state.size,
    height: +state.size,
    dotScale: +state.dotScale,
    colorDark: state.darkColor,
    colorLight: state.lightColor,
    correctLevel: state.errorCorrectionCapability,
    quietZone: state.borderSize,
    quietZoneColor:
      state.transparentBorders || !state.borderColor
        ? 'rgba(0,0,0,0)'
        : state.borderColor,
    logo: state.logo,
    logoWidth: state.logoSize,
    logoHeight: state.logoSize,
    logoBackgroundTransparent: state.logoTransparentBackground,
    backgroundImage: state.background,
    backgroundImageAlpha: state.backgroundImageAlpha,
    drawer: state.saveFormat,
  }),
};

const mutations = {
  updateQrOptions: (state, payload = {}) => {
    for (const key in payload) {
      if (Object.hasOwnProperty.call(payload, key)) {
        Vue.set(state, key, payload[key]);
      }
    }
  },
};

const actions = {
  fetchQrDefaultOptions: ({ commit, getters: { accessToken } }) =>
    api.settings.getQrSettings(accessToken).then(response => {
      commit('updateQrOptions', response.data);
    }),

  // saveQrDefaultOptions: ({ state, getters: { accessToken } }) =>
  //   api.settings
  //     .postQrSettings(accessToken, state)
  //     .then(response => response.data),
};

export default {
  state: DEFAULT_QR_STATE(),
  getters,
  mutations,
  actions,
};
