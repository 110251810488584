var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"stage-simple-form"},[_c('Subheader',{staticClass:"d-block mt-0",attrs:{"text":_vm.$$t('Tournament type', 'section-title')}}),_c('StageSettingStageType',{staticClass:"mb-6",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[(
            _vm.isSingleElimination ||
              _vm.isDoubleElimination ||
              _vm.isSwiss ||
              _vm.isRoundRobin
          )?_c('div',{staticClass:"col-auto"},[(
              _vm.isSingleElimination ||
                _vm.isDoubleElimination ||
                _vm.isSwiss ||
                _vm.isRoundRobin
            )?_c('StageSettingBestOf',{staticClass:"mb-5",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e(),(_vm.isDoubleElimination)?_c('StageSettingBestOfLower',{attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e()],1):_vm._e(),(_vm.isSingleElimination || _vm.isDoubleElimination)?_c('div',{staticClass:"col-auto d-flex",class:{
            'align-items-center': _vm.isDoubleElimination,
          }},[_c('StageSettingBestOfFinal',{attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}})],1):_vm._e(),(_vm.isSingleElimination)?_c('div',{staticClass:"col-auto d-flex"},[(_vm.thirdPlaceMatchAllowed)?_c('StageSettingBestOfThird',{attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e()],1):_vm._e(),(_vm.isSwiss || _vm.isBattleRoyale || _vm.isRoundRobin)?_c('StageSettingRounds',{staticClass:"col-1",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e(),(_vm.isBattleRoyale)?_c('StageSettingPointsPerKill',{staticClass:"col-auto",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e()],1),_c('div',{staticClass:"row mb-5"},[(_vm.isSingleElimination)?_c('StageSetting3rdPlaceMatch',{staticClass:"col-3 d-flex",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e(),(_vm.isSwiss || _vm.isRoundRobin)?[_c('StageSettingTieMatchAllowed',{staticClass:"col-2",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}),_c('StageSettingTieGameAllowed',{staticClass:"col-3",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}})]:_vm._e()],2),(_vm.isSwiss || _vm.isRoundRobin)?[_c('div',{staticClass:"row mb-5"},[_c('StageSettingPointsForWin',{staticClass:"col-auto",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled || !_vm.tieMatchAllowed}}),_c('StageSettingPointsForTie',{staticClass:"col-auto",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled || !_vm.tieMatchAllowed}}),_c('StageSettingPointsForLose',{staticClass:"col-auto",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled || !_vm.tieMatchAllowed}})],1)]:_vm._e(),(_vm.isBattleRoyale)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('TieBreakerRules',{class:{
              disabled:
                _vm.isStageFieldDisabled('tieBreakerRules') ||
                _vm.safeMode ||
                _vm.isAllDisabled,
            },attrs:{"safe-mode":_vm.safeMode},model:{value:(_vm.tieBreakerPreset),callback:function ($$v) {_vm.tieBreakerPreset=$$v},expression:"tieBreakerPreset"}})],1)]):_vm._e(),(_vm.isBattleRoyale)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('TableOfPoints',{class:{
              disabled:
                _vm.isStageFieldDisabled('pointsTable') ||
                _vm.safeMode ||
                _vm.isAllDisabled,
            },attrs:{"read-only":_vm.safeMode},model:{value:(_vm.pointsPerPlace),callback:function ($$v) {_vm.pointsPerPlace=$$v},expression:"pointsPerPlace"}})],1)]):_vm._e(),_c('div',{staticClass:"row mt-5"},[(_vm.isDoubleElimination)?_c('StageSettingFullDoubleElimination',{staticClass:"col-3 d-flex",attrs:{"disabled":_vm.safeMode || _vm.isAllDisabled}}):_vm._e()],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }