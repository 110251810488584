import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
  simplePostRequest,
} from '@/api/requests';

const NOTIFICATIONS_BASE = 'notifications';

export const getNotifications = authorizedGetRequest(NOTIFICATIONS_BASE, {
  preventCancelRequest: true,
});

export const postNotificationAction = (id, action) =>
  authorizedPostRequest(`${NOTIFICATIONS_BASE}/${id}/${action}`);

export const updateNotificationStatus = id =>
  authorizedPutRequest(`${NOTIFICATIONS_BASE}/${id}`);

export const updateAllNotificationsStatus = () =>
  authorizedPutRequest(`${NOTIFICATIONS_BASE}`);

export const deleteNotification = id =>
  authorizedDeleteRequest(`${NOTIFICATIONS_BASE}/${id}`);

export const getHubConnection = hub =>
  authorizedGetRequest(`${NOTIFICATIONS_BASE}/negotiate/${hub}`, {
    preventCancelRequest: true,
  });

const getNotificationCounters = memberId =>
  authorizedGetRequest(`${NOTIFICATIONS_BASE}/${memberId}/unread`);

const collectEmails = simplePostRequest(
  `${NOTIFICATIONS_BASE}/phmax/notify/released`
);

export default {
  getNotifications,
  postNotificationAction,
  updateNotificationStatus,
  updateAllNotificationsStatus,
  deleteNotification,
  getHubConnection,
  getNotificationCounters,
  collectEmails,
};
