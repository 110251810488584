<template>
  <Panel
    v-if="currentTournament.prize.prize_line"
    class="tournament-info_prizes pb-0"
    :variant="['transparent', 'rounded-pre-medium']"
  >
    <div class="row">
      <div class="col">
        <div class="header">
          <Subheader
            class="mb-4 text-uppercase font-weight-normal"
            :text="$t('_web_tournament-info_prizes_tournament', 'Prizes')"
            :text-block-props="{ size: 0.875 }"
            variant="hr-secondary"
          />
        </div>

        <div v-for="({ place, players }, index) in winners" :key="index">
          <hr
            v-show="index !== 0 && index !== 3"
            :class="['horizontal-line']"
          />
          <ListItem class="items" :class="{ 'bg-winner': index === 0 }">
            <template slot="title">
              <div class="prizes-container">
                <ListItem
                  class="primary font-weight-bold tournament-place"
                  :title="dummyPlaces[place]"
                  :class="{ 'invisible mt-5': index === 3 }"
                />
                <ListItemAccountSingle
                  v-if="players && players.length"
                  v-bind="{
                    type: players[0].type,
                    name: `@${$filters.displayName(players[0])}`,
                    accountId: players[0].id,
                    approved: players[0].isAmbassador || players[0].validated,
                    size: 48,
                  }"
                  class="tournament-player"
                />
                <TextBlock
                  slot="tail"
                  class="text-nowrap primary tournament-prize font-weight-bold"
                  :line-height="1.5"
                >
                  {{ getPlacePrize(index) }}
                </TextBlock>
              </div>
            </template>
          </ListItem>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
import ListItem from '@/components/common/ListItem';
import { mapGetters } from 'vuex';
import TextBlock from '@/components/atoms/TextBlock';
import ListItemAccountSingle from '@/components/common/ListItemAccountSingle';

export default {
  name: 'TournamentInfoPrizes',
  components: {
    ListItemAccountSingle,
    TextBlock,
    Panel,
    Subheader,
    ListItem,
  },
  data() {
    return {
      dummyPlaces: {
        '1': this.$t('_web_tournament-info_place-1st', '1st'),
        '2': this.$t('_web_tournament-info_place-2nd', '2nd'),
        '3': this.$t('_web_tournament-info_place-3rd', '3rd'),
      },
      winnerIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['currentTournament']),
    winners({ currentTournament: { winners } }) {
      if (winners.length === 4) {
        return winners.slice(0, 2);
      } else {
        return winners;
      }
    },
  },
  methods: {
    getPlacePrize(place) {
      return this.$lodash.get(
        this,
        `currentTournament.prize.prize_line[${place}].prize`
      );
    },
  },
};
</script>

<style lang="scss">
.horizontal-line {
  border-bottom: 0.5px solid #464646;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
}

.prizes-container {
  display: flex !important;
  justify-content: flex-start;
}

.tournament-place {
  flex: 1;
  margin-right: 0.2rem;
}

.tournament-place span {
  font-size: $hr-text-regular !important;
  font-family: $hr-font-family-secondary !important;
}

.tournament-player {
  flex: 5;
  color: $hr-text-link;
}

.tournament-player span {
  font-size: $hr-text-regular;
  font-family: $hr-font-family-secondary !important;
}

.tournament-prize {
  font-size: $hr-text-regular;
  font-family: $hr-font-family-secondary !important;
}

.bg-winner {
  background-color: $hr-bg-accent;
  height: 3rem;
  border-radius: 30px;
  padding: 0px 15px 0px 15px;
  margin-left: -15px;
  margin-right: -15px;
}
</style>
