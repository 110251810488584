import { generateMutations } from '@/utils/store';
import { isEmpty } from '@/utils/lodashUtils';

const state = {
  isSwapping: false,
  hasErrorOnSwap: false,
  swapFrom: {},
  swapTo: {},
};

const getters = {
  isSwapping: state => state.isSwapping,
  hasErrorOnSwap: state => state.hasErrorOnSwap,
  swapData: state => ({
    from: state.swapFrom,
    to: state.swapTo,
  }),
  hasExternalSwapSource: state =>
    !isEmpty(state.swapFrom) &&
    !isEmpty(state.swapTo) &&
    state.swapFrom.grid !== state.swapTo.grid,
};

const mutations = {
  ...generateMutations('isSwapping', 'swapFrom', 'swapTo', 'hasErrorOnSwap'),
  clearSwapData(state) {
    state.swapFrom = {};
    state.swapTo = {};
  },
};

export default {
  state,
  getters,
  mutations,
};
