<template>
  <div>
    <HeraFormSwitchRevert
      v-model="allowPlayerResults"
      name="allow_player_results"
      :label="
        $$t(
          'Prohibit entering match results by players',
          'required_label_results'
        )
      "
      :disabled="isStageFieldDisabled('allow_player_results') || disabled"
      :error="errors.first('allow_player_results')"
      :tooltip="
        $$t(
          'The option is recommended only for BR tournaments with admins overlooking the match results'
        )
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from '@/utils/lodashUtils';

import { translate } from '@/mixins/translate';
import HeraFormSwitchRevert from '@/components/atoms/form/HeraFormSwitchRevert';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import { prop } from '@/utils/factories';
export default {
  name: 'SettingStageResultsBlocked',
  components: {
    HeraFormSwitchRevert,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    ...mapGetters(['currentTournamentStage', 'currentTournament']),

    blockConfig() {
      return get(this.currentTournamentStage, 'block_configuration', {});
    },
    allowPlayerResults: {
      get() {
        return this.getBlockConfigurationByKey(
          'allow_player_results',
          !this.isBattleRoyale
        );
      },
      set(val) {
        this.setBlockConfigurationByKey('allow_player_results', val);
      },
    },
    isBattleRoyale() {
      return this.blockConfig.type === 'battle_royale';
    },
  },
  watch: {
    'blockConfig.type'(newVal) {
      if (newVal === 'battle_royale') {
        if (this.allowPlayerResults !== false) {
          this.allowPlayerResults = false;
        }
      } else {
        if (this.allowPlayerResults !== true) {
          this.allowPlayerResults = true;
        }
      }
    },
  },
};
</script>

<style></style>
