<template>
  <div
    class="slider-chat-comment"
    :class="{
      'slider-chat-comment_undelivered': undelivered,
    }"
  >
    <div class="slider-chat-comment__header d-flex align-items-center">
      <ListItem
        :role="$filters.localDate(data.last_updated_on, $times.DATETIME_MED)"
        :avatar="data.created_avatar"
        :account-id="data.created_by"
        :type="data.creator_type"
        :name="$filters.displayName(author)"
      >
      </ListItem>
      <!-- <Avatar :size="34" class="h-mr-2" :img-url="data.created_avatar" />
      <div class="d-flex flex-column">
        <span>{{ author | displayName }}</span>
        <span class="slider-chat-comment__time">{{
           | parseDateTime
        }}</span>
      </div> -->
      <ThreeDots
        v-if="canEdit"
        class="slider-chat-comment__dropdown"
        :items="dropdownItems"
      />
    </div>
    <TextContent
      v-if="data.text"
      class="slider-chat-comment__text"
      :content="data.text"
    />
    <span v-show="undelivered" class="slider-chat-comment__tryagain order-2">
      <Icon name="warning" width="14px" class="mr-1 ml-1" />
      {{
        $t(
          '_web_tournament_sidechat_no-delivered-comment',
          'The comment was no delivered.'
        )
      }}
      <a href="#" @click.prevent="$emit('resend')">{{
        $t('_web_tournament_sidechat_try-again', 'Try again.')
      }}</a>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { prop, propRequired } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import ThreeDots from '@/components/common/ThreeDots';
import TextContent from '@/components/text/TextContent';
import ListItem from '@/components/common/ListItemAccount';

export default {
  components: { Icon, ThreeDots, TextContent, ListItem },
  props: {
    data: propRequired(Object),
    undelivered: prop(Boolean),
    canForceEdit: prop(Boolean),
  },
  computed: {
    ...mapState('my', ['accountId']),
    dropdownItems() {
      return [
        {
          label: this.$t(
            '_web_tournament_sidechat_delete-for-all',
            'Delete for all'
          ),
          action: () => this.$emit('delete'),
        },
      ];
    },
    author() {
      return this.data.author || { name: this.data.created_by };
    },
    canEdit() {
      return (
        this.canForceEdit ||
        (this.accountId &&
          (this.author.id || this.author.login) &&
          (this.author.id === this.accountId ||
            this.author.login === this.accountId))
      );
    },
  },
};
</script>

<style lang="scss">
.slider-chat-comment {
  width: 100%;
  padding-bottom: 0.625rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  border-bottom: 1px solid $ph-card;

  &_rtl {
    text-align: right;
  }

  &_undelivered {
    background: $ph-secondary-text;
    box-shadow: 0px -8px 0px 0px $ph-secondary-text;
  }

  &:nth-last-child {
    border-bottom: none;
  }

  &__header {
    position: relative;
    margin-bottom: 0.5rem;
  }

  &__dropdown {
    position: absolute;
    @include auto-rtl(right, 0.8rem);
    margin-top: -0.5rem;
  }

  &__time {
    font-size: 0.75rem;
    color: $ph-secondary-text;
  }

  &__text {
    line-height: 1.25rem;
    word-wrap: break-word;
    a {
      color: $ph-primary-text;
      text-decoration: underline;
      &:hover {
        color: $ph-accent;
      }
    }
  }

  &__tryagain {
    font-weight: 700;
    font-size: 0.75rem;
    color: $ph-error;

    a {
      color: $ph-error;
      text-decoration: underline;
    }
  }
}
</style>
