<template>
  <div
    v-if="hasButtons"
    class="navigation-buttons d-flex justify-content-center"
  >
    <router-link
      v-if="prevStep"
      :to="{
        name: prevStep.name,
        params: { ...routeParams, ...prevStep.params },
      }"
      :class="{
        'h-mr-5':
          primaryBtn || isTournamentSetup || isTournamentSettings || nextStep,
      }"
      :replace="prevStep.replace"
      :event="disabled ? '' : 'click'"
    >
      <HeraFormBtn :disabled="disabled">
        {{ $t(prevStep.key, prevStep.default) }}
      </HeraFormBtn>
    </router-link>

    <router-link
      v-if="primaryBtn"
      :to="{
        name: primaryBtn.name,
        params: { ...routeParams, ...primaryBtn.params },
      }"
      :class="{
        'h-mr-5': isTournamentSetup || isTournamentSettings || nextStep,
        disabled: isWaiting,
      }"
      :replace="primaryBtn.replace"
      :event="disabled ? '' : 'click'"
    >
      <HeraFormBtn
        class="btn-primary"
        :disabled="disabled"
        :class="{ 'is-loading': isWaiting }"
      >
        {{ $t(primaryBtn.key, primaryBtn.default) }}
      </HeraFormBtn>
    </router-link>

    <HeraFormBtn
      v-if="isTournamentSetup || isTournamentSettings"
      class="btn-primary"
      :class="{ 'h-mr-5': nextStep }"
      :disabled="!$lodash.get(currentTournament, 'info.game', null)"
      @click="save"
    >
      {{ $t('_web_tournaments_save-btn', 'Save') }}
    </HeraFormBtn>
    <!-- <StatusBtn v-if="!hideStatusBtn" /> -->

    <router-link
      v-if="nextStep"
      :to="{
        name: nextStep.name,
        params: { ...routeParams, ...nextStep.params },
      }"
      :class="{
        'h-mr-5':
          primaryBtn || isTournamentSetup || isTournamentSettings || prevStep,
      }"
      :event="disabled ? '' : 'click'"
    >
      <HeraFormBtn :disabled="disabled">
        {{ $t(nextStep.key, nextStep.default) }}
      </HeraFormBtn>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getMetaValue } from '@/views/tournaments/utils/meta';
import { prop } from '@/utils/factories';
import { mapActions } from 'vuex';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
// import StatusBtn from '@/views/tournaments/management/singleTournament/components/StatusBtn';
import tournamentsHelper from '@/mixins/tournaments/tournamentHelpers';
export default {
  name: 'NavigationButton',
  components: {
    // StatusBtn,
    HeraFormBtn,
  },
  mixins: [...tournamentsHelper],
  props: {
    disabled: prop(Boolean, false),
    isWaiting: prop(Boolean, false),
  },
  computed: {
    ...mapGetters(['currentTournament', 'currentTournamentStages']),
    ...mapState('my', ['account', 'accountId']),

    routeParams() {
      const params = this.$lodash.cloneDeep(this.$route.params);
      delete params.discardChanges;
      return params;
    },
    nextStep() {
      return this.buttonsProps ? this.buttonsProps.next : null;
    },
    prevStep() {
      return this.buttonsProps ? this.buttonsProps.prev : null;
    },
    primaryBtn() {
      return this.buttonsProps && this.buttonsProps.primary
        ? this.buttonsProps.primary
        : null;
    },
    hideStatusBtn() {
      return getMetaValue(this.$route, 'hideStatusBtn');
    },
    buttonsProps() {
      return getMetaValue(this.$route, 'buttons');
    },
    isTournamentSetup() {
      return this.$route.name === 'TournamentSetup';
    },
    isTournamentSettings() {
      return this.$route.name === 'TournamentSettings';
    },
    hasButtons() {
      return (
        this.nextStep ||
        this.isTournamentSetup ||
        this.isTournamentSettings ||
        this.primaryBtn ||
        this.prevStep
      );
    },
  },
  methods: {
    ...mapActions(['errorNotify']),
    save() {
      this.validateCurrentForm()
        .then(this.onUpdateTournament)
        .catch(this.errorNotify)
        .finally(() => {
          if (this.$route.path.includes('dashboard')) {
            window.dataLayer.push({
              event: 'torunament created',
              userID: this.accountId,
              from: this.$route.path,
              tournamentId: this.currentTournament.id,
              gameName: this.currentTournament.game,
              squadSize: this.currentTournament.squad_size,
              tournamentName: this.currentTournament.title,
              tournamentFormat: this.currentTournamentStages[0]
                .block_configuration.type,
              tournamentCountry: this.currentTournament.country,
              tournamentPool: this.currentTournament.prize.prize_pool,
              regForm: this.currentTournament.requires_registration_form,
              tournamentPrivate:
                this.currentTournament.registration_type === 'closed'
                  ? 'true'
                  : 'false',
              tournamentOrganizer: this.currentTournament.event_organizer_name,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.navigation-buttons {
  .btn {
    min-width: 220px;
  }
}
</style>
