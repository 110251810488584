var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"d-flex flex-column position-relative p-4 cursor-pointer tournament-block tournament-block-wrapper",class:{ 'ph-panel--with-loader': _vm.isLoading },attrs:{"variant":['dark-bg', 'rounded-large']}},[(_vm.isEditMode)?_c('Panel',{staticClass:"d-flex align-items-center justify-content-center p-0",attrs:{"variant":'transparent'}},[_c('HeraFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],staticClass:"flex-grow-1",attrs:{"variant":"flat","required":"","placeholder":_vm.$$t('Enter block name', 'placeholder'),"error":_vm.errors.first(("block-" + _vm.tournamentBlockID + ".title")),"data-vv-as":_vm.$$t('Group Name', 'block_name'),"data-vv-name":"title","data-vv-scope":("block-" + _vm.tournamentBlockID),"disabled":_vm.isCompetitionFieldDisabled('title', _vm.blockInfo)},on:{"blur":_vm.saveBlock},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('Icon',{staticClass:"h-ml-4 remove-block-icon",class:{
        disabled:
          !(_vm.isEditMode && (_vm.blockInfo.stage_index || _vm.blockInfo.index)) ||
          _vm.isCompetitionFieldDisabled('deleteGroup', _vm.blockInfo),
      },attrs:{"name":"trash","title":_vm.$$t('Delete group'),"width":24},nativeOn:{"click":function($event){return _vm.removeBlock($event)}}})],1):_vm._e(),(_vm.isEditMode)?_c('Panel',{staticClass:"d-flex flex-column align-items-center justify-content-center p-0 mt-4",attrs:{"variant":'transparent'}},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('HeraFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required|numeric|min_value:2'),expression:"'required|numeric|min_value:2'",modifiers:{"disable":true}}],class:{
            'is-invalid': _vm.errors.first(
              ("block-" + _vm.tournamentBlockID + ".players_required")
            ),
          },attrs:{"variant":"flat","error":_vm.errors.first(("block-" + _vm.tournamentBlockID + ".players_required")),"type":"number","required":"","icon":"arrow-to-right","icon-size":"18","label":_vm.$$t('Participants:'),"placeholder":_vm.$t('_web_tournament_squads-placeholder', 'Total Participant'),"data-vv-as":_vm.$$t('Income', 'income_name'),"data-vv-scope":("block-" + _vm.tournamentBlockID),"data-vv-name":'players_required',"disabled":_vm.isCompetitionFieldDisabled('players_required', _vm.blockInfo)},on:{"blur":_vm.saveBlock},model:{value:(_vm.playersRequired),callback:function ($$v) {_vm.playersRequired=$$v},expression:"playersRequired"}})],1),_c('div',{staticClass:"col"},[_c('HeraFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:(
            _vm.playersRequired
              ? ("required|numeric|min_value:1|max_value:" + _vm.playersRequired)
              : "required|numeric|min_value:1"
          ),expression:"\n            playersRequired\n              ? `required|numeric|min_value:1|max_value:${playersRequired}`\n              : `required|numeric|min_value:1`\n          ",modifiers:{"disable":true}}],class:{
            'is-invalid': _vm.errors.first(
              ("block-" + _vm.tournamentBlockID + ".proceed_next")
            ),
          },attrs:{"variant":"flat","error":_vm.errors.first(("block-" + _vm.tournamentBlockID + ".proceed_next")),"type":"number","required":"","icon":"arrow-from-left","icon-size":"18","placeholder":_vm.$t(
              '_web_tournament_stage_proceed-next-placeholder',
              'Proceed next'
            ),"data-vv-as":_vm.$$t('Outcome', 'outcome_name'),"data-vv-scope":("block-" + _vm.tournamentBlockID),"data-vv-name":'proceed_next',"disabled":_vm.isCompetitionFieldDisabled('proceed_next', _vm.blockInfo)},on:{"blur":_vm.saveBlock},model:{value:(_vm.proceedNext),callback:function ($$v) {_vm.proceedNext=$$v},expression:"proceedNext"}})],1)])]):_c('Panel',{staticClass:"d-flex flex-column align-items-center justify-content-center py-0 px-0",attrs:{"variant":'transparent'}},[_c('TextBlock',{staticClass:"text-truncate w-100",attrs:{"tag":"div","variant":"bold","size":0.875}},[_vm._v("\n      "+_vm._s(_vm.blockInfo.title)+"\n    ")]),_c('div',{staticClass:"row mt-4 no-gutters w-100"},[_c('div',{staticClass:"col d-flex align-items-center justify-content-start"},[_c('Icon',{staticClass:"h-mr-3",attrs:{"name":"arrow-to-right","width":18,"fill":_vm.$scss.colors.secondaryTextBg}}),_c('TextBlock',{attrs:{"variant":_vm.blockInHighlight}},[_vm._v(_vm._s(_vm.blockInfo.players_joined))]),_c('span',{staticClass:"text-secondary"},[_vm._v(" / ")]),_c('TextBlock',[_vm._v(_vm._s(_vm.blockInfo.players_required))])],1),_c('div',{staticClass:"col d-flex align-items-center justify-content-end"},[_c('TextBlock',{attrs:{"text":_vm.blockInfo.proceed_next}}),_c('Icon',{staticClass:"h-ml-3",attrs:{"name":"arrow-from-left","width":18,"fill":_vm.$scss.colors.secondaryTextBg}})],1)]),_c('div',{staticClass:"mt-4 w-100 text-center"},[_c('StatusLabel',{staticClass:"h-ml-auto px-4 py-3",attrs:{"status":_vm.blockInfo.status}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }