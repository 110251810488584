<template>
  <Popover
    v-bind="{ ...props, closest }"
    :class="settings.popoverClass"
    class="global-popover p-3"
    @toggle="toggleHandler"
  >
    <template v-if="title">
      <TextBlock v-bind="settings.titleBlock || {}">{{ title }}</TextBlock>
      <hr />
    </template>
    <TextBlock v-if="text" v-bind="settings.textBlock">{{ text }}</TextBlock>
    <template v-if="slot">
      <slot name="slotContent"></slot>
    </template>
  </Popover>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Popover from './index';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'GlobalPopover',
  components: {
    TextBlock,
    Popover,
  },
  props: {
    closest: {
      type: Node, // closest element position must be 'relative'
      default: null,
    },
  },
  computed: {
    ...mapState('popover', {
      settings: state => state.settings,
      text: state => state.text,
      title: state => state.title,
      slot: state => state.slot,
    }),
    ...mapGetters('popover', ['targetElement']),

    props() {
      return {
        position: this.targetElement,
        targetSelector: this.settings.targetSelector,
        float: this.settings.float || [],
        regard: this.settings.regard,
        isFixed: this.settings.isFixed,
        blockIsFixed: this.settings.blockIsFixed,
        translate: this.settings.translate,
      };
    },
  },
  watch: {
    slot(newSlot) {
      this.$slots.slotContent = newSlot;
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  methods: {
    ...mapMutations('popover', ['setTargetElement']),

    toggleHandler(isVisible) {
      if (!isVisible) {
        this.setTargetElement(null);
      }
    },

    clickHandler(item, event) {
      if (this.settings.stopItemClickBubbling) {
        event.stopPropagation();
        if (this.settings.autoClose) {
          this.setTargetElement(null);
        }
      }
      item.action();
    },
  },
};
</script>
<style lang="scss">
.global-popover {
  z-index: 100001;
}
</style>
