<template>
  <div class="feed-category-wrap">
    <div
      v-if="isAuthorized"
      :class="{ active: feedType === 'trending' }"
      class="categorie"
      @click="handleFeed('trending')"
    >
      <img
        src="@/assets/images/icons/trending.svg"
        :class="{ 'active-trending': feedType === 'trending' }"
        class="trending-folowing-icon"
        alt="trending"
        width="18px"
        height="20px"
      />
      {{ $t('_web_newsfeed_trending', 'Trending') }}
    </div>
    <div
      v-if="isAuthorized"
      :class="{ active: feedType === 'following' }"
      class="categorie"
      @click="handleFeed('following')"
    >
      <img
        src="@/assets/images/icons/folowing.svg"
        :class="{ 'active-following': feedType === 'following' }"
        class="trending-folowing-icon"
        alt="following"
        width="18px"
        height="20px"
      />
      {{ $t('_web_newsfeed_following', 'Following') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TrendingFollowing',
  computed: {
    ...mapGetters('feed', ['feedType']),
    ...mapGetters(['isAuthorized']),
  },
  methods: {
    ...mapActions('feed', ['setFeedType']),
    handleFeed(feedType) {
      this.setFeedType(feedType);
    },
  },
};
</script>

<style lang="scss">
.categorie {
  font-size: 20px;
  line-height: 28px;
  line-height: 105%;
  font-style: bold;
  font-weight: 700;
  color: #c1c7d7;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.categorie.active {
  color: #9d9cf7;
}
.active-trending {
  filter: invert(69%) sepia(15%) saturate(2648%) hue-rotate(213deg)
    brightness(166%) contrast(45%);
}
.active-following {
  filter: invert(68%) sepia(38%) saturate(360%) hue-rotate(194deg)
    brightness(215%) contrast(92%);
}

.feed-category-wrap {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
@media (max-width: 1100px) {
  .feed-category-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
  }
}
</style>
