const HASHTAG = '#playhera';
export const shareFacebookLink = {
  methods: {
    shareToFacebook({ text = '', url } = {}) {
      //eslint-disable-next-line
      if (FB) {
        //eslint-disable-next-line
        FB.ui(
          {
            display: 'popup',
            method: 'share',
            href: url,
            quote: text,
            hashtag: HASHTAG,
          },
          function() {}
        );
      }
    },
  },
};

export default [shareFacebookLink];
