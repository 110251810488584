import { isDate, isFinite, isString, get } from '@/utils/lodashUtils';
import { Duration, DateTime } from 'luxon';
import store from '@/store';

export const localDate = (value, options = null, removeCommas = false) => {
  let initialDatetime;
  if (isDate(value)) {
    initialDatetime = DateTime.fromJSDate(value, { zone: 'UTC' });
  } else if (+value && isFinite(parseInt(value))) {
    initialDatetime = DateTime.fromSeconds(+value, { zone: 'UTC' });
  } else {
    initialDatetime = DateTime.invalid('Wrong Date value provided');
  }

  let result = initialDatetime.isValid
    ? initialDatetime
        .toLocal()
        .setLocale(get(store, 'getters.currentLocale.key', 'en'))
        .toLocaleString(
          options || {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }
        )
    : isString(value)
    ? value
    : '';

  // Convert Arabic numerals to Eastern Arabic numerals if direction is RTL
  if (document.dir === 'rtl') {
    result = result.replace(/[0-9]/g, d =>
      String.fromCharCode(d.charCodeAt(0) + 1584)
    );
  }

  return removeCommas ? result.replace(',', '') : result;
};

export const secToDuration = (value, format = '') => {
  let initialDuration;
  if (value && isFinite(parseInt(value))) {
    initialDuration = Duration.fromObject({ seconds: value }).normalize();
  } else {
    initialDuration = Duration.invalid('Wrong Duration value provided');
  }

  return initialDuration.isValid
    ? initialDuration.toFormat(format || `hh'h' mm'm'`)
    : '';
};

export const timeAgo = (value, $t, options) => {
  const interval = new Date().getTime() / 1000 - value;
  const minutes = Math.floor(interval / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
    return $t('_web_profile_recently', 'recently');
  }

  if (hours < 1) {
    return $t(
      '_web_profile_minutes_ago',
      '{minutes} minute ago ::: {minutes} minutes ago',
      { minutes },
      minutes
    );
  }

  if (days < 1) {
    return $t(
      '_web_profile_hours_ago',
      '{hours} hour ago ::: {hours} hours ago',
      { hours },
      hours
    );
  }
  return localDate(value, options);
};

/**
 * parse unix-time (in seconds) to ISO time (d/m/y)
 * @param input
 * @returns {string}
 */
export const parseDate = input => {
  const date = new Date(input * 1e3);

  // Use Arabic numerals only when direction is RTL
  return document.dir === 'rtl'
    ? date.toLocaleDateString('ar', { numberingSystem: 'arab' })
    : date.toLocaleDateString('en-US');
};

/**
 * parse unix-time (in seconds) to ISO time (hh:MM (A|P)M)
 * @param input
 * @returns {string}
 */
export const parseTime = input => {
  const date = new Date(input * 1e3);

  // Use Arabic numerals and RTL direction only when direction is RTL
  return document.dir === 'rtl'
    ? date.toLocaleTimeString('ar', { numberingSystem: 'arab', hour12: true })
    : date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
};

/**
 * parse unix-time (in seconds) to ISO time (hh:MM (A|P)M) if it's today or to ISO date (d/m/y)
 * @param input
 * @returns {string}
 */
export const parseDateTime = input => {
  const date = new Date(input * 1e3);
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    // Use Arabic numerals and RTL direction only when direction is RTL
    return document.dir === 'rtl'
      ? date.toLocaleTimeString('ar', { numberingSystem: 'arab', hour12: true })
      : date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
  } else {
    // Use Arabic numerals only when direction is RTL
    return document.dir === 'rtl'
      ? date.toLocaleDateString('ar', { numberingSystem: 'arab' })
      : date.toLocaleDateString('en-US');
  }
};

export const formatTimeFromSeconds = val => {
  // Use Arabic numerals, RTL direction, and UTC timezone only when direction is RTL
  return document.dir === 'rtl'
    ? new Date(val * 1e3).toLocaleTimeString('ar', {
        numberingSystem: 'arab',
        timeZone: 'UTC',
        hour12: true,
      })
    : new Date(val * 1e3).toLocaleTimeString('it-IT', { timeZone: 'UTC' });
};

export default {
  localDate,
  secToDuration,
  timeAgo,
  parseDate,
  parseTime,
  parseDateTime,
  formatTimeFromSeconds,
};
