<template>
  <div>
    <!--  reg open  -->
    <div v-if="statusNum === 0">
      <template v-if="hasJoined || hasClicked">
        <div v-if="isSolo" class="d-flex flex-column text-information">
          <span class="first-line">{{
            $t('_web_single_action_solo-joined', regOpen.soloJoined.firstLine)
          }}</span>
          <span class="second-line">{{
            $t(
              '_web_single_action_solo-joined-second',
              regOpen.soloJoined.secondLine
            )
          }}</span>
          <span class="app-dl">{{
            $t('_web_single_action_anouncement', regOpen.soloJoined.announcment)
          }}</span>
        </div>
        <div v-else-if="!isSolo" class="d-flex flex-column text-information">
          <span class="first-line">{{
            $t(
              '_web_single_action-joined-first',
              regOpen.notSoloJoined.firstLine
            )
          }}</span>
          <br />
          <span class="second-line">{{
            $t(
              '_web_single_action-joined-second',
              regOpen.notSoloJoined.secondLine
            )
          }}</span>
          <span class="app-dl">{{
            $t('_web_single_action_anouncement', regOpen.soloJoined.announcment)
          }}</span>
        </div>
      </template>
      <template v-else class="notJoined-regOpen">
        <div
          v-if="
            myAccount._type !== 'organization' &&
              !isSolo &&
              !hasJoined &&
              toManyRevokes === '' &&
              !paymentPending &&
              (currentTournament.registration_type !== 'closed' ||
                $route.query.registration_token ||
                PlayerCompStatus.join_access === 'Invited')
          "
          class="d-flex flex-row flex-wrap join-buttons"
        >
          <div
            v-if="
              currentTournament.entry_fee.required === true &&
                maxPlayers &&
                toManyRevokes === '' &&
                !paymentPending
            "
            class="tournament-paid-entry"
          >
            <span>{{ $t('_web_tournament_entry', 'Entry') }}</span>
            <div>
              <span class="h-mr-2">{{
                currentTournament.entry_fee.currency
              }}</span>
              <span>{{ currentTournament.entry_fee.cost }}</span>
            </div>
            <span> | </span>
          </div>
          <div
            v-if="maxPlayers && toManyRevokes === '' && !paymentPending"
            class="d-flex flex-row flex-wrap join-buttons"
          >
            <Button
              class="team-button p-3"
              @click.prevent.stop="selectJoinScenario(tournament)"
            >
              <!-- <img src="@/assets/images/joinWithTeam.svg" alt="join with team" /> -->
              {{ $t('_web_single_action_join-squad', 'Join with team') }}
            </Button>
          </div>
        </div>
        <div v-else class="tournament-join-wrapper">
          <div
            v-if="
              currentTournament.entry_fee.required === true &&
                maxPlayers &&
                toManyRevokes === '' &&
                !paymentPending
            "
            class="tournament-paid-entry"
          >
            <span>{{ $t('_web_tournament_entry', 'Entry') }}</span>
            <div>
              <span class="h-mr-2">{{
                currentTournament.entry_fee.currency
              }}</span>
              <span>{{ currentTournament.entry_fee.cost }}</span>
            </div>
            <span> | </span>
          </div>
          <Button
            v-if="
              myAccount._type !== 'organization' &&
                isSolo &&
                maxPlayers &&
                !paymentPending &&
                toManyRevokes === '' &&
                (currentTournament.registration_type !== 'closed' ||
                  $route.query.registration_token ||
                  currentTournament.join_access === 'Invited')
            "
            class="playButton join-button-small"
            @click.prevent.stop="selectJoinScenario(tournament)"
            >{{ $t('_web_single_action-join-btn', regOpen.notJoined) }}</Button
          >
          <span
            v-else-if="
              myAccount._type !== 'organization' &&
                !maxPlayers &&
                !paymentPending &&
                toManyRevokes === '' &&
                (currentTournament.registration_type !== 'closed' ||
                  $route.query.registration_token ||
                  currentTournament.join_access === 'Invited')
            "
            class="invite-only"
            >{{
              $t(
                '_web_single_action-limit-reached',
                'Player number limit reached'
              )
            }}</span
          >
          <!-- <span class="first-line">{{
              $t('_web_single_action-payment', 'Continue your payment')
            }}</span> -->
          <Button
            v-else-if="
              myAccount._type !== 'organization' &&
                maxPlayers &&
                paymentPending &&
                toManyRevokes === '' &&
                (currentTournament.registration_type !== 'closed' ||
                  $route.query.registration_token ||
                  currentTournament.join_access === 'Invited' ||
                  PlayerCompStatus.registration_status === 'payment_pending')
            "
            class="compleat-button  join-button-small"
            @click.prevent.stop="
              pay(
                tournament,
                tournament.max_players !== 1 ? idForPayment : accountId
              )
            "
          >
            {{ $t('_web_single_action_pay', 'Complete payment') }}
          </Button>

          <span
            v-else-if="
              maxPlayers &&
                !paymentPending &&
                toManyRevokes === '' &&
                myAccount._type !== 'organization' &&
                PlayerCompStatus.join_access !== 'Joined'
            "
            class="invite-only"
            >{{
              $t(
                '_web_single_action-invite-only',
                'This is an invite-only tournament'
              )
            }}</span
          >
          <span
            v-else-if="
              myAccount._type !== 'organization' &&
                maxPlayers &&
                !paymentPending &&
                toManyRevokes === 'too_many_clan_entries' &&
                (currentTournament.registration_type !== 'closed' ||
                  $route.query.registration_token ||
                  currentTournament.join_access === 'Invited')
            "
            class="invite-only"
            >{{
              $t('_web_single_action-much-revokes', 'Too many revokes')
            }}</span
          >
        </div>
      </template>
    </div>
    <!-- line ups -->
    <div v-if="statusNum === 1" class="mobile-view-text">
      <template v-if="!hasJoined">
        <span v-if="showCountDown" class="first-line">{{
          $t('_web_single_action-line-first-line', LineUps.NotJoined.firstLine)
        }}</span>
        {{ ' ' }}
        <Countdown
          v-if="showCountDown"
          :separator="':'"
          :class="'counter'"
          :date="currentTournament.tournament_start"
        />
        <br />
        <span class="second-line">{{
          $t(
            '_web_single_action-line-second-line',
            LineUps.NotJoined.secondLine
          )
        }}</span>
      </template>
      <template v-if="hasJoined">
        <div v-if="hasQualified">
          <span v-if="showCountDown" class="first-line">{{
            $t(
              '_web_single_action-line-qualified-first-line',
              LineUps.soloJoined.qualified.firstLine
            )
          }}</span>
          {{ ' ' }}
          <Countdown
            v-if="showCountDown"
            :separator="':'"
            :class="'counter'"
            :date="currentTournament.tournament_start"
          />
          <br />
          <span class="second-line">{{
            $t(
              '_web_single_action-line-qualified-second-line',
              LineUps.soloJoined.qualified.secondLine
            )
          }}</span>
        </div>
        <div v-else-if="hasJoineded">
          <span class="second-line">{{
            $t(
              '_web_single_action-line-not-qualified-lineNew',
              LineUps.soloJoined.notQualifiedNew
            )
          }}</span>
        </div>
        <div v-else>
          <span class="second-line">{{
            $t(
              '_web_single_action-line-not-qualified-line',
              LineUps.soloJoined.notQualified
            )
          }}</span>
        </div>
      </template>
    </div>
    <!-- trournament started -->
    <div v-if="statusNum === 2" class="d-flex flex-column p-4 two-button">
      <template v-if="hasQualified">
        <div class="label-btn-wrap">
          <!-- <TextBlock class="label-started ">{{
            $t('_web_status_started', 'STARTED')
          }}</TextBlock> -->
          <Button
            class="playButton"
            @click.prevent.stop="$router.push('/my-matches')"
            >{{ $t('_web_single_action-play', started.soloJoined.button) }}
          </Button>
        </div>
      </template>
      <template v-else class="d-flex flex-column">
        <!-- <TextBlock class="label-started">{{
          $t('_web_status_started', 'STARTED')
        }}</TextBlock> -->
        <span class="first-line">
          {{ $t('_web_status_started-first-line', started.firstLine) }}
        </span>
        <span class="second-line">
          {{ $t('_web_status_finished-second-line', started.secondLine) }}
        </span>
      </template>
    </div>

    <!-- tournament finished -->
    <div v-if="statusNum === 3" class="d-flex flex-row">
      <div class="winner-finished">
        <span class="first-line">
          {{ $t('_web_status_finished-first-line', finished.firstLine) }}
        </span>
        <span class="second-line">
          {{ $t('_web_status_finished-second-line', finished.secondLine) }}
        </span>
        <!-- <TextBlock class="label-finished"
          >{{ $t('_web_status_finished', 'FINISHED') }}
        </TextBlock> -->
      </div>
    </div>
  </div>
</template>
<script>
import { propRequired } from '@/utils/factories';
// import TextBlock from '@/components/atoms/TextBlock.vue';
import joinAction from '@/views/tournaments/mixins/joinTournamentAction';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import tournamentHelpers from '@/mixins/tournaments/tournamentHelpers';
import matchHelper from '@/views/tournaments/management/brackets/mixins/matchHelper';
import Countdown from '@/components/common/CountdownCustom.vue';
import Button from '@/components/atoms/HeraFormBtn';
export default {
  name: 'ActionNew',
  components: {
    // TextBlock,
    Button,
    Countdown,
  },
  mixins: [joinAction, tournamentHelpers, matchHelper],
  props: {
    tournament: propRequired(Object),
  },
  data() {
    return {
      hasClicked: false,

      ammountOfRequested: 0,
      toManyRevokes: '',
      idForPayment: '',
      regOpen: {
        notJoined: 'JOIN',
        soloJoined: {
          announcment: 'download the app and be ready to play',
          firstLine: 'Thank you for registering',
          secondLine: 'you will be notified when you are qualified',
        },
        primaryNot: 'Join with the team',
        secondNot: 'Create a team',
        notSoloJoined: {
          announcment: 'download the app and be ready to play',
          firstLine: 'Thank you for registering',
          secondLine: 'you will be notified when you are qualified',
        },
      },
      LineUps: {
        NotJoined: {
          firstLine: 'Starting in ',
          secondLine: 'Registrations are closed.',
        },
        soloJoined: {
          qualified: {
            firstLine: 'Starting in ',
            secondLine: 'You are qualified! Get ready!',
          },
          notQualified:
            'Unfortunately, you have not qualified for this tournament.',
        },
        primaryNot: {
          firstLine: ' Starting in ',
          secondLine: 'Registrations are closed.',
        },
        notSoloJoined: {
          qualified: {
            firstLine: 'Starting in ',
            secondLine: 'You are qualified! Get ready!',
          },
          notQualifiedNew:
            'Registration is closed. Please wait for the qualification process to end to see if you are qualified or not.',
        },
      },
      started: {
        NotJoined: 'STARTED',
        firstLine: `You are not joined. `,
        secondLine: `Check out latest results.`,
        soloJoined: {
          label: 'STARTED',
          button: 'PLAY',
        },
        soloNotJoined: {
          label: 'STARTED',
          button: 'GO TO MATCH',
        },
      },
      finished: {
        label: 'FINISHED',
        firstLine: `Tournament finished.`,
        secondLine: `Check out latest results.`,
      },
      localStatus: null,
      PlayerCompStatus: null,
    };
  },
  computed: {
    ...mapGetters(['currentTournament', 'isAuthorized']),
    ...mapState('my', ['account', 'accountId']),

    ...mapGetters('my/clans', { myClans: 'clanList' }),
    status() {
      return this.currentTournament.status;
    },
    isSolo() {
      return this.currentTournament.squad_size < 2;
    },
    maxPlayers() {
      if (
        this.ammountOfRequested > 0 &&
        this.ammountOfRequested === this.currentTournament.squads
      ) {
        return false;
      } else {
        return true;
      }
    },

    statusNum() {
      const statusKey = this.currentTournament.status;
      const variantsStatus = {
        registration_opened: 0,
        registration_closed: 1,
        qualification_completed: 1,
        started: 2,
        closed: 3,
      };
      return variantsStatus[statusKey];
    },
    hasParticipantStatus() {
      return this.statusPlayer !== null && this.statusPlayer !== 'revoked';
    },
    statusPlayer() {
      return (
        this.localStatus ||
        (this.tournament.hasOwnProperty('participantStatus')
          ? this.tournament.participantStatus
          : this.tournament.participant_status)
      );
    },
    hasJoined() {
      return (
        this.currentTournament.participant_status === 'requested' ||
        this.currentTournament.participant_status === 'qualified' ||
        this.currentTournament.participant_status === 'Requested'
      );
    },
    paymentPending() {
      return this.currentTournament.participant_status === 'payment_pending';
    },
    hasJoineded() {
      return (
        this.currentTournament.participant_status === 'requested' &&
        this.currentTournament.status !== 'qualification_completed'
      );
    },
    hasQualified() {
      return this.currentTournament.participant_status === 'qualified';
    },
    showCountDown() {
      return (
        this.currentTournament.tournament_start * 1000 - Date.now() <=
        36000 * 1000
      );
    },
    qualifiedTextJoined() {
      return this.hasQualified
        ? this.qualified.split('.')
        : this.isSolo
        ? this.notQualifiedSolo
        : this.notQualifiedTeam;
    },
  },

  mounted() {
    this.participansTournament();
    this.statusOfPlayer();
  },
  methods: {
    ...mapMutations(['updateCurrentTournament']),
    ...mapActions([
      'getTournamentParticipants',
      'revokeForPaid',
      'getCompetitionPlayersStatus',
    ]),

    statusOfPlayer() {
      this.getCompetitionPlayersStatus({
        id: this.$route.params.tournamentID,
      }).then(res => {
        if (res && res.length) {
          this.PlayerCompStatus = { ...res[0] };
        }
      });
    },

    participansTournament() {
      this.getTournamentParticipants({
        id: this.$route.params.tournamentID,
      }).then(res => {
        //fetching proper data
        const allClans = this.myClans.map(item => item.name);
        const allParticipans = res.items.map(item => item.fields_data.username);

        //checking backend data return
        const normalizedClans = allClans.map(clan => clan.trim().toLowerCase());
        const normalizedParticipants = allParticipans.map(participant =>
          participant.trim().toLowerCase()
        );

        const allFound = normalizedClans.some(clan =>
          normalizedParticipants.includes(clan)
        );

        if (this.tournament.entry_fee.required === true) {
          this.ammountOfRequested = res.properties.total_requested;
          if (this.tournament.max_players !== 1 && allFound) {
            const foundMembers = allClans.filter(item =>
              allParticipans.includes(item)
            );

            const clanID = this.myClans.find(
              clan => clan.name === foundMembers[0]
            ).id;

            this.idForPayment = clanID;

            this.revokeForPaid({
              clan: clanID,
              tournament: this.tournament.id,
            }).catch(err => {
              this.toManyRevokes = err.message;
            });
          } else {
            this.revokeForPaid({
              clan: this.accountId,
              tournament: this.tournament.id,
            }).catch(err => {
              this.toManyRevokes = err.message;
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
template {
  font-family: $hr-font-family;
}

.two-button {
  gap: 8px;

  @media (max-width: 800px) {
    text-align: start;
    padding-left: 0px !important;
  }
}

.joinText {
  line-height: 22.4px !important;
}

.joinText:first-of-type {
  font-weight: 700;
}

.label-finished {
  text-align: center;
  align-items: center;
  width: 105px;
  padding: 8px;
  border-radius: 5px;
  border: 3px solid;
  font-weight: 700;
  border-color: whitesmoke;
}

.label-started {
  text-align: center;
  align-items: center;
  width: 105px;
  padding: 8px;
  border-radius: 5px;
  border: 3px solid;
  border-color: #46dc70;
  color: #46dc70;
  font-weight: 700;
}

.join-buttons {
  gap: 8px;
  justify-content: flex-end;

  @media (max-width: 800px) {
    justify-content: center;
  }
}

.team-button {
  width: 130px !important;
  height: 36px !important;
  background: #5a58f2 !important;
  border-radius: 5px !important;
  font-family: 'Exo' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  color: white !important;

  @media (max-width: 900px) {
    width: 110px !important;
  }

  &:hover {
    color: #6300fa !important;
  }
}
.compleat-button {
  width: 150px !important;

  background: #5a58f2 !important;
  border-radius: 5px !important;
  font-family: 'Exo' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  color: white !important;

  @media (max-width: 900px) {
    width: 150px !important;
  }

  &:hover {
    color: #6300fa !important;
  }
}

.clan-button {
  width: 125px !important;
  height: 36px !important;
  border-radius: 5px !important;
  font-family: 'Exo' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  background-color: #242938 !important;
  // color: #6300fa !important;
  color: #9d9cf7 !important;

  @media (max-width: 900px) {
    width: 105px !important;
  }
}

.playButton {
  width: 105px !important;
  height: 36px !important;
  background: #5a58f2 !important;
  border-radius: 5px !important;
  font-family: 'Exo' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  color: white !important;

  &:hover {
    background-color: #4542e0 !important;
  }
}

.counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $hr-text-regular !important;
  font-weight: 700;
  margin-bottom: 0;
}

.counter-wrap {
  display: flex;
  line-height: 22.4px !important;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.winner-finished {
  span {
    font-size: 1rem;
  }

  display: flex;
  gap: 7px;
  flex-direction: column;
}

.first-line {
  font-size: 1rem;
  font-weight: 700;
  line-height: 22.4px;

  @media (max-width: 800px) {
    display: inline;
    text-align: start;

    // text-align: center;
  }
}

.second-line {
  font-size: 1rem;
  font-weight: 400;
  line-height: 22.4px;

  @media (max-width: 800px) {
    display: flex;
    text-align: start;
    // text-align: center;
  }
}

.label-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.invite-only {
  font-size: 1.2rem;
  line-height: 22.4px;
  font-weight: 700;
}

.tournament-paid-entry {
  display: flex;
  gap: 7px;
  font-family: Exo;
  font-size: 18px;
  font-weight: 600;
  line-height: 27.9px;
  letter-spacing: -0.01em;
  text-align: center;
  align-self: center;
}
.aligment-line {
  align-self: center;
}
.gap-adjustment-payment {
  gap: 5px;
}

.tournament-join-wrapper {
  display: flex;
  gap: 10px;
}

.text-information {
  text-align: right !important;

  @media (max-width: 800px) {
    text-align: center !important;

    label {
      display: flex;
    }
  }
}

.mobile-view-text {
  @media (max-width: 800px) {
    text-align: start;
  }
}

@media (max-width: 800px) {
  .join-button-small {
    margin: auto;
  }
}

@media (max-width: 768px) {
  .app-dl {
    display: none;
  }
}
</style>
