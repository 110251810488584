import {
  CLAN,
  PROFILE,
  ORGANIZATION,
  MANAGE_TEAM,
  LIST,
} from '@/constants/accounts';

/**
 * This file contains paths to modal components over the app
 * in case of dynamic components these paths have compound names
 * from other constants like
 * `${PROFILE}_${COUNT_FOLLOWERS}`
 * All modal components will be imported dynamically with webpack
 * chunks splitting for minimizing initial bundle size
 */

export const modalComponents = {
  /**
   * Confirmation modal component
   * @return {Component} Imported component
   */
  HeraConfirm: () =>
    import(/* webpackChunkName: "ModalComponent-HeraConfirm" */
    '@/components/modal/children/HeraConfirm.vue'),
  /**
   * Confirmation leave modal component
   * @return {Component} Imported component
   */
  CancelSubscription: () =>
    import(/* webpackChunkName: "ModalComponent-HeraConfirm" */
    '@/components/modal/children/CancelSubscription.vue'),
  /**
   * Confirmation leave modal component
   * @return {Component} Imported component
   */
  FormReport: () =>
    import(/* webpackChunkName: "ModalComponent-FormReport" */
    '@/components/modal/children/FormReport.vue'),
  /**
   * Confirmation leave modal component
   * @return {Component} Imported component
   */
  HeraConfirmLeave: () =>
    import(/* webpackChunkName: "ModalComponent-HeraConfirmLeave" */
    '@/components/modal/children/HeraConfirmLeave.vue'),
  /**
   * Confirmation on copy action error
   * @return {Component} Imported component
   */
  HeraConfirmCopyLinkError: () =>
    import(/* webpackChunkName: "ModalComponent-HeraConfirmLeave" */
    '@/components/modal/children/HeraConfirmCopyLinkError.vue'),

  /**
   * Tournament rules modal
   * @return {Component} Imported component
   */
  TournamentRules: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/components/modal/children/TournamentRulesModal.vue'),

  /**
   * Swiss calculator modal component
   * @return {Component} Imported component
   */
  TournamentSwissCalculatorModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/views/tournaments/management/singleTournament/stages/components/SettingsStageSwissCalculatorHelper.vue'),

  /**
   * Announce tournament modal component
   * @return {Component} Imported component
   */
  TournamentAnnounceModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/components/modal/children/TournamentAnnounceModal.vue'),
  /**
   * Please Sign In model component
   * @return {Component} Imported component
   */
  // PleaseSignIn: () =>
  //   import(/* webpackChunkName: "ModalComponent-HeraConfirm" */
  //   '@/components/modal/children/PleaseSignIn.vue'),
  //ovdje je PleaseSignIn importan  u modal mozda ga se moze samo ovdje isključit
  /**
   * Announce tournament modal component
   * @return {Component} Imported component
   */
  SignInModal: () =>
    import(/* webpackChunkName: "ModalComponent-SignInModal" */
    '@/components/modal/children/SignInModal.vue'),
  SignUpModal: () =>
    import(/* webpackChunkName: "ModalComponent-SignUpModal" */
    '@/components/modal/children/SignUpModal.vue'),
  CodeVerifyModal: () =>
    import(/* webpackChunkName: "ModalComponent-CodeVerifyModal" */
    '@/components/modal/children/CodeVerifyModal.vue'),
  AccountCreatedModal: () =>
    import(/* webpackChunkName: "ModalComponent-AccountCreatedModal" */
    '@/components/modal/children/AccountCreatedModal.vue'),
  PasswordResetModal: () =>
    import(/* webpackChunkName: "ModalComponent-PasswordResetModal" */
    '@/components/modal/children/PasswordResetModal.vue'),
  PasswordSubmitModal: () =>
    import(/* webpackChunkName: "ModalComponent-PasswordSubmitModal" */
    '@/components/modal/children/PasswordSubmitModal.vue'),

  PlayerRegistrationInfo: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/views/tournaments/management/participants/components/PlayerRegistrationInfoModal.vue'),
  /**
   * Modal component for changing round schedule
   * @return {Component} Imported component
   */
  RoundSchedulingModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/views/tournaments/management/brackets/components/RoundSchedulingModal.vue'),
  /**
   * Modal component for changing match schedule
   * @return {Component} Imported component
   */
  TeamJoinOptionsModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/views/tournaments/components/TeamJoinOptionsModal.vue'),

  /**
   * Modal component for rules agree confirmation
   * @return {Component} Imported component
   */
  RulesConfirmModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/views/account/header/statusBar/default/RulesConfirmModal.vue'),

  /**
   * Modal component for changing match schedule
   * @return {Component} Imported component
   */
  MatchSchedulingModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentModal" */
    '@/views/tournaments/management/brackets/components/MatchSchedulingModal.vue'),
  /**
   * Modal component for sending Messages to whole Round
   * @return {Component} Imported component
   */
  SigninActionModal: () =>
    import(/* webpackChunkName: "ModalComponent-signinActionModal" */
    '@/components/modal/children/SigninActionModal.vue'),

  /**
   * Modal component for signIn Messages to whole Round
   * @return {Component} Imported component
   */
  SendRoundMessageModal: () =>
    import('@/components/modal/children/sendRoundMessageModal.vue'),
  // /**
  //  * Modal component for editing match
  //  * @return {Component} Imported component
  //  */
  // EditResultsModal: () =>
  //   import(/* webpackChunkName: "ModalComponent-TournamentModal" */
  //   '@/views/tournaments/management/brackets/components/EditResultsModal.vue'),
  // /**
  //  * Modal component for editing match
  //  * @return {Component} Imported component
  //  */
  // EditResultsModal_v2: () =>
  //   import(/* webpackChunkName: "ModalComponent-TournamentModal" */
  //   '@/views/tournaments/management/brackets/components/EditResultsModal_v2.vue'),
  /**
   * Pause tournament modal component
   * @return {Component} Imported component
   */
  TournamentPauseModal: () =>
    import(/* webpackChunkName: "ModalComponent-TournamentAnnounceModal" */
    '@/components/modal/children/TournamentPauseModal.vue'),
  /**
   * Social integration settings modal component
   * @return {Component} Imported component
   */
  SocialIntegrationModal: () =>
    import(/* webpackChunkName: "ModalComponent-SocialIntegration" */
    '@/views/account/content/edit/panels/LinkSocialsModal.vue'),
  /**
   * Remove account modal component
   * @return {Component} Imported component
   */
  RemoveAccountModal: () =>
    import(/* webpackChunkName: "ModalComponent-RemoveAccount" */
    '@/views/account/content/edit/panels/RemoveAccountModal.vue'),
  /**
   * Invite to tournament modal component
   * @return {Component} Imported component
   */
  InviteToTournament: () =>
    import(/* webpackChunkName: "ModalComponent-InviteToTournament" */
    '@/views/tournaments/management/invites/TournamentsListForInvites.vue'),
  /**
   * Translate chat modal component
   * @return {Component} Imported component
   */
  TranslateChatPopup: () =>
    import(/* webpackChunkName: "ModalComponent-TranslateChatPopup" */
    `@/views/conversations/correspondence/TranslateChatPopup.vue`),
  // account statusBar
  /**
   * Clan followers modal component
   * @return {Component} Imported component
   */
  [`${CLAN}_${LIST}`]: () =>
    import(/* webpackChunkName: "ModalComponent-clan-list" */
    '@/views/account/lists/clan'),
  /**
   * Profile followers modal component
   * @return {Component} Imported component
   */
  [`${PROFILE}_${LIST}`]: () =>
    import(/* webpackChunkName: "ModalComponent-profile-list" */
    '@/views/account/lists/profile'),
  /**
   * Profile followers modal component
   * @return {Component} Imported component
   */
  [`${ORGANIZATION}_${LIST}`]: () =>
    import(/* webpackChunkName: "ModalComponent-organization-list" */
    '@/views/account/lists/organization'),
  /**
   * Team members modal component
   * @return {Component} Imported component
   */
  [`${CLAN}_${MANAGE_TEAM}`]: () =>
    import(/* webpackChunkName: "ModalComponent-clan-manage-team" */
    '@/views/account/content/teams/clanTeams/createTeamMembers'),

  /**
   * Clan invite members modal component
   * @return {Component} Imported component
   */
  ClanInvite: () =>
    import(/* webpackChunkName: "ModalComponent-ClanInvite" */
    '@/views/clans/create/inviteMembers'),

  /**
   * Clan leave modal component
   * @return {Component} Imported component
   */
  ClanLeaveConfirm: () =>
    import(/* webpackChunkName: "ModalComponent-ClanLeaveConfirm" */
    '@/views/account/header/statusBar/default/ClanLeaveConfirm'),

  /**
   * Change Password modal component
   * @return {Component} Imported component
   */
  ChangePassword: () =>
    import(/* webpackChunkName: "ModalComponent-ChangePassword" */
    '@/views/account/forms/ChangePassword'),

  /**
   * Provide results modal component
   * @return {Component} Imported component
   */
  ProvideBattleResults: () =>
    import(/* webpackChunkName: "ModalComponent-ProvideBattleResults" */
    '@/views/account/content/results/ProvideBattleResults'),

  /**
   * Checkout subscription modal component
   * @return {Component} Imported component
   */
  CheckoutCard: () =>
    import(/* webpackChunkName: "ModalComponent-CheckoutCard" */
    '@/views/subscription/CheckoutCard'),

  CheckoutFort: () =>
    import(/* webpackChunkName: "ModalComponent-CheckoutFort" */
    '@/views/subscription/CheckoutCardPayFort'),

  MobileProvide: () =>
    import(/* webpackChunkName: "ModalComponent-CheckoutCard" */
    '@/views/shop/components/ProvideMobile'),

  EmailProvide: () =>
    import(/* webpackChunkName: "ModalComponent-CheckoutCard" */
    '@/views/shop/components/ProvideEmail'),

  CheckoutShop: () =>
    import(/* webpackChunkName: "ModalComponent-CheckoutShop" */
    '@/views/shop/components/CheckoutShop'),

  CheckoutTournament: () =>
    import(/* webpackChunkName: "ModalComponent-CheckoutTournament" */
    '@/views/shop/components/CheckoutTournament'),

  ParticipansModal: () =>
    import(/* webpackChunkName: "ModalComponent-ParticipansModal" */
    '@/views/tournaments/list/Pinned/components/ParticipansModal.vue'),
};
export const ALERT_TYPES = {
  CONFIRM: 1,
};

export const ALERT_COMPONENTS = {
  [ALERT_TYPES.CONFIRM]: modalComponents.HeraConfirm,
};
