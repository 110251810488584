var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.accountEditData)?_c('div',{staticClass:"clan-create-details w-100",attrs:{"id":"create_clan"}},[_c('div',{staticClass:"d-flex justify-content-between my-5 px-5 align-items-center"},[_c('Subheader',{staticClass:"secondary-font",attrs:{"text":_vm.headerForm}}),_c('div',{class:'navigation'},[_vm._v("\n      "+_vm._s(_vm.$route.params.tournamentID
          ? _vm.$t('_web_create-clan-join_submit-button', 'Create and join')
          : _vm.hasClanStub
          ? _vm.$t('_web_create-clan_create', 'Create') + ' 1/2'
          : _vm.$t('_web_profile-edit_update', 'Update'))+"\n    ")])],1),_c('div',[_c('AccountCoverEdit'),_c('AccountEditClan',{staticClass:"mt-2"})],1),_c('CreatedPopup',_vm._b({},'CreatedPopup',{
      account: _vm.account,
      redirectTo: _vm.redirectTo,
      subTitle: _vm.subTitle,
      isVisible: _vm.congratulationPopupIsVisible,
    },false)),_c('div',{staticClass:"create-clan-btn"},[_c('HeraFormSubmitBtn',{class:'btn-next',attrs:{"label":_vm.$route.params.tournamentID
          ? _vm.$t('_web_create-clan-join_submit-button', 'Create and join')
          : _vm.hasClanStub
          ? _vm.$t('_web_create-clan_create-next', 'NEXT')
          : _vm.$t('_web_profile-edit_update', 'Update'),"loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.save($event)}}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }