<template>
  <div class="market-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MarketLayout',
};
</script>
