<template>
  <!--
      Example
      <ImageUpload
        v-model="url"
        required
        :label="$t('_web_label', 'Cover: ')"
        v-validate="'required'"
        data-vv-name="cover"
        :error="errors.first('cover')"
      />
  -->
  <WrapperFormControl
    :id="imageID"
    :required="required"
    :view-mode="disabled"
    :error="errors.first(imageID)"
    :label="label"
    :hide-label="hideLabel"
    :tooltip="tooltip"
  >
    <template slot="control">
      <EmbedBlock
        :embed="{ width, height }"
        class="upload-image-wrapper"
        :class="{ disabled, 'is-loading': isLoading }"
        @drop.native="onImgDrop"
      >
        <InputFile
          :id="imageID"
          v-model="imageURL"
          v-validate="required ? 'required' : ''"
          :data-vv-name="imageID"
          :output-as="outputAs"
          :accept="formats"
          :name="imageID"
          :is-required="required"
          :disabled="disabled"
          :placeholder="placeholder"
          :class="{ 'is-empty': !imageURL }"
          class="upload-image-input"
          :drop-file-data="dropFileData"
          :hide-label="hideLabel"
          @filesSelected="isLoading = true"
          @filesUploaded="onImgUploaded"
          @uploadError="onFileError"
        >
          <template slot="placeholder">
            <slot name="placeholder" />
          </template>
          <TextBlock slot="help" class="text-muted mt-3" :size="0.625">
            {{ help }}
          </TextBlock>
        </InputFile>
        <Icon
          v-if="imageURL && !isLoading"
          width="20"
          class="upload-image_delete cursor-pointer p-3"
          name="trash"
          :fill="$scss.iconPrimaryColor"
          hover-icon="trash-active"
          :hover-fill="$scss.iconPrimaryColor"
          @click="onClearField"
        />
        <div class="upload-image-preview">
          <img
            v-if="imageURL && type === 'image'"
            v-bind="{ src: imageURL }"
            :style="{ 'object-fit': previewType }"
            alt="image preview"
          />
          <VideoPreview
            v-if="imageURL && type === 'video'"
            class="video-preview cursor-pointer"
            :src="imageURL"
          />
        </div>
      </EmbedBlock>
    </template>
  </WrapperFormControl>
</template>

<script>
const ACCEPTED_IMAGES = [
  'png',
  'bmp',
  'jpg',
  'jpeg',
  'PNG',
  'BMP',
  'JPG',
  'JPEG',
];

import { mapActions } from 'vuex';
import EmbedBlock from '@/components/common/EmbedBlock';
import TextBlock from '@/components/atoms/TextBlock';
import InputFile from '@/components/form/HeraFormInputFile';
import { prop } from '@/utils/factories';
import Icon from '@/components/atoms/Icon';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
import VideoPreview from '@/components/atoms/VideoPreview';

export default {
  name: 'ImageUpload',
  components: {
    TextBlock,
    WrapperFormControl,
    EmbedBlock,
    InputFile,
    Icon,
    VideoPreview,
  },
  inject: ['$validator'],
  props: {
    value: prop(String),
    label: prop(String),
    name: prop(String),
    type: prop(String, 'image'),
    help: prop(String),
    tooltip: prop(String),
    placeholder: prop(String),

    required: prop(Boolean),
    outputAs: prop(String, 'binary'),
    formats: prop(Array, () => ACCEPTED_IMAGES),

    previewType: prop(String),
    width: prop(Number, 300),
    height: prop(Number, 300),
    disabled: prop(Boolean),
    hideLabel: prop(Boolean),
  },
  data() {
    return {
      dropFileData: null,
      isLoading: false,
    };
  },
  computed: {
    imageURL() {
      return this.value;
    },
    imageID() {
      return this.name || this.$lodash.uniqueId('image_');
    },
  },
  methods: {
    ...mapActions(['errorNotify']),
    onImgUploaded(filesInfo) {
      this.isLoading = false;
      this.$emit('input', filesInfo[0]);
      this.errors.remove(this.imageID);
    },
    onFileError() {
      this.onClearField();
      this.errorNotify('_web_upload-image-fail-loading', 'Failed loading');
    },
    onClearField() {
      this.onImgUploaded([null]);
    },
    onImgDrop(e) {
      if (!this.disabled) {
        this.dropFileData = e;
      }
    },
  },
};
</script>

<style lang="scss">
.upload-image-wrapper {
  position: relative;
  border-radius: $ph-large-radius;

  .upload-image-input {
    position: absolute !important;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    &.is-empty {
      background-image: url('~@/assets/images/background/upload-placeholder.png');
    }
    &:not(.is-empty) {
      opacity: 0;
    }
  }

  &:hover,
  &.is-loading {
    .upload-image-input {
      opacity: 0.9;
    }
  }

  .upload-image-preview {
    border: 1px solid $ph-secondary-text;
    border-radius: $ph-large-radius;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 5;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    > * {
      height: 100%;
    }
  }
  .upload-image_delete {
    position: absolute;
    top: 0;
    @include auto-rtl(right, 0);
    z-index: 6;
  }

  &.disabled * {
    pointer-events: none;
    .upload-image-input {
      z-index: 6;
    }
  }
}
</style>
