<template>
  <div class="layout-wrapper auth-layout-wrapper">
    <div class="auth-modal-container">
      <div class="auth-img">
        <div class="auth-left">
          <div class="auth-left-text">
            <img
              class="ph-logo-auth"
              src="@/assets/images/header/logo.svg"
              alt="playhera-logo"
            />
            <h1 class="ph-title-one">
              {{
                $t('_web_login_hero_phrase', 'Your gateway to the gaming world')
              }}
            </h1>
          </div>
        </div>
      </div>
      <div class="auth-right">
        <section class="view-content-wrapper d-flex flex-column flex-grow-1">
          <CloseBtn class="back-btn" history-mode hide-icon />

          <div class="d-flex slot-flex align-items-center w-100">
            <slot class="pt-5"></slot>
          </div>
          <!--  <div class="orOauth">
            <span>{{ $t('_web_or', 'Or') }}</span>
          </div> -->
          <div v-if="isMenaRegion" class="poweredBy">
            <img
              src="@/assets/images/general-sponsors/zain.png"
              alt="Zain Logo"
              style="height: auto; width: auto;"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import CloseBtn from '@/components/common/CloseBtn';

export default {
  name: 'AuthLayout',
  components: {
    CloseBtn,
  },
  computed: {
    showGoBackBtn() {
      return false;
      // const { name } = this.$route;
      // return (
      //   name === 'signup' || name === 'passwordReset' || name === 'verifyCode'
      // );
    },
    isMenaRegion() {
      const region = JSON.parse(localStorage.getItem('region'));
      return region.id == 'mena';
    },
  },
  created() {
    this.$store.dispatch('fetchCallingCodes').then(() => {
      this.$store.commit('countriesFetched');
    });
  },
  updated() {
    let animation = document.getElementById('main-animation');
    if (animation) document.body.removeChild(animation);
  },
};
</script>
<style lang="scss">
.auth-modal-container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  font-family: $ph-font-family-secondary;
  overflow: hidden;
}

.view-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $new-bg-page;
  width: 25vw;
  min-width: 380px;
  gap: 0.5rem;

  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
}

.auth-img {
  background-image: url('../assets/images/auth/authModal.jpg');
  width: 50%;
  background-size: cover;
  background-position: center;
  @media (max-width: 980px) {
    display: none;
  }
}

.close-btn-auth {
  width: 100%;
}

.back-btn {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  padding: 1rem;
  @media (max-width: 540px) {
    padding-right: 0rem;
  }
  @media (max-height: 700px) {
    padding-top: 0rem;
  }
}

.auth-left {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  @media (max-width: 980px) {
    display: none;
  }
}

.auth-left-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.ph-title-one {
  font-weight: 400;
  font-size: 2rem;
  padding-top: 1.5rem;
  text-align: center;
}

.auth-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .slot-flex {
    padding-top: 1rem;
    @media (max-width: 540px) {
      padding-top: 3rem;
    }
  }
  background-color: $new-bg-page;
  @media (max-width: 1250px) {
    width: 60%;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
}

.orOauth {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding-top: 1rem;
}

.poweredBy {
  position: absolute;
  bottom: 0;
  align-self: center;
  display: flex;
  justify-content: center;
  color: $hr-text-tertiary;
  font-size: 14px;
  padding-bottom: 1rem;
  @media (max-height: 700px) {
    padding: 0;
  }
}

.auth-layout-wrapper {
  min-height: 100vh;
  height: 100%;
  .hera-logo--main {
    z-index: 4;
    top: 0;
    @include auto-rtl(left, 0);
    opacity: 0.9;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
  }
  .main-wrapper {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    max-width: 580px;
  }
  .auth-back-btn-wrapper {
    width: 0;
    z-index: 2;
  }

  .ph-logo-auth {
    height: 2.5rem;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px $new-bg-base inset !important;
      -webkit-text-fill-color: $new-fg-neutral-faded !important;
    }
  }
  input::placeholder {
    color: $new-fg-neutral-faded;
  }
  label {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 17px */
    color: $new-fg-neutral;
  }
  form {
    h1 {
      font-size: 2em;
      line-height: 1.20833em;
    }
  }
  @media (max-width: 575px) and (orientation: landscape) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 576px) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 768px) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 576px) and (max-width: 825px) and (orientation: landscape) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 992px) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 1200px) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 1441px) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
  @media (min-width: 1921px) {
    .main-wrapper {
      height: min-content;
      min-height: 100vh;
    }
  }
}
</style>
