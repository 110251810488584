<template>
  <div
    v-if="indicators"
    class="widget-indicators-wrapper d-flex"
    :class="{
      'widget-indicators-wrapper--numbers-only': numbersOnly,
    }"
  >
    <PerformanceIndicator
      v-for="(indicator, index) in indicators"
      :key="index"
      :text="indicator.label"
      :current="indicator.current"
      :limit="indicator.limit"
      :current-only="!indicator.fraction"
      :no-bar="numbersOnly"
      class="widget-indicator"
    />
  </div>
</template>

<script>
import { prop } from '@/utils/factories';
import PerformanceIndicator from './PerformanceIndicator';

export default {
  name: 'SetOfPerformanceIndicators',
  components: {
    PerformanceIndicator,
  },
  props: {
    indicators: prop(Array, null),
    numbersOnly: prop(Boolean),
  },
};
</script>

<style lang="scss">
.widget-indicators-wrapper {
  .widget-indicator:not(:first-child) {
    @include auto-rtl(margin-left, $ph-large-space);
  }
  &--numbers-only {
    .widget-indicator {
      .performance-indicator-line {
        width: 0px !important;
        @include auto-rtl(margin-right, 0 !important);
      }
    }
  }
}
</style>
