import dateMethods from '@/mixins/dateMethods';
import isLoading from '@/mixins/isLoading';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import {
  TOURNAMENT_STATUSES,
  STATUSES,
  CONSTRUCTOR_MODES,
} from '@/constants/tournaments';

export const tournamentHelpers = {
  mixins: [...dateMethods, isLoading],
  inject: ['$validator'],
  data() {
    return {
      loadingStageID: null,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournament',
      'currentTournamentStages',
      'isSingle',
      'isSingleStage',
      'getBlocksByStageID',
      'canDraft',
      'isScreenLocked',
      ...STATUSES.map(i => i.getter),
    ]),
    ...mapState('my', ['account', 'accountId']),

    tournamentID() {
      return this.$route.params.tournamentID || this.currentTournament.id;
    },
    tournamentStart() {
      return this.secondsToDatetime(
        this.currentTournament.schedule.tournament_start
      );
    },
    isTournamentStartsInThePast() {
      const now = this.getNow();
      const tournamentStart = this.tournamentStart;
      return tournamentStart ? now >= this.tournamentStart : false;
    },
    isOneBlockStageTournament() {
      return (
        this.isSingleStage &&
        this.getBlocksByStageID(this.currentTournamentStages[0].id).length === 1
      );
    },
  },
  methods: {
    ...mapMutations([
      'updateCurrentTournament',
      'updateStageStatus',
      'backupCurrentTournament',
      'setTournamentBlocks',
      'updateModalProps',
    ]),
    ...mapActions([
      'publishTournament',
      'announceTournament',
      'openRegTournament',
      'closeRegTournament',
      'rejectTournament',
      'restartTournament',
      'setTournamentCompleteQualification',
      'archiveTournament',
      'toggleLockScreen',
      'createTournament',
      'pauseTournament',
      'reopenTournament',
      'updateTournament',
      'fetchTournamentDraft',
      'fetchTournamentStages',
      'fetchTournamentBlocks',
      //stages
      'fetchTournamentStage',
      'createTournamentStage',
      'updateTournamentStage',
      'publishStage',
      'startStage',
      'unPublishStage',
      'successNotify',
      'errorNotify',
    ]),

    onPublishTournament() {
      if (!this.isTournamentStartsInThePast) {
        this.errorNotify(
          this.$t(
            '_web_tournament-error-invalid-start-time',
            'Invalid tournament start time'
          )
        );
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      this.publishTournament(this.tournamentID)
        .then(() =>
          this.successNotify(
            this.$t(
              '_web_tournament-published-message',
              'Tournament has been published'
            )
          )
        )
        .catch(this.errorNotify)
        .then(() => {
          this.isLoading = false;
        });
    },

    validateCurrentForm() {
      this.errors.clear();
      return this.$validator
        .validateAll()
        .then(result => {
          const errorsCount = this.errors.count();
          return result && errorsCount === 0;
        })
        .then(isValid => {
          if (isValid) {
            return Promise.resolve();
          } else {
            const $firstErrorInput = this.$el.querySelector(
              '[aria-invalid=true]'
            );
            if ($firstErrorInput) {
              $firstErrorInput.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }
            return Promise.reject(
              this.$t('_web_form-validation-failed', 'Form validation failed')
            );
          }
        });
    },

    onUpdateStage(stageID) {
      const promise = stageID
        ? this.updateTournamentStage()
        : this.createTournamentStage();
      return this.promiseHandler(promise, null, true);
    },

    onUpdateTournament(lockScreen = true, withAnounceModal = true) {
      const createNew = this.canDraft;
      const redirectRouteName = 'TournamentDashboard';
      const method = createNew ? this.createTournament : this.updateTournament;
      this.isLoading = true;
      return this.toggleLockScreen(lockScreen)
        .then(method)
        .then(id => {
          if (!createNew) {
            this.successNotify(
              this.$t(
                '_web_tournament-save-updates-message',
                'Updates are successfully saved'
              )
            );
            this.$router.push({
              name: redirectRouteName,
              params: {
                tournamentID: id,
              },
            });
          }
          return id;
        })
        .then(id => {
          return createNew
            ? Promise.resolve().then(() => {
                this.$router.push({
                  name: redirectRouteName,
                  params: {
                    tournamentID: id,
                  },
                });
                if (
                  withAnounceModal &&
                  typeof this.showAnnounceModal === 'function'
                ) {
                  this.toggleLockScreen(false);
                  this.showAnnounceModal(this.onAnnounceTournament.bind(this));
                }
              })
            : this.fetchTournamentDraft(id).then(id => {
                this.backupCurrentTournament();
                // this.$router.push({
                //   name: redirectRouteName,
                //   params: {
                //     tournamentID: id,
                //   },
                // });
                return id;
              });
        })
        .finally(() => {
          this.isLoading = false;
          if (this.isScreenLocked) {
            this.toggleLockScreen(false);
          }
        });
    },

    promiseHandler(
      promise,
      notificationText,
      skipErrorNotify = false,
      bypassError = true
    ) {
      this.isLoading = true;
      this.updateModalProps({ isLoading: true });
      return promise
        .then(() => this.successNotify(notificationText))
        .catch(error => {
          if (!skipErrorNotify) {
            this.errorNotify(error);
          }
          if (bypassError) {
            throw error;
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.updateModalProps({ isLoading: false });
          this.loadingStageID = null;
        });
    },
    showAnnounceConfirm() {
      this.openModal({
        name: 'TournamentAnnounceModal',
        props: {
          text: this.$t(
            '_web_tournament_announce_tournament_confirm',
            'Do you really want to announce the tournament and publish it in PLAYHERA?'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onAnnounceTournament.bind(null),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },

    onAnnounceTournament() {
      this.closeModal();
      return this.promiseHandler(
        this.announceTournament(this.tournamentID),
        this.$t(
          '_web_tournament-announced-message',
          'Tournament is successfully announced'
        )
      ).finally(
        window.dataLayer.push({
          event: 'tournament announc',
          userID: this.account.name,
          from: this.$route.path,
          tournamentId: this.currentTournament.id,
          gameName: this.currentTournament.game,
          squadSize: this.currentTournament.squad_size,
          tournamentName: this.currentTournament.title,
          tournamentFormat: this.currentTournamentStages[0].block_configuration
            .type,
          tournamentCountry: this.currentTournament.country,
          tournamentPool: this.currentTournament.prize.prize_pool,
          regForm: this.currentTournament.requires_registration_form,
          tournamentPrivate:
            this.currentTournament.registration_type === 'closed'
              ? 'true'
              : 'false',
          tournamentOrganizer: this.currentTournament.event_organizer_name,
        })
      );
    },
    showStartRegistrationConfirm() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament_start_registration_confirm',
            'Do you really want to start registration before the scheduled time?'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onRegistrationOpen.bind(null),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    onRegistrationOpen() {
      this.closeModal();
      return this.promiseHandler(
        this.openRegTournament(this.tournamentID),
        this.$t(
          '_web_tournament-registration-opened-message',
          'Registration is successfully opened'
        )
      );
    },
    showCloseRegistrationConfirm() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament_close_registration_confirm',
            'Do you really want to complete a registration before scheduled time? Users will not be able to register after'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onRegistrationClose.bind(null),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    onRegistrationClose() {
      this.closeModal();
      return this.promiseHandler(
        this.closeRegTournament(this.tournamentID),
        this.$t(
          '_web_tournament-registration-closed-message',
          'Registration is successfully closed'
        )
      );
    },
    showCompleteQualifyConfirm() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament_complete_qualification_confirm',
            'Do you really want to complete qualification? This action will notify users about their qualification status'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onCompleteQualification.bind(null),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    onCompleteQualification() {
      this.closeModal();
      return this.promiseHandler(
        this.setTournamentCompleteQualification()
          .then(() =>
            this.updateCurrentTournament({
              status: TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED,
            })
          )
          .finally(
            window.dataLayer.push({
              event: 'tournament complete qualify',
              userID: this.account.name,
              from: this.$route.path,
              tournamentId: this.currentTournament.id,
              gameName: this.currentTournament.game,
              squadSize: this.currentTournament.squad_size,
              tournamentName: this.currentTournament.title,
              tournamentFormat: this.currentTournamentStages[0]
                .block_configuration.type,
              tournamentCountry: this.currentTournament.country,
              tournamentPool: this.currentTournament.prize.prize_pool,
              regForm: this.currentTournament.requires_registration_form,
              tournamentPrivate:
                this.currentTournament.registration_type === 'closed'
                  ? 'true'
                  : 'false',
              tournamentOrganizer: this.currentTournament.event_organizer_name,
            })
          ),
        this.$t(
          '_web_tournament-qualification-completed-message',
          'Qualification is successfully completed - players are notified and brackets are built'
        )
      );
    },

    onTournamentStart() {
      //start first stage
      return this.promiseHandler(
        this.startStage({
          tournamentID: this.tournamentID,
          stageID: this.$lodash.get(this, 'currentTournamentStages[0].id'),
        }).then(() =>
          this.updateCurrentTournament({
            status: TOURNAMENT_STATUSES.STARTED,
          })
        ),
        this.$t('_web_tournament-started-message', 'Started')
      );
    },

    onTournamentRestart(tournamentID = this.tournamentID) {
      return this.promiseHandler(
        this.restartTournament(tournamentID),
        this.$t(
          '_web_tournament-restart-message',
          'Tournament has been moved to draft'
        )
      );
    },
    refetchTournamentData(tournamentID = this.tournamentID) {
      return Promise.all([
        this.fetchTournamentDraft(tournamentID),
        this.fetchTournamentStages(tournamentID),
      ])
        .then(() => {
          return this.currentTournamentStages.map(stage => {
            return this.fetchTournamentBlocks({
              stage: stage.index,
            }).then(blocks => {
              this.setTournamentBlocks(blocks);
            });
          });
        })
        .then(blocksPromises =>
          Promise.all(blocksPromises).catch(this.errorNotify)
        );
    },
    onTournamentPause(tournamentID = this.tournamentID) {
      return this.promiseHandler(
        this.pauseTournament(tournamentID)
          .then(() => this.refetchTournamentData())
          .then(() => {
            this.$router.push({
              name: 'multiStage',
              params: {
                ...this.$route.params,
                constructorMode: CONSTRUCTOR_MODES.PUBLISH,
              },
            });
          }),
        this.$t(
          '_web_tournament-pause-message',
          'Tournament has been moved to qualification complete status'
        )
      );
    },

    onTournamentReopen(tournamentID = this.tournamentID) {
      return this.promiseHandler(
        this.reopenTournament(tournamentID).then(() =>
          this.refetchTournamentData()
        ),
        this.$t(
          '_web_tournament-reopen-message',
          'Tournament has been reopened'
        )
      );
    },

    onTournamentArchive(tournamentID = this.tournamentID) {
      return this.promiseHandler(
        this.archiveTournament(tournamentID),
        this.$t(
          '_web_tournament-archive-message',
          'Tournament has been moved to archive'
        )
      );
    },

    onTournamentReject(tournamentID = this.tournamentID) {
      return this.promiseHandler(
        this.rejectTournament({
          tournamentID,
          reason: this.$t(
            '_web_tournament_default-reject-reason',
            `Tournament was rejected by it's organizer`
          ),
        }),
        this.$t(
          '_web_tournament-reject-message',
          'Tournament has been unpublished'
        )
      );
    },
    // TODO think about moving into tournamentModals mixin
    showModalPublish(stageID) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_stage_publish_modal_title',
            'Do you really want to publish bracket and notify players about this?'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onPublishStage.bind(null, stageID),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    showModalUnPublish(stageID) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_stage_unpublish_modal_title',
            'This action will unpublish current stage. Do you want to continue?'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onUnPublishStage.bind(null, stageID),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    showModalStart(stageID) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_stage_start_modal_title',
            'This action will start current stage. Do you want to continue?'
          ),
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onStartStage.bind(null, stageID),
        },
        settings: {
          clickOnBackdrop: false,
          escKey: false,
        },
      });
    },
    onStartStage(stageID) {
      this.loadingStageID = stageID;
      this.closeModal();
      return this.promiseHandler(
        this.startStage({
          tournamentID: this.tournamentID,
          stageID: stageID,
        })
          .then(() => {
            this.updateStageStatus({
              stageID: stageID,
              status: 'started',
            });
          })
          .then(this.refetchTournamentData),
        this.$t('_web_tournament-stage-started-message', 'Stage started'),
        false,
        false
      );
    },
    onPublishStage(stageID) {
      this.loadingStageID = stageID;
      this.closeModal();
      return this.promiseHandler(
        this.publishStage({
          tournamentID: this.tournamentID,
          stageID: stageID,
        }).then(() => {
          this.updateStageStatus({
            stageID: stageID,
            status: 'published',
          });
        }),
        this.$t(
          '_web_tournament-stage-published-message',
          'Stage published - bracket is successfuly built'
        ),
        false,
        false
      );
    },
    onUnPublishStage(stageID) {
      this.loadingStageID = stageID;
      this.closeModal();
      return this.promiseHandler(
        this.unPublishStage({
          tournamentID: this.tournamentID,
          stageID: stageID,
        }).then(() => {
          this.updateStageStatus({
            stageID: stageID,
            status: 'draft',
            statusBlock: 'scheduled',
          });
        }),
        this.$t(
          '_web_tournament-stage-unpublished-message',
          'Stage unpublished'
        ),
        false,
        false
      );
    },
  },
};

export const tournamentModals = {
  methods: {
    ...mapActions(['openModal', 'closeModal']),

    showDiscardModal({ customText, confirm, cancel }) {
      this.showConfirmationModal({
        text:
          customText ||
          this.$t(
            '_web_tournament_discard_modal-changes',
            'Oops! Something went wrong. Do you want to discard changes?'
          ),
        cancelButtonText: this.$t(
          '_web_tournament_discard_modal-cancel-btn',
          'Cancel'
        ),
        confirmButtonText: this.$t(
          '_web_tournament_discard_modal-discard-btn',
          'Discard Changes'
        ),
        confirm,
        cancel,
      });
    },

    showSaveModal(confirm, cancel) {
      this.showConfirmationModal({
        text: this.$t(
          '_web_tournament_confirm-save-changes',
          'Do you want to save the changes?'
        ),
        cancelButtonText: this.$t('_web_tournament_confirm-leave', 'Leave'),
        confirmButtonText: this.$t('_web_tournament_confirm-save', 'Save'),
        confirm,
        cancel,
      });
    },

    showAnnounceModal(confirm) {
      this.showConfirmationModal({
        modalName: 'TournamentAnnounceModal',
        title: this.$t(
          '_web_tournament_congratulations_modal-title',
          'Congratulations!'
        ),
        text: this.$t(
          '_web_tournament_congratulations_modal-text',
          'Your tournament is created. You can announce it right now or proceed with bracket setup.'
        ),
        confirmButtonText: this.$t(
          '_web_tournament_congratulations_modal-announce',
          'Announce Now'
        ),
        cancelButtonText: this.$t(
          '_web_tournament_congratulations_modal-keep-draft',
          'Keep Draft'
        ),
        confirm,
        screenLocked: false,
        settings: {
          variant: 'fit-content',
        },
      });
    },

    confirmTournamentReopenModal() {
      if (typeof this.onTournamentReopen === 'function') {
        this.showConfirmationModal({
          text: this.$t(
            '_web_tournament_reopen_modal-text',
            'Do you really want to reopen a tournament?'
          ),
          confirmButtonText: this.$t(
            '_web_tournament_reopen_modal-confirm-button',
            'Reopen'
          ),
          isLoading: this.isLoading,
          confirm: this.onTournamentReopen,
          screenLocked: false,
          settings: {
            variant: 'fit-content',
          },
        });
      }
    },

    confirmTournamentPauseModal() {
      if (typeof this.onTournamentPause === 'function') {
        this.showConfirmationModal({
          modalName: 'TournamentPauseModal',
          title: this.$t('_web_tournament_pause_modal-title', 'Important!'),
          confirmButtonText: this.$t(
            '_web_tournament_pause_modal-confirm-button',
            'Edit brackets'
          ),
          isLoading: this.isLoading,
          confirm: this.onTournamentPause,
          screenLocked: false,
          settings: {
            variant: 'fit-content',
          },
        });
      }
    },

    showConfirmationModal(options) {
      options = Object.assign({ screenLocked: true }, options);
      const closeModalWrapper = func => {
        func = typeof func === 'function' ? func : () => {};
        return async () => {
          try {
            await func();
          } finally {
            this.closeModal();
          }
        };
      };
      this.openModal({
        name: options.modalName || 'HeraConfirm',
        props: {
          title: options.title,
          text: options.text,
          confirmButtonText: options.confirmButtonText,
          cancelButtonText: options.cancelButtonText,
          isLoading: options.isLoading,
        },
        events: {
          confirm: closeModalWrapper(options.confirm),
          cancel: closeModalWrapper(options.cancel),
        },
        settings: {
          clickOnBackdrop: !options.screenLocked,
          escKey: !options.screenLocked,
          ...(options.settings || {}),
        },
      });
    },
  },
};

export default [tournamentHelpers, tournamentModals];
