var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative flex-grow-1 col-12 tournament-participants"},[_c('Widget',{staticClass:"mb-6",attrs:{"title":_vm.$$t('Qualify Participants', 'list'),"indicators":_vm.indicators,"disabled":_vm.showStub && !_vm.filters.search_term,"actions-menu":_vm.actionsBtnItems,"indicators-centered":false,"indicators-wrapper-class":"justify-content-between h-pl-0","status-btn-mode":"participants"},on:{"input":_vm.onFilterChange},scopedSlots:_vm._u([{key:"afterIndicators",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center w-100"},[(_vm.squadSize > 1)?_c('label',{staticClass:"checkbox-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isCheckedNew),expression:"isCheckedNew"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isCheckedNew)?_vm._i(_vm.isCheckedNew,null)>-1:(_vm.isCheckedNew)},on:{"change":function($event){var $$a=_vm.isCheckedNew,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isCheckedNew=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isCheckedNew=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isCheckedNew=$$c}}}}),_c('TextBlock',{attrs:{"tag":"span","text":"Show full teams"}})],1):_vm._e()])]},proxy:true},{key:"actionBtn",fn:function(){return [_c('Btn',{staticClass:"btn-accent-simple h-ml-6",class:[
          ,
          {
            disabled:
              _vm.isStageFieldDisabled('qualify', _vm.currentTournamentStages[0]) ||
              _vm.showStub,
          } ],attrs:{"loading":_vm.qualifyLoading,"label":_vm.$$t('Qualify')},on:{"click":function($event){return _vm.generateFillParticipants('qualified')}}})]},proxy:true}]),model:{value:(_vm.filters.search_term),callback:function ($$v) {_vm.$set(_vm.filters, "search_term", $$v)},expression:"filters.search_term"}}),(_vm.showStub)?_c('HeraDummyList',{staticClass:"mt-4 ",attrs:{"size":"200","icon-name":"dummy/members","header-text":_vm.$$t('You have no participants yet', 'grid_empty'),"text":_vm.isAnnounced
        ? _vm.$$t(
            'Registration will be opened in',
            'grid_will-start-in-description'
          )
        : ''}},[(_vm.canInvitePlayers)?_c('div',{attrs:{"slot":"ctaButton"},slot:"ctaButton"},[_c('div',{staticClass:"d-inline-flex flex-column"},[_c('Btn',{staticClass:"btn-accent-simple mb-4",attrs:{"label":_vm.$t('_web_tournament_invite-people', 'Invite People')},on:{"click":function($event){$event.stopPropagation();return _vm.goToInvites($event)}}})],1),_c('div',{staticClass:"my-5 py-5"}),_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"row w-100 mx-auto justify-content-between",staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"qr-code","icon-size":"36","help-text":_vm.$t(
                    '_web_qr-code-download-tournament-help',
                    'Download QR-code for tournament invitation link'
                  )},on:{"click":function($event){$event.stopPropagation();return _vm.downloadQrCode($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":_vm.$t('_web_qr-code-download', 'QR code')}}),_c('QRCodeComponent',{attrs:{"options":_vm.qrFullOptions,"hidden":""},on:{"qrReady":_vm.getQRSource}})],1)]),_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"copy","icon-size":"36","help-text":_vm.$t(
                    '_web_copy-tournament-link-tournament-help',
                    'Copy tournament invitation link to clipboard'
                  )},on:{"click":function($event){$event.stopPropagation();return _vm.getInviteLink($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":_vm.$t('_web_copy-tournament-link-tournament-text', 'Copy link')}})],1)]),_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"social/fb","icon-size":"36","help-text":_vm.$t(
                    '_web_share-fb-tournament-help',
                    'Share tournament link to Facebook'
                  )},on:{"click":function($event){$event.stopPropagation();return _vm.shareLinkToFacebook($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":"Facebook"}})],1)]),_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"social/twitter","icon-size":"36","help-text":_vm.$t(
                    '_web_share-twitter-tournament-help',
                    'Share tournament link to Twitter'
                  )},on:{"click":function($event){$event.stopPropagation();return _vm.shareLinkToTwitter($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":"Twitter"}})],1)])])])]):_vm._e(),(_vm.isAnnounced)?_c('HeraBadge',{staticClass:"h-ml-3",attrs:{"slot":"append","variant":"bg-orange"},slot:"append"},[_c('Countdown',{attrs:{"date":_vm.currentTournament.schedule.registration_start,"tag":"span","size":0.625,"variant":"primary"}})],1):_vm._e()],1):_c('div',{staticClass:"tournament-participants-list mt-6"},[_c('Paginated',_vm._b({staticClass:"mb-5",on:{"change":_vm.fetchList}},'Paginated',{
        totalItems: _vm.playersInfo.current_total,
        hidePagination: !_vm.players.length,
        pageSize: _vm.pageSize,
        inContainer: true,
      },false),[_c('Table',{staticClass:"all-participants-table mb-5 col",attrs:{"columns":_vm.visibleColumns,"items":_vm.isChecked ? _vm.fullTeams : _vm.players,"loading":_vm.isLoading,"max-items-length":_vm.isTotalSelected ? 0 : _vm.totalItems,"selectable":true,"has-menu":true,"show-subheader":true,"menu-disabled":_vm.isLoading,"dummy-props":{
          size: 200,
          iconName: 'dummy/members',
          headerText: _vm.$t(
            '_web_tournament_players_empty',
            'You have no participants yet'
          ),
          text: _vm.$t(
            '_web_tournament_players_empty-exlanation-text',
            'When participants register or accept your invitation they will appear here'
          ),
        }},on:{"select-all":_vm.selectAllCheckboxHandler,"show-menu":_vm.onSettingsModalOpen,"clickOnRow":_vm.setActiveRow,"select":_vm.checkTotalCount},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('transition',{attrs:{"name":"translate-y"}},[_c('PlayersTableControls',{staticClass:"w-100 h-mr-6",attrs:{"selected":_vm.amountFooterCounter,"approved-to-list":_vm.distinctPlayersApprovedTo,"attributes-list":_vm.distinctPlayersAttributes,"stage-info":_vm.currentTournamentStages[0],"show-attributes":"","show-approved":""},on:{"approvedToSet":function (approvedTo) { return _vm.setSelectedPlayersExtendedData({ approvedTo: approvedTo }); },"attributesSet":function (attributes) { return _vm.setSelectedPlayersExtendedData({ attributes: attributes }); }}})],1)]},proxy:true},{key:"head-item",fn:function(ref){
        var column = ref.column;
return [_c('PlayersTableHeadCell',{attrs:{"column-name":column.name,"sort-icon":_vm.sortFields.field !== _vm.transformField(column.name)
                ? 'sort'
                : _vm.sortFields.order === 'incr'
                ? 'sort-asc'
                : 'sort-dsc'},on:{"sort":_vm.handleSort}})]}},{key:"subhead-item",fn:function(ref){
                var column = ref.column;
return [(
              _vm.distinctPlayersAttributes.length && column.name === 'attributes'
            )?_c('CustomFilter',{staticClass:"custom-filter",attrs:{"clear-if-selected-all":"","options":_vm.distinctPlayersAttributes,"disabled":_vm.isLoading},on:{"close":_vm.onFilterChange},model:{value:(_vm.filters.attributes_filter),callback:function ($$v) {_vm.$set(_vm.filters, "attributes_filter", $$v)},expression:"filters.attributes_filter"}}):_vm._e(),_c('CustomFilter',{directives:[{name:"show",rawName:"v-show",value:(column.name === 'approvedTo'),expression:"column.name === 'approvedTo'"}],staticClass:"custom-filter",attrs:{"clear-if-selected-all":"","options":_vm.distinctPlayersApprovedTo,"disabled":_vm.isLoading,"custom-order":""},on:{"close":_vm.onFilterChange},model:{value:(_vm.filters.approved_to),callback:function ($$v) {_vm.$set(_vm.filters, "approved_to", $$v)},expression:"filters.approved_to"}}),_c('CustomFilter',{directives:[{name:"show",rawName:"v-show",value:(column.name === 'check-in'),expression:"column.name === 'check-in'"}],staticClass:"custom-filter",attrs:{"clear-if-selected-all":"","options":_vm.checkinOptions,"disabled":_vm.isLoading},on:{"close":_vm.onFilterChange},model:{value:(_vm.filters.checked_in),callback:function ($$v) {_vm.$set(_vm.filters, "checked_in", $$v)},expression:"filters.checked_in"}})]}},{key:"body-item",fn:function(ref){
            var item = ref.item;
            var column = ref.column;
return [(!item.isAddon)?_c('PlayersTableCell',{attrs:{"column-name":column.name,"row":item,"is-clan":_vm.properties.is_clan,"all-attributes":_vm.properties.possible_attributes},on:{"chat":_vm.startChat,"showRegistrationData":_vm.openRegDataModal,"qualify":_vm.qualifyPlayer,"attributesSet":function($event){return _vm.setPlayersAttrtibutes(item, $event)},"checkIn":_vm.toggleCheckIn}}):_vm._e()]}},{key:"body-item-addon",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"py-0",attrs:{"colspan":_vm.visibleColumns.length}},[_c('Table',{staticClass:"py-0",attrs:{"columns":_vm.memberColumns,"items":item.members},scopedSlots:_vm._u([{key:"head-item",fn:function(ref){
            var column = ref.column;
return [_c('PlayersTableHeadCell',{attrs:{"column-name":column.name}})]}},{key:"body-item",fn:function(ref){
            var player = ref.item;
            var column = ref.column;
return [(column.name === 'Username')?_c('PlayersTableUsernameCell',{attrs:{"account":Object.assign({}, {id: player.id}, player.fields_data)}}):_c('TextBlock',{attrs:{"text":player.fields_data[column.name]}})]}}],null,true)})],1)]}},{key:"noUnitsCTA",fn:function(){return [_c('div',[_c('div',{staticClass:"d-inline-flex flex-column"},[_c('Btn',{staticClass:"btn-accent-simple mb-4",attrs:{"label":_vm.$t('_web_tournament_invite-people', 'Invite People')},on:{"click":function($event){$event.stopPropagation();return _vm.goToInvites($event)}}})],1),_c('div',{staticClass:"my-5 py-5"}),_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"row w-100 mx-auto justify-content-between",staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"qr-code","icon-size":"36","help-text":_vm.$t(
                          '_web_qr-code-download-tournament-help',
                          'Download QR-code for tournament invitation link'
                        )},on:{"click":function($event){$event.stopPropagation();return _vm.downloadQrCode($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":_vm.$t('_web_qr-code-download', 'QR code')}}),_c('QRCodeComponent',{attrs:{"options":_vm.qrFullOptions,"hidden":""},on:{"qrReady":_vm.getQRSource}})],1)]),_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"copy","icon-size":"36","help-text":_vm.$t(
                          '_web_copy-tournament-link-tournament-help',
                          'Copy tournament invitation link to clipboard'
                        )},on:{"click":function($event){$event.stopPropagation();return _vm.getInviteLink($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":_vm.$t(
                          '_web_copy-tournament-link-tournament-text',
                          'Copy link'
                        )}})],1)]),_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"social/fb","icon-size":"36","help-text":_vm.$t(
                          '_web_share-fb-tournament-help',
                          'Share tournament link to Facebook'
                        )},on:{"click":function($event){$event.stopPropagation();return _vm.shareLinkToFacebook($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":"Facebook"}})],1)]),_c('div',{staticClass:"col-auto row justify-content-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('ToolIcon',{staticClass:"mb-3",attrs:{"icon-name":"social/twitter","icon-size":"36","help-text":_vm.$t(
                          '_web_share-twitter-tournament-help',
                          'Share tournament link to Twitter'
                        )},on:{"click":function($event){$event.stopPropagation();return _vm.shareLinkToTwitter($event)}}}),_c('TextBlock',{attrs:{"tag":"div","text":"Twitter"}})],1)])])])])]},proxy:true}])})],1)],1),_c('Popover',{staticClass:"table-settings-menu",attrs:{"position":_vm.tableSettingsBtnPosition,"closest":_vm.$el,"float":['right']},on:{"toggle":_vm.onMenuToggle}},[(_vm.optionalColumns.length)?_c('PlayersTableSettingsModal',{attrs:{"clan":_vm.properties.is_clan,"optional-columns":_vm.optionalColumns},on:{"update":_vm.onSettingsModalClose}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }