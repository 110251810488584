<template>
  <div
    ref="popoverCommentClosest"
    class="comment-item my-4 position-relative w-100"
  >
    <div class="d-flex comment-section">
      <LinkAccount
        :id="comment.author.login"
        class="login"
        :type="comment.author.type"
        :vanity-id="comment.author.vanity_id"
      >
        <Avatar
          :img-url="comment.author.avatar"
          :size="isReply ? 36 : 48"
          :type="comment.author.type"
        />
      </LinkAccount>

      <div class="comment-header align-items-top w-100">
        <div class="d-flex replies-smaller">
          <div class="d-flex align-items-center">
            <LinkAccount
              :id="comment.author.login"
              class="comment-author-name"
              :type="comment.author.type"
              :vanity-id="comment.author.vanity_id"
            >
              {{ comment.author.name || comment.author.login }}
            </LinkAccount>
          </div>
          <div class="comment-timestamp ml-4 muted">
            <p class="mb-0">{{ comment.last_updated_on | timeAgo($t) }}</p>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-start">
          <div class="d-flex flex-column">
            <TextContent
              v-if="comment.text"
              has-expander
              :content="comment.text"
              class="comment-text-cont w-100"
            />
            <Attachments
              v-if="comment.attachments && comment.attachments.length"
              :attachments="comment.attachments"
              class="media-item"
            />
          </div>
          <AppIcon
            v-if="isAuthorized"
            name="dots"
            class="cursor-pointer comment-dots"
            width="18"
            :fill="$scss.heraMuted"
            :hover-fill="$scss.hoverColor"
            @click="event => openPopoverMenu(event.currentTarget)"
          />
        </div>
        <div
          class="d-flex justify-content-start reply-action align-items-center"
        >
          <a
            href="#"
            class=""
            :class="{
              active:
                comment && comment.reactions && comment.reactions.like.reacted,
            }"
            @click.prevent="likeClick"
          >
            <div class="d-flex like-link align-items-center">
              <img
                v-if="
                  comment &&
                    comment.reactions &&
                    comment.reactions.like &&
                    !comment.reactions.like.reacted
                "
                src="@/assets/images/comments/like-empty.svg"
                alt="like button"
                class="like-button-liked"
              />
              <img
                v-else
                src="@/assets/images/comments/like-filled.svg"
                alt="like button"
                class="like-button"
              />
              <span
                class="like-count"
                :class="{
                  'like-count-reacted':
                    comment &&
                    comment.reactions &&
                    comment.reactions.like &&
                    comment.reactions.like.reacted,
                }"
              >
                {{ comment.reactions.like.count }}
                {{
                  isRTL
                    ? comment.reactions.like.count > 10
                      ? $t('_web_comment_likes', 'likes')
                      : $t('_web_comment_like', 'like')
                    : comment.reactions.like.count === 1
                    ? $t('_web_comment_like', 'like')
                    : $t('_web_comment_likes', 'likes')
                }}</span
              >
            </div>
          </a>
          <a
            v-if="isAuthorized"
            href="#"
            class="add-reply ml-4"
            @click.prevent="addReplyAction"
          >
            {{ $t('_web_reply', 'Reply') }}
          </a>
        </div>
      </div>
    </div>

    <div class="comment-body">
      <!--  <TextContent
        v-if="comment.text"
        has-expander
        :content="comment.text"
        class="mt-4 w-100"
      /> -->
      <div class="d-flex justify-content-between mt-4 post-actions">
        <div
          class="show-replies"
          :class="{
            'view-more-tournament': $route.path.includes('tournament'),
          }"
        >
          <a
            v-if="!isReply && comment.comments_count"
            href="#"
            class="expand-replies"
            @click.prevent="toggleReplyBtn"
          >
            <img
              v-if="showReplies"
              src="@/assets/images/comments/arrow-up-new.svg"
              alt="arrow up"
              class="expand-arrow"
            />
            <img
              v-else
              src="@/assets/images/comments/arrow-down-new.svg"
              alt="arrow down"
              class="expand-arrow"
            />
            <span class="show-replies__link">
              {{ comment.comments_count }}
              {{
                isRTL
                  ? comment.comments_count > 10
                    ? $t('_web_tournament_sidechat_replies', 'replies')
                    : $t('_web_tournament_sidechat_reply', 'reply')
                  : comment.comments_count === 1
                  ? $t('_web_tournament_sidechat_reply', 'reply')
                  : $t('_web_tournament_sidechat_replies', 'replies')
              }}
            </span>
          </a>
        </div>
        <!-- <a
          v-if="!isMyComment"
          href="#"
          class="add-reply"
          @click.prevent="addReplyAction"
        >
          {{ $t('_web_add_reply', ' Add Reply') }}
        </a> -->

        <!--  <a
          href="#"
          class="h-ml-4"
          :class="{ active: comment.reactions.dislike.reacted }"
          @click.prevent="dislike"
        >
          <AppIcon name="dislike" class="dislikes" width="17" />
          <span>{{ comment.reactions.dislike.count }}</span>
        </a> -->
      </div>

      <Replies
        v-if="showReplies || showAddReply"
        ref="reply"
        :input-active="showAddReply"
        :post-id="postId"
        :comment="comment"
        :quantity="comment.comments_count"
        @toggleAddReply="toggleAddReply"
        @replyAdded="replyAdded"
      />

      <Popover
        :position="popoverCommentPosition"
        :closest="$refs.popoverCommentClosest"
        :post="comment"
        :owner="postowner"
        @deleted="removeComment"
        @authorBlocked="$emit('refresh')"
        @toggle="closePopover"
      />
    </div>
    <!-- <hr v-show="!isReply" /> -->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import AppIcon from '@/components/atoms/Icon';
import Avatar from '@/components/images/Avatar';
import TextContent from '@/components/text/TextContent';
import LinkAccount from '@/components/links/Account';
import Attachments from '@/components/common/MsgAttachments';
import Popover from './CommentPopover';
import Replies from '@/components/comments/Replies';

export default {
  name: 'Comment',
  components: {
    Replies,
    LinkAccount,
    AppIcon,
    Avatar,
    Popover,
    Attachments,
    TextContent,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    postId: {
      type: String,
      required: true,
    },
    isReply: {
      type: Boolean,
      required: false,
    },
    postowner: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      linkPreview: null,
      debouncedPreviewRequest: null,
      popoverCommentPosition: null,
      showReplies: false,
      showAddReply: false,
    };
  },
  computed: {
    ...mapState('my', ['accountId']),
    ...mapGetters(['isRTL', 'isAuthorized']),
    isMyComment() {
      const postAuthorId = this.comment
        ? this.comment.author.id || this.comment.author.login
        : null;
      return this.accountId === postAuthorId;
    },
  },
  created() {
    // if (this.$route.params.id && this.post) {
    //   this.showComments(this.post.comments_count);
    // }
    // console.log(this.isReply);
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),
    ...mapActions(['getOpenGraphDataByLink', 'likeComment']),
    getOpenGraphData(link) {
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },
    closePopover(isVisible) {
      if (!isVisible) this.popoverCommentPosition = null;
    },
    openPopoverMenu(position) {
      this.popoverCommentPosition = position;
    },
    removeComment(item) {
      this.$emit('delete', item);
    },
    toggleAddReply(state) {
      this.showAddReply = state;
    },
    replyAdded() {
      this.showReplies = true;
    },
    toggleReplyBtn() {
      this.showAddReply = false;
      this.showReplies = !this.showReplies;
    },
    addReplyAction() {
      if (this.isReply) this.$emit('reply', this.comment.author);
      else {
        this.showAddReply = !this.showAddReply;
        this.$nextTick(() => {
          const replyInput = this.$el.querySelector('#replyInput');
          replyInput.focus();
        });
      }
    },
    handleNonLoggeedUsers() {
      this.openModal({
        name: 'SigninActionModal',
        settings: {
          variant: 'fit-content',
        },
      });
    },
    postReaction(data) {
      const { comment } = this;
      if (this.isAuthorized) {
        // Like comment
        if (data.type === 'Like') {
          if (comment.reactions.like.reacted) {
            comment.reactions.like.reacted = false;
            comment.reactions.like.count--;
          } else {
            comment.reactions.like.reacted = true;
            comment.reactions.like.count++;

            if (comment.reactions.dislike.reacted) {
              comment.reactions.dislike.reacted = false;
              comment.reactions.dislike.count--;
            }
          }
          // Dislike comment
        } else {
          if (comment.reactions.dislike.reacted) {
            comment.reactions.dislike.reacted = false;
            comment.reactions.dislike.count--;
          } else {
            comment.reactions.dislike.reacted = true;
            comment.reactions.dislike.count++;

            if (comment.reactions.like.reacted) {
              comment.reactions.like.reacted = false;
              comment.reactions.like.count--;
            }
          }
        }
      } else {
        // this.$router.push({ name: 'signin' });
        this.handleNonLoggeedUsers();
      }
    },
    likeClick() {
      if (this.isAuthorized) {
        this.likeComment({
          id: this.comment.id,
          type: 'Like',
          reacted: this.comment.reactions.like.reacted,
        });
        this.postReaction({
          type: 'Like',
          reacted: this.comment.reactions.like.reacted,
        });
      } else {
        // this.$router.push({ name: 'signin' });
        this.handleNonLoggeedUsers();
      }
    },
    dislike() {
      if (this.isAuthorized) {
        this.likeComment({
          id: this.comment.id,
          type: 'Dislike',
          reacted: this.comment.reactions.dislike.reacted,
        });
        this.postReaction({
          type: 'Dislike',
          reacted: this.comment.reactions.dislike.reacted,
        });
      } else {
        // this.$router.push({ path: '/signin' });
        this.handleNonLoggeedUsers();
      }
    },
  },
};
</script>

<style lang="scss">
hr {
  border: none;
  border-top: 1px solid #272732;
  margin: $ph-large-space 0;
}

.comment-dots {
  visibility: hidden;
  rotate: 90deg;
}

.comment-header {
  @include auto-rtl(margin-left, $hr-spacing-05);
  a:hover {
    color: white;
  }
  @include auto-rtl(margin-left, 1rem);
}

.commentInput {
  width: 100%;
}

.comment-body {
  @include auto-rtl(margin-left, 4.375rem !important);
}

.like-count {
  text-align: bottom;
  font-weight: 700;
  font-size: 12px;
  @include auto-rtl(margin-left, 8px);
  .app-rtl & {
    margin-left: 1rem;
  }
}

.like-count-reacted {
  color: #9d9cf7;
}

.like-active {
  color: $hr-text-accent;
}

.comment-section {
  &:hover {
    .comment-dots {
      position: relative;
      top: 0;
      visibility: visible;
    }
  }
}

.comment-timestamp {
  color: #474a56 !important;
  color: $hr-text-secondary !important;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  @include auto-rtl(margin-left, 1rem !important);
}
.comment-item {
  margin-bottom: $ph-small-space;
  margin-top: $ph-large-space;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.comment-item .sc-post-attach {
  max-width: 100%;
  @include auto-rtl(margin-left, -0.75rem !important);
}
.add-reply {
  font-size: $ph-small-text-font-size;
  font-weight: 700;
  color: white;
}
.show-replies__link {
  font-weight: 700;
  @include auto-rtl(margin-left, $hr-spacing-02);
}
.show-replies {
  cursor: pointer;
  @include auto-rtl(margin-right, auto);
  @include auto-rtl(margin-left, -64px);

  @media (max-width: 767px) {
    @include auto-rtl(margin-left, -50px);
  }

  &__link {
    color: #9d9cf7;
  }
}

.view-more-tournament {
  .app-ltr & {
    margin-left: -15px;
  }

  .app-rtl & {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    .app-ltr & {
      margin-left: 25px;
    }

    .app-rtl & {
      margin-right: 40px;
    }
  }
}

.post-actions {
  margin-left: 14px;
}

.expand-arrow {
  margin-bottom: 3px;
}

.comment-text-cont {
  padding-bottom: $hr-spacing-01;
  max-width: 700px;
}

.media-item {
  width: 300px;
}
</style>
