import { generateGetters, generateToggleMutations } from '@/utils/store';
import { merge, get } from '@/utils/lodashUtils';
import { modalComponents } from '@/constants/modal';

const defaultModalSettingsFactory = () => ({
  clickOnBackdrop: true,
  escKey: true,
  variant: 'default',
  closeIcon: false,
});
/**
 * Default modal options factory function
 * @return {Object} empty modal options object
 */
const createDefaultModalOptions = () => ({
  component: null,
  props: {},
  events: {},
  settings: defaultModalSettingsFactory(),
});
/**
 * Default initial state factory function
 * @return {Object} initial state object
 */
const INITIAL_MAIN_STATE = () => {
  return {
    activeModal: null,
    activeModalName: '',
    importedModals: {},
    modalIsShow: false,
  };
};

const getters = {
  /**
   * Generating simple getters for activeModal and modalIsShow state properties
   */
  ...generateGetters('activeModal', 'activeModalName', 'modalIsShow'),
  /**
   * Getter for click-on-backdrop setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {Boolean} click-on-backdrop prop value, true by default
   */
  clickOnBackdrop: state =>
    get(state, 'activeModal.settings.clickOnBackdrop', true),
  /**
   * Getter for esc-key setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {Boolean} esc-key prop value, true by default
   */
  escKey: state => get(state, 'activeModal.settings.escKey', true),
  /**
   * Getter for variant setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {String} - variant prop value, default by default
   */
  variant: state => get(state, 'activeModal.settings.variant') || 'default',
  /**
   * Getter for Close Icon setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {String} - closeIcon prop value, false by default
   */
  closeIcon: state => get(state, 'activeModal.settings.closeIcon') || false,
  containerClass: state =>
    get(state, 'activeModal.settings.containerClass') || '',
};

const mutations = {
  /**
   * Generating toggle nutation for modalIsShow state property
   */
  ...generateToggleMutations('modalIsShow'),
  /**
   * Setter for modal options in state
   * @param {Object} state - state object
   * @param {Object} payload - payload object
   * @property {string} payload.name - modal component name.
   * @property {Object} payload.props - modal component props.
   * @property {Object} payload.events - modal component events listeners.
   * @property {Object} payload.settings - ModalBackdrop settings.
   */
  setOptions(state, { name, props, events, settings, update = false }) {
    if (name) {
      state.activeModalName = name;
    } else {
      name = state.activeModalName;
    }

    state.importedModals[name] = merge(
      update ? state.importedModals[name] : createDefaultModalOptions(),
      {
        props,
        events,
        settings,
      }
    );
  },
  clearModal(state) {
    const name = state.activeModalName;
    if (name) {
      state.importedModals[name] = createDefaultModalOptions();
    }
    state.activeModal = null;
  },
  updateModalProps(state, props) {
    if (state.activeModal) {
      state.activeModal.props = {
        ...state.activeModal.props,
        ...props,
      };
    }
  },
  /**
   * Setter for opening modal
   * Sets modal flag and active component from imported components
   * @param {Object} state - state object
   * @param {String} name - modal component name
   */
  showModal(state, name) {
    state.modalIsShow = true;
    state.activeModal = state.importedModals[name];
  },
};

const actions = {
  /**
   * Action for close modal
   * triggers toggleModalIsShow mutation
   * @param {Object} state - state context
   * @property {Function} state.commit - commit function
   */
  closeModal({ commit }) {
    commit('toggleModalIsShow', false);
    commit('clearModal');
  },
  /**
   * Action for opening modal
   * triggers showModal mutation
   * @param {Object} store - state context
   * @property {Function} store.commit - mutation trigger
   * @property {Object} store.state - current module state
   * @param {Object} payload - payload object
   * @property {string} payload.name - modal component name.
   * @property {Object} payload.props - modal component props.
   * @property {Object} payload.events - modal component events listeners.
   * @property {Object} payload.settings - ModalBackdrop settings.
   * @return {Promise} deferred triggering of showModal mutation
   * because of importing modal component
   * in case that component was already imported, promise resolves immediately
   */
  openModal({ commit, state }, { name, props, events, settings }) {
    // commit('toggleModalIsShow', true);
    return new Promise((resolve, reject) => {
      if (!name) {
        return reject('Component Not Found');
      }

      commit('setOptions', { name, props, events, settings });
      const currentModal = state.importedModals[name];
      if (!(currentModal && currentModal.component)) {
        currentModal.component = modalComponents[name];
        return currentModal.component().then(() => {
          commit('showModal', name);
          return resolve();
        });
      } else {
        commit('showModal', name);
        return resolve();
      }
    });
  },
};
/**
 * Export of the vuex module
 */
export default {
  state: INITIAL_MAIN_STATE(),
  getters,
  mutations,
  actions,
};
