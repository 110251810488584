<template>
  <ListLoader
    class="position-relative battle-list-wrapper"
    :is-loading="isLoading && isEmpty"
    :is-empty-list="isEmpty"
    :dummy-props="{
      iconName: 'dummy/matches',
      size: 200,
      headerText: $$t('Your match list is empty.', 'no-results'),
      text: $$t(
        'There are so many competions await for you',
        'no-results_subheader'
      ),
    }"
    @reload="fetchBattles"
  >
    <Paginated
      slot="list"
      v-bind="{
        totalItems,
        hidePagination: !battles || !battles.length,
        paginationProps: {
          isDark: true,
        },
        inContainer: true,
      }"
      class="m-0 h-auto"
      @change="fetchBattles"
    >
      <div class="col ">
        <div class="row">
          <div
            v-for="event in eventsInfo"
            :key="event.id"
            class="col-12 py-3 tournament-container-matches"
          >
            <LinkTournament
              v-if="type !== tournamentType"
              class="d-flex align-items-center link-tournament-width battles-list_tournament-wrapper"
              v-bind="{ id: event.id }"
            >
              <ListItem :title="event.name">
                <Avatar slot="avatar" :img-url="event.logo" :size="36" />
                <!-- <Subheader slot="title" :text="event.name" class="my-0" /> -->
                <SecondaryText
                  slot="subtitle"
                  :size="0.675"
                  class=""
                  :text="event.tournament_start | localDate($times.DATE_SHORT)"
                />
              </ListItem>
            </LinkTournament>
            <div class="matches-container">
              <div
                v-for="battle in eventsBattles[event.id]"
                :key="battle.id"
                class="matches-hover"
              >
                <component
                  :is="getMatchCardComponent(battle)"
                  v-bind="{ ...getMatchCardProps(battle) }"
                  @click="goToBattle(battle.id)"
                />
              </div>
              <!-- <div
                v-for="(group, key, index) in getGroupedEventBattles(event.id)"
                :key="`${index}-${event.id}`"
                class="row col-12 status-group justify-content-between"
              >
                <div
                  class="group-title secondary-font font-size-xl font-weight-bold mb-3 w-100"
                >
                  {{ statusText(key) }}
                </div>
                <div
                  v-for="(rankGroup,
                  rankKey,
                  rankIndex) in getGroupedByRankBattles(group)"
                  :key="`${rankIndex}-${rankGroup}-${event.id}`"
                  class="rank-group row col-12"
                >
                  <div class="rank-title font-size-base font-weight-bold w-100">
                    {{ getMatchRoundName(rankGroup[0]) }}
                  </div>
                  <div
                    v-for="battle in rankGroup"
                    :key="battle.id"
                    class="mb-5"
                  >
                    <component
                      :is="getMatchCardComponent(battle)"
                      v-bind="{ ...getMatchCardProps(battle) }"
                      @click="goToBattle(battle.id)"
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </Paginated>
  </ListLoader>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { BATTLE_STATUSES } from '@/constants/battles';
import { translate } from '@/mixins/translate';
import roundNameHelper from '@/views/tournaments/mixins/regularRoundName';
import { BATTLE, PROFILE, CLAN, TOURNAMENT } from '@/constants/accounts';
import { BLOCK_TYPES } from '@/constants/tournaments';
import Avatar from '@/components/images/Avatar';
import LinkTournament from '@/components/links/Tournament';
import ListLoader from '@/components/common/HeraListPreloader';
import SecondaryText from '@/components/atoms/SecondaryText';
import DuelCardSimple from '@/views/tournaments/management/brackets/components/DuelMatchCard.vue';
import BRCard from '@/views/tournaments/management/brackets/components/BRMatchCard.vue';
import ListItem from '@/components/common/ListItem';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';
import listMixin from '@/mixins/paginatedLists';
import Paginated from '@/components/common/Paginated';
import { matchStatuses } from '@/mixins/tournaments/matchStatuses';
// import { MAIN_MATCHES } from '@/constants/router/mains';

export default {
  name: 'BattlesList',
  components: {
    ListLoader,
    SecondaryText,
    Paginated,
    LinkTournament,
    Avatar,
    DuelCardSimple,
    BRCard,
    ListItem,
  },
  mixins: [
    ...listMixin,
    translate('_web_account_battles_list'),
    roundNameHelper,
    matchStatuses,
  ],
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
      validator: accountType =>
        [PROFILE, CLAN, TOURNAMENT].includes(accountType),
    },
    statuses: {
      type: String,
      default: '',
      validator: t => !t || Object.values(BATTLE_STATUSES).includes(t),
    },
  },
  data() {
    return {
      battles: [],
      eventsBattles: {},
      eventsInfo: [],
      visibleDetails: {},
      battleType: BATTLE,
      tournamentType: TOURNAMENT,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('my/clans', { myClans: 'clanList' }),
    ...mapState('my', ['account'], 'current', {
      currentAccountId: 'accountId',
    }),
    ...mapGetters(['platformByID', 'gameByID']),
    isEmpty() {
      return !this.battles.length;
    },
    queryParams() {
      const params = {};

      if (this.type !== TOURNAMENT) {
        params.accountId = this.accountId === this.id ? 'my' : this.id;
        params.battleStatuses = this.statuses || null;
        params.page_size = '10';
      } else {
        params.accountId = 'my';
        params.eventId = this.id;
      }
      return params;
    },
  },
  created() {
    this.fetchBattles();
  },
  methods: {
    ...mapActions(['getMatchesByPlayer', 'getMatchesByTournament']),
    goToBattle(id) {
      this.$router.push({
        name: ROUTER_NAMES_ACCOUNT.INFO,
        params: {
          accountType: BATTLE,
          accountId: id,
        },
      });
    },
    getMatchCardComponent(match) {
      return match.type === BLOCK_TYPES.BATTLE_ROYALE ? BRCard : DuelCardSimple;
    },
    getPlayerIndex(match) {
      const isBR = match.type === BLOCK_TYPES.BATTLE_ROYALE;
      const clanName = this.myClans.map(item => item.name);
      if (match.players && match.players[0].profile.type === 'clan') {
        return isBR && match.players
          ? match.players.findIndex(el =>
              clanName.some(name => el.profile.name === name)
            )
          : -1;
      } else {
        return isBR && match.players
          ? match.players.findIndex(
              el =>
                el.profile.name === this.account.displayName ||
                this.account.vanityId
            )
          : -1;
      }
    },
    getMatchCardProps(match) {
      const player = match.players ? match.players[0] : match.player_1;
      return {
        isSingle: player && player.type === PROFILE,
        roundIndex: match.round,
        matchIndex: match.index,
        playerIndex: this.getPlayerIndex(match),
        roundName: this.getMatchRoundName(match),
        showRound: true,
        match,
      };
    },
    getGroupedEventBattles(id) {
      return this.$lodash.groupBy(this.eventsBattles[id], combinedStatus);

      function combinedStatus(battle) {
        switch (battle.status) {
          case 'started':
          case 'judging':
            return 'ongoing';
          case 'pending':
          case 'scheduled':
            return 'upcoming';
          case 'completed':
          case 'cancelled':
            return 'completed';
          default:
            return battle.status;
        }
      }
    },
    getGroupedByRankBattles(group) {
      return this.$lodash.groupBy(group, this.getMatchRoundName);
    },
    statusText(status) {
      return this.$filters.startCase(this.localizedMatchStatuses[status]) || '';
    },
    isLabelVisible(battle) {
      return battle.isStarted || battle.isJudging || battle.isCanceled;
    },
    getTextLabel(battle) {
      return battle.isStarted
        ? this.$$t('Playing now')
        : battle.isJudging
        ? this.$$t('Judging')
        : battle.isCanceled
        ? this.$$t('Canceled')
        : '';
    },
    isBattleCancelled(battle) {
      return battle.isCanceled;
    },
    getMatchRoundName({ type, round, gridType, match_rank } = {}) {
      const isDE = type === BLOCK_TYPES.DOUBLE;
      return match_rank === 'grand_final'
        ? this.$t(
            '_web_tournaments_double-eliminations_grand_final',
            'Grand Final'
          )
        : isDE && gridType === 'upper' && match_rank === 'final'
        ? this.$t(
            '_web_tournaments_double-eliminations_winners-final',
            'Winners final'
          )
        : isDE && gridType === 'lower' && match_rank === 'final'
        ? this.$t(
            '_web_tournaments_double-eliminations_losers-final',
            'Losers final'
          )
        : match_rank === 'final'
        ? this.$t('_web_tournaments-tableview_final', 'Final')
        : match_rank === 'third_place'
        ? this.$t('web_tournament_third-place-match', 'Third place match')
        : match_rank === 'semi_final'
        ? this.$t('_web_tournaments-tableview_semifinal', 'Semi-final')
        : isDE && gridType === 'upper' && match_rank === 'regular'
        ? this.$t(
            '_web_tournaments_double-eliminations_upper-round',
            'Upper Round {num}',
            {
              num: round < 2 ? +round + 1 : (+round + 3) / 2,
            }
          )
        : isDE && gridType === 'lower' && match_rank === 'regular'
        ? this.$t(
            '_web_tournaments_double-eliminations_lower-round',
            'Lower Round {num}',
            {
              num: round,
            }
          )
        : this.$t(
            '_web_tournaments-tableview_round-basic-name',
            'Round {number}',
            { number: +round + 1 }
          );
    },
    fetchBattles(newPage) {
      this.isLoading = true;
      this.pageIndex = newPage;
      let getMethod =
        this.type == TOURNAMENT
          ? this.getMatchesByTournament
          : this.getMatchesByPlayer;
      this.getPageData(
        'battles',
        getMethod(this.paramsWithPageNumber(this.queryParams)),
        {
          uniqBy: 'id',
          map: battle => {
            if (battle.players && battle.type === BLOCK_TYPES.BATTLE_ROYALE) {
              const player = battle.players.find(
                el =>
                  el.profile.login === this.currentAccountId ||
                  el.profile.id === this.currentAccountId
              );
              battle.player_1 =
                player && !battle.player_1 ? player.profile : null;
            }
            battle.isStarted = battle.status === BATTLE_STATUSES.started;
            battle.isCanceled = battle.status === BATTLE_STATUSES.cancelled;
            battle.isJudging = battle.status === BATTLE_STATUSES.judging;
            battle.platform = this.platformByID(battle.platform);
            return battle;
          },
          forEach: battle => {
            if (
              this.eventsInfo.findIndex(event => event.id === battle.event.id) <
              0
            ) {
              this.eventsInfo = this.eventsInfo
                .concat([battle.event])
                .sort((a, b) => b.tournament_start - a.tournament_start);
            }
            if (Array.isArray(this.eventsBattles[battle.event.id])) {
              if (
                this.eventsBattles[battle.event.id].findIndex(
                  el => el.id === battle.id
                ) < 0
              ) {
                this.eventsBattles[battle.event.id] = this.eventsBattles[
                  battle.event.id
                ]
                  .concat([battle])
                  .sort((a, b) => b.start_date - a.start_date);
              }
            } else {
              this.eventsBattles[battle.event.id] = [battle];
            }
          },
        }
      ).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.battles-list {
  a:hover {
    text-decoration: none !important;
    color: inherit;
  }

  &_score {
    flex-basis: 6rem;
    height: 2rem;
    background: $ph-input-bg;
    border-radius: $ph-medium-radius;

    &.score-splitter {
      &:after {
        position: absolute;
        width: 1px;
        top: 0.25rem;
        bottom: 0.25rem;
        background: $ph-card;
        content: '';
      }

      .loser {
        color: $ph-secondary-text;
      }
    }

    & .results-canceled {
      color: $ph-error;
    }
  }

  &_account {
    flex-basis: calc(50% - 54px);
    max-width: calc(50% - 54px);
    margin-bottom: 0 !important;

    .list-item-avatar {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: $ph-small-space;
    }

    .list-item-title {
      text-align: center;
    }
  }

  &_duel-score {
    flex-basis: 108px;
    max-width: 108px;

    > div {
      height: 36px;
    }
  }

  &_tournament-wrapper {
    text-decoration: none !important;

    .list-item-title span.text-truncate {
      font-size: 1.125rem;
      color: $ph-primary-text;
      font-weight: bold;
    }

    .battles-list_tournament-name span {
      @include text-truncate;
    }
  }
}

.battles,
.battles-list,
.battle-list-wrapper {
  width: 100%;

  @media (max-width: 800px) {
    justify-content: center;
    padding-bottom: 4rem;
  }

  .tournament-container-matches {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
  }

  .matches-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  .matches-hover:hover {
    cursor: pointer;
    div {
      div {
        border-color: #5a58f2 !important;
      }
    }
  }

  .rank-title {
    color: var(--ph-secondary-text);
  }

  .rank-group {
    gap: 1rem;
  }
}

.link-tournament-width {
  max-width: fit-content;
}
</style>
