import { uniqueId } from '@/utils/lodashUtils';
import { generateMutations } from '@/utils/store';

const INITIAL_STATE = () => ({
  targetElementId: null,
  settings: {
    stopItemClickBubbling: false,
    menuClass: 'font-size-xs',
    itemClass: null,
    isFixed: false,
    blockIsFixed: false,
    targetSelector: '',
    float: [],
    regard: 'top',
    autoClose: false,
    width: null,
    height: null,
    translate: 0,
  },
  items: [],
});

const getters = {
  targetElement({ targetElementId }) {
    return document.querySelector(
      `[data-popover-menu-id="${targetElementId}"]`
    );
  },
};

const mutations = {
  ...generateMutations('items'),

  setTargetElement(state, payload) {
    const currentTarget = document.querySelector(
      `[data-popover-menu-id="${state.targetElementId}"]`
    );
    if (currentTarget instanceof Node) {
      delete currentTarget.dataset.popoverMenuId;
    }
    state.targetElementId =
      payload instanceof Node
        ? (payload.dataset.popoverMenuId = uniqueId('popover-menu-id'))
        : payload;
  },

  setSettings(state, payload) {
    Object.assign(state.settings, payload);
  },
};

const actions = {
  open(
    { commit },
    { event = null, node = null, selector = null, settings = {}, items = [] }
  ) {
    const targetElement =
      (event && event.target) ||
      (selector && document.querySelector(selector)) ||
      node;
    commit('setTargetElement', targetElement);
    commit('setSettings', settings);
    commit('setItems', items);
  },
  hide({ commit }) {
    commit('setSettings', { targetSelector: '' });
    commit('setTargetElement', null);
  },
};

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  getters,
  mutations,
  actions,
};
