<template>
  <div class="">
    <div
      class="d-flex justify-content-between w-100 mb-3 mt-n3 position-relative tournament-statusbar-wrapper"
    >
      <div>
        <div
          v-if="accountPlatforms && accountPlatforms.length"
          class="d-flex align-items-center"
        >
          <div
            v-for="{ id, logo, name } in accountPlatforms"
            :key="id"
            class="h-mr-2"
          >
            <Avatar
              :img-url="logo.uri"
              type="platform"
              :alt="name"
              :size="28"
            />
          </div>
          <TextBlock v-if="accountGame" :text="accountGame.name" />
        </div>
      </div>
      <TournamentActions
        class="status-bar-actions"
        :tournament="account"
        show-status
        hide-join-actions
        hide-additional-menu
        hide-sharing-button
      />
    </div>
    <!-- <div class="d-flex justify-content-between w-100">
      <div class="h-mr-auto">
        <template
          v-if="account.eventOrganizerLogo && account.eventOrganizerName"
        >
          <TextLabel>{{ $$t('Organized by') }}</TextLabel>
          <ListItemAccount
            :type="organizationType"
            :avatar="account.eventOrganizerLogo"
            :name="account.eventOrganizerName"
            :account-id="account.owner"
            :vanity-id="account.vanityId"
            :size="60"
          />
        </template>
      </div>
      <div class="mx-auto h-text-left">
        <template v-if="$options.sponsors.length">
          <TextLabel>{{ $$t('sponsored by') }}</TextLabel>
          <div>
            <span
              v-for="(sponsor, index) in $options.sponsors"
              :key="`sponsor-${index}`"
              class="h-mr-4"
            >
              <img :src="sponsor" height="60" />
            </span>
          </div>
        </template>
      </div>
      <div class="h-ml-auto h-text-text">
        <template v-if="$options.prizePool">
          <TextLabel>{{ $$t('Prize') }}</TextLabel>
          <TextBlock :text="$options.prizePool" :size="1.8" tag="div" />
          <div
            v-if="$options.prizeLine.length"
            class="d-flex justify-content-end mt-1"
          >
            <span
              v-for="(place, index) in $options.prizeLine"
              :key="`place-${index}`"
              class="d-flex align-items-center h-ml-2"
            >
              <Icon :name="`medal-${place.place}`" width="24" />
              <TextBlock
                v-if="place.prize"
                :text="` - ${place.prize} ${$options.currency}`"
                :size="0.8"
                class="text-nowrap"
              />
            </span>
          </div>
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { ORGANIZATION } from '@/constants/accounts';
import { translate } from '@/mixins/translate';

import TextBlock from '@/components/atoms/TextBlock';
import Avatar from '@/components/images/Avatar';
import TournamentActions from './actions/Tournament.vue';
// import Icon from '@/components/atoms/Icon';
// import ListItemAccount from '@/components/common/ListItemAccount';
// import TextLabel from '@/components/atoms/TextLabel';

export default {
  name: 'TournamentStatusBar',
  components: {
    // TextLabel,
    // ListItemAccount,
    // Icon,
    TournamentActions,
    TextBlock,
    Avatar,
  },
  mixins: [translate('_web_tournament')],
  data() {
    return {
      accountPlatforms: null,
      accountGame: null,
      organizationType: ORGANIZATION,
    };
  },
  computed: {
    ...mapState('current', ['account']),
    ...mapGetters(['platforms', 'games']),
  },
  created() {
    this.fetchPlatforms().then(() => {
      this.accountPlatforms = this.platforms.filter(({ id }) =>
        this.account._platforms.includes(id)
      );
    });
    this.fetchGames().then(() => {
      this.accountGame = this.games.find(({ id }) => this.account.game === id);
    });

    this.$options.sponsors = this.account.sponsorLogo || [];

    const prize = this.account.prize;
    if (prize) {
      this.$options.currency = prize.currency || '';

      const prizePool = prize.prize_pool;
      this.$options.prizePool = prizePool
        ? prizePool + ' ' + this.$options.currency
        : '';
      this.$options.prizeLine = this.$lodash.orderBy(
        prize.prize_line || [],
        'place'
      );
    }
  },
  methods: {
    ...mapActions(['fetchPlatforms', 'fetchGames']),
  },
};
</script>

<style lang="scss" scoped>
.tournament-statusbar-wrapper {
  min-height: 42px;
}
</style>
