<template>
  <router-view></router-view>
</template>

<script>
// import { mapGetters } from 'vuex';
import {
  CONSTRUCTOR_MODES,
  // TOURNAMENT_STATUSES,
} from '@/constants/tournaments';
const redirectToProperMode = (to, vm) => {
  if (to.name === 'manageMatches') {
    vm.$router.replace({
      name: 'multiStage',
      params: {
        ...to.params,
        constructorMode: CONSTRUCTOR_MODES.MANAGE,
        // !vm.isTournamentPastStatus(
        //   TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
        // )
        //   ? CONSTRUCTOR_MODES.CONSTRUCT
        //   : !vm.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED)
        //   ? CONSTRUCTOR_MODES.PUBLISH
        //   : CONSTRUCTOR_MODES.MANAGE,
      },
    });
  }
};
export default {
  name: 'MatchesWrapper',
  // computed: {
  //   ...mapGetters(['isTournamentPastStatus']),
  // },
  beforeRouteEnter(to, from, next) {
    next(vm => redirectToProperMode(to, vm));
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === 'multiStage' && to.name === 'manageMatches') {
      next(false);
    } else {
      next(redirectToProperMode(to, this));
    }
  },
};
</script>
