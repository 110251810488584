<template>
  <img
    :src="avatar"
    class="avatar"
    loading="lazy"
    :style="{
      width: `${size}px`,
      minWidth: `${size}px`,
      height: `${size}px`,
      '--background-color': fillColor,
    }"
    @error="setDefaultImage"
  />
</template>

<script>
import defaultImage from '@/assets/images/defaults/custom.svg';

export default {
  name: 'ImageRounder',
  props: {
    size: {
      type: Number,
      default: 64,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    fillColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    avatar() {
      return this.imgUrl || defaultImage;
    },
  },
  methods: {
    setDefaultImage() {
      this.$emit('imageLoadError');
    },
  },
};
</script>

<style lang="scss">
.avatar {
  --background-color: #{$ph-secondary-text};
  display: inline-block;
  position: relative;
  object-fit: cover;
  background-color: $hr-bg-avatars;
  border-radius: 5px;
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms"
  name="ImageRounder"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    fillColor: {
      default: text('fillColor', '#727288')
    },
    size: {
      default: number('size', 64)
    },
    imgUrl: {
       default: text('imgUrl', '')
    }
  }"
>
  <ImageRounder v-bind="{fillColor, size, imgUrl}"/>
</story>
