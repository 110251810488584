<template>
  <div class="position-relative flex-grow-1">
    <Widget
      v-model="filters.search_term"
      :title="
        $t('_web_tournament_manage-stage-title', 'Manage Stage') +
          ' ' +
          $t('_web_tournament_manage-participants', 'Participants')
      "
      class="mb-6"
      :actions-menu="actionsBtnItems"
      @input="onFilterChange"
    />
    <InfoPanel class="my-5">
      {{
        $$t(
          'Finalize drawing of each group and publish brackets.\nParticipants can be replaced between blocks in Stage Participants list',
          'info-text'
        )
      }}
      <template #addons>
        <div class="d-flex justify-content-end w-100">
          <Btn
            class="btn-accent-simple"
            :label="$$t('Re-draw Brackets')"
            :disabled="!isStageScheduled || drawLoading"
            @click="confirmReDraw(currentStageInfo)"
          />
        </div>
      </template>
    </InfoPanel>
    <HorizontalTabs
      v-bind="{ tabs, variant: 'folders', notExactMatch: true }"
    />
    <div class="stage-players-list">
      <Paginated
        v-bind="{
          totalItems,
          hidePagination: !players.length,
          pageSize,
          inContainer: true,
        }"
        @change="fetchList"
      >
        <Table
          :columns="visibleColumns"
          :items="players"
          :selectable="true"
          :has-menu="true"
          :show-subheader="true"
          :max-items-length="isTotalSelected ? 0 : totalItems"
          class="stage-participants-table mb-5 col"
          @select-all="selectAllCheckboxHandler"
          @show-menu="onSettingsModalOpen"
          @clickOnRow="setActiveRow"
          @select="checkTotalCount"
        >
          <template #control>
            <PlayersTableControls
              class="w-100 h-mr-6"
              :selected="amountFooterCounter"
              :approved-to-list="distinctPlayersApprovedTo"
              :attributes-list="distinctPlayersAttributes"
              show-attributes
              show-blocks
              show-approved
              :blocks-list="blocksList"
              :stage-info="currentStageInfo"
              @approvedToSet="
                approvedTo =>
                  setPlayersExtendedData(selectedItems, { approvedTo }, true)
              "
              @attributesSet="
                attributes =>
                  setPlayersExtendedData(selectedItems, { attributes })
              "
              @blockSet="block => setActivePlayerAssignBlock(block)"
            />
          </template>
          <template #head-item="{ column }">
            <PlayersTableHeadCell
              :column-name="column.name"
              :sort-icon="
                sortFields.field !== transformField(column.name)
                  ? 'sort'
                  : sortFields.order === 'incr'
                  ? 'sort-asc'
                  : 'sort-dsc'
              "
              @sort="handleSort"
            />
          </template>
          <template #subhead-item="{ column }">
            <CustomFilter
              v-if="distinctPlayersAttributes && column.name === 'attributes'"
              v-model="filters.attributes_filter"
              clear-if-selected-all
              class="h-mr-2 custom-filter"
              :options="distinctPlayersAttributes"
              @input="onFilterChange"
            />
            <CustomFilter
              v-show="column.name === 'check-in'"
              v-model="filters.checked_in"
              clear-if-selected-all
              class="custom-filter"
              :options="checkinOptions"
              :disabled="isLoading"
              @close="onFilterChange"
            />
          </template>
          <template #body-item="{ item, column }">
            <PlayersTableCell
              v-if="!item.isAddon"
              :column-name="column.name"
              :row="item"
              :is-clan="properties.is_clan"
              :all-attributes="properties.possible_attributes"
              :groups="blocksList"
              @chat="startChat"
              @checkIn="toggleCheckIn"
              @attributesSet="
                attributes => setPlayersExtendedData([item], { attributes })
              "
            />
          </template>
          <template #body-item-addon="{ item }">
            <td :colspan="visibleColumns.length" class="py-0">
              <Table
                :columns="memberColumns"
                :items="item.members"
                class="py-0"
              >
                <template #head-item="{column}">
                  <PlayersTableHeadCell :column-name="column.name" />
                </template>
                <template #body-item="{item: player, column}">
                  <PlayersTableUsernameCell
                    v-if="column.name === 'Username'"
                    :account="{ id: player.id, ...player.fields_data }"
                  />
                  <TextBlock v-else :text="player.fields_data[column.name]" />
                </template>
              </Table>
            </td>
          </template>
        </Table>
      </Paginated>
    </div>
    <!-- row assign to block-->
    <Popover
      class="table-settings-menu"
      :position="tableSettingsBtnPosition"
      :closest="$el"
      :float="['right']"
      @toggle="onMenuToggle"
    >
      <PlayersTableSettingsModal
        v-if="optionalColumns.length"
        :clan="properties.is_clan"
        :optional-columns="optionalColumns"
        @update="onSettingsModalClose"
      />
    </Popover>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { cloneDeep, isNumber, isEmpty } from '@/utils/lodashUtils';
import { cancelAllRequests } from '@/api/requestCancel';
import listMixin from '@/mixins/paginatedLists';
import resetGrid from '@/views/tournaments/management/brackets/mixins/resetGrid';
import { translate } from '@/mixins/translate';
import drawGrid from '@/views/tournaments/management/brackets/mixins/drawGrid';
import downloadFakeLink from '@/utils/downloadFile';
import HorizontalTabs from '@/components/common/HorizontalTabs';
import PlayersTableControls from '@/views/tournaments/management/participants/components/PlayersTableControls';
import PlayersTableSettingsModal from '@/views/tournaments/management/participants/components/PlayersTableSettingsModal';
import CustomFilter from '@/components/form/MultiSelectBox';
import paramsMixin from '@/mixins/queryParams';
import Btn from '@/components/atoms/HeraFormBtn';
import Popover from '@/components/popover';
import Table from '@/components/molecules/Table';
import InfoPanel from '@/components/molecules/InfoPanel';
import Widget from '@/views/tournaments/management/summaryWidgets/Widget';
import PlayersTableHeadCell from '@/views/tournaments/management/participants/components/PlayersTableHeadCell';
import PlayersTableCell from '@/views/tournaments/management/participants/components/PlayersTableCell';
import PlayersTableUsernameCell from './components/PlayersTableUsernameCell';
import TextBlock from '@/components/atoms/TextBlock';
import Paginated from '@/components/common/Paginated';

// TODO move all string 'const' in real const https://isddesign.atlassian.net/browse/TH-7139
const QUALIFIED = 'qualified';
const SCHEDULED_STATUSES = ['scheduled', 'draft'];

export default {
  name: 'StageParticipants',
  components: {
    CustomFilter,
    PlayersTableSettingsModal,
    Paginated,
    Btn,
    HorizontalTabs,
    Table,
    Widget,
    Popover,
    InfoPanel,
    PlayersTableControls,
    PlayersTableHeadCell,
    PlayersTableCell,
    PlayersTableUsernameCell,
    TextBlock,
  },
  mixins: [
    ...listMixin,
    drawGrid,
    resetGrid,
    paramsMixin,
    translate('_web_tournament_stage_participants'),
  ],
  data() {
    return {
      waitForCounterCheck: false,
      isLoading: true,
      fillBlocksLoading: false,
      resetBracketsLoading: false,
      drawLoading: false,
      activePlayer: null,
      filters: {
        search_term: '',
        country_filter: null,
        attributes_filter: null,
      },
      // total: 0,
      players: [],
      columns: [],
      sortFields: {
        field: null,
        order: null,
      },
      isFillModalActive: false,
      resultShowTime: 500,
      tabs: [
        {
          key: 'qualified',
          labelStated: this.$$t('Stage participants'),
          route: 'qualified',
        },
        {
          key: 'all',
          labelStated: this.$$t('All participants'),
          route: 'all',
        },
      ],
      stageID: this.$route.params.stageID,
      isTotalSelected: false,
      isSelectedAllActive: false,
      tableSettingsBtnPosition: null,
      defaultColumns: {
        chat: {
          name: 'chat',
          is_visible: true,
          _tdClass: 'stage-participants-table__chat-column',
          _thClass: 'stage-participants-table__chat-column',
        },
        checkIn: {
          name: 'check-in',
          is_visible: true,
          _tdClass: 'all-participants-table__checkin-column',
          _thClass: 'all-participants-table__checkin-column',
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'currentTournament',
      'getBlocksByStageID',
      'currentTournamentStages',
      'stagePlayersTableSettings',
      'isStageFieldDisabled',
    ]),

    tab() {
      return this.$route.params.tab;
    },
    memberColumns() {
      return this.properties.is_clan && this.players.length
        ? Object.keys(this.players[0].members[0].fields_data).reduce(
            (acc, el) => {
              return el === 'Avatar'
                ? acc
                : acc.concat([
                    {
                      name: el,
                      is_visible: true,
                      _tdClass: '',
                      _thClass: '',
                    },
                  ]);
            },
            []
          )
        : [];
    },
    optionalColumns: {
      get() {
        return this.stagePlayersTableSettings;
      },
      set(val) {
        return val;
      },
    },
    blocksList() {
      const blocks = this.getBlocksByStageID(this.stageID) || [];
      return [
        ...blocks.map(item => ({
          id: item.index,
          name: item.title,
        })),
        { id: null, name: 'Not selected' },
      ];
    },
    isRegistrationClosed() {
      return (
        this.currentTournament &&
        this.currentTournament.status === 'registration_closed'
      );
    },
    distinctPlayersAttributes() {
      return this.properties.possible_attributes || [];
    },
    distinctPlayersApprovedTo() {
      return [
        {
          value: 'requested',
          id: 'requested',
          name: this.$t(
            '_web_tournament_participants_registered',
            'Registered'
          ),
        },
        {
          value: 'qualified',
          id: 'qualified',
          name: this.$t('_web_tournament_participants_qualified', 'Qualified'),
        },
        {
          value: 'reserved',
          id: 'reserved',
          name: this.$t('_web_tournament_participants_reserved', 'Reserved'),
        },
        {
          value: 'revoked',
          id: 'revoked',
          name: this.$t('_web_tournament_participants_revoked', 'Revoked'),
        },
      ];
    },
    checkinOptions() {
      return [
        { value: true, id: 'true', name: 'Checked-in' },
        { value: false, id: 'false', name: 'Not checked-in' },
      ];
    },
    selectedItems() {
      return this.players.filter(player => player._selected);
    },
    selectedAll() {
      return this.selectedItems.length === this.players.length;
    },
    isEmptySelection() {
      return this.selectedItems.length === 0;
    },
    isIndeterminateSelection() {
      return !this.selectedAll && !this.isEmptySelection;
    },
    visibleColumns() {
      return this.columns.filter(c => c.is_visible);
    },
    currentStageInfo() {
      return (
        this.currentTournamentStages.find(item => item.id === this.stageID) ||
        {}
      );
    },
    hasCheckInEnabled() {
      return this.currentStageInfo.check_in_enabled;
    },
    isStageScheduled() {
      return (
        this.currentStageInfo &&
        SCHEDULED_STATUSES.includes(this.currentStageInfo.status)
      );
    },
    amountFooterCounter() {
      return this.isTotalSelected ? this.totalItems : this.selectedItems.length;
    },
    actionsBtnItems() {
      return [
        {
          key: 'fill-blocks',
          title: 'Fill blocks',
          subtitle: this.$t(
            '_web_stage_fill-blocks-tooltip',
            'Auto fill blocks'
          ),
          icon: 'fill',
          handler: this.confirmFillBlocksStage,
          disabled:
            this.isStageFieldDisabled('fill_blocks', this.currentStageInfo) ||
            !this.isStageScheduled ||
            (this.tab !== QUALIFIED && this.isLoading),
        },
        {
          key: 'stage_draw',
          title: 'Draw grid',
          subtitle: this.$t(
            'draw-grid-tooltip',
            'Distribute players to matches'
          ),
          icon: 'draw',
          handler: () => this.confirmDraw(this.currentStageInfo),
          disabled: !this.isStageScheduled || this.drawLoading,
        },
        {
          key: 'reset-brackets',
          title: 'Reset brackets',
          subtitle: this.$t(
            '_web_stage_reset-brackets-tooltip',
            'Press to clear all brackets in current stage'
          ),
          handler: this.confirmResetBrackets.bind(
            null,
            this.currentStageInfo,
            true
          ),
          icon: 'reset',
          // variant: 'success',
          disabled: !this.isStageScheduled,
        },
        {
          key: 'players-export-csv',
          title: 'Export Users to CSV',
          subtitle: this.$t(
            '_web_stage_players-export-csv-tooltip',
            'All selected users will be exported to CSV file'
          ),
          icon: 'csv',
          handler: this.exportToCSV,
          disabled: this.isLoading,
        },
      ];
    },
  },
  watch: {
    tab() {
      this.clearSelection();
      this.fetchTableSettingsData();
      this.clearPlayersState();
      this.fetchList();
    },
  },
  beforeRouteUpdate(to, from, next) {
    cancelAllRequests()
      .then(() => next())
      .catch(this.errorNotify);
  },
  created() {
    let action =
      this.tab === 'all'
        ? 'fetchStageColumnsSettingsAllApplicants'
        : 'fetchStageColumnsSettings';
    this.$store
      .dispatch(action, {
        id: this.$route.params.tournamentID,
        stageID: this.$route.params.stageID,
      })
      .then(() => {
        this.fetchList();
        this.initializeColumns();
      });
    this.addSystemSubscriber(this.systemSubscriber);
  },
  destroyed() {
    this.removeSystemSubscriber(this.systemSubscriber);
  },
  methods: {
    ...mapActions([
      'setStagePlayersExtendedData',
      'setStageAllAppliciantsStatuses',
      'successNotify',
      'errorNotify',
      'fetchStagePlayers',
      'setStagePlayerToBlock',
      'drawStage',
      'checkInParticipant',
      'resetStageBrackets',
      'setFillParticipants',
      'fetchTournamentStage',
      'fetchTournamentBlocks',
      'fetchStageColumnsSettings',
      'setStageColumnsSettings',
      'fetchStageColumnsSettingsAllApplicants',
      'createTournamentPlayersCSV',
      'startTournamentChat',
      'openModal',
      'closeModal',
      'addSystemSubscriber',
      'removeSystemSubscriber',
      'incrementBlockJoinedPlayers',
      'decrementBlockJoinedPlayers',
    ]),
    ...mapMutations(['setTournamentBlocks', 'updateModalProps']),
    systemSubscriber({ type, extended }) {
      if (
        !extended ||
        (extended.tournament_id !== this.tournamentID &&
          extended.stage_id !== this.stageID)
      ) {
        return;
      }

      if (type == 'tournament_stage_user_checked_in') {
        const { player_id, checked_in } = extended;
        const player = this.players.find(el => el.id === player_id);
        if (player) {
          this.setPlayersExtendedData([player], {
            checked_in,
          });
        }
      }
    },
    checkTotalCount(selected) {
      if (!selected) {
        this.isTotalSelected = false;
      }
    },
    setActiveRow(index) {
      const item = this.players[index];
      if (
        this.properties.is_clan &&
        !item.isAddon &&
        index !== this.activeRow
      ) {
        this.activeRow = index;
        const playersCopy = this.players.filter(item => !item.isAddon);
        const realIndex = playersCopy.findIndex(
          player => item.id === player.id
        );
        playersCopy.splice(realIndex + 1, 0, {
          members: item.members,
          isAddon: true,
          hideCheckbox: true,
        });
        this.players = this.$lodash.cloneDeep(playersCopy);
      } else {
        this.activeRow = null;
        this.players = this.players.filter(item => !item.isAddon);
      }
    },
    onSettingsModalOpen(event) {
      this.tableSettingsBtnPosition = event.target;
    },
    onMenuToggle(isVisible) {
      if (!isVisible) {
        this.tableSettingsBtnPosition = null;
      }
    },
    exportToCSV() {
      this.createTournamentPlayersCSV({
        ...this.filters,
        approved_to: this.tab === 'all' ? this.filters.approved_to : this.tab,
      }).then(res => {
        downloadFakeLink(
          new Blob([res], { type: 'text/csv' }),
          this.tournamentID
        );
      });
    },
    getMenuItemVariant(item) {
      return item.disabled ? 'muted' : item.variant || 'primary';
    },
    initializeColumns(columns = this.optionalColumns) {
      let columnsClone = this.$lodash.cloneDeep(columns);
      const usernameColumn = columnsClone.find(c => c.name === 'username');
      usernameColumn._tdClass = 'stage-participants-table__username-column';
      usernameColumn._thClass = 'stage-participants-table__username-column';
      const sortedColumns = columnsClone
        .filter(c => c.name !== 'username')
        .sort((a, b) => a.index - b.index);

      columnsClone = this.properties.is_clan
        ? [
            {
              ...usernameColumn,
              name: 'Clan name',
            },
            ...sortedColumns,
          ]
        : [usernameColumn, ...sortedColumns];
      columnsClone.splice(columnsClone.length, 0, this.defaultColumns.chat);
      if (this.hasCheckInEnabled) {
        columnsClone.splice(
          columnsClone.length,
          0,
          this.defaultColumns.checkIn
        );
      }

      this.columns = columnsClone;
    },
    fetchList(pageNumber) {
      this.pageIndex = pageNumber;
      let orderByPostfix = this.sortFields.order === 'desc' ? ':desc' : '';

      return this.getPageData(
        'players',
        this.fetchStagePlayers({
          tournamentID: this.tournamentID,
          stageID: this.stageID,
          query: this.paramsWithPageNumber({
            order_by: this.sortFields.field
              ? `${this.sortFields.field}${orderByPostfix}`
              : null,
            ...this.filters,
            approved_to:
              this.tab === 'all' ? 'default' : ['reserved', 'qualified'],
          }),
        }).then(data => ({
          ...data,
          items: data.items.map(item => ({
            ...item,
            _selected: this.isTotalSelected,
          })),
        }))
      );
    },
    clearPlayersState() {
      this.pageIndex = 0;
      this.players = [];
    },
    onFilterChange() {
      this.clearPlayersState();
      this.setQueryParams({
        ...this.$lodash.cloneDeep(this.filters),
        page: this.pageIndex,
      });
      this.fetchList();
    },

    selectAllCheckboxHandler(value) {
      this.isTotalSelected = value;
      this.players = this.players.map(player =>
        player.is_stage_added
          ? player
          : {
              ...player,
              _selected: value,
            }
      );
    },
    clearSelection(value) {
      this.selectAllCheckboxHandler(value);
    },
    setActivePlayerAssignBlock({ id: blockIndex }) {
      this.selectedItems.forEach(player => {
        const oldBlockIndex = parseInt(player.fields_data.blockIndex);
        const stageIndex = +this.currentStageInfo.index;
        if (blockIndex !== oldBlockIndex) {
          this.setPlayersExtendedData(this.selectedItems, { blockIndex })
            .then(() => {
              if (isNumber(blockIndex) && blockIndex > -1) {
                this.incrementBlockJoinedPlayers({
                  blockIndex,
                  stageIndex,
                });
              }
              if (isNumber(oldBlockIndex) && oldBlockIndex > -1) {
                this.decrementBlockJoinedPlayers({
                  blockIndex: oldBlockIndex,
                  stageIndex,
                });
              }
            })
            .then(this.refetchBlocks)
            .catch(this.errorNotify);
        }
        this.activePlayer = null;
      });
    },
    setPlayersExtendedData(changedPlayers, data, needMergeAttributes = false) {
      //TODO TH-8246 needs refactoring with backend too (very complicated functionality)
      let players = this.$lodash.cloneDeep(changedPlayers);

      players = Array.isArray(players) ? players : [players];

      const playersCount = players.length;
      if (!playersCount || !data) return;
      const playersBackup = cloneDeep(this.players);
      const propertiesBackup = cloneDeep(this.properties);
      const indexes = players.map(item =>
        this.players.findIndex(el => el.id === item.id)
      );
      const tempPlayers = cloneDeep(this.players);
      for (let i of indexes) {
        tempPlayers[i] = {
          ...tempPlayers[i],
          fields_data: {
            ...tempPlayers[i].fields_data,
            ...data,
          },
        };
      }
      //merge needed only for group operations in footer
      if (needMergeAttributes) {
        players.forEach(item => {
          let attributes = item.fields_data.attributes || [];
          attributes =
            typeof attributes === 'string' ? attributes.split(',') : attributes;
          item.fields_data = {
            ...item.fields_data,
            ...data,
            attributes: this.$lodash.uniq([
              ...(data.attributes || []),
              ...attributes,
            ]),
          };
        });
      } else {
        players.forEach(item => {
          item.fields_data = {
            ...item.fields_data,
            ...data,
            attributes: [...(data.attributes || [])],
          };
        });
      }

      //TODO: delete this hack https://isddesign.atlassian.net/browse/TH-7140
      const module =
        this.tab === 'all'
          ? 'setStageAllAppliciantsStatuses'
          : 'setStagePlayersExtendedData';
      return this[module]({
        stageID: this.stageID,
        players,
        filters: this.filters,
        isTotalSelected: this.isTotalSelected,
        approvedTo: data.approvedTo,
      })
        .then(() => {
          this.players = tempPlayers;
          this.clearSelection(false);
        })
        .catch(error => {
          this.players = playersBackup;
          this.properties = propertiesBackup;
          this.errorNotify(error);
        });
    },

    handleSort(field) {
      const transformedField = this.transformField(field);
      if (
        this.sortFields.order === 'incr' &&
        this.sortFields.field === transformedField
      ) {
        this.sortFields.order = 'desc';
      } else {
        this.sortFields.order = 'incr';
      }
      this.sortFields.field = transformedField;
      this.onFilterChange();
    },

    transformField(field) {
      // TODO: create general implementation for sorting
      return field === 'username'
        ? 'name'
        : field === 'blockIndex'
        ? 'block'
        : this.$lodash.snakeCase(field);
      // const transformedField = field.replace(/\s+/g, '');
      // return (
      //   transformedField.charAt(0).toLowerCase() + transformedField.substring(1)
      // );
    },
    confirmPartialDrawStage() {
      this.closeModal();
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_block_wrong_players_counters_modal_title',
            'At least one block contains number of participants different from its settings. If you will proceed, system will create a bracket according to the settings anyway.'
          ),
          isLoading: this.waitForCounterCheck,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onDrawStage.bind(this, true),
        },
      });
    },
    onDrawStage(isPartial = false) {
      this.updateModalProps({ isLoading: true });
      return this.drawStage({
        tournamentID: this.tournamentID,
        stageID: this.stageID,
        isPartial,
      })
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament_players_was-drawn',
              'Stage was successfully drawn'
            )
          );
          this.fetchList();
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.updateModalProps({ isLoading: false });
          this.closeModal();
        });
    },

    confirmFillBlocksStage() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_stage_qualifiers_fill_modal_title',
            'This action will trigger authomatic blocks filling by qualified players. Do you want to continue?'
          ),
          isLoading: this.fillBlocksLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onFillBlocksStage,
        },
      });
    },
    onFillBlocksStage(isPartial = true) {
      this.updateModalProps({ isLoading: true });
      this.setFillParticipants({
        id: this.tournamentID,
        stageID: this.stageID,
        isPartial,
      })
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament_players_blocks-filled',
              'Stage blocks were successfully filled'
            )
          );
        })
        .then(this.refetchStage)
        .then(this.refetchBlocks)
        .catch(this.errorNotify)
        .finally(() => {
          this.updateModalProps({ isLoading: false });
          this.closeModal();
          this.onFilterChange();
        });
    },
    fetchTableSettingsData() {
      let action =
        this.tab === 'all'
          ? 'fetchStageColumnsSettingsAllApplicants'
          : 'fetchStageColumnsSettings';
      return this[action]({
        id: this.tournamentID,
        stageID: this.stageID,
      })
        .then(response => (this.optionalColumns = response))
        .catch(this.errorNotify);
    },
    onSettingsModalClose(columns) {
      if (isEmpty(columns)) {
        this.isSettingsModalActive = false;
        return;
      }
      //actions
      this.setStageColumnsSettings({
        id: this.tournamentID,
        columns,
        stageID: this.stageID,
      })
        .then(() => {
          let activeColumns = columns.filter(i => i.is_visible === true);
          this.isSettingsModalActive = false;
          if (activeColumns && Array.isArray(activeColumns)) {
            this.initializeColumns(activeColumns);
            this.pageIndex = 0;
            this.players = [];
            this.fetchTableSettingsData().then(this.fetchList);
          }
        })
        .catch(this.errorNotify);
    },

    refetchStage(id = this.stageID) {
      return this.fetchTournamentStage(id);
    },
    refetchBlocks() {
      const { index } = this.currentStageInfo;
      if (isFinite(index)) {
        return this.fetchTournamentBlocks({
          stage: index,
        }).then(blocks => {
          this.setTournamentBlocks(blocks);
          return blocks;
        });
      } else {
        throw new Error('wrong stage index');
      }
    },
    mapBlocksToParticipantsCounters(blocks = []) {
      return blocks.map(el => ({
        players_joined: el.players_joined,
        players_required: el.players_required,
      }));
    },
    checkParticipantsCounters(counters = []) {
      return counters.some(el => el.players_joined !== el.players_required);
    },
    toggleCheckIn(playerData) {
      this.checkInParticipant({
        tournamentID: this.$route.params.tournamentID,
        stageID: this.$route.params.stageID,
        playerID: playerData.id,
      })
        .then(() => {
          this.setPlayersExtendedData([playerData], {
            checked_in: !playerData.fields_data.checked_in,
          });
        })
        .catch(this.errorNotify);
    },
    validateParticipantsCountersCheck(hasWrongCounters = false) {
      if (!hasWrongCounters) {
        this.waitForCounterCheck = false;
        this.notifyUserAboutWrongCounters();
        throw false;
      }
      return true;
    },
    notifyUserAboutWrongCounters() {
      this.closeModal();
      this.confirmPartialDrawStage();
    },
    onDraw() {
      this.updateModalProps({ isLoading: true });
      Promise.resolve()
        .then(this.refetchStage)
        .then(this.refetchBlocks)
        .then(this.mapBlocksToParticipantsCounters)
        .then(this.onDrawStage)
        .catch(this.errorNotify)
        .then(this.refetchBlocks)
        .catch(this.errorNotify)
        .finally(() => {
          this.updateModalProps({ isLoading: false });
          this.closeModal();
        });
    },
    startChat(player) {
      this.startTournamentChat(player).catch(this.errorNotify);
    },
  },
};
</script>
<style lang="scss">
.stage-players-list {
  .hera-table-wrap,
  .hera-table-wrap .table {
    @include auto-rtl(border-top-left-radius, 0);
    tr.has-addon {
      background-color: $ph-card !important;
    }
  }
  .selected-count {
    text-transform: capitalize;
    font-weight: normal;
  }
  .custom-filter {
    max-width: 12rem;
  }
  .dropdown-popover {
    min-width: 10rem !important;
  }
  .stage-participants-table {
    margin-left: 15px;
    margin-right: 15px;
    &__username-column {
      width: 230px;
    }
    &__chat-column {
      width: 40px;
    }
  }
}

.popover-toggle {
  display: inline-block !important;
  background-color: transparent !important;
  color: $ph-accent !important;
  font-weight: bold;
  padding-left: 0;

  &:focus {
    box-shadow: none !important;
  }
}
</style>
