export default {
  data() {
    return {
      isLoading: false,
      isCrashed: false,
    };
  },
  methods: {
    setLoading() {
      this.isLoading = true;
      return Promise.resolve();
    },
    unsetLoading() {
      this.isLoading = false;
      return Promise.resolve();
    },
    toggleLoading(val) {
      this.isLoading = !!val;
    },
    toggleCrashed(val) {
      this.isCrashed = !!val;
    },
  },
};
