<template>
  <StatusLabel
    :status="tournament.status"
    :is-admin="isOrganizer"
    for-tournament
  />
</template>

<script>
import { prop, propRequired } from '@/utils/factories';
import StatusLabel from '@/components/molecules/StatusLabel';

export default {
  name: 'TournamentsListPinnedItemStatusLabel',
  components: { StatusLabel },
  props: {
    tournament: propRequired(Object),
    isOrganizer: prop(Boolean),
  },
};
</script>

<style lang="scss">
.tournament-cover_status-label {
  border-radius: 0.5rem;
  min-width: unset !important;
  color: unset;
  background-color: transparent;
  span {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
  }
}
</style>
