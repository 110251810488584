<template>
  <div class="main-wrapper-organisers">
    <div class="content-wrap-organisers">
      <div class="organisers-list-wrapper">
        <div class="inner-wrap-organisator">
          <div class="top-organisers-title">
            <h2>
              {{ $t('_web_tournament-organisers-title', 'Top Organisers') }}
            </h2>
          </div>
          <div
            v-for="(organisator, index) in organisators"
            :key="index"
            class="organisers-card"
          >
            <a :href="linkPrefix + organisator.id">
              <div
                :style="organisator.bgColor"
                class="img-organisator-container"
              >
                <img
                  :src="
                    require(`@/assets/images/organisators/${
                      organisator.avatar
                    }`)
                  "
                  width="48px"
                  height="48px"
                  :style="organisator.color"
                  class="avatar-organisations"
                  alt="avatar"
                />
              </div>
              <h3>
                {{
                  $t(
                    `_web_tournament-organisers-${organisator.translation}`,
                    `${organisator.title}`
                  )
                }}
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { translate } from '@/mixins/translate';
export default {
  name: 'TopOrganisersFeed',
  mixins: [translate('_web_tournament')],
  data() {
    return {
      scrollWidth: null,
      linkPrefix: 'https://playhera.com/organizations/',
      organisators: [
        {
          id: '3211e1d1-6036-42f6-aead-9828964d1204',
          avatar: 'playheraLogo.png',
          title: 'PLAYHERA MENA',
          color: 'border: 1.5px solid #4A4A4A',
          translation: 'play-hera-top',
        },
        {
          id: 'f9178b7e-2319-4104-9101-65a4e8d2525d/',
          avatar: 'Al-Hilal.svg',
          title: 'ALHILAL ESPORTS',
          color: 'height: 30px;',
          bgColor: 'background-color: white',
          translation: 'Al-Hilal-top',
        },
        {
          id: '01dd9a7a-8ece-451a-b2d7-0ed7b82bba96/',
          avatar: 'ALSHABAB.svg',
          title: 'AL SHABAB',
          color: 'background-color : black',
          translation: 'al-shabab-top',
        },
        {
          id: '4602408d-0ae5-4b74-ac66-ad6547b8dd16/',
          avatar: 'ZainKSA.svg',
          title: 'ZAIN KSA',
          color: 'background-color : black',
          translation: 'zain-top',
        },
        {
          id: '74b88b2b-3f84-4891-878e-af3301f3ee49/',
          avatar: 'R8Soon.svg',
          title: 'R8 Esports',
          color: 'background-color : black',
          translation: 'r8-esports-top',
        },
        {
          id: '6a0c81cf-8656-489b-88db-ca178848d529/',
          avatar: 'zainEsports.webp',
          title: 'Zain Esports - Iraq',
          color: 'background-color : black',
          translation: 'zain-esports-top',
        },
        {
          id: 'e06c82e4-d287-4d61-a399-60d70a4f0286/',
          avatar: 'gcon.svg',
          title: 'GCON EVENTS',
          color: 'background-color : #189F8F ; height: 10px;',
          translation: 'gcon-top',
        },
      ],
    };
  },
  computed: {
    ...mapState('my', ['account']),
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper-organisers {
  font-family: $hr-font-family;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-wrap-organisers {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.top-organisers-title {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-bottom: 17px;
    font-size: $hr-text-regular;
    font-style: bold;
    line-height: 33px;
    line-height: 110%;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.organisers-list-wrapper {
  display: flex;

  .organisers-card a h3 {
    @include auto-rtl(padding-left, 1rem);
  }
  .inner-wrap-organisator {
    display: flex;
    width: 100%;
    gap: 24px;
    flex-direction: column;
  }

  .avatar-organisations {
    border-radius: 100px;
    background-color: white;
    height: 48px;
  }

  .organisers-card {
    a {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;

      &:hover {
        text-decoration: none;
        color: white;
      }

      h3 {
        text-transform: uppercase;
        text-align: left;
        margin: 0px;
        font-size: $hr-text-regular;
      }
    }
  }

  .organisers-card:hover h3 {
    color: #dfdfdf;
  }
}

.img-organisator-container {
  display: flex;
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  background-color: #189f8f;
  border-radius: 100px;
}
</style>
