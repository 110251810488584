<template>
  <Panel v-if="post" variant="secondary-card" class="position-relative post">
    <Loader v-if="isPinning" />
    <div
      v-if="post.type !== 'banner'"
      ref="postContainer"
      class="actions-post-item position-absolute"
    >
      <AppIcon
        v-if="post.pinned || canPinPost"
        name="pin"
        width="15"
        :fill="post.pinned ? $scss.primaryColor : $scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        class="cursor-pointer post-pin"
        :class="{ disabled: !canPinPost }"
        @click="pinAction"
      />

      <FollowBtn
        v-if="showFollowButton && showButton && isAuthorized"
        :key="post.id"
        :model="model"
        class="follow-button-newsfeed"
        follow-class="btn-primary-new"
        hover-class="btn-hover-new"
        unfollow-class="btn-secondary-new"
      />

      <AppIcon
        v-if="isDotsMenuVisible"
        name="dots"
        class="cursor-pointer post-dots py-2"
        :fill="$scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        @click="openPopoverMenu"
      />
    </div>

    <PostCard v-bind="{ post, type }" />
    <PostCard
      v-if="post.original_post"
      :post="post.original_post.post"
      :type="post.original_post.post_type"
      reposted
      class="repost-spacing"
    />
    <div v-if="post && post.reactions" class="d-flex mt-5 post-actions">
      <a
        href="#"
        class="like-link"
        :class="{
          active:
            post &&
            post.reactions &&
            post.reactions.like &&
            post.reactions.like.reacted,
        }"
        @click.prevent="likeClick"
      >
        <img
          v-if="
            post &&
              post.reactions &&
              post.reactions.like &&
              !post.reactions.like.reacted
          "
          src="@/assets/images/comments/like-empty.svg"
          alt="like button"
          class="likes"
        />
        <img
          v-else
          src="@/assets/images/comments/like-filled.svg"
          alt="like button"
          class="like-button"
        />
        <div
          class="like-count-post"
          :class="{
            'like-count-reacted':
              post &&
              post.reactions &&
              post.reactions.like &&
              post.reactions.like.reacted,
          }"
        >
          {{ post.reactions.like.count || 0 }}
          <span class="mobile-hide">
            {{
              isRTL
                ? post.reactions.like.count >= 10
                  ? $t('_web_comment_likes', 'likes')
                  : $t('_web_comment_like', 'like')
                : post.reactions.like.count === 1
                ? $t('_web_comment_like', 'like')
                : $t('_web_comment_likess', 'likes')
            }}
          </span>
        </div>
      </a>
      <!--<a
        href="#"
        class="h-ml-4 dislike-link"
        :class="{ active: post.reactions.dislike.reacted }"
        @click.prevent="dislike"
      >
        <AppIcon name="dislike" class="dislikes" width="17" />
        <span>{{ post.reactions.dislike.count }}</span>
      </a>-->
      <a
        href="#"
        class="h-ml-5 comments-link"
        :class="{
          active: post && post.comments_count && post.comments_count === 0,
        }"
        @click.prevent="showComments = !showComments"
      >
        <AppIcon name="comments" class="comments" width="17" />
        <div
          class="text-bottom"
          :class="!showComments ? '' : 'text-bottom-bold'"
        >
          {{ (post && post.comments_count) || 0 }}
          <span class="mobile-hide">
            {{
              isRTL
                ? post.comments_count >= 10
                  ? $t('_web_tournament_sidechat_comments', 'comments')
                  : $t('_web_tournament_sidechat_comment', 'comment')
                : post.comments_count === 1
                ? $t('_web_tournament_sidechat_comment', 'comment')
                : $t('_web_tournament_sidechat_comments', 'comments')
            }}</span
          >
        </div>
      </a>
      <a
        href="#"
        class="h-ml-5 repost-link"
        :class="{ active: post.repost_count > 0 }"
        @click.prevent="
          $event =>
            openSharingMenu({
              event: $event,
              type: 'SinglePost',
              id: post.id,
              text: post.text,
            })
        "
      >
        <AppIcon name="share" class="repost" width="15" />
        <div class="text-bottom">
          {{ (post && post.repost_count) || 0 }}
          <span class="mobile-hide">
            {{
              isRTL
                ? post.repost_count > 10
                  ? $t('_web_comments_share', 'share')
                  : $t('_web_comments_shares', 'shares')
                : post.repost_count === 1
                ? $t('_web_comments_share', 'share')
                : $t('_web_comments_shares', 'shares')
            }}</span
          >
        </div>
      </a>
    </div>
    <div v-if="post" class="braking-lineing"></div>
    <Comments v-if="showComments" :post="post" class="comments-smaller" />
  </Panel>
</template>

<script>
import { sharingMenu } from '@/mixins/sharingMenu';
import { API } from '@/api/config';
import { mapActions, mapGetters, mapState } from 'vuex';
import { GENERIC, INJECTION_POSTS } from '@/constants/postTypes';
import AppIcon from '@/components/atoms/Icon';
import Panel from '@/components/atoms/Panel.vue';
import PostCard from './PostContent.vue';
import Comments from '@/components/comments/Comments';
import Loader from '@/views/sliderchat/Loader';
import FollowBtn from '../common/NewFollowBtn.vue';
import AccountModel from '@/models/Account';

export default {
  name: 'Post',
  components: { AppIcon, Comments, FollowBtn, Loader, PostCard, Panel },
  mixins: [sharingMenu],
  props: {
    post: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      default: GENERIC,
    },
  },

  data() {
    return {
      showFollowButton: false,
      isLoading: false,
      isLoadingRepost: false,
      isPinning: false,
      showDeleteConfirm: false,
      showComments: this.$route.name === 'SinglePost' ? true : false,
      linkPreview: null,
      debouncedPreviewRequest: null,
      model: new AccountModel(),
      thumbnail: this.post.thumbnail,
      showButton: false,
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId', 'accountType']),
    ...mapGetters(['permissions']),
    ...mapGetters(['isRTL', 'isAuthorized']),
    isDotsMenuVisible() {
      return !INJECTION_POSTS.includes(this.type) && this.isAuthorized;
    },
    isMyPost() {
      const postAuthorId =
        this.post && this.post.author
          ? this.post.author.id || this.post.author.login
          : null;
      return this.accountId === postAuthorId;
    },
    canPinPost() {
      return this.permissions.pinPost && this.isMyPost;
    },
  },
  mounted() {
    this.isAuthorized &&
      this.$nextTick(() => {
        const postContainer = this.$refs.postContainer;
        if (postContainer instanceof Element) {
          const options = {
            rootMargin: '-30% 0px -40% 0px',
            threshold: 0.1, // Adjust the threshold as needed
          };
          const observer = new IntersectionObserver(
            this.handleIntersection,
            options
          );
          observer.observe(postContainer);
        } else {
          console.error('cannot find post container');
        }
      });
  },
  created() {
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
  },
  methods: {
    ...mapActions([
      'getOpenGraphDataByLink',
      'likePost',
      'repost',
      'togglePostPin',
      'errorNotify',
      'successNotify',
      'openModal',
      'closeModal',
    ]),
    handleNonLoggeedUsers() {
      this.openModal({
        name: 'SigninActionModal',
        settings: {
          variant: 'fit-content',
        },
        events: {
          cancel: this.closeModal,
        },
      });
    },
    handleIntersection(entries) {
      const [entry] = entries;
      this.showButton = entry.isIntersecting;
      if (entry.isIntersecting && !this.isMyPost && this.post.author) {
        this.getPostAuthorProfile(this.post.author.login);
      } else {
        this.showFollowButton = false;
      }
      if (entry.isIntersecting && this.post.tournament) {
        let tournament = this.post.tournament;
        const gameID = 'desktop';
        const gameName = tournament.game;
        const tournamentId = tournament.id;
        const tournamentName = tournament.name || tournament.title;
        const from = this.$route.path;
        const squadSize = tournament.squad_size;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'tournament viewed',
          gameID: gameID,
          gameName: gameName,
          tournamentId: tournamentId,
          tournamentName: tournamentName,
          squadSize: squadSize,
          from: from,
        });
      } else if (entry.isIntersecting && !this.post.tournament) {
        let post = this.post;
        const from = this.$route.path;
        const postId = post.id;
        const postAuthor = post.author.name;
        const hasAttachments = !!post.attachments;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'post viewed',
          postId: postId,
          postType: post.poll
            ? 'poll'
            : post.tournament
            ? 'tournament'
            : 'regular',
          postAuthor: postAuthor,
          from: from,
          hasAttachments: hasAttachments,
          userID: this.accountId,
        });
      }
      if (entry.isIntersecting && this.post.tournament) {
        let tournament = this.post.tournament;
        const gameID = 'desktop';
        const gameName = tournament.game;
        const tournamentId = tournament.id;
        const tournamentName = tournament.name || tournament.title;
        const from = this.$route.path;
        const squadSize = tournament.squad_size;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'tournament viewed',
          gameID: gameID,
          gameName: gameName,
          tournamentId: tournamentId,
          tournamentName: tournamentName,
          squadSize: squadSize,
          from: from,
        });
      } else if (entry.isIntersecting && !this.post.tournament) {
        let post = this.post;
        const from = this.$route.path;
        const postId = post.id;
        const postAuthor = post.author.name;
        const postAttachments = post.attachments ? true : false;
        const hasAttachments = postAttachments;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'post viewed',
          postId: postId,
          postType: post.poll
            ? 'poll'
            : post.tournament
            ? 'tournament'
            : 'regular',
          postAuthor: postAuthor,
          from: from,
          hasAttachments: hasAttachments,
          userID: this.accountId,
        });
      }
    },

    async getPostAuthorProfile(id) {
      const response = await API(`/profiles/${id}`);
      this.model = new AccountModel(
        response.data.type,
        response.data.login
      ).update(response.data);
      this.showFollowButton = true;
    },
    getOpenGraphData(link) {
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },
    postReaction(data) {
      const { post } = this;

      // Like Post
      if (data.type === 'Like') {
        if (post.reactions.like.reacted && this.isAuthorized) {
          post.reactions.like.reacted = false;
          post.reactions.like.count--;
        } else {
          post.reactions.like.reacted = true;
          post.reactions.like.count++;

          if (post.reactions.dislike.reacted) {
            post.reactions.dislike.reacted = false;
            post.reactions.dislike.count--;
          }
        }

        // Dislike post
      } else {
        if (post.reactions.dislike.reacted && this.isAuthorized) {
          post.reactions.dislike.reacted = false;
          post.reactions.dislike.count--;
        } else {
          post.reactions.dislike.reacted = true;
          post.reactions.dislike.count++;

          if (post.reactions.like.reacted) {
            post.reactions.like.reacted = false;
            post.reactions.like.count--;
          }
        }
      }
    },
    likeClick() {
      if (this.isAuthorized) {
        this.likePost({
          id: this.post.id,
          type: 'Like',
          reacted: this.post.reactions.like.reacted,
        });
        this.postReaction({
          type: 'Like',
          reacted: this.post.reactions.like.reacted,
        });
        const hasAttachments = !!this.post.attachments;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'post reacted',
          from: this.$route.path,
          hasAttachments: hasAttachments,
          postAuthor: this.post.author.name,
          postType: this.type,
          postId: this.post.id,
          userID: this.accountId,
        });
      } else {
        this.handleNonLoggeedUsers();
        // this.$router.push({ name: 'signin' });
      }
    },
    dislike() {
      if (this.isAuthorized) {
        this.likePost({
          id: this.post.id,
          type: 'Dislike',
          reacted: this.post.reactions.dislike.reacted,
        });
        this.postReaction({
          type: 'Dislike',
          reacted: this.post.reactions.dislike.reacted,
        });
      } else {
        // this.$router.push({ name: 'signin' });
        this.handleNonLoggeedUsers();
      }
    },
    onRepost() {
      if (this.isAuthorized) {
        const post =
          this.$lodash.get(this, 'post.original_post.post') || this.post;

        const data = {
          text: '',
          original_post_id: post.id,
          reposted_post_id: this.post.id,
        };

        this.repost(data)
          .then(() => {
            this.post.repost_count++;
            window.dataLayer.push({
              event: 'post sharing',
              postId: this.post.id,
              userID: this.accountId,
              shareType: 'Repost',

              from: this.$route.path,
            });

            this.$emit('reposted');
          })
          .catch(this.errorNotify);
      } else {
        // this.$router.push({ name: 'signin' });
        this.handleNonLoggeedUsers();
      }
    },
    openPopoverMenu(event) {
      this.$emit('dotsClick', event.currentTarget);
    },
    pinAction() {
      this.isPinning = true;
      this.togglePostPin(this.post)
        .then(() => {
          this.isPinning = false;
          this.$emit('pinned', this.post);
        })
        .catch(this.errorNotify);
    },
    // restoreCommentsDefaults() {
    //   this.nextPageToken = '';
    //   this.comments = [];
    //   this.isLoading = false;
    //   this.commentLoading = false;
    //   this.willShowComments = false;
    // },
    // showComments(list) {
    //   if (!this.willShowComments && list > 0) {
    //     // this.comments = [];
    //     // this.getComments();
    //   }
    //
    //   // if (this.willShowComments) {
    //   //   this.restoreCommentsDefaults();
    //   // } else {
    //   //   this.willShowComments = true;
    //   // }
    // },
  },
};
</script>

<style lang="scss">
/* TODO - TH-7127 use here loader from infinite scroll*/
/* TODO - TH-7340 refactor */
$spin-wrap-height: 5rem;
$spin-size: 1rem;
$spin-offset-y: 1.5rem; //calc(math.div($spin-wrap-height, 2) - math.div($spin-size, 2));

%spin-style {
  content: '';
  display: block;
  height: $spin-size;
  width: $spin-size;
  position: absolute;
  left: 50%;
  border: 2px solid transparent;
  border-left-color: $ph-accent;
  border-bottom-color: $ph-accent;
  border-radius: 50%;
  transform: translateX(-50%);
  @include rotate-element;
}

.post {
  /*  border: 1px solid #222529; */
  .actions-post-item {
    top: 1.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    @include auto-rtl(right, 20px !important);

    .follow-button-newsfeed {
      height: 1.75rem;
      min-width: 60px !important;

      @media (max-width: 560px) {
        display: none;
      }
    }

    @include auto-rtl(right, 0);

    @media (max-width: 767px) {
      top: 1rem;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #272732;
    // margin: $ph-large-space 0;
  }

  &-dots {
    @include auto-rtl(right, 0.5rem);
  }

  &-pin {
    top: 1.9rem;
    @include auto-rtl(right, 4rem);
  }

  .post-actions {
    /* padding: 0; */
    font-weight: bold;
    //margin-top: 29px !important;
    @include auto-rtl(margin-left, 64px);

    @media (max-width: 768px) {
      @include auto-rtl(margin-left, 50px);
    }

    a,
    span svg {
      transition: fill 0.3s ease, color 0.3s ease;
    }

    span svg {
      @include auto-rtl(margin-right, $ph-small-space);
    }

    .playhera-icon {
      position: relative;

      &.likes {
        top: -2px;
      }

      &.dislikes {
        top: 3px;
      }

      &.comments {
        top: 0;
      }
    }

    a {
      color: $ph-secondary-text;
      display: flex;
      align-items: center;
      text-decoration: none;

      span svg use {
        fill: $ph-secondary-text;
      }

      &:hover,
      &.active {
        color: $ph-primary-text;

        span svg use {
          fill: $ph-primary-text;
        }
      }
    }
  }
}

.post-comments {
  &-sum {
    font-family: $ph-font-family-secondary;
  }

  .comment-item {
    font-size: $ph-body-font-size;
    margin-top: 15px !important;
    margin-bottom: 15px !important;

    &.form.loading {
      position: relative;

      /* TODO - TH-7127 loading from infinite scroll */
      &:after {
        top: 0.6rem;
        @extend %spin-style !optional;
        bottom: $spin-offset-y;
      }

      > div {
        display: none;
      }
    }

    .comment-input-footer {
      display: flex;
      justify-content: space-between;
      @include auto-rtl(padding-left, 50px);
    }

    .comment-input-action {
      width: 19px;
      cursor: pointer;
      @include auto-rtl(margin-right, $ph-small-space);

      button {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 0;
        border: none;

        &:hover {
          background-color: transparent;

          svg {
            fill: $ph-accent !important;
          }
        }
      }
    }

    .comment-header {
      color: $ph-secondary-text;

      .login {
        font-family: $ph-font-family-secondary;
        font-size: $ph-body-font-size;
        font-weight: 600;
        color: $ph-primary-text;
        line-height: $ph-small-text-line-height;
        margin: 0 $ph-medium-space;
      }

      time {
        font-size: $ph-small-text-font-size;
        color: $ph-secondary-text;
      }
    }

    .comment-body {
      @include auto-rtl(margin-left, 64px !important);

      @media (max-width: 767px) {
        @include auto-rtl(margin-left, 50px !important);
      }
    }

    .sc-post-attach {
      padding: 0;
    }
  }
}

.post {
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.comment-input-footer {
  display: none !important;
}

.repost-spacing {
  margin-left: 64px;
  @include auto-rtl(margin-left, 64px);
}

.text-bottom {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #c1c7d7;
}

.braking-lineing {
  height: 1px;
  margin-top: 29px;
  background: #30374a;
  @include auto-rtl(margin-left, 64px);

  @media (max-width: 767px) {
    @include auto-rtl(margin-left, 50px);
  }
}

.text-bottom-bold {
  font-weight: 700;

  span svg use {
    fill: $ph-primary-text;
  }
}

.comments-smaller {
  @include auto-rtl(margin-left, 64px);

  h2 {
    display: none;
  }

  hr {
    display: none;
  }

  @media (max-width: 767px) {
    @include auto-rtl(margin-left, 50px);
  }
}

@media (max-width: 767px) {
  .post {
    padding: 10px;

    &-dots {
      top: 0.75rem;
    }
  }

  .post {
    .comment-header {
      .replies-smaller {
        word-wrap: normal !important;
      }

      .comment-timestamp {
        font-size: 10px;
      }
    }

    .comments-smaller {
      .post-comments {
        .replies-smaller {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap !important;

          .comment-author-name {
            margin-right: 10px !important;
            @include auto-rtl(margin-right, 10px !important);
          }

          .comment-timestamp {
            font-size: 11px;
            margin-left: 0px !important;
            @include auto-rtl(margin-left, 0px !important);
          }
        }
      }

      .placeholder {
        font-size: 14px;
      }

      .post-comments-sum {
        padding: 5%;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }

      .gif-icon {
        display: none !important;
      }

      .emoji-icon {
        display: none !important;
      }

      .comment-section {
        // padding-left: 5%;
        // padding-right: 5%;

        max-width: 95vw !important;
        word-break: normal !important;

        .bdi-wraper-text {
          display: block;
          text-align: start;
        }

        .login {
          .avatar {
            @media (max-width: 768px) {
              width: 32px !important;
              height: 32px !important;
              min-width: unset !important;
            }
          }
        }
      }

      hr {
        margin: 0;
      }

      .comment-input-footer {
        margin-top: 0px !important;
      }

      .sc-post-attach {
        max-width: 50vw;
      }

      .text-input-container {
        // margin-right: 5% !important;
        // background-color: #181c25 !important;
      }

      .text-truncate {
        // background-color: #0d1117 !important;
      }

      .textarea-container {
        // background-color: #0d1117 !important;
        // max-width: 60vw !important;
      }

      .textarea {
        // background-color: #181c25 !important;
      }

      .textarea-bottom {
        // background-color: #181c25 !important;
      }

      .comment-input-action-emoji {
        display: none;
      }

      .comment-input-action-gif {
        display: none;
      }
    }
  }
}

.like-count-post {
  color: #c1c7d7;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  @include auto-rtl(margin-left, 8px);
}

.like-count-reacted {
  color: #9d9cf7;
}
</style>
