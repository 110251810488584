<template>
  <footer class="desktop-footer-wrapper">
    <div class="footer-leftside">
      <HeraLangSwitcher
        class="col text-center px-2 font-size-xs d-flex"
        bottom-position
      />
      <HeraRegionSwitcher
        v-if="showRegionSwitcher"
        class="col text-center px-2 font-size-xs d-flex"
        bottom-position
      />
      <span class="privacy-policy">
        <a :href="isRTL ? '/static/privacypolicy/other' : privacyLink">
          {{ $t('_web_footer_privacy', 'Privacy') }}
        </a>
      </span>
      <span class="refund">
        <a :href="isRTL ? '/static/terms/other' : termsLink">
          {{ $t('_web_footer_terms-and-conditions', 'Terms & Conditions') }}
        </a>
      </span>
      <span class="terms-and-co">
        <a :href="isRTL ? '/static/refund/other' : '/static/refund/mena'">
          {{ $t('_web_footer_refund-policy', 'Refund Policy') }}
        </a>
      </span>

      <span class="online-child-safety">
        <a
          :href="isRTL ? childSafetyAr : childSafetyEn"
          download
          target="_blank"
        >
          {{ $t('_web_footer_online-child-safety', 'Online child safety') }}
        </a>
      </span>
    </div>
    <div class="footer-rightside">
      <span class="contact-us">
        <a :href="'/support'">{{
          $t('_web_footer_contact_us', 'Contact Us')
        }}</a>
      </span>
      <span class="copyrights">&copy; 2024 PLAYHERA</span>
      <div v-if="hasASponsor" class="powered-by-container">
        <img
          v-if="isPoweredByZain"
          src="@/assets/images/general-sponsors/zain.png"
          alt="Zain Logo"
          style="height: auto; width: auto;"
        />
        <img
          v-if="account && account.country === 'Oman'"
          src="@/assets/images/general-sponsors/omantel.png"
          alt="Zain Logo"
          style="height: auto; width: auto;"
        />
      </div>
    </div>
  </footer>
</template>
<script>
import { EMAIL_SUPPORT, PRIVACY_LINK, TERMS_LINK } from '@/api/config';
import HeraLangSwitcher from '@/components/common/HeraLangSwitcher';
import HeraRegionSwitcher from '@/components/common/HeraRegionSwitcher';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HeraDesktopFooter',
  components: {
    HeraLangSwitcher,
    HeraRegionSwitcher,
  },
  props: {
    showRegionSwitcher: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mailSupport: EMAIL_SUPPORT,
      termsLink: TERMS_LINK,
      privacyLink: PRIVACY_LINK,
      childSafetyAr:
        'https://heraqaindiastorage.blob.core.windows.net/public-images/Safety-Booklet-Ar.pdf',
      childSafetyEn:
        'https://heraqaindiastorage.blob.core.windows.net/public-images/Safety-Booklet-En.pdf',
      countriesMena: [
        'Morocco',
        'Algeria',
        'Libya',
        'Egypt',
        'Syria',
        'Lebanon',
        'Palestine',
        'Jordan',
        'Saudi Arabia',
        'UAE',
        'Bahrein',
        'Kuwait',
        'Iraq',
      ],
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['appId', 'isAuthorized', 'isRTL']),
    currentYear() {
      return new Date().getFullYear();
    },
    hasASponsor() {
      const region = JSON.parse(localStorage.getItem('region'));
      return region.id == 'mena';
    },

    isPoweredByZain() {
      return this.account && this.countriesMena.includes(this.account.country);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 906px) {
  .desktop-footer-wrapper {
    display: none;
  }
}
footer {
  width: 100%;
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: $new-bg-page-faded;
  align-items: center;
  padding-left: $hr-spacing-07;
  padding-right: $hr-spacing-07;
  text-align: center;

  .footer-leftside {
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;

    > span {
      align-self: center;
    }
  }

  .online-child-safety {
    padding-left: $hr-spacing-08;
    padding-right: $hr-spacing-08;
  }

  .privacy-policy {
    padding-left: $hr-spacing-09;
    padding-right: $hr-spacing-09;
  }

  a {
    font-family: $hr-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: $hr-text-tiny;
    letter-spacing: 0.16px;
    color: $hr-text-footer;

    &:hover {
      color: $hr-text-footer;
      text-decoration: none;
    }
  }
  .refund {
    padding-right: $hr-spacing-09;
    .app-rtl & {
      padding-left: $hr-spacing-09;
      padding-right: 0px !important;
    }

    a {
      font-family: $hr-font-family;
      font-style: normal;
      font-weight: 400;
      font-size: $hr-text-tiny;
      letter-spacing: 0.16px;
      color: $hr-text-footer;

      &:hover {
        color: $hr-text-footer;
        text-decoration: none;
      }
    }
  }

  .footer-rightside {
    display: flex;
    align-items: center;
    gap: $hr-spacing-075;
    a {
      font-family: $hr-font-family;
      font-style: normal;
      font-weight: 700;
      font-size: $hr-text-tiny;
      letter-spacing: 0.16px;
      color: $hr-text-footer;
      // padding-left: $hr-spacing-075;
      // padding-right: $hr-spacing-075;
      text-align: center;
    }
  }

  .copyrights {
    font-family: $hr-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: $hr-text-tiny;
    text-align: right;
    letter-spacing: 0.16px;
    color: $hr-text-footer;
  }

  .contact-us {
    text-align: center;
  }
  .powered-by-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: #eff0f1 !important;
    font-weight: 400 !important;
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  .powered-by {
    align-items: center;
  }
}
</style>
