<template>
  <div>
    <template
      v-if="
        columnName === 'username' ||
          columnName === 'Clan name' ||
          columnName === 'clan_name'
      "
    >
      <PlayersTableUsernameCell
        :account="{
          ...row.fields_data.user,
          ...row,
        }"
        :is-clan="isClan"
      />
    </template>
    <template v-else-if="columnName === 'userLogin'">
      <TextBlock :text="row.id" />
    </template>
    <!-- <Checkbox
      v-if="columnName === 'check-in'"
      :checked="row.fields_data.checked_in"
      :disabled="false"
    /> -->
    <template v-else-if="columnName === 'check-in'">
      <Btn
        :disabled="
          !isStageActive ||
            !isCheckInStarted() ||
            row.fields_data.approvedTo === 'Requested'
        "
        :class="
          row.fields_data.checked_in
            ? 'btn-secondary-simple'
            : 'btn-success-simple'
        "
        :label="getBtnTranslationByCheckInStatus(row.fields_data.checked_in)"
        @click.stop="$emit('checkIn', row)"
      />
      <!-- <Icon
        class="cursor-pointer"
        name="check-mark"
        width="18"
        :fill="
          row.fields_data.checked_in
            ? $scss.colors.greenBg
            : $scss.secondaryTextBg
        "
        @click.stop="$emit('checkIn', row)"
      /> -->
    </template>
    <template v-else-if="columnName === 'size'">
      <TextBlock
        :class="clanMemberSquadSize"
        :text="
          row.members
            ? row.members.length + '/' + squadSize
            : squadSize + '/' + squadSize
        "
      />
    </template>
    <template v-else-if="columnName === 'info'">
      <Icon
        class="cursor-pointer"
        name="paper"
        width="18"
        :fill="$scss.secondaryTextBg"
        @click.stop="$emit('showRegistrationData', row)"
      />
    </template>
    <template v-else-if="columnName === 'chat'">
      <Icon
        class="cursor-pointer"
        name="chat"
        width="18"
        :fill="$scss.secondaryTextBg"
        @click.stop="$emit('chat', row)"
      />
    </template>

    <template v-else-if="columnName === 'attributes'">
      <PlayersTableAttributesCell
        :attributes="row.fields_data[columnName]"
        :all-attributes="allAttributes"
        @attributesSet="$emit('attributesSet', $event)"
      />
    </template>
    <template v-else-if="columnName === 'applicationDate'">
      {{ row.fields_data[columnName] | localDate(DATE_SHORT) }}
    </template>
    <template v-else-if="columnName === 'applicationDatetime'">
      {{ row.fields_data['applicationDate'] | localDate(DATETIME_SUBMED) }}
    </template>
    <template v-else-if="columnName === 'applicationTime'">
      {{ row.fields_data[columnName] | localDate(TIME_SIMPLE) }}
    </template>
    <template v-else-if="columnName === 'blockIndex'">
      <TextBlock
        :text="getBlockByIndex(row.fields_data[columnName]).name || '-'"
      />
    </template>
    <template v-else-if="columnName === 'qualify'">
      <Btn
        :key="`${row.id}-${row.fields_data.approvedTo}-qualify-btn`"
        :class="isBtnActive(row) ? 'btn-accent-simple' : 'btn-secondary-simple'"
        :label="getBtnTranslationByStatus(row)"
        class="w-100"
        @click.stop="$emit('qualify', row)"
      />
    </template>
    <template v-else-if="columnName === 'approvedTo'">
      <TextBlock :text="$$t(row.fields_data[columnName])" />
    </template>
    <!--    <template v-else-if="columnName === 'Country'">
      <TextBlock :text="$$t(row.fields_data[columnName])" />
      {{row.fields_data}}
    </template>-->
    <template v-else>
      <TextBlock
        :text="
          row.fields_data[columnName]
            ? row.fields_data[columnName]
            : row.fields_data[columnLabel]
        "
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { startCase } from '@/utils/lodashUtils';
import { prop, propRequired } from '@/utils/factories';
import { DATE_SHORT, TIME_SIMPLE, DATETIME_SUBMED } from '@/constants/time';
import { translate } from '@/mixins/translate';
import { STAGE_STATUSES } from '@/constants/tournaments';
import dateMethods from '@/mixins/dateMethods';
import Icon from '@/components/atoms/Icon';
import Btn from '@/components/atoms/HeraFormBtn';
import TextBlock from '@/components/atoms/TextBlock';
import PlayersTableAttributesCell from './PlayersTableAttributesCell';
import PlayersTableUsernameCell from './PlayersTableUsernameCell';

export default {
  name: 'PlayersTableCell',
  components: {
    Btn,
    Icon,
    TextBlock,
    PlayersTableAttributesCell,
    PlayersTableUsernameCell,
  },
  mixins: [translate('_web_tournament_players_row'), ...dateMethods],
  props: {
    columnName: propRequired(String),
    columnLabel: prop(String),
    row: propRequired(Object),
    isClan: prop(Boolean),
    allAttributes: prop(Array),
    groups: prop(Array, () => []),
  },
  data() {
    return {
      DATE_SHORT,
      TIME_SIMPLE,
      DATETIME_SUBMED,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournamentStages',
      'checkIfCheckInStarted',
      'currentTournament',
    ]),
    stageID() {
      return this.$route.params.stageID;
    },
    currentStageInfo() {
      return (
        (this.$route.name === 'tournamentPlayers'
          ? this.currentTournamentStages[0]
          : this.currentTournamentStages.find(
              item => item.id === this.stageID
            )) || {}
      );
    },
    squadSize() {
      return this.currentTournament.players_config.squad_size;
    },
    clanMemberSquadSize() {
      if (this.row.members) {
        return this.squadSize <= this.row.members.length
          ? 'text-success'
          : 'text-danger';
      }
      return 'text-success';
    },
    isStageActive() {
      return this.currentStageInfo.status !== STAGE_STATUSES.FINISHED;
    },
  },
  methods: {
    isCheckInStarted() {
      return !this.$lodash.isEmpty(this.currentStageInfo)
        ? this.currentStageInfo.check_in_start_date <= this.getNowSeconds()
        : this.checkIfCheckInStarted();
    },
    isBtnActive(player) {
      return ['Reserved', 'Requested', 'Revoked'].includes(
        startCase(player.fields_data.approvedTo)
      );
    },
    getBtnTranslationByStatus(player) {
      return this.$$t(
        ['Reserved', 'Requested', 'Revoked'].includes(
          startCase(player.fields_data.approvedTo)
        )
          ? 'Qualify'
          : 'Unqualify'
      );
    },
    getBtnTranslationByCheckInStatus(checkedIn) {
      return this.$$t(checkedIn ? 'Checked-in' : 'Check-in');
    },
    getBlockByIndex(index = null) {
      return index !== null
        ? this.groups.find(item => item.id === +index) || {}
        : '-';
    },
  },
};
</script>
