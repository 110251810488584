<template>
  <div class="position-relative slider-chat-input">
    <div ref="closest">
      <FormEditable
        ref="input"
        :closest="$refs.closest"
        :grid="grid"
        :has-drop="hasDrop"
        has-tags
        has-mentions
        upload-btn-icon="attach"
        :placeholder="
          $t(
            '_web_conversation_placeholder_enter-message',
            'Enter Your message'
          )
        "
        @submit="$emit('submit', $event)"
      />
    </div>
  </div>
</template>

<script>
import FormEditable from '@/components/form/contentEditable/';

export default {
  components: { FormEditable },
  props: {
    grid: {
      type: Array,
      default: () => ['Attachments', 'Editable'],
    },
  },
  computed: {
    hasDrop() {
      return this.grid.includes('Attachments');
    },
  },
};
</script>

<style lang="scss">
.slider-chat-input {
  padding: 0.5rem 1rem 1rem 1rem;
  .input-group-prepend {
    padding: 0 !important;
    @include auto-rtl(margin-left, 0 !important);
  }
}
</style>
