import { STAGE_STATUSES_TEXT } from '@/constants/tournaments';

export const stageStatuses = {
  computed: {
    localizedStageStatuses() {
      let statuses = STAGE_STATUSES_TEXT.reduce((res, obj) => {
        res[obj.name] = this.$t(obj.key, obj.label);
        return res;
      }, {});
      return statuses;
    },
  },
};
