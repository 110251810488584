import { get } from '@/utils/lodashUtils';
import store from '@/store';
import router from '@/router';
import ROUTER_NAMES_ACCOUNT from '@/constants/router/account';
import { redirectDeletedAccount } from './redirect';
import { subRoutesFactory } from './subRoutes';

const Account = () => import('@/views/account/');

import {
  MAIN_CLANS,
  MAIN_PROFILES,
  MAIN_TOURNAMENTS,
  MAIN_MATCHES,
} from '@/constants/router/mains';
// import { BATTLE } from '@/constants/accounts';

const accountTypes = ROUTER_NAMES_ACCOUNT.ACCOUNT_TYPES.join('|');

export default [
  {
    path: `/:accountType(${accountTypes})s/:accountId/`,
    beforeEnter(to, from, next) {
      if (
        !store.getters.isAuthorized &&
        to.params.accountType != ROUTER_NAMES_ACCOUNT.TOURNAMENT
      ) {
        store.dispatch('openModal', {
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
          events: {
            cancel: store.dispatch('closeModal'),
          },
        });
        return;
      }
      router.afterEach(to => {
        if (
          to.params.accountType === ROUTER_NAMES_ACCOUNT.TOURNAMENT ||
          to.params.accountType === 'tournaments'
        ) {
          next({
            path: `/tournament/${to.params.accountId}`,
            name: 'singleCompetition',
            params: {
              tournamentID: to.params.accountId,
            },
            query: {
              ...to.query,
            },
          });
        } // to and from are both route objects.
      });

      store.getters.isAuthorized &&
        store
          .dispatch(
            'fetchAccount',
            to.params.accountType === ROUTER_NAMES_ACCOUNT.TOURNAMENT
              ? {
                  ...to.params,
                  regToken: to.query.registration_token,
                }
              : to.params
          )
          .then(account => {
            redirectDeletedAccount(account, to, next);
            const firstVisitATournament =
              to.params.accountType === ROUTER_NAMES_ACCOUNT.TOURNAMENT &&
              from.name === 'tournamentsList';
            if (
              firstVisitATournament &&
              account.participant_status &&
              account.max_players > 1
            ) {
              next({
                name: ROUTER_NAMES_ACCOUNT.MY_TEAM,
                params: {
                  ...to.params,
                },
              });
            } else {
              next();
            }
          })
          .catch(error => {
            store.dispatch('errorNotify', error);
            if (
              to.params.accountType === ROUTER_NAMES_ACCOUNT.TOURNAMENT &&
              (error.response || error.status)
            ) {
              router.push({
                name: 'notfound',
                params: {
                  id: to.params.accountId,
                  message:
                    error.response && error.response.data
                      ? error.response.data.error
                      : '',
                },
              });
            } // eslint-disable-next-line no-empty
            else if (to.params.accountType === ROUTER_NAMES_ACCOUNT.BATTLE) {
            } else {
              next({
                name: 'Error404',
                params: {
                  resource: to.params.accountType,
                  id: to.params.accountId,
                },
              });
            }
          });
    },
    components: {
      default: Account,
    },
    meta: {
      getCustomTitle: () => {
        const type = store.state.accounts.current.account._type;
        return {
          key: '_web_account-title',
          label: '{accountName} {type}',
          params: {
            type: type === ROUTER_NAMES_ACCOUNT.TOURNAMENT ? '' : type,
            accountName: store.state.accounts.current.account._name,
          },
        };
      },
      getSidebarParent(name) {
        const { account, accountId } = store.state.accounts.current;

        if (account.isTournament) {
          return name === MAIN_TOURNAMENTS;
        }

        if (account.isClan) {
          return name === MAIN_CLANS;
        }

        if (account.isBattle) {
          return name === MAIN_MATCHES;
        }

        if (account.isProfile || account.isOrganization) {
          const isMyAccount =
            get(store.state, 'accounts.my.accountId') === accountId;

          return (
            (name === ROUTER_NAMES_ACCOUNT.ACCOUNT && isMyAccount) ||
            (name === MAIN_PROFILES && !isMyAccount)
          );
        }

        return false;
      },
      hideTitleInHeader: () => {
        const type = store.state.accounts.current.account._type;
        return type === ROUTER_NAMES_ACCOUNT.BATTLE;
      },
    },
    children: subRoutesFactory(),
  },
];
