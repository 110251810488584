<template>
  <Panel
    v-if="showDescription"
    class="tournament-info_description w-75"
    :variant="['transparent', 'no-padding']"
  >
    <Subheader
      class="text-nowrap"
      :text="
        $t('_web_tournament_competition_description', 'Competition description')
      "
      :text-block-props="{ size: 1.125 }"
      variant="secondary"
    />
    <article
      v-if="longDescription"
      class="hover-pointer"
      @click="showFullDescription = !showFullDescription"
    >
      <div class="mb-3">
        <div
          v-if="!showFullDescription && shortDescription"
          class="hover-pointer mr-2"
        >
          <div :style="descriptionStyles">
            {{ shortDescription }}
          </div>
        </div>
        <div
          v-if="showFullDescription"
          variant="primary"
          size="0.8"
          class="hover-pointer mr-2"
        >
          <div :style="descriptionStyles">
            {{ description }}
          </div>
        </div>
      </div>
      <span v-if="!showFullDescription && longDescription" class="more-button">
        {{ $t('_web_show_more', 'Show more') }}
      </span>
      <span v-else class="more-button">
        {{ $t('_web_show_less', 'Show less') }}
      </span>
    </article>
    <article v-else>
      <TextBlock size="0.8" variant="secondary">
        {{ description }}
      </TextBlock>
    </article>
  </Panel>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import TextBlock from '@/components/atoms/TextBlock.vue';
import Subheader from '@/components/atoms/Subheader.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TournamentInfoDescription',
  components: {
    Panel,
    TextBlock,
    Subheader,
  },
  data() {
    return {
      showFullDescription: false,
    };
  },
  computed: {
    ...mapGetters(['currentTournament']),
    description({ currentTournament: { description } }) {
      return description || '\u00A0';
    },
    showDescription({ description }) {
      return Boolean(description && description.trim());
    },
    shortDescription() {
      if (this.description.length < 200) {
        return this.description;
      } else return this.description.slice(0, 180);
    },
    longDescription() {
      if (this.description.length > 200) {
        return this.description;
      } else return false;
    },
    descriptionStyles() {
      return {
        fontSize: '0.8rem',
        lineHeight: '1.2',
        // padding: '10px',
        whiteSpace: 'pre-wrap',
      };
    },
  },
};
</script>

<style lang="scss">
.tournament-info_description {
  white-space: pre-line;
  word-wrap: break-word; /* Break long words if necessary */
  @media (max-width: 800px) {
    margin: auto !important;
    text-align: center !important;
  }
  flex-direction: row;
  padding-top: $hr-spacing-07;
}
.hover-pointer:hover {
  cursor: pointer;
}

.more-button {
  cursor: pointer;
  font-weight: bold;
}
</style>
