<template>
  <Btn
    :label="followText"
    :variant="variant"
    class="position-relative follow-button "
    :class="[
      !isFollow ? followClass : showUnfollowText ? hoverClass : unfollowClass,
      waitingFollow ? 'is-loading' : '',
    ]"
    @click.native="follow"
    @mouseenter.native="toggleUnfollow(true)"
    @mouseleave.native="toggleUnfollow(false)"
  />
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { ORGANIZATION, PROFILE, CLAN } from '@/constants/accounts';
import { prop } from '@/utils/factories';
import Btn from '@/components/atoms/HeraFormBtn';
import AccountModel from '@/models/Account';

const AVAILABLE_ENTITIES = [PROFILE, ORGANIZATION, CLAN];

export default {
  name: 'FollowBtn',
  components: {
    Btn,
  },
  props: {
    model: prop(AccountModel, null),
    variant: prop(String, 'default'),
    followClass: prop(String, 'btn-accent-simple'),
    hoverClass: prop(String, 'btn-secondary-simple'),
    unfollowClass: prop(String, 'btn-secondary-simple'),
  },
  data() {
    return {
      isFollowLocal: null,
      waitingFollow: false,
      showUnfollowText: false,
      unfollowText: this.$t('_web_user_actions_unfollow', 'Unfollow'),
    };
  },
  computed: {
    accountId() {
      return this.$lodash.get(this, 'model._id');
    },
    accountType() {
      return this.$lodash.get(this, 'model._type');
    },
    followText() {
      return !this.isFollow
        ? this.$t('_web_user_actions_follow', 'Follow')
        : this.showUnfollowText
        ? this.$t('_web_user_actions_unfollow', 'Unfollow')
        : this.$t('_web_user_actions_following', 'Following');
    },
    isFollow: {
      get() {
        return typeof this.isFollowLocal === 'boolean'
          ? this.isFollowLocal
          : this.$lodash.get(this, 'model.socialMetadata.is_follow') ||
              this.$lodash.get(this, 'model.isFollowing');
      },
      set(v) {
        this.isFollowLocal = v;
      },
    },
  },
  methods: {
    ...mapActions(['followAccount', 'errorNotify']),
    ...mapMutations(['updateCounterKey']),
    follow() {
      if (
        !this.waitingFollow &&
        AVAILABLE_ENTITIES.includes(this.accountType)
      ) {
        this.followProfile();
      }
    },
    followProfile() {
      const follow = !this.isFollow;
      this.followProfileAction(follow)
        .then(() => {
          const socialMetadata = { is_follow: follow };
          this.model
            .update({ socialMetadata }, true)
            .updateCounters({ followed_by: follow ? +1 : -1 });
          this.updateCounterKey();
          this.isFollow = follow;
          this.$emit('toggleFollow', follow);
        })
        .catch(this.errorNotify);
    },
    toggleUnfollow(isMouseEnter) {
      if (!this.isFollow) {
        return;
      }
      this.showUnfollowText = isMouseEnter;
    },
    followProfileAction(follow) {
      this.waitingFollow = true;
      return this.followAccount({
        accountType: this.accountType,
        accountId: this.accountId,
        follow,
      }).finally(() => (this.waitingFollow = false));
    },
  },
};
</script>

<style lang="scss">
.follow-button {
  //Removed !important so buttons width could be changed trough other components
  min-width: 130px;
}
@media (max-width: 767px) {
  .follow-button {
    min-width: unset !important;
    padding: 3px 10px 3px 10px !important;
  }
}
</style>
