import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests';

const COMPETITIONS = 'competitions';
const TOURNAMENTS = 'tournaments';
export default {
  getParticipants: competitionID =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/players`),
  getParticipantsStatus: tournamentID =>
    authorizedGetRequest(`tournaments/${tournamentID}/players/status`, {
      preventCancelRequest: true,
    }),
  getParticipantsAll: competitionID =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/players/all`),
  getRounds: competitionID =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/rounds`),
  forceStartRound: (tournamentID, stageID, competitionID, roundID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/rounds/${roundID}/start`
    ),
  forceFinishRound: (tournamentID, stageID, competitionID, roundID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/rounds/${roundID}/finish`
    ),
  updateRound: (tournamentID, stageID, competitionID, roundId) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/rounds/${roundId}`
    ),
  getMatches: competitionID =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/matches`, {
      cancelStrategy: 'forward',
    }),
  sendTournamentRoundMessage: (tournamentID, roundID, blockID) =>
    authorizedPostRequest(
      `${TOURNAMENTS}/${tournamentID}/blocks/${blockID}/rounds/${roundID}/messages`
    ),
  getMatchesByPlayer: authorizedGetRequest(`${COMPETITIONS}/matches/search`, {
    cancelStrategy: 'forward',
  }),
  getMatchesByTournament: authorizedGetRequest(
    `${COMPETITIONS}/matches/search`,
    {
      cancelStrategy: 'forward',
    }
  ),
  getStandings: competitionID =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/standings`),
  updateMatchByID: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}`
    ),
  provideResultByAdmin: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPostRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/results`
    ),
  forceStartMatch: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/start`
    ),
  forceFinishMatch: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/finish`
    ),
  resetMatch: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/reset`
    ),
  setTechWin: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/tech_results`
    ),
  moveMatchToRound: (competitionID, destinationRoundID) =>
    authorizedPostRequest(
      `${COMPETITIONS}/${competitionID}/matches/rounds/${destinationRoundID}`
    ),
  swapPlayers: (tournamentID, stageID, competitionID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/swap_matches_players`
    ),
  deletePlayerFromMatch: (
    tournamentID,
    stageID,
    competitionID,
    matchID,
    playerID
  ) =>
    authorizedDeleteRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/match/${matchID}/player/${playerID}`
    ),
  addPlayerToMatch: (
    tournamentID,
    stageID,
    competitionID,
    matchID,
    playerID,
    playerPosition
  ) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/match/${matchID}/${playerPosition}/player/${playerID}`
    ),
  getPlayersListForAddingToMatch: (
    tournamentID,
    stageID,
    competitionID,
    matchID
  ) =>
    authorizedGetRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/match/${matchID}/insert_list`
    ),
  getCompetitions: authorizedGetRequest('tournaments', {
    ignoreParams: true,
  }),
};
