<template>
  <Panel
    class="overflow-hidden group-table px-0"
    :variant="[
      'transparent',
      'stroked',
      'stroked-secondary',
      'rounded',
      'half-paddings',
    ]"
  >
    <div class="position-relative h-100">
      <Paginated
        v-bind="{
          totalItems,
          hidePagination: !standings.length,
          pageSize,
          inContainer: true,
          paginationProps: {
            isDark: true,
          },
        }"
        class="container custom-scrollbar"
        @change="goToPage"
      >
        <div class="col">
          <Table
            :columns="filteredColumns"
            :items="pagedStandings"
            :loading="isLoading"
            :dummy-props="{
              iconName: 'dummy/members',
              size: 320,
              headerText: $$t(
                'There are no participants yet',
                'list-empty-state'
              ),
              text: $$t('', 'list-empty-state_subheader'),
            }"
          >
            <template v-if="title" #control>
              <div class="d-flex w-100 group-table-header">
                <TextBlock :text="title" :size="$scss.fontSizes_md" />
              </div>
            </template>
            <div
              slot="head-item"
              slot-scope="{ column }"
              class="d-flex w-100 align-items-center"
              :class="[
                {
                  'cursor-pointer': column.sort,
                },
                column.key === 'participants'
                  ? 'justify-content-start'
                  : 'justify-content-center',
              ]"
              @click="() => onSortBy(column)"
            >
              <TextBlock :size="0.75" :line-height="1.4" variant="secondary">
                {{
                  $t(
                    `_web_competitions_tournaments-list_col-title-${
                      column.key
                    }`,
                    column.label
                  )
                }}
              </TextBlock>
              <InfoTooltip
                v-if="column.tooltip"
                class="d-inline-flex group-table-infotip"
                :title="column.tooltip.title"
                :text="column.tooltip.text"
                :float="['right', 'bottom']"
              />
              <SortIcon
                v-if="column.sort"
                v-model="sortBy"
                :sort-order="sortOrder"
                :name="column.key"
                :icon-props="{ fill: $scss.colors.secondaryTextBg }"
              />
            </div>
            <template #body-item="{ item: {profile, score}, column: { key } }">
              <template v-if="key === 'participants'">
                <PlayersTableUsernameCell
                  :key="$filters.displayName(profile)"
                  :account="profile"
                  :is-clan="profile.type === 'clan'"
                />
              </template>
              <TextBlock v-else-if="key === 'index'" :text="score.place" />
              <PHBadge
                v-else-if="key === 'points' && isBattleRoyale"
                variant="inverse"
                :text="`${score[key]}`"
                class="ml-auto text-white font-size-sm"
              />
              <TextBlock v-else :text="convertToPercentages(key, score[key])" />
            </template>
          </Table>
        </div>
      </Paginated>
    </div>
  </Panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { propRequired, prop } from '@/utils/factories';
import listMixin from '@/mixins/paginatedLists';
import { translate } from '@/mixins/translate';
import { BLOCK_TYPES } from '@/constants/tournaments';
import { TIE_GAMES } from '@/constants/tournaments';
import TextBlock from '@/components/atoms/TextBlock';
import InfoTooltip from '@/components/molecules/InfoTooltip';
import Table from '@/components/molecules/Table';
import PlayersTableUsernameCell from '@/views/tournaments/management/participants/components/PlayersTableUsernameCell';
import SortIcon from '@/components/nuclides/SortIcon';
import Panel from '@/components/atoms/Panel';
import PHBadge from '@/components/atoms/HeraBadge';
import Paginated from '@/components/common/Paginated';

export default {
  name: 'GroupTable',
  components: {
    SortIcon,
    Table,
    Panel,
    InfoTooltip,
    TextBlock,
    Paginated,
    PlayersTableUsernameCell,
    PHBadge,
  },
  mixins: [...listMixin, translate('_web_competitions_standings')],
  props: {
    id: propRequired(String),
    title: prop(String),
    type: prop(String),
    tieBreaker: prop(String),
  },
  data() {
    return {
      sortBy: null,
      sortOrder: 0,
      standings: [],
    };
  },
  computed: {
    ...mapGetters(['currentTournamentStages']),
    columns() {
      let cols;
      if (this.type === BLOCK_TYPES.RR || this.type === BLOCK_TYPES.SWISS) {
        cols = this.commonColumns().concat(
          this.winLoseColumns(),
          this.currentTournamentStages[0].block_configuration.type ===
            'swiss_system'
            ? this.advancedColumns()
            : this.allExecptSwissColumns()
        );
      } else if (this.isBattleRoyale) {
        cols = this.battleRoyaleColumns();
      } else if (this.type === BLOCK_TYPES.DOUBLE) {
        cols = this.commonColumns().concat(this.winLoseColumns());
      } else {
        cols = this.commonColumns();
      }
      return this.$lodash.sortBy(cols, ['position', (obj, index) => index]);
    },
    filteredColumns() {
      return this.columns.filter(
        column => this.isTieBreakersVisible || !column.isTieBreaker
      );
    },
    isBattleRoyale() {
      return this.type === BLOCK_TYPES.BATTLE_ROYALE;
    },
    isSingleElimination() {
      return this.type === BLOCK_TYPES.SINGLE;
    },
    pagedStandings() {
      const start = this.pageIndex * this.pageSize;
      return this.standings.slice(start, start + this.pageSize);
    },
  },
  created() {
    this.pageSize = 10;
    this.getStandings();
  },
  methods: {
    ...mapActions(['getCompetitionPlayers', 'getCompetitionPlayersAll']),
    goToPage(pageIndex) {
      this.pageIndex = pageIndex;
    },
    getStandings() {
      this.getPageData(
        'standings',
        this[
          this.isBattleRoyale
            ? 'getCompetitionPlayersAll'
            : 'getCompetitionPlayers'
        ]({
          id: this.id,
        }).then(response =>
          !response.total
            ? {
                ...response,
                total: response.items.length,
              }
            : response
        )
      );
    },
    convertToPercentages(name, value) {
      if (
        !value ||
        (name !== 'match_win_per_op' &&
          name !== 'game_win_per' &&
          name !== 'game_win_per_op')
      ) {
        if (value && typeof value === 'number') {
          //it's a number
          return parseFloat(value.toFixed(2));
        }
        return value || '-';
      }
      if (typeof value === 'string') {
        value = +value || 0;
      }
      return Math.round(value * 10000) / 100; // fail
    },
    onSortBy({ key: sortBy, sort: hasSort }) {
      if (!hasSort) {
        return;
      }
      if (this.sortBy === sortBy) {
        this.sortOrder *= -1;
      } else {
        this.sortBy = sortBy;
        this.sortOrder = 1;
      }
      this.standings = this.rowsSort(
        sortBy === 'participants',
        this.columns.find(({ key }) => key === this.sortBy).sort
      );
    },
    rowsSort(isUnicode, sortPath) {
      const sorted = this.$lodash.sortBy(this.standings, [
        row => {
          const definer = this.$lodash.get(row, sortPath) || null;
          if (isUnicode) {
            return String(definer).toLowerCase();
          }
          return definer === null ? this.sortOrder * Infinity : +definer;
        },
        (row, index) => index,
      ]);

      if (this.sortOrder < 0) {
        sorted.reverse();
      }

      return sorted;
    },

    commonColumns() {
      return [
        { label: 'Place', key: 'index', position: 0 },
        { label: 'Participants', key: 'participants', position: 1 },
      ];
    },
    winLoseColumns() {
      return [
        {
          label: 'Win',
          key: 'win',
          position: 3,
          tooltip: {
            title: this.$$t('Win.', 'tooltip-w-title'),
            text: this.$$t(
              'Total number of matches ended in a winning result',
              'tooltip-w-text'
            ),
          },
        },
        {
          label: 'Lose',
          key: 'loss',
          position: 5,
          tooltip: {
            title: this.$$t('Lose.', 'tooltip-l-title'),
            text: this.$$t(
              'Total number of matches ended in a loss result',
              'tooltip-l-text'
            ),
          },
        },
      ];
    },
    advancedColumns() {
      return [
        {
          label: 'Matches',
          key: 'match_played',
          position: 2,
          tooltip: {
            title: this.$$t('Total matches.', 'tooltip-total-title'),
            text: this.$$t(
              'Total number of matches played by the player',
              'tooltip-total-text'
            ),
          },
        },
        {
          label: 'Draw',
          key: 'tie',
          position: 4,
          tooltip: {
            title: this.$$t('Draw.', 'tooltip-d-title'),
            text: this.$$t(
              'Total number of matches ended in a draw result',
              'tooltip-d-text'
            ),
          },
        },
        {
          label: 'OWM%',
          key: 'match_win_per_op',
          position: 6,
          tooltip: {
            title: this.$$t("Opponents' Winning Match %.", 'tooltip-owm-title'),
            text: this.$$t(
              'The average winning match percentage of each opponent that player faced (byes are ignored)',
              'tooltip-owm-text'
            ),
          },
        },
        {
          label: 'OWG%',
          key: 'game_win_per_op',
          position: 7,
          tooltip: {
            title: this.$$t("Opponents' Winning Game %.", 'tooltip-owg-title'),
            text: this.$$t(
              "The average winning game percentage of all of that player's opponents (byes are ignored)",
              'tooltip-owg-text'
            ),
          },
        },
        {
          label: 'WG%',
          key: 'game_win_per',
          position: 8,
          tooltip: {
            title: this.$$t('Winning Game %.', 'tooltip-wg-title'),
            text: this.$$t(
              "Player's total number of game points divided by the total game points possible in those rounds",
              'tooltip-wg-text'
            ),
          },
        },
        {
          label: 'PTS',
          key: 'match_points',
          position: 9,
          tooltip: {
            title: this.$$t('Points.', 'tooltip-pt-title'),
            text: this.$$t(
              'Total number of points gained by the player',
              'tooltip-pt-text'
            ),
          },
        },
      ];
    },
    allExecptSwissColumns() {
      return [
        {
          label: 'Matches',
          key: 'match_played',
          position: 2,
          tooltip: {
            title: this.$$t('Total matches.', 'tooltip-total-title'),
            text: this.$$t(
              'Total number of matches played by the player',
              'tooltip-total-text'
            ),
          },
        },
        {
          label: 'Draw',
          key: 'tie',
          position: 4,
          tooltip: {
            title: this.$$t('Draw.', 'tooltip-d-title'),
            text: this.$$t(
              'Total number of matches ended in a draw result',
              'tooltip-d-text'
            ),
          },
        },
        {
          label: 'PTS',
          key: 'match_points',
          position: 9,
          tooltip: {
            title: this.$$t('Points.', 'tooltip-pt-title'),
            text: this.$$t(
              'Total number of points gained by the player',
              'tooltip-pt-text'
            ),
          },
        },
      ];
    },
    battleRoyaleColumns() {
      const tieBreaker =
        this.tieBreaker === TIE_GAMES.PUBG
          ? [
              {
                label: 'Best match (pts)',
                key: 'max_points',
                tooltip: {
                  title: this.$$t('Best match (pts)', 'tooltip_bprg-pst_title'),
                  text: this.$$t(
                    'Best performing Tournament Game based on total points',
                    'tooltip_bprg-pst_text'
                  ),
                },
                sort: 'score.max_points',
              },
              {
                label: 'Best match (kills)',
                key: 'max_kills',
                tooltip: {
                  title: this.$$t(
                    'Best match (kills)',
                    'tooltip_bprg-tk_title'
                  ),
                  text: this.$$t(
                    'Best performing Tournament Game based on total kills',
                    'tooltip_bprg-tk_text'
                  ),
                },
                sort: 'score.max_kills',
              },
              {
                label: 'Last match (kills)',
                key: 'last_kills',
                tooltip: {
                  title: this.$$t('Last match (kills)', 'tooltip_tk-lpg_title'),
                  text: this.$$t(
                    'Total kills in the last-played Tournament Game',
                    'tooltip_tk-lpg_text'
                  ),
                },
                sort: 'score.last_kills',
              },
              {
                label: 'Last match (place)',
                key: 'last_rank',
                tooltip: {
                  title: this.$$t('Last match (place)', 'tooltip_p-lpg_title'),
                  text: this.$$t(
                    'Placement in the last-played Tournament Game',
                    'tooltip_p-lpg_text'
                  ),
                },
                sort: 'score.last_rank',
              },
            ]
          : this.tieBreaker === TIE_GAMES.PUBG2
          ? [
              {
                label: 'Total Victories',
                key: 'victories',
                tooltip: {
                  text: this.$$t(
                    'Total Victory Royales in the session',
                    'tooltip_total-victories'
                  ),
                },
                sort: 'score.victories',
              },
              {
                label: 'Last match (place)',
                key: 'last_rank',
                tooltip: {
                  title: this.$$t('Last match (place)', 'tooltip_p-lpg_title'),
                  text: this.$$t(
                    'Placement in the last-played Tournament Game',
                    'tooltip_p-lpg_text'
                  ),
                },
                sort: 'score.last_rank',
              },
            ]
          : [
              {
                label: 'Total Victories',
                key: 'victories',
                tooltip: {
                  text: this.$$t(
                    'Total Victory Royales in the session',
                    'tooltip_total-victories'
                  ),
                },
                sort: 'score.victories',
              },
              {
                label: 'AVG kills',
                key: 'avg_kills',
                tooltip: {
                  text: this.$$t(
                    'Average eliminations in the sessions',
                    'tooltip_avg-kills'
                  ),
                },
                sort: 'score.avg_kills',
              },
              {
                label: 'AVG place',
                key: 'avg_place',
                tooltip: {
                  text: this.$$t(
                    'Average placement per match in the session',
                    'tooltip_avg-place'
                  ),
                },
                sort: 'score.avg_place',
              },
            ]; // TIE_GAMES.FORTNITE
      return [
        { label: 'Place', key: 'place' },
        { label: 'Participants', key: 'participants', sort: 'profile.name' },
        {
          label: 'Kills',
          key: 'kills',
          tooltip: null,
          sort: 'score.kills',
        },
        {
          label: 'Kills PTS',
          key: 'kills_points',
          tooltip: null,
          sort: 'score.kills_points',
        },
        {
          label: 'Rank PTS',
          key: 'rank_points',
          tooltip: null,
          sort: 'score.rank_points',
        },
        ...tieBreaker,
        {
          label: 'Total pts',
          key: 'points',
          tooltip: null,
          sort: 'score.points',
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.group-table {
  max-height: 70vh;

  .hera-table-wrap {
    padding: 0;
    thead th {
      top: 54px;

      padding: $ph-small-space;
    }
    thead th,
    tbody td {
      &:nth-child(1) {
        width: 1px;
      }
      &:nth-child(1),
      &:nth-child(n + 3) {
        text-align: center;
      }
    }
    tbody td {
      &:first-child {
        @include auto-rtl(padding-left, 0);
      }
      &:last-child {
        @include auto-rtl(padding-right, 0);
      }
    }
    thead th {
      position: sticky;
      top: 0;
      z-index: 2;
      margin-bottom: -$ph-large-space;
      background-color: $ph-card;
    }
    // .hera-table-control {
    //   padding: $ph-large-space;
    // }
    .hera-table-head {
      th {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
        &:first-child {
          @include auto-rtl(padding-left, 0);
        }
        &:last-child {
          @include auto-rtl(padding-right, 0);
        }
        div {
          min-height: 1.5rem;
        }
      }

      .group-table-infotip {
        .playhera-icon {
          vertical-align: top !important;
          @include auto-rtl(margin-left, 0.15rem);
        }
      }
    }
    .players-table-username {
      max-width: 12rem;
    }
  }
}
</style>
