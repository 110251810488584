var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center match-wrapper simple-card",class:[
    _vm.bindClasses,
    {
      'swap-target': _vm.isSwapTarget,
      'swap-source': _vm.isSwapSource,
      'third-place-match flex-column': _vm.thirdPlace,
    } ],style:(_vm.offsetStyles),attrs:{"data-round-index":_vm.roundIndex,"data-match-index":_vm.matchIndex,"dir":"ltr"}},[_c('Panel',_vm._g({staticClass:"match-card my-2 px-4 py-3 ",class:{
      empty: !_vm.match.id,
    },attrs:{"variant":_vm.isSwapSource
        ? [
            'stroked',
            'stroked-accent',
            'rounded',
            'rounded-medium',
            'dark-bg' ]
        : [
            'stroked',
            'stroked-secondary',
            'rounded',
            'rounded-medium',
            'dark-bg' ]},on:{"click":function($event){return _vm.$emit('setActiveCard', $event)}}},_vm.$listeners),[(_vm.match.id)?[_c('div',{staticClass:"d-flex flex-column"},[(_vm.showRound)?_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('TextLabel',{staticClass:"p-0 battle-id-label"},[_vm._v("\n            "+_vm._s(_vm.roundName)+"\n          ")]),_c('TextLabel',{staticClass:"p-0 battle-id-label ml-auto"},[_vm._v("\n            "+_vm._s(_vm.$t('_web_tournament_battle_score', 'Score'))+"\n          ")])],1):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_c('playerItem',_vm._g({staticClass:"p-0 mb-1",class:{
              'swap-target': _vm.isSwapTarget,
              'swap-source': _vm.isSwapSource,
              'bolded-winner-brackets':
                _vm.match.player_1_result === 'tech_win' ||
                _vm.match.player_1_result === 'win',
              'bolded-loser-brackets':
                _vm.match.player_1_result === 'tech_loss' ||
                _vm.match.player_1_result === 'loss',
            },attrs:{"player":_vm.match.player_1,"player-score":_vm.playerOneScore,"can-swap":_vm.canSwapPlayerOne,"can-delete-player":_vm.canEditPlayerOne,"is-swap-target":_vm.isSwapTarget,"is-swap-source":_vm.isSwapSource,"swap-index":_vm.swapIndex,"player-index":1,"round-index":_vm.roundIndex,"match-index":_vm.matchIndex,"variant":_vm.playerOneVariant,"is-profile":_vm.isSingle,"chat-with":_vm.match.captain_1,"is-ready-for-battle":!_vm.match.is_detained || _vm.match.player_1_ready,"is-empty":_vm.match.player_empty_1,"hide-score":_vm.isMatchCancelled}},{
              swapCancel: _vm.onSwapCancel,
              swap: _vm.onSwap,
              deletePlayer: _vm.onDeletePlayer,
              addPlayer: _vm.$listeners.addPlayer || _vm.$lodash.noop,
              userMenuOpen: _vm.$listeners.userMenuOpen || _vm.$lodash.noop,
            })),_c('playerItem',_vm._g({staticClass:"p-0",class:{
              'swap-target': _vm.isSwapTarget,
              'swap-source': _vm.isSwapSource,
              'bolded-winner-brackets':
                _vm.match.player_2_result === 'tech_win' ||
                _vm.match.player_2_result === 'win',
              'bolded-loser-brackets':
                _vm.match.player_2_result === 'tech_loss' ||
                _vm.match.player_2_result === 'loss',
            },attrs:{"player":_vm.match.player_2,"player-score":_vm.playerTwoScore,"can-swap":_vm.canSwapPlayerTwo,"can-delete-player":_vm.canEditPlayerTwo,"is-swap-target":_vm.isSwapTarget,"is-swap-source":_vm.isSwapSource,"swap-index":_vm.swapIndex,"player-index":2,"round-index":_vm.roundIndex,"match-index":_vm.matchIndex,"variant":_vm.playerTwoVariant,"is-profile":_vm.isSingle,"chat-with":_vm.match.captain_2,"is-ready-for-battle":!_vm.match.is_detained || _vm.match.player_2_ready,"hide-score":_vm.isMatchCancelled,"is-empty":_vm.match.player_empty_2}},{
              swapCancel: _vm.onSwapCancel,
              swap: _vm.onSwap,
              deletePlayer: _vm.onDeletePlayer,
              addPlayer: _vm.$listeners.addPlayer || _vm.$lodash.noop,
              userMenuOpen: _vm.$listeners.userMenuOpen || _vm.$lodash.noop,
            }))],1),_c('div',{staticClass:"d-flex align-items-center mt-2 small-badge"},[_c('StatusLabel',{staticClass:"px-2 py-1",attrs:{"status":_vm.match.status}}),(!_vm.isMatchCancelled)?_c('div',{staticClass:"started-date mx-3"},[_vm._v("\n            "+_vm._s(_vm._f("localDate")(_vm.match.date_start,_vm.$times.DATETIME_MED))+"\n          ")]):_vm._e()],1)])]:_vm._e()],2),(_vm.thirdPlace)?_c('TextLabel',{staticClass:"my-0"},[_vm._v("\n    "+_vm._s(_vm.$t('web_tournament_third-place-match', 'Match for third Place'))+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }