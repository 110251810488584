import { DELETED, FEEDS } from '@/constants/router/account';

function redirectDeletedAccount(account, to, next) {
  if (account.deleted && to.name !== DELETED) {
    return next({ name: DELETED, params: to.params });
  } else if (!account.deleted && to.name === DELETED) {
    return next({ name: FEEDS, params: to.params });
  }
  next();
}

export { redirectDeletedAccount };
