import { authorizedGetRequest } from '@/api/requests';

const POSTS = 'posts';
const TAGS = 'tags';
const POPULAR_PLAYERS = 'popularpeople';
const TRENDING = 'trending';

export const getNewsFeeds = authorizedGetRequest(`${POSTS}/my/newsfeed`);
export const getNewsFeedTrending = authorizedGetRequest(`${POSTS}/${TRENDING}`);
export const getNewsByTag = authorizedGetRequest(`${TAGS}/${POSTS}`);
export const getPopularPlayers = authorizedGetRequest(POPULAR_PLAYERS);

export default {
  getNewsFeeds,
  getNewsByTag,
  getPopularPlayers,
  getNewsFeedTrending,
};
