<template>
  <div class="navbar-notifications">
    <div :class="notificationOpen ? 'option-line' : ''"></div>

    <div
      class="position-relative cursor-hand px-2"
      :class="[unreadNotifications ? 'has-unread' : 'text-muted']"
      :data-unread-count="unreadNotifications"
      @click="toggleMenus"
    >
      <AppIcon name="notification" :width="18" />
    </div>
    <PopoverList
      ref="popoverMenu"
      class="popover-notif"
      width="18rem"
      :height="['2rem', '16rem']"
      :position="notificationsPosition"
      :float="['right', 'top']"
      :items="filteredNotifications"
      :scroll-is-disable="isLoading || nextPageToken === null"
      is-fixed
      auto-close
      :dummy-props="dummyProps"
      :custom="'custom-style'"
      :nofix="'nofix-style'"
      @toggle="navbarSwitched"
      @scrollToBottom="fetch"
    >
      <div slot="header" class="notif-title">
        <div class="bar-wrapper-notif">
          <div class="purple-bar-notif"></div>
        </div>
        <span class="notif-title-inner">{{
          $t('_web_notifications', 'Notifications')
        }}</span>
      </div>
      <template slot-scope="{ item }">
        <NotificationItemMini
          :data="item"
          @markAsRead="markAsRead"
          @removed="removeItem"
          @click="notificationsPosition = null"
        />
      </template>
      <div slot="footer" @click="notificationsPosition = null">
        <router-link
          :to="{ name: 'notifications' }"
          class="redirect-btn py-5 text-decoration-none"
        >
          <div>
            {{ $t('_web_homepage-view-all', 'View all') }}
          </div>
        </router-link>
      </div>
    </PopoverList>
  </div>
</template>

<script>
import { NOTIFICATIONS_DATA } from '@/constants/notifications';
import { notificationFilter } from '@/utils/textLink';
import AppIcon from '@/components/atoms/Icon';
import { mapGetters, mapActions } from 'vuex';
import PopoverList from '@/components/popover/List';
import listMixins from '@/mixins/lists';
import NotificationItemMini from '@/views/notifications/list/NotificationItemMini';

export default {
  name: 'NavbarNotifications',
  components: {
    PopoverList,
    AppIcon,
    NotificationItemMini,
  },
  mixins: [...listMixins],
  data() {
    return {
      hasUnread: false,
      notificationsPosition: null,
      notifications: null,
    };
  },
  computed: {
    ...mapGetters(['unreadNotifications']),
    ...mapGetters('sidebars', ['profileOpen', 'notificationOpen']),
    dummyProps() {
      return this.isLoading
        ? {
            iconName: 'dummy/notifications',
            size: 160,
            subheaderText: this.$t(
              '_web_fetching_notifications',
              'Fetching notifications...'
            ),
          }
        : {
            iconName: 'dummy/notifications',
            size: 160,
            subheaderText: this.$t(
              '_web_no_notifications',
              "You don't have any notifications for the moment"
            ),
            text: this.$t(
              '_web_no_notifications_subheader',
              'When someone messages you, posts something or announces a new tournament - you will see it here'
            ),
          };
    },
    filteredNotifications() {
      return (this.notifications || []).filter(el => {
        if (!NOTIFICATIONS_DATA[el.type]) {
          console.log('NOTIFICATIONS: unknown type -> ', el.type);
          return false;
        }
        return true;
      });
    },
  },
  created() {
    this.addNotificationsSubscriber(this.notificationsSubscriber);
  },
  destroyed() {
    this.removeNotificationsSubscriber(this.notificationsSubscriber);
  },
  methods: {
    ...mapActions([
      'addNotificationsSubscriber',
      'removeNotificationsSubscriber',
      'getNotifications',
      'updateNotificationsStatus',
    ]),
    ...mapActions('sidebars', ['setNotificationOpen']),
    notificationsSubscriber(res) {
      if (this.notificationsPosition) {
        this.notifications = [
          ...notificationFilter(Array.isArray(res) ? res : [res]),
          ...(this.notifications || []),
        ];
      }
    },
    toggleMenus() {
      this.toggleDropbox(), this.toggleSidebar();
    },
    toggleSidebar() {
      this.setNotificationOpen(this.notificationOpen);
    },
    toggleDropbox() {
      this.notificationsPosition = this.notificationsPosition ? null : this.$el;

      if (this.notificationsPosition) {
        this.notifications = null;
        this.nextPageToken = undefined;
        this.fetch();
      }
    },
    navbarSwitched(isVisible) {
      if (!isVisible && this.notificationsPosition) {
        this.notificationsPosition = null;
      }
    },
    fetch() {
      this.getNextPageData(
        'notifications',
        this.getNotifications({
          pageToken: this.nextPageToken,
          pageSize: 5,
        }),
        {
          uniqBy: 'id',
        }
      );
    },
    markAsRead(notification) {
      if (!notification.read) {
        this.updateNotificationsStatus(new Array(notification.id)).catch(
          this.errorNotify
        );
      }
    },
    removeItem(id) {
      if (!this.notifications || !id) {
        return;
      }

      const index = this.notifications.findIndex(item => item.id === id);

      if (index >= 0) {
        this.notifications.splice(index, 1);
        this.$refs.popoverMenu.ping();
      }
    },
  },
};
</script>

<style lang="scss">
.popover-notif {
  /* right: -14.7rem; */
  top: 3.1rem !important;

  .app-rtl & {
    left: 3.2rem;
  }

  .app-ltr & {
    right: -14.7rem;
  }
}

.navbar-notifications {
  .has-unread {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      @include auto-rtl(right, 0);
      bottom: 100%;
      background: $ph-error;
      border-radius: 50%;
      transform: translateY(100%);
    }
  }
  .redirect-btn {
    border-radius: $ph-medium-radius;
    display: flex;
    justify-content: center;
    font-size: $ph-body-font-size;
    font-weight: bold;
  }
}

.notif-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: $hr-text-regular;
  padding-bottom: $hr-spacing-07;
}

.notif-title-inner {
  margin-top: $hr-spacing-05;
}

.bar-wrapper-notif {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.purple-bar-notif {
  @media (max-width: 850px) {
    display: none;
  }
  width: 18px;
  height: 4px;
  background-color: $hr-bg-accent;
  position: absolute;
  margin-top: 2px;
  /* margin-left: 44.5px; */
  @include auto-rtl(margin-left, 44.5px);
}
.popover-notif {
  @media (max-width: 850px) {
    display: none !important;
  }
}
.option-line {
  position: fixed;
  height: 3px;
  background-color: #6300fa;
  top: 63px !important;
  width: 30px;
  top: 20px;
  z-index: 300;
  @media (min-width: 850px) {
    display: none;
  }
}
@media (max-width: 850px) {
  .custom-style {
    display: none !important;
  }
}
</style>
