<template>
  <h3 class="hera-title secondary-font" :class="[`hera-title--${variant}`]">
    <TextBlock
      v-bind="{
        size: 1.5,
        variant: 'primary',
        lineHeight: 1.5,
        text,
        tag,
      }"
    >
      <slot />
    </TextBlock>
  </h3>
</template>

<script>
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'Title',
  components: {
    TextBlock,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      validator(val) {
        return ['strong', 'em', 'normal'].indexOf(val) !== -1;
      },
      default: 'normal',
    },
  },
  computed: {
    tag() {
      return this.variant === 'normal' ? 'span' : this.variant;
    },
  },
};
</script>

<style lang="scss">
.hera-title {
  &--strong {
    // fill styles for strong variant of the Title here
  }

  &--em {
    // fill styles for em variant of the Title here
  }

  &--normal {
    // fill styles for normal variant of the Title here
  }
}
</style>

<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/Text"
  name="Title"
  knobs="{
    text: {
      default: text('Text', 'Another one boring title')
    },
    variant: {
      default: select('variant', ['strong', 'em', 'normal'], 'normal')
    }
  }"
>
  <Title v-bind="{text, variant}"/>
</story>
