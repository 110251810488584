<template>
  <div :class="columns ? 'row' : ''">
    <InputSocial
      v-for="(social, index) in items"
      :key="`social_${social.id}_${index}`"
      class="social-item"
      :class="columns ? `col-${(12 / columns) | 0}` : ''"
      :label="
        $t('_web_account-edit_social-input-label', '{socialName} link', {
          socialName: social.title,
        })
      "
      :placeholder="
        social.fixedPlaceholder
          ? ''
          : $t(
              '_web_account-edit_social-${social.id}_placeholder',
              `Enter your {socialTitle} ID`,
              { socialTitle: social.title }
            )
      "
      :icon="social.id"
      :value="value[social.id]"
      :input-left-padding="social.inputLeftPadding"
      :fixed-placeholder="social.fixedPlaceholder"
      @input="event => inputSocial(social.id, event)"
    />
  </div>
</template>

<script>
import InputSocial from '@/components/form/InputSocial';
import { emptyObject, emptyArray, prop } from '@/utils/factories';

export default {
  name: 'SocialsGroup',
  components: { InputSocial },
  props: {
    value: {
      type: Object,
      default: emptyObject,
    },
    items: {
      type: Array,
      default: emptyArray,
    },
    columns: prop(Number, 1),
  },
  methods: {
    inputSocial(type, value) {
      this.$emit('input', Object.assign({ ...this.value }, { [type]: value }));
    },
  },
};
</script>
<style lang="scss">
.social-item {
  .input-form {
    padding-left: 43px !important;
    @include auto-rtl(padding-left, 43px !important);
  }
}
</style>
