<template>
  <div class="history-payment-wrap">
    <Panel
      class="payment-history-wrapper"
      :variant="['transparent', 'stroked', 'stroked-input-borders', 'rounded']"
    >
      <div class="payment-history">
        <TextBlock
          tag="div"
          :size="1.25"
          class="secondary-font"
          variant="bold"
          >{{ $$t('Payment history') }}</TextBlock
        >
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="{ tabActive: tabStatus === 'shop' }"
          @click="(tabStatus = 'shop'), fetchHistory()"
        >
          {{ $t('_web_billing-shop', 'Shop') }}
        </div>
        <div
          class="tab"
          :class="{ tabActive: tabStatus === 'phmax' }"
          @click="(tabStatus = 'phmax'), fetchHistory()"
        >
          {{ $t('_web_billing-ph-max', 'Playhera MAX') }}
        </div>
      </div>
      <Paginated
        v-bind="{
          totalItems,
          pageSize,

          inContainer: true,
        }"
        class="w-100 h-auto"
        @change="fetchHistory"
      >
        <Table
          v-if="paymentHistory.length"
          :columns="columns()"
          :items="paymentHistory"
          class="transparent w-100 p-4 payment-history-table"
        >
          <div slot="head-item" slot-scope="{ column }" class="d-flex">
            <TextBlock :size="0.75" :line-height="1.4" variant="secondary">
              {{ $$t(column.label) }}
            </TextBlock>
          </div>
          <template #body-item="{ item, column: { key } }">
            <template v-if="key === 'date'">
              <TextBlock :size="0.825">{{
                item.processedOn | localDate($times.DATE_FULL)
              }}</TextBlock>
            </template>
            <template v-else-if="key === 'method'">
              <template>
                <TextBlock
                  v-if="item.sourceLastFour"
                  :size="0.825"
                  class="method-show"
                  >{{ itemMethod(item) }}</TextBlock
                >
                <div v-if="cardLogo(item)" class="card-logo-wrapper">
                  <Icon
                    v-if="cardLogo(item)"
                    :name="cardLogo(item)"
                    width="24"
                    style="height: 16px"
                    class="card-icon-history"
                  />
                </div>
              </template>
            </template>
            <template v-else-if="key === 'summary'">
              <TextBlock :size="0.825">{{ itemSummary(item) }}</TextBlock>
            </template>
            <template v-else-if="key === 'download'">
              <Icon
                v-if="hasInvoice(item.id)"
                name="download"
                v-bind="{ item }"
                class="download-icon"
                @click="downloadInvoiceID(item.id)"
              />
            </template>
            <TextBlock v-else :text="item[key]" />
          </template>
        </Table>
        <TextBlock v-else :size="1.25" class="mar-no-rec" variant="bold">
          {{ $t('_web_history-no-records', 'No records found') }}
        </TextBlock>
      </Paginated>
    </Panel>
    <div class="redeem-subs">
      <router-link
        v-if="hasRecords || hasExternal || hasCanceled"
        class="history-sub-button-container"
        :to="{ name: 'subscriptionSetup', query: { view: true } }"
      >
        <Btn class="btn btn-primary mt-6 history-sub-button">
          {{ $t('_web_profile_popover-subscriptions', 'Subscriptions') }}
        </Btn>
      </router-link>
      <div class="voucher-reedem_billing">
        <HeraButton
          v-if="!codeInputActive"
          class="redeem_btn"
          :label="$t('_web_redeem_voucher', 'Redeem Voucher')"
          @click="voucherRedeemActive"
        />
        <div v-else class="voucher-input-wrap">
          <HeraInput
            v-model="voucherCode"
            :label="$t('_web_redeem_voucher', 'Redeem Voucher')"
            :placeholder="$t('_web_voucher-enter-placeholder', 'Enter voucher')"
            class="voucher-input"
          />
          <HeraButton
            class="redeem-enter-btn"
            :label="$t('_web_voucher_enter', 'Enter')"
            :disabled="!voucherCode"
            @click="voucherRedeem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import Paginated from '@/components/common/Paginated';
import Table from '@/components/molecules/Table';
import { mapActions } from 'vuex';
import { translate } from '@/mixins/translate';
import listMixin from '@/mixins/paginatedLists';
import isLoading from '@/mixins/isLoading';
import TextBlock from '@/components/atoms/TextBlock.vue';
import Icon from '@/components/atoms/Icon.vue';
import Btn from '@/components/atoms/HeraFormBtn.vue';
import HeraButton from '@/components/atoms/HeraFormBtn';
import HeraInput from '@/components/atoms/form/HeraFormTextInput';
export default {
  name: 'SubscriptionHistory',
  components: {
    Panel,
    Paginated,
    Table,
    TextBlock,
    Icon,
    Btn,
    HeraButton,
    HeraInput,
  },
  mixins: [...listMixin, isLoading, translate('_web_subscription_history')],
  props: {
    hasExternal: {
      type: Boolean,
      default: false,
    },
    hasCanceled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paymentHistory: [],
      invoicesHistory: [],
      tabStatus: '',
      hasRecords: false,
      voucherCode: '',
      codeInputActive: false,
    };
  },
  created() {
    this.fetchHistory();
    this.invoiceHistory();
  },
  methods: {
    ...mapActions([
      'getPaymentsHistory',
      'exportInvoice',
      'getUserInvoices',
      'subscribeWithVoucher',
      'openModal',
      'closeModal',
      'closeModal',
      'errorNotify',
      'systemNotify',
    ]),
    voucherRedeemActive() {
      this.codeInputActive = true;
    },

    voucherRedeem() {
      if (this.hasExternal) {
        this.errorNotify({
          message: this.$t(
            '_web_voucher_external_error',
            'Sorry, you already have an active subscription. You can use this voucher after its expiration.'
          ),
          inModal: true,
          yesText: this.$t('_web_popup_ok', 'ok'),
        });
        this.codeInputActive = false;
        this.voucherCode = '';
      } else {
        this.openModal({
          name: 'HeraConfirm',
          props: {
            text: this.$t(
              '_web_voucher_confirm',
              'Are you sure you want to redeem this voucher?'
            ),
            confirmButtonText: this.$t('_web_popup_ok', 'ok'),
            cancelButtonText: this.$t('_web_popup_cancel', 'cancel'),
          },
          events: {
            confirm: () => {
              this.voucherSubscribe();
              this.closeModal();
              this.codeInputActive = false;
            },

            cancel: () => {
              (this.codeInputActive = false),
                this.closeModal(),
                (this.voucherCode = null);
            },
          },
        });
      }
    },
    voucherSubscribe() {
      this.subscribeWithVoucher({ code: this.voucherCode })
        .then(() => {
          this.openModal({
            name: 'HeraConfirm',
            props: {
              text: this.$t(
                '_web_subscription_voucher_redeemed',
                'Your PLAYHERA Max account will be ready in a few minutes. In the meantime, you can browse available games'
              ),
              cancelButtonText: this.$t('_web_stay_here', 'Stay here'),
              confirmButtonText: this.$t('_web_browse_games', 'Browse Games'),
            },
            events: {
              cancel: () => this.closeModal(),
              confirm: this.redirectBrowse,
            },
          });
          this.voucherCode = null;
        })
        .catch(err => {
          this.errorNotify({
            message: this.$t('_web_' + err.message),
            inModal: true,
            yesText: this.$t('_web_popup_ok', 'ok'),
          }).finally(() => {
            this.voucherCode = null;
            this.codeInputActive = false;
          });
        });
    },
    fetchHistory(newPage) {
      this.pageIndex = newPage;

      this.getPageData(
        'paymentHistory',
        this.getPaymentsHistory({
          query: {
            ...this.paramsWithPageNumber(),
            transaction_type:
              this.tabStatus === 'phmax'
                ? ['phmax', 'voucher']
                : this.tabStatus,
          },
        })
      )
        .then(res => {
          if (this.tabStatus === '') {
            res.length > 0
              ? (this.hasRecords = true)
              : (this.hasRecords = false);
          }
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    hasInvoice(id) {
      return this.invoicesHistory.includes(id);
    },
    invoiceHistory() {
      this.getUserInvoices()
        .then(data => {
          this.invoicesHistory = data;
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);

      return;
    },
    downloadInvoiceID(invoiceId) {
      this.exportInvoice({
        invoiceId,
      })
        .then(() => {})
        .catch(this.errorNotify);
    },

    columns() {
      return [
        { label: 'Date', key: 'date' },
        { label: 'Description', key: 'description' },
        {
          label: 'Method',
          key: 'method',
        },
        {
          label: 'Summary',
          key: 'summary',
        },
        { label: 'Download', key: 'download' },
      ];
    },
    itemMethod(historyItem) {
      if (historyItem.sourceProductType === 'APPLE_PAY') {
        return `Apple Pay`;
      } else if (historyItem.transactionType !== 'voucher') {
        return `**** **** **** ${historyItem.sourceLastFour}`;
      } else {
        return `${historyItem.sourceLastFour}`;
      }
    },
    itemSummary(historyItem) {
      if (historyItem.transactionType === 'voucher') {
        return historyItem.status === 'user_cancelled'
          ? this.$$t('Cancelled')
          : historyItem.status === 'failed'
          ? this.$$t('Payment Failed')
          : historyItem.status === 'success'
          ? this.$t('_web_billing_history-voucher-applied', 'Voucher Applied')
          : '';
      } else {
        return historyItem.status === 'user_cancelled'
          ? this.$$t('Cancelled')
          : historyItem.status === 'failed'
          ? this.$$t('Payment Failed')
          : `${historyItem.currency} ${historyItem.amount}`;
      }
    },
    cardLogo(historyItem) {
      let type = historyItem.sourceProductType;

      switch (true) {
        case /visa/i.test(type):
          return 'visa-logo';
        case /master/i.test(type):
          return 'mastercard-logo';
        case /american/i.test(type):
          return 'amex-logo';
        case /MADA/i.test(type):
          return 'mada-logo-png-transparent-2';
        case /APPLE/i.test(type):
          return 'ApplePay';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss">
.payment-history-wrapper {
  .hera-table-head {
    background-color: var(--ph-card);
  }

  table {
    @include auto-rtl(text-align, left);
    border-collapse: collapse;
    .card-icon {
      position: relative;
      top: -2px;
    }

    tr {
      border-bottom: solid 1px var(--ph-input-borders);
    }
  }
}

.history-payment-wrap {
  @media only screen and (max-width: 505px) {
    .history-sub-button-container {
      display: flex;
      justify-content: center;
    }
    .history-sub-button {
      margin-bottom: 2.5rem !important;
    }
  }

  @media only screen and (max-width: 450px) {
    .ph-panel--rounded {
      padding: 16px 0px 16px 0px !important;
      overflow: hidden;
    }
    .payment-history {
      text-align: center !important;
    }
  }
}
@media only screen and (max-width: 450px) {
  .app-ltr .hera-table tr th:last-child {
    padding-right: 10px !important;
  }
  .app-ltr .hera-table tr td:last-child {
    padding-right: 10px !important;
  }
  .app-ltr .hera-table tr th:first-child {
    padding-left: 10px;
  }
  .app-ltr .hera-table tr td:first-child {
    padding-left: 10px;
  }
  .card-icon-history {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.history-sub-button {
  margin-bottom: 3.5rem !important;
  background-color: $new-bg-primary !important;
}
.download-icon {
  cursor: pointer;
}
.history-sub-button:hover {
  background-color: $new-bg-primary-highlighted !important;
}
.tabs {
  display: flex;
  gap: 10px;
  margin-top: 1.5rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  border-bottom: 2px solid #222529;
}
.tab {
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.tabActive {
  border-bottom: 2px solid white;
}
.mar-no-rec {
  margin: auto;
  margin-top: 1.5rem;
}
.method-show {
  display: inline-block;
  min-width: 130px;
  @media screen and (max-width: 490px) {
    display: block;
    min-width: unset;
  }
}
</style>

<style lang="scss">
.voucher-reedem_billing {
  margin-top: -1.5rem;

  .playhera-label {
    margin-top: -26px;
    margin-bottom: 0;
  }
  display: flex;
  // position: absolute;
  @include auto-rtl(right, 0);
  // top: 0;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  @media screen and (max-width: 850px) {
    position: relative;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-top: -36px;
  }
}
.redeem_btn {
  width: fit-content;
  height: 37px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #30374a !important;
  opacity: 1;
  color: white !important;
  overflow: visible;
}
.voucher-input-wrap {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-top: 30px;
  }
}

.voucher-input {
  ::placeholder {
    color: #404a63 !important;
  }
  .input-form {
    margin-top: -7px;
    height: 37px;
    background-color: #1c212b !important;
    color: white;
  }
}
.redeem-enter-btn {
  width: fit-content;
  height: 37px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #5a58f2 !important;
  color: white !important;
  opacity: 1;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}
.redeem-subs {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-top: 30px;
  }
}
.card-logo-wrapper {
  display: inline-block;
  padding: 2px;
  border-radius: 5px;
  background-color: #1c212b;
}
</style>
