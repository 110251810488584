<template>
  <div
    id="app"
    :class="{ 'is-dragging': isDragging, 'is-dragging-files': isDraggingFiles }"
    class="position-relative"
  >
    <component :is="layout">
      <router-view />
    </component>
    <OfflinePopup />
    <MediaPlayer />
    <systemNotification class="blur-none" />
    <Modal />
    <LockScreen v-model="isScreenLocked" />
    <Alerts />
    <Maintenance />
    <PopoverMenu :closest="$el" />
    <Popover :closest="$el" />
  </div>
</template>

<script>
import { debounce } from '@/utils/lodashUtils';
import { globalEventListener, getScrollbarWidth } from '@/utils/globalEvent';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import systemNotification from '@/components/common/SystemNotification';
import OfflinePopup from '@/components/common/OfflinePopup';
import MediaPlayer from '@/components/common/MediaPlayer';
import Modal from '@/components/modal/index';
import Alerts from '@/components/modal/Alerts';
import Maintenance from '@/components/common/Maintenance';
import PopoverMenu from '@/components/popover/GlobalMenu';
import Popover from '@/components/popover/GlobalPopover';
import LockScreen from '@/components/molecules/LockScreen';
import { resetRouterHistory } from '@/utils/routerHistory';

const DEBOUNCE_TIME = 200; // ms

export default {
  name: 'App',
  components: {
    LockScreen,
    systemNotification,
    OfflinePopup,
    MediaPlayer,
    Modal,
    PopoverMenu,
    Popover,
    Alerts,
    Maintenance,
  },
  data() {
    return {
      isDragging: false,
      isDraggingFiles: false,
      scrollBarWidth: getScrollbarWidth() + 4,
      debounced: debounce(this.toggleDrag, DEBOUNCE_TIME),
    };
  },
  computed: {
    ...mapGetters([
      'isAuthorized',
      'isRTL',
      'isScreenLocked',
      'currentRegion',
      'locales',
    ]),
    ...mapState('my', ['account']),
    layout() {
      return `${this.$route.meta.layout || 'main'}-layout`;
    },
    /*    isMobile() {
      // Using Regex from "detectmobilebrowsers.com":
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },*/
  },
  watch: {
    isRTL() {
      this.setDirectionClassToBody();
    },
    '$route.fullPath'(newPath, oldPath) {
      if (oldPath === '/profile-setup/2' && this.account.incomplete) {
        window.location.href = `/`;
      }
    },
  },
  created() {
    resetRouterHistory();
    this.fetchRegions().then(regions => {
      const current = regions.find(r => r.is_current);
      const needToReload = !this.currentRegion;
      this.setRegion(this.currentRegion || current);

      if (needToReload) {
        const locale = this.locales.find(
          l => l.key.toLowerCase() === this.currentRegion.locale.toLowerCase()
        );
        if (locale) this.setLocale(locale);
        this.$router.go();
      }
    });

    if (this.isAuthorized) {
      this.fetchMyAccount()
        .then(data => {
          if (data && data.message) {
            setTimeout(() => {
              if (this.$route.query.changeRegion) {
                console.log(
                  `Region changed to ${this.$route.query.changeRegion}`
                );
                if (
                  this.account.incomplete &&
                  this.$route.name !== this.$routerNames.PROFILE_SETUP
                ) {
                  this.$router.push({ name: this.$routerNames.PROFILE_SETUP });
                }
              }
            }, 500);
          }
          //checking if user just changed the region
          if (this.$route.query.changeRegion) {
            console.log(`Region changed to ${this.$route.query.changeRegion}`);
            if (
              this.account.incomplete &&
              this.$route.name !== this.$routerNames.PROFILE_SETUP
            ) {
              this.$router.push({ name: this.$routerNames.PROFILE_SETUP });
            }
          }
        })
        .catch(() => {
          window.location.reload();
        });
    }

    this.setDirectionClassToBody();

    const eventKey = '.root';

    globalEventListener('remove', eventKey);

    globalEventListener('add', 'dragover' + eventKey, event => {
      event.preventDefault();
      this.debounced.cancel();

      const { types } = event.dataTransfer;
      this.toggleDrag(true, types && types.includes('Files'));
    });

    globalEventListener('add', 'drop' + eventKey, event => {
      event.preventDefault();
      this.debounced.cancel();
      this.toggleDrag(false);
    });

    globalEventListener('add', 'dragleave' + eventKey, event => {
      event.preventDefault();
      this.debounced(false);
    });
  },
  methods: {
    ...mapActions(['fetchRegions']),
    ...mapActions('my', ['fetchMyAccount']),
    ...mapMutations(['setRegion', 'setLocale']),
    toggleDrag(isDragging, isDraggingFiles = false) {
      this.isDragging = isDragging;
      this.isDraggingFiles = isDragging && isDraggingFiles;
    },
    setDirectionClassToBody() {
      const classListBody = document.body.classList;

      classListBody.toggle(this.$scss['dirClasses_rtl'], this.isRTL);
      classListBody.toggle(this.$scss['dirClasses_ltr'], !this.isRTL);

      document.documentElement.setAttribute('dir', this.isRTL ? 'rtl' : 'ltr');
    },
  },
};
</script>

<style lang="scss">
// TODO TH-7273 remove import from .vue's
@import './assets/scss/main';
#app {
  /*--scrollbar-width: 30;*/
  --scrollbar-width-units: calc(1px * var(--scrollbar-width));
  width: calc(100vw - var(--scrollbar-width-units));
  @include auto-rtl(left, 0);
}
</style>
