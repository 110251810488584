import api from '@/api';
import Vue from 'vue';
import { cloneDeep, isFinite } from '@/utils/lodashUtils';

const matchResultsModel = () => ({
  attachments: [],
  player_1_scored: null,
  player_2_scored: null,
  round: null,
  uri: null,
});

const resultsRequestBody = () => [
  {
    player_index: 'player_1',
    match_results: [],
  },
  {
    player_index: 'player_2',
    match_results: [],
  },
];

function formatResultsItem(payload) {
  return {
    player_1_scored: payload.player_1_scored,
    player_2_scored: payload.player_2_scored,
    round: payload.round,
    //prevent these props updating
    //    attachments: null,
    //    text: null,
    //        uri: null,
  };
}
function prepareResultsModel(data, rounds) {
  //prevent undefined errors in console
  return Array.from({ length: rounds }, (v, i) =>
    data && data[i] ? data[i] : { ...matchResultsModel(), round: i }
  );
}
export const INITIAL_BATTLES_STATE = () => ({
  matchRoundsAmount: 1,
  matchesResults: {
    //[matchID]: {},
  },
  resultsEditingObj: {},
  matchResultsTemp: {},
});

const getters = {
  resultsByMatchId: state => matchID => state.matchesResults[matchID],
  hasMatchTieResults: state => matchID => {
    const results = state.matchResultsTemp[matchID];
    return (
      Array.isArray(results) &&
      results.some(({ match_results }) => {
        const gameToWin = Math.ceil(match_results.length / 2);
        return match_results.reduce(
          (acc, results) => {
            const newP1 =
              !isFinite(results.player_1_scored) ||
              !isFinite(results.player_2_scored)
                ? null
                : acc.p1 + +(results.player_1_scored > results.player_2_scored);
            const newP2 =
              !isFinite(results.player_2_scored) ||
              !isFinite(results.player_2_scored)
                ? null
                : acc.p2 + +(results.player_2_scored > results.player_1_scored);
            return {
              p1: newP1,
              p2: newP2,
              tie:
                newP1 <= gameToWin &&
                newP2 <= gameToWin &&
                newP1 === newP2 &&
                isFinite(newP1) &&
                isFinite(newP2),
            };
          },
          { p1: 0, p2: 0, tie: false }
        ).tie;
      })
    );
  },
  hasMatchTieGame: state => matchID => {
    const results = state.matchResultsTemp[matchID];
    return (
      Array.isArray(results) &&
      results.some(({ match_results }) =>
        match_results.some(
          scores =>
            scores.player_1_scored === scores.player_2_scored &&
            scores.player_1_scored !== null &&
            scores.player_2_scored !== null
        )
      )
    );
  },
  matchResultsTemp: state => state.matchResultsTemp,
};

const mutations = {
  clearToBattlesDefaultState(state) {
    const defaultState = INITIAL_BATTLES_STATE();
    Object.keys(defaultState).forEach(key => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  setMatchResults(state, payload) {
    Vue.set(state.matchesResults, payload.battleID, payload.results);
  },
  updateMatchResults(state, payload) {
    state.matchesResults[payload.battleID] = payload.results;
  },
  setTempMatchResults(state, payload) {
    let battleID = payload.battle_id;
    Vue.set(
      state.matchResultsTemp[battleID][0].match_results,
      payload.round,
      formatResultsItem(payload.player_1)
    );
    Vue.set(
      state.matchResultsTemp[battleID][1].match_results,
      payload.round,
      formatResultsItem(payload.player_2)
    );
  },
  initTempMatchResults(state, payload) {
    let results = resultsRequestBody();
    results[0].match_results = payload.player_1.map(i => formatResultsItem(i));
    results[1].match_results = payload.player_2.map(i => formatResultsItem(i));
    Vue.set(state.matchResultsTemp, payload.battle_id, results);
  },
  clearTempMatchResults(state, battleID) {
    state.matchResultsTemp[battleID] = resultsRequestBody();
  },
};

const actions = {
  getJudgingList({ getters }, page) {
    return api.battles
      .getConflicted()(getters.accessToken, { params: { page } })
      .then(response => response.data);
  },
  getBattleResults(
    { commit, getters },
    { battleID, rounds = 1, isBattleRoyale = false }
  ) {
    return api.battles
      .getResults(battleID)(getters.accessToken)
      .then(({ data: results }) => {
        if (isBattleRoyale) {
          return results;
        }
        results = {
          ...results,
          player_1_match_results: prepareResultsModel(
            results.match_results
              ? results.match_results.player_1_match_results
              : null,
            rounds
          ),
          player_2_match_results: prepareResultsModel(
            results.match_results
              ? results.match_results.player_2_match_results
              : null,
            rounds
          ),
        };
        commit('setMatchResults', { results, battleID });
        return results;
      });
  },
  getBattleResultsGroup(
    {
      getters: { accessToken, currentCompetitionID },
      commit,
    },
    { params }
  ) {
    return api.battles
      .getResultsAll(currentCompetitionID)(accessToken, {
        params,
      })
      .then(response => {
        let results = response.data.items.map(item => {
          let battleID = item.id;
          let numberOfRounds = item.number_of_matches;
          return {
            ...item,
            battle_id: battleID,
            number_of_rounds: numberOfRounds,
            player_1_match_results: prepareResultsModel(
              item.player_1_match_results,
              numberOfRounds
            ),
            player_2_match_results: prepareResultsModel(
              item.player_2_match_results,
              numberOfRounds
            ),
            // date_end: item.date_end * 1000,
            // date_start: item.date_start * 1000,
            //            id: item.correlation_id,
          };
        });
        results.forEach(res => {
          commit('setMatchResults', { res, battleID: res.battle_id });
        });
        return {
          ...response.data,
          items: results,
        };
      });
  },
  resolveBattle(
    {
      getters: {
        accessToken,
        matchResultsTemp,
        currentCompetitionID,
        currentTournament,
        currentStageID,
      },
      commit,
    },
    { battleID, matchID }
  ) {
    let resolution = {
      completed: true,
      players_match_results: matchResultsTemp[battleID][0].match_results,
    };
    // check if admin setted result and scores are equal TH-6966
    const hasBadProperty = matchResultsTemp[battleID][0].match_results.find(
      ({ player_1_scored, player_2_scored }, index) =>
        player_1_scored !==
          matchResultsTemp[battleID][1].match_results[index].player_1_scored ||
        player_2_scored !==
          matchResultsTemp[battleID][1].match_results[index].player_2_scored
    );
    if (hasBadProperty) {
      return Promise.reject();
    }

    return api.battles
      .resolve(
        currentTournament.id,
        currentStageID,
        currentCompetitionID,
        matchID
      )(accessToken, resolution)
      .then(response => {
        let result = response.data;
        commit('updateMatchResults', { result, battleID });
        commit('clearTempMatchResults', battleID);
        return result;
      });
  },
  resolveBattleInBattleRoyale(
    {
      getters: {
        accessToken,
        currentCompetitionID,
        currentTournament,
        currentStageID,
      },
    },
    { battleID, matchID, players }
  ) {
    let resolution = players
      .filter(({ isChanged }) => isChanged)
      .map(({ profile: { id, login }, score: { rank, kills } }) => ({
        battle_id: battleID,
        player_id: id || login,
        rank: +rank,
        kill_count: +kills || 0,
      }));

    return api.battles
      .resolve(
        currentTournament.id,
        currentStageID,
        currentCompetitionID,
        matchID
      )(accessToken, resolution)
      .then(({ data }) => data);
  },
  provideBattleResultsByAdmin(
    {
      getters: {
        accessToken,
        currentCompetitionID,
        currentTournament,
        currentStageID,
        matchResultsTemp,
      },
      commit,
    },
    { id: matchID, battle_id: battleID }
  ) {
    const tournamentID = currentTournament.id;
    const stageID = currentStageID;
    const competitionID = currentCompetitionID;
    let results = cloneDeep(matchResultsTemp[battleID]);
    // prevent null passed to backend
    results[0].match_results = results[0].match_results.filter(item => {
      return item.player_1_scored !== null && item.player_2_scored !== null;
    });
    // prevent null passed to backend
    results[1].match_results = results[1].match_results.filter(item => {
      return item.player_1_scored !== null && item.player_2_scored !== null;
    });
    return api.competitions
      .provideResultByAdmin(tournamentID, stageID, competitionID, matchID)(
        accessToken,
        {
          match_player_results: results,
          type: 'online',
        }
      )
      .then(response => {
        let result = response.data;
        commit('updateMatchResults', { result, battleID });
        commit('clearTempMatchResults', battleID);
        return result;
      });
  },
  provideBattleResultsByAdminInBattleRoyale(
    {
      getters: {
        accessToken,
        currentCompetitionID,
        currentTournament,
        currentStageID,
      },
    },
    { id: matchID, players, battle_id }
  ) {
    // prepare:
    const changedPlayers = players
      .filter(({ isChanged }) => isChanged)
      .map(({ profile: { id, login }, score: { rank, kills } }) => ({
        battle_id,
        player_id: id || login,
        rank: +rank,
        kill_count: +kills || 0,
      }));

    return api.competitions
      .provideResultByAdmin(
        currentTournament.id,
        currentStageID,
        currentCompetitionID,
        matchID
      )(accessToken, {
        match_player_results: changedPlayers,
        type: 'battle_royale',
      })
      .then(({ data }) => data);
  },
  //will be deprecated soon
  provideOfflineBattleResults: ({ getters: { accessToken } }, payload) =>
    api.battles
      .provideOfflineResult(payload.battleId)(accessToken, {
        match_results: payload.results,
      })
      .then(response => response.data),
};

export default {
  state: INITIAL_BATTLES_STATE(),
  getters,
  mutations,
  actions,
};
