<template>
  <div>
    <HeraFormSwitch
      v-model="tieMatchAllowedModel"
      v-validate="'required'"
      :label="$$t('Tie-result allowed')"
      name="tieMatchAllowed"
      :disabled="isStageFieldDisabled('tieMatchAllowed') || disabled"
      required
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';
import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import { prop } from '@/utils/factories';
export default {
  name: 'StageTieMatchSetting',
  components: {
    HeraFormSwitch,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    tieMatchAllowedModel: {
      get() {
        return this.getBlockConfigurationByKey('tie_match', false);
      },
      set(newVal) {
        const blockConfig = this.$lodash.cloneDeep(
          this.$lodash.get(
            this.currentTournamentStage,
            `block_configuration`,
            {}
          )
        );
        if (newVal) {
          blockConfig.tie_match = blockConfig.bestOf === 1;
          blockConfig.points_win =
            blockConfig.points_win || this.stageDefaults.pointsForWin;
          blockConfig.points_tie =
            blockConfig.points_tie || this.stageDefaults.pointsForTie;
          blockConfig.points_loss =
            blockConfig.points_loss || this.stageDefaults.pointsForLose;
        } else {
          blockConfig.tie_match = false;
          blockConfig.points_win = null;
          blockConfig.points_tie = null;
          blockConfig.points_loss = null;
        }
        blockConfig.tie_match = newVal;
        this.updateCurrentTournamentStage({
          block_configuration: blockConfig,
        });
      },
    },
  },
};
</script>

<style></style>
