var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plans-container"},[_c('div',{staticClass:"plans-wrapper d-flex"},[_c('div',{staticClass:"subscription-announcement-wrapper"},[_c('div',{staticClass:"full-one"},[_c('div',{staticClass:"subscription-description"},[_c('h1',{staticClass:"subscription-title"},[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_subscription_announcement_title',
                'PLAYHERA Max is Coming Soon!'
              ))+"\n          ")]),_c('img',{staticClass:"ph-bg-img-mobile",attrs:{"src":require("@/assets/images/background/subscription-default.jpg"),"alt":""}}),_c('p',{staticClass:"subtitle-text"},[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_subscription_announcement_title_subtitle',
                'The future of gaming.'
              ))+"\n          ")]),_c('p',{staticClass:"description-text"},[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_subscription_announcement_subtitle',
                " Over 500 game titles. We’ve got award winning, most popular, and new games constantly updating. Play your\n            favourite games anywhere and at any time."
              ))+"\n          ")])]),_c('div',{staticClass:"what-is-phmax"},[_c('h4',[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_subscription_announcement_explain_title',
                'What is PLAYHERA Max?'
              ))+"\n          ")]),_c('p',{staticClass:"ph-max-description"},[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_subscription_announcement_explain_text',
                "PLAYHERA MAX is a cloud gaming platform that provides two-click access to high-end video games with amazing\n            gaming experience regardless of the type of device. Play your favourite games at 60 fps (Full HD 1080p) on a\n            PC, laptop, tablet, Android TV, or even phone."
              ))+"\n          ")]),_c('p',{staticClass:"input-label"},[_vm._v("\n            "+_vm._s(_vm.$t(
                '_web_subscription_announcement_cta',
                'To be the first to know about PLAYHERA subscribe to our email list.'
              ))+"\n          ")])]),_c('div',{staticClass:"input-wrapper-subscriptions"},[_c('InputEmail',{directives:[{name:"focus",rawName:"v-focus"},{name:"validate",rawName:"v-validate",value:('email|max:128'),expression:"'email|max:128'"}],staticClass:"input-email",attrs:{"name":"email","type":"email","placeholder":_vm.$t('_web_forgotpassword_email-label', 'Email'),"tabindex":1,"autofocus":"","autocomplete-off":"","error":_vm.errors.first('email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('Btn',{staticClass:"btn-accent",attrs:{"loading":_vm.isLoading,"label":_vm.$t('_web_subscription_announcement_subscribe', 'SUBSCRIBE')},on:{"click":_vm.saveEmail}})],1)]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"empty-one"},[_c('img',{staticClass:"ph-bg-img",attrs:{"src":require("@/assets/images/background/subscription-default.jpg"),"alt":""}})])}]

export { render, staticRenderFns }