var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentTournament.prize.prize_line)?_c('Panel',{staticClass:"tournament-info_prizes pb-0",attrs:{"variant":['transparent', 'rounded-pre-medium']}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"header"},[_c('Subheader',{staticClass:"mb-4 text-uppercase font-weight-normal",attrs:{"text":_vm.$t('_web_tournament-info_prizes_tournament', 'Prizes'),"text-block-props":{ size: 0.875 },"variant":"hr-secondary"}})],1),_vm._l((_vm.winners),function(ref,index){
var place = ref.place;
var players = ref.players;
return _c('div',{key:index},[_c('hr',{directives:[{name:"show",rawName:"v-show",value:(index !== 0 && index !== 3),expression:"index !== 0 && index !== 3"}],class:['horizontal-line']}),_c('ListItem',{staticClass:"items",class:{ 'bg-winner': index === 0 }},[_c('template',{slot:"title"},[_c('div',{staticClass:"prizes-container"},[_c('ListItem',{staticClass:"primary font-weight-bold tournament-place",class:{ 'invisible mt-5': index === 3 },attrs:{"title":_vm.dummyPlaces[place]}}),(players && players.length)?_c('ListItemAccountSingle',_vm._b({staticClass:"tournament-player"},'ListItemAccountSingle',{
                  type: players[0].type,
                  name: ("@" + (_vm.$filters.displayName(players[0]))),
                  accountId: players[0].id,
                  approved: players[0].isAmbassador || players[0].validated,
                  size: 48,
                },false)):_vm._e(),_c('TextBlock',{staticClass:"text-nowrap primary tournament-prize font-weight-bold",attrs:{"slot":"tail","line-height":1.5},slot:"tail"},[_vm._v("\n                "+_vm._s(_vm.getPlacePrize(index))+"\n              ")])],1)])],2)],1)})],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }