<template>
  <div v-if="prizeCount" class="card-prize">
    <div
      class="d-flex flex-column justify-content-center tournament-card_prize"
    >
      <TextBlock
        class="text-right text-truncate px-1 mb-0"
        tag="h6"
        :size="1"
        :style="{ fontWeight: 700 }"
      >
        {{ prizeCount }}
        <br v-if="prizeCurrency" />
        {{ prizeCurrency || '' }}
      </TextBlock>
    </div>
  </div>
</template>

<script>
import { emptyObject, propRequired } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
import { mapGetters } from 'vuex';

const freezeEmptyObject = emptyObject(); // read only
Object.freeze(freezeEmptyObject);

export default {
  name: 'TournamentCardPrize',
  components: {
    TextBlock,
  },
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    ...mapGetters(['isRTL']),
    prizeCount({ tournament: { prize } }) {
      const { prize_pool } = prize || freezeEmptyObject;
      return prize_pool;
    },
    prizeCurrency({ tournament: { prize } }) {
      const { currency } = prize || freezeEmptyObject;
      return currency;
    },
    rtlStyle() {
      return this.isRTL ? { transform: 'scale(-1, 1)' } : null;
    },
  },
};
</script>

<style lang="scss">
@import '../variables';

.card-prize {
  flex: 1 1 auto;
}

.tournament-card_prize {
  min-width: 60px;
  background: var(--background-color);
  margin-left: $hr-spacing-03;
}
</style>
