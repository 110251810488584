import { prop } from '@/utils/factories';

const CLASSES = {
  [0]: 'current',
  [-1]: 'up',
  [1]: 'down',
  [-2]: 'current-up',
  [2]: 'current-down',
  [3]: 'join',
};

export default {
  props: {
    /**
     * +1 - from down (⮣⭗)
     * -1 - from up (⮡ ⭗)
     * 0 - current Y (⭢⭗)
     * +2 - current wave from down (↝⭗)
     * -2 - current wave from up (↝⭗)
     * +3 - join 2 in 1 (↣ ⭗)
     */
    fromBind: prop(Number, null),
    /**
     * +1 - to (⭗⮧)
     * -1 - to up (⭗⮥)
     * 0 - current Y (⭗⭢)
     * +2 - current wave to down (⭗↝)
     * -2 - current wave to up (⭗↝)
     * +3 - not released (non need)
     */
    toBind: prop(Number, null),
    offset: prop(Object, null), // ex: {currentY: Number, nextY: Number} // unit is `px`
  },
  methods: {
    getClasses(fromBind = this.fromBind, toBind = this.toBind) {
      const classes = [
        ...(fromBind !== null
          ? ['bind-from', `bind-from-${CLASSES[fromBind]}`]
          : ''),
        ...(toBind !== null ? ['bind-to', `bind-to-${CLASSES[toBind]}`] : ''),
      ];
      return classes.join(' ');
    },
    getOffset(offset = this.offset) {
      return offset
        ? {
            '--current-y': offset.currentY,
            '--next-y': offset.nextY,
          }
        : null;
    },
  },
};
