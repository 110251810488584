import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const getTournamentPlayers = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players`, {
    preventCancelRequest: true,
  });

export const setTournamentPlayerParams = (tournamentID = null, login = '') =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players/${login}`);

export const setTournamentPlayersExtendedData = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players`);

export const getTournamentAttributes = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players_attributes`);

export const setFillParticipants = (tournamentID = null) =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/players/fill`);

export const createTournamentPlayersCSV = tournamentID =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/players/csv`);

export const getTournament = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}`);

export const joinTournament = tournamentID =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/players`);

export const validateTournament = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players/validation`);

export const putSquadParticipants = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/squads`);

export const getSquadParticipants = tournamentID =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/squads`, {
    preventCancelRequest: true,
  });

export const putRevokeTournament = tournamentID =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/revoke`);

export default {
  getTournamentPlayers,
  setTournamentPlayerParams,
  setTournamentPlayersExtendedData,
  getTournamentAttributes,
  setFillParticipants,
  createTournamentPlayersCSV,
  getTournament,
  joinTournament,
  validateTournament,
  putSquadParticipants,
  getSquadParticipants,
  putRevokeTournament,
};
