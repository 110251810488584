<template>
  <section class="w-100">
    <form class="reset-container" @submit.prevent="formSubmit">
      <div class="d-flex justify-content-center">
        <h1 class="font-weight-bold hera-heading text-center">
          {{ $t('web_submitpassword_title', 'Enter New Password') }}
        </h1>
      </div>
      <div class="input-container">
        <HeraFormPasswordInput
          v-model.trim="password"
          v-validate="$options.passwordValidationRule"
          name="password"
          type="password"
          :tabindex="1"
          :autofocus="true"
          :label="$t('web_submitpassword_password_input_label', 'New Password')"
          :error="errors.first('password')"
        />
        <div class=" button-container w-100">
          <HeraFormSubmitBtn
            id="submitPassword"
            :tabindex="2"
            :label="$t('web_submitpassword_submit_button', 'Submit Password')"
            :loading="isLoading"
            class="button-new"
          />
          <div>
            <router-link
              :tabindex="3"
              :to="{ name: 'signin' }"
              class="back-signin"
            >
              {{ $t('web_forgotpassword_signin_label', 'Back to sign in') }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="">
        <div class=""></div>
      </div>
    </form>
    <CongratulationPopup
      :title="
        $t('_web_forgotpassword_changed', 'Your password has been changed')
      "
      :redirect-to="{ name: 'signin' }"
      :is-visible="isPasswordChanged"
    >
    </CongratulationPopup>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import { password as passwordValidationRule } from '@/constants/validationRules';
import HeraFormPasswordInput from '@/components/form/InputPassword';
import HeraFormSubmitBtn from '@/components/form/HeraFormSubmitBtn';
import CongratulationPopup from '@/components/popup/CongratulationPopup';
export default {
  name: 'SubmitPassword',
  inject: ['$validator'],
  components: {
    HeraFormPasswordInput,
    HeraFormSubmitBtn,
    CongratulationPopup,
  },
  data() {
    return {
      password: null,
      isLoading: false,
      isPasswordChanged: false,
    };
  },
  created() {
    this.$options.passwordValidationRule = passwordValidationRule;
  },
  beforeRouteLeave(to, from, next) {
    this.clearAllTokens();
    next();
  },
  methods: {
    ...mapActions(['clearAllTokens']),
    ...mapActions({
      sendRequest: 'submitPass',
      errorNotify: 'errorNotify',
    }),
    formSubmit() {
      this.isLoading = true;
      this.$validator
        .validateAll()
        .then(
          result =>
            result
              ? Promise.resolve({ password: this.password })
              : Promise.reject() // eslint-disable-line
        )
        .then(this.sendRequest)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch('clearAllTokens');
          this.isPasswordChanged = true;
        })
        .catch(codeError => {
          this.isLoading = false;
          if (
            !codeError.skipNext &&
            typeof codeError.skipNext !== 'undefined'
          ) {
            this.errorNotify(
              this.$t(
                '_web_common-error',
                'Something went wrong. Please try again later'
              )
            );
          }
        });
    },
  },
};
</script>
<style lang="scss">
.reset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.back-signin {
  color: $new-bg-primary;
}
.input-container {
  width: 25vw;
  min-width: 380px;
  gap: 1.5rem;
  display: flex;
  max-width: 25rem;
  flex-direction: column;
  @media (max-width: 650px) {
    width: 60vw;
    @media (max-width: 475px) {
      width: 80vw;
      min-width: 275px;
    }
  }
}
.button-new {
  width: 100%;
  height: 2.5rem;
  background-color: $new-bg-primary !important;
  border-radius: 0.5rem;
  border: none;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
</style>
