<script>
import { mapGetters } from 'vuex';
import {
  TOURNAMENT_STATUSES,
  STAGE_STATUSES,
  CONSTRUCTOR_MODES,
} from '@/constants/tournaments';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
import tournamentsHelper from '@/mixins/tournaments/tournamentHelpers';
import { mapActions } from 'vuex';

export default {
  name: 'StatusBtn',
  components: {
    HeraFormBtn,
  },
  mixins: [...tournamentsHelper],
  props: {
    mode: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'canDraft',
      'canAnnounce',
      'currentTournament',
      'currentTournamentStages',
      'isTournamentChanged',
      'canSaveNewTournament',
      'isTournamentPastStatus',
    ]),
    labels() {
      return {
        next: this.$t('_web_tournaments_next-btn', 'Next'),
        save: this.$t('_web_tournaments_save-btn', 'Save'),
        announce: this.$t('_web_tournaments_announce-btn', 'Announce'),
        open: this.$t(
          '_web_tournaments_open-registration-btn',
          'Open Registration'
        ),
        close: this.$t(
          '_web_tournaments_close-registration-btn',
          'Close Registration'
        ),
        complete: this.$t(
          '_web_tournaments_complete-qualification-btn',
          'Complete Qualification'
        ),
        publishBracket: this.$t(
          '_web_tournaments_build-bracket-btn',
          'Publish Bracket'
        ),
        unpublishBracket: this.$t(
          '_web_tournaments_unpublish-bracket-btn',
          'Unpublish Bracket'
        ),
        start: this.$t('_web_tournaments_start-btn', 'Start Tournament'),
        restart: this.$t('_web_tournaments_restart-btn', 'Restart'),
      };
    },
    hideButton() {
      return (
        !this.mode &&
        [
          TOURNAMENT_STATUSES.STARTED,
          TOURNAMENT_STATUSES.CLOSED,
          TOURNAMENT_STATUSES.ARCHIVED,
        ].includes(this.tournamentStatus)
      );
    },
    tournamentStatus() {
      return this.currentTournament.status;
    },
    firstStage() {
      return this.currentTournamentStages[0];
    },
    buttonProps() {
      let label;
      let disabled = false;
      let forceHide = false;
      let handler = () => {};
      // if (this.canDraft) {
      //   label = this.labels.save;
      //   handler = this.save;
      //   disabled = !this.canSaveNewTournament;
      // } else
      if (this.mode) {
        switch (this.mode) {
          case 'next':
            label = this.labels.next;
            handler = this.goToNextRoute;
            break;
          case 'announce':
            // if (this.isRejected) {
            //   label = this.labels.restart;
            //   handler = this.onTournamentRestart;
            //   disabled = !this.isRejected;
            // } else {
            label = this.labels.announce;
            handler = this.announce;
            disabled = !this.canAnnounce;
            forceHide = this.isTournamentPastStatus(
              TOURNAMENT_STATUSES.ANNOUNCED
            );
            // }
            break;
          case 'participants':
            if (this.isAnnounced || this.isDraft) {
              label = this.labels.open;
              handler = this.openRegistration;
              disabled = !this.isAnnounced;
            } else if (this.isRegStarted) {
              label = this.labels.close;
              handler = this.closeRegistration;
              disabled = !this.isRegStarted;
            } else {
              label = this.labels.complete;
              handler = this.completeQualification;
              disabled = !this.isRegClosed;
              forceHide = this.isTournamentPastStatus(
                TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
              );
            }
            break;
          case 'publish':
            if (
              (this.isScheduled &&
                this.firstStage.status === STAGE_STATUSES.DRAFT) ||
              (!this.isScheduled && !this.isStarted)
            ) {
              label = this.labels.publishBracket;
              handler = this.publishBracket;
              disabled = !(
                this.isScheduled &&
                this.firstStage.status === STAGE_STATUSES.DRAFT
              );
            } else {
              label = this.labels.unpublishBracket;
              handler = this.unpublishBracket;
              disabled = !(
                this.isScheduled &&
                this.firstStage.status === STAGE_STATUSES.PUBLISHED
              );
              forceHide = this.isTournamentPastStatus(
                TOURNAMENT_STATUSES.SCHEDULED
              );
            }
            break;
          case 'start':
            label = this.labels.start;
            handler = this.onTournamentStart;
            disabled = !this.isScheduled || !this.firstStage.id;
            forceHide = true;
            break;
          default:
            break;
        }
      } else {
        if (this.canDraft || this.canAnnounce) {
          label = this.labels.announce;
          handler = this.announce;
        } else if (this.isAnnounced) {
          label = this.labels.open;
          handler = this.openRegistration;
        } else if (this.isRegStarted) {
          label = this.labels.close;
          handler = this.closeRegistration;
        } else if (this.isRegClosed) {
          label = this.labels.complete;
          handler = this.completeQualification;
        } else if (
          this.isScheduled &&
          this.firstStage.status === STAGE_STATUSES.DRAFT
        ) {
          label = this.labels.publishBracket;
          handler = this.publishBracket;
        } else if (this.isScheduled) {
          label = this.labels.start;
          handler = this.onTournamentStart;
          disabled = !this.firstStage.id;
        } else if (this.isRejected) {
          label = this.labels.restart;
          handler = this.onTournamentRestart;
        }
      }

      return { label, disabled, handler, forceHide };
    },
    isTournamentSettings() {
      return this.$route.name === 'TournamentSettings';
    },
    isBracketSetup() {
      return ['editSingleStage', 'editStage', 'addStage'].includes(
        this.$route.name
      );
    },
  },
  methods: {
    ...mapActions(['errorNotify']),
    takeStatusAction(method, withAnounceModal = true, lockScreen = true) {
      return this.isTournamentSettings
        ? this.validateCurrentForm()
            .then(() => this.onUpdateTournament(lockScreen, withAnounceModal))
            .then(method)
            .catch(this.errorNotify)
        : this.isBracketSetup
        ? this.validateCurrentForm()
            .then(() => {
              const stageID =
                this.$route.name === 'editSingleStage'
                  ? this.firstStage.id
                  : this.$route.params.stageID;
              return this.onUpdateStage(stageID);
            })
            .then(method)
            .catch(this.errorNotify)
        : method();
    },
    announce() {
      this.takeStatusAction(this.showAnnounceConfirm, false);
    },
    goToNextRoute() {
      // TODO: should take route from NAVIGATION_BUTTONS constants config or better from current route itself
      this.$router.push({
        name: 'multiStage', // hardcoded next route value for now
        params: {
          ...this.$route.params,
          constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
        },
      });
    },
    openRegistration() {
      this.takeStatusAction(this.showStartRegistrationConfirm);
    },
    closeRegistration() {
      this.takeStatusAction(this.showCloseRegistrationConfirm);
    },
    completeQualification() {
      this.takeStatusAction(this.completeQualificationMethod);
    },
    completeQualificationMethod() {
      return this.$router
        .push({
          name: 'multiStage',
          params: {
            ...this.$route.params,
            constructorMode: CONSTRUCTOR_MODES.PUBLISH,
          },
        })
        .then(() => this.$store.getters.tournamentUpdateStopover)
        .then(() => this.showCompleteQualifyConfirm());
    },
    // publish first stage
    publishBracket() {
      if (this.firstStage.id) {
        this.takeStatusAction(this.publishMethod);
      }
    },
    publishMethod() {
      return this.$store.getters.tournamentUpdateStopover.then(() =>
        this.showModalPublish(this.firstStage.id)
      );
    },
    unpublishBracket() {
      if (this.firstStage.id) {
        this.takeStatusAction(this.unpublishMethod);
      }
    },
    unpublishMethod() {
      return this.$store.getters.tournamentUpdateStopover.then(() =>
        this.showModalUnPublish(this.firstStage.id)
      );
    },
  },
  render(createElement) {
    return this.hideButton || this.buttonProps.forceHide
      ? null
      : createElement(HeraFormBtn, {
          class: [
            { 'is-loading': this.isLoading },
            'btn-primary',
            'status-btn',
          ],
          props: {
            label: this.buttonProps.label,
            disabled:
              this.isLoading || this.disabled || this.buttonProps.disabled,
          },
          on: {
            click: () => this.buttonProps.handler(),
          },
        });
  },
};
</script>

<style scoped>
.status-btn {
  min-width: 220px !important;
}
</style>
