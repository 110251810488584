<template>
  <div class="d-flex tournament-card-price mt-2">
    <div class="entry">
      <span>{{ $t('web.tournament_entry', 'ENTRY') }}</span>
    </div>
    <div v-if="tournament.entry_fee.required" class="price">
      <span>{{ tournament.entry_fee.currency }}</span>
      <span>{{ tournament.entry_fee.cost }}</span>
    </div>
    <span v-else class="free">{{
      $t('web.tournament_entry_free', 'FREE')
    }}</span>
  </div>
</template>

<script>
import { propRequired } from '@/utils/factories';

const TOURNAMENT_INVITE_ONLY_REGISTRATION = 'closed';

export default {
  name: 'TournamentEntry',
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    text({ tournament: { title } }) {
      return title;
    },
    needInvite() {
      return (
        this.tournament.registrationType ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION ||
        this.tournament.registration_type ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION
      );
    },
  },
};
</script>
<style lang="scss">
.tournament-card-price {
  align-items: center;
  gap: 10px;
  .entry {
    font-family: Exo;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
  }
}

.title-app-icon {
  @include auto-rtl(margin-right, $hr-spacing-02);
}
</style>
