<template>
  <div class="widget-actions-wrapper">
    <WidgetWrapper
      v-if="currentTournament.id"
      :title="$$t('Actions')"
      class="bg-transparent px-5 pt-1"
    >
      <Spinner v-if="qualifyLoading" class="management-loader" />
      <TextBlock
        v-if="noActions"
        :text="$$t('No actions to show')"
        tag="p"
        variant="secondary"
        class="mb-0"
      />
      <template v-else>
        <ActionItem
          v-for="(action, index) in visibleActions"
          :key="action.key"
          v-bind="{ action }"
          :loading="isLoading"
          :class="[index !== visibleActions.length - 1 ? 'mb-3' : 'mb-0']"
        />
      </template>
    </WidgetWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  TOURNAMENT_STATUSES,
  // STAGE_STATUSES,
  CONSTRUCTOR_MODES,
} from '@/constants/tournaments';
import { translate } from '@/mixins/translate';
import Spinner from '@/views/main/components/Spinner.vue';
import drawGridMixin from '@/views/tournaments/management/brackets/mixins/drawGrid.js';
import tournamentsHelper from '@/mixins/tournaments/tournamentHelpers';
import WidgetWrapper from '@/views/tournaments/management/dashboard/components/WidgetWrapper';
import ActionItem from '@/views/tournaments/management/dashboard/components/TournamentDashboardActionItem';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'TournamentActionsWidget',
  components: {
    WidgetWrapper,
    Spinner,
    ActionItem,
    TextBlock,
  },
  mixins: [
    translate('_web_tournament_dashboard'),
    ...tournamentsHelper,
    drawGridMixin,
  ],
  data() {
    return {
      qualifyLoading: false,
      checkInLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'canAnnounce',
      'currentTournament',
      'currentTournamentStages',
      'firstDraftStage',
      'firstScheduledStage',
      'previousStageFinished',
      'isTournamentPastStatus',
      'hasCheckInInFirstStage',
      'checkIfCheckInInFirstStageStarted',
      'popoverChatIsOpened',
      'sliderChatUnreadCount',
      'getAllDisputesCount',
    ]),
    autoProcessing() {
      return this.$lodash.get(
        this.currentTournament,
        'schedule.auto_activation_mode'
      );
    },
    /*    autoRegistration() {
      return this.$lodash.get(
        this.currentTournament,
        'automation.auto_registration',
        true
      );
    },*/
    /* autoQualify() {
      return (
        this.$lodash.get(
          this.currentTournament,
          'automation.auto_qualify',
          'review'
        ) !== 'manual'
      );
    },
    fullAutoQualify() {
      return (
        this.$lodash.get(
          this.currentTournament,
          'automation.auto_qualify',
          'review'
        ) !== 'auto'
      );
    },
    autoBrackets() {
      return (
        this.$lodash.get(
          this.currentTournament,
          'automation.auto_brackets',
          'review'
        ) !== 'manual'
      );
    },
    fullAutoBrackets() {
      return (
        this.$lodash.get(
          this.currentTournament,
          'automation.auto_brackets',
          'review'
        ) !== 'auto'
      );
    },*/
    playersInfo() {
      return this.$lodash.get(this, 'currentTournament.players_info') || {};
    },
    playersConfig() {
      return this.$lodash.get(this, 'currentTournament.players_config') || {};
    },
    allActions() {
      return [
        {
          key: 'announce',
          label: this.$t('_web_tournaments_announce-btn', 'Announce'),
          disabled: !this.canAnnounce,
          handler: this.showAnnounceConfirm,
          automated: false,
          visible: true,
          isPast: this.isTournamentPastStatus(TOURNAMENT_STATUSES.ANNOUNCED),
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
        },
        {
          key: 'openReg',
          label: this.$t(
            '_web_tournaments_open-registration-btn',
            'Open Registration'
          ),
          disabled: !this.isAnnounced,
          handler: this.showStartRegistrationConfirm,
          // navigationHandler: this.goToParticipants,
          automated: true,
          visible: true,
          isPast: this.isTournamentPastStatus(TOURNAMENT_STATUSES.REG_OPENED),
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
          date: this.$lodash.get(
            this,
            'currentTournament.schedule.registration_start'
          ),
        },
        {
          key: 'closeReg',
          label: this.$t(
            '_web_tournaments_close-registration-btn',
            'Close Registration'
          ),
          disabled: !this.isRegStarted,
          handler: this.showCloseRegistrationConfirm,
          // navigationHandler: this.goToParticipants,
          automated: true,
          visible: true,
          isPast: this.isTournamentPastStatus(TOURNAMENT_STATUSES.REG_CLOSED),
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
          date: this.$lodash.get(
            this,
            'currentTournament.schedule.registration_end'
          ),
        },
        {
          key: 'quialifyParticipants',
          label: this.$t(
            '_web_tournaments_go-to-qualify-btn',
            'Qualify participants'
          ),
          disabled:
            !this.isTournamentPastStatus(TOURNAMENT_STATUSES.REG_OPENED) ||
            this.isTournamentPastStatus(
              TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
            ),
          handler: this.qualifyParticipants,
          // navigationHandler: this.goToParticipants,
          automated: this.autoProcessing,
          visible:
            this.playersInfo.current_qualifiers < this.playersConfig.squads,
          isPast: this.isTournamentPastStatus(
            TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
          ),
          // visible:
          //   !this.isTournamentPastStatus(
          //     TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
          //   ) &&
          //   this.playersInfo.current_qualifiers < this.playersConfig.squads,
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
        },
        {
          key: 'completeQualification',
          label: this.$t(
            '_web_tournaments_complete-qualification-btn',
            'Complete Qualification'
          ),
          disabled: !this.isRegClosed,
          handler: this.showCompleteQualifyConfirm,
          // navigationHandler: this.goToParticipants,
          automated: this.autoProcessing,
          visible: true,
          isPast: this.isTournamentPastStatus(
            TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
          ),
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
        },
        {
          key: 'buildBraket',
          label: this.$t(
            '_web_tournaments_build-braket-btn',
            'Build bracket for {stage}',
            { stage: this.$lodash.get(this.firstDraftStage, 'title', '') }
          ),
          isPast:
            this.isTournamentPastStatus(TOURNAMENT_STATUSES.SCHEDULED) ||
            (this.autoProcessing &&
              this.isTournamentPastStatus(
                TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
              )),
          disabled:
            !this.isTournamentPastStatus(
              TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
            ) ||
            (this.$lodash.get(this.firstDraftStage, 'index', 0) > 0 &&
              !this.previousStageFinished),
          handler: () => this.confirmReDraw(this.firstDraftStage),
          // navigationHandler: this.goToBrakets,
          automated: this.autoProcessing,
          visible: !this.$lodash.isEmpty(this.firstDraftStage),
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
        },
        {
          key: 'publishBraket',
          label: this.$t(
            '_web_tournaments_build-bracket-btn',
            'Publish Bracket for {stage}',
            { stage: this.$lodash.get(this.firstScheduledStage, 'title', '') }
          ),
          disabled: this.isTournamentPastStatus(TOURNAMENT_STATUSES.SCHEDULED),
          handler: () => this.showModalPublish(this.firstScheduledStage.id),
          // navigationHandler: this.goToBrakets,
          automated: this.autoProcessing,
          visible: !this.$lodash.isEmpty(this.firstScheduledStage),
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
        },
        {
          key: 'startCheckin',
          label: this.$t(
            '_web_tournaments_complete-start-check-in-btn',
            'Check-in'
          ),
          disabled: !this.isTournamentPastStatus(
            TOURNAMENT_STATUSES.REG_CLOSED
          ),
          handler: () => this.confirmStartCheckIn(this.firstScheduledStage),
          // navigationHandler: this.goToBrakets,
          automated: true,
          visible:
            this.hasCheckInInFirstStage &&
            !this.checkIfCheckInInFirstStageStarted,
          icon: 'bolt',
          iconFill: this.$scss.colors.accentBg,
        },
        {
          key: 'unreadMessages',
          label: this.$t(
            '_web_tournaments_unread-messages',
            'Review unread messages'
          ),
          disabled: false,
          handler: this.toggleChat,
          automated: false,
          visible: this.sliderChatUnreadCount,
          icon: 'chat',
        },
        {
          key: 'judgements',
          label: this.$t(
            '_web_tournaments_judgements',
            'Judgements ({number})',
            { number: this.getAllDisputesCount }
          ),
          disabled: true,
          navigationHandler: this.goToBrakets,
          automated: false,
          visible: this.getAllDisputesCount,
          // visible:
          //   this.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED) &&
          //   this.getAllDisputesCount,
          icon: 'warning',
          iconFill: this.$scss.colors.yellowBg,
        },
        // {
        //   key: 'stopCheckin',
        //   label: this.$t(
        //     '_web_tournaments_complete-start-check-in-btn',
        //     'Check-in'
        //   ),
        //   disabled:
        //     !this.checkIfCheckInInFirstStageStarted &&
        //     !this.isTournamentPastStatus(TOURNAMENT_STATUSES.REG_CLOSED),
        //   handler: this.$lodash.noop,
        //   visible: this.hasCheckInInFirstStage,
        // },
      ];
    },
    visibleActions() {
      return this.allActions.filter(el => el.visible);
      // .sort((a, b) => Number(a.disabled) - Number(b.disabled));
    },
    noActions() {
      return this.visibleActions.length === 0;
    },
  },
  methods: {
    ...mapActions([
      'successNotify',
      'errorNotify',
      'openModal',
      'closeModal',
      'fetchTournamentDraft',
      'startStageCheckIn',
      'fetchTournamentStage',
      'fetchTournamentBlocks',
      'setFillParticipants',
    ]),
    ...mapMutations(['toggleSliderChat', 'hidePopoverChat']),
    confirmStartCheckIn(stage) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-stage-check-in_confirm_modal_title',
            'This action will force check-in start. All participants will be notified about that.'
          ),
          isLoading: this.checkInLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: () => this.startCheckIn(stage),
        },
      });
    },
    startCheckIn(stage) {
      this.stageLoading = stage.id;
      this.startStageCheckIn({
        stageID: stage.id,
        tournamentID: this.tournamentID,
      })
        .then(this.fetchTournamentStage)
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament-stage-check-in_successful',
              'Check-In is successfully opened'
            )
          );
        })
        .then(() =>
          this.fetchTournamentBlocks({
            stage: stage.id,
            tournamentID: this.tournamentID,
          }).then(blocks => {
            this.setTournamentBlocks(blocks);
          })
        )
        .catch(this.errorNotify)
        .finally(() => {
          this.stageLoading = null;
          this.closeModal();
        });
    },
    toggleChat() {
      if (this.popoverChatIsOpened) {
        this.hidePopoverChat();
      }
      this.$nextTick(() => {
        this.toggleSliderChat();
      });
    },
    goToParticipants() {
      this.$router.push({
        name: 'tournamentPlayers',
        params: this.$route.params,
      });
    },
    goToBrakets() {
      this.$router.push({
        name: 'multiStage',
        params: {
          ...this.$route.params,
          constructorMode: CONSTRUCTOR_MODES.PUBLISH,
        },
      });
    },
    qualifyParticipants() {
      this.qualifyLoading = true;
      this.setFillParticipants({
        id: this.currentTournament.id,
        query: {
          approved_to: 'qualified',
        },
        data: {
          include: {
            attributes_ids: [],
          },
          exclude: {
            attributes_ids: [],
          },
        },
      })
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament_success_subtitle',
              'Qualifiers list was successfully generated.'
            )
          );
        })
        .catch(this.errorNotify)
        .then(() => this.fetchTournamentDraft(this.currentTournament.id))
        .finally(() => {
          this.qualifyLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.widget-actions-wrapper {
  .management-loader {
    position: fixed;
    top: 50%;
    left: 50%;
  }
  .action-item-wrapper:last-of-type {
    opacity: 1;
  }
}
</style>
