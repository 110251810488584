import { kebabCase } from '@/utils/lodashUtils';

/**
 * automatic generate translates:
 *
 * use mixin:
 * mixins: [translate('_web_prefix'),
 *
 * use in code:
 * this.$$t('foo'); // this.$t('_web_prefix_foo', 'foo');
 * this.$$t('foo', 'custom-key'); // this.$t('_web_prefix_custom-key', 'foo');
 * this.$$t('foo {count}', {count: 1}); // this.$t('_web_prefix_foo-count', 'foo {count}', {count: 1});
 * this.$$t('foo {count}', 'bar', {count: 1}); // this.$t('_web_prefix_bar', 'foo {count}', {count: 1});
 *
 * @param prefix translate key prefix
 * @returns {{methods: {$$t(*=, *=, *): *}}}
 */
export const translate = prefix => ({
  methods: {
    $$t(text, altKey, params = {}, separator = '_') {
      const key = typeof altKey !== 'string' ? kebabCase(text) : altKey;
      const argument = typeof altKey === 'object' ? altKey : params;
      return text ? this.$t(`${prefix}${separator}${key}`, text, argument) : '';
    },
    $$tCreateKey(key) {
      return `${prefix}_${key}`;
    },
  },
});
