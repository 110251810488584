<template>
  <div class="d-flex flex-column">
    <template v-if="hasInfo">
      <Widget
        :title="title"
        :actions-menu="actionsMenu"
        class="mb-5"
        hide-search
        :status-btn-mode="statusBtnMode"
        status-btn-position="bottom"
      >
      </Widget>
      <!-- <div class="d-flex align-items-center justify-content-between mb-5">

        <Title :text="title" />
        <StatusBtn
          v-if="statusBtnMode"
          :mode="statusBtnMode"
          :disabled=""
          class="h-ml-auto"
        />
        <ActionsMenu
          v-if="isPublishRoute"
          class="h-ml-5 d-flex align-content-center"
          :actions-btn-items="actionsMenu"
        />
      </div> -->
      <!-- <div class="d-flex justify-content-end">

      </div> -->
      <div
        v-if="goBackVisible || isPublishRoute"
        class="d-flex align-items-center justify-content-end mb-5"
      >
        <div class="d-flex align-items-center justify-content-end">
          <HeraFormBtn
            v-if="goBackVisible || isPublishRoute"
            :label="$t('_web_tournament_finish-btn', 'Go back')"
            class="h-ml-3 btn-accent-border"
            @click="goBack"
          />
          <Btn
            v-if="isPublishRoute"
            :disabled="
              notQualifiedYet || !firstDraftStage || !previousStageFinished
            "
            class="btn-accent-simple h-ml-3"
            :label="
              $t(
                '_web_tournament-brackets-draw-nearest-stage-button',
                'Draw {stageName}',
                {
                  stageName: (firstDraftStage && firstDraftStage.title) || '',
                }
              )
            "
            @click="confirmDraw(firstDraftStage)"
          />
        </div>
      </div>
      <!-- <InfoPanel class="my-5 align-items-center">
        {{ infoText }}
        <template #addons>

        </template>
      </InfoPanel> -->
      <Panel v-if="isConstructRoute" class="mb-5">
        <Subheader
          :text="$$t('Tournament structure')"
          class="tournament-info-wrapper__title mb-5"
        />
        <HorizontalTabs
          v-bind="{ tabs }"
          variant="panels"
          class="tournament-structure-picker"
        >
          <template slot="beforeLabel" slot-scope="{ tab }">
            <Icon
              v-if="tab.route.name === 'editSingleStage'"
              name="single-elimination"
              width="48"
              class="mb-4"
            />
            <div
              v-else
              class="row align-items-center no-gutters mb-4 text-center"
            >
              <div class="col">
                <Icon name="single-elimination" width="20" class="mb-2" />
                <Icon name="single-elimination" width="20" />
              </div>
              <div class="col-auto mx-1"></div>
              <div class="col">
                <Icon name="single-elimination" width="20" />
              </div>
            </div>
          </template>
          <template slot="afterLabel" slot-scope="{ tab }">
            <TextBlock
              v-if="tab.route.name === 'editSingleStage'"
              class="font-weight-normal mt-4 h-pl-4"
              variant="secondary"
              tag="ul"
            >
              <li>{{ $$t('single tournament format') }}</li>
              <li>{{ $$t('single group') }}</li>
            </TextBlock>
            <TextBlock
              v-else
              class="font-weight-normal mt-4 h-pl-4"
              variant="secondary"
              tag="ul"
            >
              <li>{{ $$t('multiple tournament format') }}</li>
              <li>{{ $$t('multi group') }}</li>
            </TextBlock>
          </template>
        </HorizontalTabs>
      </Panel>
    </template>
    <Panel class="h-100 p-0" :variant="['transparent']">
      <!-- <HeraDummyList
        v-if="notQualifiedYet"
        size="200"
        icon-name="dummy/tournament-grids/single_elimination"
        position="center"
        class="my-5"
        :header-text="
          $t(
            '_web_tournament-participants_not_drawn',
            'Brackets are not ready.'
          )
        "
        :text="
          $t(
            '_web_tournament-management_grid_empty-description',
            'The system is awaiting for qualifications to complete.'
          )
        "
      /> -->
      <template>
        <router-view
          :key="routeKey"
          :class="{
            '': isConstructRoute,
          }"
        ></router-view>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { translate } from '@/mixins/translate';
import Btn from '@/components/atoms/HeraFormBtn';
// import Title from '@/components/atoms/Title';
import Panel from '@/components/atoms/Panel';
import TextBlock from '@/components/atoms/TextBlock';
// import InfoPanel from '@/components/molecules/InfoPanel';
// import HeraDummyList from '@/components/common/HeraDummyList';
import HorizontalTabs from '@/components/common/HorizontalTabs';
// import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon';
import {
  TOURNAMENT_STATUSES,
  CONSTRUCTOR_MODES,
  BLOCK_TYPES,
} from '@/constants/tournaments';
import Icon from '@/components/atoms/Icon';
import Widget from '@/views/tournaments/management/summaryWidgets/Widget';
import drawGrid from '@/views/tournaments/management/brackets/mixins/drawGrid';
import resetGrid from '@/views/tournaments/management/brackets/mixins/resetGrid';
// import StatusBtn from '../components/StatusBtn';
// import ActionsMenu from '@/components/common/ActionsMenu';
import Subheader from '@/components/atoms/Subheader';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';
export default {
  name: 'StagesWrapper',
  components: {
    Btn,
    // Title,
    // InfoPanel,
    // HeraDummyList,
    Panel,
    // StatusBtn,
    // ActionsMenu,
    Widget,
    HorizontalTabs,
    HeraFormBtn,
    Subheader,
    TextBlock,
    Icon,
    // ToolIcon,
  },
  mixins: [drawGrid, resetGrid, translate('_web_tournament_stage-editor')],
  data() {
    return {
      drawLoading: false,
      shuffleLoading: false,
      routeKey: 'stages',
    };
  },
  computed: {
    ...mapGetters([
      'isTournamentPastStatus',
      'firstDraftStage',
      'currentTournamentStages',
      'firstScheduledStage',
      'firstPublishedStageWithoutBracket',
      'previousStageFinished',
      'isSingleStage',
      'isSingleBlock',
      'hasStages',
      'getBlocksByStageID',
    ]),
    canShuffle() {
      const blocksType = this.$lodash.get(
        this.firstDraftStage,
        'block_configuration.type'
      );
      return (
        blocksType &&
        ![BLOCK_TYPES.BATTLE_ROYALE, BLOCK_TYPES.RR].includes(blocksType)
      );
    },
    statusBtnMode() {
      return !this.notQualifiedYet && this.isSingleStage && this.isSingleBlock
        ? ''
        : this.isConstructRoute
        ? 'announce'
        : this.isPublishRoute
        ? 'publish'
        : 'start';
    },
    isPublishRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.PUBLISH;
    },
    isManageRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.MANAGE;
    },
    isConstructRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.CONSTRUCT;
    },
    hasInfo() {
      return (
        this.$route.name === 'multiStage' ||
        this.$route.name === 'editSingleStage'
      );
    },
    infoText() {
      return this.$route.name === 'editSingleStage' &&
        (!this.isSingleStage || !this.isSingleBlock)
        ? this.$t(
            '_web_tournaments_constract-brackets_info-panel-not-single-stage',
            'There are more than one stage/group in this tournament. Proceed to Multistage tab to edit structure.'
          )
        : this.isConstructRoute
        ? this.$t(
            '_web_tournaments_constract-brackets_info-panel',
            'Create a structure of your tournament and  make settings of each stage'
          )
        : this.isPublishRoute
        ? this.$t(
            '_web_tournaments_publish-brackets-page-info-text',
            'Qualify your participants and draw a bracket.'
          )
        : this.$t(
            '_web_tournaments_manage-brackets-page-info-text',
            'Matches will start according to a schedule or can be started manually'
          );
    },
    title() {
      return this.isPublishRoute
        ? this.$t(
            '_web_tournaments_publish-brackets-page-title-test',
            'Edit Brackets'
          )
        : this.isManageRoute
        ? this.$t(
            '_web_tournaments_manage-tournament-page-title-test',
            'Manage Matches'
          )
        : this.$t(this.$route.meta.titleKey, this.$route.meta.fallbackLabel);
    },
    notQualifiedYet() {
      return !this.isTournamentPastStatus(
        TOURNAMENT_STATUSES.QUALIFICATION_COMPLETED
      );
    },
    notStartedYet() {
      return !this.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED);
    },
    goBackVisible() {
      return (
        // this.isManageRoute ||
        (!this.notStartedYet && this.isPublishRoute) || this.isConstructRoute
      );
    },
    actionsMenu() {
      return [
        {
          key: 'draw-grid',
          title: 'Draw Brackets',
          subtitle: this.isPublishRoute
            ? this.$$t('Draw Brackets')
            : this.$t(
                '_web_tournament-block_draw-brackets-disabled-tooltip',
                'Draw action available only for draft stages in edit mode'
              ),
          icon: 'draw',
          disabled:
            !this.isPublishRoute ||
            this.isLoading ||
            !(
              (this.firstPublishedStageWithoutBracket &&
                this.firstPublishedStageWithoutBracket.id) ||
              (this.firstDraftStage && this.firstDraftStage.id)
            ),
          handler: () =>
            this.confirmReDraw(
              this.firstPublishedStageWithoutBracket || this.firstDraftStage
            ),
        },
        {
          key: 'shuffle-participants',
          title: 'Shuffle participants',
          subtitle: this.isPublishRoute
            ? this.$$t('Shuffle participants')
            : this.$t(
                '_web_tournament-block_shuffle-brackets-disabled-tooltip',
                'Shuffle action available only for draft stages in edit mode'
              ),
          icon: 'shuffle',
          disabled:
            !this.isPublishRoute ||
            this.isLoading ||
            !this.canShuffle ||
            !this.firstDraftStage ||
            !this.firstDraftStage.id,
          handler: this.confirmShuffleParticipants,
        },
        {
          key: 'reset-brackets',
          title: 'Reset brackets',
          subtitle:
            this.firstScheduledStage && this.firstScheduledStage.id
              ? this.$t(
                  '_web_tournament-block_reset-brackets-tooltip',
                  'Press to clear all brackets in {stageName} stage',
                  {
                    stageName: this.firstScheduledStage.title,
                  }
                )
              : this.$t(
                  '_web_tournament-block_reset-brackets-disabled-tooltip',
                  'Brackets reset action available only for scheduled stages in edit mode'
                ),
          handler: this.confirmResetBrackets.bind(
            null,
            this.firstScheduledStage,
            true
          ),
          icon: 'reset',
          disabled:
            this.notQualifiedYet ||
            !this.firstScheduledStage ||
            !this.firstScheduledStage.id,
        },
        {
          key: 'go-to-brackets-edit',
          title: 'Edit brackets',
          subtitle: this.$t(
            '_web_tournament-change-stage-link-tooltip',
            'Edit brackets'
          ),
          icon: 'edit',
          disabled:
            this.$route.name === 'multiStage' ||
            this.notQualifiedYet ||
            this.isPublishRoute,
          handler: this.goToBracketsEdit,
        },
        {
          key: 'go-to-stage-edit',
          title: 'Setup stage',
          subtitle: this.$t(
            '_web_tournament-change-stage-link-tooltip',
            'Change stage settings'
          ),
          icon: 'settings-outline',
          disabled: this.isConstructRoute,
          handler: this.goToStageEdit,
        },
      ];
    },
    tabs() {
      return [
        {
          route: {
            name: 'editSingleStage',
            params: {
              ...this.$route.params,
              stageID: this.hasStages ? this.currentTournamentStages[0].id : 0,
              constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
            },
          },
          key: '_web_tournaments_management_single-stage',
          label: 'Single stage',
          disabled: !this.isSingleStage || !this.isSingleBlock,
        },
        {
          route: {
            name: 'multiStage',
            params: {
              ...this.$route.params,
              constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
            },
          },
          key: '_web_tournaments_management_multi-stage',
          label: 'Multi-Stage',
        },
      ];
    },
  },
  methods: {
    ...mapMutations(['updateModalProps']),
    ...mapActions([
      'successNotify',
      'errorNotify',
      'openModal',
      'closeModal',
      'shuffleBlockParticipants',
    ]),
    goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: 'multiStage',
      //   params: {
      //     ...this.$route.params,
      //     constructorMode: this.notQualifiedYet
      //       ? CONSTRUCTOR_MODES.CONSTRUCT
      //       : this.notStartedYet
      //       ? CONSTRUCTOR_MODES.PUBLISH
      //       : CONSTRUCTOR_MODES.MANAGE,
      //   },
      // });
    },
    confirmShuffleParticipants() {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-block_qualifiers_shuffle_brackets_modal_title',
            'This action will trigger shuffle participants.'
          ),
          isLoading: this.shuffleLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: this.onShuffleParticipants,
        },
      });
    },
    onShuffleParticipants() {
      this.updateModalProps({ isLoading: true });
      const stageID = this.$lodash.get(this.firstScheduledStage, 'id');
      if (stageID) {
        const shufflePromises = this.getBlocksByStageID(stageID).map(block =>
          this.shuffleBlockParticipants({
            tournamentID: this.$route.params.tournamentID,
            stageID: stageID,
            blockID: block.id,
          })
        );
        Promise.all(shufflePromises)
          .then(() => {
            this.successNotify(
              this.$t(
                '_web_tournament-block_was-participants-shuffled',
                'Shuffle completed'
              )
            );
            this.updateRouteKey();
          })
          .catch(this.errorNotify)
          .finally(() => {
            this.updateModalProps({ isLoading: false });
            this.closeModal();
          });
      }
    },
    goToStageEdit() {
      this.$router.push({
        name: 'multiStage',
        params: {
          ...this.$route.params,
          constructorMode: CONSTRUCTOR_MODES.CONSTRUCT,
        },
      });
    },
    goToBracketsEdit() {
      this.$router.push({
        name: 'multiStage',
        params: {
          ...this.$route.params,
          constructorMode: CONSTRUCTOR_MODES.PUBLISH,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.tournament-structure-picker {
  .horizontal-tabs__item {
    max-width: 300px;
  }
}
</style>
