<template>
  <div
    class="d-flex align-items-center justify-content-between slider-chat-header"
  >
    <span class="text-truncate font-size-sm" :title="titleToRender">{{
      titleToRender
    }}</span>
    <HeraFormBtn class="w-50 btn-accent-simple" @click="showPopover">
      {{ $t('_web_tournament_sidechat_add-new', 'Add New') }}
    </HeraFormBtn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HeraFormBtn from '@/components/atoms/HeraFormBtn';

export default {
  name: 'Header',
  components: { HeraFormBtn },
  computed: {
    ...mapGetters(['currentTournament']),
    titleToRender() {
      return (
        this.currentTournament.info.title ||
        this.$t(
          '_web_tournament_sidechat_tournament-channels',
          'Tournament Channels'
        )
      );
    },
    dropdownItems() {
      return [
        {
          label: this.$t('_web_tournament_sidechat_channel', 'Channel'),
          action: () => this.$emit('addChannel'),
        },
        {
          label: this.$t('_web_tournament_sidechat_group-chat', 'Group Chat'),
          action: () => this.$emit('addGroupChat'),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      openPopoverMenu: 'popoverMenu/open',
    }),
    showPopover(event) {
      this.$nextTick(() =>
        this.openPopoverMenu({
          event,
          items: this.dropdownItems,
          settings: {
            autoClose: true,
            stopItemClickBubbling: true,
            isFixed: true,
            blockIsFixed: true,
            regard: 'top',
            float: ['top'],
          },
        })
      );
    },
  },
};
</script>

<style lang="scss">
.slider-chat-header {
  position: relative;
  min-height: 3.5rem;
  height: 3.5rem;
  background-color: $ph-input-bg;
  border-bottom: 1px solid $ph-accent;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 3.5rem;
  padding: 0 0.875rem;
  z-index: 6;
}
</style>
