var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Autosuggest',_vm._b({on:{"resultPicked":function($event){return _vm.$emit('input', $event)}}},'Autosuggest',{
    value: _vm.value,
    label: _vm.label,
    minLength: 1,
    error: _vm.error,
    variant: _vm.variant,
    showOnFocus: true,
    signup: true,
    displayProperty: 'text',
    source: _vm.suggestCountriesRequest,
    required: _vm.required,
    tabindex: _vm.tabindex,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }