<template>
  <div
    class="d-flex justify-content-center align-items-center slider-chat-show-button"
    @click="toggleChat"
  >
    <Icon :name="sliderChatIsShow ? 'arrow-right' : 'chat'" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Icon from '@/components/atoms/Icon';

export default {
  components: { Icon },
  computed: {
    ...mapGetters(['sliderChatIsShow', 'popoverChatIsOpened']),
  },
  methods: {
    ...mapMutations(['toggleSliderChat', 'hidePopoverChat']),
    toggleChat() {
      if (this.popoverChatIsOpened) {
        this.hidePopoverChat();
      }
      this.$nextTick(() => {
        this.toggleSliderChat();
      });
    },
  },
};
</script>

<style lang="scss">
.slider-chat-show-button {
  position: absolute;
  left: -42px;
  bottom: 104px;
  width: 42px;
  height: 104px;
  background-image: url('~@/assets/images/sliderchat-btn.svg');
  cursor: pointer;
  #{$selector-rtl} & {
    left: 20.625rem;
    transform: scaleX(-1);
  }
}
</style>
