<template>
  <Panel
    class="scores d-flex align-items-center justify-content-around p-0"
    :variant="scoresVariant"
  >
    <input
      v-if="editMode"
      :value="value"
      class="scores_input hide-arrows p-0"
      type="number"
      maxlength="inputMaxLength"
      @input="
        $emit(
          'update',
          $event.target.value === '' ? null : +$event.target.value
        )
      "
    />
    <span v-else class="scores_item">{{
      hasValue(value) || defaultValue
    }}</span>
  </Panel>
</template>
<script>
import { WIN, LOSE } from '@/constants/battles';
import Panel from '@/components/atoms/Panel';
export default {
  name: 'Scores',
  components: {
    Panel,
  },
  model: {
    event: 'update',
  },
  props: {
    value: {
      type: null,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    inputMaxLength: {
      type: Number,
      default: 2,
    },
    defaultValue: {
      type: [String, Number],
      default: '-',
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    scoresVariant() {
      const strokeVariant = this.hasErrors
        ? ['stroked', 'stroked-error', 'error']
        : this.borderless
        ? []
        : ['stroked', 'stroked-secondary'];
      return ['transparent', 'rounded-medium', ...strokeVariant];
    },
  },
  methods: {
    hasValue(score) {
      // check the "0" value = is also result (not false)
      return this.$lodash.isNull(score)
        ? this.defaultValue
        : Number.isInteger(score) || score === WIN || score === LOSE
        ? score.toString()
        : false;
    },
  },
};
</script>
<style lang="scss">
.scores {
  .scores_item,
  input[type='number'] {
    height: 28px;
    width: 38px;
    padding: 5px;
    border: none;
    text-align: center;
    background-color: transparent;
    color: white;
    outline: none;
  }

  &.editable {
    display: block;
    background-color: transparent;
    overflow: hidden;
  }
  &_item {
    .error & {
      color: $ph-error;
    }
  }
  .bold & {
    font-weight: 500;
  }
  .lh-30 {
    line-height: 30px;
  }
  &:focus-within {
    border-color: $ph-accent !important;
  }
}
</style>
