<template>
  <div class="row notifications-page mx-0">
    <portal to="header-left-portal">
      <GoBackBtn
        history-mode
        hide-label
        class="pl-0 pr-0 mr-5"
        style="min-width: 16px"
      />
    </portal>
    <div class="col">
      <div class="row">
        <div
          v-if="isDataFetching && !nextPageToken"
          class="col notifications-list empty"
        >
          <HeraDummyList icon-name="dummy/notifications" size="200">
            {{ $t('_web_fetching_notifications', 'Fetching notifications...') }}
          </HeraDummyList>
        </div>

        <div
          v-else-if="!notifications.length"
          class="col notifications-list empty"
        >
          <HeraDummyList
            icon-name="dummy/notifications"
            size="200"
            class="noNotifs"
          >
            {{ $t('_web_no_notifications_new', 'You have no notifications') }}
          </HeraDummyList>
        </div>

        <div
          v-else-if="
            notifications.find(notification => notification.read === false)
          "
          class="col-12 notifications-list px-0"
        >
          <InfiniteScroll
            :list="notifications"
            list-name="notifications"
            :is-disabled="isDataFetching || nextPageToken === null"
            :activation-gap="50"
            @ScrollToBottom="getNextPageData"
          >
            <div slot-scope="{}">
              <div class="notifReadView">
                <div class="notifTitle">
                  {{ $t('_web_notifications_new', 'New') }}
                </div>
                <div class="notifButtons">
                  <div class="mark-as-read">
                    <img
                      src="@/assets/images/header/doubleCheck.svg"
                      alt="mark as read"
                      class="mark-icon"
                    />
                    <a href="#" @click.stop="markAllAsRead">{{
                      $t(
                        '_web_notifications_mark_all_as_read',
                        'Mark all as read'
                      )
                    }}</a>
                  </div>
                </div>
              </div>

              <template v-for="(notification, index) in notifications">
                <div
                  v-if="notification.read === false"
                  :ref="notification.id"
                  :key="notification.id"
                  class="notification-container"
                  :class="{
                    'block-loading content-blur': isLoading,
                    'cursor-pointer':
                      notification.type === 'shop_collection_promotion',
                  }"
                  @click.stop="markAsRead(notification)"
                >
                  <div
                    class="notification-header"
                    :class="{ unreadNotif: !notification.read }"
                  >
                    <div
                      class="btn-delete-notification"
                      @click.stop="deleteItem(notification.id)"
                    >
                      <AppIcon name="close" width="10" />
                    </div>

                    <div class="notification-info">
                      <div class="notification-body">
                        <Avatar
                          v-if="notification.sender.avatar"
                          class="notifAvatar"
                          :type="(notification.sender || {}).type"
                          :img-url="(notification.sender || {}).avatar"
                        /><icon
                          v-else
                          class="notifAvatar"
                          :name="getRegionCode(notification.type)"
                          :width="57"
                        />

                        <div class="notification-content-wrapper">
                          <div class="notification-content">
                            <TextWithLinks
                              tag="div"
                              class="notification-text"
                              :content="
                                generateNotificationByData(notification).content
                              "
                              :content-props="
                                generateNotificationByData(notification)
                                  .contentProps
                              "
                              :translate-key="
                                `_web_notifications_desc_${notification.type}`
                              "
                            />
                            <time class="time"
                              >{{ notification.sent_date | timeAgo($t) }}
                            </time>
                          </div>
                          <div class="notification-actions">
                            <FormBtn
                              v-for="(action, idx) in notification.actions"
                              :key="'action-' + notification.id + '-' + idx"
                              :class="{ 'btn-bordered': action !== 'Confirm' }"
                              @click.prevent="applyAction(index, action)"
                            >
                              {{ action }}
                            </FormBtn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </InfiniteScroll>
        </div>

        <div
          v-if="notifications.length > 0"
          class="col-12 notifications-list px-0"
        >
          <InfiniteScroll
            :list="notifications"
            list-name="notifications"
            :is-disabled="isDataFetching || nextPageToken === null"
            :activation-gap="50"
            @ScrollToBottom="getNextPageData"
          >
            <div slot-scope="{}">
              <div class="notifReadView">
                <div class="notifTitle">
                  {{
                    $t(
                      'web_left-sidebar_nav-notification-link',
                      'Notifications'
                    )
                  }}
                </div>
                <!-- <div class="notifButtons">
                  <div>Strelice</div>
                  <div class="mark-as-read">
                    <a href="#" @click.stop="markAllAsRead">{{
                      $t(
                        '_web_notifications_mark_all_as_read',
                        'Mark all as read'
                      )
                    }}</a>
                  </div>
                </div> -->
              </div>

              <template v-for="(notification, index) in notifications">
                <div
                  v-if="notification.read === true"
                  :ref="notification.id"
                  :key="notification.id"
                  class="notification-container"
                  :class="{
                    'block-loading content-blur': isLoading,
                    'cursor-pointer':
                      notification.type === 'shop_collection_promotion',
                  }"
                >
                  <div
                    class="notification-header"
                    :class="{ unreadNotif: !notification.read }"
                  >
                    <div
                      class="btn-delete-notification"
                      @click.stop="deleteItem(notification.id)"
                    >
                      <AppIcon name="close" width="10" />
                    </div>

                    <div class="notification-info">
                      <div class="notification-body">
                        <Avatar
                          v-if="notification.sender.avatar"
                          class="notifAvatar"
                          :type="(notification.sender || {}).type"
                          :img-url="(notification.sender || {}).avatar"
                        /><icon
                          v-else
                          class="notifAvatar"
                          style="background-color: unset!important ;"
                          :name="getRegionCode(notification.type)"
                          :width="57"
                        />

                        <div class="notification-content-wrapper">
                          <div class="notification-content">
                            <TextWithLinks
                              tag="div"
                              class="notification-text"
                              :content="
                                generateNotificationByData(notification).content
                              "
                              :content-props="
                                generateNotificationByData(notification)
                                  .contentProps
                              "
                              :translate-key="
                                `_web_notifications_desc_${notification.type}`
                              "
                            />
                            <time class="time"
                              >{{ notification.sent_date | timeAgo($t) }}
                            </time>
                          </div>
                          <div class="notification-actions">
                            <FormBtn
                              v-for="(action, idx) in notification.actions"
                              :key="'action-' + notification.id + '-' + idx"
                              :class="{ 'btn-bordered': action !== 'Confirm' }"
                              @click.prevent="applyAction(index, action)"
                            >
                              {{ action }}
                            </FormBtn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateNotificationByData } from '@/utils/textLink';
import TextWithLinks from '@/components/text/TextWithLinks';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import HeraDummyList from '@/components/common/HeraDummyList';
import GoBackBtn from '@/components/common/GoBackBtn.vue';
import AppIcon from '@/components/atoms/Icon';
import FormBtn from '@/components/atoms/HeraFormBtn';
import Avatar from '@/components/images/Avatar';
import icon from '@/components/atoms/Icon';

export default {
  name: 'Notifications',

  components: {
    AppIcon,
    Avatar,
    TextWithLinks,
    FormBtn,
    InfiniteScroll,
    HeraDummyList,
    GoBackBtn,
    icon,
  },

  data() {
    return {
      notifications: [],
      isDataFetching: true,
      isLoading: false,
      nextPageToken: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'unreadNotifications',
      'localeDirection',
      'marketplaceLink',
    ]),
  },
  created() {
    this.nextPageToken = undefined;

    this.$nextTick(function() {
      this.getNextPageData();
    });
  },

  methods: {
    ...mapActions([
      'getNotifications',
      'postNotificationAction',
      'updateNotificationsStatus',
      'updateAllNotificationsStatuses',
      'deleteNotificationItem',
      'errorNotify',
    ]),
    getRegionCode(type) {
      switch (type) {
        case 'royale_battle_start_soon':
          return 'battle-noti';
        case 'battle_start_soon':
          return 'battle-noti';
        case 'tournament_private_invitational':
          return 'tournament-noti';
        case 'tournament_public_invitational':
          return 'tournament-noti';
        case 'tournament_qualification':
          return 'tournament-noti';
        case 'battle_cancelled':
          return 'battle-noti';
        case 'tournament_rejected':
          return 'tournament-noti';
        case 'tournament_activation_failed':
          return 'tournament-noti';
        case 'tournament_invitational_cancelled':
          return 'tournament-noti';
        case 'tournament_restarted':
          return 'tournament-noti';
        case 'battle_completed_tech_win':
          return 'battle-noti';
        case 'battle_completed_tech_loss':
          return 'battle-noti';
        case 'tournament_qualification_requested':
          return 'tournament-noti';
        case 'battle_scheduled':
          return 'battle-noti';
        case 'royale_battle_scheduled':
          return 'battle-noti';
        case 'tournament_stage_check_in_started':
          return 'tournament-noti';
        case 'tournament_stage_published':
          return 'tournament-noti';
        case 'tournament_announced':
          return 'tournament-noti';
        case 'tournament_finished':
          return 'tournament-noti';
        case 'tournament_finished_winner':
          return 'tournament-noti';
        case 'tournament_registration_opened':
          return 'tournament-noti';
        case 'tournament_started':
          return 'tournament-noti';
        case 'tournament_qualification_reserved':
          return 'tournament-noti';
        case 'tournament_published':
          return 'tournament-noti';
        case 'tournament_invitational':
          return 'tournament-noti';
        case 'tournament_entry_fee_success':
        case 'tournament_entry_fee_failed':
        case 'tournament_entry_fee_expired':
          return 'tournament-noti';
        case 'profile_password_changed':
          return 'user-noti';
        case 'friendship_requested':
          return 'user-noti';
        case 'friendship_rejected':
          return 'user-noti';
        case 'friendship_accepted':
          return 'user-noti';
        case 'profile_mentioned':
          return 'user-noti';
        case 'clan_mentioned':
          return 'clan-noti';
        case 'post_commented':
          return 'user-noti';
        case 'general_post':
          return 'generic-noti';
        case 'clan_post':
          return 'clan-noti';
        case 'external_subscription_activated':
          return 'generic-noti';
        case 'external_subscription_canceled':
          return 'generic-noti';
        case 'external_subscription_prolonged':
          return 'generic-noti';
        case 'clan_member_invited':
          return 'clan-noti';
        case 'clan_member_joined':
          return 'clan-noti';
        case 'clan_invite_rejected':
          return 'clan-noti';
        case 'clan_member_added':
          return 'clan-noti';
        case 'clan_member_requested':
          return 'clan-noti';
        case 'clan_member_removed':
          return 'clan-noti';
        case 'clan_member_updated':
          return 'clan-noti';
        case 'clan_validated':
          return 'clan-noti';
        case 'clan_unvalidated':
          return 'clan-noti';
        case 'clan_deleted':
          return 'clan-noti';
        case 'clan_founder_reassigned':
          return 'clan-noti';
        case 'clan_join_request_accepted':
          return 'clan-noti';
        case 'clan_request_rejected':
          return 'clan-noti';
        case 'member_left':
          return 'user-noti';
        case 'battle_started':
          return 'battle-noti';
        case 'royale_battle_completed':
          return 'battle-noti';
        case 'royale_battle_started':
          return 'battle-noti';
        case 'battle_match_result_updated':
          return 'battle-noti';
        case 'one_player_start_battle':
          return 'battle-noti';
        case 'battle_player_finished':
          return 'battle-noti';
        case 'player_group_up_requested':
          return 'battle-noti';
        case 'player_group_up_league_team_requested':
          return 'battle-noti';
        case 'battle_activated':
          return 'battle-noti';
        case 'battle_completed':
          return 'battle-noti';
        case 'battle_should_confirm':
          return 'battle-noti';
        default:
          return 'UNKNOWN';
      }
    },

    generateNotificationByData,
    getNextPageData() {
      this.isDataFetching = true;
      let params = {
        pageSize: 10,
        pageToken: this.nextPageToken,
      };
      this.getNotifications(params)
        .then(res => {
          this.nextPageToken =
            this.nextPageToken === res.next_page_token
              ? null
              : res.next_page_token;

          if (res.items instanceof Array) {
            this.notifications = [...this.notifications, ...res.items];
          }
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isDataFetching = false;
        });
    },

    applyAction(index, action) {
      this.isLoading = true;
      let id = this.notifications[index].id;

      this.postNotificationAction({ id, action })
        .then(
          () =>
            (this.notifications = this.notifications.filter(
              item => item.id !== id
            ))
        )
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },

    markAsRead(notification) {
      if (!notification.read) {
        this.updateNotificationsStatus(new Array(notification.id))
          .then(() => {
            const index = this.notifications.findIndex(
              item => item.id === notification.id
            );
            this.notifications[index].read = true;
          })
          .catch(this.errorNotify);
      }
      if (notification.type === 'shop_collection_promotion') {
        const url = this.marketplaceLink + notification.extended.url;
        window.open(url, '_blank', 'noreferrer');
      }
    },

    markAllAsRead() {
      if (!this.notifications) {
        return;
      }
      this.isLoading = true;
      this.updateAllNotificationsStatuses()
        .then(() => {
          this.notifications = this.notifications.map(item => {
            item.read = true;
            return item;
          });
        })
        .catch(this.errorNotify)
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteItem(id) {
      this.deleteNotificationItem(id)
        .then(() => {
          this.dropItem(id);
        })
        .catch(this.errorNotify);
    },

    dropItem(id) {
      const item = this.$refs[id][0];
      item.style.height = item.offsetHeight + 'px';

      this.$anime
        .timeline()
        .add({
          targets: item,
          opacity: 0,
          translateX: this.localeDirection === 'ltr' ? -300 : 300,
          duration: 150,
          easing: 'easeOutCubic',

          complete: () => {
            while (item.firstChild) {
              item.removeChild(item.firstChild);
            }
          },
        })
        .add({
          targets: item,
          height: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0,
          duration: 500,
          easing: 'easeOutCubic',
        })
        .finished.then(
          () =>
            (this.notifications = this.notifications.filter(
              item => item.id !== id
            ))
        );
    },
    goBack() {
      if (window.history.length > this.historyLimit) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notifReadView {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
  font-size: $hr-text-small;
}

.notifTitle {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #696969;
  text-transform: uppercase;
}

.notification-body {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
}

.notifButtons {
  display: flex;
}

.notifications-page {
  h2 {
    font-size: 1.5rem;
  }

  .status {
    font-size: 0.875rem;

    .status-col {
      display: flex;
      justify-content: space-between;
    }

    .count span {
      font-weight: bold;
    }

    .mark-as-read a {
      color: white;
      display: flex;
      margin-right: 17px;
      font-family: 'Exo';
      font-style: normal;
      font-size: 16px;
      line-height: 140%;
    }
  }
}

.mark-as-read {
  display: flex;
  align-items: center;
}

.mark-icon {
  height: 20px;
}

.mark-as-read > a {
  font-weight: normal;
  text-decoration: underline;

  &:hover {
    color: $hr-text-primary;
  }
}

.notifications-list {
  margin-bottom: 50px;

  .notifAvatar {
    width: 4.8em !important;
    height: 4.8em !important;
    margin: 0 !important;
    border-radius: 0.5em !important;
    min-width: 4.8em !important;
    @include auto-rtl(margin-left, 3.5em);
    background-color: $hr-bg-notif-avatar;
    background-size: cover !important;
    background-position: center !important;
  }

  &.empty {
    font-size: 0.9375rem;
    color: $ph-secondary-text;
    text-align: center;
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
  }

  .notification-header {
    background: $new-bg-elevated;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .unreadNotif {
    background-color: $new-bg-neutral;

    .time {
      color: $new-border-primary !important;
    }
  }

  .notification-container {
    margin-bottom: 1.9em;
    width: 90%;
    min-height: 7.39vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s, opacity 0.5s;
    border-radius: 0.5em;
    margin-left: auto;
    margin-right: auto;

    &.animated {
      transform: translate(-100px, 0);
      opacity: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .notification-content-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      @include auto-rtl(margin-left, 2.9em);
    }

    .notification-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.6em;
      @include auto-rtl(padding-right, 2.5em);

      button:first-child {
        width: 99px;
        height: 36px;
        font-family: 'Exo';
        font-style: normal;
        font-size: 16px;
        line-height: 140%;
        font-weight: 700;
        padding: 0;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: $hr-bg-accent;
        background-color: $hr-bg-accent;
        background: #ffffff;
      }

      button:not(:first-child) {
        width: 99px;
        height: 36px;
        font-family: 'Exo';
        font-style: normal;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        text-align: center;
        border: 1px solid #ffffff;
        border-radius: 0.5em;
      }
    }

    .btn-delete-notification {
      cursor: pointer;
      position: absolute;
      margin-top: 1.1em;
      margin-right: 0.9em;
      z-index: 2;
      @include auto-rtl(right, 0.5em);
    }

    &:hover {
      background-color: $ph-popover-bg-raw;
    }

    .unread {
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 1em;
        height: 1em;
        @include auto-rtl(right, 0);
        bottom: 100%;
        background: $ph-error;
        border-radius: 50%;
        transform: translateY(100%);
      }
    }

    .notification-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 2em 0;

      .subject {
        font-family: $ph-font-family-secondary;
        font-size: 1.6em;
        line-height: 140%;
      }

      .time {
        font-size: $ph-body-font-size;
        color: $new-fg-disabled;
        line-height: $ph-body-line-height;
        display: block;
        margin-top: 0.5em;
      }
    }

    .notification-text {
      font-size: $hr-text-regular;
    }
  }
}

@media screen and (max-width: 800px) {
  .notification-content-wrapper {
    .notification-actions {
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
    }

    .notification-text {
      width: 100%;
    }
  }
}

@media screen and (max-width: 615px) {
  .notification-content-wrapper {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    gap: 10px;
  }

  .notification-actions {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 400px) {
  .notification-content-wrapper {
    padding-top: 10px;
    @include auto-rtl(margin-left, 10px !important);
  }
}

.layout-wrapper.rtl {
  .notifications-list {
    padding-left: 15px;
    padding-right: 0;

    .notification-container {
      .notification-header {
        .notification-actions {
          flex-direction: row;
          margin-left: initial;
          margin-right: auto;
        }
      }
    }
  }
}
</style>
