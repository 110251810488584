<template>
  <div
    class="attach"
    :class="{
      'attach__has-error': error,
      'align-items-center d-flex justify-content-center': error && !hidePreview,
      'attach__without-preview': hidePreview,
      'attach__is-loading': isLoading,
    }"
  >
    <div
      v-if="error"
      class="attach-error-msg"
      :class="{ 'w-75 text-center': !hidePreview }"
    >
      <template v-if="error === ERROR_FILE_LARGE">
        {{ $t('_web_upload-file_error-file-large', 'File is too large!') }}
      </template>
      <template v-else-if="error === ERROR_NETWORK">
        {{ $t('_web_upload-file_error-network', 'Network error!') }}
      </template>
      <template v-else-if="error === ERROR_UNSUPPORTED_FORMAT">
        {{ $t('_web_create_post-file-format-error', 'File format is invalid') }}
      </template>
    </div>

    <template v-else-if="hidePreview">
      <template v-if="isLoading">
        <div
          class="attach__loader-wrapper d-flex align-items-end justify-content-center"
        >
          <Loader v-if="customLoader" :size="32" />
          <!-- <div  class="attach__loader"></div> -->
        </div>
        <ProgressBar
          v-if="!customLoader"
          :current="currentUpload"
          :max="totalUpload"
          show-value
          class="w-100 mt-3"
        />
      </template>
      <Icon v-else name="file" />
      <span class="attach__filename text-truncate mx-2">{{
        data.file.name
      }}</span>
    </template>

    <template v-else>
      <img
        v-if="type === 'image' && preview"
        :src="preview"
        class="attach-preview"
      />
      <template v-else-if="type === 'video' && preview">
        <video
          :src="preview"
          @loadedmetadata="videoDuration = $event.target.duration"
        ></video>
        <span class="px-1 font-size-xs text-muted video-duration">{{
          videoDuration | formatTimeFromSeconds
        }}</span>
      </template>
      <transition-group name="fade" appear>
        <template v-if="isLoading">
          <div
            key="attach-loader"
            class="attach__loader-wrapper d-flex align-items-end justify-content-center"
          >
            <Loader v-if="customLoader" :size="60" />
            <!-- <div v-else class="attach__loader"></div> -->
          </div>
          <ProgressBar
            v-if="!customLoader"
            key="attach-progress"
            :current="currentUpload"
            :max="totalUpload"
            show-value
            class="w-100 mt-3"
          />
        </template>
      </transition-group>
    </template>
    <div class="attach__delete-btn" @click="deleteThis">
      <Icon
        :name="hidePreview ? 'delete' : 'cancel'"
        :hover-icon="hidePreview ? 'delete-active' : ''"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/atoms/Icon';
import Loader from '@/components/common/Loader';

import ProgressBar from '@/components/atoms/ProgressBar';
const MAX_FILE_SIZE = 125829120; // backend info
const ERROR_FILE_LARGE = 1;
const ERROR_NETWORK = 2;
const ERROR_UNSUPPORTED_FORMAT = 3;

export default {
  name: 'EditableAttach',
  components: { Icon, Loader, ProgressBar },
  props: {
    data: {
      type: Object,
      required: true,
    },
    hidePreview: {
      type: Boolean,
      default: false,
    },
    customLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preview: '',
      isLoading: true,
      type: '',
      error: null,
      ERROR_FILE_LARGE,
      ERROR_NETWORK,
      ERROR_UNSUPPORTED_FORMAT,
      currentUpload: 0,
      totalUpload: 0,
      videoDuration: 0,
    };
  },
  created() {
    const self = this;
    this.type = this.data.type;
    if (!this.data.file && this.data[this.type]) {
      this.preview = URL.createObjectURL(this.data);
      this.isLoading = false;
      this.$emit('attachLoaded', this.data);
      return;
    }

    if (this.data.file.size > MAX_FILE_SIZE) {
      this.error = ERROR_FILE_LARGE;
      this.isLoading = false;
      return this.$emit('attachError');
    }
    this.sendFileDirect({
      fileData: this.data.file,
      config: {
        onUploadProgress: function(progressEvent) {
          self.updateFileUploadProgress(progressEvent);
        },
      },
    })
      .then(data => {
        this.isLoading = false;
        this.$emit('attachLoaded', { ...data, name: this.data.file.name });
      })
      .catch(error => {
        this.error = error.message.includes('file format')
          ? ERROR_UNSUPPORTED_FORMAT
          : ERROR_NETWORK;

        if (error.message.includes('file format'))
          error.message = this.$t(
            '_web_create_post-file-format-error',
            'File format is invalid'
          );

        this.isLoading = false;
        this.$emit('attachError');
        this.errorNotify(error);
      });

    const reader = new FileReader();
    reader.onload = e => {
      this.preview = e.target.result;
      this.$emit('previewLoaded', this.preview);
    };
    reader.readAsDataURL(this.data.file);
  },
  methods: {
    ...mapActions(['sendFileDirect', 'errorNotify']),
    deleteThis() {
      this.$emit('deleteAttach');
    },
    updateFileUploadProgress(progress) {
      this.currentUpload = progress.loaded;
      this.totalUpload = progress.total;
    },
  },
};
</script>

<style lang="scss">
.attach {
  position: relative;
  width: 6rem;
  height: 4rem;
  margin-right: $ph-tiny-space * 2;
  margin-bottom: $ph-tiny-space * 2;
  border-radius: 0.25rem;
  background-color: $ph-secondary-text;

  &__has-error {
    opacity: 0.5;
    box-shadow: inset 0 0 5px $ph-error;
    &-msg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $ph-error;
    }
  }

  img,
  video {
    object-fit: cover;
    width: 6rem;
    height: 4rem;
    border-radius: inherit;
    + .video-duration {
      background: $ph-bg;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &__is-loading {
    background-color: transparent;

    img,
    video {
      height: 2.5rem;
    }
  }

  &__delete-btn {
    position: absolute;
    top: 0;
    @include auto-rtl(right);
    padding: 0.5rem;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &__loader-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    border-radius: inherit;
  }

  &__loader {
    width: 2rem;
    height: 2rem;

    border: 2px solid transparent;
    border-left-color: $ph-accent;
    border-bottom-color: $ph-accent;
    border-radius: 50%;
    @include rotate-element(0.75s);
  }

  &__without-preview {
    display: flex;
    align-items: center;
    background-color: unset;
    width: unset;
    height: 1.5rem;
    margin-right: unset;
    margin-top: $ph-small-space;

    .attach__filename {
      position: relative;
      font-size: 0.875rem;
      line-height: 1rem;
      max-width: 80%;
      border-bottom: 1px solid $ph-accent;
    }

    .attach__delete-btn {
      position: static;
      border-radius: unset;
      background: unset;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .attach__loader-wrapper {
      position: relative;
      width: 18px;
      height: 18px;
      background-color: unset;
      & .loader {
        position: absolute;
        transform: translate(-6px, -4px);
      }
    }

    .attach__loader {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
