<template>
  <div class="form-group h-text-left m-0">
    <label :for="id" class="pull-left pb-2 m-0">
      {{ label }}
    </label>

    <div class="passwordContainer" :class="{ 'is-invalid': error }">
      <input
        class="new-password-input"
        :autocomplete="autocompleteOff ? 'new-password' : 'on'"
        :class="{ 'red-outline': error }"
        v-bind="{
          id,
          type: inputType,
          value,
          placeholder,
          tabindex,
          autofocus,
          ...inputProps,
        }"
        @input="updateValue"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div class="showPassword">
        <button
          type="button"
          class="btn btn-outline-secondary password-reveal dropdown-toggle-split border-0 passwordIcon"
          :class="{ 'is-invalid': error }"
          @click="revealPass"
          @mouseenter="isRevealBtnHovered = true"
          @mouseleave="isRevealBtnHovered = false"
        >
          <AppIcon
            :name="isDangerIconVisible ? 'danger' : revealBtnIcon"
            :fill="isDangerIconVisible ? $scss.errorColor : $scss.textColor"
          />
        </button>
      </div>
    </div>
    <span
      v-show="(error, showError)"
      id="passwordHelp"
      class="form-text text-danger error-help"
      >{{ error }}</span
    >
    <div v-if="forgotPass" class="p-2 h-text-left">
      <router-link
        class="reset-password"
        tabindex="4"
        :to="{ name: 'passwordReset' }"
      >
        {{ forgotPass }}
      </router-link>
    </div>
  </div>
</template>
<script>
import AppIcon from '@/components/atoms/Icon';
import { prop } from '@/utils/factories';

export default {
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  components: {
    AppIcon,
  },
  props: {
    name: prop(String),
    id: prop(String, 'password-input'),
    label: prop(String),
    disabled: prop(Boolean),
    required: prop(Boolean),
    value: prop(String),
    error: prop(String),
    tabindex: prop(Number, -1),
    autofocus: prop(Boolean),
    placeholder: prop(String),
    autocompleteOff: prop(Boolean),
    forgotPass: prop(String),
    inputProps: prop(Object, null),
  },
  data() {
    return {
      inputType: 'password',
      isFocused: false,
      isRevealBtnHovered: false,
      showError: true,
    };
  },
  computed: {
    isDangerIconVisible() {
      return !!this.error && !this.isFocused && !this.isRevealBtnHovered;
    },
    revealBtnIcon() {
      return this.inputType === 'password' ? 'eye_open' : 'eye_close';
    },
  },
  methods: {
    revealPass() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    updateValue(e) {
      this.$emit('input', e.target.value);
    },
    onFocus() {
      this.$emit('focus');
      this.showError = false;
    },
    onBlur() {
      this.$emit('blur');
      this.showError = true;
    },
  },
};
</script>
<style lang="scss">
.showPassword {
  position: absolute;
  /* right: 0px; */
  top: 0px;
  @include auto-rtl(right, 0px);
}

.passwordContainer {
  position: relative;
}

.new-password-input {
  color: $new-fg-neutral;
  border-radius: 0.25rem;
  background-color: $new-bg-base;
  width: 100%;
  height: 2.25rem;
  border: 1px solid;
  border-color: $new-border-neutral;
  padding: 0.5rem;
}
.passwordIcon {
  height: 36px;
  border-radius: 0.25rem;
}
.reset-password {
  font-size: 14px;
  color: $new-fg-disabled;
}

input::placeholder {
  color: $new-fg-neutral-faded;
}
.red-outline {
  border-color: rgb(223, 72, 95);
}
</style>
