const TournamentsWrapper = () => import('@/views/tournaments');
const TournamentsList = () => import('@/views/tournaments/list');
const TournamentGridView = () =>
  import('@/views/tournaments/management/brackets/GridViewWrapper');
const PlayerStageWrapper = () =>
  import('@/views/tournaments/player/PlayerStageWrapper');
const StagesList = () =>
  import('@/views/tournaments/management/singleTournament/stages/StagesList');
const BracketsWrapper = () =>
  import('@/views/tournaments/management/brackets/index');
const SingleTournamentWrapper = () =>
  import('@/views/tournaments/singleTournament');

const TournamentRegistration = () =>
  import('@/views/tournaments/player/tournament-registration/index');
const TournamentRegistrationCreateRoster = () =>
  import('@/views/tournaments/player/tournament-registration/CreateRoster');
const TournamentRegistrationTeamSelect = () =>
  import('@/views/tournaments/player/tournament-registration/TeamSelect');
const TournamentRegistrationForm = () =>
  import('@/views/tournaments/player/tournament-registration/MainForm');
const TournamentStandings = () =>
  import('@/views/tournaments/management/standings/');

const StageDetails = () => import('@/views/tournaments/player/StageDetails');

const CreateClanDetails = () => import('@/views/clans/create/Details');
import { MAIN_COMPETITIONS, MAIN_TOURNAMENTS } from '@/constants/router/mains';
const TOURNAMENTS_MODULE_NAME = 'tournaments';

export default [
  {
    path: TOURNAMENTS_MODULE_NAME,
    component: TournamentsWrapper,
    children: [
      {
        path: '',
        name: MAIN_TOURNAMENTS,
        component: TournamentsList,
        meta: {
          includeInParentMenu: MAIN_COMPETITIONS,
          guestEnabled: true,
          // inMainNav: 30,
          // inMainNavOrg: 30,
          excludeFromBreadcrumbs: true,
          titleKey: '_web_left-sidebar_nav-tournaments-link',
          fallbackLabel: 'Tournaments',
          icon: 'sidebar/all-tournaments',
          sidebarParent: MAIN_TOURNAMENTS,
        },
      },
      {
        path: ':tournamentID',
        component: SingleTournamentWrapper,
        meta: {
          titleKey: '_web_tournaments_view-link',
          fallbackLabel: 'Tournament details',
          sidebarParent: MAIN_TOURNAMENTS,
        },
        children: [
          {
            path: 'brackets',
            component: PlayerStageWrapper,
            meta: {
              titleKey: '_web_tournaments_stages-create-title',
              fallbackLabel: 'Stages',
              showAdminFunctions: false,
            },
            children: [
              {
                path: ':stageID', // <domain>/tournaments/<ID>/stages/
                component: StagesList,
                name: 'playerStagesListLink',
                meta: {
                  titleKey: '_web_tournaments_stages-create-title',
                  fallbackLabel: 'Stages',
                  showAdminFunctions: false,
                },
              },
              {
                path: ':stageID/block/:blockID', // <domain>/tournaments/<ID>/stages/<ID>/blocks/<ID>/brackets
                component: StagesList,
                meta: {
                  showAdminFunctions: false,
                },
              },
            ],
          },
          {
            path: 'stages',
            component: PlayerStageWrapper,
            meta: {
              titleKey: '_web_tournaments_stages-create-title',
              fallbackLabel: 'Stages',
              showAdminFunctions: false,
            },
            children: [
              {
                path: '', // <domain>/tournaments/<ID>/stages/
                component: StagesList,
                name: 'playerStagesList',
                meta: {
                  titleKey: '_web_tournaments_stages-create-title',
                  fallbackLabel: 'Stages',
                  showAdminFunctions: false,
                },
              },
              {
                path: ':stageID', // <domain>/tournaments/<ID>/stages/<ID>
                component: StageDetails,
                name: 'playerViewStage',
                meta: {
                  titleKey: '_web_tournaments_stages-info',
                  fallbackLabel: 'Stage info',
                },
              },
              {
                path: ':stageID/blocks/:blockID/brackets/:blockType?', // <domain>/tournaments/<ID>/stages/<ID>/blocks/<ID>/brackets
                component: BracketsWrapper,
                meta: {
                  showAdminFunctions: false,
                },
                children: [
                  {
                    path: '',
                    name: 'playerTournamentBracket',
                    component: TournamentGridView,
                    props: true,
                  },
                ],
              },
            ],
          },
          {
            path: 'create-roster',
            name: 'tournamentRegistrationCreateRoster',
            component: TournamentRegistrationCreateRoster,
            meta: {
              titleKey: '_web_tournaments_registration-create-roster',
              fallbackLabel: 'Create roster',
            },
          },
          {
            path: 'registration',
            name: 'tournamentRegistration',
            component: TournamentRegistration,
            children: [
              {
                path: 'select-team',
                name: 'tournamentRegistrationTeamSelect',
                component: TournamentRegistrationTeamSelect,
                meta: {
                  titleKey: '_web_tournaments_registration-select-team',
                  fallbackLabel: 'Select your team',
                },
              },
              {
                path: 'with-new-team',
                name: 'tournamentRegistrationWithNewTeam',
                component: CreateClanDetails,
                meta: {
                  backButtonInHeader: true,

                  titleKey: '_web_tournaments_registration-create-and-join',
                  fallbackLabel: 'Create team and join',
                },
              },
              {
                path: 'form',
                name: 'tournamentRegistrationForm',
                component: TournamentRegistrationForm,
                meta: {
                  titleKey: '_web_tournaments_registration-form',
                  fallbackLabel: 'Fill form data',
                },
              },
              // {
              //   path: 'create-roster',
              //   name: 'tournamentRegistrationCreateRoster',
              //   component: TournamentRegistrationCreateRoster,
              //   meta: {
              //     titleKey: '_web_tournaments_registration-create-roster',
              //     fallbackLabel: 'Create roster',
              //     tab: 'roster',
              //   },
              // },
            ],
          },
          {
            path: ':stageID/blocks/:blockID/standings',
            name: 'playerTournamentStandings',
            component: TournamentStandings,
            meta: {
              titleKey: '_web_tournaments_standings-table',
              fallbackLabel: 'Standings',
            },
          },
        ],
      },
    ],
  },
];
