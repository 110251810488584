<template>
  <div
    v-if="maintenance"
    class="maintenance-popup d-flex align-items-center justify-content-center"
  >
    <div
      class="d-flex h-75 flex-column align-items-center justify-content-around"
    >
      <Icon name="playhera-logo" :original="true" :width="239" :size="239" />
      <div class="d-flex flex-column align-items-center">
        <TextBlock
          :text="$t('_web_maintenance_title', 'Maintenance break')"
          :size="2.5"
          tag="h1"
          class="font-weight-normal"
        />
        <TextBlock
          variant="secondary"
          tag="p"
          class="w-75 text-center font-weight-normal"
        >
          {{
            $t(
              '_web_maintenance_subtitle',
              'We are performing a server maintenance tasks to make PLAYHERA better. It will takes some time. Stay tuned.'
            )
          }}
        </TextBlock>
      </div>
      <Icon name="worker" :original="true" :width="292" :size="292" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';

export default {
  name: 'Locker',
  components: {
    Icon,
    TextBlock,
  },

  computed: {
    ...mapGetters(['maintenance']),
  },

  watch: {
    maintenance(newVal) {
      document.body.classList[newVal ? 'add' : 'remove']('modal-open');
    },
  },

  mounted() {
    this.fetchMaintenance();
    this.addSystemSubscriber(this.subscriber);
  },

  destroyed() {
    this.removeSystemSubscriber(this.subscriber);
  },

  methods: {
    ...mapMutations(['setMaintenance']),
    ...mapActions([
      'fetchMaintenance',
      'addSystemSubscriber',
      'removeSystemSubscriber',
    ]),
    subscriber({ type, extended }) {
      if (type === 'maintenance_updated') {
        this.setMaintenance(extended.maintenance);
      }
    },
  },
};
</script>

<style lang="scss">
.maintenance-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: $ph-input-bg;
  z-index: 9998;
}
</style>
