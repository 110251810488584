var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"player-profile-teams",on:{"mouseenter":function($event){return _vm.getPostAuthorProfile(_vm.data.id)},"mouseleave":function($event){_vm.showFollowButton = false}}},[_c('router-link',{attrs:{"to":_vm.profileLink}},[_c('li',{staticClass:"search-item-teams"},[_c('div',{staticClass:"avatar-container-teams"},[_c('Avatar',_vm._b({},'Avatar',{
            size: 48,
            type: _vm.data.clan || 'clan',
            imgUrl: _vm.data.avatar ? _vm.data.avatar : _vm.data.logo,
            isDeleted: _vm.data.deleted,
          },false))],1),_c('div',{staticClass:"text-team-container"},[_c('div',{staticClass:"text-name-container-teams"},[_c('TextLabel',{staticClass:"text-name-teams"},[_vm._v("\n            "+_vm._s(_vm.data.display_name
                ? _vm.data.display_name
                : _vm.data.name
                ? _vm.data.name
                : _vm.data.login)+"\n          ")])],1),_c('div',{staticClass:"d-flex team-count"},[_c('span',{staticClass:"number-tag"},[_vm._v("\n            "+_vm._s(_vm.data.members_count)+"\n            "),_c('span',{staticClass:"players-tag"},[_vm._v(_vm._s(_vm.$t('web_player_title', 'Players')))])])])])])]),_c('div',{staticClass:"follow-button-container-teams"},[_c('FollowBtn',{staticClass:"follow-btn follow-btn-team",class:_vm.showFollowButton ? '' : 'follow-hidden',attrs:{"model":_vm.model,"dir":"rtl","follow-class":"btn-primary","hover-class":"btn-secondary","unfollow-class":"btn-secondary"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }