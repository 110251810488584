import { render, staticRenderFns } from "./InfoTooltip.vue?vue&type=template&id=4befe977&"
import script from "./InfoTooltip.vue?vue&type=script&lang=js&"
export * from "./InfoTooltip.vue?vue&type=script&lang=js&"
import style0 from "./InfoTooltip.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./InfoTooltip.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CMolecules&name=InfoTooltip&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('text'%2C%20'text')%0A%20%20%20%20%7D%2C%0A%20%20%20%20showIcon%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('readOnly'%2C%20true)%0A%20%20%20%20%7D%2C%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports