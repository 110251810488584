import Vue from 'vue';
import Vuex from 'vuex';
import hub from './modules/hub';
import accounts from './modules/accounts/';
import user from './modules/user.js';
import counter from './modules/counter.js';
import organization from './modules/organization.js';
import clans from './modules/clans.js';
import tournamentsAdmin from './modules/tournaments/admin';
import tournamentsUser from './modules/tournaments/user';
// import app from './modules/app.js';
import tags from './modules/tags.js';
import proleagues from './modules/proleagues.js';
import competitions from './modules/competitions.js';
import battlesAdmin from './modules/battles/admin';
import battlesUser from './modules/battles/user';
import battlesTournament from './modules/battles/tournament';
import files from './modules/files.js';
import statistic from './modules/statistic.js';
import newsfeeds from './modules/newsfeeds.js';
import sidebars from './modules/sidebars.js';
import ph from './modules/ph.js';
import feed from './modules/feed.js';
// import settings from './modules/settings.js'
// import translations from './modules/translations.js'
import adminMention from './modules/adminMention.js';
import comments from './modules/comments.js';
import sliderchat from './modules/sliderchat.js';
import requestCancel from './modules/requestCancel.js';
import mediaplayer from './modules/mediaplayer';
import commonData from './modules/commonData.js';
import state from './modules/appState.js';
import notification from './modules/notification.js';
import players from './modules/players.js';
import platforms from './modules/platforms.js';
import map from './modules/map.js';
import conversation from './modules/conversation.js';
import modal from './modules/modal.js';
import popover from './modules/popover.js';
import popoverMenu from './modules/popoverMenu.js';
import accountsRequests from './modules/accounts/interactionRequests.js';
import alerts from './modules/alerts';
import vanity from './modules/vanityUrls';
import metrics from './modules/metrics';
import qrCode from './modules/qrCode';
import regions from './modules/regions';
import payments from './modules/payments';
import search from './modules/search';
import shop from './modules/shop';
import indonesia from './modules/indonesia';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...accounts,
    indonesia,
    hub,
    counter,
    ph,
    user,
    search,
    clans,
    // app,
    adminMention,
    sidebars,
    feed,
    tags,
    organization,
    proleagues,
    competitions,
    battles: battlesAdmin,
    battlesUser,
    battlesTournament,
    files,
    statistic,
    newsfeeds,
    comments,
    sliderchat,
    requestCancel,
    mediaplayer,
    commonData,
    state,
    notification,
    players,
    platforms,
    map,
    conversation,
    modal,
    popover,
    popoverMenu,
    accountsRequests,
    tournamentsAdmin,
    tournamentsUser,
    alerts,
    vanity,
    metrics,
    qrCode,
    regions,
    payments,
    shop,
  },
  strict: process.env.NODE_ENV !== 'production',
});
