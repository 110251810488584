var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row notifications-page mx-0"},[_c('portal',{attrs:{"to":"header-left-portal"}},[_c('GoBackBtn',{staticClass:"pl-0 pr-0 mr-5",staticStyle:{"min-width":"16px"},attrs:{"history-mode":"","hide-label":""}})],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[(_vm.isDataFetching && !_vm.nextPageToken)?_c('div',{staticClass:"col notifications-list empty"},[_c('HeraDummyList',{attrs:{"icon-name":"dummy/notifications","size":"200"}},[_vm._v("\n          "+_vm._s(_vm.$t('_web_fetching_notifications', 'Fetching notifications...'))+"\n        ")])],1):(!_vm.notifications.length)?_c('div',{staticClass:"col notifications-list empty"},[_c('HeraDummyList',{staticClass:"noNotifs",attrs:{"icon-name":"dummy/notifications","size":"200"}},[_vm._v("\n          "+_vm._s(_vm.$t('_web_no_notifications_new', 'You have no notifications'))+"\n        ")])],1):(
          _vm.notifications.find(function (notification) { return notification.read === false; })
        )?_c('div',{staticClass:"col-12 notifications-list px-0"},[_c('InfiniteScroll',{attrs:{"list":_vm.notifications,"list-name":"notifications","is-disabled":_vm.isDataFetching || _vm.nextPageToken === null,"activation-gap":50},on:{"ScrollToBottom":_vm.getNextPageData},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _c('div',{},[_c('div',{staticClass:"notifReadView"},[_c('div',{staticClass:"notifTitle"},[_vm._v("\n                "+_vm._s(_vm.$t('_web_notifications_new', 'New'))+"\n              ")]),_c('div',{staticClass:"notifButtons"},[_c('div',{staticClass:"mark-as-read"},[_c('img',{staticClass:"mark-icon",attrs:{"src":require("@/assets/images/header/doubleCheck.svg"),"alt":"mark as read"}}),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.markAllAsRead($event)}}},[_vm._v(_vm._s(_vm.$t(
                      '_web_notifications_mark_all_as_read',
                      'Mark all as read'
                    )))])])])]),_vm._l((_vm.notifications),function(notification,index){return [(notification.read === false)?_c('div',{key:notification.id,ref:notification.id,refInFor:true,staticClass:"notification-container",class:{
                  'block-loading content-blur': _vm.isLoading,
                  'cursor-pointer':
                    notification.type === 'shop_collection_promotion',
                },on:{"click":function($event){$event.stopPropagation();return _vm.markAsRead(notification)}}},[_c('div',{staticClass:"notification-header",class:{ unreadNotif: !notification.read }},[_c('div',{staticClass:"btn-delete-notification",on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(notification.id)}}},[_c('AppIcon',{attrs:{"name":"close","width":"10"}})],1),_c('div',{staticClass:"notification-info"},[_c('div',{staticClass:"notification-body"},[(notification.sender.avatar)?_c('Avatar',{staticClass:"notifAvatar",attrs:{"type":(notification.sender || {}).type,"img-url":(notification.sender || {}).avatar}}):_c('icon',{staticClass:"notifAvatar",attrs:{"name":_vm.getRegionCode(notification.type),"width":57}}),_c('div',{staticClass:"notification-content-wrapper"},[_c('div',{staticClass:"notification-content"},[_c('TextWithLinks',{staticClass:"notification-text",attrs:{"tag":"div","content":_vm.generateNotificationByData(notification).content,"content-props":_vm.generateNotificationByData(notification)
                                .contentProps,"translate-key":("_web_notifications_desc_" + (notification.type))}}),_c('time',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeAgo")(notification.sent_date,_vm.$t))+"\n                          ")])],1),_c('div',{staticClass:"notification-actions"},_vm._l((notification.actions),function(action,idx){return _c('FormBtn',{key:'action-' + notification.id + '-' + idx,class:{ 'btn-bordered': action !== 'Confirm' },on:{"click":function($event){$event.preventDefault();return _vm.applyAction(index, action)}}},[_vm._v("\n                            "+_vm._s(action)+"\n                          ")])}),1)])],1)])])]):_vm._e()]})],2)}}])})],1):_vm._e(),(_vm.notifications.length > 0)?_c('div',{staticClass:"col-12 notifications-list px-0"},[_c('InfiniteScroll',{attrs:{"list":_vm.notifications,"list-name":"notifications","is-disabled":_vm.isDataFetching || _vm.nextPageToken === null,"activation-gap":50},on:{"ScrollToBottom":_vm.getNextPageData},scopedSlots:_vm._u([{key:"default",fn:function(ref){return _c('div',{},[_c('div',{staticClass:"notifReadView"},[_c('div',{staticClass:"notifTitle"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'web_left-sidebar_nav-notification-link',
                    'Notifications'
                  ))+"\n              ")])]),_vm._l((_vm.notifications),function(notification,index){return [(notification.read === true)?_c('div',{key:notification.id,ref:notification.id,refInFor:true,staticClass:"notification-container",class:{
                  'block-loading content-blur': _vm.isLoading,
                  'cursor-pointer':
                    notification.type === 'shop_collection_promotion',
                }},[_c('div',{staticClass:"notification-header",class:{ unreadNotif: !notification.read }},[_c('div',{staticClass:"btn-delete-notification",on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(notification.id)}}},[_c('AppIcon',{attrs:{"name":"close","width":"10"}})],1),_c('div',{staticClass:"notification-info"},[_c('div',{staticClass:"notification-body"},[(notification.sender.avatar)?_c('Avatar',{staticClass:"notifAvatar",attrs:{"type":(notification.sender || {}).type,"img-url":(notification.sender || {}).avatar}}):_c('icon',{staticClass:"notifAvatar",staticStyle:{"background-color":"unset!important"},attrs:{"name":_vm.getRegionCode(notification.type),"width":57}}),_c('div',{staticClass:"notification-content-wrapper"},[_c('div',{staticClass:"notification-content"},[_c('TextWithLinks',{staticClass:"notification-text",attrs:{"tag":"div","content":_vm.generateNotificationByData(notification).content,"content-props":_vm.generateNotificationByData(notification)
                                .contentProps,"translate-key":("_web_notifications_desc_" + (notification.type))}}),_c('time',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeAgo")(notification.sent_date,_vm.$t))+"\n                          ")])],1),_c('div',{staticClass:"notification-actions"},_vm._l((notification.actions),function(action,idx){return _c('FormBtn',{key:'action-' + notification.id + '-' + idx,class:{ 'btn-bordered': action !== 'Confirm' },on:{"click":function($event){$event.preventDefault();return _vm.applyAction(index, action)}}},[_vm._v("\n                            "+_vm._s(action)+"\n                          ")])}),1)])],1)])])]):_vm._e()]})],2)}}],null,false,22161357)})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }