<template>
  <div>
    <MainLayout>
      <div class="terms">
        <div class="terms-container">
          <h1 class="text-center">
            PLAYHERA MOBILE APPLICATION - END USER LICENCE AGREEMENT
          </h1>
          <br />
          <p>
            Entertainment Content Company LTD is a company registered in Saudi
            Arabia under company number 1010451300, with its registered office
            at 3671 Thumama rd. Al Rabie District - Unit 8
          </p>

          <p>
            Riyadh 13316 - 8536 and with VAT number 310174286700003).
            Entertainment Content Company LTD operates this official Playhera
            mobile application (the "App").
          </p>

          <ul>
            <li>
              <p><b>Understanding these terms</b></p>
              <ul>
                <li>
                  <p>
                    This end user licence agreement (this "EolA") describes how
                    you may download the App and access and use the App and the
                    services made available through the App (the "Services"). By
                    accessing the App, this EolA will apply to you and you agree
                    to the terms of this EolA. You shoold therefore read the
                    terms carefolly before using the App.
                  </p>
                </li>
                <li>
                  <p>
                    When certain words and phrases are used in this EolA, they
                    have specific meanings (these are known as "defined terms").
                    You can identify these defined terms because they start with
                    capital letters (even if they are not at the start of a
                    sentence). Where a defined term is used, it has the meaning
                    given to it in the section of this EolA where it was defined
                    (you can find these meanings by looking at the sentence
                    where the defined term is included in brackets and speech
                    marks).
                  </p>
                </li>
                <li>
                  <p>
                    In this EolA, when we refer to "we", "us" or "our", we mean
                    Playhera and Entertainment Content Company LTD; and when we
                    refer to "you" or "your" we mean:
                  </p>
                  <ul>
                    <li>
                      <p>
                        if you are a consumer, the individual using the App for
                        a purpose that is wholly or mainly outside of their
                        trade, business, craft or profession; and
                      </p>
                    </li>
                    <li>
                      <p>
                        if you are not a consumer (for example if you are a
                        professional esports player): (a) the business that you
                        have the authority to bind; or (b) the individual using
                        the App for purposes that are not wholly or mainly
                        outside of their trade, business, craft or profession
                        (in each case a "Business").
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    This App requires a smartphone or other mobile device
                    running either the iOS or Android operating systems (the
                    device you use, the "Device") and, to download the App and
                    to access the latest features, you will need Internet
                    access.
                  </p>
                </li>
                <li>
                  <p>
                    Please note, however, that certain functions made available
                    on the App are governed by additional terms and conditions,
                    including (for example) your participation in esports
                    competitions. We may also, from time to time, introduce
                    additional features and/or services, and will notify you of
                    any applicable terms and conditions of use of the same.
                  </p>
                </li>
                <li>
                  <p>
                    In addition to clause 1.5 above, please note that:
                  </p>
                  <ul>
                    <li>
                      <p>
                        to download the App, you must also review and agree to
                        the additional app terms set out in Appendix 1 to this
                        EolA and any other terms and conditions imposed by the
                        app store from which you have downloaded the App; and
                      </p>
                    </li>
                    <li>
                      <p>
                        we only use your personal information in accordance with
                        our privacy policy (available here, and which also
                        details how we use cookies).
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <p><b>The App</b></p>
              <ul>
                <li>
                  <p>
                    The App is made available free of charge. We do not
                    guarantee that the App, or any content on it, will always be
                    available or be uninterrupted. Access to the App is
                    permitted on a temporary basis. We may suspend, withdraw,
                    discontinue or change all or any part of the App without
                    notice. We will not be liable to you if for any reason the
                    App is unavailable at any time or for any period. We may
                    update the App and/or change the content on it at any time.
                  </p>
                </li>
                <li>
                  <p>
                    You are responsible for making all arrangements necessary
                    for you to have access to the App. You are also responsible
                    for ensuring that all persons who access the App through
                    your internet connection are aware of this EolA and that
                    they comply with them.
                  </p>
                </li>
                <li>
                  <p>
                    The App and the content on it are provided for general
                    information purposes only. They are not intended to amount
                    to advice on which you shoold rely.
                  </p>
                </li>
                <li>
                  <p>
                    Unless you are a Business competing in a Competition or as
                    otherwise expressly authorised by us, you may only use the
                    App for your own domestic, private and non-commercial use.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Your account and password</b></p>
              <ul>
                <li>
                  <p>
                    You will need to register an account with us on the App in
                    order to access certain services available on the App
                    ("Account"). You can register using the App or via our
                    official website (at www.playhera.com). If you register an
                    Account, you will be asked to provide certain information
                    (such as your email address) and to create a password, as
                    part of our security procedures. You must treat such
                    password as confidential and you must not disclose it to any
                    third party.
                  </p>
                </li>
                <li>
                  <p>
                    We have the right to disable any Accounts and/or passwords,
                    at any time, if in our reasonable opinion you have failed to
                    comply with any of the provisions of this EolA.
                  </p>
                </li>
                <li>
                  <p>
                    If you know or suspect that anyone other than you knows your
                    Account login details, you must immediately notify us at
                    <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
                  </p>
                </li>
                <li>
                  <p>
                    You are responsible for any unauthorised use of your Account
                    login details.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Acceptable use</b></p>
              <ul>
                <li>
                  <p>
                    <i><u>General</u></i>
                  </p>
                  <p>You agree:</p>
                  <ul>
                    <li>
                      not to use the App in any unlawfol manner, for any
                      unlawfol purpose or in any manner inconsistent with this
                      EolA;
                    </li>
                    <li>
                      not to infringe our intellectual property rights or those
                      of any third party in relation to your use of the App (to
                      the extent that such use if not licensed under this EolA);
                    </li>
                    <li>
                      not to transmit any material that is defamatory, offensive
                      or otherwise objectionable in relation to your use of the
                      App;
                    </li>
                    <li>
                      not to use the App by automated means or otherwise for the
                      purposes of scraping, extracting or otherwise obtaining
                      any material from the App for use within a third party
                      website or application;
                    </li>
                    <li>
                      not to collect or harvest any information or data from the
                      App or our systems or attempt to decipher any transmission
                      to or from the servers running the App;
                    </li>
                    <li>
                      not to copy, or otherwise reproduce or re-sell any part of
                      the App unless expressly permitted to do so in this EolA;
                    </li>
                    <li>
                      not to disassemble, decompile, reverse-engineer or create
                      derivative works based on the whole or any part of the App
                      or attempt to do any such thing;
                    </li>
                    <li>
                      not to rent, lease, sub-license, loan, translate, merge,
                      adapt, vary or modify the App;
                    </li>
                    <li>
                      to keep all copies of the App secure and to maintain
                      accurate and up-to-date records of the number and
                      locations of all copies of the App;
                    </li>
                    <li>
                      not to provide or otherwise make available the App in
                      whole or in part (including object and source code) in any
                      form to any person without prior written consent from us;
                      and
                    </li>
                    <li>
                      to comply with all technology control or export laws and
                      regolations that apply to the technology used or supported
                      by the App.
                    </li>
                  </ul>
                </li>

                <li>
                  <p>
                    You acknowledge that you have no right to have access to the
                    App in source-code form.
                  </p>
                </li>

                <li>
                  <p>
                    <i><u>User Generated Content</u></i>
                  </p>
                  <p>
                    If it is the case that you supply/upload any content to the
                    App – whether it be pictures, text, sound recordings or
                    whatever – the content you supply ("<b
                      >User Generated Content</b
                    >") must comply with the following roles:
                  </p>
                  <ul>
                    <li>
                      <p>
                        If it is the case that you supply/upload any content to
                        the App – whether it be pictures, text, sound recordings
                        or whatever – the content you supply ("<b
                          >User Generated Content</b
                        >") must comply with the following roles:
                      </p>
                    </li>
                    <li>
                      <p>it must not harass or bolly another person;</p>
                    </li>
                    <li>
                      <p>it must be true and honest so far as you know;</p>
                    </li>
                    <li>
                      <p>it must not be defamatory of anyone;</p>
                    </li>
                    <li>
                      <p>
                        it must not use the material or content or infringe the
                        rights or privacy of anyone else; for example you shoold
                        not use images of well-known characters, footage or
                        music (unless it is your own);
                      </p>
                    </li>
                    <li>
                      <p>
                        it must not contain someone else's personal details or
                        confidential information relating to other people; and
                      </p>
                    </li>
                    <li>
                      <p>
                        it must not promote or condone terrorism, violence or
                        illegal behaviour.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    We reserve the right to refuse to accept or refuse or cease
                    to use any User Generated Content supplied by any person
                    that we think contravenes these roles.
                  </p>
                </li>
                <li>
                  <p>
                    The App has a Report feature that you can use to report to
                    us any content that you feel does not comply with the above
                    roles, and operators will take necessary action within 24
                    hours. Users of offensive content may have their account
                    suspended.
                  </p>
                </li>
                <li>
                  <p>
                    In addition, we may from time to time provide interactive
                    services on the App that shall enable you to upload User
                    Generated Content, including, without limitation:
                  </p>
                  <ul>
                    <li>
                      <p>comment facilities;</p>
                    </li>
                    <li>
                      <p>chat rooms; and/or</p>
                    </li>
                    <li>
                      <p>
                        bolletin boards,<br />(together "Interactive Services").
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    Where we provide an Interactive Service, we will use
                    reasonable endeavours to provide information to you about
                    the kind of service offered and if it is moderated. However,
                    we are under no obligation to oversee, monitor or moderate
                    any Interactive Service we provide.
                  </p>
                </li>
                <li>
                  <p>
                    The use of any of our Interactive Services by a minor is
                    subject to the consent of their parent or guardian. We
                    advise parents who permit their children to use an
                    Interactive Service that it is important that they
                    communicate with their children about their safety online.
                    Minors who are using any Interactive Service shoold be made
                    aware of the potential risks to them.
                  </p>
                </li>
                <li>
                  <p>
                    <i><u>Viruses</u></i>
                  </p>
                  <p>
                    We do not guarantee that the App will be totally secure or
                    free from bugs or viruses. You are responsible for
                    configuring your information technology, computer programmes
                    and platform in order to access the App and we recommend
                    that you use your own virus protection software.
                  </p>
                </li>
                <li>
                  <p>
                    You must not misuse the App by knowingly introducing
                    viruses, trojans, worms, logic bombs or other material which
                    is malicious or technologically harmfol. You must not
                    attempt to gain unauthorised access to the App, the server
                    on which the App is stored or any server, computer or
                    database connected to the App. You must not attack the App
                    via a denial-of-service attack or a distributed denial-of
                    service attack. By breaching this provision, you woold
                    commit criminal offences. We will report any such breach to
                    the relevant law enforcement authorities and we will
                    co-operate with those authorities by disclosing your
                    identity to them. In the event of such a breach, your right
                    to use the App will cease immediately.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Intellectual property</b></p>
              <ul>
                <li>
                  <p>
                    We grant to you a non-transferable, non-exclusive, revocable
                    licence to use the App on the Devices provided that you
                    comply with the terms of this EolA and the documents
                    referred to in it. We reserve all other rights.
                  </p>
                </li>
                <li>
                  <p>
                    We are (as between you and us) the owner or licensee of all
                    intellectual property rights in the App and its content.
                    Those works are protected by intellectual property laws and
                    treaties around the world. All such rights are reserved.
                  </p>
                </li>
                <li>
                  <p>
                    You are not granted any right to use, and may not use, any
                    of our intellectual property rights other than as set out in
                    this EolA. Unless you are a Business competing in a
                    Competition, or as otherwise expressly authorised by us, you
                    must not use the App (or any part of it or its content) for
                    commercial purposes; however, you may download material from
                    the App solely for non-commercial, personal use by you.
                  </p>
                </li>
                <li>
                  <p>
                    No part of the App, including, without limitation, the text,
                    designs, graphics, photographs and images contained in it,
                    may be copied, reproduced, republished, uploaded, re-posted,
                    modified, transmitted or distributed or otherwise used in
                    any way for any non-personal, public or commercial purpose
                    without our prior written consent.
                  </p>
                </li>
                <li>
                  <p>
                    Any communications or materials (including, without
                    limitation, any User Generated Content) you send to us
                    through the App by electronic mail or other means will be
                    treated as non-proprietary and non-confidential (other than
                    communications in respect of your order if you use the App
                    to buy products or services from us). We are free to
                    publish, display, post, distribute and otherwise use any
                    ideas, suggestions, concepts, designs, know-how and other
                    information contained in such communications or material for
                    any purpose, including, but not limited to, developing,
                    manufacturing, advertising and marketing us and our
                    products.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Our liability</b></p>
              <ul>
                <li>
                  <p>
                    Nothing in this EolA excludes or limits our liability for:
                  </p>
                  <ul>
                    <li>
                      <p>death or personal injury caused by our negligence;</p>
                    </li>
                    <li>
                      <p>fraud or fraudolent misrepresentation; and</p>
                    </li>
                    <li>
                      <p>
                        any matter in respect of which it woold be unlawfol for
                        us to exclude or restrict our liability.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>If you are a consumer:</p>
                  <ul>
                    <li>
                      <p>
                        and we fail to comply with this EolA, we are responsible
                        for loss or damage you suffer that is a foreseeable
                        resolt of our breach of this EolA or our negligence, but
                        we are not responsible for any loss or damage that is
                        not foreseeable. Loss or damage is foreseeable if it was
                        an obvious consequence of our breach or if it was
                        contemplated by you and us at the time that you accessed
                        the App;
                      </p>
                    </li>
                    <li>
                      <p>
                        nothing in this EolA affects your statutory rights.
                        Advice about your statutory rights is available from
                        your local consumer protection body; and
                      </p>
                    </li>
                    <li>
                      <p>
                        we only supply the App for domestic and private use. You
                        agree not to use the App, or any content on the App, for
                        any commercial or business purposes and we have no
                        liability to you for any loss of profit, loss of
                        business, business interruption, or loss of business
                        opportunity.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>If you are a Business:</p>
                  <ul>
                    <li>
                      <p>
                        all warranties, conditions, terms, undertakings and
                        obligations implied by statute, common law, custom,
                        trade usage, course of dealing or otherwise, (including
                        but not limited to implied undertakings of satisfactory
                        quality, conformity with description, fitness for any
                        particolar purpose or ability to achieve a particolar
                        resolt) are hereby excluded to the maximum extent
                        permitted by law;
                      </p>
                    </li>
                    <li>
                      <p>
                        we will under no circumstances whatsoever be liable to
                        you, whether in contract, tort (including negligence),
                        breach of statutory duty, or otherwise, arising under or
                        in connection with these Terms for: (a) any loss of
                        profits, sales, business, or revenue; (b) loss or
                        corruption of data, information or software; (c) loss of
                        business opportunity; (d) loss of anticipated savings;
                        (e) loss of goodwill; or (f) any indirect or
                        consequential loss whatsoever;
                      </p>
                    </li>
                    <li>
                      <p>
                        without affecting clause 6.1, our maximum aggregate
                        liability under and/or in connection with this EolA
                        and/or your use of the App (whether in contract, tort
                        (including negligence) or otherwise), in respect of all
                        our acts, omissions (including negligence), breach of
                        statutory duty or breach of warranty, including those of
                        our officers, employees, agents, contractors, or
                        sub-contractors or affiliates, shall not exceed £100;
                      </p>
                    </li>
                    <li>
                      <p>
                        you acknowledge that your sole remedy in respect of any
                        breach of this EolA by us or any other act or omission
                        by us in relation to this EolA and/or the App, to the
                        exclusion of any and all other remedies (including,
                        without limitation, any claim in tort), is a contractual
                        claim for breach of this EolA; and
                      </p>
                    </li>
                    <li>
                      <p>
                        you shall indemnify us and keep us indemnified for any
                        and all losses, expenses and liabilities resolting from
                        all claims, demands, liabilities, damages, costs and
                        expenses incurred by us or by any of our officers,
                        contractors, sub-contractors, agents, employees or
                        affiliates which arise out of your use of the App,
                        and/or your breach of this EolA.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    We assume no responsibility for the content of websites or
                    mobile applications linked to from the App (including links
                    to our commercial sponsors and partners). Such links shoold
                    not be interpreted as endorsement by us of those linked
                    websites or mobile applications. We will not be liable for
                    any loss or damage that may arise from your use of them.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Suspension and termination</b></p>
              <ul>
                <li>
                  <p>
                    If you breach any of the terms of this EolA, we may
                    immediately do any or all of the following (without
                    limitation):
                  </p>
                  <ul>
                    <li>
                      <p>issue a warning to you;</p>
                    </li>
                    <li>
                      <p>
                        temporarily or permanently remove any User Generated
                        Content uploaded by you to the App;
                      </p>
                    </li>
                    <li>
                      <p>
                        temporarily or permanently withdraw your right to use
                        the App;
                      </p>
                    </li>
                    <li>
                      <p>suspend or terminate your Account;</p>
                    </li>
                    <li>
                      <p>
                        issue legal proceedings against you for reimbursement of
                        all costs resolting from the breach (including, but not
                        limited to, reasonable administrative and legal costs);
                      </p>
                    </li>
                    <li>
                      <p>take further legal action against you; and/or</p>
                    </li>
                    <li>
                      <p>
                        disclose such information to law enforcement authorities
                        as we reasonably feel is necessary to do so.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>If we withdraw your right to use the App, then:</p>
                  <ul>
                    <li>
                      <p>
                        all rights granted to you under this EolA shall cease;
                      </p>
                    </li>
                    <li>
                      <p>
                        you must immediately cease all activities authorised by
                        this EolA, including your use of any services provided
                        through the App; and
                      </p>
                    </li>
                    <li>
                      <p>
                        you must immediately delete or remove the App from all
                        Devices, and immediately destroy all copies of the App
                        then in your possession, custody or control and certify
                        to use that you have done so.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Changes to this EolA</b></p>
              <ul>
                <li>
                  <p>
                    We may make changes to the terms of this EolA from time to
                    time (if, for example, there is a change in the law that
                    means we need to change this EolA). Please check this EolA
                    regolarly to ensure that you understand the up-to-date terms
                    that apply at the time that you access and use the App. If
                    we update the terms of this EolA, the updated terms will
                    apply to each of your uses of the App from that point on.
                  </p>
                </li>
                <li>
                  <p>
                    From time to time updates to the App may be issued through
                    the relevant app store. Depending on the update, you may not
                    be able to use the App until you have downloaded the latest
                    version of the App and accepted any new terms.
                  </p>
                </li>
                <li>
                  <p>
                    You will be assumed to have obtained permission from the
                    owners of any Devices that are controlled, but not owned, by
                    you to download a copy of the App onto the Devices. You and
                    they may be charged by your and their service providers for
                    internet access on the Devices. You accept responsibility in
                    accordance with the terms of this EolA for the use of the
                    App or the Services on or in relation to any Device, whether
                    or not it is owned by you.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Other important information</b></p>
              <ul>
                <li>
                  <p>
                    Each of the clauses of this EolA operates separately. If any
                    court or relevant authority decides that any of them are
                    unlawfol or unenforceable, the remaining clauses will remain
                    in foll force and effect.
                  </p>
                </li>
                <li>
                  <p>
                    If we fail to insist that you perform any of your
                    obligations under this EolA, or if we do not enforce our
                    rights against you, or if we delay in doing so, that will
                    not mean that we have waived our rights against you and will
                    not mean that you do not have to comply with those
                    obligations. If we do waive a defaolt by you, we will only
                    do so in writing, and that will not mean that we will
                    automatically waive any later defaolt by you.
                  </p>
                </li>
                <li>
                  <p>
                    If you wish to have more information on online dispute
                    resolution, please follow this link to the website of the
                    European Commission:
                    <a href="http://ec.europa.eu/consumers/odr/"
                      >http://ec.europa.eu/consumers/odr/</a
                    >. This link is provided as required by Regolation (EU) No
                    524/2013 of the European Parliament and of the Council, for
                    information purposes only. We are not obliged to participate
                    in online dispute resolution.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p><b>Governing law and jurisdiction</b></p>
              <ul>
                <li>
                  <p>
                    This EolA is governed by English law. This means that your
                    download, access to, and use of, the App, and any dispute or
                    claim arising out of or in connection therewith will be
                    governed by English law.
                  </p>
                </li>
                <li>
                  <p>
                    You can bring proceedings in respect of this EolA in the
                    English courts. However, if you are a consumer and you live
                    in another European Union member state you can bring legal
                    proceedings in respect of this EolA in either the English
                    courts or the courts of that Member State.
                  </p>
                </li>
                <li>
                  <p>
                    If you are a consumer and you are resident in the European
                    Union and we direct this App to the member state in which
                    you are resident, you will benefit from any mandatory
                    provisions of the law of the country in which you are
                    resident. Nothing in this EolA, including clause 10.1,
                    affects your rights as a consumer to rely on such mandatory
                    provisions of local law.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p>
                <b>Contacting us</b>
                Shoold you have any reasons for a complaint, we will endeavour
                to resolve the issue and avoid any re-occurrence in the future.
                You can always contact us by sending email to
                <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a> or by
                using the following details:
                <br />
                Address:
                <br />
                Entertainment Content Company LTD
                <br />
                3671 Thumama rd. Al Rabie District - Unit 8
                <br />
                Riyadh 13316 - 8536
                <br />
                Saudi Arabia
                <br />
                Email address:
                <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
                <br />
                Telephone number: +966 11 222 1023
                <br />
                Thank you.

                <b>Terms last updated</b> Februray 20<sup>th</sup> 2019
              </p>
            </li>
          </ul>

          <h2 class="text-center">Tournament and Prize Terms and Conditions</h2>

          <p>Apple is not sponsoring any of the tournaments in PLAYHERA</p>

          <ul>
            <li>
              <p>
                The winners are informed via e-mail or PLAYHERA Direct Message
                on the application. After having received a notice the winner
                has two weeks to claim the prize. If the winner fails to respond
                within two weeks any rights to claim the prize may be dismissed.
              </p>
            </li>
            <li>
              <p>
                Prizes There is no possibility of claiming an alternate prize or
                alternate payment of the prize in cash. In addition it is not
                possible to trade the prize for other items, vouchers or goods.
                The participation in a tournament is not a contract between the
                participant and PLAYHERA. If the prize is provided by a third
                party, PLAYHERA will not be responsible for defects, incorrect
                prizes or wrong information. a. Non-cash prizes Payout of prizes
                in cash is not possible. The winner is responsible to provide
                PLAYHERA valid and correct shipping data. Therefore PLAYHERA is
                not responsible if a parcel wasn't accepted, can't be delivered,
                gets lost. b. Cash prizes The prize money will be paid by check
                or bank transfer. Therefore the winner is responsible to provide
                valid and correct data. The prize money will be transferred
                between 4 weeks to 6 months. This date may vary.
              </p>
            </li>
            <li>
              <p>
                Employees of PLAYHERA and all concerned partners and their
                affiliates are excluded from participation.
              </p>
            </li>
            <li>
              <p>
                If the winner is a Team and not an individual, the Team leader
                or Clan founder shoold sign the receipt of the prize.
              </p>
            </li>
            <li>
              <p>
                If the winner is under the age of 17, a parent or legal guardian
                shoold receive the prize in presence of the player.
              </p>
            </li>
            <li>
              <p>There is no legal recourse.</p>
            </li>
            <li>
              <p>
                PLAYHERA reserves the right to cancel or end the Tournament at
                any time and without advanced notice, if an adequate and orderly
                execution of the Tournament is not possible because of technical
                or legal issues.
              </p>
            </li>
          </ul>

          <p>
            For any queries or further information about prizes and Sponsored
            tournaments please contact our support team:
            <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a>
          </p>
          <p class="text-center"><b>APPENDIX 1</b></p>

          <h2 class="text-center">Additional App Terms</h2>

          <p>
            The following terms and conditions shall apply to your use of the
            App in addition to those set out in this EolA.
          </p>

          <p>
            For the purpose of this appendix 1, "Appstore Provider" means the
            provider of the app store through which you have downloaded the App
            (for example, Apple is the <b>Appstore Provider</b> if you have
            downloaded the App from the Apple App Store, Google is the Appstore
            Provider if you have downloaded the App from Google Play, etc).
          </p>

          <ul>
            <li>
              <p>
                You acknowledge and agree that this EolA has been concluded
                between you and Playhera, and not with the Appstore Provider.
                You acknowledge and agree that the Appstore Provider is not
                responsible for the App and its content.
              </p>
            </li>
            <li>
              <p>
                You acknowledge and agree that the Appstore Provider has no
                obligation to provide any maintenance or support in respect of
                the App. Shoold you have any problems in using the App, please
                contact Playhera at
                <a :href="`mailto:${mailSupport}`">{{ mailSupport }}</a
                >.
              </p>
            </li>
            <li>
              <p>
                In the event that the App does not conform with any product
                warranty provided for by this EolA, the Appstore Provider may
                provide you with a refund of the price that you paid to purchase
                the App (if any). The Appstore Provider shall, to the maximum
                extent permitted by law, have no obligation to you whatsoever
                with respect to the App.
              </p>
            </li>
            <li>
              <p>
                You acknowledge and agree that the Appstore Provider shall not
                be responsible for addressing any claims that you might have
                relating to the App, including (without limitation): product
                liability claims; any claim that the App fails to conform to any
                applicable legal or regolatory requirement; and claims arising
                under consumer protection or similar legislation.
              </p>
            </li>
            <li>
              <p>
                In the event that a third party claims that the App infringes
                its intellectual property rights, Playhera (and not the Appstore
                Provider) shall be solely responsible for the investigation,
                defence, settlement and discharge of such claim.
              </p>
            </li>
            <li>
              <p id="app2" ref="appendix2">
                You warrant and represent that: (i) you are not located in a
                country that is subject to a U.S. Government embargo, or that
                has been designated by the U.S. Government as a "terrorist
                supporting" country; and (ii) you are not listed on any U.S.
                Government list of prohibited or restricted parties.
              </p>
            </li>
          </ul>

          <p>
            If the Appstore Provider is Apple, you acknowledge and agree that
            Apple and its subsidiaries are third party beneficiaries to this
            EolA. Upon your acceptance of this EolA, Apple will have the right
            to enforce the EolA against you as a third party beneficiary.
          </p>
          <p class="text-center"><b>APPENDIX 2</b></p>
          <h1 class="text-center">Playhera Shop-Terms of service</h1>
          <h2>OVERVIEW</h2>
          <p>
            This website is operated by PLAYHERA. Throughout the site, the terms
            “we”, “us” and “our” refer to PLAYHERA. PLAYHERA offers this
            website, including all information, tools and Services available
            from this site to you, the user, conditioned upon your acceptance of
            all terms, conditions, policies and notices stated here. <br />
            <br />
            By visiting our site and/ or purchasing something from us, you
            engage in our “Service” and agree to be bound by the following terms
            and conditions (“Terms of Service”, “Terms”), including those
            additional terms and conditions and policies referenced herein
            and/or available by hyperlink. These Terms of Service apply to all
            users of the site, including without limitation users who are
            browsers, vendors, customers, merchants, and/ or contributors of
            content. <br />
            <br />
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any Services. If these Terms of Service
            are considered an offer, acceptance is expressly limited to these
            Terms of Service. <br /><br />
            Any new features or tools which are added to the current store shall
            also be subject to the Terms of Service. You can review the most
            current version of the Terms of Service at any time on this page. We
            reserve the right to update, change or replace any part of these
            Terms of Service by posting updates and/or changes to our website.
            It is your responsibility to check this page periodically for
            changes. Your continued use of or access to the website following
            the posting of any changes constitutes acceptance of those changes.
            <br />
            <br />
            Our store is hosted on Shopify Inc. They provide us with the online
            e-commerce platform that allows us to sell our products and Services
            to you.
          </p>
          <h2>SECTION 1 - ONLINE STORE TERMS</h2>
          <p>
            By agreeing to these Terms of Service, you represent that you are at
            least the age of majority in your state or province of residence, or
            that you are the age of majority in your state or province of
            residence and you have given us your consent to allow any of your
            minor dependents to use this site. <br />
            You may not use our products for any illegal or unauthorized purpose
            nor may you, in the use of the Service, violate any laws in your
            jurisdiction (including but not limited to copyright laws). <br />
            You must not transmit any worms or viruses or any code of a
            destructive nature. <br />
            A breach or violation of any of the Terms will result in an
            immediate termination of your Services. br
          </p>
          <h2>SECTION 2 - GENERAL CONDITIONS</h2>
          <p>
            We reserve the right to refuse Service to anyone for any reason at
            any time. You understand that your content (not including credit
            card information), may be transferred unencrypted and involve (a)
            transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            Credit card information is always encrypted during transfer over
            networks. <br />
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service or any contact on the website through which the Service is
            provided, without express written permission by us. <br />
            The headings used in this agreement are included for convenience
            only and will not limit or otherwise affect these Terms. <br />
          </p>
          <ul>
            <li>
              - All credit/debit cards details and personally identifiable
              information will NOT be stored, sold, shared, rented or leased to
              any third parties.
            </li>
            <li>- Saudi Arabia is our country of domicile.</li>
            <li>
              - Website will NOT deal or provide any services or products to any
              of OFAC (Office of Foreign Assets Control) sanctions countries in
              accordance with the law of Saudi Arabia.
            </li>
            <li>
              - We accept payments online using Visa and MasterCard credit/debit
              card in SAR.
            </li>
            <li>
              - Refunds will be done only through the Original Mode of Payment.
            </li>
            <li>
              - Any dispute or claim arising out of or in connection with this
              website shall be governed and construed in accordance with the
              laws of Saudi Arabia.
            </li>
          </ul>
          <h2>
            SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
          </h2>
          <p>
            We are not responsible if information made available on this site is
            not accurate, complete or current. The material on this site is
            provided for general information only and should not be relied upon
            or used as the sole basis for making decisions without consulting
            primary, more accurate, more complete or more timely sources of
            information. Any reliance on the material on this site is at your
            own risk. <br />
            This site may contain certain historical information. Historical
            information, necessarily, is not current and is provided for your
            reference only. We reserve the right to modify the contents of this
            site at any time, but we have no obligation to update any
            information on our site. You agree that it is your responsibility to
            monitor changes to our site. <br />
          </p>
          <h2>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h2>
          <p>
            Prices for our products are subject to change without notice. <br />
            We reserve the right at any time to modify or discontinue the
            Service (or any part or content thereof) without notice at any time.
            <br />
            We shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service. <br />
          </p>
          <h2>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</h2>
          <p>
            Certain products or Services may be available exclusively online
            through the website. These products or Services may have limited
            quantities and are subject to return or exchange only according to
            our Refund Policy. <br />
            We have made every effort to display as accurately as possible the
            colors and images of our products that appear at the store. We
            cannot guarantee that your computer monitor's display of any color
            will be accurate. <br />
            We reserve the right, but are not obligated, to limit the sales of
            our products or Services to any person, geographic region or
            jurisdiction. We may exercise this right on a case-by-case basis. We
            reserve the right to limit the quantities of any products or
            Services that we offer. All descriptions of products or product
            pricing are subject to change at anytime without notice, at the sole
            discretion of us. We reserve the right to discontinue any product at
            any time. Any offer for any product or Service made on this site is
            void where prohibited. <br />
            We do not warrant that the quality of any products, Services,
            information, or other material purchased or obtained by you will
            meet your expectations, or that any errors in the Service will be
            corrected.
          </p>
          <h2>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
          <p>
            We reserve the right to refuse any order you place with us. We may,
            in our sole discretion, limit or cancel quantities purchased per
            person, per household or per order. These restrictions may include
            orders placed by or under the same customer account, the same credit
            card, and/or orders that use the same billing and/or shipping
            address. In the event that we make a change to or cancel an order,
            we may attempt to notify you by contacting the e‑mail and/or billing
            address/phone number provided at the time the order was made. We
            reserve the right to limit or prohibit orders that, in our sole
            judgment, appear to be placed by dealers, resellers or distributors.
          </p>
          <p>
            You agree to provide current, complete and accurate purchase and
            account information for all purchases made at our store. You agree
            to promptly update your account and other information, including
            your email address and credit card numbers and expiration dates, so
            that we can complete your transactions and contact you as needed.
          </p>
          <h2>SECTION 7 - OPTIONAL TOOLS</h2>
          <p>
            We may provide you with access to third-party tools over which we
            neither monitor nor have any control nor input. <br />ou acknowledge
            and agree that we provide access to such tools ”as is” and “as
            available” without any warranties, representations or conditions of
            any kind and without any endorsement. We shall have no liability
            whatsoever arising from or relating to your use of optional
            third-party tools. <br />Any use by you of the optional tools
            offered through the site is entirely at your own risk and discretion
            and you should ensure that you are familiar with and approve of the
            terms on which tools are provided by the relevant third-party
            provider(s). <br />We may also, in the future, offer new Services
            and/or features through the website (including the release of new
            tools and resources). Such new features and/or Services shall also
            be subject to these Terms of Service.
          </p>
          <h2>SECTION 8 - THIRD-PARTY LINKS</h2>
          <p>
            Certain content, products and Services available via our Service may
            include materials from third-parties. <br />Third-party links on
            this site may direct you to third-party websites that are not
            affiliated with us. We are not responsible for examining or
            evaluating the content or accuracy and we do not warrant and will
            not have any liability or responsibility for any third-party
            materials or websites, or for any other materials, products, or
            Services of third-parties. <br />We are not liable for any harm or
            damages related to the purchase or use of goods, Services,
            resources, content, or any other transactions made in connection
            with any third-party websites. Please review carefully the
            third-party's policies and practices and make sure you understand
            them before you engage in any transaction. Complaints, claims,
            concerns, or questions regarding third-party products should be
            directed to the third-party.
          </p>
          <h2>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
          <p>
            If, at our request, you send certain specific submissions (for
            example contest entries) or without a request from us, you send
            creative ideas, suggestions, proposals, plans, or other materials,
            whether online, by email, by postal mail, or otherwise
            (collectively, 'comments'), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation (1) to maintain any comments in
            confidence; (2) to pay compensation for any comments; or (3) to
            respond to any comments. <br />We may, but have no obligation to,
            monitor, edit or remove content that we determine in our sole
            discretion to be unlawful, offensive, threatening, libelous,
            defamatory, pornographic, obscene or otherwise objectionable or
            violates any party’s intellectual property or these Terms of
            Service. <br />You agree that your comments will not violate any
            right of any third-party, including copyright, trademark, privacy,
            personality or other personal or proprietary right. You further
            agree that your comments will not contain libelous or otherwise
            unlawful, abusive or obscene material, or contain any computer virus
            or other malware that could in any way affect the operation of the
            Service or any related website. You may not use a false e‑mail
            address, pretend to be someone other than yourself, or otherwise
            mislead us or third-parties as to the origin of any comments. You
            are solely responsible for any comments you make and their accuracy.
            We take no responsibility and assume no liability for any comments
            posted by you or any third-party.
          </p>
          <h2>SECTION 10 - PERSONAL INFORMATION</h2>
          <p>
            Your submission of personal information through the store is
            governed by our Privacy Policy.
          </p>
          <h2>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h2>
          <p>
            Occasionally there may be information on our site or in the Service
            that contains typographical errors, inaccuracies or omissions that
            may relate to product descriptions, pricing, promotions, offers,
            product shipping charges, transit times and availability. We reserve
            the right to correct any errors, inaccuracies or omissions, and to
            change or update information or cancel orders if any information in
            the Service or on any related website is inaccurate at any time
            without prior notice (including after you have submitted your
            order).<br />We undertake no obligation to update, amend or clarify
            information in the Service or on any related website, including
            without limitation, pricing information, except as required by law.
            No specified update or refresh date applied in the Service or on any
            related website, should be taken to indicate that all information in
            the Service or on any related website has been modified or updated.
          </p>
          <h2>SECTION 12 - PROHIBITED USES</h2>
          <p>
            In addition to other prohibitions as set forth in the Terms of
            Service, you are prohibited from using the site or its content: (a)
            for any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Service or of any related website, other websites, or the Internet;
            (h) to collect or track the personal information of others; (i) to
            spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; or (k) to interfere with or circumvent
            the security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website for violating any of the
            prohibited uses.
          </p>
          <h2>
            SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
          </h2>
          <p>
            We do not guarantee, represent or warrant that your use of our
            Service will be uninterrupted, timely, secure or error-free.
            <br />We do not warrant that the results that may be obtained from
            the use of the Service will be accurate or reliable. <br />You agree
            that from time to time we may remove the Service for indefinite
            periods of time or cancel the Service at any time, without notice to
            you. <br />You expressly agree that your use of, or inability to
            use, the Service is at your sole risk. The Service and all products
            and Services delivered to you through the Service are (except as
            expressly stated by us) provided 'as is' and 'as available' for your
            use, without any representation, warranties or conditions of any
            kind, either express or implied, including all implied warranties or
            conditions of merchantability, merchantable quality, fitness for a
            particular purpose, durability, title, and non-infringement.
            <br />In no case shall PLAYHERA, our directors, officers, employees,
            affiliates, agents, contractors, interns, suppliers, Service
            providers or licensors be liable for any injury, loss, claim, or any
            direct, indirect, incidental, punitive, special, or consequential
            damages of any kind, including, without limitation lost profits,
            lost revenue, lost savings, loss of data, replacement costs, or any
            similar damages, whether based in contract, tort (including
            negligence), strict liability or otherwise, arising from your use of
            any of the Service or any products procured using the Service, or
            for any other claim related in any way to your use of the Service or
            any product, including, but not limited to, any errors or omissions
            in any content, or any loss or damage of any kind incurred as a
            result of the use of the Service or any content (or product) posted,
            transmitted, or otherwise made available via the Service, even if
            advised of their possibility. Because some states or jurisdictions
            do not allow the exclusion or the limitation of liability for
            consequential or incidental damages, in such states or
            jurisdictions, our liability shall be limited to the maximum extent
            permitted by law.
          </p>
          <h2>SECTION 14 - INDEMNIFICATION</h2>
          <p>
            You agree to indemnify, defend and hold harmless PLAYHERA and our
            parent, subsidiaries, affiliates, partners, officers, directors,
            agents, contractors, licensors, Service providers, subcontractors,
            suppliers, interns and employees, harmless from any claim or demand,
            including reasonable attorneys’ fees, made by any third-party due to
            or arising out of your breach of these Terms of Service or the
            documents they incorporate by reference, or your violation of any
            law or the rights of a third-party.
          </p>
          <h2>SECTION 15 - SEVERABILITY</h2>
          <p>
            In the event that any provision of these Terms of Service is
            determined to be unlawful, void or unenforceable, such provision
            shall nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion shall be deemed to be
            severed from these Terms of Service, such determination shall not
            affect the validity and enforceability of any other remaining
            provisions.
          </p>
          <h2>SECTION 16 - TERMINATION</h2>
          <p>
            The obligations and liabilities of the parties incurred prior to the
            termination date shall survive the termination of this agreement for
            all purposes. <br />These Terms of Service are effective unless and
            until terminated by either you or us. You may terminate these Terms
            of Service at any time by notifying us that you no longer wish to
            use our Services, or when you cease using our site. <br />If in our
            sole judgment you fail, or we suspect that you have failed, to
            comply with any term or provision of these Terms of Service, we also
            may terminate this agreement at any time without notice and you will
            remain liable for all amounts due up to and including the date of
            termination; and/or accordingly may deny you access to our Services
            (or any part thereof).
          </p>
          <h2>SECTION 17 - ENTIRE AGREEMENT</h2>
          <p>
            The failure of us to exercise or enforce any right or provision of
            these Terms of Service shall not constitute a waiver of such right
            or provision. <br />These Terms of Service and any policies or
            operating rules posted by us on this site or in respect to the
            Service constitutes the entire agreement and understanding between
            you and us and governs your use of the Service, superseding any
            prior or contemporaneous agreements, communications and proposals,
            whether oral or written, between you and us (including, but not
            limited to, any prior versions of the Terms of Service). <br />Any
            ambiguities in the interpretation of these Terms of Service shall
            not be construed against the drafting party.
          </p>
          <h2>SECTION 18 - GOVERNING LAW</h2>
          <p>
            These Terms of Service and any separate agreements whereby we
            provide you Services shall be governed by and construed in
            accordance with the laws of Saudi Arabia.
          </p>
          <h2>SECTION 19 - CHANGES TO TERMS OF SERVICE</h2>
          <p>
            You can review the most current version of the Terms of Service at
            any time at this page. <br />We reserve the right, at our sole
            discretion, to update, change or replace any part of these Terms of
            Service by posting updates and changes to our website. It is your
            responsibility to check our website periodically for changes. Your
            continued use of or access to our website or the Service following
            the posting of any changes to these Terms of Service constitutes
            acceptance of those changes.
          </p>
          <h2>SECTION 20 - CONTACT INFORMATION</h2>
          <p>
            Questions about the Terms of Service should be sent to us at
            info@playhera.com
          </p>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Terms',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  mounted() {
    this.scrollToApp2();
  },
  created() {
    this.scrollToApp2();
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
  methods: {
    scrollToApp2() {
      if (this.$route.hash === '#app2') {
        const intervalId = setInterval(() => {
          const app2Element = this.$refs.appendix2;
          if (app2Element) {
            clearInterval(intervalId);
            app2Element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    },
  },
};
</script>
<style lang="scss">
.terms-container {
  max-width: 1511px;
  width: 80vw !important;
}
.text-center {
  text-align: center !important;
}
.term {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.terms {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .terms-container {
    width: 90vw !important;
  }
}
</style>
