<template>
  <div
    class="d-flex align-items-center justify-content-center match-wrapper-simple--details"
    :data-round-index="roundIndex"
    :data-match-index="matchIndex"
  >
    <Panel
      class="match-card my-0 px-4 py-3 cursor-pointer active"
      :variant="[
        'transparent',
        'stroked',
        isActive ? 'stroked-accent' : 'stroked-secondary',
        'rounded',
        'rounded-medium',
      ]"
      :class="{
        empty: !match.id,
      }"
      v-on="$listeners"
    >
      <Panel
        :key="`${match.id}-info`"
        :variant="['transparent']"
        class="p-0 mb-0 match-bottom-addons d-flex align-items-center justify-content-between"
      >
        <TextLabel v-if="match.battle_id" class="p-0 battle-id-label">
          {{
            $t('_web_tournament_battle_id', '# {match}', {
              match: match.index + 1,
            })
          }}<span class="mx-3">|</span>{{ roundName }}
        </TextLabel>
        <TextLabel :text="$$t('Rank')" class="h-ml-auto h-mr-4" />
        <TextLabel :text="$$t('Kills')" class="mx-2" />
      </Panel>
      <div class="mb-1 d-flex align-items-center">
        <PlayerItem
          class="p-0 flex-grow-1"
          :player="match.players[playerIndex].profile"
          hide-score
          hide-checkin
          :avatar-size="24"
          :player-score="playerOneScore"
          :round-index="roundIndex"
          :match-index="matchIndex"
          :player-index="1"
          :variant="playerOneVariant"
          :is-profile="isSingle"
          :chat-with="match.captain_1"
          :is-ready-for-battle="!match.is_detained || match.player_1_ready"
          :is-empty="match.player_empty_1"
          v-on="{
            userMenuOpen: $listeners.userMenuOpen || $lodash.noop,
          }"
        />
        <div
          class="d-flex flex-column align-items-center justify-content-center mx-3 pt-1"
        >
          <SingleScore :value="getRankText()" borderless class="mb-1" />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center pt-1"
        >
          <SingleScore :value="playerOneScore" borderless class="mb-1" />
        </div>
      </div>
      <div class="d-flex align-items-center mt-2 small-badge">
        <StatusLabel :status="match.status" class="px-2 py-1" />
        <div v-if="!isMatchCancelled" class="started-date mx-3">
          {{ match.date_start | localDate($times.DATETIME_MED) }}
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import { BATTLE_STATUSES } from '@/constants/battles';
// import { getPlayerScore } from '@/views/tournaments/utils/players';
import PlayerItem from '@/components/tournaments/playerItem.vue';
import { prop, propRequired } from '@/utils/factories';
import StatusLabel from '@/components/molecules/StatusLabel';
import TextLabel from '@/components/atoms/TextLabel';
import SingleScore from '@/components/tournaments/SingleScore.vue';
import { translate } from '@/mixins/translate';
export default {
  name: 'BRMatchCard',
  components: {
    Panel,
    PlayerItem,
    StatusLabel,
    TextLabel,
    SingleScore,
  },
  mixins: [translate('_web_account_battle_info_card')],
  props: {
    isSingle: prop(Boolean),
    isActive: prop(Boolean),
    roundIndex: propRequired(Number),
    roundName: prop(String),
    matchIndex: propRequired(Number),
    playerIndex: propRequired(Number, 0),
    match: {
      type: Object,
      default: () => ({
        date_end: null,
        date_start: null,
        id: '',
        index: 0,
        next_match_id: '',
        next_match_index: 0,
        player_1: {},
        player_1_score: null,
        player_2: {},
        player_2_score: null,
        round: 0,
        isLoading: false,
      }),
    },
  },
  computed: {
    matchID() {
      return this.match.id;
    },
    summaryScores() {
      return {
        player_1_scored: this.playerOneScore,
      };
    },
    playerOneScore() {
      return this.match.players[this.playerIndex].score.kills;
    },

    isMatchCancelled() {
      return this.match.status === BATTLE_STATUSES.cancelled;
    },
    playerOneVariant() {
      return this.match.is_detained && !this.match.player_1_ready
        ? 'error'
        : 'transparent';
    },
    isScheduled() {
      return this.match.status === 'scheduled';
    },
  },
  methods: {
    getRankText() {
      const player = this.match.players
        ? this.match.players[this.playerIndex]
        : this.match[`player_${this.playerIndex + 1}`];
      if (player) {
        const rank = this.$lodash.get(player, 'score.rank', null);
        return this.$lodash.isNumber(rank) ? rank : '-';
      }
    },
  },
};
</script>

<style lang="scss">
.myMatches {
  .match-wrapper-simple--details {
    max-width: 294px;
    .match-card {
      width: 294px;
    }
  }
}

.match-wrapper-simple--details {
  min-height: $match-item-height;
  max-width: 294px;
  transition: all 0.2s ease-in;
  .match-card {
    z-index: 1;
    width: 294px;
    &_duel-score {
      min-width: 108px;
      flex-basis: 108px;
      height: 40px;
    }
    .player-wrapper--vs-mode {
      flex-basis: calc(50% - 54px);
      max-width: calc(50% - 54px);
    }
    .battle-id-label {
      @include auto-rtl(left, 0);
    }
    .small-badge {
      .started-date {
        color: var(--ph-secondary-text);
      }
      .badge-hera {
        font-size: 0.5rem;
        line-height: 1rem;
        min-width: 60px;
        border-radius: 8px;
      }
    }
  }
}
</style>
