<template>
  <MainLayout>
    <div class="privacy-policy-europe">
      <div class="privacypolicy-container" dir="ltr">
        <h1><b>Playhera Privacy Policy</b></h1>
        <h2><b>Summary of how we use your data</b></h2>
        <ul class="disc">
          <li>
            Entertainment Content Company (also referred to as
            <b>"Playhera"</b>, <b>"we"</b> or <b>"us"</b>) uses your personal
            data to operate the 'Playhera' platform (currently available as an
            iOS or Android application or at [www.playhera.com], the
            <b>"Platform"</b>).
          </li>
          <li>
            Where you play on the Platform, we will particularly use your data
            to allow your registration, to operate and moderate the Platform’
            social forums, to administer your involvement in competitions
            organised on the Platform, to promote and retain records of
            competitions managed on the Platform, to notify you of upcoming
            competitions or events you have registered for and to manage any
            disputes between players or other communications or complaints. We
            use SMS verification, through a company called Unifonic, as part of
            our registration process.
          </li>
          <li>
            Your data will be shared with third parties when you use the
            Platform. In particular, your information will be shared with others
            you interact with on our social forums, or those you choose to play
            against. Where you join competitions or events, your information
            will be shared with the organisers of those competitions or events.
            This may allow those organisers to contact you or send you
            notifications or updates. Information may also be shared with the
            police or other authorities where there is evidence of criminal
            conduct or where required by civil proceedings.
          </li>
          <li>
            Where we rely on your consent, such as for direct marketing or
            sharing your location for our location based services, you can
            withdraw this consent at any time.
          </li>
          <li>
            Playhera is a global platform. Your information may be transferred
            across the world in order to allow you to participate in our forums,
            events and competitions. Playhera takes appropriate steps to protect
            your data.
          </li>
          <li>
            We take appropriate safeguards to protect your personal data when it
            is transferred to countries outside the European Economic Area
            (EEA). Such transfers may occur to ensure the proper functioning of
            our services, including the global nature of the Playhera platform.
            These safeguards may include the use of Standard Contractual Clauses
            approved by the European Commission or other adequate measures as
            required by applicable data protection laws. These measures ensure
            that your personal data is afforded a level of protection that is
            consistent with the standards set forth in the General Data
            Protection Regulation (GDPR). If you would like more information
            about the specific safeguards employed or have any questions
            regarding the transfer of your personal data, please contact us
            using the details provided in this Privacy Policy.
          </li>
          <li>
            Our privacy policy sets out more details of this processing,
            including details of your data protection rights, including your
            right to object to certain processing.
          </li>
        </ul>
        <h2><b>What does this policy cover?</b></h2>
        <p>
          This policy describes how Playhera will make use of your data when you
          use the Platform and, if you are a Participant, how we will make use
          of your data when you participate in a competition.
        </p>
        <p>
          It also describes your data protection rights, including a right to
          object to some of the processing which Playhera carries out. More
          information about your rights, and how to exercise them, is set out in
          the "What rights do I have?" section.
        </p>
        <h2>What information do we collect?</h2>
        <p>
          We collect and process personal data about you when you download and
          first use our applications, when you register on the Platform, when
          you use our social forums or when you submit information to use about
          your esports activities. This will typically be provided directly by
          you, and may include information you provide on registration such as
          your name, usernames, phone number, email address and marketing
          preferences. The details being provided by you will be made clear in
          forms you complete or will be provided directly by you for example in
          social content or results of esports fixtures.
        </p>
        <h2>What information do we receive from third parties?</h2>
        <p>
          Sometimes, we receive information about you from third parties. We
          may, for example, receive information from other players where you
          have competed in a fixture, event or competition. We may also receive
          information about you when others interact or communicate with you
          through our social forums, or make complaints about your conduct.
        </p>
        <p>
          We also receive information about you through our use of cookies, as
          described in our “Cookies and similar technologies” sections below. We
          make use of Google’s Analytics cookie in particular.
        </p>
        <p>
          Please note that Playhera does not knowingly collect or process any
          information from any child under the age of 13 without explicit
          permission from their parent or legal guardian.
        </p>
        <h2>
          How do we use this information, and what is the legal basis for this
          use?
        </h2>
        <p>We process this personal data for the following purposes:</p>
        <ul class="disc">
          <li>
            As necessary to administer and perform any contract between us, in
            particular:
          </li>
          <li style="list-style-type:none">
            <ul>
              <li>
                administering and managing any competitions run by Playhera; and
              </li>
              <li>
                determining eligibility and winners and delivery of prizes in
                competitions run by Playhera;
              </li>
            </ul>
          </li>
          <li>
            As required by Playhera to conduct our business and pursue our
            legitimate interests, in particular:
          </li>
          <li style="list-style-type:none">
            <ul>
              <li>
                we will use your information to provide and administer the
                Platform, including moderation of our social forums, and respond
                to any comments or complaints you may send us;
              </li>
              <li>
                we monitor use of our websites and online services, and use your
                information to help us monitor, improve and protect our
                products, content, services and websites, both online and
                offline;
              </li>
              <li>
                we use information you provide to personalise our website,
                products or services for you;
              </li>
              <li>
                we monitor our player accounts to prevent, investigate and/or
                report fraud, terrorism, misrepresentation, security incidents
                or illegal conduct, in accordance with applicable law;
              </li>
              <li>
                we use information you provide to investigate any complaints
                received from you or from others, about the Platform or about
                the conduct of players on the Platform (including you);
              </li>
              <li>
                we will use data in connection with legal claims, compliance,
                regulatory and investigative purposes as necessary (including
                disclosure of such information in connection with legal process
                or litigation);
              </li>
              <li>
                we use data of some individuals to invite them to take part in
                market research; and
              </li>
              <li>
                using scoreboard, prize and other player information to promote
                the Platform.
              </li>
            </ul>
          </li>
          <li>Where you give us consent:</li>
          <li style="list-style-type:none">
            <ul>
              <li>
                we will send you direct marketing or notifications related to
                the Platform, or will allow competition or event organisers to
                send you notification or marketing about their events;
              </li>
              <li>
                we place cookies and use similar technologies, in accordance
                with our section on Cookies and similar technologies below; and
              </li>
              <li>
                on other occasions where we ask you for consent, we will use the
                data for the purpose which we explain at that time.
              </li>
            </ul>
          </li>
          <li>For purposes which are required by law:</li>
          <li style="list-style-type:none">
            <ul>
              <li>
                where we need parental consent to provide online services to
                children under 16.
              </li>
              <li>
                in response to requests by government or law enforcement
                authorities conducting an investigation; and
              </li>
              <li>maintaining any records as required by law.</li>
            </ul>
          </li>
        </ul>
        <h2>Withdrawing consent or otherwise objecting to direct marketing</h2>
        <p>
          Wherever we rely on your consent, you will always be able to withdraw
          that consent, although we may have other legal grounds for processing
          your data for other purposes, such as those set out above. You have an
          absolute right to opt-out of direct marketing, or profiling we carry
          out for direct marketing, at any time. You can do this by following
          the instructions in the communication where this is an electronic
          message, or by contacting us using the details set out below.
        </p>
        <h2>Cookies and similar technologies</h2>
        <p>
          Cookies are small pieces of information sent by a web server to a web
          browser which allows the server to uniquely identify the browser on
          each page. Other tracking technologies are also used which are similar
          to cookies. This can include pixel tags and tracking URLs. All these
          technologies are together referred to in this Policy as
          <strong>“cookies”</strong>.
        </p>
        <p>
          The types of cookies that we may use on our website, and the purposes
          for which they are used, are set out below:
        </p>
        <ul>
          <li>
            <strong>Strictly necessary cookies</strong>: These cookies are
            essential in order to enable you to move around our website and use
            its features, such as accessing secure areas of our website. Without
            these cookies, any services on our Platform you wish to access
            cannot be provided.
          </li>
          <li>
            <strong>Analytical/performance cookies</strong>: These cookies
            collect information about how you and other visitors use our
            Platform, for instance which pages you go to most often, and if you
            get error messages from web pages. We use data from these cookies to
            help test designs and to ensure a consistent look and feel is
            maintained on your visit to the Platform. All information these
            cookies collect is aggregated. It is only used to improve how a
            Platform works. We use Google Analytics, for example, to anonymously
            track website usage and activity.
          </li>
          <li>
            <strong>Functionality cookies</strong>: These cookies allow our
            website to remember choices you make (such as your user name,
            language or the region you are in) and provide enhanced, more
            personal features. These cookies can also be used to remember
            changes you have made to text size, fonts and other parts of web
            pages that you can customise. The information these cookies collect
            may be anonymised and they cannot track your browsing activity on
            other websites.
          </li>
          <li>
            <strong>Targeting cookies</strong>: These cookies are used to
            deliver adverts more relevant to you and your interests. We, for
            example, make use of the cookie Doubleclick. These cookies are also
            used to limit the number of times you see an advertisement as well
            as help measure the effectiveness of the advertising campaign. They
            are usually placed by advertising networks with the website
            operator’s permission. They remember that you have visited a website
            and this information is shared with other organisations such as
            advertisers. Quite often targeting or advertising cookies will be
            linked to site functionality provided by the other organisation.
          </li>
          <li>
            <strong>Social media cookies</strong>: These cookies allow you to
            share what you’ve been doing on our website on social media such as
            Facebook and Twitter. These cookies are not within our control.
            Please refer to the respective privacy policies for how their
            cookies work.
          </li>
          <li>
            <strong>Pixel tags</strong>: Also known as a clear GIF or web
            beacon. These are invisible tags placed on certain pages of our
            website but not on your computer. When you access these pages, pixel
            tags generate a generic notice of that visit. They usually work in
            conjunction with cookies, registering when a particular device
            visits a particular page. They are used to, among other things,
            track the actions of users of our services (including email
            recipients), measure the success of our marketing campaigns and
            compile statistics about usage of our services and response rates.
            If you turn off cookies, the pixel tag will simply detect an
            anonymous website visit.
          </li>
        </ul>
        <p>
          If you do not want to allow cookies at all, or only want to allow use
          of certain cookies, please refer to your browser settings. You can
          also use your browser settings to withdraw your consent to our use of
          cookies at any time and delete cookies that have already been set.
          Please note that by deleting our cookies or disabling future cookies
          you may not be able to access certain areas or features of our
          website.
        </p>
        <p>
          To find out more about cookies please visit:
          <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>
          or see
          <a href="http://www.youronlinechoices.eu">www.youronlinechoices.eu</a>
          which contains further information about behavioural advertising and
          online privacy.
        </p>
        <h2>How is data shared, where and when?</h2>
        <p>
          We will share your data with competition and event organisers where
          you choose to participate in their competitions and events. This will
          allow them to manage your participation, to communicate with you
          within the Platform and (where you have consented to notifications) to
          send you notifications and reminders. We also give access to your data
          to E-sports federations and event sponsors where they are associated
          with a competition or event you have entered so that they can carry
          out a market analysis and, where you have given your consent, send you
          associated marketing. Esports federations are also given wider access
          to information about users in their territory so that they can better
          understand their players.
        </p>
        <p>
          Personal data may be shared with government authorities and/or law
          enforcement officials if required for the purposes above, if mandated
          by law or if required for the legal protection of our legitimate
          interests in compliance with applicable laws.
        </p>
        <p>
          Personal data may be shared with market research organisations so that
          they can assist us in better understanding our players and their
          interests.
        </p>
        <p>
          Personal data will also be shared with third party service providers,
          who will process it on behalf of Playhera for the purposes identified
          above. In particular, we use third party providers of application
          development, mail campaign management service providers, website
          hosting, SMS verification services and customer support solutions.
        </p>
        <p>
          In the event that the business is sold or integrated with another
          business, your details will be disclosed to our advisers and any
          prospective purchaser’s adviser and will be passed to the new owners
          of the business.
        </p>
        <h2>What rights do I have?</h2>
        <p>
          As a data subject, you have certain rights regarding your personal
          data. You have the right to request access to the personal data we
          hold about you, and you may ask for a copy of this information. If any
          of the personal data we hold is inaccurate or incomplete, you have the
          right to request its correction or update. In certain circumstances,
          you can ask us to delete your personal data, object to its processing,
          or request the restriction of processing. Additionally, you have the
          right to receive your personal data in a structured, commonly used,
          and machine-readable format, and to transmit it to another controller.
          If you wish to exercise any of these rights or have any concerns
          regarding the processing of your personal data, please contact us
          using the contact details provided in this Privacy Policy. Depending
          on your country, you may have a number of different rights. In some
          countries, you have the right to
          <strong>ask us for a copy</strong> of your personal data;
          <strong>to correct, delete</strong> or <strong>restrict</strong> (stop
          any active) processing of your personal data; and to
          <strong
            >obtain the personal data you provide to us for a contract or with
            your consent in a structured, machine readable format, and to ask us
            to share (port) this data to another controller.</strong
          >
        </p>
        <p>
          In addition, you may also be able to
          <strong>object to the processing</strong> of your personal data in
          some circumstances (in particular, where we don’t have to process the
          data to meet a contractual or other legal requirement, or where we are
          using the data for direct marketing).
        </p>
        <p>
          These <strong>rights may be limited</strong>, for example if
          fulfilling your request would reveal personal data about another
          person, where they would infringe the rights of a third party
          (including our rights) or if you ask us to delete information which we
          are required by law to keep or have compelling legitimate interests in
          keeping. Relevant exemptions are included in local laws. We will
          inform you of relevant exemptions we rely upon when responding to any
          request you make.
        </p>
        <p>
          To exercise any of these rights, you can get in touch with us using
          the details set out below. If you have unresolved concerns, you have
          the
          <strong>right to complain</strong> to a competent authority. This may
          be a data protection authority where you live, work or where you
          believe a breach may have occurred. We will always inform you where
          information you provide is mandatory. Information that is provided in
          order to complete our registration processes, including SMS
          verification, are mandatory, as are accurate result of fixtures
          organised on the platform. Failure to provide this information will
          mean you are unable to register or that you are in breach of our Terms
          of Use.
        </p>
        <h2>How do I get in touch with you?</h2>
        <p>
          We hope that we can satisfy queries you may have about the way we
          process your data. If you have any concerns about how we process your
          data, or would like to opt out of direct marketing, you can get in
          touch at
          <a href="mailto:servicedesk@playhera.com">servicedesk@playhera.com</a>
          or by writing to address:
        </p>
        <address>
          3671 Thumama rd. Al Rabie District - Unit 8 <br />
          Riyadh 13316 - 8536 <br />
          Saudi Arabia <br />
          Company Name: Entertainment Content Co <br />
        </address>

        <p></p>
        <h2>How long will you retain my data?</h2>
        <p>
          We store information provided by you on the Platform until it is no
          longer necessary to allow you to access our services or until your
          account is deleted – whichever comes first. Where we process personal
          data for marketing purposes or with your consent, we process the data
          until you ask us to stop and for a short period after this (to allow
          us to implement your requests). We also keep a record of the fact that
          you have asked us not to send you direct marketing or to process your
          data indefinitely so that we can respect your request in future.
        </p>
        <p>
          Information about Playhera results (such as who has scored points and
          when) and still images of gameplay submitted by user will be retained
          indefinitely to keep a record of the competitions played on the
          Platform and ensure that results are verifiable.
        </p>

        <h2>GDPR and DPO</h2>

        We have appointed mr Dinko Bunčić as our Data Protection Officer (DPO).
        You can contact our DPO or the Data Protection Department using the
        following details:

        <ul>
          <li>Name: Dinko Bunčić</li>
          <li>Position: Data Protection Officer</li>
          <li>Email: <a href="mailto:dpo@playhera.com">dpo@playhera.com</a></li>
        </ul>

        The role of our DPO includes ensuring compliance with the GDPR, handling
        data protection matters, and acting as a point of contact for any
        concerns or inquiries related to data privacy and security. Please feel
        free to reach out to our DPO if you have any questions or require
        assistance regarding the processing of your personal data.
      </div>
    </div>
  </MainLayout>
</template>

<script>
import staticFields from '@/mixins/staticFields';
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  name: 'Privacypolicy',
  components: {
    MainLayout,
  },
  mixins: [...staticFields],
  data() {
    return {
      myAccountFetched: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
    ...mapState('my', ['account']),
  },
  created() {
    if (!this.account) {
      this.fetchMyAccount().then(() => {
        this.myAccountFetched = true;
      });
    } else {
      this.myAccountFetched = true;
    }
  },
};
</script>
<style lang="scss">
.privacypolicy-container {
  max-width: 1511px;
  width: 80vw !important;
}
.privacy-policy-europe {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .privacypolicy-container {
    width: 90vw !important;
  }
}
</style>
