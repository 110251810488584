import { render, staticRenderFns } from "./CollapsiblePanel.vue?vue&type=template&id=ddb82746&"
import script from "./CollapsiblePanel.vue?vue&type=script&lang=js&"
export * from "./CollapsiblePanel.vue?vue&type=script&lang=js&"
import style0 from "./CollapsiblePanel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CollapsiblePanel.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2F&name=CollapsiblePanel&notes=%23%23%20this%20component%20has%20a%20slot%20which%20could%20hold%20any%20other%20component%20inside%20it&knobs=%7B%0A%20%20%20%20label%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Label%20text'%2C%20'Click%20me')%0A%20%20%20%20%7D%2C%0A%20%20%20%20visible%3A%20%7B%0A%20%20%20%20%20%20default%3A%20boolean('Visibility'%2C%20false)%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20background%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('Variant%7Cbackground-type'%2C%20%5B'transparent'%2C%0A%20%20%20%20%20%20%20%20'dark-bg'%2C%0A%20%20%20%20%20%20%20%20'default'%2C%0A%20%20%20%20%20%20%20%20'input-bg'%2C%0A%20%20%20%20%20%20%20%20'white'%2C%0A%20%20%20%20%20%20%20%20'secondary'%2C%0A%20%20%20%20%20%20%20%20'accent'%2C%0A%20%20%20%20%20%20%20%20'error'%2C%0A%20%20%20%20%20%20%20%20'green'%2C%0A%20%20%20%20%20%20%20%20%5D%2C%20'default')%0A%20%20%20%20%7D%2C%0A%20%20%20%20strokes%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('Variant%7Cstrokes-type'%2C%20%5B''%2C%0A%20%20%20%20%20%20%20%20'stroked'%2C%0A%20%20%20%20%20%20%20%20'stroked-dark'%2C%0A%20%20%20%20%20%20%20%20'stroked-input-bg'%2C%0A%20%20%20%20%20%20%20%20'stroked-white'%2C%0A%20%20%20%20%20%20%20%20'stroked-secondary'%2C%0A%20%20%20%20%20%20%20%20'stroked-accent'%2C%0A%20%20%20%20%20%20%20%20'stroked-error'%2C%0A%20%20%20%20%20%20%20%20'stroked-green'%2C%0A%20%20%20%20%20%20%20%20%5D%2C%20'')%0A%20%20%20%20%7D%2C%0A%20%20%20%20rounded%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('Variant%7Cborder-radius-type'%2C%20%5B%0A%20%20%20%20%20%20%20%20'rounded'%2C%0A%20%20%20%20%20%20%20%20'rounded-small'%2C%0A%20%20%20%20%20%20%20%20'rounded-large'%2C%0A%20%20%20%20%20%20%20%20'rounded-full'%5D%2C%20'rounded')%0A%20%20%20%20%7D%2C%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports