<template>
  <Wrapper
    id="social-links-edit"
    :header="$t('_web_profile-edit_social-links', 'Social Links')"
    :join="join"
    ><div class="row">
      <SocialsGroup
        v-model="accountSocialLinks"
        class="col-12 col-lg-6"
        v-bind="{ columns: 0, items: leftItems }"
      />
      <SocialsGroup
        v-model="accountSocialLinks"
        class="col-12 col-lg-6"
        v-bind="{ columns: 0, items: rightItems }"
      />
    </div>
    <div v-if="account && account.isOrganization" class="mt-4">
      {{
        $t(
          '_web_profile-edit_social-accounts-info',
          'To automatically share posts to your Social media, please'
        )
      }}&nbsp;
      <a href="#" @click.prevent.stop="openSocialIntegrationModal">{{
        $t('_web_profile-edit_social-accounts-link', 'Link social accounts')
      }}</a>
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import Wrapper from '../../PanelWrapper';
import SocialsGroup from '@/components/form/SocialsGroup';
import { prop } from '@/utils/factories';

export default {
  name: 'AccountEditSocialLinks',
  components: {
    SocialsGroup,
    Wrapper,
  },
  props: {
    join: prop(String),
    columns: prop(Number, 1),
  },
  data() {
    return {
      leftItems: [
        {
          id: 'youtube',
          title: 'YouTube',
          fixedPlaceholder: 'youtube.com/',
          inputLeftPadding: '138px',
        },
        {
          id: 'twitter',
          title: 'Twitter',
          fixedPlaceholder: 'twitter.com/',
          inputLeftPadding: '127px',
        },
        {
          id: 'twitch',
          title: 'Twitch',
          fixedPlaceholder: 'twitch.tv/',
          inputLeftPadding: '109px',
        },
      ],
      rightItems: [
        {
          id: 'instagram',
          title: 'Instagram',
          fixedPlaceholder: 'instagram.com/',
          inputLeftPadding: '150px',
        },
        {
          id: 'fb',
          title: 'Facebook',
          fixedPlaceholder: 'facebook.com/',
          inputLeftPadding: '146px',
        },
        {
          id: 'discord',
          title: 'Discord',
          fixedPlaceholder: 'discordapp.com/',
          inputLeftPadding: '158px',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['accountEditData']),
    ...mapState('my', ['account']),
    accountSocialLinks: {
      get() {
        return this.accountEditData.social || {};
      },
      set(social) {
        this.updateEditData({ social });
      },
    },
  },
  methods: {
    ...mapMutations(['updateEditData']),
    ...mapActions(['openModal']),
    openSocialIntegrationModal() {
      this.openModal({
        name: 'SocialIntegrationModal',
        settings: {
          containerClass: 'p-0 overflow-hidden',
          variant: 'fit-content',
          closeIcon: true,
          clickOnBackdrop: true,
        },
      });
    },
  },
};
</script>
<style lang="scss">
#social-links-edit {
  .social-item {
    .fixed-placeholder {
      @include auto-rtl(left, 48px);
    }
  }
}
</style>
