<template>
  <InfoTooltip class="tool-icon-wrapper" :show-icon="false" :text="helpText">
    <Panel
      class="cursor-pointer"
      :class="[
        hasPaddings ? 'p-3' : 'p-0',
        {
          disabled,
        },
      ]"
      :variant="displayVariant"
      v-on="$listeners"
    >
      <Icon
        :name="iconName"
        :width="iconWidth"
        :fill="iconFill"
        :rotate="iconRotate"
      />
    </Panel>
  </InfoTooltip>
</template>

<script>
import Panel from '@/components/atoms/Panel';
import Icon from '@/components/atoms/Icon';
import InfoTooltip from '@/components/molecules/InfoTooltip';
import { prop } from '@/utils/factories';

const TOOL_ICON_VARIANTS = {
  default: {
    displayVariant: ['transparent', 'rounded', 'rounded-medium'],
  },
  panel: {
    displayVariant: [
      'transparent',
      'stroked',
      'stroked-secondary',
      'rounded',
      'rounded-medium',
    ],
    iconWidth: 16,
  },
  circle: {
    displayVariant: ['white', 'rounded', 'rounded-full'],
  },
};

export default {
  name: 'ToolIcon',
  components: {
    Icon,
    Panel,
    InfoTooltip,
  },
  props: {
    helpText: prop(String),
    iconName: prop(String),
    iconSize: prop(String),
    iconColor: prop(String),
    iconRotate: prop(Number, 0),
    variant: prop(String, 'default', val =>
      Object.keys(TOOL_ICON_VARIANTS).includes(val)
    ),
    isActive: prop(Boolean),
    disabled: prop(Boolean),
  },
  computed: {
    displayVariant() {
      return !this.isActive
        ? TOOL_ICON_VARIANTS[this.variant].displayVariant
        : [...TOOL_ICON_VARIANTS[this.variant].displayVariant, 'accent'];
    },
    iconWidth() {
      return this.iconSize || TOOL_ICON_VARIANTS[this.variant].iconWidth;
    },
    iconFill() {
      return (
        this.iconColor ||
        (this.isActive
          ? this.$scss.colors.primaryTextBg
          : this.displayVariant.includes('transparent')
          ? this.$scss.colors.primaryTextBg
          : this.$scss.colors.darkBg)
      );
    },
    hasPaddings() {
      return this.variant !== 'default';
    },
  },
};
</script>

<style lang="scss"></style>
