<template>
  <div class="layout-wrap">
    <div class="placeholder-for-bread">
      <div v-if="currentStep === 1" class="bread-crums">
        <div class="single-crum-wrap ">
          <div class="icon-active-bread"></div>
          <label class="crum-active">{{
            $t('_web_profile_setup_step-one', 'Step 1')
          }}</label>
        </div>
        <div class="single-crum-wrap ">
          <div class="step-inactive"></div>
          <label class="crum-inactive">{{
            $t('_web_profile_setup_step-two', 'Step 2')
          }}</label>
        </div>
      </div>
      <div v-else class="bread-crums">
        <div class="single-crum-wrap ">
          <div class="step-inactive"></div>
          <label class="crum-inactive">{{
            $t('_web_profile_setup_step-one', 'Step 1')
          }}</label>
        </div>
        <div class="single-crum-wrap ">
          <div class="icon-active-bread"></div>
          <label class="crum-active">{{
            $t('_web_profile_setup_step-two', 'Step 2')
          }}</label>
        </div>
      </div>
    </div>
    <div class="profile-setup-wrapper">
      <div class="title-container-with-btn">
        <div class="current-step-title-cro">
          {{ currentStepTitle }}
          <h4 class="current-subTitle">{{ currentStepSubTitle }}</h4>
        </div>
        <!-- <BackBtn
        v-if="currentStep > 1"
        class="back-btn"
        history-mode
        hide-icon
      /> -->
        <div class="btn-container">
          <Btn
            class="btn-next-cro"
            :class="currentStep === 2 && 'btn-complete-cro'"
            :loading="isLoading"
            @click.prevent="next(false)"
          >
            <template slot="prepend">
              {{ nextBtnLabel }}
            </template>
          </Btn>
        </div>
      </div>
    </div>
    <!-- <BreadcrumbAlt
      class="mb-4"
      :items="$options.breadcrumItems"
      :current-step="currentStep"
      :type="'arrow'"
      @onclick="changeStep"
    /> -->
    <component
      :is="currentStepComponent"
      class="profile-setup-content mt-5"
      @skip="next"
    />
    <div
      v-if="currentStep !== $options.breadcrumItems.length"
      class="d-flex justify-content-center p-3"
    >
      <!-- <Btn
        class="btn-primary"
        :loading="isLoading"
        @click.prevent="next(false)"
      >
        <template slot="prepend">
          {{ $$t('Next') }}
        </template>
      </Btn> -->
    </div>
    <CreatedPopup
      v-bind="{
        account,
        isVisible: createdPopupIsVisible,
        redirectTo: $config.defaultRoute,
        subTitle,
      }"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import isLoading from '@/mixins/isLoading';
// import BreadcrumbAlt from '@/components/common/BreadcrumbAlt';
import HeraLogo from '@/components/atoms/HeraLogo';
import Btn from '@/components/atoms/HeraFormBtn';
import CreatedPopup from '@/components/popup/CreatedPopup';
import BackBtn from '@/components/common/GoBackBtn';
import Title from '@/components/atoms/Title';

export default {
  name: 'ProfileSetup',
  components: {
    CreatedPopup,
    Title,
    BackBtn,
    Btn,
    HeraLogo,
    // BreadcrumbAlt,
  },
  mixins: [isLoading, translate('_web_profile-setup')],

  inject: ['$validator'],
  data() {
    return {
      createdPopupIsVisible: false,
      subTitle: '',
    };
  },
  computed: {
    ...mapState('my', ['account']),
    ...mapGetters(['templateRegData']),
    currentStep() {
      return +this.$lodash.get(this.$route, 'params.step') || 1;
    },
    currentStepComponent() {
      return this.createImport(this.currentStep);
    },
    currentStepSubTitle() {
      switch (this.currentStep) {
        case 1:
          return this.$t(
            '_web_profile_setup_first-step-sub',
            'Few more details to complete your profile and get you going! '
          );
        case 2:
          return this.$t(
            '_web_profile_setup_second-step-sub',
            'We will use your selection as your preference and will keep you up to date with related competition. You can update your selection in your profile settings later on.'
          );
        default:
          return '';
      }
    },
    currentStepTitle() {
      switch (this.currentStep) {
        case 1:
          return this.$t(
            '_web_profile_setup_first-step-title',
            'Welcome to PlayHera'
          );
        case 2:
          return this.$t(
            '_web_profile_setup_second-step-title',
            'Now select your favourite games'
          );
        // case 3:
        //   return this.$$t('Setup your phone number', 'phone_header');
        default:
          return '';
      }
    },
    nextBtnLabel() {
      return this.currentStep !== this.$options.breadcrumItems.length
        ? `${this.$$t('Next')}`
        : this.$t('_web_battles-finish', 'Finish');
    },
  },
  created() {
    this.fetchAccount(this.account.params).then(this.createEditModel);
    this.$options.breadcrumItems = [
      {
        icon: 'profile',
        label: this.$$t('Profile Info'),
      },
      {
        icon: 'swords',
        label: this.$$t('Games'),
      },
      // {
      //   icon: 'phone',
      //   label: this.$$t('Phone Number'),
      // },
    ];
  },
  methods: {
    ...mapActions(['fetchAccount', 'errorNotify', 'saveAccountChanges']),
    ...mapMutations(['createEditModel', 'clearEditData', 'updateAccountKey']),

    createImport(currentStep) {
      return () =>
        import(/* webpackChunkName: "setupProfile-step-[request]" */ `./steps/${currentStep}`).catch(
          err => {
            if (currentStep !== 1) {
              this.$router.replace({ params: { step: 1 } });
            }
            return err; // don't remove it (used in __esModule)
          }
        );
    },
    next(isSkip = false) {
      this.setLoading();
      this.$validator
        .validateAll()
        .then(res =>
          res || isSkip
            ? Promise.resolve(res)
            : Promise.reject(this.$$t('Form validation failed'))
        )
        .then(() => {
          if (!isSkip) {
            return this.saveAccountChanges();
          }

          return null;
        })
        .then(() => {
          this.clearEditData(true);
          if (this.currentStep === this.$options.breadcrumItems.length) {
            this.subTitle = this.$$t(
              'Your profile {username} has been created.',
              'created-text',
              { username: this.account._name }
            );
            this.createdPopupIsVisible = true;
            return;
          }

          this.updateAccountKey();
          return this.$router.push({
            params: { step: this.currentStep + 1 },
          });
        })
        .catch(err => {
          this.errorNotify(err);
        })
        .finally(this.unsetLoading);
    },
    back() {
      this.changeStep(this.currentStep - 1);
    },
    changeStep(index) {
      if (this.currentStep <= index) {
        return;
      }
      return this.$router.push({
        params: { step: index + 1 },
      });
    },
  },
};
</script>

<style lang="scss">
#app > .profileSetup {
  display: flex;
  flex-direction: column;
  .main-nav-wrapper,
  .header-fixed {
    display: none !important;
  }
  .main-content-wrapper {
    width: 100% !important;
  }
  footer {
    display: none !important;
  }
  main .content-wrapper > div {
    padding: 0;
  }
}

.profile-setup-wrapper {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0 !important;
  font-family: $hr-font-family;
  display: flex;
  justify-content: center;
}

.current-step-title-cro {
  width: 80%;
  text-align: start;
  display: flex;
  flex-direction: column;
  font-size: $hr-heading-4;
  font-weight: 700;
  line-height: 42px;
}

.current-subTitle {
  font-size: $hr-text-regular;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 65px;
  margin-top: 13px;
}

.title-container-with-btn {
  display: inline-flex !important;
  width: 58rem;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 !important;
  position: relative;
  .btn-next-cro {
    display: inline-block;
    border: 1px solid $new-border-neutral-faded;
    background-color: $new-bg-base;
    border-radius: 100px;
    min-width: 87px;
    height: 36px;
    font-weight: 400;
    &:hover {
      background-color: $new-bg-primary;
      color: white;
    }
  }
  .btn-complete-cro {
    display: inline-block;
    border: 1px solid $new-border-neutral-faded;
    background-color: $new-bg-base;
    border-radius: 100px;
    min-width: 87px;
    height: 36px;
    font-weight: 400;
    &:hover {
      background-color: $new-bg-primary;
      color: white;
    }
  }
}

.placeholder-for-header {
  display: flex;
  width: 100%;
  height: 64px;
}

.placeholder-for-bread {
  padding-top: 80px;
  font-weight: 700;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 92px;
  .bread-crums {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    label {
      margin-bottom: 0px;
    }
    .step-inactive {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: $new-bg-neutral-highlighted;
      border-radius: 50%;
    }
  }
  .icon-active-bread {
    display: inline-flex;
    width: 14px;
    height: 14px;

    background-image: url('~@/assets/images/icons/purple-dot.svg');

    background-position: center;
    background-repeat: no-repeat;
  }
  .single-crum-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    .crum-active {
      color: $new-bg-primary-highlighted !important;
    }
    .crum-inactive {
      color: $new-fg-disabled !important;
    }
  }
}

@media (max-width: 900px) {
  .current-step-title-cro {
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .title-container-with-btn {
    align-items: center !important;
    display: flex !important;
    width: 100%;
    justify-content: center;
  }
  .btn-container {
    position: fixed;
    bottom: 0;
    width: 100% !important;
    z-index: 1000;
  }
  .btn-next-cro {
    font-weight: 700 !important;
    width: 100%;
    border-radius: 0px !important;
    background-color: $hr-text-primary !important;
    color: $new-bg-primary !important;
    text-transform: uppercase;
    height: 48px !important;
  }
  .btn-next-cro:hover {
    background-color: $new-bg-primary !important;
    color: $hr-text-primary !important;
  }
}

@media (max-width: 1100px) {
  .title-container-with-btn {
    width: 100%;
  }
}
</style>
