<template>
  <Panel
    class="player-wrapper d-flex align-items-center"
    v-bind="{
      variant: `${variant}`,
      class: [
        `player-wrapper--${variant}`,
        `player_${playerIndex}`,
        {
          selected: swapIndex === playerIndex,
        },
      ],
      key: `player-item-panel-${playerID}`,
      'data-player': playerIdAttr,
    }"
  >
    <div
      class="d-flex align-items-center player-iswinner"
      :class="{ 'flex-column player-wrapper--player-stacked': isStacked }"
    >
      <AccountLink
        v-if="player"
        :id="playerID"
        :type="playerType"
        :vanity-id="playerVanityId"
      >
        <Avatar
          :size="avatarSize"
          :type="playerType"
          :img-url="playerAvatar"
          :avatar-type="avatarType"
          :background-fill="$scss.colors.darkBg"
        />
      </AccountLink>
      <icon
        v-else-if="canDeletePlayer && $route.meta.showAdminFunctions"
        key="add"
        name="add"
        class="player-add-trigger flex-shrink-0"
        original
        :width="avatarSize"
        :fill="$scss.colors.greenBg"
        :class="{
          disabled: isSwapSource || isSwapTarget,
        }"
        @click.native.stop="onAddPlayer"
      />
      <Avatar
        v-else
        :size="avatarSize"
        :background-fill="$scss.colors.darkBg"
      />
      <div class="spacer pl-3"></div>
      <div class="d-flex flex-column justify-content-center team-name w-100">
        <AccountLink
          v-if="player"
          :id="player.login || player.id"
          :type="playerType"
          :vanity-id="playerVanityId"
          class="d-block text-decoration-none team-name-a"
        >
          <TextBlock
            tag="div"
            variant="primary"
            size="0.75"
            :line-height="1.375"
            class="text-truncate secondary-font winner-player-name"
            >{{ playerName }}</TextBlock
          >
        </AccountLink>
        <TextBlock
          v-else
          tag="div"
          :variant="canDeletePlayer ? 'success' : 'primary'"
          :line-height="1.375"
          class="text-truncate"
          :class="{
            disabled: (isSwapSource || isSwapTarget) && canDeletePlayer,
          }"
          @click.native.stop="onAddPlayer"
          >{{ playerName }}</TextBlock
        >
        <slot />
      </div>
    </div>
    <div
      v-if="!hideCheckin || canSwap || !hideScore"
      class="filler mx-auto"
    ></div>
    <template
      v-if="!hideCheckin && checkedIn && $route.meta.showAdminFunctions"
    >
      <div class="spacer pl-3"></div>
      <InfoTooltip
        key="check-mark"
        class="mx-1 flex-shrink-0"
        :text="
          $t(
            '_web_participant-checked-in-tooltip',
            'Participant was checked-in'
          )
        "
        :icon-props="{
          name: 'check-mark',
          width: 20,
          fill: $scss.colors.greenBg,
        }"
      />
      <div class="spacer pl-3"></div>
    </template>
    <template v-if="canSwap || canDeletePlayer || !hideScore">
      <icon
        v-if="playerID && isPublishRoute && $route.meta.showAdminFunctions"
        key="cancel"
        name="cancel"
        class="player-delete-trigger mx-1 flex-shrink-0"
        :class="{
          disabled: isSwapSource || isSwapTarget || !canDeletePlayer,
        }"
        :width="24"
        :fill="$scss.colors.errorBg"
        @click.native.stop="onDeletePlayer"
      />
      <div class="spacer pl-3"></div>
      <icon
        v-if="canSwap && $route.meta.showAdminFunctions"
        :key="swapIconName"
        :name="swapIconName"
        class="swap-trigger mx-1 flex-shrink-0"
        :width="avatarSize"
        :fill="swapIconFill"
        :class="{
          disabled: !isSwapTarget && !isExactSwapSource && isSwapSource,
        }"
        @click.stop="onSwap"
      />
      <SingleScore
        v-else-if="!hideScore"
        :value="playerScore"
        class="result-score mx-1 flex-shrink-0 secondary-font"
        :class="{ 'h-mr-auto': isStacked }"
      />
    </template>
  </Panel>
</template>
<script>
import Panel from '@/components/atoms/Panel';
import TextBlock from '@/components/atoms/TextBlock';
import Avatar from '@/components/images/Avatar.vue';
import icon from '@/components/atoms/Icon';
import AccountLink from '@/components/links/Account';
import { mapActions, mapMutations } from 'vuex';
import InfoTooltip from '@/components/molecules/InfoTooltip';
import SingleScore from '@/components/tournaments/SingleScore';
import { CONSTRUCTOR_MODES } from '@/constants/tournaments';
export default {
  name: 'PlayerItem',
  components: {
    Panel,
    TextBlock,
    Avatar,
    icon,
    AccountLink,
    InfoTooltip,
    SingleScore,
  },
  props: {
    isProfile: {
      type: Boolean,
      default: false,
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
    isReadyForBattle: {
      type: Boolean,
      default: true,
    },

    variant: {
      type: String,
      default: 'default',
    },

    player: {
      type: Object,
      default: () => ({}),
    },

    playerScore: {
      type: [String, Number],
      default: '',
    },

    canSwap: {
      type: Boolean,
      default: false,
    },

    canDeletePlayer: {
      type: Boolean,
      default: false,
    },

    isSwapTarget: {
      type: Boolean,
      default: false,
    },

    isSwapSource: {
      type: Boolean,
      default: false,
    },

    swapIndex: {
      type: Number,
      default: -1,
    },

    playerIndex: {
      type: Number,
      default: -1,
    },

    roundIndex: {
      type: Number,
      default: -1,
    },

    matchIndex: {
      type: Number,
      default: -1,
    },

    chatWith: {
      type: String,
      default: '',
    },

    isEmpty: {
      type: Boolean,
      default: false,
    },

    hideScore: {
      type: Boolean,
      default: false,
    },
    hideCheckin: {
      type: Boolean,
      default: false,
    },
    avatarSize: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    playerName() {
      return this.player
        ? this.$filters.displayName(this.player)
        : this.canDeletePlayer && this.$route.meta.showAdminFunctions
        ? this.$t('_web_player_item_add_player', 'Add player')
        : this.$t('_web_player_item_empty', '-');
    },

    playerIdAttr() {
      return `${this.roundIndex}-${this.matchIndex}-${this.playerName}`;
    },
    playerID() {
      return this.player && (this.player.login || this.player.id);
    },
    playerVanityId() {
      return this.player && this.player.vanity_id;
    },
    playerType() {
      return this.player ? this.player.type : 'custom';
    },

    playerAvatar() {
      return !this.player ? undefined : this.player.logo || this.player.avatar;
    },

    avatarType() {
      return !this.player
        ? this.isEmpty
          ? 'emptyPlayer'
          : 'unknownPlayer'
        : 'normalPlayer';
    },
    checkedIn() {
      return this.player && this.player.checked_in;
    },
    isSwapSourceNeighbour() {
      return this.isSwapSource && this.swapIndex !== this.playerIndex;
    },
    isExactSwapSource() {
      return this.isSwapSource && this.swapIndex === this.playerIndex;
    },
    swapIconName() {
      return this.isSwapTarget ||
        (this.isSwapSource && this.swapIndex !== this.playerIndex)
        ? 'move-in'
        : this.isSwapSource
        ? 'move-out'
        : 'pick-for-move';
    },
    swapIconFill() {
      return this.isExactSwapSource
        ? this.$scss.colors.accentBg
        : this.isSwapTarget
        ? this.$scss.colors.primaryTextBg
        : this.$scss.colors.secondaryTextBg;
    },
    isAmbassador() {
      return this.player && this.player.is_ambassador;
    },
    isPublishRoute() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.PUBLISH;
    },
  },
  methods: {
    ...mapMutations(['setActivePopoverChat']),
    ...mapActions(['getConversationInfo', 'errorNotify']),

    onSwap() {
      if (this.isExactSwapSource) {
        this.$emit('swapCancel');
      } else {
        this.$emit('swap', this.playerIndex);
      }
    },
    onDeletePlayer(event) {
      this.$emit('deletePlayer', {
        event,
        playerID: this.playerID,
        playerIndex: this.playerIndex,
      });
    },
    onAddPlayer(event) {
      if (this.canDeletePlayer && this.$route.meta.showAdminFunctions) {
        this.$emit('addPlayer', {
          event,
          playerIndex: this.playerIndex,
        });
      }
    },
    openPopoverChat(event, login) {
      if (!event.ctrlKey) {
        this.getConversationInfo({ conversationId: login })
          .then(res => {
            this.setActivePopoverChat(res.id);
          })
          .catch(this.errorNotify);
      }
    },

    onUserMenuOpen(event) {
      const player = { ...this.player };
      if (player.type === 'profile') {
        player.id = player.login;
      }
      this.$emit('userMenuOpen', {
        event,
        player,
      });
    },
  },
};
</script>

<style lang="scss">
.player-wrapper {
  box-sizing: border-box;
  min-height: 30px;
  .swap-cancel {
    display: none;
    cursor: pointer;
  }
  &.flex-column {
    .team-name {
      margin-top: $ph-small-space;
    }
  }
  &.swap-target {
    .swap-trigger {
      display: block;
    }
  }

  &.selected {
    .swap-trigger {
      z-index: 1;
      display: block !important;
    }
    &.swap-target,
    &.swap-source {
      .swap-cancel {
        z-index: 1;
        display: block !important;
      }
    }
  }

  .team-name {
    z-index: 1;
    max-width: 122px;
  }

  .result-score {
    z-index: 1;
    border: none;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  &--default {
    border-color: transparent;
  }
  &--player-stacked {
    flex-basis: 50%;
  }
  &--error {
    border-color: $ph-error;
    .team-name,
    .result-score {
      color: white;
    }

    .start-chat {
      .chat-icon {
        fill: white !important;
      }
    }
  }
  .name {
    display: inline-block;
  }
}
</style>
