const state = {
  isChatOpen: true,
  isAdmin: false,
};

const getters = {
  isChatOpen: state => state.isChatOpen,
  isAdmin: state => state.isAdmin,
};

const mutations = {
  toggleChat(state) {
    state.isChatOpen = !state.isChatOpen;
  },

  toggleAdmin(state) {
    state.isAdmin = !state.isAdmin;
  },
  setAdmin(state) {
    state.isAdmin = true;
  },
};

const actions = {
  toggleChat({ commit }) {
    commit('toggleChat');
  },
  toggleAdmin({ commit }) {
    commit('toggleAdmin');
  },
  setAdmin({ commit }) {
    commit('setAdmin', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
