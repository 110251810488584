<template>
  <div class="svg-container">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 14C27.5 21.4558 21.4558 27.4999 14 27.4999C6.54415 27.4999 0.5 21.4558 0.5 14C0.5 6.54414 6.54415 0.5 14 0.5C21.4558 0.5 27.5 6.54414 27.5 14Z"
        fill="#06080C"
        stroke="#474A56"
      />

      <ellipse :cx="cx" :cy="cy" :rx="rx" :ry="ry" :fill="color"></ellipse>
      <path
        v-if="color !== '#474A56'"
        d="M10.498 14.0003L12.8314 16.3336L17.498
      11.667"
        stroke="black"
      />
    </svg>
  </div>
</template>

<script>
const colorConfig = [
  {
    index: 0,
    color: '#1be3ff',
  },
  {
    index: 1,
    status: 'registration_opened',
    color: '#474A56',
  },
  {
    index: 1,
    color: '#FD9F18',
  },
  {
    index: 2,
    status: [
      'registration_opened',
      'qualification_completed',
      'registration_closed',
    ],
    color: '#474A56',
  },
  {
    index: 2,
    color: '#46DC70',
  },
  {
    index: 3,
    status: 'closed',
    color: '#717171',
  },
  {
    index: 3,
    color: '#474A56',
  },
];

export default {
  name: 'TimelineSvg',
  props: {
    status: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cx: '13.9987',
      cy: '13.9996',
      rx: '8.16667',
      ry: '8.16664',
    };
  },
  computed: {
    color() {
      return colorConfig.find(
        config =>
          config.index === this.index &&
          (config.status === undefined ||
            config.status === this.status ||
            (Array.isArray(config.status) &&
              config.status.includes(this.status)))
      ).color;
    },
  },
};
</script>
