<template>
  <div class="d-flex tournament-card-title mb-2 justify-content-between">
    <AppIcon
      v-if="needInvite"
      name="lock-closed"
      :width="20"
      class="title-app-icon"
    />
    <TextBlock
      tag="h2"
      class="secondary-font mb-0 tournament-title"
      :size="1"
      :text="tournament.title"
      :variant="'card'"
    />
    <TournamentCardPrize :tournament="tournament" />
  </div>
</template>

<script>
import { propRequired } from '@/utils/factories';
import TextBlock from '@/components/atoms/TextBlock';
import AppIcon from '@/components/atoms/Icon';
import TournamentCardPrize from '@/components/common/TournamentCard/components/Prize.vue';

const TOURNAMENT_INVITE_ONLY_REGISTRATION = 'closed';

export default {
  name: 'TournamentCardTitle',
  components: { TextBlock, AppIcon, TournamentCardPrize },
  props: {
    tournament: propRequired(Object),
  },
  computed: {
    text({ tournament: { title } }) {
      return title;
    },
    needInvite() {
      return (
        this.tournament.registrationType ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION ||
        this.tournament.registration_type ===
          TOURNAMENT_INVITE_ONLY_REGISTRATION
      );
    },
  },
};
</script>
<style lang="scss">
.tournament-card-title {
  align-items: center;
}

.title-app-icon {
  @include auto-rtl(margin-right, $hr-spacing-02);
}
</style>
