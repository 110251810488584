var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLoader',{staticClass:"position-relative battle-list-wrapper",attrs:{"is-loading":_vm.isLoading && _vm.isEmpty,"is-empty-list":_vm.isEmpty,"dummy-props":{
    iconName: 'dummy/matches',
    size: 200,
    headerText: _vm.$$t('Your match list is empty.', 'no-results'),
    text: _vm.$$t(
      'There are so many competions await for you',
      'no-results_subheader'
    ),
  }},on:{"reload":_vm.fetchBattles}},[_c('Paginated',_vm._b({staticClass:"m-0 h-auto",attrs:{"slot":"list"},on:{"change":_vm.fetchBattles},slot:"list"},'Paginated',{
      totalItems: _vm.totalItems,
      hidePagination: !_vm.battles || !_vm.battles.length,
      paginationProps: {
        isDark: true,
      },
      inContainer: true,
    },false),[_c('div',{staticClass:"col "},[_c('div',{staticClass:"row"},_vm._l((_vm.eventsInfo),function(event){return _c('div',{key:event.id,staticClass:"col-12 py-3 tournament-container-matches"},[(_vm.type !== _vm.tournamentType)?_c('LinkTournament',_vm._b({staticClass:"d-flex align-items-center link-tournament-width battles-list_tournament-wrapper"},'LinkTournament',{ id: event.id },false),[_c('ListItem',{attrs:{"title":event.name}},[_c('Avatar',{attrs:{"slot":"avatar","img-url":event.logo,"size":36},slot:"avatar"}),_c('SecondaryText',{attrs:{"slot":"subtitle","size":0.675,"text":_vm._f("localDate")(event.tournament_start,_vm.$times.DATE_SHORT)},slot:"subtitle"})],1)],1):_vm._e(),_c('div',{staticClass:"matches-container"},_vm._l((_vm.eventsBattles[event.id]),function(battle){return _c('div',{key:battle.id,staticClass:"matches-hover"},[_c(_vm.getMatchCardComponent(battle),_vm._b({tag:"component",on:{"click":function($event){return _vm.goToBattle(battle.id)}}},'component',Object.assign({}, _vm.getMatchCardProps(battle)),false))],1)}),0)],1)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }