var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"support-wrapper"},[_c('h1',{staticClass:"heading-support-page"},[_vm._v("\n    "+_vm._s(_vm.$t(
        "_web_support_welcome-contact-support",
        'Welcome to Our Support Page'
      ))+"\n  ")]),_c('div',{staticStyle:{"width":"100%"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("_web_support_need-help", 'Need Help?')))]),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(
          "_web_support_need-help-text",
          "Our dedicated support team is here to assist you with any questions, concerns, or issues you may encounter while using our platform. Whether you're experiencing technical difficulties, have inquiries about our services, or need guidance on navigating our portal, we're here to help you every step of the way."
        ))+"\n    ")]),_c('HeraFormBtn',{staticClass:"btn-accent-simple report-form_submit button-cancel width-adjustment-support",staticStyle:{"width":"117px !important"},attrs:{"tabindex":"0","label":_vm.$t("_web_support_reach-us", 'Reach us')},on:{"click":function($event){$event.preventDefault();_vm.showForm = !_vm.showForm}}}),(_vm.showForm)?_c('form',{staticClass:"support-form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('HeraInput',{staticClass:"width-adjustment-support",attrs:{"label":_vm.$t("_web_support_subject", 'Subject :'),"placeholder":_vm.$t("_web_support_subject_enter", 'Enter subject')},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('div',{staticClass:"width-adjustment-support"},[_c('label',{attrs:{"for":"message"}},[_vm._v(_vm._s(_vm.$t("_web_support_message", 'Message: ')))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"input-form width-adjustment-support",attrs:{"id":"message","placeholder":_vm.$t("_web_support_message_enter", 'Enter message'),"rows":"4","required":""},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})]),_c('HeraFormBtn',{staticClass:"btn-accent-simple report-form_submit button-cancel width-adjustment-support",attrs:{"tabindex":"0","label":_vm.$t("_web_support_submit", 'Submit')},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}})],1):_vm._e()],1),_c('div',{staticStyle:{"width":"100%"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("_web_support_feedback-support", 'Feedback')))]),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(
          "_web_support_feedback-support-text",
          "We value your feedback and strive to continuously improve our platform based on your suggestions and experiences. If you have any feedback or suggestions for us, please don't hesitate to let us know. Your input helps us enhance our services and better meet your needs."
        ))+"\n    ")])]),_c('div',{staticStyle:{"width":"100%"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("_web_support_get-in-touch", 'Get in Touch')))]),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(
          "_web_support_get-in-touch-text",
          "Don't hesitate to reach out to us if you need assistance or have any questions. We're here to ensure your experience with our platform is smooth, efficient, and enjoyable."
        ))+"\n    ")]),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(
          "_web_support_get-in-touch-text-goodbay",
          "Thank you for choosing our platform. We appreciate you and look forward to assisting you!"
        ))+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }