<template>
  <div class="img-grid my-4" :class="`img-grid-${media.length} ${childclass}`">
    <div v-for="(item, index) in media" :key="index" class="img-wrapper">
      <ImagePreview
        v-if="item.type === 'image'"
        class="cursor-pointer"
        :childclass="childclass"
        :src="item.image"
        :media-group="media"
      />
      <VideoPreview
        v-else-if="item.type === 'video'"
        class="video-preview cursor-pointer"
        :childclass="childclass"
        :src="item.video"
        :thumbnail="thumbnail"
        :media-group="media"
      />
      <div v-else>Not found</div>
    </div>
  </div>
</template>

<script>
import { emptyArray } from '@/utils/factories';

import VideoPreview from '@/components/atoms/VideoPreview';
import ImagePreview from '@/components/common/ImagePreview';

export default {
  name: 'MediaGrid',

  components: {
    VideoPreview,
    ImagePreview,
  },

  props: {
    childclass: {
      type: String,
      default: '',
    },
    media: {
      type: Array,
      default: emptyArray,
    },
    thumbnail: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.img-grid,
.img-grid-1 {
  width: 100%;
  height: 200px;

  .img-wrapper {
    width: 100%;
    height: 100%;

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: $ph-small-radius;
    }

    .video-preview {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      .btn-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      .video-duration {
        font-size: 0.875rem;
        color: #b0b0b6;
        background: #000;
        height: 1.25rem;
        line-height: 1.25rem;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 0.25rem;
      }
    }
  }
}

.img-grid-2 {
  display: flex;
  .img-wrapper {
    width: 50%;
    height: 100%;
    &:nth-child(2) {
      padding-left: $ph-small-space;
    }
  }
}

.img-grid-3 {
  display: flex;
  .img-wrapper {
    &:nth-child(1) {
      width: 33%;
      height: 100%;
    }

    &:nth-child(2) {
      width: 33%;
      height: 100%;
      padding-left: $ph-small-space;
    }

    &:nth-child(3) {
      width: 33%;
      height: 100%;
      padding-left: $ph-small-space;
    }
  }
}

.img-grid-4 {
  width: 100%;
  display: flex;
  .img-wrapper {
    padding-bottom: $ph-medium-space;
    &:nth-child(2n + 1) {
      width: 25%;
      height: 100%;
    }

    &:nth-child(2n) {
      width: 25%;
      height: 100%;

      padding-left: $ph-small-space;
    }
    &:nth-child(3) {
      width: 25%;
      height: 100%;
      padding-left: $ph-small-space;
    }
    &:nth-child(4) {
      width: 25%;
      height: 100%;
      padding-bottom: 16px;
    }
  }
}

.video-player {
  width: 640px;

  video {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1100px) {
  .img-grid-4 {
    width: 100%;
  }
}
@media (max-width: 870px) {
  .img-grid,
  .img-grid-1 {
    display: flex;
    width: 100%;
    height: unset;
  }

  .img-grid-2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    .img-wrapper {
      min-width: fit-content;
      width: 100% !important;
      &:nth-child(1) {
        height: 230px;
        max-width: 230px;
        padding-right: 0px;
      }
      &:nth-child(2) {
        padding-top: 0px;
        padding-left: 0px;
        height: 230px;
        max-width: 230px;
      }
    }
  }
  .img-grid-3 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: space-between;
    gap: 5px;

    .img-wrapper {
      &:nth-child(1) {
        display: flex;
        height: 150px;
        width: 100%;
        justify-items: center;
      }
      &:nth-child(2) {
        height: 150px;
        width: 100%;
        padding-top: 0px;
      }

      &:nth-child(3) {
        padding-left: 0px;
        height: 150px;
        width: 100%;
        padding-top: 5px;
      }
    }
  }
  .img-grid-4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      .cursor-pointer {
        min-width: 120px;
        min-height: 120px;
      }
      &:nth-child(1) {
        display: flex;
        height: 120px;
        width: 100%;
      }
      &:nth-child(2) {
        height: 120px;
        width: 100%;
        padding-top: 0px;
        padding-left: 0px;
      }

      &:nth-child(3) {
        padding-left: 0px;
        height: 130px;
        width: 100%;
        padding-top: 5px;
      }

      &:nth-child(4) {
        padding-left: 0px;
        height: 120px;
        width: 100%;
        padding-top: 5px;
        object-fit: fill;
      }
    }
  }
}

@media (max-width: 600px) {
  .img-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      min-width: fit-content;
      width: 100% !important;
      &:nth-child(1) {
        height: 230px;
        max-width: 230px;
        padding-right: 0px;
      }
      &:nth-child(2) {
        padding-top: 0px;
        padding-left: 0px;
        height: 230px;
        max-width: 230px;
      }
    }
  }
}
@media (max-width: 471px) {
  .img-grid-1 {
    display: flex;
    width: 100%;
    height: unset;
    .img-wrapper {
      display: flex;
      width: 100%;
      align-items: baseline;
    }
    .cursor-pointer {
      min-width: 100%;
    }
  }

  .img-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      min-width: fit-content;
      &:nth-child(1) {
        display: flex;
        // height: 120px;
        // width: 120px;
        padding-right: 0px;
      }
      &:nth-child(2) {
        padding-top: 0px;
        padding-left: 0px;
        // height: 120px !important;
        // width: 120px;
      }
    }
  }

  .img-grid-3 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;

    .img-wrapper {
      // .cursor-pointer {
      //   width: 130px;
      //   height: 130px;
      // }
      &:nth-child(1) {
        display: flex;
        // height: 130px;
        // min-width: 130px !important;
      }
      &:nth-child(2) {
        // height: 130px;
        // width: 130px;
        padding-top: 0px;
      }

      &:nth-child(3) {
        padding-left: 0px;
        // height: 130px;
        // width: 130px;
        padding-top: 5px;
      }
    }
  }
  .img-grid-4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      // .cursor-pointer {
      //   max-width: 120px;
      //   height: 120px;
      // }
      &:nth-child(1) {
        display: flex;
        // height: 120px;
        // width: 120px;
      }
      &:nth-child(2) {
        // height: 120px;
        // width: 120px;
        padding-top: 0px;
        padding-left: 0px;
      }

      &:nth-child(3) {
        padding-left: 0px;
        // height: 130px;
        // width: 130px;
        padding-top: 5px;
      }

      &:nth-child(4) {
        padding-left: 0px;
        // height: 120px;
        // width: 120px;
        padding-top: 5px;
        object-fit: fill;
      }
    }
  }
}

@media (max-width: 350px) {
  .img-grid-1 {
    display: flex;
    width: 100%;
    height: unset;
    .img-wrapper {
      display: flex;
      width: 100%;
      align-items: baseline;
    }
    .cursor-pointer {
      min-width: 100%;
    }
  }

  .img-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      min-width: fit-content;
      &:nth-child(1) {
        display: flex;
        height: 100px;
        width: 100px;
        padding-right: 0px;
      }
      &:nth-child(2) {
        padding-top: 0px;
        padding-left: 0px;
        height: 100px !important;
        width: 100px;
      }
    }
  }

  .img-grid-3 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      .cursor-pointer {
        width: 100px;
        height: 100px;
      }
      &:nth-child(1) {
        display: flex;
        height: 100px;
        min-width: 100px !important;
      }
      &:nth-child(2),
      &:nth-child(3) {
        height: 100px;
        width: 100px;
        padding-top: 0px;
      }
    }
  }

  .img-grid-4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;
    .img-wrapper {
      .cursor-pointer {
        width: 100px;
        height: 100px;
      }
      &:nth-child(1) {
        display: flex;
        height: 100px;
        min-width: 100px !important;
      }
      &:nth-child(2),
      &:nth-child(3) {
        height: 100px;
        width: 100px;
        padding-top: 0px;
      }

      &:nth-child(3) {
        padding-top: 30px;
      }

      &:nth-child(4) {
        padding-top: 30px;
      }
    }
  }
}
</style>
