<template>
  <component
    :is="gridComponent"
    v-if="gridComponent"
    :is-admin="$route.meta.showAdminFunctions"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'GridViewWrapper',
  props: {
    blockType: {
      type: String,
      default: 'single-elimination',
    },
  },
  computed: {
    gridComponent() {
      return () => import(`./${this.blockType}`);
    },
  },
};
</script>
