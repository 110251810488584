<template>
  <div class="form-group position-relative playhera-input m-0">
    <label class="h-text-left m-0 pb-2" :for="id">
      {{ label }}
    </label>
    <div
      class="input-group input-group-lg hera-input-phone_input-group new-phone-input"
      :class="{ 'is-invalid': error }"
      style="border-radius:4px; background-color: #18181e;"
    >
      <div class="input-group-prepend">
        <button
          type="button"
          class="btn position-relative p-3"
          @click="togglePopover"
        >
          <img
            :src="selectedCountry.logo"
            :alt="selectedCountry.code"
            class="hera-input-phone_flag"
          />
          <span class="phone-calling-code"
            >+{{ selectedCountry.calling_code }}</span
          >
          <AppIcon class="multi-select_icon" name="arrow-s" width="12" />
        </button>
      </div>
      <input
        :id="id"
        v-model.trim="inputValue"
        v-mask="'#'.repeat(15)"
        :name="name"
        type="tel"
        class="form-control border-0 py-0 hera-input-phone_input"
        style="height:34px"
        v-bind="inputProps"
        :aria-describedby="`phoneHelp_${id}`"
        :aria-label="label"
      />
      <div v-if="error" class="input-group-append hera-input-phone_icon-error">
        <span class="px-3 py-2 border-0 text-danger">
          <AppIcon name="danger" />
        </span>
      </div>
    </div>
    <span
      v-show="error"
      :id="`phoneHelp_${id}`"
      class="form-text text-danger error-help"
    >
      {{ error }}
    </span>

    <PopoverList
      class="country-popover"
      :closest="$el"
      target-selector="img"
      :position="popoverPosition"
      :items="countryListFiltered"
      regard="top"
      :float="['top']"
      width="100%"
      :height="[30, 200]"
      auto-close
      scroll-is-disable
      translate="-0.5rem"
      @toggle="popoverSwitched"
      @selected="popoverSelectCountry"
    >
      <div slot="header" class="mb-n2">
        <HeraFormSearch
          ref="countrySearch"
          v-model="filterValue"
          :debounce="0"
        />
      </div>
      <span
        slot-scope="{ item }"
        :class="{
          active: selectedCountry.calling_code === item.calling_code,
        }"
      >
        <img
          :src="item.logo"
          :alt="selectedCountry.code"
          class="hera-input-phone_flag"
        />
        <span class="text-ltr">+{{ item.calling_code }}</span>
        <span>{{ $t(`_web_country_${item.code}`, item.name) }}</span>
      </span>
    </PopoverList>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { uniqueId, uniqBy } from '@/utils/lodashUtils';
import PopoverList from '@/components/popover/List';
import DefaultFlag from '@/assets/images/flags/ar.png';
import HeraFormSearch from '@/components/form/HeraFormSearch';
import AppIcon from '@/components/atoms/Icon';
import { prop } from '@/utils/factories';

const DEFAULT_COUNTRY = {
  name: 'Saudi Arabia',
  calling_code: '966',
  logo: DefaultFlag,
};

export default {
  components: {
    AppIcon,
    HeraFormSearch,
    PopoverList,
  },
  $_veeValidate: {
    value() {
      return this.value;
    },
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: prop(String),
    name: prop(String),
    label: prop(String, 'Phone Number'),
    error: prop(String),
    inputAutofocus: prop(Boolean),
    inputProps: {
      type: Object,
      default: () => ({}),
    },
    savingKey: prop(String),
  },
  data() {
    return {
      id: uniqueId('input_phone_'),
      selectedCountry: DEFAULT_COUNTRY,
      inputValue: '',
      popoverPosition: null,
      filterValue: '',
      countiesReplaced: false,
    };
  },
  computed: {
    ...mapGetters(['countries', 'currentRegion']),
    countriesReplaced() {
      return uniqBy(
        this.countries.map(country => {
          return { ...country, nameInLowerCase: country.name.toLowerCase() };
        }),
        'calling_code'
      );
    },
    countryListFiltered() {
      if (!this.filterValue.trim()) {
        return this.countriesReplaced;
      }

      const filterValue = this.filterValue.toLowerCase();
      return this.countriesReplaced.filter(
        country => country.nameInLowerCase.indexOf(filterValue) === 0
      );
    },
  },
  watch: {
    countries() {
      this.setSavedPhone();
    },
    inputValue() {
      this.emitInput();
    },
  },
  created() {
    if (
      this.currentRegion.id === 'japan' &&
      this.countries &&
      this.countries.length
    ) {
      this.selectedCountry = this.countries.find(
        county => county.name === 'Japan'
      );
    }
  },
  mounted() {
    this.$nextTick(this.setSavedPhone);
  },
  methods: {
    togglePopover(e) {
      this.popoverPosition = this.popoverPosition ? null : e.currentTarget;
    },
    popoverSwitched(isVisible) {
      if (!isVisible) {
        this.popoverPosition = null;
      } else {
        this.$nextTick(this.$refs.countrySearch.focusIn);
      }
    },
    popoverSelectCountry(item) {
      this.filterValue = '';
      this.selectedCountry = { ...item };
      this.emitInput();
    },
    clearSavedPhone() {
      window.localStorage.removeItem(`phone_${this.savingKey || 'default'}`);
      this.inputValue = '';
    },
    savePhone() {
      window.localStorage.setObjectToItem(
        `phone_${this.savingKey || 'default'}`,
        {
          code: this.selectedCountry.calling_code,
          value: this.inputValue,
          country: this.selectedCountry,
        }
      );
    },
    emitInput() {
      this.$emit(
        'input',
        this.inputValue
          ? this.selectedCountry.calling_code +
              this.inputValue.replace(/^0+/, '')
          : null
      );
    },
    setSavedPhone() {
      if (!this.countries || !this.countries.length) {
        return;
      }

      const savedPhone = window.localStorage.getObjectFromItem(
        `phone_${this.savingKey || 'default'}`
      );

      if (!savedPhone) {
        return;
      }

      const selectedCountry = this.countries.find(
        county => county.calling_code === savedPhone.code
      );

      if (!selectedCountry) {
        return;
      }

      this.inputValue = savedPhone.value;
      this.selectedCountry = selectedCountry;
    },
  },
};
</script>
<style lang="scss">
.hera-input-phone {
  &_input-group {
    border-radius: 0.25rem;
    &:focus-within {
      box-shadow: 0 0 0 0.2rem rgba($ph-accent, 0.25);
      &.is-invalid {
        box-shadow: 0 0 0 0.2rem rgba($ph-error, 0.25);
      }
    }

    // replace bootstrap styles for .input-group (always LTR)
    > .form-control:last-child,
    > .input-group-append > .input-group-text {
      border-radius: 0 0.25rem 0.25rem 0 !important;
      background-color: $new-bg-base;
    }
    > .input-group-prepend > .btn {
      border-radius: 0.25rem 0 0 0.25rem !important;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      background-color: $new-bg-base;
    }
  }

  %replace-bootstrap-styles {
    line-height: 1.5rem !important;
    font-size: 1rem !important;
    border-width: 0;
    color: $ph-primary-text !important;
  }
  &_btn {
    background-color: $new-bg-base !important;
    gap: 1.5rem;
    @include inject-pseudo('-extend', ':active', ':hover') {
      background-color: $new-bg-base !important;
    }
    @include inject-pseudo {
      box-shadow: none !important;
    }
    @extend %replace-bootstrap-styles;
  }
  &_input {
    @include inject-pseudo('-extend', ':focus') {
      // fix for webkit
      box-shadow: inset 0 0 1px 1px $new-bg-base !important;
      background-color: $new-bg-base;
      color: white;
    }
    @extend %replace-bootstrap-styles;
  }
  &_flag {
    display: inline-block;
    overflow: hidden;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    object-fit: contain;
    object-position: center;
    .dropbox-list & {
      @include auto-rtl(
        (
          margin-right: 0.75rem,
          margin-left: 0,
        )
      );
      + span {
        display: inline-block;
        width: 3.75rem;
      }
    }
  }

  .dropbox-list {
    .input-search .AppIcon {
      fill: $text-muted;
    }
    li > span {
      padding: 0.5rem 0.75rem;
    }
  }
}
.new-phone-input {
  border-radius: 0.25rem;
  background-color: $new-bg-base;
  width: 100%;
  height: 2.25rem;
  border: solid 1px;
  border-color: $new-border-neutral;
}
.country-popover {
  border-radius: 0.25rem;
  background-color: $new-bg-base;
  border: solid 1px;
  border-color: $new-border-neutral;
}

.phone-calling-code {
  color: $hr-text-primary;
  padding-top: 0.125rem;
}
</style>
