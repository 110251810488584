var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-plans-container"},[_c('div',{staticClass:"billing-details"},[(_vm.currentPlan)?_c('Panel',{staticClass:"billings-details-wrapper",attrs:{"variant":[
        'transparent',
        'stroked',
        'stroked-input-borders',
        'rounded' ]}},[_c('TextBlock',{staticClass:"secondary-font",attrs:{"tag":"div","size":1.25,"variant":"bold"}},[_vm._v(_vm._s(_vm.$$t('Plan details')))]),_c('div',{staticClass:"plan-name mt-6"},[_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825}},[_vm._v(_vm._s(_vm.currentPlan.name))]),_vm._v("\n        :\n        "),_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm.currentPlan.currency))]),_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm.currentPlan.amount))]),_vm._v("\n        /\n        "),_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm.currentPlan.period))])],1),(
          _vm.currentPlan.period &&
            _vm.subCanceled.subscriptions &&
            _vm.subCanceled.subscriptions[0].next_transaction_cancelled
        )?_c('div',{staticClass:"mt-5"},[(
            (_vm.subCanceled.subscriptions &&
              _vm.subCanceled.subscriptions[0].next_transaction_cancelled) ||
              !_vm.currentPlan.nextPaymentOn
          )?_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825}},[_vm._v(_vm._s(_vm.$t('_web_sub_valid', 'Subscription valid until')))]):_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825}},[_vm._v(_vm._s(_vm.$$t('Next payment date')))]),_vm._v("\n        :\n        "),_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm._f("localDate")(_vm.currentPlan.activeTo,_vm.$times.DATE_FULL)))])],1):_vm._e(),(_vm.currentPlan.nextPaymentOn)?_c('div',{staticClass:"mt-5"},[_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825}},[_vm._v(_vm._s(_vm.$$t('Next payment date')))]),_vm._v("\n        :\n        "),_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm._f("localDate")(_vm.currentPlan.nextPaymentOn,_vm.$times.DATE_FULL)))])],1):_vm._e(),(
          _vm.subCanceled.subscriptions &&
            !_vm.subCanceled.subscriptions[0].next_transaction_cancelled &&
            _vm.currentPlan.nextPaymentOn
        )?_c('div',{staticClass:"d-flex align-items-center mt-5 prolongation unsubscribe-billing"},[_c('Btn',{staticClass:"btn-error-border btn-details-unsubscribe",attrs:{"label":_vm.$$t('Unsubscribe')},on:{"click":_vm.openConfirmCancel}})],1):_vm._e()],1):_vm._e(),(!_vm.currentPlan && !_vm.externalPlan && !_vm.activeVoucherPlan)?_c('HeraDummyList',{staticClass:"mb-5",attrs:{"icon-name":"dummy/search","position":"center","size":"200","header-text":_vm.$t(
          '_web_subscription_no_user_subs',
          'Sorry, you don’t have any active subscriptions'
        )},scopedSlots:_vm._u([{key:"ctaButton",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'subscriptionSetup' }}},[_c('Btn',{staticClass:"btn btn-primary"},[_vm._v("\n            "+_vm._s(_vm.$t('_web_profile_popover-subscriptions', 'Subscriptions'))+"\n          ")])],1)]},proxy:true}],null,false,222338409)}):_vm._e()],1),_c('div',{staticClass:"billing-details"},[(_vm.externalPlan)?_c('Panel',{staticClass:"billings-details-wrapper",attrs:{"variant":[
        'transparent',
        'stroked',
        'stroked-input-borders',
        'rounded' ]}},[_c('TextBlock',{staticClass:"secondary-font",attrs:{"tag":"div","size":1.25,"variant":"bold"}},[_vm._v(_vm._s(_vm.$$t('Plan details')))]),_c('div',{staticClass:"plan-name mt-6"},[_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm.$t('_web_ext_sub', 'External subscription')))])],1),(_vm.externalPlan.expires)?_c('div',{staticClass:"mt-5"},[_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825}},[_vm._v(_vm._s(_vm.$t(
              '_web_sub_provider-details',
              'Please contact your provider for more details'
            )))]),_vm._v("\n          "+_vm._s(// eslint-disable-next-line vue/no-parsing-error
            _vm.showCanceled ? ' ' + _vm.$t('_web_sub_canceled', '(canceled)') : ''))],1)],1):_vm._e()],1):_vm._e(),(_vm.activeVoucherPlan)?_c('Panel',{staticClass:"billings-details-wrapper",attrs:{"variant":[
        'transparent',
        'stroked',
        'stroked-input-borders',
        'rounded' ]}},[_c('TextBlock',{staticClass:"secondary-font",attrs:{"tag":"div","size":1.25,"variant":"bold"}},[_vm._v(_vm._s(_vm.$$t('Plan details')))]),_c('div',{staticClass:"plan-name mt-6"},[(_vm.activeVoucherPlan.title)?_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm.activeVoucherPlan.title))]):_vm._e()],1),(_vm.activeVoucherPlan.expires)?_c('div',{staticClass:"mt-5"},[_c('TextBlock',{staticClass:"h-mr-2",attrs:{"size":0.825}},[_vm._v(_vm._s(_vm.$t('_web_sub_valid', 'Subscription valid until')))]),_vm._v("\n        :\n        "),_c('TextBlock',{staticClass:"mx-2",attrs:{"size":0.825,"variant":"bold"}},[_vm._v(_vm._s(_vm._f("localDate")(_vm.activeVoucherPlan.expires,_vm.$times.DATE_FULL)))])],1):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }