<template>
  <div class="standings">
    <Title
      :text="$t('_web_tournament_reports-title', 'Event Report')"
      class="mb-5"
    />
    <Panel>
      <div>
        <Icon
          class="h-mr-3"
          :name="currentStageType.key"
          :fill="$scss.secondaryTextBg"
          width="24"
        />
        <TextBlock
          :text="$$t(currentStageType.name, currentStageType.key)"
          variant="secondary"
        />
      </div>
      <div v-if="isLoading" class="text-center">
        fetching data...
      </div>
      <template v-else-if="!isLoading && reportData">
        <Subheader class="my-4" text="Total" />
        <div class="row mb-4">
          <div class="col-md-6 col-12">
            <TextBlock text="From:" variant="secondary" />&nbsp;
            <TextBlock :text="reportData.total.stage.fromStatus" /><TextBlock
              class="mx-3"
              text="||"
              variant="secondary"
            />
            <TextBlock>{{
              reportData.total.stage.fromTime | localDate
            }}</TextBlock>
          </div>
          <div class="col-md-6 col-12">
            <TextBlock text="To:" variant="secondary" />&nbsp;
            <TextBlock :text="reportData.total.stage.toStatus" /><TextBlock
              class="mx-3"
              text="||"
              variant="secondary"
            />
            <TextBlock>{{
              reportData.total.stage.toTime | localDate
            }}</TextBlock>
          </div>
        </div>
        <div class="row flex-column">
          <div class="col mb-3">
            <TextBlock text="Winners(tournament): " variant="secondary" />
            <TextBlock :text="reportData.header.winners" />
            <br />
            <TextBlock
              text="Tournament Types(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.competitionTypes" />
            <br />
            <TextBlock
              text="Has Prize Pool(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.hasPrizePool" />
            <br />
            <TextBlock text="Platforms(tournament): " variant="secondary" />
            <TextBlock :text="reportData.header.platform" />
            <br />
            <TextBlock text="Game(tournament): " variant="secondary" />
            <TextBlock :text="reportData.header.game" />
            <br />
            <TextBlock text="Is Public(tournament): " variant="secondary" />
            <TextBlock :text="reportData.header.isPublic" />
            <br />
            <TextBlock
              text="Tournament Players - Gender - Male(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.male" />
            <br />
            <TextBlock
              text="Tournament Players - Gender - Female(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.female" />
            <br />
            <TextBlock
              text="Tournament Players - Platform Accounts Linked(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.platformsLinked" />
            <br />
            <TextBlock
              text="Tournament Players - Social Networks Linked (tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.socialNetworksLinked" />
            <br />
            <TextBlock
              text="Tournament Players - Countries(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.playerCountries" />
            <br />
            <TextBlock
              text="Tournament Players - Favorite Games(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.favoriteGames" />
            <br />
            <TextBlock
              text="New Players Registered(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.newPlayersRegistered" />
            <br />
            <TextBlock
              text="New Clans Registered(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.newClansRegistered" />
            <br />
            <TextBlock text="Posts Created(time): " variant="secondary" />
            <TextBlock :text="reportData.total.report.postsCreated" />
            <br />
            <TextBlock text="Likes Count(time): " variant="secondary" />
            <TextBlock :text="reportData.total.report.likesCount" />
            <br />
            <TextBlock text="Dislikes Count(time): " variant="secondary" />
            <TextBlock :text="reportData.total.report.dislikesCount" />
            <br />
            <TextBlock text="Reposts Count(time): " variant="secondary" />
            <TextBlock :text="reportData.total.report.repostsCount" />
            <br />
            <TextBlock
              text="Number of chats created(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.chatsCreated" />
            <br />
            <TextBlock
              text="Number of group Chats Created(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.groupChatsCreated" />
            <br />
            <TextBlock
              text="Number of channels Created(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.channelsCreated" />
            <br />
            <TextBlock
              text="Number of messages sent(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.messagesSent" />
            <br />
            <TextBlock
              text="Number of matches played(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.numberOfMatches" />
            <br />
            <TextBlock
              text="Number of wins(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.numberOfWins" />
            <br />
            <TextBlock
              text="Number of looses(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.numberOfLooses" />
            <br />
            <TextBlock
              text="Number of tech wins(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.numberOfTechWins" />
            <br />
            <TextBlock
              text="Number of tech looses(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.numberOfTechLooses" />
            <br />
            <TextBlock
              text="Number of draws(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.header.numberOfMatches" />
            <br />
            <TextBlock
              text="Number of registered players(tournament): "
              variant="secondary"
            />
            <TextBlock
              :text="reportData.total.report.numberOfRegisteredPlayers"
            />
            <br />
            <TextBlock
              text="Number of qualified players(tournament): "
              variant="secondary"
            />
            <TextBlock
              :text="reportData.total.report.numberOfQualifiedPlayers"
            />
            <br />
            <TextBlock
              text="Number of reverved players(tournament): "
              variant="secondary"
            />
            <TextBlock
              :text="reportData.total.report.numberOfReservedPlayers"
            />
            <br />
            <TextBlock
              text="Number of revoked players(tournament): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.numberOfRevokedPlayers" />
            <br />
            <TextBlock
              text="Number of notifications sent(time): "
              variant="secondary"
            />
            <TextBlock
              :text="reportData.total.report.numberOfNotificationsSent"
            />
            <br />
            <TextBlock
              text="Organizer new followers(time): "
              variant="secondary"
            />
            <TextBlock :text="reportData.total.report.organizerNewFollowers" />
            <br />
            <TextBlock
              text="Organizer total followers(time): "
              variant="secondary"
            />
            <TextBlock
              :text="reportData.total.report.organizerTotalFollowers"
            />
            <br />
          </div>
        </div>
        <hr />
        <hr />
        <hr />
        <template>
          <div
            v-for="(stage, index) in reportData.byTournamentStage"
            :key="index"
            class="row flex-column"
          >
            <Subheader class="my-4" :text="`Stage ${index}`" />
            <div class="row mb-4">
              <div class="col-md-6 col-12">
                <TextBlock text="From:" variant="secondary" />&nbsp;
                <TextBlock :text="stage.stage.fromStatus" /><TextBlock
                  class="mx-3"
                  text="||"
                  variant="secondary"
                />
                <TextBlock>{{ stage.stage.fromTime | localDate }}</TextBlock>
              </div>
              <div class="col-md-6 col-12">
                <TextBlock text="To:" variant="secondary" />&nbsp;
                <TextBlock :text="stage.stage.toStatus" /><TextBlock
                  class="mx-3"
                  text="||"
                  variant="secondary"
                />
                <TextBlock>{{ stage.stage.toTime | localDate }}</TextBlock>
              </div>
            </div>
            <div class="row flex-column">
              <div class="col mb-3">
                <TextBlock
                  text="New Players Registered(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.newPlayersRegistered" />
                <br />
                <TextBlock
                  text="New Clans Registered(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.newClansRegistered" />
                <br />
                <TextBlock text="Posts Created(time): " variant="secondary" />
                <TextBlock :text="stage.report.postsCreated" />
                <br />
                <TextBlock text="Likes Count(time): " variant="secondary" />
                <TextBlock :text="stage.report.likesCount" />
                <br />
                <TextBlock text="Dislikes Count(time): " variant="secondary" />
                <TextBlock :text="stage.report.dislikesCount" />
                <br />
                <TextBlock text="Reposts Count(time): " variant="secondary" />
                <TextBlock :text="stage.report.repostsCount" />
                <br />
                <TextBlock
                  text="Number of chats created(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.chatsCreated" />
                <br />
                <TextBlock
                  text="Number of group Chats Created(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.groupChatsCreated" />
                <br />
                <TextBlock
                  text="Number of channels Created(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.channelsCreated" />
                <br />
                <TextBlock
                  text="Number of messages sent(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.messagesSent" />
                <br />
                <TextBlock
                  text="Number of registered players(tournament): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.numberOfRegisteredPlayers" />
                <br />
                <TextBlock
                  text="Number of qualified players(tournament): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.numberOfQualifiedPlayers" />
                <br />
                <TextBlock
                  text="Number of reverved players(tournament): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.numberOfReservedPlayers" />
                <br />
                <TextBlock
                  text="Number of revoked players(tournament): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.numberOfRevokedPlayers" />
                <br />
                <TextBlock
                  text="Number of notifications sent(time): "
                  variant="secondary"
                />
                <TextBlock :text="stage.report.numberOfNotificationsSent" />
                <br />
              </div>
            </div>
          </div>
        </template>
      </template>
      <div v-else class="text-center">
        something went wrong
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { STAGE_TYPES, BLOCK_TYPES } from '@/constants/tournaments';
import paramsMixin from '@/mixins/queryParams';
import { translate } from '@/mixins/translate';
import LoadingHelper from '@/mixins/isLoading';
import Icon from '@/components/atoms/Icon';
import Title from '@/components/atoms/Title';
import Subheader from '@/components/atoms/Subheader';
import TextBlock from '@/components/atoms/TextBlock';
import Panel from '@/components/atoms/Panel';

export default {
  name: 'Standings',
  components: {
    Icon,
    Title,
    Subheader,
    TextBlock,
    Panel,
  },
  mixins: [
    LoadingHelper,
    paramsMixin,
    translate('_web_competitions_standings'),
  ],

  data() {
    return {
      reportData: null,
    };
  },

  computed: {
    ...mapGetters(['currentTournamentStages']),
    stageID() {
      return this.$route.params.stageID;
    },
    currentStage() {
      return this.currentTournamentStages.find(
        stage => stage.id === this.stageID
      );
    },
    currentStageType() {
      const type = this.$lodash.get(
        this.currentStage,
        'block_configuration.type',
        BLOCK_TYPES.SINGLE
      );
      return STAGE_TYPES.find(s => s.type === type);
    },
  },

  created() {
    this.setLoading();
    this.fetchPostEventReport(this.$route.params.tournamentID)
      .then(response => {
        this.reportData = response;
      })
      .catch(this.errorNotify)
      .finally(this.unsetLoading);
  },

  methods: {
    ...mapActions(['fetchPostEventReport', 'errorNotify']),
  },
};
</script>

<style lang="scss"></style>
