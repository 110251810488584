<template>
  <div class="sc-long-post d-flex flex-column">
    <ChatHeader
      title="Post Info"
      has-back-icon
      :can-edit="canEdit"
      @back="$emit('closePost', newCommentsCount)"
    />
    <div class="sc-long-post__container">
      <div
        ref="comments"
        class="sc-long-post__comments custom-scrollbar d-flex flex-column align-items-center"
      >
        <div class="sc-long-post__original">
          <TextContent v-if="data.text" :content="data.text" />
          <div class="sc-long-post__attach-wrapper">
            <Attachments
              v-if="data.attachments && data.attachments.length"
              :attachments="data.attachments"
            />
          </div>
        </div>
        <a
          v-if="nextPageToken"
          class="sc-long-post__load-prev"
          href="#"
          @click="fetchPostComments"
        >
          {{
            $t(
              '_web_tournament_sidechat_load-previous-comments',
              'Load previous comments'
            )
          }}
        </a>
        <Comment
          v-for="item in commentsToRender"
          :key="item.id"
          :data="item"
          :can-force-edit="canEdit"
          @delete="deletePostComment(item)"
        />
        <Comment
          v-for="item in undeliveredComments"
          :key="item.commentId"
          :data="{ ...item, ...myUserProfileData }"
          undelivered
          @delete="removeUndeliveredComment(item.commentId)"
          @resend="resendUndeliveredComment(item)"
        />
      </div>
    </div>
    <SCInput :grid="['Editable']" @submit="sendCommentHandler" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { uniqBy } from '@/utils/lodashUtils';
import { scrollToBottom, isScrolledToBottom } from '@/utils/index';
import ChatHeader from '@/views/sliderchat/ChatHeader';
import Attachments from '@/components/common/MsgAttachments';
import Comment from '@/views/sliderchat/Comment';
import SCInput from '@/views/sliderchat/Input';
import TextContent from '@/components/text/TextContent';
import dateMethods from '@/mixins/dateMethods';

export default {
  components: { ChatHeader, Comment, SCInput, Attachments, TextContent },
  mixins: [...dateMethods],
  props: {
    data: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      isLoading: false,
      nextPageToken: '',
      newComment: '',
      newCommentsCount: 0,
    };
  },
  computed: {
    ...mapGetters(['allUndeliveredComments']),
    ...mapState('my', ['account']),
    undeliveredComments() {
      return this.allUndeliveredComments
        .filter(comment => comment.id === this.data.id)
        .reverse();
    },
    commentsToRender() {
      return [...this.comments].reverse();
    },
    myUserProfileData() {
      return {
        created_by: this.account._id,
        created_avatar: this.account._avatar,
        creator_type: this.account._type,
        author: this.account,
      };
    },

    entityId() {
      return `${this.data.chat_id},${this.data.id}`;
    },
  },
  created() {
    this.reloadPostComments();
    this.fetchUndeliveredComments();
    this.addSystemSubscriber(this.systemSubscriber);
  },
  destroyed() {
    this.removeSystemSubscriber(this.systemSubscriber);
  },
  methods: {
    ...mapActions([
      'fetchComments',
      'postComment',
      'deleteComment',
      'errorNotify',
      'addSystemSubscriber',
      'removeSystemSubscriber',
    ]),
    ...mapMutations(['fetchUndeliveredComments', 'removeUndeliveredComment']),
    hasCommentsWithoutId() {
      return this.comments.findIndex(e => !e.id) > -1;
    },
    systemSubscriber({ type, extended }) {
      if (!extended || extended.conversation_id != this.data.chat_id) {
        return;
      }

      if (type == 'channel_comment_added') {
        const self = this;
        const hasSuchComment = (id = extended.id) =>
          this.comments.findIndex(e => e.id === id) > -1;

        const addCommentFromWS = (comment = extended) => {
          if (!hasSuchComment()) {
            let needScroll = isScrolledToBottom(this.$refs.comments);
            this.comments.unshift(comment);
            this.newCommentsCount++;
            if (needScroll) {
              this.$nextTick(() => {
                scrollToBottom(this.$refs.comments);
              });
            }
          }
        };

        const isNewCommentMy = (createdBy = extended.created_by) =>
          this.hasCommentsWithoutId() && createdBy === this.account._id;
        //eslint-disable-next-line
        function checkComments() {
          if (self.hasCommentsWithoutId()) {
            setTimeout(checkComments, 1000);
          } else {
            addCommentFromWS();
          }
        }
        if (isNewCommentMy()) {
          setTimeout(checkComments, 1000);
        } else {
          addCommentFromWS();
        }
      }

      if (type == 'channel_comment_removed') {
        this.comments = this.comments.filter(e => e.id !== extended.comment_id);
        this.newCommentsCount--;
      }
    },
    fetchPostComments() {
      const origScrollHeight = this.$refs.comments.scrollHeight;
      this.fetchComments({
        type: 'channel_message',
        id: this.entityId,
        page: this.nextPageToken,
      })
        .then(res => {
          this.comments = uniqBy([...this.comments, ...res.items], 'id');
          this.nextPageToken = res.next_page_token;
          this.isLoading = false;
          this.$nextTick(() => {
            this.$refs.comments.scrollTop =
              this.$refs.comments.scrollHeight - origScrollHeight;
          });
        })
        .catch(this.errorNotify);
    },
    reloadPostComments() {
      this.fetchComments({
        type: 'channel_message',
        id: this.entityId,
        page: '',
      })
        .then(res => {
          this.comments = res.items;
          this.nextPageToken = res.next_page_token;
          this.isLoading = false;
          this.newComment = '';
          scrollToBottom(this.$refs.comments);
        })
        .catch(this.errorNotify);
    },
    sendCommentHandler({ text }) {
      this.sendComment(text);
    },
    sendComment(text, scrollAfterSend = true) {
      const newComment = {
        text,
        last_updated_on: this.getNowSeconds(),
        ...this.myUserProfileData,
      };
      this.comments.unshift(newComment);
      if (scrollAfterSend) {
        this.$nextTick(() => {
          scrollToBottom(this.$refs.comments);
        });
      }
      this.postComment({
        type: 'channel_message',
        id: this.data.id,
        text,
        channelId: this.data.chat_id,
      })
        .then(res => {
          this.$set(this.comments, this.comments.indexOf(newComment), {
            ...newComment,
            id: res.commentId,
          });
          this.newCommentsCount++;
        })
        .catch(error => {
          this.comments.splice(this.comments.indexOf(newComment), 1);
          this.errorNotify(error);
        });
    },
    resendUndeliveredComment(comment) {
      this.removeUndeliveredComment(comment.commentId);
      this.sendComment(comment.text, false);
    },
    deletePostComment(item) {
      if (!item.id) return;
      this.deleteComment(item.id).catch(this.errorNotify);
      const index = this.comments.indexOf(item);
      this.comments.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.sc-long-post {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $ph-input-bg;
  z-index: 3;

  &__original {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: $ph-input-bg;
    border-bottom: 1px solid $ph-card;
    line-height: 1.375rem;
    word-wrap: break-word;
    a {
      color: $ph-primary-text;
      text-decoration: underline;
      &:hover {
        color: $ph-accent;
      }
    }
  }

  &__attach-wrapper {
    margin-top: 0.5rem;

    .sc-post-attach {
      width: 100%;
      max-width: 300px;
      padding: 0;
    }
  }

  &__container {
    position: relative;
    flex: 1 auto;
  }

  &__load-prev {
    display: block;
    padding: 0.75rem 0;
    font-size: 0.8rem;
    font-weight: 700;
  }

  &__comments {
    position: absolute;
    left: 1rem;
    top: 0.5rem;
    right: 1rem;
    bottom: 0.5rem;
  }
}
</style>
