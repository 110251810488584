<template>
  <div class="multi-select" :class="[`multi-select--${variant}`]">
    <WrapperFormControl v-bind="{ error, label, tooltip }">
      <button
        slot="control"
        class="d-flex multi-select_activator input-form align-items-center justify-content-between"
        :class="{ 'multi-select_activator_active': dropdownPosition }"
        :disabled="disabled"
        tabindex="-1"
        @click.stop="clickButtonHandler"
      >
        <span v-if="!hideSelected" class="text-truncate">
          {{ selectedItemsString }}
        </span>
        <Icon class="multi-select_icon" name="arrow-s" width="12" />
      </button>
    </WrapperFormControl>
    <Popover
      :position="dropdownPosition"
      class="dropdown-popover py-4"
      :closest="$el"
      :float="popoverFloat"
      :height="heightOptions"
      regard="top"
      tabindex="-1"
      @toggle="togglePopover"
    >
      <div
        v-if="checkedList"
        class="custom-scrollbar"
        :style="{ maxHeight: customHeight }"
      >
        <label class="d-flex align-items-center checkbox-wrap">
          <Checkbox
            v-model="selectedAll"
            class="h-mr-2"
            @input="selectAllHandler"
          />
          <span class="checkbox-label">{{
            allPlaceholder || $t('_web_tournament_players-filter-all', 'All')
          }}</span>
        </label>
        <MultiCheckbox
          v-model="checkedList"
          :options="optionsList"
          :input-name="inputName || `input_${_uid}`"
        />
      </div>
    </Popover>
  </div>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';

import Popover from '@/components/popover';
import Icon from '@/components/atoms/Icon';
import Checkbox from '@/components/atoms/form/Checkbox';
import MultiCheckbox from '@/components/molecules/form/MultiCheckbox';
import WrapperFormControl from '@/components/atoms/form/WrapperFormControl';
export default {
  components: {
    Icon,
    Checkbox,
    Popover,
    MultiCheckbox,
    WrapperFormControl,
  },
  props: {
    value: prop([Array, String]),
    options: propRequired([Array, Object]),
    optionsLabel: prop(String, 'name'),
    optionsKey: prop(String, 'id'),
    disabled: prop(Boolean),
    label: prop(String),
    allPlaceholder: prop(String),
    variant: {
      type: String,
      validator: val => ['button', 'transparent', 'input'].includes(val),
      default: 'button',
    },
    dark: prop(Boolean),
    hideSelected: prop(Boolean),
    topDirection: prop(Boolean),
    hideListAfterSelect: prop(Boolean),
    clearIfSelectedAll: prop(Boolean), // todo: if clearIfSelectedAll === true => return empty array []
    itemTranslationKeyPrefix: prop(String),
    floatX: prop(String, 'center'),
    tooltip: prop(String),
    error: prop(String),
    inputName: prop(String),
    notSelectedByDefault: prop(Boolean),
    customOrder: prop(Boolean),
    customHeight: prop([String, Number]),
  },
  data() {
    return {
      dropdownPosition: null,
      wasChanged: false,
      listStyles: '',
      selectedAll: false,
    };
  },
  computed: {
    heightOptions() {
      const options = ['80%', '50vh'];
      if (this.customHeight) {
        options.unshift(this.customHeight);
      }
      return options;
    },
    /**
      show selected items in header of select box
    */
    popoverFloat() {
      return [this.floatX, this.topDirection ? 'bottom' : 'top'];
    },
    // selectedAll() {
    //   return (
    //     this.checkedList.length === this.optionsList.length ||
    //     this.$lodash.isEmpty(this.value)
    //   );
    // },
    selectedItemsString() {
      return this.selectedAll || !this.checkedList.length
        ? this.allPlaceholder ||
            this.$t('_web_tournament_players-filter-all', 'All')
        : this.checkedList.map(item => this.labelById(item)).join(', ');
    },
    optionsList() {
      return this.convertOptionsToArray(this.options);
    },
    checkedList: {
      get() {
        let value = [];
        if (this.$lodash.isEmpty(this.value)) {
          value = this.selectedAll
            ? this.optionsList.map(el => el[this.optionsKey])
            : [];
        } else if (typeof this.value === 'string' && this.value.length) {
          value.push(this.value);
        } else {
          value = this.value;
        }
        return value;
      },
      set(newValue) {
        this.selectedAll = newValue.length === this.optionsList.length;
        this.$emit(
          'input',
          this.selectedAll && this.clearIfSelectedAll ? [] : newValue
        );
      },
    },
  },
  created() {
    this.selectedAll = this.notSelectedByDefault
      ? false
      : this.$lodash.isEmpty(this.value) ||
        this.options.length === this.value.length;
  },
  methods: {
    emit(value, eventType = 'input') {
      this.$emit(eventType, value);
    },
    clickButtonHandler(event) {
      if (this.disabled) return;
      this.$nextTick(() => {
        if (this.dropdownPosition) {
          this.togglePopover(false);
        } else if (!this.wasClosedRightNow) {
          this.dropdownPosition = event.currentTarget;
        }
      });
    },
    togglePopover(visible) {
      if (!visible) {
        this.dropdownPosition = null;
        this.$emit('close', this.checkedList);
        this.wasClosedRightNow = true;
        setTimeout(() => {
          this.wasClosedRightNow = false;
        }, 150);
      }
    },
    selectAllHandler(checked) {
      if (!checked) {
        this.checkedList = [];
        return;
      }
      this.checkedList = this.optionsList.reduce((arr, item) => {
        arr.push(item[this.optionsKey]);
        return arr;
      }, []);
      if (this.hideListAfterSelect) {
        this.togglePopover(false);
      }
    },
    labelById(id) {
      const item = this.optionsList.find(i => i[this.optionsKey] === id);
      return item ? item[this.optionsLabel] : '';
    },
    /**
      Convert options
      [val1, val2, val3] => [{id: val1, name: val1}, ...]
      {key: value} => [{id: key, name: value}]
    */
    convertOptionsToArray(item) {
      //if [val1, val2...]
      let options = [];
      if (Array.isArray(item) && item[0] && item[0].constructor !== Object) {
        options = item.map(val => ({ id: String(val), name: String(val) }));
      }
      //if {key: value}
      if (typeof item === 'object' && item.constructor === Object) {
        options = Object.keys(item).map(key => ({
          id: String(key),
          name: String(item[key]),
        }));
      }
      if (Array.isArray(item) && item[0] && item[0].constructor === Object) {
        options = item;
      }
      options = this.customOrder
        ? options
        : this.$lodash.orderBy(options, this.optionsLabel);
      return options;
    },
  },
};
</script>

<style lang="scss">
.multi-select {
  width: 100%;
  outline: none;
  $blockClass: &;
  position: relative;
  &_activator {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: $input-height;
    &_active {
      .multi-select_icon {
        transform: rotate(180deg);
      }
      border-color: $ph-accent;
    }
  }
  &_icon {
    transition: transform 0.3s;
    @include auto-rtl(margin-left, $ph-small-space);
  }
  .dropdown-popover {
    min-width: 100%;
  }
}
</style>
