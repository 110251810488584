export const STAGE_LOADER = 'StageLoader';
export const STAGE_SUMMARY_LOADER = 'StageSummaryLoader';
export const DEFAULT_LOADER = 'DefaultLoader';
export const TOURNAMENT_CARD_LOADER = 'TournamentCardLoader';

export default [
  STAGE_LOADER,
  STAGE_SUMMARY_LOADER,
  DEFAULT_LOADER,
  TOURNAMENT_CARD_LOADER,
];
