<template>
  <Panel
    v-if="pageQuantity > 1"
    :variant="bgVariants"
    class="pagination-wrapper d-inline-flex align-items-center p-3 mt-5"
  >
    <ToolIcon
      icon-name="arrow-left"
      :icon-color="$scss.white"
      icon-size="30"
      :icon-rotate="isRTL ? 180 : 0"
      :disabled="isDisabledBtnPrev"
      :help-text="$t('_web_prev', 'Previous')"
      class="cursor-hand h-mr-4 hand-smallscreen-r"
      @click="goPrev"
    />
    <HorizontalTabs
      v-if="!hidePages"
      use-custom
      :tabs="pageTabs"
      hide-disable-icon
      :active-tab="`page-${currentPage}`"
      variant="circles"
      @change="goTo"
    />
    <ToolIcon
      icon-name="arrow-left"
      :icon-color="$scss.white"
      icon-size="30"
      :icon-rotate="isRTL ? 0 : 180"
      :disabled="isDisabledBtnNext"
      :help-text="$t('_web_next', 'Next')"
      class="cursor-hand h-ml-4 hand-smallscreen-l"
      @click="goNext"
    />
    <NavigationButtons v-bind="{ isWaiting: isLoading }" />
  </Panel>
</template>

<script>
import { propRequired, prop } from '@/utils/factories';
import { mapGetters } from 'vuex';
import HorizontalTabs from '@/components/common/HorizontalTabs';
import Panel from '@/components/atoms/Panel';
import ToolIcon from '@/views/tournaments/management/brackets/components/ToolIcon.vue';
import NavigationButtons from '@/views/tournaments/management/singleTournament/components/NavigationButtons';
export default {
  name: 'Pagination',
  components: {
    HorizontalTabs,
    ToolIcon,
    Panel,
    NavigationButtons,
  },
  props: {
    total: propRequired(Number), // total number of items in the list
    middleTabsQuanity: prop(Number, 3), // quantity of page links in the middle
    hidePages: prop(Boolean), // with page links or next/prev only
    pageSize: prop(Number, 20), // size of one page
    updateLocally: prop(Boolean), // watch current page index loccaly or not
    isDark: prop(Boolean), // dark style variant
  },
  data() {
    return {
      localCurrentPage: 0,
    };
  },

  computed: {
    ...mapGetters(['isRTL', 'isLoading']),
    ...mapGetters(['selectedCategory', 'categories', 'shopSearch']),
    bgVariants() {
      return [
        'half-paddings',
        this.isDark ? 'dark-bg' : 'default',
        'rounded',
        'rounded-full',
        'shadowed',
      ];
    },
    pageQuantity() {
      return Math.ceil(this.total / this.pageSize);
    },
    isDisabledBtnPrev() {
      return this.currentPage === 0;
    },
    isDisabledBtnNext() {
      return this.currentPage === this.lastIndex;
    },
    renderTabsQuantity() {
      return 2 + this.middleTabsQuanity;
    },
    isExtendable() {
      return this.pageQuantity > this.renderTabsQuantity;
    },
    middleIndex() {
      return Math.floor(this.renderTabsQuantity / 2);
    },
    lastIndex() {
      return this.pageQuantity - 1;
    },
    atTheStart() {
      return this.currentPage < this.middleIndex;
    },
    atTheEnd() {
      return this.currentPage > this.lastIndex - this.middleIndex;
    },
    inTheMiddle() {
      return !this.atTheStart && !this.atTheEnd;
    },
    currentPage: {
      get() {
        return this.updateLocally
          ? this.localCurrentPage
          : this.$route.params.page
          ? +this.$route.params.page
          : 0;
      },
      set(val) {
        if (this.updateLocally) {
          this.localCurrentPage = val;
          this.$emit('change', val);
        } else {
          this.$router.push({
            ...this.$route,
            params: {
              ...this.$route.params,
              page: val,
            },
          });
        }
        this.$parent.$el.scrollIntoView();
        // window.scrollTo(0, 0);
      },
    },
    pages() {
      return Array.from({ length: this.pageQuantity }).map((el, index) => ({
        key: `page-${index}`,
        labelStated: index + 1,
        class: 'pagination-item mx-3',
      }));
    },
    pageTabs() {
      return !this.isExtendable
        ? this.pages
        : this.inTheMiddle
        ? Array.from({ length: this.renderTabsQuantity + 2 }).reduce(
            (acc, el, index, arr) => {
              let page;
              if (index === 0) {
                page = this.pages[0];
              } else if (index === 1) {
                page = {
                  key: `page-collapse-left`,
                  icon: 'dots',
                  disabled: true,
                  class: 'page-collapse',
                };
              } else if (index === arr.length - 2) {
                page = {
                  key: `page-collapse-right`,
                  class: 'page-collapse',
                  icon: 'dots',
                  disabled: true,
                };
              } else if (index === arr.length - 1) {
                page = this.pages[this.lastIndex];
              } else {
                const halfOfCentralTabs = Math.floor(
                  this.middleTabsQuanity / 2
                );
                //      2                1                 0                0
                // 7: 2 > CP - 3 || 5: 2 > CP - 2  || 3: 2 > CP - 1 || 1: 2 > CP
                // 7: 3 > CP - 2 || 5: 3 > CP - 1  || 3: 3 > CP
                // 7: 4 > CP - 1 || 5: 4 > CP      || 3: 4 > CP + 1
                // 7: 5 > CP     || 5: 5 > CP + 1
                // 7: 6 > CP + 1 || 5: 6 > CP + 2
                // 7: 7 > CP + 2 ||
                // 7: 8 > CP + 3 ||
                let indexDelta =
                  this.middleTabsQuanity < 2
                    ? 0
                    : Math.max(
                        Math.min(
                          index +
                            Math.max(0, halfOfCentralTabs - 1) -
                            this.middleTabsQuanity,
                          halfOfCentralTabs
                        ),
                        -halfOfCentralTabs
                      );
                if (this.currentPage < this.middleIndex + 1) {
                  indexDelta += 1;
                }
                if (this.currentPage > this.lastIndex - this.middleIndex - 1) {
                  indexDelta -= 1;
                }
                page = this.pages[this.currentPage + indexDelta];
              }
              return [...acc, page];
            },
            []
          )
        : Array.from({ length: this.renderTabsQuantity }).reduce(
            (acc, el, index, arr) => {
              let page;
              if (index < this.middleIndex) {
                page = this.pages[index];
              } else if (index > this.middleIndex) {
                const indexDelta = index - arr.length + 1;
                page = this.pages[this.lastIndex + indexDelta];
              } else {
                page = {
                  key: `page-collapse-middle`,
                  // labelStated: '...',
                  disabled: true,
                  icon: 'dots',
                  class: 'page-collapse',
                };
              }
              return [...acc, page];
            },
            []
          );
    },
  },
  watch: {
    selectedCategory() {
      this.localCurrentPage = 0;
    },
  },
  methods: {
    goPrev() {
      this.updateCurrentPage(Math.max(0, this.currentPage - 1));
    },
    goNext() {
      this.updateCurrentPage(
        Math.min(+this.currentPage + 1, Math.max(this.pageQuantity - 1, 0))
      );
    },
    goTo(pageKey) {
      this.updateCurrentPage(+pageKey.split('-')[1]);
    },
    updateCurrentPage(index) {
      this.currentPage = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  &-wrapper {
    min-height: 52px;
    .page-collapse {
      font-size: 18px;
      .disabled {
        opacity: 1 !important;
      }
    }
  }
  &-item {
    min-width: 36px;
    min-height: 36px;
    > * {
      height: 100%;
    }
  }
}
</style>
