<template>
  <component
    :is="tag"
    class="hera-text-block"
    :class="classList"
    v-bind="{ style }"
    ><slot>{{ text }}</slot></component
  >
</template>

<script>
import { prop } from '@/utils/factories';
const variantArr = [
  'card',
  'muted',
  'primary',
  'accent',
  'secondary',
  'error',
  'success',
  'warning',
  'bold',
];

const DEFAULT_SIZE = 0.875;
export default {
  name: 'TextBlock',
  props: {
    text: prop([String, Number]),
    variant: prop(
      [String, Array],
      'primary',
      val =>
        typeof val === 'string'
          ? variantArr.indexOf(val) !== -1
          : !val.some(c => !variantArr.includes(c)) // check all variants in array
    ),
    size: prop([Number, String], DEFAULT_SIZE),
    lineHeight: prop([Number, String], 1.2),
    tag: prop(String, 'span'),
    unsetStyles: prop(Boolean, false),
  },

  computed: {
    style() {
      return this.unsetStyles
        ? null
        : {
            fontSize: this.unsetStyles
              ? null
              : `${parseFloat(this.size) || DEFAULT_SIZE}rem`,
            lineHeight: this.unsetStyles ? null : this.lineHeight,
          };
    },
    classList() {
      const { variant } = this;
      return typeof variant === 'string'
        ? `text-block--${variant}`
        : variant.map(classSuffix => `text-block--${classSuffix}`);
    },
  },
};
</script>

<style lang="scss">
.text-block {
  &--card {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: 20px;
  }
  &--accent {
    color: $ph-accent;
  }
  &--hr-secondary {
    color: $hr-text-secondary;
  }
  &--warning {
    color: $ph-yellow;
  }
  &--primary {
    color: $ph-primary-text;
  }
  &--secondary,
  &--muted {
    color: $ph-secondary-text;
  }
  &--error {
    color: $ph-error;
  }
  &--success {
    color: $ph-green;
  }
  &--bold {
    font-weight: bold;
  }
  &--invert {
    color: $ph-card;
  }
}
</style>
<story
  options="{
    panelPosition: 'right'
  }"
  group="Common|Atoms/Text"
  name="TextBlock"
  notes="## this component has a slot which could hold any other component inside it"
  knobs="{
    text: {
      default: text('Inner text', 'I am a text in the textblock ;)')
    },
    variant: {
      default: select('variant', [
        'primary',
        'secondary',
        'muted',
        'error',
        'success',
        'bold',], 'primary')
    },
    size: {
      default: number('Size', 0.875)
    },
    lineHeight: {
      default: number('lineHeight', 1.2)
    },
    unsetStyles: {
       default: boolean('unsetStyles', false)
    },
    tag: {
       default: text('tag', 'span')
    }
  }"
>
  <TextBlock v-bind="{text, variant, size, lineHeight, unsetStyles, tag}"/>
</story>
