const downloadFakeLink = (blob, fileName) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.download = `${fileName}.csv`;
  anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
    ':'
  );
  anchor.click();
  anchor.remove();
};

export default downloadFakeLink;
