<template>
  <Panel class="stage-simple-form">
    <Subheader
      class="d-block mt-0"
      :text="$$t('Match settings', 'match-settings-section-title')"
    />
    <div class="row mt-5">
      <div class="col-auto">
        <MatchDuration
          class="match-duration-picker"
          :multiplier="bestOf"
          :disabled="isStageFieldDisabled('matchDuration') || isAllDisabled"
        />
      </div>
      <StageSettingAutofinishMatch
        class="col-auto"
        :disabled="safeMode || isAllDisabled"
      />
      <div>
        <SettingStageResultsBlocked :disabled="safeMode || isAllDisabled" />
      </div>
    </div>
    <div class="row mt-5">
      <!--  Require result attachments  -->
      <div class="col-3">
        <StageSettingRequireAttachment :disabled="safeMode || isAllDisabled" />
      </div>
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { STAGE_TYPES } from '@/constants/tournaments';
import { translate } from '@/mixins/translate';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
import MatchDuration from './MatchDuration';
import StageSettingRequireAttachment from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageRequiresAttachment';
import StageSettingAutofinishMatch from '@/views/tournaments/management/singleTournament/stages/components/SettingsStageAutocompleteMatch';
import SettingStageResultsBlocked from '@/views/tournaments/management/singleTournament/stages/components/SettingStageResultsBlocked';
export default {
  name: 'StageSectionMatchSetup',
  components: {
    Subheader,
    Panel,
    MatchDuration,
    StageSettingAutofinishMatch,
    StageSettingRequireAttachment,
    SettingStageResultsBlocked,
  },
  mixins: [translate('_web_tournament_stage-editor')],
  inject: ['$validator'],
  data() {
    return {
      STAGE_TYPES,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournamentStage',
      'isSingleStage',
      'isSingleBlock',
      'isStageFieldDisabled',
    ]),
    bestOf() {
      return this.$lodash.get(
        this.currentTournamentStage,
        `block_configuration.best_of`,
        1
      );
    },
    safeMode() {
      return Boolean(this.$route.meta.safeMode);
    },
    isAllDisabled() {
      return (
        this.$route.name === 'editSingleStage' &&
        !(this.isSingleStage && this.isSingleBlock)
      );
    },
  },
};
</script>
