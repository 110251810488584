<template>
  <Panel
    class="h-100"
    :class="{
      '': canEdit,
    }"
  >
    <div class="w-100">
      <template v-if="getStageLoader">
        <div class="row">
          <div
            v-for="index in 4"
            :key="index"
            class="col-3 border-right border-muted"
            style="border-right-style: dashed!important;"
          >
            <ContentLoader variant="StageLoader" />
          </div>
        </div>
      </template>

      <template v-else>
        <transition-group
          tag="div"
          name="stage"
          class="row flex-nowrap custom-scrollbar no-gutters stages-wrap"
        >
          <template v-for="(stage, index) in stages">
            <div
              :key="index"
              class=" d-flex flex-column justify-content-between"
            >
              <TournamentStage
                :key="stage.id"
                class="h-mr-4 h-100"
                :stage-info="stage"
                :can-edit="canEdit"
                :publish-mode="canPublish"
                :class="{
                  'ph-panel--with-loader':
                    isLoading || (stageLoading === stage.id && !groupLoading),
                }"
                @publishStage="showModalPublish(stage.id)"
                @unPublishStage="showModalUnPublish(stage.id)"
                @startStage="showModalStart(stage.id)"
                @deleteStage="deleteStage(stage.id)"
                @startCheckIn="confirmStartCheckIn(stage)"
              >
                <template v-if="!isLoading">
                  <TournamentBlockEditable
                    v-for="block in getBlocksByStageID(stage.id)"
                    :key="block.id"
                    class="mb-4"
                    :block-info="block"
                    :block-type="getBlockType(stage)"
                    :is-edit-mode="canEdit"
                    :is-loading="groupLoading === block.id"
                    @change="onBlockUpdate"
                    @click.native="onClickBlockHandler(stage, block)"
                    @deleteBlock="deleteBlock"
                  />
                </template>
                <AddTournamentBlockCover
                  v-if="
                    canEdit &&
                      $route.name !== $options.TOURN_ROUTE_NAMES.EDIT_STAGE
                  "
                  key="add-block"
                  :class="{
                    disabled: isStageFieldDisabled('addGroup', stage),
                    'ph-panel--with-loader loader-only':
                      groupLoading && stageLoading === stage.id,
                  }"
                  @click.native="addBlock(stage.id)"
                />
              </TournamentStage>
            </div>
          </template>

          <div
            v-if="canEdit"
            key="addStage"
            data-delay="false"
            class="col-4 d-flex"
          >
            <AddTournamentStageCover
              class=""
              :class="{ disabled: isTournamentFieldDisabled('addStage') }"
            />
          </div>
        </transition-group>
      </template>
    </div>
  </Panel>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { get, kebabCase } from '@/utils/lodashUtils';
import { TOURN_ROUTE_NAMES } from '@/constants/router/account';
import {
  BLOCK_TYPES,
  GRID_TYPES,
  CONSTRUCTOR_MODES,
} from '@/constants/tournaments.js';
import isLoading from '@/mixins/isLoading';
import tournamentHelpers from '@/mixins/tournaments/tournamentHelpers';
import routeHelpers from '@/mixins/routeHelpers';
import AddTournamentStageCover from './components/AddTournamentStageCover';
import TournamentStage from './components/TournamentStage';
import AddTournamentBlockCover from './components/AddTournamentBlockCover';
import TournamentBlockEditable from './components/TournamentBlockEditable';
import ContentLoader from '@/components/common/Loaders/index';
import Panel from '@/components/atoms/Panel';
const getConstructModeTabRoute = (to, from, vm) =>
  vm.isSingleStage &&
  vm.isSingleBlock &&
  to.name === 'multiStage' &&
  from.name !== 'editSingleStage'
    ? {
        name: 'editSingleStage',
        params: to.params,
      }
    : to;

const redirectToBrackets = (constructorMode, vm) => {
  if (
    vm.stages.length !== 1 ||
    vm.stages[0].blocks !== 1 ||
    constructorMode === CONSTRUCTOR_MODES.CONSTRUCT
  ) {
    return false;
  }
  vm.showBrackets(
    vm.stages[0],
    vm.$lodash.get(vm.getBlocksByStageID(vm.stages[0].id), 0, { id: 0 })
  );
  return true;
};

export default {
  inject: ['$validator'],
  components: {
    TournamentStage,
    TournamentBlockEditable,
    AddTournamentBlockCover,
    AddTournamentStageCover,
    ContentLoader,
    Panel,
  },
  mixins: [isLoading, ...tournamentHelpers, routeHelpers],
  props: {
    constructorMode: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      delay: 0,
      modalAnchor: null,
      stageLoading: null,
      groupLoading: null,
      checkInLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentTournament',
      'currentTournamentBlock',
      'currentTournamentStages',
      'getStageLoader',
      'isTournamentFieldDisabled',
      'isStageFieldDisabled',
      'isSingleStage',
      'isSingleBlock',
      'hasStages',
      'getBlocksByStageID',
    ]),
    canEdit() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.CONSTRUCT;
    },
    canPublish() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.PUBLISH;
    },
    canManage() {
      return this.$route.params.constructorMode === CONSTRUCTOR_MODES.MANAGE;
    },
    showAdminFunctions() {
      return this.getMetaFromParents('showAdminFunctions');
    },
    isOneStageLeft() {
      return this.stages.length === 1;
    },
    isOneBlockLeft() {
      return this.getBlocksByStageID(this.stages[0].id).length === 1;
    },
    tournamentID() {
      return this.$route.params.tournamentID;
    },
    stageID() {
      return this.$route.params.stageID;
    },
    blockID() {
      return this.$route.params.blockID;
    },
    visibleStages() {
      return this.$route.name !== TOURN_ROUTE_NAMES.STAGES_LIST &&
        this.$route.name !== TOURN_ROUTE_NAMES.PLAYER_STAGES_LIST
        ? this.stages.filter(el => el.id === this.stageID)
        : this.stages;
    },
    stages() {
      return this.currentTournamentStages;
    },
    // hasStages() {
    //   return this.stages.length > 0;
    // },
  },
  watch: {
    getStageLoader() {
      this.$nextTick(() => {
        this.modalAnchor = this.$refs.stageContainer;
      });
    },
  },
  created() {
    this.$options.TOURN_ROUTE_NAMES = TOURN_ROUTE_NAMES;
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      this.modalAnchor = this.$refs.stageContainer;
    });
  },
  updated() {
    //in case stageID is index
    const stageIndex = parseInt(this.stageID);
    if (stageIndex) {
      const stage = this.stages[stageIndex - 1];
      if (!stage) return;

      const block = this.getBlocksByStageID(stage.id).find(
        b => b.id == this.blockID
      );
      if (stage && block) {
        this.showBrackets(stage, block);
      }
    }
  },
  methods: {
    ...mapActions([
      'deleteTournamentStage',
      'createTournamentBlock',
      'updateTournamentBlock',
      'deleteTournamentBlock',
      'fetchTournamentStages',
      'fetchTournamentStage',
      'fetchTournamentBlocks',
      'startStageCheckIn',
      'errorNotify',
      'openModal',
      'closeModal',
    ]),

    ...mapMutations([
      'updateStageStatus',
      'setTournamentBlocks',
      'setCurrentTournamentBlock',
      'clearCurrentTournamentBlock',
    ]),
    init() {
      this.clearCurrentTournamentBlock();
      redirectToBrackets(this.$route.params.constructorMode, this);
      this.setLoading();
      this.fetchTournamentStages(this.tournamentID)
        .then(() => {
          return Promise.all(
            this.currentTournamentStages.map(stage => {
              return this.fetchTournamentBlocks({
                stage: stage.id,
                tournamentID: this.tournamentID,
              }).then(blocks => {
                this.setTournamentBlocks(blocks);
              });
            })
          );
        })
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    getBlockType(stage = {}) {
      return get(
        stage,
        'block_configuration.type',
        this.$t('_web_tournament_stage_unknown', 'Unknown')
      );
    },
    confirmStartCheckIn(stage) {
      this.openModal({
        name: 'HeraConfirm',
        props: {
          text: this.$t(
            '_web_tournament-stage-check-in_confirm_modal_title',
            'This action will force check-in start. All participants will be notified about that.'
          ),
          isLoading: this.checkInLoading,
        },
        events: {
          cancel: this.closeModal,
          confirm: () => this.startCheckIn(stage),
        },
      });
    },
    startCheckIn(stage) {
      this.stageLoading = stage.id;
      this.startStageCheckIn({
        stageID: stage.id,
        tournamentID: this.tournamentID,
      })
        .then(this.fetchTournamentStage)
        .then(() => {
          this.successNotify(
            this.$t(
              '_web_tournament-stage-check-in_successful',
              'Check-In is successfully opened'
            )
          );
        })
        .then(() =>
          this.fetchTournamentBlocks({
            stage: stage.id,
            tournamentID: this.tournamentID,
          }).then(blocks => {
            this.setTournamentBlocks(blocks);
          })
        )
        .catch(this.errorNotify)
        .finally(() => {
          this.stageLoading = null;
          this.closeModal();
        });
    },
    onClickBlockHandler(stage, block) {
      this.$nextTick(() => {
        if (!this.canEdit) {
          this.showBrackets(stage, block);
        }
      });
    },
    addBlock(stageID) {
      this.stageLoading = stageID;
      this.groupLoading = true;
      this.createTournamentBlock({ stageID, translator: this.$t }).finally(
        () => {
          this.stageLoading = null;
          this.groupLoading = false;
        }
      );
    },
    editBlock(id) {
      if (this.currentTournamentBlock.id !== id) {
        this.setCurrentTournamentBlock(id);
      }
    },
    showBrackets(stage, block) {
      const blockType = this.getBlockType(stage);
      this.$router.push({
        name: !this.showAdminFunctions
          ? 'playerTournamentBracket'
          : this.canManage && blockType !== BLOCK_TYPES.BATTLE_ROYALE
          ? 'BlockBracketsTable'
          : 'BlockBracketsGrid',
        params: {
          ...this.$route.params,
          stageID: stage.id,
          blockID: block.id,
          blockType: kebabCase(blockType),
          roundID: 0,
          ...(blockType === BLOCK_TYPES.DOUBLE
            ? { grid: GRID_TYPES.UPPER }
            : null),
        },
        query: this.$route.query,
      });
    },
    cancel() {
      if (this.showAdminFunctions) {
        this.$router.push({
          name: 'multiStage',
          params: {
            ...this.$route.params,
            blockID: null,
            stageID: null,
            tournamentID: this.tournamentID,
          },
        });
      } else {
        this.$router.push({
          name: 'playerStagesList',
          params: {
            ...this.$route.params,
            tournamentID: this.tournamentID,
          },
        });
      }
    },
    disableValidation() {
      this.$validator.fields.items.forEach(field =>
        this.$validator.detach(field)
      );
    },
    deleteStage(stageID) {
      if (this.isOneStageLeft) {
        this.errorNotify(
          this.$t(
            '_web_stage_can-not-delete-stage',
            'You can not delete last stage'
          )
        );
      } else {
        this.stageLoading = stageID;
        this.disableValidation();
        this.deleteTournamentStage(stageID)
          .catch(this.errorNotify)
          .finally(() => {
            this.stageLoading = null;
          });
      }
    },
    deleteBlock({ stageID = this.stageID, blockID = this.blockID }) {
      if (this.isOneStageLeft && this.isOneBlockLeft) {
        this.errorNotify(
          this.$t(
            '_web_stage_can-not-delete-block',
            'You can not delete last block in last stage'
          )
        );
      } else {
        this.groupLoading = blockID;
        this.disableValidation();
        this.deleteTournamentBlock({
          stageID,
          blockID,
        })
          // .then(this.cancel)
          .catch(this.errorNotify)
          .finally(() => {
            this.groupLoading = null;
          });
      }
    },
    onBlockUpdate(blockInfo) {
      this.updateTournamentBlock(blockInfo).catch(this.errorNotify);
    },
    /*saveForm() {
      //TODO TH-7419

      this.errors.clear();
      const child = this.$route.params.blockID
        ? this.$refs.constructorForm.$children[0].$children[0]
        : this.$route.name === TOURN_ROUTE_NAMES.CREATE_BLOCK ||
          this.$route.name === TOURN_ROUTE_NAMES.EDIT_STAGE
        ? this.$refs.constructorForm.$children[0]
        : this.$refs.constructorForm;
      if (child.hasOwnProperty('validateForm')) {
        child.validateForm();
      }
      this.setLoading();
      const customErrorsCount = this.errors.count();
      const promiseChain =
        customErrorsCount === 0
          ? this.$validator.validateAll()
          : Promise.reject(
              this.$t('_web_form-validation-failed', 'Form validation failed')
            );
      promiseChain
        .then(result => {
          const errorsCount = this.errors.count();
          return result && errorsCount === 0;
        })
        .then(isValid => {
          if (isValid) {
            return Promise.resolve();
          } else {
            const $firstErrorInput = this.$el.querySelector(
              '[aria-invalid=true]'
            );
            if ($firstErrorInput) {
              $firstErrorInput.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }
            return Promise.reject(
              this.$t('_web_form-validation-failed', 'Form validation failed')
            );
          }
        })
        .then(() => {
          this.disableValidation();
          child.disableConfirmLeave();
          return this.$route.name === TOURN_ROUTE_NAMES.CREATE_STAGE
            ? this.createTournamentStage()
            : this.$route.name === TOURN_ROUTE_NAMES.EDIT_STAGE
            ? this.updateTournamentStage()
            : this.$route.name === TOURN_ROUTE_NAMES.CREATE_BLOCK
            ? this.createTournamentBlock(this.stageID)
            : this.$route.name === TOURN_ROUTE_NAMES.EDIT_BLOCK
            ? this.updateTournamentBlock(this.stageID)
            : Promise.resolve();
        })
        .then(() => {
          this.$router.push({
            name: 'stagesList',
            params: { tournamentID: this.tournamentID },
          });
        })
        .catch(this.errorNotify)
        .then(this.unsetLoading);
    },*/
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.constructorMode === CONSTRUCTOR_MODES.CONSTRUCT) {
        const nextRoute = getConstructModeTabRoute(to, from, vm);
        if (!vm.$lodash.isEqual(to, nextRoute)) {
          vm.$router.replace(nextRoute);
        }
        return false;
      } else {
        return redirectToBrackets(to.params.constructorMode, vm);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.modalAnchor =
      to.name === 'multiStage' || to.name === 'playerStagesList'
        ? null
        : this.$refs.stageContainer;
    const nextTrigger = () =>
      to.params.constructorMode === CONSTRUCTOR_MODES.CONSTRUCT
        ? getConstructModeTabRoute(to, from, this)
        : !redirectToBrackets(to.params.constructorMode, this);
    const nextRoute = nextTrigger();
    const isSameNextTrigger = this.$lodash.isEqual(to.path, nextRoute.path);
    if (!isSameNextTrigger) {
      next(nextTrigger());
    } else {
      next();
    }
  },
};
</script>
