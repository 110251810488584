import MyFeed from '@/views/myFeed/MyFeed.vue';
import IndividualPost from '@/components/posts/IndividualPost.vue';
import SinglePost from '@/components/posts/SinglePost.vue';
import ReplyPage from '@/components/posts/ReplyPage.vue';
import { MAIN_NEWSFEED } from '@/constants/router/mains';
import { POST, REPLY } from '@/constants/router/';

export default [
  {
    path: 'feed/:tag?',
    beforeEnter(to, from, next) {
      next();
      // store.getters.isAuthorized ? next() : next('/signin');
      //ovdje je bio PleaseSignIn modal
    },
    name: MAIN_NEWSFEED,
    component: MyFeed,
    meta: {
      inMainNav: 15,
      guestEnabled: true,
      inMainNavOrg: 15,
      titleKey: '_web_left-sidebar_nav-feed-link',
      fallbackLabel: 'Newsfeed',
      icon: 'sidebar/newsfeed',
    },
  },
  {
    path: 'posts/:id',
    name: POST,
    component: IndividualPost,
    meta: {
      sidebarParent: MAIN_NEWSFEED,
    },
  },
  {
    path: 'singlePosts/:id',
    name: 'SinglePost',
    component: SinglePost,
    meta: {
      sidebarParent: MAIN_NEWSFEED,
    },
  },
  {
    path: 'posts/:id/reply/:threadId',
    name: REPLY,
    component: ReplyPage,
    meta: {
      sidebarParent: MAIN_NEWSFEED,
    },
  },
];
