<template>
  <div class="py-5 column-setup-wrapper">
    <div v-if="activeColumns.length > 0">
      <TextLabel
        :text="$$t('Active columns', 'active-columns')"
        variant="caption"
        class="mx-5"
      />
      <draggable
        v-model="activeColumns"
        order="first"
        v-bind="dragOptions"
        class="list-group"
        @end="onSave"
      >
        <div
          v-for="(element, index) in activeColumns"
          :key="(element.index = index)"
          class="item d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <Icon name="action" class="move" width="16" />
            <TextBlock class="text-capitalize h-ml-3">{{
              $$t(
                element.name.split(/(?=[A-Z])/).join(' '),
                `column-${$lodash.kebabCase(element.name)}`
              )
            }}</TextBlock>
          </div>
          <Icon
            v-if="!element.require"
            name="delete"
            class="cursor-pointer delete"
            width="14"
            @click="removeColumn(element, index)"
          />
        </div>
      </draggable>
    </div>
    <div v-if="inactiveColumns.length > 0" class="list-group-wrapper">
      <TextLabel
        :text="$$t('More columns', 'more-columns')"
        variant="caption"
        class="mx-5"
      />
      <div class="list-group">
        <template v-for="(element, index) in inactiveColumns">
          <div
            :key="element.name"
            class="item d-flex align-items-center justify-content-between"
          >
            <TextBlock class="text-capitalize">{{
              $$t(
                element.name.split(/(?=[A-Z])/).join(' '),
                `column-${$lodash.kebabCase(element.name)}`
              )
            }}</TextBlock>
            <Icon
              name="add"
              width="20"
              class="cursor-pointer add"
              @click="addColumn(element, index)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { cloneDeep } from '@/utils/lodashUtils';
import { translate } from '@/mixins/translate';
import Icon from '@/components/atoms/Icon';
import TextBlock from '@/components/atoms/TextBlock';
import TextLabel from '@/components/atoms/TextLabel';

export default {
  name: 'PlayersTableSettingsModal',
  components: {
    draggable,
    Icon,
    TextBlock,
    TextLabel,
  },
  mixins: [translate('_web_tournament_players')],
  props: {
    clan: {
      type: Boolean,
      default: false,
    },
    optionalColumns: {
      type: Array,
      required: true,
    },
    requireColumns: {
      type: Array,
      default: () => ['username', 'Clan name', 'approvedTo'],
    },
  },

  data() {
    return {
      activeColumns: [],
      activeColumnsBackup: [],
      inactiveColumns: [],
    };
  },

  computed: {
    tournamentID() {
      return this.$route.params.tournamentID;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        ghostClass: 'ghost',
        disabled: false,
      };
    },
    isSaveAvailable() {
      return !this.$lodash.isEqual(
        this.activeColumns,
        this.activeColumnsBackup
      );
    },
  },

  created() {
    let columns = cloneDeep(this.optionalColumns);
    columns.forEach(e => {
      if (e.name === 'username') {
        this.clan ? (e.name = 'Clan name') : 'Username';
      }
      this.requireColumns.includes(e.name) ? (e.require = true) : e;
      if (e.is_visible) {
        this.activeColumns.push(e);
      } else {
        this.inactiveColumns.push(e);
      }
    });

    this.activeColumns.sort(function(a, b) {
      if (a.index < b.index) return -1;
      if (a.index > b.index) return 1;
      return 0;
    });

    this.createBackup();
  },

  methods: {
    addColumn(element, index) {
      element.is_visible = !element.is_visible;
      this.inactiveColumns.splice(index, 1);
      this.activeColumns.push(element);
      this.onSave();
    },

    removeColumn(element, index) {
      element.is_visible = !element.is_visible;
      this.activeColumns.splice(index, 1);
      this.inactiveColumns.push(element);
      this.onSave();
    },

    onSave() {
      let columns = [...this.activeColumns, ...this.inactiveColumns].map(
        column => {
          if (column.name === 'Username' || column.name === 'Clan name') {
            column.name = 'username';
          }
          return column;
        }
      );
      this.$emit('update', columns);
    },

    createBackup() {
      this.activeColumnsBackup = this.$lodash.cloneDeep(this.activeColumns);
    },
  },
};
</script>

<style lang="scss">
.column-setup-wrapper {
  min-width: 13rem;
  .ghost {
    background: $ph-card;
  }
  .list-group {
    .item {
      padding: $ph-small-space $ph-large-space;
    }
  }
  .list-group-wrapper:last-child .item {
    padding-bottom: 0;
  }
  .delete {
    @include auto-rtl(margin-right, 3px);
  }
}
</style>
