<template>
  <div>
    <div
      v-if="isProfileEntity"
      class="d-flex status-bar-user position-absolute align-items-center"
    >
      <div
        class="d-flex align-items-end status-bar-user-avatar"
        :class="{ 'cursor-pointer': account._avatar }"
        @click="avatarHandler"
      >
        <Avatar
          :type="accountType"
          :size="64"
          :img-url="account._avatar"
          :is-deleted="account.deleted"
        />
      </div>
      <div class="status-bar-user-info">
        <LinkAccount
          :id="accountId"
          :type="accountType"
          :verified="account._verified"
          :vanity-id="account.vanityId"
          class="status-bar-user-name text-decoration-none"
          :title="account._name"
        >
          {{ account._name }}
        </LinkAccount>
        <span v-if="account.isProfile" class="text-muted"
          >@{{ account._id }}</span
        >
        <span v-if="account.isClan" class="text-muted text-uppercase">{{
          account.country
        }}</span>
      </div>
    </div>
    <EmbedBlock
      :embed="{
        width: account.isTournament || account.isBattle ? 1152 : 805,
        height: account.isTournament || account.isBattle ? 600 : 290,
      }"
      class="cover position-relative heroContainer hero-container-profile"
      :class="[
        { active: isCoverActive, 'is-battle mb-5': account.isBattle },
        $route.path.includes('profiles') ||
        $route.path.includes('organizations') ||
        $route.path.includes('clans')
          ? 'smaller-screen-heroContainer'
          : '',
        $route.path.includes('battles')
          ? 'smaller-screen-heroContainer-battle'
          : '',
      ]"
      :style="coverStyle"
    >
      <component :is="overlay" v-if="overlay" />
    </EmbedBlock>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EmbedBlock from '@/components/common/EmbedBlock';
import Avatar from '@/components/images/Avatar';
import LinkAccount from '@/components/links/Account';
import * as ACCOUNT from '@/constants/accounts';
export default {
  name: 'AccountCover',
  components: {
    EmbedBlock,
    Avatar,
    LinkAccount,
  },
  data() {
    return {
      overlay: null,
    };
  },
  computed: {
    ...mapState('current', ['account', 'accountType', 'accountId']),
    coverStyle() {
      return this.account._cover
        ? { backgroundImage: `url(${this.account._cover})` }
        : {
            backgroundImage: `url(${require('@/assets/images/coverPlaceholder.svg')})`,
          };
    },
    isCoverActive() {
      return !this.overlay && this.account._cover;
    },
    isProfileEntity() {
      return [ACCOUNT.PROFILE, ACCOUNT.ORGANIZATION, ACCOUNT.CLAN].includes(
        this.account._type
      );
    },
  },
  mounted() {
    if (this.account.isTournament) {
      this.overlay = () =>
        import(/* webpackChunkName: "tournamentCover" */ './Tournament');
    } else if (this.account.isBattle) {
      this.overlay = () =>
        import(/* webpackChunkName: "tournamentCover" */ './Battle');
    }
  },
  methods: {
    ...mapMutations('mediaplayer', ['openPlayer']),

    avatarHandler() {
      if (this.account && this.account._avatar) {
        this.openPlayer({
          type: 'image',
          url: this.account._avatar,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.heroContainer {
  width: 729px;
  height: 413px;
  background: linear-gradient(
    180deg,
    rgba(23, 18, 36, 0.62) 10.42%,
    rgba(22, 18, 36, 0) 48.76%,
    rgba(22, 18, 36, 0.5) 91.43%
  );
  border-radius: 5px !important;
}
.status-bar-user {
  border-radius: $ph-large-radius;
  padding: 12px;
  z-index: 2;
  .status-bar-user-name {
    font-size: 1.75rem;
  }
}
.cover {
  z-index: 1;
  border-radius: $ph-medium-radius;
  overflow: hidden;
  background-size: cover;
  &:not(.active) {
    background-color: #11171f;
    background-size: cover;
  }
  &.is-battle {
    border-radius: $ph-large-radius;
  }
}

.hero-container-profile {
  margin: 0px;
  width: 100%;
}
@media (max-width: 767px) {
  .smaller-screen-heroContainer {
    height: 255px;
  }
  .smaller-screen-heroContainer-battle {
    height: 500px !important;
  }
  .cover {
    &:not(.active) {
      background-color: #11171f;
      background-position: center;
    }
  }
}
</style>
