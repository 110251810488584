import accountsRequests from './interactionRequests.js';
import getRequests from './getRequests.js';
import Vue from 'vue';
import clansModule from './accountClans';

import AccountModel from '@/models/Account';
import api from '@/api';
import {
  cloneDeep,
  isEqual,
  difference,
  isEmpty,
  uniqueId,
} from '@/utils/lodashUtils';
import { generateGetters } from '@/utils/store';
import {
  // CLAN,
  PROFILE,
  BATTLE,
  TOURNAMENT,
  ORGANIZATION,
} from '@/constants/accounts';

export const state = () => ({
  accountKey: 0,
  counterKey: 0,
  // account: null,
  // accountType: '',
  // accountId: '',
  // myAccountHasClan: false, // [warn] update ONLY fetchMyAccount -> fetchAccountClan -> setMyAccountHasClan
  accountEditData: null,
  accountChangedData: null,
});

// root account module
const rootGetters = {
  ...generateGetters(
    'accountKey',
    'counterKey',
    'accountEditData',
    'accountChangedData'
  ),
  hasAccessByPermission: (state, { permissions }) => permission => {
    return permissions.fullPermissions || permissions[permission] || false;
  },
  myProfile: state => {
    return state.my.account;
  },
};
const rootMutations = {
  updateAccountKey(state) {
    state.accountKey = uniqueId('account_key_');
  },
  updateCounterKey(state) {
    state.counterKey = uniqueId('counter_key_');
  },
  createEditModel(state, data) {
    state.accountEditData = cloneDeep(data);
    state.accountEditData.isCreate = !data.id;
    state.accountChangedData = {};
  },
  updateEditData(state, data) {
    const [key, value] = Object.entries(data)[0];
    if (!isEqual(state.accountEditData[key], value)) {
      // non recursive!
      state.accountEditData = { ...state.accountEditData, ...data };
      state.accountChangedData = { ...state.accountChangedData, ...data };
    }
  },
  clearEditData(state, onlyChanges = false) {
    // debugger;
    if (!onlyChanges) {
      state.accountEditData = null;
    }
    state.accountChangedData = onlyChanges ? {} : null;
  },
  // ...generateMutations('myAccountHasClan'),
};
const rootActions = {
  fetchAccount(
    {
      state: { my, current },
      getters: { accessToken },
      commit,
      dispatch,
    },
    { accountType, accountId, regToken }
  ) {
    if (my.accountId === accountId && my.account) {
      // myAccountModel is already created.
      const data = my.account._data;

      commit('current/createAccountModel', {
        parentModel: my.account,
      });
      return Promise.resolve(data);
    }

    return api.accounts
      .getAccount({ accountType, accountId })(accessToken, {
        params: {
          registration_token: regToken,
        },
      })
      .then(({ data }) => {
        commit('current/createAccountModel', {
          accountType,
          accountId,
          data,
        });

        if (accountType === PROFILE) {
          return dispatch('fetchAccountClan', accountId);
        }
        if (accountType === BATTLE) {
          commit('current/setPlayersModels', data);
          commit('current/setTournamentModel', data);
          commit('setBattleModel', {
            myId: my.accountId,
            battle: current.account,
          });
        } else {
          commit('clearBattleModel');
        }

        return data;
      });
  },

  fetchAccountNoAuth(
    {
      state: { my, current },
      commit,
      dispatch,
    },
    { accountType, accountId, regToken }
  ) {
    if (my.accountId === accountId && my.account) {
      // myAccountModel is already created.
      const data = my.account._data;

      commit('current/createAccountModel', {
        parentModel: my.account,
      });
      return Promise.resolve(data);
    }

    return api.accounts
      .getAccountNoAuth({ accountType, accountId })({
        params: {
          registration_token: regToken,
        },
      })
      .then(({ data }) => {
        commit('current/createAccountModel', {
          accountType,
          accountId,
          data,
        });

        if (accountType === PROFILE) {
          return dispatch('fetchAccountClan', accountId);
        }
        if (accountType === BATTLE) {
          commit('current/setPlayersModels', data);
          commit('current/setTournamentModel', data);
          commit('setBattleModel', {
            myId: my.accountId,
            battle: current.account,
          });
        } else {
          commit('clearBattleModel');
        }

        return data;
      });
  },

  fetchIdentityInfo(
    {
      getters: { accessToken },
      state: { my },
      commit,
    },
    accountId = my.accountId
  ) {
    return api.accounts
      .fetchIdentityInfo(accountId)(accessToken)
      .then(({ data }) => {
        commit('current/setEmailOrPhone', data);

        return data;
      })
      .catch(error => error);
  },

  fetchAccountWall(
    {
      getters: { accessToken },
    },
    { accountType, accountId, pageToken }
  ) {
    return api.accounts
      .fetchAccountWall({
        accountType,
        accountId,
        pageToken,
      })(accessToken, { params: { page: pageToken } })
      .then(({ data }) => data)
      .catch(error => error);
  },

  getAccountBlockedBy(
    {
      getters: { accessToken },
    },
    { accountType, accountId }
  ) {
    return api.accounts
      .getAccountBlockedBy({
        accountType,
        accountId,
      })(accessToken)
      .then(({ data }) => data)
      .catch(error => error);
  },

  fetchAccountClan(
    {
      getters: { accessToken },
      state: { my },
      commit,
    },
    accountId = my.accountId
  ) {
    return api.accounts
      .fetchAccountClan(accountId)(accessToken)
      .then(({ data }) => {
        if (accountId === my.accountId) {
          commit('my/clans/deleteClans');
          commit('my/clans/setClans', data);
        } else {
          commit('current/clans/deleteClans');
          commit('current/clans/setClans', data);
        }
        return data;
      });
  },
  getTwitterToken({ getters: { accessToken } }) {
    return api.socials.requestTwitterToken(accessToken).then(({ data }) => {
      return data;
    });
  },
  fetchAccountSocialConnectionStatus(
    {
      getters: { accessToken },
      state: { my },
      commit,
    },
    accountId = my.accountId
  ) {
    return api.socials
      .getSocialConnectionStatus(accountId)(accessToken)
      .then(({ data }) => {
        commit('my/updateAccountSocialConnections', data);
        return data;
      });
  },
  setAccountSocialConnection(
    {
      getters: { accessToken },
      state: { my },
    },
    { accountId = my.accountId, connections }
  ) {
    return api.socials
      .setSocialConnections(accountId)(accessToken, connections)
      .then(({ data }) => {
        return data;
      });
  },
  deleteSocialConnection(
    {
      getters: { accessToken },
      state: { my },
    },
    { accountID = my.accountId, platform }
  ) {
    return api.socials
      .deleteSocialConnection({ accountID, platform })(accessToken)
      .then(({ data }) => {
        return data;
      });
  },

  saveAccountImage(
    {
      state: { current },
      rootGetters,
      commit,
    },
    { type, data }
  ) {
    // type is avatar or cover
    if (!data) {
      // cover and avatar use `set` for update avatar|logo|etc
      const dataChanged = { [`_${type}`]: '' };
      commit('current/updateAccountModel', dataChanged);
      if (
        current.account.isClan &&
        rootGetters['my/clans/isMemberOfAClan'](current.account._id)
      ) {
        commit('my/clans/updateClan', {
          ...dataChanged,
          id: current.account._id,
        });
      }
      return Promise.resolve('');
    }

    const dataChanged = { [`_${type}`]: data['display_uri'] };
    //change to multiclan here
    commit('current/updateAccountModel', dataChanged);
    if (
      current.account.isClan &&
      rootGetters['my/clans/isMemberOfAClan'](current.account._id)
    ) {
      commit('my/clans/updateClan', {
        ...dataChanged,
        id: current.account._id,
      });
    }
    return data['file_id'];
  },
  saveAccountPlatforms(
    {
      getters: { accessToken },
      commit,
      state: { current },
    },
    { prevPlatforms, nextPlatforms }
  ) {
    const prevPlatformsIDs = Object.keys(prevPlatforms);
    const nextPlatformsIDs = Object.keys(nextPlatforms);
    const removedPlatforms = difference(prevPlatformsIDs, nextPlatformsIDs);
    const addedPlatforms = difference(nextPlatformsIDs, prevPlatformsIDs);

    const promises = [
      ...removedPlatforms.map(platform =>
        api.accounts.deletePlatform(platform, current.accountType)(accessToken)
      ),
      ...addedPlatforms.map(platform =>
        api.accounts.putPlatform(platform, current.accountType)(accessToken)
      ),
    ];

    if (promises.length) {
      commit('current/updateAccountModel', { platforms: nextPlatforms });
    }

    return Promise.all(promises);
  },
  saveAccountChanges({
    state: { current },
    getters: { accessToken, accountChangedData },
    rootGetters,
    commit,
    dispatch,
  }) {
    let replacedAccountChangedData = {};

    return new Promise(res => res()) // for validate (feature)
      .then(() => {
        // cover
        if (accountChangedData.hasOwnProperty('__cover')) {
          return dispatch('saveAccountImage', {
            type: 'cover',
            data: accountChangedData.__cover,
          }).then(fileId => {
            if (current.account.isProfile) {
              return api.accounts.putData({ detail: 'cover', ...current })(
                accessToken,
                fileId
              );
            } else {
              replacedAccountChangedData.cover = fileId;
              return true;
            }
          });
        }
        return true;
      })
      .then(() => {
        // avatar
        if (accountChangedData.hasOwnProperty('__avatar')) {
          return dispatch('saveAccountImage', {
            type: 'avatar',
            data: accountChangedData.__avatar,
          }).then(fileId => {
            if (current.account.isProfile) {
              return api.accounts.putData({ detail: 'avatar', ...current })(
                accessToken,
                fileId
              );
            } else {
              if (current.account.isClan) {
                replacedAccountChangedData.logo = fileId;
              } else {
                replacedAccountChangedData.avatar = fileId;
              }
              return true;
            }
          });
        }
        return true;
      })
      .then(() => {
        // platforms
        if (accountChangedData.hasOwnProperty('__platforms')) {
          return dispatch('saveAccountPlatforms', {
            nextPlatforms: accountChangedData.__platforms,
            prevPlatforms: accountChangedData.__prevPlatforms,
          });
        }
        return true;
      })
      .then(() => {
        // other information
        Object.keys(accountChangedData).forEach(key => {
          if (key.indexOf('__') === 0) {
            delete accountChangedData[key]; // not send with other props
          }
        });

        replacedAccountChangedData = {
          ...replacedAccountChangedData,
          ...accountChangedData,
        };

        if (!isEmpty(accountChangedData)) {
          commit('current/updateAccountModel', accountChangedData);
          //change to multiclan here
          if (
            current.account.isClan &&
            rootGetters['my/clans/isMemberOfAClan'](current.accountId)
          ) {
            commit('my/clans/updateClan', {
              ...accountChangedData,
              id: current.accountId,
            });
          }
        }

        return true;
      })
      .then(() => {
        if (!isEmpty(replacedAccountChangedData)) {
          if (!current.accountId) {
            return api.accounts
              .postData({ ...current })(accessToken, replacedAccountChangedData)
              .then(({ data }) => {
                commit('current/updateAccountModel', data);
                if (current.account.isClan) {
                  commit('my/clans/addClan', data);
                }
                return data; // return account data
              });
          }

          return api.accounts.putData({ ...current })(
            accessToken,
            replacedAccountChangedData
          );
        }

        return null;
      });
  },
};

// namespaced modules
const mutations = {
  setEmailOrPhone(state, payload) {
    payload.email
      ? state.account.update({ email: payload.email })
      : payload.phone
      ? state.account.update({ phone: payload.phone })
      : null;
  },
  createAccountModel(
    state,
    {
      parentModel = null,
      data = parentModel._data,
      accountId = (data && (data.id || data.login)) || '',
      accountType = data.type,
    }
  ) {
    state.accountType = accountType;
    state.accountId = accountId;

    Vue.set(
      state,
      'account',
      parentModel ||
        new AccountModel(accountType, accountId).update({ ...data })
    );
  },
  updateAccountSocialConnections(
    state,
    data = {
      facebookCredentialsDefined: false,
      twitterCredentialsDefined: false,
    }
  ) {
    Vue.set(state, 'socialConnections', {
      ...state.socialConnections,
      ...data,
    });
  },
  updateAccountModel(state, data) {
    // it is create (clan):
    const { id } = data;
    if (id) {
      state.accountId = id;
      state.account.update({ _id: id });
    }
    state.account.update({ ...data });
  },
  removeAccountModel(state) {
    // state.accountType = '';
    // state.accountId = '';
    if (state.account._id) {
      state.account = null;
    }
  },
  setPlayersModels(state, data) {
    const playerModel = player => {
      const playerId = (player && (player.id || player.login)) || '';
      return isEmpty(player)
        ? null
        : new AccountModel(data.type, playerId).update({ ...player });
    };
    state.account.update({
      player1: playerModel(data.player_1),
      player2: playerModel(data.player_2),
    });
  },
  setTournamentModel(state, data) {
    const originalTournament = data.event;
    const tournamentModel = isEmpty(originalTournament)
      ? null
      : new AccountModel(TOURNAMENT, originalTournament.id).update({
          ...originalTournament,
        });
    state.account.update({
      tournament: tournamentModel,
      event: undefined, // @olsy and @ohul
    });
  },
};
const myActions = {
  fetchMyAccount({ rootGetters: { accessToken }, commit, dispatch }) {
    if (!accessToken) {
      return;
    }
    return api.accounts
      .getMyAccount(accessToken)
      .then(({ data }) => {
        commit('createAccountModel', {
          data,
        });

        if (data.type === PROFILE) {
          return dispatch('fetchAccountClan', undefined, { root: true });
        }
        if (data.type === ORGANIZATION) {
          dispatch('fetchAccountSocialConnectionStatus', undefined, {
            root: true,
          });
        }
        return data;
      })
      .catch(error => error);
  },
  fetchMyOrganizations({ rootGetters: { accessToken } }) {
    return api.accounts.fetchMyOrganizations(accessToken).then(({ data }) => {
      return data;
    });
  },
};

export default {
  accountsRequests,
  getRequests,
  accounts: {
    state: state(),
    getters: rootGetters,
    mutations: rootMutations,
    actions: rootActions,
    modules: {
      my: {
        namespaced: true,
        state: {},
        actions: { ...myActions },
        mutations: { ...mutations },
        modules: {
          clans: {
            namespaced: true,
            ...clansModule(true),
          },
        },
      },
      current: {
        namespaced: true,
        state: {},
        mutations: { ...mutations },
        modules: {
          clans: {
            namespaced: true,
            ...clansModule(true),
          },
        },
      },
    },
  },
};
