<template>
  <div
    class="bannerContainer"
    :class="{ 'highlights-feed': $route.path.includes('feed') }"
  >
    <button v-if="!isRTL" class="left-arrow pagi-arrow" @click="handleGoLeft">
      <Icon name="arrow-left" width="30" />
    </button>
    <button v-else class="right-arrow pagi-arrow" @click="handleGoRight">
      <Icon name="arrow-left" width="30" :rotate="180" />
    </button>
    <div ref="highlightsContainer" class="itemsContainer scroll">
      <div
        v-for="post in posts.filter(post => post.post.attachments)"
        :key="post.post.id"
        @click="openPost(post.post.id)"
      >
        <div class="highlight">
          <HighlightsMediaGrid
            :media="post.post.attachments"
            :thumbnail="post.post.thumbnail"
          />
          <div class="highlights-flow-items">
            <div class="flow-group-1">
              <img
                class="highlights-image"
                :src="
                  post.post.author.avatar ||
                    require('@/assets/images/defaults/user.svg')
                "
                alt="highlights image"
              />
              <div class="highlights-author highlights-autor-hr">
                {{ post.post.author.display_name || post.post.author.name }}
              </div>
            </div>

            <TextContent
              :content="post.post.text"
              class="highlights-description"
            />
          </div>
        </div>
      </div>
    </div>
    <button v-if="!isRTL" class="right-arrow pagi-arrow" @click="handleGoRight">
      <Icon name="arrow-left" width="30" :rotate="180" />
    </button>
    <button v-else class="left-arrow pagi-arrow" @click="handleGoLeft">
      <Icon name="arrow-left" width="30" />
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { API } from '@/api/config';
import router from '@/router';
import Icon from '@/components/atoms/Icon';
import HighlightsMediaGrid from '@/components/common/HighlightsMediaGrid.vue';
import TextContent from '@/components/text/TextContent';
export default {
  components: {
    Icon,
    HighlightsMediaGrid,
    TextContent,
  },
  data() {
    return {
      posts: [],
      nextPage: '',
    };
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isRTL']),
  },
  created() {
    this.getAllPosts();
  },
  mounted() {
    let element = this.$refs.highlightsContainer;

    element.addEventListener('wheel', this.transformScroll);
    element.addEventListener('scroll', this.handleScroll);
    element.addEventListener('touchmove', this.handleScroll);
  },
  beforeDestroy() {
    let element = this.$refs.highlightsContainer;
    element.removeEventListener('wheel', this.transformScroll);
    element.removeEventListener('scroll', this.handleScroll);
    element.removeEventListener('touchmove', this.handleScroll);
  },
  methods: {
    ...mapActions(['getPostDetails']),
    transformScroll(event) {
      if (!event.deltaY) {
        return;
      }

      event.currentTarget.scrollLeft += event.deltaY + event.deltaX;

      event.preventDefault();
    },
    handleGoLeft() {
      this.$refs.highlightsContainer.style.scrollBehavior = 'smooth';
      this.$refs.highlightsContainer.scrollLeft =
        this.$refs.highlightsContainer.scrollLeft - window.innerWidth / 2;
      this.$refs.highlightsContainer.style.scrollBehavior = 'unset';
    },
    handleGoRight() {
      this.$refs.highlightsContainer.style.scrollBehavior = 'smooth';
      this.$refs.highlightsContainer.scrollLeft =
        this.$refs.highlightsContainer.scrollLeft + window.innerWidth / 2;
      this.$refs.highlightsContainer.style.scrollBehavior = 'unset';
    },
    handleScroll(event) {
      const container = event.target;
      const scrollLeft = container.scrollLeft;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;
      const scrollRight = scrollWidth + scrollLeft - clientWidth;

      if (scrollLeft + clientWidth >= scrollWidth - 10) {
        this.loadNextPosts();
      }
      if (this.isRTL && scrollRight === 0) {
        this.loadNextPosts();
      }
    },
    async loadNextPosts() {
      if (this.isLoading || !this.nextPage) {
        return;
      }

      this.isLoading = true;

      try {
        const response = await API(
          `posts/homepage/highlights?page=${this.nextPage}&page_size=10`
        );
        this.nextPage = response.data.next_page_token;
        this.posts = [...this.posts, ...response.data.items];
      } catch (error) {
        console.error('Error loading next posts:', error);
      }

      this.isLoading = false;
    },
    openPost: function(id) {
      router.push(`/posts/${id}`);
    },
    async getAllPosts() {
      const response = await API(
        `posts/homepage/highlights?page=${this.nextPage}&page_size=10`
      );
      this.nextPage = response.data.next_page_token;
      this.posts = response.data.items;
    },

    async fetchPost(id) {
      if (!id) return;
      const post = await this.getPostDetails(id);
      this.posts = [...this.posts, post];
    },
  },
};
</script>
<style lang="scss" scoped>
@import './variables';

.bannerContainer {
  width: 100%;
  height: $highlights-banner-height;
  background-color: #11171f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlight {
  width: 195.71px;
  height: 316.95px;
  background-color: green;
  border-radius: $hr-radius-01;
  background-size: cover;
  object-fit: cover;
  object-fit: fill;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 77.6%
  ) !important;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .highlights-feed {
    background-color: #0d1117;
  }
}

.highlight::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 77.6%
  ) !important;
  opacity: 1;
  transition: opacity 0.5s ease;
  border-radius: 5px;
}

.highlight:hover:after {
  opacity: 0;
}

.highlight-description {
  max-height: 20px;
  width: 100%;
  overflow: hidden;
}

.itemsContainer {
  display: flex;
  width: $hr-wrapper-width;
  max-width: $hr-wrapper-max-width;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  gap: 10px;
  // scroll-behavior: smooth;
}

@media screen and (max-width: 1483px) {
  .itemsContainer {
    width: 92vw;
  }
}

.itemsContainer::-webkit-scrollbar {
  display: none;
}

.highlights-image {
  width: 23.4px;
  height: 23.92px;
  border-radius: 90%;
  z-index: 5;
  border: 0.5px solid #727288;
  border-radius: 12.5px;
}

.highlights-author {
  font-size: 16px;
  font-weight: 700;
  color: white;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.highlights-flow-items {
  position: absolute;
  z-index: 5;
  bottom: 14px;
  left: 10px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  max-width: 180px;

  .app-rtl & {
    bottom: 14px;
    right: 10px;
  }
}

.highlights-description {
  font-size: 14px;
  font-weight: 200;
  color: white;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d8d;
  margin-top: 4px;
}

.flow-group-1 {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.itemsContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: white;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}

.img-grid-1 {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .itemsContainer {
    // width: calc(100vw - 17px);
    width: 92vw;
    max-height: 400px;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // gap: 2rem;
  }

  .highlight {
    height: 250px;
    width: auto;
    // aspect-ratio: 9/16;
    // @include auto-rtl(margin-right, 1.3rem);
  }

  .bannerContainer {
    height: auto;
    padding-bottom: 1rem;
  }

  .highlights-flow-items {
    max-width: 125px;
    .app-rtl & {
      right: 10px;
    }
  }
}
.highlights-autor-hr {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 995px) {
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;
  }
}
.left-arrow {
  margin-right: -1.3rem;
  z-index: 100;
}
.right-arrow {
  margin-left: -1.3rem;
  z-index: 100;
}
.pagi-arrow {
  border-radius: 50%;
  background: #30374a;
  height: 40px;
  width: 40px;
  font-size: 2rem;
  color: white;
  border: 1px solid #30374a;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    display: none;
  }
}
</style>
