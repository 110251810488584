<template>
  <div class="create-clan-wrapper d-flex flex-column">
    <div class="row">
      <div class="col-12 h-100">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';

export default {
  name: 'CreateClanGeneral',

  computed: {
    ...mapState('current', ['account']),
    currentStep() {
      return this.$route.meta.step;
    },
    hasClanStub() {
      return this.account && !this.account._id;
    },
    headerForm() {
      return this.hasClanStub
        ? this.$t('_web_clans_edit-team', 'Edit team')
        : this.$t('_web_create-team-link', 'Create team');
    },
  },
  beforeRouteEnter(to, from, next) {
    store.commit('current/createAccountModel', {
      accountType: 'clan',
      data: {},
    });
    next();
  },
};
</script>

<style lang="scss">
.create-clan-wrapper {
  max-height: calc(100% - #{$footer-height});
  .step-progress-wrapper {
    display: flex;
    position: relative;

    .line-block {
      width: 24px;
      height: 30px;
      display: flex;
      justify-content: center;
      margin: 0.75rem 0;

      &.big {
        height: 60px;
      }

      .line {
        border-radius: 1px;
        background: $ph-input-bg;
        width: 2px;
        height: 100%;
        display: block;

        &.is-active {
          background: $ph-accent;
        }
      }

      &:after {
        content: attr(data-clan-name);
        font-size: 0.875rem;
        color: $ph-secondary-text;
        display: block;
        position: absolute;
        left: 2rem;
        margin-top: -0.5rem;
      }
    }

    .btn-icon-text {
      font-size: 0.875rem;
    }
  }
}
</style>
