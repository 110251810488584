import api from '@/api';

const actions = {
  addLoginToPlatform: ({ getters: { accessToken } }, payload) => {
    return api.platforms.postPlatformLogin(accessToken, payload);
  },
};

export default {
  actions,
};
