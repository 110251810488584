<template>
  <TextInputMultiline
    id="description-accounts-edit"
    v-model="accountDescription"
    :rows="rows"
    :maxlength="500"
    fixed
    :has-counter="hasCounter"
    :padding-bottom="paddingBottom"
    :label="$t('_web_profile-edit-description-label', 'Description')"
    :data-vv-as="$t('_web_profile-edit-description-label', 'Description')"
    :placeholder="
      $t('_web_profile-edit_description-placeholder', 'Description')
    "
  />
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import TextInputMultiline from '@/components/molecules/form/TextInputMultiline';

export default {
  name: 'AccountEditDescription',
  components: {
    TextInputMultiline,
  },
  props: {
    join: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 4,
    },
    hasCounter: {
      type: Boolean,
      default: true,
    },
    paddingBottom: {
      type: String,
      default: '8px',
    },
  },
  computed: {
    ...mapGetters(['accountEditData']),
    ...mapState('current', ['account']),
    accountDescription: {
      get() {
        return this.accountEditData.description;
      },
      set(description) {
        this.updateEditData({ description });
      },
    },
  },
  methods: {
    ...mapMutations(['updateEditData']),
  },
};
</script>
