import {
  authorizedGetRequest,
  authorizedDeleteRequest,
  authorizedPutRequest,
} from '@/api/requests';

const SOCIALS_BASE = 'platforms/social';

export const getSocialConnectionStatus = accountID =>
  authorizedGetRequest(`${SOCIALS_BASE}/${accountID}`, {
    preventCancelRequest: true,
  });
export const setSocialConnections = accountID =>
  authorizedPutRequest(`${SOCIALS_BASE}/${accountID}`);
export const deleteSocialConnection = ({ accountID, platform }) =>
  authorizedDeleteRequest(`${SOCIALS_BASE}/${accountID}/${platform}`);
export const requestTwitterToken = authorizedGetRequest(
  `${SOCIALS_BASE}/twitter/request_token`
);

export default {
  getSocialConnectionStatus,
  setSocialConnections,
  deleteSocialConnection,
  requestTwitterToken,
};
