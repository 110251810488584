<template>
  <div v-if="popularClans.length" class="my-5 popular-clans">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <TextBlock
        :size="1.125"
        :text="$t('_web_newsfeed_popular-teams', 'Popular Teams')"
      />
      <router-link :to="{ name: 'clans' }" class="font-size-sm">
        {{ $t('_web_newsfeed_popular-clans-see-all', 'See All') }}
      </router-link>
    </div>
    <Scroller :items="popularClans">
      <ClanCard slot="item" slot-scope="{ item }" :clan="item" />
    </Scroller>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import TextBlock from '@/components/atoms/TextBlock';
import Scroller from '../Scroller';
import ClanCard from './ClanCard';

export default {
  components: { TextBlock, ClanCard, Scroller },

  computed: {
    ...mapGetters(['popularClans', 'popularClansIsLoading']),
  },
  created() {
    this.fetchPopularClans().catch(this.errorNotify);
  },
  methods: {
    ...mapActions(['fetchPopularClans', 'errorNotify']),
  },
};
</script>
