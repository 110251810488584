<template>
  <div class="auth-btn-wrapper">
    <button class="auth-close-btn" type="button" @click="goBack">
      <img src="@/assets/images/auth/close.svg" alt="close-button" />
    </button>
  </div>
</template>

<script>
import { cloneDeep } from '@/utils/lodashUtils';
import { mapGetters } from 'vuex';
import { getRouterHistory } from '@/utils/routerHistory';
export default {
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    historyMode: {
      type: Boolean,
      default: false,
    },
    backRouteName: {
      type: String,
      default: '',
    },
    directPath: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['isRTL']),
  },
  methods: {
    goBack() {
      if (this.historyMode) {
        const n = this.findLastVisited();
        return n ? this.$router.go(-n) : this.$router.push('/');
      } else if (this.directPath) {
        this.$router.push(this.directPath);
      } else {
        const parentRoute = this.findParentRoute();
        this.$router.push({
          name: parentRoute.resolved.name,
          params: {
            ...cloneDeep(parentRoute.location.params),
            ...cloneDeep(parentRoute.resolved.params),
          },
          meta: cloneDeep(parentRoute.resolved.meta),
        });
      }
    },
    findLastVisited() {
      const authRoutes = ['signup', 'signin']; // Skip these routes
      const routerHistory = getRouterHistory() || [];
      return (
        routerHistory
          .reverse()
          .findIndex(route => !authRoutes.includes(route)) + 1 || null
      );
    },
    findParentRoute() {
      let findedRoute = null;
      for (let i = this.$route.matched.length - 2; i > 0; i--) {
        let path = this.$route.matched[i].path;
        // TODO - TH-7084 May work not well with GET params in URI. Like a .../players?sort=Username. Investigation and testing required.
        Object.keys(this.$route.params).forEach(param => {
          path = path.replace(
            RegExp(`:${param}\\??`, 'i'),
            this.$route.params[param]
          );
        });
        const resolved = this.$router.resolve(path);
        if (resolved.route.name !== this.$route.name) {
          if (this.backRouteName) {
            if (resolved.route.name === this.backRouteName) {
              findedRoute = resolved;
              break;
            }
          } else {
            findedRoute = resolved;
            break;
          }
        }
      }
      if (!findedRoute) {
        return this.$router.resolve('/');
      } else {
        return findedRoute;
      }
    },
  },
};
</script>

<style>
.auth-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.auth-close-btn {
  background: none;
  border: none;
}
</style>
