<template>
  <div>
    <HeraFormSwitch
      v-model="tieGameAllowedModel"
      v-validate="'required'"
      name="tieGameAllowed"
      :label="$$t('Tie-result allowed for games')"
      :disabled="
        isStageFieldDisabled('tieGameAllowed') ||
          !tieMatchAllowed ||
          bestOf === 1 ||
          disabled
      "
      required
    />
  </div>
</template>

<script>
import { translate } from '@/mixins/translate';

import stageSetupHelpers from '@/views/tournaments/management/singleTournament/mixins/stageHelpers';
import HeraFormSwitch from '@/components/atoms/form/HeraFormSwitch';
import { prop } from '@/utils/factories';
export default {
  name: 'StageTieGameSetting',
  components: {
    HeraFormSwitch,
  },
  inject: ['$validator'],
  mixins: [translate('_web_tournament_stage-editor'), stageSetupHelpers],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    bestOf() {
      return this.getBlockConfigurationByKey('best_of');
    },
    tieGameAllowedModel: {
      get() {
        return this.getBlockConfigurationByKey('tie_game', false);
      },
      set(newVal) {
        this.setBlockConfigurationByKey('tie_game', newVal);
      },
    },
  },
};
</script>

<style></style>
