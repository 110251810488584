<template>
  <div>
    <SegmentControl
      v-model="availabilityType"
      v-bind="{
        id: 'stagePlaceType',
        viewMode: disabled,
        options: stageAvailabilityTypes,
        required: !disabled,
        error: errors.first('stagePlaceType'),
        label: $t('_web_tournament_stage_type-of-stage-label', 'Type'),
        tooltip: $t(
          '_web_tournament_stage_type-tooltip',
          'Switcher defines if a current stage is conducted online or offline. For offline location must be provided'
        ),
        readOnly: disabled,
        disabled: isStageFieldDisabled('stagePlaceType') || disabled,
        'data-vv-name': 'stagePlaceType',
        'data-vv-as': $t('_web_tournament_stage_type-of-stage-label', 'Type'),
      }"
      @input="availabilityTypeChanged"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import SegmentControl from '@/components/atoms/form/HeraFormSegmentControl';
import { prop } from '@/utils/factories';
export default {
  name: 'StageAvailabilitySetting',
  components: {
    SegmentControl,
  },
  inject: ['$validator'],
  props: {
    disabled: prop(Boolean),
  },
  computed: {
    ...mapGetters(['currentTournamentStage', 'isStageFieldDisabled']),
    stageAvailabilityTypes() {
      return [
        { label: 'Online', value: 'online', id: 'online' },
        { label: 'Offline', value: 'offline', id: 'offline' },
      ];
    },
    availabilityType: {
      get() {
        return this.$lodash.get(
          this.currentTournamentStage,
          'availability_type',
          'online'
        );
      },
      set(newVal) {
        this.updateCurrentTournamentStage({
          availability_type: newVal,
        });
        if (newVal === 'online') {
          this.updateCurrentTournamentStage({
            location: {},
          });
          this.errors.remove('location');
          this.errors.remove('locationValidity');
        }
      },
    },
    // isOfflineTournament() {
    //   return this.availabilityType !== 'online';
    // },
  },
  methods: {
    ...mapMutations(['updateCurrentTournamentStage']),
    availabilityTypeChanged(value) {
      this.availabilityType = value;
    },
  },
};
</script>

<style></style>
