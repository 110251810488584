var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-chat-message__wrapper d-flex align-items-center justify-content-center",class:{
    'align-self-start': !_vm.isPost,
    'align-self-end': _vm.isMy && !_vm.isPost,
  }},[(!_vm.isMy && !_vm.isPost)?_c('Avatar',{staticClass:"slider-chat-message__avatar align-self-end",attrs:{"type":"user","size":28,"img-url":_vm.data.sender_avatar},nativeOn:{"click":function($event){return _vm.$emit('openUserInfo')}}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column position-relative",class:{ 'order-1': _vm.isMy && !_vm.isPost }},[_c('div',{staticClass:"slider-chat-message",class:{
        'slider-chat-message_post': _vm.isPost,
        'slider-chat-message_my': _vm.isMy && !_vm.isPost,
        'slider-chat-message_undelivered': _vm.undelivered,
        'order-1': _vm.isMy && !_vm.isPost,
        'slider-chat-message_full-width': _vm.hasMediaAttach && !_vm.isPost,
      }},[(_vm.isPost)?_c('div',{staticClass:"slider-chat-message__header d-flex"},[_c('span',{staticClass:"slider-chat-message__postedby"},[_vm._v(_vm._s(_vm.$t('_web_tournament_slidechat_posted-by:', 'Posted by:')))]),_vm._v("\n        "+_vm._s(_vm.data.sender_name || _vm.data.sender)+"\n      ")]):_vm._e(),_c('TextContent',{staticClass:"p-3 slider-chat-message__text",attrs:{"content":_vm.data.text}}),(_vm.data.attachments && _vm.data.attachments.length)?_c('Attachments',{attrs:{"attachments":_vm.data.attachments}}):_vm._e(),_c('div',{staticClass:"slider-chat-message__footer d-flex justify-content-end",class:{ 'justify-content-between': _vm.isPost }},[(_vm.isPost)?_c('div',{staticClass:"slider-chat-message__comments d-flex",on:{"click":_vm.openComments}},[_c('Icon',{staticClass:"mx-1",attrs:{"name":"comments","width":"12px"}}),(!_vm.data.comments_count)?_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('_web_tournament_sidechat_no-comments', 'No comments'))+"\n          ")]):_c('span',{staticClass:"slider-chat-message__comments-count"},[_vm._v("\n            "+_vm._s(_vm.data.comments_count)+"\n            "+_vm._s(_vm.$t('_web_tournament_sidechat_comments', 'comments'))+"\n          ")])],1):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("localDate")(_vm.data.sent_date,_vm.$times.DATETIME_MED)))])])],1),(!_vm.isMy && !_vm.isPost)?_c('span',{staticClass:"slider-chat-message__sender-info"},[_vm._v(_vm._s(_vm.senderInfo))]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.undelivered),expression:"undelivered"}],staticClass:"slider-chat-message__tryagain order-2",class:{ 'slider-chat-message__tryagain_post': _vm.isPost }},[_c('Icon',{staticClass:"mx-1",attrs:{"name":"warning","width":"14px"}}),_vm._v("\n      "+_vm._s(_vm.$t(
          '_web_tournament_sidechat_no-delivered',
          'The message was no delivered.'
        ))+"\n      "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('resend')}}},[_vm._v(_vm._s(_vm.$t('_web_tournament_sidechat_try-again', 'Try again.')))])],1)]),(_vm.isMy)?_c('div',{class:[
      'slider-chat-message__dots p-1 mx-1',
      { 'order-0': _vm.isMy && !_vm.isPost, 'mb-3': !_vm.isMy && !_vm.isPost } ],on:{"click":_vm.dotsClickHandler}},[_c('Icon',{attrs:{"name":"dots"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }