import { render, staticRenderFns } from "./TextBlock.vue?vue&type=template&id=e0ea0aac&"
import script from "./TextBlock.vue?vue&type=script&lang=js&"
export * from "./TextBlock.vue?vue&type=script&lang=js&"
import style0 from "./TextBlock.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TextBlock.vue?vue&type=custom&index=0&blockType=story&options=%7B%0A%20%20%20%20panelPosition%3A%20'right'%0A%20%20%7D&group=Common%7CAtoms%2FText&name=TextBlock&notes=%23%23%20this%20component%20has%20a%20slot%20which%20could%20hold%20any%20other%20component%20inside%20it&knobs=%7B%0A%20%20%20%20text%3A%20%7B%0A%20%20%20%20%20%20default%3A%20text('Inner%20text'%2C%20'I%20am%20a%20text%20in%20the%20textblock%20%3B)')%0A%20%20%20%20%7D%2C%0A%20%20%20%20variant%3A%20%7B%0A%20%20%20%20%20%20default%3A%20select('variant'%2C%20%5B%0A%20%20%20%20%20%20%20%20'primary'%2C%0A%20%20%20%20%20%20%20%20'secondary'%2C%0A%20%20%20%20%20%20%20%20'muted'%2C%0A%20%20%20%20%20%20%20%20'error'%2C%0A%20%20%20%20%20%20%20%20'success'%2C%0A%20%20%20%20%20%20%20%20'bold'%2C%5D%2C%20'primary')%0A%20%20%20%20%7D%2C%0A%20%20%20%20size%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('Size'%2C%200.875)%0A%20%20%20%20%7D%2C%0A%20%20%20%20lineHeight%3A%20%7B%0A%20%20%20%20%20%20default%3A%20number('lineHeight'%2C%201.2)%0A%20%20%20%20%7D%2C%0A%20%20%20%20unsetStyles%3A%20%7B%0A%20%20%20%20%20%20%20default%3A%20boolean('unsetStyles'%2C%20false)%0A%20%20%20%20%7D%2C%0A%20%20%20%20tag%3A%20%7B%0A%20%20%20%20%20%20%20default%3A%20text('tag'%2C%20'span')%0A%20%20%20%20%7D%0A%20%20%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports