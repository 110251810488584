import mains from '@/constants/router/mains';
import accounts from '@/constants/router/account';

export const PROFILE_SETUP = 'profileSetup';
export const SUBSCRIPTION_SETUP = 'subscriptionSetup';
export const CONVERSATIONS = 'conversations';
export const POST = 'post';
export const REPLY = 'reply';
export const SEARCH = 'search';
export const VANITY_PREFIX = '';
export const BILLING = 'billing';
// removed 'vanity-' from VANITY_PREFIX

export default {
  ...mains,
  ...accounts,
  PROFILE_SETUP,
  SUBSCRIPTION_SETUP,
  CONVERSATIONS,
  POST,
  REPLY,
  SEARCH,
  BILLING,
};
