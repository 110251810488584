var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"action-item-wrapper overflow-hidden px-4 pb-4 pt-0",class:{
    disabled: _vm.action.disabled || _vm.action.isPast,
    automated: _vm.action.automated,
    'cursor-pointer': _vm.action.handler,
  },attrs:{"variant":['transparent']}},[_c('div',{staticClass:"action h-100"},[_c('div',{staticClass:"action__label",class:{ 'action__done-text': _vm.action.isPast }},[_c('Icon',{staticClass:"mr-4",attrs:{"width":"18","name":_vm.action.isPast
            ? 'check-mark-charped'
            : _vm.action.disabled
            ? 'time'
            : 'arrow-right-big',"fill":_vm.action.disabled ? _vm.$scss.heraMuted : 'white'}}),_vm._v("\n      "+_vm._s(_vm.action.label)+"\n    ")],1),_c('div',{staticClass:"d-flex align-items-center h-100 flex-grow-1",staticStyle:{"margin-left":"38px"}},[(_vm.action.automated)?_c('div',{staticClass:"action__auto"},[_vm._v("\n        "+_vm._s(_vm.$t('_web_auto', 'auto'))+"\n      ")]):_vm._e(),_c('div',{staticClass:"action__date"},[_vm._v("\n        "+_vm._s(_vm._f("localDate")(_vm.action.date,_vm.$times.DATETIMEWEEKDAY_MED))+"\n      ")])])]),(!_vm.action.disabled)?_c('Btn',{staticClass:"mt-3 ml-6 btn-primary",on:{"click":_vm.action.handler}},[_vm._v("\n    "+_vm._s(_vm.action.label)+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }