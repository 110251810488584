import { USER_LOCALE } from '@/api/user-locale';
import { authorizedPutRequest, simpleGetRequest } from '@/api/requests';

const TRANSLATIONS_BASE = 'translations';

const getTranslationByLocale = (lang = USER_LOCALE) =>
  simpleGetRequest(`${TRANSLATIONS_BASE}/${lang}`);

const getLocales = simpleGetRequest(`${TRANSLATIONS_BASE}/locales`);

const putTranslation = (lang = USER_LOCALE) =>
  authorizedPutRequest(`${TRANSLATIONS_BASE}/${lang.toLowerCase()}`);

export default {
  getLocales,
  getTranslationByLocale,
  putTranslation,
};
