<template>
  <Autosuggest
    v-bind="{
      value,
      label,
      minLength: 1,
      error,
      variant,
      showOnFocus: true,
      signup: true,
      displayProperty: 'text',
      source: suggestCountriesRequest,
      required,
      tabindex,
    }"
    @resultPicked="$emit('input', $event)"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { emptyObject, prop } from '@/utils/factories';
import Autosuggest from './HeraCountrySuggest';

export default {
  components: {
    Autosuggest,
  },

  props: {
    value: {
      type: null,
      default: emptyObject,
    },
    label: prop(String),
    error: prop(String),
    variant: prop(String, 'default'),
    required: prop(Boolean),
    tabindex: prop(Number),
  },

  methods: {
    ...mapActions(['suggestCountriesRequest']),
  },
};
</script>
