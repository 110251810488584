import { authorizedGetRequest, simpleGetRequest } from '@/api/requests';

const SETTINGS_BASE = 'settings/';
const CALLING_CODES = SETTINGS_BASE + 'calling_codes';
const SUGGEST_COUNTRIES = SETTINGS_BASE + 'suggest/countries';
const COUNTRIES = SETTINGS_BASE + 'countries';
const SUGGEST_CITY = SETTINGS_BASE + 'suggest/city';
const GENDERS = SETTINGS_BASE + 'genders';
const PLATFORMS = SETTINGS_BASE + 'platforms';
const GENRES = SETTINGS_BASE + 'genres';
const GAMES = SETTINGS_BASE + 'games';
const MAINTENANCE = SETTINGS_BASE + 'maintenance';
const QR_CODE = SETTINGS_BASE + 'qrcodesettings';

const getCallingCodes = simpleGetRequest(CALLING_CODES, {
  preventCancelRequest: true,
});

const suggestCountries = query =>
  simpleGetRequest(
    `${SUGGEST_COUNTRIES}${query ? '?Prefix=' : ''}${query ? query : ''}`
  );

const getCountryByCode = code => simpleGetRequest(`${COUNTRIES}/${code}`);

const suggestCities = (country, query) =>
  simpleGetRequest(`${SUGGEST_CITY}?Country=${country}&Prefix=${query}`);

const getGenders = simpleGetRequest(GENDERS, {
  preventCancelRequest: true,
});
const getPlatforms = authorizedGetRequest(PLATFORMS, {
  preventCancelRequest: true,
});

const getGenres = (published = false) =>
  authorizedGetRequest(GENRES + `?published_only=${published.toString()}`, {
    preventCancelRequest: true,
  });

const getGames = (published = false) =>
  authorizedGetRequest(GAMES + `?published_only=${published.toString()}`, {
    preventCancelRequest: true,
  });

const getMaintenance = () =>
  simpleGetRequest(MAINTENANCE, { preventCancelRequest: true })();

const getQrSettings = authorizedGetRequest(QR_CODE, {
  preventCancelRequest: true,
});
export default {
  getCallingCodes,
  getMaintenance,
  getCountryByCode,
  suggestCountries,
  suggestCities,
  getGenders,
  getPlatforms,
  getGenres,
  getGames,
  getQrSettings,
};
