<template>
  <div class="plans-container">
    <div class="plans-wrapper d-flex">
      <div class="subscription-announcement-wrapper">
        <div class="full-one">
          <div class="subscription-description">
            <h1 class="subscription-title">
              {{
                $t(
                  '_web_subscription_announcement_title',
                  'PLAYHERA Max is Coming Soon!'
                )
              }}
            </h1>
            <img
              class="ph-bg-img-mobile"
              src="@/assets/images/background/subscription-default.jpg"
              alt=""
            />
            <p class="subtitle-text">
              {{
                $t(
                  '_web_subscription_announcement_title_subtitle',
                  'The future of gaming.'
                )
              }}
            </p>
            <p class="description-text">
              {{
                $t(
                  '_web_subscription_announcement_subtitle',
                  ` Over 500 game titles. We’ve got award winning, most popular, and new games constantly updating. Play your
              favourite games anywhere and at any time.`
                )
              }}
            </p>
          </div>
          <div class="what-is-phmax">
            <h4>
              {{
                $t(
                  '_web_subscription_announcement_explain_title',
                  'What is PLAYHERA Max?'
                )
              }}
            </h4>
            <p class="ph-max-description">
              {{
                $t(
                  '_web_subscription_announcement_explain_text',
                  `PLAYHERA MAX is a cloud gaming platform that provides two-click access to high-end video games with amazing
              gaming experience regardless of the type of device. Play your favourite games at 60 fps (Full HD 1080p) on a
              PC, laptop, tablet, Android TV, or even phone.`
                )
              }}
            </p>
            <p class="input-label">
              {{
                $t(
                  '_web_subscription_announcement_cta',
                  'To be the first to know about PLAYHERA subscribe to our email list.'
                )
              }}
            </p>
          </div>
          <div class="input-wrapper-subscriptions">
            <InputEmail
              v-model.trim="email"
              v-focus
              v-validate="'email|max:128'"
              class="input-email"
              name="email"
              type="email"
              :placeholder="$t('_web_forgotpassword_email-label', 'Email')"
              :tabindex="1"
              autofocus
              autocomplete-off
              :error="errors.first('email')"
            />
            <Btn
              class="btn-accent"
              :loading="isLoading"
              :label="
                $t('_web_subscription_announcement_subscribe', 'SUBSCRIBE')
              "
              @click="saveEmail"
            />
          </div>
        </div>
        <div class="empty-one">
          <img
            class="ph-bg-img"
            src="@/assets/images/background/subscription-default.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { translate } from '@/mixins/translate';
import InputEmail from '@/components/form/InputEmail';
import Btn from '@/components/atoms/HeraFormBtn';

export default {
  name: 'DefaultPage',
  components: { Btn, InputEmail },
  mixins: [translate('_web_subscription')],
  inject: ['$validator'],
  data() {
    return {
      isLoading: false,
      email: '',
    };
  },

  methods: {
    ...mapActions(['collectEmails', 'errorNotify', 'successNotify']),
    saveEmail() {
      if (!this.errors.count()) {
        this.isLoading = true;
        this.collectEmails({ email: this.email })
          .then(() => {
            this.successNotify({
              text: this.$t('_web_clan_join_sent', 'Request Sent'),
            });
          })
          .catch(err => {
            this.errorNotify(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss">
.plans-container {
  .new-email-input {
    text-decoration: none;
  }

  @media (max-width: 900px) {
    .new-email-input {
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
.plans-container {
  width: 100%;
  height: 100%;
  background-color: #0d1117;
}

.ph-bg-img {
  display: flex;
  height: 80vh;
  min-height: 800px;
  max-height: 800px;
  /* for webkit-based browsers */
  -webkit-mask-image: linear-gradient(to top, #0d1117 0%, #0d1117 100%),
    linear-gradient(to top, transparent 0%, #0d1117 100%),
    linear-gradient(to right, transparent 0%, #0d1117 100%),
    linear-gradient(to bottom, transparent 0%, #0d1117 100%),
    linear-gradient(to left, transparent 0%, #0d1117 100%);
  -webkit-mask-position: center, top, right, bottom, left;
  -webkit-mask-size: 100% 100%, 100% 35%, 35% 100%, 100% 35%, 35% 100%;
  -webkit-mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  -webkit-mask-composite: source-out, source-over, source-over, source-over;

  /* for browsers which have implemented the official spec */
  mask-image: linear-gradient(to top, #0d1117 0%, #0d1117 100%),
    linear-gradient(to top, transparent 0%, #0d1117 100%),
    linear-gradient(to right, transparent 0%, #0d1117 100%),
    linear-gradient(to bottom, transparent 0%, #0d1117 100%),
    linear-gradient(to left, transparent 0%, #0d1117 100%);
  mask-position: center, top, right, bottom, left;
  mask-size: 100% 100%, 100% 35%, 35% 100%, 100% 35%, 35% 100%;
  mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  mask-composite: subtract, add, add, add;
}

.plans-wrapper {
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.input-label {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  color: $new-border-primary;
}

.input-wrapper-subscriptions {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 64%;
}

.ph-bg-img-mobile {
  display: none;
}

@media (max-width: 900px) {
  .ph-bg-img-mobile {
    display: flex;
    -webkit-mask-image: linear-gradient(to top, #0d1117 0%, #0d1117 100%),
      linear-gradient(to top, transparent 0%, #0d1117 100%),
      linear-gradient(to right, transparent 0%, #0d1117 100%),
      linear-gradient(to bottom, transparent 0%, #0d1117 100%),
      linear-gradient(to left, transparent 0%, #0d1117 100%);
    -webkit-mask-position: center, top, right, bottom, left;
    -webkit-mask-size: 100% 100%, 100% 35%, 35% 100%, 100% 35%, 35% 100%;
    -webkit-mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    -webkit-mask-composite: source-out, source-over, source-over, source-over;

    mask-image: linear-gradient(to top, #0d1117 0%, #0d1117 100%),
      linear-gradient(to top, transparent 0%, #0d1117 100%),
      linear-gradient(to right, transparent 0%, #0d1117 100%),
      linear-gradient(to bottom, transparent 0%, #0d1117 100%),
      linear-gradient(to left, transparent 0%, #0d1117 100%);
    mask-position: center, top, right, bottom, left;
    mask-size: 100% 100%, 100% 35%, 35% 100%, 100% 35%, 35% 100%;
    mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    mask-composite: subtract, add, add, add;
  }

  .ph-bg-img {
    display: none;
  }

  .empty-one {
    display: none !important;
  }

  .subscription-title {
    justify-content: center;
    text-align: center;
  }

  .subtitle-text,
  .description-text,
  .what-is-phmax {
    text-align: center;
  }

  .input-wrapper-subscriptions {
    align-items: center;
    width: 100%;
  }

  .input-email {
    width: 100%;
    padding-bottom: 1rem;
    text-align: center;
    max-width: 460px !important;
  }

  .btn-accent {
    width: 100%;
  }

  .subscription-announcement-wrapper {
    width: 80vw !important;
  }

  .input-label {
    text-align: center;
  }

  .full-one {
    min-width: 0px !important;
    @include auto-rtl(margin-right, -8rem);
  }
}

@media (max-width: 420px) {
  .subscription-title {
    font-size: 34px !important;
  }
}

@media (max-width: 507px) {
  .subscription-description {
    font-size: 22px !important;
  }

  .ph-max-description {
    font-size: 13px !important;
  }

  .input-label {
    font-size: 13px !important;
  }
}

@media (max-width: 588px) {
  .subscription-description {
    font-size: 25px !important;
  }

  .subscription-announcement-wrapper {
    width: 90vw !important;
  }
}

@media (max-width: 851px) {
  .btn-accent {
    margin-bottom: 6rem !important;
  }
}

@media (max-width: 801px) {
  .btn-accent {
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 1254px) {
  .btn-accent {
    margin-bottom: 2rem;
  }
}

.ph-bg-img {
  .app-rtl & {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.ph-max-logo {
  width: 137px;
}

.description-text {
  font-size: 23px;
}

.subscription-title {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.subtitle-text {
  padding-top: 1rem;
  font-weight: 600;
}

.subscription-description {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 130%;
  display: flex;
  flex-direction: column;
  color: $new-bg-primary;
  margin-top: 2.5rem;
}

.ph-max-description {
  font-size: 15px;
}

.what-is-phmax {
  display: flex;
  flex-direction: column;
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  margin-top: 1rem;
  gap: 15px;
}

.subscription-announcement-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: $hr-wrapper-width; // 80vw
  max-width: $hr-wrapper-max-width; //1511 after paddings
  z-index: 2;
  margin-top: 4rem;
}

.full-one {
  @include auto-rtl(margin-right, -18rem);
  width: 100%;
  height: 100%;
  max-width: 700px;
  min-width: 640px;
  margin-left: 0px;
}

.empty-one {
  display: flex;
  height: 100%;
  width: 60%;
  justify-content: flex-start;
  margin-top: -8.7rem;
}

.btn-accent {
  background-color: $new-bg-primary !important;
  margin-bottom: 2rem !important;
  max-width: 460px;
}
</style>
