<template>
  <Panel
    class="tournament-info_format pb-0"
    :variant="['transparent', 'rounded-pre-medium']"
  >
    <Subheader
      class="mb-4 text-uppercase font-weight-normal"
      :text="$t('_web_tournament-info_format', 'Format')"
      :text-block-props="{ size: 0.875, lineHeight: 1.5 }"
      variant="hr-secondary"
    />
    <div class="info-items">
      <InfoItem
        v-for="stage in stages"
        v-show="stages !== undefined"
        :key="stage"
        :value="stage"
        class="rectangle-format"
      />
      <InfoItem
        v-show="squad !== undefined"
        :value="squad + $t('web_vs', 'vs') + squad"
        class="rectangle-format"
      />
      <InfoItem
        v-show="bestof !== undefined"
        :value="bestof"
        class="rectangle-format"
      />
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoItem from './infoItem';
import Panel from '@/components/atoms/Panel';
import Subheader from '@/components/atoms/Subheader';
export default {
  name: 'TournamentInfoFormat',
  components: {
    Panel,
    InfoItem,
    Subheader,
  },
  computed: {
    ...mapGetters(['currentTournament', 'currentTournamentStages']),
    stages() {
      const formatType = {
        single_elimination: this.$t('single_elimination', 'Single Elimination'),
        double_elimination: this.$t('double_elimination', 'Double Elimination'),
        round_robin: this.$t('round_robin', 'Round Robin'),
        swiss_system: this.$t('swiss_system', 'Swiss'),
        battle_royale: this.$t('battle_royale', 'Battle Royale'),
      };
      return [
        ...new Set(
          this.currentTournamentStages.map(
            stage => formatType[stage.block_configuration.type]
          )
        ),
      ];
    },
    bestof() {
      const bestofType = {
        1: this.$t('_web_tournament_best-of', 'Best of') + ' ' + 1,
        3: this.$t('_web_tournament_best-of', 'Best of') + ' ' + 3,
        5: this.$t('_web_tournament_best-of', 'Best of') + ' ' + 5,
        7: this.$t('_web_tournament_best-of', 'Best of') + ' ' + 7,
      };
      const minBestOf = Math.min(
        ...[
          ...new Set(
            this.currentTournamentStages.map(
              bestof => bestof.block_configuration.best_of
            )
          ),
        ]
      );
      return bestofType[minBestOf];
    },
    squad() {
      return this.currentTournament.squad_size;
    },
  },
};
</script>

<style lang="scss">
.info-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: fit-content;
  gap: 0 12px;
  .rectangle-format {
    border: 0.5px solid #ffffff;
    border-radius: 5px;
    padding: 0px 10px;
    white-space: nowrap;
  }
}
</style>
