import api from '@/api';

const actions = {
  getEntityDetailsByVanityUrl(
    {
      getters: { accessToken },
    },
    url
  ) {
    return api.vanityUrls
      .getEntityByUrl(url)(accessToken)
      .then(response => response.data);
  },
  getEntityDetailsByVanityUrlNoAuth(
    {
      getters: { accessToken },
    },
    url
  ) {
    return api.vanityUrls
      .getEntityByUrlNoAuth(url)(accessToken)
      .then(response => response.data);
  },

  checkVanityUrlForExisting(state, url) {
    return api.vanityUrls
      .checkUrl(url)()
      .then(response => response.data);
  },
};

export default {
  actions,
};
