<template>
  <WidgetWrapper
    :title="$$t('Timeline')"
    class="bg-transparent border border-hover ph-panel--rounded-small p-5"
  >
    <TextBlock
      v-if="noEvents"
      :text="$$t('No events to show')"
      tag="p"
      variant="secondary"
      class="mb-0"
    />
    <template v-else>
      <Panel
        v-for="(events, key) in grouppedEvents"
        :key="key"
        :variant="['dark-bg', 'rounded', 'half-paddings']"
        class="mb-4 timeline-card"
      >
        <TextBlock
          :text="key"
          tag="h4"
          class="border-bottom border-muted pb-3"
        />
        <div
          v-for="(event, index) in events"
          :key="`${key}-${index}`"
          class="mt-3"
        >
          <template v-if="event.key === 'matches'">
            <p
              v-for="line in event.text"
              :key="line.text + line.count"
              class="d-flex justify-content-between"
            >
              <TextBlock :text="line.text" />
              <TextBlock :text="line.count" />
            </p>
          </template>
          <TextBlock
            v-else
            :text="event.text"
            tag="p"
            :class="{ 'mb-0': event.isAutomated }"
          />
          <!--          <Badge
            v-if="event.isAutomated"
            v-bind="{
              text: $t('_web_auto', 'auto'),
              variant: 'green',
            }"
            class="mt-2"
          />-->
        </div>
      </Panel>
    </template>
  </WidgetWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import { ISOSecondsConvertion } from '@/mixins/dateMethods';
import WidgetWrapper from '@/views/tournaments/management/dashboard/components/WidgetWrapper';
import Panel from '@/components/atoms/Panel';
import TextBlock from '@/components/atoms/TextBlock';
// import Badge from '@/components/atoms/HeraBadge';
const EVENT_TYPES = {
  REMINDER: 'reminder',
  STATUS_CHANGER: 'status',
  SUMMARY: 'summary',
};
import {
  TOURNAMENT_STATUSES,
  STAGE_STATUSES,
  // CONSTRUCTOR_MODES,
} from '@/constants/tournaments';
// const MATCH_SUMMARY_SEPARATOR = '-';

export default {
  name: 'TimelineWidget',
  components: {
    WidgetWrapper,
    Panel,
    TextBlock,
    // Badge,
  },

  mixins: [ISOSecondsConvertion, translate('_web_tournament_dashboard')],
  data() {
    return {
      // matchesCountSummary: [
      //   // {
      //   //   date: this.toSeconds(this.endOfDayObj()),
      //   //   summary: {
      //   //     total: 991,
      //   //     started: 992,
      //   //     completed: 993,
      //   //     not_started: 994,
      //   //     cancelled: 995,
      //   //     judging: 996,
      //   //   },
      //   // },
      //   // {
      //   //   date: this.getNowSeconds() + 3600 * 24 * 2,
      //   //   summary: {
      //   //     total: 993,
      //   //   },
      //   // },
      // ],
    };
  },
  computed: {
    ...mapGetters([
      'currentTournament',
      'hasCheckInInFirstStage',
      'currentTournamentStages',
      'isTournamentPastStatus',
      'isStagePastStatus',
      'isSingleStage',
      'isSingleBlock',
      'getActiveMatchesSummary',
    ]),
    currentTournamentSchedule() {
      return this.currentTournament ? this.currentTournament.schedule : {};
    },
    timelineEvents() {
      return [
        {
          key: 'announce',
          eventType: EVENT_TYPES.REMINDER,
          datetime: this.currentTournamentSchedule.registration_start,
          textStub: 'Tournament must be announced till {date}',
          isAutomated: false,
          visible: !this.isTournamentPastStatus(TOURNAMENT_STATUSES.ANNOUNCED),
        },
        {
          key: 'registration-open',
          eventType: EVENT_TYPES.STATUS_CHANGER,
          datetime: this.currentTournamentSchedule.registration_start,
          textStub: 'Registration will be opened on {date} @ {time}',
          isAutomated: true,
          visible: !this.isTournamentPastStatus(TOURNAMENT_STATUSES.REG_OPENED),
        },
        {
          key: 'registration-close',
          eventType: EVENT_TYPES.STATUS_CHANGER,
          datetime: this.currentTournamentSchedule.registration_end,
          textStub: 'Registration will be closed on {date} @ {time}',
          isAutomated: true,
          visible: !this.isTournamentPastStatus(TOURNAMENT_STATUSES.REG_CLOSED),
        },
        ...(this.currentTournamentStages || []).reduce(
          (acc, stage) => [
            ...acc,
            {
              key: 'qualification',
              eventType: EVENT_TYPES.REMINDER,
              datetime: !stage.check_in_enabled
                ? stage.date_start
                : stage.check_in_start_date,
              textStub: this.isSingleStage
                ? 'Participants must be qualified till {date} @ {time}'
                : `Participants for ${
                    stage.title
                  } must be qualified till {date} @ {time}`,
              isAutomated: true, //TODO
              visible: !this.isStagePastStatus(STAGE_STATUSES.PUBLISHED, stage),
            },
            {
              key: 'brackets-build-and-publish',
              eventType: EVENT_TYPES.REMINDER,
              datetime: stage.date_start,
              textStub: this.isSingleStage
                ? 'Bracket must be built and published till {date} @ {time}'
                : `Bracket for ${
                    stage.title
                  } must be built and published till {date} @ {time}`,
              isAutomated: true, //TODO
              visible: !this.isStagePastStatus(STAGE_STATUSES.PUBLISHED, stage),
            },
          ],
          []
        ),
        {
          key: 'tournament-start',
          eventType: EVENT_TYPES.STATUS_CHANGER,
          datetime: this.currentTournamentSchedule.tournament_start,
          textStub: 'Tournament will start on {date} @ {time}',
          isAutomated: true,
          visible: !this.isTournamentPastStatus(TOURNAMENT_STATUSES.STARTED),
        },
        ...(this.matchesCountSummary || []).map(el => ({
          key: 'matches',
          eventType: EVENT_TYPES.SUMMARY,
          datetime: el.date,
          isAutomated: false,
          visible: true,
          textStub: [
            'Matches:',
            ...(el.date === this.getTodaySeconds() ||
            (el.date < this.getTodaySeconds() &&
              el.summary.total !== el.summary.completed)
              ? Object.keys(el.summary).map(
                  key => `${this.$lodash.startCase(key)}`
                )
              : []),
          ],
        })),
      ];
    },
    // withoutOutdated() {
    //   return this.timelineEvents
    //     .filter(el => el.datetime >= this.getNowSeconds())
    //     .sort((a, b) => a.datetime - b.datetime);
    // },
    onlyVisible() {
      return this.timelineEvents
        .filter(el => el.visible)
        .sort((a, b) => a.datetime - b.datetime);
    },
    withDateLabels() {
      return this.onlyVisible.map(el => ({
        ...el,
        dateLabel: this.$filters.localDate(
          el.datetime,
          this.$times.DATE_SUBMED
        ),
        timeLabel: this.$filters.localDate(
          el.datetime,
          this.$times.TIME_SIMPLE
        ),
      }));
    },
    withTexts() {
      return this.withDateLabels.map(el => ({
        ...el,
        text:
          el.key === 'matches'
            ? el.textStub.map((stubString, index) => ({
                text: this.$t(
                  `_web_tournament-timeline-${el.key}-${
                    el.eventType
                  }-event-text-${index}`,
                  stubString
                ),
                count: index
                  ? this.matchesCountSummary.find(
                      summaryEl => summaryEl.date === el.datetime
                    ).summary[this.$lodash.snakeCase(stubString)]
                  : el.datetime === this.getTodaySeconds() ||
                    (el.datetime < this.getTodaySeconds() &&
                      this.matchesCountSummary.some(
                        summaryEl =>
                          summaryEl.summary.total !==
                          summaryEl.summary.completed
                      ))
                  ? ''
                  : this.matchesCountSummary.find(
                      summaryEl => summaryEl.date === el.datetime
                    ).summary.total,
              }))
            : this.$t(
                `_web_tournament-timeline-${el.key}-${el.eventType}-event-text`,
                el.textStub,
                { date: el.dateLabel, time: el.timeLabel }
              ),
      }));
    },
    grouppedEvents() {
      return this.withTexts.reduce(
        (acc, el) => ({
          ...acc,
          [this.getTodayLabel(el.dateLabel)]: [
            ...(Array.isArray(acc[el.dateLabel]) ? acc[el.dateLabel] : []),
            el,
          ],
        }),
        {}
      );
    },
    noEvents() {
      return Object.keys(this.grouppedEvents).length === 0;
    },
    matchesCountSummary() {
      return Object.entries(this.getActiveMatchesSummary).map(entry => ({
        summary: entry[1],
        date: entry[0],
      }));
    },
  },
  methods: {
    getTodayLabel(dateLabel) {
      return this.$filters.localDate(
        this.getNowSeconds(),
        this.$times.DATE_SUBMED
      ) === dateLabel
        ? this.$t('_web_tournament-timeline-today-label', 'Today({date})', {
            date: dateLabel,
          })
        : dateLabel;
    },
    getTodaySeconds() {
      return `${this.toSeconds(this.startOfDayObj())}`;
    },
  },
};
</script>
