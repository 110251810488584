<template>
  <div class="hr-post-wrapper custom-scrollbar">
    <Panel variant="secondary-card" class="position-relative hr-post">
      <Loader v-if="isPinning" />

      <AppIcon
        v-if="isDotsMenuVisible && isAuthorized"
        name="dots"
        class="cursor-pointer post-dots position-absolute py-2"
        :fill="$scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        @click="openPopoverMenu"
      />
      <AppIcon
        v-if="post.pinned || canPinPost"
        name="pin"
        width="15"
        :fill="post.pinned ? $scss.primaryColor : $scss.heraMuted"
        :hover-fill="$scss.hoverColor"
        class="cursor-pointer position-absolute post-pin"
        :class="{ disabled: !canPinPost }"
        @click="pinAction"
      />
      <HRPostCard :hide-cover="hideCover" v-bind="{ post, type }" />
      <HRPostCard
        v-if="post.original_post"
        :hide-cover="hideCover"
        :post="post.original_post.post"
        :type="post.original_post.post_type"
        reposted
        class="h-ml-5 h-pl-4"
      />

      <!-- <Comments v-if="showComments" :post="post" /> -->
    </Panel>
    <hr class="hr-hr m-0" />
    <div
      class="d-flex justify-content-between mt-5 post-actions hr-post-actions"
    >
      <div class="d-flex w-75">
        <a
          href="#"
          class="like-link h-ml-5"
          :class="{ active: post.reactions.like.reacted }"
          @click.prevent="like"
        >
          <img
            v-if="
              post &&
                post.reactions &&
                post.reactions.like &&
                !post.reactions.like.reacted
            "
            src="@/assets/images/comments/like-empty.svg"
            alt="like button"
            class="likes"
          />
          <img
            v-else
            src="@/assets/images/comments/like-filled.svg"
            alt="like button"
            class="like-button"
          />
          <span
            class="like-count-post"
            :class="{
              'like-count-reacted':
                post &&
                post.reactions &&
                post.reactions.like &&
                post.reactions.like.reacted,
            }"
          >
            {{ post.reactions.like.count }}
            {{
              isRTL
                ? post.reactions.like.count > 10
                  ? $t('_web_comment_likes', 'likes')
                  : $t('_web_comment_like', 'like')
                : post.reactions.like.count === 1
                ? $t('_web_comment_like', 'like')
                : $t('_web_comment_likes', 'likes')
            }}</span
          >
        </a>
        <!-- <a
        href="#"
        class="h-ml-5 dislike-link"
        :class="{ active: post.reactions.dislike.reacted }"
        @click.prevent="dislike"
      >
        <AppIcon name="dislike" class="dislikes" width="17" />
        <span>{{ post.reactions.dislike.count }}</span>
      </a> -->
        <a
          href="#"
          class="h-ml-5 comments-link"
          :class="{ active: post.comments_count.length > 0 }"
          @click.prevent="showComments = !showComments"
        >
          <AppIcon name="comments" class="comments" width="17" />
          <span class="actions-label"
            >{{ post.comments_count || 0 }}
            {{ $t('_web_comments', 'comments') }}
          </span>
        </a>
        <a
          href="#"
          class="h-ml-5 repost-link"
          :class="{ active: post.repost_count > 0 }"
          @click.prevent="
            $event =>
              openSharingMenu({
                event: $event,
                type: 'post',
                id: post.id,
                text: post.text,
              })
          "
        >
          <AppIcon id="share-icon" name="share" class="repost" width="15" />
          <span class="actions-label" @click="shareClick">
            {{ post.repost_count || 0 }}
            {{ $t('_web_comments_shares', 'shares') }}
          </span>
        </a>
      </div>
    </div>
    <!-- <hr class="hr-hr m-0" /> -->
    <div class="ml-5 hr-comments-wrap">
      <Comments :key="post.id" :post="post" />
    </div>
  </div>
</template>

<script>
import { sharingMenu } from '@/mixins/sharingMenu';
import { mapActions, mapGetters, mapState } from 'vuex';
import { GENERIC, INJECTION_POSTS } from '@/constants/postTypes';
import AppIcon from '@/components/atoms/Icon';
import Panel from '@/components/atoms/Panel.vue';
import HRPostCard from './HRPostContent.vue';
import Comments from '@/components/comments/Comments';
import Loader from '@/views/sliderchat/Loader';

export default {
  name: 'HRPost',
  components: { Loader, Comments, AppIcon, HRPostCard, Panel },
  mixins: [sharingMenu],
  props: {
    post: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: GENERIC,
    },
    hideCover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isLoadingRepost: false,
      isPinning: false,
      showDeleteConfirm: false,
      showComments: false,
      linkPreview: null,
      debouncedPreviewRequest: null,
    };
  },
  computed: {
    ...mapState('my', ['account', 'accountId', 'accountType']),
    ...mapGetters(['isRTL', 'permissions', 'isAuthorized']),
    isDotsMenuVisible() {
      return !INJECTION_POSTS.includes(this.type);
    },
    isMyPost() {
      const postAuthorId = this.post
        ? this.post.author.id || this.post.author.login
        : null;
      return this.accountId === postAuthorId;
    },
    canPinPost() {
      return this.permissions.pinPost && this.isMyPost;
    },
  },
  created() {
    this.debouncedPreviewRequest = this.$lodash.debounce(
      this.getOpenGraphData,
      300
    );
  },
  methods: {
    ...mapActions([
      'getOpenGraphDataByLink',
      'likePost',
      'repost',
      'togglePostPin',
      'errorNotify',
      'successNotify',
    ]),
    getOpenGraphData(link) {
      this.getOpenGraphDataByLink(link)
        .then(response => {
          if (response) {
            this.linkPreview = {
              ...response,
            };
          }
        })
        .catch(this.errorNotify);
    },
    postReaction(data) {
      const { post } = this;

      // Like Post
      if (data.type === 'Like' && this.isAuthorized) {
        if (post.reactions.like.reacted) {
          post.reactions.like.reacted = false;
          post.reactions.like.count--;
        } else {
          post.reactions.like.reacted = true;
          post.reactions.like.count++;

          if (post.reactions.dislike.reacted) {
            post.reactions.dislike.reacted = false;
            post.reactions.dislike.count--;
          }
        }

        // Dislike post
      } else {
        if (post.reactions.dislike.reacted && this.isAuthorized) {
          post.reactions.dislike.reacted = false;
          post.reactions.dislike.count--;
        } else {
          post.reactions.dislike.reacted = true;
          post.reactions.dislike.count++;

          if (post.reactions.like.reacted) {
            post.reactions.like.reacted = false;
            post.reactions.like.count--;
          }
        }
      }
    },
    like() {
      if (this.isAuthorized) {
        this.likePost({
          id: this.post.id,
          type: 'Like',
          reacted: this.post.reactions.like.reacted,
        });
        this.postReaction({
          type: 'Like',
          reacted: this.post.reactions.like.reacted,
        });
      } else {
        // this.$router.push({ name: 'signin' });
        this.$store.dispatch('openModal', {
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
          events: {
            cancel: this.$store.dispatch('closeModal'),
          },
        });
      }
    },
    dislike() {
      if (this.isAuthorized) {
        this.likePost({
          id: this.post.id,
          type: 'Dislike',
          reacted: this.post.reactions.dislike.reacted,
        });
        this.postReaction({
          type: 'Dislike',
          reacted: this.post.reactions.dislike.reacted,
        });
      } else {
        // this.$router.push({ name: 'signin' });
        this.$store.dispatch('openModal', {
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
          events: {
            cancel: this.$store.dispatch('closeModal'),
          },
        });
      }
    },
    onRepost() {
      if (this.isAuthorized) {
        const post =
          this.$lodash.get(this, 'post.original_post.post') || this.post;

        const data = {
          text: '',
          original_post_id: post.id,
          reposted_post_id: this.post.id,
        };

        this.repost(data)
          .then(() => {
            this.post.repost_count++;

            this.$emit('reposted');
          })
          .catch(this.errorNotify);
      } else {
        // this.$router.push({ name: 'signin' });
        this.$store.dispatch('openModal', {
          name: 'SigninActionModal',
          settings: {
            variant: 'fit-content',
          },
          events: {
            cancel: this.$store.dispatch('closeModal'),
          },
        });
      }
    },
    openPopoverMenu(event) {
      this.$emit('dotsClick', event.currentTarget);
    },
    pinAction() {
      this.isPinning = true;
      this.togglePostPin(this.post)
        .then(() => {
          this.isPinning = false;
          this.$emit('pinned', this.post);
        })
        .catch(this.errorNotify);
    },
    // restoreCommentsDefaults() {
    //   this.nextPageToken = '';
    //   this.comments = [];
    //   this.isLoading = false;
    //   this.commentLoading = false;
    //   this.willShowComments = false;
    // },
    // showComments(list) {
    //   if (!this.willShowComments && list > 0) {
    //     // this.comments = [];
    //     // this.getComments();
    //   }
    //
    //   // if (this.willShowComments) {
    //   //   this.restoreCommentsDefaults();
    //   // } else {
    //   //   this.willShowComments = true;
    //   // }
    // },
    shareClick() {
      let icon = document.getElementById('share-icon');
      return icon.click();
    },
  },
};
</script>

<style lang="scss" scoped>
/* TODO - TH-7127 use here loader from infinite scroll*/
/* TODO - TH-7340 refactor */
$spin-wrap-height: 5rem;
$spin-size: 1rem;
$spin-offset-y: 1.5rem; //calc(math.div($spin-wrap-height, 2) - math.div($spin-size, 2));

%spin-style {
  content: '';
  display: block;
  height: $spin-size;
  width: $spin-size;
  position: absolute;
  left: 50%;
  border: 2px solid transparent;
  border-left-color: $ph-accent;
  border-bottom-color: $ph-accent;
  border-radius: 50%;
  transform: translateX(-50%);
  @include rotate-element;
}

.hr-post-wrapper {
  background-color: #11171f;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible !important;
  @media (max-width: 1100px) {
    width: 100%;
    overflow-y: unset;
    overflow-x: unset;
    padding-bottom: 76px;
  }
}

.hr-post {
  @include auto-rtl(margin-left, 24px);
  @include auto-rtl(margin-right, 24px);
  padding: 20px 10px;
  padding-top: 30px;
  background-color: #11171f !important;
  @media (max-width: 1100px) {
    margin-left: 0px !important;
  }
}
.hr-post-hr {
  border: 0.5px solid #313139 !important;
  margin: 0 !important;
}

.hr-post-actions {
  color: white !important;
  margin: 0px !important;
  /*  border-top: 0.5px solid #313139;
  border-bottom: 0.5px solid #313139; */
  padding: 20px 10px;
  @media (max-width: 1100px) {
    :first-child {
      width: unset !important;
      // margin: auto;
    }
  }
}
.comments-count {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #ffffff;
}

.comments-link {
  cursor: default;
  color: #c1c7d7 !important;
}
.actions-label {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: #c1c7d7;
}
.hr-comments-wrap {
  // padding: 0px 10px;
  margin-left: 0px !important;

  @media (max-width: 1100px) {
    margin-left: 0px !important;
    // padding-left: 0px;
  }
}
.hr-post {
  min-width: 300px;
  background-color: #000000;

  hr {
    border: none;
    border: 0.5px solid #313139 !important;
    margin: 0 !important;
    /* margin: $ph-large-space 0; */
  }

  &-dots {
    top: 1.5rem;
    @include auto-rtl(right, 1.5rem);
  }

  &-pin {
    top: 1.9rem;
    @include auto-rtl(right, 4rem);
  }

  .post-actions {
    padding: 0;
    font-weight: bold;

    a,
    span svg {
      transition: fill 0.3s ease, color 0.3s ease;
    }

    span svg {
      @include auto-rtl(margin-right, $ph-small-space);
    }

    .playhera-icon {
      position: relative;

      &.likes {
        top: -2px;
      }

      &.dislikes {
        top: 3px;
      }

      &.comments {
        top: 0;
      }
    }

    a {
      color: $ph-secondary-text;
      display: flex;
      align-items: center;
      text-decoration: none;

      span svg use {
        fill: $ph-secondary-text;
      }

      &:hover,
      &.active {
        color: $ph-primary-text;

        span svg use {
          fill: $ph-primary-text;
        }
      }
    }

    .dislike-link {
      @include auto-rtl(margin-right, auto);
    }
  }
}
.like-link {
  @media (max-width: 1100px) {
    margin-left: 0px !important;
  }
}
.post-comments {
  &-sum {
    font-family: $ph-font-family-secondary;
  }

  .comment-item {
    font-size: $ph-body-font-size;

    &.form.loading {
      position: relative;

      /* TODO - TH-7127 loading from infinite scroll */
      &:after {
        top: 0.6rem;
        @extend %spin-style !optional;
        bottom: $spin-offset-y;
      }

      > div {
        display: none;
      }
    }

    .comment-input-footer {
      display: flex;
      justify-content: space-between;
      @include auto-rtl(padding-left, 50px);
    }

    .comment-input-action {
      width: 19px;
      cursor: pointer;
      @include auto-rtl(margin-right, $ph-small-space);

      button {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 0;
        border: none;

        &:hover {
          background-color: transparent;

          svg {
            fill: $ph-accent !important;
          }
        }
      }
    }
    .hr-hr {
      margin: 0px !important;
    }

    .comment-header {
      color: $ph-secondary-text;

      .login {
        font-family: $ph-font-family-secondary;
        font-size: $ph-body-font-size;
        font-weight: 600;
        color: $ph-primary-text;
        line-height: $ph-small-text-line-height;
        margin: 0 $ph-medium-space;
      }

      time {
        font-size: $ph-small-text-font-size;
        color: $ph-secondary-text;
      }
    }

    .comment-body {
      @include auto-rtl(margin-left, 50px);
    }

    .sc-post-attach {
      padding: 0;
    }
  }
}

.like-count-post {
  color: #c1c7d7;
  font-weight: bold;
  @include auto-rtl(margin-left, 8px);
}

.like-count-reacted {
  color: #9d9cf7;
}
</style>
