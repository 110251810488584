<template>
  <div>
    <Country
      v-model="accountCountry"
      v-validate="'required'"
      name="country"
      :label="$$t('Country', 'label')"
      required
      :error="errors.first('country')"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { translate } from '@/mixins/translate';
import Country from '@/components/molecules/form/autoComplete/Country';
import { CLAN } from '@/constants/accounts';

export default {
  name: 'AccountEditCountry',
  components: {
    Country,
  },
  inject: ['$validator'],
  mixins: [translate('_web_account-edit_country')],
  computed: {
    ...mapGetters(['accountEditData']),
    ...mapState('current', ['account']),
    ...mapState('my', { myAccount: 'account' }),
    accountCountry: {
      get() {
        return this.accountEditData.country;
      },
      set(country) {
        this.updateEditData({
          country: country ? country.value : '',
          country_id: country ? country.id : '',
        });
      },
    },
  },
  created() {
    if (this.account._type === CLAN && !this.account._id) {
      this.accountCountry = {
        value: this.myAccount.country,
        id: this.myAccount.countryId,
      };
    }
  },
  methods: {
    ...mapMutations(['updateEditData']),
  },
};
</script>
