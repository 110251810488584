var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('section',{},[_c('form',{staticClass:"remove-autofill-styles d-flex flex-column",attrs:{"id":("signin_form_" + _vm.idSuff),"autocomplete":_vm.autocomplete},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"signin-container"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"font-size-32 m-0"},[_vm._v("\n            "+_vm._s(_vm.$t('_web_sign_in_new', 'Log In To PLAYHERA'))+"\n          ")])]),_c('section',{attrs:{"slot":"alternative"},slot:"alternative"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('h1',{staticClass:"m-0",staticStyle:{"font-size":"1rem","font-weight":"500"}},[_c('span',[_vm._v("\n                  "+_vm._s(_vm.$$t("Don’t have an account?", 'no_account'))+"\n                ")]),_c('router-link',{staticStyle:{"color":"white","font-weight":"400","text-decoration":"underline"},attrs:{"to":{
                    name: 'signup',
                  },"tabindex":"5"}},[_vm._v("\n                  "+_vm._s(_vm.$t('_web_register', 'Register'))+"\n                ")])],1)])])]),_c('HorizontalTabs',{attrs:{"tabs":_vm.$options.signinTabs,"active-tab":_vm.activeTab,"use-custom":""},on:{"change":_vm.setActiveTab}}),_c('div',{staticClass:"input-container"},[_c('div',{staticClass:"text-danger"},[_vm._v("\n            "+_vm._s(_vm.authError &&
                _vm.$t(
                  '_web_server_errors_incorrect_credentials_email',
                  'Incorrect Email or Password.'
                ))+"\n          ")]),(_vm.signinByPhone)?_c('InputPhone',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],ref:"phoneInput",attrs:{"name":"phone","label":_vm.$$t('Phone Number', 'phone_input_label'),"error":_vm.errors.first('phone'),"saving-key":_vm.saveAuth ? 'last_phone' : null,"input-props":{
              autofocus: true,
              tabindex: 1,
            }},on:{"input":_vm.formChanged},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}}):_c('InputEmail',{directives:[{name:"focus",rawName:"v-focus"},{name:"validate",rawName:"v-validate",value:('required|email|max:128'),expression:"'required|email|max:128'"}],attrs:{"name":_vm.$$t('Email', 'email-label'),"type":"email","label":_vm.$$t('Email', 'email-label'),"tabindex":1,"placeholder":_vm.$t('_web_email_placeholder', 'your@email.com'),"autofocus":"","autocomplete-off":!_vm.saveAuth,"error":_vm.errors.first(_vm.$$t('Email', 'email-label'))},on:{"input":_vm.formChanged},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('InputPassword',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:64'),expression:"'required|max:64'"}],attrs:{"name":_vm.$t('_web_password_placeholder', 'Password'),"placeholder":_vm.$t('_web_password_placeholder', 'Password'),"forgot-pass":_vm.$$t('Forgot password?', 'forgot_password_link'),"label":_vm.$$t('Password', 'password_input_label'),"autocomplete-off":!_vm.saveAuth,"error":_vm.errors.first(_vm.$t('_web_password_placeholder', 'Password')),"input-props":{
              tabindex: 2,
              maxLength: 64,
            }},on:{"input":_vm.formChanged},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('HeraFormSubmitBtn',{staticClass:"new-login-btn",attrs:{"tabindex":3,"label":_vm.$$t('Login', 'submit_button'),"loading":_vm.isLoading}})],1),_c('div',{staticClass:"row justify-content-center mt-5"},[_c('GoogleSignIn'),_c('TwitterSignIn'),_c('AppleSignIn')],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }