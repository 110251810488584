<template>
  <div>
    <h2 class="following-title">
      {{ $t('_web_feed-following-title', 'FOLLOWING') }} ({{ total }})
    </h2>
    <div v-if="followingUsers.length > 0">
      <div
        v-for="(user, index) in followingUsers"
        :key="index"
        class="Following-user"
      >
        <LinkAccount :id="user.login" class="login" :type="user.type">
          <Avatar :img-url="user.avatar" :type="user.type" :size="48" />
          <div class="following-name">
            {{ user.type === 'profile' ? user.display_name : user.name }}
            <span v-if="user.name" class="account-tag">@{{ user.name }} </span>
            <span v-else class="account-tag">@{{ user.login }}</span>
          </div>
        </LinkAccount>
      </div>
    </div>
    <div v-else class="not-following">
      {{
        $t(
          '_web_feed-following-not-following-text',
          'You are not following anyone yet.'
        )
      }}
    </div>
    <button
      v-if="nextPage !== null && followingUsers.length > 0"
      class="show-more-less"
      @click="fetchFollowingUsers(account._id, nextPage)"
    >
      {{ $t('_web_feed-following-See-more', 'See more') }}
    </button>
    <button v-else class="show-more-less" @click="showLess(account._id)">
      <span v-if="followingUsers.length > 7">
        {{ $t('_web_feed-following-Show-less', 'Show less') }}
      </span>
    </button>
  </div>
</template>

<script>
import { API } from '@/api/config';
import { mapState } from 'vuex';
import LinkAccount from '@/components/links/Account';
import Avatar from '@/components/images/Avatar';

export default {
  name: 'Following',

  components: {
    LinkAccount,
    Avatar,
  },
  data() {
    return {
      followingUsers: [],
      nextPage: '',
      total: 0,
    };
  },
  computed: {
    ...mapState('my', ['account']),
  },
  created() {
    this.fetchFollowingUsers(this.account._id, this.nextPage);
  },
  methods: {
    async fetchFollowingUsers(id, nextPage) {
      if (nextPage !== null) {
        const response = await API(
          `profiles/${id}/follow?page=${nextPage}&page_size=7`
        );
        if (response.data.items.length !== 0) {
          this.total = response.data.total;
          this.nextPage = response.data.next_page_token;
          this.followingUsers = [
            ...this.followingUsers,
            ...response.data.items,
          ];
        } else {
          this.nextPage = null;
        }
      }
    },
    async showLess(id) {
      const response = await API(`profiles/${id}/follow?page=&page_size=7`);
      this.nextPage = response.data.next_page_token;

      this.followingUsers = [...response.data.items];
    },
  },
};
</script>

<style lang="scss">
.login {
  display: flex;

  .account-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $new-fg-neutral-faded;
    text-align: center;
  }
}
.Following-user {
  margin-bottom: 10px;
  .avatar {
    @include auto-rtl(margin-right, 10px);
  }
  .following-name {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #eff0f1;
  }
}
.following-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #eff0f1;
  margin-bottom: 24px;
}
.show-more-less {
  border: none;
  background-color: transparent;
  color: #9d9cf7;
  font-weight: 500;
  font-size: 14px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 0;
  line-height: 20px;
}
.not-following {
  color: #404a63;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
</style>
