<template>
  <div class="position-relative lang-switcher">
    <button
      ref="chosenLocale"
      class="dropdown-lang-button"
      @click="togglePopover"
    >
      {{ currentLocale.nativeTitle }}
      <AppIcon name="dropdown" width="10" />
    </button>
    <PopoverMenu
      class="dropdown-lang-menu"
      :position="langPopoverPosition"
      :closest="$el"
      :items="locales"
      :float="[bottomPosition ? 'bottom' : 'top', 'left']"
      regard="top"
      @toggle="togglePopoverVisible"
    >
      <template slot-scope="{ item }">
        <span @click="changeLocale(item)">{{ item.nativeTitle }}</span>
      </template>
    </PopoverMenu>
  </div>
</template>
<script>
import { isEmpty } from '@/utils/lodashUtils';
import { mapGetters, mapMutations } from 'vuex';
import PopoverMenu from '@/components/popover/Menu';
import AppIcon from '../atoms/Icon';

export default {
  name: 'HeraLangSwitcher',
  components: {
    AppIcon,
    PopoverMenu,
  },
  inject: ['$validator'],
  props: {
    defaultLocale: {
      type: String,
      default: null,
    },
    bottomPosition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      langPopoverPosition: null,
      popoverIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['currentLocale', 'locales', 'isRTL']),
    newClass() {
      return this.isRTL ? 'newClass' : '';
    },
  },
  created() {
    if (this.$route.query.lang) {
      const locale = this.locales.find(
        lang => lang.key === this.$route.query.lang
      );
      if (locale) {
        this.changeLocale(locale);
      }
    }
    if (isEmpty(this.currentLocale)) {
      const locale = this.locales.find(
        lang => lang.key === this.$i18n.locale()
      );
      if (locale) {
        this.changeLocale(locale);
      }
    }
  },
  methods: {
    ...mapMutations(['setLocale']),
    /**
     * open popover-menu for switch
     */
    togglePopover() {
      this.langPopoverPosition = this.popoverIsVisible
        ? null
        : this.$refs.chosenLocale.getBoundingClientRect();
    },
    /**
     * check if popover is visible
     */
    togglePopoverVisible(isVisible) {
      this.popoverIsVisible = isVisible;
    },
    /**
     * change locale
     * @param locale {Object} - new locale
     */
    changeLocale(locale) {
      this.langPopoverPosition = null;

      if (locale) {
        this.setLocale(locale);
        this.$i18n.set(locale.key);
        this.$validator.localize(locale.key);
      }
      this.$emit('localChanged', locale.key);
    },
  },
};
</script>

<style lang="scss">
.dropdown-lang-button {
  width: 124px;
  height: 48px;
  background-color: $new-bg-page-faded;
  font-family: $hr-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: $hr-text-small;
  letter-spacing: 0.16px;
  color: $hr-text-primary;
  padding: 0px !important;
  margin: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: none;
  border-top: 1px solid $hr-bgselected !important;
  &:hover {
    background-color: $new-bg-base;
  }
  &:focus {
    outline: none !important;
  }
}
.newClass {
  position: absolute;
  left: 0px !important;
  transform: translateX(0px);
  transform: translateY(-140px);
}

.dropdown-lang-menu {
  &:after {
    display: none !important;
  }
  max-height: 143px !important;
  border: none !important;
  position: absolute;
  transform: translateX(0px);
  transform: translateY(-140px);
  left: 0px !important;
  top: 10px !important;

  ul {
    background-color: $hr-bg-option !important;
  }
  li {
    font-family: $hr-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: $hr-text-small;
    letter-spacing: 0.16px;
    color: $hr-bg-search;
    width: 124px !important;
    height: 48px !important;
    & > *:not(hr) {
      line-height: 1.25rem !important;
      padding: 0rem !important;
      &.active {
        background-color: $hr-bgselected !important;
      }
      &:hover {
        background-color: $hr-bg-hover-option !important;
      }
      &.disabled {
        pointer-events: none !important;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
    span {
      padding-left: 10px !important;
      padding-top: 15px !important;
      padding-bottom: 13px !important;
      line-height: 1.25rem !important;
      padding-right: 10px !important;
    }
  }
}
.dropbox-menu {
  & > div {
    max-height: 143px !important;
  }
}
.custom-scrollbar {
  overflow-x: hidden;
}
.list-wrapper {
  border-radius: 0px !important;
}
</style>
