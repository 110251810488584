import { mapActions, mapGetters } from 'vuex';
import { get } from '@/utils/lodashUtils';
import { BATTLE_STATUSES as STATUSES } from '@/constants/battles';

export default {
  data() {
    return {
      gridKey: 'grid',
    };
  },
  computed: {
    ...mapGetters(['currentTournamentStage']),
  },
  created() {
    this.UPDATE_CONFIRM_MSG = this.$t(
      '_web_tournaments-tableview_update-confirm',
      'It takes some time to update further grid. Update it now?'
    );
  },
  methods: {
    ...mapActions([
      //match actions
      'updateMatchAction',
      'startMatchAction',
      'finishMatchAction',
      'resetMatchAction',
      'setTechWinAction',
      'resolveBattle',
      'resolveBattleInBattleRoyale',
      'provideBattleResultsByAdmin',
      'provideBattleResultsByAdminInBattleRoyale',
      'closeModal',
    ]),
    isStageFinished() {
      const stageEnd = this.secondsToDatetime(
        this.currentTournamentStage.date_end
      );
      const now = this.getNow();
      return now > stageEnd;
    },
    isMatchConflicted(match = {}) {
      return this.getBattleStatus(match) === STATUSES.judging;
    },
    isMatchFinished({ status } = {}) {
      return [STATUSES.completed, STATUSES.cancelled].includes(status);
    },
    canChangeMatchDate(status) {
      const hasValidStatus = ![
        STATUSES.started,
        STATUSES.completed,
        STATUSES.cancelled,
      ].includes(status);
      return hasValidStatus;
    },
    canChangeMatchDuration(status) {
      const hasValidStatus = ![STATUSES.completed, STATUSES.cancelled].includes(
        status
      );
      return hasValidStatus;
    },
    canViewMatchResult(match) {
      return [
        STATUSES.completed,
        STATUSES.cancelled,
        STATUSES.started,
        STATUSES.judging,
        STATUSES.pending,
      ].includes(this.getBattleStatus(match));
    },
    canEditMatchResult(match) {
      const matchStatus = this.getBattleStatus(match);
      return (
        this.isMatchConflicted(match) ||
        STATUSES.started === matchStatus ||
        (match.type === 'battle_royale' &&
          [STATUSES.completed, STATUSES.cancelled].includes(matchStatus))
      );
    },
    canSetTieResult(match) {
      const status = this.getBattleStatus(match);
      return this.canEditMatchResult(match) || status === STATUSES.pending;
    },
    getBattleStatus(match) {
      return match && get(match, 'status', '');
    },
    canStartMatch(match) {
      return [STATUSES.pending, STATUSES.scheduled].includes(
        this.getBattleStatus(match)
      );
    },
    canFinishMatch(match) {
      return this.getBattleStatus(match) === STATUSES.started;
    },
    canRestartMatch(match) {
      return [
        STATUSES.completed,
        STATUSES.cancelled,
        STATUSES.started,
        STATUSES.judging,
      ].includes(this.getBattleStatus(match));
    },
    isThirdPlaceMatch(match) {
      return Boolean(
        match &&
          (match.match_type === 'third_place' ||
            match.match_rank === 'third_place')
      );
    },
    setTechWin(params) {
      return this.setTechWinAction(params).catch(this.errorNotify);
    },
    startMatch({ round: roundID, id: matchID, index }) {
      this.closeModal();
      const hasValidMatch =
        this.matches &&
        this.matches[roundID] &&
        !this.$lodash.isEmpty(this.matches[roundID][index]);
      if (hasValidMatch) {
        this.matches[roundID][index].isLoading = true;
      }
      if (this.$refs.grid) {
        this.$refs.grid.forceRender();
      }
      return this.startMatchAction({ roundID, matchID })
        .then(() => {
          this.successNotify({
            text: this.$t(
              '_web_tournament_match-started',
              'Match is successfully started'
            ),
          });
        })
        .catch(this.errorNotify)
        .finally(() => {
          if (hasValidMatch) {
            this.matches[roundID][index].isLoading = false;
          }
        });
    },
    resetMatch({ round: roundID, id: matchID, index }) {
      this.closeModal();
      const hasValidMatch =
        this.matches &&
        this.matches[roundID] &&
        !this.$lodash.isEmpty(this.matches[roundID][index]);
      if (hasValidMatch) {
        this.matches[roundID][index].isLoading = true;
      }
      if (this.$refs.grid) {
        this.$refs.grid.forceRender();
      }
      return this.resetMatchAction({ roundID, matchID })
        .then(() => {
          this.successNotify({
            text: this.$t(
              '_web_tournament_match-reseted',
              'Match is successfully reseted'
            ),
          });
        })
        .catch(this.errorNotify)
        .finally(() => {
          if (hasValidMatch) {
            this.matches[roundID][index].isLoading = false;
          }
        });
    },
    finishMatch({ round: roundID, id: matchID, index }) {
      this.closeModal();
      const hasValidMatch =
        this.matches &&
        this.matches[roundID] &&
        !this.$lodash.isEmpty(this.matches[roundID][index]);
      if (hasValidMatch) {
        this.matches[roundID][index].isLoading = true;
      }
      if (this.$refs.grid) {
        this.$refs.grid.forceRender();
      }
      return this.finishMatchAction({ roundID, matchID })
        .then(() => {
          this.successNotify({
            text: this.$t(
              '_web_tournament_match-finished',
              'Match is successfully finished'
            ),
          });
        })
        .catch(this.errorNotify)
        .finally(() => {
          if (hasValidMatch) {
            this.matches[roundID][index].isLoading = false;
          }
        });
    },
    saveResults(match, isBattleRoyale = false) {
      if (this.isMatchConflicted(match)) {
        return this.resolveMatchResults(match, isBattleRoyale);
      } else {
        return this.editMatchResults(match, isBattleRoyale);
      }
    },
    resolveMatchResults(match, isBattleRoyale) {
      return this[
        isBattleRoyale ? 'resolveBattleInBattleRoyale' : 'resolveBattle'
      ]({
        battleID: match.battle_id,
        matchID: match.id,
        tournamentID: this.tournamentID,
        stageID: this.stageID,
        blockID: this.competitionID,
        players: match.players,
      });
    },
    editMatchResults(match, isBattleRoyale) {
      return this[
        isBattleRoyale
          ? 'provideBattleResultsByAdminInBattleRoyale'
          : 'provideBattleResultsByAdmin'
      ](match).then(() => {
        if (isBattleRoyale) {
          match.players.forEach(item => (item.isChanged = false));
        }
      });
    },
    onMatchDurationChanged(match, duration) {
      const roundID = match.round || 0;
      if (duration) {
        const hasValidMatch =
          this.matches &&
          this.matches[roundID] &&
          !this.$lodash.isEmpty(this.matches[roundID][match.index]);
        if (hasValidMatch) {
          this.$set(this.matches[roundID][match.index], 'isLoading', true);
        }
        if (this.$refs.grid) {
          this.$refs.grid.forceRender();
        }
        return this.updateMatchAction({
          roundID,
          matchID: match.id,
          data: {
            // date_start: match.date_start,
            date_end: match.date_start + duration,
          },
        })
          .then(() => {
            this.successNotify({
              text: this.$t(
                '_web_tournament_match-duration-changed',
                'Match duration changed. Further schedule recalculated'
              ),
            });
          })
          .then(() => this.fetchRoundsData(true))
          .then(() => {
            this.forceUpdate = true;
            return this.fetchMatchesData(roundID + 1);
          })
          .catch(e => {
            this.updateGridKey();
            this.errorNotify(e);
          })
          .finally(() => {
            this.forceUpdate = false;
            this.matches[roundID][match.index].isLoading = false;
          });
      }
    },
    onMatchDateChanged(match, seconds, prop = 'date_start') {
      const roundID = match.round || 0;
      const duration = match.date_end - match.date_start;
      if (seconds) {
        const hasValidMatch =
          this.matches &&
          this.matches[roundID] &&
          !this.$lodash.isEmpty(this.matches[roundID][match.index]);
        if (hasValidMatch) {
          this.matches[roundID][match.index].isLoading = true;
        }
        const data =
          prop === 'date_start'
            ? {
                date_start: seconds,
                date_end: seconds + duration,
              }
            : {
                date_end: seconds,
              };
        return this.updateMatchAction({
          roundID,
          matchID: match.id,
          data,
        })
          .then(() => {
            this.successNotify({
              text: this.$t(
                `_web_tournament_match-${prop}-changed`,
                `Match ${prop.replace(
                  'date_',
                  ''
                )} date changed. Further schedule recalculated`
              ),
            });
          })
          .then(() => this.fetchRoundsData(true))
          .then(() => {
            this.forceUpdate = true;
            return this.fetchMatchesData(roundID + 1);
          })
          .catch(e => {
            this.updateGridKey();
            this.errorNotify(e);
          })
          .finally(() => {
            this.forceUpdate = false;
            this.matches[roundID][match.index].isLoading = false;
            if (typeof this.setActiveMatchConfig === 'function') {
              this.setActiveMatchConfig();
            }
          });
      }
    },
    updateGridKey() {
      this.gridKey = 'grid' + Date.now();
    },
  },
};
