<template>
  <Icon :name="iconName" v-bind="{ ...iconProps }" />
</template>

<script>
import Icon from '../atoms/Icon';
import { prop } from '@/utils/factories';

export default {
  name: 'SortIcon',
  components: { Icon },
  props: {
    value: prop(String),
    name: prop(String),
    sortOrder: prop(Number),
    iconProps: prop(null),
  },
  computed: {
    iconName() {
      return `arrow-${
        this.value !== this.name ? 't-b' : this.sortOrder === 1 ? 'b' : 't'
      }`;
    },
  },
};
</script>
