<template>
  <span
    :class="[
      `tournament-status-icon-base`,
      `tournament-status-icon-${variant.type}`,
    ]"
    class="uppercase-status"
  >
    {{ variant.text }}
  </span>
</template>

<script>
import { prop, propRequired } from '@/utils/factories';

export default {
  props: {
    tournamentStatus: propRequired(String),
    mini: prop(Boolean),
  },
  computed: {
    variant() {
      if (this.tournamentStatus === 'registration_opened') {
        return {
          type: 'regopened',
          text: this.$t('signup_label', 'Sign Up'),
        };
      } else if (this.tournamentStatus === 'announced') {
        return {
          type: 'regopened',
          text: this.$t('_android_status_announced', 'COMING UP'),
        };
      } else if (this.tournamentStatus === 'registration_closed') {
        return {
          type: 'lineup',
          text: this.$t('signup_label', 'Sign Up'),
        };
      } else if (this.tournamentStatus === 'qualification_completed') {
        return {
          type: 'lineup',
          text: this.$t(
            '_web_tournaments-list_status_qualification',
            'Qualification'
          ),
        };
      } else if (this.tournamentStatus === 'started') {
        return {
          type: 'started',
          text: this.$t('_web_status_started', 'STARTED'),
        };
      } else if (this.tournamentStatus === 'draft') {
        return {
          type: 'draft',
          text: this.$t('_web_status_draft', 'DRAFT'),
        };
      } else {
        return {
          type: 'finished',
          text: this.$t('_web_status_finished', 'FINISHED'),
        };
      }
    },
  },
};
</script>

<style lang="scss">
.tournament-status-icon-base {
  border-radius: 5px;
  padding: $hr-spacing-01 $hr-spacing-03 $hr-spacing-01 $hr-spacing-03;
}
.tournament-status-icon-lineup {
  border: 1px solid $hr-status-lineup;
  color: $hr-status-lineup;
}
.tournament-status-icon-started {
  border: 1px solid $hr-status-started;
  color: $hr-status-started;
}
.tournament-status-icon-regopened {
  border: 1px solid $hr-status-regopened;
  color: $hr-status-regopened;
}
.tournament-status-icon-finished {
  border: 1px solid $hr-status-finished;
  color: $hr-status-finished;
}
.tournament-status-icon-draft {
  border: 1px solid $hr-status-finished;
  color: $hr-status-finished;
}
.uppercase-status {
  text-transform: uppercase;
}
</style>
