var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WrapperFormControl',_vm._b({},'WrapperFormControl',{
    hideLabel: !_vm.label,
    required: _vm.required && !_vm.hideAsterisk,
    label: _vm.label,
    tooltip: _vm.tooltip,
    error: _vm.error,
    hideErrorText: _vm.hideErrorText,
  },false),[_c('template',{slot:"control"},[_c('VueDatePicker',_vm._g(_vm._b({staticClass:"time-picker",class:{ duration: _vm.duration, 'time-picker--fit-content': _vm.fitContent },attrs:{"type":"time","value-type":"timestamp","minute-step":5,"format":_vm.duration ? 'HH[h] mm[m]' : 'HH:mm',"clearable":false},model:{value:(_vm.valueModel),callback:function ($$v) {_vm.valueModel=$$v},expression:"valueModel"}},'VueDatePicker',{
        disabled: _vm.disabled,
        disabledTime: _vm.disabledTime,
        placeholder: _vm.placeholder,
        inputAttr: { size: !_vm.fitContent ? 20 : _vm.duration ? 5 : 4 },
      },false),_vm.eventListeners),[_c('template',{slot:"icon-calendar"},[(!_vm.hideIcon)?_c('Icon',{staticClass:"dropdown-icon",attrs:{"name":"arrow-s","width":"16"}}):_vm._e()],1)],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }