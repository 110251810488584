const state = {
  feedType: 'trending',
  createPostModal: false,
};

const getters = {
  feedType: state => state.feedType,
  createPostModal: state => state.createPostModal,
};

const mutations = {
  setFeedType(state, feedType) {
    state.feedType = feedType;
  },
  setCreatePostModal(state, createPostModal) {
    state.createPostModal = createPostModal;
  },
};

const actions = {
  setFeedType({ commit }, feedType) {
    commit('setFeedType', feedType);
  },
  setCreatePostModal({ commit }, createPostModal) {
    commit('setCreatePostModal', createPostModal);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
